import React, { useContext, useEffect } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import CodeContext from "../../context/cuentaBloqueada/codeContext";
import AuthContext from "../../context/autenticacion/authContext";
import ReactGa from "react-ga";

import SolicitudView from "./SolicitudView";

const Solicitud = (props) => {
  const codeContext = useContext(CodeContext);
  const authContext = useContext(AuthContext);

  const { limpiarVariablesAccountBlock, mensaje, autenticado } = authContext;
  const { limpiarVariablesCodePost, limpiarVariablesCode } = codeContext;
  useEffect(() => {
    return () => {
      limpiarVariablesAccountBlock();
      limpiarVariablesCodePost();
      limpiarVariablesCode();
    };
  }, []);

  useEffect(() => {
    if (autenticado) {
      props.history.push("/mi-habitat-digital/afiliado/inicio");
    } else {
      ReactGa.pageview(window.location.href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensaje, autenticado, props.history]);
  return <SolicitudView ruta={props.location.pathname}></SolicitudView>;
};
export default hocWidthWindow(Solicitud);
