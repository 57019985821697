import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import {
  ModalPlazosNormativosComponent,
  Header,
  Body,
  NextButton,
} from "./styles";
import { Modal } from "@mui/material";

const ModalPlazosNormativos = ({ open, onNext, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalPlazosNormativosComponent>
        <Header>
          <div className="close" onClick={onClose}>
            <BsX />
          </div>
          <p className="title">Confirmación</p>
        </Header>
        <Body>
          <div className="header">
            <AiOutlineCalendar />
            <div className="title">Transferencia bancaria</div>
          </div>
          <div className="description">
            Se hará efectivo dentro de los siguientes 5 días útiles despues de
            registrada la solicitud.
          </div>
        </Body>
        <NextButton onClick={onNext}>Entendido</NextButton>
      </ModalPlazosNormativosComponent>
    </Modal>
  );
};

export default ModalPlazosNormativos;
