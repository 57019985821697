/** LOGIN */
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_FORCE = "LOGIN_FORCE";
export const LOGIN_ERROR_SERVICE = "LOGIN_FORCE_SERVICE";
export const RESET_TIMER = "RESET_TIMER";
export const CAMBIO_CLAVE_EXITOSO = "CAMBIO_CLAVE_EXITOSO";
export const VALIDO_DATOS_EXITOSO = "VALIDO_DATOS_EXITOSO";
export const CLOSE_MODAL_CAMBIO_CLAVE = "CLOSE_MODAL_CAMBIO_CLAVE";
export const CLOSE_MODAL_VALIDO_DATOS = "CLOSE_MODAL_VALIDO_DATOS";
export const CLOSE_MODAL_BANNER = "CLOSE_MODAL_BANNER";

export const ACCOUNT_BLOCK_LOADING = "ACCOUNT_BLOCK_LOADING";
export const ACCOUNT_BLOCK_DATA = "ACCOUNT_BLOCK_DATA";
export const ACCOUNT_BLOCK_ERROR = "ACCOUNT_BLOCK_ERROR";
export const ACCOUNT_BLOCK_CLEAR = "ACCOUNT_BLOCK_CLEAR";

/** HOME */
export const HOME_EXITOSO = "HOME_EXITOSO";
export const HOME_ERROR = "HOME_ERROR";
export const HOME_LOADIN = "HOME_LOADIN";
export const HOME_CLEANER = "HOME_CLEANER";
export const HOME_CLEANER_PARCIAL = "HOME_CLEANER_PARCIAL";

/** ESTADO CUENTA MENSUAL */
export const EC_MENSUAL_LISTA_MESES_EXITOSO = "EC_MENSUAL_LISTA_MESES_EXITOSO";
export const EC_MENSUAL_LISTA_MESES_ERROR = "EC_MENSUAL_LISTA_MESES_ERROR";
export const EC_MENSUAL_LISTA_MESES_LOADIN = "EC_MENSUAL_LISTA_MESES_LOADIN";
export const EC_MENSUAL_LISTA_MESES_CLEANER = "EC_MENSUAL_LISTA_MESES_CLEANER";
export const EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL =
  "EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL";
export const EC_MENSUAL_CLEANER_ONLY_VARIABLE =
  "EC_MENSUAL_CLEANER_ONLY_VARIABLE";

export const EC_MENSUAL_EXITOSO = "EC_MENSUAL_EXITOSO";
export const EC_MENSUAL_ERROR = "EC_MENSUAL_ERROR";
export const EC_MENSUAL_LOADIN = "EC_MENSUAL_LOADIN";
export const EC_MENSUAL_CLEANER = "EC_MENSUAL_CLEANER";

/** ESTADO CUENTA NO APORTANTE */
export const EC_NO_APORTANTE_EXITOSO = "EC_NO_APORTANTE_EXITOSO";
export const EC_NO_APORTANTE_ERROR = "EC_NO_APORTANTE_ERROR";
export const EC_NO_APORTANTE_LOADIN = "EC_NO_APORTANTE_LOADIN";
export const EC_NO_APORTANTE_CLEANER = "EC_NO_APORTANTE_CLEANER";
export const EC_NO_APORTANTE_CLEANER_PARCIAL =
  "EC_NO_APORTANTE_CLEANER_PARCIAL";

/** CAMBIO DE FONDO */
export const CAMBIO_FONDO_GET_EXITOSO = "CAMBIO_FONDO_GET_EXITOSO";
export const CAMBIO_FONDO_GET_ERROR = "CAMBIO_FONDO_GET_ERROR";
export const CAMBIO_FONDO_GET_LOADIN = "CAMBIO_FONDO_GET_LOADIN";
export const CAMBIO_FONDO_CLEANER = "CAMBIO_FONDO_CLEANER";
export const CAMBIO_FONDO_CLEANER_PARCIAL = "CAMBIO_FONDO_CLEANER_PARCIAL";

export const CAMBIO_FONDO_POST_EXITOSO = "CAMBIO_FONDO_POST_EXITOSO";
export const CAMBIO_FONDO_POST_ERROR = "CAMBIO_FONDO_POST_ERROR";
export const CAMBIO_FONDO_POST_LOADIN = "CAMBIO_FONDO_POST_LOADIN";

/** ULTIMOS MOVIMIENTOS */
export const ULTIMOS_MOVIMIENTOS_EXITOSO = "ULTIMOS_MOVIMIENTOS_EXITOSO";
export const ULTIMOS_MOVIMIENTOS_ERROR = "ULTIMOS_MOVIMIENTOS_ERROR";
export const ULTIMOS_MOVIMIENTOS_LOADIN = "ULTIMOS_MOVIMIENTOS_LOADIN";
export const ULTIMOS_MOVIMIENTOS_CLEANER = "ULTIMOS_MOVIMIENTOS_CLEANER";
export const ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL =
  "ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL";

/** RETIRO APV SIN FIN*/
export const RETIRO_APVSINFIN_GET_EXITOSO = "RETIRO_APVSINFIN_GET_EXITOSO";
export const RETIRO_APVSINFIN_GET_ERROR = "RETIRO_APVSINFIN_GET_ERROR";
export const RETIRO_APVSINFIN_GET_LOADIN = "RETIRO_APVSINFIN_GET_LOADIN";
export const RETIRO_APVSINFIN_CLEANER = "RETIRO_APVSINFIN_CLEANER";
export const RETIRO_APVSINFIN_CLEANER_PARCIAL =
  "RETIRO_APVSINFIN_CLEANER_PARCIAL";

export const RETIRO_APVSINFIN_POST_EXITOSO = "RETIRO_APVSINFIN_POST_EXITOSO";
export const RETIRO_APVSINFIN_POST_ERROR = "RETIRO_APVSINFIN_POST_ERROR";
export const RETIRO_APVSINFIN_POST_LOADIN = "RETIRO_APVSINFIN_POST_LOADIN";
export const RETIRO_RESETEAR = "RETIRO_RESETEAR";

/** APORTE APV SIN FIN*/
export const APORTAR_APVSINFIN_GET_EXITOSO = "APORTAR_APVSINFIN_GET_EXITOSO";
export const APORTAR_APVSINFIN_GET_ERROR = "APORTAR_APVSINFIN_GET_ERROR";
export const APORTAR_APVSINFIN_GET_LOADIN = "APORTAR_APVSINFIN_GET_LOADIN";
export const APORTAR_APVSINFIN_CLEANER = "APORTAR_APVSINFIN_CLEANER";
export const APORTAR_APVSINFIN_CLEANER_PARCIAL =
  "APORTAR_APVSINFIN_CLEANER_PARCIAL";
export const APORTAR_APVSINFIN_CLEANER_MENSAJE =
  "APORTAR_APVSINFIN_CLEANER_MENSAJE";

export const APORTAR_APVSINFIN_POST_EXITOSO = "APORTAR_APVSINFIN_POST_EXITOSO";
export const APORTAR_APVSINFIN_POST_ERROR = "APORTAR_APVSINFIN_POST_ERROR";
export const APORTAR_APVSINFIN_POST_LOADIN = "APORTAR_APVSINFIN_POST_LOADIN";
export const APORTAR_RESETEAR = "APORTAR_RESETEAR";
export const APORTAR_CLEAR_MENSAJE_POST = "APORTAR_CLEAR_MENSAJE_POST";

/** MODAL */
export const HOME_LAYOUT_MODAL = "HOME_LAYOUT_MODAL";
export const HOME_CLEAR_LAYOUT_MODAL = "HOME_CLEAR_LAYOUT_MODAL";

/** MODAL TOKEN DIGITAL */

export const CODE_GET_EXITOSO = "CODE_GET_EXITOSO";
export const CODE_BTN_LOADIN = "CODE_BTN_LOADIN";
export const CODE_GET_ERROR = "CODE_GET_ERROR";
export const CODE_CLEANER = "CODE_CLEANER";
export const CODE_CLEANER_PARCIAL = "CODE_CLEANER_PARCIAL";
export const CODE_CLEANER_POST = "CODE_CLEANER_POST";
export const CODE_POST_EXITOSO = "CODE_POST_EXITOSO";
export const CODE_POST_ERROR = "CODE_POST_ERROR";
export const CODE_POST_LOADIN = "CODE_POST_LOADIN";

/** MODAL */
export const LAYOUT_TIMER = "LAYOUT_TIMER";

/** SLIDER */
export const SLIDER_TOGGLE = "SLIDER_TOGGLE";

/** RETIRO DU038*/
export const RETIRO_DU038_GET_EXITOSO = "RETIRO_DU038_GET_EXITOSO";
export const RETIRO_DU038_GET_ERROR = "RETIRO_DU038_GET_ERROR";
export const RETIRO_DU038_GET_LOADIN = "RETIRO_DU038_GET_LOADIN";
export const RETIRO_DU038_CLEANER = "RETIRO_DU038_CLEANER";

export const RETIRO_DU038_POST_EXITOSO = "RETIRO_DU038_POST_EXITOSO";
export const RETIRO_DU038_POST_ERROR = "RETIRO_DU038_POST_ERROR";
export const RETIRO_DU038_POST_LOADIN = "RETIRO_DU038_POST_LOADIN";
export const RETIRO_DU038_RESETEAR = "RETIRO_DU038_RESETEAR";

/** GRAPHYCS*/
export const HOME_GET_GRAPHYC = "GET DATA";
export const HOME_LOADING_GRAPHYC = "LOADING DATA";
export const HOME_ERROR_GRAPHYC = "ERROR DATA";
export const HOME_RESETEAR_GRAPHYC = "RESETEAR DATA";

/** MODAL TOKEN DIGITAL CUENTA BLOQUEADA */

export const CODE_BLOQUEADA_GET_EXITOSO = "CODE_BLOQUEADA_GET_EXITOSO";
export const CODE_BLOQUEADA_BTN_LOADIN = "CODE_BLOQUEADA_BTN_LOADIN";
export const CODE_BLOQUEADA_GET_ERROR = "CODE_BLOQUEADA_GET_ERROR";
export const CODE_BLOQUEADA_CLEANER = "CODE_BLOQUEADA_CLEANER";
export const CODE_BLOQUEADA_CLEANER_PARCIAL = "CODE_BLOQUEADA_CLEANER_PARCIAL";
export const CODE_BLOQUEADA_CLEANER_POST = "CODE_BLOQUEADA_CLEANER_POST";
export const CODE_BLOQUEADA_POST_EXITOSO = "CODE_BLOQUEADA_POST_EXITOSO";
export const CODE_BLOQUEADA_POST_ERROR = "CODE_BLOQUEADA_POST_ERROR";
export const CODE_BLOQUEADA_POST_LOADIN = "CODE_BLOQUEADA_POST_LOADIN";

/** GRAPHYCS*/
export const ETARIO_GET = "GET ETARIO";
export const ETARIO_LOADING = "LOADING ETARIO";
export const ETARIO_ERROR = "ERROR ETARIO";
export const ETARIO_RESETEAR = "RESETEAR ETARIO";
export const ETARIO_POTS = "POST ETARIO";
export const ETARIO_CONNECT = "CONNECT ETARIO";
export const GET_DATA_INVESTMENTS = "GET_DATA_INVESTMENTS";

/** GRAPHYCS*/
export const VALORES_CUOTA_GET = "GET VALORES CUOTA";
export const VALORES_CUOTA_LOADING = "LOADING VALORES CUOTA";
export const VALORES_CUOTA_ERROR = "ERROR VALORES CUOTA";
export const VALORES_CUOTA_RESETEAR = "RESETEAR VALORES CUOTA";
export const VALORES_CUOTA_POTS = "POST VALORES CUOTA";
export const VALORES_CUOTA_CONNECT = "CONNECT VALORES CUOTA";
export const VALORES_CUOTA_UTILS_GET = "GET VALORES CUOTA UTILS";
export const VALORES_CUOTA_VAL_12_GET = "GET VALORES CUOTA VALORES 12";
export const VALORES_CUOTA_VAL_12_LOADING = "LOADING VALORES CUOTA VALORES 12";
export const VALORES_CUOTA_VAL_12_ERROR = "ERROR VALORES CUOTA VALORES 12";
export const VALORES_CUOTA_VAL_12_RESETEAR =
  "RESETEAR VALORES CUOTA VALORES 12";

export const ERROR_GENERICO = "ERROR_GENERICO";
