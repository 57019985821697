import React from "react";

export default function AddIcon({ size = 24, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1498 6.43367L3.03312 30.0003C2.74207 30.5044 2.58807 31.0758 2.58644 31.6579C2.58481 32.2399 2.73561 32.8122 3.02383 33.3179C3.31206 33.8235 3.72767 34.2449 4.2293 34.5401C4.73094 34.8353 5.30112 34.9939 5.88312 35.0003H34.1165C34.6985 34.9939 35.2686 34.8353 35.7703 34.5401C36.2719 34.2449 36.6875 33.8235 36.9757 33.3179C37.264 32.8122 37.4148 32.2399 37.4131 31.6579C37.4115 31.0758 37.2575 30.5044 36.9665 30.0003L22.8498 6.43367C22.5527 5.94385 22.1343 5.53887 21.6351 5.25781C21.1359 4.97675 20.5727 4.8291 19.9998 4.8291C19.4269 4.8291 18.8637 4.97675 18.3645 5.25781C17.8653 5.53887 17.4469 5.94385 17.1498 6.43367V6.43367Z"
        stroke="#EFBD0C"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 15V21.6667"
        stroke="#EFBD0C"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 28.333H20.0147"
        stroke="#EFBD0C"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
