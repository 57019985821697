import styled, { css } from "styled-components";
import { device } from "../../../utils/device";
import Button from "../../../components/Buttons";
import * as Input from "../../../components/Input";
import DividerComponent from "../../../components/Divider";

export const PasoDatosDelAfiliadoComponent = styled.div`
  p {
    margin-bottom: 0;
  }

  ${css`
    @media screen and ${device.tablet} {
      width: calc(100% / 8 * 6);
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and ${device.desktopS} {
      width: 100%;
      display: grid;
      grid-template-rows: auto auto auto 1fr auto;
    }

    @media screen and ${device.desktop} {
      width: calc(100% / 12 * 10);
    }

    @media screen and ${device.desktopL} {
      width: calc(100% / 12 * 8);
    }
  `}
`;

export const ResponseRequestWrapper = styled.div`
  span.noSearch {
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-items: center;
    color: ${(p) => p.theme.palette.grayscale[300]};

    ${css`
      @media screen and ${device.desktopS} {
        display: grid;
      }
    `}
  }
`;

export const Affiliate = styled.div`
  padding: 24px;
  border-radius: 24px;
  border: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  margin: 16px 0;
`;

export const AffiliateWrapper = styled.div`
  padding: 10px;
  display: grid;
  column-gap: 10px;
  grid-template-areas:
    "avatar name"
    "avatar document";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
`;

export const AffiliateSubtitle = styled.p`
  ${(p) => p.theme.tipography.bodySmallRegular}
  padding-bottom: 8px;
`;

export const AffiliateName = styled.p`
  grid-area: name;
  ${(p) => p.theme.tipography.bodyMediumBold}
`;

export const AffiliateAvatar = styled.p`
  grid-area: avatar;
  width: 32px;
  height: 32px;
  display: grid;
  justify-content: center;
  align-content: center;
  align-self: center;
  border-radius: 100%;
  background-color: ${(p) => p.theme.palette.primary[50]};
  color: ${(p) => p.theme.palette.primary.main};
  ${(p) => p.theme.tipography.bodySmallRegular}
`;

export const AffiliateDocument = styled.p`
  grid-area: document;
  ${(p) => p.theme.tipography.caption}
  color: ${(p) => p.theme.palette.grayscale[400]};
`;

export const FormWrapper = styled.div`
  ${css`
    @media screen and ${device.desktopS} {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      column-gap: 16px;
    }
  `}
`;

export const Divider = styled(DividerComponent)`
  display: none;

  ${css`
    @media screen and ${device.desktopS} {
      display: block;
    }
  `}
`;

export const CaptchaWrapper = styled(Input.Wrapper)`
  display: grid;
  justify-items: center;

  ${css`
    @media screen and ${device.desktopS} {
      grid-column: 1/-1;
      /* margin-bottom: 0; */
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

export const SearchButton = styled(Button)`
  grid-row: 1/2;
  grid-column: 3/4;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;

  ${css`
    @media screen and ${device.desktopS} {
      margin-bottom: 0;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;

  ${css`
    @media screen and ${device.desktopS} {
      display: grid;
      grid-template-columns: auto auto;
      gap: 16px;
    }
  `}
`;

export const PrevButton = styled(Button)`
  display: none;

  ${css`
    @media screen and ${device.desktopS} {
      display: block;
    }
  `}
`;

export const NextButton = styled(Button)``;
