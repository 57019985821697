import {
    PAGOS_DESEMBOLSOS_CLEANER,
    PAGOS_DESEMBOLSOS_ERROR,
    PAGOS_DESEMBOLSOS_EXITOSO,
    PAGOS_DESEMBOLSOS_LOADING,

    PAGOS_DESEMBOLSOS_PERIODOS_CLEANER,
    PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
    PAGOS_DESEMBOLSOS_PERIODOS_LOADING,
    PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO,

    PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER,
    DETALLE_TRAMITE_EXITOSO,
    DETALLE_TRAMITE_LOADING,
    DETALLE_TRAMITE_ERROR,
    CHECK_EXITOSO,
    CHECK_LOADING,
    CHECK_ERROR,
    VALORES_CUOTA_LOADING,
    VALORES_CUOTA_EXITOSO,
    VALORES_CUOTA_ERROR,

    USER_LOADING,
    USER_EXITOSO,
    USER_ERROR,

    GRAFICO_VALORES_CUOTA_RANGO_FECHAS_LOADING,
    GRAFICO_VALORES_CUOTA_RANGO_FECHAS_EXITOSO,
    GRAFICO_VALORES_CUOTA_RANGO_FECHAS_ERROR,

    GRAFICO_DATA_PIECHART_LOADING,
    GRAFICO_DATA_PIECHART_EXITOSO,
    GRAFICO_DATA_PIECHART_ERROR,

    GRAFICO_DATA_CARTERA_LOADING,
    GRAFICO_DATA_CARTERA_EXITOSO,
    GRAFICO_DATA_CARTERA_ERROR
} from "../../types/fondoLibre";

export default (state, action) => {
    switch (action.type) {
        /* Tramite */
        case DETALLE_TRAMITE_EXITOSO:
            return {
                ...state,
                detalle: action.payload,
                loadingPage: false,
            }
        case DETALLE_TRAMITE_LOADING:
            return {
                ...state,
                detalle: null,
                loadingPage: true,
            };
        case DETALLE_TRAMITE_ERROR:
            return {
                ...state,
                detalle: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /*CHECK TRAMITE*/
        case CHECK_EXITOSO:
            return {
                ...state,
                check: action.payload,
                loadingPage: false,
            }
        case CHECK_LOADING:
            return {
                ...state,
                check: null,
                loadingPage: true,
            };
        case CHECK_ERROR:
            return {
                ...state,
                check: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* Empresas */
        /* VALORES_CUOTA */
        case VALORES_CUOTA_EXITOSO:
            return {
                ...state,
                valoresCuota: action.payload,
                loadingPage: false,
            }
        case VALORES_CUOTA_LOADING:
            return {
                ...state,
                valoresCuota: null,
                loadingPage: true,
            };
        case VALORES_CUOTA_ERROR:
            return {
                ...state,
                valoresCuota: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN USER */

        /* USER */
        case USER_EXITOSO:
            return {
                ...state,
                user: action.payload,
                loadingPage: false,
            }
        case USER_LOADING:
            return {
                ...state,
                user: null,
                loadingPage: true,
            };
        case USER_ERROR:
            return {
                ...state,
                user: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN USER */

        /* GRAFICOS VALORES DATA PIE CHART */
        case GRAFICO_DATA_PIECHART_EXITOSO:
            return {
                ...state,
                valoresPieChart: action.payload,
                loadingPage: false,
            }
        case GRAFICO_DATA_PIECHART_LOADING:
            return {
                ...state,
                valoresPieChart: null,
                loadingPage: true,
            };
        case GRAFICO_DATA_PIECHART_ERROR:
            return {
                ...state,
                valoresPieChart: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN GRAFICOS VALORES DATA PIE CHART */

        /* GRAFICOS VALORES DATA CARTERA */
        case GRAFICO_DATA_CARTERA_EXITOSO:
            return {
                ...state,
                valoresColumnCartera: action.payload,
                loadingPage: false,
            }
        case GRAFICO_DATA_CARTERA_LOADING:
            return {
                ...state,
                valoresColumnCartera: null,
                loadingPage: true,
            };
        case GRAFICO_DATA_CARTERA_ERROR:
            return {
                ...state,
                valoresColumnCartera: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN GRAFICOS VALORES DATA CARTERA */

        /* GRAFICOS VALORES CUOTAS POR RANGO FECHA */
        case GRAFICO_VALORES_CUOTA_RANGO_FECHAS_EXITOSO:
            return {
                ...state,
                valoresCuotaPorRangoFecha: action.payload,
                loadingPage: false,
            }
        case GRAFICO_VALORES_CUOTA_RANGO_FECHAS_LOADING:
            return {
                ...state,
                valoresCuotaPorRangoFecha: null,
                loadingPage: true,
            };
        case GRAFICO_VALORES_CUOTA_RANGO_FECHAS_ERROR:
            return {
                ...state,
                valoresCuotaPorRangoFecha: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN GRAFICOS VALORES CUOTAS POR RANGO FECHA */


        case PAGOS_DESEMBOLSOS_EXITOSO:
            return {
                ...state,
                dataEmpresas: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_LOADING:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_DESEMBOLSOS_ERROR:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_CLEANER:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO:
            return {
                ...state,
                dataDeudaPeriodos: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_LOADING:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_ERROR:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_CLEANER:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO:
            return {
                ...state,
                allDataExcel: action.payload,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING:
            return {
                ...state,
                loadingAllDataExcel: true,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: action.payload,
                loadingAllDataExcel: false,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        default:
            break;
    }
}