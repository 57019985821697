import {
  DETALLE_PRODUCTO_SPP_EXITOSO,
  DETALLE_PRODUCTO_SPP_ERROR,
  DETALLE_PRODUCTO_SPP_LOADIN,
  DETALLE_PRODUCTO_SPP_CLEANER,
  DETALLE_PRODUCTO_SPP_CLEANER_PARCIAL,
} from "../../types/detalleProductoSpp";

export default (state, action) => {
  switch (action.type) {
    case DETALLE_PRODUCTO_SPP_EXITOSO:
      return {
        ...state,
        datosDetalleProductoSpp: action.payload,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case DETALLE_PRODUCTO_SPP_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case DETALLE_PRODUCTO_SPP_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
        datosDetalleProductoSpp: null,
        loadingPage: false,
      };
    case DETALLE_PRODUCTO_SPP_CLEANER:
      return {
        ...state,
        datosDetalleProductoSpp: null,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case DETALLE_PRODUCTO_SPP_CLEANER_PARCIAL:
      return {
        ...state,
        datosDetalleProductoSpp: null,
        loadingPage: false,
      };
    default:
      break;
  }
};
