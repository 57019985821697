export default {
  h1: {
    fontWeight: "bold",
    fontSize: "56px",
    letterSpacing: "0px",
    lineHeight: "60px",
  },
  h2: {
    fontWeight: "500",
    fontSize: "48px",
    letterSpacing: "0px",
    lineHeight: "56px",
  },
  h3: {
    fontWeight: "700",
    fontSize: "32px",
    letterSpacing: "0px",
    lineHeight: "24px",
  },
  h4: {
    fontWeight: 600,
    fontSize: "24px",
    letterSpacing: "0px",
    lineHeight: "32px",
  },
  h5: {
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: "0px",
    lineHeight: "28px",
  },
  h6: {
    fontWeight: 600,
    fontSize: "18px",
    letterSpacing: "0px",
    lineHeight: "20px",
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.2px",
    lineHeight: "24px",
  },
  subtitleM: {
    fontWeight: "600 !important",
    fontSize: "16px",
    letterSpacing: "0.2px",
    lineHeight: "24px",
  },
  subtitleS: {
    fontWeight: "600 !important",
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "20px",
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "20px",
  },
  body1: {
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "0.2px",
    lineHeight: "24px",
  },
  body2: {
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "20px",
  },

  button: {
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "24px",
  },
  caption: {
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: "0.2px",
    lineHeight: "16px",
  },
  overline: {
    fontWeight: "bold",
    fontSize: "10px",
    letterSpacing: "1.5px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  body_link_large: {
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "0.2px",
    lineHeight: "24px",
  },
  body_link_medium: {
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "0.2px",
    lineHeight: "20px",
  },
  body_link_small: {
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.2px",
    lineHeight: "16px",
  },
  // type/size/weight
  bodyMediumRegular: {
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "0.2px",
  },
  bodyMediumMedium: {
    fontWeight: "500 !important",
    fontSize: "16px",
    letterSpacing: "0.2px",
  },
  bodyMediumBold: {
    fontWeight: "500 !important",
    fontSize: "16px",
    letterSpacing: "0.2px",
  },
  bodySmallMedium: {
    fontWeight: "500 !important",
    fontSize: "14px",
    letterSpacing: "0.2px",
  },
  bodySmallRegular: {
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "0.2px",
  },
  bodySmallLight: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "16.41px",
    letterSpacing: "0.2px",
  },
  headingSubtitleSemi: {
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.2px",
    lineHeight: "20px",
  },

  caption2: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  bodySmallMessage: {
    fontWeight: 600,
    fontSize: "13px",
    letterSpacing: "0.2px",
  },
};
