import React from 'react';
import close from "../../../../assets/icons/close.svg";
import { LoginForm } from "../../components";
import hocWidthWindow from "../../../../HOC/HocWidthWindow";
import "./index.css";

const Section2 = (props) => {
  const { onSubmit, loading, error } = props;


  if (!props.hideSection) return null;
  if (props.windowWidth > 960) return null;
  return (
    <section className="section-content section2">
      <div className="container-new-home">
        <img
          className="btn-close"
          src={close}
          alt="close"
          onClick={() => props.setHideSection(false)}
        />
        {/* <h2 className="h2-new-home mb-5 virtual-agent-title">
                    ¡Est&aacute;s en tu Agencia Digital!
                </h2> */}
        <LoginForm sendRequest={onSubmit} loading={loading} error={error} />
      </div>
    </section>
  );
};

export default hocWidthWindow(Section2);
