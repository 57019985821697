import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import moment from "moment";
import "./index.css";

function RetiroDU038Preview(props) {
  useEffect(() => {
    // obtenerprops.misDatosGet(r_datos
  }, []);

  // (mensajeAlertaDatosGet !== null) return <p></p>;
  // if (props.misDatosGet r_datos return <Loading />;
  return (
    <div className="container retirodu038">
      <h1 className="title-page">Vista previa del retiro</h1>
      <Row>
        <Col md="8" className="form-retirodu038 mb-3">
          <div>
            <Card>
              <CardHeader className="custom-header-cambio-fondo custom-header-li">
                RetiroDU038
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_fecha">Fecha de solicitud</Label>
                      <Input
                        // type="date"
                        name="r_fecha"
                        id="r_fecha"
                        placeholder="date placeholder"
                        disabled={true}
                        value={
                          props.misDatosGet === null
                            ? ""
                            : moment(
                                props.misDatosGet.r_datos.fec_solicitud
                              ).format("DD/MM/YYYY")
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_numero_solicitud">
                        Numero de solicitud
                      </Label>
                      <Input
                        type="number"
                        name="r_numero_solicitud"
                        id="r_numero_solicitud"
                        placeholder="N.°"
                        disabled={true}
                        value={
                          props.misDatosGet === null
                            ? ""
                            : props.misDatosGet.r_datos.id_solicitud
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_apellido_paterno">
                        Apellido paterno
                      </Label>
                      <Input
                        type="text"
                        name="r_apellido_paterno"
                        id="r_apellido_paterno"
                        placeholder=""
                        value={
                          props.misDatosGet === null
                            ? ""
                            : props.misDatosGet.r_datos.primer_apellido
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_apellido_materno">
                        Apellido materno
                      </Label>
                      <Input
                        type="text"
                        name="r_apellido_materno"
                        id="r_apellido_materno"
                        placeholder=""
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.segundo_apellido
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_nombres">Nombres</Label>
                      <Input
                        type="text"
                        name="r_nombres"
                        id="r_nombres"
                        placeholder=""
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.nombres
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_cuspp">CUSPP </Label>
                      <Input
                        type="text"
                        name="r_cuspp "
                        id="r_cuspp"
                        placeholder=""
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.cuspp
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_email">Email </Label>
                      <Input
                        type="email"
                        name="r_email "
                        id="r_email"
                        placeholder="email@email.com"
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.email
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_telefono">Celular</Label>
                      <Input
                        placeholder="Ingresar Celular"
                        name="r_telefono"
                        pattern="[0-9]*"
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.num_telefono
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_saldo">Saldo soles</Label>
                      <Input
                        placeholder="00.00"
                        name="r_saldo"
                        id="r_saldo"
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : Number(props.misDatosGet.r_datos.saldo).toFixed(2)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_monto">Monto solicitado </Label>
                      <Input
                        placeholder="00.00"
                        name="r_monto"
                        id="r_monto"
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.monto_solicitado
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_banco">Banco Escogido</Label>
                      <Input
                        placeholder=""
                        name="r_banco"
                        id="r_banco"
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.cod_banco === "10"
                            ? "BCP"
                            : props.misDatosGet.r_datos.cod_banco === "11"
                            ? "DE LA NACION"
                            : props.misDatosGet.r_datos.cod_banco === "13"
                            ? "PICHINCHA"
                            : props.misDatosGet.r_datos.cod_banco === "14"
                            ? "BANBIF"
                            : props.misDatosGet.r_datos.cod_banco === "85"
                            ? "FALABELLA"
                            : props.misDatosGet.r_datos.cod_banco === "81"
                            ? "GNB"
                            : props.misDatosGet.r_datos.cod_banco === "9"
                            ? "COMERCIO"
                            : props.misDatosGet.r_datos.cod_banco === "116"
                            ? "RIPLEY"
                            : props.misDatosGet.r_datos.cod_banco === "15"
                            ? "INTERBANK"
                            : props.misDatosGet.r_datos.cod_banco === "19"
                            ? "SCOTIABANK"
                            : props.misDatosGet.r_datos.cod_banco === "7"
                            ? "BBVA"
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_tipo_cuenta">Tipo de cuenta</Label>
                      <Input
                        placeholder=""
                        name="r_tipo_cuenta"
                        id="r_tipo_cuenta"
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.tip_cuenta === "AHO"
                            ? "AHORRO"
                            : props.misDatosGet.r_datos.tip_cuenta === "CHK"
                            ? "CUENTA CORRIENTE"
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_numero_cuenta">N.° de cuenta</Label>
                      <Input
                        placeholder=""
                        name="r_numero_cuenta"
                        id="r_numero_cuenta"
                        disabled={true}
                        value={
                          props.misDatosGet.r_datos === null
                            ? ""
                            : props.misDatosGet.r_datos.numero_cuenta
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default hocWidthWindow(RetiroDU038Preview);
