const elevations = {
  hover: "0 2px 8px rgba(27, 29, 39, 0.12)",
  above: "0 4px 13px rgba(27, 29, 39, 0.12)",
  aboveMd: "0px -6px 24px 0px rgba(27, 29, 39, 0.12)",
  aboveLg: "0px -12px 32px 0px rgba(27, 29, 39, 0.1)",
  below: "0 4px 13px rgba(27, 29, 39, 0.12)",
  belowMd: "0px 8px 24px 0px rgba(27, 29, 39, 0.12)",
  belowLg: "0px 16px 32px 0px rgba(27, 29, 39, 0.1)",
};

export default elevations;
