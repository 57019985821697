import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import ReactGa from "react-ga";
import { Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import imgJao from "../../assets/Jao-sin.svg";
import imgPmo from "../../assets/icons/flag_Red.svg";
// import imgBono from "../../assets/Bono_rec2.svg";
import imgReja from "../../assets/Reja.svg";
// import imgLegal from "../../assets/JLEGAL.svg";
import imgBono from "../../assets/icons/jub_bono_reco.svg";
import imgLegal from "../../assets/icons/jub_legal.svg";
import imgNuts from "../../assets/nuts.svg";
import CustomModal from "./Components/custromModal/CustomModal";
import ModalExitoso from "./ModalExitoso";
import ModalFilter from "./ModalFilter";
import ModalMessage from "./ModalMessage";
import ModalAlertaAgencia from "../../components/ModalAlertaAgencia";
import AuthContext from "../../context/autenticacion/authContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import ModalContext from "../../context/modal/modalContext";
import JubilacionContext from "../../context/jubilacion/jubilacionContext";
import Loading from "../../components/Loading/index.jsx";
import Texto from "./Components/Texto";
import { CardContainer, Page, NotFoundData, Button } from "../../components";
import { TYPE_OF_PROCEDURE } from "../../types/jubilacion/index";
import "./index.css";
import ModalDeErrorGeneral from "./Components/GeneralErrorModal";
const Title = styled.div`
  & h1 {
    ${(props) => props.theme.tipography.h4};
    text-align: center;
  }
  & p {
    margin-bottom: 0;
  }
`;

const ContentCheckValidacion = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${(props) => props.theme.spacing(2)};
`;
const Jubilacion = (props) => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misDatosContext = useContext(MisDatosContext);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    obtenerMisDatosGet,
    limpiarVariablesMisDatosParcial,
    isDataUpdated,
  } = misDatosContext;

  const {
    opcionesMenuPost,
    opcionesMenu,
    /*Legal */
    legalData,
    legalDataLoading,
    obtenerProcesoLegalData,
    limpiarObtenerProcesoLegalData,
    createLegalData,
    createLegalDataLoading,
    crearProcesoLegalData,
    limpiarCrearProcesoLegalData,
    /*Reja */
    rejaSgaValidarData,
    rejaSgaValidarLoading,
    enviarRejaSgaValidador,
    limpiarEnviarRejaSgaValidador,
    rejaData,
    rejaDataLoading,
    obtenerProcesoRejaData,
    limpiarObtenerProcesoRejaData,
    createRejaData,
    createRejaDataLoading,
    crearProcesoRejaData,
    limpiarCrearProcesoRejaData,

    jaoAccessData,
    jaoAccessLoading,
    obtenerAccesoJaoData,
    accedeSegundoRetiroDataLoading,
    accedeSegundoRetiroData,
    obtenerProcesoSegundoRetiroData,
    limpiarObtenerProcesoSegundoRetiroData,

    createJubilacionData,
    createJubilacionDataLoading,
    crearProcesoJubilacionData,
    limpiarCrearProcesoJubilacionData,

    mensajeAlerta,
  } = jubilacionContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [confirmacionInfo, setConfirmacionInfo] = useState(null);
  const [modalExitoso, setModalExitoso] = useState(null);
  const [modalActualizarDatos, setModalActualizarDatos] = useState(false);
  const [modalCelular, setModalCelular] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalAlerta, setModalAlerta] = useState(null);
  const [modalFilter, setModalFilter] = useState(null);
  const [modalShowError, setModalShowError] = useState(false);
  const [textError, setTextError] = useState([]);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);

  const [evitarAsesoria, setEvistarAsesoria] = useState(false);
  /*Legal */
  const [modalJubilacionLegal, setModalJubilacionLegal] = useState(false);
  const [validacionLegal, setValidacionLegal] = useState(null);
  const [modalValidacionLegal, setModalValidacionLegal] = useState(false);
  const [modalContinuarAsesoriaLegal, setModalContinuarAsesoriaLegal] =
    useState(false);
  const [modalFinalizarLegal, setModalFinalizarLegal] = useState(false);
  /*Reja */
  const [modalJubilacionReja, setModalJubilacionReja] = useState(false);
  const [validacionReja, setValidacionReja] = useState(null);
  const [modalValidacionReja, setModalValidacionReja] = useState(false);
  const [modalContinuarAsesoriaReja, setModalContinuarAsesoriaReja] =
    useState(false);
  const [modalFinalizarReja, setModalFinalizarReja] = useState(false);
  /*JAO */
  const [modalJAO, setModalJAO] = useState(false);
  const [modalIpcJAOError, setModalIpcJAOError] = useState(false);

  /* Segunda Decision */
  const [modalSegundaDecision, setModalSegundaDecision] = useState(false);
  const [modalContinuarSegundaDecision, setModalContinuarSegundaDecision] =
    useState(false);

  const [tempTemaDatos, setTempTemasDatos] = useState(null);
  const [sendRequest, setSendRequest] = useState(false);
  const [tramiteId, setTramiteId] = useState(0);

  const [generalErrorModalIsOpen, setGeneralErrorModalIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    opcionesMenuPost();
    return () => {
      console.log("EJECUTANDOSE");
      limpiarObtenerProcesoLegalData();
      limpiarCrearProcesoLegalData();
      // limpiarObtenerProcesoRejaData();
      limpiarCrearProcesoRejaData();
      //limpiarObtenerAccesoJaoData();
      limpiarObtenerProcesoSegundoRetiroData();
      limpiarCrearProcesoJubilacionData();
      document.body.removeAttribute("style");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlertaDatosGet) {
      mostrarMensaje(mensajeAlertaDatosGet);
    } else {
      ReactGa.pageview(window.location.href);
      obtenerMisDatosGet();
    }

    return () => {
      limpiarVariablesMisDatosParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlertaDatosGet]);

  useEffect(() => {
    if (rejaSgaValidarData && tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA) {
      if (rejaSgaValidarData.r_validacion)
        setModalFinalizarReja(!modalFinalizarReja);
    }
    if (rejaSgaValidarData && tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL) {
      setModalFinalizarLegal(!modalFinalizarLegal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejaSgaValidarData]);

  useEffect(() => {
    if (mensajeAlerta) mostrarMensaje(mensajeAlerta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  useEffect(() => {
    if (legalData && sendRequest) {
      if (legalData.success) {
        setTempTemasDatos(legalData.data);
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-reja/tramite"
        );
        // if (legalData.data.has_procedure) {
        //   setModalContinuarAsesoriaLegal(true);
        // } else {
        //   setModalJubilacionLegal(true);
        // }
      } else {
        setModalShowError(true);
        setTextError(legalData.errors);
      }
      setSendRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalData]);
  useEffect(() => {
    if (createLegalData) {
      if (createLegalData.r_validacion === 1)
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLegalData]);

  useEffect(() => {
    if (jaoAccessData && sendRequest) {
      if (jaoAccessData.success) setModalJAO(!modalJAO);
      else {
        if (jaoAccessData.type === "icon") {
          setModalIpcJAOError(!modalIpcJAOError);
          setTextError([jaoAccessData.errors]);
        } else {
          setModalShowError(!modalShowError);
          setTextError(jaoAccessData.errors);
        }
      }
      setSendRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jaoAccessData]);

  useEffect(() => {
    if (rejaData && sendRequest) {
      if (rejaData.r_validacion === 1) {
        console.log("TEMPDATOS", rejaData.data);
        setTempTemasDatos(rejaData.data);
        if (rejaData.data.has_procedure) {
          if (
            localStorage.getItem("isDataUpdated").toString() === "true" &&
            opcionesMenu?.r_jubilacion_anticipada === "1"
          ) {
            setModalContinuarAsesoriaReja(true);
          } else {
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-reja/actualizacion-datos"
            );
          }
        } else {
          if (
            localStorage.getItem("isDataUpdated").toString() === "true" &&
            opcionesMenu?.r_jubilacion_anticipada === "1"
          ) {
            setModalJubilacionReja(true);
          } else {
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-reja/actualizacion-datos"
            );
          }
        }
      } else if (rejaData.r_validacion === 2) {
        setModalShowError(true);
        setTextError([rejaData.message]);
      } else {
        setModalShowError(true);
        setTextError(rejaData.errors);
      }
      setSendRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejaData]);

  useEffect(() => {
    if (
      localStorage.getItem("firstUpdateData")?.toString() === "true" &&
      opcionesMenu?.r_jubilacion_anticipada === "1"
    ) {
      setTempTemasDatos(rejaData.data);
      if (rejaData.data.has_procedure) {
        console.log("Continuar Asesoria Reja");
        setModalContinuarAsesoriaReja(true);
      } else {
        console.log("Iniciar Reja");
        limpiarObtenerProcesoRejaData();
        setModalJubilacionReja(true);
      }
    }
  }, [localStorage.getItem("firstUpdateData")]);

  useEffect(() => {
    if (createRejaData) {
      if (createRejaData.r_validacion === 1)
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-reja/tramite"
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRejaData]);

  /*SEGUNDO RETIRO*/
  useEffect(() => {
    if (accedeSegundoRetiroData && sendRequest) {
      if (accedeSegundoRetiroData.success) {
        setTempTemasDatos(accedeSegundoRetiroData.data);
        if (accedeSegundoRetiroData.data.has_procedure) {
          setModalContinuarSegundaDecision(true);
        } else {
          setModalSegundaDecision(true);
        }
      } else {
        setModalShowError(true);
        setTextError(accedeSegundoRetiroData.errors);
      }
      setSendRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accedeSegundoRetiroData]);

  useEffect(() => {
    if (createJubilacionData) {
      if (
        createJubilacionData.r_validacion === 1 &&
        tramiteId === TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION
      )
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso"
        );
      else if (
        createJubilacionData.r_validacion === 1 &&
        tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL
      )
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
        );
      else if (
        createJubilacionData.r_validacion === 1 &&
        tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA
      )
        props.history.push(
          "/mi-habitat-digital/afiliado/jubilacion-reja/tramite"
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJubilacionData]);

  const mostrarMensaje = (mensaje) => {
    setMessage(mensaje);
  };

  if (mensajeAlertaDatosGet !== null || mensajeAlerta !== null) return <p></p>;
  if (misDatosGet === null || opcionesMenu === null) return <Loading />;

  const closeBtn = () => {
    setConfirmacionInfo(null);
  };

  const closeModalExitoso = () => () => {
    setModalExitoso(null);
  };

  const closeModalFilter = () => {
    setModalFilter(null);
    if (!misDatosGet.r_persona.r_datos.r_celular) {
      setModalCelular(true);
    }
    setConfirmacionInfo(opcionSeleccionada);
  };
  const onCloseBtnFilter = () => {
    setModalFilter(null);
  };

  const procesarJubilacionSinAlerta = (dato) => {
    setTramiteId(dato);
    setSendRequest(true);
    if (dato === TYPE_OF_PROCEDURE.TYPE_LEGAL)
      props.history.push(
        "/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
      );
    else if (dato === TYPE_OF_PROCEDURE.TYPE_REJA) obtenerProcesoRejaData();
    else if (dato === TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION)
      obtenerProcesoSegundoRetiroData();
    else if (dato === TYPE_OF_PROCEDURE.TYPE_PMO) obtenerProcesoLegalData();
  };

  const closeModalAlerta = () => {
    if (Number(opcionSeleccionada) === 6 || Number(opcionSeleccionada) === 5) {
      setModalFilter(true);
    } else {
      setModalAlerta(null);
      if (!misDatosGet.r_persona.r_datos.r_celular) {
        setModalCelular(true);
      }
      setConfirmacionInfo(opcionSeleccionada);
    }
  };
  const closeModalMessage = () => {
    setModalMessage(null);
  };
  const openModalMessage = () => {
    setModalMessage(true);
    setModalFilter(null);
  };
  const openModalAlertaX = () => {
    setModalFilter(null);
    setModalAlerta(true);
  };
  const closeModalAlertaX = () => () => {
    setModalAlerta(null);
  };

  const fnBtnPrimaryModalJubilacionLegal = () => {
    setModalJubilacionLegal(false);

    if (evitarAsesoria) setEvistarAsesoria(false);

    setModalValidacionLegal(true);
    if (!misDatosGet.r_persona.r_datos.r_celular) {
      setModalCelular(true);
    }
  };

  const fnCloseIconBtnModalJubilacionLegal = () => {
    if (evitarAsesoria) setEvistarAsesoria(false);
    setModalJubilacionLegal(false);
  };
  const fnBtnPrimaryModalValidacionLegal = () => {
    if (validacionLegal === null) return;
    setModalValidacionLegal(false);
    if (validacionLegal === "0") {
      misDatosGet.r_persona.r_datos.r_celular
        ? setModalActualizarDatos(true)
        : setModalCelular(true);
      limpiarObtenerProcesoLegalData();
    } else setModalFinalizarLegal(true);
  };

  const fnBtnPrimaryModalJubilacionReja = () => {
    localStorage.setItem("firstUpdateData", false);
    setModalJubilacionReja(false);

    if (evitarAsesoria) setEvistarAsesoria(false);

    setModalValidacionReja(true);
    if (!misDatosGet.r_persona.r_datos.r_celular) {
      setModalCelular(true);
    }
  };

  const fnCloseIconBtnModalJubilacionReja = () => {
    localStorage.setItem("firstUpdateData", false);
    if (evitarAsesoria) setEvistarAsesoria(false);
    setModalJubilacionReja(false);
  };

  const fnBtnPrimaryModalValidacionReja = () => {
    if (validacionReja === null) return;
    setModalValidacionReja(false);
    if (validacionReja === "0") {
      // misDatosGet.r_persona.r_datos.r_celular
      //   ? setModalActualizarDatos(true)
      //   : setModalCelular(true);
      // limpiarObtenerProcesoRejaData();
      const datos = {
        type_of_procedure_id: 1,
      };
      crearProcesoRejaData(datos);
    } else setModalFinalizarReja(true);
  };
  const fnBtnSecondaryModalValidacionReja = () => {
    setModalValidacionReja(false);
    setModalJubilacionReja(true);
  };

  const checkSomeOption = () => {
    return (
      opcionesMenu.r_jubilacion_legal !== "0" ||
      opcionesMenu.r_bono_reconocimiento !== "0" ||
      opcionesMenu.r_jubilacion_anticipada !== "0" ||
      opcionesMenu.r_pmo !== "0" ||
      opcionesMenu?.r_jao !== "0"
    );
  };

  return (
    <Page title="Beneficios y jubilación" showHeader={"mobile"}>
      {checkSomeOption() ? (
        <CardContainer>
          <Title>
            <p>
              En este espacio puedes realizar las siguientes solicitudes
              totalmente online.
            </p>
          </Title>
        </CardContainer>
      ) : (
        <CardContainer>
          <NotFoundData
            mensaje={
              <span>
                Por el momento no calificas a alguna de las opciones de
                jubilación.
                <br /> Te invitamos a seguir navegando dentro de Mi Habitat
                Digital.
              </span>
            }
            btnText="Más información"
            link={"https://www.afphabitat.com.pe/estoy-proximo-a-jubilarme/"}
          />
        </CardContainer>
      )}
      <div className="jubilacion-menu container-cards">
        <Grid container spacing={2} justifyContent="center">
          <ModalDeErrorGeneral
            isOpen={generalErrorModalIsOpen}
            setIsOpen={setGeneralErrorModalIsOpen}
          />
          {opcionesMenu?.r_pmo !== "0" &&
            opcionesMenu?.r_segunda_decision !== "1" && (
              <Grid item xs={12} sm={6} md={3}>
                <div className="card-box">
                  <div className="card-content-img">
                    <img src={imgPmo} alt="monedas-circulo*azul" />
                  </div>

                  <h3>Pensión Objetivo</h3>
                  <Button
                    onClick={() => {
                      props.history.push(
                        "/mi-habitat-digital/afiliado/jubilacion-pmo/tramite"
                      );
                    }}
                  >
                    Iniciar
                  </Button>
                </div>
              </Grid>
            )}
          {/* Ocultado hasta nuevo aviso */}
          {/* {opcionesMenu?.r_bono_reconocimiento !== "0" && (
            <Grid item xs={12} sm={6} md={3}>
              <div className="card-box">
                <div className="card-content-img">
                  <img
                    id="bono-icon"
                    src={imgBono}
                    alt="monedas-circulo*azul"
                  />
                </div>

                <h3>Bono de Reconocimiento</h3>
                <Button onClick={procesarJubilacion(5)}>Iniciar</Button>
              </div>
            </Grid>
          )} */}
          {/* <Col
                md="6"
                xs="12"
                className="text-left custom-mt-jubilacion custom-mt-jubilacion col-jub-resp"
              >
                <button
                  onClick={procesarJubilacion(6)}
                  className="btn-red btn-red-jubilacion"
                >
                  Pensión por invalidez
                </button>
              </Col> */}
          {opcionesMenu?.r_jubilacion_legal !== "0" &&
            opcionesMenu?.r_segunda_decision !== "1" && (
              <Grid item xs={12} sm={6} md={3}>
                <div className="card-box">
                  <div className="card-content-img">
                    <img src={imgLegal} alt="monedas-circulo*azul" />
                  </div>

                  <h3>Jubilación por Edad Legal</h3>
                  <Button
                    onClick={() => {
                      // setGeneralErrorModalIsOpen(true);
                      props.history.push(
                        "/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
                      );
                      // procesarJubilacionSinAlerta(TYPE_OF_PROCEDURE.TYPE_LEGAL);
                    }}
                    disabled={
                      legalDataLoading &&
                      tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL
                    }
                  >
                    Iniciar
                    {legalDataLoading &&
                      tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Grid>
            )}
          {opcionesMenu?.r_jubilacion_anticipada !== "0" &&
            opcionesMenu?.r_segunda_decision !== "1" && (
              <Grid item xs={12} sm={6} md={3}>
                <div className="card-box">
                  <div className="card-content-img">
                    <img src={imgReja} alt="monedas-circulo*azul" />
                  </div>

                  <h3>Jubilación Anticipada por Desempleo (REJA)</h3>
                  <Button
                    onClick={() => {
                      // setGeneralErrorModalIsOpen(true);
                      localStorage.setItem("firstUpdateData", false);
                      procesarJubilacionSinAlerta(TYPE_OF_PROCEDURE.TYPE_REJA);
                    }}
                    disabled={
                      rejaDataLoading &&
                      tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA
                    }
                  >
                    Iniciar
                    {rejaDataLoading &&
                      tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Grid>
            )}
          {opcionesMenu?.r_jao !== "0" &&
            opcionesMenu?.r_segunda_decision !== "1" && (
              <Grid item xs={12} sm={6} md={3}>
                <div className="card-box">
                  <div className="card-content-img">
                    <img src={imgJao} alt="monedas-circulo*azul" />
                  </div>

                  <h3>PRE-EVALUACIÓN DE JUBILACIÓN ANTICIPADA</h3>
                  <Button
                    onClick={() => {
                      obtenerAccesoJaoData();
                      setSendRequest(true);
                      // setGeneralErrorModalIsOpen(true);
                    }}
                    disabled={jaoAccessLoading}
                  >
                    Iniciar
                    {jaoAccessLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Grid>
            )}
          {opcionesMenu?.r_segunda_decision !== "0" && (
            <Grid item xs={12} sm={6} md={3}>
              <div className="card-box">
                <div className="card-content-img">
                  <img src={imgJao} alt="monedas-circulo*azul" />
                </div>
                <h3>Nuevo Retiro 95.5%</h3>
                <Button
                  onClick={() => {
                    //setGeneralErrorModalIsOpen(true);
                    procesarJubilacionSinAlerta(
                      TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION
                    );
                  }}
                  disabled={
                    accedeSegundoRetiroDataLoading &&
                    tramiteId === TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION
                  }
                >
                  Iniciar
                  {accedeSegundoRetiroDataLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {modalAlerta && (
        <ModalAlertaAgencia
          dispositivo="desktop"
          buttonCloseTop={true}
          titulo={"!Recuerda que!"}
          view={"Jubilación"}
          dato={opcionSeleccionada}
          closeModal={closeModalAlertaX}
          closeBtn={closeModalAlerta}
          componenteInfo={<Texto opcion={opcionSeleccionada} />}
        // componenteInfo={}
        />
      )}
      {modalFilter && (
        <ModalFilter
          dispositivo="desktop"
          buttonCloseTop={true}
          info={modalFilter}
          titulo={"Validación de jubilación"}
          dato={opcionSeleccionada}
          openModalAlertaX={openModalAlertaX}
          openModalMessage={openModalMessage}
          closeBtn={onCloseBtnFilter}
          closeModal={closeModalFilter}
        />
      )}

      {modalMessage && (
        <ModalMessage
          dispositivo="desktop"
          buttonCloseTop={true}
          titulo={"Solicitud Registrada"}
          closeModal={closeModalMessage}
        />
      )}
      {modalExitoso && (
        <ModalExitoso
          dispositivo="desktop"
          buttonCloseTop={true}
          info={modalExitoso}
          history={props}
          titulo={"Siguiente Paso"}
          closeBtn={closeBtn}
          closeModal={closeModalExitoso}
        />
      )}

      <CustomModal
        showModal={modalActualizarDatos}
        className="beauty-modal"
        toggle={() => {
          setModalActualizarDatos(!modalActualizarDatos);
        }}
        fnCloseIconBtn={() => {
          setModalActualizarDatos(!modalActualizarDatos);
        }}
        title="Confirma tus datos de contacto"
        description={
          <>
            <div className="user-data">
              <div className="field mb-2">
                <span className="label">Correo personal:</span>
                <span className="data">
                  {misDatosGet.r_persona.r_datos.r_correo
                    ? misDatosGet.r_persona.r_datos.r_correo
                    : "No registrado"}
                </span>
              </div>

              <div className="field">
                <span className="label">Celular:</span>
                <span className="data">
                  {misDatosGet.r_persona.r_datos.r_celular
                    ? misDatosGet.r_persona.r_datos.r_celular
                    : "No registrado"}
                </span>
              </div>
            </div>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          const datos = {
            type_of_procedure_id: tramiteId,
          };
          if (tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL)
            crearProcesoLegalData(datos);
          else if (tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA)
            crearProcesoRejaData(datos);
          else if (tramiteId === TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION)
            crearProcesoJubilacionData(datos);
        }}
        textBtnPrimary="Confirmar datos"
        btnPrimaryLoading={
          createRejaDataLoading ||
          createLegalDataLoading ||
          createJubilacionDataLoading
        }
        hasBtnSecondary={true}
        fnBtnSecondary={() =>
          props.history.push(
            "/mi-habitat-digital/afiliado/informacion/datos-personales"
          )
        }
        textBtnSecondary="Actualizar datos"
        disableBtnSecondary={
          createRejaDataLoading ||
          createLegalDataLoading ||
          createJubilacionDataLoading
        }
      />

      <CustomModal
        showModal={modalCelular}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalCelular(!modalCelular);
        }}
        title="Necesitas actualizar tus datos"
        description={
          <p>
            Por favor, actualiza tu número de celular para continuar con la
            solicitud y poder contactarte.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() =>
          props.history.push(
            "/mi-habitat-digital/afiliado/informacion/datos-personales"
          )
        }
        textBtnPrimary="Actualizar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => setModalCelular(!modalCelular)}
        textBtnSecondary="Cancelar"
      />

      <CustomModal
        showModal={modalJubilacionLegal}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={fnCloseIconBtnModalJubilacionLegal}
        title="Jubilación por Edad Legal"
        description={
          <p>
            Te presentamos esta alternativa de jubilación. Aquí podrás solicitar
            una pensión y/o retiro de hasta el 95.5% de tu fondo a partir de los
            65 años de edad.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnPrimaryModalJubilacionLegal}
        textBtnPrimary="Iniciar"
      />

      {/* Eliminar ----------------------------------------- */}

      <CustomModal
        showModal={modalValidacionLegal}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalValidacionLegal(!modalValidacionLegal);
        }}
        title="¿Actualmente percibes una pensión en otro régimen?"
        description={
          <>
            <p style={{ textAlign: "left", marginBottom: "8px" }}>
              Por ejemplo: Ley 20530, Ley 19990, Caja policial y Caja militar.
            </p>

            <ContentCheckValidacion>
              {" "}
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  className="radio-btn"
                  id="tfa_78"
                  name="tfa_77"
                  onChange={(e) => {
                    setValidacionLegal(e.target.value);
                  }}
                />
                <label className="postRadioField" id="tfa_78" for="tfa_78">
                  <span className="input-radio-faux"></span>Sí, percibo
                </label>
              </span>
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  className="radio-btn"
                  id="tfa_781"
                  name="tfa_77"
                  onChange={(e) => {
                    setValidacionLegal(e.target.value);
                  }}
                />
                <label className="postRadioField" id="tfa_781" for="tfa_781">
                  <span className="input-radio-faux"></span>No, no percibo
                </label>
              </span>
            </ContentCheckValidacion>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnPrimaryModalValidacionLegal}
        disableBtnPrimary={validacionLegal === null}
        textBtnPrimary="Continuar"
      />
      {/* Eliminar ----------------------------------------- */}

      <CustomModal
        showModal={modalFinalizarLegal}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalFinalizarLegal(!modalFinalizarLegal);
        }}
        title="Recuerda que ..."
        description={
          <p>
            Como actualmente percibes una pensión, puedes solicitar la
            devolución de tu fondo por jubilación en otro régimen (Circular
            040). Te enviamos un correo con los pasos a seguir.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          enviarRejaSgaValidador();
        }}
        btnPrimaryLoading={rejaSgaValidarLoading}
        disableBtnPrimary={rejaSgaValidarLoading}
        textBtnPrimary="Finalizar"
        onClosed={() => limpiarEnviarRejaSgaValidador()}
      />

      <CustomModal
        showModal={modalContinuarAsesoriaLegal}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalContinuarAsesoriaLegal(!modalContinuarAsesoriaLegal);
          setTempTemasDatos(null);
        }}
        title="Jubilación por Edad Legal"
        description={
          tempTemaDatos && tempTemaDatos.status === "finished" ? (
            <p>
              Actualmente usted ya ha culminado con el proceso de Jubilación
              Legal
              <br />
            </p>
          ) : (
            <p>
              Te encuentras en el{" "}
              {`${tempTemaDatos && tramiteId === TYPE_OF_PROCEDURE.TYPE_LEGAL
                ? tempTemaDatos.timeline[tempTemaDatos.step - 1].title
                : ""
                }`}
              .
              <br />
              <br />
              ¿Deseas continuar?{" "}
            </p>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          if (
            tempTemaDatos &&
            (tempTemaDatos.status === "pending" ||
              tempTemaDatos.status === "in-process")
          )
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
            );
          else setModalContinuarAsesoriaLegal(!modalContinuarAsesoriaLegal);
        }}
        textBtnPrimary={
          tempTemaDatos && tempTemaDatos.estado_id === 7 ? "Aceptar" : "Aceptar"
        }
        hasAdvice={false}
      />
      {/* Eliminar ----------------------------------------- */}
      <CustomModal
        showModal={modalJubilacionReja}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={fnCloseIconBtnModalJubilacionReja}
        title="Jubilación Anticipada por Desempleo (REJA)"
        description={
          <p>
            Si estás interesado en iniciar tu Jubilación Anticipada por
            Desempleo (REJA), te presentamos nuestro proceso virtual donde te
            explicaremos y brindaremos asesoría para que puedas elegir la opción
            que mejor se adapte a tus necesidades.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnPrimaryModalJubilacionReja}
        textBtnPrimary="INICIAR"
      />
      <CustomModal
        showModal={modalValidacionReja}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalValidacionReja(!modalValidacionReja);
        }}
        title="¿Actualmente percibes una pensión en otro régimen?"
        description={
          <>
            <p style={{ textAlign: "left", marginBottom: "8px" }}>
              Por ejemplo: Ley 20530, Ley 19990, Caja policial y Caja militar.
            </p>

            <ContentCheckValidacion>
              {" "}
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  className="radio-btn"
                  id="tfa_78"
                  name="tfa_77"
                  onChange={(e) => {
                    setValidacionReja(e.target.value);
                  }}
                />
                <label className="postRadioField" id="tfa_78" for="tfa_78">
                  <span className="input-radio-faux"></span>Sí, percibo
                </label>
              </span>
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  className="radio-btn"
                  id="tfa_781"
                  name="tfa_77"
                  onChange={(e) => {
                    setValidacionReja(e.target.value);
                  }}
                />
                <label className="postRadioField" id="tfa_781" for="tfa_781">
                  <span className="input-radio-faux"></span>No, no percibo
                </label>
              </span>
            </ContentCheckValidacion>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnPrimaryModalValidacionReja}
        textBtnPrimary="Continuar"
        hasBtnSecondary={true}
        fnBtnSecondary={fnBtnSecondaryModalValidacionReja}
        textBtnSecondary="Volver"
        hasAdvice={false}
      />
      <CustomModal
        showModal={modalFinalizarReja}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalFinalizarReja(!modalFinalizarReja);
        }}
        title="Recuerda que ..."
        description={
          <p>
            Como actualmente percibes una pensión, puedes solicitar la
            devolución de tu fondo por jubilación en otro régimen (Circular
            040). Te enviamos un correo con los pasos a seguir.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          enviarRejaSgaValidador();
        }}
        btnPrimaryLoading={rejaSgaValidarLoading}
        disableBtnPrimary={rejaSgaValidarLoading}
        textBtnPrimary="FINALIZAR"
        onClosed={() => limpiarEnviarRejaSgaValidador()}
      />
      <CustomModal
        showModal={modalContinuarAsesoriaReja}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalContinuarAsesoriaReja(!modalContinuarAsesoriaReja);
          setTempTemasDatos(null);
        }}
        title="Jubilacion Anticipada por desempleo (REJA)"
        description={
          tempTemaDatos && tempTemaDatos.status === "finished" ? (
            <p>
              Actualmente usted ya ha culminado con el proceso de Jubilacion
              Reja
              <br />
            </p>
          ) : (
            <p>
              Actualmente usted se encuentra en el{" "}
              {`${tempTemaDatos && tramiteId === TYPE_OF_PROCEDURE.TYPE_REJA
                ? tempTemaDatos.timeline[tempTemaDatos.step - 1].title
                : ""
                }`}{" "}
              del proceso de <strong>Jubilación Anticipada</strong>.<br />
              ¿Deseas continuar?{" "}
            </p>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          if (
            tempTemaDatos &&
            (tempTemaDatos.status === "pending" ||
              tempTemaDatos.status === "in-process")
          ) {
            console.log("PRIMERO");
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-reja/tramite"
            );
          } else {
            console.log("SEGUNDO");
            setModalContinuarAsesoriaReja(!modalContinuarAsesoriaReja);
          }
        }}
        textBtnPrimary={
          tempTemaDatos && tempTemaDatos.estado_id === 7 ? "Aceptar" : "Aceptar"
        }
        hasAdvice={false}
      />

      <CustomModal
        showModal={modalShowError}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalShowError(false);
          setTextError("");
        }}
        title="¡Advertencia!"
        description={
          <div className="content-message-validation">
            <p>Estimado afiliado,</p>
            {textError &&
              textError.length > 0 &&
              textError.map((item) => {
                if (
                  item === "No contamos con la información de 7 UIT de sunat"
                ) {
                  return (
                    <p className="item-message-validation">
                      Por el momento no se encuentra disponible el reporte
                      emitido por Sunat para la verificación de sus ingresos
                      bajo el régimen de 4ta. Categoría.
                      <br /> Le sugerimos esperar la actualización del reporte o
                      hacerle seguimiento en la página web de{" "}
                      <a href="https://ww3.sunat.gob.pe/sol.html">SUNAT</a>{" "}
                      ingresando con su clave SOL. Si la información no es
                      correcta, le solicitamos comunicarse con nuestro Call
                      Center al 01 230-2200 Lima o al 0801-14-224 provincias
                      para mayor detalle.
                    </p>
                  );
                } else if (
                  item === "No aplica SYSDE"
                ) {
                  return (
                    <p className="item-message-validation">
                      Lamentamos informarte que actualmente <b>no aplicas a este tipo de jubilación</b>,
                      inténtalo luego. Revisa todos los requisitos {" "}
                      <a href="https://www.afphabitat.com.pe/pensiones/#jubilacion-anticipada-por-desempleo" style={{ textDecoration: "underline", fontWeight: "bold" }}>aquí</a>.{" "}
                    </p>
                  );
                } else {
                  return <p className="item-message-validation">{item}</p>;
                }
              })}
            <p className="message-remenber-validation mt-2">
              Si la información no es correcta, le solicitamos comunicarse con
              nuestro Call Center al 01 230-2200 lima o al 0801-14-224
              provincias para mayor detalle.{" "}
            </p>
          </div>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setModalShowError(false);
          setTextError("");
        }}
        textBtnPrimary="Cerrar"
      />

      <CustomModal
        showModal={modalJAO}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalJAO(!modalJAO);
        }}
        title="Pre-evaluación de Jubilación Anticipada"
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, si estás
            interesado en saber si accedes a la Jubilación Anticipada Ordinaria,
            esta pre-avaluación te ayudará a saber si cumples con los
            principales requisitos.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          props.history.push(
            "/mi-habitat-digital/afiliado/jubilacion-jao/info"
          );
        }}
        textBtnPrimary="Continuar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          setModalJAO(!modalJAO);
        }}
        textBtnSecondary="Lo realizaré luego"
      />

      <Modal
        isOpen={modalIpcJAOError}
        className="beauty-modal"
        backdrop="static"
        centered
      >
        <div className="modal-close">
          <i
            className="fa fa-times-circle-o"
            onClick={() => {
              setModalIpcJAOError(!modalIpcJAOError);
            }}
          ></i>
        </div>
        <div id="modal-header">
          <img src={imgNuts} alt="nuts" />
          <h5>¡Ocurrió un problema!</h5>
        </div>
        <ModalBody>{<p>{textError ? textError[0] : ""}</p>}</ModalBody>
        <ModalFooter>
          <Row className="footer-row custom-total-buttons">
            <Col className="footer-col-btn">
              <button
                className="btn-modal-primary"
                onClick={() => {
                  setModalIpcJAOError(!modalIpcJAOError);
                }}
              >
                Volver
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>

      <CustomModal
        showModal={modalSegundaDecision}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalSegundaDecision(!modalSegundaDecision);
        }}
        title="Nuevo Retiro 95.5%"
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, si
            deseas iniciar el proceso de Nuevo Retiro 95.5%, te presentamos
            nuestro proceso virtual, donde te explicaremos y asesoraremos para
            que puedas elegir la opción que mejor se adapte a tus necesidades.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          misDatosGet.r_persona.r_datos.r_celular
            ? setModalActualizarDatos(true)
            : setModalCelular(true);
          setModalSegundaDecision(false);
          // props.history.push("/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso");
        }}
        textBtnPrimary="Iniciar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          setModalSegundaDecision(!modalSegundaDecision);
        }}
        textBtnSecondary="Lo realizaré luego"
      />

      <CustomModal
        showModal={modalContinuarSegundaDecision}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalContinuarSegundaDecision(!modalContinuarSegundaDecision);
          setTempTemasDatos(null);
        }}
        title="Nuevo Retiro 95.5%"
        description={
          tempTemaDatos && tempTemaDatos.status === "finished" ? (
            <p>
              Actualmente usted ya ha culminado con el proceso de nuevo retiro
              <br />
            </p>
          ) : (
            <p>
              Actualmente usted se encuentra en el{" "}
              {`${tempTemaDatos &&
                tramiteId === TYPE_OF_PROCEDURE.TYPE_SEGUNDA_DECISION
                ? tempTemaDatos.timeline[tempTemaDatos.step - 1].title
                : ""
                }`}{" "}
              del proceso de <strong>Nuevo Retiro 95.5%</strong>.<br />
              ¿Deseas continuar?{" "}
            </p>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          if (
            tempTemaDatos &&
            (tempTemaDatos.status === "pending" ||
              tempTemaDatos.status === "in-process")
          ) {
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso"
            );
          } else {
            setModalContinuarSegundaDecision(!modalContinuarSegundaDecision);
          }
        }}
        textBtnPrimary={
          tempTemaDatos && tempTemaDatos.estado_id === 7 ? "Aceptar" : "Aceptar"
        }
        hasAdvice={false}
      />
    </Page>
  );
};

export default Jubilacion;
