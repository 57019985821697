import React from "react";
import {
  Box,
  Grid,
  Typography,
  Alert,
  Button,
  useMediaQuery,
  TextField,
  FormControlLabel,
  FormControl,
  useTheme,
  Paper,
  Checkbox,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import ValidateToken from "../../../components/validateToken";
import CoinsIcon from "../../../assets/IconBucket/Icon/CoinsIcon";
import SavingIcon from "../../../assets/IconBucket/Icon/SavingIcon";
import CardFondoSelected from "../components/CardFondoSelected";
import ModalErrorToken from "../ModalErrorToken";
import "moment/min/locales";

/* iconos */
import { makeStyles, createStyles } from "@mui/styles";
import { NotFoundData } from "../../../components";

import CalendarIcon from "../../../assets/IconBucket/Icon/CalendarIcon";
import FileTextIcon from "../../../assets/IconBucket/Icon/FileTextIcon";
import VerticalLinesOneIcon from "../../../assets/IconBucket/Icon/VerticalLinesOneIcon";
import VerticalLinesTwoIcon from "../../../assets/IconBucket/Icon/VerticalLinesTwoIcon";
import CheckCircleSuccessIcon from "../../../assets/IconBucket/Icon/CheckCircleSuccessIcon";
import CloseIcon from "@mui/icons-material/Close";

/* material icon */
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContentSearchIcon from "../../../assets/IconBucket/Icon/ContentSearchIcon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/* componentes */
const screenHeight = window.innerHeight;
const height = screenHeight - 570;
const heightToken = screenHeight - 580;
const heightDialog = screenHeight - 260;
export const Styles = {
  cardStyles: {
    backgroundColor: "white",
    border: "1px solid #CDCED0",
    boxShadow: "0px 4px 13px rgba(27, 29, 39, 0.12)",
    borderRadius: "16px",
    p: 2,
    marginBottom: "16px",
    minHeight: "280px",
  },
  textFieldFilledStyles: {
    "& .MuiFilledInput-root": {
      borderRadius: "16px",
      mb: 2,
    },
    "& .MuiFilledInput.Mui-focused": {
      borderColor: " none !important",
    },
  },
};

const useStyles = makeStyles({
  // DialogStyle: {
  //   "& .MuiDialog-paper": {
  //     borderRadius: "16px",
  //     top: `${height}px !important`,
  //   },
  // },
  DialogStyle: {
    // "& .MuiDialog-paper": {
    //   borderRadius: screenHeight <= 667 ? "0px" : "16px 16px 0 0",
    //   top: `${height}px !important`,
    //   zIndex: "2000 ! important",
    // },
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  DialogStyleChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },
  DialogTokenStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      top: `${heightToken}px !important`,
    },
  },
  DialoMessageStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      top: `${heightDialog}px !important`,
    },
  },
});

const DetailProcess = (props) => {
  const {
    datos,
    etario,
    setFondoActual,
    activeButton,
    setActiveButton,
    mostrarModalToken,
    datosResultadoCambio,
    openModalToken,
    setopenModalToken,
    datosCambioFondo,
    setMostrarModalToken,
    messageErro,
    setMostrarMensajeInfo,
    anularFondos,
    valueResult,
    validacionEtario,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  // const matches = useMediaQuery("(min-width:1500px)");
  const mediaScreen = useMediaQuery("(max-width:1364px)");
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const smallScreenSm = useMediaQuery(theme.breakpoints.down("sm"));

  moment.locale("pe");

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [requestInfoDialog, setRequestInfoDialog] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setopenModalToken(false);
  };
  const aceptarProcesoModal = (token) => {
    // let arrayFiltrado = dataLocal.filter(
    //   (item) => item.r_fondo_destino != null
    // );
    // let sendData = {};
    // let arrayFinal = arrayFiltrado.map((item) => {
    //   return {
    //     tipo_aporte: item.r_tipo_aporte,
    //     fondo_origen: item.r_fondo_origen,
    //     fondo_destino: item.r_fondo_destino,
    //   };
    // });
    // sendData.product = arrayFinal;
    // sendData.v_token_digital = token;
    // procesarCambio(sendData);
  };

  const actualTipoDeFondo = datos.r_aporte;
  const indexTipoDeFondo = datosCambioFondo.findIndex(
    (e) => e.r_aporte === actualTipoDeFondo
  );

  moment.locale("pe");

  // Se hace esto porque la fuente de informacion donde se recibe
  // no es constante. La primera vez se recibe desde datosResultadoCambio
  // las siguientes veces desde datosCambioFondo
  const fechaMaximaDeCancelacion = datosResultadoCambio
    ? datosResultadoCambio[0].r_fecha_maxima_cancelacion
    : datosCambioFondo?.[indexTipoDeFondo].r_fecha_maxima_cancelacion;

  const limitDate = moment(fechaMaximaDeCancelacion, "DD/MM/YYYY");
  const limitDateNextDay = moment(fechaMaximaDeCancelacion, "DD/MM/YYYY").add(
    1,
    "day"
  );

  const isCancelable = moment().valueOf() < limitDateNextDay.valueOf();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ContentSearchIcon size={32} color="black" />
            &nbsp; &nbsp;
            {datos.r_estado !== "EN TRAMITE" ? null : valueResult ===
              2 ? null : etario ? (
              <Typography
                sx={{ color: "#363A3E", fontSize: "20px" }}
                fontWeight="bold"
              >
                Tu solicitud de cambio de fondo etario se registró con éxito
              </Typography>
            ) : (
              <Typography
                sx={{ color: "#363A3E", fontSize: "20px" }}
                fontWeight="bold"
              >
                Tu solicitud se encuentra en curso
              </Typography>
            )}
            {datos.r_estado !==
            "NO PERMITIDO POR PLAZO*" ? null : valueResult === 2 ? null : (
              <Typography
                sx={{ color: "#363A3E", fontSize: "20px" }}
                fontWeight="bold"
              >
                Tu cambio de tipo de fondo se aplicó exitosamente
              </Typography>
            )}
            {valueResult === 2 && (
              <Typography
                sx={{ color: "#363A3E", fontSize: "20px" }}
                fontWeight="bold"
              >
                Solicitud anulada
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {datos.r_estado !== "EN TRAMITE" ? null : valueResult ===
            2 ? null : etario ? null : (
            <Alert
              severity="info"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Puedes anular esta solicitud hasta el{" "}
              <b>{limitDate.format("L")}</b> en&nbsp;
              <b>
                Inicio {">"} Mis Fondos {">"} Cambio de tipo de fondo.
              </b>
            </Alert>
          )}
          {datos.r_estado === "NO PERMITIDO POR PLAZO*" && (
            <NotFoundData
              mensaje={
                "Por normativa, podrás realizar un nuevo cambio de tipo de fondo luego de dos meses de haber realizado el registro de tu solicitud."
              }
              btnText={"Volver al inicio"}
              url={"/mi-habitat-digital/afiliado/inicio"}
              icon={true}
            />
          )}
          {valueResult === 2 && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Tu{" "}
              <b>
                {datos.r_aporte === "APORTE OBLIGATORIO"
                  ? "Fondo de Jubilación"
                  : datos.r_aporte === "APORTE VOLUNTARIO SIN FIN"
                  ? "Fondo Libre Habitat"
                  : datos.r_aporte}
              </b>{" "}
              se mantendrá en el <b>Fondo {datos.r_fondo_origen}</b>.
            </Typography>
          )}
        </Grid>
        {datos.r_estado !== "NO PERMITIDO POR PLAZO*" && (
          <Grid
            item
            xs={matches ? 12 : valueResult !== 2 ? (etario ? 12 : 8) : 12}
          >
            <Box sx={Styles.cardStyles}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                      Datos de la solicitud
                    </Typography>
                  </Box>
                </Grid>
                {etario ? (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl fullWidth>
                      <TextField
                        id="standard-multiline-static"
                        label="Producto"
                        multiline
                        defaultValue={
                          datos.r_aporte === "APORTE OBLIGATORIO"
                            ? "Fondo de Jubilación"
                            : datos.r_aporte === "APORTE VOLUNTARIO SIN FIN"
                            ? "Fondo Libre Habitat"
                            : datos.r_aporte === "APORTE VOLUNTARIO CON FIN"
                            ? "Fondo con Fin Previsional"
                            : datos.r_aporte
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-read-only-input"
                        label="Producto"
                        defaultValue={
                          datos.r_aporte === "APORTE OBLIGATORIO"
                            ? "Fondo de Jubilación"
                            : datos.r_aporte === "APORTE VOLUNTARIO SIN FIN"
                            ? "Fondo Libre Habitat"
                            : datos.r_aporte === "APORTE VOLUNTARIO CON FIN"
                            ? "Fondo con Fin Previsional"
                            : datos.r_aporte
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                )}

                {!etario && (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl Label fullWidth>
                      <TextField
                        id="outlined-read-only-input"
                        label="Fondo origen"
                        defaultValue={
                          datos.r_fondo_origen === "1"
                            ? "Fondo 1 - Conservador"
                            : datos.r_fondo_origen === "2"
                            ? "Fondo 2 - Moderado"
                            : datos.r_fondo_origen === "3"
                            ? "Fondo 3 - Arriesgado"
                            : "Fondo 0 - Protegido"
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={matches ? 12 : 6}>
                  <FormControl fullWidth>
                    <TextField
                      sx={{
                        textDecoration:
                          valueResult !== 2 ? "none" : "line-through",
                        color: valueResult !== 2 ? "#000000" : "#ACAFB3",
                        "& .MuiInputBase-root .MuiInputBase-input": {
                          color: valueResult !== 2 ? "#000000" : "#ACAFB3",
                        },

                        "& .Mui-disabled": {
                          backgroundColor: "#F8F9FB",
                        },
                      }}
                      id="outlined-read-only-input"
                      label="Fondo destino"
                      defaultValue={
                        Number(datos.r_fondo_destino) === 1
                          ? "Fondo 1 - Conservador"
                          : Number(datos.r_fondo_destino) === 2
                          ? "Fondo 2 - Moderado"
                          : Number(datos.r_fondo_destino) === 3
                          ? "Fondo 3 - Arriesgado"
                          : "Fondo 0 - Protegido"
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
                {valueResult === 2 && (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-read-only-input"
                        label="Fecha de anulación"
                        defaultValue={moment().format("DD/MM/YYYY")}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                )}

                {!etario && (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-read-only-input"
                        label="N° de solicitud"
                        defaultValue={datos.r_num_formulario}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={matches ? 12 : 6}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-read-only-input"
                      label="Fecha de solicitud"
                      defaultValue={datos.r_fecha_solicitud}
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
                {valueResult !== 2 && !etario && (
                  <Grid item xs={matches ? 12 : 6}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-read-only-input"
                        label="Fecha de ejecución"
                        defaultValue={datos.r_fecha_estimada}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        )}
        {datos.r_estado !== "NO PERMITIDO POR PLAZO*" &&
          valueResult !== 2 &&
          !etario && (
            <Grid item xs={matches ? 12 : 4}>
              <Box sx={Styles.cardStyles}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                      Avance de solicitud
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "100%",
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#1B7935",
                      }}
                    >
                      <DoneOutlinedIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      sx={{
                        height: "32px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        pl: 2,
                      }}
                    >
                      <Typography>Solicitud ingresada</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32px",
                        height: "32px",
                      }}
                    >
                      <VerticalLinesOneIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      sx={{
                        height: "32px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        pl: 4,
                      }}
                    >
                      <Typography sx={{ color: "#6E7378" }}>
                        Ingresate tu solicitud el {datos.r_fecha_solicitud}.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "100%",
                        width: "32px",
                        height: "32px",
                        backgroundColor: "#CDCED0",
                      }}
                    >
                      <AccessTimeIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box
                      sx={{
                        height: "32px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        pl: 2,
                      }}
                    >
                      <Typography sx={{ color: "#ACAFB3" }}>
                        Solicitud aplicada
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32px",
                        height: "32px",
                      }}
                    ></Box>
                  </Grid>

                  <Grid item xs={11}>
                    <Box
                      sx={{
                        height: "32px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        pl: 4,
                      }}
                    >
                      <Typography sx={{ color: "#6E7378" }}>
                        Si todo se encuentra en orden, tu cambio será efectuado
                        el&nbsp;
                        {datos.r_fecha_estimada}.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        {datos.r_estado !== "NO PERMITIDO POR PLAZO*" && (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {datos.r_estado !== "NO PERMITIDO POR PLAZO*" &&
                isCancelable &&
                !etario &&
                valueResult !== 2 && (
                  <Box
                    sx={{
                      width: "49%",
                      pr: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: "16px",
                        width: "100%",
                        maxWidth: "240px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <Typography>Anular</Typography>
                    </Button>
                  </Box>
                )}

              <Box
                sx={{
                  width: "49%",
                  pl: matches ? 1 : "",
                }}
              >
                <Button
                  onClick={() => {
                    history.push("/mi-habitat-digital/afiliado/inicio");
                    validacionEtario();
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "16px",
                    width: "100%",
                    maxWidth: "240px",
                    display: "flex",
                    justifyContent: "center",
                    m:
                      valueResult === 2 ||
                      datos.r_estado === "NO PERMITIDO POR PLAZO*"
                        ? "auto"
                        : etario
                        ? "auto"
                        : "",
                  }}
                >
                  <Typography>Volver al inicio</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        )}

        <Box>
          <Dialog
            className={
              smallScreenSm ? classes.DialogStyleChages : classes.DialogStyle
            }
            fullScreen={smallScreenSm}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: " center" }}
            >
              <Box sx={{ maxWidth: "500px" }}>
                <DialogTitle>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      onClick={() => {
                        setOpen(false);
                        setActiveButton(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Condiciones de anulación
                  </Typography>
                </DialogTitle>

                <DialogContent>
                  <>
                    <Box
                      sx={{
                        backgroundColor: "#F8F9FB",
                        borderRadius: "16px",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Typography sx={{ textAlign: "justify", pb: 1 }}>
                        No realizaremos ningún cobro en tu fondo por esta
                        anulación.
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#F8F9FB",
                        borderRadius: "16px",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Typography sx={{ textAlign: "justify", pb: 1 }}>
                        Podrás volver a registrar un cambio de fondo para este
                        producto en cualquier momento después de esta anulación,
                        en Inicio {">"} Mis Fondos {">"} Cambio de Tipo de
                        Fondo.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#F8F9FB",
                        borderRadius: "16px",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Typography sx={{ textAlign: "justify" }}>
                        Los plazos de ejecución para nuevos cambios de fondos se
                        realizarán al 6to día útil del mes siguiente del
                        registro de la solicitud.
                      </Typography>
                    </Box>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeButton ? true : false}
                          onClick={() => setActiveButton(!activeButton)}
                        />
                      }
                      label="Acepto las condiciones"
                    />

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        pr: 2,
                        pl: 2,
                        pb: 2,
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          width: "50%",
                          "& button": { width: "100%" },
                        }}
                      >
                        <Button
                          disabled={!activeButton ? true : false}
                          onClick={() => {
                            setopenModalToken(true);
                            setOpen(false);
                            setActiveButton(false);
                            // guardarCambio();
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Aceptar
                        </Button>
                      </Box>
                    </Box>
                  </>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>

          {openModalToken && (
            <Dialog
              className={smallScreen && classes.DialogTokenStyle}
              fullScreen={smallScreen}
              open={openModalToken}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle sx={{ pb: 0 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton onClick={() => setopenModalToken(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <ValidateToken
                  setMostrarModalToken={setMostrarModalToken}
                  setMostrarMensajeInfo={setMostrarMensajeInfo}
                  children={
                    <Box
                      sx={{
                        border: "1px solid #EDEDED",
                        borderRadius: "16px",
                        p: 1,
                        display: "flex",
                        flexDirection: mediaScreen && "column",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        {datos.r_aporte === "APORTE VOLUNTARIO CON FIN" && (
                          <CoinsIcon size={22} color="black" />
                        )}
                        {datos.r_aporte === "APORTE OBLIGATORIO" && (
                          <SavingIcon size={22} color="black" />
                        )}
                        &nbsp;
                        <Typography
                          color="textColor.main"
                          sx={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          {datos.r_aporte === "APORTE OBLIGATORIO" &&
                            "Fondo de Jubilación"}
                          {datos.r_aporte === "APORTE VOLUNTARIO CON FIN" &&
                            "Fondo con Fin Previsional"}
                          {datos.r_aporte !== "APORTE VOLUNTARIO CON FIN" &&
                            datos.r_aporte !== "APORTE OBLIGATORIO" &&
                            "Fondo Libre Habitat"}
                        </Typography>
                      </Box>
                      <Box>
                        <CardFondoSelected
                          fondoActual={datos}
                          fondoDestino={datos.r_fondo_destino}
                        />
                      </Box>
                    </Box>
                  }
                  activeChildren={smallScreen ? true : false}
                  cerrarModal={() => {
                    setopenModalToken(false);
                  }}
                  guardarCode={(token) => {
                    let obj = {
                      r_num_formulario: datos.r_num_formulario,
                      v_token_digital: token,
                    };
                    anularFondos(obj);
                  }}
                  setRequestInfoDialog={setRequestInfoDialog}
                  handleClose={handleClose}
                />
              </DialogContent>
            </Dialog>
          )}

          <Dialog
            open={requestInfoDialog}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={smallScreen}
            className={smallScreen && classes.DialoMessageStyle}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        onClick={() => {
                          setRequestInfoDialog(false);
                          setopenModalToken(true);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      No puedo validar esta solicitud
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography>
                  Por tu seguridad, si ya no cuentas con tus métodos de
                  validacion registrados, comunicate con nosotros.
                </Typography>
                <Box sx={{ width: "100%", display: "flex", pt: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Llama al:&nbsp;
                  </Typography>

                  <Typography
                    sx={{ textDecoration: "underline", color: "#2F6D95" }}
                  >
                    01 2302200
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  pr: 2,
                  pl: 2,
                  pb: 2,
                  pt: 2,
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    width: "50%",
                    "& button": { width: "100%" },
                  }}
                >
                  <Button
                    onClick={() => {
                      setRequestInfoDialog(false);
                      setopenModalToken(true);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Aceptar
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {mostrarModalToken && (
            <ModalErrorToken
              Transition={Transition}
              smallScreen={smallScreen}
              mostrarModalToken={mostrarModalToken}
              mensaje={messageErro[0].message}
              classes={classes.DialoMessageStyle}
              cerrarModal={(e) => {
                setMostrarModalToken(false);
              }}
              guardarCode={() => {
                setMostrarModalToken(false);
                setopenModalToken(true);
              }}
            />
          )}
        </Box>
      </Grid>
    </>
  );
};

export default DetailProcess;
