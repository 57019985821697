import React from "react";
import { Row, Col, CardBody } from "reactstrap";
import { montoFormat } from "../../helper/amountFormat";
import Collapse from "@mui/material/Collapse";

const ConSaldo = ({ datos, deuda }) => {
  const [checked, setChecked] = React.useState(false);
  const client = JSON.parse(localStorage.getItem("client"));
  const handleClick = (e) => {
    e.preventDefault();
  };
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const capitalizarPrimeraLetra = (palabra) => {
    return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
  };

  return (
    <CardBody className="custom-card-body card-comision">
      <Row>
        <Col>
          <p className="text-justify">
            Hola {capitalizarPrimeraLetra(client.r_primer_nombre)},
          </p>
          <p className="text-justify">
            En AFP Habitat queremos asegurarnos de que estés al tanto de lo que
            sucede con tu fondo de jubilación. Por eso, queremos informarte que
            no se han registrado nuevos aportes obligatorios en tu Cuenta
            Personal de AFP durante el 2023.
          </p>
          <p className="text-justify">
            Al respecto, tu saldo al 31/03/2024 es el siguiente:
          </p>
          <section className="table-responsive">
            <div className="show-mobile liena-head body-table-item mb-2">
              <div className="body-t custom-cf-mobile">
                <span className="only-mobile">Tipo de Aporte:</span>
                <span className="text-right ml-2">Saldo CIC</span>
              </div>
              <div className="body-t custom-cf-mobile">
                <span className="only-mobile">Aportes Obligatorios:</span>
                <span className="text-right ml-2">
                  S/ {montoFormat(datos.r_obl)}
                </span>
              </div>
              <div className="body-t custom-cf-mobile">
                <span className="only-mobile">
                  Aportes Voluntarios con Fin Previsional:
                </span>
                <span className="text-right ml-2">
                  S/ {montoFormat(datos.r_vcf)}
                </span>
              </div>
              <div className="body-t custom-cf-mobile">
                <span className="only-mobile">
                  Aportes Voluntarios sin Fin Previsional:
                </span>
                <span className="text-right ml-2">
                  S/ {montoFormat(datos.r_vsf)}
                </span>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table show-desktop">
                <thead>
                  <tr>
                    <th className="custom-header-table-noa">Tipo de Aporte</th>
                    <th className="custom-header-table-noa">
                      Aportes Obligatorios
                    </th>
                    <th className="custom-header-table-noa">
                      Aportes Voluntarios con Fin Previsional
                    </th>
                    <th className="custom-header-table-noa">
                      Aportes Voluntarios sin Fin Previsional
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>Saldo CIC</td>
                    <td>S/ {montoFormat(datos.r_obl)}</td>
                    <td>S/ {montoFormat(datos.r_vcf)}</td>
                    <td>S/ {montoFormat(datos.r_vsf)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <p className="text-justify">
            Es importante que tengas en cuenta que para mantener la cobertura
            del Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio activo
            debes haber realizado al menos cuatro aportes mensuales en los ocho
            meses anteriores al mes en que ocurra un siniestro. Este seguro te
            protege a ti y a tu familia en caso de una situación que te impida
            trabajar, otorgando una pensión de invalidez, pensión de
            sobrevivencia en caso de fallecimiento, y cubriendo los gastos de
            sepelio.
          </p>
          {!checked && (
            <a className="link-more" onClick={handleChange}>
              Ver más
            </a>
          )}
          <Collapse in={checked}>
            <p className="text-justify">
              Si eres un trabajador(a) dependiente y validas que en tus boletas
              de pago del año 2023 se ha realizado un descuento por concepto de
              aporte obligatorio a AFP Habitat, por favor envíanos una copia de
              la(s) boleta(s) al correo electrónico{" "}
              <a
                onClick={() => handleClick}
                href="mailto:miagenciavirtual@afphabitat.com.pe"
                rel="noopener noreferrer"
              >
                miagenciavirtual@afphabitat.com.pe
              </a>
              {". "}Es importante que las boletas cuenten con la firma y sello
              del representante legal de la empresa para que podamos iniciar la
              gestión de cobranza a tu empleador de manera adecuada.{" "}
            </p>
            <p className="text-justify">
              En caso de que tengas alguna duda o consulta, no dudes en
              contactarnos escribiendo a través del WhatsApp al 981-211-004 o
              llamando a nuestro Contact Center al 01-230-2200 desde Lima o al
              0-801-14-224 desde provincias. Nuestro horario de atención es de
              lunes a viernes de 9 a. m. a 6 p. m. y los sábados de 9 a. m. a 1
              p. m.{" "}
            </p>
          </Collapse>
          {datos.r_secuencial > 0 && (
            <div>
              <p className="text-justify">
                Finalmente, te informamos que, a la fecha tus aportes en proceso
                de cobranza son:
              </p>
              <section className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="custom-header-table-noa">
                        Periodo (año/mes)
                      </th>
                      <th className="custom-header-table-noa">
                        Estado de la deuda
                      </th>
                      <th className="custom-header-table-noa">
                        RUC del Empleador
                      </th>
                      <th className="custom-header-table-noa">
                        Razón Social del Empleador
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deuda.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.r_periodo}</td>
                        <td>{item.r_estado_deuda}</td>
                        <td>{item.r_ruc_empleador}</td>
                        <td>{item.r_razon_empleador}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </div>
          )}
          <p className="text-left">Atentamente,</p>
          <p className="text-left">
            <strong>AFP HABITAT</strong>
          </p>
        </Col>
      </Row>
    </CardBody>
  );
};

export default ConSaldo;
