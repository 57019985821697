import React from "react";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const CardContainer = styled(Card)`
  display: none;
  @media (min-width: 990px) {
    display: block;
    /* margin-bottom: ${(props) => props.theme.spacing(3)}; */
    box-shadow: unset;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
    width: 100%;
    position: relative;
  }
`;
const Header = styled(Box)`
  /* background: ${(props) => props.theme.palette.primary.main}; */
  padding: ${(props) => props.theme.spacing(3)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleCard = styled.h3`
  ${(props) => props.theme.tipography.title};
  /* color: ${(props) => props.theme.palette.white}; */
  margin-bottom: 0;
`;
const card = (props) => {
  const { title, seeMore, product, display, showHeader, color, children } =
    props;
  const style = {
    background: color,
    display,
  };
  const styleTitle = {
    color: product ? "#ffffff" : "#000000",
  };
  return (
    <CardContainer>
      {showHeader && (
        <Header style={style}>
          <TitleCard style={styleTitle}>{title}</TitleCard>
          {seeMore && (
            <a className="primary-link" href="#">
              Ver más
            </a>
          )}
        </Header>
      )}

      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};

export default card;
