import clienteAxios from "../../../config/axios";
import * as actions from "./actions";

const handleErrors = ({ dispatch, error, action }) => {
  const status = error?.response?.status;
  const message = error?.response?.data?.message;

  if (status >= 500) {
    openGeneralErrorModal(dispatch)();
    return actions.GENERAL_ERROR_MODAL;
  }

  dispatch({ type: action, payload: message });
  return action;
};

export const getMonthlyDebitDetails = (dispatch) => async () => {
  dispatch({ type: actions.GET_MONTHLY_DEBIT_DETAILS });

  try {
    const { data, ...rest } = await clienteAxios.get(
      "/fondo-libre/aporte-mensual"
    );

    dispatch({
      type: actions.MONTHLY_DEBIT_DETAILS_SUCCESS,
      payload: data.detalle_aporte,
    });

    return actions.MONTHLY_DEBIT_DETAILS_SUCCESS;
  } catch (error) {
    return handleErrors({
      dispatch,
      error,
      action: actions.MONTHLY_DEBIT_DETAILS_FAIL,
    });
  }
};

export const deactivateMonthlyDebit = (dispatch) => async (v_token_digital) => {
  dispatch({ type: actions.DEACTIVATE_MONTHLY_DEBIT });

  try {
    const { data } = await clienteAxios.post(
      "/fondo-libre/aporte-mensual-cancelar",
      { v_token_digital }
    );

    dispatch({ type: actions.DEACTIVATE_MONTHLY_DEBIT_SUCCESS, payload: data });

    return actions.DEACTIVATE_MONTHLY_DEBIT_SUCCESS;
  } catch (error) {
    return handleErrors({
      dispatch,
      error,
      action: actions.DEACTIVATE_MONTHLY_DEBIT_FAIL,
    });
  }
};

export const closeConfirmationModal = (dispatch) => () => {
  dispatch({ type: actions.CONFIRMATION_MODAL, payload: false });
};

export const openConfirmationModal = (dispatch) => () => {
  dispatch({ type: actions.CONFIRMATION_MODAL, payload: true });
};

export const closeTokenModal = (dispatch) => () => {
  dispatch({ type: actions.TOKEN_MODAL, payload: false });
};

export const openTokenModal = (dispatch) => () => {
  dispatch({ type: actions.TOKEN_MODAL, payload: true });
};

export const closeGeneralErrorModal = (dispatch) => () => {
  dispatch({ type: actions.GENERAL_ERROR_MODAL, payload: false });
};

export const openGeneralErrorModal = (dispatch) => () => {
  dispatch({ type: actions.GENERAL_ERROR_MODAL, payload: true });
};

export const closeImpossibleDeactivationModal = (dispatch) => () => {
  dispatch({ type: actions.IMPOSSIBLE_DEACTIVATION_MODAL, payload: false });
};

export const openImpossibleDeactivationModal = (dispatch) => () => {
  dispatch({ type: actions.IMPOSSIBLE_DEACTIVATION_MODAL, payload: true });
};

export const closeDeactivationErrorModal = (dispatch) => () => {
  dispatch({ type: actions.IMPOSSIBLE_DEACTIVATION_MODAL, payload: false });
};

export const openDeactivationErrorModal = (dispatch) => () => {
  dispatch({ type: actions.IMPOSSIBLE_DEACTIVATION_MODAL, payload: true });
};

export const changeCurrentSection = (dispatch) => (targetSection) => {
  dispatch({ type: actions.CURRENT_SECTION, payload: targetSection });
};
