import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import styled from "styled-components";
import { ModalGeneral } from "../../../../components";
import ICON_HEADPHONE from "../../../../assets/IconBucket/ICON_HEADPHONE.jsx";
const Title = styled.h1`
  ${(props) => props.theme.tipography.h1};
  font-weight: 400;
  margin-bottom: 32px;
`;
const ContentText = styled(Box)`
  text-align: center;
  margin: auto;
  color: #363a3e;
`;
const TextPrimary = styled.p`
  ${(props) => props.theme.tipography.h5};
  color: ${(props) => (props.color ? props.color : "#363A3E")};
  font-weight: 600;
  margin-bottom: 0;
`;

const Index = (props) => {
  const { open, setOpen, connectEtario, validateEtareo } = props;
  const history = useHistory();
  return (
    <ModalGeneral
      open={open}
      textPrimary="Escuchar ahora"
      textTertiary="Escuchar luego"
      textRecomendacion="Te recomendamos usar audífonos"
      hasPrimary={true}
      hasTertiary={true}
      closeModal={() => setOpen(!open)}
      onClickPrimary={() => {
        connectEtario(true);
        setOpen(!open);
      }}
      onClickTertiary={() => setOpen(!open)}
      iconPrimary={<ICON_HEADPHONE />}
    >
      <ContentText>
        <Title>¡Hola!</Title>
        {validateEtareo.tipo_etario === 60 ? (
          <TextPrimary color="#CE1336">
            Estás a solo 5 años de jubilarte
          </TextPrimary>
        ) : (
          <TextPrimary color="#CE1336">
            Llegaste a la edad de jubilación
          </TextPrimary>
        )}

        <TextPrimary>y tenemos información importante para ti</TextPrimary>
      </ContentText>
    </ModalGeneral>
  );
};

export default Index;
