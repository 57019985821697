import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContex from '../../context/autenticacion/authContext';

const RouteLogin = ({ component: Component, ...props }) => {
    const authContext = useContext(AuthContex);
    const { autenticado, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado('');
        // eslint-disable-next-line
    }, []);
    return (
        <Route
            {...props} render={props => !autenticado && cargando ? (<Redirect to="/mi-habitat-digital/empezar" />) :
                (
                    <Component {...props} />
                )}
        />
    );
};

export default RouteLogin;