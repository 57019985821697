import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import valorescuotaContext from "../../context/valoresCuota/valoresCuotaContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ReactGa from "react-ga";
import Loading from "../../components/Loading/index.jsx";
import { Page, CardContainer } from "../../components";
import { Select } from "../../components/Input";
import { FormProvider, useForm } from "react-hook-form";
import FeeValue from "./FeeValue";
import GraficoDetalle from "./GraficoDetalle";
import TabValue from "./TabValue";
import GraficoDoceMeses from "./GraficoDoceMeses";
import {
  ButtonComponent,
  ButtonSecondaryComponent,
  CardContainerComponent,
  PageComponent,
  BoxTwelve,
  BoxNotFound,
} from "./styles";

const items = [
  { name: "Listado", id: 0 },
  { name: "Gráfico", id: 1 },
];
const ValoresCuota = () => {
  const methods = useForm({ mode: "all", criteriaMode: "all" });
  const [showOption, setShowOption] = useState(-1);
  const { getValues, setValue } = methods;

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [showListadoGrafico, setShowListadoGrafico] = useState(0);
  const ValoresCuotaContext = useContext(valorescuotaContext);
  const {
    loading,
    datos,
    mensajeAlerta,
    obtenerDatosValoresCuota,
    obtenerDatosYears,
    obtenerDatosValoresCuotaVal12,
    datos_12,
    utils,
  } = ValoresCuotaContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);

      obtenerDatosValoresCuotaVal12();
      obtenerDatosYears();
    }

    return () => {
      actualizarTimer();
    };
    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };
  const changeListadoGrafico = (option) => {
    setShowListadoGrafico(option);
  };
  const handleConsult = (data) => {
    setShowOption(1);
    setMonth(data.mes);
    setYear(data.año);
    obtenerDatosValoresCuota(data);
  };

  const handleGet12 = () => {
    setShowOption(2);

    obtenerDatosValoresCuotaVal12();
  };
  if (mensajeAlerta !== null) return <p></p>;
  if (loading) return <Loading />;
  return (
    <PageComponent title="Valores Cuota" showHeader={"mobile"}>
      <CardContainerComponent>
        <div className={`text-center`}>
          <h2>Valor Cuota</h2>
          <p className="hbi-text">
            Para determinar el valor de tu fondo en soles debes tener en cuenta
            la cantidad de cuotas que posees y su valor cuota al día de la
            consulta, puedes revisar los valores aquí:
          </p>
        </div>
        {utils.length > 0 && (
          <Grid container>
            <FormProvider {...methods}>
              <Grid xs={6} md={4} lg={2} px={1} mb={1}>
                <Select
                  classNamePrefix="select"
                  name="año"
                  placeholder={"Seleccione el Año"}
                  options={utils[0].years}
                  defaultValue={utils[0].years[0].value}
                  isSearchable={false}
                />
              </Grid>
              <Grid xs={6} md={4} lg={2} px={1} mb={1}>
                <Select
                  classNamePrefix="select"
                  name="mes"
                  placeholder={"Seleccione el Mes"}
                  options={utils[0].months}
                  // defaultValue={utils[0].months[0]}
                  defaultValue={{ label: "Select Dept", value: 0 }}
                  isSearchable={false}
                />
              </Grid>
              <Grid xs={12} md={6} lg={2} px={1} mb={1}>
                <ButtonComponent
                  disabled={!(getValues("año") && getValues("mes"))}
                  onClick={() => methods.handleSubmit(handleConsult)()}
                >
                  Consultar
                </ButtonComponent>
              </Grid>
              <Grid xs={12} md={6} lg={2} px={1} mb={1}>
                <ButtonSecondaryComponent onClick={() => handleGet12()}>
                  Últimos 12 meses
                </ButtonSecondaryComponent>
              </Grid>
            </FormProvider>
          </Grid>
        )}
        <Grid>
          {showOption === 1 && Object.keys(datos).length > 0 && (
            <Box>
              {datos.rows.length > 0 && (
                <Box>
                  <TabValue
                    changeListadoGrafico={changeListadoGrafico}
                    items={items}
                  />
                  {showListadoGrafico === 0 && (
                    <FeeValue
                      content={datos}
                      value={showListadoGrafico}
                      month={month}
                      year={year}
                    />
                  )}
                  {showListadoGrafico === 1 && (
                    <GraficoDetalle
                      month={month}
                      year={year}
                      feeValue={datos}
                    />
                  )}
                </Box>
              )}

              {datos.rows.length === 0 && (
                <BoxNotFound className="container-alert">
                  <p className="text-alert">
                    Lo sentimos aún no tenemos información para el mes y año
                    seleccionado
                  </p>
                </BoxNotFound>
              )}
            </Box>
          )}

          {showOption === 2 && (
            <Box>
              {Object.keys(datos_12).length > 0 && (
                <BoxTwelve>
                  <h2>
                    Valores Cuota de los <strong>últimos 12 meses</strong>
                  </h2>
                  <GraficoDoceMeses twelveMonth={datos_12.rows} />
                </BoxTwelve>
              )}
            </Box>
          )}
        </Grid>{" "}
      </CardContainerComponent>
    </PageComponent>
  );
};

export default ValoresCuota;
