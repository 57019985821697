import React, { createContext, useEffect } from "react";
import SolicitudCancelada from "./SolicitudCandelada";
import DatosDeLaSolicitud from "./DatosDeLaSolicitud/DatosDeLaSolicitud";
import { CancelarAporteVoluntarioSinFinPage } from "./styles";
import {
  MonthlyDebitDetailsProvider,
  useMonthlyDebitDetailsContext,
} from "./context/index";
import * as actions from "./context/actions";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import GeneralErrorModal from "./GeneralErrorModal";

export const CancelarAporteVoluntarioSinFinContext = createContext();

const MonthlyDebitDetails = () => {
  // if (aporteMensual === null) {
  //   return <div></div>;
  // }

  // if (aporteMensual?.vigente === false) {
  //   return <Redirect to={"/mi-habitat-digital/afiliado/inicio"} />;
  // }

  return (
    <MonthlyDebitDetailsProvider>
      <CancelarAporteVoluntarioSinFinPage>
        <Body />
        <GeneralErrorModal />
      </CancelarAporteVoluntarioSinFinPage>
    </MonthlyDebitDetailsProvider>
  );
};

export default MonthlyDebitDetails;

export const Body = () => {
  const {
    state: { currentSection, monthlyDebitDetails },
    ...api
  } = useMonthlyDebitDetailsContext();

  useEffect(() => {
    const request = async () => {
      const response = await api.getMonthlyDebitDetails();

      if (response === actions.MONTHLY_DEBIT_DETAILS_SUCCESS) {
        api.changeCurrentSection("details");
      }
    };

    request();
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (monthlyDebitDetails.state === "fail") {
      setTimeout(() => {
        console.log("pushed");
        history.push("/mi-habitat-digital/afiliado/inicio");
      }, 3000);
    }
  }, [monthlyDebitDetails.state]);

  if (monthlyDebitDetails.state === "fail") {
    return (
      <div>
        No tiene un debito débito mensual activo. Será redirigido a home de Mi
        habitat digital en 3 segundos.
      </div>
    );
  }
  if (currentSection === "loading") {
    return <div>Cargando . . .</div>;
  }

  if (currentSection === "details") {
    return <DatosDeLaSolicitud />;
  }

  if (currentSection === "deactivated") {
    return <SolicitudCancelada />;
  }

  return <></>;
};
