import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../../../../utils/device";

export const ContainerView = styled(Box)`
  ${css`
    &&& {
      @media screen and ${device.tablet} {
        padding: 24px;

        border-radius: 16px;
        & .HBT_JUBLEGAL_Divider {
          border-right: 2px solid #ededed;
        }
      }
    }
  `}
`;

export const ContentTitle = styled(Box)`
  ${(p) => p.theme.tipography.h4}
  padding:24px 16px;
  background-color: ${(p) => p.theme.palette.lead[100]};
  & h4 {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
  }

  ${css`
    &&& {
      @media screen and ${device.tablet} {
        margin-bottom: 32px;
      }
    }
  `}
`;

export const Contents = styled(Box)`
  ${(p) => p.theme.tipography.bodySmallRegular}
  padding:24px 16px;

  & h4 {
    ${(p) => p.theme.tipography.subtitle1}
    margin-bottom: 8px;
  }
`;

export const BoxCard = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  z-index: 2;
`;
export const BoxNumber = styled(Box)`
  min-width: 32px;

  flex-shrink: 0;
`;

export const BoxNumCircle = styled(Box)`
  ${(p) => p.theme.tipography.h5}
  width:32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.secondary.lightest};
  color: ${(p) => p.theme.palette.secondary.main};
  margin-right: 8px;
`;

export const BoxText = styled(Box)`
  flex: 1 1 auto;
  min-width: 0;
  ${(p) => p.theme.tipography.body2}
  color: ${(p) => p.theme.palette.textColor.main};
`;
export const BoxTextFunds = styled(Box)`
  position: relative;
  margin-top: 24px;
  padding: 0 9px;
`;

export const BoxFund = styled(Box)`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  & h4 {
    background-color: ${(p) => p.theme.tipography.subtitle1};
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-left: 8px;
  }
`;
export const BoxLine = styled.div`
  width: 91px;
  position: absolute;
  z-index: 1;
  left: -20px;
  top: -20px;
  background-image: repeating-linear-gradient(
      -2deg,
      #2f6d95,
      #2f6d95 12px,
      transparent 12px,
      transparent 19px,
      #2f6d95 19px
    ),
    repeating-linear-gradient(
      88deg,
      #2f6d95,
      #2f6d95 12px,
      transparent 12px,
      transparent 19px,
      #2f6d95 19px
    ),
    repeating-linear-gradient(
      178deg,
      #2f6d95,
      #2f6d95 12px,
      transparent 12px,
      transparent 19px,
      #2f6d95 19px
    ),
    repeating-linear-gradient(
      268deg,
      #2f6d95,
      #2f6d95 12px,
      transparent 12px,
      transparent 19px,
      #2f6d95 19px
    );
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  height: 2px;

  transform: rotate(90deg);
`;

export const ContentsLabel = styled(Box)`
  ${(p) => p.theme.tipography.bodySmallRegular}
  padding:0px 16px 24px 16px;

  & h4 {
    ${(p) => p.theme.tipography.subtitle1}
    margin-bottom: 8px;
  }
`;
