import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ChipTarjeta from "./ChipTarjeta";
const Cards = ({ cuentas, setCuenta, setMensaje }) => {
  const [cuentaDestino, setCuentsDestino] = useState(-1);
  const selectCard = (option) => (e) => {
    e.preventDefault();
    setCuenta(option);
    setMensaje(null);
    setCuentsDestino(option);
  };

  return (
    <Row>
      {cuentas.map((item, index) => (
        <Col md={4} key={index} className="mb-2">
          <section style={{ position: "relative" }} onClick={selectCard(item)}>
            <div
              className={
                cuentaDestino === item
                  ? `creditCard ${item.r_nombre_banco} creditCard-active`
                  : `creditCard ${item.r_nombre_banco}`
              }
            >
              <div className="banco">{item.r_nombre_banco}</div>
              <div className="chipLogo">
                <ChipTarjeta />
              </div>
              <div className="content-card-number d-flex justify-content-around">
                <p>{item.r_numero_cuenta.substring(0,4)}</p>
                <p>{item.r_numero_cuenta.substring(4,8)}</p>
                <p>{item.r_numero_cuenta.substring(8,11)}</p>
                <p>{item.r_numero_cuenta.substring(11,item.r_numero_cuenta.length)}</p>
              </div>
            </div>
          </section>
        </Col>
      ))}
    </Row>
  );
};

export default Cards;
