import { Box } from "@mui/material";
import styled from "styled-components";

export const ContentFilesExtention = styled(Box)`
  ${(p) => p.theme.tipography.caption2}
  margin-bottom: 24px;
`;

export const ContentText = styled(Box)`
  ${(p) => p.theme.tipography.bodySmallRegular}
  margin-bottom: 24px;
`;
export const ContentCheckMode = styled(Box)`
  display: flex;
`;

export const ContentTable = styled(Box)`
  & h2 {
    ${(p) => p.theme.tipography.headingSubtitleSemi}
  }
`;

export const ContentResumenValidar = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${(p) => p.theme.tipography.bodySmallRegular}

  & h2 {
    ${(p) => p.theme.tipography.h4}
    margin-bottom: 16px;
  }

  & svg {
    margin-bottom: 24px;
  }
  & .HBT_JUBLEGAL_TextValidacion {
    margin-bottom: 32px;
    text-align: center;
  }
`;

export const ContentCheckBox = styled(Box)`
  /* display: flex; */
`;

export const ContentInputCheck = styled(Box)`
  display: flex;
`;
