import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";

const ModalActualizarDatos = ({ isOpen, setIsOpen }) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      close={false}
      isOpen={isOpen}
      icon={true}
      onClose={closeModal}
      title="Seguimos validando tus documentos"
      subtitle={"Te enviaremos un correo al terminar."}
      onClickBtnPrimary={() =>
        history.push("/mi-habitat-digital/afiliado/jubilacion")
      }
      textBtnPrimary={"Volver luego"}
    />
  );
};

export default ModalActualizarDatos;
