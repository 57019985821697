import React from "react";
import { Button } from "../../components";
const ModalConfirmacion = ({ cerrarModal, guardarCode, message }) => {
  return (
    <div className="wraper-modal">
      <div className="modal-content">
        <h5 className="card-title text-center" style={{ fontWeight: "600" }}>
          Alerta
        </h5>
        <div>
          <div>
            <section className="custom-confirm-table">
              <p>{message}, vuelve a intentarlo.</p>
            </section>
          </div>
          <div className="d-flex justify-content-around mt-3">
            <Button onClick={cerrarModal} name="secondary" bgColor="white">
              Cerrar
            </Button>
            <Button onClick={guardarCode}>Volver</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmacion;
