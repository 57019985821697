import React from "react";

function Icon({ color = "#0043CE" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.66432 13.8002C0.474773 13.8002 0.316667 13.7363 0.19 13.6085C0.0633333 13.4808 0 13.3224 0 13.1335V0.866856C0 0.677976 0.0638865 0.519642 0.19166 0.391855C0.319447 0.264082 0.47778 0.200195 0.66666 0.200195H6.43334C6.58889 0.200195 6.72777 0.249082 6.85 0.346855C6.97221 0.444642 7.04888 0.573535 7.08 0.733535L7.33332 1.8802H11.3333C11.5222 1.8802 11.6806 1.94408 11.8083 2.07186C11.9361 2.19964 12 2.35798 12 2.54686V8.81352C12 9.00241 11.9361 9.16074 11.8083 9.28852C11.6806 9.4163 11.5222 9.4802 11.3333 9.4802H7.26C7.10445 9.4802 6.96557 9.43353 6.84334 9.3402C6.72111 9.24686 6.64445 9.1202 6.61334 8.9602L6.36 7.81352H1.33332V13.1335C1.33332 13.3224 1.26921 13.4808 1.14098 13.6085C1.01275 13.7363 0.853867 13.8002 0.66432 13.8002ZM7.84 8.14686H10.6667V3.21352H6.21334L5.85334 1.53352H1.33332V6.4802H7.48L7.84 8.14686Z"
        fill="#2F6D95"
      />
    </svg>
  );
}

export default Icon;
