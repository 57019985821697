import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";

const ModalActualizarDatos = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);
  const history = useHistory();

  return (
    <Modal
      close={false}
      isOpen={isOpen}
      onClose={closeModal}
      title="Jubilación por Edad Legal"
      subtitle={
        "Actualmente usted ya ha culminado con el proceso de Jubilación Legal"
      }
      onClickBtnPrimary={() =>
        history.push("/mi-habitat-digital/afiliado/jubilacion")
      }
      textBtnPrimary={"Aceptar"}
    />
  );
};

export default ModalActualizarDatos;
