import {
    SEGUIMINETO_APORTES_CLEAN,
    SEGUIMINETO_APORTES_ERROR,
    SEGUIMINETO_APORTES_EXITOSO,
    SEGUIMINETO_APORTES_LOADING
} from "../../types/seguiminetoAportes";


export default (state, action) => {
    switch (action.type) {
        case SEGUIMINETO_APORTES_EXITOSO:
            return {
                ...state,
                seguimientoAportes: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            }
        case SEGUIMINETO_APORTES_LOADING:
            return {
                ...state,
                loadingPage: true,
            };
        case SEGUIMINETO_APORTES_ERROR:
            return {
                ...state,
                seguimientoAportes: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case SEGUIMINETO_APORTES_CLEAN:
            return {
                ...state,
                seguimientoAportes: null,
                mensajeAlerta: null,
                loadingPage: false,
            };
        default:
            break;
    }
}