import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CoinMoney from "../../../../assets/icons/coin-money.svg";
import HandMoney from "../../../../assets/icons/hand-money.svg";
import HomeContext from "../../../../context/newHome/newHomeContext";
import { montoFormat } from "../../../../helper/amountFormat";
import Wallet from "../../../../assets/icons/wallet.svg";
import carbonInformation from "../../../../assets/icons/carbon_information.svg";
import coins from "../../../../assets/icons/coins.svg";
import saveMoney from "../../../../assets/icons/save-money.svg";
import whiteArrow from "../../../../assets/icons/akar-icons_arrow-right.svg";
import Refresh from "../../../../assets/icons/refresh.svg";
import Calculator from "../../../../assets/icons/carbon_calculator.svg";
import $ from "jquery";
import moment from "moment";
import { useForm } from "react-hook-form";
import "./index.css";
const globalErrors = {
  aporte: "",
  remu: "",
  saldo: "",
};
const Section6 = () => {
  const homeContext = useContext(HomeContext);
  const { register, handleSubmit, errors } = useForm();

  const [showResult, setShowResult] = useState(false);
  const [formInputs, setFormInputs] = useState(null);

  const [loadingCalculate, setLoadingCalculate] = useState(false);
  const [errorMesaageAmount, seterrorMessageAmount] = useState(globalErrors);
  const [messageAmount, setMessageAmount] = useState(null);
  const {
    pensionCalculateData,
    obtenerEstimasionPension,
    limpiarObtenerEstimasionPension,
    pensionCalculateRangeData,
    obtenerEstimasionPensionRango,
    limpiarObtenerEstimasionPensionRango,
    mensajeAlerta,
  } = homeContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formInputs) {
      sendRequest1();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInputs]);

  useEffect(() => {
    if (mensajeAlerta) {
      setLoadingCalculate(false);
    }
  }, [mensajeAlerta]);

  useEffect(() => {
    if (pensionCalculateData) {
      if (
        parseFloat(pensionCalculateData.RPAFPpen) <
        parseFloat(formInputs.aporte)
      ) {
        const valuesInitialPen = {
          penDesea: formInputs.aporte,
        };
        obtenerEstimasionPensionRango(pensionCalculateData, valuesInitialPen);
        setMessageAmount("mayor");
      } else {
        setMessageAmount("menor");
        setShowResult(true);
        setLoadingCalculate(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pensionCalculateData]);

  useEffect(() => {
    if (pensionCalculateRangeData) {
      setShowResult(true);
      setLoadingCalculate(false);
    }
  }, [pensionCalculateRangeData]);

  useEffect(() => {
    if (showResult) showGoToAnimateById("render-result");
  }, [showResult]);

  const showGoToAnimateById = (id) => {
    $("html, body").animate(
      {
        scrollTop: $(`#${id}`).offset().top,
      },
      1000
    );
  };

  const renderResult = () => {
    if (showResult)
      return (
        <section className="section-content section6-result bg-gray-light">
          <div className="container-new-home">
            <h2 className="mb-3 h2-new-home">Resultados:</h2>
            <div className="responsive-result-container">
              <div className="result-main-content">
                <div className="height-content">
                  <Box
                    className="mb-3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="span-small-info">
                      Tu pensión y fondo actual serán:
                    </span>
                  </Box>
                  <div className="card-border card-result-content">
                    <img src={coins} alt="red-coins" />
                    <div className="card-result-info">
                      <h3>S/. {montoFormat(pensionCalculateData.RPAFPpen)}</h3>
                      <span>Tu pensión actual estimada</span>
                    </div>
                  </div>

                  <div className="card-border card-result-content">
                    <img src={saveMoney} alt="red-coins" />
                    <div className="card-result-info">
                      <h3>S/. {montoFormat(pensionCalculateData.CP)}</h3>
                      <span>Tu fondo total estimado</span>
                    </div>
                  </div>
                </div>

                {messageAmount && messageAmount === "mayor" ? (
                  <div className="card-border card-circle-content">
                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="span-small-info">
                        Si deseas jubilarte con:
                      </span>
                      <span className="span-mediun-info">
                        S/. {formInputs.aporte}
                      </span>
                    </Box>
                    <span className="span-small-info">
                      Necesitarías realizar Aportes Voluntarios (APV{" "}
                      <Tooltip
                        title="Son aportes adicionales y voluntarios que puedes realizar para mejorar tu pensión."
                        placement="top"
                      >
                        <img src={carbonInformation} alt="hand-money" />
                      </Tooltip>
                      ) de:
                    </span>

                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div id="outer-circle">
                        <div id="inner-circle" className="inside-circle-info">
                          <span className="span-mediun-info">S/.</span>
                          <span className="span-big-info">
                            {pensionCalculateRangeData.montoMensu}
                          </span>
                          <span className="span-small-info">al mes</span>
                        </div>
                      </div>
                    </Box>

                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="span-small-info">
                        Así acumularías un fondo de:
                      </span>
                      <span className="span-mediun-info">
                        S/. {pensionCalculateRangeData.capitanNece1}
                      </span>
                    </Box>

                    <Box>
                      <div
                        className="advice-voluntary-contribution"
                        onClick={() => {
                          window
                            .open(
                              "https://www.afphabitat.com.pe/aporte-voluntario/",
                              "_self"
                            )
                            .focus();
                        }}
                      >
                        <span>
                          Conoce un poco más sobre <br /> APORTES VOLUNTARIOS
                        </span>
                        <img src={whiteArrow} alt="white-arrow" />
                      </div>
                    </Box>
                  </div>
                ) : (
                  <div className="card-border card-circle-content">
                    <Box
                      className="mb-4"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="span-small-info">
                        Si deseas jubilarte con:
                      </span>
                      <span className="span-mediun-info">
                        S/. {formInputs.aporte}
                      </span>
                    </Box>

                    <div className="mb-3 content-messa-result">
                      <h3 className="messa-result-title">
                        Tu pensión deseada es menor a la estimada
                      </h3>
                      <p className="messa-result-content">
                        Si quieres recibir una pensión mayor a la estimada
                        selecciona la opción Volver a calcular.
                      </p>
                    </div>
                  </div>
                )}

                <Box
                  className="mt-5 option-calculator-content"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    className="option-calculator"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      cleanVariables();
                    }}
                  >
                    <div className="box-option">
                      <img src={Refresh} alt="white-resfresh" />
                    </div>
                    <span>
                      {" "}
                      Volver a<br />
                      calcular
                    </span>
                  </Box>

                  <Box
                    className="option-calculator"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.afphabitat.com.pe/privado/cotizador/complejo",
                        "_self"
                      );
                    }}
                  >
                    <div className="box-option">
                      <img src={Calculator} alt="white-calculator" />
                    </div>
                    <span>
                      Estimador
                      <br />
                      avanzado
                    </span>
                  </Box>
                </Box>
              </div>

              {messageAmount && messageAmount === "mayor" ? (
                <div className="result-main-content-desktop">
                  <div className="card-border card-circle-content">
                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="span-small-info">
                        Si deseas jubilarte con:
                      </span>
                      <span className="span-mediun-info">
                        S/. {formInputs.aporte}
                      </span>
                    </Box>
                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="span-small-info">
                        Necesitarías realizar Aportes Voluntarios (APV
                        <Tooltip
                          title="Son aportes adicionales y voluntarios que puedes realizar para mejorar tu pensión."
                          placement="top"
                        >
                          <img src={carbonInformation} alt="hand-money" />
                        </Tooltip>
                        ) de:
                      </span>
                    </Box>
                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div id="outer-circle">
                        <div id="inner-circle" className="inside-circle-info">
                          <span className="span-mediun-info">S/.</span>
                          <span className="span-big-info">
                            {pensionCalculateRangeData.montoMensu}
                          </span>
                          <span className="span-small-info">al mes</span>
                        </div>
                      </div>
                    </Box>

                    <Box
                      className="mb-3"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className="span-small-info">
                        Así acumularías un fondo de:
                      </span>
                      <span className="span-mediun-info">
                        S/. {pensionCalculateRangeData.capitanNece1}
                      </span>
                    </Box>

                    <Box>
                      <div
                        className="advice-voluntary-contribution"
                        onClick={() => {
                          window
                            .open(
                              "https://www.afphabitat.com.pe/aporte-voluntario/",
                              "_self"
                            )
                            .focus();
                        }}
                      >
                        <span>
                          Conoce un poco más sobre <br /> APORTES VOLUNTARIOS
                        </span>
                        <img src={whiteArrow} alt="white-arrow" />
                      </div>
                    </Box>
                  </div>
                </div>
              ) : (
                <div className="result-main-content-desktop">
                  <div className="card-border card-circle-content">
                    <Box
                      className="mb-4"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="span-small-info">
                        Si deseas jubilarte con:
                      </span>
                      <span className="span-mediun-info">
                        S/. {formInputs.aporte}
                      </span>
                    </Box>
                    <div className="mb-3 content-messa-result">
                      <h3 className="messa-result-title">
                        Tu pensión deseada es menor a la estimada
                      </h3>
                      <p className="messa-result-content">
                        Si quieres recibir una pensión mayor a la estimada
                        selecciona la opción Volver a calcular.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="mt-5 content-legal"
              style={{ width: "90%", margin: "0 auto" }}
            >
              <Box
                className="mb-3"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="description-legal">
                  Los resultados de los montos de pensión son sólo una
                  estimación. Cualquier variación en los supuestos puede
                  modificar los resultados de manera importante. La tasa de
                  descuento y la rentabilidad son fluctuantes, es decir, varían
                  de acuerdo a las condiciones del mercado, por lo que pueden
                  cambiar en el futuro. Estas cifras no comprometen de modo
                  alguno a la AFP ni a la empresa de seguros a garantizar un
                  monto de pensión determinado.
                </p>
              </Box>
            </div>
          </div>
        </section>
      );
    else return null;
  };

  const cleanVariables = () => {
    showGoToAnimateById("calculator-section");
    setShowResult(false);
    limpiarObtenerEstimasionPension();
    limpiarObtenerEstimasionPensionRango();
  };
  const validarData = (formData) => {
    let mensajesError = {
      desiredPension: "",
      salaryPension: "",
      acumulateFund: "",
    };

    if (parseFloat(formData.desiredPension) < 100) {
      mensajesError = {
        ...mensajesError,
        desiredPension: "Debes indicar un valor mayor a 100.",
      };
    }
    if (parseFloat(formData.salaryPension) < 100) {
      mensajesError = {
        ...mensajesError,
        salaryPension: "Debes indicar un valor mayor a 100",
      };
    }

    if (parseFloat(formData.acumulateFund) < 1) {
      mensajesError = {
        ...mensajesError,
        acumulateFund: "Debes indicar un valor mayor",
      };
    }
    seterrorMessageAmount(mensajesError);

    // Vemos si existe algun error

    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }
    return respuesta;
  };
  const sendRequest1 = () => {
    const dateM = moment(formInputs.date).format("DD/MM/YYYY");
    const formData = new FormData();

    formData.append("SIMPLE", "SI");
    formData.append("csrf_habitat", "");
    formData.append("txtnom", "estimador_simple");
    formData.append("txtmail", "estimadorsimple@estimador.cl");
    formData.append("docu", "DNI");
    formData.append("numdocu", "12345678");
    formData.append("gene", formInputs.gender);
    formData.append("fechanac", dateM);
    formData.append("tipojubi", "LEGAL");
    formData.append("edadjanti", "");
    formData.append("tasaobli", "2");
    formData.append("tasavolu", "0");
    formData.append("tasaVoluSin", "0");
    formData.append("verifBono", "NO");
    formData.append("tipobono", "");
    formData.append("vanomi", "");
    formData.append(
      "cadena",
      "Beneficiario,Fecha de Nacimiento,Género,Estado de Salud,"
    );
    formData.append("cant", "");
    formData.append("verifBene", "SI");
    formData.append("fonren60", "2");
    formData.append("fonren65", "1");

    formData.append("tipocomi", "SALDO");
    formData.append("dencoti", "1");
    formData.append("txtremu", montoFormat(formInputs.remu));
    formData.append("numremu", "12");
    formData.append("aporobli", montoFormat(formInputs.saldo));
    formData.append("aporvolu", "");
    formData.append("aporVoluSin", "");
    formData.append("verifSinFin", "NO");
    formData.append("ahovolumensual", "");
    formData.append("rentabi", "SUGERIDA");
    formData.append("esce60", "0.06");
    formData.append("esce65", "0.04");
    formData.append("finedad1O", "");
    formData.append("renesce1O", "0.02");
    formData.append("inicioedad2O", "");
    formData.append("finedad2O", "60");
    formData.append("renesce2O", "0.02");
    formData.append("inicioedad3O", "60");
    formData.append("finedad3O", "65");
    formData.append("renesce3O", "0.02");
    formData.append("inicioedad1V", "");
    formData.append("finedad1V", "");
    formData.append("renesce1V", "0.02");
    formData.append("inicioedad2V", "");
    formData.append("finedad2V", "60");
    formData.append("renesce2V", "0.02");
    formData.append("inicioedad3V", "60");
    formData.append("finedad3V", "65");
    formData.append("renesce3V", "0.02");

    obtenerEstimasionPension(formData);
  };

  const onSubmit = (data) => {
    if (validarData(data)) {
      setLoadingCalculate(true);
      setFormInputs({
        gender: data.radioButton,
        date: data.birthday,
        remu: data.salaryPension,
        saldo: data.acumulateFund,
        aporte: data.desiredPension,
      });
    }
  };
  var date = new Date();
  // date.setDate( date.getDate() - 6 );
  date.setFullYear(date.getFullYear() - 18);
  let onlyDate =
    date.getDate().toString().length === 1
      ? "0" + date.getDate()
      : date.getDate();
  let dateformat =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + onlyDate;
  return (
    <>
      <section id="calculator-section" className={`section-content section6`}>
        <div className="cotizador-inputs-content">
          <div className="bg-img"></div>
          <div className="container calculator-form">
            <h2 className="mb-3 mt-3 h2-new-home">
              ¿Con cuánto quisieras jubilarte?
            </h2>
            <p className="text-description text-center margin-description">
              Llena los siguientes datos y calcularemos cuánto necesitarías
              aportar para recibir la pensión que deseas.
            </p>
            <br />

            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="radio-group-container container">
                  <div className="check-container">
                    <input
                      type="radio"
                      id="radio-button-mele"
                      value="M"
                      disabled={showResult || loadingCalculate}
                      {...register("radioButton", { required: true })}
                    />
                    <label htmlFor="radio-button-mele">
                      <strong>Hombre</strong>
                    </label>
                  </div>
                  <div className="check-container">
                    <input
                      type="radio"
                      id="radio-button-female"
                      value="F"
                      disabled={showResult || loadingCalculate}
                      {...register("radioButton", { required: true })}
                    />
                    <label htmlFor="radio-button-female">
                      <strong>Mujer</strong>
                    </label>
                  </div>
                </div>
                {errors?.radioButton && (
                  <span className="error-new-home text-center">
                    Campo requerido*
                  </span>
                )}
              </div>

              <br />

              <div className="content-calculator-input">
                <Box
                  className="label-box"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <label htmlFor="desired-pension">Pensión deseada:</label>
                </Box>
                <div className="input-content">
                  <img src={HandMoney} alt="hand-money" />
                  <div className="input-adorment-content">
                    <span>S/.</span>
                    <input
                      id="desired-pension"
                      type="text"
                      className="input-field"
                      disabled={showResult || loadingCalculate}
                      autoComplete="off"
                      //  pattern={"^[0-9]+([,][0-9]+)?$"}
                      min={100}
                      onKeyPress={(event) => {
                        let regex = new RegExp("^[0-9]+$");
                        let key = String.fromCharCode(
                          !event.charCode ? event.which : event.charCode
                        );

                        if (!regex.test(key) && event.which !== 46) {
                          event.preventDefault();
                          return false;
                        }
                      }}
                      {...register("desiredPension", { required: true })}
                    />
                  </div>
                </div>
                {errors?.desiredPension ? (
                  <span className="error-new-home">Campo requerido*</span>
                ) : errorMesaageAmount.desiredPension ? (
                  <span className="error-new-home">
                    {errorMesaageAmount.desiredPension}
                  </span>
                ) : null}
              </div>

              <div className="content-calculator-input">
                <Box
                  className="label-box"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <label htmlFor="salary-pension">Sueldo bruto:</label>
                  <Tooltip
                    title="Tu sueldo antes de aplicar los descuentos por aportes u otras retenciones."
                    placement="top"
                  >
                    <img src={carbonInformation} alt="hand-money" />
                  </Tooltip>
                </Box>
                <div className="input-content">
                  <img src={CoinMoney} alt="hand-money" />
                  <div className="input-adorment-content">
                    <span>S/.</span>
                    <input
                      id="salary-pension"
                      type="text"
                      className="input-field"
                      disabled={showResult || loadingCalculate}
                      autoComplete="off"
                      min={100}
                      onKeyPress={(event) => {
                        let regex = new RegExp("^[0-9]+$");
                        let key = String.fromCharCode(
                          !event.charCode ? event.which : event.charCode
                        );

                        if (!regex.test(key) && event.which !== 46) {
                          event.preventDefault();
                          return false;
                        }
                      }}
                      // value={remu}
                      {...register("salaryPension", { required: true })}
                    />
                  </div>
                </div>
                {errors?.salaryPension ? (
                  <span className="error-new-home">Campo requerido*</span>
                ) : errorMesaageAmount.salaryPension ? (
                  <span className="error-new-home">
                    {errorMesaageAmount.salaryPension}
                  </span>
                ) : null}
              </div>

              <div className="content-columns">
                <div className="column-a">
                  <div className="content-calculator-input">
                    <Box
                      className="label-box"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <label htmlFor="acumulate-fund">Fondo acumulado:</label>
                      <Tooltip
                        title="Monto total acumulado como afiliado(a) en tu AFP."
                        placement="top"
                      >
                        <img src={carbonInformation} alt="hand-money" />
                      </Tooltip>
                    </Box>
                    <div className="input-content">
                      <img src={Wallet} alt="hand-money" />
                      <div className="input-adorment-content">
                        <span>S/.</span>
                        <input
                          id="acumulate-fund"
                          type="text"
                          className="input-field"
                          disabled={showResult || loadingCalculate}
                          autoComplete="off"
                          min="100.00"
                          // value={saldo}
                          onKeyPress={(event) => {
                            let regex = new RegExp("^[0-9]+$");
                            let key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );

                            if (!regex.test(key) && event.which !== 46) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          {...register("acumulateFund", { required: true })}
                        />
                      </div>
                    </div>
                    {errors?.acumulateFund ? (
                      <span className="error-new-home">Campo requerido*</span>
                    ) : errorMesaageAmount.acumulateFund ? (
                      <span className="error-new-home">
                        {errorMesaageAmount.acumulateFund}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="column-b">
                  <div className="content-calculator-input">
                    <Box
                      className="label-box"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginLeft: "0.5em",
                      }}
                    >
                      <label htmlFor="birthday">Fecha de nacimiento:</label>
                    </Box>
                    <div className="input-content">
                      <div className="input-adorment-content">
                        <input
                          id="birthday"
                          type="date"
                          className="input-field"
                          disabled={showResult || loadingCalculate}
                          autoComplete="off"
                          max={dateformat}
                          // onKeyDown={(e) => e.preventDefault()}
                          // value={date}
                          // min={dateformat}
                          {...register("birthday", { required: true })}
                        />
                      </div>
                    </div>
                    {errors?.birthday && (
                      <span className="error-new-home">Campo requerido*</span>
                    )}
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="btn-primary-new-home block margin-center"
                disabled={showResult || loadingCalculate}
              >
                Calcular{" "}
                {loadingCalculate && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
      <div id="render-result">{renderResult()}</div>
    </>
  );
};

export default Section6;
