import React, { useState } from "react";

import Modal from "./CustomModal";

const ModalPensionC40Final = ({
  isOpen,
  setIsOpen,
  enviarRejaSgaValidador,
  rejaSgaValidarLoading,
}) => {
  const closeModal = () => setIsOpen(false);
  const onFinish = () => enviarRejaSgaValidador();
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Recuerda que ..."
      subtitle={
        "Como actualmente percibes una pensión, puedes solicitar la devolución de tu fondo por jubilación en otro régimen (Circular 040). Te enviamos un correo con los pasos a seguir."
      }
      loadingBtnPrimary={rejaSgaValidarLoading}
      onClickBtnPrimary={() => onFinish()}
      textBtnPrimary={"Finalizar"}
    />
  );
};

export default ModalPensionC40Final;
