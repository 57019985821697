import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import { Alert, Snackbar } from '@mui/material';


const ErrorGenerico = () => {

    const authContext = useContext(AuthContext);

    const {
        errorGenerico,
      } = authContext;
      
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(errorGenerico.activo){
            setOpen(true)
        }
    }, [errorGenerico])

    const handleClose = () => {
        setOpen(false);
      };

    return (
        <>  {errorGenerico.activo
            ?
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="error" className='mb-3'>
                    <b className="">{errorGenerico.message} </b>
                </Alert>
            </Snackbar>
            : ''}

        </>
    );
}

export default ErrorGenerico;