import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../../components/BasicModal";
import Divider from "../../../../../../components/Divider";
// import CloseIcon from "../../../../../../assets/IconBucket/CloseIcon";
import { IconWrapper, Title, ModalContent, ModalFooter } from "./styles";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../../../Components/ButtonsComponents";
const CustomModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  isBtnSecondary,
  textBtnSecondary,
  onClickBtnSecondary,
  loadingBtnSecondary,
  textBtnPrimary,
  onClickBtnPrimary,
  loadingBtnPrimary,
}) => {
  // const closeModal = () => setIsOpen(false);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Title>
        {/* <IconWrapper>
          <CloseIcon />
        </IconWrapper> */}
        <span>{title}</span>
      </Title>
      <Modal.Subtitle>{subtitle}</Modal.Subtitle>
      <ModalContent>{children}</ModalContent>
      <Divider />
      <ModalFooter>
        <ButtonsWrapper>
          {isBtnSecondary && (
            <PrevButton
              variant="secondary"
              disabled={loadingBtnSecondary}
              onClick={onClickBtnSecondary}
            >
              {textBtnSecondary}
            </PrevButton>
          )}
          <NextButton
            disabled={loadingBtnPrimary}
            onClick={onClickBtnPrimary}
            isBtnSecondary
          >
            {textBtnPrimary}
          </NextButton>
        </ButtonsWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
CustomModal.propTypes = {
  children: PropTypes.node,
  isBtnSecondary: PropTypes.bool,
  onClickBtnSecondary: PropTypes.func,
  onClickBtnPrimary: PropTypes.func,
};

CustomModal.defaultProps = {
  isBtnSecondary: false,
  loadingBtnPrimary: false,
  loadingBtnSecondary: false,
};
