import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { CardContainer, MessageSnack } from "../../../../components";
import "./index.css";
import { montoFormat } from "../../../../helper/amountFormat";

const FUNDS = {
  OBL: 0,
  VSF: 1,
  VCF: 2,
};

const BoxBody = styled(Box)`
  padding: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.tipography.h5};
  & .spanText {
    display: block;
    color: ${(props) => props.theme.palette.grayscale.light};
    font-size: 14px;
    // margin-bottom: 10px;
  }
  & .amount {
    ${(props) => props.theme.tipography.number};
  }
  & .content-text {
    display: flex;
  }
  & .invested-amount {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
  }
`;

export default function MyProducts(props) {
  const {
    total,
    fondos,
    getObtener,
    setProductMovement,
    setOpen,
    open,
    validateEtareo,
  } = props;
  const history = useHistory();
  const getClient = localStorage.getItem("client");
  const parseClient = JSON.parse(getClient);
  const [fundSelected, setFundSelected] = useState(FUNDS.OBL);
  const onClickFund = (item, fund) => {
    setFundSelected(fund);
    getObtener(item.r_cuenta);
    setProductMovement(item.r_cuenta);
  };

  const filterProduct = fondos.filter((item) => item.r_aporte !== "VSF");
  const filterVSF = filterProduct.filter((item) => Number(item.r_saldo) !== 0);

  const formatDate =
    validateEtareo.code === "No aplica"
      ? "2/4/2022"
      : validateEtareo.fec_limite;
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        {open && filterVSF.length > 0 && (
          <MessageSnack
            alt="ICON_ADV"
            bgAlert="#FFFAF0"
            shadow={true}
            title="Cambio de fondo etario en curso"
            description={`Podrás decidir un fondo para tus productos de Jubilación hasta el ${formatDate}, de lo contrario pasarán al Fondo ${
              validateEtareo.tipo_etario === 60 ? 1 : 0
            }.`}
            action={true}
            textAction="Saber más"
            color={"#EFBD0C"}
            onClickAction={() => setOpen(true)}
          />
        )}
      </Box>

      <CardContainer
        title={"Fondos invertidos"}
        seeMore={false}
        color={"#CE1336"}
        product={true}
        showHeader={true}
        showContainer={"desktop"}
      >
        <BoxBody className="home-box-body">
          <span className="spanText">Al día de hoy tienes: </span>
          <div className="info-metrics mb-4">
            <span className="amount">{`S/ ${montoFormat(total.r_saldo)}`}</span>
          </div>

          <h4 className="mb-3">Revisa tus productos:</h4>

          <div className="custom-tabs mb-4">
            {fondos.map((item, index) => (
              <div
                key={index}
                className={`tab ${fundSelected === index ? "selected" : ""}`}
                onClick={() => onClickFund(item, index)}
              >
                {item.r_cuenta === "OBL"
                  ? "Fondo de Jubilación"
                  : item.r_cuenta === "VSF"
                  ? "Fondo Libre Habitat"
                  : "Fondo con Fin Previsional"}
              </div>
            ))}
          </div>
          <div className="ml-3">
            <div className="content-text">
              <span className="spanText mr-4">Saldo acumulado </span>
              <span className="label-fund">
                {`Fondo ${fondos[fundSelected].r_fondo}`}
              </span>
            </div>

            <span className="block invested-amount">{`S/ ${montoFormat(
              fondos[fundSelected].r_saldo
            )}`}</span>
          </div>
          {fondos[fundSelected].r_saldo === "0" ? null : (
            <button
              className="btn-primary position-bottom-right btn-products"
              onClick={() => {
                sessionStorage.setItem(
                  "product",
                  fondos[fundSelected].r_cuenta
                );
                history.push(
                  "/mi-habitat-digital/afiliado/tipo-jubilacion?name=" +
                    fondos[fundSelected].r_cuenta
                );
              }}
            >
              Ver más
            </button>
          )}
        </BoxBody>
      </CardContainer>
    </>
  );
}
