import React, { forwardRef } from "react";
import LargeInput from "./LargeInput";
import SmallInput from "./SmallInput";
import { FileProps } from "./types";

const FileInput = forwardRef((props, ref) => {
  return (
    <>
      {props.size === "large" && <LargeInput {...props} ref={ref} />}
      {props.size === "small" && <SmallInput {...props} ref={ref} />}
    </>
  );
});

export default FileInput;

FileInput.propTypes = { ...FileProps };

FileInput.defaultProps = {
  name: "file-input",
  size: "large",
  extensions: ["pdf", "png"],
  required: { value: true, message: "Este campo es requerido." },
  maxFileSize: 2,
};
