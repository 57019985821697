import styled from "styled-components";

export const ModalDeErrorGeneralComponent = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: height(calc(100% - 16px));
  width: min(100%, calc(400px - 16px));
  padding: 16px;
  background-color: ${(p) => p.theme.palette.white};
  outline: none;
  border-radius: 16px;
  p {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: grid;
  row-gap: 16px;
  margin-bottom: 24px;

  .close {
    justify-self: end;
    cursor: pointer;
  }

  .title {
    justify-self: center;
    ${(p) => p.theme.tipography.h5}
  }
`;
