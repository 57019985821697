import React from "react";
import svgConfirmado from "../../../../assets/icons/confirmado_tema.svg";
import svgEscuchando from "../../../../assets/icons/volume-high.svg";
import svgSinEscuchar from "../../../../assets/icons/espera_tema.svg";
import svgRepeticionTema from "../../../../assets/repeticion_tema.svg";

export default function Topic(props) {
  const {
    topic,
    title,
    description,
    currentTopic,
    currentTopicData,
    setModalBlockTopic,
    setTopicForBlock,
    logicToChangeTopics,
    isRepeatWorkflow,
    actualizarTimer,
  } = props;

  const validateTopicinteraction = () => {
    if (currentTopic < topic) {
      setModalBlockTopic(true);
      setTopicForBlock(topic);
    } else if (currentTopicData.topic === topic) actualizarTimer();
    else logicToChangeTopics(topic, description);
  };
  return (
    <div className="cd-timeline-block">
      <div
        className={
          (topic < currentTopic || isRepeatWorkflow
            ? "cd-timeline-img cd-topic-complete"
            : "cd-timeline-img cd-topic-complete-blocked") +
          (topic === 7 ? " delete-line" : "")
        }
        onClick={validateTopicinteraction}
      >
        <div className="content-img-topic">
          <img
            src={
              topic === currentTopicData.topic
                ? svgEscuchando
                : topic < currentTopic || isRepeatWorkflow
                ? svgConfirmado
                : svgSinEscuchar
            }
            className={topic === currentTopicData.topic ? "svgEscuchando" : ""}
            alt="icono-timeline"
          />
        </div>

        <h6>{title}</h6>
      </div>

      <div className="cd-timeline-content" onClick={validateTopicinteraction}>
        {topic === currentTopicData.topic && topic < currentTopic && (
          <img
            className="icon-repeat-desktop"
            src={svgRepeticionTema}
            alt="icono de repeticion"
          />
        )}

        <div className="info-topic">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
      <div className="mobile-white-space"></div>
    </div>
  );
}
