import { useState, useEffect } from "react";
import { montoFormat } from "../helper/amountFormat.js";
const initTablaComision = {
  header: [
    {
      name: "Tipo de saldo",
    },
    {
      name: "Comisión por flujo",
    },
    {
      name: "Comisión por saldo",
    },
  ],
  body: [],
};
export const useComisionHistorica = (datosComision) => {
  const [state, setState] = useState({
    data: {
      tabla: initTablaComision,
      showTable: false,
    },
  });
  useEffect(() => {
    if (datosComision) {
      if (datosComision.r_validacion === 1) {
        crearTabla(datosComision.r_comision_historica);
      }
    }

    // eslint-disable-next-line
  }, [datosComision]);

  const crearTabla = (datos) => {
    const body = datos.map((item) => {
      return {
        tipoSaldo: item.r_tipo_aporte,
        flujo: item.r_comision_flujo
          ? `S/ ${montoFormat(item.r_comision_flujo)}`
          : "-",
        saldo: item.r_comision_saldo
          ? `S/ ${montoFormat(item.r_comision_saldo)}`
          : "-",
      };
    });

    const tabla = { ...initTablaComision, body: body };
    setState((estado) => ({
      ...estado,
      data: { ...estado.data, tabla: tabla, showTable: true },
    }));
  };

  return state;
};
