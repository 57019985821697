import React from "react";
import Divider from "../../../../components/Divider";
import Stepper from "../../../../components/Stepper";

const Header = ({ activeStep = 0 }) => {
  const steps = [
    { label: "Datos de retiro" },
    { label: "Confirmación de solicitud" },
  ];
  return (
    <div>
      <Stepper steps={steps} activeStep={activeStep} />
      {activeStep === 0 && <Divider />}
    </div>
  );
};

export default Header;
