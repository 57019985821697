import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const CardContainer = styled(Card)`
  display: ${(props) =>
    props.show === "mobile" || props.show === "both" ? "block" : "none"};
  background: inherit;
  margin-bottom: 24px;
  overflow: unset !important;
  &.MuiCard-root {
    box-shadow: none;
    @media (min-width: 990px) {
      border-radius: 16px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    }
  }
  @media (min-width: 990px) {
    border-radius: 16px;
    overflow: unset !important;
    display: ${(props) =>
      props.show === "desktop" || props.show === "both" ? "block" : "none"};
    border: 1px solid #e5e5e5;
    width: 100%;
    position: relative;
  }
`;
const Content = styled(CardContent)`
  background: #fff;
  border-top: ${(props) => (!props.border ? "1px solid #e5e5e5" : "none")};

  &.MuiCardContent-root:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};

    @media (min-width: 990px) {
      border-radius: 16px;
    }
  }
`;
const Header = styled(Box)`
  padding: ${(props) => props.theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  @media (min-width: 990px) {
    padding: ${(props) => props.theme.spacing(2, 3)};
  }
`;
const TitleCard = styled.h3`
  font-weight: 700;
  margin-bottom: 0;
  ${(props) => props.theme.tipography.h5};
  @media (min-width: 990px) {
    ${(props) => props.theme.tipography.h4};
    min-width: 420px;
  }
`;
const Index = (props) => {
  const {
    title,
    seeMore,
    product,
    display,
    showHeader,
    showContainer,
    color,
    children,
    className,
    border,
    link,
    padding,
  } = props;

  const style = {
    background: color,
    display,
  };
  const styleTitle = {
    color: product ? "#ffffff" : "#000000",
  };
  let pad;
  if (padding === "p-0") {
    pad = {
      padding: "0px",
    };
  }

  return (
    <CardContainer
      show={showContainer}
      border={border.toString()}
      className={className}
    >
      {showHeader && (
        <Header style={style}>
          <TitleCard style={styleTitle}>{title}</TitleCard>
          {seeMore && (
            <Link className="primary-link" to={link}>
              Ver más
            </Link>
          )}
        </Header>
      )}

      <Content p={0}>{children}</Content>
    </CardContainer>
  );
};

export default Index;
Index.defaultProps = {
  title: "",
  seeMore: false,
  product: false,
  display: false,
  showHeader: false,
  color: "#fff",
  showContainer: "both",
  border: false,
  className: "",
  link: "",
  padding: "",
};
