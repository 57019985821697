import styled, { css } from "styled-components";

const mdBreakpoint = "1440px";

export const InvesmentHistoricalComponent = styled.div`
  background-color: white;
  display: grid;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 4px 13px rgba(27, 29, 39, 0.12);

  p {
    margin: 0;
  }

  @media screen and (max-width: ${mdBreakpoint}) {
    /* max-width: 500px; */
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Header = styled.div`
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  gap: 8px;

  p {
    font-weight: 500;
    color: #363a3e;
    margin-bottom: 0;
  }

  svg {
    color: ${(p) => p.theme.palette.secondary.main};
    width: 16px;
    height: 16px;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 24px;
`;

export const TR = styled.tr`
  border-bottom: 1px solid #ededed;

  th,
  td {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #51565f;
    padding: 8px;
  }

  th {
    font-weight: 500;
    color: #363a3e;
  }

  td.rendimiento {
    &.positiveAmount {
      color: ${(p) => p.theme.palette.numbers.positive};
    }

    &.negativeAmount {
      color: ${(p) => p.theme.palette.numbers.negative};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid #ededed;
`;

export const PaginationComponent = styled.div`
  display: grid;
  justify-content: end;
  ul {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;

    li {
      font-size: 12px;
      position: relative;

      background-color: #ffebef;
      z-index: 1;
      color: #6e7378;
      list-style: none;

      & {
        padding-right: 8px;

        ${(p) =>
          p.size === "small" &&
          css`
            padding-right: 0px;
          `}
      }

      a {
        display: grid;
        justify-content: center;
        align-content: center;
        width: 34px;
        height: 34px;
        z-index: 2;

        ${(p) =>
          p.size === "small" &&
          css`
            width: 24px;
            height: 24px;
          `}
      }

      &:first-of-type,
      &:last-of-type,
      &:nth-last-of-type(2) {
        padding-right: 0px;
      }

      &:nth-of-type(2) {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }

      &:nth-last-of-type(2) {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        padding-right: 0px;
      }

      &.selected {
        color: white;
        a {
          background-color: #ce1336;
          border-radius: 100px;
        }
      }

      &.previous,
      &.next {
        border-radius: 100px;
        a {
          color: #ce1336;
        }

        &.disabled {
          background-color: ${(p) => p.theme.palette.blocked.disabled};
          cursor: not-allowed;

          a {
            color: white;
          }
        }

        .icon {
          svg {
            font-size: 16px;
          }
        }
      }

      &.previous {
        margin-right: 8px;
      }

      &.next {
        margin-left: 8px;
      }
    }
  }
`;

export const MobileTableComponent = styled.div`
  margin-bottom: 8px;
`;

export const PeriodTable = styled.div`
  padding: 8px 16px;
  color: ${(p) => p.theme.palette.textColor.mainLaight};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Period = styled.p`
  ${(p) => p.theme.typography.subtitle2}
  color: ${(p) => p.theme.palette.textColor.main};
`;

export const Growth = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 16px;
  align-items: center;
  p {
    font-size: 14px;
  }
  .label {
    ${(p) => p.theme.typography.subtitle2}
    color : ${(p) => p.theme.palette.textColor.secondary}
  }

  .value {
    font-weight: 300;
    &.positiveAmount {
      color: ${(p) => p.theme.palette.numbers.positive};
    }

    &.negativeAmount {
      color: ${(p) => p.theme.palette.numbers.negative};
    }
  }

  .icon {
    display: grid;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    height: 24px;

    &[data-isclosed="true"] {
      transform: rotate(-90deg);
    }

    svg {
      width: 18px;
      height: 18px;
      color: ${(p) => p.theme.palette.textColor.main};
    }
  }
`;

export const MoreInformation = styled.div`
  div {
    border-radius: 16px;
    display: grid;
    grid-template-columns: minmax(150px, 300px) minmax(100px, 1fr);
    padding: 4px 8px;
    ${(props) => props.theme.tipography.body2}
    font-weight: 300;
    gap: 16px;

    &:nth-child(odd) {
      background-color: ${(p) => p.theme.palette.background.default};
    }
    .label {
      align-self: center;
    }

    .value {
      justify-self: end;
      align-self: center;
    }
  }
`;
