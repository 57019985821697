import styled, { css } from "styled-components";
import Buttons from "../../../../components/Buttons";

export const FileInputComponent = styled.div`
  input[type="file"] {
    display: none;
  }

  width: 100%;
  /* min-height: 172px; */
  border-radius: 16px;
  display: block;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  padding: 8px 16px;
  border: 1px dashed ${(p) => p.theme.palette.grayscale[400]};

  display: grid;

  ${(p) => p.theme.tipography.bodySmallRegular};

  ${(p) =>
    p.error &&
    css`
      border-color: ${(p) => p.theme.palette.state.error};
    `}
`;

export const NoFilesContainerComponent = styled.div`
  justify-self: center;
  align-self: center;
  display: grid;
  justify-items: center;
  gap: 16px;
  color: ${(p) => p.theme.palette.grayscale[200]};

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};
    `};
`;

export const GrayButton = styled(Buttons).attrs((p) => {
  return {
    textColor: p.theme.palette.grayscale[400],
  };
})``;

export const UploadAgain = styled.div`
  align-self: center;
  display: grid;
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: ${(p) => p.theme.palette.grayscale[400]};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const WithFilesContainerComponent = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: minmax(0, 1fr) auto;
  ${(p) => p.theme.tipography.bodySmallMedium}
`;

export const DropdownFilesContainer = styled.div`
  /* height: 35px; */
  z-index: 10;
`;

export const FilesNoteWrapper = styled(DropdownFilesContainer)``;

export const DropdownFilesWrapper = styled.div`
  background-color: ${(p) => p.theme.palette.grayscale[100]};
  justify-content: start;
  border: 1px solid ${(p) => p.theme.palette.grayscale[300]};
  border-radius: 8px;
  display: grid;
  padding: 8px 16px;
  grid-template-columns: 1fr;
  z-index: 10;

  border-color: ${(p) => p.theme.palette.state.success};
  background-color: ${(p) => p.theme.palette.state.successLight};

  ${(p) =>
    p.open &&
    css`
      box-shadow: ${(p) => p.theme.elevations.below};
    `}

  ${(p) =>
    p.error &&
    !p.open &&
    css`
      border-color: ${(p) => p.theme.palette.state.error};
      background-color: ${(p) => p.theme.palette.state.errorLight};
      color: ${(p) => p.theme.palette.state.error};
    `}
`;

export const FilesNote = styled.div`
  color: ${(p) => p.theme.palette.grayscale[500]};
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) => p.theme.palette.state.success};
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  position: relative;
  align-items: center;

  .remove {
    display: grid;
    position: absolute;
    background-color: ${(p) => p.theme.palette.state.successLight};
    right: 0;
    padding-left: 4px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);

    ${(p) =>
      p.error &&
      css`
        background-color: ${(p) => p.theme.palette.state.errorLight};
      `}

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${(p) =>
    p.error &&
    !p.open &&
    css`
      border-color: ${(p) => p.theme.palette.state.error};
      color: ${(p) => p.theme.palette.state.error};
    `}
`;

export const DeleteFilesButton = styled.div``;

export const FilesContainer = styled.div`
  display: grid;
  gap: 16px;
  overflow-wrap: break-word;
`;

export const File = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 8px;

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};
    `}
`;

export const FileName = styled.div``;

export const FileIcon = styled.div`
  width: 16px;
  height: 16px;
  display: grid;
  svg {
    width: 100%;
    height: 100%;
  }
`;
