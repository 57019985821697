import React, { useState, useEffect } from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import Cards from "../../../components/CreditCard/Cards";
import CurrencyInput from "../InputMask";
import { montoFormat } from "../../../helper/amountFormat";
import Dropzone from "react-dropzone";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SolicitudNoProcesada from "../SolicitudNoProcesada";
import SolicitudProcesada from "../SolicitudProcesada";
import ModalToken from "../../../components/ModalToken";
import { obtenerCtaRemesa } from "../../../utils/formulaRemesa";
import SelectGeneral from "./SelectGeneral";
import { CardContainer } from "../../../components";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomModal from "../../../components/custromModal/CustomModal";
import { retiro } from "../../AporteVoluntarioSinFin/DataLocal";
import RadioButton from "../../../components/RadioButtonCustom";
import ModalExitFatca from "./ModalExitFatca";
import IMG_EVIDENCE from "../../../assets/img/img_evidencia.png";
import { Card, CardBody, Form } from "reactstrap";
import HorizontalStepper from "../../../components/HorizontalStepper/HorizontalStepper.jsx";
import IC_EVIDENCE_ACCOUNT from "../../../assets/icons/IC_EVIDENCE_ACCOUNT.svg";
import InputMask from "react-input-mask";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputLabel,
  TextField,
  Paper,
  Alert,
} from "@mui/material";
import { FormatColorReset } from "@mui/icons-material";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 16,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const globalErrors = {
  fondo: "",
  cuentaCard: "",
  banco: "",
  tipoCuenta: "",
  anioYmes: "",
  nroCuenta: "",
  monto: "",
  image: "",
};

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  iconBlue: "#0043CE",
  disabledText: "#CDCED0",
  disabledBg: "#F8F9FB",
};

/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};
/* estilos en className */
const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainerStyles: {
      borderRadius: "16px",
      boxShadow: 2,
      userSelect: "none",
    },
    paperContainerStylesChanges: {
      borderRadius: "0px",
      border: "0px",
      boxShadow: "none",
      userSelect: "none",
      padding: "16px",
    },
    bodyContent: {
      paddingBottom: "16px",
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
        margin: 0,
      },
      "&.MuiFormControlLabel-root": {
        margin: 0,
      },
    },
    evidenceContent: {
      width: "343px",
      margin: "auto",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
      borderRadius: "16px",
      padding: "16px",
      "& div": {
        borderRadius: "16px",
        padding: "16px",
        fontSize: "12px",
        "& img, & p, & .btn-text": {
          paddingBottom: "14px",
          marginBottom: 0,
        },
        "& div": {
          backgroundColor: "#CE1336",
          padding: "4px 8px",
          color: "#ffffff",
        },
      },
    },
    selectStyles: {
      "& .MuiSelect-filled": {
        borderRadius: "16px",
      },
      "& .MuiSelect-filled.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    textFieldStyles: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "16px",
      },
    },
    textFieldFilledStyles: {
      "& .MuiFilledInput-root": {
        borderRadius: "16px",
      },
      "& .MuiFilledInput-root::after, & .MuiFilledInput-root::before, & .MuiFilledInput-root:hover":
        {
          border: "none",
        },
      "& .MuiFilledInput-root.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    boxDescriptionStyles: {
      display: "flex",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    boxAmount: {
      display: "flex",
      width: "287px",
      alignItems: "center",
      background: "#F2FAFF",
      margin: "0 auto",
      justifyContent: "center",
      padding: "16px 0",
      border: "1px solid #2F6D95",
      borderRadius: "16px",
      color: "#2F6D95",
      fontSize: "24px",
    },
    boxFondoStyles: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      color: palletteColors.GreyText,
    },

    boxWarningStyles: {
      mt: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    iconStyles: {
      color: palletteColors.iconBlue,
    },
  })
);
const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});
const bancos = [
  { r_codigo: "11", r_descripcion: "DE LA NACION" },
  { r_codigo: "13", r_descripcion: "PICHINCHA" },
  { r_codigo: "14", r_descripcion: "BANBIF" },
  { r_codigo: "85", r_descripcion: "FALABELLA" },
  { r_codigo: "81", r_descripcion: "GNB" },
  { r_codigo: "9", r_descripcion: "COMERCIO" },
  { r_codigo: "116", r_descripcion: "RIPLEY" },
  { r_codigo: "10", r_descripcion: "BCP" },
  { r_codigo: "15", r_descripcion: "INTERBANK" },
  { r_codigo: "19", r_descripcion: "SCOTIABANK" },
  { r_codigo: "7", r_descripcion: "BBVA" },
];
const PrimerPaso = (props) => {
  /** aqui solo cae cuando r_validacion es 1 o 2 */
  const { data, montoTotal, resultado } = props;
  const mostrarCuentas = data.r_cuentas ? true : false;
  const [confirmacionInfo, setConfirmacionInfo] = useState(null);

  const [formaRetiro, setFormaRetiro] = useState("T"); // 2 es parcial 1 es total
  const [typeAccount, setTypeAccount] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalToken, setOpenModalToken] = useState(false);
  const [openModalTokenError, setOpenModalTokenError] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [cuentaDestino, setCuentsDestino] = useState(-1);
  const [banco, setBanco] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [nroDeCuenta, setNroDeCuenta] = useState("");
  const [montoRetiro, setMontoRetiro] = useState("");
  const [filtroTipoCuenta, seFiltroTipoCuenta] = useState([]);
  /** Opciones dependientes del Tipo de cuenta*/
  const [longitudNroCuenta, setLongitudNroCuenta] = useState(0);
  const [mostrarFormularioCompleto, setMostrarFormularioCompleto] = useState(
    !mostrarCuentas
  );
  const [showErrorToken, setShowErrorToken] = useState(false);

  const [showModalInfo, setShowModalInfo] = useState(false);

  /* Estado para la BasicCard */
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("-1");
  /* estados para el stepper */
  const [stepLength, setStepLength] = useState([
    "Datos de retiro",
    "Confirmación de solicitud",
  ]);
  const [step, setStep] = useState(0);
  const [errores, setErrores] = useState(globalErrors);
  const disableMonto = formaRetiro === "P" ? false : true;
  const disableMontoClass =
    formaRetiro === "P" ? "custom-mask" : "custom-mask custom-mask-disable";
  useEffect(() => {
    if (resultado) {
      if (Number(resultado.result) === 9) {
        setOpenModalToken(false);

        setOpenModalTokenError(true);
      } else {
        setStep(1);
        setOpenModalToken(false);
      }
    }

    // eslint-disable-next-line
  }, [resultado]);

  const handleRadio = (option) => {
    console.log(option);
    // e.preventDefault();
    if (option === "T") {
      // retiro parcial
      setMontoRetiro(montoFormat(montoTotal));
    } else {
      setMontoRetiro("");
      delete errores["monto"];
    }
    setFormaRetiro(option);
  };

  const handlePreviewOpen = () => {
    setOpenModalPreview(true);
  };
  const handlePreviewClose = () => {
    setOpenModalPreview(false);
  };
  const opcionSeleccionadaCard = (opcion) => {
    if (opcion === "nueva") {
      setMostrarFormularioCompleto(true);
      setCuentsDestino("nueva");
    } else {
      setMostrarFormularioCompleto(false);
      setCuentsDestino(opcion);
    }
    // limpiamos campos
    setBanco("");
    setTipoDeCuenta("");
    setImage("");
    setImageFile(null);
    setNroDeCuenta("");
    seFiltroTipoCuenta([]);
    setLongitudNroCuenta(0);
    setErrores(globalErrors);
  };
  const handlerTipoCuenta = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);

      if (opcion.r_cci === "1") {
        setTypeAccount("isCci");
      } else {
        setTypeAccount(null);
      }
      setTipoDeCuenta(JSON.stringify(opcion));
    } else {
      opcion = "";
      setTipoDeCuenta("");
    }

    if (opcion.r_banco === "11") {
      setNroDeCuenta("018");
    } else if (opcion.r_banco === "7") {
      setNroDeCuenta("0011");
    } else {
      setNroDeCuenta("");
    }

    if (opcion !== "") {
      setLongitudNroCuenta(opcion.r_longitud);
    } else {
      setLongitudNroCuenta(0);
    }
    setErrores({ ...errores, tipoCuenta: "" });
  };

  const handlerbanco = (opcion) => {
    seFiltroTipoCuenta([]);
    if (opcion !== "") {
      setBanco(opcion);
      let newListTipoCuenta = data.r_tipo_cuentas.filter(
        (item) => item.r_banco === opcion.r_codigo
      );

      seFiltroTipoCuenta(newListTipoCuenta);
    } else {
      setBanco("");
    }
    setNroDeCuenta("");
    setTypeAccount(null);

    setErrores({ ...errores, banco: "" });
  };
  const handlerNroCuenta = (e) => {
    const valor = e.target.validity.valid ? e.target.value : nroDeCuenta;

    setNroDeCuenta(valor);

    setErrores({ ...errores, nroCuenta: "" });
  };

  const validarData = () => {
    let montoLocal;
    let mensajesError = {
      cuentaCard: "",
      banco: "",
      tipoCuenta: "",
      anioYmes: "",
      nroCuenta: "",
      monto: "",
    };

    const percent = 0.98;
    const limitRetire = parseFloat(montoTotal).toFixed(2) * percent;

    const fixedDecimalLimit = +(Math.round(limitRetire + "e+2") + "e-2");
    let montoARetirar = montoRetiro.replace("S/ ", "").replace(/,/g, "");

    if (montoRetiro.length > 0) {
      montoLocal = montoRetiro.replace("S/ ", "").replace(/,/g, "");
      if (parseFloat(montoLocal) === 0.0) {
        mensajesError = {
          ...mensajesError,
          monto: "El monto a retirar debe ser mayor a 0,",
        };
      } else {
        if (parseFloat(montoLocal) > parseFloat(montoTotal).toFixed(2)) {
          mensajesError = {
            ...mensajesError,
            monto:
              "El monto a retirar no puede ser mayor al saldo de su fondo.",
          };
        } else if (
          parseFloat(fixedDecimalLimit) < parseFloat(montoARetirar) &&
          formaRetiro === "P"
        ) {
          mensajesError = {
            ...mensajesError,
            monto:
              "El monto ingresado es muy próximo al monto total, por lo que es posible que su solicitud pueda ser rechazada, por favor ingrese un monto menor o elija retiro total.",
          };
        }
      }
    } else {
      mensajesError = {
        ...mensajesError,
        monto: "El monto a retirar es requerido.",
      };
    }

    if (cuentaDestino === -1) {
      mensajesError = { ...mensajesError, cuentaCard: "campo requerido" };
    }

    if (mostrarFormularioCompleto) {
      if (banco.length === 0) {
        mensajesError = { ...mensajesError, banco: "campo requerido" };
      }
      if (image.length === 0) {
        mensajesError = { ...mensajesError, image: "subir un archivo" };
      }
      if (tipoDeCuenta.length === 0) {
        mensajesError = { ...mensajesError, tipoCuenta: "campo requerido" };
      } else {
        let tipoCuentaObject = JSON.parse(tipoDeCuenta);
        if (nroDeCuenta.length === 0) {
          mensajesError = { ...mensajesError, nroCuenta: "campo requerido" };
        } else {
          if (nroDeCuenta.length < tipoCuentaObject.r_longitud) {
            mensajesError = {
              ...mensajesError,
              nroCuenta: `campo de ${tipoCuentaObject.r_longitud} digitos`,
            };
          }
        }
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error

    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }
    return respuesta;
  };
  const handleMonto = (e) => {
    setMontoRetiro(e.target.value);
  };

  const procesarSolicitud = () => {
    if (validarData()) {
      // console.log(props.conTokenDigital);
      // if (props.conTokenDigital === 1) {
      //   // setOpenModal(true);
      //   setOpenModalToken(true);
      // } else {
      //   enviarDataAlPadre(null);
      // }
      let montoLocal = montoRetiro.replace("S/ ", "").replace(/,/g, "");

      let retiro = {
        v_codigo_banco: "",
        v_tipo_cuenta: "",
        v_num_cuenta: "",
        v_monto: "",
        v_form_pago: "",
      };

      if (mostrarFormularioCompleto) {
        retiro = {
          ...retiro,
          v_codigo_banco: banco.r_codigo,
          v_tipo_cuenta: JSON.parse(tipoDeCuenta).r_codigo,
          v_num_cuenta: nroDeCuenta,
          v_monto: montoLocal,
        };
      } else {
        // selecciono una tarjeta pre cargada
        retiro = {
          ...retiro,
          v_monto: montoLocal,
          v_codigo_banco: cuentaDestino.r_nombre_banco,
          v_tipo_cuenta: cuentaDestino.r_tipo_cuenta,
          v_num_cuenta: cuentaDestino.r_numero_cuenta,
        };
      }

      setConfirmacionInfo(retiro);
      setShowModalInfo(!showModalInfo);
    }
  };

  const listTipoCuenta = () => {
    if (filtroTipoCuenta.length > 0) {
      return filtroTipoCuenta.map((item, index) => (
        <option key={index} value={JSON.stringify(item)}>
          {item.r_descripcion}
        </option>
      ));
    } else {
      return "";
    }
  };

  const enviarDataAlPadre = (codeToken) => {
    let montoARetirar = montoRetiro.replace("S/ ", "").replace(/,/g, "");
    let newCuenta = {};
    if (cuentaDestino === "nueva") {
      let cuenta = {
        r_nombre_banco: banco.r_codigo,
        r_tipo_cuenta: JSON.parse(tipoDeCuenta).r_codigo,
        r_numero_cuenta: nroDeCuenta,
        r_cuenta_remesa:
          Number(banco.r_codigo) === 7 ? obtenerCtaRemesa(nroDeCuenta) : "",
        r_codigo: "",
      };
      newCuenta = cuenta;
    } else {
      let cuentaSelect = {
        r_nombre_banco: "",
        r_tipo_cuenta: "",
        r_numero_cuenta: "",
        r_cuenta_remesa: "",
        r_codigo: cuentaDestino.r_codigo,
      };

      newCuenta = cuentaSelect;
    }
    let bodyFormData = new FormData();
    if (codeToken) {
      bodyFormData.set("v_token_digital", codeToken);
    }
    bodyFormData.set("v_monto", montoARetirar);
    bodyFormData.set("v_forpag", "A");
    bodyFormData.set("v_tip_pago", formaRetiro);
    bodyFormData.set("v_referencia", newCuenta.r_codigo);
    bodyFormData.set("v_tipo_banco", newCuenta.r_nombre_banco);
    bodyFormData.set("v_tipo_cuenta", newCuenta.r_tipo_cuenta);
    bodyFormData.set("v_nro_cuenta", newCuenta.r_numero_cuenta);
    bodyFormData.set("v_cuenta_remesa", newCuenta.r_cuenta_remesa);

    bodyFormData.set("v_adjunto", image);
    bodyFormData.append("v_adjunto_blob", imageFile);
    bodyFormData.append("v_adjunto_name", imageFile ? imageFile.name : null);
    bodyFormData.append("v_adjunto_type", imageFile ? imageFile.type : null);
    bodyFormData.append("v_adjunto_size", imageFile ? imageFile.size : null);

    for (var pair of bodyFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    // props.procesarSolicitud(bodyFormData);
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const resultFile = reader.result;
        setImage(resultFile);
        setImageFile(file);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const getNameBanco = (codigo) => {
    if (!isNaN(codigo)) {
      const filterBank = bancos.filter((item) => item.r_codigo === codigo);
      return filterBank[0].r_descripcion;
    }
    return codigo;
  };

  return (
    <Paper
      className={
        matches
          ? classes.paperContainerStylesChanges
          : classes.paperContainerStyles
      }
    >
      <Box sx={{ pt: 2, pb: 4 }}>
        <HorizontalStepper
          step={step}
          stepLength={stepLength}
          opcionSeleccionada={opcionSeleccionada}
          setOpcionSeleccionada={setOpcionSeleccionada}
        />
      </Box>
      {step === 0 && (
        <Box>
          <Box sx={{ p: matches ? 0 : 2 }}>
            <Typography className={classes.bodyContent}>
              Al día de hoy tienes:
            </Typography>
            <Box>
              <Box className={classes.boxAmount}>{`S/ ${montoFormat(
                montoTotal
              )}`}</Box>
            </Box>
          </Box>
          <Box sx={{ p: matches ? 0 : 2 }}>
            <Grid container>
              <Grid item xs={matches ? 12 : 6} sx={{ pt: 2 }}>
                <RadioButton
                  info={retiro}
                  selectOption={handleRadio}
                  matches={matches}
                />
              </Grid>
              <Grid
                item
                xs={matches ? 12 : 6}
                sx={{
                  pb: matches ? "4px" : "",
                  pr: matches ? "" : 2,
                  pt: 2,
                }}
              >
                <ThemeProvider theme={Theme}>
                  <FormControl fullWidth>
                    <TextField
                      label="Monto a retirar"
                      className={classes.textFieldFilledStyles}
                      type="text"
                      value={montoRetiro}
                      onChange={handleMonto}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    {formaRetiro === "T" ? (
                      <span style={{ fontSize: "12px", color: "#bf0d3e" }}>
                        Recuerda que el monto es referencial
                      </span>
                    ) : (
                      ""
                    )}
                    {errores.monto && formaRetiro === "P" && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.monto}
                      </span>
                    )}
                  </FormControl>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Box>
          <Box component="form">
            <>
              <Box sx={{ pb: 3 }}>
                <Cards
                  setShowEvidence={setOpenModalPreview}
                  cuentas={data.r_cuentas}
                  setCuenta={opcionSeleccionadaCard}
                />
                {errores.cuentaCard && (
                  <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                    {errores.cuentaCard}
                  </span>
                )}
              </Box>
            </>

            <Box>
              <Grid container spacing={2}>
                {mostrarFormularioCompleto && (
                  <>
                    <Grid item xs={matches ? 12 : 4}>
                      <SelectGeneral
                        info={{
                          labelText: "",
                          placeHolder: "Selecciona tu banco",
                          opciones: data.r_bancos,
                        }}
                        defaultSelect={"1"}
                        selectOption={handlerbanco}
                      />
                      {errores.banco && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.banco}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={matches ? 12 : 4}>
                      <ThemeProvider theme={Theme}>
                        <FormControl
                          variant="filled"
                          disabled={banco === "" ? true : false}
                          fullWidth
                        >
                          <InputLabel id="Type-account">
                            Selecciona tu tipo de cuenta
                          </InputLabel>

                          <Select
                            color="info"
                            sx={{
                              height: "55px",
                              borderRadius: "16px",
                              color: banco === "" ? "#CDCED0" : "",
                            }}
                            className={classes.selectStyles}
                            value={tipoDeCuenta}
                            onChange={handlerTipoCuenta}
                            disableUnderline
                          >
                            {listTipoCuenta()}
                          </Select>
                        </FormControl>

                        {errores.tipoCuenta && (
                          <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                            {errores.tipoCuenta}
                          </span>
                        )}
                      </ThemeProvider>
                    </Grid>

                    <Grid
                      item
                      xs={matches ? 12 : 4}
                      sx={{ pb: matches ? "4px" : "" }}
                    >
                      <ThemeProvider theme={Theme}>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.textFieldFilledStyles}
                            label="Número de cuenta"
                            disabled={longitudNroCuenta === 0 ? true : false}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            inputProps={{
                              maxLength: Number(longitudNroCuenta),
                            }}
                            variant="filled"
                            value={nroDeCuenta}
                            onChange={handlerNroCuenta}
                          />
                          {errores.nroCuenta && (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#bf0d3e",
                              }}
                            >
                              {errores.nroCuenta}
                            </span>
                          )}
                        </FormControl>
                      </ThemeProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography className={classes.bodyContent}>
                        Adjuntar evidencia bancaria:
                      </Typography>
                      <Typography
                        className={classes.bodyContent}
                        sx={{ cursor: "pointer", color: "#2F6D95" }}
                        onClick={() => setOpenModalPreview(!openModalPreview)}
                      >
                        ¿Qué es?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={Theme}>
                        <Box className={classes.evidenceContent}>
                          <Dropzone
                            onDrop={onDrop}
                            accept=".doc,.docx,.pdf,image/png,image/jpeg,image/jpg"
                            minSize={0}
                            maxSize={1.2e6}
                            multiple={false}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                              fileRejections,
                              acceptedFiles,
                            }) => {
                              const isFileTooLarge =
                                fileRejections.length > 0 &&
                                fileRejections[0].file.size > 1.2e6;
                              const isFileTypeError =
                                fileRejections.length > 0 &&
                                fileRejections[0].errors[0].code ===
                                  "file-invalid-type";

                              return (
                                <div {...getRootProps()} style={baseStyle}>
                                  <img
                                    src={IC_EVIDENCE_ACCOUNT}
                                    alt="IC_EVIDENCE_ACCOUNT"
                                  />
                                  {acceptedFiles.length === 0 ? (
                                    <>
                                      <input {...getInputProps()} />

                                      <p>
                                        {!isDragActive &&
                                          "Asegurarse de que la imagen sea nitida"}
                                        {isDragActive &&
                                          !isDragReject &&
                                          "¡Déjalo caer como si estuviera ardiendo!"}
                                      </p>

                                      <p>--------------- o -------------</p>

                                      <div className="#CE1336">
                                        Buscar Archivo
                                      </div>

                                      {isDragReject &&
                                        "Tipo de archivo no aceptado, lo siento."}
                                      {isFileTooLarge && (
                                        <div className="text-danger mt-2 error-size">
                                          El archivo es demasiado grande. Sube
                                          un archivo de menos de 1.2 MB
                                        </div>
                                      )}
                                      {isFileTypeError && (
                                        <div className="text-danger mt-2 error-size">
                                          El formato del archivo no es válido
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <ul className="list-group mt-2">
                                      <p>¡Sustento subido correctamente!</p>
                                      {acceptedFiles.length > 0 &&
                                        acceptedFiles.map((acceptedFile) => (
                                          <li className="list-group-item list-group-item-success">
                                            {acceptedFile.name}
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </div>
                              );
                            }}
                          </Dropzone>
                          {errores.image && (
                            <span
                              style={{ fontSize: "14px", color: "#bf0d3e" }}
                            >
                              {errores.image}
                            </span>
                          )}
                        </Box>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={matches ? 12 : 12}>
                      <ThemeProvider theme={Theme}>
                        <FormControlLabel
                          control={<Checkbox />}
                          className={classes.bodyContent}
                          label="He verificado que el número de cuenta de la entidad financiera que estoy registrando es correcto. AFP Habitat no será responsable en caso la cuenta esté errada."
                        />
                      </ThemeProvider>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: 1,
              pb: 4,
            }}
          >
            <Box sx={{ minWidth: matches ? "35%" : "20%", m: 1 }}>
              <Button
                onClick={procesarSolicitud}
                // onClick={() => setShowModalInfo(!showModalInfo)}
                variant="contained"
                disableElevation
                sx={{ width: "100%", borderRadius: "16px", height: "50px" }}
              >
                Confirmar
              </Button>
            </Box>
          </Box>{" "}
        </Box>
      )}

      {step === 1 && (
        <Box sx={{ p: matches ? 0 : 2 }}>
          {resultado && (
            <Box>
              <Box>
                {resultado.message ? (
                  <SolicitudNoProcesada
                    resetear={props.resetear}
                    error={resultado}
                  />
                ) : (
                  <SolicitudProcesada resultado={resultado} />
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}

      <Card>
        <CardBody className="p-0">
          <Form className="p-0">
            {/* <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="saldo" className="labelAmount retiro">
                    <span
                      className="tooltip-custom"
                      style={{ color: "#196691" }}
                    >
                      <i className="fa fa-info-circle" id="TooltipExample"></i>
                      <span className="tooltiptext">
                        Recuerda: El saldo disponible no incluye el cobro de
                        comisión ni el impuesto a las ganancias.
                      </span>
                    </span>
                  </Label>

                  <span className="legendTotal">
                    * El saldo mostrado puede variar en función al valor cuota
                    que se encuentre vigente al momento de procesar tu
                    solicitud.
                  </span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="switch-container">
                  <Label htmlFor="nombre" className="labelAmount">
                    Forma de retiro
                  </Label>
                  <div className="buttongroup">
                    <button
                      onClick={handleRadio("T")}
                      className={
                        formaRetiro === "T" ? "select-radio rbtn" : "rbtn"
                      }
                    >
                      Total
                    </button>
                    <button
                      onClick={handleRadio("P")}
                      className={
                        formaRetiro === "P" ? "select-radio rbtn" : "rbtn"
                      }
                    >
                      Parcial
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </Row> */}
          </Form>
        </CardBody>
      </Card>

      <CustomModal
        showModal={openModalPreview}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          handlePreviewClose();
        }}
        title="Modelo de evidencia"
        description={
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography>Recuerda</Typography>
              </Grid>
              <Grid item xs={12}>
                <ul style={{ textAlign: "justify", listStyle: "none" }}>
                  <li>
                    La cuenta debe estar a tu nombre, no puede ser mancomunada y
                    debe ser en soles.
                  </li>
                  <li>
                    La evidencia bancaria debe incluir los siguientes datos:
                    nombres, apellidos y número de cuenta completo, no es
                    necesaria la foto de tu tarjeta.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={IMG_EVIDENCE}
                  alt="IMG_EVIDENCE"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </>
        }
        hasBtnSecondary={FormatColorReset}
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          handlePreviewClose();
        }}
        textBtnPrimary="Entendido"
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(!openModalToken);
          }} // fn para cerrar modal
          guardarCode={enviarDataAlPadre}
        />
      )}
      <CustomModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de Fondo Libre Habitat"
        description={
          confirmacionInfo && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Box className={classes.boxDescriptionStyles}>
                    <Typography sx={{ pl: 2, pr: 2 }}>
                      ¡Hola!, Estás a punto de invertir en tu Fondo Libre
                      Habitat con la siguiente información:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.boxWarningStyles}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ErrorOutlineIcon
                          className={classes.iconStyles}
                          fontSize="large"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>
                          Recuerda que debes ser el titular de la cuenta o
                          tarjeta que hayas ingresado.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        id="outlined-read-only-input"
                        label="Entidad financiera"
                        defaultValue={getNameBanco(
                          confirmacionInfo.v_codigo_banco
                        )}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        id="outlined-read-only-input"
                        label="Tipo de cuenta"
                        defaultValue={confirmacionInfo.v_tipo_cuenta}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        id="outlined-read-only-input"
                        label="N.° de cuenta"
                        defaultValue={confirmacionInfo.v_num_cuenta}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        id="outlined-read-only-input"
                        label="Monto"
                        defaultValue={confirmacionInfo.v_monto}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        id="outlined-read-only-input"
                        label="Forma de retiro"
                        defaultValue={formaRetiro === "T" ? "Total" : "Parcial"}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setShowModalInfo(false);
          setOpenModalToken(true);
        }}
        textBtnPrimary="Confirmar"
      />
      {openModalTokenError && (
        <ModalExitFatca
          message={resultado.r_mensaje}
          cerrarModal={() => {
            setOpenModalTokenError(false);
          }} // fn para cerrar modal
          guardarCode={() => {
            setOpenModalTokenError(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </Paper>
  );
};

export default hocWidthWindow(PrimerPaso);
