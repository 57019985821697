import React, { useContext } from "react";
import { montoFormat } from "../../../helper/amountFormat";
import formatFondo from "../../../utils/formatFondo";
import maskBankAccount from "../../../utils/maskBankAccount";
import {
  DatosDeLaSolicitudComponent,
  Header,
  Divider,
  Section,
  InformationItem,
  BotonDesactivar,
} from "./styles";
import { useMonthlyDebitDetailsContext } from "../context";
import ModalDeConfirmacion from "../ModalDeConfirmacion";
import ImpossibleDeactivationModal from "../ImpossibleDeactivationModal";
import ModalToken from "../../../components/ModalToken";
import debitDateOptions from "../../../utils/debitDateOptions";
import {
  DEACTIVATE_MONTHLY_DEBIT_SUCCESS,
  DEACTIVATE_MONTHLY_DEBIT_FAIL,
} from "../context/actions";

export const DatosDeLaSolicitud = () => {
  const {
    state: { monthlyDebitDetails, tokenModal },
    ...api
  } = useMonthlyDebitDetailsContext();

  const {
    monto,
    fecha_aporte,
    cargos_dobles,
    fondo,
    banco,
    tipo_cuenta,
    nro_cuenta,
    v_fecha_cargo,
    fecha_cargo,
    anulable,
  } = monthlyDebitDetails.data;

  const getTypeOfAccount = {
    AHO: "Cuenta de ahorros",
    CHK: "Cuenta corriente",
    TCR: "Tarjeta de crédito",
  };

  const typeOfAccountFormatted = getTypeOfAccount[tipo_cuenta] || tipo_cuenta;

  const procesarSolicitud = async (token) => {
    const response = await api.deactivateMonthlyDebit(token);

    api.closeTokenModal();

    if (response === DEACTIVATE_MONTHLY_DEBIT_SUCCESS) {
      return api.changeCurrentSection("deactivated");
    }

    if (response === DEACTIVATE_MONTHLY_DEBIT_FAIL) {
      return api.openDeactivationErrorModal();
    }
  };

  const onClickDeactivate = () => {
    if (anulable) {
      return api.openConfirmationModal();
    }

    return api.openImpossibleDeactivationModal();
  };

  return (
    <DatosDeLaSolicitudComponent>
      <Header>
        <div className="title">
          <p>Débito mensual</p>
          <span className="status">Activo</span>
        </div>
        <p className="subtitle">
          Es una herramienta que te permite invertir de forma automática en tu
          Fondo Libre Habitat cada mes, configurando el monto y día de débito.
        </p>
      </Header>
      <Divider />
      <Section>
        {[
          { label: "Monto de aporte", value: montoFormat(monto, true) },
          {
            label: "Fecha de débito mensual",
            value: `${v_fecha_cargo}`,
          },
          {
            label: "Cargos dobles en julio y diciembre ",
            value: cargos_dobles ? "Activo" : "Inactivo",
          },
          { label: "Fondo de inversión", value: formatFondo(fondo) },
          { label: "Entidad financiera", value: banco },
          { label: "Tipo de cuenta", value: typeOfAccountFormatted },
          { label: "N.° de cuenta", value: maskBankAccount(nro_cuenta) },
        ].map(({ label, value }, index) => (
          <InformationItem key={index}>
            <p className="label">{label}</p>
            <p className="value">{value}</p>
          </InformationItem>
        ))}
      </Section>
      <BotonDesactivar onClick={onClickDeactivate}>Desactivar</BotonDesactivar>
      <ModalDeConfirmacion />
      <ImpossibleDeactivationModal />
      {tokenModal && (
        <ModalToken
          showModal={tokenModal}
          cerrarModal={api.closeTokenModal}
          guardarCode={procesarSolicitud}
        />
      )}
    </DatosDeLaSolicitudComponent>
  );
};

export default DatosDeLaSolicitud;
