import React from "react";
import Modal from "../../../../../../../components/BasicModal";
import Button from "../../../../../../../components/Buttons";
import * as Input from "../../../../../../../components/Input";
import { text } from "@mui/icons-material";
import DocumentItem from "./DocumentItem";

const BirthCertificateModal = ({ open, setOpen, data }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Partida de nacimiento</Modal.Title>
      <p style={{ fontWeight: "bold" }}>Que debes adjuntar</p>
      <DocumentItem>
        Copia Simple de la Partida de Nacimiento emitida por RENIEC (ambas
        caras) o Copia Certificada emitida por la Municipalidad.
      </DocumentItem>
      <Button
        fullWidth
        onClick={onClose}
        style={{ margin: "auto", marginTop: "16px" }}
      >
        Entiendo
      </Button>
    </Modal>
  );
};

export default BirthCertificateModal;
