import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 92 32">
      <g clipPath="url(#clip0_3877_6176)">
        <path
          fill="#004192"
          d="M31.654 15.488c2.223.758 3.484 2.07 3.644 4.344.18 2.52-.88 4.405-2.863 5.799-1.141.82-2.443 1.311-3.804 1.557a21.993 21.993 0 01-7.769.082c-2.602-.43-3.744-2.049-3.183-4.713 1.161-5.491 2.402-10.983 3.624-16.454.1-.43.22-.758.76-.8 2.924-.266 5.827-.716 8.77-.204 1.082.184 2.123.45 3.084.943 3.323 1.68 3.664 5.737.72 8.011-.84.636-1.781 1.045-2.983 1.435zm-7.648 2.09c-.38 1.783-.741 3.422-1.101 5.04-.14.616-.06 1.107.66 1.148 1.682.082 3.364 0 4.866-.901 1.141-.677 1.621-1.742 1.461-3.095-.16-1.29-1.06-1.803-2.162-2.151-1.221-.369-2.403.246-3.724-.041zm3.704-8.955c-.52.349-1.502-.491-1.822.615-.34 1.23-.58 2.5-.861 3.77-.1.43.04.82.5.84 1.802.103 3.604.123 5.066-1.188 1.422-1.27.981-3.237-.82-3.832-.642-.184-1.302-.184-2.063-.205zm47.412.308c-1.021-2.869-3.604-3.545-6.247-3.812-2.442-.245-4.905-.123-7.348.164-.84.103-1.261.43-1.462 1.332-1.441 6.721-2.943 13.442-4.404 20.163-.06.246-.18.513.14.82h1.922c2.443 0 3.163-.594 3.704-3.012.32-1.434.64-2.89.941-4.283.5-.184.901-.04 1.301-.02 3.524.123 6.868-.472 9.45-3.176 2.203-2.295 3.124-5.082 2.003-8.176zm-4.405 3.586c-.48 2.336-2.442 3.79-5.145 3.811-1.762 0-1.923-.205-1.542-2.029.32-1.495.66-2.97.92-4.467.121-.737.481-1.024 1.182-1.024.741-.02 1.482-.062 2.223.082 1.922.369 2.763 1.66 2.362 3.627zM55.36 5.509c-4.625-1.721-9.07-1.414-12.894 1.967-3.664 3.237-4.825 7.54-4.365 12.377.26 2.766 1.402 5.081 3.825 6.495 3.083 1.803 6.387 1.865 9.71.86.741-.225 1.302-.757 1.502-1.557.3-1.209.56-2.418.86-3.79-1.04.573-1.881 1.106-2.842 1.413-3.024.963-6.047-.225-7.108-2.807-1.462-3.565.62-9.18 4.084-10.82 2.843-1.331 5.587-.737 8.33.82.16-.614.32-1.167.46-1.72.4-1.701.06-2.624-1.562-3.238z"
        ></path>
        <path
          fill="#FF7D34"
          d="M8.91 7.189a16.306 16.306 0 016.506 6.065c.982 1.62.861 3.402-.24 4.96-2.262 3.237-5.266 5.614-8.75 7.253C4.085 26.573 1.502 25.283.5 22.68c1.402-.758 2.803-1.537 4.084-2.5 1.923-1.434 3.584-3.094 4.405-5.45 1.122-2.52 1.222-5.041-.08-7.541zm75.082 0c2.723 1.393 4.905 3.463 6.527 6.065.981 1.578.9 3.32-.16 4.857-2.283 3.32-5.346 5.737-8.91 7.397-2.323 1.065-4.845-.246-5.826-2.787 1.321-.738 2.662-1.475 3.884-2.397 1.982-1.476 3.744-3.156 4.585-5.594 1.141-2.541 1.221-5.062-.1-7.541z"
        ></path>
        <path
          fill="#004192"
          d="M8.91 7.189c1.3 2.5 1.18 5 .06 7.52-1.703-1.885-3.825-3.176-6.067-4.2 1.18-3.259 3.864-4.693 6.006-3.32zm75.082 0c1.321 2.5 1.241 5 .08 7.54-1.682-1.885-3.784-3.155-6.147-4.241.78-1.803 1.902-3.115 3.664-3.791.901-.369 1.682-.103 2.403.492z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3877_6176">
          <path fill="#fff" d="M0 0H91V32H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
