import React, { useState, useEffect, useContext } from "react";
import ReactGa from "react-ga";
import { Card } from "reactstrap";
import AuthContext from "../../context/autenticacion/authContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import ModalContext from "../../context/modal/modalContext";
import Retiro25Context from "../../context/retiro25/retiro25Context";
import Loading from "../../components/Loading/index.jsx";
import Grid from "@mui/material/Grid";
import CustomModal from "../../components/custromModal/CustomModal";

import ModalActualizarCelular from "../../components/ModalActualizarCelular";
import Texto from "./Components/Texto";
import { Page, Button, CardContainer } from "../../components";

const Retiro25 = (props) => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misDatosContext = useContext(MisDatosContext);
  const retiro25Context = useContext(Retiro25Context);

  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    obtenerMisDatosGet,
    limpiarVariablesMisDatosParcial,
  } = misDatosContext;

  const { loadingBtnRetiro25Post, retiro25Post } = retiro25Context;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [confirmacionInfo, setConfirmacionInfo] = useState(null);
  const [modalExitoso, setModalExitoso] = useState(null);
  const [modalCelular, setModalCelular] = useState(null);
  const [modalAlerta, setModalAlerta] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
  const [modalActualizarDatos, setModalActualizarDatos] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaDatosGet) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerMisDatosGet();
    }

    return () => {
      limpiarVariablesMisDatosParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlertaDatosGet]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlertaDatosGet);
  };

  if (mensajeAlertaDatosGet !== null) return <p></p>;
  if (misDatosGet === null) return <Loading />;

  const closeBtn = () => {
    setConfirmacionInfo(null);
  };

  const openModalExitoso = () => {
    setModalExitoso(true);
  };

  const closeModalCelular = () => {
    setModalCelular(null);
  };

  const procesarJubilacion = (dato) => () => {
    setModalAlerta(null);
    if (!misDatosGet.r_persona.r_datos.r_celular) {
      setModalCelular(true);
    } else {
      setModalActualizarDatos(true);
    }
    setConfirmacionInfo(dato);
  };
  const closeModalAlerta = () => {};
  const closeModalAlertaX = () => () => {
    setModalAlerta(null);
  };
  const procesarSolicitud = () => {
    retiro25Post(confirmacionInfo);
    closeBtn();
    openModalExitoso();
    setModalActualizarDatos(false);
  };
  return (
    <Page title="Retiro del 25% para tu primera vivienda" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <CardContainer>
            <Card>
              <p className="text-justify">
                Si tienes planificado adquirir tu primer inmueble o amortizar el
                crédito hipotecario del mismo, puedes disponer de hasta un 25%
                de tu fondo de jubilación, ya sea que esté a tu nombre, bajo
                sociedad de gananciales o seas copropietario del 50% o más.
              </p>
            </Card>
            <section className="d-flex justify-content-around">
              <Button onClick={procesarJubilacion(9)}>Iniciar</Button>
            </section>
          </CardContainer>
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <InfoUsuario />
        </Grid> */}
      </Grid>

      <CustomModal
        showModal={modalAlerta}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalAlerta(!modalAlerta);
        }}
        title="Recuerda que..."
        description={
          <>
            {" "}
            <Texto opcion={opcionSeleccionada} />
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          closeModalAlerta();
        }}
        textBtnPrimary="Aceptar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          closeModalAlertaX();
        }}
        textBtnSecondary="Cancelar"
      />
      {modalCelular && (
        <ModalActualizarCelular closeModal={closeModalCelular} />
      )}
      <CustomModal
        showModal={modalActualizarDatos}
        className="beauty-modal"
        toggle={() => {
          setModalActualizarDatos(!modalActualizarDatos);
        }}
        fnCloseIconBtn={() => {
          setModalActualizarDatos(!modalActualizarDatos);
        }}
        title="Confirma tus datos de contacto"
        description={
          <>
            <div className="user-data">
              <div className="field mb-2">
                <span className="label">Correo personal:</span>
                <span className="data">
                  {misDatosGet.r_persona.r_datos.r_correo
                    ? misDatosGet.r_persona.r_datos.r_correo
                    : "No registrado"}
                </span>
              </div>

              <div className="field">
                <span className="label">Celular:</span>
                <span className="data">
                  {misDatosGet.r_persona.r_datos.r_celular
                    ? misDatosGet.r_persona.r_datos.r_celular
                    : "No registrado"}
                </span>
              </div>
            </div>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          procesarSolicitud();
        }}
        textBtnPrimary="Confirmar datos"
        btnPrimaryLoading={loadingBtnRetiro25Post}
        hasBtnSecondary={true}
        fnBtnSecondary={() =>
          props.history.push(
            "/mi-habitat-digital/afiliado/informacion/datos-personales"
          )
        }
        textBtnSecondary="Actualizar datos"
        disableBtnSecondary={loadingBtnRetiro25Post}
      />
      <CustomModal
        showModal={modalExitoso}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalExitoso(!modalExitoso);
        }}
        title="Solicitud Registrada"
        description={
          <>
            <p>
              {sessionStorage.getItem("nombreUsuario")}, te hemos enviado a tu
              correo registrado la información, requisitos, procedimiento y
              documentos necesarios para que puedas iniciar el trámite. Por
              favor revisa tu bandeja de correos no deseados o spam. Gracias.
            </p>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setModalExitoso(false);
        }}
        textBtnPrimary="Cerrar"
      />
    </Page>
  );
};

export default Retiro25;
