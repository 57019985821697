import { clienteZonaBeneficiarios } from "../../../config/axios";
import * as actions from "./actions";

export const getRequestInfo =
  (dispatch, setGeneralError) => async (payload) => {
    try {
      dispatch({ type: actions.GET_REQUEST_INFO });

      const { data } = await clienteZonaBeneficiarios.post(
        "/zona-beneficiarios/buscar-solicitud",
        {
          tramite: payload["tipo-tramite"],
          solicitud: payload["numero-tramite"],
          doc_type: payload["tipo-documento"],
          doc_num: payload["valor-documento"],
        }
      );

      dispatch({ type: actions.GET_REQUEST_INFO_SUCCESS, payload: data.data });
      return actions.GET_REQUEST_INFO_SUCCESS;
    } catch (error) {
      const status = error?.response?.status;

      if (status >= 500) {
        setGeneralError();
      }

      dispatch({ type: actions.GET_REQUEST_INFO_FAILURE, payload: error });

      return actions.GET_REQUEST_INFO_FAILURE;
    }
  };

export const resetRequestInfo = (dispatch) => () => {
  dispatch({ type: actions.RESET_REQUEST_INFO_INFO });
};
