import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ReactGa from "react-ga";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import Documento from "./Documento";
import Loading from "../../components/Loading/index.jsx";
import AuthContext from "../../context/autenticacion/authContext";
import DrsppContext from "../../context/drspp/drsppContext";
import ModalContext from "../../context/modal/modalContext";
import { CardContainer, Page, NotFoundData } from "../../components";
import "./index.css";

const Drspp = (props) => {
  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const drsppoContext = useContext(DrsppContext);
  const {
    datosDrspp,
    mensajeAlerta,
    obtenerDatosDrspp,
    limpiarVariablesDrsppParcial,
  } = drsppoContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosDrspp();
    }

    return () => {
      limpiarVariablesDrsppParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };
  if (mensajeAlerta !== null) return <p></p>;
  if (datosDrspp === null) return <Loading />;
  return (
    <Page title="Certificado de afiliación" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          {datosDrspp.r_respuesta === 1 && (
            <CardContainer>
              <p className="text-drsp">
                Este documento certifica tu afiliación al Sistema Privado de
                Pensiones en AFP Habitat.
              </p>
              <PDFDownloadLink
                className="btn btn-danger custon-btn-donwload"
                document={<Documento datos={datosDrspp} />}
                fileName="documentoderegistro.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Cargando..."
                  ) : (
                    <span>
                      Descargar{" "}
                      {props.windowWidth > 600 && (
                        <i className="fa fa-download" aria-hidden="true"></i>
                      )}
                    </span>
                  )
                }
              </PDFDownloadLink>
            </CardContainer>
          )}

          {datosDrspp.r_respuesta === 1 ? (
            <>
              {props.windowWidth > 600 && (
                <section className="contenedor-over">
                  <PDFViewer width="100%" height="100%">
                    <Documento datos={datosDrspp} />
                  </PDFViewer>
                </section>
              )}
            </>
          ) : (
            <CardContainer>
              <NotFoundData
                mensaje={
                  "Este documento no se puede generar para afiliados que se han traspasado de otra AFP."
                }
                btnText={"Volver al inicio"}
                url={"/mi-habitat-digital/afiliado/inicio"}
                icon={true}
              />
            </CardContainer>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default hocWidthWindow(Drspp);
