import styled, { css } from "styled-components";
import { inputStyles, inputIcon } from "../styles";
import "react-datepicker/dist/react-datepicker.css";

export const DateInputComponent = styled.div`
  position: relative;

  .react-datepicker__input-container {
    input {
      ${inputStyles}
    }
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  .react-datepicker {
    border: none;
    box-shadow: ${(p) => p.theme.elevations.below};
    padding: 16px;
    font-family: "Roboto";

    .react-datepicker__header {
      background-color: ${(p) => p.theme.palette.white};
      border-bottom: none;
      .react-datepicker__day-names {
        margin-top: 16px;
        margin-bottom: 4px;

        .react-datepicker__day-name {
          color: ${(p) => p.theme.palette.grayscale[400]};
          ${(p) => p.theme.tipography.caption}
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__header__dropdown {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        justify-items: start;
        padding: 0 16px;

        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
          width: 100%;
          position: relative;
          text-align: left;

          .react-datepicker__year-dropdown--scrollable {
            height: 240px;
          }
        }

        .react-datepicker__month-read-view--selected-month,
        .react-datepicker__year-read-view--selected-year {
          font-weight: 700;
          color: ${(p) => p.theme.palette.grayscale[800]};
          text-transform: capitalize;
        }

        .react-datepicker__month-dropdown,
        .react-datepicker__year-dropdown {
          min-width: 100%;
          background-color: ${(p) => p.theme.palette.white};
          border: none;
          box-shadow: ${(p) => p.theme.elevations.below};
          left: 0;
          top: 0px;

          .react-datepicker__month-option,
          .react-datepicker__year-option {
            text-transform: capitalize;

            &:hover {
              background-color: ${(p) => p.theme.palette.grayscale[50]};
            }

            .react-datepicker__month-option--selected {
              left: 4px;
            }
          }
        }

        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
          position: absolute;
          right: 0;
          font-size: ${(p) => p.theme.tipography.caption};
          border-width: 2px 2px 0 0;
          border-color: ${(p) => p.theme.palette.grayscale[400]};
        }
      }
    }
    /* react-datepicker__header__dropdown--scroll */
    .react-datepicker__navigation {
      display: none;
    }

    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__day {
      outline: none;
      color: ${(p) => p.theme.palette.grayscale[800]};

      &:hover {
        background-color: ${(p) => p.theme.palette.grayscale[50]};
      }

      &.react-datepicker__day--today {
        font-weight: 400;
      }

      &.react-datepicker__day--selected {
        background-color: #ffebef;
        color: ${(p) => p.theme.palette.primary.main};
        font-weight: 500;
      }

      &.react-datepicker__day--keyboard-selected {
        background-color: ${(p) => p.theme.palette.white};
      }

      &.react-datepicker__day--outside-month {
        visibility: hidden;
      }

      &.react-datepicker__day--disabled {
        color: ${(p) => p.theme.palette.grayscale[100]};
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export const Icon = styled.div`
  ${inputIcon}
`;
