import { GET_ASESOR_EXITOSO, SET_CLICK_ASESOR } from "../../types/asesor";

function capitalizarPrimeraLetra(palabra) {
  return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
}

export default (state, action) => {
  switch (action.type) {
    case GET_ASESOR_EXITOSO:
      if (action.payload.r_validacion == 1) {
        var ejecutivo = action.payload.r_datos_ejecutivo;
        var celular = ejecutivo.r_celular === null ? "" : ejecutivo.r_celular;
        celular = celular.split("");
        celular =
          celular[0] +
          celular[1] +
          celular[2] +
          " " +
          celular[3] +
          celular[4] +
          celular[5] +
          " " +
          celular[6] +
          celular[7] +
          celular[8];
        ejecutivo.r_celularshow = celular;
        var primerNombre = ejecutivo.r_name.split(" ");
        ejecutivo.r_nombreAbreviado =
          capitalizarPrimeraLetra(primerNombre[0]) +
          " " +
          capitalizarPrimeraLetra(ejecutivo.r_firstname);
        return {
          ...state,
          encontroAsesor: true,
          ejecutivo: ejecutivo,
        };
      } else {
        return {
          ...state,
          encontroAsesor: false,
        };
      }

    // return {
    //     ...state,
    //     mensajeAlerta: action.payload,
    // };
    case SET_CLICK_ASESOR:
      return {
        ...state,
        clickAsesor: !state.clickAsesor,
      };
    default:
      break;
  }
};
