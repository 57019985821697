import React from "react";

function DefaulBancoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M28 13v-1a2 2 0 002-2V6.8a1.99 1.99 0 00-1.551-1.948l-12-2.769a2.006 2.006 0 00-.9 0l-12 2.77A1.99 1.99 0 002 6.8V10a2 2 0 002 2v1a1.993 1.993 0 001 1.722V22H4a2 2 0 00-2 2v2a2 2 0 002 2h24a2 2 0 002-2v-2a2 2 0 00-2-2h-1v-7.278A1.993 1.993 0 0028 13zm-2 0h-3v-1h3zm-16 1.722A1.993 1.993 0 0011 13v-1h1.5v1a1.993 1.993 0 001 1.722V22H10zM17.5 13h-3v-1h3zm-2 2h1v7h-1zm3-.278a1.993 1.993 0 001-1.722v-1H21v1a1.993 1.993 0 001 1.722V22h-3.5zM4 6.8l12-2.77L28 6.8V10H4zM9 12v1H6v-1zm-2 3h1v7H7zm21 9v2H4v-2zm-4-2v-7h1v7z"></path>
    </svg>
  );
}

export default DefaulBancoLogo;
