import styled, { css } from "styled-components";
import { inputStyles, textInputWrapper, inputIcon } from "../styles";

export const InputWrapper = styled.div`
  ${textInputWrapper}
`;

export const TextInputComponent = styled.textarea`
  ${inputStyles}

  resize: none;
  font-family: inherit;
  padding: 16px;
  min-height: 116px;
  ${(p) =>
    p.$icon &&
    css`
      padding-right: 32px;
    `};
`;

export const Icon = styled.div`
  ${inputIcon}
`;

export const Counter = styled.p`
  ${(p) => p.theme.tipography.caption}
  color : ${(p) => p.theme.palette.grayscale[300]}
`;
