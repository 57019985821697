import React, { useState, useContext, useEffect } from "react";
import "../CambioFondo/index.css";
import "./index.css";
import ReactGa from "react-ga";
import AuthContext from "../../context/autenticacion/authContext";
import RetiroapvsinfinContext from "../../context/retiroapvsinfin/retiroapvsinfinContext";
import ModalContext from "../../context/modal/modalContext";
import HomeContext from "../../context/home/homeContext";
import CodeContext from "../../context/code/codeContext";
import MensajeInformativo from "./MensajeInformativo";

import Loading from "../../components/Loading/index.jsx";
import ConCuenta from "./ConCuenta";
import SinCuenta from "./SinCuenta";
import { Page } from "../../components";
import Grid from "@mui/material/Grid";
import moment from "moment";

const RetiroApv = () => {
  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer, birthday } = authContext;

  const retiroapvsinfinContext = useContext(RetiroapvsinfinContext);
  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");
  const {
    datosRetiroApvSinFin,
    mensajeInformativo,
    conTokenDigital,
    mensajeAlerta,
    obtenerDatosRetiroApvSinFin,
    limpiarVariablesRetiroApvSinFinParcial,
    resetear,
    retirarApvSinFin,
    datosResultadoRetiroApvSinFin,
  } = retiroapvsinfinContext;
  const homeContext = useContext(HomeContext);
  const { fondos } = homeContext;
  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const codeContext = useContext(CodeContext);
  const { limpiarVariablesCode } = codeContext;

  const [montoApvSinFin, setMontoApvSinFin] = useState(null);

  const evaluarOpcionRetiroAPV = (fondos) => {
    let fondosStorage = JSON.parse(sessionStorage.getItem("fondos"));

    if (fondosStorage === null && fondos.length === 0) {
      setMontoApvSinFin(null);
    } else if (fondosStorage !== null) {
      const encontrarAPVSF = fondosStorage.find(
        (item) => item.r_cuenta === "VSF"
      );
      if (encontrarAPVSF) {
        if (parseFloat(encontrarAPVSF.r_saldo) > 0) {
          setMontoApvSinFin(encontrarAPVSF.r_saldo);
        } else {
          setMontoApvSinFin(null);
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (datosResultadoRetiroApvSinFin) {
      if (!datosResultadoRetiroApvSinFin.message) {
        loadNPS();
      }
    }
  }, [datosResultadoRetiroApvSinFin]);

  const loadNPS = () => {
    const filterAge =
      age < 25
        ? "Menores de 25"
        : age >= 25 && age < 35
        ? "25 A 34"
        : age >= 35 && age < 51
        ? "35 a 51"
        : age >= 51 && age < 65
        ? "51 a 65"
        : "Mayores de 65";

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: "Retiro de fondo Libre",
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", { detail: { id: 814 } })
    );
  };

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosRetiroApvSinFin();
      evaluarOpcionRetiroAPV(fondos);
    }

    return () => {
      limpiarVariablesRetiroApvSinFinParcial();
      limpiarVariablesCode();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta, fondos]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const procesarSolicitud = (data) => {
    retirarApvSinFin(data);
  };
  const cuentas = datosRetiroApvSinFin
    ? datosRetiroApvSinFin.r_cuentas
      ? datosRetiroApvSinFin.r_cuentas
      : []
    : [];
  if (mensajeAlerta !== null) return <p></p>;
  if (datosRetiroApvSinFin === null) return <Loading />;
  return (
    <Page
      title="Retiro de Fondo Libre Habitat"
      showHeader={"mobile"}
      identificador={"retiro"}
      sx={{ userSelect: "none" }}
    >
      {
        Number(datosRetiroApvSinFin.r_validacion) === 0 ||
        Number(datosRetiroApvSinFin.r_validacion) === 9 ? ( //9 es cuando se realizo 
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <MensajeInformativo mensaje={datosRetiroApvSinFin.r_mensaje} />
            </Grid>
          </Grid>
        ) : (
          <SinCuenta
            datosRetiroApvSinFin={datosRetiroApvSinFin}
            cuentas={cuentas}
            montoApvSinFin={montoApvSinFin}
            procesarSolicitud={procesarSolicitud}
            conTokenDigital={conTokenDigital}
            resetear={resetear}
            datosResultadoRetiroApvSinFin={datosResultadoRetiroApvSinFin}
            mensajeInformativo={datosRetiroApvSinFin}
          />
        )
        //   : (
        // <ConCuenta
        //   datosRetiroApvSinFin={cuentas}
        //   conTokenDigital={conTokenDigital}
        //   montoApvSinFin={montoApvSinFin}
        //   procesarSolicitud={procesarSolicitud}
        //   datosResultadoRetiroApvSinFin={datosResultadoRetiroApvSinFin}
        //   resetear={resetear}
        //   mensajeInformativo={mensajeInformativo}
        // />
        // )
      }
    </Page>
  );
};

export default RetiroApv;
