import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  IconButton,
  Chip,
  AppBar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ChartIcon from "../../../assets/IconBucket/Icon/ChartIcon";
/* material icon */
import CoinsIcon from "../../../assets/IconBucket/Icon/CoinsIcon";
import SavingIcon from "../../../assets/IconBucket/Icon/SavingIcon";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";

const drawerBleeding = 60;

function SwipeableEdgeDrawer(props) {
  const {
    option,
    fondoActual,
    fondoDestino,
    step,
    etario,
    setStep,
    info,
    setFondoFinal,
    setFondoActual,
    setFondoDestino,
    setActiveButton,
    changeSwipeableState,
    setChangeSwipeableState,
    setOpen,
  } = props;

  const [state, setState] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    if (newOpen === "arrowDownIcon") setChangeSwipeableState(true);
    if (newOpen === "arrowUpIcon") setChangeSwipeableState(false);
    if (newOpen === "SeleccionarFondo") {
      setFondoFinal({ ...fondoActual, r_fondo_destino: `${fondoDestino}` });
      //setChangeSwipeableState(false);
      setOpen(true);
    }
    if (newOpen === "closeSwipe") setOpen(false);
  };

  const filterFund = info.filter((item) => item.r_tipo_aporte !== "VSF");
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
            position: "absolute",
          },
        }}
      />

      <SwipeableDrawer
        PaperProps={{ style: { height: "40vh" } }}
        anchor="bottom"
        open={false}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={50}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            fontSize: "10px",
            position: "absolute",
            top: !changeSwipeableState ? -240 : -110,
            height: 247,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            backgroundColor: "#fff",
            pointerEvents: "all",
            borderTop: "1.5px solid #CDCED0",
            borderRadius: "16px",
          }}
        >
          <Grid container sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "450px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid container sxpacing={2}>
                    <Grid item xs={10}>
                      <Typography sx={{ fontWeight: "bold", pt: 1 }}>
                        Detalles de operación
                      </Typography>
                    </Grid>
                    {false ? (
                      <>
                        <Grid item xs={2}>
                          <IconButton
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                            onClick={toggleDrawer("closeSwipe")}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItem: "center",
                            }}
                          >
                            {!changeSwipeableState && (
                              <IconButton
                                onClick={toggleDrawer("arrowDownIcon")}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            )}

                            {changeSwipeableState && (
                              <IconButton onClick={toggleDrawer("arrowUpIcon")}>
                                <KeyboardArrowUpIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: !changeSwipeableState ? "0px" : "12px",
              }}
            >
              {!changeSwipeableState && !etario && (
                <Box
                  sx={{
                    borderRadius: "16px",
                    padding: "8px 16px",
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {fondoActual.r_aporte === "APORTE VOLUNTARIO CON FIN" && (
                      <CoinsIcon size={22} color="black" />
                    )}
                    {fondoActual.r_aporte === "APORTE OBLIGATORIO" && (
                      <SavingIcon size={22} color="black" />
                    )}
                    {fondoActual.r_aporte === "APORTE VOLUNTARIO SIN FIN" && (
                      <ChartIcon size={22} lineColor="#000" />
                    )}
                    &nbsp;
                    <Typography
                      color="textColor.main"
                      sx={{ fontWeight: 500, fontSize: "16px" }}
                    >
                      {fondoActual.r_aporte === "APORTE OBLIGATORIO" &&
                        "Fondo de Jubilación"}
                      {fondoActual.r_aporte === "APORTE VOLUNTARIO CON FIN" &&
                        "Fondo con Fin Previsional"}
                      {fondoActual.r_aporte === "APORTE VOLUNTARIO SIN FIN" &&
                        "Fondo Libre Habitat"}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "800px",
                  display: "flex",
                  justifyContent: "center",

                  borderTop: changeSwipeableState && "1px solid #EDEDED",
                }}
              >
                {!changeSwipeableState && !etario && (
                  <Box
                    sx={{
                      p: 2,
                      minHeight: "5px",
                      display: "flex",
                      justifyContent: fondoDestino ? "space-evenly" : "center",
                      alignItem: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <Chip
                        label="FONDO ACTUAL"
                        size="small"
                        sx={{ p: "4px 10px", fontSize: "1em" }}
                      />
                      <Typography sx={{ fontSize: "1.2em", paddingTop: "8px" }}>
                        &nbsp; Fondo {fondoActual.r_fondo_origen}
                      </Typography>
                      <Typography sx={{ fontSize: "1.3em" }}>
                        &nbsp;
                        {fondoActual.r_fondo_origen === "1" && "Conservador"}
                        {fondoActual.r_fondo_origen === "2" && "Moderado"}
                        {fondoActual.r_fondo_origen === "3" && "Arriesgado"}
                      </Typography>
                    </Box>
                    {fondoDestino !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#CDCED0",
                          width: "80px",
                        }}
                      >
                        <ArrowForwardIcon />
                      </Box>
                    )}
                    {fondoDestino !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <Chip
                          label="FONDO DESTINO"
                          size="small"
                          sx={{
                            p: "4px 10px",
                            fontSize: "1em",
                            backgroundColor: "#CE1336",
                            color: "white",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "1.2em", paddingTop: "8px" }}
                        >
                          &nbsp; Fondo {fondoDestino}
                        </Typography>
                        <Typography sx={{ fontSize: "1.3em" }}>
                          &nbsp;
                          {fondoDestino === 0 && "Protegido"}
                          {fondoDestino === 1 && "Conservador"}
                          {fondoDestino === 2 && "Moderado"}
                          {fondoDestino === 3 && "Arriesgado"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {!changeSwipeableState && etario && (
                  <Box
                    sx={{
                      p: 2,
                      minHeight: "5px",
                      display: "flex",
                      justifyContent: fondoDestino ? "space-evenly" : "center",
                      alignItem: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "16px",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "150px",
                      }}
                    >
                      {filterFund.map((item) => {
                        return (
                          <Box sx={{ display: "flex" }}>
                            <SavingIcon size={24} color="black" />
                            &nbsp;
                            <Typography
                              color="textColor.main"
                              sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                marginLeft: "8px",
                              }}
                            >
                              {item.r_aporte === "APORTE OBLIGATORIO" &&
                                "Fondo de Jubilación"}
                              {item.r_aporte === "APORTE VOLUNTARIO CON FIN" &&
                                "Fondo con Fin Previsional"}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#CDCED0",
                        width: "80px",
                      }}
                    >
                      <ArrowForwardIcon />
                    </Box>

                    {fondoDestino !== "" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <Chip
                          label="FONDO DESTINO"
                          size="small"
                          sx={{
                            p: "4px 10px",
                            fontSize: "1em",
                            backgroundColor: "#CE1336",
                            color: "white",
                          }}
                        />
                        <Typography
                          sx={{ fontSize: "1.2em", paddingTop: "8px" }}
                        >
                          &nbsp; Fondo {fondoDestino}
                        </Typography>
                        <Typography sx={{ fontSize: "1.3em" }}>
                          &nbsp;
                          {fondoDestino === 0 && "Protegido"}
                          {fondoDestino === 1 && "Conservador"}
                          {fondoDestino === 2 && "Moderado"}
                          {fondoDestino === 3 && "Arriesgado"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%", pl: 1 }}>
                  <Button
                    disabled={fondoDestino === "" ? true : false}
                    variant="contained"
                    sx={{ borderRadius: "16px", width: "100%" }}
                    onClick={toggleDrawer("SeleccionarFondo")}
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
