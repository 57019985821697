import React, { useEffect } from "react";
import "./customModal.css";
import "./beautyModal.css";

import { Box, Dialog, Divider, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useMediaQuery, useTheme, Grid, Button } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { makeStyles } from "@mui/styles";
import CallIcon from "../../../../assets/icons/ico_call.svg";
import WtspIcon from "../../../../assets/icons/ico_wtsp.svg";
import MailIcon from "../../../../assets/icons/ico_mail.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      maxWidth: "388px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
    backgroundColor: "#AF1733",
  },
});

const styles = {
  titleModal: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeigt: "28px",
    textAlign: "center",
    marginTop: "20px",
  },
  ContentModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
  },
  ContentAdviceModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    color: "black",
  },
  ContentMessageModal: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeigt: "normal",
    color: "#E11900",
  },
  SecondaryColor: {
    color: "#2F6D95",
  },
  BoxContact: {
    display: "flex",
    width: "100%",
    height: "40px",
    borderRadius: "16px",
    backgroundColor: "#F6F8F9",
    alignItems: "center",
    marginTop: "6px",
    marginBottom: "6px",
  },
  BoxIcon: {
    padding: "0px 10px",
  },
  BoxTitle: {
    fontWeight: 400,
    fontSize: "8px",
    lineHeigt: "9.38px",
  },
  BoxSubtitle: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeigt: "12px",
  },
};

const CustomModal = (props) => {
  const { showModal, onClosed, dataAsesor, fnBtnPrimary } = props;

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // getTitle();
  }, []);

  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.titleModal}>
              Gracias por validar tus datos de contacto
            </Typography>
          </Grid>
        </Grid>
        <DialogContent style={{ padding: "24px 5px 0px 5px" }}>
          {dataAsesor ? (
            <>
              <Typography sx={styles.ContentModal}>
                Pronto recibirás comunicación de{" "}
                <span style={{ fontWeight: "700" }}>
                  {dataAsesor?.r_nombreAbreviado}
                </span>
                , tu {dataAsesor?.r_sexo === "F" ? "asesora" : "asesor"}, quien
                te mantendrá informado sobre la solicitud que estás a punto de
                iniciar.
              </Typography>
              <Typography style={{ marginTop: "5px" }} sx={styles.ContentModal}>
                <span style={{ fontWeight: "700" }}>
                  ¿Te gustaría{" "}
                  {dataAsesor?.r_sexo === "F" ? "contactarla" : "contactarlo"}{" "}
                  de inmediato?
                </span>{" "}
                Aquí tienes sus datos de contacto:
              </Typography>
            </>
          ) : (
            <Typography sx={styles.ContentModal}>
              Te hemos asignado un asesor de servicio quien se pondrá en
              contacto contigo para mantenerte informado sobre la solicitud que
              estás por iniciar.
            </Typography>
          )}
          {dataAsesor && (
            <Box sx={{ marginTop: "23px" }}>
              <div style={styles.BoxContact}>
                <div style={styles.BoxIcon}>
                  <img src={CallIcon} alt="call_icon" />
                </div>
                <div>
                  <div style={styles.BoxTitle}>Llama al</div>
                  <div style={styles.BoxSubtitle}>{dataAsesor?.r_celular}</div>
                </div>
              </div>
              <div style={styles.BoxContact}>
                <div style={styles.BoxIcon}>
                  <img src={WtspIcon} alt="call_icon" />
                </div>
                <div>
                  <div style={styles.BoxTitle}>Whatsapp</div>
                  <div style={styles.BoxSubtitle}>{dataAsesor?.r_celular}</div>
                </div>
              </div>
              <div style={styles.BoxContact}>
                <div style={styles.BoxIcon}>
                  <img src={MailIcon} alt="call_icon" />
                </div>
                <div>
                  <div style={styles.BoxTitle}>Correo</div>
                  <div style={styles.BoxSubtitle}>{dataAsesor?.r_correo}</div>
                </div>
              </div>
            </Box>
          )}

          <Divider sx={{ marginTop: "16px" }} />
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{ paddingTop: "8px", justifyContent: "space-evenly" }}
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.buttonsDownStyles}
                onClick={fnBtnPrimary}
                autoFocus
              >
                Iniciar solicitud
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogTitle>
    </Dialog>
  );
};

export default CustomModal;
