import React from "react";

const MensajeInformativo = ({mensaje}) => {
  return (
    <div className="component-mensaje">
      <h5 style={{ color: "#196691" }}>Condiciones para realizar un Retiro</h5>
      <p className="text-justify mb-3">
      No cuentas con los requisitos para generar esta solicitud. 
      </p>
      <p className="text-justify m-0">
        En caso tengas alguna duda o consulta puedes comunicarte con nuestro Contact Centrer al 01 2230 2200 desde Lima o 0801 14 224 desde provincias.
      </p>
 
    </div>
  );
};

export default MensajeInformativo;
