import React from 'react';

const Step = (props) => {
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - dashArray * props.percentage / 100;

  return (
    <div className="container-step">
      <div className={"content-step-text " + props.position}>
        <div className="line-step"></div>
        <div className="title-step">
          <h3>{props.name}</h3>
        </div>
      </div>
      <div className="circle-step">
        <svg
          width={props.sqSize}
          height={props.sqSize}
          viewBox={viewBox}>
          <circle
            className="circle-background"
            cx={props.sqSize / 2}
            cy={props.sqSize / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`} />
          <circle
            className={"circle-progress " + props.color}
            cx={props.sqSize / 2}
            cy={props.sqSize / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }} />
          <text
            className={(props.number > props.currectStepNumber ? "circle-text pending" : (props.number < props.currectStepNumber) ? "circle-text finished" : "circle-text active")}
            x="50%"
            y="50%"
            dy=".2em"
            textAnchor="middle">
            <tspan x="40" y="28" className="text1">{`${props.text1}`}</tspan>
            <tspan x="40" y="55" className="text2">{`${props.number}`}</tspan>
          </text>

        </svg>
      </div>

    </div>
  );
};

export default Step;

Step.defaultProps = {
  sqSize: 80,
  percentage: 100,
  strokeWidth: 10
};