import React, { useReducer } from "react";
import PagosDesembolsosContext from "./pagosDesembolsosContext";
import PagosDesembolsosReducer from "./pagosDesembolsosReducer";

import clienteAxios from "../../config/axios";
import {
  PAGOS_DESEMBOLSOS_CLEANER,
  PAGOS_DESEMBOLSOS_ERROR,
  PAGOS_DESEMBOLSOS_EXITOSO,
  PAGOS_DESEMBOLSOS_LOADING,
  PAGOS_DESEMBOLSOS_PERIODOS_CLEANER,
  PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
  PAGOS_DESEMBOLSOS_PERIODOS_LOADING,
  PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO,
  PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO,
  PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
  PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING,
  PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER,
  DETALLE_TRAMITE_EXITOSO,
  DETALLE_TRAMITE_LOADING,
  DETALLE_TRAMITE_ERROR,
  CHECK_LOADING,
  CHECK_EXITOSO,
  CHECK_ERROR,
  USER_LOADING,
  USER_EXITOSO,
  USER_ERROR,
} from "../../types/pagosDesembolsos";

const PagosDesembolsosState = (props) => {
  const initialState = {
    dataEmpresas: null,
    detalle: null,
    check: null,
    user: null,
    dataDeudaPeriodos: null,
    allDataExcel: null,
    loadingAllDataExcel: false,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const getUser = async () => {
    dispatch({
      type: USER_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/pagos-desembolsos/get_user");
      dispatch({
        type: USER_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error?.message === "Network Error") {
        dispatch({
          type: USER_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: USER_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response?.status === 404) {
          dispatch({
            type: USER_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (
          error.response?.data?.errors?.message === "Unauthenticated."
        ) {
          dispatch({
            type: USER_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const clearDataEmpresas = () => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_EXITOSO,
      payload: null,
    });
  };

  const checkTramites = async () => {
    dispatch({
      type: CHECK_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get(
        "/pagos-desembolsos/check-tramites"
      );
      dispatch({
        type: CHECK_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error?.message === "Network Error") {
        dispatch({
          type: CHECK_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: CHECK_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response?.status === 404) {
          dispatch({
            type: CHECK_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (
          error.response?.data?.errors?.message === "Unauthenticated."
        ) {
          dispatch({
            type: CHECK_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const obtenerRetiroAPV = async (tipo) => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get(
        "/pagos-desembolsos/tracking/" + tipo
      );
      dispatch({
        type: PAGOS_DESEMBOLSOS_EXITOSO,
        payload: respuesta.data,
      });
      return respuesta.data;
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: PAGOS_DESEMBOLSOS_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response.status === 500) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response.status === 404) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (error.response.data.errors.message === "Unauthenticated.") {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const obtenerTramite = async (nro_solicitud, tipo) => {
    dispatch({
      type: DETALLE_TRAMITE_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get(
        "/pagos-desembolsos/tracking/" + tipo + "/" + nro_solicitud
      );
      dispatch({
        type: DETALLE_TRAMITE_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: DETALLE_TRAMITE_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response.status === 500) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response.status === 404) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (error.response.data.errors.message === "Unauthenticated.") {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const limpiarDeudasEmpresas = () => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_CLEANER,
    });
  };

  const obtenerDeudaPeriodos = async (ruc) => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_PERIODOS_LOADING,
      payload: true,
    });

    try {
      const respuesta = await clienteAxios.get(
        `/deudas-DESEMBOLSOS/${ruc}/periodos`
      );
      dispatch({
        type: PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response.status === 500) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response.status === 404) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (error.response.data.errors.message === "Unauthenticated.") {
          dispatch({
            type: PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const limpiarDeudaPeriodos = () => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_PERIODOS_CLEANER,
    });
  };

  const obtenerAllDataExcel = async (ruc = "") => {
    let datos = new FormData();
    datos.set("ruc", ruc);

    dispatch({
      type: PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING,
      payload: true,
    });

    try {
      const respuesta = await clienteAxios.post(
        "/deudas-DESEMBOLSOS/historial/excel",
        datos
      );
      dispatch({
        type: PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response.status === 500) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (error.response.status === 404) {
          dispatch({
            type: PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Servicio no encontrado.",
            },
          });
        } else if (error.response.data.errors.message === "Unauthenticated.") {
          dispatch({
            type: PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const limpiarAllDataExcel = () => {
    dispatch({
      type: PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER,
    });
  };

  const [state, dispatch] = useReducer(PagosDesembolsosReducer, initialState);
  return (
    <PagosDesembolsosContext.Provider
      value={{
        dataEmpresas: state.dataEmpresas,
        dataDeudaPeriodos: state.dataDeudaPeriodos,
        allDataExcel: state.allDataExcel,
        loadingAllDataExcel: state.loadingAllDataExcel,
        loadingPage: state.loadingPage,
        detalle: state.detalle,
        check: state.check,
        user: state.user,
        mensajeAlerta: state.mensajeAlerta,
        getUser,
        obtenerRetiroAPV,
        clearDataEmpresas,
        checkTramites,
        obtenerTramite,
        limpiarDeudasEmpresas,
        obtenerDeudaPeriodos,
        limpiarDeudaPeriodos,
        obtenerAllDataExcel,
        limpiarAllDataExcel,
      }}
    >
      {props.children}
    </PagosDesembolsosContext.Provider>
  );
};

export default PagosDesembolsosState;
