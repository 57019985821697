import React from "react";
import styled from "styled-components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";

const DialogAction = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: ${(props) => props.theme.spacing(2)};
  }
`;

const TextRecomendation = styled.p`
  ${(props) => props.theme.tipography.body2};
  color: #6e7378;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const CustomModal = (props) => {
  const {
    open,
    closeModal,
    title,
    children,
    textRecomendacion,
    hasPrimary,
    hasSecondary,
    hasTertiary,
    onClose,
    textClose,
    btnPrimary,
    btnSecondary,
    btnTertiary,
    onClickPrimary,
    onClickSecondary,
    onClickTertiary,
    iconPrimary,
    iconSecondary,
    textPrimary = "Salir",
    textSecondary = "Continuar",
    textTertiary = "Escuchar luego",
  } = props;
  const theme = useTheme();
  /* MUI CSS styles */
  const classes = useStyles();

  const smallScreenSm = useMediaQuery(theme.breakpoints.down("sm"));

  // const renderPrimaryIcon = () => {
  //   if (btnPrimaryLoading)
  //     return (
  //       <span
  //         className="spinner-border spinner-border-sm"
  //         role="status"
  //         aria-hidden="true"
  //       ></span>
  //     );
  //   else return false;
  // };

  // const renderSecondaryIcon = () => {
  //   if (btnSecondaryLoading)
  //     return (
  //       <span
  //         className="spinner-border spinner-border-sm"
  //         role="status"
  //         aria-hidden="true"
  //       ></span>
  //     );
  //   else return false;
  // };

  return (
    <Dialog
      open={open}
      className={
        smallScreenSm ? classes.paperStylesChages : classes.paperStyles
      }
      keepMounted
      fullScreen={smallScreenSm}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography sx={{ textAlign: "center" }}>{title}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <Typography
            sx={{ textAlign: "right", color: "#CE1336", cursor: "pointer" }}
            onClick={closeModal}
          >
            {textClose}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent sx={{ display: "flex" }}>{children}</DialogContent>
      {hasSecondary ||
        hasPrimary ||
        textRecomendacion ||
        (hasTertiary && (
          <DialogAction>
            <Grid container spacing={1}>
              {textRecomendacion && (
                <Grid item xs={12}>
                  <TextRecomendation>{textRecomendacion}</TextRecomendation>
                </Grid>
              )}
              {hasSecondary && (
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    onClick={onClickSecondary}
                    variant="outlined"
                    color="primary"
                    sx={{ width: "100%" }}
                    icon={iconSecondary}
                  >
                    {textSecondary}
                  </Button>
                </Grid>
              )}

              {hasPrimary && (
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    onClick={onClickPrimary}
                    variant="contained"
                    color="primary"
                    sx={{ width: "100%" }}
                    icon={iconPrimary}
                  >
                    {textPrimary}
                  </Button>
                </Grid>
              )}

              {hasTertiary && (
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    onClick={onClickTertiary}
                    variant="text"
                    color="primary"
                    sx={{ width: "100%" }}
                  >
                    {textTertiary}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogAction>
        ))}
      <DialogAction>
        <Grid container spacing={1}>
          {textRecomendacion && (
            <Grid item xs={12}>
              <TextRecomendation>{textRecomendacion}</TextRecomendation>
            </Grid>
          )}
          {hasSecondary && (
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                onClick={onClickSecondary}
                variant="outlined"
                color="primary"
                sx={{ width: "100%" }}
                icon={iconSecondary}
              >
                {textSecondary}
              </Button>
            </Grid>
          )}

          {hasPrimary && (
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                onClick={onClickPrimary}
                variant="contained"
                color="primary"
                sx={{ width: "100%" }}
                icon={iconPrimary}
              >
                {textPrimary}
              </Button>
            </Grid>
          )}

          {hasTertiary && (
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                onClick={onClickTertiary}
                variant="text"
                color="primary"
                sx={{ width: "100%" }}
              >
                {textTertiary}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogAction>
    </Dialog>
  );
};

export default CustomModal;
