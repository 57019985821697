import React from "react";
import {
  ButtonsWrapper,
  CauseOfDeathWrapper,
  Information,
  InputsWrapper,
  NextButton,
  PasoDocumentosComponent,
  PrevButton,
  RequiredInputsCaption,
} from "./styles";
import * as Input from "../../../components/Input";
import SectionTitle from "../components/SectionTitle";
import Divider from "../../../components/Divider";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import getDeepValue from "../../../utils/getDeepValue";
import ModalDeConfirmacion from "./ModalDeConfirmacion";
import ModalDeError from "./ModalDeError";
import { useGastoDeSepelioContext } from "../context";

const PasoDocumentos = ({ handleStepNavigation }) => {
  const causeOfDeathOptions = [
    { label: "Accidente", value: "accidental" },
    { label: "Enfermedad", value: "enfermedad" },
  ];

  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  // delete after development
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      const getDefaultValues = () => {
        // Datos paso Datos del solicitante
        setValue("datos-solicitante.relationship", true ? "conyuge" : "otro");
        setValue(
          "datos-solicitante.person-type",
          true ? "juridica" : "natural"
        );
        setValue(
          "documentos.cause-of-death",
          true ? "accidental" : "enfermedad"
        );

        const defaultFile = [
          {
            path: "blanco y negro 6 hojas.pdf",
            lastModified: 1669539430231,
            lastModifiedDate: new Date(),
            name: "blanco y negro 6 hojas.pdf",
            size: 98585,
            type: "application/pdf",
            webkitRelativePath: "",
          },
        ];

        setValue("documentos.id", defaultFile);
        setValue("documentos.id-representante", defaultFile);
        setValue("documentos.escritura-publica", defaultFile);
        setValue("documentos.facturas-gastos", defaultFile);
        setValue("documentos.certificado-medico-defuncion", defaultFile);
        setValue("documentos.acta-defuncion", defaultFile);
        setValue("documentos.necropcia", defaultFile);
        setValue("documentos.atestado-policial", defaultFile);
        setValue("documentos.autorizacion-reembolso", defaultFile);
      };

      // getDefaultValues();
    }
  }, []);

  const petitionerType = getTypeOfPetitioner(
    getValues("datos-solicitante.person-type"),
    getValues("datos-solicitante.relationship")
  );

  const canContinue = () => {
    var validation;

    const getValidation = (array) => {
      return array
        .map(({ key, required }) => {
          return (required ? watch(key) : true) && !getDeepValue(errors, key);
        })
        .every(Boolean);
    };

    const generalValidation = [
      { key: "documentos.id", required: true },
      { key: "documentos.facturas-gastos", required: true },
      { key: "documentos.certificado-medico-defuncion", required: true },
      { key: "documentos.acta-defuncion", required: true },
      { key: "documentos.voucher-bancario", required: false },
      { key: "documentos.comentarios", required: false },
    ];

    const sicknessValidation = [
      { key: "documentos.epicrisis-historia-clinica", required: false },
    ];

    const accidentValidation = [
      { key: "documentos.necropcia", required: true },
      { key: "documentos.atestado-policial", required: true },
    ];

    const nonImmediateFamilyValidation = [
      { key: "documentos.autorizacion-reembolso", required: true },
      { key: "documentos.autorizacion-reembolso-dni", required: true },
    ];

    const thirdPartyValidation = [
      { key: "documentos.id-representante", required: true },
      { key: "documentos.escritura-publica", required: true },
    ];

    // Immediate family validation
    if (
      watch("documentos.cause-of-death") === "accidental" &&
      petitionerType === "immediate-family"
    ) {
      // console.log(generalValidation);
      validation = getValidation([...generalValidation, ...accidentValidation]);
      return validation;
    }

    if (
      watch("documentos.cause-of-death") === "enfermedad" &&
      petitionerType === "immediate-family"
    ) {
      validation = getValidation([...generalValidation, ...sicknessValidation]);
      return validation;
    }

    // Distant relative validation
    if (
      watch("documentos.cause-of-death") === "accidental" &&
      petitionerType === "distant-relative"
    ) {
      validation = getValidation([
        ...generalValidation,
        ...accidentValidation,
        ...nonImmediateFamilyValidation,
      ]);
      return validation;
    }

    if (
      watch("documentos.cause-of-death") === "enfermedad" &&
      petitionerType === "distant-relative"
    ) {
      validation = getValidation([
        ...generalValidation,
        ...nonImmediateFamilyValidation,
        ...sicknessValidation,
      ]);
      return validation;
    }

    // Third party validation
    if (
      watch("documentos.cause-of-death") === "accidental" &&
      petitionerType === "third-party"
    ) {
      validation = getValidation([
        ...generalValidation,
        ...accidentValidation,
        ...thirdPartyValidation,
        ...nonImmediateFamilyValidation,
      ]);
      return validation;
    }

    if (
      watch("documentos.cause-of-death") === "enfermedad" &&
      petitionerType === "third-party"
    ) {
      validation = getValidation([
        ...generalValidation,
        ...thirdPartyValidation,
        ...sicknessValidation,
      ]);
      return validation;
    }
  };

  const [modalDeConfirmacionIsOpen, setModalDeConfirmacionIsOpen] =
    useState(false);
  const [modalDeErrorIsOpen, setModalDeErrorIsOpen] = useState(false);

  const onContinue = () => setModalDeConfirmacionIsOpen(true);
  const onPrev = () => handleStepNavigation("previous");

  const {
    state: { request },
  } = useGastoDeSepelioContext();

  useEffect(() => {
    if (request.status === "error") {
      setModalDeErrorIsOpen(true);
    }
  }, [request.status]);

  return (
    <PasoDocumentosComponent>
      <SectionTitle>Ingreso de documentos</SectionTitle>
      <CauseOfDeathWrapper $shrink={watch("documentos.cause-of-death")}>
        <Input.Wrapper>
          <Input.Title required={false}>Causa del fallecimiento</Input.Title>
          <Input.Select
            name="documentos.cause-of-death"
            options={causeOfDeathOptions}
            placeholder="Selecciona uno"
          />
          <Input.ErrorMessage name="documentos.cause-of-death" />
        </Input.Wrapper>
      </CauseOfDeathWrapper>

      <Divider marginTop={"0px"} />
      {!watch("documentos.cause-of-death") && (
        <Information>
          <div>
            <div>
              <p className="subtitle">Enfermedad:</p>
              <p className="information">
                Fallecimiento por enfermedades no preexistentes o causas
                naturales.
              </p>
            </div>
            <div>
              <p className="subtitle">Accidental:</p>
              <p className="information">
                Fallecimiento por causas ajenas a una enfermedad.
              </p>
            </div>
          </div>
        </Information>
      )}
      {watch("documentos.cause-of-death") && (
        <>
          <Information>
            <div>
              <p className="information">
                Formatos admitidos: png, jpg, jpeg, pdf.
              </p>
              <p className="information">
                Se debe subir un archivo por cada documento.
              </p>
              <p className="information">Tamaño máximo de archivo: 2mb.</p>
            </div>
          </Information>
          <Documents
            petitionerType={petitionerType}
            causeOfDeath={watch("documentos.cause-of-death")}
          />
        </>
      )}
      {watch("documentos.cause-of-death") && (
        <RequiredInputsCaption>
          Los campos con <span>*</span> son obligatorios.
        </RequiredInputsCaption>
      )}
      <ButtonsWrapper>
        <PrevButton fullWidth variant="secondary" size="lg" onClick={onPrev}>
          Volver
        </PrevButton>
        <NextButton
          fullWidth
          disabled={!canContinue()}
          onClick={onContinue}
          size="lg"
        >
          Continuar
        </NextButton>
      </ButtonsWrapper>
      <ModalDeConfirmacion
        open={modalDeConfirmacionIsOpen}
        setOpen={setModalDeConfirmacionIsOpen}
        handleStepNavigation={handleStepNavigation}
      />
      <ModalDeError
        modalIsOpen={modalDeErrorIsOpen}
        setModalIsOpen={setModalDeErrorIsOpen}
      />
    </PasoDocumentosComponent>
  );
};

export default PasoDocumentos;

const Documents = ({ petitionerType, causeOfDeath }) => {
  const filesMaxSize = 2;
  const filesExtensions = ["pdf", "jpg", "jpeg", "png"];
  return (
    <InputsWrapper>
      <Input.Wrapper>
        <Input.Title>Copia simple DNI del solicitante</Input.Title>
        <Input.File
          extensions={filesExtensions}
          name="documentos.id"
          size={"small"}
          maxFileSize={filesMaxSize}
        />

        <Input.ErrorMessage name="documentos.id" />
      </Input.Wrapper>

      {petitionerType === "third-party" && (
        <>
          <Input.Wrapper>
            <Input.Title>
              Copia simple del documento de identidad del representante legal de
              la empresa
            </Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.id-representante"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.id-representante" />
          </Input.Wrapper>
          <Input.Wrapper>
            <Input.Title>
              Copia autenticada de escritura pública de la empresa
            </Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.escritura-publica"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.escritura-publica" />
          </Input.Wrapper>
        </>
      )}

      <Input.Wrapper>
        <Input.Title>Copia simple de cert. médico de defunción</Input.Title>
        <Input.File
          extensions={filesExtensions}
          maxFileSize={filesMaxSize}
          name="documentos.certificado-medico-defuncion"
          size={"small"}
        />
        <Input.ErrorMessage name="documentos.certificado-medico-defuncion" />
      </Input.Wrapper>
      <Input.Wrapper>
        <Input.Title>Acta de defunción</Input.Title>
        <Input.File
          extensions={filesExtensions}
          maxFileSize={filesMaxSize}
          name="documentos.acta-defuncion"
          size={"small"}
        />
        <Input.ErrorMessage name="documentos.acta-defuncion" />
      </Input.Wrapper>

      {causeOfDeath === "accidental" && (
        <>
          <Input.Wrapper>
            <Input.Title>Copia simple de necropsia</Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.necropcia"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.necropcia" />
          </Input.Wrapper>
          <Input.Wrapper>
            <Input.Title>Copia simple del atestado policial</Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.atestado-policial"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.atestado-policial" />
          </Input.Wrapper>
        </>
      )}

      <Input.Wrapper>
        <Input.Title>
          Facturas o boletas electrónicas de gastos funerarios
        </Input.Title>
        <Input.File
          extensions={filesExtensions}
          maxFileSize={filesMaxSize}
          name="documentos.facturas-gastos"
          size={"small"}
        />
        <Input.ErrorMessage name="documentos.facturas-gastos" />
      </Input.Wrapper>

      {petitionerType !== "immediate-family" && (
        <>
          <Input.Wrapper>
            <Input.Title>Autorización de reembolso</Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.autorizacion-reembolso"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.autorizacion-reembolso" />
          </Input.Wrapper>
          <Input.Wrapper>
            <Input.Title>
              Copia simple DNI del familiar que autoriza
            </Input.Title>
            <Input.File
              extensions={filesExtensions}
              maxFileSize={filesMaxSize}
              name="documentos.autorizacion-reembolso-dni"
              size={"small"}
            />
            <Input.ErrorMessage name="documentos.autorizacion-reembolso-dni" />
          </Input.Wrapper>
        </>
      )}

      {causeOfDeath === "enfermedad" && (
        <Input.Wrapper>
          <Input.Title required={false}>
            Copia simple de epicrisis o historia clínica
          </Input.Title>
          <Input.File
            extensions={filesExtensions}
            maxFileSize={filesMaxSize}
            name="documentos.epicrisis-historia-clinica"
            size={"small"}
            required={false}
          />
          <Input.ErrorMessage name="documentos.epicrisis-historia-clinica" />
        </Input.Wrapper>
      )}

      <Input.Wrapper>
        <Input.Title required={false}>
          Voucher bancario para reembolso
        </Input.Title>
        <Input.File
          extensions={filesExtensions}
          maxFileSize={filesMaxSize}
          name="documentos.voucher-bancario"
          size={"small"}
          required={false}
        />
        <Input.ErrorMessage name="documentos.voucher-bancario" />
      </Input.Wrapper>

      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
        <Input.Title required={false}>Comentarios sobre documentos</Input.Title>
        <Input.TextArea
          required={false}
          name="documentos.comentarios"
          placeholder="Escribe tu observación aquí. Por ejemplo: El voucher tiene manchas, pero es legible."
          size="lg"
        />

        <Input.ErrorMessage name="documentos.comentarios" />
      </Input.Wrapper>
    </InputsWrapper>
  );
};

const getTypeOfPetitioner = (personType, relationship) => {
  if (personType === "natural") {
    const isImmediateFamily = [
      "conyuge",
      "conviviente",
      "hijo(a)",
      "hermano(a)",
      "padre-madre",
    ].includes(relationship);

    return isImmediateFamily ? "immediate-family" : "distant-relative";
  }

  if (personType === "juridica") {
    return "third-party";
  }
};
