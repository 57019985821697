import React from "react";
import PropTypes from "prop-types";
import { IconConent } from "../../../styles/Icon";
const Icon = ({ url, width, height, type, padding, ...props }) => {
  return (
    <IconConent
      width={width}
      height={height}
      padding={padding}
      type={type}
      {...props}
    >
      <img src={url} alt="arrow back red" />
    </IconConent>
  );
};

export default Icon;

Icon.propTypes = {
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.string,
};

Icon.defaultProps = {
  width: "24px",
  height: "24px",
  type: "square",
  padding: "8px",
};
