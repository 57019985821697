import styled, { css } from "styled-components";
import Button from "../../../../components/Buttons";
import { device } from "../../../../utils/device";

export const ButtonsWrapperComponent = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 8px);
  margin-left: -8px;
  flex-flow: row wrap;
  & > Button {
    margin-left: 8px;
  }
  ${(p) => css`
    @media screen and ${device.desktopS} {
      grid-template-columns: 1fr 1fr;
      /* width: calc(480px + 16px); */
      column-gap: 16px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

export const NextButtonComponent = styled(Button)`
  /* width: 50%; */
  flex-basis: 0px;
  flex-grow: 1;
  max-width: 50%;
  ${(p) =>
    p.isBtnSecondary &&
    css`
      max-width: 100%;
    `}

  ${(p) => css`
    @media screen and ${device.tablet} {
      max-width: 240px;
    }
  `}
`;

export const PrevButtonComponent = styled(Button)`
  flex-basis: 0px;
  flex-grow: 1;
  max-width: 50%;

  ${(p) => css`
    @media screen and ${device.desktopS} {
      max-width: 240px;
    }
  `}
`;
