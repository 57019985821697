import React, { useEffect, useState } from "react";
import {
  ContentAmount,
  InfoText,
  HeaderAccordion,
  HeadingTextAccordion,
  ContentFondos,
  ContentSelect,
  GridContainer,
} from "./styles";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import * as Input from "../../../../../../components/Input";
import { useFormContext } from "react-hook-form";
import InfoCircle from "../../../../../../assets/IconBucket/info_circle";
import ICON_FONDOJUB from "../../../../../../assets/IconBucket/ICON_FONDOJUB";
import ICON_FONDOLIBRE from "../../../../../../assets/IconBucket/ICON_FONDOLIBRE";
import Icon from "../../../../Components/IconSVG";
import Button from "../../../../../../components/Buttons";
import Card from "../../../../Components/Card";

import { ModalDeclaracionJurada } from "../Modals";
import Divider from "../../../../../../components/Divider";
import "./index.css";
import { montoFormat } from "../../../../../../helper/amountFormat";

const Dropdown = ({ fondos, amounPension, objComplete, data }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const { getValues, setValue, trigger, clearErrors } = useFormContext();
  const [amount, setAmount] = useState(null);
  const [complete, setComplete] = useState(objComplete);

  useEffect(() => {
    setValue("calculo.fondoJub", ["true"]);
  }, []);

  const handleAmount = (e) => {
    const value = e.target.value;
    const numberFormatted = value.replace("S/ ", "").replaceAll(",", "");
    setAmount(numberFormatted);
    setValue("calculo.amount", numberFormatted);
    trigger("calculo.amount");
  };

  const handleFirstNext = () => {
    complete.item1 = true;
    complete.open2 = true;
    setComplete(complete);
    setSelectedItem(1);
  };

  const validateBtnNext = () => {
    if (getValues("typePension")) {
      if (getValues("typePension") === "base") {
        return false;
      }
      if (
        getValues("calculo.amount") &&
        Number(getValues("calculo.amount")) >= 1 &&
        Number(getValues("calculo.amount")) > amounPension
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  const isCloseToTotal = (e, total) => {
    if (isNaN(e) || e > total) {
      return true;
    }

    return `El monto debe ser mayor a S/ ${amounPension}`;
  };

  const disabledInput = getValues("typePension") !== "otros";

  const getOBL = fondos.filter((item) => item.r_cuenta === "OBL");
  const getVCF = fondos.filter((item) => item.r_cuenta === "VCF");
  const getVSF = fondos.filter((item) => item.r_cuenta === "VSF");

  return (
    <div className="HBT_ACCORDIONCUSTOM_dropdown-Desktop">
      <div className="HBT_ACCORDIONCUSTOM_dropdown-Desktop-header">
        <HeaderAccordion>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="HBT_PMO_AccordionHeader_number">1</div>
            <div> Establece tu pensión menual</div>
          </div>

          <div>{complete.item1 && <ArrowForwardIosRoundedIcon />}</div>
        </HeaderAccordion>
        <Divider />
        <HeaderAccordion disabled={!complete.open2 ? "disabled" : ""}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="HBT_PMO_AccordionHeader_number">2</div>
            <div>Confirma tus fondos</div>
          </div>

          <div>
            <div>{complete.item2 && <ArrowForwardIosRoundedIcon />}</div>
          </div>
        </HeaderAccordion>
        <Divider />
      </div>

      <div className={`HBT_ACCORDIONCUSTOM_dropdown-Desktop-body`}>
        {selectedItem === 0 ? (
          <ContentSelect>
            <HeadingTextAccordion>
              Este será el monto que buscas recibir mes a mes al jubilarte.
            </HeadingTextAccordion>
            <GridContainer>
              <Input.RadioCard
                name="typePension"
                value="base"
                indicatorSide="right"
                onClick={() => {
                  clearErrors("calculo.amount");
                  setValue("calculo.amount", "");
                  setAmount(null);
                }}
              >
                <ContentAmount>
                  <div className="HBT_PM_Funds">
                    <h2>Monto mínimo de pensión</h2>
                    <h3>S/ {montoFormat(Number(data.cbc))} por mes</h3>
                    <InfoText>
                      <Icon
                        width="24px"
                        height="24px"
                        componentIcon={<InfoCircle color="#ACAFB3" />}
                      />
                      <p>
                        Equivale al costo de la Canasta Básica de Consumo actual
                        según INEI; puede variar en el tiempo.
                      </p>
                    </InfoText>
                  </div>
                </ContentAmount>
              </Input.RadioCard>

              <Input.RadioCard
                name="typePension"
                value="otros"
                indicatorSide="right"
                onClick={() => {
                  setValue("typePension", "otros");
                }}
              >
                <ContentAmount>
                  <h2>Otro monto</h2>

                  <Input.Wrapper>
                    <Input.Title required={false} disabled={disabledInput}>
                      Monto de pensión
                    </Input.Title>
                    <Input.Money
                      disabled={disabledInput}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          console.log("Enter");
                          e.preventDefault();
                        }
                      }}
                      onChange={handleAmount}
                      value={amount}
                      name="calculo.amount"
                      validate={{
                        greaterThanTotal: (e) =>
                          isCloseToTotal(e, amounPension),
                      }}
                    />
                    <Input.ErrorMessage name="calculo.amount" />
                  </Input.Wrapper>
                </ContentAmount>
              </Input.RadioCard>
            </GridContainer>

            <Button
              fullWidth
              onClick={handleFirstNext}
              disabled={validateBtnNext()}
              style={{ marginLeft: "auto" }}
            >
              Siguiente
            </Button>
          </ContentSelect>
        ) : (
          <ContentSelect>
            <HeadingTextAccordion>
              Selecciona los productos que deseas considerar para calcular tu
              Pensión Objetivo.
            </HeadingTextAccordion>
            {getOBL.length > 0 && (
              <Card border={getValues("calculo.fondoJub") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOJUB />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo de Jubilación</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getOBL[0].r_saldo)} </h3>
                    </div>
                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.fondoJub"
                          value={"true"}
                          required={false}
                          // disabled
                          style={{ pointerEvents: "none" }}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
            {getVCF.length > 0 && (
              <Card border={getValues("calculo.conFin") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOJUB />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo con Fin Previsional</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getVCF[0].r_saldo)} </h3>
                    </div>
                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.conFin"
                          value={"true"}
                          required={false}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
            {getVSF.length > 0 && (
              <Card border={getValues("calculo.fondoLibre") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOLIBRE />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo Libre Habitat</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getVSF[0].r_saldo)} </h3>
                    </div>

                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.fondoLibre"
                          value={"true"}
                          required={false}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
          </ContentSelect>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
