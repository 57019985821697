import React, { useEffect, useState } from "react";
import clienteAxios from "../../../../config/axios";
import InformacionDeInversion from "../InformacionDeInversion";
import SeleccionaCuentaBancaria from "../SeleccionaCuentaBancaria";
import Header from "./Header";

const DatosDeInversion = ({
  cuentas,
  setCuenta,
  fondoSeleccionado,
  step,
  // new account props
  data,
  handlerbanco,
  tipoDeCuenta,
  handlerTipoCuenta,
  mostrarFecha,
  yearTarjeta,
  handlerYear,
  listMonth,
  handlerNroCuenta,
  errores,
  listTipoCuenta,
  listAnio,
  monthTarjeta,
  handlerMonth,
  longitudNroCuenta,
  nroDeCuenta,
  filtroTipoCuenta,
  mostrarFormularioCompleto,
  // informacion de la inversion
  setFrecuenciaCargo,
  montoACargar,
  handlerMonto,
  frecuenciaCargo,
  setFechaCargo,
  fechaCargo,
  cargoDoble,
  banco,
  setCargoDoble,
}) => {
  const [aporteMensual, setAporteMensual] = useState(null);

  useEffect(() => {
    const getCancelarAporteMensual = async () => {
      try {
        const { data } = await clienteAxios.get("/fondo-libre/aporte-mensual");

        setAporteMensual({ ...data });
      } catch (error) {
        console.log({ error });
      }
    };

    getCancelarAporteMensual();
  }, []);

  return (
    <div>
      <Header fondoSeleccionado={fondoSeleccionado} step={step} data={data} />
      <SeleccionaCuentaBancaria
        cuentas={cuentas}
        setCuenta={setCuenta}
        // new account props
        data={data}
        handlerbanco={handlerbanco}
        tipoDeCuenta={tipoDeCuenta}
        handlerTipoCuenta={handlerTipoCuenta}
        mostrarFecha={mostrarFecha}
        yearTarjeta={yearTarjeta}
        handlerYear={handlerYear}
        listMonth={listMonth}
        handlerNroCuenta={handlerNroCuenta}
        errores={errores}
        listTipoCuenta={listTipoCuenta}
        listAnio={listAnio}
        monthTarjeta={monthTarjeta}
        handlerMonth={handlerMonth}
        longitudNroCuenta={longitudNroCuenta}
        nroDeCuenta={nroDeCuenta}
        banco={banco}
        filtroTipoCuenta={filtroTipoCuenta}
        mostrarFormularioCompleto={mostrarFormularioCompleto}
        aporteMensual={aporteMensual}
      />
      <InformacionDeInversion
        data={data}
        setFrecuenciaCargo={setFrecuenciaCargo}
        montoACargar={montoACargar}
        handlerMonto={handlerMonto}
        frecuenciaCargo={frecuenciaCargo}
        setFechaCargo={setFechaCargo}
        fechaCargo={fechaCargo}
        errores={errores}
        cargoDoble={cargoDoble}
        setCargoDoble={setCargoDoble}
        aporteMensual={aporteMensual}
      />
    </div>
  );
};

export default DatosDeInversion;
