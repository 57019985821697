import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./customModal.css";
import sgvAdvice from "../../assets/recuerda.svg";

const CustomModal = (props) => {
  const {
    showModal,
    backdrop = true,
    className,
    toggle,
    title,
    description,
    hasBtnPrimary,
    textBtnPrimary,
    fnBtnPrimary,
    btnPrimaryLoading,
    disableBtnPrimary,
    hasBtnSecondary,
    textBtnSecondary,
    fnBtnSecondary,
    hasAdvice,
    txtAdvice,
    onClosed,
  } = props;

  const renderIcon = () => {
    if (btnPrimaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      className={className}
      backdrop={backdrop}
      centered
      onClosed={onClosed}
    >
      <div className="modal-close">
        {/* <i className="fa fa-times-circle-o" onClick={fnCloseIconBtn}></i> */}
      </div>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Row className="footer-row total-buttons">
          <Col className="footer-col-btn">
            {hasBtnPrimary && (
              <button
                className="btn-modal-primary"
                disabled={disableBtnPrimary}
                onClick={fnBtnPrimary}
              >
                {textBtnPrimary} {renderIcon()}
              </button>
            )}
            {hasBtnSecondary && (
              <button className="btn-modal-secondary" onClick={fnBtnSecondary}>
                {textBtnSecondary}
              </button>
            )}
          </Col>
        </Row>
        {hasAdvice && (
          <Row className="footer-row remenber-modal">
            <Col className="footer-col-advice">
              {/* <i className="fa fa-info-circle mr-3"></i> */}
              <div className="icon-container">
                <img src={sgvAdvice} alt={"icono de alerta"} />
              </div>
              {txtAdvice}
            </Col>
          </Row>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
