import React, { useEffect, useState } from "react";
import {
  BankAccounts,
  NewCardInformationComponent,
  SeleccionaCuentaBancariaComponent,
  BankSelect,
  AccountTypeSelect,
  ExpirationYearSelect,
  ExpirationMonthSelect,
  ErrorMessage,
  InputWrapper,
  Divider,
  AccountNumberInput,
  YearMonthWrapper,
  SectionTitleComponent,
  YearMonthErrorMessage,
  DeleteAccountMessage,
} from "./styles";
import * as Input from "../../../../components/Input";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

const SeleccionaCuentaBancaria = ({
  cuentas,
  setCuenta,

  // New account props
  data,
  handlerbanco,
  tipoDeCuenta,
  handlerTipoCuenta,
  mostrarFecha,
  yearTarjeta,
  handlerYear,
  listMonth,
  handlerNroCuenta,
  errores,
  listTipoCuenta,
  listAnio,
  monthTarjeta,
  handlerMonth,
  longitudNroCuenta,
  nroDeCuenta,
  filtroTipoCuenta,
  mostrarFormularioCompleto,
  aporteMensual,
  banco,
}) => {
  const [bankOptionSelected, setBankOptionSelected] = useState(null);
  const onSelectBank = (cuentaCode) => {
    if (cuentaCode === "remove" || cuentaCode === null) {
      setCuenta(null);
      setBankOptionSelected(null);

      return;
    }
    if (cuentaCode === "nueva") {
      setCuenta(cuentaCode);
      setBankOptionSelected(cuentaCode);

      return;
    }

    const cuenta = cuentas.find((e) => e.r_codigo === cuentaCode) || "nueva";

    setCuenta(cuenta);
    setBankOptionSelected(cuenta);
  };

  const methods = useForm();
  const {
    watch,
    formState: { dirtyFields },
  } = methods;

  useEffect(() => {
    onSelectBank(watch("banco"));
  }, [watch("banco")]);

  return (
    <SeleccionaCuentaBancariaComponent>
      <Divider />
      <SectionTitle>Selecciona tu cuenta bancaria</SectionTitle>
      <FormProvider {...methods}>
        <form>
          <BankAccounts numeroDeComponentes={cuentas ? cuentas.length : 0}>
            <SavedBankAccounts
              cuentas={cuentas}
              bankSelected={bankOptionSelected}
              onSelectBank={onSelectBank}
              aporteMensual={aporteMensual}
            />
            <Input.NewCreditCard name="banco" value="nueva" />

            {errores?.cuentaCard && (
              <ErrorMessage>{errores?.cuentaCard}</ErrorMessage>
            )}
          </BankAccounts>
        </form>
      </FormProvider>
      <Divider />
      {mostrarFormularioCompleto && (
        <NewCardInformation
          data={data}
          handlerbanco={handlerbanco}
          tipoDeCuenta={tipoDeCuenta}
          handlerTipoCuenta={handlerTipoCuenta}
          mostrarFecha={mostrarFecha}
          yearTarjeta={yearTarjeta}
          handlerYear={handlerYear}
          listMonth={listMonth}
          handlerNroCuenta={handlerNroCuenta}
          errores={errores}
          listTipoCuenta={listTipoCuenta}
          listAnio={listAnio}
          monthTarjeta={monthTarjeta}
          handlerMonth={handlerMonth}
          longitudNroCuenta={longitudNroCuenta}
          nroDeCuenta={nroDeCuenta}
          banco={banco}
          filtroTipoCuenta={filtroTipoCuenta}
          bankOptionSelected={bankOptionSelected}
        />
      )}
    </SeleccionaCuentaBancariaComponent>
  );
};

export default SeleccionaCuentaBancaria;

const SavedBankAccounts = ({
  cuentas,
  bankSelected,
  onSelectBank,
  aporteMensual,
}) => {
  const [listOfCreditCards, setListOfCreditCards] = useState(cuentas);
  const accountReferenceNotAllowedToRemove =
    aporteMensual?.detalle_aporte?.ref_cuenta;

  const onSuccededRemoval = ({ reference }) => {
    const newListOfCreditCards = listOfCreditCards.filter(({ r_codigo }) => {
      return r_codigo !== reference;
    });
    setListOfCreditCards(newListOfCreditCards);

    // Removed the selected credit card
    if (watch("banco") === reference) {
      onSelectBank("remove");
      setValue("banco", null);
    }
  };
  const { watch, setValue } = useFormContext();

  return (
    <>
      {listOfCreditCards &&
        listOfCreditCards.map((cuenta) => {
          return (
            <Input.CreditCard
              key={cuenta.r_codigo}
              cuenta={cuenta}
              name="banco"
              isRemovable={
                accountReferenceNotAllowedToRemove !== cuenta.r_codigo
              }
              value={cuenta.r_codigo}
              onSuccededRemoval={onSuccededRemoval}
            />
          );
        })}
    </>
  );
};

export const NewCardInformation = ({
  data,
  handlerbanco,
  tipoDeCuenta,
  handlerTipoCuenta,
  mostrarFecha,
  handlerYear,
  yearTarjeta,
  listMonth,
  handlerNroCuenta,
  errores,
  listTipoCuenta,
  listAnio,
  monthTarjeta,
  handlerMonth,
  longitudNroCuenta,
  nroDeCuenta,
  banco,
  filtroTipoCuenta,
  bankOptionSelected,
}) => {
  const bankOptions = data.r_bancos.map((e) => ({
    label: e.r_descripcion,
    value: e,
  }));
  const accountTypeOptions = filtroTipoCuenta
    .filter((tipoDeCuenta) => tipoDeCuenta.r_codigo !== "TCR")
    .map((e) => ({
      label: e.r_descripcion,
      value: JSON.stringify(e),
    }));

  const expirationYearOptions = listAnio().map((e) => ({ value: e, label: e }));
  const expirationMonthOptions = listMonth(yearTarjeta)
    ? listMonth(yearTarjeta).map(({ code, mes }) => ({
        value: code,
        label: mes,
      }))
    : [];

  return (
    <NewCardInformationComponent data-creditcard={mostrarFecha}>
      <InputWrapper>
        <SectionTitle>Entidad financiera</SectionTitle>
        <BankSelect
          key={`bank-account-${bankOptionSelected}`}
          options={bankOptions}
          onChange={(item, e) => handlerbanco(item.value)}
          isSearchable={false}
          placeholder="Escoge tu banco"
          classNamePrefix={"select"}
        />
        {errores?.banco && <ErrorMessage>{errores?.banco}</ErrorMessage>}
      </InputWrapper>
      <InputWrapper>
        <SectionTitle>Tipo de cuenta</SectionTitle>
        <AccountTypeSelect
          options={accountTypeOptions}
          onChange={(item, e) => handlerTipoCuenta(item.value)}
          value={[
            accountTypeOptions.find(({ value }) => value === tipoDeCuenta),
          ]}
          isSearchable={false}
          placeholder="Selecciona tu tipo de cuenta"
          isDisabled={!Boolean(banco)}
          classNamePrefix={"select"}
          Divider
        />

        {errores?.tipoCuenta && (
          <ErrorMessage>{errores?.tipoCuenta}</ErrorMessage>
        )}
      </InputWrapper>
      {mostrarFecha && (
        <YearMonthWrapper>
          <div>
            <SectionTitle>Año</SectionTitle>
            <ExpirationYearSelect
              options={expirationYearOptions}
              value={expirationYearOptions.find(
                ({ value }) => value == yearTarjeta
              )}
              isSearchable={false}
              placeholder="Año"
              onChange={(item, e) => handlerYear(String(item.value))}
              classNamePrefix={"select"}
            />
          </div>
          <div>
            <SectionTitle>Mes</SectionTitle>
            <ExpirationMonthSelect
              options={expirationMonthOptions}
              value={
                expirationMonthOptions &&
                expirationMonthOptions.find(
                  ({ value }) => value === monthTarjeta
                )
              }
              isSearchable={false}
              placeholder="Mes"
              onChange={(item, e) => handlerMonth(item.value)}
              classNamePrefix={"select"}
            />
          </div>

          {errores?.anioYmes && (
            <YearMonthErrorMessage>{errores?.anioYmes}</YearMonthErrorMessage>
          )}
        </YearMonthWrapper>
      )}

      <InputWrapper>
        <SectionTitle>Número de cuenta</SectionTitle>
        <AccountNumberInput
          placeholder="Número de cuenta"
          onChange={handlerNroCuenta}
          pattern="[0-9]*"
          value={nroDeCuenta}
          disabled={longitudNroCuenta === 0 ? true : false}
          maxLength={longitudNroCuenta}
        />
        {errores?.nroCuenta && <ErrorMessage>{errores.nroCuenta}</ErrorMessage>}
      </InputWrapper>
    </NewCardInformationComponent>
  );
};

const SectionTitle = ({ children, style = null }) => {
  return (
    <SectionTitleComponent style={style}>
      <span>* </span>
      {children}
    </SectionTitleComponent>
  );
};
