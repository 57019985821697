import React, { Component, Fragment } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

am4core.useTheme(am4themes_animated);
class GraficoGeneral extends Component {
  constructor(props) {
    super(props);
    this.outerDiv = React.createRef();
    this.scrollingWrapper = React.createRef();
  }

  componentDidMount() {
    const { twelveMonth, isMobile } = this.props;
    this.drawGraphic(twelveMonth.data);

    if (isMobile) {
      let outerDiv = this.outerDiv.current;
      let scrollingWrapper = this.scrollingWrapper.current;
      scrollingWrapper.scrollLeft = 9999999;

      outerDiv.onscroll = function () {
        scrollingWrapper.scrollLeft = outerDiv.scrollLeft;
      };

      scrollingWrapper.onscroll = function () {
        outerDiv.scrollLeft = scrollingWrapper.scrollLeft;
      };
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  drawGraphic = (dataArray) => {
    let chart = am4core.create("generalchartdiv", am4charts.XYChart);
    chart.responsive.enabled = true;
    chart.zoomOutButton.disabled = true;
    chart.language.locale = am4lang_es_ES;
    if (this.props.isMobile) {
      chart.paddingRight = 0;
      chart.paddingLeft = 10;
    } else {
      chart.paddingRight = 16;
      chart.paddingLeft = 16;
    }

    if (!this.props.isMobile) {
      var scrollbarX = new am4core.Scrollbar();
      scrollbarX.marginBottom = 20;
      if (this.props.isMobile) {
        scrollbarX.paddingRight = 10;
      }
      chart.scrollbarX = scrollbarX;
    }

    chart.data = dataArray;
    chart.language.locale["_decimalSeparator"] = ".";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.startLocation = 0.01;
    categoryAxis.endLocation = 0.94;
    categoryAxis.renderer.minLabelPosition = 0.0001;
    categoryAxis.renderer.maxLabelPosition = 0.94;
    //categoryAxis.renderer.endLocation = 1;
    categoryAxis.renderer.minGridDistance = 110;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.dx = -15;
    categoryAxis.renderer.labels.template.fontSize = "11px";

    // categoryAxis.title.adapter.add("text", function (text, target) {
    //     let fechamonthyear = new String(text).split('-');
    //     return '444'; //fechamonthyear[2];
    // });
    categoryAxis.tooltip.label.adapter.add("text", function (text, target) {
      // if (target.dataItem) {
      //     return target.dataItem.dataContext.axisLabel;
      // }
      return text;
    });

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.tooltip.disabled = false;
    yAxis.numberFormatter = new am4core.NumberFormatter();
    yAxis.numberFormatter.numberFormat = "'S/' #,###.##";
    if (this.props.isMobile) {
      yAxis.renderer.inside = false;
    }

    /** Formateamos el número a 2 decimales */
    // valueAxis.numberFormatter.numberFormat = "#,###.00";

    let series0 = this.createSeries(
      "fund0",
      "date",
      "Fondo 0",
      chart,
      "#1868b7"
    );
    let series1 = this.createSeries(
      "fund1",
      "date",
      "Fondo 1",
      chart,
      "#CE1336"
    );
    let series2 = this.createSeries(
      "fund2",
      "date",
      "Fondo 2",
      chart,
      "#48C180"
    );
    let series3 = this.createSeries(
      "fund3",
      "date",
      "Fondo 3",
      chart,
      "#77adff"
    );

    this.customSeriesColor(series0, "#1868b7");
    this.customSeriesColor(series1, "#CE1336");
    this.customSeriesColor(series2, "#48C180");
    this.customSeriesColor(series3, "#77adff");

    this.createEventLine(
      this.props.twelveMonth.events.fund0,
      "value",
      "Eventos Fondo 0",
      chart
    );
    this.createEventLine(
      this.props.twelveMonth.events.fund1,
      "value",
      "Eventos Fondo 1",
      chart
    );
    this.createEventLine(
      this.props.twelveMonth.events.fund2,
      "value",
      "Eventos Fondo 2",
      chart
    );
    this.createEventLine(
      this.props.twelveMonth.events.fund3,
      "value",
      "Eventos Fondo 3",
      chart
    );

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panX";
    // chart.cursor.behavior = "none";
    chart.cursor.lineX.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(2, 2, 2, 2);
    marker.strokeWidth = 2;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");
    chart.legend.itemContainers.template.events.on("hit", function (ev) {});
    this.chart = chart;
  };

  updateRangeLabels(minRange, maxRange, categoryAxis) {
    const { twelveMonth, isMobile } = this.props;

    minRange.value = twelveMonth.fecha_ini;
    minRange.label.text = twelveMonth.fecha_ini;

    maxRange.value = twelveMonth.fecha_fin;
    maxRange.label.text = twelveMonth.fecha_fin;
  }

  createSeries = (field, date, name, chart, customColor) => {
    let series = chart.series.push(new am4charts.LineSeries());
    if (this.props.isMobile) {
      series.hiddenInLegend = true;
    }

    var bullet = series.bullets.push(new am4charts.Bullet());
    bullet.disabled = true;
    bullet.propertyFields.disabled = "disabled";

    var square = bullet.createChild(am4core.Rectangle);
    square.width = 20;
    square.height = 20;
    square.horizontalCenter = "middle";
    square.verticalCenter = "middle";
    square.stroke = am4core.color("#2F4858");
    square.strokeWidth = 1;

    series.dataFields.valueY = field;
    series.dataFields.msgTooltip = field;
    series.dataFields.categoryX = "date";
    series.dataFields.categoryXX = "date2";
    series.name = name;
    series.strokeWidth = 2;
    series.tooltipHTML = "{msgTooltip}";

    series.stroke = am4core.color(customColor);

    return series;
  };

  createEventLine(data, fondo, name, chart) {
    var trend = chart.series.push(new am4charts.LineSeries());
    trend.name = name;
    trend.hiddenInLegend = true;
    trend.dataFields.valueY = fondo;
    trend.dataFields.msgTooltip = "tooltip";
    trend.dataFields.categoryX = "date";
    trend.strokeWidth = 0;
    trend.stroke = trend.fill = am4core.color("#FF5E10");
    trend.data = data;

    var bullet = trend.bullets.push(new am4charts.CircleBullet());
    //bullet.tooltipHTML = "{date}<br>{msgTooltip}<br>Cuota: {valueY}";
    bullet.tooltipHTML = "{msgTooltip}";
    //bullet.showTooltipOn = "always";
    bullet.strokeWidth = 1;
    bullet.stroke = am4core.color("#FF5E10");
    bullet.circle.fill = trend.stroke;

    var hoverState = bullet.states.create("hover");
    hoverState.properties.scale = 1.7;

    return trend;
  }

  customSeriesColor = (series, customColor) => {
    series.stroke = am4core.color(customColor);
    series.fill = am4core.color(customColor);
  };

  render() {
    const { twelveMonth, windowWidth, isMobile } = this.props;
    let widthContainer = "100%";
    let outerDivStyle = { overflowX: "auto", overflowY: "hidden" };
    let innerDivStyle = { paddingTop: "1px", width: widthContainer };

    let daterighfloat = 0;
    if (isMobile) {
      if (windowWidth > 365) {
        daterighfloat = 450;
      } else {
        daterighfloat = windowWidth + 134;
      }
    }
    return (
      <Fragment>
        {twelveMonth && (
          <div
            className="container-graphic bg-white"
            style={{
              width: isMobile ? "calc(" + windowWidth + "px - 32px)" : "auto",
            }}
          >
            {isMobile && (
              <div ref={this.outerDiv} style={outerDivStyle}>
                <div style={innerDivStyle}>&nbsp;</div>
              </div>
            )}

            <div
              ref={this.scrollingWrapper}
              id="containerScrollingWrapper"
              //   className="scrolling-wrapper"
            >
              <div
                id="generalchartdiv"
                style={{
                  width: widthContainer,
                  height: isMobile ? "400px" : "600px",
                }}
              ></div>
              <div
                className="date_fecha_fin_general"
                style={{ right: "calc(-" + daterighfloat + "px)" }}
              >
                {twelveMonth.fecha_fin}
              </div>
            </div>

            <div className="row mt-3 d-none">
              <div className="col-4 col-md-4">
                <h5
                  className={isMobile ? "text-center mb-4" : "text-center mb-0"}
                >
                  Rango de fechas
                </h5>
              </div>
              <div className="col-8 col-md-8">
                <div
                  className={
                    isMobile
                      ? "flex-wrap text-center"
                      : "align-items-center d-flex justify-content-around w-100"
                  }
                >
                  <p className="mb-0">
                    Fecha de inicio: {twelveMonth.fecha_ini}
                  </p>
                  <p className="mb-0">Fecha de fin: {twelveMonth.fecha_fin}</p>
                </div>
              </div>
            </div>

            <div id="box-logo"></div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default GraficoGeneral;
