import React from "react";
import { Card } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { CardContainer, LinkStyle } from "../../components";

const InfoUsuario = ({ mensajes, hiddenTop = false }) => {
  const [opciones, setOpciones] = useState({
    r_obligatorio: false,
    r_vsf: false,
  });

  useEffect(() => {
    const tieneOblig = verificar("r_obligatorio");
    const tienevsf = verificar("r_vsf");
    setOpciones({
      r_obligatorio: tieneOblig,
      r_vsf: tienevsf,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verificar = (fondo) => {
    return mensajes?.some((item) => item.r_codigo === fondo);
  };

  return (
    <>
      {!hiddenTop && (
        <Card className="mb-2">
          <section className="text-center content-invierte">
            <div className="img-invierte"></div>
            <p className="text-justify px-3 pt-3">
              Conoce más acerca de la rentabilidad de los fondos de Habitat.
            </p>
            <p className="text-center px-3">
              <LinkStyle
                to="https://www.afphabitat.com.pe/rentabilidad/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ingresa aquí
              </LinkStyle>
            </p>
          </section>
        </Card>
      )}

      <CardContainer
        title={"Recuerda"}
        seeMore={false}
        color={"#bf0d3e"}
        product={true}
        showHeader={true}
      >
        <ul className="recomendaciones mb-0">
          <li>
            {" "}
            <p className="p-0 text-justify">
              La información del gráfico corresponde a los montos registrados
              desde tu primer Estado de Cuenta en AFP Habitat, el cual se genera
              el último día útil de cada mes.
            </p>
          </li>
        </ul>
      </CardContainer>
    </>
  );
};

export default InfoUsuario;
