import React, { useReducer, useContext } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";

import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";
import MisdatosContext from "../misdatos/misdatosContext";

import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
  LOGIN_LOADING,
  OBTENER_USUARIO,
  LOGIN_FORCE,
  LAYOUT_TIMER,
  RESET_TIMER,
  CAMBIO_CLAVE_EXITOSO,
  VALIDO_DATOS_EXITOSO,
  CLOSE_MODAL_CAMBIO_CLAVE,
  CLOSE_MODAL_VALIDO_DATOS,
  CLOSE_MODAL_BANNER,
  LOGIN_ERROR_SERVICE,
  ACCOUNT_BLOCK_LOADING,
  ACCOUNT_BLOCK_DATA,
  ACCOUNT_BLOCK_ERROR,
  ACCOUNT_BLOCK_CLEAR,
  ERROR_GENERICO,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: sessionStorage.getItem("token"),
    token_type: sessionStorage.getItem("token_type"),
    cambio_clave: null,
    valido_datos: null,
    dataBlock: null,
    autenticado: null,
    banner: null,
    nombreUsuario: null,
    mensaje: null,
    gender: null,
    birthday: null,
    cargando: true,
    loading: false,
    loginForce: false,
    timer: null,
    reset: false,
    redirectHabitat: false,
    simulador_apv: null,
    dataUnBlockLoading: null,
    dataUnBlock: null,
    dataUnBlockError: null,
    errorGenerico: { message: "", activo: false },
  };

  const misdatosContext = useContext(MisdatosContext);
  const { actualizarDatosProcesos } = misdatosContext;

  const usuarioAutenticado = async (mensaje) => {
    const token = sessionStorage.getItem("token");
    const token_type = sessionStorage.getItem("token_type");
    if (token && token_type) {
      tokenAuth(token, token_type);
    }
    /* Se debe implementar un servicio de tipo de tipo get para resetear 
           el tiempo de duración del token, esti inicialmente cuando se navegue
           de vista en vista o se refresque la pagina
           A CONTINUACION SE SIMULARA ESTO CONSULTANDO EL LOCALSTORAGE
        */
    try {
      if (token && token_type) {
        dispatch({
          type: OBTENER_USUARIO,
        });
      } else {
        dispatch({
          type: LOGIN_ERROR_SERVICE,
          payload: mensaje,
        });
      }
    } catch (error) {}
  };

  const iniciarSesion = async (datos) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post("/login", datos);

      localStorage.setItem("client", JSON.stringify(respuesta.data));

      window.dataLayer.push({
        event: "login",
        userid: respuesta.data.r_cuspp,
        categoria_renta: respuesta.data.r_segmento,
      });
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
      });
      await actualizarDatosProcesos(respuesta.data.r_is_data_updated);
      localStorage.setItem("isDataUpdated", respuesta.data.r_is_data_updated);
      usuarioAutenticado();
      return true;
    } catch (error) {
      if (error.message === "Network Error") {
        // Problema del internet del cliente
        dispatch({
          type: LOGIN_ERROR_SERVICE,
          payload: "Problema de su red, vuelva a intentarlo",
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: LOGIN_ERROR,
            payload: "Error en servicios",
          });
        } else {
          if (error.response?.data?.r_errores?.r_codigo === "0") {
            // mensaje simple
            dispatch({
              type: LOGIN_ERROR,
              payload: error.response.data,
            });
          } else {
            if (error.response?.data?.r_errores?.r_codigo === "1") {
              // tiene una sesión activada, y mostramos un formulario para forzar el inicio de sesion

              dispatch({
                type: LOGIN_FORCE,
                payload: true,
              });
              dispatch({
                type: LOGIN_ERROR,
                payload: error.response.data,
              });
            } else {
              console.log(error.status);
            }
          }
        }
      }
      return false;
    }
  };

  const LlenarErrorGenerico = (datos) => {
    dispatch({
      type: ERROR_GENERICO,
      payload: datos,
    });
  };

  const obtenerDatosBloqueados = async (datos) => {
    let codigoR = 0;

    try {
      dispatch({
        type: ACCOUNT_BLOCK_LOADING,
        payload: true,
      });

      try {
        const respuesta = await clienteAxios.post("/desbloqueo", datos);
        if (respuesta?.data?.code === "404") {
          dispatch({
            type: ACCOUNT_BLOCK_DATA,
            payload: null,
          });
          codigoR = 1;
        } else {
          dispatch({
            type: ACCOUNT_BLOCK_DATA,
            payload: respuesta.data,
          });
          usuarioAutenticado();
        }
      } catch (error) {
        if (error.message === "Network Error") {
          // Problema del internet del cliente
          dispatch({
            type: LOGIN_ERROR_SERVICE,
            payload: "Problema de su red, vuelva a intentarlo",
          });
        } else {
          if (error.response?.status === 500) {
            dispatch({
              type: LOGIN_ERROR,
              payload: "Error en servicios",
            });
            LlenarErrorGenerico({
              message: "Error en los servicios, por favor recargue la página.",
              activo: true,
            });
          } else {
            if (error.response?.data?.r_errores?.r_codigo === "0") {
              // mensaje simple
              dispatch({
                type: ACCOUNT_BLOCK_ERROR,
                payload: error.response.data,
              });
            } else {
              console.log(error.status);
            }
          }
        }
      }
    } catch {
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }

    return codigoR;
  };

  const cerrarModalLoginForce = () => {
    dispatch({
      type: LOGIN_FORCE,
      payload: false,
    });
  };

  const cerrarSesion = async (dato, location = null) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/logout");
      dispatch({
        type: CERRAR_SESION,
        payload: dato,
      });
      if (dato && respuesta) {
        if (location && location.includes("mi-habitat-digital"))
          window.location = "https://www.afphabitat.com.pe/";
      }
      // usuarioAutenticado();
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: CERRAR_SESION,
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: CERRAR_SESION,
        });
      }
      if (dato) {
        window.location = "https://www.afphabitat.com.pe/";
      }
    }
  };

  const actualizarTimer = async () => {
    let veamosToken = sessionStorage.getItem("token");

    if (veamosToken) {
      try {
        const respuesta = await clienteAxios.get("/refresh-token");

        dispatch({
          type: LAYOUT_TIMER,
          payload: {
            timer: respuesta.data.time,
            reset: true,
          },
        });
      } catch (error) {}
    }
  };

  const resetearTimer = () => {
    dispatch({
      type: RESET_TIMER,
    });
  };

  const cerrarSesionForce = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  const cambioClavePrimeraVezExitoso = () => {
    dispatch({
      type: CAMBIO_CLAVE_EXITOSO,
    });
  };
  const validoDatosExitoso = (dato) => {
    dispatch({
      type: VALIDO_DATOS_EXITOSO,
      payload: dato,
    });
  };

  const closeModalCambioClave = () => {
    dispatch({
      type: CLOSE_MODAL_CAMBIO_CLAVE,
    });
  };
  const closeModalValidoDatos = () => {
    dispatch({
      type: CLOSE_MODAL_VALIDO_DATOS,
    });
  };
  const closeModalBanner = () => {
    dispatch({
      type: CLOSE_MODAL_BANNER,
    });
  };
  const limpiarVariablesAccountBlock = () => {
    dispatch({
      type: ACCOUNT_BLOCK_CLEAR,
    });
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        dataBlock: state.dataBlock,
        mensaje: state.mensaje,
        autenticado: state.autenticado,
        nombreUsuario: state.nombreUsuario,
        banner: state.banner,
        cargando: state.cargando,
        loading: state.loading,
        loginForce: state.loginForce,
        timer: state.timer,
        reset: state.reset,
        redirectHabitat: state.redirectHabitat,
        cambio_clave: state.cambio_clave,
        valido_datos: state.valido_datos,
        simulador_apv: state.simulador_apv,
        gender: state.gender,
        birthday: state.birthday,
        dataUnBlockLoading: state.dataUnBlockLoading,
        dataUnBlock: state.dataUnBlock,
        dataUnBlockError: state.dataUnBlockError,
        errorGenerico: state.errorGenerico,
        usuarioAutenticado,
        iniciarSesion,
        cerrarModalLoginForce,
        cerrarSesion,
        cerrarSesionForce,
        actualizarTimer,
        resetearTimer,
        cambioClavePrimeraVezExitoso,
        validoDatosExitoso,
        closeModalCambioClave,
        closeModalValidoDatos,
        closeModalBanner,
        obtenerDatosBloqueados,
        limpiarVariablesAccountBlock,
        LlenarErrorGenerico,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
