import React from "react";
import LogoImage from "../../../assets/logo.png";

import { Text, View, Image } from "@react-pdf/renderer";

export const Logo = ({ style }) => {
  return (
    <View
      style={{
        marginBottom: "16px",
        width: 120,
        ...style,
      }}
    >
      <Image src={LogoImage} />
    </View>
  );
};

export const Title = ({ children, textStyle, style }) => {
  return (
    <View
      style={{
        marginBottom: "16px",
        ...style,
      }}
    >
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          fontSize: "14px",
          textAlign: "center",
          ...textStyle,
        }}
      >
        {children}
      </Text>
    </View>
  );
};
