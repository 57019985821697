import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import styled from "styled-components";
import HOME_ICON from "../../assets/IconBucket/HOME_ICON.svg";
import Tipography from "../Tipography";
import Link from "@mui/material/Link";
import IC_HOME from "./../../assets/icons/ic_home.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useHistory, useParams } from "react-router-dom";

export const BoxDesktop = styled(Box)`
  display: none;
  @media (min-width: 990px) {
    display: block;
  }
`;

export const BoxMobile = styled(Box)`
  padding: 1.5rem 1rem;
  display: flex;
  @media (min-width: 990px) {
    display: none;
  }
`;

const Index = ({ title, title_mobile, rutas }) => {
  const history = useHistory();
  const { nro, tipo } = useParams();

  const onClickBack = (e) => {
    if (nro) {
      history.replace("/mi-habitat-digital/afiliado/estado-de-tramite/" + tipo);
      return;
    }
    if (tipo) {
      history.replace("/mi-habitat-digital/afiliado/estado-de-tramite/");
      return;
    }
    history.replace("/mi-habitat-digital/afiliado/inicio");
    /* history.goBack(); */
  };

  return (
    <>
      <BoxDesktop
        sx={{
          margin: "0 3px",
          height: "auto",
          width: "calc(100%- 6px)",
        }}
      >
        <Box
          sx={{
            padding: "8px 0rem",
          }}
          md={{
            padding: "0rem",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#2B2B2B",
                fontSize: "18px",
                fontWeight: "400",
                marginLeft: "4px",
                lineHeight: "24px",
              }}
            >
              {title}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={IC_HOME} />
            </div>

            {rutas?.map((e, key) => (
              <Link
                key={`${e.id}-${key}`}
                underline="none"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.2rem",
                  underline: "none",
                  padding: e.id == 1 ? "0 2px 0 8px" : "0 1px 0 0",
                }}
                color={
                  e.id === rutas[rutas?.length - 1].id ? "#CE1336" : "#625F6E"
                }
                href={e.ruta !== " " ?? "#"}
              >
                <ChevronRight fontSize="inherit" sx={{ color: "#6E7378" }} />
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.2px",
                  }}
                >
                  {e.title}
                </div>
              </Link>
            ))}
          </div>
        </Box>
      </BoxDesktop>

      <BoxMobile
        style={{
          position: "fixed",
          zIndex: "99",
          padding: "12px 1rem",
          /* height: 'auto', */
          height: "65px",
          width: "100%",
          background: "#f8f9fb",
          /* boxShadow: '4px 4px rgba(0, 0, 0, 0.12)', */
          boxShadow: "rgb(0 0 0 / 12%) 1px -16px 10px -16px inset",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <KeyboardArrowLeftIcon
            style={{
              margin: "auto",
            }}
          />
          <div className="title_breacrumb_home_md">{title}</div>
          <div
            className="title_breacrumb_home_xs"
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              letterSpacing: "0.2px",
              color: "#363A3E",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title_mobile}
          </div>
        </div>
      </BoxMobile>
    </>
  );
};

export default Index;
