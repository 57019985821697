import React from "react";
import imgLogo from "../../../../../../../../../assets/exclamation-mark.svg";
import { Button } from "reactstrap";
import "./index.css";

export default function Denegado(props) {
  return (
    <>
      <div className="logo-container mb-3">
        <img src={imgLogo} alt="icono de confirmacion" />
      </div>
      <h1>Pre-Evaluación Denegada</h1>
      <p>
        Gracias por tu tiempo realizando el proceso. A la fecha de hoy,{" "}
        <strong>{props.date}</strong>, todavía no cumples{" "}
        {props.jaoResultadoData.data.result.pension
          ? "con el siguiente requisito:"
          : "con los siguientes requisitos:"}
      </p>

      <div className="content-requisitos error-jao mb-4" id="jao-result-erros">
        <div className="requisitos-card">
          <div className="whitecard-block">
            <h4>Aportes realizados</h4>
            <p>
              Debes contar con al menos <strong>72 meses de aportes</strong>{" "}
              durante los últimos 120 meses (10 años).
            </p>
          </div>
        </div>
        {!props.jaoResultadoData.data.result.pension && (
          <div className="requisitos-card density-error">
            <div className="whitecard-block">
              <h4>Pensión estimada</h4>
              <p>
                Tu pensión calculada debe ser{" "}
                <strong>
                  por lo menos el 40% del promedio de las remuneraciones
                </strong>{" "}
                que has percibido durante los últimos 10 años, actualizadas
                según el IPC (Índice de precios del Consumidor) y deduciendo las
                gratificaciones de julio y diciembre.
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="container-jao-buttons mt-5">
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => props.getOutOfProcess()}
        >
          Finalizar{" "}
        </Button>
      </div>
    </>
  );
}
