import React, { useState, useEffect } from "react";
import { Grid, Modal, Drawer } from "@mui/material";
import EnvioModal from "./Envio";
import { ModalComponent } from "./stylesModalJS";
import { BsX } from "react-icons/bs";
import ModalGeneralWeb from "./ModalGeneral";
import { CodigoInvalido, CodigoInvalidoD } from "./CodigoInvalido";
import { SinDatos, SinDatosD } from "./SinDatos";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  drawerStyle: {
    "& .MuiDrawer-paper": {
      borderRadius: "16px 16px 0px 0px",
    },
  },
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      height: "auto",
    },
    fontSize: "10px",
    // top: "unset",
  },
  paperStylesChages: {
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0px 0px",
      height: "auto",
    },
    fontSize: "10px",
    top: "unset",
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    width: "100%",
  },
});

const ModalClave = (props) => {
  const classes = useStyles();
  const { openModal, changeStep, bloquear, close, changeStepOne } = props;
  const [open] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [openModalCI, setOpenModalCI] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [openModalSD, setOpenModalSD] = useState(false);

  const openModalAction = () => {
    // setOpen(true)
  };

  const onClose = () => {
    // setOpen(false)
    close();
  };

  const bloquearP = () => {
    bloquear();
  };

  useEffect(() => {
    if (openModal) openModalAction();
  }, [openModal]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const changeModalCI = () => {
    changeStepOne();
    onClose();
    setOpenModalCI(false);
    setReturnModal(true);
  };

  const closeModalCI = () => {
    setOpenModalCI(false);
  };

  const openCI = () => {
    setReturnModal(false);
    setOpenModalCI(true);
  };

  const openSD = () => {
    setOpenModalSD(true);
  };

  const changePrevEnvio = () => {
    setOpenModalCI(false);
    setReturnModal(true);
  };

  const closeModalSD = () => {
    setOpenModalSD(false);
  };

  const changeModalSD = () => {
    changeStepOne();
    onClose();
    setOpenModalSD(false);
  };

  return (
    <>
      {width > 600 ? (
        <Modal open={open} onClose={onClose}>
          <ModalComponent sx={{ padding: 4 }}>
            <div className="close" onClick={onClose}>
              <BsX />
            </div>
            <div>
              <Grid container justifyContent="center">
                <Grid item>
                  <b>Esta operación se confirmará con tu token digital.</b>
                </Grid>
              </Grid>
              <EnvioModal
                closeModalPrincipal={onClose}
                openCI={openCI}
                openSD={openSD}
                changePrevEnvio={returnModal}
                bloquear={bloquearP}
                changeStep={changeStep}
                moreTime={true}
              ></EnvioModal>
            </div>
          </ModalComponent>
        </Modal>
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          anchor={"bottom"}
          className={classes.drawerStyle}
          PaperProps={{
            sx: {
              bordeRadius: "16px 16px 0 0 !important",
              padding: "32px 32px 40px 32px",
            },
          }}
        >
          <div className="">
            <Grid container justifyContent="center">
              <Grid item>
                <b>Esta operación se confirmará con tu token digital.</b>
              </Grid>
            </Grid>
            <EnvioModal
              closeModalPrincipal={onClose}
              openCI={openCI}
              openSD={openSD}
              changePrevEnvio={returnModal}
              bloquear={bloquearP}
              changeStep={changeStep}
              moreTime={true}
            ></EnvioModal>
          </div>
        </Drawer>
      )}

      {openModalCI ? (
        <ModalGeneralWeb openModal={openModalCI} close={closeModalCI}>
          <CodigoInvalido
            changeStepOne={changeModalCI}
            changePrevEnvio={changePrevEnvio}
          ></CodigoInvalido>
          <CodigoInvalidoD
            changeStepOne={changeModalCI}
            changePrevEnvio={changePrevEnvio}
          ></CodigoInvalidoD>
        </ModalGeneralWeb>
      ) : (
        ""
      )}

      {openModalSD ? (
        <ModalGeneralWeb openModal={openModalSD} close={closeModalSD}>
          <SinDatos changeStepOne={changeModalSD}></SinDatos>
          <SinDatosD changeStepOne={changeModalSD}></SinDatosD>
        </ModalGeneralWeb>
      ) : (
        ""
      )}
    </>
  );
};

export default ModalClave;
