import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import PagosPendientesContext from "../../context/pagosPendientes/pagosPendientesContext";
import ModalContext from "../../context/modal/modalContext";
import Loading from "../../components/Loading/index.jsx";
import { CardDeuda } from "./components";
import "./index.css";
import downLoadExcel from "../../utils/downLoadExcel";
import makeCustomExcel from "../../utils/makeCustomExcel";
import { CardContainer, Page, NotFoundData } from "../../components";

/**QA */
const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];
export default function PagosPendientes(props) {
  const pagosPendientesContext = useContext(PagosPendientesContext);
  const {
    dataEmpresas,
    allDataExcel,
    loadingPage,
    mensajeAlerta,
    obtenerDeudasEmpresas,
    limpiarDeudasEmpresas,
    obtenerAllDataExcel,
    limpiarAllDataExcel,
  } = pagosPendientesContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [data, setData] = useState([]);
  const [isBuildingExcel, setIsBuildingExcel] = useState(false);
  const [userInfo, setUserInfo] = useState({
    doc_type: "",
    doc_num: "",
    user_name: "",
    cuspp: "",
    apellido_paterno: "",
    apellido_materno: "",
    nombres: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerDeudasEmpresas();

    return () => {
      limpiarDeudasEmpresas();
      limpiarAllDataExcel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlerta) {
      mostrarMensaje();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  useEffect(() => {
    if (dataEmpresas) {
      const user = JSON.parse(localStorage.getItem("client"));
      const obj = {
        doc_type: localStorage.getItem("doc_type"),
        doc_num: localStorage.getItem("doc_num"),
        user_name: user.r_primer_nombre,
        cuspp: user.r_cuspp,
        apellido_paterno: user.r_primer_apellido,
        apellido_materno: user.r_segundo_apellido,
        nombres: user.r_primer_nombre + " " + user.r_segundo_nombre,
      };
      setUserInfo(obj);
      setData(dataEmpresas);
    }
  }, [dataEmpresas]);

  useEffect(() => {
    if (allDataExcel) {
      const arr = Object.values(allDataExcel);

      const addValue = arr.map((item) => {
        const splitA = item.r_periodo.split(" ");
        const monthValue = months.indexOf(splitA[0]) + 1;
        const month =
          monthValue.toString().length === 1 ? "0" + monthValue : monthValue;
        item.numDate = Number(splitA[1]) + "" + month;
        return item;
      });

      const sortDateNumber = addValue.sort((a, b) => {
        return a.numDate - b.numDate;
      });
      const workbook = makeCustomExcel(sortDateNumber, userInfo);
      downLoadExcel(workbook, "reporte");
      setIsBuildingExcel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDataExcel]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const btnDonwLoadExcel = () => {
    setIsBuildingExcel(true);
    obtenerAllDataExcel();
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (loadingPage) return <Loading />;
  return (
    <Page title="Deuda de tu empleador" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {data.data && data.data.length === 0 ? (
            <CardContainer>
              <NotFoundData
                mensaje={
                  <span>
                    No cuentas con deuda pendiente de regularizar por parte de
                    algún anterior empleador.
                    <br /> Te invitamos a seguir navegando dentro de Mi Habitat
                    Digital.
                  </span>
                }
                btnText="Volver al inicio"
                url="/mi-habitat-digital/afiliado/inicio"
                icon={true}
              />
            </CardContainer>
          ) : (
            <>
              <CardContainer>
                <div className="user-information">
                  <p>
                    En este espacio podrás revisar la deuda pendiente de tu(s)
                    empleador(es) a tu Fondo de Jubilación.
                  </p>
                  <br />
                  <p>
                    Recuerda que la falta de aportes podría ocasionar que no
                    cuentes con la cobertura del Seguro de Invalidez,
                    Sobrevivencia y Gastos de Sepelio.
                    {/* <i
                      id="remenber-aportes"
                      className="fa fa-info-circle"
                      aria-hidden="true"
                    ></i>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="remenber-aportes"
                    >
                      Recuerda que la falta de aportes podría ocasionar que no
                      cuentes con la cobertura del Seguro de Invalidez,
                      Sobrevivencia y Gastos de Sepelio.
                    </UncontrolledTooltip> */}
                  </p>

                  {/* <h1 className="text-center user-name">{userInfo.user_name}</h1>
                <p className="document-number">
                    {`${userInfo.doc_type == '00' ? 'DNI'
                        : userInfo.doc_type == '01' ? 'CE'
                            : userInfo.doc_type == '03' ? 'Lib. Adolesc. Trab' : 'PAS'}: ${userInfo.doc_num}`}
                </p> */}
                </div>
                {data.data && data.data.length > 2 ? (
                  <div id="lista-deudas" className="lista-pagos">
                    {data.data.map((value, index) => {
                      return (
                        <CardDeuda
                          key={index}
                          companyState={value.r_company_status}
                          businessName={value.r_razon_social}
                          amount={value.r_total_nominal}
                          ruc={value.r_ruc}
                          estado={value.r_estado}
                          isBuildingExcel={isBuildingExcel}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div id="lista-deudas" className="lista-pagos content-center">
                    {data.data &&
                      data.data.map((value, index) => {
                        return (
                          <CardDeuda
                            key={index}
                            businessName={value.r_razon_social}
                            amount={value.r_total_nominal}
                            companyState={value.r_company_status}
                            ruc={value.r_ruc}
                            estado={value.r_estado}
                            isBuildingExcel={isBuildingExcel}
                          />
                        );
                      })}
                  </div>
                )}

                <div className="footer-payments">
                  <button
                    className={
                      isBuildingExcel
                        ? "btn-main-red btn-disable"
                        : "btn-main-red"
                    }
                    onClick={btnDonwLoadExcel}
                    disabled={isBuildingExcel}
                  >
                    {isBuildingExcel ? "Descargando " : "Descargar historial"}
                    {isBuildingExcel && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                  {/* <p className="footer-text">El incumplimiento de pago de los aportes obligatorios sitúa en estado de desprotección a los trabajadores afiliados para acceder a la cobertura de seguro de invalidez, sobrevivencia para sus familiares, gastos de sepelio y pensión de jubilación.</p> */}
                </div>
              </CardContainer>
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}
