import React from "react";

function Icon({ color = "#0043CE" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4H3.33333H14"
        stroke="#363A3E"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33203 3.99992V2.66659C5.33203 2.31296 5.47251 1.97382 5.72256 1.72378C5.9726 1.47373 6.31174 1.33325 6.66536 1.33325H9.33203C9.68565 1.33325 10.0248 1.47373 10.2748 1.72378C10.5249 1.97382 10.6654 2.31296 10.6654 2.66659V3.99992M12.6654 3.99992V13.3333C12.6654 13.6869 12.5249 14.026 12.2748 14.2761C12.0248 14.5261 11.6857 14.6666 11.332 14.6666H4.66536C4.31174 14.6666 3.9726 14.5261 3.72256 14.2761C3.47251 14.026 3.33203 13.6869 3.33203 13.3333V3.99992H12.6654Z"
        stroke="#363A3E"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Icon;
