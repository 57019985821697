import moment from "moment";

let dataRelationsChip = {
  1: "Cónyuge",
  2: "Hijo",
  3: "Padre",
  4: "Madre",
};

export const dataEstimation = (data) => {
  let arrEstimation = [];
  for (const key in data) {
    let obj = {
      id: 0,
      name: "",
      text: "",
      amount: "",
      balance: "",
    };
    if (Object.hasOwnProperty.call(data, key)) {
      if (key === "0") {
        obj.id = "0";
        obj.name = "PENSIÓN MENSUAL";
        obj.text = "Dejo el 100% de mi fondo para pensión";
        arrEstimation.push(obj);
      } else if (key === "955") {
        obj.id = "955";
        obj.name = "RETIRO DE 95.5%";
        obj.text = "Retiro hasta";
        obj.amount = data[key].amount;
        arrEstimation.push(obj);
      } else if (key === "pmo") {
        let textName =
          parseFloat(data[key].excedente) < 0
            ? "Monto faltante"
            : "Monto excedente";
        obj.id = "pm";
        obj.name = "PENSIÓN OBJETIVO";
        obj.text = textName;
        obj.balance = data[key].excedente;
        arrEstimation.push(obj);
      }
    }
  }

  return arrEstimation;
};

export const filterTypePension = (arrayType, typePension, data) => {
  let getId = arrayType[0];

  const result = arrayType.map((i) => {
    if (i === "pm") {
      return {
        [i]: {
          fondos: data.cic,
          necesario: data["pmo"]["excedente"][typePension],
        },
      };
    } else {
      return {
        [i]: {
          anual: data[i]["retirement"]["annual"][typePension],
          mensual: data[i]["retirement"]["monthly"][typePension],
        },
      };
    }
  });
  return { data: result };
};

export const reestructureArrayBeneficiarios = (data) => {
  let newArray = [];

  data.forEach((element) => {
    let getYears = moment().diff(element.birthday, "years");
    let obj = {
      parentesco: dataRelationsChip[element.relationship_id],
      edad: getYears,
      sexo: element.gender,
      documentos: 0,
    };
    newArray.push(obj);
  });
  return newArray;
};
