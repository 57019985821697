import React from "react";
import * as actions from "./actions";

// Initial state
const conexionErrorState = false;

export const initialState = {
  conexionError: conexionErrorState,
};

const reducer = (state, action) => {
  switch (action.type) {
    // conexion error
    case actions.SET_CONEXION_ERROR:
      return {
        ...state,
        conexionError: true,
      };
    case actions.REMOVE_CONEXION_ERROR:
      return {
        ...state,
        conexionError: false,
      };
    default:
      break;
  }
};

export default reducer;
