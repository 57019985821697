export const SET_CONEXION_ERROR = "SET_CONEXION_ERROR";
export const REMOVE_CONEXION_ERROR = "REMOVE_CONEXION_ERROR";

export const GET_AFFILIATE_INFO = "GET_AFFILIATE_INFO";
export const AFFILIATE_SUCCESS = "AFFILIATE_INFO_SUCCESS";
export const AFFILIATE_FAILURE = "AFFILIATE_INFO_FAILURE";
export const RESET_AFFILIATE_INFO = "RESET_AFFILIATE_INFO";

export const GET_VERIFICATON_EMAIL_TOKEN = "GET_VERIFICATON_EMAIL_TOKEN";
export const VERIFICATION_EMAIL_TOKEN_SUCCESS =
  "VERIFICATION_EMAIL_TOKEN_SUCCESS";
export const VERIFICATION_EMAIL_TOKEN_FAILURE =
  "VERIFICATION_EMAIL_TOKEN_FAILURE";

export const VALIDATION_EMAIL_TOKEN = "VALIDATION_EMAIL_TOKEN";
export const VALIDATION_EMAIL_TOKEN_SUCCESS = "VALIDATION_EMAIL_TOKEN_SUCCESS";
export const VALIDATION_EMAIL_TOKEN_FAILURE = "VALIDATION_EMAIL_TOKEN_FAILURE";

export const SEND_REQUEST = "SEND_REQUEST";
export const SEND_REQUEST_SUCCESS = "SEND_REQUEST_SUCCESS";
export const SEND_REQUEST_FAILURE = "SEND_REQUEST_FAILURE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
