import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import $ from "jquery";
import mp3LegalTema1 from "../../../../assets/audios/nuevoRetiro/1_Introduccion.mp3";
import mp3LegalTema6 from "../../../../assets/audios/nuevoRetiro/2_Consideraciones.mp3";
import mp3LegalTema7 from "../../../../assets/audios/nuevoRetiro/3_Que_SIGUE.mp3";
import "./lyricMusicPlayer.css";

export default function LyricMusicPlayer(props) {
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const { currentTopicData, setEndVideo, setDurationVideo, asesoria } = props;
  let currentLine = "";
  let lyricHeight = $(".lyrics").height();

  const align = () => {
    if (window.document.getElementsByClassName("highlighted").length > 0) {
      let a = $(".highlighted").height();
      let c = $(".content").height();
      let d =
        $(".highlighted").offset().top -
        $(".highlighted").parent().offset().top;
      /*align highlighted line at top */
      //let e = d;
      /*align highlighted line at center */
      let e = d + a / 2 - c / 2;
      $(".content").animate(
        { scrollTop: e + "px" },
        { easing: "swing", duration: 250 }
      );
    }
  };

  const generate = () => {
    let html = "";
    for (let i = 0; i < currentTopicData["lyrics"].length; i++) {
      html += "<div";
      // if (i == 0) {
      //     html += ` class="highlighted"`;
      //     currentLine = 0;
      // }
      if (currentTopicData["lyrics"][i]["bold"]) {
        html += ' style="font-weight: bold;"';
      }
      html += ">";
      html +=
        currentTopicData["lyrics"][i]["line"] == ""
          ? "<br />"
          : currentTopicData["lyrics"][i]["line"];
      html += "</div>";
    }
    $(".lyrics").html(html);
    align();
  };

  const timeUpdate = (e) => {
    let time = document.getElementById("video").currentTime * 1000;
    let past = currentTopicData["lyrics"].filter(function (item) {
      return item.time < time;
    });
    if (currentTopicData["lyrics"][past.length] != currentLine) {
      currentLine = currentTopicData["lyrics"][past.length];
      $(".lyrics div").removeClass("highlighted");
      $(`.lyrics div:nth-child(${past.length})`).addClass("highlighted");
      align();
    }
  };

  useEffect(() => {
    document
      .getElementById("video")
      .addEventListener("loadedmetadata", function () {
        if (document.getElementById("video")) {
          const duration = document.getElementById("video").duration / 60;
          setDurationVideo(duration.toFixed(0));
          setIsVideoLoading(false);
        }
      });

    document.getElementById("video").addEventListener(
      "ended",
      function (e) {
        setEndVideo(true);
      },
      false
    );

    window.addEventListener(
      "resize",
      () => {
        if ($(".lyrics").height() != lyricHeight) {
          //Either width changes so that a line may take up or use less vertical space or the window height changes, 2 in 1
          lyricHeight = $(".lyrics").height();
          align();
        }
      },
      true
    );
  }, []);

  useEffect(() => {
    generate();
    setIsVideoLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTopicData]);

  return (
    <>
      {isVideoLoading && (
        <div className="audio-container-loader">
          <Spinner color="danger" />
        </div>
      )}
      <div className={`audio-container ${isVideoLoading ? "d-none" : ""}`}>
        <div className="content">
          <div className="lyrics"></div>
        </div>

        <div className="player">
          {/* <div className="left">
                    <img src={imgPlayTopic} alt="imagen-del-audio" />
                </div> */}
          <div className="right">
            <div className="top">
              <p className="song">{currentTopicData.title}</p>
              <p className="artist">{currentTopicData.description}</p>
            </div>
            <div className="bottom">
              <audio
                id="video"
                controls={true}
                _autoplay=""
                name="media"
                controlsList="nodownload"
                onTimeUpdate={timeUpdate}
              >
                <CustomSource
                  asesoria={asesoria}
                  topic={currentTopicData.topic}
                />
              </audio>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CustomSource(props) {
  if (props.topic === 1) {
    return <source src={mp3LegalTema1} type="audio/mpeg" />;
  } else if (props.topic === 2) {
    return <source src={mp3LegalTema6} type="audio/mpeg" />;
  } else if (props.topic === 3)
    return <source src={mp3LegalTema7} type="audio/mpeg" />;
}
