import { SLIDER_TOGGLE } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case SLIDER_TOGGLE:
      return {
        ...state,
        showSidebar: action.payload,
      };
    default:
      break;
  }
};
