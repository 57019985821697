import React, { useReducer } from 'react';
import PagosPendientesContext from './pagosPendientesContext';
import PagosPendientesReducer from './pagosPendientesReducer';

import clienteAxios from '../../config/axios';
import {
    PAGOS_PENDIENTES_EMPRESAS_CLEANER,
    PAGOS_PENDIENTES_EMPRESAS_ERROR,
    PAGOS_PENDIENTES_EMPRESAS_EXITOSO,
    PAGOS_PENDIENTES_EMPRESAS_LOADIN,

    PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO,

    PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO,
    PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
    PAGOS_PENDIENTES_DATA_EXCEL_LOADIN,
    PAGOS_PENDIENTES_DATA_EXCEL_CLEANER,
} from "../../types/pagosPendientes";

const PagosPendientesState = props => {
    const initialState = {
        dataEmpresas: null,
        dataDeudaPeriodos: null,
        allDataExcel: null,
        loadingAllDataExcel: false,
        mensajeAlerta: null,
        loadingPage: false
    };

    const obtenerDeudasEmpresas = async () => {
        dispatch({
            type: PAGOS_PENDIENTES_EMPRESAS_LOADIN,
            payload: true
        });
        try {
            const respuesta = await clienteAxios.get("/deudas-pendientes");
            dispatch({
                type: PAGOS_PENDIENTES_EMPRESAS_EXITOSO,
                payload: respuesta.data,
            });
        } catch (error) {
            if (error.message === 'Network Error') {
                dispatch({
                    type: PAGOS_PENDIENTES_EMPRESAS_ERROR,
                    payload: {
                        code: 1, // error de red
                        msg: 'Su red presenta lentitud, por favor recargue la página.'
                    }
                })
            } else {
                if (error.response?.status === 500) {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESAS_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Error en los servicios, por favor recargue la página.'
                        }
                    })
                }
                else if (error.response?.status === 404) {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESAS_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Servicio no encontrado.'
                        }
                    })
                }
                else if (error.response?.data?.errors?.message === 'Unauthenticated.') {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESAS_ERROR,
                        payload: {
                            code: 2, // su token a expirado 
                            msg: 'Su sesión ha expirado, por favor vuelva a iniciar sesión.'
                        }
                    })
                }
            }
        }
    }

    const limpiarDeudasEmpresas = () => {
        dispatch({
            type: PAGOS_PENDIENTES_EMPRESAS_CLEANER
        })
    }

    const obtenerDeudaPeriodos = async (ruc) => {
        dispatch({
            type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN,
            payload: true
        });

        try {
            const respuesta = await clienteAxios.get(`/deudas-pendientes/${ruc}/periodos`);
            dispatch({
                type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO,
                payload: respuesta.data,
            });
        } catch (error) {
            if (error.message === 'Network Error') {
                dispatch({
                    type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR,
                    payload: {
                        code: 1, // error de red
                        msg: 'Su red presenta lentitud, por favor recargue la página.'
                    }
                })
            } else {
                if (error.response?.status === 500) {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Error en los servicios, por favor recargue la página.'
                        }
                    })
                }
                else if (error.response?.status === 404) {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESAS_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Servicio no encontrado.'
                        }
                    })
                }
                else if (error.response?.data?.errors?.message === 'Unauthenticated.') {
                    dispatch({
                        type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR,
                        payload: {
                            code: 2, // su token a expirado 
                            msg: 'Su sesión ha expirado, por favor vuelva a iniciar sesión.'
                        }
                    })
                }
            }
        }
    }

    const limpiarDeudaPeriodos = () => {
        dispatch({
            type: PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER
        })
    }

    const obtenerAllDataExcel = async (ruc = '') => {
        let datos = new FormData();
        datos.set("ruc", ruc);

        dispatch({
            type: PAGOS_PENDIENTES_DATA_EXCEL_LOADIN,
            payload: true
        });

        try {
            const respuesta = await clienteAxios.post('/deudas-pendientes/historial/excel', datos);
            dispatch({
                type: PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO,
                payload: respuesta.data,
            });
        } catch (error) {
            if (error.message === 'Network Error') {
                dispatch({
                    type: PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
                    payload: {
                        code: 1, // error de red
                        msg: 'Su red presenta lentitud, por favor recargue la página.'
                    }
                })
            } else {
                if (error.response?.status === 500) {
                    dispatch({
                        type: PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Error en los servicios, por favor recargue la página.'
                        }
                    })
                }
                else if (error.response?.status === 404) {
                    dispatch({
                        type: PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Servicio no encontrado.'
                        }
                    })
                }
                else if (error.response?.data?.errors?.message === 'Unauthenticated.') {
                    dispatch({
                        type: PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
                        payload: {
                            code: 2, // su token a expirado 
                            msg: 'Su sesión ha expirado, por favor vuelva a iniciar sesión.'
                        }
                    })
                }
            }
        }
    }

    const limpiarAllDataExcel = () => {
        dispatch({
            type: PAGOS_PENDIENTES_DATA_EXCEL_CLEANER,
        })
    }


    const [state, dispatch] = useReducer(PagosPendientesReducer, initialState);
    return (
        <PagosPendientesContext.Provider
            value={{
                dataEmpresas: state.dataEmpresas,
                dataDeudaPeriodos: state.dataDeudaPeriodos,
                allDataExcel: state.allDataExcel,
                loadingAllDataExcel: state.loadingAllDataExcel,
                loadingPage: state.loadingPage,
                mensajeAlerta: state.mensajeAlerta,
                obtenerDeudasEmpresas,
                limpiarDeudasEmpresas,
                obtenerDeudaPeriodos,
                limpiarDeudaPeriodos,
                obtenerAllDataExcel,
                limpiarAllDataExcel
            }}
        >
            {props.children}
        </PagosPendientesContext.Provider>
    );
};

export default PagosPendientesState;