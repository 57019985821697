import React, { useState, useEffect } from 'react';
import { DetalleMonto, Residencia } from './components';
export default function Mensual(props) {
    const [showView, setShowView] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showView]);


    const renderView = () => {
        switch (showView) {
            case 1:
                return <DetalleMonto getBackOneStep={props.getBackOneStep} nextView={() => setShowView(2)} />;

            case 2:
                return <Residencia prevView={() => setShowView(1)}  processId={props.processId} endProcess={props.endProcess} />;
            default:
                return (<p></p>)
        }
    }


    return (
        <div>
            {renderView()}
        </div>
    )
}
