import React, { useState } from "react";
import styled from "styled-components";
import { Box, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { Button } from "../../../../components";
import LyricsMusic from "./lyricsMusicPlayer/LyricMusicPlayer";
import dummyLyeric from "../../../../Dummys/dummyLyeric.json";
import dummyLyeric65 from "../../../../Dummys/dummyLyeric65.json";

import svgTiempo from "../../../../assets/tiempo.svg";

const ContentLyrics = styled(Box)`
  text-align: center;
  & h4 {
    ${(props) => props.theme.tipography.h5};
    margin-bottom: 16px;
  }
  & h6,
  span {
    ${(props) => props.theme.tipography.caption};
    margin-bottom: 4px;
  }
  @media (min-width: 980px) {
    text-align: left;
  }

  .time-row-cambio-fondo {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  }
  .time-row-cambio-fondo span {
    display: inline-block;
    background-color: #cfdffd;
    color: #196691;
    padding: 8px;
    text-align: center;
    width: 100px;
    border-radius: 15px;
  }
  .time-row-cambio-fondo span img {
    width: 13px;
    margin-right: 8px;
  }
  & .break-line {
    background: #cdced0;
    height: 1px;
    width: 100%;
  }
`;

const LabelCheckbox = styled(FormControlLabel)`
  &.MuiFormControlLabel-root .MuiFormControlLabel-label {
    ${(props) => props.theme.tipography.body1};
    text-align: left;l    color: ${(props) =>
      props.colorLabel ? props.colorLabel : "#acafb3"};
  }

  & .MuiCheckbox-root svg {
    fill: ${(props) => (props.color ? props.color : "#acafb3")};
  }
`;
const Index = (props) => {
  const { setNextView, setOpenConfirm, validacionEtario } = props;
  const [endVideo, setEndVideo] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [durationVideo, setDurationVideo] = useState("0");
  return (
    <ContentLyrics>
      <div className="time-row-cambio-fondo">
        <span>
          <img src={svgTiempo} alt="tiempo" />
          {durationVideo} minuto
        </span>
      </div>
      <h6>{"Tema"}</h6>
      <h4>{"Cambio de tipo de fondo etario"}</h4>
      <div className="break-line"></div>
      <Box sx={{ maxWidth: "603px", margin: "auto" }}>
        <LyricsMusic
          asesoria="reja"
          currentTopicData={
            validacionEtario.tipo_etario === 60 ? dummyLyeric : dummyLyeric65
          }
          validacionEtario={validacionEtario}
          setEndVideo={setEndVideo}
          setDurationVideo={setDurationVideo}
        />
        <LabelCheckbox
          color={endVideo ? (activeButton ? "#CE1336" : "#363A3E") : "#acafb3"}
          colorlabel={endVideo ? "#363A3E" : "#acafb3"}
          control={
            <Checkbox
              disabled={!endVideo}
              checked={activeButton ? true : false}
              onClick={() => {
                setActiveButton(!activeButton);
              }}
            />
          }
          label="Acepto haber recibido y entendido la información brindada"
        />
        <Grid container spacing={1} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => {
                setNextView(true);
                setOpenConfirm(true);
              }}
              disabled={activeButton === false}
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ContentLyrics>
  );
};

export default Index;
