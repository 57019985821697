import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import ReactGa from "react-ga";

import AuthContext from "../../../context/autenticacion/authContext";
import MisDatosContext from "../../../context/misdatos/misdatosContext";
import ModalContext from "../../../context/modal/modalContext";
import Loading from "../../../components/Loading/index.jsx";

import Datos from "./Datos";
import Direcciones from "./Direcciones";
import SolicitudProcesadaUsuario from "./SolicitudProcesadaUsuario";
import SolicitudProcesadaDirecciones from "./SolicitudProcesadaDirecciones";
import { CardContainer, Page } from "../../../components";
import Grid from "@mui/material/Grid";
const MisDatos = () => {
  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misDatosContext = useContext(MisDatosContext);
  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    loadingPage,
    obtenerMisDatosGet,
    resultadoDatosPost,
    resultadoDireccionPost,
    limpiarVariablesMisDatosParcial,
  } = misDatosContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const [openModalToken, setOpenModalToken] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaDatosGet) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerMisDatosGet();
    }

    return () => {
      limpiarVariablesMisDatosParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlertaDatosGet]);
  useEffect(() => {
    if (resultadoDatosPost) {
      if (resultadoDatosPost.r_respuesta === -3) {
        setMostrarModalToken(resultadoDatosPost);
        setOpenModalToken(false);
      } else {
        setMostrarModalToken(null);
        setOpenModalToken(false);
      }
    }
  }, [resultadoDatosPost]);
  useEffect(() => {
    if (resultadoDireccionPost) {
      if (resultadoDireccionPost.r_respuesta === -3) {
        setMostrarModalToken(resultadoDireccionPost);
        setOpenModalToken(false);
      } else {
        setMostrarModalToken(null);
        setOpenModalToken(false);
      }
    }
  }, [resultadoDireccionPost]);
  const mostrarMensaje = () => {
    setMessage(mensajeAlertaDatosGet);
  };

  if (mensajeAlertaDatosGet !== null) return <p></p>;
  if (misDatosGet === null) return <Loading />;
  return (
    <Page title="Actualización de datos" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <CardContainer>
            <ul className="list-steps-datos">
              <li
                onClick={() => {
                  obtenerMisDatosGet();
                  setOpcionSeleccionada(1);
                  //DATALAYER CONFIGURACIONES USUARIO : DATOS PERSONALES
                  window.dataLayer.push({
                    event: "button_click",
                    option: "actualizar",
                  });
                }}
                className={
                  opcionSeleccionada === 1 ? "active-selected-datos" : ""
                }
              >
                Datos personales
              </li>
              <li
                onClick={() => {
                  obtenerMisDatosGet();
                  setOpcionSeleccionada(2);
                }}
                className={
                  opcionSeleccionada === 2 ? "active-selected-datos" : ""
                }
              >
                Dirección
              </li>
            </ul>
            {opcionSeleccionada === 1 ? (
              <>
                {resultadoDatosPost === null ||
                (resultadoDatosPost &&
                  resultadoDatosPost.r_respuesta === -3) ? (
                  <Datos
                    info={misDatosGet.r_persona.r_datos}
                    listaCodigos={misDatosGet.r_utilitarios.r_prefijos_telefono}
                    mostrarModalToken={mostrarModalToken}
                    setOpenModalToken={setOpenModalToken}
                    openModalToken={openModalToken}
                    setMostrarModalToken={setMostrarModalToken}
                    recargar={obtenerMisDatosGet}
                  />
                ) : (
                  <SolicitudProcesadaUsuario
                    loading={loadingPage}
                    recargar={obtenerMisDatosGet}
                    info={resultadoDatosPost}
                  />
                )}
              </>
            ) : (
              <>
                {resultadoDireccionPost === null ||
                (resultadoDireccionPost &&
                  resultadoDireccionPost.r_respuesta === -3) ? (
                  <Direcciones
                    info={misDatosGet.r_persona.r_direccion}
                    listas={misDatosGet.r_utilitarios}
                    mostrarModalToken={mostrarModalToken}
                    setOpenModalToken={setOpenModalToken}
                    openModalToken={openModalToken}
                    setMostrarModalToken={setMostrarModalToken}
                    recargar={obtenerMisDatosGet}
                  />
                ) : (
                  <SolicitudProcesadaDirecciones
                    loading={loadingPage}
                    recargar={obtenerMisDatosGet}
                    info={resultadoDireccionPost}
                  />
                )}
              </>
            )}
          </CardContainer>
        </Grid>
        {/* <Informacion /> */}
      </Grid>
    </Page>
  );
};

export default MisDatos;
