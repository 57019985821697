import React, { useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  InfoTooltipComponent,
  InfoButton,
  TooltipContent,
  TooltipArrow,
} from "./styles";
import * as Tooltip from "@radix-ui/react-tooltip";
import PropTypes from "prop-types";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const InfoTooltip = ({
  open,
  onOpenChange,
  delayDuration,
  side,
  onClick,
  align,
  children,
  defaultOpen,
  tooltip,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleOnClick = () => setIsOpen(!isOpen);
  const selectOnClick = onClick || handleOnClick;
  const closeTooltip = () => setIsOpen(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <InfoTooltipComponent>
      <Tooltip.Provider>
        <Tooltip.Root
          open={tooltip !== false && isOpen}
          onOpenChange={onOpenChange}
          delayDuration={delayDuration}
          defaultOpen={defaultOpen}
        >
          <Tooltip.Trigger
            asChild
            onClick={selectOnClick}
            ref={ref}
            type="button"
            onMouseLeave={closeTooltip}
          >
            <InfoButton className="IconButton">
              <AiOutlineInfoCircle />
            </InfoButton>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <TooltipContent sideOffset={4} side={side} align={align}>
              {children}
              <TooltipArrow />
            </TooltipContent>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </InfoTooltipComponent>
  );
};
export default InfoTooltip;

InfoTooltip.propTypes = {
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  delayDuration: PropTypes.number,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  children: "Tooltip",
  open: false,
  disableHoverableContent: false,
};
