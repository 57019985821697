import styled, { css } from "styled-components";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import { device } from "../../../../../../../utils/device";

export const ContainerAsesoria = styled.div`
  text-align: left;
  ${(p) => p.theme.tipography.bodySmallRegular}

  & .HBT_PMO_TitleContent {
    margin-bottom: 16px;
    ${(p) => p.theme.tipography.h4}
  }
`;

export const ContentAmount = styled(Box)`
  text-align: left;

  & h2 {
    ${(p) => p.theme.tipography.h6}
  }
  & h3 {
    ${(p) => p.theme.tipography.subtitleS}
    color: ${(p) => p.theme.palette.secondary.main};
  }

  & .MuiRadio-root {
    padding: 0;
  }
`;

export const InfoText = styled(Box)`
  display: flex;
  color: ${(p) => p.theme.palette.grayscale[300]};
  align-items: flex-start;
  margin-bottom: 8px;
  & a:not([href]):not([class]):hover,
  & a:not([href]):not([class]) {
    text-decoration: underline;
    color: ${(p) => p.theme.palette.secondary.main};
    cursor: pointer;
  }
  ${(p) =>
    p.variant === "default" &&
    css`
      color: ${(p) => p.theme.palette.grayscale.dark};
    `}
  &
    p {
    margin-bottom: 0;
  }
`;

export const HeaderAccordion = styled(Box)`
  display: flex;
  align-items: center;

  ${css`
    && {
      @media screen and ${device.tablet} {
        justify-content: space-between;
      }
    }
  `}
  color: ${(p) => p.theme.palette.secondary.main};
  ${(p) => p.theme.tipography.h6}

  & .HBT_PMO_AccordionHeader_number {
    margin-right: 8px;
    border-radius: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(p) => p.theme.palette.secondary.main};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${(p) =>
    p.disabled === "disabled" &&
    css`
      color: ${(p) => p.theme.palette.grayscale.blocked};

      & .HBT_PMO_AccordionHeader_number {
        border-color: ${(p) => p.theme.palette.grayscale.blocked};
      }
    `}
`;

export const HeadingTextAccordion = styled(Box)`
  margin-bottom: 16px;
`;

export const ContentTable = styled(TableContainer)`
  border: 1px solid #ededed;
  border-radius: 8px;
  margin-bottom: 16px;
  & .MuiTableHead-root {
    background-color: ${(p) => p.theme.palette.grayscale[50]};
  }

  & .HBT_PMO_BtnDelete {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .HBT_PMO_BtnDeleteIcon {
    border-radius: 24px;
    border-style: solid;
    border-width: 1px;
    margin-left: 8px;
    border-color: ${(p) => p.theme.palette.grayscale[100]};
    padding: 4px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ContentCheckbox = styled(Box)`
  border: 1px solid #cdced0;
  border-radius: 16px;
  margin-bottom: 16px;
`;

export const ContentFondos = styled(Box)`
  display: flex;
  border-radius: 16px;
  margin: -16px;
  ${(p) => p.theme.tipography.bodySmallRegular}
  & .HBT_PMO_ContentIconFondos {
    background-color: ${(p) => p.theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px 0px 0px 16px;
    padding: 16px 0 16px 16px;
  }

  & .HBT_PMO_ContentTextFondos {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px;
    & h3 {
      margin-bottom: 0;
    }
  }
  & p {
    margin-bottom: 0;
  }

  & h2,
  h3 {
    ${(p) => p.theme.tipography.h6}
  }

  & .MuiFormControlLabel-root {
    margin: 0;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }
`;

export const ContentResult = styled(Box)`
  display: flex;
  padding: 16px;
  justify-content: flex-start;

  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.secondary.lightest};
  width: 100%;
  ${css`
    && {
      @media screen and ${device.tablet} {
        justify-content: center;
      }
    }
  `}
  & p {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    ${(p) => p.theme.tipography.bodySmallRegular}
  }
  & span {
    ${(p) => p.theme.tipography.subtitle1}
  }
  margin-bottom: 16px;
`;

export const ResultAmountCalc = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  ${(p) => p.theme.tipography.bodySmallRegular}
  background-color: ${(p) => p.theme.palette.lead[100]};
  border-radius: 16px;
  & .HBT_PMO_LabelTextFunds {
    width: 165px;
    border-right: 1px solid #ededed;
    padding: 16px 16px 16px 16px;
    ${(p) => p.theme.tipography.subtitleS}
  }
  & .HBT_PMO_LabelTextAmount {
    padding: 16px 24px;
    display: grid;
    align-items: center;
  }
  ${css`
    && {
      @media screen and ${device.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 8px;

        & .HBT_PMO_LabelTextFunds {
          width: 100%;
          border-right: 1px solid #ededed;
          padding: 16px 0 16px 16px;
          ${(p) => p.theme.tipography.subtitleS}
        }
      }
    }
  `}
`;
export const AmountToday = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  ${(p) => p.theme.tipography.bodySmallRegular}
  & .HBT_PMO_LabelTextFunds {
    width: 165px;
    padding: 16px 16px 16px 16px;
    ${(p) => p.theme.tipography.subtitleS}
  }
  & .HBT_PMO_LabelTextAmount {
    padding: 16px 24px;
    display: grid;
    align-items: center;
  }
  ${css`
    && {
      @media screen and ${device.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 8px;

        & .HBT_PMO_LabelTextFunds {
          width: 100%;
          border-right: 1px solid #ededed;
          padding: 16px 0 16px 16px;
          ${(p) => p.theme.tipography.subtitleS}
        }
      }
    }
  `}
`;
export const CalcAmount = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & h2 {
    ${(p) => p.theme.tipography.subtitleS}
  }

  & h3 {
    ${(p) => p.theme.tipography.h3}
    color: ${(p) => p.theme.palette.secondary.main};
  }
`;

export const TextLayaout = styled(Box)`
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.palette.grayscale[300]};
  ${(p) => p.theme.tipography.caption2}
  text-align: center;
  margin-bottom: 16px;
`;

export const ContentAcordion = styled(Box)`
  margin-bottom: 32px;
`;

export const ContentSubtitle = styled(Box)`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 24px;
  & h4 {
    margin-bottom: 0;
    ${(p) => p.theme.tipography.h5};
  }
  & a {
    color: ${(p) => p.theme.palette.primary.main};
    ${(p) => p.theme.tipography.subtitle2};
    text-decoration: none;
    &:not([href]):not([class]) {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

export const ContentSelect = styled(Box)``;

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto;

  column-gap: 8px;
  margin-bottom: 0;
  ${css`
    && {
      @media screen and ${device.desktop} {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 16px;
      }
    }
  `}
`;
export const GridContainerResult = styled(Box)`
  display: grid;
  grid-template-columns: auto;
  column-gap: 24px;
  margin-bottom: 0;
  ${css`
    && {
      @media screen and ${device.tablet} {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 16px;
      }
    }
  `}
`;
