import React from "react";

const ModalDetalle = ({
  dispositivo,
  buttonCloseTop,
  closeModal,
  className,
  description,
}) => {
  const renderInfo = () => {
    if (description) {
      return description;
    } else {
      return (
        <>
          <p>
            Debes ser el titular de la cuenta bancaria. No se admiten cuentas
            mancomunadas ni a nombre de otras personas.
          </p>

          <p>
            Para validar tu cuenta debes adjuntar cualquier documento emitido
            por el banco, que contenga los siguientes datos: nombre o logotipo
            del banco, tipo y número de cuenta completo y tus nombres y
            apellidos.
          </p>
        </>
      );
    }
  };

  return (
    <section className={`wraper-modal wraper-modal-dj`}>
      <div className={`modal-content ${className}`}>
        <div className="modal-close">
          <i className="fa fa-times-circle-o" onClick={() => closeModal()}></i>
        </div>

        <div
          className={
            buttonCloseTop
              ? `mb-modal-title d-flex mt-3`
              : `mb-modal-title d-flex`
          }
        >
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>Consideraciones</strong>
          </p>
        </div>
        <section className="section-info-import">
          {/* {info} */}
          {renderInfo()}
        </section>
      </div>
    </section>
  );
};

export default ModalDetalle;
