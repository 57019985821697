import React from "react";

const TablaDesktop = ({ data }) => {
  const columnas = (objeto) => {
    let nuevoArreglo = [];
    Object.keys(objeto).forEach((element) => {
      nuevoArreglo.push(element);
    });
    return nuevoArreglo.map((item, index) => (
      <td key={index} className="real-body-item">
        {objeto[item]}
      </td>
    ));
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {data.header.map((item, index) => (
            <th key={index} className="">
              {item.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="original-body-resumen">
        {data.body.map((objeto, index) => (
          <tr key={index}>{columnas(objeto)}</tr>
        ))}
        {/* <tr key={10000000}><td></td><td></td><td></td></tr> */}
      </tbody>
    </table>
  );
};

export default TablaDesktop;
