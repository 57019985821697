export const JUBILACION_POST_EXITOSO = "JUBILACION_POST_EXITOSO";
export const JUBILACION_POST_ERROR = "JUBILACION_POST_ERROR";
export const JUBILACION_POST_LOADIN = "JUBILACION_POST_LOADIN";
export const JUBILACION_CLEANER = "JUBILACION_CLEANER";
export const JUBILACION_CLEANER_PARCIAL = "JUBILACION_CLEANER_PARCIAL";
export const MENU_DATA = "MENU_DATA";

export const OBTENER_PROCESO_JUBILACION_LOADIN =
  "OBTENER_PROCESO_JUBILACION_LOADIN";
export const OBTENER_PROCESO_JUBILACION_EXITOSO =
  "OBTENER_PROCESO_JUBILACION_EXITOSO";
export const OBTENER_PROCESO_JUBILACION_ERROR =
  "OBTENER_PROCESO_JUBILACION_ERROR";
export const OBTENER_PROCESO_JUBILACION_CLEANER =
  "OBTENER_PROCESO_JUBILACION_CLEANER";

export const CREAR_PROCESO_JUBILACION_LOADIN =
  "CREAR_PROCESO_JUBILACION_LOADIN";
export const CREAR_PROCESO_JUBILACION_EXITOSO =
  "CREAR_PROCESO_JUBILACION_EXITOSO";
export const CREAR_PROCESO_JUBILACION_ERROR = "CREAR_PROCESO_JUBILACION_ERROR";
export const CREAR_PROCESO_JUBILACION_CLEANER =
  "CREAR_PROCESO_JUBILACION_CLEANER";

export const ACTUALIZAR_PROCESO_JUBILACION_LOADIN =
  "ACTUALIZAR_PROCESO_JUBILACION_LOADIN";
export const ACTUALIZAR_PROCESO_JUBILACION_EXITOSO =
  "ACTUALIZAR_PROCESO_JUBILACION_EXITOSO";
export const ACTUALIZAR_PROCESO_JUBILACION_ERROR =
  "ACTUALIZAR_PROCESO_JUBILACION_ERROR";
export const ACTUALIZAR_PROCESO_JUBILACION_CLEANER =
  "ACTUALIZAR_PROCESO_JUBILACION_CLEANER";

export const OBTENER_PROCESO_LEGAL_LOADIN = "OBTENER_PROCESO_LEGAL_LOADIN";
export const OBTENER_PROCESO_LEGAL_EXITOSO = "OBTENER_PROCESO_LEGAL_EXITOSO";
export const OBTENER_PROCESO_LEGAL_ERROR = "OBTENER_PROCESO_LEGAL_ERROR";
export const OBTENER_PROCESO_LEGAL_CLEANER = "OBTENER_PROCESO_LEGAL_CLEANER";

export const CREAR_PROCESO_LEGAL_LOADIN = "CREAR_PROCESO_LEGAL_LOADIN";
export const CREAR_PROCESO_LEGAL_EXITOSO = "CREAR_PROCESO_LEGAL_EXITOSO";
export const CREAR_PROCESO_LEGAL_ERROR = "CREAR_PROCESO_LEGAL_ERROR";
export const CREAR_PROCESO_LEGAL_CLEANER = "CREAR_PROCESO_LEGAL_CLEANER";

export const OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN =
  "OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN";
export const OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO =
  "OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO";
export const OBTENER_CONTENIDO_PASO_JUBILACION_ERROR =
  "OBTENER_CONTENIDO_PASO_JUBILACION_ERROR";
export const OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER =
  "OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER";

export const GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN =
  "GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN";
export const GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO =
  "GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO";
export const GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR =
  "GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR";
export const GUARDAR_AVANCE_TRAMITE_JUBILACION_CLEANER =
  "GUARDAR_AVANCE_TRAMITE_JUBILACION_CLEANER";

export const OBTENER_PROCESO_REJA_LOADIN = "OBTENER_PROCESO_REJA_LOADIN";
export const OBTENER_PROCESO_REJA_EXITOSO = "OBTENER_PROCESO_REJA_EXITOSO";
export const OBTENER_PROCESO_REJA_ERROR = "OBTENER_PROCESO_REJA_ERROR";
export const OBTENER_PROCESO_REJA_CLEANER = "OBTENER_PROCESO_REJA_CLEANER";

export const CREAR_PROCESO_REJA_LOADIN = "CREAR_PROCESO_REJA_LOADIN";
export const CREAR_PROCESO_REJA_EXITOSO = "CREAR_PROCESO_REJA_EXITOSO";
export const CREAR_PROCESO_REJA_ERROR = "CREAR_PROCESO_REJA_ERROR";
export const CREAR_PROCESO_REJA_CLEANER = "CREAR_PROCESO_REJA_CLEANER";

export const OBTENER_PASO_DOS_REJA_LOADIN = "OBTENER_PASO_DOS_REJA_LOADIN";
export const OBTENER_PASO_DOS_REJA_EXITOSO = "OBTENER_PASO_DOS_REJA_EXITOSO";
export const OBTENER_PASO_DOS_REJA_ERROR = "OBTENER_PASO_DOS_REJA_ERROR";
export const OBTENER_PASO_DOS_REJA_CLEANER = "OBTENER_PASO_DOS_REJA_CLEANER";

export const GUARDAR_PASO_DOS_REJA_LOADIN = "GUARDAR_PASO_DOS_REJA_LOADIN";
export const GUARDAR_PASO_DOS_REJA_EXITOSO = "GUARDAR_PASO_DOS_REJA_EXITOSO";
export const GUARDAR_PASO_DOS_REJA_ERROR = "GUARDAR_PASO_DOS_REJA_ERROR";
export const GUARDAR_PASO_DOS_REJA_CLEANER = "GUARDAR_PASO_DOS_REJA_CLEANER";

export const OBTENER_PARENTESCOS_REJA_LOADIN =
  "OBTENER_PARENTESCOS_REJA_LOADIN";
export const OBTENER_PARENTESCOS_REJA_EXITOSO =
  "OBTENER_PARENTESCOS_REJA_EXITOSO";
export const OBTENER_PARENTESCOS_REJA_ERROR = "OBTENER_PARENTESCOS_REJA_ERROR";
export const OBTENER_PARENTESCOS_REJA_CLEANER =
  "OBTENER_PARENTESCOS_REJA_CLEANER";

export const REJA_SGA_VALIDAR_PENSION_LOADING =
  "REJA_SGA_VALIDAR_PENSION_LOADING";
export const REJA_SGA_VALIDAR_PENSION_EXITOSO =
  "REJA_SGA_VALIDAR_PENSION_EXITOSO";
export const REJA_SGA_VALIDAR_PENSION_ERROR = "REJA_SGA_VALIDAR_PENSION_ERROR";
export const REJA_SGA_VALIDAR_PENSION_CLEANER =
  "REJA_SGA_VALIDAR_PENSION_CLEANER";

export const REJA_ESTIMACION_LOADING = "REJA_ESTIMACION_LOADING";
export const REJA_ESTIMACION_EXITOSO = "REJA_ESTIMACION_EXITOSO";
export const REJA_ESTIMACION_ERROR = "REJA_ESTIMACION_ERROR";
export const REJA_ESTIMACION_CLEANER = "REJA_ESTIMACION_CLEANER";

export const REJA_PDF_ESTIMACION_LOADING = "REJA_PDF_ESTIMACION_LOADING";
export const REJA_PDF_ESTIMACION_EXITOSO = "REJA_PDF_ESTIMACION_EXITOSO";
export const REJA_PDF_ESTIMACION_ERROR = "REJA_PDF_ESTIMACION_ERROR";
export const REJA_PDF_ESTIMACION_CLEANER = "REJA_PDF_ESTIMACION_CLEANER";

export const CREAR_PROCESO_ETAPA1_LOADIN = "CREAR_PROCESO_ETAPA1_LOADIN";
export const CREAR_PROCESO_ETAPA1_EXITOSO = "CREAR_PROCESO_ETAPA1_EXITOSO";
export const CREAR_PROCESO_ETAPA1_ERROR = "CREAR_PROCESO_ETAPA1_ERROR";
export const CREAR_PROCESO_ETAPA1_CLEANER = "CREAR_PROCESO_ETAPA1_CLEANER";

export const OBTENER_TEMAS_ASESORIA_REJA_LOADIN =
  "OBTENER_TEMAS_ASESORIA_REJA_LOADIN";
export const OBTENER_TEMAS_ASESORIA_REJA_EXITOSO =
  "OBTENER_TEMAS_ASESORIA_REJA_EXITOSO";
export const OBTENER_TEMAS_ASESORIA_REJA_ERROR =
  "OBTENER_TEMAS_ASESORIA_REJA_ERROR";
export const OBTENER_TEMAS_ASESORIA_REJA_CLEANER =
  "OBTENER_TEMAS_ASESORIA_REJA_CLEANER";

export const CREAR_PROCESO_ETAPA2_LOADIN = "CREAR_PROCESO_ETAPA2_LOADIN";
export const CREAR_PROCESO_ETAPA2_EXITOSO = "CREAR_PROCESO_ETAPA2_EXITOSO";
export const CREAR_PROCESO_ETAPA2_ERROR = "CREAR_PROCESO_ETAPA2_ERROR";
export const CREAR_PROCESO_ETAPA2_CLEANER = "CREAR_PROCESO_ETAPA2_CLEANER";

export const CREAR_PROCESO_ETAPA3_LOADIN = "CREAR_PROCESO_ETAPA3_LOADIN";
export const CREAR_PROCESO_ETAPA3_EXITOSO = "CREAR_PROCESO_ETAPA3_EXITOSO";
export const CREAR_PROCESO_ETAPA3_ERROR = "CREAR_PROCESO_ETAPA3_ERROR";
export const CREAR_PROCESO_ETAPA3_CLEANER = "CREAR_PROCESO_ETAPA3_CLEANER";

export const OBTENER_MARCO_BONO_LOADIN = "OBTENER_MARCO_BONO_LOADIN";
export const OBTENER_MARCO_BONO_EXITOSO = "OBTENER_MARCO_BONO_EXITOSO";
export const OBTENER_MARCO_BONO_ERROR = "OBTENER_MARCO_BONO_ERROR";
export const OBTENER_MARCO_BONO_CLEANER = "OBTENER_MARCO_BONO_CLEANER";

export const CREAR_PROCESO_ETAPA4_LOADIN = "CREAR_PROCESO_ETAPA4_LOADIN";
export const CREAR_PROCESO_ETAPA4_EXITOSO = "CREAR_PROCESO_ETAPA4_EXITOSO";
export const CREAR_PROCESO_ETAPA4_ERROR = "CREAR_PROCESO_ETAPA4_ERROR";
export const CREAR_PROCESO_ETAPA4_CLEANER = "CREAR_PROCESO_ETAPA4_CLEANER";

export const CREAR_PROCESO_ETAPA5_LOADIN = "CREAR_PROCESO_ETAPA5_LOADIN";
export const CREAR_PROCESO_ETAPA5_EXITOSO = "CREAR_PROCESO_ETAPA5_EXITOSO";
export const CREAR_PROCESO_ETAPA5_ERROR = "CREAR_PROCESO_ETAPA5_ERROR";
export const CREAR_PROCESO_ETAPA5_CLEANER = "CREAR_PROCESO_ETAPA5_CLEANER";

export const OBTENER_MARCO_PENSION_LOADIN = "OBTENER_MARCO_PENSION_LOADIN";
export const OBTENER_MARCO_PENSION_EXITOSO = "OBTENER_MARCO_PENSION_EXITOSO";
export const OBTENER_MARCO_PENSION_ERROR = "OBTENER_MARCO_PENSION_ERROR";
export const OBTENER_MARCO_PENSION_CLEANER = "OBTENER_MARCO_PENSION_CLEANER";

export const CREAR_PROCESO_ETAPA6_LOADIN = "CREAR_PROCESO_ETAPA6_LOADIN";
export const CREAR_PROCESO_ETAPA6_EXITOSO = "CREAR_PROCESO_ETAPA6_EXITOSO";
export const CREAR_PROCESO_ETAPA6_ERROR = "CREAR_PROCESO_ETAPA6_ERROR";
export const CREAR_PROCESO_ETAPA6_CLEANER = "CREAR_PROCESO_ETAPA6_CLEANER";

export const CREAR_PROCESO_ETAPA7_LOADIN = "CREAR_PROCESO_ETAPA7_LOADIN";
export const CREAR_PROCESO_ETAPA7_EXITOSO = "CREAR_PROCESO_ETAPA7_EXITOSO";
export const OBTENER_PASO_6_REJA_DATOS = "OBTENER_PASO_DOS_REJA_DATOS";
export const CREAR_PROCESO_ETAPA7_ERROR = "CREAR_PROCESO_ETAPA7_ERROR";
export const CREAR_PROCESO_ETAPA7_CLEANER = "CREAR_PROCESO_ETAPA7_CLEANER";

export const ACCEDER_JAO_LOADING = "ACCEDER_JAO_LOADING";
export const ACCEDER_JAO_EXITOSO = "ACCEDER_JAO_EXITOSO";
export const ACCEDER_JAO_ERROR = "ACCEDER_JAO_ERROR";
export const ACCEDER_JAO_CLEAR = "ACCEDER_JAO_CLEAR";

export const GET_JAO_PROCESS_LOADING = "GET_JAO_PROCESS_LOADING";
export const GET_JAO_PROCESS_EXITOSO = "GET_JAO_PROCESS_EXITOSO";
export const GET_JAO_PROCESS_ERROR = "GET_JAO_PROCESS_ERROR";
export const GET_JAO_PROCESS_CLEAR = "GET_JAO_PROCESS_CLEAR";

export const GET_JAO_BENEFICIARIES_LOADING = "GET_JAO_BENEFICIARIES_LOADING";
export const GET_JAO_BENEFICIARIES_EXITOSO = "GET_JAO_BENEFICIARIES_EXITOSO";
export const GET_JAO_BENEFICIARIES_ERROR = "GET_JAO_BENEFICIARIES_ERROR";
export const GET_JAO_BENEFICIARIES_CLEAR = "GET_JAO_BENEFICIARIES_CLEAR";

export const SAVE_JAO_BENEFICIARIES_LOADING = "SAVE_JAO_BENEFICIARIES_LOADING";
export const SAVE_JAO_BENEFICIARIES_EXITOSO = "SAVE_JAO_BENEFICIARIES_EXITOSO";
export const SAVE_JAO_BENEFICIARIES_ERROR = "SAVE_JAO_BENEFICIARIES_ERROR";
export const SAVE_JAO_BENEFICIARIES_CLEAR = "SAVE_JAO_BENEFICIARIES_CLEAR";

export const GET_JAO_SALDOS_LOADING = "GET_JAO_SALDOS_LOADING";
export const GET_JAO_SALDOS_EXITOSO = "GET_JAO_SALDOS_EXITOSO";
export const GET_JAO_SALDOS_ERROR = "GET_JAO_SALDOS_ERROR";
export const GET_JAO_SALDOS_CLEAR = "GET_JAO_SALDOS_CLEAR";

export const SAVE_JAO_SALDOS_LOADING = "SAVE_JAO_SALDOS_LOADING";
export const SAVE_JAO_SALDOS_EXITOSO = "SAVE_JAO_SALDOS_EXITOSO";
export const SAVE_JAO_SALDOS_ERROR = "SAVE_JAO_SALDOS_ERROR";
export const SAVE_JAO_SALDO_CLEAR = "SAVE_JAO_SALDO_CLEAR";

export const SAVE_JAO_AVISO_LOADING = "SAVE_JAO_AVISO_LOADING";
export const SAVE_JAO_AVISO_EXITOSO = "SAVE_JAO_AVISO_EXITOSO";
export const SAVE_JAO_AVISO_ERROR = "SAVE_JAO_AVISO_ERROR";
export const SAVE_JAO_AVISO_CLEAR = "SAVE_JAO_AVISO_CLEAR";

export const GET_JAO_RESULTADO_LOADING = "GET_JAO_RESULTADO_LOADING";
export const GET_JAO_RESULTADO_EXITOSO = "GET_JAO_RESULTADO_EXITOSO";
export const GET_JAO_RESULTADO_ERROR = "GET_JAO_RESULTADO_ERROR";
export const GET_JAO_RESULTADO_CLEAR = "GET_JAO_RESULTADO_CLEAR";

export const RETIRO_RESETEAR = "RETIRO_RESETEAR";

export const GET_ACCEDE_SEGUNDO_RETIRO_LOADING =
  "GET_ACCEDE_SEGUNDO_RETIRO_LOADING";
export const GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO =
  "GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO";
export const GET_ACCEDE_SEGUNDO_RETIRO_ERROR =
  "GET_ACCEDE_SEGUNDO_RETIRO_ERROR";
export const GET_ACCEDE_SEGUNDO_RETIRO_CLEAR =
  "GET_ACCEDE_SEGUNDO_RETIRO_CLEAR";
export const TYPE_OF_PROCEDURE = {
  TYPE_REJA: 1,
  TYPE_LEGAL: 2,
  TYPE_SEGUNDA_DECISION: 3,
  TYPE_PMO: 4,
};

export const SEGUNDO_RETIRO_TYPE_BANK = "SEGUNDO_RETIRO_TYPE_BANK";
export const JUBILACION_LEGAL_TYPE_BANK = "JUBILACION_LEGAL_TYPE_BANK";
export const REJA_TYPE_BANK = "REJA_TYPE_BANK";