import React from "react";
import Button from "../Buttons";
import { MdOutlineClose } from "react-icons/md";

const CloseButton = ({ style, ...props }) => {
  return (
    <Button
      model={"circle"}
      variant={"secondary"}
      icon={<MdOutlineClose />}
      style={{ marginLeft: "auto", ...style }}
      {...props}
    />
  );
};

export default CloseButton;
