import {
  EC_MENSUAL_LISTA_MESES_EXITOSO,
  EC_MENSUAL_LISTA_MESES_ERROR,
  EC_MENSUAL_LISTA_MESES_LOADIN,
  EC_MENSUAL_EXITOSO,
  EC_MENSUAL_ERROR,
  EC_MENSUAL_LOADIN,
  EC_MENSUAL_CLEANER,
  EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL,
  EC_MENSUAL_CLEANER_ONLY_VARIABLE,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case EC_MENSUAL_LISTA_MESES_EXITOSO:
      return {
        ...state,
        listaDeOpciones: action.payload,
        loadingPage: false,
      };
    case EC_MENSUAL_EXITOSO:
      return {
        ...state,
        externalLink: action.payload,
        loadingBtn: false,
      };
    case EC_MENSUAL_LISTA_MESES_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case EC_MENSUAL_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case EC_MENSUAL_LISTA_MESES_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case EC_MENSUAL_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case EC_MENSUAL_CLEANER:
      return {
        ...state,
        listaDeOpciones: null,
        externalLink: null,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
      };
    case EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL:
      return {
        ...state,
        listaDeOpciones: null,
        externalLink: null,
        // mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
      };
    case EC_MENSUAL_CLEANER_ONLY_VARIABLE:
      return {
        ...state,
        externalLink: null
      };
    default:
      break;
  }
};
