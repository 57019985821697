import React, { useState, useContext } from "react";
import ModalToken from "../../../components/ModalToken";
import MisdatosContext from "../../../context/misdatos/misdatosContext";
import { monedas, meses } from "../../../data/DataLocal";
import { Button } from "../../../components";
import { Card, CardBody, Row, Col, FormGroup, Label, Input } from "reactstrap";
import CustomModal from "../../../components/custromModal/CustomModal";
import ModalErrorToken from "./ModalErrorToken";

const globalErrors = {
  banco: "",
  tipoCuenta: "",
  nroCuenta: "",
  fechaVenc: "",
};

const AgregarTarjeta = (props) => {
  const misdatosContext = useContext(MisdatosContext);
  const {
    agregarTarjetaPost,
    mensajeAlertaAgregarTarjetaPost,
    loadingBtnAgregarTarjetaPost,
  } = misdatosContext;
  const {
    bancos,
    tipoCuentas,
    mostrarModalToken,
    openModalToken,
    setOpenModalToken,
    setMostrarModalToken,
  } = props;
  const [filtroTipoCuenta, seFiltroTipoCuenta] = useState([]);
  const [formCard, setFormCard] = useState({
    banco: {
      v_codigo_banco: "",
      value: "",
    },
    tipoCuenta: {
      v_tipo_cuenta: "",
      value: "",
    },
    v_num_cuenta: "",
  });
  const [longitudNroCuenta, setLongitudNroCuenta] = useState(0);
  const [moneda, setMoneda] = useState(monedas.defaultSelect);
  const [errores, setErrores] = useState(globalErrors);

  const [modalInfo, setModalInfo] = useState(null);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [mostrarFecha, setMostrarFecha] = useState(false);
  const [yearTarjeta, setYearTarjeta] = useState("");
  const [monthTarjeta, setMonthTarjeta] = useState("");

  const handleBanco = (name) => (e) => {
    seFiltroTipoCuenta([]);
    setLongitudNroCuenta(0);
    setMostrarFecha(false);
    setYearTarjeta("");
    setMonthTarjeta("");
    let tipoCuentaLocal = {
      v_tipo_cuenta: "",
      value: "",
    };
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);

      let newListTipoCuenta = tipoCuentas.filter(
        (tipo) => tipo.r_banco === item.r_codigo
      );
      seFiltroTipoCuenta(newListTipoCuenta);
      setFormCard({
        ...formCard,
        banco: {
          v_codigo_banco: JSON.stringify(item),
          value: item.r_codigo,
        },
        tipoCuenta: tipoCuentaLocal,
        v_num_cuenta: "",
      });
    } else {
      setFormCard({
        ...formCard,
        banco: {
          v_codigo_banco: "",
          value: "",
        },
        tipoCuenta: tipoCuentaLocal,
        v_num_cuenta: "",
      });
    }
    setErrores(globalErrors);
  };

  const handlerTipoCuenta = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      // setTipoDeCuenta(JSON.stringify(opcion));
      setFormCard({
        ...formCard,
        tipoCuenta: {
          v_tipo_cuenta: JSON.stringify(opcion),
          value: opcion.r_codigo,
        },
        v_num_cuenta: "",
      });
      setLongitudNroCuenta(opcion.r_longitud);
      if (parseInt(opcion.r_fecha_vencimiento) === 1) {
        setMostrarFecha(true);
      } else {
        setMostrarFecha(false);
        setYearTarjeta("");
        setMonthTarjeta("");
      }
    } else {
      setFormCard({
        ...formCard,
        tipoCuenta: {
          v_tipo_cuenta: "",
          value: "",
        },
        v_num_cuenta: "",
      });
      setLongitudNroCuenta(0);
    }
    setErrores(globalErrors);
  };

  const handlerNroCuenta = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setFormCard({
      ...formCard,
      v_num_cuenta: valor,
    });
    setErrores(globalErrors);
  };

  const handlerYear = (e) => {
    setYearTarjeta(e.target.value);
    setMonthTarjeta("");
    setErrores(globalErrors);
  };
  const handlerMonth = (e) => {
    setMonthTarjeta(e.target.value);
    setErrores(globalErrors);
  };

  const listTipoCuenta = () => {
    if (filtroTipoCuenta.length > 0) {
      return filtroTipoCuenta.map((item, index) => (
        <option key={index} value={JSON.stringify(item)}>
          {item.r_descripcion}
        </option>
      ));
    } else {
      return "";
    }
  };

  const listAnio = () => {
    let date = new Date();
    let anioActual = date.getFullYear();
    let anios = [];
    for (let i = 0; i < 10; i++) {
      anios.push(anioActual++);
    }
    return anios.map((item, index) => (
      <option key={index} value={item}>
        {item}
      </option>
    ));
  };
  const procesarSolicitud = (token) => {
    const estructuraDatos = formatearDatos(token);
    agregarTarjetaPost(estructuraDatos.enviar);
    // setShowModalInfo(!showModalInfo);
  };
  const listMonth = (option) => {
    let date = new Date();
    let anioActual = date.getFullYear();
    let mesActual = date.getMonth();
    let localMeses = meses;
    if (option.length > 0) {
      if (parseInt(option) === parseInt(anioActual)) {
        localMeses = meses.slice(parseInt(mesActual), meses.length);
      }
      return localMeses.map((item, index) => (
        <option key={index} value={item.code}>
          {item.mes}
        </option>
      ));
    } else {
      return "";
    }
  };

  const validarData = () => {
    let mensajesError = {
      ...globalErrors,
    };

    if (formCard.banco.value.length === 0) {
      mensajesError = { ...mensajesError, banco: "campo requerido" };
    }

    if (formCard.tipoCuenta.value.length === 0) {
      mensajesError = { ...mensajesError, tipoCuenta: "campo requerido" };
    }

    if (formCard.v_num_cuenta.length === 0) {
      mensajesError = { ...mensajesError, nroCuenta: "campo requerido" };
    } else {
      if (formCard.v_num_cuenta.length < longitudNroCuenta) {
        mensajesError = {
          ...mensajesError,
          nroCuenta: `campo de ${longitudNroCuenta} digitos`,
        };
      }
    }

    if (mostrarFecha) {
      if (yearTarjeta.length === 0 || monthTarjeta.length === 0) {
        mensajesError = { ...mensajesError, fechaVenc: "campo requerido" };
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const formatearDatos = (token) => {
    let bodyFormData = new FormData();
    bodyFormData.set("v_codigo_banco", formCard.banco.value);
    bodyFormData.set("v_tipo_cuenta", formCard.tipoCuenta.value);
    bodyFormData.set("v_num_cuenta", formCard.v_num_cuenta);
    bodyFormData.set("v_codigo_moneda", moneda);
    bodyFormData.set("v_token_digital", token);
    mostrarFecha
      ? bodyFormData.set("v_fecha_venc", `${monthTarjeta}${yearTarjeta}`)
      : bodyFormData.set("v_fecha_venc", "");
    const data = {
      datos: {
        banco: JSON.parse(formCard.banco.v_codigo_banco).r_descripcion,
        tipoCuneta: JSON.parse(formCard.tipoCuenta.v_tipo_cuenta).r_descripcion,
        fechaVenc: mostrarFecha ? `${monthTarjeta}/${yearTarjeta}` : "",
        nroCuenta: formCard.v_num_cuenta,
        moneda: moneda === "1" ? "Soles" : "Dolares",
      },
      enviar: bodyFormData,
    };

    return data;
  };

  const procesar = () => {
    if (validarData()) {
      const estructuraDatos = formatearDatos();
      setModalInfo(estructuraDatos);
      setShowModalInfo(!showModalInfo);
    }
  };
  return (
    <div>
      <Card>
        <CardBody>
          <section>
            <Row>
              <Col md={mostrarFecha ? 4 : 6}>
                <FormGroup>
                  <Label htmlFor="v_codigo_banco">Escoge tu banco</Label>
                  <select
                    className="form-control"
                    name="v_codigo_banco"
                    onChange={handleBanco()}
                    value={formCard.banco.v_codigo_banco}
                  >
                    <option value="">- Selecciona tu banco</option>
                    {bancos.map((banco, index) => (
                      <option key={index} value={JSON.stringify(banco)}>
                        {banco.r_descripcion}
                      </option>
                    ))}
                  </select>
                  {errores.banco && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.banco}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={mostrarFecha ? 4 : 6}>
                <FormGroup>
                  <Label htmlFor="v_tipo_cuenta">Tipo de cuenta</Label>
                  <select
                    className="form-control"
                    name="v_tipo_cuenta"
                    onChange={handlerTipoCuenta}
                    value={formCard.tipoCuenta.v_tipo_cuenta}
                  >
                    <option value="">- Selecciona tu tipo de cuenta</option>
                    {listTipoCuenta()}
                  </select>
                  {errores.tipoCuenta && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.tipoCuenta}
                    </span>
                  )}
                </FormGroup>
              </Col>
              {mostrarFecha && (
                <Col md={4}>
                  <FormGroup>
                    <Label htmlFor="nombre">F. de vencimiento</Label>
                    <section className="d-flex justify-content-between">
                      <select
                        className="form-control mr-1"
                        value={yearTarjeta}
                        onChange={handlerYear}
                      >
                        <option value="">-año -</option>
                        {listAnio()}
                      </select>
                      <select
                        className="form-control"
                        value={monthTarjeta}
                        onChange={handlerMonth}
                      >
                        <option>- mes -</option>
                        {listMonth(yearTarjeta)}
                      </select>
                    </section>
                    {errores.fechaVenc && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.fechaVenc}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="r_tipo_cuenta">N.° de cuenta</Label>
                  <Input
                    placeholder="Número de cuenta"
                    onChange={handlerNroCuenta}
                    pattern="[0-9]*"
                    disabled={longitudNroCuenta === 0 ? true : false}
                    maxLength={longitudNroCuenta}
                    value={formCard.v_num_cuenta}
                  />
                  {errores.nroCuenta && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.nroCuenta}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </section>
        </CardBody>
      </Card>
      <section className=" d-flex justify-content-around">
        <Button onClick={procesar}>Crear</Button>
      </section>

      <CustomModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de creación de cuenta bancaria"
        description={
          modalInfo && (
            <>
              <div className="px-3 py-2">
                <p style={{ textAlign: "justify" }}>
                  Estás apunto de agregar la siguiente cuenta bancaria, con lo
                  siguientes datos. Si esta de acuerdo con esto, de clic en
                  Confirmar.
                </p>
              </div>

              <section className="mb-2">
                {modalInfo.datos.banco && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Banco:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.datos.banco}
                    </p>
                  </div>
                )}
                {modalInfo.datos.tipoCuneta && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Tipo de cuenta:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.datos.tipoCuneta}
                    </p>
                  </div>
                )}
                {modalInfo.datos.fechaVenc.length > 0 && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">F. Venc:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.datos.fechaVenc}
                    </p>
                  </div>
                )}
                {modalInfo.datos.nroCuenta && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">N.° de cuenta:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.datos.nroCuenta}
                    </p>
                  </div>
                )}
                {modalInfo.datos.moneda && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Moneda:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.datos.moneda}
                    </p>
                  </div>
                )}
                {mensajeAlertaAgregarTarjetaPost && (
                  <div className="alert alert-danger mt-2">
                    Hubo un problema por favor vuelva a intentarlo
                  </div>
                )}
              </section>
            </>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setOpenModalToken(true);
          setShowModalInfo(false);
        }}
        btnPrimaryLoading={loadingBtnAgregarTarjetaPost}
        textBtnPrimary="Confirmar"
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalErrorToken
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </div>
  );
};

export default AgregarTarjeta;
