import React, { createContext, useContext, useReducer } from "react";
import * as actionCreators from "./actionCreators";
import reducer, { initialState } from "./reducer";

export const MonthlyDebitDetailsContext = createContext();

export const MonthlyDebitDetailsProvider = ({ children, fakeState }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...fakeState,
  });

  const actionCreatorsList = Object.entries(actionCreators).reduce(
    (accumulator, currentValue) => {
      return Object.assign(accumulator, {
        [currentValue[0]]: currentValue[1](dispatch),
      });
    },
    {}
  );

  const value = {
    state,
    dispatch,
    ...actionCreatorsList,
  };

  return (
    <MonthlyDebitDetailsContext.Provider value={value}>
      {children}
    </MonthlyDebitDetailsContext.Provider>
  );
};

export const useMonthlyDebitDetailsContext = () => {
  // get the context
  const context = useContext(MonthlyDebitDetailsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error(
      "useMonthlyDebitDetailsContext was used outside of its Provider"
    );
  }

  return context;
};
