import React, { useState, useEffect } from "react";
import IC_CHECKED from "../../assets/icons/checked.svg";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
/* Iconos */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import styled from "styled-components";

const DivRentaFija = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? props.height : "114px")};
  background: ${(props) => (props.background ? props.background : "#C92D54")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivRentaVariable = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? props.height : "114px")};
  background: ${(props) => (props.background ? props.background : "#CDCED0")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DivRentaVariable2 = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? props.height : "114px")};
  background: ${(props) => (props.background ? props.background : "#CDCED0")};
  border-radius: 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivRentaAlternativo = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? props.height : "114px")};
  background: ${(props) => (props.background ? props.background : "#2F6D95")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled("div")`
  height: ${(props) => (props.size ? props.size : "25px")};
  width: ${(props) => (props.size ? props.size : "25px")};
  background-color: ${(props) =>
    props.background ? props.background : "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;
/* estilos */
export const styles = {
  cardStyle: {
    width: "98%",
    fontSize: "10px",
    borderRadius: "16px",
    cursor: "pointer",
    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
    userSelect: "none",
  },
  cardStyleChanges: {
    width: "98%",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #2F6D95",
    cursor: "pointer",
    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
    userSelect: "none",
  },
  cardContentStyle: {
    minHeight: "116px",
    p: 2,
  },
  boxContainerTitle: {
    borderRadius: "8px",
    pl: 1,
    pr: 1,
  },
  boxContainerTitleChanges: {
    borderRadius: "8px",
    pl: 1,
    pr: 1,
  },
  boxContainerTitleChangesXL: {
    borderRadius: "8px",
  },
  titleDesktopStyle: {
    fontSize: "1.5em",
  },
  titleDesktopSubtitleStyle: {
    background: " #2F6D95",
    borderRadius: "16px",
    fontSize: "12px",
    padding: "4px 8px",
    textAlign: "center",
    color: "#ffffff",
  },
  titleMobileSubtitleStyle: {
    background: " #2F6D95",
    borderRadius: "16px",
    fontSize: "12px",
    textAlign: "center",
    padding: "4px 8px",
    color: "#ffffff",
  },
  titleResponsiveStyle: {
    fontSize: "1.6em",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridRentabilidadStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    bgcolor: "#F6F6F6",
    borderRadius: "8px",
  },
};

const BasicCard = ({
  info,
  defaultSelect,
  buttonType,
  selectOption,
  step,
  setShowEvidence,
}) => {
  /* Hooks para los cambios al ser seleccionados */
  const [typeButton, setTypeButton] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(defaultSelect);
  /* material ui */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const screenXL = useMediaQuery(theme.breakpoints.down("xl"));

  /* Hook verifica la data */
  useEffect(() => {
    if (buttonType === "eligeFondo") setTypeButton(true);
  }, [buttonType, info]);

  useEffect(() => {
    if (step === 0) {
      setOpcionSeleccionada(defaultSelect);
    }
  }, [step]);

  /* manejo del evento onClick */
  const handleClick = (opcion) => (e) => {
    e.preventDefault();
    setOpcionSeleccionada(opcion);
    selectOption(opcion);
  };

  return (
    <>
      {info.map((item) => (
        <Grid key={item.id} item xs={matches ? 12 : 4}>
          <Card
            sx={
              opcionSeleccionada === item.id
                ? styles.cardStyleChanges
                : styles.cardStyle
            }
          >
            <Box sx={styles.cardContentStyle} onClick={handleClick(item.id)}>
              <Grid>
                <Grid container>
                  <Grid item xs={8}>
                    <Box
                      sx={
                        screenXL
                          ? matches
                            ? styles.boxContainerTitle
                            : styles.boxContainerTitleChangesXL
                          : styles.boxContainerTitleChanges
                      }
                    >
                      <Typography
                        sx={
                          matches
                            ? styles.titleResponsiveStyle
                            : styles.titleDesktopStyle
                        }
                      >
                        {item.title} - {item.subTitle}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={styles.gridCheckboxStyle}>
                    <Checkbox
                      sx={{
                        "& .MuiCheckbox-root:hover": { background: "none" },
                      }}
                      icon={
                        opcionSeleccionada === item.id ? (
                          <img src={IC_CHECKED} />
                        ) : (
                          <PanoramaFishEyeIcon fontSize="medium" />
                        )
                      }
                      checkedIcon={<img src={IC_CHECKED} />}
                      style={{
                        fontSize: "0.5em",
                        padding: "0px",
                        paddingBottom: "8px",
                        color:
                          opcionSeleccionada === item.id
                            ? "#2F6D95"
                            : "#ADADAD",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ pt: 1 }}>
                <Grid item xs={4} sx={styles.gridRiesgoStyle}>
                  <Box
                    component="img"
                    sx={{ width: "86px" }}
                    src={item.riesgoImg}
                    alt="Gráfico de nivel de riesgo"
                  />
                </Grid>

                <Grid item xs={8}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      borderRadius: "8px",
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            Potencial de rentabilidad:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              sx={{
                                color: "#6E7378",
                                fontSize: "12px",
                                textAlign: "left",
                              }}
                            >
                              {item.rentabilidad}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            Nivel de Riesgo:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#6E7378", fontSize: "12px" }}
                            >
                              {item.riesgo}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default BasicCard;
