import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Logo from "../../assets/logo.jpeg";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f8f9fb",
    width: "100%",
  },
  headerTop: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  centerImage: {
    width: 200,
    padding: 10,
  },
  wraperContent: {
    padding: "0 30px",
  },
  wraperContentMarca: {
    padding: "0 30px",
    marginBottom: 40,
  },
  cabecera: {
    backgroundColor: "#ca082d",
    color: "#f8f9fb",
    padding: "20",
  },
  cabecera_title: {
    display: "flex",
    flexDirection: "row",
  },
  cabeceraTitulo: {
    fontWeight: "350px",
    fontSize: 16,
    borderBottom: 1,
    borderColor: "#f8f9fb",
    paddingBottom: "3px",
    width: 300,
  },
  cabeceraTituloright: {
    // display:"flex",
    marginLeft: "100px",
    fontSize: 11,
    fontWeight: 700,
  },
  cabecerasubTituloright: {
    // display:"flex",
    marginLeft: "160px",
    fontSize: 11,
    fontWeight: 700,
  },
  cabecera_SubTitulo: {
    display: "flex",
    flexDirection: "row",
  },
  cabeceraSubTitulo: {
    fontSize: 10,
    width: 300,
    paddingTop: "3px",
  },
  referente: {
    marginTop: "20",
  },
  referenteText: {
    fontSize: 11,
    textAlign: "justify",
  },
  referenteTextBottom: {
    fontSize: 8,
    marginBottom: 20,
    textAlign: "justify",
  },
  referenteTextRight: {
    fontSize: 10,
    textAlign: "right",
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#000",
  },
  textOther: {
    fontSize: "12",
  },
  itemLeft: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#373a3e",
    color: "#FFF",
    padding: "3px",
    fontSize: 12,
    fontWeight: "bold",
  },
  itemLeftRed: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ca082d",
    color: "#FFF",
    padding: "3px",
    fontSize: 12,
    fontWeight: "bold",
  },
  rowBottom: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
    borderTop: 1,
    borderColor: "#000",
  },
  itemLeftBottom: {
    width: "35%",
    color: "#f8f9fb",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#6b6c70",
    padding: "3px",
    fontSize: 12,
    fontWeight: "bold",
  },
  letterAmount: {
    fontSize: 15,
  },
  itemRight: {
    width: "65%",
    padding: "4px",
    fontSize: 10,
    color: "#393939",
  },
  trianguloLeft: {
    borderRight: 60,
    borderRightColor: "#f8f9fb",
    borderTop: 60,
    borderTopColor: "#e5e6e8",
    position: "fixed",
    backgroundColor: "#f8f9fb",
  },
  trianguloRight: {
    borderLeft: 60,
    borderLeftColor: "#f8f9fb",
    borderBottom: 60,
    borderBottomColor: "#ca082d",
    position: "fixed",
    backgroundColor: "#f8f9fb",
  },
  contentTrianguloLeft: {
    width: "49%",
  },
  contentImage: {
    width: "40%",
    alignSelf: "flex-end",
  },
  contentTrianguloRight: {
    // width: "15%",
  },
  marcaTop: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
});
const Registro = ({ datos }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.headerTop}>
        </View> */}
        <View style={styles.wraperContentMarca}>
          <View style={styles.marcaTop}>
            <View style={styles.contentTrianguloLeft}>
              <View style={styles.trianguloLeft}></View>
            </View>
            <View style={styles.contentImage}>
              <View style={styles.centerImage}>
                <Image src={Logo} />
              </View>
            </View>
            <View style={styles.contentTrianguloRight}>
              <View style={styles.trianguloRight}></View>
            </View>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View style={styles.cabecera}>
            <View style={styles.cabecera_title}>
              {datos.r_tipo_doc_sunat === "18" ? (
                <View>
                  <Text bold>COMPROBANTE DE PAGO</Text>
                  <Text style={styles.cabeceraTitulo} bold>
                    ELECTRÓNICO
                  </Text>
                </View>
              ) : (
                <Text style={styles.cabeceraTitulo} bold>
                  NOTA DE CRÉDITO DE VENTA ELECTRÓNICA
                </Text>
              )}

              <Text style={styles.cabeceraTituloright}>RUC: 20551464971</Text>
            </View>
            <View style={styles.cabecera_SubTitulo}>
              <Text style={styles.cabeceraSubTitulo}>
                Comisiones consolidadas
              </Text>
              <Text style={styles.cabecerasubTituloright}>
                {datos.r_caracter}-{datos.r_numerico}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteText}></Text>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Tipo documento del afiliado</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>
                  {datos.r_tipo_doc_afil === "0"
                    ? "DOC.TRIB.NO.DOM.SIN.RUC"
                    : datos.r_tipo_doc_afil === "1"
                    ? "Documento Nacional de Identidad"
                    : datos.r_tipo_doc_afil === "4"
                    ? "Carnet de extranjeria"
                    : datos.r_tipo_doc_afil === "6"
                    ? "Registro Único de Contribuyentes"
                    : datos.r_tipo_doc_afil === "7"
                    ? "Pasaporte"
                    : datos.r_tipo_doc_afil === "A"
                    ? "Cédula Diplomática de identidad"
                    : datos.r_tipo_doc_afil === "B"
                    ? "DOC.IDENT.PAIS.RESIDENCIA-NO.D"
                    : datos.r_tipo_doc_afil === "C"
                    ? "Tax Identification Number - TIN -Doc Trib PP.NN"
                    : datos.r_tipo_doc_afil === "D"
                    ? "Identification Number - IN - Doc Trib PP.JJ"
                    : datos.r_tipo_doc_afil === "E"
                    ? "TAM - Tarjeta Andina de Migración"
                    : datos.r_tipo_doc_afil === "F"
                    ? "Permiso Temporal de Permanencia - PTP"
                    : ""}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Nro. documento del afiliado</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_num_doc}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Apellidos y nombres</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_nombre}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>CUSSP</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_cussp}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>F. Emisión</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_fecha}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Moneda</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_moneda}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Periodo</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_periodo}</Text>
              </View>
            </View>
            <View>
              <View>
                <Text> </Text>
              </View>
              <View></View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Comisión sobre remuneración</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_monto_total_srem_per}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Comisión sobre el saldo </Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_monto_total_ssal_per}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Otras comisiones</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_monto_total_otrc_per}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Valor Total. Op. Inafectas</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_monto_total_vvoi_per}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Importe Total</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_monto_total_per}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteText}>(*) Sin impuestos.</Text>
            <Text style={styles.referenteText}>
              (**) Incluye impuestos, de ser Op. Gravada
            </Text>
            <Text style={styles.referenteText}>
              (***) El monto de la comisión sobre el saldo incluye la comisión
              por aportes obligatorios y/o voluntarios.
            </Text>
          </View>
        </View>
        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.letterAmount}>
              SON:{datos.r_monto_total_per_txt}
            </Text>
          </View>
        </View>
        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.textOther}>
              Esta es una representación impresa de la Boleta de Venta
              Electrónica, generada en el Sistema de la Sunat. El Emisor
              Electrónico puede verificarla utilizando su clave SOL, el
              Adquiriente o Usuario puede consultar su validez en SUNAT Virtual:
              www.sunat.gob.pe, en Opciones sin Clave SOL/Consulta de validez
              del CPE
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Registro;
