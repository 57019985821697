import React, { useState, useEffect, useCallback, useContext } from "react";
import "./index.css";
/*Componentes para validacion de cuentas */
import hocWidthWindow from "../../../../../../../../HOC/HocWidthWindow";
import SelectCuentas from "../../../../../../../RetiroApv/SinCuenta/SelectCuentas";
import SelectGeneral from "../../../../../../../RetiroApv/SinCuenta/SelectGeneral";
import Cards from "../../../../../../../../components/CreditCard/Cards";
import ModalPreviewNew from "../../../../../../../RetiroApv/SinCuenta/ModalPreviewNewCard";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import { obtenerCtaRemesa } from "../../../../../../../../utils/formulaRemesa";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import { Row, Col, Input, FormGroup, Label, Button } from "reactstrap";
import Dropzone from "react-dropzone";
import CustomBankModal from "../../../../../../Components/customBankModal/CustomModal";
const globalErrors = {
  fondo: "",
  cuentaCard: "",
  banco: "",
  tipoCuenta: "",
  anioYmes: "",
  nroCuenta: "",
  monto: "",
  image: "",
};

function SubidaDocumentos(props) {
  const { processId, contents, dependencies, endSecondStep, getOutOfProcess } =
    props;

  const [errores, setErrores] = useState(globalErrors);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarAvanceJuilacionData,
    guardarAvanceJuilacionLoading,
    guardarAvanceTramiteJubilacion,
    limpiarGuardarAvanceTramiteJubilacion,
    updateTypeBank,
  } = jubilacionContext;
  /*Reuso de variables para el componente de cuentas */
  const [mostrarFormularioCompleto, setMostrarFormularioCompleto] = useState(
    dependencies.r_accountDetail.account_detail.r_cuentas.lenght === 0
  );
  const [foreignInfoModal, setForeignInfoModal] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);

  const [cuentaDestino, setCuentsDestino] = useState(-1);

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFinalChecked, setIsFinalChecked] = useState(false);

  const [banco, setBanco] = useState("");

  /*files adjuntos*/
  const [
    cuentaBancoExtranjeroAdjuntoFile,
    setCuentaBancoExtranjeroAdjuntoFile,
  ] = useState(null);
  const [cuentaBancoAdjuntoFile, setCuentaBancoAdjuntoFile] = useState(null);
  const [cuentaBancoExtranjeroAdjunto, setCuentaBancoExtranjeroAdjunto] =
    useState("");
  const [cuentaBancoAdjunto, setCuentaBancoAdjunto] = useState("");
  const [nroDeCuenta, setNroDeCuenta] = useState("");
  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [filtroTipoCuenta, seFiltroTipoCuenta] = useState([]);
  const [typeAccount, setTypeAccount] = useState(null);
  const [disclaimer, setDisclaimer] = useState(false);
  const [foreignBankAccount, setForeignBankAccount] = useState(false);
  const [foreignAccountinputs, setForeignAccountinputs] = useState({
    fullName: "",
    docType: "",
    docNum: "",
    bankNum: "",
    coin: "",
    bankName: "",
    code: "",
  });
  const [sendRequest, setSendRequest] = useState(false);
  const [savedModal, setSavedModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** Opciones dependientes del Tipo de cuenta*/
  const [longitudNroCuenta, setLongitudNroCuenta] = useState(0);
  useEffect(() => {
    if (guardarAvanceJuilacionData && sendRequest) {
      if (guardarAvanceJuilacionData.success) {
        if (foreignBankAccount) {
          setSavedModal(true);
        } else {
          setShowModalSuccess(true);
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarAvanceJuilacionData]);

  useEffect(() => {
    if (foreignBankAccount) {
      setForeignAccountinputs({
        fullName: "",
        docType: "",
        docNum: "",
        bankNum: "",
        coin: "",
        bankName: "",
        code: "",
      });
      setCuentaBancoExtranjeroAdjunto("");
      setForeignInfoModal(true);
    } else {
      setCuentsDestino(-1);
      setBanco("");
      setTipoDeCuenta("");
      setCuentaBancoAdjunto("");
      setNroDeCuenta("");
      seFiltroTipoCuenta([]);
      setLongitudNroCuenta(0);
      setErrores(globalErrors);
      setCuentaBancoAdjunto("");
    }
    setDisclaimer(false);
  }, [foreignBankAccount]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const handlePreviewOpen = () => {
    setOpenModalPreview(true);
  };

  const opcionSeleccionadaCard = (opcion) => {
    if (opcion === "nueva") {
      setMostrarFormularioCompleto(true);
      setCuentsDestino("nueva");
    } else {
      setMostrarFormularioCompleto(false);
      setCuentsDestino(opcion);
    }
    // limpiamos campos
    setBanco("");
    setTipoDeCuenta("");
    setCuentaBancoAdjunto("");
    setNroDeCuenta("");
    seFiltroTipoCuenta([]);
    setLongitudNroCuenta(0);
    setErrores(globalErrors);
  };

  const handlePreviewClose = () => {
    setOpenModalPreview(false);
  };

  const handleCheckDisclaimer = () => {
    setDisclaimer(!disclaimer);
  };

  const handlerbanco = (opcion) => {
    seFiltroTipoCuenta([]);
    if (opcion !== "") {
      setBanco(opcion);
      let newListTipoCuenta =
        dependencies.r_accountDetail.account_detail.r_tipo_cuentas.filter(
          (item) => item.r_banco === opcion.r_codigo
        );

      let newTypesCuenta = newListTipoCuenta.filter(
        (item) => item.r_codigo !== "CHK"
      );
      seFiltroTipoCuenta(newTypesCuenta);
    } else {
      setBanco("");
    }
    setNroDeCuenta("");
    setTypeAccount(null);

    setErrores({ ...errores, banco: "" });
  };

  const handlerTipoCuenta = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      if (opcion.r_cci === "1") {
        setTypeAccount("isCci");
      } else {
        setTypeAccount(null);
      }
      setTipoDeCuenta(JSON.stringify(opcion));
    } else {
      opcion = "";
      setTipoDeCuenta("");
    }

    if (opcion.r_banco === "11") {
      setNroDeCuenta("018");
    } else if (opcion.r_banco === "7") {
      setNroDeCuenta("0011");
    } else {
      setNroDeCuenta("");
    }
    if (opcion !== "") {
      setLongitudNroCuenta(opcion.r_longitud);
    } else {
      setLongitudNroCuenta(0);
    }
    setErrores({ ...errores, tipoCuenta: "" });
  };

  const listTipoCuenta = () => {
    if (filtroTipoCuenta.length > 0) {
      return filtroTipoCuenta
        .filter((tipoDeCuenta) => tipoDeCuenta.r_codigo !== "TCR")
        .map((item, index) => (
          <option key={index} value={JSON.stringify(item)}>
            {item.r_descripcion}
          </option>
        ));
    } else {
      return "";
    }
  };

  const handlerNroCuenta = (e) => {
    const valor = e.target.validity.valid ? e.target.value : nroDeCuenta;

    setNroDeCuenta(valor);

    setErrores({ ...errores, nroCuenta: "" });
  };

  const onDropCuentaBancaria = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCuentaBancoAdjuntoFile(file);
      trasnformBase64(file, setCuentaBancoAdjunto, setCuentaBancoAdjuntoFile);
    }
  }, []);

  // const onDropConstanciaPensionista = useCallback(acceptedFiles => {
  //     if (acceptedFiles.length) {
  //         const file = acceptedFiles[0];
  //         setConstanciaPensionAdjuntoFile(file);
  //         trasnformBase64(file, setConstanciaPensionAdjunto);
  //     }

  // }, []);

  // const onDropBoletaPensionista = useCallback(acceptedFiles => {
  //     if (acceptedFiles.length) {
  //         const file = acceptedFiles[0];
  //         setBoletaPensionAdjuntoFile(file)
  //         trasnformBase64(file, setBoletaPensionAdjunto);
  //     }
  // }, []);

  const onDropCuentaBancariaExtranjera = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCuentaBancoExtranjeroAdjuntoFile(file);
      trasnformBase64(file, setCuentaBancoExtranjeroAdjunto);
    }
  }, []);

  const trasnformBase64 = (file, set) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const resultFile = reader.result;
      set(resultFile);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const changeIconForLoading = () => {
    if (guardarAvanceJuilacionLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const validarCuentaBancariaFormulario = () => {
    if (foreignBankAccount) {
      if (
        foreignAccountinputs.fullName !== "" &&
        foreignAccountinputs.docType !== "" &&
        foreignAccountinputs.docNum !== "" &&
        foreignAccountinputs.bankNum !== "" &&
        foreignAccountinputs.coin !== "" &&
        foreignAccountinputs.bankName !== "" &&
        foreignAccountinputs.code !== "" &&
        cuentaBancoExtranjeroAdjunto !== "" &&
        disclaimer
      )
        return true;
      else return false;
    } else {
      if (cuentaDestino === "nueva") {
        if (
          banco !== "" &&
          tipoDeCuenta !== "" &&
          nroDeCuenta !== "" &&
          cuentaBancoAdjunto !== "" &&
          disclaimer
        )
          return true;
        else return false;
      } else {
        if (cuentaDestino.r_codigo) return true;
        else return false;
      }
    }
  };

  const validateData = () => {
    if (!validarCuentaBancariaFormulario()) return false;
    // else if (dependencies.has_a_pension && constanciaPensionAdjunto === "")
    //     return false;
    // else if (dependencies.has_a_pension && boletaPensionAdjunto === "")
    //     return false;
    else return true;
  };

  const renderSelectBankAccount = (width) => {
    if (width > 600)
      return (
        <>
          <Cards
            cuentas={dependencies.r_accountDetail.account_detail.r_cuentas}
            preview={handlePreviewOpen}
            setCuenta={opcionSeleccionadaCard}
          />
          {errores.cuentaCard && (
            <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
              {errores.cuentaCard}
            </span>
          )}
        </>
      );
    else
      return (
        <>
          <SelectCuentas
            cuentas={dependencies.r_accountDetail.account_detail.r_cuentas}
            onClick={() => {}}
            setCuenta={opcionSeleccionadaCard}
          />
          {errores.cuentaCard && (
            <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
              {errores.cuentaCard}
            </span>
          )}
        </>
      );
  };
  const validateForm = () => {
    let mensajesError = {
      cuentaCard: "",
      banco: "",
      tipoCuenta: "",
      anioYmes: "",
      nroCuenta: "",
      monto: "",
      disclaimer: "",
    };
    let respuesta = true;
    if (mostrarFormularioCompleto) {
      let tipoCuentaObject = JSON.parse(tipoDeCuenta);

      if (nroDeCuenta.length < tipoCuentaObject.r_longitud) {
        mensajesError = {
          ...mensajesError,
          nroCuenta: `campo de ${tipoCuentaObject.r_longitud} digitos`,
        };
      }
      setErrores(mensajesError);

      for (let item in mensajesError) {
        if (mensajesError[item].length > 0) {
          respuesta = false;
          return respuesta;
        }
      }
      return respuesta;
    } else {
      return respuesta;
    }
  };
  const handleCrearPaso5 = async () => {
    const newCuenta =
      Number(banco.r_codigo) === 7
        ? obtenerCtaRemesa(nroDeCuenta)
        : nroDeCuenta;
    const data = [];
    const codeBank =
      cuentaDestino.r_nombre_banco === "BCP"
        ? 10
        : cuentaDestino.r_nombre_banco === "DE LA NACION"
        ? 11
        : cuentaDestino.r_nombre_banco === "PICHINCHA"
        ? 13
        : cuentaDestino.r_nombre_banco === "BANBIF"
        ? 14
        : cuentaDestino.r_nombre_banco === "FALABELLA"
        ? 85
        : cuentaDestino.r_nombre_banco === "GNB"
        ? 81
        : cuentaDestino.r_nombre_banco === "COMERCIO"
        ? 9
        : cuentaDestino.r_nombre_banco === "RIPLEY"
        ? 116
        : cuentaDestino.r_nombre_banco === "INTERBANK"
        ? 15
        : cuentaDestino.r_nombre_banco === "SCOTIABANK"
        ? 19
        : 7;
    if (validateForm()) {
      for (const content of contents) {
        let object = { id: content.id, response: false, type: "boleean" };
        if (content.slug === "step-2.1") {
          object.response = true;
          if (foreignBankAccount) {
            object["bank_account"] = {
              oracle_id: "",
              bank: foreignAccountinputs.bankName,
              code_bank: "",
              type: "abono",
              code_type: foreignAccountinputs.code,
              attachment: {
                base64: cuentaBancoExtranjeroAdjunto,
                name: cuentaBancoExtranjeroAdjuntoFile.name,
              },
              number: foreignAccountinputs.bankNum,
              owner: foreignAccountinputs.fullName,
              document_type: foreignAccountinputs.docType,
              document_number: foreignAccountinputs.docNum,
              currency: foreignAccountinputs.coin,
              national: false,
              terms: false,
            };
          } else {
            object["bank_account"] = {
              oracle_id:
                cuentaDestino !== "nueva" ? cuentaDestino.r_codigo : "",
              bank:
                cuentaDestino !== "nueva"
                  ? cuentaDestino.r_nombre_banco
                  : banco.r_descripcion,
              code_bank: cuentaDestino !== "nueva" ? codeBank : banco.r_codigo,
              type:
                cuentaDestino !== "nueva"
                  ? cuentaDestino.r_tipo_cuenta
                  : JSON.parse(tipoDeCuenta).r_descripcion,
              code_type:
                cuentaDestino !== "nueva"
                  ? cuentaDestino.r_tipo_cuenta === "CUENTA AHORROS"
                    ? "AHO"
                    : "CHK"
                  : JSON.parse(tipoDeCuenta).r_codigo,
              attachment:
                cuentaDestino !== "nueva"
                  ? null
                  : {
                      base64: cuentaBancoAdjunto,
                      name: cuentaBancoAdjuntoFile.name,
                    },
              number:
                cuentaDestino !== "nueva"
                  ? cuentaDestino.r_numero_cuenta
                  : newCuenta,
              owner: "",
              document_type: null,
              document_number: null,
              currency: null,
              national: true,
              terms: true,
            };
          }
        }

        data.push(object);
      }

      setSendRequest(true);
      guardarAvanceTramiteJubilacion(processId, 2, { data });
      await updateTypeBank(!foreignBankAccount);
    }
  };
  return (
    <div className="segundo-retiro-documentos">
      <h1 className="mb-2">Carga de documentos</h1>
      <p id="text-intro">
        Para seguir con tu solicitud de nuevo retiro, necesitamos contar con tu
        cuenta bancaria donde realizaremos el abono.
      </p>
      <br />
      <div className="content">
        {foreignBankAccount ? (
          <div className="document-card-boder">
            <div className="document-card-content mb-2">
              <div className="document-info">
                <div className="check-container info-check">
                  {/* <span className="oneChoice">
                                        <input type="radio" value={0} checked={validarCuentaBancariaFormulario()} className="radio-btn" id="tfa_711" name="cuenta_bancaria_entranjera" disabled={true} />
                                        <label className="postRadioField" for="tfa_711">
                                            <span className="input-radio-faux"></span>
                                        </label>
                                    </span> */}
                  <label htmlFor="tfa_711">
                    <span className="check-label">
                      Cuenta Bancaria para el abono del nuevo retiro.
                    </span>
                  </label>
                  <h5>Tomar en cuenta: </h5>
                  <ul>
                    <li>
                      La información a completar debe coincidir con la
                      consignada en la entidad bancaria que recibirá el dinero.
                    </li>
                    <li>
                      Evitar abreviaturas en el Tipo de documento como por
                      ejemplo CC (Cedula de Ciudadanía) DNI (Documento de
                      identidad), RUN (Registro Único nacional).
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="document-actions"></div> */}
            </div>
            <div className="center-items mb-2 margin-banks options-banks">
              <div className="check-container circle">
                <span className="oneChoice" style={{ margin: "0px 5px" }}>
                  <input
                    type="radio"
                    checked={!foreignBankAccount}
                    className="radio-btn"
                    id="tfa_522"
                    name="cuenta_bancaria"
                    onClick={() => setForeignBankAccount(false)}
                    disabled={guardarAvanceJuilacionLoading}
                  />
                  <label
                    className="postRadioField"
                    for="tfa_522"
                    style={{ marginBottom: 0 }}
                  >
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label htmlFor="tfa_522" style={{ marginBottom: 0 }}>
                  <span className="check-label">
                    Tengo cuenta de banco nacional
                  </span>
                </label>
              </div>

              <div className="check-container circle">
                <span className="oneChoice" style={{ margin: "0px 5px" }}>
                  <input
                    type="radio"
                    checked={foreignBankAccount}
                    className="radio-btn"
                    id="tfa_521"
                    name="cuenta_bancaria"
                    onClick={() => setForeignBankAccount(true)}
                    disabled={guardarAvanceJuilacionLoading}
                  />
                  <label
                    className="postRadioField"
                    for="tfa_521"
                    style={{ marginBottom: 0 }}
                  >
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label htmlFor="tfa_521" style={{ marginBottom: 0 }}>
                  <span className="check-label">
                    Tengo cuenta de banco en el exterior
                  </span>
                </label>
              </div>
            </div>
            <div className="margin-banks">
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="fba-fullname">Nombre y apellido</Label>
                    <Input
                      id="fba-fullname"
                      name="fba-fullname"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          fullName: e.target.value,
                        })
                      }
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-document-type">Tipo de documento</Label>
                    <Input
                      id="fba-document-type"
                      name="fba-document-type"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          docType: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                      onKeyPress={(e) => {
                        let regex = new RegExp("^[a-zA-Z]+$");
                        let key = String.fromCharCode(
                          !e.charCode ? e.which : e.charCode
                        );
                        if (!regex.test(key)) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-document-number">Número de documento</Label>
                    <Input
                      id="fba-document-number"
                      name="fba-document-number"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          docNum: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                      onPaste={false}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        let regex = new RegExp("^[0-9]+$");
                        let key = String.fromCharCode(
                          !e.charCode ? e.which : e.charCode
                        );
                        if (!regex.test(key)) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-bank-account">
                      Número de cuenta bancaria
                    </Label>
                    <Input
                      id="fba-bank-account"
                      name="fba-bank-account"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          bankNum: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                      onPaste={false}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        let regex = new RegExp("^[0-9]+$");
                        let key = String.fromCharCode(
                          !e.charCode ? e.which : e.charCode
                        );
                        if (!regex.test(key)) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-coin">Tipo de moneda</Label>
                    <Input
                      id="fba-coin"
                      name="fba-coin"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          coin: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-bank-name">Nombre del banco</Label>
                    <Input
                      id="fba-bank-name"
                      name="fba-bank-name"
                      autoComplete="off"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          bankName: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="fba-code">Código ABA, BIC, IBAN o SWIFT</Label>
                    <Input
                      id="fba-code"
                      name="fba-code"
                      autoComplete="off"
                      onBlur={(e) =>
                        setForeignAccountinputs({
                          ...foreignAccountinputs,
                          code: e.target.value,
                        })
                      }
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="saldo-extranjero">
                      <p style={{ marginBottom: "0px" }}>
                        Adjuntar un sustento bancario con los datos que estás
                        registrando.
                      </p>
                      <span style={{ fontSize: "12px" }}>
                        *En caso el sustento difiera de los datos registrados,
                        la forma de pago podría ser rechazada.
                      </span>
                      <span
                        className="tooltip-custom"
                        style={{ color: "#bf253d" }}
                      >
                        <span className="tooltiptext">
                          Recuerda: El documento que se adjunte debe figurar el
                          número de cuenta que esta ingresando y el nombre de la
                          persona.
                        </span>
                      </span>
                    </Label>
                    <div className="text-center mt-1">
                      <Dropzone
                        onDrop={onDropCuentaBancariaExtranjera}
                        accept=".pdf,image/png,image/jpeg,image/jpg"
                        minSize={0}
                        maxSize={1.2e6}
                        multiple={false}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                          fileRejections,
                          acceptedFiles,
                        }) => {
                          const isFileTooLarge =
                            fileRejections.length > 0 &&
                            fileRejections[0].file.size > 1.2e6;
                          const isFileTypeError =
                            fileRejections.length > 0 &&
                            fileRejections[0].errors[0].code ===
                              "file-invalid-type";

                          return (
                            <div {...getRootProps()} style={baseStyle}>
                              <input {...getInputProps()} />
                              {!isDragActive &&
                                "¡Haga clic aquí para adjuntar el sustento!"}
                              {isDragActive &&
                                !isDragReject &&
                                "¡Déjalo caer como si estuviera ardiendo!"}
                              {isDragReject &&
                                "Tipo de archivo no aceptado, lo siento."}
                              {isFileTooLarge && (
                                <div className="text-danger mt-2 error-size">
                                  El archivo es demasiado grande, sube un
                                  archivo de menos de 1.2 MB
                                </div>
                              )}
                              {isFileTypeError && (
                                <div className="text-danger mt-2 error-size">
                                  El formato del archivo no es válido
                                </div>
                              )}

                              <ul className="list-group mt-2">
                                {acceptedFiles.length > 0 &&
                                  acceptedFiles.map((acceptedFile) => (
                                    <li className="list-group-item list-group-item-success">
                                      {acceptedFile.name}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup check>
                    <Label check className="input_disclaimer_retiro">
                      <Input
                        type="checkbox"
                        checked={disclaimer}
                        onChange={handleCheckDisclaimer}
                      />{" "}
                      Doy mi conformidad de haber verificado que el número de
                      cuenta bancario que he registrado es el correcto. AFP
                      Habitat no es responsable en caso la cuenta brindada esté
                      errada.
                    </Label>
                  </FormGroup>
                  {errores.disclaimer && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.disclaimer}
                    </span>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="document-card-boder">
            <div className="document-card-content mb-3">
              <div className="document-info">
                <div className="check-container info-check">
                  {/* <span className="oneChoice">
                                    
                                        <input type="radio" value={0} checked={validarCuentaBancariaFormulario()} className="radio-btn" id="tfa_721" name="cuenta_bancaria" disabled={true} />
                                        <label className="postRadioField" for="tfa_721">
                                            <span className="input-radio-faux"></span>
                                        </label>
                                    </span> */}
                  <label htmlFor="tfa_721">
                    <span className="check-label">
                      Cuenta Bancaria para el pago del nuevo retiro.
                    </span>
                  </label>
                  <h5>Tomar en cuenta: </h5>
                  <ul>
                    <li>
                      La información a completar debe coincidir con la
                      consignada en la entidad bancaria que recibirá el dinero.
                    </li>
                    <li>
                      Evitar abreviaturas en el Tipo de documento como por
                      ejemplo CC (Cedula de Ciudadanía) DNI (Documento de
                      identidad), RUN (Registro Único nacional).
                    </li>
                  </ul>
                </div>
                <p>
                  *En caso ya tengas una cuenta validada, puedes elegirla como
                  medio de pago.
                </p>
              </div>
              {/* <div className="document-actions">
                {/* <button className="btn-red">Cargar</button> 
              </div> */}
            </div>
            <div className="center-items mb-2 margin-banks options-banks">
              <div className="check-container circle">
                <span className="oneChoice" style={{ margin: "0px 5px" }}>
                  <input
                    type="radio"
                    checked={!foreignBankAccount}
                    className="radio-btn"
                    id="tfa_522"
                    name="cuenta_bancaria"
                    onClick={() => setForeignBankAccount(false)}
                    disabled={guardarAvanceJuilacionLoading}
                  />
                  <label
                    className="postRadioField"
                    for="tfa_522"
                    style={{ marginBottom: 0 }}
                  >
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label htmlFor="tfa_522" style={{ marginBottom: 0 }}>
                  <span className="check-label">
                    Tengo cuenta de banco nacional
                  </span>
                </label>
              </div>

              <div className="check-container circle">
                <span className="oneChoice" style={{ margin: "0px 5px" }}>
                  <input
                    type="radio"
                    checked={foreignBankAccount}
                    className="radio-btn"
                    id="tfa_521"
                    name="cuenta_bancaria"
                    onClick={() => setForeignBankAccount(true)}
                    disabled={guardarAvanceJuilacionLoading}
                  />
                  <label
                    className="postRadioField"
                    for="tfa_521"
                    style={{ marginBottom: 0 }}
                  >
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label htmlFor="tfa_521" style={{ marginBottom: 0 }}>
                  <span className="check-label">
                    Tengo cuenta de banco en el exterior
                  </span>
                </label>
              </div>
            </div>
            <div className="margin-banks">
              {renderSelectBankAccount(props.windowWidth)}
              {mostrarFormularioCompleto && (
                <Row form>
                  <Col md={4} className="mb-3">
                    <SelectGeneral
                      info={{
                        labelText: "Escoge tu banco",
                        placeHolder: "- Selecciona tu banco -",
                        opciones:
                          dependencies.r_accountDetail.account_detail.r_bancos,
                      }}
                      defaultSelect={"1"}
                      selectOption={handlerbanco}
                    />
                    {errores.banco && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.banco}
                      </span>
                    )}
                  </Col>
                  <Col md={4} className="mb-3">
                    <Label htmlFor="nombre">Tipo de cuenta</Label>
                    <select
                      className="form-control mr-1"
                      value={tipoDeCuenta}
                      onChange={handlerTipoCuenta}
                    >
                      <option value="">- Selecciona tu tipo de cuenta -</option>
                      {listTipoCuenta()}
                    </select>
                    {errores.tipoCuenta && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.tipoCuenta}
                      </span>
                    )}
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      {typeAccount === "isCci" ? (
                        <Label htmlFor="nombre">N.° de CCI * </Label>
                      ) : (
                        <Label htmlFor="nombre">N.° de cuenta</Label>
                      )}
                      <Input
                        placeholder={
                          typeAccount === "isCci"
                            ? "Número de cci"
                            : "Número de cuenta"
                        }
                        onChange={handlerNroCuenta}
                        pattern="[0-9]*"
                        disabled={longitudNroCuenta === 0 ? true : false}
                        maxLength={longitudNroCuenta}
                        value={nroDeCuenta}
                      />
                      {errores.nroCuenta && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.nroCuenta}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    {typeAccount === "isCci" ? (
                      <p className="text_cci">
                        El Código de Cuenta Interbancario (CCI) tiene 20 dígitos
                        y es diferente a tu número de cuenta. Puedes consultar
                        con tu banco cuál es tu número CCI para realizar tu pago
                        sin inconvenientes.
                      </p>
                    ) : null}
                  </Col>
                  <Col md={12}>
                    <FormGroup style={{ position: "relative" }}>
                      <Label htmlFor="saldo">
                        <p style={{ marginBottom: "0px" }}>
                          Adjuntar un sustento bancario con los datos que estás
                          registrando.
                        </p>
                        <span style={{ fontSize: "12px" }}>
                          *En caso el sustento difiera de los datos registrados,
                          la forma de pago podría ser rechazada.
                        </span>
                        <span
                          className="tooltip-custom"
                          style={{ color: "#bf253d" }}
                        >
                          <span className="tooltiptext">
                            Recuerda: El documento que se adjunte debe figurar
                            el número de cuenta que esta ingresando y el nombre
                            de la persona.
                          </span>
                        </span>
                      </Label>
                      <div className="text-center mt-1">
                        <Dropzone
                          onDrop={onDropCuentaBancaria}
                          accept=".pdf,image/png,image/jpeg,image/jpg"
                          minSize={0}
                          maxSize={1.2e6}
                          multiple={false}
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                            fileRejections,
                            acceptedFiles,
                          }) => {
                            const isFileTooLarge =
                              fileRejections.length > 0 &&
                              fileRejections[0].file.size > 1.2e6;
                            const isFileTypeError =
                              fileRejections.length > 0 &&
                              fileRejections[0].errors[0].code ===
                                "file-invalid-type";

                            return (
                              <div {...getRootProps()} style={baseStyle}>
                                <input {...getInputProps()} />
                                {!isDragActive &&
                                  "¡Haga clic aquí para adjuntar el sustento!"}
                                {isDragActive &&
                                  !isDragReject &&
                                  "¡Déjalo caer como si estuviera ardiendo!"}
                                {isDragReject &&
                                  "Tipo de archivo no aceptado, lo siento."}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2 error-size">
                                    El archivo es demasiado grande, sube un
                                    archivo de menos de 1.2 MB
                                  </div>
                                )}
                                {isFileTypeError && (
                                  <div className="text-danger mt-2 error-size">
                                    El formato del archivo no es válido
                                  </div>
                                )}

                                <ul className="list-group mt-2">
                                  {acceptedFiles.length > 0 &&
                                    acceptedFiles.map((acceptedFile) => (
                                      <li className="list-group-item list-group-item-success">
                                        {acceptedFile.name}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            );
                          }}
                        </Dropzone>
                      </div>
                      {errores.image && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.image}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check>
                      <Label check className="input_disclaimer_retiro">
                        <Input
                          type="checkbox"
                          checked={disclaimer}
                          onChange={handleCheckDisclaimer}
                        />{" "}
                        Doy mi conformidad de haber verificado que el número de
                        cuenta bancario que he registrado es el correcto. AFP
                        Habitat no es responsable en caso la cuenta brindada
                        esté errada.
                      </Label>
                    </FormGroup>
                    {errores.disclaimer && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.disclaimer}
                      </span>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </div>
        )}

        {/* {dependencies && dependencies.has_a_pension && (
                    <>
                        <h4 className="header-orfandad">Si recibes una pensión por Sobrevivencia o Viudez </h4>

                        <div className="document-card-boder">
                            <div className="document-card-content mb-2">
                                <div className="document-info">
                                    <div className="check-container mb-0">
                                        <span className="oneChoice">
                                            <input type="radio" value={0} checked={constanciaPensionAdjunto !== ""} className="radio-btn" id="tfa_731" name="constancia_pensionista" disabled={true} />
                                            <label className="postRadioField" id="tfa_731" for="tfa_731">
                                                <span className="input-radio-faux"></span>
                                            </label>
                                        </span>
                                        <span className="check-label">Constancia de pensionista</span>
                                    </div>
                                </div>
                                <div className="document-actions">
                                    <Dropzone
                                        onDrop={onDropConstanciaPensionista}
                                        accept=".pdf,image/png,image/jpeg,image/jpg"
                                        minSize={0}
                                        maxSize={1.2e+6}
                                        multiple={false}
                                    >
                                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, acceptedFiles }) => {
                                            const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > 1.2e+6;
                                            const isFileTypeError = fileRejections.length > 0 && fileRejections[0].errors[0].code === "file-invalid-type";

                                            if (isDragReject || isFileTooLarge || isFileTypeError)
                                                setConstanciaPensionAdjunto("");

                                            return (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <button className="btn-red">Cargar</button>
                                                    {isDragReject && (
                                                        <span className="text-danger mt-2 error-size">
                                                            Tipo de archivo no aceptado, lo siento.
                                                        </span>
                                                    )}
                                                    {isFileTooLarge && (
                                                        <span className="text-danger mt-2 error-size">
                                                            El archivo es demasiado grande, sube un archivo de menos de 1.2 MB
                                                        </span>
                                                    )}
                                                    {isFileTypeError && (
                                                        <span className="text-danger mt-2 error-size">
                                                            El formato del archivo no es válido
                                                        </span>
                                                    )}
                                                </div>
                                            )
                                        }
                                        }

                                    </Dropzone>
                                </div>
                            </div>
                            <div className="document-card-content">
                                <div className="document-info">
                                    <div className="check-container mb-0">
                                        <span className="oneChoice">
                                            <input type="radio" value={0} checked={boletaPensionAdjunto !== ""} className="radio-btn" id="tfa_731" name="boleta_pensionista" disabled={true} />
                                            <label className="postRadioField" id="tfa_731" for="tfa_731">
                                                <span className="input-radio-faux"></span>
                                            </label>
                                        </span>
                                        <span className="check-label">Última boleta de pensión</span>
                                    </div>
                                </div>
                                <div className="document-actions">
                                    <Dropzone
                                        onDrop={onDropBoletaPensionista}
                                        accept=".pdf,image/png,image/jpeg,image/jpg"
                                        minSize={0}
                                        maxSize={1.2e+6}
                                        multiple={false}
                                    >
                                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, acceptedFiles }) => {
                                            const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > 1.2e+6;
                                            const isFileTypeError = fileRejections.length > 0 && fileRejections[0].errors[0].code === "file-invalid-type";

                                            if (isDragReject || isFileTooLarge || isFileTypeError)
                                                setBoletaPensionAdjunto("");

                                            return (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <button className="btn-red">Cargar</button>
                                                    {isDragReject && (
                                                        <span className="text-danger mt-2 error-size">
                                                            Tipo de archivo no aceptado, lo siento.
                                                        </span>
                                                    )}
                                                    {isFileTooLarge && (
                                                        <span className="text-danger mt-2 error-size">
                                                            El archivo es demasiado grande, sube un archivo de menos de 1.2 MB
                                                        </span>
                                                    )}
                                                    {isFileTypeError && (
                                                        <span className="text-danger mt-2 error-size">
                                                            El formato del archivo no es válido
                                                        </span>
                                                    )}
                                                </div>
                                            )
                                        }
                                        }

                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </>
                )} */}

        <h5 className="file-type">
          Tomar en cuenta que los formatos aceptados son: PDF, JPEG, PNG
        </h5>
        <span className="file-size">Tamaño: máximo 1.2mb</span>
      </div>

      <div className="container-legal-buttons">
        <Button
          className={`mb-4 btn-modal-secondary ${
            validateData() ? "" : "disable-primary"
          }`}
          onClick={() => getOutOfProcess()}
          disabled={guardarAvanceJuilacionLoading}
        >
          Cerrar
        </Button>
        <Button
          className={`mb-4 btn-modal-primary ${
            validateData() ? "" : "disable-secondary"
          }`}
          onClick={() =>
            foreignBankAccount
              ? handleCrearPaso5()
              : isFinalChecked
              ? handleCrearPaso5()
              : setShowModalConfirmation(true)
          }
          disabled={!validateData() || guardarAvanceJuilacionLoading}
        >
          Continuar {changeIconForLoading()}
        </Button>
      </div>

      {openModalPreview && (
        <ModalPreviewNew
          closeModal={handlePreviewClose} // fn para cerrar modal
          className="segundo-retiro-documentos-aviso"
          // guardarCode={enviarDataAlPadre}
        />
      )}

      {foreignInfoModal && (
        <ModalPreviewNew
          closeModal={() => setForeignInfoModal(false)} // fn para cerrar modal
          className="segundo-retiro-documentos-aviso"
          description={
            <>
              <p>
                En caso no tengas una cuenta bancaria en Perú, y si no te es
                posible crear una de manera virtual con los bancos indicados,
                puedes registrar tu cuenta bancaria del exterior considerando la
                siguiente información:
              </p>
              <ul>
                <li>
                  Solo se aceptan cuentas a nombre del afiliado como único
                  titular, en ningún caso se aceptarán cuentas mancomunadas.
                </li>
                <li>
                  El afiliado asumirá los costos y/o comisiones que puedan
                  generarse por el banco receptor al momento de la
                  transferencia.
                </li>
                <li>El tipo de cambio será aplicado por el banco receptor.</li>
              </ul>
              <p>
                Si estás conforme con lo mencionado, puedes continuar para
                registrar los datos de tu cuenta.
              </p>
            </>
          }
          // guardarCode={enviarDataAlPadre}
        />
      )}

      <CustomBankModal
        showModal={showModalConfirmation}
        className="beauty-modal"
        toggle={() => setShowModalConfirmation(!showModalConfirmation)}
        fnCloseIconBtn={() => setShowModalConfirmation(!showModalConfirmation)}
        title="Importante"
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setIsFinalChecked(true);
          setShowModalConfirmation(false);
          handleCrearPaso5();
        }}
        textBtnPrimary="Confirmar"
        btnPrimaryLoading={true}
        isDisabled={false}
        hasBtnSecondary={true}
        fnBtnSecondary={() => setShowModalConfirmation(false)}
        textBtnSecondary="Editar datos"
        acceptField={isChecked}
        fnBtnChecked={(e) => setIsChecked(e)}
      />

      <CustomBankModal
        showModal={showModalSuccess}
        className="beauty-modal"
        toggle={() => setShowModalSuccess(!showModalSuccess)}
        // fnCloseIconBtn={() => setShowModalSuccess(!showModalSuccess)}
        fnCloseIconBtn={() => endSecondStep()}
        title="Configuración exitosa"
        hasBtnPrimary={true}
        fnBtnPrimary={() => endSecondStep()}
        textBtnPrimary="Continuar"
        btnPrimaryLoading={true}
        isDisabled={false}
        acceptField={false}
      />

      <CustomModal
        showModal={savedModal}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={() => endSecondStep()}
        title="Validación exitosa"
        description={
          <p>
            Tus documentos fueron guardados con éxito. Pulsa en Continuar para
            ir al siguiente paso.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => endSecondStep()}
        textBtnPrimary="Continuar"
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}

export default hocWidthWindow(SubidaDocumentos);
