import styled, { css } from "styled-components";
import * as Input from "../../../../components/Input";

export const DatosDeRetiroComponent = styled.form``;

export const DatosDeRetiroWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.div`
  display: grid;
`;

export const InputLabelComponent = styled.div`
  grid-column: 1/-1;
  ${(p) => p.theme.tipography.bodySmallMedium};
  margin-bottom: 8px;

  span {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const SeleccionFondoSection = styled(Input.Wrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const MontoARetirar = styled(Section)``;

export const DatosEntidadFinancieraComponent = styled(Section)``;

export const BankAccounts = styled(Input.Wrapper)`
  display: grid;
  ${(p) =>
    p.numeroDeComponentes <= 1 &&
    css`
      grid-template-columns: repeat(auto-fit, clamp(250px, 40%, 300px));

      @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    `}

  ${(p) =>
    p.numeroDeComponentes > 1 &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    `}

  grid-auto-rows: 1fr;
  gap: 16px;
  position: relative;
`;

export const NewCardInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;

  .left-side {
    display: grid;
  }

  .right-side {
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`;

export const FileInputWrapper = styled(Input.Wrapper)`
  display: grid;
  grid-template-rows: auto 1fr auto;

  .restrictions {
    text-align: center;
    margin: auto;
    margin-top: 8px;
    /* max-width: min(100%, 300px); */
    ${(p) => p.theme.tipography.caption}
    color : ${(p) => p.theme.palette.grayscale[400]}
  }
`;

export const InputFileContainer = styled.div`
  position: relative;
`;

export const ModalTrigger = styled.div`
  position: absolute;
  background: transparent;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const CamposObligatorios = styled.p`
  ${(p) => p.theme.tipography.bodySmallMedium}
  color: ${(p) => p.theme.palette.state.error}
`;

export const Message = styled.div`
 ${(p) => p.theme.tipography.bodySmallMessage}
  margin-bottom: 16px;
`;