import React, { useEffect, useState } from "react";
import Modal from "./CustomModal";
import { useFormContext, use } from "react-hook-form";
import moment from "moment";
import { ContentCheckValidacion, CustomErrorBeneficiaryModal } from "./styles";
import * as Input from "../../../../../../components/Input";
import MaskedTextInput from "react-text-mask";

const parentesco = [
  { label: "Hijo(a)", value: "H" },
  { label: "Padre", value: "P" },
  { label: "Madre", value: "M" },
  { label: "Cónyuge/Concubino(a)", value: "C" },
];
const sexo = [
  { label: "Femenino", value: "F" },
  { label: "Masculino", value: "M" },
];
const ModalBeneficiarios = ({
  isOpen,
  setIsOpen,
  handleAgregarBeneficiarios,
  cantAaños,
  handleSetBeneficiairos,
  arrayBeneficiarios,
}) => {
  const {
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors, isValid },
  } = useFormContext();

  const [errorDate, setErrorDate] = useState("");
  const closeModal = () => setIsOpen(false);

  const clientData = JSON.parse(localStorage.getItem("client"));

  const customErrors = useCustomValidation(
    watch,
    arrayBeneficiarios,
    clientData
  );
  // setValue("calculo.sexo", "M");

  // It changes the gender
  useEffect(() => {
    if (watch("calculo.parentesco") === "P") {
      return setValue("calculo.sexo", "M");
    }

    if (watch("calculo.parentesco") === "M") {
      return setValue("calculo.sexo", "F");
    }

    if (watch("calculo.parentesco") === "C") {
      return setValue("calculo.sexo", clientData?.gender === "M" ? "F" : "M");
    }

    setValue("calculo.sexo", null);
  }, [watch("calculo.parentesco")]);

  useEffect(() => {
    return () =>
      reset({
        "calculo.parentesco": null,
        "calculo.fechaNacimiento": null,
        "calculo.sexo": null,
        "calculo.discapacidad": null,
      });
  }, []);

  const handleAgregar = () => {
    if (!getValues("calculo.discapacidad")) {
      return;
    }

    let valueFecha = getValues("calculo.fechaNacimiento");
    let birthday = moment(valueFecha).diff(valueFecha, "years");

    let sumAños = birthday + cantAaños;
    if (customErrors) {
      return;
    }
    if (
      getValues("calculo.parentesco") === "H" &&
      getValues("calculo.discapacidad") === "no" &&
      sumAños > 18
    ) {
      setErrorDate("Debe ser menor de 18 años a tu edad de jubilación");
    } else {
      let filterParentesco = parentesco.filter(
        (items) => items.value === getValues("calculo.parentesco")
      );
      let filterSexo = sexo.filter(
        (items) => items.value === getValues("calculo.sexo")
      );
      let obj = {
        parentesco: filterParentesco[0].label,
        fechaNacimiento: moment().diff(valueFecha, "years"),
        sexo: filterSexo[0].label,
        discapacidad: getValues("calculo.discapacidad"),
      };
      let objArray = {
        relationship: filterParentesco[0].value,
        birthday: moment(valueFecha).format("DD/MM/YYYY"),
        gender: filterSexo[0].value,
        situation:
          getValues("calculo.discapacidad") === "si" ? "invalido" : "sano",
      };

      handleSetBeneficiairos(objArray);
      handleAgregarBeneficiarios(obj);
      setIsOpen(false);
      setValue("calculo.parentesco", "");
      setValue("calculo.fechaNacimiento", "");
      setValue("calculo.beneficiarios", false);
      setValue("calculo.sexo", "");
      setValue("calculo.discapacidad", "");
      setErrorDate("");
    }
  };

  const isCloseToTotal = (e) => {
    let valueFecha = e;
    let birthday = moment(e).diff(valueFecha, "years");
    let sumAños = birthday + cantAaños;

    if (
      getValues("calculo.parentesco") === "H" &&
      getValues("calculo.discapacidad") === "no" &&
      sumAños < 18
    ) {
      return true;
    }

    return "Debe ser menor de 18 años a tu edad de jubilación";
  };

  return <>
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Agregar beneficiario"
      onClickBtnPrimary={() => handleAgregar()}
      textBtnPrimary={"Agregar"}
      isBtnSecondary={true}
      textBtnSecondary="Cancelar"
      onClickBtnSecondary={closeModal}
    >
      <ContentCheckValidacion>
        <Input.Wrapper>
          <Input.Title required={true}>Parentesco</Input.Title>
          <Input.Select
            name="calculo.parentesco"
            options={parentesco}
            placeholder="Selecciona uno"
          />

          <Input.ErrorMessage name="calculo.parentesco" />
        </Input.Wrapper>
        <Input.Wrapper>
          <Input.Title>Fecha de Nacimiento</Input.Title>
          <Input.Date
            name="calculo.fechaNacimiento"
            validate={{
              greaterThanTotal: (e) => isCloseToTotal(e),
            }}
            datePickerProps={{
              customInput: (
                <MaskedTextInput
                  type="text"
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              ),
            }}

          // MaskedTextInput
          />

          <p className="errorDate">{errorDate}</p>
        </Input.Wrapper>

        <Input.Wrapper
          style={{
            display: watch("calculo.parentesco") === "H" ? "block" : "none",
          }}
        >
          <Input.Title required={true}>Sexo</Input.Title>
          <Input.Select
            name="calculo.sexo"
            options={sexo}
            placeholder="Selecciona uno"
          />
          <Input.ErrorMessage name="calculo.sexo" />
        </Input.Wrapper>

        <Input.Wrapper
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
          }}
        >
          <Input.Title>¿Posee discapacidad?</Input.Title>
          <Input.RadioCard
            name="calculo.discapacidad"
            value="no"
            required={true}
          >
            No posee
          </Input.RadioCard>
          <Input.RadioCard
            name="calculo.discapacidad"
            value="si"
            required={true}
          >
            Si posee
          </Input.RadioCard>
          <Input.ErrorMessage name="tipoDeRetiro" />
          {customErrors && (
            <CustomErrorBeneficiaryModal>
              <span>{customErrors}</span>
            </CustomErrorBeneficiaryModal>
          )}
        </Input.Wrapper>
      </ContentCheckValidacion>
    </Modal>
  </>;
};

export default ModalBeneficiarios;

const useCustomValidation = (watch, arrayBeneficiarios, clientData) => {
  const [error, setError] = useState("");
  // const clientGender = clientData?.gender;
  // const clientBirthday =
  //   clientData?.r_fecha_cumpleaños";
  const clientGender = clientData?.gender || "F"; // for testing in storybook
  const clientBirthday =
    clientData?.r_fecha_cumpleaños || "1990-06-20 00:00:00"; // for testing in storybook

  useEffect(() => {
    const maximumYears = () => {
      if (!watch("calculo.fechaNacimiento")) {
        return true;
      }

      const age = moment().diff(watch("calculo.fechaNacimiento"), "years");
      if (age > 120) return false;

      return true;
    };

    const maximumYearsValidation = maximumYears();

    if (!maximumYearsValidation) {
      return setError("No puedes declarar a alguien mayor de 120 años.");
    }

    const parentsAreAdults = () => {
      if (
        !(
          watch("calculo.parentesco") &&
          watch("calculo.fechaNacimiento") &&
          ["P", "M"].includes(watch("calculo.parentesco"))
        )
      ) {
        return true;
      }

      const beneficiaryAge = moment().diff(
        watch("calculo.fechaNacimiento"),
        "years"
      );

      if (beneficiaryAge < 18) return false;

      return true;
    };

    const parentsAreAdultsValidation = parentsAreAdults();

    if (!parentsAreAdultsValidation) {
      return setError("Los padres deben ser mayores de edad.");
    }

    const oneFather = () => {
      if (!watch("calculo.parentesco") || watch("calculo.parentesco") !== "P") {
        return true;
      }

      const numberOfParents = arrayBeneficiarios.filter(
        (e) => e.parentesco === "Padre"
      );
      if (numberOfParents.length > 0) return false;
      return true;
    };

    const oneFatherValidation = oneFather();

    if (!oneFatherValidation) {
      return setError("Solo puedes declarar a un padre.");
    }

    const oneMother = () => {
      if (!watch("calculo.parentesco") || watch("calculo.parentesco") !== "M") {
        return true;
      }

      const numberOfParents = arrayBeneficiarios.filter(
        (e) => e.parentesco === "Madre"
      );

      if (numberOfParents.length > 0) return false;
      return true;
    };

    const oneMotherValidation = oneMother();

    if (!oneMotherValidation) {
      return setError("Solo puedes declarar a una madre.");
    }

    const hasParentsofSameSex = (gender) => {
      if (watch("calculo.parentesco") !== "P") {
        return true;
      }

      const parent = arrayBeneficiarios.find((e) => e.parentesco === "Padre");
      const secondParentGender =
        gender === "F" ? "Femenimo" : gender === "M" ? "Masculino" : null;

      if (!parent) {
        return true;
      }

      if (parent.sexo === secondParentGender) {
        return false;
      }

      return true;
    };

    const hasParentsofSameSexValidation = hasParentsofSameSex(
      watch("calculo.sexo")
    );

    if (!hasParentsofSameSexValidation) {
      return setError("No puedes declarar a dos padres del mismo sexo.");
    }

    const spouseHasSameSexThanAffiliate = (spouseGender, afilliateGender) => {
      if (
        watch("calculo.parentesco") !== "C" ||
        !spouseGender ||
        !afilliateGender
      ) {
        return true;
      }

      const spouseActualGender =
        spouseGender === "F"
          ? "Femenimo"
          : spouseGender === "M"
            ? "Masculino"
            : null;

      const affiliateActualGender =
        afilliateGender === "F"
          ? "Femenimo"
          : afilliateGender === "M"
            ? "Masculino"
            : null;

      if (!watch("calculo.parentesco") === "C") {
        return true;
      }

      const condition = affiliateActualGender === spouseActualGender;
      // console.log({
      //   affiliateActualGender,
      //   spouseActualGender,
      //   condition,
      // });

      if (condition) {
        return false;
      }

      return true;
    };

    const spouseHasSameSexThanAffiliateValidation =
      spouseHasSameSexThanAffiliate(watch("calculo.sexo"), clientGender);

    if (!spouseHasSameSexThanAffiliateValidation) {
      return setError("No puedes declarar al cónyuge del mismo sexo.");
    }

    const childreCantBeOlderThanParent = () => {
      if (
        !watch("calculo.fechaNacimiento") ||
        watch("calculo.parentesco") !== "H"
      ) {
        return true;
      }

      const clientsAge = moment().diff(clientBirthday, "years");
      const childsAge = moment().diff(
        watch("calculo.fechaNacimiento"),
        "years"
      );

      if (watch("calculo.parentesco") !== "H" || childsAge >= clientsAge) {
        return false;
      }

      return true;
    };

    const childreCantBeOlderThanParentValidation =
      childreCantBeOlderThanParent();

    if (!childreCantBeOlderThanParentValidation) {
      return setError("No se puede declarar a un hijo mayor que el padre.");
    }

    const adultChildrenWithoutDisability = () => {
      const clientsAge = moment().diff(clientBirthday, "years");
      const numberOfYearsToAdd = 65 - clientsAge > 0 ? 65 - clientsAge : 0;

      if (!watch("calculo.fechaNacimiento")) {
        return true;
      }
      const years = moment().diff(watch("calculo.fechaNacimiento"), "years");

      if (
        watch("calculo.parentesco") !== "H" ||
        years + numberOfYearsToAdd < 18
      ) {
        return true;
      }

      if (watch("calculo.discapacidad") === "no") {
        return false;
      }

      return true;
    };

    const adultChildrenWithoutDisabilityValidation =
      adultChildrenWithoutDisability();

    if (!adultChildrenWithoutDisabilityValidation) {
      return setError(
        "Solo serán beneficiarios los hijos que tengan menos de 18 años al momento de tu jubilación."
      );
    }

    const spouseIsNotAdult = () => {
      if (!watch("calculo.fechaNacimiento")) {
        return true;
      }

      const years = moment().diff(watch("calculo.fechaNacimiento"), "years");

      if (watch("calculo.parentesco") !== "C") {
        return true;
      }

      if (years < 18) {
        return false;
      }

      return true;
    };

    const spouseIsNotAdultValidation = spouseIsNotAdult(
      watch("calculo.sexo"),
      clientGender
    );

    if (!spouseIsNotAdultValidation) {
      return setError("El cónyuge debe ser mayor de edad.");
    }

    const insertingMoreThanOneSpouse = () => {
      if (watch("calculo.parentesco") !== "C") {
        return true;
      }

      const numberOfSpouses = arrayBeneficiarios.filter(
        (e) => e.parentesco === "Cónyuge"
      );

      if (numberOfSpouses.length > 0) {
        return false;
      }

      return true;
    };

    const insertingMoreThanOneSpouseValidation = insertingMoreThanOneSpouse();
    // console.log({ insertingMoreThanOneSpouseValidation });

    if (!insertingMoreThanOneSpouseValidation) {
      return setError("Solo puedes declarar a un cónyuge.");
    }

    return setError("");
  }, [
    watch("calculo.parentesco"),
    watch("calculo.sexo"),
    watch("calculo.discapacidad"),
    watch("calculo.fechaNacimiento"),
  ]);

  return error;
};
