import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Sheet } from "konsta/react";

import {
  Box,
  List,
  ListItem,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import CustomModal from "../../../components/custromModal/CustomModal";
import { CardContainer, Button, Tipography } from "../../../components";
import MessageSnack from "./MessageSnack";
import AporteapvsinfinContext from "../../../context/aporteapvsinfin/aportarapvsinfinContext";
import { considerationsFatca } from "../../../utils/constants";
import ICON_INFO from "../../../assets/icons/info_circle.svg";
import CustomMaintenceModal from "./customMaintenanceModal/CustomModal";

const Body = styled.div`
  padding: ${(props) => props.theme.spacing(2, 0)};
  text-align: center;
`;

const PrimerPaso = (props) => {
  const { showValidateFatca, setShowValidateFatca, misDatosGet } = props;
  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const { aportarApvSinFin, loadingBtn } = aporteapvsinfinContext;
  const [showDeclaracion, setShowDeclaracion] = useState(false);
  const [showModalFatca, setShowModalFatca] = useState(false);
  const [showModalExitFatca, setShowModalExitFatca] = useState(false);
  const [showModalMaintence, setShowModalMaintence] = useState(true);
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (props.resultado && props.resultado.r_respuesta === -6) {
      setShowModalFatca(!showModalFatca);
      setShowModalExitFatca(!showModalExitFatca);
    }
    // eslint-disable-next-line
  }, [props.resultado]);

  const handleCheckDisclaimer = () => {
    //DATALAYER INVERTIR
    window.dataLayer.push({
      event: "button_click",
      product_name: "fondo libre habitat",
      step_name: "inicio",
      description: "no tienes residencia fiscal fuera de peru",
      option: "no tengo",
    });
    setShowValidateFatca(!showValidateFatca);
  };

  const handleCheckAccept = () => {
    //DATALAYER INVERTIR
    window.dataLayer.push({
      event: "button_click",
      product_name: "fondo libre habitat",
      step_name: "inicio",
      description: "tienes residencia fiscal fuera de peru",
      option: "si tengo",
    });
    setShowModalFatca(!showModalFatca);
  };

  const handleCheckOpenDeclaracion = () => {
    setShowDeclaracion(!showDeclaracion);
  };
  const procesarSolicitud = () => {
    let objetoProcesado = {};
    objetoProcesado.fatca = 1;
    let debito = {
      v_email: misDatosGet.r_persona.r_datos.r_correo,
      v_cel: misDatosGet.r_persona.r_datos.r_celular,
    };

    objetoProcesado.debito = debito;
    aportarApvSinFin(objetoProcesado);
  };

  return (
    <CardContainer showHeader={false} color={"#ffffff"} border={true}>
      <Body>
        <Tipography component="h4">
          ¿Tienes residencia fiscal fuera de Perú?{" "}
        </Tipography>
        <Box sx={{ width: matches ? "100%" : "70%", margin: "0 auto" }}>
          <List>
            <ListItem sx={{ justifyContent: "flex-start" }}>
              <Tipography component="body2">Se considera cuando</Tipography>
            </ListItem>
            {considerationsFatca.map((item) => (
              <ListItem key={item.id} sx={{ justifyContent: "flex-start" }}>
                <Tipography component="body2">{item.text}</Tipography>
              </ListItem>
            ))}
          </List>

          {/* <MessageSnack
            alt="ICON_INFO"
            icon={ICON_INFO}
            shadow={true}
            description={`Esta información tiene carácter de Declaración Jurada ...`}
            action={true}
            textAction="Ver más"
            color={"#EFBD0C"}
            onClickAction={() => setOpen(true)}
          /> */}
          <MessageSnack icon={ICON_INFO} alt="ICON_INFO">
            <p style={{ marginBottom: "0px", textAlign: "left" }}>
              Esta información tiene carácter de Declaración Jurada ...
              <Link
                component="button"
                onClick={handleCheckOpenDeclaracion}
                underline="none"
                sx={{
                  color: "#2F6D95",
                  fontSize: "14px",
                  fontWeight: "600",
                  float: "unset",
                  display: "inline-block",
                }}
              >
                Ver más
              </Link>
            </p>
          </MessageSnack>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              onClick={handleCheckAccept}
              name="secondary"
              bgColor="white"
            >
              Sí tengo
            </Button>
            <Button onClick={handleCheckDisclaimer}>No tengo</Button>
          </Box>
        </Box>
      </Body>
      <CustomModal
        showModal={showModalFatca}
        className="beauty-modal"
        toggle={() => setShowModalFatca(!showModalFatca)}
        fnCloseIconBtn={() => setShowModalFatca(!showModalFatca)}
        title="Confirma tus datos de contacto"
        description={
          <Box>
            <Box>
              <Tipography component={"body1"}>
                <span>Correo personal: </span>
                <span>
                  {misDatosGet.r_persona.r_datos.r_correo
                    ? misDatosGet.r_persona.r_datos.r_correo
                    : "No registrado"}
                </span>
              </Tipography>
            </Box>
            <Box
              sx={{
                marginTop: "8px",
              }}
            >
              <Tipography component={"body1"}>
                <span>Celular: </span>
                <span>
                  {misDatosGet.r_persona.r_datos.r_celular
                    ? misDatosGet.r_persona.r_datos.r_celular
                    : "No registrado"}
                </span>
              </Tipography>
            </Box>
          </Box>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => procesarSolicitud()}
        textBtnPrimary="Confirmar datos"
        btnPrimaryLoading={loadingBtn}
        isDisabled={loadingBtn}
        hasBtnSecondary={true}
        fnBtnSecondary={() =>
          props.history.push(
            "/mi-habitat-digital/afiliado/informacion/datos-personales"
          )
        }
        textBtnSecondary="Actualizar datos"
      />

      <CustomModal
        showModal={showModalExitFatca}
        className="beauty-modal"
        toggle={() => setShowModalExitFatca(!showModalExitFatca)}
        fnCloseIconBtn={() => setShowModalExitFatca(!showModalExitFatca)}
        title="Muchas gracias por brindarnos tus datos"
        description={
          <Tipography component={"body1"}>
            En un plazo máximo de 1 a 2 días hábiles nos comunicaremos contigo
            para orientarte y concretar tu inversión en Fondo Libre Habitat.
          </Tipography>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          //DATALAYER INVERTIR
          window.dataLayer.push({
            event: "button_click",
            product_name: "fondo libre habitat",
            step_name: "inicio",
            description: "muchas gracias por brindarnos tus datos",
            option: "aceptar",
          });
          setShowModalExitFatca(false);
        }}
        textBtnPrimary="Aceptar"
      />

      <CustomModal
        showModal={showDeclaracion}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => setShowDeclaracion(!showDeclaracion)}
        title="Declaración Jurada"
        description={
          <Tipography component={"body1"}>
            Con carácter de Declaración Jurada manifiesto que soy una “US
            Person”, es decir soy ciudadano(a) estadounidense, residente
            estadounidense o cuento con residencia fiscal con más de 180 días de
            permanencia en los Estados Unidos; que la información declarada es
            exacta, verdadera y eximo a AFP Habitat de toda responsabilidad que
            se derive por la información errónea, falsa o inexacta que yo
            hubiere proporcionado.
          </Tipography>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => setShowDeclaracion(false)}
        textBtnPrimary="Cerrar"
      />
      <CustomMaintenceModal showModal={showModalMaintence} title="Importante" />
    </CardContainer>
  );
};

export default hocWidthWindow(PrimerPaso);
