import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import hocWindowWidth from "../../HOC/HocWidthWindow";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import APVConFin from "./componentes";
import APVConFinMobile from "./componentesMobile";
import { Tutorial } from "./componentesMobile/components";
import HomeContext from "../../context/home/homeContext";
import MisInversionesContext from "../../context/misinversiones/misinversionesContext";
import JubilacionContexts from "../../context/jubilacion/jubilacionContext";
import DetalleProductoSppContext from "../../context/detalleProductoSpp/detalleProductoSppContext";
import Loading from "../../components/Loading/index.jsx";
import InvestmentHistorical from "./componentes/components/InvesmentHistorical";
import useInvestmentData from "./hooks/useInvestmentData";
import useQuery from "../../hooks/useQuery";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = {
  slide: {
    height: 100,
    WebkitOverflowScrolling: "touch", // iOS momentum scrolling
  },
  // root: {
  //   padding: "0 30px",
  // },
  slideContainer: {
    padding: "0 10px",
  },
};
const SwipeTypes = (props) => {
  const getProduct = sessionStorage.getItem("product");
  const SadolProductos = sessionStorage.getItem("fondos");
  const [product, setProduct] = React.useState(getProduct);
  const [value, setValue] = React.useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [cuotasValue, setCuotasValue] = useState(null);
  const [habitatValue, setHabitatValue] = useState(null);
  const [SPPValue, setSPPValue] = useState(null);
  const [cuotasMobileValue, setCuotasMobileValue] = useState(null);

  const homeContext = useContext(HomeContext);
  const detalleProductoSppContext = useContext(DetalleProductoSppContext);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(true);
  const [showTuto, setShowTuto] = useState(true);
  // const subUrlView = sessionStorage.getItem("product");
  const subUrlView = location.search !== "" ? location.search.split("=") : "";
  const jubilacionContext = useContext(JubilacionContexts);
  const { opcionesMenuPost, opcionesMenu } = jubilacionContext;
  const misinversionesContext = useContext(MisInversionesContext);
  const { datosMisInversiones, obtenerDatosMisInversiones } =
    misinversionesContext;
  const { datosDetalleProductoSpp, obtenerDatosDetalleProductoSpp } =
    detalleProductoSppContext;
  const {
    obtenerSummaryFund,
    summaryFundData,
    fondos,
    obtenerDatosHome,
    limpiarVariablesHomeParcial,
  } = homeContext;
  useEffect(() => {
    const cookieName = "something";
    const cookieValue = "something";

    const daysToExpire = new Date(2147483647 * 1000).toUTCString();
    document.cookie =
      cookieName + "=" + cookieValue + "; expires=" + daysToExpire;
    const valueChange =
      getProduct === "OBL" ? "OBL" : getProduct === "VSF" ? "SFP" : "CFP";
    obtenerSummaryFund(valueChange);
    opcionesMenuPost();
    obtenerDatosDetalleProductoSpp(subUrlView[1]);
    obtenerDatosMisInversiones();

    return () => {
      // limpiarVariablesSummaryFund();
      // limpiarVariablesDetalleProductoSppParcial();
    };
    // eslint-disable-next-line
  }, []);

  const extractUserNameFromCookie = (cookieName) => {
    var userName = cookieName + "=";

    // Splitting cookie
    var allCookieArray = document.cookie.split(";");
    for (var i = 0; i < allCookieArray.length; i++) {
      // Extracting userName and returning the same
      var temp = allCookieArray[i].trim();
      if (temp.indexOf(userName) == 0)
        return temp.substring(userName.length, temp.length);
    }

    // Else return empty string
    return "";
  };
  useEffect(() => {
    const valueTab =
      getProduct === "OBL" || (getProduct === "VSF" && fondos.length === 1)
        ? 0
        : getProduct === "VSF" && (fondos.length === 2 || fondos.length === 3)
        ? 1
        : 2;
    setValue(valueTab);
    if (fondos.length >= 0) {
      fondos.map((items) => {
        items.id =
          items.r_cuenta === "OBL" ? 0 : items.r_cuenta === "VSF" ? 1 : 2;
        return items;
      });
      fondos.sort(function (a, b) {
        return a.id - b.id;
      });
    }
    // eslint-disable-next-line
  }, [fondos]);

  useEffect(() => {
    if (summaryFundData) {
      const productSalo = product;
      const habitatValueSaldo = JSON.parse(SadolProductos).filter(
        (item) => item.r_cuenta === productSalo
      );
      setTotalValue(habitatValueSaldo[0].r_saldo);
      const cuotasObl =
        productSalo === "OBL"
          ? calculateOBL(summaryFundData.cuotas)
          : summaryFundData.cuotas[productSalo];

      cuotasObl.fecha_afiliacion =
        summaryFundData.cuotas.length > 0
          ? summaryFundData.cuotas.fecha_afiliacion
          : "";
      cuotasObl.fecha_incorporacion =
        summaryFundData.cuotas.length > 0
          ? summaryFundData.cuotas.fecha_incorporacion
          : "";

      setCuotasValue(cuotasObl);
      setCuotasMobileValue(summaryFundData.cuotas[productSalo]);
      setHabitatValue(summaryFundData.data);
    }
    // eslint-disable-next-line
  }, [summaryFundData]);
  useEffect(() => {
    if (datosDetalleProductoSpp) {
      const sppOBL = datosDetalleProductoSpp.filter(
        (item) => item.grupo === "OBL"
      );

      setSPPValue(sppOBL);
    }

    // eslint-disable-next-line
  }, [datosDetalleProductoSpp]);

  useEffect(() => {
    setIsMobile(props.windowWidth <= 990);
    if (props.windowWidth <= 990 && fondos) obtenerDatosHome();

    return () => {
      limpiarVariablesHomeParcial();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.windowWidth]);

  const calculateOBL = (summaryFundData) => {
    const OblObject = summaryFundData?.["OBL"];
    const OblInaObject = summaryFundData?.["OBL_INA"];
    const OblCalculatedObject = {};

    for (let key in OblObject) {
      if (key !== "valCuota" && key !== "fondo") {
        const OblValue = Number(OblObject[key].replace(",", ""));
        const OblInaValue = Number(OblInaObject[key].replace(",", "")) || 0;
        OblCalculatedObject[key] = OblValue + OblInaValue;
      } else {
        OblCalculatedObject[key] = OblObject[key];
      }
    }

    return OblCalculatedObject;
  };
  const handleChange = (event, newValue) => {
    const id = event.target.id.split("-");

    setProduct(id[2]);
    setValue(newValue);

    const productValue =
      id[2] === "VSF" ? "SFP" : id[2] === "VCF" ? "CFP" : id[2];
    obtenerSummaryFund(productValue);
  };
  const handleChangeIndex = (index) => {
    const productFilter = fondos.filter((item) => item.id === index);

    setProduct(productFilter[0].r_cuenta);
    setValue(index);
    const productValue =
      productFilter[0].r_cuenta === "VSF"
        ? "SFP"
        : productFilter[0].r_cuenta === "VCF"
        ? "CFP"
        : productFilter[0].r_cuenta;
    obtenerSummaryFund(productValue);
  };

  const query = useQuery();
  const productName = query.get("name");

  const invesmentData = useInvestmentData();

  if (
    summaryFundData === null ||
    datosDetalleProductoSpp === null ||
    datosMisInversiones === null ||
    habitatValue === null ||
    SPPValue === null ||
    opcionesMenu === null ||
    (isMobile && !fondos)
  )
    return <Loading />;

  return (
    <div>
      {isMobile ? (
        <div>
          {fondos.length > 1 &&
            extractUserNameFromCookie("something") === "" && (
              <Tutorial show={showTuto} setShow={setShowTuto} />
            )}

          <Box sx={{ borderBottom: 1, borderColor: "divider", height: "65px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={fondos.length > 2 ? "count-products" : "only"}
              aria-label="basic tabs example"
            >
              {fondos.map((item, index) => {
                const indesTab = item.r_cuenta;
                return (
                  <Tab
                    key={index}
                    label={
                      item.r_cuenta === "OBL"
                        ? "Fondo de Jubilación"
                        : item.r_cuenta === "VSF"
                        ? "Fondo Libre Habitat"
                        : "Fondo con Fin Previsional"
                    }
                    {...a11yProps(indesTab)}
                  />
                );
              })}
            </Tabs>
          </Box>
          <SwipeableViews
            enableMouseEvents
            index={value}
            onChangeIndex={handleChangeIndex}
            // containerStyle={styles.slideContainer}
            style={styles.root}
            slideStyle={styles.slideContainer}
          >
            {fondos.map((fondo, index) => (
              <APVConFinMobile
                key={index.r_cuenta}
                datosMisInversiones={datosMisInversiones}
                habitatValue={habitatValue}
                SPPValue={SPPValue}
                totalValue={totalValue}
                cuotasValue={cuotasValue}
                jubilacion={
                  opcionesMenu.r_jubilacion_legal !== "0" ||
                  opcionesMenu.r_bono_reconocimiento !== "0" ||
                  opcionesMenu.r_jubilacion_anticipada !== "0" ||
                  opcionesMenu?.r_jao !== "0"
                }
                summaryFundData={summaryFundData}
                index={
                  fondo.r_cuenta === "OBL"
                    ? 0
                    : fondo.r_cuenta === "VSF"
                    ? 1
                    : 2
                }
                value={value}
                product={product}
              />
            ))}
          </SwipeableViews>
          {productName === "VSF" &&
            invesmentData?.data &&
            invesmentData?.data.length > 0 && (
              <InvestmentHistorical {...invesmentData} />
            )}
        </div>
      ) : (
        <APVConFin
          totalValue={totalValue}
          habitatValue={habitatValue}
          SPPValue={SPPValue}
          product={subUrlView[1]}
          cuotasValue={cuotasValue}
          jubilacion={
            opcionesMenu.r_jubilacion_legal !== "0" ||
            opcionesMenu.r_bono_reconocimiento !== "0" ||
            opcionesMenu.r_jubilacion_anticipada !== "0" ||
            opcionesMenu?.r_jao !== "0"
          }
          productDesktop={product}
          invesmentData={invesmentData}
        />
      )}
    </div>
  );
};
export default hocWindowWidth(SwipeTypes);
