import styled from "styled-components";

export const CaptchaInputComponent = styled.div`
  @media screen and (max-width: 400px) {
    width: 250px;
    & > div {
      transform: scale(0.9);
      transform-origin: 0 0;
    }
  }
`;
