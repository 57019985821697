import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 8px;
  ${(p) => p.theme.tipography.h5}

  .icon-wrapper {
    color: ${(p) => p.theme.palette.state.warning};
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
