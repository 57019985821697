import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import { SubidaDocumentos } from "./components";
import { VistaEspera } from "../../../jubilacionReja/etapas/components";
import imgConfirmation from "../../../../../../assets/confirmacion.svg";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso4(props) {
  const [showView, setShowView] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [dependencies, setDependencies] = useState({
    r_haspension: { has_a_pension: false },
    r_accountDetail: {
      account_detail: {
        r_cuentas: [],
        r_bancos: [],
        r_tipo_cuentas: [],
        bancos_cci: [],
      },
    },
  });
  const [contents, setContents] = useState(null);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    limpiarObtenerContenidoTemaJubilacion,
    limpiarGuardarAvanceTramiteJubilacion,
    mensajeAlerta,
  } = jubilacionContext;
  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerContenidoTemaJubilacion(props.processId, 4);
    return () => {
      limpiarObtenerContenidoTemaJubilacion();
      limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setContents(contenidoTemaJubilacionData.contents);
        setShowView(1);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  useEffect(() => {
    if (mensajeAlerta) {
    }
  }, [mensajeAlerta]);

  if (mensajeAlerta !== null) return <p></p>;
  if (contenidoTemaJubilacionLoading) return <Loading />;
  if (
    contenidoTemaJubilacionData?.name !== "Carga de Documentos" ||
    !dependencies.r_accountDetail
  ) {
    return <Loading />;
  }

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <SubidaDocumentos
            processId={props.processId}
            contents={contents}
            dependencies={dependencies}
            endFourthStep={() => props.setCurrectStepNumber(5)}
            showSendDocumentMessage={() => {
              setShowView(2);
            }}
            getOutOfProcess={() => props.getOutOfProcess()}
          />
        );
      case 2:
        return (
          <VistaEspera
            imgLogo={imgConfirmation}
            title="Se han enviado correctamente los archivos para validar"
            description={
              <>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,
                ahora que tenemos la información necesaria para generar tu
                estimación de pensión, realizaremos la validación de tus datos y
                documentos para dar conformidad y seguir con el trámite.
                <br />
                <br />
                En un plazo de X dias útiles te estaremos informando vía correo
                si todo fue conforme o si existe alguna observación para que
                puedas regularizarla y seguir con el proceso virtual.
              </>
            }
            note="Recuerda mantener tus datos de contacto actualizados y revisar tu bandeja de spam o correos no deseados."
            btnLabel="Regresar al inicio"
            btnFunction={() => props.setCurrectStepNumber(5)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderView()}
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
