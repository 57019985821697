import React from "react";
import PropTypes from "prop-types";
import { IconConent } from "./styles";
const Icon = ({
  componentIcon,
  width,
  height,
  type,
  padding,

  ...props
}) => {
  return (
    <IconConent
      width={width}
      height={height}
      padding={padding}
      type={type}
      {...props}
    >
      {componentIcon}
    </IconConent>
  );
};

export default Icon;

Icon.propTypes = {
  componentIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.string,
};

Icon.defaultProps = {
  width: "24px",
  height: "24px",
  padding: "0",
};
