import React, { Fragment, useContext, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Button, NotFoundData } from "../../../components";
import CustomModal from "../../../components/custromModal/CustomModal";
import ModalToken from "../../../components/ModalToken";
import MisdatosContext from "../../../context/misdatos/misdatosContext";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import GeneralErrorModal from "../GeneralErrorModal";
import ModalErrorToken from "./ModalErrorToken";
import { useAporteMensualRequest } from "./useAporteMensualRequest";

const ListaTarjetas = (props) => {
  const {
    info,
    agregar,
    mostrarModalToken,
    openModalToken,
    setOpenModalToken,
    setMostrarModalToken,
  } = props;
  const [modalInfo, setModalInfo] = useState(null);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const misdatosContext = useContext(MisdatosContext);
  const {
    eliminarTarjetaPost,
    loadingBtnEliminarTarjetaPost,
    mensajeAlertaEliminarTarjetaPost,
  } = misdatosContext;

  const [bankAccountNotRemovableModal, setBankAccountNotRemovableModal] =
    useState(false);

  const aporteMensualRequest = useAporteMensualRequest();
  const bankAccountNotAllowsToRemove =
    aporteMensualRequest.data?.detalle_aporte?.ref_cuenta;

  const handleEliminar = (tarjeta) => (e) => {
    e.preventDefault();

    if (bankAccountNotAllowsToRemove === tarjeta.r_codigo) {
      setBankAccountNotRemovableModal(true);
      return;
    }
    setShowModalInfo(!showModalInfo);
    setModalInfo(tarjeta);
  };
  const procesarSolicitud = (token) => {
    let bodyFormData = new FormData();
    bodyFormData.set("v_referencia", modalInfo.r_codigo);
    bodyFormData.set("v_token_digital", token);
    eliminarTarjetaPost(bodyFormData);
  };

  return (
    <div>
      <Card>
        <CardBody className="custom-only-mobil-body">
          {info.length > 0 ? (
            <div>
              <section className="header-table-make">
                <div className="header-t hdr1">Banco</div>
                <div className="header-t hdr1">Tipo de cuenta</div>
                <div className="header-t hdr2">Nº de cuenta</div>
                <div className="header-t hdr5">Acción</div>
              </section>

              <section className="body-table-make">
                {info.map((tarjeta, index) => (
                  <Fragment key={index}>
                    <section className="body-table-item">
                      <div className="body-t custom-cf-mobile">
                        <span className="only-mobile">
                          {" "}
                          <strong> Banco: </strong>
                        </span>{" "}
                        {/* {tarjeta.r_nombre_banco} */}
                        <p style={{ marginBottom: 0 }}>
                          {tarjeta.r_nombre_banco} <br />*
                          <span className="text_tarjeta">
                            {Number(tarjeta.r_ind_validado) === 1
                              ? "Tarjeta validada"
                              : "Tarjeta no validada"}
                          </span>
                        </p>
                      </div>
                      <div className="body-t custom-cf-mobile">
                        <span className="only-mobile">
                          {" "}
                          <strong> Tipo de cuenta.: </strong>
                        </span>{" "}
                        <p> {tarjeta.r_tipo_cuenta}</p>
                      </div>
                      <div className="body-t custom-cf-mobile">
                        <span className="only-mobile">
                          {" "}
                          <strong> N.º cta.: </strong>
                        </span>{" "}
                        <p> {tarjeta.r_numero_cuenta}</p>
                      </div>
                      <div className="body-t text-center negrita-mobile">
                        <Button onClick={handleEliminar(tarjeta)}>
                          Eliminar
                        </Button>
                      </div>
                    </section>
                    {props.windowWidth < 600 && <br />}
                  </Fragment>
                ))}
              </section>
            </div>
          ) : (
            <NotFoundData
              mensaje={
                "Usted no cuenta con niguna tarjeta, le invitamos agregar una tarjeta."
              }
              btnText={"Agregar tarjeta"}
              funcBtn={agregar}
            />
          )}
        </CardBody>
      </Card>
      <CustomModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de eliminación"
        description={
          modalInfo && (
            <>
              <div className="px-3 py-2">
                <p style={{ textAlign: "justify" }}>
                  Estimado usuario, usted está apunto de eliminar la siguiente
                  cuenta bancaria, si esta de acuerdo con esto, de click en
                  Confirmar.
                </p>
              </div>

              <section className="mb-2">
                {modalInfo.r_nombre_banco && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Banco:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_nombre_banco}
                    </p>
                  </div>
                )}
                {modalInfo.r_tipo_cuenta && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Tipo de cuenta:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_tipo_cuenta}
                    </p>
                  </div>
                )}
                {modalInfo.r_numero_cuenta && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">N.° de cuenta:</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_numero_cuenta}
                    </p>
                  </div>
                )}
                {mensajeAlertaEliminarTarjetaPost && (
                  <div className="alert alert-danger mt-2">
                    Hubo un problema por favor vuelva a intentarlo
                  </div>
                )}
              </section>
            </>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setOpenModalToken(true);
          setShowModalInfo(false);
        }}
        btnPrimaryLoading={loadingBtnEliminarTarjetaPost}
        textBtnPrimary="Confirmar"
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}{" "}
      {mostrarModalToken && (
        <ModalErrorToken
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
      <GeneralErrorModal
        isOpen={bankAccountNotRemovableModal}
        setIsOpen={setBankAccountNotRemovableModal}
        title="Ocurrio un error"
        subtitle="Lo sentimos, pero no puede eliminar esta cuenta porque tiene un débito mensual asociado"
      />
    </div>
  );
};

export default hocWidthWindow(ListaTarjetas);
