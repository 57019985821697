import React, { useReducer } from "react";
import GenerarClaveContext from "./generarClaveContext";
import GenerarClaveReducer from "./generarClaveReducer";
import { clienteGeneralAxios } from "../../config/axios";

import {
    GENC_DATOS_USUARIO_ERROR,
    GENC_DATOS_USUARIO_EXITOSO,
    GENC_BLOQUEO_PASOS_GENERAL,
    GENC_INTENTO_DOCUMENTO,
    GENC_INTENTO_VOLVER_TOKEN,
    GENC_INTENTO_CONFIRMAR_TOKEN,
    GENC_INTENTO_CAMBIO_CLAVE,
    GENC_TOKEN_MOD_GENCLAVEWEB,
    ERROR_GENERICO_CLAVE_WEB

} from "../../types/generarClave";

const GenerarClaveState = (props) => {
    const initialState = {
        gTokenClaveWeb: "",
        gUsuarioExitoso: { "email": "", "cel": "", "name": "" },
        gBloqueoPasos: false,
        gIntentoDocumento: 0,
        gIntentoVolverToken: 0,
        gIntentoConfirmaToken: 0,
        gintentoCambioClave: 0,
        genericoError: { 'message': '', "activo": false }
    };

    const handleErrorsLocal = (error, origin_error) => {
        if (error.message === "Network Error") {
            dispatch({
                type: origin_error,
                payload: {
                    code: 1, // error de red
                    msg: "Su red presenta lentitud, por favor recargue la página.",
                },
            });
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
        } else {
            if (error.response?.status === 500) {
                dispatch({
                    type: origin_error,
                    payload: {
                        code: 1, // su token a expirado
                        msg: "Error en los servicios, por favor recargue la página.",
                    },
                });
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
                dispatch({
                    type: origin_error,
                    payload: {
                        code: 2, // su token a expirado
                        msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
                    },
                });
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            }
        }
    };

    const obtenerDatosUsuario = async (datos) => {

        let response = true
        let intentosG = 0
        let bloqueadoG = false
        try {
            const respuesta = await clienteGeneralAxios.post('/services/recuperacion-clave/info-afiliado', datos)
            if (respuesta.data.not_available) {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                if (respuesta.data.success === false && respuesta.data.intentos > 2) {
                    bloquearPasos(true)
                } else if (respuesta.data.success === false) {
                    response = true
                } else if (respuesta.data.success === true) {
                    dispatch({
                        type: GENC_DATOS_USUARIO_EXITOSO,
                        payload: respuesta.data.data,
                    });

                    response = false
                }
                if (respuesta.data.token) {
                    actualizarToken(respuesta.data.token)
                } else if (respuesta.data.session_expired) {
                    LlenarErrorGenerico({ 'message': 'Su sesión ha expirado, por favor recargue la página.', "activo": true })
                }
                intentosG = respuesta.data.intentos
                cambioIntentoDocumento(respuesta.data.intentos)
                bloqueadoG = respuesta.data.bloqueado
            }


        } catch (error) {
            datosInicialUsuario()
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            handleErrorsLocal(error, GENC_DATOS_USUARIO_ERROR);
        }
        return { response: response, intentosG: intentosG }
    };

    const actualizarToken = (datos) => {
        dispatch({
            type: GENC_TOKEN_MOD_GENCLAVEWEB,
            payload: datos,
        });
    }

    const LlenarErrorGenerico = (datos) => {
        dispatch({
            type: ERROR_GENERICO_CLAVE_WEB,
            payload: datos,
        });
    }

    const datosInicialUsuario = () => {
        dispatch({
            type: GENC_DATOS_USUARIO_EXITOSO,
            payload: { "email": "", "cel": "" },
        });
    }

    const changePassFunct = async (datos) => {
        let response = true
        let intentosG = 0
        let bloqueadoG = false
        try {
            const respuesta = await clienteGeneralAxios.post('/services/recuperacion-clave/cambio-clave', datos)
            if (respuesta.data.not_available) {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                if (respuesta.data.success === false && respuesta.data.intentos === 3) {
                    bloquearPasos(true)
                } else if (respuesta.data.success === false) {
                    response = true
                } else if (respuesta.data.success === true) {
                    response = false
                }
                intentosG = respuesta.data.intentos
                cambioIntentoClave(respuesta.data.intentos)
                bloqueadoG = respuesta.data.bloqueado
                if (respuesta.data.token) {
                    actualizarToken(respuesta.data.token)
                } else if (respuesta.data.session_expired) {
                    LlenarErrorGenerico({ 'message': 'Su sesión ha expirado, por favor recargue la página.', "activo": true })
                }
            }

        } catch (error) {
            datosInicialUsuario()
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            handleErrorsLocal(error, GENC_DATOS_USUARIO_ERROR);
        }
        return { response: response, intentosG: intentosG }
    }

    const envioTokenDigital = async (datos) => {
        let response = true
        let intentosG = 0
        let bloqueadoG = false
        try {
            const respuesta = await clienteGeneralAxios.post('/services/recuperacion-clave/send-token', datos)
            if (respuesta.data.not_available) {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                if (respuesta.data.success === false && respuesta.data.intentos > 3) {
                    bloquearPasos(true)
                } else if (respuesta.data.success === false) {
                    response = true
                } else if (respuesta.data.success === true) {
                    response = false
                }
                if (respuesta.data.token) {
                    actualizarToken(respuesta.data.token)
                } else if (respuesta.data.session_expired) {
                    LlenarErrorGenerico({ 'message': 'Su sesión ha expirado, por favor recargue la página.', "activo": true })
                }

                intentosG = respuesta.data.intentos
                cambioIntentoVolverToken(respuesta.data.intentos)
                bloqueadoG = respuesta.data.bloqueado
            }

        } catch (error) {
            datosInicialUsuario()
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            handleErrorsLocal(error, GENC_DATOS_USUARIO_ERROR);
        }
        return { response: response, intentosG: intentosG, bloqueadoG: bloqueadoG }
    }

    const confirmaTokenDigital = async (datos) => {
        let response = true
        let intentosG = 0
        let bloqueadoG = false
        try {
            const respuesta = await clienteGeneralAxios.post('/services/recuperacion-clave/validate-token', datos)
            if (respuesta.data.not_available) {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                if (respuesta.data.success === false && respuesta.data.intentos > 2) {
                    bloquearPasos(true)
                } else if (respuesta.data.success === false) {
                    response = true
                } else if (respuesta.data.success === true) {
                    response = false
                }
                if (respuesta.data.token) {
                    actualizarToken(respuesta.data.token)
                } else if (respuesta.data.session_expired) {
                    LlenarErrorGenerico({ 'message': 'Su sesión ha expirado, por favor recargue la página.', "activo": true })
                }
                intentosG = respuesta.data.intentos
                cambioIntentoConfirmaToken(respuesta.data.intentos)
                bloqueadoG = respuesta.data.bloqueado
            }

        } catch (error) {
            datosInicialUsuario()
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            handleErrorsLocal(error, GENC_DATOS_USUARIO_ERROR);
        }
        return { response: response, intentosG: intentosG }
    }

    const guardarIntentoGeneral = async (datos) => {
        let response = true
        let intentosG = 0
        let bloqueadoG = false
        try {
            const respuesta = await clienteGeneralAxios.post('/services/recuperacion-clave/guardar-intento', datos)
            if (respuesta.data.not_available) {
                LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            } else {
                if (respuesta.data.success === false && respuesta.data.intentos === 3) {
                    bloquearPasos(true)
                } else if (respuesta.data.success === false) {
                    response = true
                } else if (respuesta.data.success === true) {
                    response = false
                }
                if (respuesta.data.token) {
                    actualizarToken(respuesta.data.token)
                } else if (respuesta.data.session_expired) {
                    LlenarErrorGenerico({ 'message': 'Su sesión ha expirado, por favor recargue la página.', "activo": true })
                }
                intentosG = respuesta.data.intentos
                cambioIntentoClave(respuesta.data.intentos)
                bloqueadoG = respuesta.data.bloqueado
            }

        } catch (error) {
            datosInicialUsuario()
            LlenarErrorGenerico({ 'message': 'Error en los servicios, por favor recargue la página.', "activo": true })
            handleErrorsLocal(error, GENC_DATOS_USUARIO_ERROR);
        }
        return { response: response, intentosG: intentosG }
    }

    const bloquearPasos = async (datos) => {
        dispatch({
            type: GENC_BLOQUEO_PASOS_GENERAL,
            payload: datos,
        });
    };

    const cambioIntentoDocumento = async (datos) => {
        dispatch({
            type: GENC_INTENTO_DOCUMENTO,
            payload: datos,
        });
    };

    const cambioIntentoVolverToken = async (datos) => {
        dispatch({
            type: GENC_INTENTO_VOLVER_TOKEN,
            payload: datos,
        });
    };

    const cambioIntentoConfirmaToken = async (datos) => {
        dispatch({
            type: GENC_INTENTO_CONFIRMAR_TOKEN,
            payload: datos,
        });
    };

    const cambioIntentoClave = async (datos) => {
        dispatch({
            type: GENC_INTENTO_CAMBIO_CLAVE,
            payload: datos,
        });
    };

    const [state, dispatch] = useReducer(GenerarClaveReducer, initialState);
    return (
        <GenerarClaveContext.Provider
            value={{
                gUsuarioExitoso: state.gUsuarioExitoso,
                gBloqueoPasos: state.gBloqueoPasos,
                gIntentoDocumento: state.gIntentoDocumento,
                gIntentoVolverToken: state.gIntentoVolverToken,
                gIntentoConfirmaToken: state.gIntentoConfirmaToken,
                gintentoCambioClave: state.gintentoCambioClave,
                gTokenClaveWeb: state.gTokenClaveWeb,
                genericoError: state.genericoError,
                obtenerDatosUsuario,
                bloquearPasos,
                cambioIntentoDocumento,
                cambioIntentoVolverToken,
                cambioIntentoConfirmaToken,
                cambioIntentoClave,
                changePassFunct,
                envioTokenDigital,
                confirmaTokenDigital,
                guardarIntentoGeneral,
                actualizarToken,
                LlenarErrorGenerico,
                datosInicialUsuario
            }}
        >
            {props.children}
        </GenerarClaveContext.Provider>
    );
};

export default GenerarClaveState;
