const white = "#ffffff";
const black = "#000000";

export default {
  black,
  white,
  textColor: {
    main: "#363A3E",
    mainLaigth: "#51565F",
    secondary: "#6E7378",
    disabled: "#8C9195",
    dark: "#ffffff",
    subTitle: "#2E2E2E",
  },
  grayscale: {
    light: "#8E9B9E",
    blod: "#51565F",
    dark: "#363A3E",
    blocked: "#8C9195",
    800: "#363A3E",
    700: "#51565F",
    500: "#6E7378",
    400: "#8C9195",
    300: "#ACAFB3",
    200: "#CDCED0",
    100: "#EDEDED",
    50: "#F6F6F6",
  },

  primary: {
    contrastText: white,
    main: "#CE1336",
    900: "#8A001A",
    400: "#CE1336",
    200: "#FF3A5E",
    100: "#FF8AA0",
    50: "#FFEBEF",
  },
  secondary: {
    contrastText: white,
    main: "#2F6D95",
    light: "#B7E3FF",
    lightest: "#F2FAFF",
  },
  lead: {
    600: "#8E9B9E",
    300: "#DCE3E4",
    100: "#F8F9FB",
  },
  transparencies: {
    dark: "rgba(54, 58, 62, 0.4)",
    light: "rgba(20, 20, 20, 0.15)",
    lightest: "rgba(20, 20, 20, 0.05)",
  },
  blocked: {
    default: "#ff9eb9",
    disabled: "#EDEDED",
  },
  background: {
    default: "#F8F9FB",
    disabled: "#E5E5E5",
  },
  trafficLight: {
    red: "#C92D54",
    yellow: "#FAC522",
    green: "#48C180",
    purple: "#73607D",
  },
  alert: {
    info: "#EFF3FE",
  },
  numbers: {
    positive: "#48c180",
    negative: "#ce1336",
  },
  shadows: "rgba(27, 29, 39, 0.32)",
  state: {
    info: "#396DBB",
    infoLight: "#EBF3FF",
    warning: "#EFBD0C",
    warningLight: "#FFFAF0",
    error: "#E11900",
    errorLight: "#FFEFED",
    success: "#1B7935",
    successLight: "#E6F2ED",
  },
};
