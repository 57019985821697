import React, { useEffect, useState } from "react";
import Modal from "../../../../../../../components/BasicModal";
import Button from "../../../../../../../components/Buttons";
import * as Input from "../../../../../../../components/Input";
import InfoButton from "../../../../../../../components/InfoButton";
import BirthCertificateModal from "./BirthCertificateModal";
import DisabilityCertificateModal from "./DisabilityCertificateModal";
import styled from "styled-components";
import MarriageCertificateModal from "./MarriageCertificateModal";
import { useBeneficiariesContext } from "../../Table/beneficiariesContext";
import { useFormContext } from "react-hook-form";
import { getRelationshipName, getYearsFromNow } from "../../../utils";

const UploadDocumentsModal = ({ open, setOpen, data }) => {
  const {
    getValues,
    reset,
    resetField,
    formState: { errors },
  } = useFormContext();

  const onClose = () => setOpen(false);
  const [birthCertificateModalIsOpen, setBirthCertificateModalIsOpen] =
    useState(false);
  const [
    disabilityCertificateModalIsOpen,
    setDisabilityCertificateModalIsOpen,
  ] = useState(false);
  const [marriageCertificateModalIsOpen, setMarriageCertificateModalIsOpen] =
    useState(false);

  const { sendBeneficiaryDocument, ...contenxt } = useBeneficiariesContext();

  const onSaveDocument = () => {
    sendBeneficiaryDocument({
      id: data.id_oracle,
      documento: getValues("beneficiaryId")?.[0],
      documento_partida: getValues("birthCertificate")?.[0],
      documento_sustento: getValues("disability")?.[0],
      documento_matrimonio: getValues("marriageCertificate")?.[0],
    });
    onClose();
  };

  const validateName = (name) => {
    const dotCount = (name.match(/\./g) || []).length;
    if (dotCount > 1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (open) return;
    resetField("beneficiaryId");
    resetField("birthCertificate");
    resetField("marriageCertificate");
    resetField("disability");
  }, [open]);

  const filesMaxSize = 2;
  const filesExtensions = ["pdf", "jpg", "jpeg", "png"];

  const validateDisableButton = () => {
    let marriage;
    let birthCertificate;
    let disability;

    let beneficiary = [
      getValues("beneficiaryId")?.[0],
      !errors["beneficiaryId"],
    ].every(Boolean);

    if (data.relationship_id === 1) {
      marriage = [
        getValues("marriageCertificate")?.[0],
        !errors["marriageCertificate"],
      ].every(Boolean);
    } else {
      marriage = true;
    }

    if (data.relationship_id !== 1) {
      birthCertificate = [
        getValues("birthCertificate")?.[0],
        !errors["birthCertificate"],
      ].every(Boolean);
    } else {
      birthCertificate = true;
    }

    if (data.situation === "Con discapacidad" && data.relationship_id !== 1) {
      disability = [getValues("disability")?.[0], !errors["disability"]].every(
        Boolean
      );
    } else {
      disability = true;
    }

    return beneficiary && marriage && birthCertificate && disability;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Documentos de beneficiario</Modal.Title>
      <Modal.Subtitle style={{ marginBottom: "16px" }}>
        <p>
          Sube los siguientes documentos para que podamos validar a tu
          beneficiario:
        </p>
      </Modal.Subtitle>
      <UserInformationTable data={data} />
      <DocumentsConsiderations />
      <Input.Wrapper>
        <InputTitleWrapperStyles>
          <Input.Title>Documento de identidad (ambas caras) </Input.Title>
        </InputTitleWrapperStyles>

        <Input.File
          extensions={filesExtensions}
          maxFileSize={filesMaxSize}
          size={"small"}
          name="beneficiaryId"
        />
        <Input.ErrorMessage name="beneficiaryId" />
      </Input.Wrapper>
      {data.relationship_id !== 1 && (
        <Input.Wrapper>
          <InputTitleWrapperStyles>
            <Input.Title>
              {data.relationship_id === 3
                ? "Partida de nacimiento del afiliado"
                : "Partida de nacimiento del beneficiario"}
            </Input.Title>
            <InfoButton
              tooltip={false}
              onClick={() => setBirthCertificateModalIsOpen(true)}
            />
          </InputTitleWrapperStyles>
          <Input.File
            extensions={filesExtensions}
            maxFileSize={filesMaxSize}
            size={"small"}
            name="birthCertificate"
          />
          <Input.ErrorMessage name="birthCertificate" />
        </Input.Wrapper>
      )}
      {/* conyuge */}
      {data.relationship_id === 1 && (
        <Input.Wrapper>
          <InputTitleWrapperStyles>
            <Input.Title>Constancia de matrimonio o concubinato</Input.Title>
            <InfoButton
              tooltip={false}
              onClick={() => setMarriageCertificateModalIsOpen(true)}
            />
          </InputTitleWrapperStyles>
          <Input.File
            extensions={filesExtensions}
            maxFileSize={filesMaxSize}
            size={"small"}
            name="marriageCertificate"
          />
          <Input.ErrorMessage name="marriageCertificate" />
        </Input.Wrapper>
      )}
      {data.situation === "Con discapacidad" && data.relationship_id !== 1 && (
        <Input.Wrapper>
          <InputTitleWrapperStyles>
            <Input.Title>Sustento de condición de invalidez</Input.Title>
            <InfoButton
              tooltip={false}
              onClick={() => setDisabilityCertificateModalIsOpen(true)}
            />
          </InputTitleWrapperStyles>
          <Input.File
            extensions={filesExtensions}
            maxFileSize={filesMaxSize}
            size={"small"}
            name="disability"
          />
          <Input.ErrorMessage name="disability" />
        </Input.Wrapper>
      )}
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "20px",
        }}
      >
        <Button fullWidth onClick={onClose} variant={"secondary"}>
          Cancelar
        </Button>
        <Button
          fullWidth
          onClick={onSaveDocument}
          disabled={!validateDisableButton()}
        >
          Subir
        </Button>
      </div>
      <BirthCertificateModal
        open={birthCertificateModalIsOpen}
        setOpen={setBirthCertificateModalIsOpen}
      />
      <DisabilityCertificateModal
        open={disabilityCertificateModalIsOpen}
        setOpen={setDisabilityCertificateModalIsOpen}
      />
      <MarriageCertificateModal
        open={marriageCertificateModalIsOpen}
        setOpen={setMarriageCertificateModalIsOpen}
      />
    </Modal>
  );
};

const DocumentsConsiderations = () => {
  const Consideration = styled.p`
    ${(p) => p.theme.tipography.bodySmallRegular};
    color: ${(p) => p.theme.palette.grayscale["400"]};
    margin-bottom: 16px !important;
  `;

  return (
    <Consideration>
      Formatos admitidos: pdf, jpg, jpeg, png. Se debe subir un archivo por cada
      documento. Tamaño máximo: 2mb por archivo.
    </Consideration>
  );
};

const UserInformationTable = ({ data }) => {
  const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
  `;

  const InfoItem = styled.div`
    p {
      text-align: center;
      color: ${(p) => p.theme.palette.grayscale["800"]};
    }

    p.key {
      padding: 8px;
      ${(p) => p.theme.tipography.headingSubtitleSemi};
      background-color: ${(p) => p.theme.palette.grayscale["50"]};
    }

    p.value {
      padding: 8px;
      ${(p) => p.theme.tipography.bodySmallRegular};
    }

    span.divider {
      display: block;
      height: 1px;
      width: 100%;
      background: ${(p) => p.theme.palette.grayscale["800"]};
    }
  `;

  return (
    <Wrapper>
      <InfoItem>
        <p className="key">Parentesco</p>
        <span className="divider"></span>
        <p className="value">{getRelationshipName(data.relationship_id)}</p>
      </InfoItem>
      <InfoItem>
        <p className="key">Edad</p>
        <span className="divider"></span>
        <p className="value">{getYearsFromNow(data.birthday)}</p>
      </InfoItem>
      <InfoItem>
        <p className="key">Sexo</p>
        <span className="divider"></span>

        <p className="value">{data.gender === "Mujer" ? "F" : "M"}</p>
      </InfoItem>
      <InfoItem>
        <p className="key">Condicion</p>
        <span className="divider"></span>

        <p className="value">
          {data.situation === "Sin discapacidad" ? "Sano" : "Inválido"}
        </p>
      </InfoItem>
    </Wrapper>
  );
};

export default UploadDocumentsModal;

const InputTitleWrapperStyles = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  * {
    grid-column: auto;
  }
`;
