import styled, { css } from "styled-components";
import { device } from "../../utils/device";

export const ZonaDeBeneficiariosComponent = styled.div`
  grid-area: main;
  display: grid;
  grid-template-rows: auto 1fr;

  grid-template-areas:
    "header"
    "body";

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;

export const Body = styled.div`
  grid-area: body;
  background-color: ${(p) => p.theme.palette.white};
  border-radius: 16px;
  margin-bottom: 24px;
`;

export const Main = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  padding: 24px 16px 16px 16px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }

  ${(p) => css`
    @media screen and ${device.desktopS} {
      padding: 24px;
    }

    @media screen and ${device.desktop} {
      max-width: calc(100% / 12 * 8);
      padding: 48px 24px;
    }

    @media screen and ${device.desktopL} {
    }
  `}
`;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 16px;

  ${css`
    @media screen and ${device.desktopS} {
      ${(p) => p.theme.tipography.h4}
    }
  `}
`;

export const ProcedureWrapper = styled.div`
  display: grid;
  gap: 16px;

  ${css`
    @media screen and ${device.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const Footer = styled.div`
  align-self: end;
`;
