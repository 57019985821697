import React, { useContext, useEffect, useState } from "react";
import ReactGa from "react-ga";
import Grid from "@mui/material/Grid";
import { Fatca } from "./components";
import { Page } from "../../components";
import AuthContext from "../../context/autenticacion/authContext";
import AporteapvsinfinContext from "../../context/aporteapvsinfin/aportarapvsinfinContext";
import SeguimientoAportesContext from "../../context/seguimientoAportes/seguimientoAportesContext";
import ModalContext from "../../context/modal/modalContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";

import Informacion from "./InfoUsuario";
import PrimerPaso from "./PrimerPaso";
import SolictudNoProcesada from "./SolicitudNoProcesada";
import SolicitudProcesada from "./SolicitudProcesada";
import MensajeInformativo from "./MensajeInformativo";
import Loading from "../../components/Loading/index.jsx";
import moment from "moment";

import "./index.css";
const AporteVoluntarioSinFin = (props) => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer, nombreUsuario, birthday } = authContext;
  const misDatosContext = useContext(MisDatosContext);
  const [breadCrumb, setBreadCrumb] = useState(0);
  const [showValidateFatca, setShowValidateFatca] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const seguimientoAportesContext = useContext(SeguimientoAportesContext);
  const {
    seguimientoAportes,
    mensajeAlerta: mensajeAportesAlerta,
    loadingPage: loadingAportesPage,
    obtenerSeguimientosAportes,
    limpiarSeguimientosAportes,
  } = seguimientoAportesContext;
  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;
  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const {
    datosAportarApvSinFin,
    mensajeInformativo,
    mensajeAlerta,
    obtenerDatosParaAportarApvSinFin,
    limpiarVariablesAportarApvSinFin,
    limpiarVariablesAportarApvSinFinParcial,
    limpiarMensajeErrorPost,
    datosResultadoAportarApvSinFin,
  } = aporteapvsinfinContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (mensajeAlerta) {
      mostrarMensaje(mensajeAlerta);
    } else if (mensajeAportesAlerta) {
      mostrarMensaje(mensajeAportesAlerta);
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosParaAportarApvSinFin();
      obtenerSeguimientosAportes();
      obtenerMisDatosGet();
    }
    return () => {
      limpiarVariablesAportarApvSinFinParcial();
      limpiarSeguimientosAportes();
      limpiarVariablesAportarApvSinFin();
      limpiarMensajeErrorPost();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta, mensajeAportesAlerta]);

  useEffect(() => {
    if (datosResultadoAportarApvSinFin) {
      if (datosResultadoAportarApvSinFin.r_respuesta === -6) {
        setShowMessage(false);
      } else {
        setShowMessage(true);
        loadNPS();
      }
    }
    // eslint-disable-next-line
  }, [datosResultadoAportarApvSinFin]);

  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");

  const loadNPS = () => {
    const filterAge =
      age < 25
        ? "Menores de 25"
        : age >= 25 && age < 35
        ? "25 A 34"
        : age >= 35 && age < 51
        ? "35 a 51"
        : age >= 51 && age < 65
        ? "51 a 65"
        : "Mayores de 65";

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: "aporte fondo libre",
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", {
        detail: {
          id: 814,
        },
      })
    );
  };

  const mostrarMensaje = (mensaje) => {
    setMessage(mensaje);
  };
  const changeView = () => {
    props.history.push("/mi-habitat-digital/afiliado/seguimiento/apv");
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (datosAportarApvSinFin === null || misDatosGet === null)
    return <Loading />;
  return (
    <Page
      title="Invertir en Fondo Libre Habitat"
      showHeader={"mobile"}
      identificador={"aporte"}
      sx={{ userSelect: "none" }}
    >
      <Grid container>
        {/* {false ? ( */}
        {datosAportarApvSinFin.r_validacion !== "0" && showValidateFatca ? (
          <Grid item xs={12} lg={12}>
            <Fatca
              showValidateFatca={showValidateFatca}
              setShowValidateFatca={setShowValidateFatca}
              misDatosGet={misDatosGet}
              resultado={datosResultadoAportarApvSinFin}
              history={props.history}
              limpiarVariablesAportarApvSinFinParcial={
                limpiarVariablesAportarApvSinFin
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12} lg={12}>
            {/* {true && ( */}
            {datosAportarApvSinFin.r_validacion !== "0" && (
              <PrimerPaso
                opcionFondo={"mostrar"} // mostrar o seleccionar
                opcionCuentas={"conCuentas"} // conCuentas sinCuentas
                opcion="favoritos"
                nombreUsuario={nombreUsuario}
                data={datosAportarApvSinFin}
                resultado={datosResultadoAportarApvSinFin}
                seguimientoAportes={seguimientoAportes}
                obtenerSeguimientosAportes={obtenerSeguimientosAportes}
                limpiarVariablesAportarApvSinFinParcial={
                  limpiarVariablesAportarApvSinFinParcial
                }
                misDatosGet={misDatosGet}
                obtenerDatosParaAportarApvSinFin={
                  obtenerDatosParaAportarApvSinFin
                }
                changeView={changeView}
                setBreadCrumb={setBreadCrumb}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default AporteVoluntarioSinFin;
