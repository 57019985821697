import React from "react";
import { Link } from "react-router-dom";
import { CardContainer, Button } from "../../../components";
const SolicitudNoProcesada = ({ mensaje, resetear }) => {
  const handlerReset = () => {
    resetear();
  };
  return (
    <CardContainer>
      <div className="component-mensaje px-1">
        <h5 style={{ color: "#bf253d", fontWeight: 600 }}>¡Alerta!</h5>
        <p className="text-center">{mensaje}</p>
        {/* <p className="text-center">{errorMessage}</p> */}
        <p className="contenedor-btn-no-proceso justify-content-around">
          <Link to="/mi-habitat-digital/afiliado/inicio">
            <Button>Volver al inicio</Button>
          </Link>
          <Button onClick={handlerReset}>Volver a intentarlo</Button>
        </p>
      </div>
    </CardContainer>
  );
};

export default SolicitudNoProcesada;
