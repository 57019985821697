import React, { useState, useContext } from "react";
import MisdatosContext from "../../context/misdatos/misdatosContext";
import AuthContext from "../../context/autenticacion/authContext";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { useEffect } from "react";
import { Button } from "../../components";
import ModalToken from "../../components/ModalToken/index.jsx";
import ModalTokenError from "../../components/ModalTokenError/index.jsx";

const globalErrors = {
  r_clave_web: "",
  r_clave_nueva: "",
  r_clave_confirmar: "",
};

const Formulario = (props) => {
  const misdatosContext = useContext(MisdatosContext);
  const { cambiarClavePost, loadingBtnClavePost, resultadoClavePost } =
    misdatosContext;

  const [claves, setClaves] = useState({
    r_clave_web: "",
    r_clave_nueva: "",
    r_clave_confirmar: "",
  });
  const [errores, setErrores] = useState(globalErrors);
  const [showPassword, setShowPassword] = useState("");

  const authContext = useContext(AuthContext);
  const { cambioClavePrimeraVezExitoso, cambio_clave, closeModalCambioClave } =
    authContext;

  const [openModalToken, setOpenModalToken] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);

  useEffect(() => {
    if (resultadoClavePost) {
      if (resultadoClavePost.r_respuesta === "1") {
        cambioClavePrimeraVezExitoso();
      }
    }
    // eslint-disable-next-line
  }, [resultadoClavePost]);

  const handlerShowPassword = (option) => (e) => {
    if (e.type === "mousedown") {
      setShowPassword(option);
    } else {
      setShowPassword("");
    }
  };

  const handlerPassword = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setClaves({ ...claves, [e.target.name]: valor });
    setErrores({ ...errores, [e.target.name]: "" });
  };

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const validarData = () => {
    let mensajesError = {
      r_clave_web: "",
      r_clave_nueva: "",
      r_clave_confirmar: "",
    };

    if (claves.r_clave_web === 0 || claves.r_clave_web.trim() === "") {
      mensajesError = { ...mensajesError, r_clave_web: "campo requerido" };
    } else {
      if (claves.r_clave_web.length < 4) {
        mensajesError = { ...mensajesError, r_clave_web: "campo de 4 dígitos" };
      }
    }
    if (claves.r_clave_nueva === 0 || claves.r_clave_nueva.trim() === "") {
      mensajesError = { ...mensajesError, r_clave_nueva: "campo requerido" };
    } else {
      if (claves.r_clave_nueva.length < 4) {
        mensajesError = {
          ...mensajesError,
          r_clave_nueva: "campo de 4 dígitos",
        };
      } else {
        if (claves.r_clave_web === claves.r_clave_nueva) {
          mensajesError = {
            ...mensajesError,
            r_clave_nueva: "debe ser diferente a la clave actual",
          };
        }
      }
    }

    if (
      claves.r_clave_confirmar === 0 ||
      claves.r_clave_confirmar.trim() === ""
    ) {
      mensajesError = {
        ...mensajesError,
        r_clave_confirmar: "campo requerido",
      };
    } else {
      if (claves.r_clave_confirmar.length < 4) {
        mensajesError = {
          ...mensajesError,
          r_clave_confirmar: "campo de 4 dígitos",
        };
      } else {
        if (claves.r_clave_confirmar !== claves.r_clave_nueva) {
          mensajesError = {
            ...mensajesError,
            r_clave_confirmar: "no es igual a la nueva clave",
          };
        }
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const handleSubmit = () => {
    if (validarData()) {
      // closeModalCambioClave();
      setOpenModalToken(true);
    }
  };
  const handleUpdate = async (token) => {
    let bodyFormData = new FormData();
    bodyFormData.set("v_clave", claves.r_clave_web);
    bodyFormData.set("v_nueva_clave", claves.r_clave_nueva);
    bodyFormData.set("v_token_digital", token);
    let response = await cambiarClavePost(bodyFormData);

    if (response.status === "200" && response.data.r_respuesta !== -3) {
      setOpenModalToken(false);
    }
  };

  const handlerClose = () => {
    closeModalCambioClave();
  };

  useEffect(() => {
    if (resultadoClavePost) {
      if (resultadoClavePost.r_respuesta === -3) {
        setMostrarModalToken(resultadoClavePost);
        setOpenModalToken(false);
      } else {
        setMostrarModalToken(null);
        setOpenModalToken(false);
      }
    }
  }, [resultadoClavePost]);

  return (
    <section className={`wraper-modal`}>
      <div className={`modal-content`}>
        {cambio_clave.loCambio && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={handlerClose}></i>
          </div>
        )}

        <section className="py-3">
          <div>
            {cambio_clave.loCambio ? (
              <>
                <Card>
                  <CardHeader className="custom-header-cambio-fondo custom-header-li">
                    Cambio de clave web Exitosa
                  </CardHeader>
                  <CardBody>
                    <p>
                      Has modificado exitosamente tu{" "}
                      <strong style={{ color: "#196691" }}>Clave Web</strong>,
                      la cual deberás utilizar la próxima vez que desees
                      ingresar a este portal.
                    </p>
                  </CardBody>
                </Card>
                <section className="d-flex justify-content-around">
                  <Button onClick={handlerClose}>Aceptar</Button>
                </section>
              </>
            ) : (
              <div>
                <Card>
                  <CardHeader className="custom-header-cambio-fondo custom-header-li">
                    Actualizar Clave Web
                  </CardHeader>
                  <CardBody>
                    <section>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label htmlFor="r_clave_web">
                              Clave Web Actual
                            </Label>
                            <InputGroup>
                              <Input
                                type={
                                  showPassword === "r_clave_web"
                                    ? "text"
                                    : "password"
                                }
                                placeholder="Clave actual"
                                name="r_clave_web"
                                value={claves.r_clave_web}
                                maxLength={4}
                                pattern="[0-9]*"
                                onPaste={handlerDisable}
                                onCopy={handlerDisable}
                                onChange={handlerPassword}
                              />
                              <InputGroupAddon addonType="append">
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="input-group-text"
                                  onMouseDown={handlerShowPassword(
                                    "r_clave_web"
                                  )}
                                  onMouseUp={handlerShowPassword("r_clave_web")}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </InputGroupAddon>
                            </InputGroup>

                            {errores.r_clave_web && (
                              <span
                                style={{ fontSize: "14px", color: "#bf0d3e" }}
                              >
                                {errores.r_clave_web}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label htmlFor="r_clave_nueva">
                              Nueva Clave Web
                            </Label>
                            <InputGroup>
                              <Input
                                type={
                                  showPassword === "r_clave_nueva"
                                    ? "text"
                                    : "password"
                                }
                                placeholder="Nueva clave"
                                name="r_clave_nueva"
                                value={claves.r_clave_nueva}
                                maxLength={4}
                                pattern="[0-9]*"
                                onPaste={handlerDisable}
                                onCopy={handlerDisable}
                                onChange={handlerPassword}
                              />
                              <InputGroupAddon addonType="append">
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="input-group-text"
                                  onMouseDown={handlerShowPassword(
                                    "r_clave_nueva"
                                  )}
                                  onMouseUp={handlerShowPassword(
                                    "r_clave_nueva"
                                  )}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </InputGroupAddon>
                            </InputGroup>

                            {errores.r_clave_nueva && (
                              <span
                                style={{ fontSize: "14px", color: "#bf0d3e" }}
                              >
                                {errores.r_clave_nueva}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label htmlFor="r_clave_confirmar">
                              Confirmar Nueva Clave
                            </Label>
                            <InputGroup>
                              <Input
                                type={
                                  showPassword === "r_clave_confirmar"
                                    ? "text"
                                    : "password"
                                }
                                placeholder="Confirmar clave"
                                name="r_clave_confirmar"
                                value={claves.r_clave_confirmar}
                                maxLength={4}
                                pattern="[0-9]*"
                                onPaste={handlerDisable}
                                onCopy={handlerDisable}
                                onChange={handlerPassword}
                              />
                              <InputGroupAddon addonType="append">
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="input-group-text"
                                  onMouseDown={handlerShowPassword(
                                    "r_clave_confirmar"
                                  )}
                                  onMouseUp={handlerShowPassword(
                                    "r_clave_confirmar"
                                  )}
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </InputGroupAddon>
                            </InputGroup>

                            {errores.r_clave_confirmar && (
                              <span
                                style={{ fontSize: "14px", color: "#bf0d3e" }}
                              >
                                {errores.r_clave_confirmar}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {resultadoClavePost && (
                        <Row>
                          {resultadoClavePost.r_respuesta === "0" && (
                            <Col>
                              <div className="alert alert-danger mb-0">
                                {resultadoClavePost.r_mensaje}
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}
                    </section>
                  </CardBody>
                </Card>
                <section className="d-flex justify-content-around">
                  <Button onClick={handleSubmit}>
                    Actualizar Clave web{" "}
                    {loadingBtnClavePost && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </section>
              </div>
            )}
          </div>
        </section>
      </div>
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }}
          guardarCode={handleUpdate}
        />
      )}
      {mostrarModalToken && (
        <ModalTokenError
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </section>
  );
};

export default Formulario;
