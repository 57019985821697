import {
    PAGOS_PENDIENTES_EMPRESAS_CLEANER,
    PAGOS_PENDIENTES_EMPRESAS_ERROR,
    PAGOS_PENDIENTES_EMPRESAS_EXITOSO,
    PAGOS_PENDIENTES_EMPRESAS_LOADIN,

    PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN,
    PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO,

    PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO,
    PAGOS_PENDIENTES_DATA_EXCEL_ERROR,
    PAGOS_PENDIENTES_DATA_EXCEL_LOADIN,
    PAGOS_PENDIENTES_DATA_EXCEL_CLEANER,
} from "../../types/pagosPendientes";

export default (state, action) => {
    switch (action.type) {
        /* Empresas */
        case PAGOS_PENDIENTES_EMPRESAS_EXITOSO:
            return {
                ...state,
                dataEmpresas: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_PENDIENTES_EMPRESAS_LOADIN:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_PENDIENTES_EMPRESAS_ERROR:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_PENDIENTES_EMPRESAS_CLEANER:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO:
            return {
                ...state,
                dataDeudaPeriodos: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO:
            return {
                ...state,
                allDataExcel: action.payload,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        case PAGOS_PENDIENTES_DATA_EXCEL_LOADIN:
            return {
                ...state,
                loadingAllDataExcel: true,
            };
        case PAGOS_PENDIENTES_DATA_EXCEL_ERROR:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: action.payload,
                loadingAllDataExcel: false,
            };
        case PAGOS_PENDIENTES_DATA_EXCEL_CLEANER:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        default:
            break;
    }
}