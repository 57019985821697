import React from "react";

import { useState } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};
/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
    backgroundColor: "#F6F6F6",
    // "& .MuiSelect-select": {
    //   paddingTop: "0px",
    //   height: "30px",
    // },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const SelectGeneral = ({ info, selectOption }) => {
  /* MUI CSS styles */
  const classes = useStyles();

  const [opcionSeleccionada, setOpcionSeleccioanda] = useState("");

  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);
      setOpcionSeleccioanda(JSON.stringify(item));
      selectOption(item);
    } else {
      setOpcionSeleccioanda(e.target.value);
      selectOption(e.target.value);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="Type-bank">{info.placeHolder}</InputLabel>
        <Select
          label={info.placeHolder}
          style={{ height: "55px" }}
          className={classes.selectStyles}
          name="tipoFondo"
          onChange={handleChange}
          value={opcionSeleccionada}
          disableUnderline
        >
          {info.opciones.map((item, index) => (
            <MenuItem
              sx={styles.menuItemStyles}
              key={index}
              value={JSON.stringify(item)}
            >
              {item.r_descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default SelectGeneral;
