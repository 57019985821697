import React, { useState } from "react";
import { Button, Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import sampleAporte from "../../../../../../../../../assets/ejemplo_aporte_requerido.png";
import MinusIcon from "../../../../../../../../../assets/minus.svg";
import EqualIcon from "../../../../../../../../../assets/equals.svg";
import CallIcon from "../../../../../../../../../assets/call.svg";
import MailIcon from "../../../../../../../../../assets/mail.svg";
import CastIcon from "../../../../../../../../../assets/cast.svg";
import "./index.css";

export default function Exitoso(props) {
  const [showModal, setShowModal] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);

  const shortStringWithPoints = (value) => {
    const maxLength = 23;
    if (value.length <= maxLength) return value;
    return `${value.substr(0, maxLength)}...`;
  };

  const renderModalContent = () => {
    if (activeIndex === 1)
      return (
        <>
          <p className="modal-pagination">1/2</p>
          <div className="resultado-modal-info">
            <p>
              <strong>
                Recuerda que en esta evaluación consideramos el 100% de tus
                aportes obligatorios y voluntarios.
              </strong>
            </p>
            <p>
              En la evaluación formal si tus aportes voluntarios con fin
              previsional o Fondo Libre Habitat fueron hechos hace 9 meses o
              más, serán considerados en su totalidad para el cálculo. En caso
              contrario, solo podrá ser considerado como máximo el equivalente
              al <strong>20% de tu Fondo Obligatorio.</strong>
            </p>
            <p>
              En el caso desees hacer el aporte requerido te presentamos un
              ejemplo práctico de cómo deberías calcular tu aporte.
            </p>
          </div>
          <div className="resultado-modal-footer mt-4">
            <Button
              className=" btn-modal-primary"
              onClick={() => setActiveIndex(2)}
            >
              Siguiente
            </Button>
          </div>
        </>
      );
    else
      return (
        <>
          <p className="modal-pagination">2/2</p>
          <div className="resultado-modal-info">
            <p className="text-left mb-0">
              <strong>Ejemplo:</strong>
            </p>
            <div>
              <img src={sampleAporte} alt="ejemplo-aporte-requerido" />
            </div>
            <p>
              Si el día de hoy realizas el aporte de{" "}
              <strong style={{ color: "#bf0d3e" }}>500 soles</strong> para
              alcanzar el fondo requerido y decidas solicitar esa jubilación,
              sólo se considerán hasta{" "}
              <strong style={{ color: "#bf0d3e" }}>
                S/ 200 (20% de tu Fondo Actual)
              </strong>
              , sólo se tendrá en cuenta el total del aporte hasta{" "}
              <strong style={{ color: "#bf0d3e" }}>9 meses posteriores</strong>{" "}
              a la fecha del aporte.
            </p>
          </div>
          <div className="resultado-modal-footer mt-4">
            <Button
              className=" btn-modal-primary mb-3"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </Button>

            <Button
              className=" btn-modal-secondary"
              onClick={() => setActiveIndex(1)}
            >
              Volver
            </Button>
          </div>
        </>
      );
  };

  return (
    <>
      <h1>Resultado Evaluación</h1>
      <p>
        Por el momento no cuentas con el Fondo Requerido necesario para obtener
        una pensión que equivalga al 40% de tu sueldo promedio durante los
        últimos 10 años. Enviaremos a tu correo registrado más detalles de tu
        pre-evaluación.
      </p>

      <div id="fondos-aporte" className="center-items mt-4 mb-3 column">
        <div className="content-aporte-requerido">
          <div className="whitecard-block fondo mb-3 mt-3">
            <h4>Fondo Requerido:</h4>
            <span className="amount green-light">
              {props.jaoResultadoData.data.funds.required.format}
            </span>

            {/* <span
                            id="tooltip-fondo"
                            className="tooltip-custom"
                            style={{ color: "#196691" }}
                        >
                            <i className="fa fa-info-circle" id="TooltipExample"></i>
                            <span className="tooltiptext">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit
                            </span>
                        </span> */}

            <div className="tooltip-funds">
              <i className="fa fa-info-circle" id="required-fund"></i>
              <UncontrolledTooltip
                placement="left"
                target="required-fund"
                className="tooltip-reactstrap"
              >
                Fondo requerido para obtener la pensión mínima de acceso
                equivalente al 40% del promedio de tus remuneraciones.
              </UncontrolledTooltip>
            </div>
          </div>

          <img className="math-icon" src={MinusIcon} alt="icono_menos" />

          <div className="whitecard-block fondo mb-3 mt-3">
            <h4>Fondo Acumulado:</h4>
            <span className="amount green-light">
              {props.jaoResultadoData.data.funds.accumulated.format}
            </span>

            {/* <span
                            id="tooltip-fondo"
                            className="tooltip-custom"
                            style={{ color: "#196691" }}
                        >
                            <i className="fa fa-info-circle" id="TooltipExample"></i>
                            <span className="tooltiptext">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit
                            </span>
                        </span> */}

            <div className="tooltip-funds">
              <i className="fa fa-info-circle" id="acumulate-fund"></i>
              <UncontrolledTooltip
                placement="left"
                target="acumulate-fund"
                className="tooltip-reactstrap"
              >
                Este es el saldo de tu cuenta individual de capitalización.
              </UncontrolledTooltip>
            </div>
          </div>
          <img
            id="equal"
            className="math-icon"
            src={EqualIcon}
            alt="icono_igual"
          />

          <div className="aporte-width bluecard-block aporte">
            <h4>Aporte Requerido</h4>
            <span>{props.jaoResultadoData.data.funds.necessary.format}</span>
          </div>
        </div>

        <p className="advice">
          <strong>Recuerda que</strong> en esta pre-evaluación se está
          considerando el 100% de tus aportes voluntarios, en la evaluación
          formal si tus aportes voluntarios con fin previsional o Fondo Libre
          Habitat fueron hechos hace 9 meses o más, serán considerados en su
          totalidad para el cálculo. En caso contrario, solo podrá ser
          considerado como máximo el equivalente al 20% de tu Fondo Obligatorio.
        </p>
      </div>

      {!props.jaoResultadoData.data.executive && (
        <p className="text-left">
          <strong>PASOS A SEGUIR:</strong> Si estás interesado en realizar el
          Aporte Requerido deberás comunicarte con nuestros{" "}
          <a
            href="https://www.afphabitat.com.pe/canales-de-atencion/"
            className="link-canales"
            target="__blank"
          >
            <strong>canales de atención</strong>
          </a>{" "}
          para solicitar una cita de Asesoría.
        </p>
      )}

      {props.jaoResultadoData.data.executive && (
        <>
          <p className="text-left">
            En caso desees proceder con el aporte, puedes comunicarte con tu
            ejecutivo(a) de cuenta para continuar con tu solicitud.
          </p>
          <div className="center-items">
            <div className="whitecard-block contact-content">
              <div className="row-info">
                <img src={CastIcon} alt="call_icon" />{" "}
                <p>{props.jaoResultadoData.data.executive.name}</p>
              </div>
              <div className="row-info">
                <img src={CallIcon} alt="call_icon" />{" "}
                <p>{props.jaoResultadoData.data.executive.cell_phone}</p>
              </div>
              <div className="row-info">
                <img src={MailIcon} alt="call_icon" />{" "}
                <p>
                  {shortStringWithPoints(
                    props.jaoResultadoData.data.executive.email
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container-jao-buttons mt-5">
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => props.getOutOfProcess()}
        >
          Finalizar
        </Button>
      </div>

      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        className="pre-evaluacion-resultado-modal"
        centered
        backdrop="static"
      >
        <ModalBody>
          <div className="resultado-modal-header">
            <h3>Aviso importante</h3>
          </div>
          {renderModalContent()}
        </ModalBody>
      </Modal>
    </>
  );
}
