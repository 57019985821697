import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
/* Iconos */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
/* Logos Bancarios */
import BCP_LOGO_ICON from "../../assets/IconBucket/BCP_LOGO_ICON.svg";
import BBVA_LOGO_ICON from "../../assets/icons/bbva-100px.svg";
import INT_LOGO_ICON from "../../assets/icons/interbank-100px.svg";
import SCOTI_LOGO_ICON from "../../assets/icons/scotiabanck-100px.svg";
/* Paleta de colores usados en este componente */
export const palletteColors = {
  Grey: "#DCDCDC",
  Blue: "#569BBE",
};

/* Estilos CSS cons MUI */
const useStyles = makeStyles({
  gridPrincipalContainerStyle: {
    fontSize: "10px",
    minHeight: "154px",
    userSelect: "none",
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    // gap: "1.5em",
  },
  gridPrincipalContainerStyleChanges: {
    fontSize: "10px",
    minHeight: "20em",
    userSelect: "none",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "1.5em",
  },

  cardStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    border: `solid 1px ${palletteColors.Grey}`,
    borderStyle: "dashed",
    borderRadius: "16px",
    boxShadow: "none",
    cursor: "pointer",
    margin: "4px",
  },
  cardStylesChanges: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    border: `solid 1px ${palletteColors.Blue}`,
    borderRadius: "16px",
    boxShadow: "none",
    cursor: "pointer",
    margin: "4px",
    padding: "16px",
  },
  gridIconStyles: {
    pt: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridIconStylesChanges: {
    pt: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palletteColors.Blue,
  },
  gridContentStyle: {
    pt: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridContentStyleChanges: {
    pt: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palletteColors.Blue,
  },

  boxCardStyle: {
    width: "100%",
    minHeight: "18em",
    border: "none",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
  },

  /* Estilos de la card para escoger cuenta ya agregada */
  chooseBankAccountStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `solid 1px ${palletteColors.Grey}`,
    borderRadius: "16px",
    cursor: "pointer",
    margin: "4px",
    padding: "24px",
    position: "relative",
  },
  chooseBankAccountStyleChanges: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `solid 1px ${palletteColors.Blue}`,
    borderRadius: "16px",
    padding: "24px",
    cursor: "pointer",
    margin: "4px",
    position: "relative",
  },
});

const Cards = ({ cuentas, setCuenta, preview, setShowEvidence }) => {
  const [cuentaDestino, setCuentsDestino] = useState(-1);

  /* MUI CSS styles */
  const classes = useStyles();

  /* Breakpoint de material ui */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  /* Función para la selección del card */
  const selectCard = (option) => (e) => {
    e.preventDefault();
    if (option === "nueva") {
      if (preview !== undefined) {
        preview();
      }
      if (setShowEvidence !== undefined) {
        setShowEvidence(true);
      }
      setCuentsDestino(option);
    } else {
      setCuentsDestino(option.r_codigo);
    }
    setCuenta(option);
  };

  /*Función: Para el cambio de los logos de acuerdo al caso */
  const nameOfBank = (name) => {
    switch (name) {
      case "INTERBANK":
        return INT_LOGO_ICON;
      case "BCP":
        return BCP_LOGO_ICON;
      case "SCOTIABANK":
        return SCOTI_LOGO_ICON;
      case "BBVA":
        return BBVA_LOGO_ICON;
      default:
        return BCP_LOGO_ICON;
    }
  };

  /* Función: Pasa a minúscula todo el string y luego a mayúscula el primer caracter de un string */
  const capitalize = (s) => {
    let name = s.toLowerCase().slice(7, 14);
    return name[0].toUpperCase() + name.slice(1);
  };
  return (
    <Grid
      container
      className={
        matches
          ? classes.gridPrincipalContainerStyle
          : classes.gridPrincipalContainerStyleChanges
      }
    >
      <Grid
        item
        xs={12}
        lg={3}
        className={
          cuentaDestino === "nueva"
            ? classes.cardStylesChanges
            : classes.cardStyles
        }
        onClick={selectCard("nueva")}
        sx={{
          height: "167px",
        }}
      >
        <Box
          className={
            cuentaDestino === "nueva"
              ? classes.gridIconStylesChanges
              : classes.gridIconStyles
          }
        >
          <AddCircleOutlineIcon />
        </Box>
        <Box
          className={
            cuentaDestino === "nueva"
              ? classes.gridContentStyleChanges
              : classes.gridContentStyle
          }
        >
          <Typography sx={{ textAlign: "center" }}>
            Agregar una nueva cuenta
          </Typography>
        </Box>
      </Grid>

      {cuentas &&
        cuentas.map((item, index) => (
          <Grid
            item
            xs={12}
            lg={3}
            key={index}
            className={
              cuentaDestino === item.r_codigo
                ? classes.chooseBankAccountStyleChanges
                : classes.chooseBankAccountStyle
            }
            onClick={selectCard(item)}
          >
            <Box>
              <Box>
                <Box
                  component="img"
                  src={nameOfBank(item.r_nombre_banco)}
                  alt={`Logo del banco ${item.r_nombre_banco}`}
                  sx={{ padding: "8px 0" }}
                />
              </Box>
              <Box sx={{ padding: "8px 0" }}>
                <Typography sx={{ fontSize: matchesMD ? "1.5em" : "" }}>
                  Cuenta: {capitalize(item.r_tipo_cuenta)} en Soles
                </Typography>
                <Typography sx={{ fontSize: matchesMD ? "1.4em" : "" }}>
                  N.°: {item.r_numero_cuenta.substring(0, 4)}
                  {item.r_numero_cuenta.substring(4, 8)}
                  {item.r_numero_cuenta.substring(8, 11)}
                  {item.r_numero_cuenta.substring(
                    11,
                    item.r_numero_cuenta.length
                  )}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ position: "absolute", top: "24px", right: "24px" }}>
              <Checkbox
                icon={<CheckCircleIcon />}
                checkedIcon={<CheckCircleIcon />}
                style={{
                  padding: 0,
                  color:
                    cuentaDestino === item.r_codigo
                      ? palletteColors.Blue
                      : palletteColors.Grey,
                }}
              />
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default Cards;
