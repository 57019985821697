import React from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import { Button } from "../../../components";
const SolicitudProcesadaUsuario = ({ info, recargar, loading }) => {
  return (
    <div className="card">
      <div className="custom-header-cambio-fondo custom-header-li card-header">
        Solicitud de actualización de dirección.
      </div>
      <div className="card-body">
        <div>
          {info.r_respuesta === "1" && (
            <p>Los datos de direcciones fueron actualizados correctamente</p>
          )}

          {info.r_respuesta === "0" && (
            <p>
              Hubo un error al procesar su solicitud, por favor vuelvalo a
              intentar mas tarde
            </p>
          )}
        </div>
        <div>
          <div className="text-center">
            <Button
              className="btn-red btn-red-custom-ec"
              onClick={() => recargar()}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}{" "}
              Volver
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hocWidthWindow(SolicitudProcesadaUsuario);
