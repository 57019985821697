import React, { useState, useEffect } from "react";
import { SelectComponent } from "./styles";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import getDeepValue from "../../../utils/getDeepValue";

const SelectInput = ({
  options = [],
  placeholder,
  name,
  mode,
  error,
  required,
  validate,
  disabled,
  ...props
}) => {
  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext();
  register(name, { required, ...validate });
  const isError = error || getDeepValue(errors, name);
  const onChangeHandler = (items) => {
    setValue(name, items.value);
  };

  const currentValue = options.find((e) => e.value === watch(name));

  return (
    <SelectComponent
      placeholder={placeholder}
      options={options}
      classNamePrefix="select"
      noOptionsMessage={() => "Sin opciones aún"}
      value={[currentValue]}
      isSearchable={false}
      isDisabled={disabled}
      error={isError}
      // menuIsOpen={true}
      onChange={onChangeHandler}
      {...props}
    />
  );
};

export default SelectInput;

SelectInput.propTypes = {
  placeholder: PropTypes.string,
  mode: PropTypes.oneOf(["single", "multi"]),
  name: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
};

SelectInput.defaultProps = {
  placeholder: "Selecciona uno",
  mode: "single",
  name: "select-input",
  required: { value: true, message: "Este campo es requerido." },
};
