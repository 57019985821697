import React from "react";

export default function SavingIcon({ size = 24, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      width={size}
      height={size}
      fill="none"
      stroke={color}
    >
      <path
        d="M19 11.6725H5C3.89543 11.6725 3 12.5679 3 13.6725V20.6725C3 21.7771 3.89543 22.6725 5 22.6725H19C20.1046 22.6725 21 21.7771 21 20.6725V13.6725C21 12.5679 20.1046 11.6725 19 11.6725Z"
        stroke="#8C9195"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 11.6725V7.67249C7 6.3464 7.52678 5.07463 8.46447 4.13695C9.40215 3.19927 10.6739 2.67249 12 2.67249C13.3261 2.67249 14.5979 3.19927 15.5355 4.13695C16.4732 5.07463 17 6.3464 17 7.67249V11.6725"
        stroke="#8C9195"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
