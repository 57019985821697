import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadButton extends React.Component {
  render() {
    const { content, button, month, year } = this.props;
    return (
      <ExcelFile element={button} filename={`valores-cuota-${month}-${year}`}>
        <ExcelSheet data={content.rows} name={`Valores Cuota ${month} ${year}`}>
          <ExcelColumn label="DÍA" value="day" />
          <ExcelColumn label="FONDO 0" value="fund0" />
          <ExcelColumn label="FONDO 1" value="fund1" />
          <ExcelColumn label="FONDO 2" value="fund2" />
          <ExcelColumn label="FONDO 3" value="fund3" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default DownloadButton;
