import React, { useReducer } from 'react';
import EcnoaportanteContext from './ecnoaportanteContext';
import EcnoaportanteReducer from './ecnoaportanteReducer';

import clienteAxios from '../../config/axios';

import {
  EC_NO_APORTANTE_EXITOSO,
  EC_NO_APORTANTE_ERROR,
  EC_NO_APORTANTE_LOADIN,
  EC_NO_APORTANTE_CLEANER,
  EC_NO_APORTANTE_CLEANER_PARCIAL,
} from '../../types';

const EcnoaportanteState = props => {
  const initialState = {
    resultado: null, // puede recibir true o false
    datos: null, // puede llegar "" o un {}
    deuda: null, // puede llegar "" o un []
    mensajeAlerta: null,
    loading: false
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log('entramos al default errors');
      }
    }
  };

  const obtenerDatosNoAportante = async () => {
    dispatch({
      type: EC_NO_APORTANTE_LOADIN,
      payload: true
    })
    try {
      const respuesta = await clienteAxios.get('/no-aportante');
      dispatch({
        type: EC_NO_APORTANTE_EXITOSO,
        payload: respuesta.data
      });
    } catch (error) {

      handleErrorsLocal(error, EC_NO_APORTANTE_ERROR);
    }
  }

  const limpiarVariablesEcnoaportante = () => {
    dispatch({
      type: EC_NO_APORTANTE_CLEANER
    })
  }

  const limpiarVariablesEcnoaportanteParcial = () => {
    dispatch({
      type: EC_NO_APORTANTE_CLEANER_PARCIAL
    })
  }



  const [state, dispatch] = useReducer(EcnoaportanteReducer, initialState);
  return (
    <EcnoaportanteContext.Provider
      value={{
        resultado: state.resultado,
        datos: state.datos,
        deuda: state.deuda,
        mensajeAlerta: state.mensajeAlerta,
        loading: state.loading,
        obtenerDatosNoAportante,
        limpiarVariablesEcnoaportante,
        limpiarVariablesEcnoaportanteParcial
      }}
    >
      {props.children}
    </EcnoaportanteContext.Provider>
  );
};

export default EcnoaportanteState;