import styled, { css } from "styled-components";

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  gap: 16px;
  width: min(640px, 100%);
  margin-top: 48px;

  ${(p) =>
    p.activeStep === 1 &&
    css`
      grid-template-columns: 1fr;
      width: min(320px, 100%);
    `}
`;
