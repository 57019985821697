import React from "react";

const Texto = ({ opcion }) => {
  return (
    <div style={{ paddingTop: "1em" }}>
      <p style={{ textAlign: "justify" }}>
        Cumpliendo ciertos{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.afphabitat.com.pe/todo-sobre-el-sistema/#retiro-de-hasta-el-25-de-tu-fondo-para-primera-vivienda"
        >
          requerimientos
        </a>
        , puedes retirar el 25% de tu fondo para pagar la cuota inicial o
        crédito hipotecario de tu primer inmueble, ya sea que esté a tu nombre,
        bajo sociedad de gananciales o seas copropietario del 50% o más.
      </p>
    </div>
  );
};

export default Texto;
