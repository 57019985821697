import React from "react";
import elaborarPresupuesto from "../../../../assets/img/elaborar-presupuesto.png";
import talentoDigital from "../../../../assets/img/talento-digital.png";
import rentabilidadAcumulada from "../../../../assets/img/rentabilidad-acumulada.png";
import cantidadAFP from "../../../../assets/img/cuanto-tengo-en-mi-afp.png";
import pertenezcoAFP from "../../../../assets/img/a-que-afp-pertenezco.png";
import claveWeb from "../../../../assets/img/clave-web.jpg";
import "./index.css";

const Section8 = () => {
  return (
    <section className="section-content bg-gray-light section8">
      <div className="container-new-home">
        <h2 className="left-title h2-new-home">Decide tu futuro hoy</h2>
        <p className="text-description">Noticias, actualidad y educación</p>

        <div className="mb-3 mt-3 blogs-container">
          <div className="blog-card">
            <div className="left-side">
              <h5
                onClick={() => {
                  window
                    .open(
                      "https://www.afphabitat.com.pe/aprende-de-prevision/afp/como-saber-cuanto-tengo-en-mi-afp/",
                      "_self"
                    )
                    .focus();
                }}
              >
                ¿Cómo saber cuánto tengo en mi AFP?
              </h5>
              <span>29/03/23</span>

              <p className="description-blog">
                Como aportante a un fondo de jubilación, de seguro te has
                preguntado en más de una ocasión: “¿Cuánto tengo en mi AFP?”. Te
                explicaremos cómo averiguarlo para que puedas hacer un
                seguimiento constante de tu dinero.
              </p>

              <button>AFP</button>
            </div>
            <div className="right-side">
              <img src={cantidadAFP} alt="chica-telefono" />
            </div>
          </div>

          <div className="blog-card">
            <div className="left-side">
              <h5
                onClick={() => {
                  window
                    .open(
                      "https://www.afphabitat.com.pe/aprende-de-prevision/afp/a-que-afp-pertenezco/",
                      "_self"
                    )
                    .focus();
                }}
              >
                ¿Cómo saber a qué AFP pertenezco? ¿Estoy afiliado a una AFP?
              </h5>
              <span>27/03/23</span>

              <p className="description-blog">
                “¿Cómo saber a qué AFP pertenezco?” y “¿cómo saber si pertenezco
                a una AFP?” son dudas muy frecuentes entre los trabajadores
                dependientes. Te contaremos qué hacer para saber en qué AFP
                estás afiliado(a) y más.
              </p>

              <button>AFP</button>
            </div>
            <div className="right-side">
              <img src={pertenezcoAFP} alt="chica-telefono" />
            </div>
          </div>

          <div className="blog-card">
            <div className="left-side">
              <h5
                onClick={() => {
                  window
                    .open(
                      "https://www.afphabitat.com.pe/aprende-de-prevision/afp/clave-web-en-afp-habitat/",
                      "_self"
                    )
                    .focus();
                }}
              >
                Clave web en AFP Habitat: ¿Cómo generarla y para qué sirve?
              </h5>
              <span>20/09/22</span>

              <p className="description-blog">
                En AFP Habitat puedes realizar diversas operaciones y trámites
                en línea, pero necesitas una contraseña o clave. Te contamos
                cómo obtener tu clave web en AFP Habitat y qué puedes hacer con
                ella.
              </p>

              <button>AFP</button>
            </div>
            <div className="right-side">
              <img src={claveWeb} alt="chica-telefono" />
            </div>
          </div>
        </div>

        <button
          className="block btn-primary-new-home margin-center"
          onClick={() => {
            window
              .open(
                "https://www.afphabitat.com.pe/aprende-de-prevision/",
                "_self"
              )
              .focus();
          }}
        >
          Ingresa al blog
        </button>
      </div>
    </section>
  );
};

export default Section8;
