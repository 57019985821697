import React, { useState, useEffect } from "react";
import { Progress } from "reactstrap";
import { CardContainer } from "../../../../components";
import "./index.css";

const FUNDS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export default function AFPInvestments(props) {
  const [fundSelected, setFundSelected] = useState(FUNDS.ONE);
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    let activeFund = props.grafico?.funds?.list?.find(
      (item) => item.active === true
    );
    const currentFund =
      props.grafico?.funds?.list?.length > 0 ? activeFund.id : "";
    setFundSelected(currentFund);
    let arrayPies = [];
    arrayPies = props.grafico?.funds?.list?.map((itemX) => {
      const temporal = [];
      props.grafico.investmentChart.forEach((itemY) => {
        if (itemY.fondo === itemX.id) {
          temporal.push(itemY);
        }
      });
      return {
        pie: temporal,
        foundId: itemX.id,
      };
    });
    setData(arrayPies);
  }, []);
  const onClickFund = (value) => {
    const aux = fundSelected;
    if (value !== aux) {
      setFundSelected(value);
    }
  };

  return (
    <CardContainer
      title={"¿En qué invirtió AFP Habitat?"}
      seeMore={false}
      color={"#ffffff"}
      product={false}
      showHeader={true}
      showContainer={"desktop"}
    >
      <div className="afp-investment">
        <div className="custom-tabs mb-4">
          {props.grafico?.funds?.list?.map((item, index) => {
            return (
              <div
                key={index}
                className={`tab ${fundSelected === item.id ? "selected" : ""}`}
                onClick={() => onClickFund(item.id)}
              >
                {item.name}
              </div>
            );
          })}
        </div>

        {data &&
          data.map((line, index) => {
            let pie;
            if (line.foundId === fundSelected) {
              pie = (
                <div key={index}>
                  <LinearBarData key={index} data={line.pie} />
                </div>
              );
            }
            return pie;
          })}
      </div>
    </CardContainer>
  );
}

function LinearBarData({ data }) {
  return (
    <div className="fund-data-render">
      <div className="linear-bar-container mt-3 mb-3">
        {data.map((item, index) => {
          return (
            <div key={index} className="linear-bar mb-2">
              <div className="info-linear-bar">
                <span>{item.name}</span>
                <span>{item.value + "%"}</span>
              </div>
              <Progress
                barClassName={"bg-process-" + index}
                value={item.value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
