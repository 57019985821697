import React from "react";
import { ZonaBeneficiariosLayout } from "./styles";
import Sidebar from "./Sidebar";
import { GastoDeSepelioProvider } from "./context";
import ModalDeErrorGeneral from "./ModalDeErrorGeneral";

const ZonaBeneficiarios = ({ children, ...props }) => {
  return (
    <GastoDeSepelioProvider>
      <ZonaBeneficiariosLayout {...props}>
        <ModalDeErrorGeneral />
        <Sidebar />
        {children}
      </ZonaBeneficiariosLayout>
    </GastoDeSepelioProvider>
  );
};

ZonaBeneficiarios.propTypes = {};

export default ZonaBeneficiarios;
