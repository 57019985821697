import React, { useState, useEffect } from "react";
import { Exitoso, Denegado, Incompleto } from "./components";
import Loading from "../../../../../../../components/Loading/index.jsx";
import "./index.css";

export default function PreEvaluacionResultado(props) {
  const { dataResult } = props;
  const [showContent, setShowContent] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(() => {
    if (dataResult) {
      if (dataResult.data.view === "successful") setShowContent(1);
      else if (dataResult.data.view === "evaluation_result") setShowContent(2);
      else if (dataResult.data.view === "required_contribution")
        setShowContent(3);
    }
  }, [dataResult]);

  if (dataResult === null) return <Loading />;

  const renderContent = () => {
    if (showContent === 1)
      return (
        <Exitoso
          jaoResultadoData={dataResult}
          getOutOfProcess={props.getOutOfProcess}
        />
      );
    else if (showContent === 2)
      return (
        <Denegado
          date={props.date}
          jaoResultadoData={dataResult}
          getOutOfProcess={props.getOutOfProcess}
        />
      );
    else if (showContent === 3)
      return (
        <Incompleto
          jaoResultadoData={dataResult}
          getOutOfProcess={props.getOutOfProcess}
        />
      );
    // else if (showContent === 4)
    //     return <Solicitud jaoResultadoData={jaoResultadoData} getOutOfProcess={props.getOutOfProcess} />
    else return null;
  };

  return <div className="pre-evaluacion-resultado">{renderContent()}</div>;
}
