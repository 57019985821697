import { InfoCard } from "./constants";

const formatFondo = (fondoSeleccionado) => {
  const fondo = InfoCard.find((e) => e.id == fondoSeleccionado);
  const fondoTexto = fondo && `${fondo.title} - ${fondo.subTitle}`;

  return fondoTexto;
};

export default formatFondo;
