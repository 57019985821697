import styled, { css } from "styled-components";

export const SummarySectionComponent = styled.div``;

export const TableComponent = styled.div`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
  box-shadow: ${(p) => p.theme.elevations.below};
  p {
    margin-bottom: 0;
  }
`;

export const TableTitleComponent = styled.div`
  ${(p) => p.theme.tipography.h6}
  color: ${(p) => p.theme.palette.grayscale["700"]};
  text-align: center;
`;

export const HeaderComponent = styled.div`
  display: grid;
  column-gap: 8px;
  grid-template-columns: auto auto;
  justify-content: start;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  p {
    ${(p) => p.theme.tipography.h5}
  }
`;

export const BodyComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 340px;
  gap: 16px;

  @media screen and (max-width: 743px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.div`
  ${(p) => p.theme.tipography.headingSubtitleSemi}
  margin-bottom: 4px;
  color: ${(p) => p.theme.palette.grayscale[500]};
`;
export const SectionComponent = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
export const InformationItem = styled.div`
  padding: 8px 16px;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  border-radius: 16px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .label {
    ${(p) => p.theme.tipography.caption};
    color: ${(p) => p.theme.palette.grayscale[500]};
  }
  .value {
    color: ${(p) => p.theme.palette.grayscale[800]};
  }
`;

export const ProgressComponent = styled.div`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
  box-shadow: ${(p) => p.theme.elevations.below};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "body"
    "footer";
`;

export const ProgressHeaderComponent = styled.div`
  grid-area: header;
  text-align: center;
  ${(p) => p.theme.tipography.h6}
  color: ${(p) => p.theme.palette.grayscale["700"]};
`;

export const ProgressBodyComponent = styled.div`
  grid-area: body;

  .MuiStepper-root {
    .MuiStepLabel-iconContainer {
    }

    .MuiStepLabel-label {
      ${(p) => p.theme.tipography.subtitleM}
      color: ${(p) => p.theme.palette.grayscale[800]};
      font-weight: 400 !important;
    }

    .MuiStepConnector-root {
      margin-left: 16px;
      .MuiStepConnector-line {
        min-height: 4px;
      }
    }

    .MuiCollapse-wrapperInner {
      ${(p) => p.theme.tipography.subtitleS}
      font-weight: 400 !important;
      color: ${(p) => p.theme.palette.grayscale[400]};
      padding-left: 24px;
    }

    .MuiStep-root {
      &[data-line="done"] {
        .MuiStepContent-root {
          border-color: ${(p) => p.theme.palette.state.success};
        }

        & + .MuiStepConnector-root {
          .MuiStepConnector-line {
            border-color: ${(p) => p.theme.palette.state.success};
          }
        }
      }

      &[data-line="waiting"] {
        .MuiStepContent-root {
          border-left-style: dashed;
          border-color: ${(p) => p.theme.palette.grayscale[200]};
        }

        & + .MuiStepConnector-root {
          .MuiStepConnector-line {
            border-left-style: dashed;
            border-color: ${(p) => p.theme.palette.grayscale[200]};
            margin-top: 1px;
          }
        }
      }

      &[data-line="rejected"] {
        .MuiStepContent-root {
          border-color: ${(p) => p.theme.palette.state.error};
        }

        & + .MuiStepConnector-root {
          .MuiStepConnector-line {
            border-color: ${(p) => p.theme.palette.state.error};
          }
        }
      }
    }

    .MuiStepContent-root {
      margin-left: 16px;
    }

    .MuiSvgIcon-root {
    }
  }
`;

export const StepIconComponent = styled.div`
  width: 32px;
  height: 32px;
  display: grid;
  justify-content: center;
  color: white;
  align-content: center;
  border-radius: 100%;
  position: relative;

  svg {
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px;
  }

  ${(p) =>
    p.status === "waiting"
      ? css`
          background-color: ${(p) => p.theme.palette.grayscale[200]};
        `
      : p.status === "done"
      ? css`
          background-color: ${(p) => p.theme.palette.state.success};
        `
      : p.status === "rejected"
      ? css`
          background-color: ${(p) => p.theme.palette.state.error};
        `
      : null}
`;

export const ProgressFooterComponent = styled.div`
  grid-area: footer;
  display: grid;
  justify-items: center;
`;
