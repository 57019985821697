import React from "react";

const TablaMobile = ({ data, tipo }) => {
  const fila = (objeto, titulo) => {
    let nuevoArreglo = [];
    Object.keys(objeto).forEach((element) => {
      nuevoArreglo.push(element);
    });

    return nuevoArreglo.map((item, index) => (
      <div className="body-t custom-cf-mobile">
        <span
          className="only-mobile"
          style={{
            display: titulo[index].name === "Tipo de saldo" ? "none" : "flex",
          }}
        >
          {titulo[index].name}:
        </span>
        <span
          className="text-center ml-2"
          style={{
            minWidth: "60px",
            fontSize:
              titulo[index].name === "Tipo de saldo" ? "16px" : "inherit",
            fontWeight: titulo[index].name === "Tipo de saldo" ? "600" : "400",
            color:
              titulo[index].name === "Tipo de saldo" ? "#196691" : "inherit",
          }}
        >
          {objeto[item] === "APORTE OBLIGATORIO"
            ? "Fondo de Jubilación"
            : objeto[item]}
        </span>
      </div>
    ));
  };

  const ultimaFila = (objeto, titulo) => {
    let nuevoArreglo = [];
    Object.keys(objeto).forEach((element) => {
      nuevoArreglo.push(element);
    });
    return nuevoArreglo.map((item, index) => {
      return (
        <div className="body-t custom-cf-mobile">
          <span
            className="only-mobile"
            style={{
              display: titulo[index].name === "Tipo de saldo" ? "none" : "flex",
            }}
          >
            {titulo[index].name}:
          </span>
          <span
            className="ml-2"
            style={{
              textAlign: "center",
              minWidth: "60px",
              fontSize:
                titulo[index].name === "Tipo de saldo" ? "16px" : "inherit",
              fontWeight:
                titulo[index].name === "Tipo de saldo" ? "600" : "400",
              color:
                titulo[index].name === "Tipo de saldo" ? "#196691" : "inherit",
            }}
          >
            {objeto[item] === "VOLUNTARIO CON FIN"
              ? "Fondo con Fin Previsional"
              : objeto[item]}
          </span>
        </div>
      );
    });
  };

  return (
    <section className="body-table-make-comision ">
      {data.body.map((item, index) => {
        if (index < data.body.length - 1) {
          // return <></>;
          return (
            <section className="liena-head body-table-item mb-2">
              {fila(item, data.header)}
            </section>
          );
        } else {
          return (
            <section className="liena-head body-table-item">
              {ultimaFila(item, data.header)}
            </section>
          );
        }
      })}
    </section>
  );
};

export default TablaMobile;
