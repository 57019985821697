import styled, { css } from "styled-components";

export const CancelarAporteVoluntarioSinFinPage = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  padding: 24px, 16px, 24px, 16px;
  background-color: ${(p) => p.theme.palette.white};
  padding: 24px;
  border-radius: 16px;
  box-shadow: ${(p) => p.theme.elevations.below};
  p {
    margin-bottom: 0px;
  }
`;
