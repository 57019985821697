import React from "react";
import { NextButtonComponent } from "./styles";

const NextButton = ({ children, ...props }) => {
  return (
    <NextButtonComponent fullWidth size="lg" {...props}>
      {children}
    </NextButtonComponent>
  );
};

export default NextButton;
