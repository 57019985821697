import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import { AsesoriaInicio, AsesoriaTemas } from "./components";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso1(props) {
  const [showInitView, setShowInitView] = useState(0);
  const [contens, setContens] = useState([]);
  const [dependencies, setDependencies] = useState({});
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    limpiarObtenerContenidoTemaJubilacion,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    props.setCurrectStepTitle("Asesoria");
    obtenerContenidoTemaJubilacion(props.processId, 1);
    return () => {
      limpiarObtenerContenidoTemaJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        setShowInitView(1);
        setContens(contenidoTemaJubilacionData.contents);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  const renderView = () => {
    switch (showInitView) {
      case 1:
        return <AsesoriaInicio gotToTopics={() => setShowInitView(2)} />;
      case 2:
        return (
          <AsesoriaTemas
            processId={props.processId}
            contens={contens}
            dependencies={dependencies}
            endFirstStep={() => props.setCurrectStepNumber(2)}
          />
        );
      default:
        return <Loading />;
    }
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (contenidoTemaJubilacionLoading) return <Loading />;

  return (
    <div>
      {renderView()}
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
