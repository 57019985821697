import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { Beneficiarios, SaldoCuenta, Aviso, Resultado } from "./components";
import JubilacionContext from "../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../components/Loading/index.jsx";
import VistaEspera from "../../jubilacionReja/etapas/components/vistaEspera/VistaEspera";
import imgLogo from "../../../../../assets/solicitud.svg";
import moment from "moment";
import AuthContext from "../../../../../context/autenticacion/authContext";

import "./index.css";

export default function WorkflowJao(props) {
  const [step, setStep] = useState(-1);
  const [beneficiarieRegister, setBeneficiarieRegister] = useState({
    haveBeneficiaries: null,
    beneficiaries: [],
  });
  const [saldosRegister, setsaldosRegister] = useState({
    considerFreeFund: null,
    haveAprilRemuneration: false,
    amountAprilRemuneration: "",
  });
  const [dataResult, setDataResult] = useState(null);
  const authContext = useContext(AuthContext);
  const { birthday } = authContext;
  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");

  const loadNPS = () => {
    const filterAge =
      age < 25
        ? "Menores de 25"
        : age >= 25 && age < 35
        ? "25 A 34"
        : age >= 35 && age < 51
        ? "35 a 51"
        : age >= 51 && age < 65
        ? "51 a 65"
        : "Mayores de 65";

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: "Trámite de JAO",
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", { detail: { id: 1303 } })
    );
  };
  const jubilacionContext = useContext(JubilacionContext);
  const {
    relationshipsData,
    relationshipsLoading,
    obtenerParentescosRejaData,
    limpiarObtenerParentescosRejaData,

    jaoProcessData,
    jaoProcessLoading,
    obtenerProcesoJaoData,
    limpiarObtenerProcesoJaoData,

    jaoAccessData,
    limpiarObtenerAccesoJaoData,

    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    if (jaoAccessData === null)
      props.history.push("/mi-habitat-digital/afiliado/jubilacion");
    else if (!jaoAccessData.success)
      props.history.push("/mi-habitat-digital/afiliado/jubilacion");

    obtenerParentescosRejaData();
    obtenerProcesoJaoData();
    return () => {
      limpiarObtenerParentescosRejaData();
      limpiarObtenerProcesoJaoData();
      limpiarObtenerAccesoJaoData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (step === 4) {
      loadNPS();
    }
  }, [step]);

  useEffect(() => {
    if (jaoProcessData) {
      if (jaoProcessData.data.pay_history) setStep(1);
      else {
        setStep(5);
      }
    }
  }, [jaoProcessData]);

  const getOutOfProcess = () => {
    props.history.push("/mi-habitat-digital/afiliado/jubilacion");
  };

  const renderStepScene = () => {
    switch (step) {
      case 1:
        return (
          <Beneficiarios
            userBirthdate={jaoProcessData.data.birthday}
            jaoProcessData={jaoProcessData}
            relationshipsData={relationshipsData}
            beneficiarieRegister={beneficiarieRegister}
            setBeneficiarieRegister={setBeneficiarieRegister}
            setStep={setStep}
            getOutOfProcess={getOutOfProcess}
          />
        );
      case 2:
        return (
          <SaldoCuenta
            jaoProcessData={jaoProcessData}
            saldosRegister={saldosRegister}
            setsaldosRegister={setsaldosRegister}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <Aviso
            setDataResult={setDataResult}
            beneficiarieRegister={beneficiarieRegister}
            saldosRegister={saldosRegister}
            setStep={setStep}
          />
        );
      case 4:
        return (
          <Resultado
            dataResult={dataResult}
            date={jaoProcessData.data.date}
            getOutOfProcess={getOutOfProcess}
          />
        );
      case 5:
        return (
          <VistaEspera
            imgLogo={imgLogo}
            title="Solicitud generada"
            description={
              <>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,
                ahora que tenemos la información necesaria para estimar tu
                pensión, validaremos tus datos para seguir con el trámite
                <br />
                <br />
                Te estaremos informando vía email los resultados de tu
                pre-avaluación en un{" "}
                <strong>plazo máximo de 7 días hábiles</strong>.
                <br />
                <br />
                Por favor, recuerda mantener tus datos de contacto{" "}
                <strong>actualizados</strong> y revisar tu bandeja de{" "}
                <strong>spam</strong> o correos no deseados
              </>
            }
            btnLabel="VOLVER A PANTALLA PRINCIPAL"
            //btnFunction={() => props.getOutOfProcess()}
            btnFunction={() => getOutOfProcess()}
          />
        );
      default:
        return null;
    }
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (relationshipsLoading || jaoProcessLoading) return <Loading />;

  return (
    <Row className="container-jao">
      <Col xl="8" lg="8" md="8">
        {renderStepScene()}
      </Col>
    </Row>
  );
}
