import styled from "styled-components";
import Modal from "../../../../components/BasicModal";

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
    color: ${(p) => p.theme.palette.state.info};
  }
`;

export const Title = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  ${(p) => p.theme.tipography.h5}
  margin: 16px auto;
  width: fit-content;
  text-align: center;
`;

export const ModalSubTitle = styled(Modal.Subtitle)`
  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;
