import styled, { css } from "styled-components";
import { device } from "../../../../../../utils/device";

export const Container = styled.div`
  grid-area: main;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "body";

  ${(p) => css`
    @media screen and ${device.desktopS} {
      padding-bottom: 24px;
    }
  `}
`;

export const Main = styled.div`
  grid-area: body;
  display: grid;
  grid-template-rows: auto auto 1fr;
  min-height: 100%;
  padding: 16px 8px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.white};

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }

  ${css`
    @media screen and ${device.desktopS} {
      padding: 48px 24px;
    }

    @media screen and ${device.desktopL} {
      padding: 48px 32px;
    }
  `}
`;
