import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import AuthContex from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import AsesorContext from "../../context/asesor/asesorContext";
import CerrarSesion from "../../components/CerrarSesion";
import Modal from "../../components/Modal/index.jsx";
import NavBar from "../../components/MainNavBar";
import ModalUpdateClave from "../../components/ModalUpdateClave/index.jsx";
import ModalBannerCampania from "../../components/ModalBannerCampania";
import MainDrawer from "../../components/MainDrawer";
import BottomNavegationBar from "../../components/BottomNavegationBar";
import Asesor from "./Asesor";
import AporteapvsinfinContext from "../../context/aporteapvsinfin/aportarapvsinfinContext";
import ComisionHistoricaContext from "../../context/comisionhistorica/comisionhistoricaContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";

import ModalInvertir from "./ModalInvertir";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ContacWidget from "./contacWidget";
// import "./style.css";
// import Sidebar from "./Sidebar";
// import NavBar from "./NavBar";
// import ModalAPV from "../../components/ModalAPV/index.jsx";
// import logo from "../../assets/logo_white.png";
const screenWidth = window.innerWidth;
const Root = styled.div`
  display: flex;
  min-height: 100%;
  overflow: hidden;
`;

const Content = styled.main`
  flex-grow: 1;
  margin-top: ${(props) => props.theme.spacing(7)};

  background: ${screenWidth <= 900 ? "#fff" : "#f8f9fb"}
  margin-bottom: 50px;
  min-height: 100%;
  @media (min-width: 990px) {
    margin-top: ${(props) => props.theme.spacing(14)};

    margin-bottom: 0px;
    overflow-x: hidden;
  }
  @media (max-width: 576px) {
    background: #ffffff;
  }
`;
const RouteLaout = ({ component: Component, ...props }) => {
  const location = useLocation();
  const urlView = location.pathname.split("/");
  let urlPath = urlView[3];

  const authContext = useContext(AuthContex);
  const {
    autenticado,
    cargando,
    loading,
    usuarioAutenticado,
    cambio_clave,
    banner,
    valido_datos,
    actualizarTimer,
  } = authContext;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const modalContext = useContext(ModalContext);
  const asesorContext = useContext(AsesorContext);
  const { encontroAsesor, clickAsesor, obtenerAsesor } = asesorContext;
  const { status, message } = modalContext;
  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const {
    datosAportarApvSinFin,
    mensajeInformativo,
    obtenerDatosParaAportarApvSinFin,
    limpiarVariablesAportarApvSinFin,
  } = aporteapvsinfinContext;
  const comisionHistoricaContext = useContext(ComisionHistoricaContext);
  const {
    datosComisionHistorica,
    obtenerComisionHistorica,
    limpiarVariablesComisionHistoricaParcial,
  } = comisionHistoricaContext;
  const misDatosContext = useContext(MisDatosContext);

  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;

  const [openDrawe, setOpenDrawe] = useState(false);
  const [openModalInvertir, setOpenModalInvertir] = useState(false);

  const [stylebackdrop, setStyleBackdrop] = useState({
    // display: "none",
  });
  const [classFooter, setClassFooter] = useState("align-items-center");
  useEffect(() => {
    if (urlPath === "inicio") {
      obtenerMisDatosGet();
    }
  }, []);

  useEffect(() => {
    if (autenticado) {
      obtenerAsesor();
      obtenerDatosParaAportarApvSinFin();
      obtenerComisionHistorica();
    }
  }, [autenticado]);
  useEffect(() => {
    if (encontroAsesor) {
      setClassFooter("align-items-start");
    }
    // alert(encontroAsesor);
  }, [encontroAsesor]);
  useEffect(() => {
    if (clickAsesor) {
      setStyleBackdrop({
        display: "block",
      });
    } else {
      setStyleBackdrop({
        display: "none",
      });
    }
  }, [clickAsesor]);

  useEffect(() => {
    window.scrollTo(0, 0);
    usuarioAutenticado("");
    // eslint-disable-next-line
  }, []);

  const updateTimer = (e) => {
    // si damos click en cerrar sesión no debe de ejecutar el actualizar el timer
    if (e.target.className.search("ref-close")) {
      actualizarTimer();
    }
  };
  // let sideDrawer;
  let backdrop;
  const backDropClickHandler = () => {
    setOpenDrawe(false);
    // const updateTimer = (e) => {
    //   // si damos click en cerrar sesiÃ³n no debe de ejecutar el actualizar el timer
    //   if(e.target.className.search('ref-close')) {
    //     actualizarTimer();
    //   }
  };

  const styleMain = {
    overflow: urlPath === "tipo-jubilacion" && "auto",
    marginBottom: urlPath === "tipo-jubilacion" ? "30px" : "60px",
  };
  if (openDrawe) {
    // sideDrawer = <SideDrawer />;
    backdrop = (
      <div
        className="m-backdrop"
        // style={stylebackdrop}
        onClick={() => backDropClickHandler()}
      ></div>
    );
  }

  return (
    <Route
      {...props}
      render={(props) =>
        !autenticado && !cargando ? (
          <Redirect to="/" />
        ) : loading ? (
          <CerrarSesion />
        ) : (
          <Root>
            {encontroAsesor &&
            location.pathname === "/mi-habitat-digital/afiliado/inicio" ? (
              <Asesor></Asesor>
            ) : null}

            {!encontroAsesor && <ContacWidget />}

            <NavBar setOpenDrawe={setOpenDrawe} {...props} />
            <Box
              sx={{
                zIndex: matches && 2050,
              }}
            >
              <MainDrawer
                isOpen={openDrawe}
                setIsOpen={setOpenDrawe}
                {...props}
              />
            </Box>

            <Content>
              <Component {...props} />
            </Content>

            {location.pathname === "/mi-habitat-digital/afiliado/inicio" && (
              <BottomNavegationBar
                {...props}
                mensajeInformativo={mensajeInformativo}
                setOpenModalInvertir={setOpenModalInvertir}
              />
            )}

            {banner === "1" ? (
              <ModalBannerCampania />
            ) : cambio_clave !== null ? (
              <ModalUpdateClave
                type={"clave"}
                info={cambio_clave}
                history={props.history}
                datos={misDatosGet?.r_persona}
              />
            ) : valido_datos !== null ? (
              <ModalUpdateClave
                type={"datos"}
                info={valido_datos}
                history={props.history}
                datos={misDatosGet?.r_persona}
              />
            ) : (
              ""
            )}

            {status && <Modal mensajeAlerta={message} />}
            <ModalInvertir
              open={openModalInvertir}
              onClose={() => setOpenModalInvertir(!openModalInvertir)}
              datos={datosComisionHistorica}
            />
          </Root>
        )
      }
    />
  );
};

export default RouteLaout;
