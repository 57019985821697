import {
    SOLICITUD_POST_ERROR, POST_SOLICITUD_EXITOSO,POST_LOADING_SOLICITUD,CLEAN_GUARDAR_SOLICITUD
} from "../../types/solicitud";

export default (state, action) => {
    switch (action.type) {
        case POST_LOADING_SOLICITUD:
            return {
                ...state,
                loadingBtn: true,
            };
        case POST_SOLICITUD_EXITOSO:
            return {
                ...state,
                guardo: true,
                loadingBtn:false
            };
        case SOLICITUD_POST_ERROR:
            return {
                ...state,
                error_solicitud: action.payload,
            };
        case CLEAN_GUARDAR_SOLICITUD:
            return {
                ...state,
                guardo: false,
            };
        default:
            break;
    }
};
