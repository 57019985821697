import React from "react";
import { Item, Content, Header } from "../../components/Accordion";
import { ContentWrapper } from "./styles";

const Caracteristicas = () => {
  return (
    <Item value="quienes-acceden">
      <Header>¿Quiénes acceden?</Header>
      <Content>
        <ContentWrapper>
          <p className="description">
            Tendrán derecho a este beneficio aquellos trabajadores que se
            encuentren en alguno de los siguientes casos:
          </p>
          <ul>
            <li>Afiliados en condición de trabajadores activos.</li>
            <li>Afiliados en condición de pensionistas por invalidez.</li>
            <li>Afiliados en condición de pensionistas por jubilación.</li>
          </ul>
        </ContentWrapper>
      </Content>
    </Item>
  );
};

export default Caracteristicas;
