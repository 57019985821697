import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { montoFormat } from "../../../../../helper/amountFormat";
import Alcancia from "../../../../../assets/icons/Alcancia-rent.svg";
import Retire from "../../../../../assets/icons/retire.svg";
import Bullet from "../../../../../assets/icons/bullet.svg";
import "./index.css";
import { CardContainerDesktop } from "../../../../../components";

import { BoxBody } from "./styles";

const Coin = {
  AMOUNT: 1,
  QUOTA: 2,
};

export default function MyProducts({
  detalleProductoSPP,
  detalleHabitatSPP,
  acumulated_mount,
  product,
}) {
  const [companySelected, setCompanySelected] = useState(Coin.AMOUNT);
  const onClickCompany = (fund) => {
    setCompanySelected(fund);
  };
  const aportAcumH =
    Number(detalleHabitatSPP[0].aportes) === 0
      ? Number(detalleHabitatSPP[0].aportes)
      : Number(detalleHabitatSPP[0].aportes.replace(",", ""));
  const cargosH =
    Number(detalleHabitatSPP[0].retiros) === 0
      ? Number(detalleHabitatSPP[0].retiros)
      : Number(detalleHabitatSPP[0].retiros.replace("-", ""));
  const newRent =
    +(Number(acumulated_mount) - 0 - Number(aportAcumH)) + Math.abs(cargosH);

  const period =
    detalleProductoSPP !== ""
      ? detalleProductoSPP.periodo.split("-")
      : detalleProductoSPP;
  return (
    <>
      {/* {product === "OBL"
        ? null
        : */}
      <CardContainerDesktop
        title={"¿En qué invirtió AFP Habitat?"}
        seeMore={false}
        color={"#ffffff"}
        product={false}
        showHeader={false}
      >
        <BoxBody className="home-box-body">
          {/* {product !== "OBL" ? null : (  */}
          <div className="custom-tabs mb-2">
            <div
              className={`tab ${
                companySelected === Coin.AMOUNT ? "selected" : ""
              }`}
              onClick={() => onClickCompany(Coin.AMOUNT)}
            >
              AFP Habitat
            </div>

            {detalleProductoSPP !== "" && (
              <div
                className={`tab ${
                  companySelected === Coin.QUOTA ? "selected" : ""
                }`}
                onClick={() => onClickCompany(Coin.QUOTA)}
              >
                SPP
              </div>
            )}
          </div>
          {/* )} */}

          <div className="ml-1 mr-1 pt-2">
            <div className="content-rentabilidad-desktop">
              <h3>Tu fondo se encuentra compuesto por lo siguiente:</h3>

              <div className="list-desktop-rentabilidad">
                <Grid container>
                  <Grid items lg={12}>
                    <div className="list-detail-rent-desktop">
                      <Grid container>
                        <Grid
                          items
                          lg={5}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <div className="box-image">
                            <img src={Alcancia} alt="" />
                          </div>

                          <p>Aportes acumulados</p>
                        </Grid>
                        <Grid
                          items
                          lg={7}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span>
                            {companySelected === 1
                              ? "+ S/ " + montoFormat(aportAcumH)
                              : "+ S/ " +
                                montoFormat(
                                  detalleProductoSPP.aportes_acum_spp
                                )}
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid items lg={12}>
                    <div className="list-detail-rent-desktop">
                      <Grid container>
                        <Grid
                          items
                          lg={5}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <div className="box-image">
                            <img src={Retire} alt="" />
                          </div>

                          <p>Retiros y cargos acumulados</p>
                        </Grid>
                        <Grid
                          items
                          lg={7}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span style={{ color: "rgb(138 140 143)" }}>
                            {companySelected === 1
                              ? "- S/ " + montoFormat(cargosH)
                              : "- S/ " +
                                montoFormat(
                                  detalleProductoSPP.ret_cargos_spp.replace(
                                    "-",
                                    ""
                                  )
                                )}
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid items lg={12}>
                    <div className="list-detail-rent-desktop">
                      <Grid container>
                        <Grid
                          items
                          lg={5}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <div className="box-image">
                            <img src={Bullet} alt="" />
                          </div>

                          <p>Rentabilidad acumulada</p>
                        </Grid>
                        <Grid
                          items
                          lg={7}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{
                              color:
                                companySelected === 2
                                  ? detalleProductoSPP.rent_spp.includes("-")
                                    ? "rgb(138 140 143)"
                                    : "+"
                                  : newRent < 0
                                  ? "rgb(138 140 143)"
                                  : "+",
                            }}
                          >
                            {companySelected === 2
                              ? detalleProductoSPP.rent_spp.includes("-")
                                ? "- "
                                : "+ "
                              : newRent < 0
                              ? "- "
                              : "+ "}
                            {companySelected === 1
                              ? "S/ " +
                                montoFormat(newRent.toString().replace("-", ""))
                              : "S/ " +
                                montoFormat(detalleProductoSPP.rent_spp)}
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                {companySelected === 1 ? (
                  ""
                ) : (
                  <div className="mt-3 info-spp">
                    <p className="mb-0">
                      * Considerar que la información del SPP no son datos al
                      día de hoy, la información es con corte a{" "}
                      {period[0].toLowerCase() + "/" + period[1]}.
                    </p>
                    <p className="mb-0">* SPP: Sistema Privado de Pensiones</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </BoxBody>
      </CardContainerDesktop>
      {/* } */}
    </>
  );
}
