import React from "react";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import Alert from "../../../../components/Alert";
import Modal from "../../../../components/BasicModal";
import { ButtonsWrapper } from "./styles";
import { useFormContext } from "react-hook-form";
import { useGastoDeSepelioContext } from "../../context";
import { SEND_REQUEST_SUCCESS } from "../../context/actions";

const ModalDeConfirmacion = ({ open, setOpen, handleStepNavigation }) => {
  const onClose = () => setOpen(false);

  const {
    state: { request, token },
    sendRequest,
  } = useGastoDeSepelioContext();

  const onContinue = async () => {
    const data = new FormData();

    const inputsPayload = {
      afiliado_doctype: getValues("buscar-afiliado.identification-type"),
      afiliado_docnum: getValues("buscar-afiliado.identification-value"),
      solic_tipo: getValues("datos-solicitante.person-type"),
      solic_primer_nombre: getValues("datos-solicitante.first-name"),
      solic_segundo_nombre: getValues("datos-solicitante.second-name"),
      solic_primer_apellido: getValues("datos-solicitante.first-lastname"),
      solic_segundo_apellido: getValues("datos-solicitante.second-lastname"),
      solic_doctype: getValues("datos-solicitante.document-type"),
      solic_docnum: getValues("datos-solicitante.document-value"),
      solic_celular: getValues("datos-solicitante.cellphone"),
      solic_correo: getValues("datos-solicitante.email"),
      solic_parentesco: getValues("datos-solicitante.relationship"),
      solic_ruc: getValues("datos-solicitante.ruc"),
      solic_razonsocial: getValues("datos-solicitante.business-name"),
      afiliado_causafallecimiento: getValues("documentos.cause-of-death"),
      comentario: getValues("documentos.comentarios"),
      leido_informacion: Boolean(getValues("asesoria.acepta-informarcion"))
        ? "1"
        : "0",
      leido_tratamiento: Boolean(getValues("datos-solicitante.data-processing"))
        ? "1"
        : "0",
    };

    Object.entries(inputsPayload).forEach((item) => {
      data.append(item[0], item[1]);
    });

    const filesInputs = [
      { name: "doc_identidad", formKey: "documentos.id" },
      { name: "facturas_boletas", formKey: "documentos.facturas-gastos" },
      {
        name: "certif_defuncion",
        formKey: "documentos.certificado-medico-defuncion",
      },
      { name: "acta_defuncion", formKey: "documentos.acta-defuncion" },
      { name: "voucher_bancario", formKey: "documentos.voucher-bancario" },
      {
        name: "copia_epicrisis",
        formKey: "documentos.epicrisis-historia-clinica",
      },
      { name: "copia_necropcia", formKey: "documentos.necropcia" },
      { name: "atestado_policial", formKey: "documentos.atestado-policial" },
      {
        name: "autorizacion_reembolso",
        formKey: "documentos.autorizacion-reembolso",
      },
      {
        name: "autorizacion_reembolso_dni",
        formKey: "documentos.autorizacion-reembolso-dni",
      },
      {
        name: "identi_repre_legal_empresa",
        formKey: "documentos.id-representante",
      },
      {
        name: "escritura_publica_empresa",
        formKey: "documentos.escritura-publica",
      },
    ];

    filesInputs.forEach((item) => {
      data.append(item.name, getValues(item.formKey)?.[0] || null);
    });

    data.append("token", token);

    const response = await sendRequest(data);

    if (response === SEND_REQUEST_SUCCESS) {
      handleStepNavigation("next");
    }
  };

  const { getValues } = useFormContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeWithBackdrop={false}
      close={false}
    >
      <Modal.Title>¿Estás seguro(a)?</Modal.Title>
      <Modal.Subtitle>
        Estás a punto de registrar tu solicitud de evaluación de Gastos de
        Sepelio, ¿Quieres continuar?
      </Modal.Subtitle>
      {!getValues("documentos.voucher-bancario") && (
        <Alert
          variant={"warning"}
          description={
            "No has subido un Voucher bancario, una vez aprobado el desembolso, si posees DNI este podrá ser cobrado desde una agencia Scotiabank , o BCP si posees otro documento de identidad."
          }
          style={{ marginTop: "16px" }}
        />
      )}
      <Divider />
      <ButtonsWrapper>
        <Button variant="secondary" fullWidth onClick={onClose}>
          Volver
        </Button>
        <Button
          fullWidth
          onClick={onContinue}
          disabled={request.status === "loading"}
        >
          Registrar solicitud
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default ModalDeConfirmacion;

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

const getDecodedFiles = async (fileList) => {
  try {
    const file = fileList?.[0];
    if (file) {
      const fileAsDataURL = await readFileAsync(file);
      return fileAsDataURL;
    }
  } catch (error) {
    console.log({ error });
  }
};
