import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export default function Interestcard(props) {
  const { title, info, img, alt, className, link, btnText, isMobile } = props;

  if (isMobile) {
    return (
      <Link to={link} className="btn-interest">
        <div
          className={`dashboard-componente-interest ${className} interest-mobile`}
        >
          <div className="dashboard-componente-interest_info">
            <h4>{title}</h4>
            <span>{info}</span>
          </div>
          <div className="dashboard-componente-interest_img">
            <img src={img} alt={alt} />
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className={`dashboard-componente-interest ${className} interest-desktop`}
      >
        <div className="dashboard-componente-interest_info">
          <h4>{title}</h4>
          <span>{info}</span>
          <Link to={link} className="btn-interest">
            {btnText}
          </Link>
        </div>
        <div className="dashboard-componente-interest_img">
          <img src={img} alt={alt} />
        </div>
      </div>
    );
  }
}
