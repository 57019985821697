import styled, { css } from "styled-components";

export const inputStyles = css`
  height: 100%;
  width: 100%;
  ${(p) => p.theme.tipography.bodyMediumRegular};
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  color: ${(p) => p.theme.palette.grayscale[800]};
  outline: none;
  border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
  border-radius: 16px;

  ::placeholder {
    color: ${(p) => p.theme.palette.grayscale[200]};
  }
  &:focus:enabled {
    border-color: ${(p) => p.theme.palette.grayscale[700]};
  }

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};
      border-color: ${(p) => p.theme.palette.state.error} !important;
      /* 
      &::placeholder {
        color: ${(p) => p.theme.palette.state.error};
      } */
    `}

  &:disabled {
    cursor: not-allowed;
    &::placeholder {
      color: ${(p) => p.theme.palette.grayscale[200]};
    }
  }

  ${(p) => {
    if (p.size === "sm") {
      return css`
        padding: 8px 16px;
      `;
    }

    if (p.size === "md") {
      return css`
        padding: 10px 16px;
      `;
    }
    if (p.size === "lg") {
      return css`
        padding: 14px 16px;
      `;
    }
  }}
`;

export const textInputWrapper = css`
  position: relative;
`;

export const inputIcon = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: ${(p) => p.theme.palette.grayscale[800]};

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};
    `}

  ${(p) =>
    p.disabled &&
    css`
      color: ${(p) => p.theme.palette.grayscale[200]};
    `}

  svg {
    height: 16px;
    width: 16px;
  }
`;
