import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../../../components";
import Slider from "./Slider";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import FondoDeImg from "../../../../../../../../assets/fondo_de.png";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import ModalToken from "../../../components/ModalToken";
import clienteAxios from "../../../../../../../../config/axios";
import moment from "moment";
import "./index.css";

const Estimacion = (props) => {
  const { showDetail, dateDecision, data, setDetail, processId, idContent } = props;

  const [confirmeCheck, setConfirmeCheck] = useState(false);
  const [showModalConfirmEnd, setshowModalConfirmEnd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadPdf, setLoadPDF] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true); // Nuevo estado para la primera visita

  const jubilacionContext = useContext(JubilacionContext);
  const { guardarPaso4ResponseLoading, crearProcesoRejaEtapa4 } = jubilacionContext;

  useEffect(() => {
    // Mostrar el modal solo la primera vez que se carga el componente, y si la fecha de decisión es mayor a la fecha actual.
    if (dateDecision > moment().format("YYYY-MM-DD")) {
      setshowModalConfirmEnd(true);
    }
  }, [dateDecision]);

  const changeIconForLoading = (value) => {
    if (value) {
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    } else {
      return false;
    }
  };

  const handleSaveSte4 = () => {
    if (dateDecision <= moment().format("YYYY-MM-DD")) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
      setshowModalConfirmEnd(true);
    }
  };

  const cerrarModal = () => {
    setOpenModal(false);
  };

  const downloadPDF = async () => {
    setLoadPDF(true);
    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${props.processId}/pdf-estimation`
      );

      const linkSource = `data:application/pdf;base64,${respuesta.data.data}`;
      const downloadLink1 = document.getElementById("download");
      const fileName = "Estimacion-de-pensión.pdf";
      downloadLink1.href = linkSource;
      downloadLink1.download = fileName;
      downloadLink1.click();

      setLoadPDF(false);
    } catch (error) { }
  };

  const finishStep = (codeToken) => {
    if (dateDecision <= moment().format("YYYY-MM-DD")) {
      const dataTosend = {
        content_id: idContent,
        v_token_digital: codeToken,
      };
      crearProcesoRejaEtapa4(processId, dataTosend);
      setOpenModal(false);
    }
  };

  return (
    <div className="estimacion-container">
      <h1> Estimador de pensión según las modalidades de jubilación</h1>

      <div className="content">
        <div>
          <p className="description">
            En este espacio te mostramos cuanto sería tu pensión en cada
            modalidad de jubilación. Así podrás elegir la que mejor se ajuste a
            tus preferencias.
          </p>
        </div>
        <div className="main-card-background mt-4">
          <span className="header">Fondo total</span>
          <p>S/ {data.cic}</p>
        </div>
        <h4 className="sub-header mb-1">Modalidades de jubilación</h4>

        <Slider
          data={data}
          setDetail={setDetail}
          showDetail={showDetail}
          loading={guardarPaso4ResponseLoading}
        />

        <div>
          <p className="description">
            A continuación, descarga tu estimación de pensiones para que puedas
            revisarlo con mayor detenimiento.
          </p>
          <div
            className="container-reja-buttons"
            style={{ justifyContent: "center" }}
          >
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={() => {
                downloadPDF();
              }}
              disabled={loadPdf}
            >
              Descargar estimación {changeIconForLoading(loadPdf)}
              <a id="download"></a>
            </Button>
          </div>
        </div>

        <div className="check-container mb-3">
          <span className="oneChoice">
            <input
              type="radio"
              value={0}
              checked={confirmeCheck}
              className="radio-btn"
              id="tfa_711"
              name="confirm_check"
              onClick={() => setConfirmeCheck(!confirmeCheck)}
            />

            <label className="postRadioField" id="tfa_711" for="tfa_711">
              <span className="input-radio-faux"></span>
            </label>
          </span>
          <label htmlFor="tfa_711">
            <span className="check-label">
              Doy mi conformidad sobre la información brindada en mi estimación
              de pensiones y por lo tanto asumo las responsabilidades que este
              documento implica.
            </span>
          </label>
        </div>
      </div>

      <div
        className="container-reja-buttons"
        style={{ justifyContent: "center" }}
      >
        <Button
          className={`mb-4 btn-modal-primary ${confirmeCheck ? "" : "disable-secondary"
            }`}
          disabled={!confirmeCheck}
          onClick={() => handleSaveSte4()}
        >
          Continuar {changeIconForLoading(guardarPaso4ResponseLoading)}
        </Button>
      </div>

      {openModal && (
        <ModalToken
          cerrarModal={cerrarModal} // fn para cerrar modal
          guardarCode={finishStep}
        />
      )}

      <CustomModal
        showModal={showModalConfirmEnd} // Controlado por el estado
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => setshowModalConfirmEnd(false)}
        title="Toma de decisión"
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, hemos
            concluido con éxito la primera etapa de tu solicitud.
            <br /> <br />
            El dia {moment(dateDecision).format("DD/MM/YYYY")} podrás ingresar
            de nuevo a esta opción para elegir tu modalidad de pensión.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => setshowModalConfirmEnd(false)}
        textBtnPrimary={
          dateDecision <= moment().format("YYYY-MM-DD") ? "Continuar" : "Cerrar"
        }
        hasAdvice={true}
        txtAdvice={
          <p>
            <strong>Recuerda</strong> estar atento a tu correo o celular para
            recibir tu notificación
          </p>
        }
      />
    </div>
  );
};

export default Estimacion;
