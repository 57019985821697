import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/autenticacion/authContext";
import ReactGa from "react-ga";

import "./index.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button, FormGroup } from "reactstrap";
import ModalLogin from "../../components/ModalLogin";
import logo from "../../assets/logo-fixed.png";
import icono_paso2_1 from "./img/1.png";
import icono_paso2_2 from "./img/2.png";
import icono_paso2_3 from "./img/3.png";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { LoginForm } from "../NewHome/components";

const Login = (props) => {
  //Extraemos los valores del context
  const authContext = useContext(AuthContext);
  const {
    mensaje,
    autenticado,
    loading,
    loginForce,
    iniciarSesion,
    redirectHabitat,
    dataBlock,
    limpiarVariablesAccountBlock,
  } = authContext;
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    if (autenticado) {
      props.history.push("/mi-habitat-digital/afiliado/inicio");
    } else {
      ReactGa.pageview(window.location.href);
      if (props.location.search !== "") {
        setMostrar(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensaje, autenticado, props.history]);

  // Estado inicial del Input Numero de documento

  const [userInfoLogin, guardarUserInfoLogin] = useState(null);

  const onSubmit = async (data, captcha) => {
    let bodyFormData = new FormData();
    localStorage.setItem("doc_type", data.documentType);
    localStorage.setItem("doc_num", data.documentNumber);
    bodyFormData.set("doc_type", data.documentType);
    bodyFormData.set("doc_num", data.documentNumber);
    bodyFormData.set("password", data.password);
    bodyFormData.set("g-recaptcha-response", captcha);

    if (data.firstAttempt === false) {
      bodyFormData.set("firstAttempt", data.firstAttempt);
    }

    guardarUserInfoLogin(bodyFormData); // lo guardamos en caso usemos el modal para forzar el inicio de sesion
    let response = await iniciarSesion(bodyFormData); // invocamos al metodo de login
    return response;
  };

  const verHeredero = (e) => {
    e.preventDefault();
    setMostrar(!mostrar);
  };

  let claseEfecto = "animate__animated animate__fadeInDown";
  let claseEfecto1 = "animate__animated animate__fadeInUp";

  const history = useHistory();
  const onClickBeneficiaryZone = () => history.push("/zona-beneficiarios");

  return (
    !redirectHabitat && (
      <Container className="auth-container2" fluid={true}>
        <div className="headerLogin">
          <a href="https://www.afphabitat.com.pe/">
            <img src={logo} alt="" className="logoImg" />
          </a>
        </div>
        <div id="first_paso" className={!mostrar ? claseEfecto1 : "d-none"}>
          <div className="home_header_title">
            <h2 className="firstTitle">¡Hola!</h2>
            <h6 className="secondTitle">
              <b>¡Bienvenid@ a Mi Habitat Digital!</b> Tus inversiones y
              operaciones online las 24 horas del día, fácil y rápido desde
              donde estés.
            </h6>
          </div>
          <div className="full-container">
            <LoginForm
              sendRequest={onSubmit}
              loading={loading}
              dataBlock={dataBlock}
              error={mensaje}
              limpiarVariablesAccountBlock={limpiarVariablesAccountBlock}
            />
            {loginForce && (
              <ModalLogin mensaje={mensaje} datos={userInfoLogin} />
            )}
          </div>
          {/* <div className="bottom-container">
            <div className="form-container contentBottom">
              <div className="contentButton">
                <div>
                  <button
                    className="beneficiary-zone-button"
                    onClick={onClickBeneficiaryZone}
                  >
                    Zona de beneficiarios
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div
          id="second_paso"
          className={mostrar ? claseEfecto : "d-none " + claseEfecto}
        >
          <div className="home_header_title">
            <h2 className="firstTitle d-none d-sm-block">¡Hola!</h2>
            <h6 className="secondTitle">
              Recuerda que si eres el beneficiario o heredero de un afiliado
              nuestro,
              <br />
              ahora puedes hacer diversas solicitudes en línea, sin salir de
              casa, de <br />
              manera fácil y segura.
            </h6>
          </div>
          <div className="full-container">
            <div className="form-container info-container">
              <div className="contenido2">
                <h5>Realiza las siguientes solicitudes:</h5>
                <div className="forTelefono">
                  <h6>¿Eres beneficiario o heredero?</h6>
                  <hr />
                  <h6 className="maspeque">
                    Puedes realizar las siguientes solicitudes:
                  </h6>
                </div>
                <div className="content-bloque">
                  <span>
                    <img src={icono_paso2_1} alt="" />
                  </span>
                  <NavLink
                    className="btn btn-secondary"
                    to="/mi-habitat-digital/solicitud/reembolso"
                  >
                    Realiza tu solicitud para pensión de Sobrevivencia
                  </NavLink>
                </div>
                <div className="content-bloque">
                  <span>
                    <img src={icono_paso2_2} alt="" />
                  </span>
                  <NavLink
                    className="btn btn-secondary"
                    to="/mi-habitat-digital/solicitud/herencia"
                  >
                    Realiza tu solicitud por Herencia
                  </NavLink>
                </div>
                <div className="content-bloque">
                  <span>
                    <img src={icono_paso2_3} alt="" />
                  </span>
                  <NavLink
                    className="btn btn-secondary"
                    to="/mi-habitat-digital/solicitud/sepelio"
                  >
                    Realiza el reembolso por gastos de sepelio
                  </NavLink>
                </div>
                <Button
                  className="btn-block btn-comosaber"
                  onClick={() => {
                    window.open(
                      "https://www.afphabitat.com.pe/soy-beneficiario-o-heredero/"
                    );
                  }}
                >
                  ¿Cómo sé si soy beneficiario <br className="d-lg-none" /> o
                  heredero?
                  <i className="fa fa-arrow-right">&nbsp;</i>
                </Button>
              </div>
            </div>
            <div className="bottom-container">
              <div className="form-container contentBottom">
                <div className="contentButton">
                  <FormGroup>
                    <Button
                      className="botomRegresar btn-block"
                      onClick={verHeredero}
                    >
                      Soy Afiliado
                      <i className="fa fa-arrow-right">&nbsp;</i>
                    </Button>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  );
};

export default Login;
