import React from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/Button";

const DialogAction = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: ${(props) => props.theme.spacing(2)};
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    bottom: "unset",
    "& .MuiBackdrop-root": {
      background: "transparent",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      background: "#2F6D95",
      color: "#fff",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  paperStylesChages: {
    bottom: "unset",

    "& .MuiBackdrop-root": {
      background: "transparent",
    },
    "& .MuiDialog-paper": {
      background: "#2F6D95",
      color: "#fff",
      fontSize: "14px",
      fontWeight: 400,
      maxHeight: "95%",
      height: "unset",
      margin: "32px 8px",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const CustomModal = (props) => {
  const {
    open,
    closeModal,
    title,
    children,
    hasPrimary,
    onClose,
    onClickPrimary,
    nombreUsuario,
    iconPrimary,
    textPrimary = "Salir",
  } = props;
  const theme = useTheme();
  /* MUI CSS styles */
  const classes = useStyles();

  const smallScreenSm = useMediaQuery(theme.breakpoints.down("sm"));

  // const renderPrimaryIcon = () => {
  //   if (btnPrimaryLoading)
  //     return (
  //       <span
  //         className="spinner-border spinner-border-sm"
  //         role="status"
  //         aria-hidden="true"
  //       ></span>
  //     );
  //   else return false;
  // };

  // const renderSecondaryIcon = () => {
  //   if (btnSecondaryLoading)
  //     return (
  //       <span
  //         className="spinner-border spinner-border-sm"
  //         role="status"
  //         aria-hidden="true"
  //       ></span>
  //     );
  //   else return false;
  // };

  return (
    <Dialog
      open={open}
      className={
        smallScreenSm ? classes.paperStylesChages : classes.paperStyles
      }
      keepMounted
      // fullScreen={smallScreenSm}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography
          sx={{ textAlign: "left", fontSize: "18px", fontWeight: 400 }}
        >
          Te extrañamos, {nombreUsuario}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ display: "flex" }}>
        Tus metas te siguen esperando. Recuerda que puedes alcanzarlas con Fondo
        Libre Habitat.
      </DialogContent>

      <DialogAction>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
            <Button
              onClick={onClickPrimary}
              variant="contained"
              name="tertiary"
              sx={{ width: "100%", background: "rgba(255, 255, 255, 0.15);" }}
              icon={iconPrimary}
            >
              Invertir
            </Button>
          </Grid>
        </Grid>
      </DialogAction>
    </Dialog>
  );
};

export default CustomModal;
