import React, { Component, Fragment } from 'react';
import { Box, Divider, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";


class PieChartFondo extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { datachart, serieschart, information, type } = this.props;
        let dataseries = [];
        if (type === 'single') {
            dataseries = [datachart];
        }

        if (type === 'group') {
            dataseries = datachart;
        }

        this.drawGraphic(dataseries, serieschart);
    }

    drawGraphic = (dataArray, serieschart) => {
        const { graphId } = this.props;

        let chart = am4core.create(graphId, am4charts.XYChart);
        chart.responsive.enabled = false;
        chart.paddingTop = 0;
        chart.paddingRight = 0;
        chart.paddingLeft = 0;
        chart.maskBullets = false;
        chart.numberFormatter.numberFormat = "#.##";
        chart.language.locale = am4lang_es_ES;
        chart.data = dataArray;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "fondo";
        categoryAxis.title.text = null;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
        categoryAxis.renderer.labels.template.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.renderer.minGridDistance = 5;
        valueAxis.title.text = null;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.inside = false;

        valueAxis.extraMax = 0.1;
        valueAxis.calculateTotals = false;

        serieschart.map((item, index) => {
            this.createSeries(index, item.slug, item.name, item.color, item.textColor, item.legend_title, item.legend_desc, chart);
        });

        chart.legend = new am4charts.Legend();

        chart.legend.itemContainers.template.clickable = false;
        chart.legend.itemContainers.template.focusable = false;
        chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    }

    createSeries = (index, field, name, color, textColor, title, description, chart) => {
        var series = chart.series.push(new am4charts.ColumnSeries());
        let shadow = series.columns.template.filters.push(new am4core.DropShadowFilter());
        shadow.dx = 0;
        shadow.dy = 5;
        shadow.blur = 16;
        shadow.color = am4core.color('rgba(0, 0, 0, 0.12)');
        var columnTemplate = series.columns.template;

        var fnTopCornerRadius = function (radius, item) {
            var dataItem = item.dataItem;
            var lastSeries;
            chart.series.each(function (series) {
                if (dataItem.dataContext[series.dataFields.valueY] && !series.isHidden && !series.isHiding) {
                    lastSeries = series;
                }
            });
            return dataItem.component == lastSeries ? 10 : radius;

        };
        var fnBottomCornerRadius = function (radius, item) {
            var dataItem = item.dataItem;
            var lastSeries;
            let it = 0;
            chart.series.each(function (series) {

                if (dataItem.dataContext[series.dataFields.valueY] && !series.isHidden && !series.isHiding && it == 0) {
                    lastSeries = series;
                }
                it++;
            });
            return dataItem.component == lastSeries ? 10 : radius;

        };

        columnTemplate.column.adapter.add("cornerRadiusTopLeft", fnTopCornerRadius);
        columnTemplate.column.adapter.add("cornerRadiusTopRight", fnTopCornerRadius);
        columnTemplate.column.adapter.add("cornerRadiusBottomLeft", fnBottomCornerRadius);
        columnTemplate.column.adapter.add("cornerRadiusBottomRight", fnBottomCornerRadius);

        series.sequencedInterpolation = true;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "fondo";
        series.dataFields.valPercentage = field;
        series.name = name;
        series.hiddenInLegend = true;
        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]%";
        series.stacked = true;
        series.columns.template.width = am4core.percent(95);
        series.fill = am4core.color(color);
        series.stroke = am4core.color(color);
        series.marginTop = 0;
        series.marginLeft = 0;
        series.marginRight = 0;
        series.paddingTop = 0;
        series.paddingLeft = 0;
        series.paddingRight = 0;

        var tooltipLegendHTML = '<h4>' + title + '</h4><p>' + description + '</p>';

        series.dummyData = {
            description: tooltipLegendHTML
        };

        // Add label inside Column
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.label.fill = am4core.color(textColor);
        labelBullet.label.fontSize = "24px";
        labelBullet.label.adapter.add("text", function (text, target) {

            if (target.dataItem && target.dataItem.valPercentage) {
                return target.dataItem.valPercentage + "%";
            }
            else {
                return text;
            }
        });


        labelBullet.locationY = 0.5;
    }

    render() {
        const { graphId, isMobile, widthContainer, heightContainer } = this.props;


        return (
            <Fragment>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                    <div id={graphId} style={{ width: widthContainer, height: heightContainer, marginTop: "0px" }}></div>
                </Box>
            </Fragment>
        );
    }

}

export default PieChartFondo; 