import styled, { css } from "styled-components";
import { device } from "../../../../../../../utils/device";

export const ContainerAsesoria = styled.div`
  text-align: center;

  & p {
    text-align: left;
  }
  & h6 {
    ${(p) => p.theme.tipography.caption2}
  }
  & h4 {
    ${(p) => p.theme.tipography.h5}
    margin-bottom: 16px;
  }

  & .lyrics_pmo {
    ${(p) => p.theme.tipography.bodySmallRegular}
    color: ${(p) => p.theme.palette.grayscale[400]}
  }

  & .highlighted {
    ${(p) => p.theme.tipography.h5}
    color: ${(p) => p.theme.palette.grayscale.dark}
  }

  & .break-line {
    width: 100%;
    border: 2px solid #ededed;
  }
`;
