import {
  BOLETA_PERIODO_EXITOSO,
  BOLETA_PERIODO_ERROR,
  BOLETA_PERIODO_LOADIN,
  BOLETA_CATEGORIA_EXITOSO,
  BOLETA_CATEGORIA_ERROR,
  BOLETA_CATEGORIA_LOADIN,
  CLEAR_BENEFICIARIOS,
  BOLETA_PAGO_EXITOSO,
  BOLETA_PAGO_ERROR,
  BOLETA_PAGO_LOADIN,
  BOLETA_PAGO_CLEANER,
  BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL,
  CLEAR_BOLETA,
  // EC_MENSUAL_CLEANER_ONLY_VARIABLE,
} from "../../types/boletaPagoPension";

export default (state, action) => {
  switch (action.type) {
    case BOLETA_PERIODO_EXITOSO:
      return {
        ...state,
        periodoInfo: action.payload,
        categoriaInfo: null,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case BOLETA_CATEGORIA_EXITOSO:
      return {
        ...state,
        categoriaInfo: action.payload,
        mensajeAlerta: null,
        categoriaLoading: false,
      };
    case BOLETA_PAGO_EXITOSO:
      return {
        ...state,
        datosBoleta: action.payload,
        loadingBtn: false,
      };
    case BOLETA_PAGO_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case BOLETA_PAGO_ERROR:
      return {
        ...state,
        datosBoleta: null,
        mensajeAlerta: action.payload,
      };
    case BOLETA_PERIODO_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };

    case BOLETA_CATEGORIA_LOADIN:
      return {
        ...state,
        categoriaInfo: null,
        categoriaLoading: true,
      };
    case CLEAR_BENEFICIARIOS:
      return {
        ...state,
        categoriaInfo: null,
        datosBoleta: null,
      };
    case CLEAR_BOLETA:
      return {
        ...state,
        datosBoleta: null,
      };
    case BOLETA_PERIODO_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case BOLETA_CATEGORIA_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
        categoriaLoading: false,
      };

    case BOLETA_PAGO_CLEANER:
      return {
        ...state,
        periodoInfo: null,
        categoriaInfo: null,
        categoriaLoading: false,
        datosBoleta: null,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
      };
    case BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL:
      return {
        ...state,
        periodoInfo: null,
        categoriaInfo: null,
        categoriaLoading: false,
        datosBoleta: null,
        // mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
      };
    default:
      break;
  }
};
