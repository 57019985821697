import React from "react";
import Button from "../Buttons";
import PropTypes from "prop-types";

import {
  AlertComponet,
  Icon,
  Close,
  Title,
  Description,
  Action,
  Header,
} from "./styles";

import { FiCheckCircle } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsXCircle, BsX } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
const Alert = ({
  variant,
  title,
  description,
  action,
  onAction,
  closeButtom,
  onClose,
  actionProps,
  icon,
  ...props
}) => {
  return (
    <AlertComponet $variant={variant} $title={title} {...props}>
      <Icon $variant={variant} $title={title}>
        {variant === "success" && (icon ? icon : <FiCheckCircle />)}
        {variant === "info" && (icon ? icon : <AiOutlineInfoCircle />)}
        {variant === "error" && (icon ? icon : <BsXCircle />)}
        {variant === "warning" && (icon ? icon : <IoWarningOutline />)}
      </Icon>
      {title && (
        <Header>
          <Title>{title}</Title>

          {closeButtom && (
            <Close onClick={onClose}>
              <BsX />
            </Close>
          )}
        </Header>
      )}
      <Description $title={title} $action={action}>
        {description}
      </Description>
      {action && (
        <Action $variant={variant}>
          <Button variant={"link"} onClick={onAction} {...actionProps}>
            {action}
          </Button>
        </Action>
      )}
    </AlertComponet>
  );
};

export default Alert;

Alert.propTypes = {
  variant: PropTypes.oneOf(["info", "success", "error", "warning"]),
  action: PropTypes.string,
  actionProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  closeButtom: PropTypes.bool,
};

Alert.defaultProps = {
  variant: "info",
};
