import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Sheet from "react-modal-sheet";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import {
  BoxDesktop,
  BoxMobile,
  BreadCrumbsFL,
} from "../componentes/data/components/BreadcrumbsFL";
// import Interest from '../componentes/data/components/Interest'
import { CardDouble } from "./componentes/CardDouble";
import { CardSimple } from "./componentes/CardSimple";
import { ItemState } from "./componentes/ItemState";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Redirect, useParams } from "react-router-dom";
import PagosDesembolsosContext from "../../../context/pagosDesembolsos/pagosDesembolsosContext";
import Loading from "../../../components/Loading";
import moment from "moment";
import styled from "styled-components";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import IC_CALENDAR from "./../../../assets/icons/ic_calendar.svg";
import MENU_MOBILE from "./../../../assets/icons/menu_mobile_elipse_2.png";
import IC_N_SOLICITUD from "./../../../assets/icons/icon_n_solicitud.svg";
import IC_COIN from "./../../../assets/icons/ic_coin.svg";
import IC_INFO_RETIRO_EXTRAORDINARIO from "./../../../assets/icons/ic_info_retiro_extraordinario.svg";
import IC_ICON_PLAZO_NORMATIVO from "./../../../assets/icons/ic_icon_plazo_normativo.svg";
import IC_PLAZO_NORMATIVO from "./../../../assets/icons/ic_plazo_normativo_expand.svg";
import IC_BANK from "./../../../assets/icons/ic_bank.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { OverlayProvider } from "@react-aria/overlays";
import { FocusScope } from "@react-aria/focus";
import { useButton } from "@react-aria/button";
import { ContentModal } from "./Modal";
import DownloadButton from "./componentes/DownloadButton";
import { BsDownload } from "react-icons/bs";

const DivNext = styled("div")`
  width: 20px;
  height: 100%;
  position: absolute;
  left: calc(100% - 20px);
  background: #ce1336;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  align-self: flex-end;
  display: flex;
  @media (min-width: 990px) {
    display: none;
  }
`;

const styleDetail = {
  padding: "0 0.12rem 0.12rem 0.12rem",
  color: "#6E7378",
  fontSize: "12px",
};

const styleTitle = {
  padding: "0 0.12rem 0.12rem 0.12rem",
  color: "#2B2B2B",
  fontWeight: "400",
  fontSize: "14px",
  margin: "auto !important",
};

export const PagoDetalle = () => {
  const [isMore, setIsMore] = useState(false);
  const { nro, tipo } = useParams();

  const pagosDesembolsosContext = useContext(PagosDesembolsosContext);

  const { loadingPage, detalle, obtenerTramite, getUser } =
    pagosDesembolsosContext;

  const {
    current_sol_status,
    monto_acreditado,
    subtitle,
    tipo_retiro,
    armadas,
    cuenta,
    moneda,
    pais_entidad,
    mod_retiro,
    banco_abono,
    desc_entidad_ext,
    nro_solicitud,
    tipo_cta,
    num_cta,
    fecha_recep,
    monto_solicitado,
    codigo_swift,
    entidad_financiera,
    forma_pago,
    codigo_iban,
    codigo_interbancario,
    codigo_aba,
    nib,
  } = detalle ?? {};

  const estado =
    tipo === "retiro-apv" || tipo === "tfe" || tipo === "credito-hipotecario"
      ? current_sol_status === "RECHAZADA"
        ? "RECHAZADA"
        : current_sol_status === "PROCESADA"
        ? "ACREDITADO"
        : "EN CURSO"
      : "RETIROS EXTRAORDINARIOS";

  useEffect(() => {
    obtenerTramite(nro, tipo);
    getUser();
    return () => {};

    // eslint-disable-next-line
  }, []);

  const onClickNext = () => {
    setIsMore(!isMore);
  };

  if (loadingPage) return <Loading />;
  const redirect =
    tipo !== "retiro-apv" &&
    tipo !== "tfe" &&
    tipo !== "credito-hipotecario" &&
    tipo !== "retiros-extraordinarios";

  if (redirect) {
    return <Redirect to="/mi-habitat-digital/afiliado/estado-de-tramite" />;
  }
  return (
    <>
      <BreadCrumbsFL
        title_mobile={
          tipo === "retiro-apv"
            ? "Retiro Fondo Libre Habitat"
            : tipo === "tfe"
            ? "Transferencia al exterior"
            : tipo === "retiros-extraordinarios"
            ? "Retiros extraordinarios"
            : "Retiro 25% para vivienda"
        }
        title={
          tipo === "retiros-extraordinarios"
            ? subtitle?.toLowerCase().includes("ley")
              ? subtitle
              : subtitle?.substring(
                  0,
                  subtitle?.length - 5
                ) /* + ' ' + nro_solicitud */
            : "Solicitud N.º " + nro_solicitud
        }
        rutas={[
          {
            id: 1,
            title: "Trámites",
            ruta: "#/mi-habitat-digital/afiliado/estado-de-tramite",
          },
          {
            id: 2,
            title:
              tipo === "retiro-apv"
                ? "Retiro Fondo Libre Habitat"
                : tipo === "tfe"
                ? "Transferencia al exterior"
                : tipo === "retiros-extraordinarios"
                ? "Retiros extraordinarios"
                : "Retiro 25% para vivienda",
            ruta: "#/mi-habitat-digital/afiliado/estado-de-tramite/" + tipo,
          },
          {
            id: 3,
            title:
              tipo === "retiros-extraordinarios"
                ? subtitle?.toLowerCase().includes("ley")
                  ? subtitle
                  : subtitle?.substring(
                      0,
                      subtitle?.length - 5
                    ) /* + ' ' + nro_solicitud */
                : "Solicitud N.º " + nro_solicitud,
            ruta: "",
          },
        ]}
      />

      <Grid
        container
        sx={{
          paddingTop: { xs: "65px", md: "96px" },
          background: { xs: "#ffffff", md: "#f8f9fb" },
          paddingBottom: { xs: "60px", md: "0px" },
          minHeight: { xs: "78vh", md: "auto" },
        }}
      >
        <Grid item xs={12} lg={12} p={2}>
          {tipo === "retiros-extraordinarios" ? (
            subtitle?.toLowerCase().includes("ley") ? (
              <AlertPlazo armadas={armadas} />
            ) : (
              <></>
            )
          ) : (
            <AlertPlazo armadas={armadas} />
          )}

          <div>
            <Box
              sx={{
                border: {
                  xs: "1px solid rgba(20, 20, 20, 0.15)",
                  md: "1px solid rgba(20, 20, 20, 0.12)",
                },
                boxShadow: { xs: "0", md: "0px -4px 16px rgba(0, 0, 0, 0.12)" },
                borderRadius: "16px",
                background: "#fff",
                display: { xs: "flex", md: "block" },
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Container
                sx={{
                  paddingBottom: "0.5rem",
                }}
              >
                <Grid
                  container
                  className="grid_container_mobile"
                  sx={{
                    minHeight: { xs: "65vh", md: "100%" },
                  }}
                >
                  <Grid
                    item
                    xs={11}
                    md={5}
                    sx={{
                      display: !isMore ? "block" : "none",
                    }}
                    className="animate__animated animate__fadeInLeft animate__faster"
                  >
                    <Box
                      sx={{
                        padding: "1rem 0",
                      }}
                    >
                      <ItemState {...detalle} estado={estado} />
                    </Box>
                    {armadas?.map((e, index) => (
                      <div
                        key={index}
                        style={{
                          paddingLeft: "1.5rem",
                        }}
                      >
                        <ArmadaContent key={index} {...e} index={index} />
                        {index !== armadas.length - 1 ? (
                          <div
                            style={{
                              padding: "16px 0px",
                            }}
                          >
                            <div
                              style={{
                                background: "#E5E5E5",
                                width: "100%",
                                border: "1px solid #E5E5E5",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <div
                            style={{
                              padding: "16px 0px",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={1}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1rem",
                        paddingBottom: "1.5rem",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "1px",
                          background: "#EDEDED",
                          position: "relative",
                          border: "1.5px solid #EDEDED",
                        }}
                      ></div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    md={6}
                    sx={{
                      display: { xs: !isMore ? "none" : "block", md: "block" },
                    }}
                    className="animate__animated animate__fadeInRight animate__faster"
                  >
                    <Box
                      sx={{
                        padding: "1.5rem 1rem 1rem 1rem",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400, regular",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.2px",
                        color: "#101010",
                      }}
                    >
                      Datos del trámite
                    </Box>

                    {nro_solicitud && tipo !== "retiros-extraordinarios" && (
                      <CardSimple
                        title="N.º de solicitud"
                        detail={nro_solicitud}
                        imagen={IC_N_SOLICITUD}
                      />
                    )}
                    {fecha_recep && (
                      <CardSimple
                        title="Fecha de solicitud"
                        detail={moment(new Date(fecha_recep)).format(
                          "DD/MM/YYYY"
                        )}
                        imagen={IC_CALENDAR}
                      />
                    )}

                    {monto_solicitado && (
                      <CardSimple
                        title="Monto solicitado"
                        imagen={IC_COIN}
                        detail={"S/" + monto_solicitado}
                      />
                    )}

                    {tipo === "tfe" && (
                      <CardSimple
                        title="Monto solicitado"
                        imagen={IC_COIN}
                        detail={
                          monto_acreditado
                            ? "S/" + monto_acreditado
                            : "¡Monto en proceso!"
                        }
                      />
                    )}

                    {tipo !== "retiros-extraordinarios" && (
                      <>
                        <Box
                          sx={{
                            padding: "1rem",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400, regular",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.2px",
                            color: "#101010",
                          }}
                        >
                          Datos del banco de abono
                        </Box>

                        <div
                          style={{
                            padding: "0.25rem",
                          }}
                        >
                          {" "}
                        </div>
                        <CardDouble
                          imagen={tipo === "tfe" ? IC_COIN : IC_COIN}
                          item1={{
                            title:
                              tipo === "tfe" ? "Entidad financiera" : "Retiro",
                            detail:
                              tipo === "tfe" ? desc_entidad_ext : tipo_retiro,
                          }}
                          item2={{
                            title:
                              tipo === "tfe"
                                ? "N.º de cuenta"
                                : forma_pago
                                ? "Forma de pago"
                                : "Modalidad de retiro",
                            detail:
                              tipo === "tfe"
                                ? "************".concat(
                                    cuenta?.slice(
                                      cuenta?.length - 4,
                                      cuenta?.length
                                    )
                                  )
                                : forma_pago ?? mod_retiro,
                          }}
                        />

                        <div
                          style={{
                            padding: "0.25rem",
                          }}
                        >
                          {" "}
                        </div>

                        <CardDouble
                          imagen={tipo === "tfe" ? IC_BANK : IC_BANK}
                          item1={{
                            title:
                              tipo === "tfe" ? "País" : "Entidad financiera",
                            detail:
                              tipo === "tfe"
                                ? pais_entidad
                                : entidad_financiera,
                          }}
                          item2={{
                            title:
                              tipo === "tfe"
                                ? "Moneda"
                                : tipo_cta
                                ? "Tipo de cuenta"
                                : "Entidad a abonar",
                            detail:
                              tipo === "tfe" ? moneda : tipo_cta ?? banco_abono,
                          }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        padding: "0.25rem",
                      }}
                    ></div>
                    {num_cta && (
                      <>
                        <CardBorderLine
                          key={1}
                          title="N.º de cuenta"
                          value={num_cta}
                          isPassword={true}
                        />

                        {console.log({ current_sol_status })}
                      </>
                    )}

                    {current_sol_status === "PROCESADA" &&
                      tipo === "retiro-apv" && (
                        <div style={{ paddingBottom: "16px" }}>
                          <DownloadButton icon={<BsDownload />} />
                        </div>
                      )}

                    {codigo_iban && (
                      <CardBorderLine
                        key={2}
                        title="IBAN"
                        value={codigo_iban}
                        isPassword={true}
                      />
                    )}

                    {codigo_interbancario && (
                      <CardBorderLine
                        key={3}
                        title="CCI"
                        value={codigo_interbancario}
                        isPassword={true}
                      />
                    )}

                    {codigo_aba && (
                      <CardBorderLine
                        key={4}
                        title="CCI"
                        value={codigo_aba}
                        isPassword={true}
                      />
                    )}

                    {nib && (
                      <CardBorderLine
                        key={5}
                        title="CCI"
                        value={nib}
                        isPassword={true}
                      />
                    )}

                    {codigo_swift && (
                      <CardBorderLine
                        key={6}
                        title="SWIFT"
                        value={codigo_swift}
                        isPassword={false}
                      />
                    )}
                  </Grid>
                </Grid>
              </Container>

              <DivNext>
                <div
                  style={{
                    position: "absolute",
                    top: "calc(50% - 40px)",
                    right: "0.4rem",
                    height: "150px",
                    padding: "0.75rem",
                  }}
                  onClick={onClickNext}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(50% - 94px)",
                      right: "10px",
                      zIndex: 0,
                    }}
                  >
                    <img src={MENU_MOBILE} height={"150px"} alt="menu-mobile" />
                  </div>
                  <div
                    style={{
                      padding: "0.15rem",
                      borderRadius: "50%",
                      position: "absolute",
                      right: "2px",
                      top: "calc(50% - 36px)",
                      zIndex: 3,
                      border: "1px solid #FFFFFF",
                    }}
                  >
                    {/* {(!isMore) ? <KeyboardDoubleArrowLeftRoundedIcon sx={{ color: '#FFFFFF' }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: '#FFFFFF' }} />} */}
                    <KeyboardDoubleArrowRightIcon sx={{ color: "#FFFFFF" }} />
                  </div>
                </div>
              </DivNext>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const CardBorderLine = ({ title, value, isPassword = false }) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            margin: "4px",
            padding: "0.25rem",
            border: "2px dashed #569BBE",
            borderRadius: "16px",
            textAlign: "center",
            width: "216px",
          }}
        >
          <Typography sx={styleTitle} gutterBottom component="div">
            {title}
          </Typography>
          {isPassword ? (
            <>
              <Typography sx={styleDetail} gutterBottom component="div">
                {value
                  ? "************".concat(
                      value?.slice(value?.length - 4, value?.length)
                    )
                  : null}
              </Typography>
            </>
          ) : (
            <>
              <Typography sx={styleDetail} gutterBottom component="div">
                {value}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const PBold = styled("div")`
  color: #2b2b2b;
  display: inline-block;
  font-weight: 400;
`;

const AlertPlazo = ({ armadas }) => {
  const { tipo } = useParams();

  const mensajes = [
    <>
      <PBold>
        Transferencia a entidad financiera{" "}
        <Box
          sx={{
            display: { md: "inline", xs: "none" },
          }}
        >
          :
        </Box>
      </PBold>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          height: { md: "0px", xs: "2px" },
        }}
      ></Box>{" "}
      Se dará en 5 días útiles contados desde el día siguiente de recibida tu
      solicitud.
    </>,
    <>
      <PBold>
        Transferencia a entidad financiera{" "}
        <Box
          sx={{
            display: { md: "inline", xs: "none" },
          }}
        >
          :
        </Box>
      </PBold>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          height: { md: "0px", xs: "2px" },
        }}
      ></Box>{" "}
      Después de haber sido aprobada tu solicitud, se contarán 15 días útiles
      desde el día siguiente que la ingresaste.
    </>,
    [
      {
        id: 1,
        value: (
          <>
            <PBold>
              Evaluación y plazos de procedencia{" "}
              <Box
                sx={{
                  display: { md: "inline", xs: "none" },
                }}
              >
                :
              </Box>
            </PBold>
            <Box
              sx={{
                display: { md: "none", xs: "block" },
                height: { md: "0px", xs: "2px" },
              }}
            ></Box>{" "}
            10 días útiles desde la fecha en la que recibimos tu solicitud.
          </>
        ),
      },
      {
        id: 2,
        value: (
          <>
            <PBold>
              Transferencia a entidad financiera
              <Box
                sx={{
                  display: { md: "inline", xs: "none" },
                }}
              >
                :
              </Box>
            </PBold>
            <Box
              sx={{
                display: { md: "none", xs: "block" },
                height: { md: "0px", xs: "2px" },
              }}
            ></Box>{" "}
            Si se trata de <PBold>cuota inicial</PBold>, el pago se realizará en
            7 días útiles luego de que la entidad financiera nos indique
            efectuarlo. En caso de <PBold>amortización</PBold>, se considerará
            un plazo de 7 días útiles desde la fecha de procedencia.
          </>
        ),
      },
    ],
  ];

  const mensajesArmadas = [
    <>
      <PBold>
        Primera armada
        <Box
          sx={{
            display: { md: "inline", xs: "none" },
          }}
        >
          :
        </Box>
      </PBold>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          height: { md: "0px", xs: "2px" },
        }}
      ></Box>{" "}
      Hasta 10 días calendario contados desde el día siguiente de ingresada tu
      solicitud.
    </>,
    <>
      <PBold>
        Segunda armada
        <Box
          sx={{
            display: { md: "inline", xs: "none" },
          }}
        >
          :
        </Box>
      </PBold>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          height: { md: "0px", xs: "2px" },
        }}
      ></Box>{" "}
      Hasta 30 días calendario contados desde el día siguiente de efectuado el
      primer desembolso.
    </>,
    <>
      <PBold>
        Tercera armada
        <Box
          sx={{
            display: { md: "inline", xs: "none" },
          }}
        >
          :
        </Box>
      </PBold>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          height: { md: "0px", xs: "2px" },
        }}
      ></Box>{" "}
      Hasta 30 días calendario contados desde el día siguiente de efectuado el
      segundo desembolso.
    </>,
  ];

  const mensajesAll = (
    <>
      {armadas && (
        <div>
          {armadas?.map((e, index) => (
            <Box
              key={index}
              sx={{
                padding: { md: "0", xs: "0.5rem 1rem" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  borderRadius: { md: "0", xs: "16px" },
                  boxShadow: {
                    md: "0",
                    xs: "0px 4px 16px rgba(0, 0, 0, 0.12)",
                  },
                  padding: { md: "0", xs: "12px 16px" },
                }}
              >
                <CalendarTodayTwoToneIcon
                  style={{
                    color: "#2F6D95",
                    paddingRight: "0.5rem",
                  }}
                />
                <div
                  style={{
                    fontSize: "14divx",
                    letterSpacing: "0.2px",
                    marginBottom: "0px",
                    fontWeight: "300",
                  }}
                >
                  {mensajesArmadas[index]}
                </div>
              </Box>
            </Box>
          ))}
        </div>
      )}

      {tipo !== "retiros-extraordinarios" && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: Array.isArray(
              mensajes[tipo === "retiro-apv" ? 0 : tipo === "tfe" ? 1 : 2]
            )
              ? "column"
              : "row",
          }}
        >
          {Array.isArray(
            mensajes[tipo === "retiro-apv" ? 0 : tipo === "tfe" ? 1 : 2]
          ) ? (
            mensajes[tipo === "retiro-apv" ? 0 : tipo === "tfe" ? 1 : 2].map(
              (e) => (
                <Box
                  key={e.id}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    boxShadow: {
                      xs: "0px 4px 16px rgba(0, 0, 0, 0.12)",
                      md: "0",
                    },
                    padding: { md: "0", xs: "12px 16px" },
                    margin: { md: "0", xs: "8px 16px" },
                    borderRadius: { md: "0", xs: "16px" },
                  }}
                >
                  <CalendarTodayTwoToneIcon
                    style={{
                      color: "#2F6D95",
                      paddingRight: "0.5rem",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      letterSpacing: "0.2px",
                      marginBottom: "0px",
                      fontWeight: "300",
                    }}
                  >
                    {e.value}
                  </div>
                </Box>
              )
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                boxShadow: { xs: "0px 4px 16px rgba(0, 0, 0, 0.12)", md: "0" },
                padding: { md: "0", xs: "12px 16px" },
                margin: { md: "0", xs: "8px 16px" },
                borderRadius: { md: "0", xs: "16px" },
              }}
            >
              <CalendarTodayTwoToneIcon
                style={{
                  color: "#2F6D95",
                  paddingRight: "0.5rem",
                }}
              />
              <div className="block_item_plazosnormativos-text">
                {mensajes[tipo === "retiro-apv" ? 0 : tipo === "tfe" ? 1 : 2]}
              </div>
            </Box>
          )}
        </div>
      )}
    </>
  );

  const sheetState = useOverlayTriggerState({});
  const openButtonRef = React.useRef(null);
  const openButton = useButton({ onPress: sheetState.open }, openButtonRef);

  const closeButtonRef = React.useRef(null);
  const closeButton = useButton(
    { onPress: sheetState.close, "aria-label": "Close sheet" },
    closeButtonRef
  );

  return (
    <>
      <BoxDesktop>
        <div
          style={{
            position: "relative",
            width: "100%",
            top: "-45px",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              left: "calc(100% - 36px)",
              top: "25px",
            }}
          >
            <img src={IC_ICON_PLAZO_NORMATIVO} alt="normativo" />
          </div>
          <div
            style={{
              padding: "24px",
              background: "#F2FAFF",
              border: "1px solid #B7E3FF",
              borderRadius: "16px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#2B2B2B",
              }}
            >
              {tipo === "retiros-extraordinarios"
                ? "Consideraciones"
                : "Plazos Normativos"}
            </p>

            {mensajesAll}
          </div>
        </div>
      </BoxDesktop>

      <BoxMobile
        style={{
          padding: "0rem 0rem 24px 0rem",
        }}
      >
        <div className="plazosNormativosHeader_mobile">
          <div className="plazosNormativosHeader_mobile_title">
            <img
              src={IC_INFO_RETIRO_EXTRAORDINARIO}
              width={25}
              height={25}
              alt="retiro-extraordinario-info"
            />
            <div
              {...openButton.buttonProps}
              ref={openButtonRef}
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "0.2px",
              }}
            >
              Ver{" "}
              {tipo === "retiros-extraordinarios"
                ? "las consideraciones"
                : "los plazos normativos"}
            </div>
          </div>
          <img src={IC_PLAZO_NORMATIVO} alt="normativo" />
          <Sheet isOpen={sheetState.isOpen} onClose={sheetState.close}>
            <OverlayProvider>
              <FocusScope contain autoFocus restoreFocus>
                <ContentModal
                  sheetState={sheetState}
                  content={
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1rem 0rem",
                        }}
                      >
                        <div></div>
                        <div>
                          {tipo === "retiros-extraordinarios"
                            ? "Consideraciones"
                            : "Plazos Normativos"}
                        </div>
                        <div
                          {...closeButton.buttonProps}
                          style={{
                            paddingRight: "10px",
                          }}
                        >
                          <CloseIcon />{" "}
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "0rem 0rem 1rem 0rem",
                        }}
                      >
                        {mensajesAll}
                      </div>
                    </>
                  }
                />
              </FocusScope>
            </OverlayProvider>
          </Sheet>
        </div>
      </BoxMobile>
    </>
  );
};

const AccordionStyle = styled(Accordion)`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  :before {
    background: #ffffff;
  }
`;

const ArmadaContent = ({
  monto,
  banco,
  date_fecha_pago,
  fecha_pago,
  tipo_abono,
  index,
}) => {
  return (
    <AccordionStyle
      style={{
        padding: "0",
        borderRadius: "16px",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <p
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            color: "#000000",
            marginBottom: "0px",
          }}
        >
          Armada {index + 1}
        </p>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "0",
        }}
      >
        {banco && (
          <CardSimple
            title="Entidad financiera"
            colorBackground="#ffffff"
            colorBorder="#14141426"
            detail={banco}
            imagen={IC_BANK}
          />
        )}

        {tipo_abono && (
          <CardSimple
            title="Tipo de abono"
            colorBackground="#ffffff"
            colorBorder="#14141426"
            detail={tipo_abono}
            imagen={IC_N_SOLICITUD}
          />
        )}

        {fecha_pago && (
          <CardSimple
            title="Fecha de pago"
            colorBackground="#ffffff"
            colorBorder="#14141426"
            detail={fecha_pago}
            imagen={IC_CALENDAR}
          />
        )}

        {monto && (
          <CardSimple
            title="Monto abonado"
            colorBackground="#ffffff"
            colorBorder="#14141426"
            detail={"S/" + monto}
            imagen={IC_COIN}
          />
        )}
      </AccordionDetails>
    </AccordionStyle>
  );
};
