export const montoFormat = (monto, simbolo = false) => {
  const redondeado = parseFloat(monto).toFixed(2);
  const formateado = redondeado
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const condicionado = simbolo
    ? `${simbolo ? "S/ " : null}${formateado}`
    : formateado;
  return condicionado;
};

export const montoFormatMiles = (
  floatValue = 0,
  decimals = 7,
  multiplier = 1
) => {
  let floatMultiplied = floatValue * multiplier;
  let stringFloat = floatMultiplied + "";
  let arraySplitFloat = stringFloat.split(".");
  let decimalsValue = "0";
  if (arraySplitFloat.length > 1) {
    decimalsValue = arraySplitFloat[1].slice(0, decimals);
  }
  let integerValue = arraySplitFloat[0];
  let arrayFullStringValue = [integerValue, decimalsValue];
  let FullStringValue = arrayFullStringValue.join(".");
  let floatFullValue = parseFloat(FullStringValue) + "";
  let formatFloatFullValue = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: decimals,
  }).format(floatFullValue);
  return formatFloatFullValue;
};
