import React, { useReducer } from "react";
import NewHomeContext from "./newHomeContext";
import NewHomeReducer from "./newHomeReducer";
import axios from "axios";
import {
  CALCULAR_ESTIMACION_PENSION_LOADING,
  CALCULAR_ESTIMACION_PENSION_EXISOTSO,
  CALCULAR_ESTIMACION_PENSION_ERROR,
  CALCULAR_ESTIMACION_PENSION_CLEANER,
  CALCULAR_ESTIMACION_PENSION_RANGO_LOADING,
  CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO,
  CALCULAR_ESTIMACION_PENSION_RANGO_ERROR,
  CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER,
  OBTENER_HASH_LOADING,
  OBTENER_HASH_EXITOSO,
  OBTENER_HASH_ERROR,
  OBTENER_HASH_CLENEAR,
} from "../../types/newHome";

const MisDatosState = (props) => {
  const initialState = {
    pensionCalculateData: null,
    pensionCalculateLoading: false,

    pensionCalculateRangeData: null,
    pensionCalculateRangeLoading: false,

    hashData: null,
    hashLoading: false,

    mensajeAlerta: null,
  };

  const [state, dispatch] = useReducer(NewHomeReducer, initialState);

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response) {
        if (
          error.response.status === 500 ||
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.message === "Unauthenticated."
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        } else {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: error.response.data.message,
            },
          });
        }
      }
    }
  };

  const obtenerEstimasionPension = async (datos) => {
    dispatch({
      type: CALCULAR_ESTIMACION_PENSION_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const hashRequest = await axios.get(
        `https://www.afphabitat.com.pe/privado/hash`
      );
      datos.set("csrf_habitat", hashRequest.data.hash);

      const respuesta = await axios.post(
        `https://www.afphabitat.com.pe/privado/cotizador/resultados`,
        datos
      );

      // const response = {
      //     csrf_habitat: getHash(),
      //     penDesea: dato2.penDesea,
      //     idResumen: respuesta.idResumen,
      //     CRUJsoles1: respuesta.CRUJsoles1,
      //     CP: "CRUJsoles1",
      //     factorN1: respuesta.factorN1,
      //     factorN2: respuesta.factorN2,
      //     tasaafp: respuesta.tasaafp,
      // };
      // const respuesta2 = await clienteAxios.post("/", response);

      // const obj = {
      //     pension: respuesta,
      // aporte: respuesta2,}
      // };
      dispatch({
        type: CALCULAR_ESTIMACION_PENSION_EXISOTSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CALCULAR_ESTIMACION_PENSION_ERROR);
    }
  };

  const limpiarObtenerEstimasionPension = () => {
    dispatch({
      type: CALCULAR_ESTIMACION_PENSION_CLEANER,
    });
  };

  const obtenerHash = async () => {
    dispatch({
      type: OBTENER_HASH_LOADING,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta1 = await axios.get(
        `https://www.afphabitat.com.pe/privado/hash`
      );
      const respuesta2 = await axios.get(
        `https://www.afphabitat.com.pe/privado/hash`
      );

      dispatch({
        type: OBTENER_HASH_EXITOSO,
        payload: { hash1: respuesta1.data.hash, hash2: respuesta2.data.hash },
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_HASH_ERROR);
    }
  };
  const limpiarObtenerHash = () => {
    dispatch({
      type: OBTENER_HASH_CLENEAR,
    });
  };

  const obtenerEstimasionPensionRango = async (data1, data2) => {
    dispatch({
      type: CALCULAR_ESTIMACION_PENSION_RANGO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const hashRequest = await axios.get(
        `https://www.afphabitat.com.pe/privado/hash`
      );

      const formData = new FormData();
      formData.append("csrf_habitat", hashRequest.data.hash);
      formData.append("penDesea", data2.penDesea);
      formData.append("idResumen", data1.id_resumen);
      formData.append("CRUJsoles1", data1.CRUJsoles1);
      formData.append("CP", data1.CP);
      formData.append("factorN1", data1.N1);
      formData.append("factorN2", data1.N2);
      formData.append("tasaafp", data1.tasaafp);

      const respuesta = await axios.post(
        `https://www.afphabitat.com.pe/privado/cotizador/pension_deseada`,
        formData
      );

      dispatch({
        type: CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CALCULAR_ESTIMACION_PENSION_RANGO_ERROR);
    }
  };

  const limpiarObtenerEstimasionPensionRango = () => {
    dispatch({
      type: CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER,
    });
  };

  return (
    <NewHomeContext.Provider
      value={{
        pensionCalculateData: state.pensionCalculateData,
        pensionCalculateLoading: state.pensionCalculateLoading,
        pensionCalculateRangeData: state.pensionCalculateRangeData,
        pensionCalculateRangeLoading: state.pensionCalculateRangeLoading,
        mensajeAlerta: state.mensajeAlerta,
        hashData: state.hashData,
        hashLoading: state.hashLoading,
        obtenerHash,
        limpiarObtenerHash,
        obtenerEstimasionPension,
        limpiarObtenerEstimasionPension,
        obtenerEstimasionPensionRango,
        limpiarObtenerEstimasionPensionRango,
      }}
    >
      {props.children}
    </NewHomeContext.Provider>
  );
};

export default MisDatosState;
