import React from "react";

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="currentColor"
        d="M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.168 0 13 0zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S2 19.087 2 13 6.913 2 13 2zM9.22 7.78L7.78 9.22 11.564 13l-3.782 3.78 1.44 1.44L13 14.437l3.78 3.78 1.44-1.437L14.437 13l3.78-3.78-1.437-1.44L13 11.564 9.22 7.782V7.78z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
