import styled from "styled-components";

export const ContentWrapper = styled.div`
  p.description {
    margin-bottom: 16px;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      text-indent: -20px;
      margin-left: 20px;
      margin-bottom: 14px;
    }

    ul {
      text-indent: -20px;
      margin-left: 20px;
    }
  }
`;
