import React, { useReducer } from "react";
import ValoresCuotaContext from "./valoresCuotaContext";
import ValoresCuotaReducer from "./valoresCuotaReducer";

import clienteAxios from "../../config/axios";

import {
  VALORES_CUOTA_GET,
  VALORES_CUOTA_ERROR,
  VALORES_CUOTA_LOADING,
  VALORES_CUOTA_RESETEAR,
  VALORES_CUOTA_UTILS_GET,
  VALORES_CUOTA_VAL_12_GET,
} from "../../types";

const ValoresCuotaState = (props) => {
  const initialState = {
    datos: [], // puede llegar "" o un {}
    mensajeAlerta: null,
    loading: false,
    datos_12: [],
    utils: {},
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosValoresCuota = async (data) => {
    let obj = { year: data.año, month: data.mes };
    try {
      const respuesta = await clienteAxios.post("/valores-cuota/date", obj);
      dispatch({
        type: VALORES_CUOTA_GET,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, VALORES_CUOTA_ERROR);
    }
  };

  const obtenerDatosValoresCuotaVal12 = async () => {
    try {
      const respuesta = await clienteAxios.get("/valores-cuota/val12");
      dispatch({
        type: VALORES_CUOTA_VAL_12_GET,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, VALORES_CUOTA_ERROR);
    }
  };
  const obtenerDatosYears = async () => {
    dispatch({
      type: VALORES_CUOTA_LOADING,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/valores-cuota/utils");
      let data = [
        {
          months: respuesta.data.months,
          years: respuesta.data.years,
        },
      ];
      dispatch({
        type: VALORES_CUOTA_UTILS_GET,
        payload: data,
      });
    } catch (error) {
      handleErrorsLocal(error, VALORES_CUOTA_ERROR);
    }
  };
  const limpiarVariablesValoresCuota = () => {
    dispatch({
      type: VALORES_CUOTA_RESETEAR,
    });
  };

  const [state, dispatch] = useReducer(ValoresCuotaReducer, initialState);
  return (
    <ValoresCuotaContext.Provider
      value={{
        loading: state.loading,
        datos: state.datos,
        mensajeAlerta: state.mensajeAlerta,
        utils: state.utils,
        datos_12: state.datos_12,
        obtenerDatosValoresCuota,
        limpiarVariablesValoresCuota,
        obtenerDatosYears,
        obtenerDatosValoresCuotaVal12,
      }}
    >
      {props.children}
    </ValoresCuotaContext.Provider>
  );
};

export default ValoresCuotaState;
