import React, { useEffect } from "react";
import Formulario from "./Formulario";
import FormularioRatificaDatos from "./FormularioRatificaDatos";
import FormularioCorreo from "./FormularioCorreo";
import ModalMessage from "./messageModal/CustomModal";
import { useHistory } from "react-router-dom";
const ModalUpdateClave = (props) => {
  const { type, info, datos } = props;
  // console.log("datos", datos);
  const history = useHistory();
  let componente = "";

  useEffect(() => {
    if (type === "clave") {
      if (history.location.pathname !== "/mi-habitat-digital/afiliado/inicio") {
        history.push("/mi-habitat-digital/afiliado/inicio");
      }
    } else {
      if (info.cambiar === "1") {
        if (
          history.location.pathname !== "/mi-habitat-digital/afiliado/inicio"
        ) {
          history.push("/mi-habitat-digital/afiliado/inicio");
        }
      }
    }
  }, [history.location.pathname]);

  // const elementId = "protectedElement"; // ID del elemento que queremos proteger
  // const element = document.getElementById(elementId);
  // // const element2 = document.getElementById(elementId);
  // // console.log("element", element, banner, cambio_clave, valido_datos);
  // useEffect(() => {
  //   console.log("asdadsadsa");

  //   // if (!element) return;
  //   if (element) {
  //     const originalStyle = element.getAttribute("style") || "";

  //     const observer = new MutationObserver((mutations) => {
  //       mutations.forEach((mutation) => {
  //         if (
  //           mutation.type === "attributes" &&
  //           mutation.attributeName === "style"
  //         ) {
  //           console.log("Cambio de estilo detectado. Revirtiendo...");
  //           element.setAttribute("style", originalStyle);
  //         }
  //       });
  //     });

  //     observer.observe(element, { attributes: true });

  //     return () => {
  //       observer.disconnect();
  //     };
  //   }
  // }, []);

  if (type === "clave") {
    componente = <Formulario />;
  } else {
    if (info.cambiar === "1" && datos !== undefined) {
      if (
        datos?.r_datos?.r_celular?.replace(/\s/g, "") ||
        datos?.r_datos?.r_correo?.replace(/\s/g, "")
      ) {
        componente = <FormularioCorreo />;
      } else {
        componente = <ModalMessage showModal={true} history={props.history} />;
      }
    } else {
      componente = <FormularioRatificaDatos />;
    }
  }
  return componente;
};

export default ModalUpdateClave;
