import React from "react";
import {
  CheckboxComponent,
  CheckboxIndicator,
  CheckboxWrapper,
  LabelText,
} from "./styles";
import { BsCheck } from "react-icons/bs";
import { Checkbox as CheckboxMUI } from "@mui/material";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const Checkbox = ({
  children,
  name,
  value,
  required,
  validate,
  disabled,
  ...props
}) => {
  const { register, watch } = useFormContext();

  const isChecked = getIsChecked(watch(name), value);

  return (
    <CheckboxWrapper {...props}>
      <CheckboxMUI
        value={value}
        checked={isChecked}
        {...register(name, {
          required,
          validate: { ...validate },
        })}
        disabled={disabled}
      />
      <LabelText>{children}</LabelText>
    </CheckboxWrapper>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
};

Checkbox.defaultProps = {
  children: "Label",
  name: "checkbox-input",
  value: "checkbox-input",
  required: {
    value: true,
    message: "No puede continuar sin aceptar las condiciones.",
  },
  disabled: false,
};

const getIsChecked = (currentValue, value) => {
  if (typeof currentValue === "boolean" || typeof currentValue === "string") {
    if (currentValue === false) {
      return false;
    }

    return true;
  }

  if (currentValue?.includes(value)) {
    return true;
  }

  return false;
};
