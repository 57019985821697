import { useState, useEffect } from "react";

const useDecodedFiles = (fileList, multi = false) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    const file = fileList?.[0];

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const resultFile = reader.result;
        setFile(resultFile);
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    setFile(null);
  }, [fileList]);

  return file;
};

export default useDecodedFiles;
