import { Box } from "@mui/system";
import React, { useState } from "react";
import styled from "styled-components";

const DivSwitch = styled("div")`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  flex-grow: 1;
  padding: 16px;
  color: ${(props) => (props.active ? "#ffffff" : "#363A3E")};
  border-radius: 16px;
  background: ${(props) => (props.active ? "#CE1336" : "transparent")};
`;

export const SwicthButton = ({ onChange }) => {
  const [index, setIndex] = useState(0);

  const onChangeValue = (value) => {
    setIndex(value);
    onChange();
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid rgba(20, 20, 20, 0.15)",
          borderRadius: "16px",
          display: "flex",
        }}
      >
        <DivSwitch active={index == 0} onClick={() => onChangeValue(0)}>
          Soles
        </DivSwitch>
        <DivSwitch active={index == 1} onClick={() => onChangeValue(1)}>
          Cuotas
        </DivSwitch>
      </Box>
    </>
  );
};
