import React, { useState, useEffect } from "react";
import { Input, Collapse } from "reactstrap";
import ManoImg from "../../../../../../../assets/fond_obligatorio.png";
import MonedaImg from "../../../../../../../assets/aporte_voluntariocon_fin_previsional.png";
import Loading from "../../../../../../../components/Loading/index.jsx";
import { Button } from "../../../../../../../components";
import "./index.css";

export default function SaldoCuenta(props) {
  const { jaoProcessData, saldosRegister, setsaldosRegister, setStep } = props;

  const [saldosData, setSaldosData] = useState(null);

  const [checked, setChecked] = useState(saldosRegister.considerFreeFund);
  const [amount, setAmount] = useState(saldosRegister.amountAprilRemuneration);

  useEffect(() => {
    if (jaoProcessData) setSaldosData(jaoProcessData);
  }, [jaoProcessData]);

  const onContinue = () => {
    setsaldosRegister({
      considerFreeFund: checked === null ? false : checked,
      haveAprilRemuneration: saldosData.data.has_april_remuneration,
      amountAprilRemuneration: saldosData.data.has_april_remuneration
        ? null
        : amount,
    });
    setStep(3);
  };

  if (saldosData === null) return <Loading />;

  return (
    <div className="registro-jao">
      <h1>Registro de información</h1>

      <div className="content-render mb-4">
        <div className="saldos-cuentas-jao">
          <h6 className="mt-4">II. Saldos de tu cuenta:</h6>
          <p>
            {saldosData.data.funds.voluntary || saldosData.data.funds.free
              ? "Los fondos mostrados son a la fecha de hoy"
              : "El fondo mostrado es a la fecha de hoy"}
            , <strong>{saldosData.data.date}</strong>.
          </p>

          {/* <p>Actualmente cuentas con {saldosData.data.funds.voluntary || saldosData.data.funds.free ? 'los siguientes saldos' : 'el siguiente saldo'}.</p> */}

          <div className="fondos-cotent mt-5">
            <div className="cards-width mb-4">
              <div className="whitecard-block mb-4 fondo">
                <img src={MonedaImg} alt="mano_con_moneda" />
                <h4>Fondo Obligatorio</h4>
                <span className="green-light">
                  {saldosData.data.funds.obligatory.format}
                </span>
              </div>
            </div>

            {saldosData.data.funds.voluntary && (
              <div className="cards-width mb-4">
                <div className="whitecard-block mb-4 fondo">
                  <img src={ManoImg} alt="mano_azul" />
                  <h4>Fondo con Fin Previsional</h4>
                  <span className="blue-lightdark">
                    {saldosData.data.funds.voluntary.format}
                  </span>
                </div>
              </div>
            )}
          </div>

          {saldosData.data.funds.free && (
            <>
              <div className="check-container">
                <span className="oneChoice">
                  <input
                    type="radio"
                    value={0}
                    checked={checked}
                    className="radio-btn"
                    id="tfa_721"
                    name="checked"
                    onClick={(e) => setChecked(!checked)}
                  />
                  <label className="postRadioField" htmlFor="tfa_721">
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label className="postRadioField" htmlFor="tfa_721">
                  <span className="check-label">
                    <strong>
                      ¿Deseas incluir tus aportes realizados al Fondo Libre
                      Habitat para estimar tu pensión?
                    </strong>
                  </span>
                </label>
              </div>

              <Collapse isOpen={checked}>
                <div
                  className="fondos-cotent mt-5"
                  id="fondo-libre-haboitat-block"
                >
                  <div className="cards-width">
                    <div className="whitecard-block mb-3 fondo">
                      <img src={ManoImg} alt="mano_azul" />
                      <h4>Fondo Libre Habitat</h4>
                      <span className="blue-lightdark">
                        {saldosData.data.funds.free.format}
                      </span>
                    </div>
                  </div>
                </div>
              </Collapse>
            </>
          )}

          {(saldosData.data.funds.voluntary || saldosData.data.funds.free) && (
            <p className="little-p">
              Recuerda que en esta pre-evaluación se está considerando el 100%
              de tus aportes voluntarios, en la evaluación formal si tus aportes
              voluntarios con fin previsional o Fondo Libre Habitat fueron
              hechos hace 9 meses o más, serán considerados en su totalidad para
              el cálculo. En caso contrario, solo podrá ser considerado como
              máximo el equivalente al 20% de tu Fondo Obligatorio.
            </p>
          )}

          {!saldosData.data.has_april_remuneration && (
            <>
              <h6 className="mt-4">
                III. Remuneración Abril 2020:{" "}
                <span className="tooltip-custom" style={{ color: "#196691" }}>
                  <i className="fa fa-info-circle" id="TooltipExample"></i>
                  <span className="tooltiptext">
                    Esta información deberá coincidir con el monto que indica su
                    boleta de pago, la cual le será solicitada para su
                    evaluación.
                  </span>
                </span>
              </h6>
              <p>
                No contamos con tu remuneración de abril 2020 porque la
                percibiste estando en otra AFP. En el siguiente cuadro indícanos
                tu remuneración percibida en dicho periodo.
              </p>

              <div className="center-items">
                <div className="input-amount-container mt-2">
                  <div className="label-field">
                    <span>Monto</span>
                  </div>
                  <div className="input-field">
                    <Input
                      type="number"
                      name="amount"
                      value={amount}
                      id="amount"
                      placeholder="00.00"
                      onChange={(e) => {
                        if (!e.target.value) return;
                        let value = Number(e.target.value);
                        if (!isNaN(value) && value >= 0) {
                          setAmount(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="container-jao-buttons mt-5">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={() => setStep(1)}
            >
              Volver
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={() => onContinue()}
              disabled={
                !saldosData.data.has_april_remuneration && amount === ""
              }
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
