import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M16 29.333c7.363 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.334-13.333C8.636 2.667 2.666 8.637 2.666 16c0 7.364 5.97 13.333 13.333 13.333zM20 12l-8 8M12 12l8 8"
      ></path>
    </svg>
  );
}

export default Icon;
