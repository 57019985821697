import React, { useState, useContext, useEffect } from "react";
import {
  Header,
  Footer,
  Section1,
  Section2,
  Section3,
  Section4,
  Section6,
  Section7,
  Section8,
  ContacWidget,
} from "./components";
import AuthContext from "../../context/autenticacion/authContext";
import ModalLogin from "../../components/ModalLogin";

import hocWidthWindow from "../../HOC/HocVariables";
import "./index.css";
import TraspasoWidget from "./components/traspasoWidget";

const NewHome = (props) => {
  const [requestSended, setRequestSended] = useState(false);
  const [userInfoLogin, guardarUserInfoLogin] = useState(null);
  //Extraemos los valores del context
  const [hideSection, setHideSection] = useState(false);
  const authContext = useContext(AuthContext);
  const {
    mensaje,
    autenticado,
    loading,
    loginForce,
    iniciarSesion,
    cerrarSesion,
  } = authContext;

  useEffect(() => {
    if (autenticado) {
      sessionStorage.removeItem("token");
      cerrarSesion(true);
      props.limpiarVariablesContext();
      props.limpiarVariablesModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autenticado && requestSended) {
      props.history.push("/mi-habitat-digital/afiliado/inicio");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autenticado, requestSended]);

  const onSubmit = async (data, captcha) => {
    let bodyFormData = new FormData();
    bodyFormData.set("doc_type", data.documentType);
    bodyFormData.set("doc_num", data.documentNumber);
    bodyFormData.set("password", data.password);
    bodyFormData.set("g-recaptcha-response", captcha);
    if (data.firstAttempt === false) {
      bodyFormData.set("firstAttempt", data.firstAttempt);
    }

    // console.log(captcha,'EN LOGIN')
    setRequestSended(true);
    guardarUserInfoLogin(bodyFormData); // lo guardamos en caso usemos el modal para forzar el inicio de sesion
    let response = await iniciarSesion(bodyFormData); // invocamos al metodo de login
    return response;
  };

  return (
    <>
      <Header />
      <main className="new-home-main">
        <Section1
          onSubmit={onSubmit}
          loading={loading}
          error={mensaje}
          setHideSection={(e) => setHideSection(e)}
        />
        <Section2
          onSubmit={onSubmit}
          loading={loading}
          error={mensaje}
          hideSection={hideSection}
          setHideSection={(e) => setHideSection(e)}
        />
        <Section3 />
        <Section4 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Footer />
      </main>

      <ContacWidget />
      <TraspasoWidget />
      {/* <ModalLogin mensaje={mensaje} datos={userInfoLogin} /> */}
      {loginForce && <ModalLogin mensaje={mensaje} datos={userInfoLogin} />}
    </>
  );
};

export default hocWidthWindow(NewHome);
