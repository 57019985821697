import React, { useState, useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MisDatosContext from "../../../../../../context/misdatos/misdatosContext";
import Icon from "../../../../Components/Icon";
import Page from "../../../../Components/Page";
import Card from "../../../../Components/Card";
import Appbar from "../../../../Components/Appbar";
import Elderly from "../../../../../../assets/icons/elderly.svg";
import Flag from "../../../../../../assets/icons/flag.svg";
import Buttons from "../../../../../../components/Buttons";
import { ModalActualizarDatos, ModalNoDatos } from "../Modals";
import useWindowSize from "../../../../../../hooks/useWindowSize";

import { size } from "../../../../../../utils/device";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../../../Components/ButtonsComponents";
import {
  ContainerView,
  ContentTitle,
  Contents,
  BoxNumber,
  BoxText,
  BoxCard,
  BoxLine,
  BoxNumCircle,
  BoxTextFunds,
  BoxFund,
} from "./styles";

const ViewComponent = ({
  setShowStepComponent,
  getOutOfProcess,
  defaultStep,
  mensaje,
  showModal,
  setShowModal,
}) => {
  const [isOpenModalActualizarDatos, setIsOpenModalActualizarDatos] =
    useState(false);
  const [isOpenModalNoDatos, setIsOpenModalNoDatos] = useState(false);
  const { width } = useWindowSize();
  const isSmallScreen = width <= size.desktopS;
  const misDatosContext = useContext(MisDatosContext);

  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;

  useEffect(() => {
    obtenerMisDatosGet();
  }, []);

  const handleStart = () => {
    if (defaultStep === "calculo") {
      setShowStepComponent(true);
    } else {
      if (
        misDatosGet.r_persona.r_datos.r_celular === null ||
        misDatosGet.r_persona.r_datos.r_correo === null
      ) {
        setIsOpenModalNoDatos(true);
      } else {
        setIsOpenModalActualizarDatos(true);
      }
    }
  };
  const AppbarComponent = isSmallScreen ? true : false;
  if (misDatosGet === null) return <p></p>;

  return (
    <Page>
      {AppbarComponent && (
        <Appbar title="Pensión Objetivo" onHandleClick={getOutOfProcess} />
      )}
      <ContainerView>
        <ContentTitle>
          <Icon
            url={Flag}
            width={"48px"}
            height={"48px"}
            padding={"10px"}
            className="HBT_PMO_Header"
          />

          <Grid item xs={9}>
            <h4>Pensión Objetivo</h4>
          </Grid>
        </ContentTitle>
        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            className="HBT_JUBLEGAL_Divider"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Contents>
              <h4>Sobre esta modalidad</h4>
              <p>
                La Pensión Mínima Objetivo (PMO) te permite establecer una meta
                de pensión mayor o igual a la canasta básica de consumo
                determinada por el INEI.
              </p>
              <p>
                Cuando tu fondo alcance la meta establecida, seguirás realizando
                aportes con normalidad hasta jubilarte a los 65 años, donde
                podrás retirar el excedente (en caso de haberlo) para cualquiera
                de los siguientes fines:
              </p>
              <ul>
                <li>Usarlo para lo que desees.</li>
                <li>Invertirlo en Fondo Libre Habitat.</li>
              </ul>
            </Contents>

            {!isSmallScreen && (
              <div>
                {mensaje !== "" && (
                  <p className="HBT_PMO_ErrorMessage">{mensaje}</p>
                )}

                <ButtonsWrapper>
                  <PrevButton
                    variant="secondary"
                    isBtnSecondary
                    onClick={() => getOutOfProcess()}
                  >
                    Volver
                  </PrevButton>

                  <NextButton
                    onClick={() => handleStart()}
                    disabled={mensaje !== ""}
                  >
                    {defaultStep === "calculo" ? "Ver mi" : "Establecer"}{" "}
                    Pensión Objetivo
                  </NextButton>
                </ButtonsWrapper>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Contents>
              <h4>Pasos para acceder</h4>
              <Box style={{ overflow: "hidden" }}>
                <Card>
                  <BoxCard>
                    <BoxNumber>
                      <BoxNumCircle>1</BoxNumCircle>
                    </BoxNumber>
                    <BoxText>
                      Establece la pensión que deseas recibir cuando te jubiles
                      por edad legal.
                    </BoxText>
                  </BoxCard>
                </Card>
                <Card>
                  <BoxCard>
                    <BoxNumber>
                      <BoxNumCircle>2</BoxNumCircle>
                    </BoxNumber>
                    <BoxText>
                      Aporta regularmente a tu fondo hasta alcanzar el monto
                      requerido que pueda financiar tu pensión deseada.
                    </BoxText>
                  </BoxCard>
                </Card>
                <Card>
                  <BoxCard>
                    <BoxNumber>
                      <BoxNumCircle>3</BoxNumCircle>
                    </BoxNumber>
                    <BoxText>
                      Al cumplir 65 años, solicita tu jubilación Legal y
                      selecciona la modalidad de pensión: “Pensión Objetivo”.
                    </BoxText>
                  </BoxCard>
                </Card>
                <BoxTextFunds>
                  <BoxLine></BoxLine>
                  <BoxFund>
                    <Icon
                      url={Flag}
                      width={"32px"}
                      height={"32px"}
                      padding={"10px"}
                    />
                    <h4>Obtén tu pensión mensual</h4>
                  </BoxFund>
                </BoxTextFunds>
              </Box>
            </Contents>
          </Grid>
        </Grid>
        {isSmallScreen && (
          <Contents>
            {mensaje !== "" && (
              <p className="HBT_PMO_ErrorMessage">{mensaje}</p>
            )}
            <Buttons
              size={"lg"}
              fullWidth={true}
              onClick={() => handleStart()}
              disabled={mensaje !== ""}
            >
              {defaultStep === "calculo" ? "Ver mi" : "Establecer"} Pensión
              Objetivo
            </Buttons>
          </Contents>
        )}
      </ContainerView>
      <ModalActualizarDatos
        isOpen={isOpenModalActualizarDatos}
        setIsOpen={setIsOpenModalActualizarDatos}
        misDatosGet={misDatosGet}
        startProcess={() => setShowStepComponent(true)}
      />
      <ModalNoDatos
        isOpen={isOpenModalNoDatos}
        setIsOpen={setIsOpenModalNoDatos}
      />
    </Page>
  );
};

export default ViewComponent;
