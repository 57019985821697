import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { BreadCrumbsFL } from "../../PagosDesembolsos/componentes/data/components/BreadcrumbsFL";
import { CardDouble } from "./componentes/CardDouble";
import { CardSimple } from "./componentes/CardSimple";
import { ItemState } from "./componentes/ItemState";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Redirect, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import SeguimientoAportesContext from "../../../context/seguimientoAportes/seguimientoAportesContext";

import moment from "moment";
import styled from "styled-components";
import IC_CALENDAR from "./../../../assets/icons/ic_calendar.svg";
import MENU_MOBILE from "./../../../assets/icons/menu_mobile_elipse_2.png";
import IC_N_SOLICITUD from "./../../../assets/icons/icon_n_solicitud.svg";

import IC_BANK from "./../../../assets/icons/ic_bank.svg";

const DivNext = styled("div")`
  width: 20px;
  height: 100%;
  position: absolute;
  left: calc(100% - 20px);
  background: #ce1336;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  align-self: flex-end;
  display: flex;
  @media (min-width: 990px) {
    display: none;
  }
`;

const styleDetail = {
  padding: "0 0.12rem 0.12rem 0.12rem",
  color: "#6E7378",
  fontSize: "12px",
};

const styleTitle = {
  padding: "0 0.12rem 0.12rem 0.12rem",
  color: "#2B2B2B",
  fontWeight: "400",
  fontSize: "14px",
  margin: "auto !important",
};

export const PagoDetalleSeguimiento = () => {
  const [isMore, setIsMore] = useState(false);
  const [detail, setDetail] = useState([]);
  const { nro, tipo } = useParams();

  const seguimientoAportesContext = useContext(SeguimientoAportesContext);
  const {
    seguimientoAportes,
    mensajeAlerta,
    loadingPage,
    obtenerSeguimientosAportes,
    limpiarSeguimientosAportes,
  } = seguimientoAportesContext;

  const {
    r_estado,
    subtitle,
    r_solicitud,
    r_fecha_solicitud,
    r_fecha_banco,
    r_fecha_acreditacion,

    r_banco,
  } = detail ?? {};

  const estado =
    r_estado === "RECHAZADO"
      ? "RECHAZADA"
      : r_estado === "PROCESADA"
      ? "ACREDITADO"
      : "EN CURSO";

  useEffect(() => {
    obtenerSeguimientosAportes();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (seguimientoAportes) {
      const filterAporte = seguimientoAportes.filter(
        (item) => item.r_solicitud === nro
      );

      setDetail(filterAporte[0]);
    }

    return () => {};
  }, [seguimientoAportes]);

  const onClickNext = () => {
    setIsMore(!isMore);
  };

  if (loadingPage) return <Loading />;

  return (
    <>
      <BreadCrumbsFL
        title_mobile={"Retiro 25% para vivienda"}
        title={"Solicitud N.º " + r_solicitud}
        rutas={[
          {
            id: 1,
            title: "Trámites",
            ruta: "#/mi-habitat-digital/afiliado/seguimiento/apv",
          },

          {
            id: 2,
            title: "Solicitud N.º " + r_solicitud,
            ruta: "",
          },
        ]}
      />

      <Grid
        container
        sx={{
          paddingTop: { xs: "65px", md: "96px" },
          background: { xs: "#ffffff", md: "#f8f9fb" },
          paddingBottom: { xs: "60px", md: "0px" },
          minHeight: { xs: "78vh", md: "auto" },
        }}
      >
        <Grid item xs={12} lg={12} p={2}>
          <Box
            sx={{
              border: {
                xs: "1px solid rgba(20, 20, 20, 0.15)",
                md: "1px solid rgba(20, 20, 20, 0.12)",
              },
              boxShadow: { xs: "0", md: "0px -4px 16px rgba(0, 0, 0, 0.12)" },
              borderRadius: "16px",
              background: "#fff",
              display: { xs: "flex", md: "block" },
              flexDirection: "row",
              position: "relative",
            }}
          >
            <Container
              sx={{
                paddingBottom: "0.5rem",
              }}
            >
              <Grid
                container
                className="grid_container_mobile"
                sx={{
                  minHeight: { xs: "65vh", md: "100%" },
                }}
              >
                <Grid
                  item
                  xs={11}
                  md={5}
                  sx={{
                    display: !isMore ? "block" : "none",
                  }}
                  className="animate__animated animate__fadeInLeft animate__faster"
                >
                  <Box
                    sx={{
                      padding: "1rem 0",
                    }}
                  >
                    <ItemState {...detail} estado={estado} />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "1rem",
                      paddingBottom: "1.5rem",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "1px",
                        background: "#EDEDED",
                        position: "relative",
                        border: "1.5px solid #EDEDED",
                      }}
                    ></div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={11}
                  md={6}
                  sx={{
                    display: { xs: !isMore ? "none" : "block", md: "block" },
                  }}
                  className="animate__animated animate__fadeInRight animate__faster"
                >
                  <Box
                    sx={{
                      padding: "1.5rem 1rem 1rem 1rem",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400, regular",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.2px",
                      color: "#101010",
                    }}
                  >
                    Datos del trámite
                  </Box>

                  {r_solicitud && (
                    <CardSimple
                      title="N.º de solicitud"
                      detail={r_solicitud}
                      imagen={IC_N_SOLICITUD}
                    />
                  )}
                  {r_fecha_solicitud && (
                    <CardSimple
                      title="Fecha de solicitud"
                      detail={r_fecha_solicitud}
                      imagen={IC_CALENDAR}
                    />
                  )}

                  {
                    <>
                      <Box
                        sx={{
                          padding: "1rem",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400, regular",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.2px",
                          color: "#101010",
                        }}
                      >
                        Datos del banco de abono
                      </Box>

                      <div
                        style={{
                          padding: "0.25rem",
                        }}
                      >
                        {" "}
                      </div>

                      <div
                        style={{
                          padding: "0.25rem",
                        }}
                      >
                        {" "}
                      </div>
                      <CardSimple
                        title="Entidad financiera"
                        detail={r_banco}
                        imagen={IC_BANK}
                      />
                    </>
                  }
                </Grid>
              </Grid>
            </Container>

            <DivNext>
              <div
                style={{
                  position: "absolute",
                  top: "calc(50% - 40px)",
                  right: "0.4rem",
                  height: "150px",
                  padding: "0.75rem",
                }}
                onClick={onClickNext}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "calc(50% - 94px)",
                    right: "10px",
                    zIndex: 0,
                  }}
                >
                  <img src={MENU_MOBILE} height={"150px"} alt="menu-mobile" />
                </div>
                <div
                  style={{
                    padding: "0.15rem",
                    borderRadius: "50%",
                    position: "absolute",
                    right: "2px",
                    top: "calc(50% - 36px)",
                    zIndex: 3,
                    border: "1px solid #FFFFFF",
                  }}
                >
                  {/* {(!isMore) ? <KeyboardDoubleArrowLeftRoundedIcon sx={{ color: '#FFFFFF' }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: '#FFFFFF' }} />} */}
                  <KeyboardDoubleArrowRightIcon sx={{ color: "#FFFFFF" }} />
                </div>
              </div>
            </DivNext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
