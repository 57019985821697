import { Modal } from "@mui/material";
import styled from "styled-components";

export const ModalNumeroDeSolicitudComponent = styled(Modal)`
  .MuiBackdrop-root {
    background-color: ${(p) => p.theme.palette.transparencies.dark};
  }
`;

export const ModalNumeroDesolicitudWrapper = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  outline: none;
`;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 8px;
  text-align: center;
`;

export const Subtitle = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  text-align: center;

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;
