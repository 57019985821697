import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import FormClave from "./components/Forms/FormClave";
import ModalConfirmar from "./components/Modal/Confirmar";
// import ModalInfo from "../CambioFondo/ModalInfo";
import ModalInstrucciones from "./components/Modal/Instrucciones";
import GenerarClaveContext from "../../context/generarClave/generarClaveContext";

const Step02 = (props) => {
  const { bloquear } = props;
  const generarClaveContexto = useContext(GenerarClaveContext);
  const { bloquearPasos, gUsuarioExitoso } = generarClaveContexto;
  const [openModal, setOpenModal] = useState(false);
  const [openModalI, setOpenModalI] = useState(true);

  const openModalConfirm = () => {
    setOpenModal(true);
  };
  const openModalInfo = () => {
    setOpenModalI(true);
  };

  const closeOpenModalInfo = () => {
    setOpenModalI(false);
  };

  const bloquearP = () => {
    bloquear();
    bloquearPasos(true);
  };

  return (
    <div>
      <div>
        <p>
          Hola <b>{gUsuarioExitoso ? gUsuarioExitoso.name : ""}</b>, aqui podrás
          generar una nueva clave web para ingresar a
          <b> Mi Habitat Digital. </b>
        </p>
      </div>
      <div className="privatezone-mobile">
        <FormClave
          openModalConfirm={openModalConfirm}
          openModalInfo={openModalInfo}
          bloquear={bloquearP}
        >
          {" "}
        </FormClave>
      </div>
      <ModalConfirmar openModal={openModal}></ModalConfirmar>
      <ModalInstrucciones
        openModal={openModalI}
        close={closeOpenModalInfo}
      ></ModalInstrucciones>
    </div>
  );
};

export default Step02;
