import React from 'react'

const GraficOneActiveIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7601 13.1384L24.9203 27.4973C29.2957 22.0818 35.9905 18.6178 43.4984 18.6178V0C31.4079 0 20.4984 5.04416 12.7601 13.1384Z"
        fill="#CDCED0"
      />
      <path
        d="M0.998352 42.4581H19.6346C19.6346 36.7912 21.6152 31.5884 24.92 27.4978L12.7597 13.1389C5.47252 20.7613 0.998352 31.0875 0.998352 42.4581Z"
        fill="#CE1336"
      />
      <path
        d="M43.4984 0V18.6178C50.9695 18.6178 57.6391 22.0489 62.0146 27.4199L74.169 13.0688C66.4365 5.01515 55.554 0 43.4984 0Z"
        fill="#CDCED0"
      />
      <path
        d="M85.9975 42.4572C85.9975 31.0517 81.4943 20.6965 74.1684 13.0684L62.014 27.4195C65.3556 31.5217 67.3613 36.7554 67.3613 42.4572H85.9975Z"
        fill="#CDCED0"
      />
      <path
        d="M41.4447 37.8703L38.713 36.8995C37.6903 36.5361 36.7003 37.5239 37.066 38.5442L38.167 41.6175C38.0563 41.9551 37.9713 42.3043 37.9175 42.6678C37.4033 46.1398 39.807 49.365 43.285 49.8696C46.763 50.3743 50.0007 47.9677 50.5149 44.4956C51.0291 41.0236 48.6254 37.7984 45.1474 37.2937C43.8329 37.103 42.5547 37.3279 41.4431 37.8681L41.4447 37.8703Z"
        fill="#CDCED0"
      />
    </svg>
  );
};

export default GraficOneActiveIcon;