import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { device } from "../../utils/device";

export const HeaderComponent = styled.div`
  grid-area: header;
  padding: 0 16px;
  background-color: ${(p) => p.theme.palette.lead[100]};
  min-height: 40px;
  display: grid;
  align-items: center;

  ${css`
    @media screen and ${device.desktopS} {
      padding: 16px 0;
      position: sticky;
      top: 0;
      z-index: 1000;
    }
  `}
`;

export const WelcomeMessage = styled.h4`
  ${(p) => p.theme.tipography.h4}
  font-weight: 400;

  ${css`
    @media screen and ${device.desktopS} {
      padding: 20px 24px;
      background-color: ${(p) => p.theme.palette.white};
      border-radius: 16px;
    }
  `}
`;

export const AppBarComponent = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-areas: "back location close";
  column-gap: 8px;
  align-items: center;
  justify-items: center;
  color: ${(p) => p.theme.palette.primary.main};
  ${(p) => p.theme.tipography.headingSubtitleSemi}
`;

export const IconButton = styled.div`
  width: 40px;
  height: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Location = styled.span`
  grid-area: location;
`;
export const GoBackButton = styled(IconButton)`
  grid-area: back;
`;
export const CloseButton = styled(IconButton)`
  grid-area: close;
`;

export const HeaderBarComponent = styled.div`
  padding: 24px;
  background-color: ${(p) => p.theme.palette.white};
  border-radius: 16px;
`;

export const BreadcumbItem = styled(Link)`
  && {
    text-decoration: none !important;
    color: ${(p) => p.theme.palette.grayscale[400]};
    ${(p) => p.theme.tipography.bodySmallRegular}

    ${(p) =>
      p.current &&
      css`
        color: ${(p) => p.theme.palette.primary.main};
      `}

  &:hover {
      text-decoration: none !important;
    }
  }
`;
