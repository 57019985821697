import React from "react";
import { AsesoriaTitle, Description, QueEsComponent, Title } from "./styles";

const Header = () => {
  return (
    <div>
      <AsesoriaTitle>Asesoría: Gastos de Sepelio</AsesoriaTitle>
      <QueEsComponent>
        <Title>¿Qué es?</Title>
        <Description>
          Es un beneficio del SPP que le da a los beneficiarios o terceros el
          derecho a solicitar el dinero para los gastos de sepelio, en caso el
          afiliado fallezca.
        </Description>
      </QueEsComponent>
    </div>
  );
};

export default Header;
