import styled, { css } from "styled-components";
import ReactSelect from "react-select";

export const SeleccionaCuentaBancariaComponent = styled.div`
  color: ${(p) => p.theme.palette.grayscale[800]};
`;

export const BankAccounts = styled.div`
  p {
    /* margin: 0; */
    margin-bottom: 0;
  }

  display: grid;
  ${(p) =>
    p.numeroDeComponentes <= 1 &&
    css`
      grid-template-columns: repeat(auto-fit, clamp(250px, 40%, 300px));

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
      }
    `}

  ${(p) =>
    p.numeroDeComponentes > 1 &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    `}

  grid-auto-rows: 1fr;
  gap: 16px;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const CardComponent = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: 1fr auto;
  border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
  border-radius: 24px;
  background-color: ${(p) => p.theme.palette.white};
  cursor: pointer;
  text-align: left;
  font-family: "Roboto";
  box-shadow: ${(p) => p.theme.elevations.below};

  &[data-isactive="true"] {
    border-color: ${(p) => p.theme.palette.primary.main};
    .radioButton {
      border-color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.primary.main};
      color: white;

      svg {
        display: inline-block;

        path {
          color: white;
        }
      }
    }
  }

  .content {
    padding: 8px 16px;
    justify-self: start;
    display: grid;
    font-size: 14px;
    color: ${(p) => p.theme.palette.grayscale[500]};

    .logo {
      justify-self: start;
      max-width: 100%;
      margin-bottom: 32px;

      svg {
        height: 32px;
      }
    }
  }

  .radioButton {
    width: 24px;
    height: 24px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
    border-radius: 100px;

    svg {
      display: none;
      position: relative;
      width: 12px;
      height: 12px;
      top: 1px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.palette.grayscale[50]};
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const NewCardComponent = styled.div`
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  padding: 20px;
  border: 1px dashed ${(p) => p.theme.palette.grayscale["200"]};
  border-radius: 24px;
  justify-items: center;
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.white};

  &[data-isactive="true"] {
    border-color: ${(p) => p.theme.palette.primary.main};
    border-style: solid;
    * {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }

  .icon {
    display: grid;
    justify-content: center;
    align-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const NewCardInformationComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

export const YearMonthWrapper = styled(InputWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  margin-bottom: 0px;
`;

export const ErrorMessage = styled.p`
  position: absolute;
  width: 100%;
  top: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.palette.state.error};
  margin-bottom: 0;
`;

export const YearMonthErrorMessage = styled(ErrorMessage)`
  top: auto;
  bottom: 0;
`;

export const Select = styled(ReactSelect)`
  .select__control {
    background: red;
    background-color: ${(p) => p.theme.palette.grayscale[50]};
    border-radius: 0;
    padding: 10px 16px;
    border: none;
    border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
    outline: none;
    box-shadow: none;
    border-radius: 16px;

    &:hover {
      border-color: ${(p) => p.theme.palette.grayscale[800]};
    }
  }

  .select__indicators {
    display: none;
  }

  .select__value-container {
    margin: 0;
    padding: 0;
  }

  .select__menu {
    border-radius: 16px;
    overflow: hidden;
  }
  .select__menu-list {
    padding: 0;
  }

  .select__option {
    background-color: ${(p) => p.theme.palette.white};

    ${(p) => p.theme.tipography.bodySmallRegular}
    &:hover {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
      color: ${(p) => p.theme.palette.grayscale[800]};
    }

    &.select__option--is-selected {
      color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.primary[50]};
    }
  }
`;

export const BankSelect = styled(Select)``;

export const AccountTypeSelect = styled(Select)``;

export const ExpirationYearSelect = styled(Select)``;

export const ExpirationMonthSelect = styled(Select)``;

export const AccountNumberInput = styled.input`
  padding: 10px 16px;
  border-radius: 16px;
  ${(p) => p.theme.tipography.bodyMediumRegular};
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  color: ${(p) => p.theme.palette.textColor.main};
  border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
  outline: none;
  width: 100%;

  &:focus {
    border-color: ${(p) => p.theme.palette.grayscale[700]};
  }

  &:disabled {
    color: ${(p) => p.theme.palette.grayscale[200]};
  }

  &.error {
    border-color: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
  }

  ${(p) =>
    p.size === "sm" &&
    css`
      padding: 8px 16px;
      ${(p) => p.theme.tipography.caption};
    `}

  ${(p) =>
    p.size === "lg" &&
    css`
      padding: 14px 16px;
      ${(p) => p.theme.tipography.bodyMediumRegular};
    `}


  &::placeholder {
    color: ${(p) => p.theme.palette.grayscale[400]};
  }
`;

export const SectionTitleComponent = styled.p`
  ${(p) => p.theme.tipography.bodySmallMedium}
  grid-column: 1/-1;
  margin-bottom: 8px;

  span {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const DeleteAccountMessage = styled.div`
  grid-column: 1/-1;

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline !important;
  }
`;
