import React from "react";
import "./customModal.css";
import "./beautyModal.css";

import { Alert, Box, Dialog, Divider, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";
import { FormGroup, Input, Label } from "reactstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      maxWidth: "375px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const styles = {
  titleModal: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeigt: "28px",
    textAlign: "center",
  },
  ContentModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
  },
  ContentAdviceModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    color: "black",
  },
};

const CustomModal = (props) => {
  const {
    showModal,
    fnCloseIconBtn,
    title,
    fnBtnPrimary = false,
    fnBtnSecondary = false,
    onClosed,
    textBtnPrimary,
    textBtnSecondary,
    isCorrectlyUpdated = false,
    formulario,
    fnBtnContinuar = false,
  } = props;

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!isCorrectlyUpdated ? (
              <IconButton
                className={classes.buttonUpCloseStyles}
                onClick={fnCloseIconBtn}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <div style={{ height: "16px" }}></div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.titleModal}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px" }}>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {!isCorrectlyUpdated && (
          <Typography sx={styles.ContentModal}>
            Estás a punto de cambiar los siguientes datos, si estás de acuerdo
            con esto da clic en{" "}
            <span style={{ fontWeight: "700" }}>Aceptar.</span>
          </Typography>
        )}
        <Box sx={{ margin: "16px 0" }}>
          <FormGroup>
            <Label htmlFor="l_celular" className="label_title">
              Celular
            </Label>
            <Input
              placeholder="Ingresa tu celular"
              name="l_celular"
              value={formulario.l_celular}
              disabled
              bsSize="lg"
              style={{ marginBottom: "7px" }}
              // className={errores.status_celular ? "error_txt" : normalTxt}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="l_correo" className="label_title">
              Correo electrónico
            </Label>
            <Input
              placeholder="Ingresa tu correo electrónico"
              name="l_correo"
              value={formulario.l_correo}
              style={{ marginBottom: "7px" }}
              disabled
              // className={errores.status_correo ? "error_txt" : normalTxtCorreo}
              bsSize="lg"
            />
          </FormGroup>
        </Box>
        {isCorrectlyUpdated && (
          <Alert
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
          >
            Tus datos fueron actualizados correctamente.
          </Alert>
        )}
        <Divider sx={{ marginTop: "16px" }} />

        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ p: 1, justifyContent: "space-evenly" }}>
          {fnBtnSecondary && !isCorrectlyUpdated && (
            <Grid item xs={5}>
              <Button
                variant="outlined"
                className={classes.buttonsDownStyles}
                onClick={fnBtnSecondary}
              >
                {textBtnSecondary}
              </Button>
            </Grid>
          )}

          {fnBtnPrimary && !isCorrectlyUpdated && (
            <Grid item xs={5}>
              <Button
                variant="contained"
                className={classes.buttonsDownStyles}
                onClick={fnBtnPrimary}
                autoFocus
              >
                {textBtnPrimary}
              </Button>
            </Grid>
          )}

          {isCorrectlyUpdated && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.buttonsDownStyles}
                onClick={fnBtnContinuar}
                autoFocus
              >
                {"Continuar"}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
