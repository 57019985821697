import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import { Button } from "reactstrap";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function Paso1(props) {
  const [check1, setCheck1] = useState(null);
  const [check2, setCheck2] = useState(null);
  const [check3, setCheck3] = useState(null);
  const [sendRequest, setSendRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarPaso3Response,
    guardarPaso3ResponseLoading,
    crearProcesoRejaEtapa3,
    limpiarCrearProcesoRejaEtapa3,
  } = jubilacionContext;

  useEffect(() => {
    return () => {
      limpiarCrearProcesoRejaEtapa3();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guardarPaso3Response) {
      if (guardarPaso3Response.success) {
        if (sendRequest) props.endThirdStep();
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarPaso3Response]);

  const handleSaveCheck = () => {
    setSendRequest(true);
    const obj = {
      content_id: props.idContent,
      response: {
        affidavit: check3,
      },
    };

    crearProcesoRejaEtapa3(props.idProcess, obj);
  };

  const changeIconForLoading = () => {
    if (guardarPaso3ResponseLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };
  return (
    <div>
      <div className="reja-declaracion">
        <h1 className="mb-2">DJ de No derecho a Bono</h1>
        <p id="texto-frase">
          A continuación, le solicitamos revisar las siguientes declaraciones
          juradas.
        </p>
        <br />
        <div className="content-box">
          <h3>
            Legajo Puntual Sección I - Declaración Jurada de Derecho a Bono de
            Reconocimiento
          </h3>
          <br />
          <h3>I. Identificación del Declarante</h3>
          <br />
          <div className="row-declaracion">
            <div className=" center-tems">
              <div className="first-column table-header center-tems">
                <span>Identificación</span>
              </div>
              <div className="second-column table-header center-tems">
                <span>{props.dependencies.type_and_number_of_document}</span>
              </div>
            </div>
            <div className="center-tems">
              <div className="first-column table-header center-tems">
                <span>tipo de pensión</span>
              </div>
              <div className="second-column table-header center-tems">
                <span>Jubilación y/o retiro</span>
              </div>
            </div>
            <div className="center-tems">
              <div className="first-column table-header center-tems">
                <span>Apellido paterno, materno y nombres del afiliado</span>
              </div>
              <div className="second-column table-header center-tems">
                <span>{props.dependencies.full_name}</span>
              </div>
            </div>
            <div className="center-tems">
              <div className="first-column table-header center-tems">
                <span>CUSPP</span>
              </div>
              <div className="second-column table-header center-tems">
                <span>{props.dependencies.cuspp}</span>
              </div>
            </div>
          </div>
          <br />

          <h3>II. Declaración Jurada </h3>

          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  checked={check1}
                  className="radio-btn"
                  id="tfa_711"
                  name="beneficiarios"
                  onClick={() => setCheck1(!check1)}
                />
                <label className="postRadioField" id="tfa_711" for="tfa_711">
                  <span className="input-radio-faux"></span>
                  <div>
                    <p className="check">
                      Declaro bajo juramento que no tengo derecho a Bono de
                      Reconocimiento, debido a que no cumplo con haber aportado
                      a alguno de los sistemas de pensiones administrados por la
                      ONP, un mínimo de 48 meses dentro de:
                    </p>
                    <p id="texto-declaracion" className="check">
                      Los 10 años previos al 6 de diciembre de 1992, es decir,
                      entre el 6 de diciembre de 1982 y el 5 de diciembre de
                      1992. (Bono 1992)
                    </p>
                    <p id="texto-declaracion" className="check">
                      Los 10 años anteriores al 1 de enero de 1997, es decir,
                      entre el 1 de enero de 1987 y el 31 de diciembre de 1996.
                      (Bono 1996)
                    </p>
                    <p id="texto-declaracion">
                      Los 10 años anteriores al 1 de enero de 2002, es decir,
                      entre el 1 de enero de 1992 y el 31 de diciembre de 2001.
                      (Bono 2001)
                    </p>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={check2}
                  className="radio-btn"
                  id="tfa_730"
                  name="juramento1"
                  onClick={() => setCheck2(!check2)}
                />
                <label className="postRadioField" id="tfa_730" for="tfa_730">
                  <span className="input-radio-faux"></span>
                  <p>
                    {" "}
                    Asimismo, declaro bajo juramento que no he realizado aportes
                    al Sistema Nacional de Pensiones dentro de los últimos 120
                    meses anteriores a la fecha de mi Sección I.
                  </p>
                </label>
              </span>
            </div>
          </div>
          <p id="texto-declaracion">
            Por lo antes expuesto libero a AFP Habitat de la responsabilidad de
            cualquier reclamo posterior.
          </p>

          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={check3}
                  className="radio-btn"
                  id="tfa_731"
                  name="juramento2"
                  onClick={() => setCheck3(!check3)}
                />
                <label className="postRadioField" id="tfa_731" for="tfa_731">
                  <span className="input-radio-faux"></span>
                  <p>
                    Afirmo y ratifico que todo lo mencionado en esta declaración
                    jurada es válida y por lo tanto asumo las responsabilidades
                    que este documento implica.
                  </p>
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className="container-reja-buttons">
          <Button
            className={`mb-4 btn-modal-secondary`}
            onClick={() => props.getOutOfProcess()}
          >
            Cerrar
          </Button>
          <Button
            className={`mb-4 btn-modal-primary`}
            onClick={() => handleSaveCheck()}
            disabled={!check1 || !check3}
          >
            Continuar {changeIconForLoading()}
          </Button>
        </div>
      </div>
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
