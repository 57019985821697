import React from 'react'

const GraficThreeActiveIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 85 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7617 13.1806L23.9219 27.5855C28.2974 22.1526 34.9922 18.6775 42.5001 18.6775V0C30.4096 0 19.5 5.06036 11.7617 13.1806Z"
        fill="#CE1336"
      />
      <path
        d="M0 42.594H18.6362C18.6362 36.9088 20.6168 31.6894 23.9216 27.5856L11.7614 13.1807C4.47417 20.8275 0 31.1868 0 42.594Z"
        fill="#CE1336"
      />
      <path
        d="M42.5 0V18.6775C49.9711 18.6775 56.6408 22.1197 61.0162 27.5079L73.1706 13.1107C65.4381 5.03125 54.5557 0 42.5 0Z"
        fill="#CE1336"
      />
      <path
        d="M84.9992 42.5935C84.9992 31.1515 80.496 20.763 73.17 13.1104L61.0156 27.5075C64.3572 31.623 66.3629 36.8735 66.3629 42.5935H84.9992Z"
        fill="#CDCED0"
      />
      <path
        d="M47.3047 40.5802L48.2232 37.8227C48.5669 36.7903 47.5584 35.8212 46.5438 36.2105L43.4881 37.3824C43.1478 37.2792 42.7965 37.202 42.4315 37.1563C38.9447 36.7197 35.7624 39.1997 35.3254 42.694C34.8885 46.1883 37.362 49.3763 40.8488 49.8128C44.3357 50.2494 47.5179 47.7695 47.9549 44.2751C48.12 42.9544 47.8697 41.6795 47.3069 40.5785L47.3047 40.5802Z"
        fill="#CDCED0"
      />
    </svg>
  );
}

export default GraficThreeActiveIcon;