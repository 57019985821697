import React, { useState, useEffect, useContext } from "react";
import ReactGa from "react-ga";
import { Card } from "reactstrap";
import InfoUsuario from "./InfoUsuario";
import ModalConfirmacion from "./ModalConfirmacion";
import AuthContext from "../../context/autenticacion/authContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import ModalContext from "../../context/modal/modalContext";
import TransferenciaExteriorContext from "../../context/transferenciaExterior/transferenciaexteriorContext";
import Loading from "../../components/Loading/index.jsx";
import { Page, Button, CardContainer } from "../../components";
import Grid from "@mui/material/Grid";
import CustomModal from "../../components/custromModal/CustomModal";

import ModalActualizarCelular from "../../components/ModalActualizarCelular";
import Texto from "./Components/Texto";
const TransferenciaFondoExterior = () => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misDatosContext = useContext(MisDatosContext);
  const transferenciaExteriorContext = useContext(TransferenciaExteriorContext);
  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    obtenerMisDatosGet,
    limpiarVariablesMisDatosParcial,
  } = misDatosContext;

  const { loadingBtnTransferenciaExteriorPost, transferenciaExteriorPost } =
    transferenciaExteriorContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [confirmacionInfo, setConfirmacionInfo] = useState(null);
  const [modalExitoso, setModalExitoso] = useState(null);
  const [modalCelular, setModalCelular] = useState(null);
  const [modalAlerta, setModalAlerta] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaDatosGet) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerMisDatosGet();
    }

    return () => {
      limpiarVariablesMisDatosParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlertaDatosGet]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlertaDatosGet);
  };

  if (mensajeAlertaDatosGet !== null) return <p></p>;
  if (misDatosGet === null) return <Loading />;

  const closeDetalle = () => () => {
    setConfirmacionInfo(null);
  };

  const closeBtn = () => {
    setConfirmacionInfo(null);
  };

  const closeModalExitoso = () => () => {
    setModalExitoso(null);
  };
  const openModalExitoso = () => {
    setModalExitoso(true);
  };

  const closeModalCelular = () => {
    setModalCelular(null);
  };

  const procesarJubilacion = (dato) => () => {
    setOpcionSeleccionada(dato);
    setModalAlerta(true);
    // if (!misDatosGet.r_persona.r_datos.r_celular) {
    //   setModalCelular(true);
    // }
    // setConfirmacionInfo(dato);
  };
  const closeModalAlerta = () => {
    setModalAlerta(null);
    if (!misDatosGet.r_persona.r_datos.r_celular) {
      setModalCelular(true);
    }
    setConfirmacionInfo(opcionSeleccionada);
  };
  const closeModalAlertaX = () => () => {
    setModalAlerta(null);
  };
  return (
    <Page title="Transferencia de fondos al exterior" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <CardContainer>
            <Card>
              <p>
                Recuerda que si vas a vivir de forma permanente fuera del país,
                puedes solicitar que tu fondo acumulado sea transferido a una
                cuenta bancaria del exterior, siempre y cuando cumplas con todos
                los requisitos de ley.
              </p>
            </Card>
            <section className=" d-flex justify-content-around">
              <Button onClick={procesarJubilacion(8)}>Iniciar</Button>
            </section>
          </CardContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoUsuario />
        </Grid>
      </Grid>
      {/* {modalAlerta && (
        <ModalAlertaAgencia
          dispositivo="desktop"
          buttonCloseTop={true}
          titulo={"!Recuerda que!"}
          closeModal={closeModalAlertaX}
          closeBtn={closeModalAlerta}
          componenteInfo={<Texto opcion={opcionSeleccionada} />}
          // componenteInfo={}
        />
      )} */}

      <CustomModal
        showModal={modalAlerta}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalAlerta(!modalAlerta);
        }}
        title="Recuerda que..."
        description={
          <>
            {" "}
            <Texto opcion={opcionSeleccionada} />
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          closeModalAlerta();
        }}
        textBtnPrimary="Aceptar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          closeModalAlertaX();
        }}
        textBtnSecondary="Cancelar"
      />
      {confirmacionInfo &&
        (misDatosGet.r_persona.r_datos.r_celular ? (
          <ModalConfirmacion
            dispositivo="desktop"
            buttonCloseTop={true}
            info={confirmacionInfo}
            titulo={"Confirmanos tus datos"}
            closeModal={closeDetalle}
            closeBtn={closeBtn}
            openModalExitoso={openModalExitoso}
            datosAfiliado={misDatosGet.r_persona.r_datos}
            loading={loadingBtnTransferenciaExteriorPost}
            procesarPedido={transferenciaExteriorPost}
          />
        ) : (
          modalCelular && (
            <ModalActualizarCelular closeModal={closeModalCelular} />
          )
        ))}

      <CustomModal
        showModal={modalExitoso}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setModalExitoso(!modalExitoso);
        }}
        title="Solicitud Registrada"
        description={
          <>
            <p>
              {sessionStorage.getItem("nombreUsuario")}, de acuerdo a la
              información soliciada te estamos enviando los detalles, requisitos
              y el procedimiento al correo registrado para que puedas saber como
              ingresar tu trámite.Gracias.
            </p>
          </>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          closeModalExitoso();
        }}
        textBtnPrimary="Cerrar"
      />
    </Page>
  );
};

export default TransferenciaFondoExterior;
