import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import moment from "moment";
import CustomModal from "../../../../../Components/custromModal/CustomModal";
import "./index.css";

export default function Beneficiarios(props) {
  const {
    jaoProcessData,
    relationshipsData,
    beneficiarieRegister,
    setBeneficiarieRegister,
    setStep,
    getOutOfProcess,
    userBirthdate,
  } = props;

  const [relationships, setRelationships] = useState([]);
  const [haveBeneficiaries, setHaveBeneficiaries] = useState(
    beneficiarieRegister.haveBeneficiaries
  );
  const [beneficiaries, setBeneficiaries] = useState(
    beneficiarieRegister.beneficiaries
  );
  const [modalAddBeneficiarie, setModalAddBeneficiarie] = useState(false);
  const [addBeneficiarie, setAddbeneficiarie] = useState({
    relationship: "",
    birthday: "",
    gender: "",
    situation: "",
  });
  const [error, setError] = useState({
    birthday: "",
    situation: "",
  });

  useEffect(() => {
    if (relationshipsData) setRelationships(relationshipsData.data);
  }, [relationshipsData]);

  const handleHaveBeneficiaries = (value) => {
    if (
      (value === "1" && haveBeneficiaries === null) ||
      (value === "1" && haveBeneficiaries === false)
    )
      setHaveBeneficiaries(true);
    else if (
      (value === "0" && haveBeneficiaries === null) ||
      (value === "0" && haveBeneficiaries === true)
    )
      setHaveBeneficiaries(false);
  };

  const resetAddBeneficiarie = () => {
    setAddbeneficiarie({
      relationship: NaN,
      birthday: "",
      gender: "",
      situation: "",
    });
    setError({ birthday: "", situation: "" });
  };

  const deleteBeneficiaryToArray = (indexForDelete) => {
    const temporal = beneficiaries.slice();
    temporal.splice(indexForDelete, 1);
    setBeneficiaries(temporal);
  };

  const addBeneficiaryToArray = () => {
    if (addBeneficiarie.relationship === 1) {
      if (moment().diff(addBeneficiarie.birthday, "years", false) < 18) {
        setError({
          birthday: "No se puede agregar a un conyuge menor de 18 años.",
          situation: "",
        });
        return;
      }
    } else if (addBeneficiarie.relationship === 2) {
      if (
        moment().diff(addBeneficiarie.birthday, "years", false) > 18 &&
        addBeneficiarie.situation === "Sin discapacidad"
      ) {
        setError({
          birthday: "",
          situation:
            "No se puede agregar a un hijo mayor de 18 años sin discapacidad.",
        });
        return;
      } else if (
        moment(addBeneficiarie.birthday).isBefore(moment(userBirthdate))
      ) {
        setError({
          birthday: "La edad del hijo no puede ser mayor a la de usted",
          situation: "",
        });
        return;
      }
    } else if (
      addBeneficiarie.relationship === 3 ||
      addBeneficiarie.relationship === 4
    ) {
      if (moment(userBirthdate).isBefore(moment(addBeneficiarie.birthday))) {
        setError({
          birthday: `${
            addBeneficiarie.relationship === 4
              ? "La edad de la madre"
              : "La edad del padre"
          }  es menor a su edad`,
          situation: "",
        });
        return;
      }
    }

    const temporal = beneficiaries.slice();
    temporal.push(addBeneficiarie);
    setBeneficiaries(temporal);
    resetAddBeneficiarie();
    setModalAddBeneficiarie(!modalAddBeneficiarie);
  };

  const isInvalidateAddBeneficiaie = () => {
    if (isNaN(addBeneficiarie.relationship)) return true;

    if (addBeneficiarie.birthday === "") return true;

    if (addBeneficiarie.gender === "") return true;

    if (addBeneficiarie.situation === "") return true;

    return false;
  };

  const onContinue = () => {
    setBeneficiarieRegister({ haveBeneficiaries, beneficiaries });
    setStep(2);
  };

  return (
    <div className="registro-jao">
      <h1>Registro de información</h1>

      <div className="content-render mb-4">
        <div className="beneficiarios-jao">
          <p>
            Por favor, revisa y completa la siguiente información para poder
            registrar tu solicitud de pre-evaluación para Jubilación Anticipada.
          </p>
          <h6>
            I. Beneficiarios{" "}
            <span className="tooltip-custom" style={{ color: "#196691" }}>
              <i className="fa fa-info-circle" id="TooltipExample"></i>
              <span
                className="tooltiptext"
                style={{ backgroundColor: "#196691" }}
              >
                Pueden ser tus hijos menores de 18 años o mayores de 18 con
                alguna discapacidad, padres que dependan económicamente de ti y
                tu cónyuge.
              </span>
            </span>
          </h6>
          <div className="ml-1 mr-1 mb-4 info-beneficiarios">
            {/* <span className="info">*Si en tu DNI figuras como casado, deberás declarar a tu cónyuge.</span><br />
                        <span className="info">*Esta información es para fines de estimar tu pensión.</span> */}

            <div className="radio-container mt-2">
              <div className="check-container">
                <span className="oneChoice">
                  <input
                    type="radio"
                    value={1}
                    checked={haveBeneficiaries === true}
                    className="radio-btn"
                    id="tfa_710"
                    name="beneficiarios"
                    onClick={(e) => {
                      setBeneficiaries([]);
                      handleHaveBeneficiaries(e.target.value);
                    }}
                  />
                  <label className="postRadioField mb-0" for="tfa_710">
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label className="postRadioField mb-0" for="tfa_710">
                  <span className="check-label">Si, tengo beneficiarios</span>
                </label>
              </div>

              <div className="check-container">
                <span className="oneChoice">
                  <input
                    type="radio"
                    value={0}
                    checked={haveBeneficiaries === false}
                    className="radio-btn"
                    id="tfa_711"
                    name="beneficiarios"
                    onClick={(e) => handleHaveBeneficiaries(e.target.value)}
                  />
                  <label className="postRadioField mb-0" for="tfa_711">
                    <span className="input-radio-faux"></span>
                  </label>
                </span>
                <label className="postRadioField mb-0" for="tfa_711">
                  <span className="check-label">
                    No, declaro no tener beneficiarios
                  </span>
                </label>
              </div>
            </div>
          </div>

          {haveBeneficiaries && (
            <div className="beneficiarios-table-container">
              <div className="border-table mb-4">
                <div className="row-beneficiaries">
                  <div className="first-column center-tems">
                    <div className="table-header center-tems">
                      <span>Parentesco</span>
                    </div>
                  </div>
                  <div className="second-column center-tems">
                    <div className="table-header center-tems">
                      <span>Fecha Nacimiento</span>
                    </div>
                  </div>
                  <div className="third-column center-tems">
                    <div className="table-header center-tems">
                      <span>Sexo</span>
                    </div>
                  </div>
                  <div className="fourth-column center-tems">
                    <div className="table-header center-tems">
                      <span>Estado</span>
                    </div>
                  </div>
                  <div className="fifth-column center-tems "></div>
                </div>
                {beneficiaries.map((value, index) => {
                  return (
                    <div
                      key={`beneficiario-${index}`}
                      className="row-beneficiaries"
                    >
                      <div className="first-column center-tems">
                        <span className="beneficiaries-rows-span">
                          {relationships.find((x) => x.id == value.relationship)
                            ? relationships.find(
                                (x) => x.id == value.relationship
                              ).name
                            : ""}
                        </span>
                      </div>
                      <div className="second-column center-tems">
                        <span className="beneficiaries-rows-span">
                          {value.birthday}
                        </span>
                      </div>
                      <div className="third-column center-tems">
                        <span className="beneficiaries-rows-span">
                          {value.gender}
                        </span>
                      </div>
                      <div className="fourth-column center-tems">
                        <span className="beneficiaries-rows-span">
                          {value.situation}
                        </span>
                      </div>
                      <div
                        className="fifth-column center-tems"
                        onClick={() => deleteBeneficiaryToArray(index)}
                      >
                        <i className="fa fa-times"></i>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="center-tems">
                <button
                  className="btn-red"
                  onClick={() => setModalAddBeneficiarie(true)}
                >
                  Agregar Beneficiario
                </button>
              </div>
            </div>
          )}

          <div className="container-jao-buttons">
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={() => getOutOfProcess()}
            >
              Volver
            </Button>
            <Button
              className="mb-4 btn-modal-primary"
              onClick={() => onContinue()}
              disabled={
                haveBeneficiaries === null ||
                (haveBeneficiaries && beneficiaries.length === 0)
              }
            >
              Continuar
            </Button>
          </div>

          <CustomModal
            showModal={modalAddBeneficiarie}
            className="beauty-modal"
            backdrop={"static"}
            fnCloseIconBtn={() => {
              resetAddBeneficiarie();
              setModalAddBeneficiarie(!modalAddBeneficiarie);
            }}
            title="Añadir Beneficiario"
            description={
              <div style={{ textAlign: "left" }}>
                <FormGroup>
                  <Label htmlFor="exampleSelect">Parentesco</Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    value={addBeneficiarie.relationship}
                    onChange={(e) => {
                      if (parseInt(e.target.value) === 3) {
                        setAddbeneficiarie({
                          ...addBeneficiarie,
                          gender: "Hombre",
                          relationship: parseInt(e.target.value),
                        });
                      } else if (parseInt(e.target.value) === 4) {
                        setAddbeneficiarie({
                          ...addBeneficiarie,
                          gender: "Mujer",
                          relationship: parseInt(e.target.value),
                        });
                      } else if (parseInt(e.target.value) === 1) {
                        const genderAfiliado =
                          jaoProcessData.data.gender === "M"
                            ? "Mujer"
                            : "Hombre";
                        setAddbeneficiarie({
                          ...addBeneficiarie,
                          gender: genderAfiliado,
                          relationship: parseInt(e.target.value),
                        });
                      } else {
                        setAddbeneficiarie({
                          ...addBeneficiarie,
                          gender: "",
                          relationship: parseInt(e.target.value),
                        });
                      }
                      setError({ birthday: "", situation: "" });
                    }}
                  >
                    <option value="">--Selecionar--</option>
                    {relationships.map((value, index) => {
                      if (
                        value.id === 1 &&
                        beneficiaries.some((x) => x.relationship === 1)
                      )
                        return null;
                      else if (
                        value.id === 3 &&
                        beneficiaries.some((x) => x.relationship === 3)
                      )
                        return null;
                      else if (
                        value.id === 4 &&
                        beneficiaries.some((x) => x.relationship === 4)
                      )
                        return null;
                      else
                        return (
                          <option
                            key={`${index}-${value.name}`}
                            value={value.id}
                          >
                            {value.name}
                          </option>
                        );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="exampleDate">Fecha de Nacimiento</Label>
                  <Input
                    type="date"
                    name="date"
                    id="birthdate"
                    placeholder="dd/mm/aaaa"
                    max={moment().format("YYYY-MM-DD")}
                    value={addBeneficiarie.birthday}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      const value = e.target.value;
                      setError({ birthday: "", situation: "" });
                      setAddbeneficiarie({
                        ...addBeneficiarie,
                        birthday: value,
                      });
                    }}
                  />
                  <p id="beneficiarie-text-error">{error.birthday}</p>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="exampleSelectMulti">Sexo</Label>
                  <Input
                    type="select"
                    name="sexo"
                    id="sexo"
                    value={addBeneficiarie.gender}
                    onChange={(e) =>
                      setAddbeneficiarie({
                        ...addBeneficiarie,
                        gender: e.target.value,
                      })
                    }
                    disabled={
                      addBeneficiarie.relationship === 3 ||
                      addBeneficiarie.relationship === 4 ||
                      addBeneficiarie.relationship === 1
                    }
                  >
                    <option value="">--Selecionar--</option>
                    <option value="Hombre">Masculino</option>
                    <option value="Mujer">Femenino</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleSelectMulti">Estado</Label>
                  <Input
                    type="select"
                    name="estado"
                    id="estado"
                    onChange={(e) => {
                      setError({ birthday: "", situation: "" });
                      setAddbeneficiarie({
                        ...addBeneficiarie,
                        situation: e.target.value,
                      });
                    }}
                  >
                    <option value="">--Selecionar--</option>
                    <option value="Sin discapacidad">Sin discapacidad</option>
                    <option value="Con discapacidad">Con discapacidad</option>
                  </Input>
                  <p id="beneficiarie-text-error">{error.situation}</p>
                </FormGroup>
              </div>
            }
            hasBtnPrimary={true}
            fnBtnPrimary={addBeneficiaryToArray}
            textBtnPrimary="Guardar"
            disableBtnPrimary={isInvalidateAddBeneficiaie()}
          />
        </div>
      </div>
    </div>
  );
}
