export const extractUserNameFromCookie = (cookieName) => {
  var userName = cookieName + "=";

  var allCookieArray = document.cookie.split(";");
  for (var i = 0; i < allCookieArray.length; i++) {
    // Extracting userName and returning the same
    var temp = allCookieArray[i].trim();
    if (temp.indexOf(userName) === 0)
      return temp.substring(userName.length, temp.length);
  }

  // Else return empty string
  return "";
};
