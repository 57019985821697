import React, { useContext, useEffect, useState } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { FormGroup } from "reactstrap";
import Documento from "./Documento";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Loading from "../../components/Loading/index.jsx";
import ReactGa from "react-ga";
import AuthContext from "../../context/autenticacion/authContext";
import BoletaContext from "../../context/boleta/boletaContext";
import ModalContext from "../../context/modal/modalContext";
import "./index.css";
import { CardContainer, Page, NotFoundData, Button } from "../../components";
import Grid from "@mui/material/Grid";

const Boleta = (props) => {
  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;
  const boletaContext = useContext(BoletaContext);
  const {
    periodos,
    datosBoleta,
    mensajeAlerta,
    obtenerDatosPeriodos,
    obtenerDatosBoleta,
    loadingPage,
    limpiarVariablesBoletaParcial,
  } = boletaContext;
  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const [periodosSelect, setPeriodosSelect] = useState("");
  const [filtroTipoDocumento, seFiltroTipoDocumento] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosPeriodos("3");
    }

    return () => {
      limpiarVariablesBoletaParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };
  const handlerDocumentBolet = () => {
    seFiltroTipoDocumento(datosBoleta.r_boleta);
  };
  const handlerDocumentNote = () => {
    seFiltroTipoDocumento(datosBoleta.r_nota);
  };

  const handlerPeriodosSelect = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setPeriodosSelect(JSON.stringify(opcion));
      // obtenerDatosBoleta(opcion.r_codigo);
      limpiarVariablesBoletaParcial();
    } else {
      opcion = "";
      setPeriodosSelect("");
    }
    // setErrores({ ...errores, fondo: "" });
  };

  const validateSelect = () => {
    let result = false;
    let opcion = JSON.parse(periodosSelect);
    if (opcion.trim().length !== 0) {
      result = true;
    } else {
      result = false;
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateSelect) {
      let opcion = JSON.parse(periodosSelect);

      obtenerDatosBoleta(opcion.r_codigo);
    }
  };

  const listPeriodo = (lista) => {
    if (lista.length > 0) {
      return lista.map((item, index) => (
        <option key={index} value={JSON.stringify(item)}>
          {item.r_codigo.substr(item.r_codigo.length - 4)} {item.r_descrpcion}
        </option>
      ));
    } else {
      return "";
    }
  };

  const btnDisable = periodosSelect.trim().length !== 0 ? false : true;

  if (mensajeAlerta !== null) return <p></p>;
  if (periodos === null) return <Loading />;
  return (
    <Page title="Comprobante de pago" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {periodos.r_periodos.length > 0 && (
            <CardContainer>
              <FormGroup style={{ width: "50%" }}>
                <label>Períodos:</label>
                <select
                  className="form-control"
                  value={periodosSelect}
                  onChange={handlerPeriodosSelect}
                >
                  <option value="">- Selecciona un período - </option>
                  {listPeriodo(periodos.r_periodos)}
                </select>
              </FormGroup>
              <section className="d-flex justify-content-around">
                <Button
                  className={
                    btnDisable ? "btn-white btn-disable-white" : "btn-white"
                  }
                  disabled={btnDisable}
                  onClick={handleSubmit}
                >
                  Consultar boleta
                  {loadingPage && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </Button>
              </section>
            </CardContainer>
          )}{" "}
        </Grid>
        {datosBoleta !== null && datosBoleta.r_validacion === "3" && (
          <Grid item xs={12} lg={4}>
            <button className="btn-red" onclick={handlerDocumentBolet}>
              Boleta
            </button>
            <button className="btn-red ml-3" onclick={handlerDocumentNote}>
              Nota de crédito
            </button>
          </Grid>
        )}
        <Grid item xs={12}>
          {datosBoleta !== null && datosBoleta.r_validacion === "2" ? (
            <>
              <div className="d-flex align-items-center">
                <PDFDownloadLink
                  className="btn btn-danger custon-btn-donwload"
                  document={<Documento datos={datosBoleta.r_boleta} />}
                  fileName="boletapago.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Cargando..."
                    ) : (
                      <span>
                        Descargar{" "}
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </span>
                    )
                  }
                </PDFDownloadLink>
              </div>
              {props.windowWidth > 600 && (
                <section className="contenedor-over">
                  <PDFViewer width="100%" height="100%">
                    <Documento datos={datosBoleta.r_boleta} />
                  </PDFViewer>
                </section>
              )}
            </>
          ) : null}
          {datosBoleta !== null && datosBoleta.r_validacion === "3" ? (
            <>
              <div className="d-flex align-items-center">
                <PDFDownloadLink
                  className="btn btn-danger custon-btn-donwload"
                  document={<Documento datos={filtroTipoDocumento} />}
                  fileName="boletapago.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Cargando..."
                    ) : (
                      <span>
                        Descargar{" "}
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </span>
                    )
                  }
                </PDFDownloadLink>
              </div>
              {props.windowWidth > 600 && (
                <section className="contenedor-over">
                  <PDFViewer width="100%" height="100%">
                    <Documento datos={filtroTipoDocumento} />
                  </PDFViewer>
                </section>
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
      {periodos !== null && periodos.r_periodos.length <= 0 && (
        <CardContainer>
          <NotFoundData
            mensaje={
              <span>
                Actualmente no registras con comprobantes de pago.
                <br /> Te invitamos a seguir navegando dentro de Mi Habitat
                Digital.
              </span>
            }
            btnText={"Volver al inicio"}
            url={"/mi-habitat-digital/afiliado/inicio"}
            icon={true}
          />
        </CardContainer>
      )}
    </Page>
  );
};

export default hocWidthWindow(Boleta);
