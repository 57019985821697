import React from "react";
import { Modal } from "@mui/material";
import Button from "../../../../components/Buttons";
import Alert from "../../../../components/Alert";
import { Header, ModalDeErrorGeneralComponent } from "./styles";
import { BsX } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const ModalDeErrorGeneral = ({
  open = true,
  onNext,
  onClose,
  title,
  message,
}) => {
  const history = useHistory();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDeErrorGeneralComponent>
        <Header>
          <p className="title">{title || "Ocurrió un error"}</p>
        </Header>
        <Alert
          variant={"error"}
          description={
            <span>
              <p>
                {message ||
                  "Estimado(a) afiliado(a), no podemos procesar su solicitud en este momento. Por favor, inténtelo de nuevo más tarde."}
              </p>
              <br />
            </span>
          }
        />
        <Button
          fullWidth
          style={{ margin: "auto" }}
          onClick={() =>
            history.push("/mi-habitat-digital/afiliado/jubilacion")
          }
        >
          Ir a inicio
        </Button>
      </ModalDeErrorGeneralComponent>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
