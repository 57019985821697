export const monedas = {
  labelText: "Moneda de la cuenta",
  opciones: [
    { codigo: "1", nombre: "SOLES" },
    { codigo: "2", nombre: "DOLARES" },
  ],
  defaultSelect: "1",
};

export const cargo = {
  labelText: "Frecuencia de débito",
  opciones: [
    { codigo: "0", nombre: "Única" },
    { codigo: "1", nombre: "Mensual" },
  ],
  defaultSelect: "0",
};
export const retiro = {
  labelText: "Selecciona tu forma de retiro",
  opciones: [
    { codigo: "T", nombre: "Total" },
    { codigo: "P", nombre: "Parcial" },
  ],
  defaultSelect: "T",
};
export const fondos = {
  labelText: "",
  opciones: [
    { codigo: "1", nombre: "Fondo 1" },
    { codigo: "2", nombre: "Fondo 2" },
    { codigo: "3", nombre: "Fondo 3" },
  ],
  defaultSelect: "-1",
};

export const cuentasLocal = [
  {
    r_codigo: "C1664",
    r_numero_cuenta: "47520533399064",
    r_nombre_banco: "BCP",
  },
  {
    r_codigo: "C1280",
    r_numero_cuenta: "47593345430070",
    r_nombre_banco: "BCP",
  },
];

export const formasDePago = {
  labelText: "Forma de pago",
  placeHolder: "- seleccionar forma de pago -",
  opciones: [
    { codigo: "1", nombre: "BCP" },
    { codigo: "2", nombre: "INTERBANK" },
    { codigo: "3", nombre: "SCOTIABANK" },
  ],
  defaultSelect: "",
};

export const tipoDeCuenta = {
  labelText: "Tipo de cuenta",
  placeHolder: "- seleccionar tipo de cuenta -",
  opciones: [
    { codigo: "1", nombre: "Cuenta de ahorros" },
    { codigo: "2", nombre: "Cuenta corriente" },
    { codigo: "3", nombre: "Tarjeta de crédito" },
    { codigo: "4", nombre: "Tarjeta American Express" },
  ],
  defaultSelect: "",
};

export const meses = [
  {
    code: "01",
    mes: "Enero",
  },
  {
    code: "02",
    mes: "Febrero",
  },
  {
    code: "03",
    mes: "Marzo",
  },
  {
    code: "04",
    mes: "Abril",
  },
  {
    code: "05",
    mes: "Mayo",
  },
  {
    code: "06",
    mes: "Junio",
  },
  {
    code: "07",
    mes: "Julio",
  },
  {
    code: "08",
    mes: "Agosto",
  },
  {
    code: "09",
    mes: "Setiembre",
  },
  {
    code: "10",
    mes: "Octubre",
  },
  {
    code: "11",
    mes: "Noviembre",
  },
  {
    code: "12",
    mes: "Diciembre",
  },
];
