
import { Box } from '@mui/system'

import Typography from '@mui/material/Typography';
import React from 'react'
import IC_EMPTY from './../../../../assets/icons/ic_empty.svg';
import { BreadCrumbsFL } from '../data/components/BreadcrumbsFL';

export const EmptyData = () => {
    return (
        <>
            <BreadCrumbsFL title_mobile={'Home'} title={'Trámite'} />
            <Box sx={{
                paddingTop: { xs: '75px', md: '90px' },
                background: { xs: '#ffffff', md: '#f8f9fb' },
                minHeight: { xs: '78vh', md: 'auto' },
                paddingBottom: { xs: '82px', md: '0px' },
            }}>
                <div style={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }>
                    <Box sx={
                        {
                            boxShadow: 'rgb(26 26 26 / 30%) 0px 0px 5px 0px',
                            position: 'absolute',
                            background: '#FFFFFF',
                            borderRadius: '0.5rem',
                            height: { xs: '75%', md: '67%' },
                            width: { xs: '100%', md: '47%' },
                        }
                    }>
                        <Box sx={
                            {

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: { xs: '45%', md: '60%' },
                            }
                        }>
                            <Box sx={{
                                margin: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <img src={IC_EMPTY}
                                    width={'75%'}
                                    height={'75%'}
                                    alt="empty"
                                    /* style={{
                                    height: '50%'
                                }} */ />
                            </Box>
                        </Box>

                        <Box sx={
                            {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: { xs: '30%', md: '35%' },
                                flexDirection: 'column'
                            }
                        }>
                            <div style={
                                {
                                    height: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }
                            }>
                                <Typography variant="h5" component="div" sx={{
                                    textAlign: 'center',
                                    padding: '0.5rem',
                                    fontWeight: '400',
                                    fontSize: '24px',
                                    lineWeight: '32px',
                                }} gutterBottom>
                                    No existen trámites en proceso
                                </Typography>

                                <Typography variant="body2" component="div" gutterBottom sx={{
                                    textAlign: 'justify',
                                    color: '#6E7378',
                                    padding: { xs: '0 3rem', md: '0 7rem' },
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    letterSpacing: '0.2px',
                                }}>
                                    Si tienes alguna duda sobre algún trámite presencial o semipresencial que hayas realizado, puedes comunicarte con el área encargada mediante los canales digitales que tenemos disponibles en nuestra web.
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </div>
            </Box>
        </>
    )
}


