import React from "react";
import { FooterRowOption } from "./components";
import Facebook from "../../../../assets/icons/white-facebook.svg";
import Twter from "../../../../assets/icons/white-twter.svg";
import Youtube from "../../../../assets/icons/white-youtube.svg";
import Instagram from "../../../../assets/icons/white-ig.svg";
import LinkedIn from "../../../../assets/icons/white-linkendin.svg";
import "./index.css";

const Footer = () => {
  const footerOptions = [
    {
      title: "Tipo de Aportes",
      subOptions: [
        {
          title: "Fondo de Pensión",
          url: "https://www.afphabitat.com.pe/aporte-obligatorio/",
        },
        // {
        //   title: "Aporte Voluntario",
        //   url: "https://www.afphabitat.com.pe/aporte-voluntario/",
        // },
        {
          title: "Pensiones",
          url: "https://www.afphabitat.com.pe/pensiones/",
        },
        {
          title: "Independientes",
          url: "https://www.afphabitat.com.pe/soy-independiente/",
        },
        {
          title: "Multifondos",
          url: "https://www.afphabitat.com.pe/multifondos/",
        },
        {
          title: "Todo sobre el sistema",
          url: "https://www.afphabitat.com.pe/todo-sobre-el-sistema/",
        },
      ],
    },
    {
      title: "Buen Gobierno Corporativo",
      subOptions: [
        {
          title: "Memoria Anual",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#memoria-anual",
        },
        {
          title: "Registro de Accionistas",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#registro-de-accionistas",
        },
        {
          title: "Directores y Plana Gerencial",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#directores-y-plana-gerencial",
        },
        {
          title: "Reconocimientos",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#reconocimientos",
        },
        {
          title: "Hechos de importancia",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#hechos-de-importancia",
        },
        {
          title: "Políticas",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#politicas",
        },
        {
          title: "Estados Financieros",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#estados-financieros",
        },
        {
          title: "Informe COPAC",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#informe-copac",
        },
        {
          title: "Conoce AFP Habitat",
          url: "https://www.afphabitat.com.pe/corporativo/",
        },
        {
          title: "Reporte de Sostenibilidad",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#reporte-de-sostenibilidad",
        },
        {
          title: "Directores designados por las AFP",
          url: "https://www.afphabitat.com.pe/buen-gobierno-corporativo/#eleccion-directores",
        },
      ],
    },
    {
      title: "Empleadores",
      subOptions: [
        {
          title: "Obligaciones como empleador",
          url: "https://www.afphabitat.com.pe/empleadores/ayuda-empleadores/#obligaciones-como-empleador",
        },
        {
          title: "Pago de Aportes",
          url: "https://www.afphabitat.com.pe/empleadores/ayuda-empleadores/#pago-de-aportes",
        },
        {
          title: "AFPnet",
          url: "https://www.afphabitat.com.pe/empleadores/ayuda-empleadores/#afpnet",
        },
        {
          title: "Interés Moratorio",
          url: "https://www.afphabitat.com.pe/empleadores/ayuda-empleadores/#intereses-moratorios",
        },
        {
          title: "Comunidad para empresas",
          url: "https://www.afphabitat.com.pe/empleadores/ayuda-empleadores/#comunidad-para-empresas",
        },
      ],
    },
    {
      title: "Atención al Cliente",
      subOptions: [
        {
          title: "Canales de Atención",
          url: "https://www.afphabitat.com.pe/canales-de-atencion/",
        },
        {
          title: "Atención de Citas",
          url: "https://www.afphabitat.com.pe/atencion-de-citas/",
        },
        {
          title: "Atención de reclamos",
          url: "https://www.afphabitat.com.pe/atencion-de-reclamos-2/",
        },
        {
          title: "Solicitud ARCO",
          url: "https://www.afphabitat.com.pe/arco/",
        },
        {
          title: "Prospecto Informativo",
          url: "https://www.afphabitat.com.pe/wp-content/uploads/2022/06/prospectoinformativo2022.pdf",
        },
        {
          title: "Trabaja con nosotros",
          url: "https://afphabitat.buk.pe/trabaja-con-nosotros",
        },
        {
          title: "Libro de Reclamaciones",
          url: "https://www.afphabitat.com.pe/libro-de-reclamaciones/",
        },
        {
          title: "Política de Protección de Datos",
          url: "https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf",
        },
        {
          title: "Términos y condiciones",
          url: "https://www.afphabitat.com.pe/wp-content/uploads/2024/09/Terminos_y_Condiciones.pdf",
        },
      ],
    },
  ];

  const goToUrl = (url) => {
    window.open(url, "_self").focus();
  };

  return (
    <footer className="main-footer">
      <div className="container-new-home footer-container">
        {footerOptions.map((value, index) => (
          <FooterRowOption key={index} value={value} />
        ))}

        <div className="contact-info">
          <div className="footer-option">
            <div className="footer-header-option mb-1">
              <h4>Centro de Contacto:</h4>
            </div>
            <div className="contact-number-info">
              <span>Contact Center Lima </span>
              <span className="number">01 230 2200</span>
            </div>
            <div className="contact-number-info">
              <span>Contact Center Provincias</span>
              <span className="number"> 0801 14 224</span>
            </div>
            <div className="contact-number-info">
              <span className="number">AFP HABITAT S.A.</span>
            </div>
            <div className="contact-number-info">
              <span className="number">RUC: 20551464971</span>
            </div>
          </div>

          <hr id="white-breake-line" />

          <div className="social-media mt-2">
            <img
              src={Facebook}
              alt="fb-icon"
              onClick={() =>
                goToUrl("https://www.facebook.com/afphabitatperu/")
              }
            />
            {/* <img
              src={Twter}
              alt="tw-icon"
              onClick={() =>
                goToUrl("https://twitter.com/AFPHabitatPeru?lang=es")
              }
            />
            <img
              src={Youtube}
              alt="yt-icon"
              onClick={() =>
                goToUrl(
                  "https://www.youtube.com/channel/UCEDfWWHWfiHsY_SM7NzM2lQ"
                )
              }
            /> */}
            <img
              src={Instagram}
              style={{ height: "29px" }}
              alt="ig-icon"
              onClick={() =>
                goToUrl("https://www.instagram.com/afphabitat_peru/")
              }
            />
            <img
              src={LinkedIn}
              style={{ height: "29px" }}
              alt="lkn-icon"
              onClick={() =>
                goToUrl("https://www.linkedin.com/company/afphabitatperu/")
              }
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
