
import React from 'react'
import Sheet from 'react-modal-sheet';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { OverlayProvider } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';
import styled from 'styled-components';
import { useButton } from 'react-aria';
import CloseIcon from '@mui/icons-material/Close';

import { ContentModal } from '../../../PagosDesembolsos/PagoDetalle/Modal';
import { ContentFlex, FlexItem } from '..';

const DivTitle = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
`;

const Titulo = styled('div')`
    color: #CE1336;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
`;

export const ModalAll = ({ titulo, contenido, active = false, onChange }) => {

    const sheetState = useOverlayTriggerState({
        isOpen: active, onOpenChange: onChange

    });
    const closeButtonRef = React.useRef(null);
    const closeButton = useButton(
        { onPress: sheetState.close, 'aria-label': 'Close sheet' },
        closeButtonRef
    );

    return (
        <Sheet isOpen={sheetState.isOpen} onClose={sheetState.close}>
            <OverlayProvider>
                <FocusScope contain autoFocus restoreFocus>
                    <ContentModal sheetState={sheetState}
                        heigth={
                            { height: '100%' }
                        }
                        hasHeader={false}
                        content={
                            <>
                                <DivTitle>
                                    <ContentFlex color='transparent' style={{
                                        width: '100%',
                                    }}>
                                        <FlexItem size={1}></FlexItem>
                                        <FlexItem size={5}>
                                            <Titulo>
                                                {titulo}
                                            </Titulo>
                                        </FlexItem>
                                        <FlexItem size={1}>
                                            <div
                                                {...closeButton.buttonProps}
                                                style={{
                                                    paddingRight: '0px'
                                                }}><CloseIcon sx={{
                                                    color: '#CE1336'
                                                }} /> </div>
                                        </FlexItem>
                                    </ContentFlex>
                                </DivTitle>
                                <div
                                    style={{
                                        padding: '0rem 0rem 1rem 0rem'
                                    }}>
                                    {contenido}
                                </div>
                            </>
                        } />
                </FocusScope>
            </OverlayProvider>
        </Sheet>
    )
}
