import React from "react";
import * as Input from "../../../../components/Input";
import Button from "../../../../components/Buttons";
import { ContinuarComponent } from "./styles";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../components/ButtonsComponents";

const Continuar = ({ handleStepNavigation }) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const canContinue =
    watch("asesoria.acepta-informarcion") &&
    !errors["asesoria.acepta-informarcion"];

  const onContinue = () => handleStepNavigation("next");
  const history = useHistory();
  const onClickPrevButton = () => {
    history.push("/zona-beneficiarios");
  };

  return (
    <ContinuarComponent>
      <ButtonsWrapper>
        <Input.Wrapper style={{ gridColumn: "1/-1" }}>
          <Input.Checkbox
            name="asesoria.acepta-informarcion"
            value={true}
            required={true}
          >
            He entendido la información brindada
          </Input.Checkbox>
          <Input.ErrorMessage name="asesoria.acepta-informarcion" />
        </Input.Wrapper>

        <PrevButton onClick={onClickPrevButton}>Volver</PrevButton>
        <NextButton disabled={!canContinue} onClick={onContinue}>
          Continuar
        </NextButton>
      </ButtonsWrapper>
    </ContinuarComponent>
  );
};

export default Continuar;
