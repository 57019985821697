import React from "react";
import { Button } from "../../../../../../../../../../components";
import "./index.css";

export default function DetalleInfo(props) {
  const { getBackOneStep, nextView } = props;
  return (
    <div className="detalle-info-container">
      <h1 className="mb-3">Pensión mensual</h1>
      <p>Debes tener en cuenta lo siguiente:</p>
      <ul>
        <li>
          Consiste en recibir pagos mensuales con cargo a tu cuenta individual
          de capitalización o a través de una compañía de seguros, las cuales
          ofrecen una renta vitalicia, es decir, de por vida.
        </li>
        <li>
          Dicha pensión dependerá del fondo que tengas acumulado en tu cuenta,
          incluida la rentabilidad y el bono de reconocimiento de ser el caso.
          Para el cálculo también se considera la cantidad de beneficiarios que
          tengas, tu edad, expectativa de vida, entre otros.
        </li>
        <li>
          Cada mes se descontará el 4% de tu pensión para el pago a EsSalud, lo
          que brindará derecho a atención médica para ti y tus beneficiarios. En
          tu boleta de pago de pensiones podrás revisar el descuento mencionado.
        </li>
      </ul>
      <p>
        Esta modalidad consta de 5 etapas que deberás realizar mediante citas
        telefónicas y/o videollamadas antes de poder generar los pagos de
        pensión.
      </p>
      <p>
        Si estás de acuerdo con esta opción, selecciona Continuar para seguir el
        proceso.
      </p>

      <br />
      <br />
      <div className="container-reja-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className="mb-4 btn-modal-secondary"
          onClick={() => getBackOneStep()}
        >
          Volver
        </Button>
        <Button className="mb-4 btn-modal-primary" onClick={() => nextView()}>
          Continuar
        </Button>
      </div>
    </div>
  );
}
