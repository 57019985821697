import React, { useReducer } from "react";
import UltimosmovimientosContext from "./ultimosmovimientosContext";
import UltimosmovimientosReducer from "./ultimosmovimientosReducer";

import clienteAxios from "../../config/axios";

import {
  ULTIMOS_MOVIMIENTOS_EXITOSO,
  ULTIMOS_MOVIMIENTOS_LOADIN,
  ULTIMOS_MOVIMIENTOS_ERROR,
  ULTIMOS_MOVIMIENTOS_CLEANER,
  ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL,
} from "../../types";

const UltimosMovimientosState = (props) => {
  const initialState = {
    resultado: null,
    movimientos: null,
    mensajeAlerta: null,
    loadingBtn: false,
  };

  const obtenerUltimosMovimientos = async (datos) => {
    dispatch({
      type: ULTIMOS_MOVIMIENTOS_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post(
        "/mis-fondos/ultimos-movimientos",
        datos
      );

      dispatch({
        type: ULTIMOS_MOVIMIENTOS_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: ULTIMOS_MOVIMIENTOS_ERROR,
          payload: {
            code: 1, // error de red
            msg: "Su red presenta lentitud, por favor recargue la página.",
          },
        });
      } else {
        if (error.response?.status === 500) {
          dispatch({
            type: ULTIMOS_MOVIMIENTOS_ERROR,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response?.data?.errors?.message === "Unauthenticated."
        ) {
          dispatch({
            type: ULTIMOS_MOVIMIENTOS_ERROR,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        }
      }
    }
  };

  const limpiarUltimosMovimientos = () => {
    dispatch({
      type: ULTIMOS_MOVIMIENTOS_CLEANER,
    });
  };

  const limpiarUltimosMovimientosParcial = () => {
    dispatch({
      type: ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(UltimosmovimientosReducer, initialState);
  return (
    <UltimosmovimientosContext.Provider
      value={{
        resultado: state.resultado,
        movimientos: state.movimientos,
        mensajeAlerta: state.mensajeAlerta,
        loadingBtn: state.loadingBtn,
        obtenerUltimosMovimientos,
        limpiarUltimosMovimientos,
        limpiarUltimosMovimientosParcial,
      }}
    >
      {props.children}
    </UltimosmovimientosContext.Provider>
  );
};

export default UltimosMovimientosState;
