import { Modal } from "@mui/material";
import styled, { css } from "styled-components";
import { device } from "../../utils/device";

export const ModalComponent = styled(Modal)`
  p {
    margin-bottom: 0;
  }

  .MuiBackdrop-root {
    background-color: ${(p) => p.theme.palette.transparencies.dark};
  }
`;

export const ModalBody = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  outline: none;
  max-height: calc(100% - 48px);
  overflow: auto;

  ${css`
    &&& {
      @media screen and ${device.tablet} {
        max-width: 380px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;
        border-radius: 16px;
      }
    }
  `}
`;

export const TitleComponent = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 8px;
  text-align: center;
`;

export const SubtitleComponent = styled.h5`
  ${(p) => p.theme.tipography.bodySmallRegular}
  text-align: center;

  span {
    ${(p) => p.theme.tipography.bodySmallMedium}
  }
`;
