import React, { useEffect, useState } from "react";
import "./customModal.css";
import "./beautyModal.css";

import { Box, Checkbox, Dialog, Divider, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";
import { FormGroup, Input, Label } from "reactstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const styles = {
  titleModal: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeigt: "28px",
  },
  ContentModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
  },
  ContentAdviceModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    color: "black",
  },
  ContentMessageModal: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeigt: "normal",
    color: "#E11900",
  },
  SecondaryColor: {
    color: "#2F6D95",
  },
};

const CustomModal = (props) => {
  const {
    showModal,
    fnCloseIconBtn,
    title,
    onClosed,
    textBtnPrimary,
    fnButtonPrimary,
    globalErrors,
    labelFormulario,
    isActiveTreatment,
  } = props;

  const initialFormErrors = {
    status_celular: false,
    status_correo: false,
    message_error_celular: "",
    message_error_correo: "",
  };

  const initForm = {
    l_celular: "",
    l_correo: "",
  };

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [formulario, setFormulario] = useState(initForm);
  const [errores, setErrores] = useState(initialFormErrors);
  const [normalTxt, setNormalTxt] = useState("success_txt");
  const [normalTxtCorreo, setNormalTxtCorreo] = useState("success_txt");
  const [isValidFields, setIsValidFields] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handlerNumeros = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setFormulario({ ...formulario, [e.target.name]: valor });
    if (valor.length < 9) {
      setErrores({
        ...errores,
        message_error_celular: "El número de celular debe tener 9 dígitos",
        status_celular: false,
      });
      setNormalTxt("");
    } else {
      setErrores({ ...errores, message_error_celular: "" });
    }
  };

  const handlerEmail = (e) => {
    const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setFormulario({ ...formulario, [e.target.name]: e.target.value });
    if (!expresionRegular.test(e.target.value)) {
      setErrores({
        ...errores,
        message_error_correo: "Correo electrónico inválido",
      });
    } else {
      setErrores({ ...errores, message_error_correo: "" });
    }
    const newValue = e.target.value;
    if (newValue.length < formulario.l_correo.length) {
      setNormalTxtCorreo("");
      setErrores({
        ...errores,
        status_correo: false,
      });
    }
  };

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const handleChangeBox = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleProcessUpdate = () => {
    // setShowModalInfo(true);
  };

  useEffect(() => {
    function load() {
      setFormulario({
        ...formulario,
        l_celular: labelFormulario.l_celular,
        l_correo: labelFormulario.l_correo,
      });
    }
    load();
  }, [labelFormulario]);

  useEffect(() => {
    setErrores({
      ...errores,
      status_celular: globalErrors.status_celular,
      status_correo: globalErrors.status_correo,
    });
  }, [globalErrors]);

  useEffect(() => {
    if (
      formulario.l_celular !== "" &&
      formulario.l_correo !== "" &&
      errores.message_error_celular === "" &&
      errores.message_error_correo === ""
    ) {
      setIsValidFields(true);
    } else {
      setIsValidFields(false);
    }
  }, [formulario]);

  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.buttonUpCloseStyles}
              onClick={fnCloseIconBtn}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.titleModal}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px" }}>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <>
          <Typography sx={styles.ContentModal}>
            <div>
              La información resaltada a continuación corresponde a los datos
              que ingresaste previamente. Por favor, verifica si estos son los
              datos correctos.
            </div>
          </Typography>
          <Box sx={{ margin: "16px 0" }}>
            <FormGroup>
              <Label htmlFor="l_celular" className="label_title">
                <span
                  className={
                    globalErrors.status_celular
                      ? "label_error"
                      : "label_success"
                  }
                >
                  *{" "}
                </span>
                Celular
              </Label>
              <Input
                placeholder="Ingresa tu celular"
                name="l_celular"
                value={formulario.l_celular}
                pattern="[0-9]*"
                onChange={handlerNumeros}
                onPaste={handlerDisable}
                onCopy={handlerDisable}
                maxLength={9}
                bsSize="lg"
                style={{ marginBottom: "7px" }}
                className={errores.status_celular ? "error_txt" : normalTxt}
                invalid={errores.status_celular}
              />
              {errores.message_error_celular && (
                <span
                  style={{
                    fontSize: "14px",
                    color: "#bf0d3e",
                  }}
                >
                  {errores.message_error_celular}
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="l_correo" className="label_title">
                <span
                  className={
                    errores.status_correo ? "label_error" : "label_success"
                  }
                >
                  *{" "}
                </span>
                Correo electrónico
              </Label>
              <Input
                placeholder="Ingresa tu correo electrónico"
                name="l_correo"
                onChange={handlerEmail}
                onPaste={handlerDisable}
                onCopy={handlerDisable}
                value={formulario.l_correo}
                style={{ marginBottom: "7px" }}
                className={
                  errores.status_correo ? "error_txt" : normalTxtCorreo
                }
                invalid={errores.status_correo}
                bsSize="lg"
              />
              {errores.message_error_correo && (
                <span
                  style={{
                    fontSize: "14px",
                    color: "#bf0d3e",
                  }}
                >
                  {errores.message_error_correo}
                </span>
              )}
            </FormGroup>
          </Box>
          <Divider />
          <Box sx={{ marginTop: "16px", marginBottom: "16px" }}>
            <Typography sx={styles.ContentModal}>
              <div>
                Conozco que la información será tratada de conformidad con la{" "}
                <a
                  href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                  target="_blank"
                  style={{ color: "#2F6D95" }}
                >
                  Política de Privacidad de AFP Habitat S.A.
                </a>
              </div>
            </Typography>
          </Box>
          {isActiveTreatment && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "10px",
              }}
            >
              <Checkbox defaultChecked={isChecked} onChange={handleChangeBox} />
              <Typography sx={styles.ContentModal}>
                Autorizo voluntariamente que AFP Habitat S.A. realice
                tratamiento de mis datos personales, con fines de envío de
                publicidad sobre los productos y servicios de la compañía y de
                aquellas que forman parte de su grupo económico, de conformidad
                con la 
                <a
                  href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                  target="_blank"
                  style={{ color: "#2F6D95" }}
                >
                  Política de Privacidad de AFP Habitat S.A.
                </a>
              </Typography>
            </Box>
          )}
          <Divider />
          <Box sx={{ marginTop: "16px" }}>
            <Typography sx={styles.ContentMessageModal}>
              Los campos marcados con (*) son obligatorios.
            </Typography>
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ p: 1, justifyContent: "space-evenly" }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.buttonsDownStyles}
              onClick={() => fnButtonPrimary(formulario, isChecked)}
              autoFocus
              disabled={!isValidFields}
            >
              {textBtnPrimary}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
