import { Stepper } from "@mui/material";
import styled from "styled-components";

export const StepperComponent = styled(Stepper)`
  max-width: min(100%, 740px);
  margin: auto;
  .MuiStepIcon-root {
    color: ${(p) => p.theme.palette.grayscale[300]};
    &.Mui-active,
    &.Mui-completed {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
  .MuiStepConnector-root {
    left: calc(-50% + 15px);
    right: calc(50% + 15px);

    &.Mui-active,
    &.Mui-completed {
      .MuiStepConnector-line {
        border-color: ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;
