import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M15.333 3.167v4h-4M.667 13.834v-4h4"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M2.34 6.5a6 6 0 019.9-2.24l3.093 2.907M.667 9.833L3.76 12.74a6 6 0 009.9-2.24"
      ></path>
    </svg>
  );
}

export default Icon;
