import React from "react";
import PropTypes from "prop-types";
import Arrow_back_24px from "../../../../assets/icons/arrow_back_24px.svg";
import { Content, ContentAppbar, TitleAppbar, IconButton } from "./styles";
const Appbar = ({ title, onHandleClick }) => {
  return (
    <ContentAppbar>
      <Content>
        <IconButton onClick={onHandleClick}>
          <img src={Arrow_back_24px} alt="arrow back red" />
        </IconButton>
        <TitleAppbar>{title}</TitleAppbar>
      </Content>
    </ContentAppbar>
  );
};

export default Appbar;

Appbar.propTypes = {
  children: PropTypes.node,
};

Appbar.defaultProps = {
  title: "",
};
