import React from "react";
import PrimerPaso from "./PrimerPaso";
import SolicitudNoProcesada from "../SolicitudNoProcesada";
import SolicitudProcesada from "../SolicitudProcesada";
import MensajeInformativo from "../MensajeInformativo";
import Informacion from "./InfoUsuario";
import Grid from "@mui/material/Grid";

const ConCuenta = ({
  cuentas,
  datosRetiroApvSinFin,
  conTokenDigital,
  montoApvSinFin,
  procesarSolicitud,
  datosResultadoRetiroApvSinFin,
  resetear,
  mensajeInformativo,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        {/* {cuentas.length >= 0  ? ( */}
        <PrimerPaso
          opcionCuentas={"conCuentas"} // conCuentas sinCuentas
          opcion="favoritos"
          data={datosRetiroApvSinFin}
          conTokenDigital={conTokenDigital}
          montoTotal={montoApvSinFin}
          procesarSolicitud={procesarSolicitud}
          resultado={datosResultadoRetiroApvSinFin}
          resetear={resetear}
        />
        {/* )  : (
          <section className="container container-mensaje-info-retiro">
            <MensajeInformativo mensaje={datosRetiroApvSinFin.r_mensaje} />
          </section>
        )} */}
      </Grid>
      {/* <Informacion /> */}
    </Grid>
  );
};

export default ConCuenta;
