import React from "react";
import PropTypes from "prop-types";
import { CardContent } from "./styles";
const Card = ({ children, border, style, ...props }) => {
  return (
    <CardContent border={border} style={style}>
      {children}
    </CardContent>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node,
};

Card.defaultProps = {
  width: "24px",
  height: "24px",
  type: "square",
  padding: "8px",
};
