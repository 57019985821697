import React, { useReducer } from "react";
import SolicitudContext from "./solicitudContext";
import SolicitudReducer from "./solicitudReducer";
import clienteAxios from "../../config/axios";
import { SOLICITUD_POST_ERROR, POST_SOLICITUD_EXITOSO, POST_LOADING_SOLICITUD, CLEAN_GUARDAR_SOLICITUD } from "../../types/solicitud";
const SolicitudState = (props) => {
    const initialState = {
        error_solicitud: null,
        guardo: false,
        loadingBtn: false,
    };
    const handleErrorsLocal = (error, origin_error) => {
        if (error.message === "Network Error") {
            dispatch({
                type: origin_error,
                payload: {
                    code: 1, // error de red
                    msg: "Su red presenta lentitud, por favor recargue la página.",
                },
            });
        } else {
            if (error.response?.status === 500) {
                dispatch({
                    type: origin_error,
                    payload: {
                        code: 1, // su token a expirado
                        msg: "Error en los servicios, por favor recargue la página.",
                    },
                });
            } else {
                console.log("entramos al default errors");
            }
        }
    };

    const guardarSolicitud = async (datos) => {
        dispatch({
            type: POST_LOADING_SOLICITUD,
        });
        try {
            const respuesta = await clienteAxios.post("av-beneficiaro", datos);

            dispatch({
                type: POST_SOLICITUD_EXITOSO,
                payload: respuesta.data,
            });
        } catch (error) {

            handleErrorsLocal(error, SOLICITUD_POST_ERROR);
        }
    };
    const resetGuardo = () => {
        dispatch({
            type: CLEAN_GUARDAR_SOLICITUD,
            payload: false
        });
    }
    const [state, dispatch] = useReducer(SolicitudReducer, initialState);
    return (
        <SolicitudContext.Provider
            value={{
                guardo: state.guardo,
                loadingBtn: state.loadingBtn,
                guardarSolicitud,
                resetGuardo
            }}
        >
            {props.children}
        </SolicitudContext.Provider>
    );
};
export default SolicitudState;