import React, { useState, useEffect } from "react";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";

/* iconos de los graficos */
import GraficCeroActiveIcon from "../../../assets/IconBucket/Icon/GraficCeroActiveIcon";
import GraficOneActiveIcon from "../../../assets/IconBucket/Icon/GraficOneActiveIcon";
import GraficTwoActiveIcon from "../../../assets/IconBucket/Icon/GraficTwoActiveIcon";
import GraficThreeActiveIcon from "../../../assets/IconBucket/Icon/GraficThreeActiveIcon";

import GraficCeroDisableIcon from "../../../assets/IconBucket/Icon/GraficCeroDisableIcon";
import GraficOneDisableIcon from "../../../assets/IconBucket/Icon/GraficOneDisableIcon";
import GraficTwoDisableIcon from "../../../assets/IconBucket/Icon/GraficTwoDisableIcon";
import GraficThreeDisableIcon from "../../../assets/IconBucket/Icon/GraficThreeDisableIcon";

/* Iconos material ui */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

/* estilos */
export const styles = {
  cardStyle: {
    // width: "227px",
    maxWidth: "227px",
    fontSize: "10px",
    border: "1.5px solid #ACAFB3",
    borderRadius: "16px",
    cursor: "pointer",
    userSelect: "none",
  },
  cardStyleChanges: {
    // width: "227px",
    maxWidth: "227px",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #CE1336",
    cursor: "pointer",
    userSelect: "none",
  },
  cardAlReadySelectedStyle: {
    width: "227px",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #EDEDED",
    cursor: "pointer",
    userSelect: "none",
    maxWidth: "250px",
  },
  cardContentStyle: {
    // height: "220px",
    p: 1,
  },
  boxContainerTitle: {
    borderRadius: "8px",
    bgcolor: "#CE1336",
    width: "90%",
  },
  boxContainerTitleChanges: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "85%",
  },
  boxContainerAlReadySelectedStyle: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "85%",
  },
  boxContainerTitleChangesXL: {
    borderRadius: "8px",
    bgcolor: "#B7E3FF",
    width: "80%",
  },
  titleDesktopStyle: {
    fontSize: "1.5em",
    pt: "6px",
    fontWeight: "bold",
    color: "white",
  },
  titleResponsiveStyle: {
    fontSize: "1.2em",
    pt: "6px",
    fontWeight: "bold",
    color: "white",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridRentabilidadStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 1,
    bgcolor: "#F6F6F6",
    borderRadius: "8px",
  },
};

/*  title={item.title}
              img={item.riesgoImg}
              rentabilidad={item.rentabilidad}
              riesgo={item.riesgo} */

const BasicCardDesktop = ({
  id,
  info,
  defaultSelect,
  buttonType,
  OptionSelect,
  step,
  fondoOrigen,
  setFondoDestino,

  title,
  img,
  rentabilidad,
  riesgo,
  opcionSeleccionada,
}) => {
  /* Hooks para los cambios al ser seleccionados */
  const [typeButton, setTypeButton] = useState(false);

  /* material ui */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const screenXL = useMediaQuery(theme.breakpoints.down("xl"));

  /* Hook verifica la data */
  useEffect(() => {
    if (buttonType === "eligeFondo") setTypeButton(true);
  }, [buttonType, info]);

  return (
    <>
      <Box sx={{ fontSize: "10px", width: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {opcionSeleccionada === id ? (
            <Chip
              label="FONDO DESTINO"
              size="small"
              variant="outlined"
              sx={{
                mb: 1,
                border: "none",
                // backgroundColor: "#B7E3FF",
                p: "4px 16px",
                fontWeight: "600",
              }}
            />
          ) : (
            <Chip
              label=""
              size="small"
              variant="outlined"
              sx={{ mb: 1, border: "none", p: "4px 16px" }}
            />
          )}
          <Card
            sx={
              opcionSeleccionada === id
                ? styles.cardStyleChanges
                : styles.cardStyle
            }
          >
            <Box sx={styles.cardContentStyle}>
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <Chip
                      label={title}
                      size="small"
                      sx={{
                        p: "4px 10px",

                        backgroundColor:
                          opcionSeleccionada === id ? "#CE1336" : "#2F6D95",
                        color: "white",
                        borderRadius: "8px",
                        fontSize: "12px",
                        "&.MuiChip-root.Mui-disabled": {
                          backgroundColor: "#ACAFB3",
                          opacity: 1,
                          width: "100%",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={1} sx={styles.gridCheckboxStyle}>
                    {opcionSeleccionada === id ? (
                      <CheckCircleIcon
                        sx={{ color: "#CE1336" }}
                        fontSize="medium"
                      />
                    ) : (
                      <PanoramaFishEyeIcon
                        sx={{ color: "#ACAFB3" }}
                        fontSize="medium"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={styles.gridRiesgoStyle}>
                  {
                    <Box sx={{ pt: 2 }}>
                      {id === 0 && <GraficCeroActiveIcon />}
                      {id === 1 && <GraficOneActiveIcon />}
                      {id === 2 && <GraficTwoActiveIcon />}
                      {id === 3 && <GraficThreeActiveIcon />}
                    </Box>
                  }
                </Grid>
                <Grid item xs={12} sx={{ pb: 0.5 }}>
                  <Box
                    sx={{
                      p: 1,
                      bgcolor: "#F6F6F6",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.2em",
                        fontWeight: "600",
                      }}
                    >
                      Riesgo:
                    </Typography>

                    <Typography sx={{ color: "#6E7378", fontSize: "1.2em" }}>
                      {riesgo}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={styles.gridRentabilidadStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={screenXL ? (matches ? 6 : 6) : 6}>
                      <Typography
                        sx={{
                          fontSize: "1.2em",
                          fontWeight: "600",
                        }}
                      >
                        Potencial de rentabilidad:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={screenXL ? (matches ? 6 : 6) : 6}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ color: "#6E7378", fontSize: "1.2em" }}
                        >
                          {rentabilidad}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default BasicCardDesktop;
