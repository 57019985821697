import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { TextAll } from "../../../../FondoLibre/componentes";
import BasicCard from "../../../../../components/BasicCardDetail/BasicCard";
import BasicCardMobile from "../../../../../components/BasicCardDetailMobile/BasicCard";
import BottomButtonsInline from "../../../../FondoLibre/componentes/BottomButtons/index";
import Loading from "../../../../../components/Loading";
import HomeContext from "../../../../../context/home/homeContext";
import ModalsInformation from "../../../componentes/ModalsInformation";
/* imagenes/iconos SVG de las CARDS */
import NIVEL_RIESGO_F1 from "./../../../../../assets/IconBucket/NIVEL_RIESGO_F1.svg";
import RENTABILIDAD_F1 from "./../../../../../assets/IconBucket/RENTABILIDAD_F1.svg";
import NIVEL_RIESGO_F2 from "./../../../../../assets/IconBucket/NIVEL_RIESGO_F2.svg";
import RENTABILIDAD_F2 from "./../../../../../assets/IconBucket/RENTABILIDAD_F2.svg";
import NIVEL_RIESGO_F3 from "./../../../../../assets/IconBucket/NIVEL_RIESGO_F3.svg";
import RENTABILIDAD_F3 from "./../../../../../assets/IconBucket/RENTABILIDAD_F3.svg";

const DivContainer = styled("div")`
  position: ${(props) => (props.isMobile ? "initial" : "relative")};
  padding: ${(props) => (props.isMobile ? "0" : "16px")};
  width: ${(props) => (props.isMobile ? "initial" : "100%")};
`;
const DivRowRed = styled("div")`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? "unset" : "center")};
  align-items: center;
  padding: 0 24px;
  margin: 0 0 24px;
  position: relative;
`;
const InfoCard = [
  {
    id: 1,
    title: "Fondo 1",
    subTitle: " Conservador",
    rentabilidad: "Baja",
    riesgo: "Baja",
    riesgoImg: NIVEL_RIESGO_F1,
    rentabilidadImg: RENTABILIDAD_F1,
    name: `Fondo 1`,
  },
  {
    id: 2,
    title: "Fondo 2",
    subTitle: " Moderado",
    rentabilidad: "Moderada",
    riesgo: "Moderada",
    riesgoImg: NIVEL_RIESGO_F2,
    rentabilidadImg: RENTABILIDAD_F2,
    name: `Fondo 2`,
  },

  {
    id: 3,
    title: "Fondo 3",
    subTitle: " Arriesgado",
    rentabilidad: "Alta",
    riesgo: "Alta",
    riesgoImg: NIVEL_RIESGO_F3,
    rentabilidadImg: RENTABILIDAD_F3,
    name: `Fondo 3`,
  },
];

export const Circle = styled("div")`
  height: ${(props) => (props.size ? props.size : "25px")};
  width: ${(props) => (props.size ? props.size : "25px")};
  background-color: ${(props) =>
    props.background ? props.background : "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;

const Alternativas = ({ isMobile, SetStep, setShowOnboard, showOnboard }) => {
  const [openDetailFund, setOpenDetailFund] = useState(false);
  const [openDetailTextInvertion, setOpenDetailTextInvertion] = useState(false);
  const [arrayFunds, setArrayFunds] = useState([]);
  const [selectFunds, setSelectFund] = useState(null);

  const homeContext = useContext(HomeContext);
  const { graphycs, obtenerDatosGraphyc } = homeContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerDatosGraphyc();
  }, []);

  useEffect(() => {
    if (graphycs) {
      const resultFund = InfoCard.map((item) => {
        let arrayPies = graphycs.grafico4.funds?.typeOfIncomeChart?.filter(
          (itemX) => Number(itemX.fondo) === item.id
        );
        let arrayCartera = graphycs.grafico6.ManagedPortfolioChart?.filter(
          (itemY) => Number(itemY.fondo) === item.id
        );
        item.arrayFund = arrayPies;
        item.arrayCartera = arrayCartera;
        return item;
      });
      setArrayFunds(resultFund);
    }
  }, [graphycs]);

  const handleSelectFund = (id) => {
    const filterFund = arrayFunds.filter((item) => item.id === id);

    setSelectFund(filterFund[0]);
    setOpenDetailFund(true);
  };

  if (arrayFunds.length === 0) return <Loading />;
  return (
    <DivContainer isMobile={isMobile}>
      <DivRowRed isMobile={isMobile}>
        <div>
          <TextAll
            fontSize={isMobile ? "20px" : "24px"}
            lineHeight={isMobile ? "28px" : "32px"}
            fontWeight="400"
            textAlign={"center"}
            color={"#363A3E"}
            padding={isMobile ? "16px" : "0"}
          >
            Alternativas para invertir en Fondo Libre Habitat
          </TextAll>

          <TextAll
            fontSize={isMobile ? "14px" : "14px"}
            lineHeight={isMobile ? "20px" : "22px"}
            fontWeight="400"
            textAlign={"center"}
            color={"#141414"}
            padding={isMobile ? "8px 16px" : "0"}
          >
            Se diferencian entre sí por sus niveles de riesgo y potencial de
            rentabilidad a largo plazo.
          </TextAll>
        </div>
      </DivRowRed>

      {/* {isMobile ? ( */}
      <BasicCardMobile
        info={InfoCard}
        handleSelectFund={handleSelectFund}
        isMobile={isMobile}
      />
      {/* ) : (
        <BasicCard
          info={InfoCard}
          grafico={graphycs.grafico4}
          cartera={graphycs.grafico6}
        />
      )} */}

      <BottomButtonsInline
        isMobile={isMobile}
        width={"100%"}
        active={true}
        activeSecond={true}
        position={isMobile ? "relative" : "fixed"}
        padding={isMobile ? "8px 0px" : "8px 8rem"}
        positiontop={isMobile ? "0px" : "calc(100% - 80px)"}
        flexDirection={isMobile ? "column-reverse" : "column-reverse"}
        firstPadding={isMobile ? "0px 16px" : "0px 16px"}
        secondPadding={isMobile ? "0px 16px 16px 16px" : "0px 16px"}
        secondText="Conocer mi perfil de inversionista"
        firstText="Invertir"
        secondOnClick={() => SetStep(1)}
        firstOnClick={() => setShowOnboard(!showOnboard)}
      />

      <ModalsInformation
        openDetailFund={openDetailFund}
        openDetailTextInvertion={openDetailTextInvertion}
        setOpenDetailFund={setOpenDetailFund}
        setOpenDetailTextInvertion={setOpenDetailTextInvertion}
        selectFunds={selectFunds}
      />
    </DivContainer>
  );
};

export default Alternativas;
