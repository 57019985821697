import React, { useState, useEffect } from "react";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ModalsInformation from "../../../../componentes/ModalsInformationResult";

import styled from "styled-components";

const ContentRent = styled("div")`
  padding: 16px;
`;
const DivRentaFija = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C92D54")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaVariable2 = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#73607D")};
  border-radius: ${(props) => (props.borderRent ? props.borderRent : "0px")};

  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaAlternativo = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C3ACCF")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaLocal = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#CE1336")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
`;

const DivRentaInternacional = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8A001A")};
  border-radius: 0px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivRentaTransito = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}%` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8E9B9E")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Circle = styled("div")`
  height: ${(props) => (props.size ? props.size : "25px")};
  width: ${(props) => (props.size ? props.size : "25px")};
  background-color: ${(props) =>
    props.background ? props.background : "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;
/* estilos */
export const styles = {
  cardStyle: {
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid rgba(20, 20, 20, 0.15)",
    userSelect: "none",
    boxShadow: "none",
  },
  cardContentStyle: {
    minHeight: "116px",
    p: 2,
  },

  textCardDetail: {
    fontSize: "12px",
    color: "#363A3E",
  },

  titleMobileSubtitleStyle: {
    fontSize: "14px",
    textAlign: "center",
    padding: "0",
    color: "#2F6D95",
    cursor: "pointer",
  },
  titleResponsiveStyle: {
    fontSize: "14px",
  },

  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

const BasicCard = (props) => {
  const { info, indexFondo, isMobile } = props;
  /* Hooks para los cambios al ser seleccionados */
  const [arrayFunds, setArrayFunds] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const resultFund = info.map((item) => {
      let arrayPies = props.grafico?.funds?.typeOfIncomeChart?.filter(
        (itemX) => itemX.fondo == item.id
      );
      let arrayCartera = props.cartera?.ManagedPortfolioChart?.filter(
        (itemY) => itemY.fondo == item.id
      );
      item.arrayFund = arrayPies;
      item.arrayCartera = arrayCartera;
      return item;
    });
    const filterArray = resultFund.filter((item) => item.id === indexFondo);
    setArrayFunds(filterArray);
  }, []);
  if (arrayFunds.length === 0) return <p></p>;
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Card sx={styles.cardStyle}>
        <Box sx={styles.cardContentStyle}>
          <Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography sx={styles.titleResponsiveStyle}>
                  {arrayFunds[0].title} - {arrayFunds[0].subTitle}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={styles.titleMobileSubtitleStyle}
                  onClick={() => setOpen(!open)}
                >
                  Ver detalle
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ pt: 1 }}>
            <Grid item xs={6} sx={styles.gridRiesgoStyle}>
              <Box
                component="img"
                sx={{ width: "86px", pb: 2, pt: 2 }}
                src={arrayFunds[0].riesgoImg}
                alt="Gráfico de nivel de riesgo"
              />
            </Grid>
            <Grid item xs={6} sx={styles.gridRiesgoStyle}>
              <Box
                component="img"
                sx={{ width: "86px", pb: 2, pt: 2 }}
                src={arrayFunds[0].rentabilidadImg}
                alt="Gráfico de nivel de riesgo"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={1}
                sx={{
                  borderRadius: "8px",
                  pl: 1,
                  pr: 1,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Nivel de Riesgo:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#6E7378", fontSize: "12px" }}>
                      {arrayFunds[0].riesgo}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                spacing={1}
                sx={{
                  borderRadius: "8px",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Potencial de rentabilidad:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#6E7378",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {arrayFunds[0].rentabilidad}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <ModalsInformation
          arrayFunds={arrayFunds}
          open={open}
          setOpen={setOpen}
        />
      </Card>
    </Grid>
  );
};

export default BasicCard;
