import React, { useEffect, useContext } from "react";
import MisdatosContext from "../../context/misdatos/misdatosContext";
import AuthContext from "../../context/autenticacion/authContext";
import { Card, CardHeader, CardBody } from "reactstrap";
import { withRouter } from "react-router";
import Button from "../Button";
const FormularioRatificaDatos = (props) => {
  const misdatosContext = useContext(MisdatosContext);
  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    obtenerMisDatosGet,
    actualiza6MesesPost,
  } = misdatosContext;

  const authContext = useContext(AuthContext);
  const { validoDatosExitoso, valido_datos, closeModalValidoDatos } =
    authContext;

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaDatosGet) {
    } else {
      obtenerMisDatosGet();
    }

    // eslint-disable-next-line
  }, [mensajeAlertaDatosGet]);

  const actualizar = () => {
    closeModalValidoDatos();
    props.history.push(
      "/mi-habitat-digital/afiliado/informacion/datos-personales"
    );
  };
  const confirmar = () => {
    actualiza6MesesPost();
    validoDatosExitoso("2");
    closeModalValidoDatos();
  };

  const handlerClose = () => {
    closeModalValidoDatos();
  };
  return (
    <section className={`wraper-modal`}>
      <div className={`modal-content`}>
        {valido_datos.loCambio && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={handlerClose}></i>
          </div>
        )}

        <section>
          <div>
            {valido_datos.loCambio ? (
              <>
                <Card>
                  <CardHeader className="custom-header-cambio-fondo custom-header-li">
                    Confirmación de datos exitosa.
                  </CardHeader>
                  <CardBody>
                    <p>
                      Se confirmaron tus datos correctamente, te recordamos
                      mantener siempre actualizado tus datos, para mantenerte
                      informado.
                    </p>
                  </CardBody>
                </Card>
                <section className="d-flex justify-content-around">
                  <Button onClick={handlerClose}>Aceptar</Button>
                </section>
              </>
            ) : (
              <div>
                <Card>
                  <CardHeader className="custom-header-cambio-fondo custom-header-li title-modal">
                    Confirmación de datos
                  </CardHeader>
                  <CardBody className="px-0 py-0 body-modal">
                    {mensajeAlertaDatosGet !== null ? (
                      <p>Hubo Un error por favor recargue la página</p>
                    ) : misDatosGet === null ? (
                      <p>Cargando ...</p>
                    ) : (
                      <section>
                        <section className="mb-2">
                          <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                            <p className="m-0 custom-mb-modal-bco">
                              Teléfono fijo
                            </p>
                            <p className="m-0 text-right custom-mb-modal-bco">
                              {misDatosGet.r_persona.r_datos.r_telefono !== null
                                ? misDatosGet.r_persona.r_datos.r_telefono
                                : "No tiene"}
                            </p>
                          </div>
                          <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                            <p className="m-0 custom-mb-modal-bco">Celular</p>
                            <p className="m-0 text-right custom-mb-modal-bco">
                              {misDatosGet.r_persona.r_datos.r_celular !== null
                                ? misDatosGet.r_persona.r_datos.r_celular
                                : "No tiene"}
                            </p>
                          </div>
                          <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                            <p className="m-0 custom-mb-modal-bco">E-mail</p>
                            <p
                              className="m-0 text-right custom-mb-modal-bco"
                              style={{ overflowWrap: "break-word" }}
                            >
                              {misDatosGet.r_persona.r_datos.r_correo !== null
                                ? misDatosGet.r_persona.r_datos.r_correo
                                : "No tiene"}
                            </p>
                          </div>
                        </section>
                      </section>
                    )}
                  </CardBody>
                </Card>
                <section className="d-flex justify-content-around">
                  {mensajeAlertaDatosGet !== null ? (
                    <p>Hubo Un error por favor recargue la página</p>
                  ) : misDatosGet === null ? (
                    <p></p>
                  ) : (
                    <>
                      <Button
                        onClick={actualizar}
                        name="secondary"
                        bgColor="white"
                      >
                        Actualizar
                      </Button>

                      <Button onClick={confirmar}>Confirmar</Button>
                    </>
                  )}
                </section>
              </div>
            )}
          </div>
        </section>
      </div>
    </section>
  );
};

export default withRouter(FormularioRatificaDatos);
