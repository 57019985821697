import {
    RETIRO_FONDO_LIBRE_CHANGE_PAGE_EXITOSO,
} from "../../../types/fondoLibre/RetiroFondoLibre";

export default (state, action) => {
    switch (action.type) {
        case RETIRO_FONDO_LIBRE_CHANGE_PAGE_EXITOSO:
            return {
                ...state,
                indexPage: action?.payload,
            }
        default:
            break;
    }
}