import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../../../../../components";
import JubilacionContext from "../../../../../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function Index(props) {
  const { prevView, endProcess } = props;

  const [location, setLocation] = useState(null);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarModalidad,
    guardarAPVf,

    crearProcesoRejaEtapa7,
    limpiarCrearProcesoRejaEtapa7,
    guardarPaso7Response,
    guardarPaso7ResponseLoading,
  } = jubilacionContext;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    return () => {
      limpiarCrearProcesoRejaEtapa7();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guardarPaso7Response) {
      if (guardarPaso7Response.success) {
        endProcess();
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarPaso7Response]);

  const HandlSaveModalidadPension = () => {
    const datos = {
      response: {
        modality: !guardarModalidad ? "pension" : "retiro",
        include_voluntary_contribution:
          guardarAPVf === null ? false : guardarAPVf,
        residency: location === false ? "extranjero" : "peru",
      },
    };

    crearProcesoRejaEtapa7(props.processId, datos);
  };

  const changeIconForLoading = () => {
    if (guardarPaso7ResponseLoading) {
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    } else {
      return false;
    }
  };

  return (
    <div className="detalle-info-container residencia">
      <h1 className="mb-3">Elección de Modalidad de Pensión </h1>

      <p className="text-residencia">
        Es importante que nos envíes la siguiente documentación para iniciar el
        proceso:
      </p>
      <div className="content-box">
        <div className="detalle-info-container">
          <p>
            1. Si cuentas con beneficiarios directos, revisa los documentos{" "}
            <a
              href="https://www.afphabitat.com.pe/pensiones/#mis-beneficiarios"
              target="__blank"
            >
              aquí
            </a>
            .
          </p>
          <p>
            2. Adjuntar su documento de identidad como titular (ambas caras).
          </p>
          <p>
            3. En caso hayas laborado en abril 2020, adjunta la boleta de pago
            correspondiente para contabilizar en el cálculo de la pensión.
          </p>
        </div>
        <p>
          Una vez cuentes con los documentos solicitados, envíalos al buzón{" "}
          <a href="mailto:tramitespensiones@afphabitat.com.pe">
            tramitespensiones@afphabitat.com.pe
          </a>
           con el siguiente asunto: Solicitud de pensión REJA – Tipo y número de
          documento – Nombre y Apellido.
        </p>
      </div>
      <div className="content-box">
        <p>
          Para finalizar por favor indíquenos si se encuentra en Perú o en el
          exterior:{" "}
        </p>
      </div>
      <div className="content-box">
        <div className="margin-container residencia-container">
          <div className="radio-container">
            <span className="oneChoice">
              <input
                type="radio"
                value={1}
                checked={location === true}
                className="radio-btn"
                id="tfa_730"
                name="location"
                onClick={() => setLocation(true)}
                disabled={guardarPaso7ResponseLoading}
              />
              <label className="postRadioField" id="tfa_730" for="tfa_730">
                <span className="input-radio-faux"></span>
                <p>
                  <strong>Estoy en Perú</strong>
                </p>
              </label>
            </span>
          </div>
          <div className="radio-container">
            <span className="oneChoice">
              <input
                type="radio"
                value={1}
                checked={location === false}
                className="radio-btn"
                id="tfa_731"
                name="location"
                onClick={() => setLocation(false)}
                disabled={guardarPaso7ResponseLoading}
              />
              <label className="postRadioField" id="tfa_731" for="tfa_731">
                <span className="input-radio-faux"></span>
                <p>
                  <strong>Estoy en el extranjero</strong>
                </p>
              </label>
            </span>
          </div>
        </div>
      </div>
      <p className="text-residencia">
        Validaremos tu información y enviaremos la fecha y hora de tu cita a tu
        correo registrado.
      </p>
      <div className="advice-card">
        <p>
          Recuerda tener tus datos de contacto actualizados y revisar la carpeta
          spam o no deseados de tu correo.
        </p>
      </div>

      <br />
      <br />
      <div className="container-reja-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className="mb-4 btn-modal-secondary"
          onClick={() => prevView()}
          disabled={guardarPaso7ResponseLoading}
        >
          Volver
        </Button>
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => HandlSaveModalidadPension()}
          disabled={location === null || guardarPaso7ResponseLoading}
        >
          Continuar {changeIconForLoading()}
        </Button>
      </div>
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
