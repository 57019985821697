import React from "react";
import styled from "styled-components";
import { ButtonFL, ContentFlex, FlexItem } from "..";

const DivContent = styled("div")`
  background: #ffffff;
  width: 100%;
  padding: 8px 0px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "0")};
`;

const BottomButtons = ({
  firstText = "Regresar",
  secondText = "Continuar",
  firstOnClick,
  flexDirection,
  secondOnClick,
  active,
  isMobile,
  activeSecond = false,
  width = "50%",
}) => {
  return (
    <DivContent active={active} isMobile={isMobile}>
      <ContentFlex
        padding="0px"
        color="#ffffff"
        flexDirection={isMobile ? "column" : "row"}
        style={{
          width: width,
          margin: "auto",
        }}
      >
        <FlexItem
          padding={isMobile ? "4px 0px" : "8px 0px"}
          width={isMobile ? "100%" : "100%"}
        >
          <ButtonFL
            active={active}
            isMobile={isMobile}
            onClick={active ? firstOnClick : null}
            width={isMobile ? "100%" : "auto"}
          >
            {firstText}
          </ButtonFL>
        </FlexItem>
        {activeSecond && (
          <FlexItem
            padding={isMobile ? "0" : "8px 0px"}
            width={isMobile ? "100%" : "100%"}
          >
            <ButtonFL
              primary
              isMobile={isMobile}
              onClick={active ? secondOnClick : null}
              width={isMobile ? "100%" : "auto"}
            >
              {secondText}
            </ButtonFL>
          </FlexItem>
        )}
      </ContentFlex>
    </DivContent>
  );
};

export default BottomButtons;
