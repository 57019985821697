import React, { useState, useEffect, useContext } from "react";
import { Button } from "../../../../../../../../components";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function Paso1(props) {
  const [check1, setCheck1] = useState(null);
  const [check2, setCheck2] = useState(null);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarPaso3Response,
    guardarPaso3ResponseLoading,
    crearProcesoRejaEtapa3,
    limpiarCrearProcesoRejaEtapa3,
  } = jubilacionContext;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    return () => {
      limpiarCrearProcesoRejaEtapa3();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guardarPaso3Response) {
      if (guardarPaso3Response.success) {
        if (props.haveBono) {
          props.endThirdStep();
        } else {
          props.showBonoView();
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarPaso3Response]);

  const logicToCheck = (value) => {
    if (
      (value === "0" && check1 === false) ||
      (value === "1" && check1 === true)
    )
      setCheck1(null);
    else if (
      (value === "0" && check1 === true) ||
      (value === "1" && check1 === false)
    )
      setCheck1(!check1);
    else {
      if (value === "1" && check1 === null) setCheck1(true);
      else setCheck1(false);
    }
  };
  const handleSaveCheck = () => {
    const obj = {
      content_id: props.idContent,
      response: {
        have_ruc: check1 === true,
        not_have_ruc: check1 === false,
        affidavit: check2,
      },
    };
    if (props.haveBono) {
      crearProcesoRejaEtapa3(props.idProcess, obj);
    } else {
      crearProcesoRejaEtapa3(props.idProcess, obj);

      props.setObjDesempleo(obj.response);
      props.showBonoView();
    }
  };

  const changeIconForLoading = () => {
    if (guardarPaso3ResponseLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <div>
      <div className="reja-declaracion">
        <h1 className="mb-2">DJ de Desempleo</h1>
        <p id="texto-frase">
          A continuación, le solicitamos revisar las siguientes declaraciones
          juradas.
        </p>
        <br />
        <div className="content-box">
          <h3>
            Anexo N.° 1 “DECLARACIÓN JURADA SITUACIÓN DE DESEMPLEO – LEY N.°
            31332”
          </h3>
          <p id="texto-declaracion">
            Yo <strong>{props.dependencies.full_name}</strong>, identificado con
            TIPO DE DOCUMENTO{" "}
            <strong>{props.dependencies.type_and_number_of_document}</strong>{" "}
            afiliado a AFP Habitat declaro bajo juramento que cumplo con la
            condición de registrar un período mínimo de doce (12) meses
            ininterrumpidos y consecutivos de desempleo con anterioridad a mi
            solicitud de acogimiento al Régimen Especial de Jubilación
            Anticipada para Desempleados del Sistema Privado de Administración
            de Fondos de Pensiones.
          </p>
          <p id="texto-declaracion">
            Asimismo, respecto del tratamiento de ingresos por cuarta categoría,
            declaro:
          </p>
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  checked={check1 === false}
                  disabled={guardarPaso3ResponseLoading}
                  className="radio-btn"
                  id="tfa_711"
                  name="beneficiarios"
                  onClick={(e) => logicToCheck(e.target.value)}
                />
                <label className="postRadioField" id="tfa_711" for="tfa_711">
                  <span className="input-radio-faux"></span>
                  <p>
                    No tener RUC, por lo cual declaro no haber percibido
                    ingresos por cuarta categoría.
                  </p>
                </label>
              </span>
            </div>
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  disabled={guardarPaso3ResponseLoading}
                  checked={check1 === true}
                  className="radio-btn"
                  id="tfa_712"
                  name="beneficiarios"
                  onClick={(e) => logicToCheck(e.target.value)}
                />
                <label className="postRadioField" id="tfa_712" for="tfa_712">
                  <span className="input-radio-faux"></span>
                  <p>
                    Sí tener RUC, pero haber percibido un monto igual o menor a
                    7 UIT en los últimos doce (12) meses anteriores a la
                    presentación de la solicitud de acogimiento al régimen,el
                    cual se validará mediante el “Reporte Tributario de Rentas
                    de Cuarta Categoría”, expedido por la SUNAT.
                  </p>
                </label>
              </span>
            </div>
          </div>
        </div>

        <p id="texto-declaracion">
          Adicionalmente, declaro conocer que si de la revisión efectuada por la
          AFP para la acreditación del acceso a alguno de los beneficios
          previstos por la Ley N.° 31332 se determinara que existen indicios
          razonables de alguna infracción administrativa, es decir, la
          utilización de cualquier medio fraudulento, astucia o ardid destinado
          a obtener para sí o terceros, un beneficio indebido, la AFP iniciará
          las acciones que correspondan. Asimismo, declaro conocer que, en caso
          ello se confirme y la pensión ya haya sido otorgada, quedo sujeto a
          poder perder la pensión y devolver lo indebidamente cobrado
        </p>

        <div className="content-box">
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  checked={check2}
                  value={1}
                  disabled={guardarPaso3ResponseLoading}
                  className="radio-btn"
                  id="tfa_730"
                  name="bono_reconocimiento"
                  onClick={() => setCheck2(!check2)}
                />
                <label className="postRadioField" id="tfa_730" for="tfa_730">
                  <span className="input-radio-faux"></span>
                  <p>
                    Afirmo y ratifico que todo lo mencionado en esta declaración
                    jurada es válida y por lo tanto asumo las responsabilidades
                    que este documento implica.{" "}
                  </p>
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className="container-reja-buttons">
          <Button
            name="secondary"
            bgColor="white"
            className={`mb-4 btn-modal-secondary`}
            onClick={() => props.getOutOfProcess()}
          >
            Cerrar
          </Button>
          <Button
            className={`mb-4 btn-modal-primary`}
            onClick={() => handleSaveCheck()}
            disabled={check1 === null || check2 === null}
          >
            Continuar {changeIconForLoading()}
          </Button>
        </div>
      </div>
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
