import React from "react";
import { Row, Col, CardBody } from "reactstrap";
const SinSaldo = ({ secuencial, deuda }) => {
  const handleClick = (e) => {
    e.preventDefault();
  };
  const client = JSON.parse(localStorage.getItem("client"));
  const capitalizarPrimeraLetra = (palabra) => {
    return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
  };

  return (
    <CardBody className="custom-card-body">
      <Row>
        <Col>
          <p className="text-justify">
            Hola {capitalizarPrimeraLetra(client.r_primer_nombre)},
          </p>
          <p className="text-justify">
            En AFP Habitat queremos asegurarnos de que estés al tanto de lo que
            sucede con tu fondo de jubilación. Por ello queremos comentarte que,
            en tu Cuenta Individual de Capitalización (CIC), no se han
            registrado nuevos aportes obligatorios durante el 2023.
          </p>
          <p className="text-justify">
            Es importante que tengas en cuenta que para mantener la cobertura
            del Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio activo
            debes haber realizado al menos cuatro aportes mensuales en los ocho
            meses anteriores al mes en que ocurra un siniestro.
          </p>
          <p className="text-justify">
            Este seguro te protege a ti y a tu familia en caso de una situación
            que te impida trabajar, otorgando una pensión de invalidez, pensión
            de sobrevivencia en caso de fallecimiento, y cubriendo los gastos de
            sepelio.
          </p>
          <p className="text-justify">
            Si eres un trabajador(a) dependiente y validas que en tus boletas de
            pago del año 2023 se ha realizado un descuento por concepto de
            aporte obligatorio a AFP Habitat, por favor envíanos una copia de
            la(s) boleta(s) al correo electrónico{" "}
            <a
              onClick={() => handleClick}
              href="mailto:miagenciavirtual@afphabitat.com.pe"
              rel="noopener noreferrer"
            >
              miagenciavirtual@afphabitat.com.pe
            </a>
            {". "}Es importante que las boletas cuenten con la firma y sello del
            representante legal de la empresa para que podamos iniciar la
            gestión de cobranza a tu empleador de manera adecuada.
          </p>
          <p className="text-justify">
            En caso de que tengas alguna duda o consulta, no dudes en
            contactarnos escribiendo a través del WhatsApp al 981-211-004 o
            llamando a nuestro Contact Center al 01-230-2200 desde Lima o al
            0-801-14-224 desde provincias. Nuestro horario de atención es de
            lunes a viernes de 9 a. m. a 6 p. m. y los sábados de 9 a. m. a 1 p.
            m.
          </p>
          {secuencial > 0 && (
            <div>
              <p className="text-justify">
                Finalmente, te informamos que, a la fecha tus aportes en
                cobranza son los siguientes:
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="custom-header-table-noa">
                        Periodo (año / mes)
                      </th>
                      <th className="custom-header-table-noa">
                        Estado de la deuda
                      </th>
                      <th className="custom-header-table-noa">
                        RUC del Empleador
                      </th>
                      <th className="custom-header-table-noa">
                        Razón Social del Empleador
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deuda.map((item, index) => (
                      <tr key={index} className="text-center custom-tr-noa">
                        <td>{item.r_periodo}</td>
                        <td>{item.r_estado_deuda}</td>
                        <td>{item.r_ruc_empleador}</td>
                        <td>{item.r_razon_empleador}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <p className="text-left">Atentamente,</p>
          <p className="text-left">
            <strong>AFP HABITAT</strong>
          </p>
        </Col>
      </Row>
    </CardBody>
  );
};

export default SinSaldo;
