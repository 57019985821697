import {
  RETIRO_25_POST_EXITOSO,
  RETIRO_25_POST_ERROR,
  RETIRO_25_POST_LOGIN,
  RETIRO_25_CLEAR,
  RETIRO_25_CLEAR_PARCIAL,
} from "../../types/retiro25";

export default (state, action) => {
  switch (action.type) {
    case RETIRO_25_CLEAR:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoRetiro25Post: null,
        mensajeAlertaRetiro25Post: null,
        loadingBtnRetiro25Post: false,
      };
    case RETIRO_25_CLEAR_PARCIAL:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoRetiro25Post: null,
        mensajeAlertaRetiro25Post: null,
        loadingBtnRetiro25Post: false,
      };
    case RETIRO_25_POST_EXITOSO:
      return {
        ...state,
        resultadoRetiro25Post: action.payload,
        mensajeAlertaRetiro25Post: null,
        loadingBtnRetiro25Post: false,
      };
    case RETIRO_25_POST_LOGIN:
      return {
        ...state,
        loadingBtnRetiro25Post: true,
        mensajeAlertaRetiro25Post: null,
      };
    case RETIRO_25_POST_ERROR:
      return {
        ...state,
        mensajeAlertaRetiro25Post: action.payload,
        loadingBtnRetiro25Post: false,
      };
    default:
      break;
  }
};
