import React, {useReducer} from 'react';
import SidebarContext from './sidebarContext';
import SidebarReducer from './sidebarReducer';


import {
    SLIDER_TOGGLE,
} from '../../types';

const SidebarState = props => {
    const initialState = {
        showSidebar: false,
    };

    const toogle = (option) => {
        dispatch({
            type:SLIDER_TOGGLE,
            payload: option
        })
    }

    const [state, dispatch] = useReducer(SidebarReducer, initialState);
    return (
        <SidebarContext.Provider
            value={{
                showSidebar: state.showSidebar,
                toogle
            }}
        >
            {props.children}
        </SidebarContext.Provider>
    );
};

export default SidebarState;