import React, { Component, Fragment } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

am4core.useTheme(am4themes_animated);
class GraficoFondo extends Component {
  constructor(props) {
    super(props);
    this["outerDiv" + props.idfondo] = React.createRef();
    this["containerScrollingWrapper" + props.idfondo] = React.createRef();
  }

  componentDidMount() {
    const { data, idfondo, isMobile } = this.props;
    this.drawGraphic(data.data);

    if (isMobile) {
      let outerDiv = this["outerDiv" + idfondo];
      let scrollingWrapper = this["containerScrollingWrapper" + idfondo];
      scrollingWrapper.current.scrollLeft = 9999999;

      outerDiv.current.onscroll = function () {
        scrollingWrapper.current.scrollLeft = outerDiv.current.scrollLeft;
      };

      scrollingWrapper.current.onscroll = function () {
        outerDiv.current.scrollLeft = scrollingWrapper.current.scrollLeft;
      };
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  drawGraphic = (dataArray) => {
    //const { data } = this.props;

    let chart = am4core.create(
      "chartdiv" + this.props.idfondo,
      am4charts.XYChart
    );

    // Enable chart cursor innerWidth pixelWidth
    // chart.cursor = new am4charts.XYCursor();
    // chart.cursor.lineX.disabled = true;
    // chart.cursor.lineY.disabled = true;
    chart.language.locale = am4lang_es_ES;
    if (this.props.isMobile) {
      chart.paddingRight = 0;
      chart.paddingLeft = 0;
    } else {
      chart.paddingRight = 100;
      chart.paddingLeft = 0;
    }

    if (!this.props.isMobile) {
      var scrollbarX = new am4core.Scrollbar();
      scrollbarX.marginBottom = 20;
      if (this.props.isMobile) {
        scrollbarX.paddingLeft = 14;
        scrollbarX.paddingRight = 14;
      }
      chart.scrollbarX = scrollbarX;
    }

    chart.data = dataArray;
    chart.language.locale["_decimalSeparator"] = ".";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.startLocation = 0.01;
    categoryAxis.endLocation = 0.95;
    categoryAxis.renderer.minLabelPosition = 0.0001;
    categoryAxis.renderer.maxLabelPosition = 0.95;
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.dx = -15;
    categoryAxis.renderer.labels.template.fontSize = "11px";
    categoryAxis.renderer.minGridDistance = 100;
    categoryAxis.renderer.grid.template.location = 0.5;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (!this.props.isMobile) {
      valueAxis.renderer.inside = false;
    }
    valueAxis.title.text = "Valor Cuota";
    valueAxis.tooltip.disabled = false;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.fontSize = "11px";

    // Linea Cuota Actual
    var axisRange0 = valueAxis.axisRanges.create();
    axisRange0.value = this.props.valcuota;
    axisRange0.grid.strokeOpacity = 1;
    axisRange0.grid.strokeWidth = 1;
    axisRange0.grid.strokeDasharray = "8,4";
    axisRange0.label.text =
      this.props.title + "\n" + "S/ " + this.props.valcuota;
    axisRange0.label.fill = am4core.color("#063E74");
    axisRange0.label.align = "right";
    axisRange0.label.verticalCenter = "middle";
    axisRange0.label.fillOpacity = 1;
    axisRange0.label.fontSize = "11px";

    valueAxis.renderer.labels.template.adapter.add(
      "text",
      function (value, target, key) {
        if (
          target.dataItem &&
          target.dataItem.component.dataFields.lineValCuota !== value
        ) {
          return "S/ " + value;
        }
        return "";
      }
    );

    valueAxis.renderer.gridContainer.zIndex = 1;

    /** Formateamos el número a 2 decimales */
    // valueAxis.numberFormatter.numberFormat = "#,###.00";

    let series = this.createSeries(
      this.props.idfondo,
      "date",
      this.props.title,
      chart,
      this.props.color
    );
    this.customSeriesColor(series, this.props.color);

    // this.createEventLine(
    //   this.props.data.events[this.props.idfondo],
    //   this.props.idfondo,
    //   chart
    // );

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panX";

    // chart.cursor.behavior = "none";
    // chart.cursor.lineY.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(2, 2, 2, 2);
    marker.strokeWidth = 2;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");
    chart.legend.itemContainers.template.events.on("hit", function (ev) {});
    this.chart = chart;

    if (this.props.isMobile) {
      axisRange0.label.paddingRight = "0px";
      axisRange0.label.text = "";
    } else {
      axisRange0.label.marginRight = "-" + (chart.innerWidth + 10) + "px";
    }
  };

  createSeries = (field, date, name, chart, customColor) => {
    let series = chart.series.push(new am4charts.LineSeries());
    if (this.props.isMobile) {
      series.hiddenInLegend = true;
    }

    var bullet = series.bullets.push(new am4charts.Bullet());
    bullet.disabled = true;
    bullet.propertyFields.disabled = "disabled";

    var square = bullet.createChild(am4core.Rectangle);
    square.width = 20;
    square.height = 20;
    square.horizontalCenter = "middle";
    square.verticalCenter = "middle";
    square.stroke = am4core.color("#2F4858");
    square.strokeWidth = 1;

    series.dataFields.valueY = field;
    series.dataFields.msgTooltip = field;
    series.dataFields.categoryX = "date";
    series.dataFields.categoryXX = "date2";
    series.name = name;
    series.strokeWidth = 2;
    series.tooltipHTML = "{msgTooltip}";

    series.stroke = am4core.color(customColor);

    return series;
  };

  customSeriesColor = (series, customColor) => {
    series.stroke = am4core.color(customColor);
    series.fill = am4core.color(customColor);
  };

  createEventLine(data, fondo, chart) {
    var trend = chart.series.push(new am4charts.LineSeries());

    if (this.props.isMobile) {
      trend.hiddenInLegend = true;
    }
    trend.name = "Eventos";
    trend.dataFields.valueY = "value";
    trend.dataFields.msgTooltip = "tooltip";
    trend.dataFields.categoryX = "date";
    trend.strokeWidth = 0;
    trend.stroke = trend.fill = am4core.color("#FF5E10");
    trend.data = data;

    var bullet = trend.bullets.push(new am4charts.CircleBullet());
    //bullet.tooltipHTML = "{date}<br>{msgTooltip}<br>Cuota: {valueY}";
    bullet.showTooltipOn = "always";
    bullet.tooltipHTML = "{msgTooltip}";
    bullet.strokeWidth = 1;
    bullet.stroke = am4core.color("#FF5E10");
    bullet.circle.fill = trend.stroke;

    var hoverState = bullet.states.create("hover");
    hoverState.properties.scale = 1.7;

    return trend;
  }

  render() {
    const { data, idfondo, valcuota, title, windowWidth, isMobile } =
      this.props;

    let widthContainer = "100%";
    let outerDivStyle = { overflowX: "auto", overflowY: "hidden" };
    let innerDivStyle = { paddingTop: "1px", width: widthContainer };

    let daterighfloat = 0;
    if (isMobile) {
      if (windowWidth > 365) {
        daterighfloat = 450;
      } else {
        daterighfloat = windowWidth + 134;
      }
    }

    return (
      <Fragment>
        {data && (
          <div
            className="container-graphic"
            style={{
              width: isMobile ? "calc(" + windowWidth + "px - 32px)" : "auto",
            }}
          >
            {isMobile && (
              <div>
                <p className="mb-0 mt-3">
                  <b>{title}:</b> Valor cuota actual {valcuota}
                </p>
              </div>
            )}

            {isMobile && (
              <div ref={this["outerDiv" + idfondo]} style={outerDivStyle}>
                <div style={innerDivStyle}>&nbsp;</div>
              </div>
            )}
            <div
            //   ref={this["containerScrollingWrapper" + idfondo]}
            //   id={"containerScrollingWrapper" + idfondo}
            //   className="scrolling-wrapper"
            >
              <div
                id={"chartdiv" + idfondo}
                style={{
                  width: widthContainer,
                  height: isMobile ? "400px" : "600px",
                }}
              ></div>
              <div
                className="date_fecha_fin_fondo"
                style={{ right: "calc(-" + daterighfloat + "px)" }}
              >
                {data.fecha_fin}
              </div>
            </div>
            <div className="row mt-3 d-none">
              <div className="col-4 col-md-4">
                <h5
                  className={isMobile ? "text-center mb-4" : "text-center mb-0"}
                >
                  Rango de fechas
                </h5>
              </div>
              <div className="col-8 col-md-8">
                <div
                  className={
                    isMobile
                      ? "flex-wrap text-center"
                      : "align-items-center d-flex justify-content-around w-100"
                  }
                >
                  <p className="mb-0">Fecha de inicio: {data.fecha_ini}</p>
                  <p className="mb-0">Fecha de fin: {data.fecha_fin}</p>
                </div>
              </div>
            </div>
            <div id="box-logo"></div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default GraficoFondo;
