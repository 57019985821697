import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
  LOGIN_LOADING,
  OBTENER_USUARIO,
  LOGIN_FORCE,
  LAYOUT_TIMER,
  RESET_TIMER,
  CAMBIO_CLAVE_EXITOSO,
  VALIDO_DATOS_EXITOSO,
  CLOSE_MODAL_CAMBIO_CLAVE,
  CLOSE_MODAL_VALIDO_DATOS,
  CLOSE_MODAL_BANNER,
  LOGIN_ERROR_SERVICE,
  ACCOUNT_BLOCK_LOADING,
  ACCOUNT_BLOCK_DATA,
  ACCOUNT_BLOCK_ERROR,
  ACCOUNT_BLOCK_CLEAR,
  ERROR_GENERICO,
} from "../../types";

const setCambioClave = (dato) => {
  let cambioClave;
  if (dato === "0") {
    cambioClave = {
      cambiar: true,
      loCambio: false,
    };
    sessionStorage.setItem("cambio_clave", JSON.stringify(cambioClave));
  } else {
    cambioClave = null;
  }
  return cambioClave;
};
const setActualizarDatos = (datos) => {
  const { r_validacion_datos, r_cambio_clave } = datos;
  let validoDatos;
  if (r_cambio_clave !== "0") {
    if (r_validacion_datos !== "0") {
      validoDatos = {
        cambiar: r_validacion_datos, // puede ser 1 (No tiene correo) // 2 ratificar datos.
        loCambio: false,
      };
      sessionStorage.setItem("valido_datos", JSON.stringify(validoDatos));
    } else {
      validoDatos = null;
    }
  } else {
    validoDatos = null;
  }

  return validoDatos;
};

export default (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
      sessionStorage.setItem("token", action.payload.r_token_acceso);
      sessionStorage.setItem("token_type", action.payload.r_tipo_token);
      sessionStorage.setItem("nombreUsuario", action.payload.r_primer_nombre);
      sessionStorage.setItem("genero", action.payload.gender);
      sessionStorage.setItem("birthday", action.payload.r_fecha_cumpleaños);
      sessionStorage.setItem("banner", action.payload.r_banner);
      return {
        ...state,
        autenticado: true,
        cambio_clave: setCambioClave(action.payload.r_cambio_clave),
        valido_datos: setActualizarDatos(action.payload),
        nombreUsuario: action.payload.r_primer_nombre,
        banner: action.payload.r_banner,
        mensaje: null,
        cargando: false,
        gender: action.payload.gender,
        birthday: action.payload.r_fecha_cumpleaños,
        loading: false,
        loginForce: false,
        dataBlock: action.payload.r_block,
        timer: action.payload.r_tiempo_sesion, // Cambiar
        simulador_apv: action.payload.simulador_apv,
      };
    case LOGIN_ERROR:
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_type");
      sessionStorage.removeItem("nombreUsuario");
      sessionStorage.removeItem("cambio_clave");
      sessionStorage.removeItem("valido_datos");
      return {
        ...state,
        token: null,
        autenticado: null,
        cambio_clave: null,
        valido_datos: null,
        mensaje: action?.payload?.r_errores?.r_mensaje,
        cargando: false,
        gender: null,
        birthday: null,
        loading: false,
        simulador_apv: null,
        dataBlock: action.payload.r_block,
      };
    case LOGIN_ERROR_SERVICE:
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_type");
      sessionStorage.removeItem("nombreUsuario");
      sessionStorage.removeItem("cambio_clave");
      sessionStorage.removeItem("valido_datos");
      return {
        ...state,
        token: null,
        autenticado: null,
        cambio_clave: null,
        valido_datos: null,
        mensaje: action.payload,
        cargando: false,
        gender: null,
        birthday: null,
        loading: false,
        simulador_apv: null,
        dataBlock: null,
      };
    case ACCOUNT_BLOCK_CLEAR:
      return {
        ...state,
        token: null,
        autenticado: null,
        cambio_clave: null,
        valido_datos: null,
        mensaje: null,
        cargando: false,
        loading: false,
        timer: 0,
        gender: null,
        birthday: null,
        reset: false,
        redirectHabitat: null,
        simulador_apv: null,
        dataBlock: null,

        dataUnBlockLoading: false,
        dataUnBlock: null,
        dataUnBlockError: null,
      };
    case CERRAR_SESION:
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_type");
      sessionStorage.removeItem("nombreUsuario");
      sessionStorage.removeItem("total");
      sessionStorage.removeItem("cambio_clave");
      sessionStorage.removeItem("valido_datos");
      sessionStorage.removeItem("opcionesMenu");

      /** esta sesion fue generada del homeReducer */
      sessionStorage.removeItem("fondos");
      return {
        ...state,
        token: null,
        autenticado: null,
        cambio_clave: null,
        valido_datos: null,
        mensaje: null,
        cargando: false,
        loading: false,
        timer: 0,
        gender: null,
        birthday: null,
        reset: false,
        redirectHabitat: action.payload,
        simulador_apv: null,
        dataBlock: null,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_FORCE:
      return {
        ...state,
        loginForce: action.payload,
        mensaje: action.payload === false ? null : state.mensaje,
      };
    case LAYOUT_TIMER:
      return {
        ...state,
        timer: action.payload.timer,
        reset: action.payload.reset,
      };
    case RESET_TIMER:
      return {
        ...state,
        reset: false,
      };
    case OBTENER_USUARIO:
      let nombreUsuario = sessionStorage.getItem("nombreUsuario");
      let genero = sessionStorage.getItem("genero");
      let cumpleaños = sessionStorage.getItem("birthday");
      let cambioClave = JSON.parse(sessionStorage.getItem("cambio_clave"));
      let validoDatos = JSON.parse(sessionStorage.getItem("valido_datos"));
      let autenticado = sessionStorage.getItem("token") ? true : false;
      return {
        ...state,
        autenticado: autenticado,
        cambio_clave: cambioClave,
        gender: genero,
        birthday: cumpleaños,
        valido_datos: validoDatos,
        nombreUsuario: nombreUsuario,
        mensaje: null,
        cargando: false,
      };
    case CAMBIO_CLAVE_EXITOSO:
      let localVariableCambioClave = {
        cambiar: true,
        loCambio: true,
      };
      sessionStorage.setItem(
        "cambio_clave",
        JSON.stringify(localVariableCambioClave)
      );
      return {
        ...state,
        cambio_clave: localVariableCambioClave,
      };
    case VALIDO_DATOS_EXITOSO:
      let localVariableValidoDatos = {
        cambiar: action.payload,
        loCambio: true,
      };
      sessionStorage.setItem(
        "valido_datos",
        JSON.stringify(localVariableValidoDatos)
      );
      return {
        ...state,
        valido_datos: localVariableValidoDatos,
      };
    case CLOSE_MODAL_CAMBIO_CLAVE:
      sessionStorage.removeItem("cambio_clave");
      return {
        ...state,
        cambio_clave: null,
      };
    case CLOSE_MODAL_VALIDO_DATOS:
      sessionStorage.removeItem("valido_datos");
      return {
        ...state,
        valido_datos: null,
      };
    case CLOSE_MODAL_BANNER:
      sessionStorage.removeItem("banner");
      return {
        ...state,
        banner: null,
      };
    case ACCOUNT_BLOCK_LOADING:
      return {
        ...state,

        dataUnBlockLoading: true,
      };
    case ACCOUNT_BLOCK_DATA:
      return {
        ...state,

        dataUnBlockLoading: false,
        dataUnBlock: action.payload,
        dataUnBlockError: null,
      };
    case ACCOUNT_BLOCK_ERROR:
      return {
        ...state,

        dataUnBlockLoading: false,
        dataUnBlock: null,
        dataUnBlockError: action.payload,
      };
    case ERROR_GENERICO:
      console.log('ACA')
      return {
        ...state,
        errorGenerico: action.payload
      };
    default:
      break;
  }
};
