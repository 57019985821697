import React, {
  useCallback,
  useRef,
  useState,
  useContext,
  createContext,
} from "react";
import { useFormContext } from "react-hook-form";
import {
  FileInputComponent,
  NoFilesContainerComponent,
  GrayButton,
  WithFilesContainerComponent,
  File,
  FileName,
  FileIcon,
  FilesContainer,
  FilesNote,
  DropdownFilesContainer,
  DropdownFilesWrapper,
  UploadAgain,
} from "./styles";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { BsFileEarmarkPdf, BsXLg, BsUpload } from "react-icons/bs";
import PropTypes from "prop-types";
import {
  fileType,
  getFakeFiles,
  maxSize,
  getFileIcon,
  getFileName,
  maxFilesValidation,
  dotFileName,
} from "../utils";
import Button from "../../../Buttons";
import Divider from "../../../Divider";
import { FileProps } from "../types";
import { FiRefreshCcw } from "react-icons/fi";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import getDeepValue from "../../../../utils/getDeepValue";
import { useEffect } from "react";
import useElementSize from "../../../../hooks/useElementSize";
import Upload from "../../../../assets/IconBucket/Upload";

const FileInputContext = createContext();

const SmallInput = (props) => {
  const {
    name,
    validate,
    required,
    maxFileSize,
    maxFiles,
    extensions,
    multi,
    truncateAt,
  } = props;

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext();
  register(name, {
    required: required,
    validate: {
      maxSize: (e) => maxSize(e, maxFileSize),
      fileType: (e) => fileType(e, extensions),
      maxFiles: (e) => maxFilesValidation(e, maxFiles),
      dotFileName: (e) => dotFileName(e),
      ...validate,
    },
  });

  const error = getDeepValue(errors, name);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles === "removeAllFiles") {
      setValue(name, null);
      trigger(name);
      return null;
    }

    if (acceptedFiles.length === 0) {
      return null;
    }

    setValue(name, acceptedFiles);
    trigger(name);
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open: openFileInput,
  } = useDropzone({
    onDrop,
    multiple: multi,
    noClick: true,
    noKeyboard: true,
  });

  const files = watch(name);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // onDrop(getFakeFiles(1));
    }
  }, []);

  return (
    <FileInputContext.Provider
      value={{
        error,
        files,
        multi,
        openFileInput,
        acceptedFiles,
        onDrop,
        maxFiles,
        truncateAt,
      }}
    >
      <FileInputComponent {...getRootProps()} error={error}>
        <input {...getInputProps()} />
        {!watch(name) && <NoFilesContainer />}
        {watch(name) && <WithFilesContainer />}
      </FileInputComponent>
    </FileInputContext.Provider>
  );
};

export default SmallInput;

SmallInput.propTypes = { ...FileProps };
SmallInput.defaultProps = {
  maxFiles: 2,
};

const NoFilesContainer = () => {
  const { error, files, multi, openFileInput, maxFiles } =
    useContext(FileInputContext);

  return (
    <NoFilesContainerComponent error={error}>
      <GrayButton icon={<FiUpload />} variant="link" onClick={openFileInput}>
        {!multi && "Subir un archivo"}
        {multi && `Subir archivos (máx. ${maxFiles})`}
      </GrayButton>
    </NoFilesContainerComponent>
  );
};

const WithFilesContainer = () => {
  const { error, files, multi, openFileInput, onDrop, maxFiles } =
    useContext(FileInputContext);

  // validar que pasa si elimina el archivo
  const [filesContainerIsOpen, setFilesContainerIsOpen] = useState(null);
  const handleFilesContainer = () => {
    if (multi) setFilesContainerIsOpen(!filesContainerIsOpen);
  };

  const dropdownFilesContainerRef = useRef(null);
  useOnClickOutside(dropdownFilesContainerRef, () =>
    setFilesContainerIsOpen(false)
  );

  const removeFiles = () => onDrop("removeAllFiles");
  const [filesContainerRef, { height, width: filesContainerWidth }] =
    useElementSize();

  const truncateAt =
    filesContainerWidth < 350
      ? 13
      : filesContainerWidth < 450
      ? 25
      : filesContainerWidth < 600
      ? 40
      : 1000;

  return (
    <WithFilesContainerComponent error={error} ref={filesContainerRef}>
      <DropdownFilesContainer error={error}>
        <DropdownFilesWrapper
          onClick={handleFilesContainer}
          error={error}
          open={filesContainerIsOpen}
          ref={dropdownFilesContainerRef}
        >
          {!multi && (
            <FilesNote error={error} open={filesContainerIsOpen}>
              <FileIcon>{getFileIcon({ path: files[0].path })}</FileIcon>
              <div>{getFileName({ name: files[0].name, truncateAt })}</div>
              <div className="remove" onClick={removeFiles}>
                <BsXLg />
              </div>
            </FilesNote>
          )}
          {multi && (
            <>
              <FilesNote error={error} open={filesContainerIsOpen}>
                <p>
                  {`${files.length} ${
                    files.length === 1 ? "archivo" : "archivos"
                  } (máx. ${maxFiles})`}
                </p>
              </FilesNote>
              {filesContainerIsOpen && (
                <>
                  <Divider bg="dark" />
                  <FilesContainer>
                    {files.map((file) => {
                      const { path, name, size, type } = file;

                      return (
                        <File
                          error={checkSizeIsOk(size, 2)}
                          open={filesContainerIsOpen}
                          key={path}
                        >
                          <FileIcon>{getFileIcon({ path })}</FileIcon>
                          <FileName>
                            {getFileName({ name, truncateAt })}
                          </FileName>
                        </File>
                      );
                    })}
                  </FilesContainer>
                  <Divider bg="dark" />
                  <GrayButton
                    variant="link"
                    size="sm"
                    icon={<FiRefreshCcw />}
                    onClick={removeFiles}
                  >
                    Vaciar
                  </GrayButton>
                </>
              )}
            </>
          )}
        </DropdownFilesWrapper>
      </DropdownFilesContainer>
      {multi && (
        <GrayButton variant="link" onClick={openFileInput}>
          Subir
        </GrayButton>
      )}

      {!multi && (
        <UploadAgain onClick={openFileInput}>
          <Upload />
        </UploadAgain>
      )}
    </WithFilesContainerComponent>
  );
};

export const checkSizeIsOk = (fileSize, maxSizeLimit) => {
  let fileIsBelowMaximumSize = fileSize / 1000 / 1000 <= maxSizeLimit;

  if (fileIsBelowMaximumSize) {
    return false;
  }

  return true;
};
