import React from "react";
import "./index.css";
const Loading = () => {
  return (
    <div className="container-fluid custom-loading-container">
      <div className="contenedor-text-spinner">
          <span className="loadtext">CARGANDO</span>
          <div className="spinner"></div>
        </div>
    </div>
  );
};

export default Loading;
