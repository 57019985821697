import React from "react";
import "./index.css";
const ModalDetalle = ({ dispositivo, buttonCloseTop, info, titulo, closeModal }) => {
  return (
    <section className={buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`}>
      <div className={buttonCloseTop ? `modal-content` : `modal-content modal-content-full`}>
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={closeModal()}></i>
          </div>
        )}

        <div className={buttonCloseTop ? `mb-modal-title d-flex mt-3` : `mb-modal-title d-flex`}>
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>{titulo}</p>
        </div>
        <section>
          {info.map((item, index) => (
            <div
              key={index}
              className="px-3 py-2 d-flex justify-content-between mb-table-modal"
            >
              <p className="m-0">{item.titulo}</p>
              <p className="m-0 text-right">{item.descripcion}</p>
            </div>
          ))}
        </section>
      </div>
    </section>
  );
};

export default ModalDetalle;
