import { MenuItem } from "@mui/material";
import styled, { css } from "styled-components";
import * as Switch from "@radix-ui/react-switch";
import Select from "react-select";
import MaskedInput from "react-text-mask";

export const InformacionDeInversionComponente = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 20px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const MontoAInvertirComponent = styled.div`
  position: relative;
`;

export const FrecuenciaDeDebitoComponente = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

export const FrecuenciaDeDebitoHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 8px;
  align-items: center;
  grid-column: 1/-1;
`;

export const SectionTitleComponent = styled.p`
  ${(p) => p.theme.tipography.bodySmallMedium}
  grid-column: 1/-1;
  margin-bottom: 8px;

  span {
    color: ${(p) => p.theme.palette.primary.main};
  }

  &.frecuencia {
    grid-column: auto;
    margin-bottom: 0px;
  }
`;

export const FechaDeCargoComponent = styled.div`
  position: relative;
`;

export const CargoDobleComponent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: start;
  ${(p) => p.theme.tipography.bodySmallMedium};
  p.lable {
    margin-bottom: 0;
    margin-right: 8px;
  }
`;

export const SelectOption = styled(MenuItem)`
  display: 100%;
  background-color: red;
  color: red;
`;

export const CargoDobleBotton = styled.div`
  margin-left: 16px;
`;

export const SwitchRoot = styled(Switch.Root)`
  background-color: gray;
  border: none;
  width: 52px;
  height: 32px;
  padding: 2px 4px;
  border-radius: 100px;
  display: grid;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &[data-state="checked"] {
    background-color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const SwitchThumb = styled(Switch.Thumb)`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: block;
  padding: 0;
  background-color: white;
  align-self: center;
  margin-left: auto;
  transition: all 200ms ease-in-out;
  margin-left: 0%;
  background-color: ${(p) => p.theme.palette.grayscale[50]};

  &[data-state="checked"] {
    margin-left: calc(100% - 24px);
  }
`;

export const DebitDateSelect = styled(Select)`
  .select__control {
    background: red;
    background-color: ${(p) => p.theme.palette.grayscale[50]};
    border-radius: 0;
    padding: 8px 16px;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 16px;
  }

  .select__indicators {
    display: none;
  }

  .select__value-container {
    margin: 0;
    padding: 0;
  }

  .select__menu {
    border-radius: 16px;
    overflow: hidden;
  }
  .select__menu-list {
    padding: 0;
  }

  .select__option {
    background-color: ${(p) => p.theme.palette.white};

    ${(p) => p.theme.tipography.bodySmallRegular}
    &:hover {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
      color: ${(p) => p.theme.palette.grayscale[800]};
    }

    &.select__option--is-selected {
      color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.primary[50]};
    }
  }
`;

export const MensajeCamposObligatorios = styled.div`
  ${(p) => p.theme.tipography.bodySmallMedium}
  color: ${(p) => p.theme.palette.grayscale[600]};

  span {
    color: ${(p) => p.theme.palette.state.error};
  }
`;

export const CardSelectComponent = styled.button`
  padding: 10px 16px;
  border: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  box-shadow: ${(p) => p.theme.elevations.below};
  align-items: center;
  cursor: pointer;
  letter-spacing: 0px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.grayscale[800]};

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      color: ${(p) => p.theme.palette.grayscale[400]};
    `}

  ${(p) =>
    p.isChecked &&
    css`
      border-color: ${(p) => p.theme.palette.primary.main};
    `}

  .radio {
    width: 24px;
    height: 24px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
    border-radius: 100px;

    ${(p) =>
      p.isChecked &&
      css`
        border-color: ${(p) => p.theme.palette.primary.main};
        background-color: ${(p) => p.theme.palette.primary.main};
      `}

    svg {
      position: relative;
      width: 12px;
      top: 1px;
      height: 12px;
      color: ${(p) => p.theme.palette.white};
      display: none;
      ${(p) =>
        p.isChecked &&
        css`
          display: block;
        `}
    }
  }

  .label {
    margin-bottom: 0;
  }
`;

export const DebitFrecuencyCardWrapper = styled.div`
  position: relative;
  display: grid;
`;
export const ModalTrigger = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  background-color: transparent;
`;

const inputStyled = css`
  padding: 10px 16px;
  border-radius: 16px;
  ${(p) => p.theme.tipography.bodyMediumRegular};
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  color: ${(p) => p.theme.palette.textColor.main};
  border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
  outline: none;
  width: 100%;

  &:focus,
  &:hover {
    border-color: ${(p) => p.theme.palette.grayscale[800]};
  }

  &:disabled {
    color: ${(p) => p.theme.palette.grayscale[200]};
  }

  &.error {
    border-color: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
  }

  ${(p) =>
    p.size === "sm" &&
    css`
      padding: 8px 16px;
      ${(p) => p.theme.tipography.caption};
    `}

  ${(p) =>
    p.size === "lg" &&
    css`
      padding: 14px 16px;
      ${(p) => p.theme.tipography.bodyMediumRegular};
    `}


  &::placeholder {
    color: ${(p) => p.theme.palette.grayscale[400]};
  }
`;

export const InputComponent = styled.input`
  ${inputStyled}
`;

export const MoneyInput = styled(MaskedInput)`
  ${inputStyled}
`;

export const ErrorMessage = styled.p`
  position: absolute;
  width: 100%;
  top: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.palette.state.error};
  margin-bottom: 0;
`;
