import {
  JUBPMO_ACCESS_EXITOSO,
  JUBPMO_ACCESS_ERROR,
  JUBPMO_ACCESS_LOADING,
  JUBPMO_ASESORIA_ERROR,
  JUBPMO_ASESORIA_EXITOSO,
  JUBPMO_ASESORIA_LOADING,
  JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR,
  JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO,
  JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING,
  JUBPMO_CALCULO_LOADING,
  JUBPMO_CALCULO_EXITOSO,
  JUBPMO_CALCULO_ERROR,
  JUBPMO_ACOGIMIENTO_ERROR,
  JUBPMO_ACOGIMIENTO_EXITOSO,
  JUBPMO_ACOGIMIENTO_LOADING,
  JUBPMO_RESETEAR_ESTADOS,
  JUBPMO_RESETEAR_PREV_CALCULO,
  JUBPMO_DECISION_VALIDAR_PMO_EXITOSO,
  JUBPMO_DECISION_VALIDAR_PMO_LOADING,
  JUBPMO_DECISION_VALIDAR_PMO_ERROR,
  JUBPMO_DECISION_VALIDAR_PMO_CLEAR,
  JUBPMO_DOCUMENTO_PMO_EXITOSO,
  JUBPMO_DOCUMENTO_PMO_LOADING,
  JUBPMO_DOCUMENTO_PMO_ERROR,
  JUBPMO_DOCUMENTO_PMO_CLEAR,
  JUBPMO_DECISION_PMO_EXITOSO,
  JUBPMO_DECISION_PMO_LOADING,
  JUBPMO_DECISION_PMO_ERROR,
} from "../../../types/jubilacion/pmo";
import { initialState } from "./jubPmoState";

export default (state, action) => {
  switch (action.type) {
    case JUBPMO_ACCESS_EXITOSO:
      return {
        ...state,
        dataAccessPmo: action.payload,
        loadingAccessPmo: false,
        errorAccespmo: null,
      };
    case JUBPMO_ACCESS_ERROR:
      return {
        ...state,
        dataAccessPmo: null,
        loadingAccessPmo: false,
        errorAccespmo: action.payload,
      };
    case JUBPMO_ACCESS_LOADING:
      return {
        ...state,
        loadingAccessPmo: true,
      };

    case JUBPMO_ASESORIA_EXITOSO:
      return {
        ...state,
        mensajeAsesoria: action.payload,
        loadingAsesoria: false,
        errorAsesoria: null,
      };
    case JUBPMO_ASESORIA_ERROR:
      return {
        ...state,
        mensajeAsesoria: null,
        loadingAsesoria: false,
        errorAsesoria: action.payload,
      };
    case JUBPMO_ASESORIA_LOADING:
      return {
        ...state,
        loadingAsesoria: true,
      };

    case JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO:
      return {
        ...state,
        datoEliminarAcogimiento: action.payload,
        loadingEliminarAcogimiento: false,
        errorEliminarAcogimiento: null,
      };
    case JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR:
      return {
        ...state,
        datoEliminarAcogimiento: null,
        loadingEliminarAcogimiento: false,
        errorEliminarAcogimiento: action.payload,
      };
    case JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING:
      return {
        ...state,
        loadingEliminarAcogimiento: true,
      };

    case JUBPMO_CALCULO_EXITOSO:
      return {
        ...state,
        dataCalculo: action.payload,
        loadingCalculo: false,
        errorCalculo: null,
      };
    case JUBPMO_CALCULO_ERROR:
      return {
        ...state,
        mensajeAcogimiento: null,
        loadingCalculo: false,
        errorCalculo: action.payload,
      };
    case JUBPMO_CALCULO_LOADING:
      return {
        ...state,
        loadingCalculo: true,
      };

    case JUBPMO_ACOGIMIENTO_EXITOSO:
      return {
        ...state,
        dataAcogimiento: action.payload,
        loadingAcogimiento: false,
        errorAcogimiento: null,
      };
    case JUBPMO_ACOGIMIENTO_ERROR:
      return {
        ...state,
        dataAcogimiento: null,
        loadingAcogimiento: false,
        errorAcogimiento: action.payload,
      };
    case JUBPMO_ACOGIMIENTO_LOADING:
      return {
        ...state,
        loadingAcogimiento: true,
      };
    case JUBPMO_RESETEAR_ESTADOS:
      return { ...state, ...initialState };
    case JUBPMO_RESETEAR_PREV_CALCULO:
      return {
        ...state,
        mensajeAsesoria: null,
        loadingAsesoria: false,
        errorAsesoria: null,
        dataCalculo: null,
        loadingCalculo: false,
        errorCalculo: null,
      };

    case JUBPMO_DECISION_VALIDAR_PMO_EXITOSO:
      return {
        ...state,
        dataDecisionValiarPmo: action.payload,
        loadingDecisionValiarPmo: false,
        errorDecisionValiarPmo: null,
      };
    case JUBPMO_DECISION_VALIDAR_PMO_ERROR:
      return {
        ...state,
        dataDecisionValiarPmo: null,
        loadingDecisionValiarPmo: false,
        errorDecisionValiarPmo: action.payload,
      };
    case JUBPMO_DECISION_VALIDAR_PMO_LOADING:
      return {
        ...state,
        loadingDecisionValiarPmo: true,
      };
    case JUBPMO_DECISION_VALIDAR_PMO_CLEAR:
      return {
        ...state,
        dataDecisionValiarPmo: null,
        loadingDecisionValiarPmo: false,
        errorDecisionValiarPmo: null,
      };
    case JUBPMO_DOCUMENTO_PMO_EXITOSO:
      return {
        ...state,
        dataDocumentoPmo: action.payload,
        loadingDocumentoPmo: false,
        errorDocumentoPmo: null,
      };
    case JUBPMO_DOCUMENTO_PMO_ERROR:
      return {
        ...state,
        dataDocumentoPmo: null,
        loadingDocumentoPmo: false,
        errorDocumentoPmo: action.payload,
      };
    case JUBPMO_DOCUMENTO_PMO_LOADING:
      return {
        ...state,
        loadingDocumentoPmo: true,
      };
    case JUBPMO_DOCUMENTO_PMO_CLEAR:
      return {
        ...state,
        dataDocumentoPmo: null,
        loadingDocumentoPmo: false,
        errorDocumentoPmo: null,
      };
    case JUBPMO_DECISION_PMO_EXITOSO:
      return {
        ...state,
        dataDecisionPmo: action.payload,
        loadingDecisionPmo: false,
        errorDecisionPmo: null,
      };
    case JUBPMO_DECISION_PMO_ERROR:
      return {
        ...state,
        dataDecisionPmo: null,
        loadingDecisionPmo: false,
        errorDecisionPmo: action.payload,
      };
    case JUBPMO_DECISION_PMO_LOADING:
      return {
        ...state,
        loadingDecisionPmo: true,
      };
    default:
      break;
  }
};
