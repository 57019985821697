import React, { useState } from "react";
import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import Lirycs from "./lyrics";
import AaceptEtario from "./AceptEtario";

const Index = (props) => {
  const {
    setEtario,
    cambiarFondoEtario,
    datosCambioFondo,
    setStep,
    setFondoActual,
    validacionEtario,
    validateEtareo,
  } = props;

  const [next, setNextView] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(true);
  return (
    <Box sx={{ height: "100%" }}>
      {next && (
        <AaceptEtario
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          setEtario={setEtario}
          cambiarFondoEtario={cambiarFondoEtario}
          datosCambioFondo={datosCambioFondo}
          setFondoActual={setFondoActual}
          setStep={setStep}
          validacionEtarioFunction={validacionEtario}
          validacionDatos={validateEtareo}
        />
      )}
      <Lirycs
        setNextView={setNextView}
        setEtario={setEtario}
        validacionEtario={validateEtareo}
        setOpenConfirm={setOpenConfirm}
      />
    </Box>
  );
};

export default Index;
