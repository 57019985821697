import styled, { css } from "styled-components";
import Select from "react-select";

export const SelectComponent = styled(Select)`
  .select__control {
    background: red;
    background-color: ${(p) => p.theme.palette.grayscale[50]};
    border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
    border-radius: 0;
    padding: 4px 16px;
    outline: none;
    box-shadow: none;
    border-radius: 16px;

    &:active {
      border-color: ${(p) => p.theme.palette.grayscale[700]};
    }

    &:hover {
      border-color: ${(p) => p.theme.palette.grayscale[50]};
    }

    &.select__control--menu-is-open {
      border-color: ${(p) => p.theme.palette.grayscale[700]};

      .select__indicators {
        transform: rotate(180deg);
      }
    }

    ${(p) =>
      p.error &&
      css`
        border-color: ${(p) => p.theme.palette.state.error};
      `}
  }

  .select__placeholder {
    white-space: nowrap;
    color: ${(p) => p.theme.palette.grayscale[200]};
  }

  .select__indicators {
    /* display: none; */
    .select__indicator-separator {
      display: none;
    }
  }

  .select__value-container {
    margin: 0;
    padding: 0;
  }

  .select__menu {
    border-radius: 8px;
    overflow: hidden;
  }
  .select__menu-list {
    padding: 0;
  }

  .select__option {
    background-color: ${(p) => p.theme.palette.white};
    border-radius: 8px;

    ${(p) => p.theme.tipography.bodySmallRegular}
    &:hover {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
      color: ${(p) => p.theme.palette.grayscale[800]};
    }

    &.select__option--is-selected {
      color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.primary[50]};
    }
  }
`;
