import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import hocVariables from "../../../../HOC/HocVariables";
import AuthContext from "../../../../context/autenticacion/authContext";
import logo from "../../../../assets/img/logo.png";
import "./index.css";
import logoutIcon from "../../../../assets/logout-icon.svg";
import blackArrowDown from "../../../../assets/icons/akar-icons_chevron-down.svg";
import blackArrowUp from "../../../../assets/icons/akar-icons_chevron-up.svg";
import whiteArrowDown from "../../../../assets/icons/white_chevron-down.svg";
import whiteArrowUp from "../../../../assets/icons/white_chevron-up.svg";
import redArrowRight from "../../../../assets/icons/red-right-arrow.svg";
import logoutIconHover from "../../../../assets/icons/icon-logout.svg";
import Collapse from "@mui/material/Collapse";

const Container = styled.div`
  flex-shrink: 0;
  width: 300px;
  z-index: 3;
`;

const Content = styled.div`
  background-color: #fff;
  color: #212b36;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  border-right: 1px solid rgba(145, 158, 171, 0.24);
  width: 300px;
  border-right-style: dashed;
`;

const ContainerOptionMenu = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;
function OptionMenu(props) {
  const {
    id,
    title,
    icon,
    iconHover,
    altIcon,
    subOptions,
    optionSelected,
    setOptionSelected,
    limpiarVariablesContext,
    sidebarOptions,
    history,
    location,
    connectEtario,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const { cerrarSesion } = authContext;
  useEffect(() => {
    if (id === optionSelected) setIsOpen(true);
    else setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelected]);
  const closeSesion = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(true, history.location);
    limpiarVariablesContext();
  };

  return (
    <ContainerOptionMenu>
      {props.url ? (
        <div
          className="desktop-menu_option"
          onClick={() => localStorage.setItem("firstUpdateData", false)}
        >
          <Link to={props.url}>
            <img
              className="desktop-menu_option-icon icon-1"
              src={icon}
              alt={altIcon}
            />
            <img
              className="desktop-menu_option-icon icon-2"
              src={iconHover}
              alt={altIcon}
            />
            <span className="desktop-menu_option-title">{title}</span>
          </Link>
        </div>
      ) : (
        <div
          className="desktop-menu_option option-menu"
          style={{
            display:
              !props.sidebarOptions.tfehow &&
              !props.sidebarOptions.retiro25Show &&
              props.id === "retTfe" &&
              "none",
          }}
          onClick={() => {
            if (id === "logout") {
              closeSesion();
            }
            connectEtario(false);
            if (isOpen) setOptionSelected("");
            else setOptionSelected(id);
          }}
        >
          <img
            className="desktop-menu_option-icon icon-1"
            src={icon}
            alt={altIcon}
          />
          <img
            className="desktop-menu_option-icon icon-2"
            src={iconHover}
            alt={altIcon}
          />
          <span className="desktop-menu_option-title">{title}</span>
          {subOptions && (
            <img
              className="desktop-menu_option-arrow icon-arrow-1"
              src={isOpen ? blackArrowUp : blackArrowDown}
              alt="fecha-negra-abajo"
            />
          )}
          {subOptions && (
            <img
              className="desktop-menu_option-arrow icon-arrow-2"
              src={isOpen ? whiteArrowDown : whiteArrowUp}
              alt="fecha-blanca-abajo"
            />
          )}
        </div>
      )}

      <Collapse in={isOpen}>
        <div className="desktop-menu_sub-options-container">
          {subOptions &&
            subOptions
              .filter((e) => e.show !== false)
              .map((option, index) => {
                return (
                  <div
                    key={`suboption-${option.id}`}
                    className={
                      "desktop-menu_sub-option " +
                      (location.pathname === option.url && "checked")
                    }
                    style={{
                      display:
                        (!sidebarOptions.cambioFondo &&
                          !sidebarOptions.retiroApv &&
                          option.id === "sub-retiro") ||
                        (!props.sidebarOptions.retiro25Show &&
                          option.id === "sub-credito") ||
                        (!props.sidebarOptions.tfehow &&
                          option.id === "sub-exterior") ||
                        (!props.sidebarOptions.showAporteMensual &&
                          option.id === "debito-mensual")
                          ? "none"
                          : "block",
                    }}
                  >
                    <Link to={option.url}>
                      <span>{option.title}</span>

                      <img src={redArrowRight} alt="red-arrow-right" />
                    </Link>
                  </div>
                );
              })}
        </div>
      </Collapse>
    </ContainerOptionMenu>
  );
}

const DesktopMenu = (props) => {
  const {
    optionMenuArray,
    optionSelected,
    setOptionSelected,
    history,
    limpiarVariablesContext,
    sidebarOptions,
    location,
    connectEtario,
  } = props;

  // console.log({ sidebarOptions });

  const deleteObj = (array) => {
    let filteredArray = JSON.parse(JSON.stringify(array));

    if (filteredArray) {
      if (sidebarOptions.cambioFondo && !sidebarOptions.retiroApv) {
        filteredArray[2].subMenu[2].show = false;
      }
      if (!sidebarOptions.tfehow && !sidebarOptions.retiro25Show) {
        filteredArray[3].show = false;
      } else {
        if (!sidebarOptions.tfehow) {
          filteredArray[3].subMenu[1].show = false;
        } else if (!sidebarOptions.retiro25Show) {
          filteredArray[3].subMenu[0].show = false;
        }
      }
    }
    return filteredArray;
  };

  return (
    <Container>
      <Content className="desktop-menu_container">
        <div className="header-menu_navbar-img">
          <img src={logo} alt="habitat-logo" />
        </div>
        <div className="body-menu_navbar">
          <div className="mb-5">
            {deleteObj(optionMenuArray)
              .filter((e) => e.show !== false)
              .map((value, index) => (
                <OptionMenu
                  key={value.id}
                  id={value.id}
                  title={value.title}
                  icon={value.icon}
                  iconHover={value.iconHover}
                  url={value.url}
                  altIcon={value.altIcon}
                  subOptions={value.subMenu}
                  optionSelected={optionSelected}
                  setOptionSelected={setOptionSelected}
                  history={history}
                  limpiarVariablesContext={limpiarVariablesContext}
                  sidebarOptions={sidebarOptions}
                  location={location}
                  connectEtario={connectEtario}
                />
              ))}
          </div>
          <div>
            {/* <OptionMenu
              id="help"
              title="Ayuda"
              icon={questionIcon}
              iconHover={helpIcon}
              // url={"/"}
              altIcon="question-icon"
              subOptions={null}
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              limpiarVariablesContext={limpiarVariablesContext}
              history={history}
            /> */}

            <OptionMenu
              id="logout"
              title="Cerrar Sesión"
              icon={logoutIcon}
              iconHover={logoutIconHover}
              // url={"/"}
              altIcon="logout-icon"
              subOptions={null}
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              limpiarVariablesContext={limpiarVariablesContext}
              history={history}
              sidebarOptions={sidebarOptions}
              connectEtario={connectEtario}
            />
          </div>
        </div>
      </Content>
    </Container>
  );
};
export default hocVariables(DesktopMenu);
