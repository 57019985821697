import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "../../../../../../components/Buttons";
import { ContentPage } from "../../styles/Table";
import { ModalBeneficiarios } from "../../component/Modals";
import { PMOBeneficiariesProvider } from "./beneficiariesContext";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useBeneficiariesContext } from "./beneficiariesContext";
import UploadDocumentsModal from "../Modals/UploadDocumentsModal/UploadDocumentsModal";
import moment from "moment";
let relationships = {
  1: "Cónyuge/Concubino(a)",
  2: "Hijo(a)",
  3: "Padre",
  4: "Madre",
};

const Index = ({
  children,
  setIsOpenModalBeneficiarios,
  beneficiarios,
  ...props
}) => {
  return (
    <ContentPage sx={{ width: "100%", overflow: "hidden" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Parenteso</TableCell>
                <TableCell align="center">Edad</TableCell>
                <TableCell align="center">Sexo</TableCell>
                <TableCell align="center">Documentos</TableCell>
              </TableRow>
            </TableHead>
            <TableBodyComponent beneficiarios={beneficiarios} />
          </Table>
        </TableContainer>
      </Paper>
    </ContentPage>
  );
};

export default Index;

const TableBodyComponent = ({ beneficiarios }) => {
  const { state, requestBeneficiaries } = useBeneficiariesContext();
  const { getValues } = useFormContext();

  useEffect(() => {
    requestBeneficiaries(beneficiarios || []);
  }, [beneficiarios]);

  return (
    // <FormProvider {...formMethods}>
    <TableBody>
      {state.data.length > 0 &&
        state.data.map((data, index) => (
          <BeneficiaryRow key={data.id} data={data} />
        ))}
    </TableBody>
    // </FormProvider>
  );
};

const BeneficiaryRow = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const uploadButtonIsDisabled = ["success", "loading"].includes(
    data.documentState
  );

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center">
          {relationships[data.relationship_id]}
        </TableCell>
        <TableCell align="center">
          {moment().diff(new Date(data?.birthday), "years")}
        </TableCell>
        <TableCell align="center">{data?.gender}</TableCell>
        <TableCell align="center">
          <Button
            variant="secondary"
            onClick={() => setModalIsOpen(true)}
            disabled={uploadButtonIsDisabled}
          >
            {data.documentState === "loading" && "Cargando ..."}
            {data.documentState === "fail" && "Subir"}
            {data.documentState === "idle" && "Subir"}
            {data.documentState === "success" && "Cargado"}
          </Button>
        </TableCell>
      </TableRow>
      <UploadDocumentsModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        data={data}
      />
    </>
  );
};

Index.propTypes = {
  children: PropTypes.node,
};

Index.defaultProps = {};
