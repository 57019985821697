import React from "react";
import CloseIcon from "../../../assets/IconBucket/CloseIcon";
import Modal from "../../../components/BasicModal";
import Button from "../../../components/Buttons";
import { useMonthlyDebitDetailsContext } from "../context";
import { ButtonWrapper, ModalIcon, ModalTitle, ModalTitleText } from "./styles";
import Divider from "../../../components/Divider";

const ImpossibleDeactivationModal = () => {
  const {
    state: { monthlyDebitDetails, impossibleDeactivationModal },
    ...api
  } = useMonthlyDebitDetailsContext();

  const { v_fecha_cargo } = monthlyDebitDetails.data;

  const rangeFormatted = () => {
    return `${v_fecha_cargo.split(" ")[0]} y el ${v_fecha_cargo.split(" ")[2]}`;
  };

  // console.log({ rangeFormatted: rangeFormatted() });

  return (
    <Modal
      open={impossibleDeactivationModal}
      onClose={api.closeImpossibleDeactivationModal}
    >
      <ModalTitle>
        <ModalIcon>
          <CloseIcon />
        </ModalIcon>
        <ModalTitleText>
          Aún no puedes desactivar tu débito mensual
        </ModalTitleText>
      </ModalTitle>
      <Modal.Subtitle>
        Tienes una inversión programada entre el día {rangeFormatted()} de este
        mes. Podrás desactivar tu débito mensual después de ese rango.
      </Modal.Subtitle>
      <Divider />
      <Button
        style={{ margin: "auto" }}
        fullWidth
        onClick={api.closeImpossibleDeactivationModal}
      >
        Entendido
      </Button>
    </Modal>
  );
};

export default ImpossibleDeactivationModal;
