import React, { useReducer } from "react";
import JubilacionContext from "./jubPmoContext";
import JubilacionReducer from "./jubPmoReducer";
import clienteAxios from "../../../config/axios";
import {
  JUBPMO_ACCESS_EXITOSO,
  JUBPMO_ACCESS_ERROR,
  JUBPMO_ACCESS_LOADING,
  JUBPMO_ASESORIA_ERROR,
  JUBPMO_ASESORIA_EXITOSO,
  JUBPMO_ASESORIA_LOADING,
  JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR,
  JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO,
  JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING,
  JUBPMO_CALCULO_LOADING,
  JUBPMO_CALCULO_EXITOSO,
  JUBPMO_CALCULO_ERROR,
  JUBPMO_ACOGIMIENTO_ERROR,
  JUBPMO_ACOGIMIENTO_EXITOSO,
  JUBPMO_ACOGIMIENTO_LOADING,
  JUBPMO_RESETEAR_ESTADOS,
  JUBPMO_RESETEAR_PREV_CALCULO,
  JUBPMO_DECISION_VALIDAR_PMO_EXITOSO,
  JUBPMO_DECISION_VALIDAR_PMO_LOADING,
  JUBPMO_DECISION_VALIDAR_PMO_ERROR,
  JUBPMO_DECISION_VALIDAR_PMO_CLEAR,
  JUBPMO_DOCUMENTO_PMO_EXITOSO,
  JUBPMO_DOCUMENTO_PMO_LOADING,
  JUBPMO_DOCUMENTO_PMO_ERROR,
  JUBPMO_DOCUMENTO_PMO_CLEAR,
  JUBPMO_DECISION_PMO_EXITOSO,
  JUBPMO_DECISION_PMO_LOADING,
  JUBPMO_DECISION_PMO_ERROR,
} from "../../../types/jubilacion/pmo";

export const initialState = {
  dataAccessPmo: null,
  mensajeAccesPmo: null,
  loadingAccessPmo: false,
  errorAccespmo: null,
  mensajeAsesoria: null,
  loadingAsesoria: false,
  errorAsesoria: null,
  datoEliminarAcogimiento: null,
  loadingEliminarAcogimiento: false,
  errorEliminarAcogimiento: null,
  dataCalculo: null,
  loadingCalculo: false,
  errorCalculo: null,
  dataAcogimiento: null,
  loadingAcogimiento: false,
  errorAcogimiento: null,
  dataDecisionValiarPmo: null,
  loadingDecisionValiarPmo: false,
  errorDecisionValiarPmo: null,
  dataDocumentoPmo: null,
  loadingDocumentoPmo: false,
  errorDocumentoPmo: null,
  dataDecisionPmo: null,
  loadingDecisionPmo: false,
  errorDecisionPmo: null,
};

const MisDatosState = (props) => {
  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response) {
        if (
          error.response.status === 500 ||
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.message === "Unauthenticated."
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        } else {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: error.response.data.message,
            },
          });
        }
      }
    }
  };

  const obtenerAccesoPmo = async () => {
    dispatch({
      type: JUBPMO_ACCESS_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/pmo/acceso`);
      dispatch({
        type: JUBPMO_ACCESS_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_ACCESS_ERROR);
    }
  };
  const guardarAsesoria = async () => {
    dispatch({
      type: JUBPMO_ASESORIA_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/pmo/guardar-asesoria`);
      dispatch({
        type: JUBPMO_ASESORIA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_ASESORIA_ERROR);
    }
  };
  const obtenerCalculo = async (dato) => {
    dispatch({
      type: JUBPMO_CALCULO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/pmo/calculo`, dato);
      dispatch({
        type: JUBPMO_CALCULO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_CALCULO_ERROR);
    }
  };
  const eliminarAcogimiento = async (dato) => {
    dispatch({
      type: JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/pmo/eliminar`, dato);
      dispatch({
        type: JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR);
    }
  };
  const guardarAcogimiento = async (dato) => {
    dispatch({
      type: JUBPMO_ACOGIMIENTO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/pmo/acogimiento`, dato);
      dispatch({
        type: JUBPMO_ACOGIMIENTO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_ACOGIMIENTO_ERROR);
    }
  };
  const decisionValidarPmo = async (dato) => {
    dispatch({
      type: JUBPMO_DECISION_VALIDAR_PMO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/pmo/decision/validar-pmo`,
        dato
      );
      dispatch({
        type: JUBPMO_DECISION_VALIDAR_PMO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_DECISION_VALIDAR_PMO_ERROR);
    }
  };
  const decisionPmoDocumento = async (dato) => {
    dispatch({
      type: JUBPMO_DOCUMENTO_PMO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/pmo/documento`, dato);
      dispatch({
        type: JUBPMO_DOCUMENTO_PMO_EXITOSO,
        payload: respuesta.data,
      });
      return respuesta;
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_DOCUMENTO_PMO_ERROR);
      return false;
    }
  };
  const decisionPmoDocumentoBeneficiario = async (dato) => {
    dispatch({
      type: JUBPMO_DOCUMENTO_PMO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/pmo/documentos-beneficiario`,
        dato
      );
      dispatch({
        type: JUBPMO_DOCUMENTO_PMO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_DOCUMENTO_PMO_ERROR);
    }
  };
  const decisionPmo = async (dato) => {
    dispatch({
      type: JUBPMO_DECISION_PMO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/pmo/decision/registrar`,
        dato
      );
      dispatch({
        type: JUBPMO_DECISION_PMO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBPMO_DECISION_PMO_ERROR);
    }
  };
  const resetearDocumentPmo = () => {
    dispatch({
      type: JUBPMO_DOCUMENTO_PMO_CLEAR,
      payload: true, // mandamos el loading al boton
    });
  };
  const resetearDecision = () => {
    dispatch({
      type: JUBPMO_DECISION_VALIDAR_PMO_CLEAR,
      payload: true, // mandamos el loading al boton
    });
  };
  const resetearPrevCalculo = () => {
    dispatch({
      type: JUBPMO_RESETEAR_PREV_CALCULO,
      payload: true, // mandamos el loading al boton
    });
  };

  const resetearEstados = () => {
    dispatch({
      type: JUBPMO_RESETEAR_ESTADOS,
      payload: true, // mandamos el loading al boton
    });
  };
  const [state, dispatch] = useReducer(JubilacionReducer, initialState);
  return (
    <JubilacionContext.Provider
      value={{
        dataAccessPmo: state.dataAccessPmo,
        loadingAccessPmo: state.loadingAccessPmo,
        errorAccespmo: state.errorAccespmo,
        mensajeAsesoria: state.mensajeAsesoria,
        loadingAsesoria: state.loadingAsesoria,
        errorAsesoria: state.errorAsesoria,
        datoEliminarAcogimiento: state.datoEliminarAcogimiento,
        loadingEliminarAcogimiento: state.loadingEliminarAcogimiento,
        errorEliminarAcogimiento: state.errorEliminarAcogimiento,
        dataCalculo: state.dataCalculo,
        loadingCalculo: state.loadingCalculo,
        errorCalculo: state.errorCalculo,
        dataAcogimiento: state.dataAcogimiento,
        loadingAcogimiento: state.loadingAcogimiento,
        errorAcogimiento: state.errorAcogimiento,
        dataDecisionValiarPmo: state.dataDecisionValiarPmo,
        loadingDecisionValiarPmo: state.loadingDecisionValiarPmo,
        errorDecisionValiarPmo: state.errorDecisionValiarPmo,
        dataDocumentoPmo: state.dataDocumentoPmo,
        loadingDocumentoPmo: state.loadingDocumentoPmo,
        errorDocumentoPmo: state.errorDocumentoPmo,
        dataDecisionPmo: state.dataDecisionPmo,
        loadingDecisionPmo: state.loadingDecisionPmo,
        errorDecisionPmo: state.errorDecisionPmo,
        obtenerAccesoPmo,
        guardarAsesoria,
        obtenerCalculo,
        eliminarAcogimiento,
        guardarAcogimiento,
        resetearEstados,
        resetearPrevCalculo,
        decisionValidarPmo,
        resetearDecision,
        decisionPmoDocumento,
        resetearDocumentPmo,
        decisionPmo,
      }}
    >
      {props.children}
    </JubilacionContext.Provider>
  );
};

export default MisDatosState;
