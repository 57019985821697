import React from 'react'

const GraficThreeDisableIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7593 13.1806L24.9195 27.5855C29.2949 22.1526 35.9897 18.6775 43.4977 18.6775V0C31.4071 0 20.4976 5.06036 12.7593 13.1806Z"
        fill="#CDCED0"
      />
      <path
        d="M0.997559 42.594H19.6338C19.6338 36.9088 21.6144 31.6894 24.9192 27.5856L12.759 13.1807C5.47173 20.8275 0.997559 31.1868 0.997559 42.594Z"
        fill="#CDCED0"
      />
      <path
        d="M43.4976 0V18.6775C50.9687 18.6775 57.6383 22.1197 62.0138 27.5079L74.1682 13.1107C66.4357 5.03125 55.5532 0 43.4976 0Z"
        fill="#CDCED0"
      />
      <path
        d="M85.9967 42.5935C85.9967 31.1515 81.4935 20.763 74.1676 13.1104L62.0132 27.5075C65.3548 31.623 67.3605 36.8735 67.3605 42.5935H85.9967Z"
        fill="#EDEDED"
      />
      <path
        d="M48.3023 40.5802L49.2207 37.8227C49.5645 36.7903 48.5559 35.8212 47.5414 36.2105L44.4856 37.3824C44.1454 37.2792 43.7941 37.202 43.429 37.1563C39.9422 36.7197 36.76 39.1997 36.323 42.694C35.8861 46.1883 38.3596 49.3763 41.8464 49.8128C45.3332 50.2494 48.5155 47.7695 48.9524 44.2751C49.1176 42.9544 48.8673 41.6795 48.3044 40.5785L48.3023 40.5802Z"
        fill="#EDEDED"
      />
    </svg>
  );
};

export default GraficThreeDisableIcon;