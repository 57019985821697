import React from "react";
import { RadioCardComponent } from "./styles";
import { BsCheckLg } from "react-icons/bs";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const RadioCard = ({
  children,
  name,
  value,
  disabled,
  required,
  validate,
  stylesWhenChecked,
  indicatorSide,
  indicatorAlignment,
  ...props
}) => {
  const { watch, register } = useFormContext();

  const isChecked = watch(name) === value;

  return (
    <RadioCardComponent
      isChecked={isChecked}
      disabled={disabled}
      indicatorSide={indicatorSide}
      indicatorAlignment={indicatorAlignment}
      {...props}
    >
      <div className="radio">
        <BsCheckLg />
      </div>
      <div className="body">{children}</div>
      <input
        type="radio"
        value={value}
        {...register(name, { required, validate: { ...validate } })}
      />
    </RadioCardComponent>
  );
};

export default RadioCard;

RadioCard.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.any,
  indicatorSide: PropTypes.oneOf(["right", "left"]),
  indicatorAlignment: PropTypes.oneOf(["top", "center", "down"]),
};

RadioCard.defaultProps = {
  name: "radio-card-input",
  disabled: false,
  required: { value: true, message: "Este campo es requerido." },
  indicatorSide: "left",
  indicatorAlignment: "top",
};
