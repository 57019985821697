import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { Container } from "reactstrap";
import IC_RECHAZADA from "./../../../../../assets/icons/ic_rechazada.svg";
import IC_EN_ESPERA from "./../../../../../assets/icons/ic_en_espera.svg";
import { useParams } from "react-router-dom";
import PagosDesembolsosContext from "./../../../../../context/pagosDesembolsos/pagosDesembolsosContext";
import moment from "moment";
import styled from "styled-components";
import {
  BoxDesktop,
  BoxMobile,
} from "../../../../PagosDesembolsos/componentes/data/components/BreadcrumbsFL";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PBold = styled.div`
  color: #2b2b2b;
  display: inline-block;
`;

export const ItemState = ({
  monto_acreditado,
  fecha_solicitud,
  fec_aplicacion,
  estado,
  r_cod_estado,
  monto_solicitado,
  r_fecha_solicitud,
  r_fecha_banco,
  r_fecha_acreditacion,
  r_estado,
  r_fecha_rechazo,
  r_motivo,
}) => {
  const { tipo } = useParams();

  const icons = [
    <Container
      style={{
        background: "#EFBD0C",
        borderRadius: "250px",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={IC_RECHAZADA} height={24} width={24} alt="rechazada" />
    </Container>,
    <CheckTwoToneIcon
      fontSize="large"
      sx={{
        padding: "0.3rem",
        borderRadius: "120px",
        background: "#1B7935",
        color: "#FFF",
      }}
    />,
    <Container
      style={{
        background: "#EDEDED",
        borderRadius: "250px",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={IC_EN_ESPERA} height={24} width={24} alt="wait" />
    </Container>,
  ];

  const titulos = ["Completada", "Estás aquí", "En espera"];

  const subtitulos = [
    "Solicitud ingresada",
    estado.trim() === "ACREDITADO"
      ? "Resultado de la solicitud"
      : estado === "RECHAZADA"
      ? "Solicitud rechazada"
      : "Resultado de la solicitud",
    "Solicitud procesada",
  ];
  const mensajes = [
    <>
      Se ingresó el <PBold>{r_fecha_solicitud} </PBold>. validaremos ciertos
      requisitos y te informaremos cualquier novedad.
    </>,
    r_cod_estado === "1" || r_cod_estado === "2" ? (
      <>
        Validaremos ciertos requisitos y te informaremos cualquier novedad el{" "}
        <PBold> {r_fecha_banco}.</PBold>
      </>
    ) : r_cod_estado === "-1" ? (
      <>
        Lo sentimos, tu solicitud ha sido rechazada el{" "}
        <PBold>{r_fecha_rechazo}.</PBold> Por el siguiente motivo:{" "}
        <PBold> {r_motivo}.</PBold>
      </>
    ) : (
      <>
        Tu solicitud fue aprobada en la siguiente fecha de procedencia:{" "}
        <PBold>{r_fecha_banco}</PBold>.
      </>
    ),

    r_cod_estado === "1" || r_cod_estado === "2" ? (
      <>
        {" "}
        Estamos <strong> enviando la solicitud </strong> a tu banco para debitar
        el monto a aportar. el <PBold> {r_fecha_acreditacion}.</PBold>
      </>
    ) : (
      <>
        ¡TU APORTE HA SIDO PROCESADO EXITOSAMENTE! fue debitado el{" "}
        <PBold>{r_fecha_acreditacion}</PBold>. Puedes revisar el valor cuota
        ingresando a Mis Fondos {">"} Últimos movimientos
      </>
    ),
  ];

  return (
    <>
      <ItemPagoDesembolso
        key="1"
        id={1}
        colorLine={"#1B7935"}
        icon={icons[1]}
        titulo={
          r_cod_estado === "2" || r_cod_estado === "3" || r_cod_estado === "-1"
            ? titulos[0]
            : titulos[1]
        }
        subtitulo={subtitulos[0]}
        mensaje={mensajes[0]}
        hasExpandend={estado === "EN CURSO" && r_estado !== "APROBADA"}
      />
      <ItemPagoDesembolso
        key="2"
        id={2}
        colorLine={
          r_cod_estado === "1" || r_cod_estado === "2" ? "#1B7935" : "#ACAFB3"
        }
        icon={
          r_cod_estado === "1"
            ? icons[2]
            : r_cod_estado === "2" || r_cod_estado === "3"
            ? icons[1]
            : icons[0]
        }
        titulo={
          r_cod_estado === "1"
            ? titulos[2]
            : r_cod_estado === "2" || r_cod_estado === "-1"
            ? titulos[1]
            : titulos[0]
        }
        subtitulo={subtitulos[1]}
        mostrarCorreo={r_cod_estado === "-1"}
        ultimo={r_cod_estado === "-1"}
        mensaje={mensajes[1]}
        isOpacy={r_cod_estado === "1" && r_cod_estado !== "2"}
      />
      {r_cod_estado !== "-1" && (
        <ItemPagoDesembolso
          key="3"
          id={3}
          colorLine={r_cod_estado === "3" ? "#1B7935" : "#ACAFB3"}
          icon={
            r_estado === "APROBADA"
              ? icons[2]
              : r_cod_estado === "3"
              ? icons[1]
              : icons[2]
          }
          titulo={
            r_cod_estado === "3"
              ? titulos[0]
              : r_cod_estado === "2"
              ? titulos[1]
              : titulos[2]
          }
          ultimo={true}
          subtitulo={subtitulos[2]}
          mensaje={mensajes[2]}
          isOpacy={r_cod_estado === "1"}
          hasExpanded={r_cod_estado === "3" || r_cod_estado === "4"}
        />
      )}
    </>
  );
};

export const ItemPagoDesembolso = ({
  hasExpanded = false,
  titulo,
  subtitulo,
  mensaje,
  icon,
  colorLine,
  ultimo = false,
  mostrarCorreo = false,
  id,
  isOpacy = false,
}) => {
  const pagosDesembolsosContext = useContext(PagosDesembolsosContext);

  const { user } = pagosDesembolsosContext;

  const [expanded, setExpanded] = useState(
    hasExpanded || titulo === "Estás aquí" ? id : null
  );
  useEffect(() => {
    setExpanded(hasExpanded || titulo === "Estás aquí" ? id : null);
  }, [titulo, hasExpanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={2} md={1}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </div>
        </Grid>
        <Grid item xs={10} md={11}>
          <Box
            sx={{
              padding: "0.5rem 0.2rem",
            }}
          >
            <div
              style={{
                padding: "0 6px 6px 8px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.2px",
                color: isOpacy ? "#ACAFB3" : "#2B2B2B",
                display: "flex",
                alignItems: "center",
                margin: "auto !important",
              }}
            >
              {" "}
              {titulo}{" "}
            </div>
          </Box>
        </Grid>

        <Grid item xs={2} md={1}>
          <div
            style={{
              margin: "auto",
              height: ultimo ? "0px" : "100%",
              backgroundImage: `repeating-linear-gradient(0deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px), repeating-linear-gradient(90deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px),repeating-linear-gradient(180deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px),repeating-linear-gradient(270deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px)`,
              backgroundSize: "3px 100%, 100% 3px, 3px 100% , 100% 3px",
              backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
              backgroundRepeat: "no-repeat",
              width: "2px",
              textAlign: "center",
            }}
          ></div>
        </Grid>

        <Grid
          item
          xs={10}
          md={11}
          sx={{
            paddingBottom: "0px",
          }}
        >
          <Box
            sx={{
              padding: {
                xs: ultimo ? "0 0.7rem" : "1rem 0.7rem",
                md: "0 0.7rem",
              },
            }}
          >
            <BoxDesktop>
              <div
                style={{
                  padding: "0 0.12rem 0.12rem 0.12rem",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: "16px",
                  color: isOpacy ? "#6E7378" : "#101010",
                  lineHeight: "24px",
                  letterSpacing: "0.2px",
                  margin: "auto !important",
                }}
              >
                {subtitulo}
              </div>

              <Box
                sx={{
                  display: { xs: ultimo ? "block" : "none", md: "block" },
                }}
              >
                <div className="detail_message_container">
                  <div className="detail_message_container-msg">{mensaje}</div>
                </div>
              </Box>
            </BoxDesktop>

            <BoxMobile style={{ padding: "0" }}>
              <Accordion
                expanded={expanded === id}
                onChange={handleChange(id)}
                sx={{
                  padding: "0",
                  boxShadow: "0",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="panel1bh-header"
                  sx={{
                    padding: "0",
                  }}
                >
                  <div
                    style={{
                      padding: "0 0.12rem 0.12rem 0.12rem",
                      fontWeight: "400",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: isOpacy ? "#6E7378" : "#101010",
                      lineHeight: "24px",
                      letterSpacing: "0.2px",
                      margin: "auto !important",
                    }}
                  >
                    {subtitulo}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0",
                  }}
                >
                  <Box>
                    <div className="detail_message_container">
                      <div className="detail_message_container-msg">
                        {mensaje}
                      </div>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </BoxMobile>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
