import React, { useState, useEffect } from "react";
import { Drawer, Grid, Modal } from "@mui/material";
import { ModalComponent } from "./stylesModalJS";
import { BsX } from "react-icons/bs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  drawerStyle: {
    "& .MuiDrawer-paper": {
      borderRadius: "16px 16px 0px 0px",
    },
  },
});

const ModalInstrucciones = (props) => {
  const classes = useStyles();
  const { openModal, close } = props;
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const openModalAction = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    close();
  };

  useEffect(() => {
    if (openModal) openModalAction();
  }, [openModal]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      {width > 600 ? (
        <Modal open={open} disableBackdropClick>
          <ModalComponent>
            {/* <div className="close" onClick={onClose}>
                            <BsX />
                        </div> */}
            <div>
              <Grid container justifyContent="center">
                <Grid item>
                  <h5 className="margin-center text-center mb-4">
                    {/* <span><InfoIcon></InfoIcon></span> */}
                    <b> Consideraciones para tu clave web </b>
                  </h5>

                  <ul className="ml-4">
                    <li>
                      La clave debe ser <b>numérica</b>.
                    </li>
                    <li>
                      No puede contener números consecutivos como{" "}
                      <b>1234, 4321,</b> etc.
                    </li>
                    <li>
                      Debe contener números diferentes, sin repeticiones como{" "}
                      <b>1111, 2222,</b> etc.
                    </li>
                    <li>No debe ser igual a tu anterior clave.</li>
                  </ul>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <button
                    onClick={onClose}
                    className={`btn-recover margin-center btn-modal-total`}
                  >
                    Entendido
                  </button>
                </Grid>
                <br />
              </Grid>
            </div>
          </ModalComponent>
        </Modal>
      ) : (
        <Drawer
          open={open}
          anchor={"bottom"}
          className={classes.drawerStyle}
          PaperProps={{
            sx: {
              bordeRadius: "16px 16px 0 0",
              padding: "32px 32px 24px 32px",
            },
          }}
        >
          <div>
            <div>
              <Grid container justifyContent="center">
                <Grid item>
                  <h5 className="my-4 margin-center text-center">
                    <b> Consideraciones para tu clave web </b>
                  </h5>

                  <ul className="ml-3">
                    <li>
                      La clave debe ser <b>numérica</b> .
                    </li>
                    <li>
                      No puede contener números consecutivos como{" "}
                      <b>1234, 4321,</b> etc.
                    </li>
                    <li>
                      Debe contener números diferentes, sin repeticiones como{" "}
                      <b>1111, 2222,</b> etc.
                    </li>
                    <li>No debe ser igual a tu anterior clave.</li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <button
                    onClick={onClose}
                    className={`btn-recover margin-center btn-modal-total`}
                  >
                    Entendido
                  </button>
                </Grid>
                <br />
              </Grid>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default ModalInstrucciones;
