import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import { SubidaDocumentos } from "./components";
import { VistaEspera } from "../components";
import imgConfirmation from "../../../../../../assets/confirmacion.svg";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso5(props) {
  const [showView, setshowView] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // const [dependencies, setDependencies] = useState({
  //   has_a_pension: false,
  //   r_cuentas: [],
  //   r_bancos: [],
  //   r_tipo_cuentas: [],
  //   bancos_cci: [],
  // });
  const [dependencies, setDependencies] = useState(null);
  const [contents, setContents] = useState(null);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    obtenerContenidoPasoReja,
    limpiarObtenerContenidoPasoReja,
    temasAsesoriaRejaData,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    if (mensajeAlerta) {
      //   mostrarMensaje();
    } else {
      //   obtenerDatosRetiroApvSinFin();
      obtenerContenidoPasoReja(props.processId, 5);
      window.scrollTo(0, 0);
      props.setCurrectStepTitle("carga en Documentos");
    }
    return () => {
      //   limpiarVariablesRetiroApvSinFinParcial();
      limpiarObtenerContenidoPasoReja();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);
  // useEffect(() => {
  //     if (datosRetiroApvSinFin)
  //         setshowView(1);
  // }, [datosRetiroApvSinFin]);
  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        props.setCurrectStepTitle(temasAsesoriaRejaData.name);
        setDependencies(temasAsesoriaRejaData.dependencies);
        setContents(temasAsesoriaRejaData.contents);
        setshowView(1);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <SubidaDocumentos
            // tarjetasGet={datosRetiroApvSinFin}
            processId={props.processId}
            contents={contents}
            dependencies={dependencies}
            endFithStep={() => props.setCurrectStepNumber(6)}
            sendFilesToValidate={() => {
              setshowView(2);
            }}
            getOutOfProcess={() => props.getOutOfProcess()}
          />
        );
      case 2:
        return (
          <VistaEspera
            imgLogo={imgConfirmation}
            title="Se han enviado correctamente los archivos para validar"
            description={
              <>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,
                ahora que tenemos la información necesaria para generar tu
                estimación de pensión, realizaremos la validación de tus datos y
                documentos para dar conformidad y seguir con el trámite.
                <br />
                <br />
                En un plazo de X dias útiles te estaremos informando vía correo
                si todo fue conforme o si existe alguna observación para que
                puedas regularizarla y seguir con el proceso virtual.
              </>
            }
            note="Recuerda mantener tus datos de contacto actualizados y revisar tu bandeja de spam o correos no deseados."
            btnLabel="Regresar al inicio"
            //btnFunction={() => props.getOutOfProcess()}
            btnFunction={() => props.setCurrectStepNumber(5)}
          />
        );
      default:
        return <Loading />;
    }
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (temasAsesoriaRejaData === null || dependencies === null)
    return <Loading />;

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
