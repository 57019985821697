import React, { useReducer } from "react";
import Retiro25Context from "./retiro25Context";
import Retiro25Reducer from "./retiro25Reducer";
import clienteAxios from "../../config/axios";
import {
  RETIRO_25_POST_EXITOSO,
  RETIRO_25_POST_ERROR,
  RETIRO_25_POST_LOGIN,
  RETIRO_25_CLEAR,
  RETIRO_25_CLEAR_PARCIAL,
} from "../../types/retiro25";

const Retiro25State = (props) => {
  const initialState = {
    /**Actualizar Datos*/
    resultadoRetiro25Post: null,
    mensajeAlertaRetiro25Post: null,
    loadingBtnRetiro25Post: false, // procesando la actualización de datos
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const retiro25Post = async (dato) => {
    dispatch({
      type: RETIRO_25_POST_LOGIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/av-afiliado", { tipo: dato });
      dispatch({
        type: RETIRO_25_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, RETIRO_25_POST_ERROR);
    }
  };

  const limpiarVariablesRetiro25 = () => {
    dispatch({
      type: RETIRO_25_CLEAR,
    });
  };
  const limpiarVariablesRetiro25Parcial = () => {
    dispatch({
      type: RETIRO_25_CLEAR_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(Retiro25Reducer, initialState);
  return (
    <Retiro25Context.Provider
      value={{
        resultadoRetiro25Post: state.resultadoRetiro25Post,
        mensajeAlertaRetiro25Post: state.mensajeAlertaRetiro25Post,
        loadingBtnRetiro25Post: state.loadingBtnRetiro25Post,
        retiro25Post,
        limpiarVariablesRetiro25,
        limpiarVariablesRetiro25Parcial,
      }}
    >
      {props.children}
    </Retiro25Context.Provider>
  );
};

export default Retiro25State;
