import React from "react";
import { montoFormat } from "../../../../../helper/amountFormat";
import { Period, Table, TR } from "./styles";
import { formatNumberMonthToText, formatPeriod } from "./utils";

const DesktopTable = ({ items, data }) => {
  return (
    <div>
      <Table>
        <thead>
          <TR>
            {[
              "Periodo",
              "Rendimiento",
              "Aportes acumulados",
              "Retiros y cargos",
              "Rentabilidad acumulada",
            ].map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </TR>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map(
              (
                {
                  fin_anio,
                  fin_mes,
                  ini_anio,
                  ini_mes,
                  rendimiento,
                  aportes,
                  retiros,
                  rentabilidad,
                },
                index
              ) => {
                return (
                  <TR key={index}>
                    <td>
                      {formatPeriod(ini_anio, ini_mes, fin_anio, fin_mes)}
                    </td>
                    <td
                      className={`rendimiento ${
                        rendimiento > 0 ? "positiveAmount" : "negativeAmount"
                      }`}
                    >
                      {rendimiento}%
                    </td>
                    <td>{aportes ? `S/ ${montoFormat(aportes)}` : "-"}</td>
                    <td>{retiros ? `S/ ${montoFormat(retiros)}` : "-"}</td>
                    <td>
                      {rentabilidad ? `S/ ${montoFormat(rentabilidad)}` : "-"}
                    </td>
                  </TR>
                );
              }
            )}
        </tbody>
      </Table>
    </div>
  );
};

export default DesktopTable;
