import React, { useContext, useEffect, useState } from "react";
import ReactGa from "react-ga";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import HomeContext from "../../context/home/homeContext";
import AuthContext from "../../context/autenticacion/authContext";
import UltimosmovimientosContext from "../../context/ultimosmovimientos/ultimosmovimientosContext";
import SeguimientoAportesContext from "../../context/seguimientoAportes/seguimientoAportesContext";
import CambiofondoContext from "../../context/cambiofondo/cambiofondoContext";

import AsesorContext from "../../context/asesor/asesorContext";
import { ModalEtario } from "./components";
import ModalAport from "./components/ModalAport";
import ModalInvertir from "./components/ModalInvertir";
import ModalContext from "../../context/modal/modalContext";
import styled from "styled-components";
import Loading from "../../components/Loading/index.jsx";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ICON_ADV from "../../assets/icons/error_triangle_24px.svg";
import "./index.css";
import moment from "moment";
import {
  Funds,
  MyProducts,
  LastContributions,
  FreeFundMonitoring,
  Interest,
  AFPInvestments,
} from "./components";
import "./new-dashboard.css";

const Container = styled(Box)`
  background: ${(props) => props.theme.palette.background.default};
`;

const Dashboard = (props) => {
  const homeContext = useContext(HomeContext);
  const cambiofindoContext = useContext(CambiofondoContext);
  const { validacionEtario, validateEtareo, connectEtario } =
    cambiofindoContext;
  const ultimosmovimientosContext = useContext(UltimosmovimientosContext);
  const { movimientos, obtenerUltimosMovimientos } = ultimosmovimientosContext;
  const {
    fondos,
    total,
    graphycs,
    obtenerDatosGraphyc,
    mensajeAlerta,
    obtenerDatosHome,
    limpiarVariablesHomeParcial,
    obtenerOpcionesMenu,
    modalSixAport,
    openModalAport,
  } = homeContext;
  const asesorContext = useContext(AsesorContext);
  const { encontroAsesor, obtenerAsesor } = asesorContext;
  const seguimientoAportesContext = useContext(SeguimientoAportesContext);
  const { seguimientoAportes, obtenerSeguimientosAportes } =
    seguimientoAportesContext;
  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const authContext = useContext(AuthContext);
  const {
    nombreUsuario,
    actualizarTimer,
    gender,
    usuarioAutenticado,
    cambio_clave,

    valido_datos,
  } = authContext;
  const [aportesData, setAportesData] = useState([]);
  const [productMovement, setProductMovement] = useState("OBL"); //"OBL"
  const [open, setOpen] = useState(false);
  const [showEtario, setShowEtario] = useState(false);
  const [showLastContributions, setShowLastContributions] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShowLastContributions(false);
    const delay = setTimeout(() => {
      setShowLastContributions(true);
    }, 1000);

    return () => clearTimeout(delay);
  }, [productMovement]);

  useEffect(() => {
    getObtener("OBL"); //OBL
    obtenerDatosHome();
    obtenerOpcionesMenu();
    usuarioAutenticado();
    obtenerAsesor();
    validacionEtario();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosGraphyc();
      obtenerSeguimientosAportes();
    }

    return () => {
      limpiarVariablesHomeParcial();
      actualizarTimer();
    };
    // eslint-disable-next-line
  }, [mensajeAlerta]);
  useEffect(() => {
    if (validateEtareo) {
      if (validateEtareo.code === "aplica") {
        setOpen(true);
        setShowEtario(true);
      }
    }
  }, [validateEtareo]);

  useEffect(() => {
    if (seguimientoAportes) {
      if (seguimientoAportes.length > 0) {
        const firstAport = seguimientoAportes[0];
        const leastSixMonth = moment();
        const dateSolicitud = firstAport.r_fecha_solicitud
          .replaceAll("/", "-")
          .split("-");
        const diffDate = moment(
          dateSolicitud[2] + "-" + dateSolicitud[1] + "-" + dateSolicitud[0]
        ).diff(leastSixMonth, "month");

        if (diffDate < -6) {
          modalSixAport(!openModalAport);
        }
      }
    }
  }, [seguimientoAportes]);
  const getObtener = (product) => {
    let bodyFormData = new FormData();
    let dateIni = moment().subtract(3, "month");
    let dateFin = moment();
    let pe_ini =
      Number((dateIni.month() + 1).toString().length) === 1
        ? dateIni.year() + "0" + (dateIni.month() + 1)
        : dateIni.year() + "" + (dateIni.month() + 1);
    let pe_fin =
      Number((dateFin.month() + 1).toString().length) === 1
        ? dateFin.year() + "0" + (dateFin.month() + 1)
        : dateFin.year() + "" + (dateFin.month() + 1);
    bodyFormData.set("tipo_aporte", product);
    bodyFormData.set("pe_ini", pe_ini);
    bodyFormData.set("pe_fin", pe_fin);
    obtenerUltimosMovimientos(bodyFormData);
  };

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (
    total === null ||
    fondos.length === 0 ||
    movimientos === null ||
    graphycs.length === 0 ||
    validateEtareo === null
  )
    return <Loading />;
  return (
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={7}
          p={2}
          sx={{
            pb: 0,
          }}
        >
          <MyProducts
            name={nombreUsuario}
            fondos={fondos}
            total={total}
            encontroAsesor={encontroAsesor}
            getObtener={getObtener}
            setProductMovement={setProductMovement}
            open={showEtario}
            setOpen={setOpen}
            validateEtareo={validateEtareo}
          />
          {showLastContributions && (
            <LastContributions
              movimientos={movimientos.slice(0, 5)}
              productMovement={productMovement}
            />
          )}
          {/* <FreeFundMonitoring data={aportesData} /> */}
          <Funds
            name={nombreUsuario}
            fondos={fondos}
            total={total}
            gender={gender}
            encontroAsesor={encontroAsesor}
            open={showEtario}
            setOpen={setOpen}
            validateEtareo={validateEtareo}
          />
        </Grid>
        <Grid item xs={12} lg={5} p={2}>
          <AFPInvestments grafico={graphycs.grafico5} />
          <Interest />
        </Grid>
      </Grid>
      {cambio_clave === null ? (
        valido_datos === null ? (
          <ModalEtario
            open={open}
            fondos={fondos}
            setOpen={setOpen}
            connectEtario={connectEtario}
            validateEtareo={validateEtareo}
          />
        ) : null
      ) : null}

      {/* <ModalAport
        open={false}
        onClose={() => modalSixAport(!openModalAport)}
        nombreUsuario={nombreUsuario}
      /> */}
    </Container>
  );
};

export default hocWidthWindow(Dashboard);
