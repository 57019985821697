import styled from "styled-components";
import Box from "@mui/material/Box";

export const BoxBody = styled(Box)`
  // padding: ${(props) => props.theme.spacing(1)};
  position: relative;
  ${(props) => props.theme.tipography.h5};
  & .spanText {
    display: block;
    color: ${(props) => props.theme.palette.grayscale.light};
        font-size: 14px;
        // margin-bottom:10px;
  }

  & .content-text {
    display: flex;
  }
  & .invested-amount {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
  }
   & .content-rentabilidad-desktop {
 
    position:relative;
  }
  & .content-rentabilidad-desktop h3 {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-bottom:24px;
  }
    & .content-rentabilidad-desktop p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-bottom:16px;
   
  }
  & .list-desktop-rentabilidad{
    width:90%;
    margin:0 auto;
  }
   & .content-rentabilidad-desktop button {
    font-weight: bold;
    font-size: 25px;
    line-height: 16px;
    text-align: left;
    position:absolute;
    padding: 4px 8px;
    background: none;
    color:#2F6D95;
    border:none;
    right: 0;
    top: 5px;
  }
   & .list-detail-rent-desktop{
    // padding-left:  ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(3)};
   }
   & .list-detail-rent-desktop img {
   width: 30px;
  }
  & .list-detail-rent-desktop p {
    ${(props) => props.theme.tipography.h5};
    display:flex;
    font-size:18px;
    align-items:center;
    font-weight: 500;
    margin-bottom: 0px;
    // color: #1a6691;
    text-align: left;
    margin-left: 10px;
  }
  .box-image{
    background: rgb(26 102 145 / 62%);
    padding:8px 8px;
    border-radius: 16px;
    display:flex;
    justify-content:center;
    align-items:center;

  }
  .list-detail-rent-desktop span {
    font-weight: bold;
    color: #1a6691;
    display: block;
    font-weight: bold;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.3px;
    text-align: center;
    // color: #51565f;
  }
  & .list-desktop-rentabilidad .info-spp p{
  
      font-weight: 700;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 13px;
  }
  & .simbolo-desktop {
    display: flex;
    align-items: center;
  }
  & .simbolo-desktop span {
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #ce1336;
  }
  & .message-rent-desktop {
    display: none;
    background: #2f6d95;
    border-radius: 7px;
    padding: 7px 8px;
    margin-top: 16px;
  }
  & .message-rent-desktop p {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    margin: 0 auto 0;
    text-align: center;
    letter-spacing: -0.0125em;
    width: 264px;
    color: #ffffff;
  }
`;