import React, { useState, useEffect } from "react";
import MessageSnack from "../MessageSnack";
/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import styled from "styled-components";

export const Circle = styled("div")`
  height: ${(props) => (props.size ? props.size : "25px")};
  width: ${(props) => (props.size ? props.size : "25px")};
  background-color: ${(props) =>
    props.background ? props.background : "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;
/* estilos */
export const styles = {
  cardStyle: {
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid rgba(20, 20, 20, 0.15)",
    userSelect: "none",
    boxShadow: "none",
  },

  cardContentStyle: {
    minHeight: "116px",
    p: 2,
  },

  textCard: {
    fontSize: "14px",
    color: "#363A3E",
  },
  textCardDetail: {
    fontSize: "12px",
    color: "#363A3E",
  },

  titleMobileSubtitleStyle: {
    fontSize: "14px",
    textAlign: "center",
    padding: "0",
    color: "#2F6D95",
  },
  titleResponsiveStyle: {
    fontSize: "14px",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

const BasicCard = (props) => {
  const { info, handleSelectFund, isMobile } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const CardSlide = (item, key) => {
    return (
      <Box
        sx={{
          mb: 3,
        }}
        key={key}
      >
        <Card sx={styles.cardStyle}>
          <Box sx={styles.cardContentStyle}>
            <Grid container>
              <Grid item xs={8}>
                <Typography sx={styles.titleResponsiveStyle}>
                  {item.title} - {item.subTitle}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={styles.titleMobileSubtitleStyle}
                  onClick={() => handleSelectFund(item.id)}
                >
                  Ver detalle
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ pt: 2 }}>
              <Grid item xs={3} sx={styles.gridRiesgoStyle}>
                <Box
                  component="img"
                  sx={{ width: "86px" }}
                  src={item.riesgoImg}
                  alt="Gráfico de nivel de riesgo"
                />
              </Grid>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid
                      container
                      sx={{
                        borderRadius: "8px",
                        paddingLeft: "16px",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          Potencial de rentabilidad:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography
                            sx={{
                              color: "#6E7378",
                              fontSize: "12px",
                            }}
                          >
                            {item.rentabilidad}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      container
                      sx={{
                        borderRadius: "8px",
                        pl: 1,
                        pr: 1,
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          Nivel de Riesgo:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#6E7378", fontSize: "12px" }}
                          >
                            {item.riesgo}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    );
  };
  return (
    <Box>
      <Box
        sx={{
          display: !matches ? "flex" : "block",
          justifyContent: !matches ? "space-between" : "",
        }}
      >
        {info.map((item, index) => {
          return CardSlide(item, index);
        })}
      </Box>
      <MessageSnack
        bgAlert="#EFF3FE"
        description="¿Cuánto riesgo te dispones a asumir? Es importante que sepas qué tipo
          de inversionista eres antes de elegir un fondo."
        color={"#EFBD0C"}
      />
    </Box>
  );
};

export default BasicCard;
