import React from "react";
import * as actions from "./actions";

// Initial state
export const initialState = {
  data: [],
  status: "idle", // idle | loading | success | error
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.BENEFICIARIES_REQUEST:
      return {
        ...state,
        status: "loading",
      };

    case actions.BENEFICIARIES_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.map((e) => ({ ...e, documentState: "idle" })),
        status: "success",
      };
    case actions.BENEFICIARIES_REQUEST_FAIL:
      return {
        ...state,
        status: "error",
      };

    // BENEFICIARIES SEND DOCUMENT
    case actions.BENEFICIARIES_SEND_DOCUMENT:
      var newData = state.data.map((beneficiary) => {
        if (beneficiary.id_oracle === action.payload.id) {
          return { ...beneficiary, documentState: "loading" };
        }

        return beneficiary;
      });
      return {
        ...state,
        data: newData,
      };

    case actions.BENEFICIARIES_SEND_DOCUMENT_SUCCESS:
      var newData = state.data.map((beneficiary) => {
        if (beneficiary.id_oracle === action.payload.id) {
          return { ...beneficiary, documentState: "success" };
        }

        return beneficiary;
      });

      return {
        ...state,
        data: newData,
      };
    case actions.BENEFICIARIES_SEND_DOCUMENT_FAIL:
      var newData = state.data.map((beneficiary) => {
        if (beneficiary.id_oracle === action.payload.id) {
          return { ...beneficiary, documentState: "fail" };
        }

        return beneficiary;
      });

      return {
        ...state,
        data: newData,
      };

    default:
      break;
  }
};

export default reducer;
