import React, { useContext } from "react";
import "./index.css";
import AuthContext from "../../context/autenticacion/authContext";

import hocVariables from "../../HOC/HocVariables";
import Button from "../Button";

const Modal = (props) => {
  const authContext = useContext(AuthContext);
  const { cerrarSesion, actualizarTimer } = authContext;

  const { mensajeAlerta, limpiarVariablesModal, limpiarVariablesContext } =
    props;

  const irAlLogin = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(false);
    limpiarVariablesContext();
  };

  const actualizartoken = () => {
    actualizarTimer();
    limpiarVariablesModal();
  };

  return (
    <div className="wraper-modal top-0">
      <div className="modal-content">
        <h5 className="card-title text-center" style={{ fontWeight: "600" }}>
          Advertencia
        </h5>
        <p className="text-center">{mensajeAlerta.msg}</p>
        <div className="text-center">
          {mensajeAlerta.code === 2 && (
            <Button className="btn btn-danger pl-4 pr-4" onClick={irAlLogin}>
              Aceptar
            </Button>
          )}
          {mensajeAlerta.code === 3 && (
            <div className="d-flex justify-content-around">
              <Button
                bgColor="white"
                name="secondary"
                className="btn btn-secondary pl-4 pr-4"
                onClick={() => limpiarVariablesModal()}
              >
                Cancelar
              </Button>
              <Button
                className="btn btn-danger pl-4 pr-4"
                onClick={actualizartoken}
              >
                Continuar
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hocVariables(Modal);
