import React from "react";
import InfoUsuario from "./InfoUsuario";
import { Button } from "../../components";
const ModalDetalle = ({ mensajes, closeModal }) => {
  return (
    <section className="wraper-modal">
      <div className="modal-content">
        <div className="modal-close">
          <i className="fa fa-times-circle-o" onClick={closeModal()}></i>
        </div>
        <section className="mt-4">
          <InfoUsuario mensajes={mensajes} hiddenTop={true} />
               <section className="d-flex justify-content-around">
                  <Button
                    onClick={closeModal()}
                  >
                    Aceptar
                  </Button>
                </section>
        </section>
      </div>
    </section>
  );
};

export default ModalDetalle;
