import styled from "styled-components";
import * as Tooltip from "@radix-ui/react-tooltip";

export const InfoTooltipComponent = styled.div`
  /* display: inline; */
  position: relative;
`;

export const InfoButton = styled.button`
  margin: 0;
  padding: 0;
  width: 26px;
  height: 26px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.secondary.main};
  border: 1px solid ${(p) => p.theme.palette.grayscale["100"]};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TooltipContent = styled(Tooltip.Content)``;

export const TooltipContentWrapper = styled.div`
  background-color: ${(p) => p.theme.palette.secondary.main};
  color: ${(p) => p.theme.palette.white};
  padding: 5px 12px;
  border-radius: 8px;
  ${(p) => p.theme.tipography.caption}
  max-width: min(100%, 300px);
`;

export const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${(p) => p.theme.palette.secondary.main};
`;
