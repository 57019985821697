import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TextAll } from "../../../../FondoLibre/componentes";
import BottomButtonsInvertir from "../../../../FondoLibre/componentes/BottomButtonsPagination";
import SimpleQuestionCheck from "../../../../FondoLibre/componentes/SimpleListaCheck";
import MessageSnackbar from "../../../../FondoLibre/componentes/MessageSnackbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

const DivContainer = styled("div")`
  display: ${(props) => (props.isMobile ? "block" : "flex")};
  height: ${(props) => (props.isMobile ? "auto" : "auto")};
  position: ${(props) => (props.isMobile ? "relative" : "relative")};
  padding-top: ${(props) => (props.isMobile ? "24px" : "0")};
  width: ${(props) => (props.isMobile ? "initial" : "100%")};
`;
const DivRowRed = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => (props.isMobile ? "0px " : "0 24px")};
  margin: ${(props) => (props.isMobile ? "0px 0px 24px" : "0")};
  background: #ce1336;
  position: ${(props) => (props.isMobile ? "absolute" : "relative")};
  top: ${(props) => (props.isMobile ? "-16px" : "0")};
  left: ${(props) => (props.isMobile ? "-32px" : "0")};
  right: ${(props) => (props.isMobile ? "-32px" : "0")};
  height: ${(props) => (props.isMobile ? "250px" : "unset")};
  border-radius: ${(props) => (props.isMobile ? "0 0 100% 100%" : "0")};
`;
const DivRowQuestion = styled("div")`
  background-color: #ffffff;
  padding: ${(props) => (props.isMobile ? "0px" : "0 24px")};
  display: flex;

  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? "250px" : "24px")};
`;

const Induccion = ({
  isMobile,
  onCloseModal,
  onCloseModalToResult,
  SetResultFondo,
  SetStep,
}) => {
  return (
    <>
      <ContenidoInduccion
        isMobile={isMobile}
        onCloseModal={onCloseModal}
        onCloseModalToResult={onCloseModalToResult}
        SetResultFondo={SetResultFondo}
        SetStep={SetStep}
      />
    </>
  );
};

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const ContenidoInduccion = ({
  // isMobile,
  onCloseModal,
  onCloseModalToResult,
  SetResultFondo,
  SetStep,
}) => {
  const [indexPregunta, setIndexPregunta] = useState(1);
  const [showError, setShowError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [windowHeight, setWindowHeight] = useState(getHeight());
  const [page, setPage] = useState(1);

  const [paddingTopHeader, setPaddingTopHeader] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  const [valPregunta1, setValPregunta1] = useState(0);
  const [valPregunta2, setValPregunta2] = useState(0);
  const [valPregunta3, setValPregunta3] = useState(0);
  const [valPregunta4, setValPregunta4] = useState(0);

  const onNextQuestion = (value) => {
    setShowError(false);
    setPage(value);
    // indexPregunta < 3
    setIndexPregunta(value);
    // : onCalculateFondoIndex();
  };

  const onBackQuestion = () => {
    setShowError(false);
    // setIndexPregunta(value - 1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const onCalculateFondoIndex = () => {
    let resultado =
      valPregunta1.val + valPregunta2.val + valPregunta3.val + valPregunta4.val;
    let fondoindex = 0;
    if (resultado < 7) {
      fondoindex = 0;
      SetResultFondo(1);
      SetStep(3);
    }
    if (resultado >= 8 && resultado < 14) {
      fondoindex = 1;
      SetResultFondo(2);
      SetStep(3);
    }
    if (resultado >= 14) {
      fondoindex = 2;
      SetResultFondo(3);
      SetStep(3);
    }
    // onCloseModalToResult(fondoindex);
  };
  const onHandleBackPregunta = () => {
    if (indexPregunta == 0) {
      onCloseModal();
    } else {
      onBackQuestion();
    }
  };
  const onHandleChangePregunta = (event, value) => {
    if (indexPregunta === 1) {
      valPregunta1 <= 0 ? setShowError(true) : onNextQuestion(value);
    }
    if (indexPregunta === 2) {
      valPregunta2 <= 0 ? setShowError(true) : onNextQuestion(value);
    }
    if (indexPregunta === 3) {
      valPregunta3 <= 0 ? setShowError(true) : onNextQuestion(value);
    }
    if (indexPregunta === 4) {
      valPregunta4 <= 0 ? setShowError(true) : onNextQuestion(value);
    }
  };
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWindowWidth(getWidth());
      setWindowHeight(getHeight());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    let calctop = 0;
    if (windowHeight < 750) {
      calctop = (windowHeight * 3) / 100;
    } else {
      calctop = (windowHeight * 6) / 100;
    }

    setPaddingTopHeader(calctop);
  }, [windowWidth, windowHeight]);
  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, [windowWidth]);

  return (
    <DivContainer isMobile={isMobile}>
      <DivRowRed isMobile={isMobile}>
        <TextAll
          fontSize={isMobile ? "20px" : "24px"}
          lineHeight={isMobile ? "24px" : "32px"}
          fontWeight="400"
          textAlign={isMobile ? "center" : "left"}
          color={"#ffffff"}
          padding={isMobile ? "8px 16px" : "0px"}
        >
          ¿Qué tipo de inversionista soy?
        </TextAll>

        <TextAll
          fontSize={isMobile ? "12px" : "14px"}
          lineHeight={isMobile ? "18px" : "22px"}
          fontWeight="400"
          textAlign={isMobile ? "center" : "left"}
          color={"#ffffff"}
          padding={isMobile ? "16px 35px" : "0px"}
        >
          4 preguntas para descubrir el tipo de fondo que más se ajusta a ti.
        </TextAll>
      </DivRowRed>
      <DivRowQuestion isMobile={isMobile}>
        <div style={{ width: "100%" }}>
          {indexPregunta == 1 ? (
            <Pregunta1
              isMobile={isMobile}
              valPregunta={valPregunta1}
              onChange={(val) => {
                setValPregunta1(val);
                setShowError(false);
              }}
            />
          ) : indexPregunta == 2 ? (
            <Pregunta2
              isMobile={isMobile}
              valPregunta={valPregunta2}
              onChange={(val) => {
                setValPregunta2(val);
                setShowError(false);
              }}
            />
          ) : indexPregunta == 3 ? (
            <Pregunta3
              isMobile={isMobile}
              valPregunta={valPregunta3}
              onChange={(val) => {
                setValPregunta3(val);
                setShowError(false);
              }}
            />
          ) : (
            <Pregunta4
              isMobile={isMobile}
              valPregunta={valPregunta4}
              onChange={(val) => {
                setValPregunta4(val);
                setShowError(false);
              }}
            />
          )}

          {showError && (
            <MessageSnackbar
              mensaje="Debes elegir una respuesta"
              type="error"
            />
          )}
          <Box sx={{ marginTop: "100px" }}>
            <BottomButtonsInvertir
              isMobile={isMobile}
              firstOnClick={() => SetStep(0)}
              secondOnClick={onCalculateFondoIndex}
              page={page}
              disabled={
                valPregunta1 === 0 ||
                valPregunta2 === 0 ||
                valPregunta3 === 0 ||
                valPregunta4 === 0
              }
              onChange={onHandleChangePregunta}
            />
          </Box>
        </div>
      </DivRowQuestion>
    </DivContainer>
  );
};

const Pregunta1 = ({ isMobile, onChange, valPregunta }) => {
  const [respuesta, setRespuesta] = useState(valPregunta);

  const onHandleChange = (val) => {
    setRespuesta(val);
    onChange(val);
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <TextAll
          fontSize={isMobile ? "14px" : "18px"}
          lineHeight="20px"
          fontWeight="400"
          textAlign={isMobile ? "left" : "center"}
          padding={isMobile ? "0" : "16px"}
        >
          1. Imagina que acabas de recibir una herencia de S/10,000.00, pero
          solo podrás gastarla en 5 años. Mientras tanto, ¿en qué invertirías?
        </TextAll>
      </Grid>
      <Grid item xs={12} lg={12} sx={{ pt: 2 }}>
        <SimpleQuestionCheck
          isMobile={isMobile}
          flexDirection="column"
          onChange={(val) => onHandleChange(val)}
          optionSelected={respuesta}
          options={[
            {
              val: 1,
              question: "En una cuenta de ahorro.",
            },
            {
              val: 3,
              question:
                "Una parte en una cuenta de ahorro y la otra en acciones de empresas.",
            },
            {
              val: 5,
              question: "Casi todo en acciones de empresas.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const Pregunta2 = ({ isMobile, onChange, valPregunta }) => {
  const [respuesta, setRespuesta] = useState(valPregunta);
  const onHandleChange = (val) => {
    setRespuesta(val);
    onChange(val);
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <TextAll
          fontSize={isMobile ? "14px" : "18px"}
          lineHeight="20px"
          fontWeight="400"
          textAlign={isMobile ? "left" : "center"}
          padding={isMobile ? "0" : "16px"}
        >
          2. ¿Qué frase explica mejor lo que opinas sobre una cuenta de ahorro?
        </TextAll>
      </Grid>
      <Grid item xs={12} lg={12}>
        <SimpleQuestionCheck
          isMobile={isMobile}
          flexDirection="column"
          onChange={(val) => onHandleChange(val)}
          optionSelected={respuesta}
          options={[
            {
              val: 1,
              question:
                "Es una excelente inversión aunque no me dé el mejor interés.",
            },
            {
              val: 3,
              question:
                "Preferiría una opción que mezcle cuenta de ahorro con acciones, así podría mejorar mi rentabilidad aunque aumente un poco el riesgo.",
            },
            {
              val: 5,
              question:
                "Preferiría invertir casi todo en acciones con el fin de ganar más, aunque corra mayor riesgo.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const Pregunta3 = ({ isMobile, onChange, valPregunta }) => {
  const [respuesta, setRespuesta] = useState(valPregunta);

  const onHandleChange = (val) => {
    setRespuesta(val);
    onChange(val);
  };
  return (
    <Grid
      container
      sx={{
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <TextAll
          fontSize={isMobile ? "14px" : "18px"}
          lineHeight="20px"
          fontWeight="400"
          textAlign={isMobile ? "left" : "center"}
          padding={isMobile ? "0" : "16px"}
        >
          3. Si estuvieras invirtiendo en acciones y estas cae un 20% en una
          semana, ¿qué harías?
        </TextAll>
      </Grid>
      <Grid item lg={6}>
        <SimpleQuestionCheck
          isMobile={isMobile}
          flexDirection="column"
          onChange={(val) => onHandleChange(val)}
          optionSelected={respuesta}
          options={[
            {
              val: 1,
              question: "Vendería todas las acciones.",
            },
            {
              val: 3,
              question: "Vendería la mayor parte de las acciones.",
            },
            {
              val: 5,
              question:
                "Sería paciente, no vendería nada de acciones e incluso compraría más.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const Pregunta4 = ({ isMobile, onChange, valPregunta }) => {
  const [respuesta, setRespuesta] = useState(valPregunta);

  const onHandleChange = (val) => {
    setRespuesta(val);
    onChange(val);
  };
  return (
    <Grid
      container
      sx={{
        justifyContent: isMobile ? "flex-start" : "center",
      }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <TextAll
          fontSize={isMobile ? "14px" : "18px"}
          lineHeight="20px"
          fontWeight="400"
          textAlign={isMobile ? "left" : "center"}
          padding={isMobile ? "0" : "16px"}
        >
          4. ¿Qué afirmación refleja mejor lo que opinas sobre la rentabilidad
          generada hoy por tu AFP?
        </TextAll>
      </Grid>
      <Grid item lg={6}>
        <SimpleQuestionCheck
          isMobile={isMobile}
          flexDirection="column"
          onChange={(val) => onHandleChange(val)}
          optionSelected={respuesta}
          options={[
            {
              val: 1,
              question:
                "Estoy satisfecho(a), pero preferiría que la rentabilidad nunca fuera negativa.",
            },
            {
              val: 3,
              question:
                "Estoy satisfecho(a), ya que la rentabilidad es variable y lo importante es el resultado a largo plazo.",
            },
            {
              val: 5,
              question:
                "Estoy medianamente satisfecho(a), ya que podría haber obtenido mejores resultados, asumiendo un mayor nivel de riesgo.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Induccion;
