import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { withRouter } from "react-router";

const ModalActualizaCelular = ({ closeModal, history }) => {
  const actualizar = () => {
    history.push("/mi-habitat-digital/afiliado/informacion/datos-personales");
  };
  return (
    <section className={`wraper-modal`}>
      <div className={`modal-content`}>
        <div className="modal-close">
          {/* <i className="fa fa-times-circle-o" onClick={() => closeModal()}></i> */}
        </div>

        <section className="py-3">
          <div>
            <Card>
              <CardHeader className="custom-header-cambio-fondo custom-header-li">
                Habitat te informa
              </CardHeader>
              <CardBody>
                <section>
                  <p className="text-jutify px-3">
                    Hemos detectado que no tienes actualizado algunos datos,
                    para acceder a esta opción, te pedimos actualizar tu número
                    de celular para podernos contactar contigo.
                  </p>
                </section>
              </CardBody>
            </Card>
            <section className="section-buttons d-flex justify-content-around">
              {/* <button className="btn-white" onClick={() => closeModal()}>
                Cancelar{" "}
              </button> */}
              <button className="btn-white" onClick={actualizar}>
                Actualizar{" "}
              </button>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalActualizaCelular);
