import React from "react";

import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";

const RadioButton = ({ name, value, required, validate }) => {
  const { register } = useFormContext();

  return (
    <div>
      <Radio
        value={value}
        inputProps={{ "aria-label": "B" }}
        {...register(name, { required, validate: { ...validate } })}
      />
    </div>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.any,
};

RadioButton.defaultProps = {
  name: "radio-card-input",
  required: { value: true, message: "Este campo es requerido." },
};
