import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";

import hocWidthWindow from "../../../../../HOC/HocWidthWindow";
import "./index.css";

am4core.useTheme(am4themes_animated);
class GraficoLineas extends Component {
  state = {
    infoCuadros: null,
    cantidadAportes: 0,
  };

  componentDidMount() {
    const { otraData } = this.props;
    const dataFormateada = this.formatData(otraData);

    if (dataFormateada.length > 0) {
      this.setState({
        ...this.state,
        infoCuadros: dataFormateada[dataFormateada.length - 1],
        cantidadAportes: dataFormateada.length,
      });
      this.drawGraphic(dataFormateada);
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  formatData = (data) => {
    let newData = data.map((item) => {
      const fechaArray = item.r_fecha.split("-");
      const newFecha = `${fechaArray[2]}-${fechaArray[0]}-${fechaArray[1]}`;
      return {
        date: newFecha,
        rentAcum: Number(item.r_rentabilidad),
        aportAcum: Number(item.r_aporte),
        fondAcum: Number(item.r_saldo),
        cargo: Number(item.r_retiro_cargo),
      };
    });

    return newData;
  };

  drawGraphic = (dataArray) => {
    let chart = am4core.create("chartdivAp", am4charts.XYChart);
    chart.language.locale = am4lang_es_ES;
    chart.data = dataArray;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 23;
    dateAxis.dateFormats.setKey("month", "MM/YY");
    dateAxis.periodChangeDateFormats.setKey("month", "MM/YY");
    dateAxis.renderer.labels.template.rotation = 270;
    dateAxis.renderer.labels.template.dx = -5;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.renderer.baseGrid.disabled = true;
    // valueAxis.renderer.opposite = true;
    chart.numberFormatter.language = new am4core.Language();
    chart.numberFormatter.language.locale = am4lang_en_US;
    valueAxis.numberFormatter.numberFormat = "S/ #,###.##";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "fondAcum";
    series.dataFields.dateX = "date";
    series.tooltipText = "S/ {fondAcum}";
    series.strokeWidth = 2;
    series.minBulletDistance = 0;
    series.stroke = am4core.color("#48C180");
    series.tooltipY = am4core.percent(-100);

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 10;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#48C180");
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.fill = am4core.color("#FFFFFF");
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";

    // Make bullets grow on hover
    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 0.2;
    bullet.circle.fill = am4core.color("#6bb8d9");

    var bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 8;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "bottom";
    // chart.legend.labels.template.text = "Fondo Acumulado en soles";
    chart.logo.disabled = true;
    this.chart = chart;
  };

  render() {
    return (
      <div
        className="container-graphic bg-white"
        style={{ width: "100%", textAlign: "center" }}
      >
        <div id="chartdivAp"></div>
        <Link
          className="btn-primary position-bottom-right"
          to="/mi-habitat-digital/afiliado/mis-fondos/evolucion-de-mi-fondo"
        >
          {" "}
          Ver más
        </Link>
      </div>
    );
  }
}

export default hocWidthWindow(GraficoLineas);
