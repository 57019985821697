import React from "react";
import * as actions from "./actions";

// Initial state
const affiliateInitialState = {
  data: null,
  status: "idle", // idle loading success error
  error: null,
};

const verificationEmailTokenInitialState = {
  data: null,
  status: "idle", // idle loading success error
  error: null,
};

const validationEmailTokenInitialState = {
  data: null,
  status: "idle", // idle loading success error
  error: null,
};

const requestInitialState = {
  data: null,
  status: "idle", // idle loading success error
  error: null,
};

const tokenInitialState = null;

export const initialState = {
  affiliate: affiliateInitialState,
  verificationEmailToken: verificationEmailTokenInitialState,
  validationEmailToken: validationEmailTokenInitialState,
  request: requestInitialState,
  token: tokenInitialState,
};

const reducer = (state, action) => {
  switch (action.type) {
    // affiliate
    case actions.GET_AFFILIATE_INFO:
      return {
        ...state,
        affiliate: {
          ...affiliateInitialState,
          status: "loading",
        },
      };
    case actions.AFFILIATE_SUCCESS:
      return {
        ...state,
        affiliate: {
          ...affiliateInitialState,
          status: "success",
          data: action.payload,
        },
      };
    case actions.AFFILIATE_FAILURE:
      return {
        ...state,
        affiliate: {
          ...affiliateInitialState,
          status: "error",
          error: action.payload,
        },
      };
    case actions.RESET_AFFILIATE_INFO:
      return {
        ...state,
        affiliate: {
          ...affiliateInitialState,
        },
      };

    // verification email token
    case actions.GET_VERIFICATON_EMAIL_TOKEN:
      return {
        ...state,
        verificationEmailToken: {
          ...verificationEmailTokenInitialState,
          status: "loading",
        },
      };
    case actions.VERIFICATION_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        verificationEmailToken: {
          ...verificationEmailTokenInitialState,
          status: "success",
          data: action.payload,
        },
      };
    case actions.VERIFICATION_EMAIL_TOKEN_FAILURE:
      return {
        ...state,
        verificationEmailToken: {
          ...verificationEmailTokenInitialState,
          status: "error",
          error: action.payload,
        },
      };

    // validation email token
    case actions.VALIDATION_EMAIL_TOKEN:
      return {
        ...state,
        validationEmailToken: {
          ...validationEmailTokenInitialState,
          status: "loading",
        },
      };
    case actions.VALIDATION_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        validationEmailToken: {
          ...validationEmailTokenInitialState,
          status: "success",
          data: action.payload,
        },
      };
    case actions.VALIDATION_EMAIL_TOKEN_FAILURE:
      return {
        ...state,
        validationEmailToken: {
          ...validationEmailTokenInitialState,
          status: "error",
          error: action.payload,
        },
      };

    // send request
    case actions.SEND_REQUEST:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "loading",
        },
      };
    case actions.SEND_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "success",
          data: action.payload,
        },
      };
    case actions.SEND_REQUEST_FAILURE:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "error",
          error: action.payload,
        },
      };
    // token
    case actions.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      break;
  }
};

export default reducer;
