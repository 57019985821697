import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import AuthContext from "../../context/autenticacion/authContext";
import TimerHook from "../../components/CountDown/IndexHook";
import HocVariables from "../../HOC/HocVariables";
import IC_USER from "../../assets/icons/ic_user.svg";
import MenuBurguer from "../../assets/icons/menu-burguer.svg";
import NavbarIcon from "../../assets/logo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "./index.css";
import { Box, useTheme, useMediaQuery, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

/*  <IconButton onClick={openMainDrawer}>
            <MenuIcon sx={{ fontSize: "32px" }} />
          </IconButton> */

import BreadCrumbsFL from "../BreadcrumbsFL";

import {
  PRIMER_URL,
  SEGUNDO_URL,
  INFORMACION_PERSONAL,
} from "../../utils/constants";
const settings = [
  {
    id: "datos",
    title: "Datos personales",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/datos-personales",
  },
  {
    id: "clave",
    title: "Clave Web",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/clave-web",
  },
  {
    id: "cuentas",
    title: "Cuentas bancarias",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/cuentas-bancarias",
  },
  {
    id: "sesion",
    title: "Cerrar sesión",
    subMenu: null,
    url: "",
  },
];
const MainNavbar = (props) => {
  const { setOpenDrawe } = props;
  const location = useLocation();
  const urlView = location.pathname.split("/");
  const geturl1 = PRIMER_URL.filter((item) => item.id === urlView[3]);
  const geturl2 = SEGUNDO_URL.filter((item) => item.id === urlView[4]);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [arrayURL, setArrayUrl] = useState([]);

  const theme = useTheme();
  const matches = useMediaQuery("(max-width:980px)");

  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, timer, actualizarTimer, cerrarSesion } =
    authContext;

  useEffect(() => {
    usuarioAutenticado();
    if (timer === null) {
      actualizarTimer();
    }
    // eslint-disable-next-line
  }, [timer]);

  const openMainDrawer = () => {
    setOpenDrawe(true);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const subUrlView = location.search !== "" ? location.search.split("=") : "";
  const nameSection =
    subUrlView !== ""
      ? subUrlView[subUrlView.length - 1]
      : urlView[urlView.length - 1];
  const titleNav =
    subUrlView !== ""
      ? subUrlView[1] === "OBL"
        ? "Fondo de jubilación"
        : subUrlView[1] === "VSF"
        ? "Fondo Libre Habitat"
        : "Aporte Voluntario con fin Previsional"
      : nameSection === "inicio"
      ? "Bienvenido"
      : nameSection === "estado-cuenta-mensual"
      ? "Estado de cuenta mensual"
      : nameSection === "ultimos-movimientos"
      ? "Últimos movimientos"
      : nameSection === "cambio-de-fondo"
      ? "Cambio de Tipo de fondo"
      : nameSection === "comision-historica"
      ? "Comisión histórica"
      : nameSection === "estado-cuenta-no-aportante"
      ? "Estado de cuenta no aportante"
      : nameSection === "evolucion-de-mi-fondo"
      ? "Evolución de tu fondo"
      : nameSection === "retiro-aporte-voluntario-sin-fin"
      ? "Retiro de Fondo Libre Habitat"
      : nameSection === "aporte-voluntario-sin-fin"
      ? "Invertir en Fondo Libre Habitat"
      : nameSection === "apv"
      ? "Seguimiento a tus aportes"
      : nameSection === "tipo-jubilacion"
      ? "Fondo de Jubilación"
      : nameSection === "datos-personales"
      ? "Actualización de datos"
      : nameSection === "clave-web"
      ? "Actualización de clave web"
      : nameSection === "cuentas-bancarias"
      ? "Cuentas Bancarias"
      : nameSection === "boleta-pago"
      ? "Boleta de Pago"
      : nameSection === "drspp"
      ? "Certificado de afiliación"
      : nameSection === "boleta-digital"
      ? "Comprobante de pago"
      : nameSection === "pagos-pendientes"
      ? "Deuda de tu empleador"
      : nameSection === "jubilacion" ||
        nameSection === "jubilacion-legal" ||
        nameSection === "jubilacion-reja"
      ? "Jubilación"
      : nameSection === "25"
      ? "Retiro del 25%"
      : nameSection === "transferencia-fondos-exterior"
      ? "Transferencia de fondos al exterior"
      : urlView[3] === "jubilacion-legal" && nameSection === "tramite"
      ? "Jubilación por Edad Legal"
      : urlView[3] === "jubilacion-reja" && nameSection === "tramite"
      ? "Jubilación Anticipada por Desempleo"
      : nameSection === "estado-de-tramite"
      ? "Estado de trámites"
      : nameSection === "noticias-de-inversiones"
      ? "Noticias de inversión"
      : nameSection;

  const client = JSON.parse(localStorage.getItem("client"));

  const closeSesion = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(true, props.history.location);
    props.limpiarVariablesContext();
  };

  const array =
    geturl1.length > 0 && geturl2.length === 0
      ? [geturl1[0]]
      : geturl1.length > 0 && geturl2.length > 0
      ? [geturl1[0], geturl2[0]]
      : arrayURL;

  return (
    <header className={matches ? "header-menuChanges" : "header-menu"}>
      <nav
        className={matches ? "header-menu_navbarChange" : "header-menu_navbar"}
      >
        <div className="header-menu_navbar-options">
          <div className="title-container">
            <Box>
              {array.length > 0 ? (
                <BreadCrumbsFL
                  // tipo={tipo}
                  title_mobile={"Trámite"}
                  title={""}
                  rutas={array}
                />
              ) : (
                <Typography sx={{ fontSize: "18px" }}>Bienvenido</Typography>
              )}
            </Box>
          </div>

          <div className="user-info-container">
            <TimerHook />
            <Box
              sx={{
                padding: "0 8px",
              }}
            >
              <div className="info">
                <Box
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    textAlign: "right",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.2px",
                    color: "#363A3E",
                  }}
                >
                  {client.r_primer_nombre}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    textAlign: "right",
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.2px",
                    color: "#8C9195",
                  }}
                >
                  DNI: {localStorage.getItem("doc_num")}
                </Box>
              </div>
            </Box>
            <div className="initial-letter" onClick={handleOpenUserMenu}>
              <img src={IC_USER} alt="user" />
            </div>
            {/* <div className="arrow-icon" onClick={handleOpenUserMenu}>
              <img src={downArrowicon} alt="" />
            </div> */}
            <Menu
              sx={{ mt: "45px" }}
              id="demo-customized-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {INFORMACION_PERSONAL.map((setting) => (
                <MenuItem key={setting.id} onClick={handleCloseUserMenu}>
                  {setting.id === "sesion" ? (
                    <a href="#" onClick={() => closeSesion()}>
                      <Typography textAlign="center">
                        {setting.title}
                      </Typography>
                    </a>
                  ) : (
                    <Link to={setting.url}>
                      <Typography textAlign="center">
                        {setting.title}
                      </Typography>
                    </Link>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </div>
          {matches ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "35px !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <IconButton onClick={openMainDrawer}>
                  <MenuIcon sx={{ fontSize: "32px" }} />
                </IconButton>
                <Box
                  sx={{
                    display: { xs: "flex" },
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "120px",
                    }}
                    src={NavbarIcon}
                    alt="logo"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  minWidth: "70px",
                  backgroundColor: "white",
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TimerHook />
              </Box>
            </Box>
          ) : (
            <>
              <button className="menu-hamburger" onClick={openMainDrawer}>
                <img src={MenuBurguer} alt="menu-hamburger" />
              </button>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "150px",
                  }}
                  src={NavbarIcon}
                  alt="logo"
                />
              </Box>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default HocVariables(MainNavbar);
