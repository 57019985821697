import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { Grid } from "@mui/material";
import "./index.css";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import Alert from "../../../../../../../../components/Alert";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../../../component/ButtonsComponents";
import { filterTypePension } from "../../../../../../../../utils/Functions";
import dummyEstimacion from "../../../../../../../../Dummys/dummyEstimacion.json";
import { CardContent, ProductsConsidered } from "../../../../styles/Card";
import {
  Content,
  ContentPension,
  Chip,
  GridBorderColor,
  ContentPm,
} from "../../../../styles/Estimacion";
import { montoFormat } from "../../../../../../../../helper/amountFormat";
const array95 = [25, 50, 75, 955];
export default function EstimacionDetalle(props) {
  const { data, getBackToMain, detailData, setDetail } = props;
  const dataArray =
    detailData.id === "0"
      ? [0]
      : detailData.id === "955"
      ? [25, 50, 75, 955]
      : ["pm"];
  const [showModalDetailPension, setShowModalDetailPension] = useState(false);

  const [pensionSelected, setPensionSelected] = useState("retiro_programado");

  let detailpensionSlected = filterTypePension(
    dataArray,
    pensionSelected,
    data
  );

  useEffect(() => {
    return () => {
      setDetail({ percent: "", object: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const excendete = {
    number: Number(data?.pmo?.excedente?.replaceAll(",", "")),
    text: data?.pmo?.excedente,
  };

  return (
    <div className="estimacion-detalle-container HBT_JUBLEGAL_Estimacion">
      {detailData.id === "0" ? (
        <h1>Si optas por Pensión Mensual</h1>
      ) : detailData.id === "955" ? (
        <h1>Si retiras hasta 95.5%</h1>
      ) : (
        <h1>Si optas por tu pensión objetivo</h1>
      )}

      <div className="content">
        {detailData.id !== "pm" && (
          <h3 className="my-pension">
            Tu pensión en{" "}
            {detailData.id === "pm" ? (
              <span>Retiro programado</span>
            ) : (
              <Input
                className="type-pension"
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => setPensionSelected(e.target.value)}
              >
                <option value="retiro_programado">Retiro programado</option>
                <option value="retiro_vitalicia_familiar">
                  Renta vitalicia familiar
                </option>
                <option value="renta_temporal">
                  Renta temporal con renta vitalicia diferida
                </option>
              </Input>
            )}{" "}
            sería:
          </h3>
        )}
      </div>
      {detailpensionSlected.data.map((item, index) => {
        let getKey = Object.keys(item)[0];
        console.log(getKey);
        return (
          <Content>
            {detailData.id === "pm" && excendete.number <= 0 && (
              <Alert
                variant={"error"}
                title={"Lo sentimos"}
                description={
                  "No posees fondos suficientes en tu Fondo de Jubilación para acceder a esta opción."
                }
              />
            )}

            {array95.includes(Number(getKey)) && (
              <h3>Si retiras el {Number(getKey)}%</h3>
            )}

            <CardContent>
              <ContentPension>
                {detailData.id !== "pm" && <h4>Recibo una pensión de:</h4>}

                <Grid container mb={1}>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {detailData.id !== "pm" ? (
                      <Chip>Al mes</Chip>
                    ) : (
                      <Chip>Tus fondos</Chip>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {detailData.id !== "pm" ? (
                      <Chip color="secondary">Anual</Chip>
                    ) : (
                      <Chip color="secondary">
                        {excendete.number >= 0
                          ? "Monto excedente"
                          : "Monto faltante"}
                      </Chip>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <GridBorderColor
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                    HBTborderColor="right"
                  >
                    {detailData.id !== "pm" ? (
                      <div>S/ {item[getKey].mensual}</div>
                    ) : (
                      <div>S/ {data?.cic}</div>
                    )}
                  </GridBorderColor>
                  <GridBorderColor
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                    HBTborderColor="left"
                  >
                    {detailData.id !== "pm" ? (
                      <div>S/ {item[getKey].anual}</div>
                    ) : (
                      <div>S/ {excendete.text.replace("-", "")}</div>
                    )}
                  </GridBorderColor>
                </Grid>
              </ContentPension>
            </CardContent>
          </Content>
        );
      })}
      {detailData.id === "pm" && (
        <ContentPm>
          <CardContent>
            Pensión mínima objetivo: S/ {montoFormat(data?.pmo?.pension)}{" "}
          </CardContent>
          {data?.productos_considerados?.length > 0 && (
            <ProductsConsidered>
              <div className="title">
                <b>Productos considerados para el cálculo:</b>
              </div>
              <ul>
                {data?.productos_considerados.map((e) => (
                  <li className="list-">{e}</li>
                ))}
              </ul>
            </ProductsConsidered>
          )}

          <Alert
            title={"Sobre el cálculo de pensión"}
            description={
              <>
                <p style={{ marginBottom: "16px" }}>
                  El Monto excedente es la diferencia entre tus fondos y el
                  Saldo Mínimo de Jubilación (SMJ).
                </p>
                <p style={{ marginBottom: "16px" }}>
                  El Saldo Mínimo de Jubilación (SMJ) es el Capital o monto
                  requerido, que necesitas para poder acceder a la PMO.
                </p>
                <p style={{ marginBottom: "16px" }}>
                  En caso de no haber establecido previamente tu Pensión
                  Objetivo, se considera como pensión el monto vigente de la
                  Canasta Básica Familiar del INEI. Además, los productos
                  considerados son los siguientes: Fondo de Jubilación - Fondo
                  con fin previsional.
                </p>
              </>
            }
          />
        </ContentPm>
      )}

      {detailData.id !== "pm" && (
        <>
          <p className="pension-info">
            ¿Qué es{" "}
            <strong>
              {pensionSelected === "retiro_programado"
                ? "Retiro Programado"
                : pensionSelected === "retiro_vitalicia_familiar"
                ? "Renta vitalicia familiar"
                : "Renta temporal con renta vitalicia diferida"}
            </strong>
            ?
          </p>
          <p className="pension-info detail-pension mb-3">
            {pensionSelected === "retiro_programado"
              ? "Recibirás una pensión mensual, la cual se recalcula anualmente según tu saldo acumulado, expectativas de vida, beneficiarios, rentabilidad, etc. Al mismo tiempo, tu saldo, al seguir estando en la AFP, seguirá ganando rentabilidad."
              : pensionSelected === "retiro_vitalicia_familiar"
              ? "Tu fondo se transfiere a una compañía de seguros, que será la encargada de darte una pensión de por vida. Si lamentablemente falleces, tus beneficiarios pueden recibir pensión de sobrevivencia."
              : "Una parte de tu fondo va a una compañía de seguros mientras lo restante sigue en la AFP. Bajo esta modalidad, la AFP otorga una pensión temporal por 1, 3 o 5 años; posteriormente, la aseguradora brinda una pensión de por vida."}
          </p>
        </>
      )}

      <ButtonsWrapper>
        {detailData.id !== "pm" && (
          <PrevButton
            variant="secondary"
            onClick={() => setShowModalDetailPension(true)}
          >
            Saber más
          </PrevButton>
        )}
        <NextButton onClick={() => getBackToMain()}>Volver</NextButton>
      </ButtonsWrapper>
      {/* 

        {pensionSelected === "renta_temporal_anual" && (
          <>
            <h5 className="mt-3">Temporal en el año 1</h5>
            <div className="center-items mb-4">
              <div className="estimate-table-container">
                <div className="estimate-header mb-3">
                  <div className="half-col">
                    <span className="header-span-block bg-gray">Al mes</span>
                  </div>
                  <div className="half-col">
                    <span className="header-span-block bg-blue">Anual</span>
                  </div>
                </div>
                <div className="estimate-amount">
                  <div className="half-col border-right-blue">
                    <span className="bold-gray">
                      s/. {detailData.object.monthly["renta_temporal"]}
                    </span>
                  </div>
                  <div className="half-col  border-left-blue">
                    <span className="bold-blue">
                      S/.{detailData.object.annual["renta_temporal"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <h5>Vitalicia en el año 1</h5>
            <div className="center-items mb-5">
              <div className="estimate-table-container">
                <div className="estimate-header  mb-3">
                  <div className="half-col ">
                    <span className="header-span-block bg-gray">Al mes</span>
                  </div>
                  <div className="half-col ">
                    <span className="header-span-block bg-blue">Anual</span>
                  </div>
                </div>
                <div className="estimate-amount ">
                  <div className="half-col  border-right-blue">
                    <span className="bold-gray">
                      s/. {detailData.object.monthly["renta_vitalicia"]}
                    </span>
                  </div>
                  <div className="half-col   border-left-blue">
                    <span className="bold-blue">
                      S/.{detailData.object.annual["renta_vitalicia"]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>


     */}
      <CustomModal
        showModal={showModalDetailPension}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => setShowModalDetailPension(false)}
        title={`${
          pensionSelected === "retiro_programado"
            ? "RETIRO PROGRAMADO"
            : pensionSelected === "retiro_vitalicia_familiar"
            ? "RENTA VITALICIA FAMILIAR"
            : "RENTA TEMPORAL CON RENTA VITALICIA DIFERIDA"
        }`}
        description={
          <div className="more-pension">
            {pensionSelected === "retiro_programado" ? (
              <p>
                Es una modalidad de pensión donde la AFP genera un pago mensual
                en nuevos soles. Cada pensión se recalcula cada año,
                considerando algunos factores importantes como el saldo
                acumulado, tus expectativas de vida, la rentabilidad futura de
                la cuenta, entre otras.
                <br /> Es importante tener en cuenta que los saldos son
                exclusivamente propiedad del afiliado y que continúan ganando
                rentabilidad en la AFP. Por un lado, los fondos destinados a la
                prestación que no se lleguen a utilizar por concepto de pago de
                pensión, constituirán herencia para todo efecto legal, siempre y
                cuando no quedasen beneficiarios. Por otro lado, puedes cambiar
                a otra Modalidad de Pensión (Renta Vitalicia Familiar, Renta
                Temporal con Renta Vitalicia Diferida, entre otras) cuando lo
                desees y mientras el saldo de la cuenta lo permita. Pero
                recuerda que mientras se encuentre ejercitando esta modalidad de
                pensión, el afiliado o los beneficiarios pueden traspasar los
                fondos de una AFP a otra.
              </p>
            ) : pensionSelected === "retiro_vitalicia_familiar" ? (
              <div>
                <p>
                  Es una modalidad de pensión donde tus ahorros son transferidos
                  a una compañía de seguros, quien deberá pagar una pensión
                  mensual hasta la fecha de tu fallecimiento y posteriormente
                  una pensión de sobrevivencia a tus beneficiarios. De no
                  existir beneficiarios, se termina la obligación de pago de la
                  compañía de seguros <br />
                  Es importante considerar que los fondos acumulados en la AFP
                  se transfieren a la compañía de seguros y es esta misma la
                  responsable de realizar el pago de la pensión. Además, es
                  irrevocable por ambas partes, y sólo tendrá término con el
                  fallecimiento del afiliado en caso no tuviera beneficiarios, o
                  si el último beneficiario que tuviera derecho a pensión
                  perdiera tal condición. Esta opción genera pensiones de
                  sobrevivencia y los fondos que no se lleguen a utilizar por
                  concepto de pago de pensión, no constituyen herencia. El monto
                  de la renta es en nuevos soles o dólares americanos:
                </p>
                <ul>
                  <li>
                    Nuevos soles, cuyo monto se actualiza trimestralmente en
                    función de la variación del IPC (Índice de Precios al
                    Consumidor).
                  </li>
                  <li>
                    Nuevos soles, actualizado a una tasa fija anual, no menor al
                    2%.
                  </li>
                  <li>
                    En dólares americanos, actualizado por una tasa fija anual,
                    no menor al 2%.
                  </li>
                </ul>
              </div>
            ) : (
              <p>
                Es una modalidad de pensión por la que un afiliado retiene en su
                Cuenta Individual de Capitalización los fondos suficientes para
                obtener de la AFP una Renta Temporal y, adicionalmente, contrata
                una Renta Vitalicia Familiar, con la finalidad de recibir pagos
                mensuales a partir de una fecha determinada.
                <br />
                La Renta Temporal se otorga por un periodo de uno (1), tres (3)
                o cinco (5) años a elección del afiliado, o en caso de
                sobrevivencia a elección de sus beneficiarios y se recalcula
                cada año. Mientras se encuentre ejercitando la Renta Temporal el
                afiliado o los beneficiarios pueden traspasar los fondos de una
                AFP hacia otra, sujetándose a las disposiciones que establezca
                la Superintendencia sobre la materia. Si el afiliado fallece
                cuando esté ejerciendo la Renta Temporal, los fondos
                administrados por la AFP para financiar la Renta Temporal,
                constituyen herencia para todos los efectos legales siempre que
                no existan beneficiarios al momento de la ocurrencia del
                siniestro. De existir beneficiarios, se procederá a otorgar las
                pensiones de sobrevivencia que correspondan. El último pago bajo
                la modalidad de Renta Temporal, deberá contener el íntegro del
                saldo existente en la Cuenta Individual de Capitalización del
                afiliado que mantiene la AFP.
                <br />
                Después se procederá a abonar la renta vitalicia familiar, con
                la condición de que no puede ser inferior al 50% del primer pago
                mensual de la Renta Temporal ni superior al 100% del mismo. Para
                el caso de Renta Vitalicia Diferida en dólares, el cálculo del
                porcentaje se efectuará sobre la pensión del primer mes de
                devengue, utilizando el tipo de cambio vigente a dicha fecha.
                Además, se calcula una única vez estando sujeta a mecanismos de
                reajuste, ya sea por IPC o por tasa fija anual, en el caso de
                soles o; únicamente, por tasa fija anual, en el caso de dólares
                americanos.
              </p>
            )}
          </div>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => setShowModalDetailPension(false)}
        textBtnPrimary="Cerrar"
      />
    </div>
  );
}
