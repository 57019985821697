import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
      <path
        stroke="#363A3E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.333"
        d="M36.667 18.467V20a16.666 16.666 0 11-9.883-15.233"
      ></path>
      <path
        stroke="#363A3E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.333"
        d="M36.667 6.667L20 23.35l-5-5"
      ></path>
    </svg>
  );
}

export default Icon;
