import React from 'react'

const GraficOneDisableIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7576 13.1384L24.9178 27.4973C29.2933 22.0818 35.9881 18.6178 43.496 18.6178V0C31.4055 0 20.4959 5.04416 12.7576 13.1384Z"
        fill="#EDEDED"
      />
      <path
        d="M0.995911 42.4581H19.6322C19.6322 36.7912 21.6127 31.5884 24.9175 27.4978L12.7573 13.1389C5.47008 20.7613 0.995911 31.0875 0.995911 42.4581Z"
        fill="#CDCED0"
      />
      <path
        d="M43.4959 0V18.6178C50.9671 18.6178 57.6367 22.0489 62.0121 27.4199L74.1665 13.0688C66.434 5.01515 55.5516 0 43.4959 0Z"
        fill="#EDEDED"
      />
      <path
        d="M85.9951 42.4572C85.9951 31.0517 81.4919 20.6965 74.1659 13.0684L62.0115 27.4195C65.3531 31.5217 67.3589 36.7554 67.3589 42.4572H85.9951Z"
        fill="#EDEDED"
      />
      <path
        d="M41.3835 37.8914L38.6418 36.9494C37.6153 36.5968 36.6358 37.5949 37.0121 38.6113L38.1454 41.6729C38.0383 42.0117 37.957 42.3617 37.907 42.7257C37.4293 46.203 39.8668 49.4027 43.3499 49.8707C46.833 50.3388 50.0453 47.8983 50.5229 44.421C51.0006 40.9437 48.5631 37.744 45.08 37.276C43.7635 37.0991 42.4877 37.3374 41.3819 37.8893L41.3835 37.8914Z"
        fill="#EDEDED"
      />
    </svg>
  );
}

export default GraficOneDisableIcon