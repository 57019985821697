import React from "react";

const SelectCard = ({ cuentas, setCuenta, setMensaje }) => {

    const formatNombre = (banco, numero) => {
        let banquito = banco.toLowerCase();
        return `${banquito.charAt(0).toUpperCase() + banquito.slice(1)} - ${numero}`;
    }

    const selectCardOption = (e) =>{
        let item = JSON.parse(e.target.value);
        setCuenta(item);
        setMensaje(null);
    }
  return (
    <div className="mb-2">
      <select
        className="form-control"
        type="select"
        name="cuenta"
        id="cuenta"
        onChange={selectCardOption}
        // ref={register}
      >
        <option value={""}>-Seleccione cuenta-</option>
        {cuentas.map((item, index) => (
          <option key={index} value={JSON.stringify(item)}>
            {formatNombre(item.r_nombre_banco, item.r_numero_cuenta)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectCard;
