import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  Dialog,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useMediaQuery, useTheme, Grid, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import {
  listaDepartamentos,
  listaProvincias,
  listaDistritos,
  listaUbicaciones,
} from "../../data/peruInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  disabledBg: "#F8F9FB",
};

const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};

const useStyles = makeStyles({
  selectStyles: {
    "& .MuiSelect-filled": {
      borderRadius: "16px",
    },

    "& .MuiSelect-filled.Mui-disabled": {
      backgroundColor: palletteColors.disabledBg,
    },
  },
  textFieldFilledStyles: {
    "& .MuiFilledInput-root": {
      borderRadius: "16px",
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: palletteColors.disabledBg,
    },
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
    fonSize: "10px",
  },
  boxDescriptionStyles: {
    display: "flex",
    alignItems: "center",
    backgroundColor: palletteColors.BlueBg,
    borderRadius: "16px",
    height: "80px",
    color: palletteColors.GreyText,
  },
  paperStylesChages: {
    "& .MuiDialog-paper": {
      borderRadius: "0px",
    },
    fonSize: "10px",
  },
  titleStyles: {
    color: palletteColors.Red,
  },
  buttonUpCloseStyles: {
    color: palletteColors.Red,
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    width: "100%",
  },
});

const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const globalErrors = {
  v_tipo_cuenta: "",
  v_declaracion_otros: "",
  v_centro_laboral: "",
  v_declaracion_cargo: "",
  v_declaracion_tiempo_servicio: "",
  v_declaracion_ingreso_mensual: "",
  v_declaracion_profesion: "",
  v_tipo_trabajador: "",
  // v_declaracion_origen_fondos: "",
  departamento: "",
  provincia: "",
  distrito: "",
  ubicacion: "",
  direccion: "",
};
const ModalDeclaracionJurada = ({
  openModal,
  closeModal,
  adjuntarDeclaracionJurada,
}) => {
  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [formulario, setFormulario] = useState({
    v_tipo_cuenta: "A",
    v_declaracion_otros: "",
    v_centro_laboral: "",
    v_direccion: "",
    v_declaracion_cargo: "",
    v_declaracion_tiempo_servicio: "",
    v_declaracion_ingreso_mensual: "",
    v_declaracion_profesion: "",
    v_tipo_trabajador: "dependiente",
    // v_declaracion_origen_fondos: "",
  });

  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [declaracionOtros, setDeclaracionOtros] = useState("");
  const [centroDeLabores, setCentroDeLabores] = useState("");
  const [tiempodeServicio, setTiempodeServicio] = useState("");
  const [declaracionIngresoMensual, setDeclaracionIngresoMensual] =
    useState("");
  const [declaracionProfesion, setDeclaracionProfesion] = useState();
  const [declaracionCargo, setDeclaracionCargo] = useState("");
  const [tipoTrabajador, setTipoTrabajador] = useState("");
  const [yearormonth, setYearormonth] = useState("");

  const [departamento, setDepartamento] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [direccion, setDireccion] = useState("");
  const [errores, setErrores] = useState(globalErrors);

  const handlerSelect = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
      v_declaracion_otros: "",
    });
  };
  const handlerInput = (e) => {
    setFormulario({ ...formulario, [e.target.name]: e.target.value });
    setErrores({ ...errores, [e.target.name]: "" });
  };
  const handlerInputNumber = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    // setNroDeCuenta(valor);
    // setErrores({ ...errores, nroCuenta: "" });

    setFormulario({ ...formulario, [e.target.name]: valor });
    setErrores({ ...errores, [e.target.name]: "" });
  };

  const handleYearOrMonth = (e) => {
    setYearormonth(e.target.value);
  };

  const handleInputDirection = (e) => {
    setDireccion(e.target.value);
    setErrores({ ...errores, [e.target.name]: "" });
  };

  const handlerSelectInfo = (setData) => (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setData(JSON.stringify(opcion));
      setErrores({ ...errores, [e.target.name]: "" });
    } else {
      setData("");
    }
  };

  const renderDepartamentos = () => {
    return listaDepartamentos.map((item, index) => (
      <MenuItem
        sx={styles.menuItemStyles}
        key={index}
        value={JSON.stringify(item)}
      >
        {item.name}
      </MenuItem>
    ));
  };
  const renderUbicacion = () => {
    return listaUbicaciones.map((item, index) => (
      <MenuItem
        sx={styles.menuItemStyles}
        key={index}
        value={JSON.stringify(item)}
      >
        {item.name}
      </MenuItem>
    ));
  };
  const renderProvincias = (idDeparment) => {
    if (idDeparment !== "") {
      const departamento = JSON.parse(idDeparment);
      let listaFiltrada = listaProvincias.filter(
        (item) => item.department_id === departamento.id
      );
      return listaFiltrada.map((item, index) => (
        <MenuItem
          sx={styles.menuItemStyles}
          key={index}
          value={JSON.stringify(item)}
        >
          {item.name}
        </MenuItem>
      ));
    } else {
      return "";
    }
  };
  const renderDistritos = (idDeparment, idProvincia) => {
    if (idDeparment !== "" && idProvincia !== "") {
      const departamento = JSON.parse(idDeparment);
      const provincia = JSON.parse(idProvincia);
      let listaFiltrada = listaDistritos.filter(
        (item) =>
          item.department_id === departamento.id &&
          item.province_id === provincia.id
      );
      return listaFiltrada.map((item, index) => (
        <MenuItem
          sx={styles.menuItemStyles}
          key={index}
          value={JSON.stringify(item)}
        >
          {item.name}
        </MenuItem>
      ));
    } else {
      return "";
    }
  };

  const validarData = () => {
    let mensajesError = {
      v_tipo_cuenta: "",
      v_declaracion_otros: "",
      v_centro_laboral: "",
      departamento: "",
      provincia: "",
      distrito: "",
      ubicacion: "",
      direccion: "",
      v_tipo_trabajador: "",
    };

    if (tipoDeCuenta === 0 || tipoDeCuenta.trim() === "") {
      mensajesError = {
        ...mensajesError,
        v_tipo_cuenta: "campo requerido",
      };
    }
    if (tipoDeCuenta === "Otros") {
      if (declaracionOtros.length === 0 || declaracionOtros.trim() === "") {
        mensajesError = {
          ...mensajesError,
          v_declaracion_otros: "campo requerido",
        };
      }
    }
    if (centroDeLabores.length === 0 || centroDeLabores.trim() === "") {
      mensajesError = { ...mensajesError, v_centro_laboral: "campo requerido" };
    }
    if (tipoTrabajador.length === 0) {
      mensajesError = {
        ...mensajesError,
        v_tipo_trabajador: "campo requerido",
      };
    }
    if (departamento.length === 0) {
      mensajesError = { ...mensajesError, departamento: "campo requerido" };
    }
    if (provincia.length === 0) {
      mensajesError = { ...mensajesError, provincia: "campo requerido" };
    }
    if (distrito.length === 0) {
      mensajesError = { ...mensajesError, distrito: "campo requerido" };
    }
    if (ubicacion.length === 0) {
      mensajesError = { ...mensajesError, ubicacion: "campo requerido" };
    }
    if (declaracionCargo.length === 0 || declaracionCargo.trim() === "") {
      mensajesError = {
        ...mensajesError,
        v_declaracion_cargo: "campo requerido",
      };
    }
    if (tiempodeServicio.length === 0 || tiempodeServicio.trim() === "") {
      mensajesError = {
        ...mensajesError,
        v_declaracion_tiempo_servicio: "campo requerido",
      };
    }
    if (
      declaracionIngresoMensual.length === 0 ||
      declaracionIngresoMensual.trim() === ""
    ) {
      mensajesError = {
        ...mensajesError,
        v_declaracion_ingreso_mensual: "campo requerido",
      };
    }

    if (direccion.length === 0 || direccion.trim() === "") {
      mensajesError = { ...mensajesError, direccion: "campo requerido" };
    }

    setErrores(mensajesError);
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const handleSubmit = () => {
    if (validarData()) {
      const dep = JSON.parse(departamento);
      const prov = JSON.parse(provincia);
      const dist = JSON.parse(distrito);
      const ubic = JSON.parse(ubicacion);

      let nuevoFormulario = {
        v_tipo_cuenta: tipoDeCuenta,
        v_declaracion_otros: declaracionOtros,
        v_centro_laboral: centroDeLabores,
        v_declaracion_tiempo_servicio: tiempodeServicio,
        v_declaracion_ingreso_mensual: declaracionIngresoMensual,
        v_declaracion_profesion: declaracionProfesion,
        v_declaracion_cargo: declaracionCargo,
        v_tipo_trabajador: tipoTrabajador,
      };

      nuevoFormulario["v_declaracion_origen_fondos"] =
        nuevoFormulario["v_tipo_cuenta"];
      nuevoFormulario["v_declaracion_tiempo_servicio"] =
        nuevoFormulario["v_declaracion_tiempo_servicio"] + " " + yearormonth;
      nuevoFormulario["v_tipo_trabajador"] = tipoTrabajador;
      nuevoFormulario.v_direccion = `${dep.name} ${prov.name} ${dist.name} ${ubic.name} ${direccion}`;
      adjuntarDeclaracionJurada(nuevoFormulario);
    }
  };
  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={openModal}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="md"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container sx={{ pl: fullScreen ? 2 : 5 }}>
          <Grid
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className={classes.titleStyles}>
              Declaración de origen de fondos
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className={classes.buttonUpCloseStyles}
              onClick={closeModal()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12}>
          <Box className={classes.boxDescriptionStyles}>
            <Typography sx={{ pl: 2, pr: 2 }}>
              Declaro que mi inversión en Fondo Libre Habitat proviene de
              actividades lícitas y brindo detalles sobre sus fuentes de origen.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={fullScreen ? 12 : 6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#000000", fontWeight: 400 }}>
                    Datos generales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel required id="Type-account">
                        Origen de fondos
                      </InputLabel>

                      <Select
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                        }}
                        className="form-control"
                        value={tipoDeCuenta}
                        onChange={(e) => {
                          setTipoDeCuenta(e.target.value);
                        }}
                        disableUnderline
                      >
                        <MenuItem sx={styles.menuItemStyles} value="A">
                          Ahorros
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="PH">
                          Pago de haberes
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="AE">
                          Asignaciones especiales
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="IN">
                          Ingresos por negocio
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="HF<">
                          Herencia familiar
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="CDP">
                          Cancelación de depósitos a plazo
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="VP">
                          Venta de propiedades
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="VTV">
                          Venta de títulos valor
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="P">
                          Préstamos
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="J">
                          Jubilado
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="ANL">
                          Actualmente no labora
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="RR">
                          Recibe rentas
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="Otros">
                          Otros
                        </MenuItem>
                      </Select>
                      {errores.v_tipo_cuenta && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_tipo_cuenta}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                {tipoDeCuenta === "Otros" && (
                  <Grid item xs={12}>
                    <ThemeProvider theme={Theme}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          className={classes.textFieldFilledStyles}
                          id="filled-basic"
                          label="Otro origen de fondo"
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={declaracionOtros}
                          onChange={(e) => {
                            setDeclaracionOtros(e.target.value);
                          }}
                        />
                        {errores.v_declaracion_otros && (
                          <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                            {errores.v_declaracion_otros}
                          </span>
                        )}
                      </FormControl>
                    </ThemeProvider>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        className={classes.textFieldFilledStyles}
                        id="filled-basic"
                        label="Centro de labores"
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={centroDeLabores}
                        onChange={(e) => {
                          setCentroDeLabores(e.target.value);
                        }}
                      />
                      {errores.v_centro_laboral && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_centro_laboral}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="Type-account">Tipo de Trabajo</InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                        }}
                        className={classes.selectStyles}
                        value={tipoTrabajador}
                        onChange={(e) => {
                          setTipoTrabajador(e.target.value);
                          setErrores({ ...errores, [e.target.name]: "" });
                        }}
                        disableUnderline
                      >
                        <MenuItem
                          sx={styles.menuItemStyles}
                          value="dependiente "
                        >
                          Dependiente
                        </MenuItem>
                        <MenuItem
                          sx={styles.menuItemStyles}
                          value="independiente"
                        >
                          Independiente
                        </MenuItem>
                      </Select>
                      {errores.v_tipo_trabajador && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_tipo_trabajador}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        label="Cargo"
                        value={declaracionCargo}
                        onChange={(e) => {
                          setDeclaracionCargo(e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                      {errores.v_declaracion_cargo && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_declaracion_cargo}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={8}>
                  <ThemeProvider theme={Theme}>
                    <InputMask
                      mask="99"
                      disabled={false}
                      maskChar=" "
                      value={tiempodeServicio}
                      onChange={(e) => {
                        setTiempodeServicio(e.target.value);
                      }}
                    >
                      {(inputProps) => (
                        <FormControl fullWidth>
                          <TextField
                            required
                            className={classes.textFieldFilledStyles}
                            label="Tiempo de servicio"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="filled"
                          />
                          {errores.v_declaracion_tiempo_servicio && (
                            <span
                              style={{ fontSize: "14px", color: "#bf0d3e" }}
                            >
                              {errores.v_declaracion_tiempo_servicio}
                            </span>
                          )}
                        </FormControl>
                      )}
                    </InputMask>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={4}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="Type-account">Tiempo</InputLabel>
                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                        }}
                        className={classes.selectStyles}
                        value={yearormonth}
                        onChange={handleYearOrMonth}
                        disableUnderline
                      >
                        <MenuItem value="meses">meses</MenuItem>
                        <MenuItem value="años">años</MenuItem>
                      </Select>
                      {errores.v_declaracion_tiempo_servicio && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_declaracion_tiempo_servicio}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        className={classes.textFieldFilledStyles}
                        label="Profesión"
                        value={declaracionProfesion}
                        onChange={(e) => {
                          setDeclaracionProfesion(e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="Type-account">
                        Ingreso promedio mensual
                      </InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                        }}
                        className={classes.selectStyles}
                        value={declaracionIngresoMensual}
                        onChange={(e) => {
                          setDeclaracionIngresoMensual(e.target.value);
                        }}
                        disableUnderline
                      >
                        <MenuItem sx={styles.menuItemStyles} value="">
                          Seleccione
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="A">
                          Hasta S/ 3,000
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="B">
                          De S/ 3,001 hasta S/ 5,000
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="C">
                          De S/ 5,001 hasta S/ 10,000
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="D">
                          De S/ 10,001 hasta S/ 20,000
                        </MenuItem>
                        <MenuItem sx={styles.menuItemStyles} value="E">
                          Mayor a S/ 20, 000
                        </MenuItem>
                      </Select>
                      {errores.v_declaracion_ingreso_mensual && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.v_declaracion_ingreso_mensual}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={fullScreen ? 12 : 6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#000000", fontWeight: 400 }}>
                    Datos de ubicación
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="Type-account">Departamento</InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                        }}
                        className={classes.selectStyles}
                        value={departamento}
                        onChange={handlerSelectInfo(setDepartamento)}
                        disableUnderline
                      >
                        {renderDepartamentos()}
                      </Select>
                      {errores.departamento && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.departamento}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl
                      disabled={departamento === "" ? true : false}
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel id="Type-account">Provincia</InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                          color: departamento === "" ? "#CDCED0" : "",
                        }}
                        className={classes.selectStyles}
                        value={provincia}
                        onChange={handlerSelectInfo(setProvincia)}
                        disableUnderline
                      >
                        {renderProvincias(departamento)}
                      </Select>
                      {errores.provincia && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.provincia}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl
                      disabled={provincia === "" ? true : false}
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel id="Type-account">Distrito</InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                          color: provincia === "" ? "#CDCED0" : "",
                        }}
                        className={classes.selectStyles}
                        value={distrito}
                        onChange={handlerSelectInfo(setDistrito)}
                        disableUnderline
                      >
                        {renderDistritos(departamento, provincia)}
                      </Select>
                      {errores.distrito && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.distrito}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl
                      disabled={distrito === "" ? true : false}
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel id="Type-account">Tipo de vía</InputLabel>

                      <Select
                        color="info"
                        sx={{
                          height: "55px",
                          borderRadius: "16px",
                          color: distrito === "" ? "#CDCED0" : "",
                        }}
                        className={classes.selectStyles}
                        value={ubicacion}
                        onChange={handlerSelectInfo(setUbicacion)}
                        disableUnderline
                      >
                        {renderUbicacion()}
                      </Select>
                      {errores.ubicacion && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.ubicacion}
                        </span>
                      )}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={Theme}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textFieldFilledStyles}
                        label="Dirección"
                        value={direccion}
                        onChange={handleInputDirection}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="filled"
                      />
                      {errores.direccion && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.direccion}
                        </span>
                      )}{" "}
                    </FormControl>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              className={classes.buttonsDownStyles}
              onClick={closeModal()}
            >
              Volver
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className={classes.buttonsDownStyles}
              onClick={handleSubmit}
              autoFocus
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeclaracionJurada;
