import {
  RETIRO_DU038_GET_EXITOSO,
  RETIRO_DU038_GET_ERROR,
  RETIRO_DU038_GET_LOADIN,
  RETIRO_DU038_CLEANER,
  RETIRO_DU038_POST_EXITOSO,
  RETIRO_DU038_POST_ERROR,
  RETIRO_DU038_POST_LOADIN,
  RETIRO_DU038_RESETEAR
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case RETIRO_DU038_GET_EXITOSO:
      // const tieneCuenta = action.payload.r_cuentas? action.payload.r_cuentas: [];
      const tieneMensaje = action.payload.r_message? action.payload.r_message: null;
      return {
        ...state,
        datosAlifiadoRetiro: action.payload,
        mensajeInformativo: tieneMensaje,
        // conTokenDigital: action.payload.r_token_digital,
        loadingPage: false,
      };
    case RETIRO_DU038_GET_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case RETIRO_DU038_GET_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case RETIRO_DU038_CLEANER:
      return {
        ...state,
        datosAlifiadoRetiro: null,
        mensajeInformativo: null,
        // conTokenDigital: null,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadosAfiliadosRetiro: null,
      };
 
    case RETIRO_DU038_RESETEAR:
      return {
        ...state,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadosAfiliadosRetiro: null,
      };
    case RETIRO_DU038_POST_EXITOSO:
      return {
        ...state,
        datosResultadosAfiliadosRetiro: action.payload,
        loadingBtn: false,
      };
    case RETIRO_DU038_POST_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case RETIRO_DU038_POST_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    default:
      break;
  }
};
