import React, { useEffect } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { useState } from "react";

const MenuSelect = ({
  fondos,
  opcionSeleccionada,
  setOpcionSeleccionada,
  setNombreFondo,
  windowWidth,
}) => {
  const [fondo, setFondo] = useState(0);
  const [localFondos, setLocalFondos] = useState(null);
  const handlerFondo = (e) => {
    setOpcionSeleccionada(parseInt(e.target.value));
    setFondo(e.target.value);
  };

  const handleClick = (item, indice) => () => {
    setOpcionSeleccionada(indice);
    setNombreFondo(item.r_descripcion);
  };

  useEffect(() => {
    // Veamos el SesionStorage de fondos;
    let fondosStorage = JSON.parse(sessionStorage.getItem("fondos"));

    // Agregamos la opcion del estado si debe mostrar o no las opciones de incremento de fondo

    let newFondos = fondos.map((item) => {
      let newItem = item;
      fondosStorage.forEach((element) => {
        if (
          item.r_codigo.toLowerCase().includes(element.r_cuenta.toLowerCase())
        ) {
          newItem = { ...item, estado: element.r_eecc };
        }
      });
      return newItem;
    });

    setLocalFondos(newFondos);
  }, []);

  if (!localFondos) return <p>...Cargando</p>;

  if (!localFondos.length) return <p>No hay información disponible.</p>;

  let filterFondos = localFondos.filter((item) => item.estado === "1");

  return (
    <>
      {windowWidth > 600 ? (
        <ul
          className={
            filterFondos.length === 3
              ? "list-steps-datos list-steps-inversiones"
              : filterFondos.length === 1
                ? "list-steps-datos list-steps-only"
                : "list-steps-datos"
          }
        >
          {localFondos.map((item, index) => (
            // item.estado === "1" &&
            <li
              key={index}
              onClick={handleClick(item, index)}
              className={
                opcionSeleccionada === index ? "active-selected-datos" : ""
              }
            >
              {item.r_descripcion === "APORTE OBLIGATORIO"
                ? "Fondo de Jubilación"
                : item.r_descripcion === "VOLUNTARIO SIN FIN"
                  ? "Fondo Libre Habitat"
                  : "Aportes Voluntarios con fin Previsional"}
            </li>
          ))}
        </ul>
      ) : (
        <select
          className="form-control mb-2"
          value={fondo}
          onChange={handlerFondo}
        >
          {localFondos.map(
            (item, index) =>
              item.estado === "1" && (
                <option key={index} value={index}>
                  {item.r_descripcion === "APORTE OBLIGATORIO"
                    ? "Fondo de Jubilación"
                    : item.r_descripcion === "r_vsf"
                      ? "Fondo Libre Habitat"
                      : "Aportes Voluntarios con fin Previsional"}
                </option>
              )
          )}
        </select>
      )}
    </>
  );
};

export default hocWidthWindow(MenuSelect);
