import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Cards from "../../components/CreditCard/Cards";
import { re } from "../../data/DataLocal";

import SelectCuentas from "../RetiroApv/SinCuenta/SelectCuentas";
import SelectGeneral from "../RetiroApv/SinCuenta/SelectGeneral";
import CustomModal from "./ModalMessageEnd";
import RetiroDU038Context from "../../context/retiroDU038/retiroDU038Context";
import ModalActualizarCelular from "../../components/ModalActualizarDatos";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import RetiroapvsinfinContext from "../../context/retiroapvsinfin/retiroapvsinfinContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import Loading from "../../components/Loading/index.jsx";
import InputMask from "../../components/InputMask/InputMask";
import "./index.css";
import { obtenerCtaRemesa } from "../../utils/formulaRemesa";
import moment from "moment";

const globalErrors = {
  fondo: "",
  cuentaCard: "",
  banco: "",
  tipoCuenta: "",
  anioYmes: "",
  nroCuenta: "",
  monto: "",
  image: "",
};

function RetiroDU038(props) {
  const [errores, setErrores] = useState(globalErrors);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [typeAccount, setTypeAccount] = useState(null);
  const [montoTotal, setMontoTotal] = useState(0);
  const [modalCelular, setModalCelular] = useState(null);
  const [cuentaDestino, setCuentsDestino] = useState(-1);
  const [banco, setBanco] = useState("");
  const [image, setImage] = useState("");
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");

  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [nroDeCuenta, setNroDeCuenta] = useState("");
  const [montoRetiro, setMontoRetiro] = useState("");

  const [filtroTipoCuenta, seFiltroTipoCuenta] = useState([]);
  const [longitudNroCuenta, setLongitudNroCuenta] = useState(0);
  const [mostrarFormularioCompleto, setMostrarFormularioCompleto] =
    useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const retiroapvsinfinContext = useContext(RetiroapvsinfinContext);
  const misDatosContext = useContext(MisDatosContext);
  const retiroDU038Context = useContext(RetiroDU038Context);

  const { datosResultadosAfiliadosRetiro } = retiroDU038Context;
  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;
  const {
    datosRetiroApvSinFin,
    mensajeAlerta,
    obtenerDatosRetiroApvSinFin,
    limpiarVariablesRetiroApvSinFinParcial,
  } = retiroapvsinfinContext;
  useEffect(() => {
    getFondoTotal();
    obtenerDatosRetiroApvSinFin();
    obtenerMisDatosGet();

    return () => {
      limpiarVariablesRetiroApvSinFinParcial();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (misDatosGet) {
      setCelular(misDatosGet.r_persona.r_datos.r_celular);
      setCorreo(misDatosGet.r_persona.r_datos.r_correo);
    }
  }, [misDatosGet]);
  useEffect(() => {
    if (datosResultadosAfiliadosRetiro) {
      if (datosResultadosAfiliadosRetiro.r_validacion === 1) {
        setModalSuccess(true);
      }
    }
  }, [datosResultadosAfiliadosRetiro]);
  const getFondoTotal = () => {
    let fondoTotal = JSON.parse(sessionStorage.getItem("total"));
    if (fondoTotal) setMontoTotal(fondoTotal.r_saldo);
    else props.history.push("/mi-habitat-digital/afiliado/inicio");
  };

  useEffect(() => {
    if (datosRetiroApvSinFin) {
      const mostrarCuentas = datosRetiroApvSinFin.r_cuentas ? true : false;
      setMostrarFormularioCompleto(!mostrarCuentas);
    }
  }, [datosRetiroApvSinFin]);

  const handlePreviewOpen = () => {
    setOpenModalPreview(true);
  };
  const opcionSeleccionadaCard = (opcion) => {
    if (opcion === "nueva") {
      setMostrarFormularioCompleto(true);
      setCuentsDestino("nueva");
    } else {
      setMostrarFormularioCompleto(false);
      setCuentsDestino(opcion);
    }
    // limpiamos campos
    setBanco("");
    setTipoDeCuenta("");
    setImage("");
    setNroDeCuenta("");
    seFiltroTipoCuenta([]);
    setLongitudNroCuenta(0);
    setErrores(globalErrors);
  };

  const handlerbanco = (opcion) => {
    seFiltroTipoCuenta([]);
    if (opcion !== "") {
      setBanco(opcion);
      let newListTipoCuenta = datosRetiroApvSinFin.r_tipo_cuentas.filter(
        (item) => item.r_banco === opcion.r_codigo
      );

      seFiltroTipoCuenta(newListTipoCuenta);
    } else {
      setBanco("");
    }
    setNroDeCuenta("");
    setTypeAccount(null);

    setErrores({ ...errores, banco: "" });
  };

  const handleCorreo = (e) => {
    setCorreo(e.target.value);
  };
  const handleCelular = (e) => {
    setCelular(e.target.value);
  };
  const handlerTipoCuenta = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);

      if (opcion.r_cci === "1") {
        setTypeAccount("isCci");
      } else {
        setTypeAccount(null);
      }
      setTipoDeCuenta(JSON.stringify(opcion));
    } else {
      opcion = "";
      setTipoDeCuenta("");
    }

    if (opcion.r_banco === "11") {
      setNroDeCuenta("018");
    } else if (opcion.r_banco === "7") {
      setNroDeCuenta("0011");
    } else {
      setNroDeCuenta("");
    }

    if (opcion !== "") {
      setLongitudNroCuenta(opcion.r_longitud);
    } else {
      setLongitudNroCuenta(0);
    }
    setErrores({ ...errores, tipoCuenta: "" });
  };

  const listTipoCuenta = () => {
    if (filtroTipoCuenta.length > 0) {
      return filtroTipoCuenta.map((item, index) => (
        <option key={index} value={JSON.stringify(item)}>
          {item.r_descripcion}
        </option>
      ));
    } else {
      return "";
    }
  };

  const handlerNroCuenta = (e) => {
    const valor = e.target.validity.valid ? e.target.value : nroDeCuenta;

    setNroDeCuenta(valor);

    setErrores({ ...errores, nroCuenta: "" });
  };

  const handlerMonto = (e) => {
    const value = e.target.value.replace("S/", "");
    if (Number(value.replace(/,/, "")) <= 2000) {
      setMontoRetiro(value);
      setErrores({ ...errores, monto: "" });
    } else {
      setMontoRetiro(value);
      setErrores({
        ...errores,
        monto: "*Monto excede el tope limite de 2,000",
      });
    }
  };

  const validarData = () => {
    let montoLocal;
    let mensajesError = {
      cuentaCard: "",
      banco: "",
      tipoCuenta: "",
      anioYmes: "",
      nroCuenta: "",
      monto: "",
      correo: "",
      celular: "",
    };

    if (montoRetiro.length > 0) {
      montoLocal = montoRetiro.replace("S/ ", "").replace(/,/g, "");
      if (parseFloat(montoLocal) === 0.0) {
        mensajesError = {
          ...mensajesError,
          monto: "El monto a retirar debe ser mayor a 0,",
        };
      } else {
        if (parseFloat(montoLocal) > 2000) {
          mensajesError = {
            ...mensajesError,
            monto: "El monto a retirar no puede ser mayor a los 2000.",
          };
        }
      }
    } else {
      mensajesError = {
        ...mensajesError,
        monto: "El monto a retirar es requerido.",
      };
    }

    if (cuentaDestino === -1) {
      mensajesError = { ...mensajesError, cuentaCard: "campo requerido" };
    }

    if (celular.length === 0 || celular.trim() === "") {
      mensajesError = { ...mensajesError, celular: "campo requerido" };
    } else {
      if (celular.length !== 9) {
        mensajesError = { ...mensajesError, celular: "campo de 9 dígitos" };
      }
    }

    // validamos Correo
    if (correo.length === 0 || correo.trim() === "") {
      mensajesError = { ...mensajesError, correo: "campo requerido" };
    } else {
      if (!re.test(String(correo))) {
        mensajesError = {
          ...mensajesError,
          correo: "formato de correo incorrecto",
        };
      } else {
        if (
          correo.includes("..") ||
          correo.includes(".@") ||
          correo.includes("--") ||
          correo.includes("__") ||
          correo.charAt(0) === "."
        ) {
          mensajesError = {
            ...mensajesError,
            correo: "formato de correo incorrecto",
          };
        } else {
          if (correo.length > 160) {
            mensajesError = {
              ...mensajesError,
              correo: "campo máximo de 160 caracteres",
            };
          }
        }
      }
    }
    if (mostrarFormularioCompleto) {
      if (banco.length === 0) {
        mensajesError = { ...mensajesError, banco: "campo requerido" };
      }

      if (tipoDeCuenta.length === 0) {
        mensajesError = { ...mensajesError, tipoCuenta: "campo requerido" };
      } else {
        let tipoCuentaObject = JSON.parse(tipoDeCuenta);
        if (nroDeCuenta.length === 0) {
          mensajesError = { ...mensajesError, nroCuenta: "campo requerido" };
        } else {
          if (nroDeCuenta.length < tipoCuentaObject.r_longitud) {
            mensajesError = {
              ...mensajesError,
              nroCuenta: `campo de ${tipoCuentaObject.r_longitud} digitos`,
            };
          }
        }
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }
    return respuesta;
  };

  const procesarSolicitud = () => {
    if (validarData()) {
      let montoARetirar = montoRetiro.replace("S/ ", "").replace(/,/g, "");
      let newCuenta = {};

      if (cuentaDestino === "nueva") {
        let cuenta = {
          r_nombre_banco: banco.r_codigo,
          r_tipo_cuenta: JSON.parse(tipoDeCuenta).r_codigo,
          r_numero_cuenta: nroDeCuenta,
          r_cuenta_remesa:
            Number(banco.r_codigo) === 7 ? obtenerCtaRemesa(nroDeCuenta) : "",
          r_codigo: "",
        };
        newCuenta = cuenta;
      } else {
        let cuentaSelect = {
          r_nombre_banco: "",
          r_tipo_cuenta: "",
          r_numero_cuenta: "",
          r_cuenta_remesa: "",
          r_codigo: cuentaDestino.r_codigo,
        };

        newCuenta = cuentaSelect;
      }

      const objRetiro = {
        v_fec_solicitud: moment().format("DD/MM/YYYY"),
        v_tipo_doc: props.client.r_datos[0].tipo_doc,
        v_num_doc: props.client.r_datos[0].num_doc,
        v_primer_apellido: misDatosGet.r_persona.r_datos.r_primer_apellido,
        v_segundo_apellido: misDatosGet.r_persona.r_datos.r_segundo_apellido,
        v_nombres:
          misDatosGet.r_persona.r_datos.r_primer_nombre +
          " " +
          misDatosGet.r_persona.r_datos.r_segundo_nombre,
        v_cuspp: props.client.r_client.cuspp,
        v_email: misDatosGet.r_persona.r_datos.r_correo,
        v_num_telefono: misDatosGet.r_persona.r_datos.r_celular,
        v_saldo: montoTotal,
        v_monto_solicitado: montoARetirar,
        v_cod_banco: newCuenta.r_nombre_banco,
        v_tip_cuenta: newCuenta.r_tipo_cuenta,
        v_numero_cuenta: newCuenta.r_numero_cuenta,
      };

      props.endProcess(objRetiro);
    } else {
      console.log("invalid");
    }
  };

  const Close = () => {
    props.history.push("/mi-habitat-digital/afiliado/inicio");

    setModalSuccess(false);
  };
  const closeModalCelular = () => {
    setModalCelular(null);
    props.history.push(
      "/mi-habitat-digital/afiliado/informacion/datos-personales"
    );
  };

  // const deleteBanks = datosRetiroApvSinFin === null?[]: datosRetiroApvSinFin.r_bancos.filter(item => {
  //     if(item.r_descripcion=== "INTERBANK") {
  //         return item
  //     }
  //     if(item.r_descripcion=== "SCOTIABANK") {
  //         return item
  //     }
  //     if(item.r_descripcion=== "BBVA") {
  //         return item
  //     }
  //     if(item.r_descripcion=== "BANBI") {
  //         return item
  //     }
  // })

  if (mensajeAlerta !== null) return <p></p>;
  if (datosRetiroApvSinFin === null || misDatosGet === null) return <Loading />;
  return (
    <div className="container retirodu038">
      <h1 className="title-page">Formulario de Retiro</h1>
      <Row>
        <Col md="8" className="form-retirodu038 mb-3">
          <div>
            <Card>
              <CardHeader className="custom-header-cambio-fondo custom-header-li">
                RetiroDU038
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_fecha">Fecha de solicitud</Label>
                      <Input
                        // type="date"
                        name="r_fecha"
                        id="r_fecha"
                        placeholder="date placeholder"
                        disabled={true}
                        value={moment().format("DD/MM/YYYY")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_numero_solicitud">
                        Numero de solicitud
                      </Label>
                      <Input
                        type="number"
                        name="r_numero_solicitud"
                        id="r_numero_solicitud"
                        value="0001"
                        placeholder="N.°"
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_apellido_paterno">
                        Apellido paterno
                      </Label>
                      <Input
                        type="text"
                        name="r_apellido_paterno"
                        id="r_apellido_paterno"
                        placeholder=""
                        value={
                          misDatosGet.r_persona === null
                            ? ""
                            : misDatosGet.r_persona.r_datos.r_primer_apellido
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_apellido_materno">
                        Apellido materno
                      </Label>
                      <Input
                        type="text"
                        name="r_apellido_materno"
                        id="r_apellido_materno"
                        placeholder=""
                        value={
                          misDatosGet.r_persona === null
                            ? ""
                            : misDatosGet.r_persona.r_datos.r_segundo_apellido
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_nombres">Nombres</Label>
                      <Input
                        type="text"
                        name="r_nombres"
                        id="r_nombres"
                        placeholder=""
                        value={
                          misDatosGet.r_persona === null
                            ? ""
                            : misDatosGet.r_persona.r_datos.r_primer_nombre +
                              " " +
                              misDatosGet.r_persona.r_datos.r_segundo_nombre
                        }
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_cuspp">CUSPP </Label>
                      <Input
                        type="text"
                        name="r_cuspp "
                        id="r_cuspp"
                        placeholder=""
                        disabled={true}
                        value={
                          props.client === null
                            ? ""
                            : props.client.r_client.cuspp
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_email">Email </Label>
                      <Input
                        type="email"
                        name="r_email "
                        id="r_email"
                        placeholder="email@email.com"
                        value={correo}
                        onChange={handleCorreo}
                        // disabled={true}
                      />
                    </FormGroup>
                    {errores.correo && (
                      <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                        {errores.correo}
                      </span>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_telefono">Celular</Label>
                      <Input
                        placeholder="Ingresar Celular"
                        name="r_telefono"
                        pattern="[0-9]*"
                        value={celular}
                        onChange={handleCelular}
                        // disabled={true}
                      />
                      {errores.celular && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.celular}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="r_saldo">Saldo soles</Label>
                      <InputMask
                        name="r_saldo"
                        id="r_saldo"
                        placeholder="S/ 0.00"
                        type="text"
                        className="custom-mask"
                        value={Number(montoTotal).toFixed(2)}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="nombre">Monto a cargar</Label>
                      <InputMask
                        name="r_monto"
                        id="r_monto"
                        placeholder="S/ 0.00"
                        type="text"
                        className="custom-mask"
                        value={montoRetiro}
                        onChange={handlerMonto}
                      />
                      {errores.monto && (
                        <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                          {errores.monto}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <>
                      <FormGroup className="mb-0">
                        <Label htmlFor="saldo">
                          Selecciona tu cuenta favorita :{" "}
                        </Label>
                      </FormGroup>
                      {props.windowWidth > 600 ? (
                        <>
                          <Cards
                            cuentas={[]}
                            preview={handlePreviewOpen}
                            setCuenta={opcionSeleccionadaCard}
                          />
                          {errores.cuentaCard && (
                            <span
                              style={{ fontSize: "14px", color: "#bf0d3e" }}
                            >
                              {errores.cuentaCard}
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <SelectCuentas
                            cuentas={[]}
                            onClick={handlePreviewOpen}
                            setCuenta={opcionSeleccionadaCard}
                          />
                          {errores.cuentaCard && (
                            <span
                              style={{ fontSize: "14px", color: "#bf0d3e" }}
                            >
                              {errores.cuentaCard}
                            </span>
                          )}
                        </>
                      )}
                    </>
                  </Col>
                </Row>
                <Row form>
                  {mostrarFormularioCompleto && (
                    <>
                      <Col md={4} className="mb-3">
                        <SelectGeneral
                          info={{
                            labelText: "Escoge tu banco",
                            placeHolder: "- Selecciona tu banco -",
                            opciones: datosRetiroApvSinFin.r_bancos.filter(
                              (item) => {
                                if (item.r_descripcion === "INTERBANK") {
                                  return item;
                                }
                                if (item.r_descripcion === "SCOTIABANK") {
                                  return item;
                                }
                              }
                            ),
                          }}
                          defaultSelect={"1"}
                          selectOption={handlerbanco}
                        />
                        {errores.banco && (
                          <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                            {errores.banco}
                          </span>
                        )}
                      </Col>
                      <Col md={4} className="mb-3">
                        <Label htmlFor="nombre">Tipo de cuenta</Label>
                        <select
                          className="form-control mr-1"
                          value={tipoDeCuenta}
                          onChange={handlerTipoCuenta}
                        >
                          <option value="">
                            - Selecciona tu tipo de cuenta -
                          </option>
                          {listTipoCuenta()}
                        </select>
                        {errores.tipoCuenta && (
                          <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                            {errores.tipoCuenta}
                          </span>
                        )}
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          {typeAccount === "isCci" ? (
                            <Label htmlFor="nombre">N.° de CCI * </Label>
                          ) : (
                            <Label htmlFor="nombre">N.° de cuenta</Label>
                          )}
                          <Input
                            placeholder={
                              typeAccount === "isCci"
                                ? "Número de cci"
                                : "Número de cuenta"
                            }
                            onChange={handlerNroCuenta}
                            pattern="[0-9]*"
                            disabled={longitudNroCuenta === 0 ? true : false}
                            maxLength={longitudNroCuenta}
                            value={nroDeCuenta}
                          />
                          {errores.nroCuenta && (
                            <span
                              style={{ fontSize: "14px", color: "#bf0d3e" }}
                            >
                              {errores.nroCuenta}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        {typeAccount === "isCci" ? (
                          <p className="text_cci">
                            * El codigo de cuenta interbancario (CCI) tiene 20
                            digitos y es diferente a tu número de cuenta. Puedes
                            consultar con tu banco cuál es tu número CCI para
                            realizar tu pago sin inconvenientes.
                          </p>
                        ) : null}
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
            <section className="section-buttons d-flex justify-content-around">
              <button className="btn-white" onClick={() => procesarSolicitud()}>
                Retirar
              </button>
            </section>
          </div>
        </Col>
      </Row>
      {modalCelular && (
        <ModalActualizarCelular closeModal={closeModalCelular} />
      )}
      {/* {openModalPreview && (
                <ModalPreviewNew
                    closeModal={handlePreviewClose} // fn para cerrar modal
                // guardarCode={enviarDataAlPadre}
                />
            )} */}

      <CustomModal
        showModal={modalSuccess}
        className="custom-modal"
        backdrop={"static"}
        fnCloseIconBtn={Close}
        title="Solicitud exitosa"
        description={<p>Se registró con éxito su solicitud.</p>}
        hasBtnPrimary={true}
        fnBtnPrimary={Close}
        textBtnPrimary="Aceptar"
      />
    </div>
  );
}

export default hocWidthWindow(RetiroDU038);
