function getDeepValue(sourceObject, dotNotationPath) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    returnData = returnData?.[subPath];
  });

  return returnData;
}

export default getDeepValue;
