
import React from "react";
import { Grid } from "@mui/material";

export const CodigoInvalido = (props) => {
    const {changeStepOne,changePrevEnvio} = props

    const change = () =>{
        changeStepOne()
    }

    const changePrev = () =>{
        changePrevEnvio()
    }

    return (

        <div >
            <h5 className="margin-center text-center">
                <b> Alerta</b>
            </h5>

            <p className="margin-center text-center">El código no es válido, vuelve a intentarlo.</p>

            <Grid container spacing={1}> 
                <Grid item xs={6}>
                    <button
                    onClick={change}
                        className={`mt-3 mb-2 btn-secundary margin-center btn-modal-total-2`}
                    >
                        Cerrar{" "}
                    </button>
                </Grid>
                <Grid xs={6}>
                    <button
                    onClick = {changePrev}
                        className={`mt-4  btn-recover margin-center btn-modal-total-2`}
                    >
                        Volver{" "}
                    </button>
                </Grid>
            </Grid>
        </div>

    );
};

export const CodigoInvalidoD = (props) => {
    const {changeStepOne,changePrevEnvio} = props

    const change = () =>{
        changeStepOne()
    }

    const changePrev = () =>{
        changePrevEnvio()
    }

    return (
        <div className="">
            <h5 className="my-4 margin-center text-center">
                <b> Alerta</b>
            </h5>

            <p>El código no es válido, vuelve a intentarlo.</p>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <button
                    onClick={change}
                        className={`mt-2 btn-secundary margin-center btn-modal-total`}
                    >
                        Cerrar{" "}
                    </button>
                </Grid>

                <Grid item xs={6}>
                    <button
                    onClick = {changePrev}
                        className={`mt-2  btn-recover margin-center btn-modal-total`}
                    >
                        Volver{" "}
                    </button>
                </Grid>
            </Grid>

        </div>
    );
};



