import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../../../../../components";
import JubilacionContext from "../../../../../../../../../../context/jubilacion/jubilacionContext";
import JubPmoContext from "../../../../../../../../../../context/jubilacion/pmo/jubPmoContext";
import { Box } from "@mui/material";
import { ModalBeneficiarios } from "../../../../../../component/Modals";
import { Table } from "../../../../../../component";
import { ContentText, ContentTable } from "../../../../../../styles/Decision";
import { reestructureArrayBeneficiarios } from "../../../../../../../../../../utils/Functions";
import {
  PMOBeneficiariesProvider,
  useBeneficiariesContext,
} from "../../../../../../../jubilacionLegal/component/Table/beneficiariesContext";
import { useFormContext } from "react-hook-form";

export default function Index(props) {
  const { prevView, nextView, beneficiarios } = props;
  const [isOpenModalBeneficiarios, setIsOpenModalBeneficiarios] =
    useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarAvanceJuilacionLoading,
    limpiarGuardarAvanceTramiteJubilacion,
  } = jubilacionContext;

  useEffect(() => {
    return () => {
      // limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeIconForLoading = () => {
    if (guardarAvanceJuilacionLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const data = reestructureArrayBeneficiarios(beneficiarios);
  const handleContinue = () => {};
  return (
    <PMOBeneficiariesProvider>
      <div className="detalle-info-container residencia">
        <h1 className="mb-3">Ingreso de documentos 2/2 </h1>
        <ContentText>
          Necesitamos que nos proporciones los siguientes documentos para
          continuar con tu solicitud:
        </ContentText>

        <Box>
          <ContentTable>
            <h2>Documentos de beneficiarios</h2>
            <Table
              setIsOpenModalBeneficiarios={setIsOpenModalBeneficiarios}
              beneficiarios={beneficiarios}
            />
          </ContentTable>
        </Box>

        <NavigationButtons
          prevView={prevView}
          nextView={nextView}
          changeIconForLoading={changeIconForLoading}
          guardarAvanceJuilacionLoading={guardarAvanceJuilacionLoading}
        />

        <ModalBeneficiarios
          isOpen={isOpenModalBeneficiarios}
          setIsOpen={setIsOpenModalBeneficiarios}
        />
      </div>
    </PMOBeneficiariesProvider>
  );
}

const NavigationButtons = ({
  prevView,
  nextView,
  changeIconForLoading,
  guardarAvanceJuilacionLoading,
}) => {
  const { state } = useBeneficiariesContext();
  const jubilacionPmoContext = useContext(JubPmoContext);
  const { watch, getValues } = useFormContext();
  const {
    decisionPmoDocumento,
    resetearDocumentPmo,
    dataDocumentoPmo,
    loadingDocumentoPmo,
  } = jubilacionPmoContext;

  const canContinue = () => {
    if (state.data.length === 0) return false;
    return (
      state.data.every((e) => e.documentState === "success") &&
      !loadingDocumentoPmo
    );
  };

  const onSendAffiliateDocument = async (file) => {
    const formData = new FormData();
    formData.append("id", "0");
    formData.append("documento", file);

    const decisionResult = await decisionPmoDocumento(formData);
    if (decisionResult) return nextView();
  };

  return (
    <div className="container-reja-buttons">
      <Button
        name="secondary"
        bgColor="white"
        className="mb-4 btn-modal-secondary"
        onClick={() => prevView()}
        disabled={guardarAvanceJuilacionLoading}
      >
        Volver
      </Button>
      <Button
        className="mb-4 btn-modal-primary"
        onClick={() => onSendAffiliateDocument(watch("documentos.id")?.[0])}
        disabled={!canContinue()}
      >
        {!loadingDocumentoPmo ? (
          <>Continuar {changeIconForLoading()}</>
        ) : (
          "Cargando"
        )}
      </Button>
    </div>
  );
};
