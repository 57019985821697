export const CONSECUTIVOS =[
    '1234',
    '2345',
    '3456',
    '4567',
    '5678',
    '6789',
    '0123',
    '9876',
    '8765',
    '7654',
    '6543',
    '5432',
    '4321',
    '3210'
]