const maskBankAccount = (account) => {
  const bankAccount = String(account);
  const length = bankAccount.length;
  const numbersToShow = 5;
  const masked =
    Array(length - 5).join("*") + bankAccount.slice(-numbersToShow);
  return masked;
};

export default maskBankAccount;
