import React from "react";
import styled from "styled-components";

const LinkComponent = styled.a`
  ${(props) => props.theme.tipography.button};
  min-width: 20px;
  color: ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette.primary.main};
  text-transform: inherit;
  border-radius: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(1, 3)};
  font-style: initial;
  display: inline-block;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.white};
  }
  &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
    background-color: ${(props) => props.theme.palette.blocked.disabled};
    color: ${(props) => props.theme.palette.grayscale.blocked};
    border: none;
  }
`;
const Index = (props) => {
  const { to, children, icon, ...rest } = props;
  return (
    <LinkComponent href={to} {...rest}>
      {children}
    </LinkComponent>
  );
};

export default Index;
Index.defaultProps = {
  name: "primary",
  bgColor: "",
  disabled: false,
};
