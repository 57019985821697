import React, { useEffect, useRef, useState } from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  DocumentInputComponent,
  InputText,
  InputWrapper,
  SelectComponent,
} from "./styles";
import { string, oneOf, node, bool, any, object, oneOfType } from "prop-types";
import { shape } from "prop-types";
import { useFormContext } from "react-hook-form";
import getDeepValue from "../../../utils/getDeepValue";
import { AiOutlineConsoleSql } from "react-icons/ai";

const DocumentInput = ({ documentProps, valueProps }) => {
  const {
    trigger,
    getValues,
    formState: { errors, touchedFields },
  } = useFormContext();

  // If at least one follwing errors is truthy return isError true

  const isError = [
    documentProps.error,
    valueProps.error,
    getDeepValue(errors, documentProps.name),
    getDeepValue(errors, valueProps.name),
  ].some((e) => e);

  useEffect(() => {
    if (touchedFields?.[documentProps.name]) {
      trigger(documentProps.name);
    }

    if (touchedFields?.[valueProps.name]) {
      trigger(valueProps.name);
    }
  }, [isError]);

  return (
    <DocumentInputComponent className={isError ? "error" : ""} error={isError}>
      <DocumentSelect {...documentProps} />
      <ValueInput documentTypeName={documentProps?.name} {...valueProps} />
    </DocumentInputComponent>
  );
};

export default DocumentInput;

const DocumentSelect = ({
  placeholder,
  disabled,
  name,
  required,
  onChange,
  validate,
  error,
  ...props
}) => {
  const {
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const documentOptions = [
    { label: "DNI", value: "00" },
    { label: "CE", value: "01" },
    { label: "Lib. Adol. Trab.", value: "03" },
    { label: "Pasaporte", value: "04" },
  ];

  const defaultOption = documentOptions[0];
  register(name, { required, ...validate });

  const currentFormValues = useRef(null);
  currentFormValues.current = getValues();

  useEffect(() => {
    if (!getValues(name)) {
      setValue(name, defaultOption.value);
    }
  }, []);

  const isError = error || getDeepValue(errors, name);

  const onChangeHandler = (items) => {
    setValue(name, items.value);
  };

  const value = documentOptions.find((e) => e.value === watch(name));

  return (
    <SelectComponent
      classNamePrefix="select"
      options={documentOptions}
      isDisabled={disabled}
      value={value}
      defaultValue={defaultOption}
      isSearchable={false}
      error={isError}
      // menuIsOpen={true}
      onChange={onChange || onChangeHandler}
      {...props}
    />
  );
};

const ValueInput = ({
  documentTypeName,
  placeholder,
  disabled,
  name,
  required,
  onChange,
  validate,
  error,
  ...props
}) => {
  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: false,
    allowNegative: false,
    allowLeadingZeroes: true,
  };

  const {
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext();

  const isError = error || getDeepValue(errors, name);

  const handleOnChange = (e) => {
    setValue(name, e.target.value);
    trigger(name);
  };

  const propsByDocument = getPropsByDocumentType({
    documentType: watch(documentTypeName),
    placeholder,
  });

  const currencyMask = createNumberMask(defaultMaskOptions);

  const minLength = propsByDocument.minLength;
  const maxLength = propsByDocument.maxLength;

  const currenValue = watch(name);

  useEffect(() => {
    if (getDeepValue(touchedFields, name)) {
      trigger(name);
    }
  }, [watch(documentTypeName)]);

  return (
    <InputWrapper>
      <InputText
        type={`text`}
        error={isError}
        key={`document-value`}
        placeholder={propsByDocument.placeholder}
        mask={propsByDocument.isJustNumber && currencyMask}
        disabled={disabled}
        value={currenValue}
        maxLength={propsByDocument.maxLength}
        formNoValidate={false}
        {...register(name, {
          required,
          minLength: {
            value: minLength,
            message: ["00", "01", "03"].includes(watch(documentTypeName))
              ? `El número de ${propsByDocument.label} debe tener ${minLength} dígitos.`
              : watch(documentTypeName) === "04" &&
                `El número de ${propsByDocument.label} no puede tener menos que ${minLength} caracteres.`,
          },
          maxLength: {
            value: maxLength,
            message: `No puede tener más que ${maxLength} caracteres.`,
          },
          validate: {
            ...validate,
          },
        })}
        onChange={handleOnChange}
        {...props}
      />
    </InputWrapper>
  );
};

const getPropsByDocumentType = (props) => {
  const { defaultPlaceholder, documentType = "00" } = props;

  const options = {
    "00": {
      label: "DNI",
      placeholder: "12345678",
      maxLength: 8,
      minLength: 8,
      isJustNumber: true,
    },
    "01": {
      label: "CE",
      placeholder: "123456789",
      maxLength: 9,
      minLength: 9,
      isJustNumber: true,
    },
    "03": {
      label: "Lib. Adol. Trab.",
      placeholder: "0123456789",
      maxLength: 12,
      minLength: 12,
      isJustNumber: true,
    },
    "04": {
      label: "Pasaporte",
      placeholder: "AA12345678",
      maxLength: 12,
      minLength: 8,
      isJustNumber: false,
    },
  };

  const optionSelected = options[documentType];
  const defaultOption = options["00"];

  if (defaultPlaceholder) {
    Object.assign(optionSelected, { placeholder: defaultPlaceholder });
  }

  return optionSelected || defaultOption;
};

const documentSelectType = {
  name: string,
  disabled: bool,
  error: bool,
  required: oneOfType([bool, object]),
  placeholder: string,
  validate: object,
};

const documentSelectDefaultProps = {
  name: "document-select",
  required: {
    value: true,
    message: "El documento es requerido.",
  },
};

const valueInputType = {
  name: string,
  disabled: bool,
  error: bool,
  required: oneOfType([bool, object]),
  placeholder: string,
  validate: object,
};

const valueInputDefaultProps = {
  name: "document-value",
  required: {
    value: true,
    message: "El valor del documento es requerido.",
  },
};

DocumentInput.propTypes = {
  size: oneOf(["sm", "md", "lg"]),
  documentProps: shape({ ...documentSelectType }),
  valueProps: shape({ ...valueInputType }),
};

DocumentInput.defaultProps = {
  documentProps: { ...documentSelectDefaultProps },
  valueProps: { ...valueInputDefaultProps },
};

DocumentSelect.propTypes = { ...documentSelectType };

DocumentSelect.defaultProps = { ...documentSelectDefaultProps };

ValueInput.propTypes = { ...valueInputType };

ValueInput.defaultProps = { ...valueInputDefaultProps };

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
