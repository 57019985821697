import {
  EC_NO_APORTANTE_EXITOSO,
  EC_NO_APORTANTE_ERROR,
  EC_NO_APORTANTE_LOADIN,
  EC_NO_APORTANTE_CLEANER,
  EC_NO_APORTANTE_CLEANER_PARCIAL,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case EC_NO_APORTANTE_EXITOSO:
      return {
        ...state,
        resultado: action.payload.result,
        datos: action.payload.datos,
        deuda: action.payload.deuda,
        mensajeAlerta: null,
        loading: false,
      };
    case EC_NO_APORTANTE_ERROR:
      return {
        ...state,
        resultado: null,
        datos: null,
        deuda: null,
        mensajeAlerta: null,
        loading: false,
      };
    case EC_NO_APORTANTE_LOADIN:
      return {
        ...state,
        loading: true,
      };
    case EC_NO_APORTANTE_CLEANER:
      return {
        ...state,
        resultado: null,
        datos: null,
        deuda: null,
        mensajeAlerta: null,
        loading: false,
      };
    case EC_NO_APORTANTE_CLEANER_PARCIAL:
      return {
        ...state,
        resultado: null,
        datos: null,
        deuda: null,
        // mensajeAlerta: null,
        loading: false,
      };
    default:
      break;
  }
};
