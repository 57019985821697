import React from "react";
import { useHistory } from "react-router-dom";
import redCloseIcon from "../../../../assets/red-close.svg";
import blackArrowRight from "../../../../assets/icons/black-arrow-right.svg";
import "./index.css";

export default function BottomDrawer(props) {
  const {
    draweOptions,
    isDraweOpen,
    setIsDrawerOpen,
    location,
    setOptionSelected,
  } = props;

  const onCloseDrawerIcon = () => {
    setIsDrawerOpen(false);
    setOptionSelected(location);
  };

  const onClickSubOption = (url) => {
    setIsDrawerOpen(false);
  };

  const history = useHistory();
  // if (!isDraweOpen) return null;

  return (
    <div
      className={`bottom-drawer-container ${isDraweOpen ? "open-drawer" : ""}`}
    >
      <img
        className="img-close"
        src={redCloseIcon}
        alt="red-close"
        onClick={onCloseDrawerIcon}
      />
      <div className="bottom-drawer-container__scroll">
        {draweOptions.map((option) => (
          <div
            key={`subMenu-${option.title}`}
            className="bottom-drawer-container__card"
            onClick={() => {
              onClickSubOption(option.url);
              history.push(option.url);
            }}
          >
            <div className="bottom-drawer-container__card-info">
              <h4>{option.title}</h4>
              <span>{option.subTitle}</span>
            </div>
            <div className="bottom-drawer-container__card-img">
              <img src={option.icon} alt="" />
            </div>
            {option.title !== "Cerrar sesión" && (
              <div className="bottom-drawer-container__card-arrow">
                <img src={blackArrowRight} alt="black-arrow-right" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
