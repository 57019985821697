import React, { useReducer } from "react";
import AsesorContext from './asesorContext';
import AsesorReducer from './asesorReducer';
import clienteAxios from "../../config/axios";
import {
    ASESOR_GET_ERROR,
    GET_ASESOR_EXITOSO,
    SET_CLICK_ASESOR
} from "../../types/asesor";

const AsesorState = (props) => {
    const initialState = {
        encontroAsesor: false,
        clickAsesor: false,
        ejecutivo: []
    };
    const handleErrorsLocal = (error, origin_error) => {
        if (error.message === "Network Error") {
            dispatch({
                type: origin_error,
                payload: {
                    code: 1, // error de red
                    msg: "Su red presenta lentitud, por favor recargue la página.",
                },
            });
        } else {
            if (error.response?.status === 500) {
                dispatch({
                    type: origin_error,
                    payload: {
                        code: 1, // su token a expirado
                        msg: "Error en los servicios, por favor recargue la página.",
                    },
                });
            } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
                dispatch({
                    type: origin_error,
                    payload: {
                        code: 2, // su token a expirado
                        msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
                    },
                });
            } else {
                console.log("entramos al default errors");
            }
        }
    };
    const obtenerAsesor = async () => {

        try {
            const respuesta = await clienteAxios.get("/asesor-comercial");
            // dispatch
            dispatch({
                type: GET_ASESOR_EXITOSO,
                payload: respuesta.data,
            });
        } catch (error) {

            handleErrorsLocal(error, ASESOR_GET_ERROR);
        }
    };
    const setClickAsesor = (e) => {
        dispatch({
            type: SET_CLICK_ASESOR,
        });
    }
    const [state, dispatch] = useReducer(AsesorReducer, initialState);

    return (
        <AsesorContext.Provider
            value={{
                encontroAsesor: state.encontroAsesor,
                clickAsesor: state.clickAsesor,
                ejecutivo: state.ejecutivo,
                obtenerAsesor,
                setClickAsesor,
            }}
        >
            {props.children}
        </AsesorContext.Provider>
    );
}
export default AsesorState;