import React from "react";
import "./index.css";
import iconFinger from "../../../../../assets/icons/icon-finger.png";

export default function Tutorial({ show, setShow }) {
  return (
    <div>
      {show && (
        <div className="tutorial-container">
          <div className="tutorial-boxes">
            <div className="tutorial-box md"></div>
            <div className="tutorial-box md"></div>
            {/* <div className="tutorial-box sm"></div> */}
          </div>
          <div className="tutorial-description">
            <div className="tutorial-text">
              Desliza para conocer más de tus productos.
            </div>
            <img src={iconFinger} alt="icon-finger" />
          </div>
          <button className="btm-tuto" onClick={() => setShow(!show)}>
            Entendido
          </button>
        </div>
      )}
    </div>
  );
}
