import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <mask
        id="mask0_1805_12330"
        style={{ maskType: "alpha" }}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="currentColor" d="M0 0H32V32H0z"></path>
      </mask>
      <g mask="url(#mask0_1805_12330)">
        <path
          fill="currenColor"
          d="M18.233 17.766c-1.111 0-2.056-.388-2.833-1.166-.778-.778-1.167-1.722-1.167-2.834 0-1.11.39-2.055 1.167-2.832.777-.779 1.722-1.168 2.833-1.168 1.111 0 2.056.39 2.833 1.168.778.777 1.168 1.721 1.168 2.832 0 1.112-.39 2.056-1.168 2.834-.777.777-1.722 1.166-2.832 1.166zM8 22.234c-.622 0-1.15-.217-1.583-.65A2.153 2.153 0 015.767 20V7.566c0-.622.216-1.15.65-1.583.433-.433.96-.65 1.583-.65h20.433c.622 0 1.15.217 1.584.65.433.434.65.961.65 1.583V20c0 .622-.217 1.15-.65 1.583-.434.434-.962.65-1.584.65H8zM11.1 20h14.234c0-.867.3-1.6.9-2.2.6-.6 1.333-.9 2.2-.9v-6.234c-.867 0-1.6-.3-2.2-.9-.6-.6-.9-1.333-.9-2.2H11.1c0 .867-.3 1.6-.9 2.2-.6.6-1.333.9-2.2.9V16.9c.867 0 1.6.3 2.2.9.6.6.9 1.333.9 2.2zm15.566 6.666h-23.1c-.622 0-1.15-.216-1.583-.65a2.156 2.156 0 01-.65-1.582v-15.1h2.233v15.1h23.1v2.232z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
