import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../../../utils/device";

export const CardContent = styled(Box)`
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.palette.grayscale[200]};
  padding: 16px;
  box-shadow: 0px 4px 13px rgba(27, 29, 39, 0.12);
  width: 100%;
  background-color: ${(p) => p.theme.palette.white};
  font-size: ${(p) => p.theme.tipography.body2};
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
  ${(p) =>
    p.border === "primary" &&
    css`
      border-color: ${(p) => p.theme.palette.primary[400]};
    `}

  ${css`
    && {
      @media screen and ${device.tablet} {
        padding: 16px;
      }
    }
  `}
`;
