import React, { useEffect, useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import Divider from "../../../../components/Divider";
import * as Input from "../../../../components/Input";
import { montoFormat } from "../../../../helper/amountFormat";
import DatosEntidadFinanciera from "./DatosEntidadFinanciera";

import {
  DatosDeRetiroComponent,
  DatosDeRetiroWrapper,
  SeleccionFondoSection,
  Message,
} from "./styles";

const DatosDeRetiro = ({ montoTotal }) => {
  const {
    formState: { dirtyFields, isDirty, touchedFields },
    setValue,
    trigger,
    getValues,
    handleSubmit,
    watch,
  } = useFormContext();

  const [montoARetirar, setMontoARetirar] = useState(null);

  const onChangeMontoARetirar = (e) => {
    const value = e.target.value;
    const numberFormatted = value.replace("S/ ", "").replaceAll(",", "");
    setMontoARetirar(numberFormatted);
    setValue("montoARetirar", numberFormatted);
    trigger("montoARetirar");
  };

  const onClickTotal = () => {
    setValue("montoARetirar", montoTotal);
    setMontoARetirar(montoFormat(montoTotal, true));

    if (touchedFields["montoARetirar"]) {
      trigger("montoARetirar");
    }
  };

  const onClickParcial = () => {
    if (getValues("tipoDeRetiro") === "T") {
      setValue("montoARetirar", "");
      setMontoARetirar("");
    }

    if (touchedFields["montoARetirar"]) {
      trigger("montoARetirar");
    }
  };

  useEffect(() => {
    if (touchedFields["montoARetirar"]) {
      trigger("montoARetirar");
    }
  }, [watch("tipoDeRetiro")]);

  const isCloseToTotal = (e, total) => {
    const percentage = 0.98;
    const limit = parseFloat(total) * percentage;

    if (watch("tipoDeRetiro") === "T" || isNaN(e) || e <= limit) {
      return true;
    }

    return "El retiro parcial no puede tan cercano ser igual o mayor al total disponible.";
  };

  return (
    <DatosDeRetiroComponent onSubmit={handleSubmit((e) => console.log(e))}>
      <DatosDeRetiroWrapper>
        <SeleccionFondoSection>
          <Input.Title>Selecciona tu forma de retiro</Input.Title>
          <Input.RadioCard name="tipoDeRetiro" value="T" onClick={onClickTotal}>
            Total
          </Input.RadioCard>
          <Input.RadioCard
            name="tipoDeRetiro"
            value="P"
            onClick={onClickParcial}
          >
            Parcial
          </Input.RadioCard>
          <Input.ErrorMessage name="tipoDeRetiro" />
        </SeleccionFondoSection>
        <Input.Wrapper>
          <Input.Title>Monto a retirar</Input.Title>
          <Input.Money
            name="montoARetirar"
            size={"lg"}
            value={montoARetirar}
            disabled={watch("tipoDeRetiro") === "T"}
            onChange={onChangeMontoARetirar}
            validate={{
              greaterThanTotal: (e) => isCloseToTotal(e, montoTotal),
            }}
          />
          <Input.ErrorMessage name="montoARetirar" />
        </Input.Wrapper>
      </DatosDeRetiroWrapper>
      <Message>
        * El monto que visualiza es referencial ya que puede variar por efecto
        del valor cuota.
      </Message>
      <Divider marginTop={"0px"} marginBottom={"24px"} />
      <DatosEntidadFinanciera />
    </DatosDeRetiroComponent>
  );
};

export default DatosDeRetiro;
