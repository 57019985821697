import React, { useState, useEffect, useContext } from "react";
import ConocePerfil from "./ConocePerfil/index.jsx";
import Induccion from "./Induccion/index.jsx";
import Resultado from "./Resultados/Fondo1.jsx";
import NIVEL_RIESGO_F1 from "./../../../../assets/IconBucket/NIVEL_RIESGO_F1.svg";
import RENTABILIDAD_F1 from "./../../../../assets/IconBucket/RENTABILIDAD_F1.svg";
import NIVEL_RIESGO_F2 from "./../../../../assets/IconBucket/NIVEL_RIESGO_F2.svg";
import RENTABILIDAD_F2 from "./../../../../assets/IconBucket/RENTABILIDAD_F2.svg";
import NIVEL_RIESGO_F3 from "./../../../../assets/IconBucket/NIVEL_RIESGO_F3.svg";
import RENTABILIDAD_F3 from "./../../../../assets/IconBucket/RENTABILIDAD_F3.svg";
import HomeContext from "./../../../../context/home/homeContext";

import "./index.css";
const InfoCard = [
  {
    id: 1,
    title: "Fondo 1",
    subTitle: " Conservador",
    rentabilidad: "Baja",
    riesgo: "Baja",
    riesgoImg: NIVEL_RIESGO_F1,
    rentabilidadImg: RENTABILIDAD_F1,
    name: `Fondo 1`,
  },
  {
    id: 2,
    title: "Fondo 2",
    subTitle: " Moderado",
    rentabilidad: "Moderada",
    riesgo: "Moderada",
    riesgoImg: NIVEL_RIESGO_F2,
    rentabilidadImg: RENTABILIDAD_F2,
    name: `Fondo 2`,
  },

  {
    id: 3,
    title: "Fondo 3",
    subTitle: " Arriesgado",
    rentabilidad: "Alta",
    riesgo: "Alta",
    riesgoImg: NIVEL_RIESGO_F3,
    rentabilidadImg: RENTABILIDAD_F3,
    name: `Fondo 3`,
  },
];
const OnBoarding = (props) => {
  const { setShowOnboard, showOnboard } = props;
  const [index, SetStep] = useState(0);
  const [fondo, SetResultFondo] = useState(1);
  const homeContext = useContext(HomeContext);

  const { graphycs, obtenerDatosGraphyc } = homeContext;
  useEffect(() => {
    obtenerDatosGraphyc();
  }, []);
  if (graphycs.length === 0) return <p></p>;
  return (
    <>
      {index === 0 ? (
        <ConocePerfil
          SetStep={SetStep}
          setShowOnboard={setShowOnboard}
          showOnboard={showOnboard}
        />
      ) : index === 1 ? (
        <Induccion
          SetResultFondo={SetResultFondo}
          SetStep={SetStep}
          setShowOnboard={setShowOnboard}
          showOnboard={showOnboard}
        />
      ) : (
        <Resultado
          fondo={fondo}
          info={InfoCard}
          SetStep={SetStep}
          grafico={graphycs.grafico4}
          cartera={graphycs.grafico6}
          setShowOnboard={setShowOnboard}
          showOnboard={showOnboard}
        />
      )}
    </>
  );
};

export default OnBoarding;
