import React from "react";
import {
  BotonesDeNavegacionComponente,
  BotonSiguiente,
  BotonVolver,
} from "./styles";

const BotonesDeNavegacion = ({ onPrevious, onNext, step }) => {
  return (
    <BotonesDeNavegacionComponente step={step}>
      {step !== 2 && (
        <BotonVolver type="button" fluid onClick={onPrevious}>
          Volver
        </BotonVolver>
      )}
      <BotonSiguiente type="button" fluid onClick={onNext}>
        {step !== 2 ? "Siguiente" : "Volver al inicio"}
      </BotonSiguiente>
    </BotonesDeNavegacionComponente>
  );
};

export default BotonesDeNavegacion;
