import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MessageSnackbar({ mensaje, active = true, type = "success" }) {
  const [open, setOpen] = React.useState(active);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{
            background: type == "success" ? "#E6F2ED" : "#ffffff",
            border:
              type == "success" ? "1px solid #E6F2ED" : "1px solid #E11900",
            color: type == "success" ? "#1B7935" : "#E11900",
            width: "100%",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            borderRadius: "16px",
            boxShadow: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingRight: "40px",
            }}
          >
            {mensaje}
          </div>
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default MessageSnackbar;
