import styled from "styled-components";
import "react-phone-input-2/lib/high-res.css";

export const CellphoneInputComponent = styled.div`
  width: 100%;
  padding: 14px 16px 14px 0;
  border: 1px solid ${(p) => p.theme.palette.grayscale[50]};
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  border-radius: 16px;

  :focus-within {
    border-color: ${(p) => p.theme.palette.grayscale[500]};
  }

  &.error {
    border-color: ${(p) => p.theme.palette.state.error};

    input[type="tel"] {
      color: ${(p) => p.theme.palette.state.error};
    }
  }

  input[type="tel"] {
    width: 100%;
  }

  .react-tel-input {
    border-color: ${(p) => p.theme.palette.state.error};

    &::after {
      content: "";
      width: 1px;
      height: 100%;
      top: 0;
      position: absolute;
      background-color: ${(p) => p.theme.palette.grayscale[200]};
      left: 54px;
    }

    .form-control {
      border: none;
      padding-left: 62px;
      height: 100%;
      min-height: 24px;
      background: transparent;
      ${(p) => p.theme.tipography.bodyMediumRegular}
      position: relative;
    }

    .flag-dropdown {
      padding: 0 8px;
      border: none;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      background: transparent;

      &:active,
      &:focus,
      &::selection {
        background: transparent;
      }

      &.open {
        border: none;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        background: transparent;
      }

      .selected-flag {
        &:hover {
          background: transparent;
        }

        &.open {
          background: transparent;
        }

        .arrow {
          left: 28px;
        }
      }
    }
  }
`;
