import React from "react";
import { DividerComponent } from "../../../../components/Divider/styles";
import { Item, Content, Header } from "../../components/Accordion";
import { ContentWrapper, Section, SubTitle } from "./styles";

const Caracteristicas = () => {
  return (
    <Item value="conceptos">
      <Header>¿Qué conceptos cubre?</Header>
      <Content>
        <ContentWrapper>
          <Section>
            <SubTitle>Sepelio tradicional</SubTitle>
            <li>Carroza</li>
            <li>Cargadores</li>
            <li>Necropsia</li>
            <li>
              Derechos de Salubridad (incluye el pago por certificado médico de
              defunción y certificado de defunción expedido por el Municipio
              respectivo)
            </li>
            <li>Tramitación e instalación</li>
            <li>Ataúd</li>
            <li>Camioneta para aparatos florales</li>
            <li>Capilla ardiente</li>
            <li>Velatorio</li>
            <li>Tumba o nicho</li>
          </Section>
          <DividerComponent mode="vertical" />
          <Section>
            <SubTitle>Cremación</SubTitle>
            <li>Carroza</li>
            <li>Cargadores</li>
            <li>Necropsia</li>
            <li>Derechos de Salubridad</li>
            <li>Alquiler de ataúd para cremación</li>
            <li>Tramitación e instalación</li>
            <li>Camioneta para aparatos florales</li>
            <li>Capilla ardiente</li>
            <li>Velatorio</li>
            <li>Tumba o nicho</li>
            <li>Costo de cremación</li>
            <li>Urna</li>
          </Section>
        </ContentWrapper>
      </Content>
    </Item>
  );
};

export default Caracteristicas;
