import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { withRouter } from "react-router";
import Button from "../Button";
const ModalActualizaCelular = ({ closeModal, history }) => {
  const actualizar = () => {
    history.push("/mi-habitat-digital/afiliado/informacion/datos-personales");
  };
  return (
    <section className={`wraper-modal`}>
      <div className={`modal-content`}>
        <div className="modal-close">
          <i className="fa fa-times-circle-o" onClick={() => closeModal()}></i>
        </div>

        <section className="py-3">
          <div>
            <Card>
              <CardHeader className="custom-header-cambio-fondo custom-header-li">
                Habitat te informa
              </CardHeader>
              <CardBody>
                <section>
                  <p className="text-jutify px-3">
                    Hemos detectado que no tienes actualizado algunos datos,
                    para acceder a esta opción, te pedimos actualizar tu número
                    de celular para podernos contactar contigo.
                  </p>
                </section>
              </CardBody>
            </Card>
            <section className="d-flex justify-content-around">
              <Button
                name="secondary"
                bgColor="white"
                onClick={() => closeModal()}
              >
                Cancelar{" "}
              </Button>
              <Button onClick={actualizar}>Actualizar </Button>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalActualizaCelular);
