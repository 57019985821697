import React, { useState, useContext } from "react";
import MisdatosContext from "../../../context/misdatos/misdatosContext";
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Button } from "../../../components";
// import ModalConfirmacion from "./ModalConfirmacionDireccion";
import CustomModal from "../../../components/custromModal/CustomModal";
import ModalErrorToken from "./ModalErrorToken";
import ModalToken from "../../../components/ModalToken";

const globalErrors = {
  r_departamento: "",
  r_provincia: "",
  r_distrito: "",
  r_prefijo_direccion: "",
  r_direccion: "",
  r_prefijo_localidad: "",
  r_localidad: "",
  r_referencia: "",
  r_autorizacion_dp: "",
};

const Direccion = ({
  info,
  listas,
  mostrarModalToken,
  openModalToken,
  setOpenModalToken,
  setMostrarModalToken,
}) => {
  const initForm = {
    r_departamento: info.r_departamento === null ? "" : info.r_departamento,
    r_provincia: info.r_provincia === null ? "" : info.r_provincia,
    r_distrito: info.r_distrito === null ? "" : info.r_distrito,
    r_prefijo_direccion:
      info.r_prefijo_direccion === null ? "" : info.r_prefijo_direccion,
    r_direccion: info.r_direccion === null ? "" : info.r_direccion,
    r_prefijo_localidad:
      info.r_prefijo_localidad === null ? "" : info.r_prefijo_localidad,
    r_localidad: info.r_localidad === null ? "" : info.r_localidad,
    r_referencia: info.r_referencia === null ? "" : info.r_referencia,
    r_autorizacion_dp: "",
    r_tratamiento_datos: info.r_tratamiento_datos ?? false,
  };

  const misdatosContext = useContext(MisdatosContext);
  const {
    actualizarDireccionPost,
    loadingBtnDireccionPost,
    mensajeAlertaDireccionPost,
  } = misdatosContext;

  const [formulario, setFormulario] = useState(initForm);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const initSelect = (codigo, lista) => {
    let valorRetorno;
    if (codigo === "") {
      valorRetorno = "";
    } else {
      valorRetorno = JSON.stringify(lista.find((i) => i.r_codigo === codigo));
    }
    return valorRetorno;
  };
  const initSelectAll = (dep, prov, dist, lista) => {
    let valorRetorno;
    if (dist === null) {
      valorRetorno = JSON.stringify(
        lista.find(
          (i) => i.r_departamento_codigo === dep && i.r_codigo === prov
        )
      );
    } else {
      valorRetorno = JSON.stringify(
        lista.find(
          (i) =>
            i.r_departamento_codigo === dep &&
            i.r_provincia_codigo === prov &&
            i.r_codigo === dist
        )
      );
    }
    return valorRetorno;
  };

  const [formLocal, setFormLocal] = useState({
    r_departamento: initSelect(initForm.r_departamento, listas.r_departamentos),
    r_provincia: initSelectAll(
      initForm.r_departamento,
      initForm.r_provincia,
      null,
      listas.r_provincias
    ),
    r_distrito: initSelectAll(
      initForm.r_departamento,
      initForm.r_provincia,
      initForm.r_distrito,
      listas.r_distritos
    ),
    r_prefijo_direccion: initSelect(
      initForm.r_prefijo_direccion,
      listas.r_prefijos_direccion
    ),
    r_direccion: initForm.r_direccion,
    r_prefijo_localidad: initSelect(
      initForm.r_prefijo_localidad,
      listas.r_prefijos_localidad
    ),
    r_localidad: initForm.r_localidad,
    r_referencia: initForm.r_referencia,
    r_autorizacion_dp: initForm.r_autorizacion_dp,
  });

  const [mensaje1, setMensaje1] = useState("");
  const [errores, setErrores] = useState(globalErrors);
  const [modalInfo, setModalInfo] = useState(null);

  const listaOpciones = (lista) => {
    return lista.map((item, index) => (
      <option key={index} value={JSON.stringify(item)}>
        {item.r_descripcion}
      </option>
    ));
  };

  const listaProvincias = (dep) => {
    let respuesta = "";
    if (dep !== "") {
      const filtroProvincias = listas.r_provincias.filter(
        (i) => i.r_departamento_codigo === dep
      );
      respuesta = listaOpciones(filtroProvincias);
    }
    return respuesta;
  };
  const listaDistritos = (dep, prov) => {
    let respuesta = "";
    if (dep !== "" && prov !== "") {
      const filtroDistritos = listas.r_distritos.filter(
        (i) => i.r_departamento_codigo === dep && i.r_provincia_codigo === prov
      );
      respuesta = listaOpciones(filtroDistritos);
    }
    return respuesta;
  };

  const handlerSelectDepartamento = (e) => {
    let opcion;
    setFormLocal({
      ...formLocal,
      [e.target.name]: e.target.value,
      r_provincia: "",
      r_distrito: "",
    });
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setFormulario({
        ...formulario,
        [e.target.name]: opcion.r_codigo,
        r_provincia: "",
        r_distrito: "",
      });
    } else {
      setFormulario({
        ...formulario,
        [e.target.name]: "",
        r_provincia: "",
        r_distrito: "",
      });
    }
    setMensaje1("");
    setErrores(globalErrors);
  };

  const handlerSelectProvincia = (e) => {
    let opcion;
    setFormLocal({ ...formLocal, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setFormulario({
        ...formulario,
        [e.target.name]: opcion.r_codigo,
        r_distrito: "",
      });
    } else {
      setFormulario({
        ...formulario,
        [e.target.name]: "",
        r_distrito: "",
      });
    }
    setMensaje1("");
    setErrores(globalErrors);
  };

  const handlerSelectLocalidad = (e) => {
    let opcion;

    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setFormulario({
        ...formulario,
        [e.target.name]: opcion.r_codigo,
      });
      setFormLocal({ ...formLocal, [e.target.name]: e.target.value });
    } else {
      opcion = "";
      setFormLocal({
        ...formLocal,
        [e.target.name]: e.target.value,
        r_localidad: "",
      });
      setFormulario({
        ...formulario,
        [e.target.name]: "",
        r_localidad: "",
      });
    }
    setMensaje1("");
    setErrores(globalErrors);
  };
  const handlerSelect = (e) => {
    let opcion;
    setFormLocal({ ...formLocal, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setFormulario({
        ...formulario,
        [e.target.name]: opcion.r_codigo,
      });
    } else {
      setFormulario({
        ...formulario,
        [e.target.name]: "",
      });
    }
    setMensaje1("");
    setErrores(globalErrors);
  };

  const handlerInput = (e) => {
    setFormLocal({ ...formLocal, [e.target.name]: e.target.value });
    setFormulario({ ...formulario, [e.target.name]: e.target.value });
    setMensaje1("");
    setErrores(globalErrors);
  };

  const handlerCheckBox = (e) => {
    if (e.target.checked) {
      setFormLocal({ ...formLocal, [e.target.name]: e.target.value });
      setFormulario({ ...formulario, [e.target.name]: e.target.value });
    } else {
      setFormLocal({ ...formLocal, [e.target.name]: "" });
      setFormulario({ ...formulario, [e.target.name]: "" });
    }
  };

  const validarData = () => {
    let mensajesError = {
      r_departamento: "",
      r_provincia: "",
      r_distrito: "",
      r_prefijo_direccion: "",
      r_direccion: "",
      r_prefijo_localidad: "",
      r_localidad: "",
      r_referencia: "",
      r_autorizacion_dp: "",
    };
    let respuesta = true;

    /** No actualiza ningun dato */

    if (
      formulario.r_departamento === initForm.r_departamento &&
      formulario.r_provincia === initForm.r_provincia &&
      formulario.r_distrito === initForm.r_distrito &&
      formulario.r_prefijo_direccion === initForm.r_prefijo_direccion &&
      formulario.r_direccion === initForm.r_direccion &&
      formulario.r_prefijo_localidad === initForm.r_prefijo_localidad &&
      formulario.r_localidad === initForm.r_localidad &&
      formulario.r_referencia === initForm.r_referencia
    ) {
      respuesta = false;
      setMensaje1("No  modificó ningún campo.");
    } else {
      // Validamos el Departamento
      if (formulario.r_departamento !== initForm.r_departamento) {
        if (formulario.r_departamento.length === 0) {
          mensajesError = {
            ...mensajesError,
            r_departamento: "campo requerido",
          };
        }
      }
      // Validamos la Provincia
      if (formulario.r_provincia !== initForm.r_provincia) {
        if (formulario.r_provincia.length === 0) {
          mensajesError = {
            ...mensajesError,
            r_provincia: "campo requerido",
          };
        }
      }
      // Validamos de distritos
      if (formulario.r_distrito !== initForm.r_distrito) {
        if (formulario.r_distrito.length === 0) {
          mensajesError = {
            ...mensajesError,
            r_distrito: "campo requerido",
          };
        }
      }
      // Validamos tipo de via
      if (formulario.r_prefijo_direccion !== initForm.r_prefijo_direccion) {
        if (formulario.r_prefijo_direccion.length === 0) {
          mensajesError = {
            ...mensajesError,
            r_prefijo_direccion: "campo requerido",
          };
        }
      }
      // Validamos direccion
      if (
        formulario.r_direccion.length === 0 ||
        formulario.r_direccion.trim() === ""
      ) {
        mensajesError = {
          ...mensajesError,
          r_direccion: "campo requerido",
        };
      } else {
        const re = /^[a-zA-Z0-9 -.]*$/;
        if (!re.test(String(formulario.r_direccion))) {
          mensajesError = {
            ...mensajesError,
            r_direccion:
              "formato incorrecto solo se aceptan letras numeros puntos y guiones",
          };
        } else {
          if (
            formulario.r_direccion.includes("..") ||
            formulario.r_direccion.includes("--") ||
            formulario.r_direccion.charAt(0) === "."
          ) {
            mensajesError = {
              ...mensajesError,
              r_direccion: "formato incorrecto",
            };
          } else {
            if (formulario.r_direccion.length > 110) {
              mensajesError = {
                ...mensajesError,
                r_direccion: "campo máximo de 110 caracteres",
              };
            }
          }
        }
      }
      // Validamos tipo de localidad y Localidad
      if (formulario.r_prefijo_localidad !== initForm.r_prefijo_localidad) {
        if (formulario.r_prefijo_localidad.length !== 0) {
          // Validamos Localidad
          if (
            formulario.r_localidad.length === 0 ||
            formulario.r_localidad.trim() === ""
          ) {
            mensajesError = {
              ...mensajesError,
              r_localidad: "campo requerido",
            };
          } else {
            const re = /^[a-zA-Z0-9 -.]*$/;
            if (!re.test(String(formulario.r_localidad))) {
              mensajesError = {
                ...mensajesError,
                r_localidad:
                  "formato incorrecto solo se aceptan letras numeros puntos y guiones",
              };
            } else {
              if (
                formulario.r_localidad.includes("..") ||
                formulario.r_direccion.includes("--") ||
                formulario.r_localidad.charAt(0) === "."
              ) {
                mensajesError = {
                  ...mensajesError,
                  r_localidad: "formato incorrecto",
                };
              } else {
                if (formulario.r_localidad.length > 110) {
                  mensajesError = {
                    ...mensajesError,
                    r_localidad: "campo máximo de 110 caracteres",
                  };
                }
              }
            }
          }
        } else {
          if (
            formulario.r_localidad.length !== 0 ||
            formulario.r_localidad.trim() !== ""
          ) {
            mensajesError = {
              ...mensajesError,
              r_prefijo_localidad: "campo requerido",
            };
          }
        }
      } else {
        // Validamos Localidad
        if (
          formulario.r_localidad.length === 0 ||
          formulario.r_localidad.trim() === ""
        ) {
          mensajesError = {
            ...mensajesError,
            r_localidad: "campo requerido",
          };
        } else {
          const re = /^[a-zA-Z0-9 -.]*$/;
          if (!re.test(String(formulario.r_localidad))) {
            mensajesError = {
              ...mensajesError,
              r_localidad:
                "formato incorrecto solo se aceptan letras numeros puntos y guiones",
            };
          } else {
            if (
              formulario.r_localidad.includes("..") ||
              formulario.r_localidad.includes("--") ||
              formulario.r_localidad.charAt(0) === "."
            ) {
              mensajesError = {
                ...mensajesError,
                r_localidad: "formato incorrecto",
              };
            } else {
              if (formulario.r_localidad.length > 110) {
                mensajesError = {
                  ...mensajesError,
                  r_localidad: "campo máximo de 110 caracteres",
                };
              }
            }
          }
        }
      }

      // Validamos referencia
      if (formulario.r_referencia !== initForm.r_referencia) {
        if (formulario.r_referencia.length > 0) {
          const re = /^[a-zA-Z0-9 -.]*$/;
          if (!re.test(String(formulario.r_referencia))) {
            mensajesError = {
              ...mensajesError,
              r_referencia:
                "formato incorrecto solo se aceptan letras numeros puntos y guiones",
            };
          } else {
            if (
              formulario.r_referencia.includes("..") ||
              formulario.r_referencia.includes("--") ||
              formulario.r_referencia.charAt(0) === "."
            ) {
              mensajesError = {
                ...mensajesError,
                r_referencia: "formato incorrecto",
              };
            } else {
              if (formulario.r_referencia.length > 110) {
                mensajesError = {
                  ...mensajesError,
                  r_referencia: "campo máximo de 110 caracteres",
                };
              }
            }
          }
        }
      }
    }
    setErrores(mensajesError);

    // Vemos si existe algun error

    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const armarEstructura = () => {
    let estructura = {};

    estructura["r_departamento"] = formulario.r_departamento;
    estructura["r_provincia"] = formulario.r_provincia;
    estructura["r_distrito"] = formulario.r_distrito;
    estructura["r_prefijo_direccion"] = formulario.r_prefijo_direccion;
    estructura["r_direccion"] = formulario.r_direccion;
    estructura["r_prefijo_localidad"] = formulario.r_prefijo_localidad;
    estructura["r_localidad"] = formulario.r_localidad;
    estructura["r_referencia"] = formulario.r_referencia;
    if (formulario.r_autorizacion_dp !== "") {
      estructura["r_autorizacion_dp"] = formulario.r_autorizacion_dp;
    }

    return estructura;
  };

  const procesar = () => {
    if (validarData()) {
      const estructura = armarEstructura();
      setModalInfo({
        base: estructura,
        r_departamento:
          formLocal.r_departamento === ""
            ? ""
            : JSON.parse(formLocal.r_departamento).r_descripcion,
        r_provincia:
          formLocal.r_provincia === ""
            ? ""
            : JSON.parse(formLocal.r_provincia).r_descripcion,
        r_distrito:
          formLocal.r_distrito === ""
            ? ""
            : JSON.parse(formLocal.r_distrito).r_descripcion,
        r_via:
          formLocal.r_prefijo_direccion === ""
            ? ""
            : JSON.parse(formLocal.r_prefijo_direccion).r_descripcion,
        r_localidad:
          formLocal.r_prefijo_localidad === ""
            ? ""
            : JSON.parse(formLocal.r_prefijo_localidad).r_descripcion,
      });
      setShowModalInfo(!showModalInfo);
    }
  };
  const procesarSolicitud = (token) => {
    let info = {
      data: modalInfo.base,
      v_token_digital: token,
    };
    actualizarDireccionPost(info);
  };
  return (
    <div>
      <Card>
        <CardBody>
          <section>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="r_departamento">Departamento</Label>
                  <select
                    className="form-control"
                    name="r_departamento"
                    value={formLocal.r_departamento}
                    onChange={handlerSelectDepartamento}
                  >
                    <option value=""> -Seleccione departamento-</option>
                    {listaOpciones(listas.r_departamentos)}
                  </select>
                  {errores.r_departamento && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_departamento}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="r_provincia">Provincia</Label>
                  <select
                    className="form-control"
                    name="r_provincia"
                    value={formLocal.r_provincia}
                    onChange={handlerSelectProvincia}
                  >
                    <option value="">-Seleccione provincia-</option>
                    {listaProvincias(formulario.r_departamento)}
                  </select>
                  {errores.r_provincia && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_provincia}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="r_distrito">Distrito</Label>
                  <select
                    className="form-control"
                    name="r_distrito"
                    value={formLocal.r_distrito}
                    onChange={handlerSelect}
                  >
                    <option value="">Seleccione distrito</option>
                    {listaDistritos(
                      formulario.r_departamento,
                      formulario.r_provincia
                    )}
                  </select>
                  {errores.r_distrito && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_distrito}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="r_prefijo_direccion">Tipo de vía</Label>
                  <select
                    className="form-control"
                    name="r_prefijo_direccion"
                    value={formLocal.r_prefijo_direccion}
                    onChange={handlerSelect}
                  >
                    <option value="">Seleccione tipo de via</option>
                    {listaOpciones(listas.r_prefijos_direccion)}
                  </select>
                  {errores.r_prefijo_direccion && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_prefijo_direccion}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={8}>
                <FormGroup>
                  <Label htmlFor="r_direccion">Dirección</Label>
                  <Input
                    placeholder="Dirección"
                    disabled={formulario.r_prefijo_direccion === ""}
                    name="r_direccion"
                    value={formLocal.r_direccion}
                    maxLength={110}
                    onChange={handlerInput}
                  />
                  {errores.r_direccion && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_direccion}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="r_prefijo_localidad">Tipo de localidad</Label>
                  <select
                    className="form-control"
                    name="r_prefijo_localidad"
                    value={formLocal.r_prefijo_localidad}
                    onChange={handlerSelectLocalidad}
                  >
                    <option value="">-Tipo de localidad-</option>
                    {listaOpciones(listas.r_prefijos_localidad)}
                  </select>
                  {errores.r_prefijo_localidad && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_prefijo_localidad}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Label htmlFor="r_localidad">Localidad</Label>
                  <Input
                    placeholder="Ingrese su localidad"
                    name="r_localidad"
                    disabled={formLocal.r_prefijo_localidad === ""}
                    value={formLocal.r_localidad}
                    maxLength={110}
                    onChange={handlerInput}
                  />
                  {errores.r_localidad && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_localidad}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="r_referencia">Referencia</Label>
                  <Input
                    placeholder="Ingrese su dirección"
                    name="r_referencia"
                    value={formLocal.r_referencia}
                    maxLength={100}
                    onChange={handlerInput}
                  />
                  {errores.r_referencia && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_referencia}
                    </span>
                  )}
                </FormGroup>
              </Col>
              {mensaje1 && (
                <Col md={12}>
                  <div className="m-0 alert alert-danger">{mensaje1}</div>
                </Col>
              )}
            </Row>
          </section>
        </CardBody>
        {info.r_tratamiento_datos && (
          <CardFooter>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                    Conozco que la información será tratada de conformidad con
                    la{" "}
                    <a
                      rel="noopener noreferrer"
                      href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                      target="_blank"
                    >
                      Política de Privacidad de AFP Habitat S.A.
                    </a>
                  </p>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="r_autorizacion_dp"
                      name="r_autorizacion_dp"
                      value={"1"}
                      onChange={handlerCheckBox}
                    />
                    <label
                      style={{ fontSize: "12px" }}
                      className="custom-control-label"
                      htmlFor="r_autorizacion_dp"
                    >
                      Autorizo voluntariamente que AFP Habitat S.A. realice
                      tratamiento de mis datos personales, con fines de envío de
                      publicidad sobre los productos y servicios de la compañía
                      y de aquellas que forman parte de su grupo económico, de
                      conformidad con la{" "}
                      <a
                        href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Política de Privacidad de AFP Habitat S.A.
                      </a>
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
      <section className="pt-3 d-flex justify-content-around">
        <Button onClick={procesar}>Actualizar</Button>
      </section>
      <CustomModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de actualización de datos personales"
        description={
          modalInfo && (
            <>
              <p className="text-jutify">
                Estás a punto de cambiar los siguientes datos, si estás de
                acuerdo con esto da clic en Aceptar.
              </p>

              <section className="mb-2">
                {modalInfo.base.r_departamento && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Departamento</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_departamento}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_provincia && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Provincia</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_provincia}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_distrito && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Distrito</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_distrito}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_prefijo_direccion && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Tipo de via</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_via}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_direccion && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Dirección</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.base.r_direccion}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_prefijo_localidad && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Tipo de localidad</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.r_localidad}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_localidad && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Localidad</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.base.r_localidad}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_referencia && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Referencia</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.base.r_referencia}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_autorizacion_dp && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">
                      Terminos y condiciones
                    </p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      Aceptado
                    </p>
                  </div>
                )}
                {mensajeAlertaDireccionPost && (
                  <div className="alert alert-danger mt-2">
                    Hubo un problema por favor vuelva a intentarlo
                  </div>
                )}
              </section>
            </>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          // procesarSolicitud();
          setOpenModalToken(true);
          setShowModalInfo(false);
        }}
        btnPrimaryLoading={loadingBtnDireccionPost}
        textBtnPrimary="Aceptar"
      />{" "}
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalErrorToken
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </div>
  );
};

export default Direccion;
