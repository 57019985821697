import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import arrowDown from "../../../../../../../assets/icons/akar-icons_chevron-down.svg";
import arrowUp from "../../../../../../../assets/icons/akar-icons_chevron-up.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const SubMenuOption = styled.div`
  & .selectUrl {
    // background: ${(props) => props.theme.palette.background.default};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }
  & a {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    ${(props) => props.theme.tipography.body_link_small}
    & svg {
      width: 12px;
      height: 12px;
      font-weight: bold;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;
export default function DrawerOptionUnCollapseMode(props) {
  const {
    id,
    title,
    icon,
    altIcon,
    url,
    subOptions,
    subMenuOptions,
    setOpenDrawer,
    optionSelected,
    handleClose,
    setOptionSelected,
    sidebarOptions,
    location,
    connectEtario,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id === optionSelected) setIsOpen(true);
    else
      setIsOpen(
        url
          ? window.location.href.includes(url) && subMenuOptions == null
          : false
      );
  }, [optionSelected]);
  return (
    <div
      className="content-padding"
      style={{
        padding: "16px 12px",
      }}
    >
      {props.url ? (
        <div className={`${isOpen ? "selected" : ""}`}>
          <div
            className="drawer-option-menu"
            onClick={() => {
              setOpenDrawer(false);
              connectEtario(false);
              if (isOpen) setOptionSelected("");
              else setOptionSelected(id);
            }}
          >
            <Link
              to={props.url}
              style={{
                color: "#000",
              }}
            >
              <img className="icon-menu" src={icon} alt={altIcon} />
              <span className={`${isOpen ? "bold" : ""}`}>{title}</span>
            </Link>
          </div>
        </div>
      ) : (
        <div
          className="drawer-option-menu"
          onClick={() => {
            if (id === "logout") {
              handleClose();
            }
            connectEtario(false);
            if (isOpen) setOptionSelected("");
            else setOptionSelected(id);
          }}
        >
          <img className="icon-menu" src={icon} alt={altIcon} />
          <span className={`${isOpen ? "bold" : ""}`}>{title}</span>
          {subOptions && (
            <img
              className="black-arrow-down"
              src={isOpen ? arrowUp : arrowDown}
              alt="fecha-negra-abajo"
            />
          )}
        </div>
      )}

      {subOptions && isOpen && (
        <SubMenuOption className="toggle-open-options-container">
          <div className="center-items vertical-line-content">
            <div className="vertical-line"></div>
          </div>
          <div
            className="options-content"
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            {subOptions
              .filter((e) => e.show !== false)
              .map((option, index) => {
                return (
                  <div
                    key={`suboption-${option.id}`}
                    className={
                      "option-menu" +
                      (location.pathname === option.url ? " selectUrl" : "")
                    }
                    style={{
                      display:
                        (!sidebarOptions.cambioFondo &&
                          !sidebarOptions.retiroApv &&
                          option.id === "sub-retiro") ||
                        (!props.sidebarOptions.misInversiones &&
                          option.id === "sub-evolucion") ||
                        (!props.sidebarOptions.retiro25Show &&
                          option.id === "sub-credito") ||
                        (!props.sidebarOptions.tfehow &&
                          option.id === "sub-exterior") ||
                        (!props.sidebarOptions.showAporteMensual &&
                          option.id === "debito-mensual")
                          ? "none"
                          : "block",
                    }}
                  >
                    <Link to={option.url}>
                      <span>{option.title}</span>
                      {location.pathname === option.url && (
                        <ArrowForwardIosIcon />
                      )}
                    </Link>
                  </div>
                );
              })}
          </div>
        </SubMenuOption>
      )}
    </div>
  );
}
