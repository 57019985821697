import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { Grid, Alert, Button } from "@mui/material";
import ErrorMessage from "../../errors/error";
import { CONSECUTIVOS } from "./consecutivos";
import GenerarClaveContext from "../../../../context/generarClave/generarClaveContext";

const FormClave = (props) => {
  const { openModalConfirm, openModalInfo, bloquear } = props;
  const generarClaveContexto = useContext(GenerarClaveContext);
  const {
    gTokenClaveWeb,
    gintentoCambioClave,
    gBloqueoPasos,
    bloquearPasos,
    cambioIntentoDocumento,
    cambioIntentoVolverToken,
    cambioIntentoConfirmaToken,
    cambioIntentoClave,
    changePassFunct,
    guardarIntentoGeneral,
    LlenarErrorGenerico,
  } = generarClaveContexto;
  const globalErrors = {
    r_clave_web: "",
    r_clave_confirmar: "",
    r_clave_repet: "",
  };

  const { handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [errores, setErrores] = useState(globalErrors);
  const [apiExecuted, setApiExecuted] = useState(false);
  const [dataForm, setDataForm] = useState({
    password: "",
    confirmpassword: "",
  });

  const todosIntentosBloqueados = () => {
    cambioIntentoDocumento(3);
    cambioIntentoVolverToken(3);
    cambioIntentoConfirmaToken(3);
    cambioIntentoClave(3);
  };

  const onSubmit = async () => {
    //DATALAYER GENERA TU CLAVE WEB
    window.dataLayer.push({
      event: "button_click",
      description: "genera tu clave web",
      option: "confirmar",
    });
    //validar nros iguales y consecutivos, validar que ambos son iguales,
    setLoading(true);
    let body = { clave: dataForm.password, token: gTokenClaveWeb };
    try {
      if (validateSubmit() && validateIgualdad() && validateConsecutivo()) {
        let { response, intentosG } = await changePassFunct(body);
        if (intentosG > 2 && response) {
          bloquear();
          bloquearPasos(true);
          todosIntentosBloqueados();
          setLoading(false);
        } else {
          if (response) {
            setLoading(false);
            setErrores({
              ...errores,
              r_clave_confirmar:
                "La clave ingresada no cumple con las consideraciones indicadas.",
            });
            setDataForm({
              password: "",
              confirmpassword: "",
            });
            setApiExecuted(true);
          } else {
            openModalConfirm();
            setLoading(false);
          }
        }
      } else {
        body = { token: gTokenClaveWeb };
        let { response, intentosG } = await guardarIntentoGeneral(body);
        //guardar intento
        if (intentosG === 3) {
          bloquear();
          bloquearPasos(true);
          todosIntentosBloqueados();
        }
        setLoading(false);
      }
    } catch (error) {
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }
  };

  const validateSubmit = () => {
    if (dataForm.password !== dataForm.confirmpassword) {
      setErrores({ ...errores, r_clave_repet: "Las claves no coinciden" });
      setDataForm({
        password: "",
        confirmpassword: "",
      });
      setApiExecuted(true);
      return false;
    } else {
      setErrores({ ...errores, r_clave_repet: "" });
      return true;
    }
  };

  const validateConsecutivo = () => {
    if (CONSECUTIVOS.includes(dataForm.password)) {
      setErrores({
        ...errores,
        r_clave_repet: "No utilices números consecutivos: Ejemplo 4321, 5678",
      });
      setDataForm({
        password: "",
        confirmpassword: "",
      });
      setApiExecuted(true);
      return false;
    } else {
      setErrores({ ...errores, r_clave_repet: "" });
      return true;
    }
  };

  const validateIgualdad = () => {
    let regex = new RegExp("([0-9])\\1{3}");
    if (regex.test(dataForm.password)) {
      setErrores({
        ...errores,
        r_clave_repet: "No utilices números únicos: Ejemplo 1111, 2222",
      });
      setDataForm({
        password: "",
        confirmpassword: "",
      });
      setApiExecuted(true);
      return false;
    } else {
      setErrores({ ...errores, r_clave_repet: "" });
      return true;
    }
  };

  const validateData = (event) => {
    let mensajesError = {
      r_clave_web: "",
      r_clave_confirmar: "",
    };
    const { name, value } = event.target;
    mensajesError = errores;
    mensajesError.r_clave_repet = "";
    let valueDoc = value.replace(/[^0-9]/g, "");
    switch (
      name //validar que solo son nros
    ) {
      case "password":
        if (valueDoc.length === 0) {
          mensajesError.r_clave_web = "";
          break;
        }
        // if (regexOnlyNumber(value)) {
        //     mensajesError.r_clave_web = ''
        // } else {
        //     mensajesError.r_clave_web = 'Tu clave debe ser numérica.'
        // }
        break;

      case "confirmpassword":
        if (valueDoc.length === 0) {
          mensajesError.r_clave_confirmar = "";
          break;
        }
        // if (regexOnlyNumber(value)) {
        //     mensajesError.r_clave_confirmar = ''
        // } else {
        //     mensajesError.r_clave_confirmar = 'Tu clave debe ser numérica.'
        // }
        break;

      default:
        break;
    }
    setDataForm({ ...dataForm, [name]: valueDoc });
    setErrores(mensajesError);
    if (apiExecuted && valueDoc.length > 0) {
      mensajesError = errores;
      mensajesError.r_clave_repet = "";
      mensajesError.r_clave_web = "";
      mensajesError.r_clave_confirmar = "";
      setErrores(mensajesError);
      setApiExecuted(false);
    }
  };

  // const regexOnlyNumber = (text) => {
  //     let regex = new RegExp("^[0-9]+$");
  //     if (!regex.test(text)) {
  //         return false;
  //     } else {
  //         return true
  //     }
  // }

  const OpenInfo = () => {
    openModalInfo();
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="new-home-form-group-web">
            <label htmlFor="password">Nueva clave</label>
            <input
              className="form-field bg-form-web modify-select"
              id="password"
              maxLength={4}
              type="password"
              name="password"
              pattern="[0-9]*"
              value={dataForm.password}
              onChange={validateData}
            />
            <ErrorMessage
              mensaje={errores.r_clave_web}
              mensajeletra={true}
            ></ErrorMessage>
            {errors?.password && errors?.password.type === "required" && (
              <span className="error-new-home">Campo requerido*</span>
            )}
            {errors?.password &&
              (errors?.password.type === "minLength" ||
                errors?.password.type === "maxLength") && (
                <p style={{ fontSize: "14px" }} className="text-danger m-0">
                  campo de 4 caracteres
                </p>
              )}
          </div>
          <div className="new-home-form-group-web">
            <label htmlFor="confirmpassword">Confirmar clave</label>
            <input
              className="form-field bg-form-web modify-select"
              id="confirmpassword"
              maxLength={4}
              type="password"
              name="confirmpassword"
              pattern="[0-9]*"
              value={dataForm.confirmpassword}
              onChange={validateData}
            />
            <ErrorMessage
              mensaje={errores.r_clave_confirmar}
              mensajeletra={true}
              handleOpenModal={openModalInfo}
            ></ErrorMessage>
            <ErrorMessage
              mensaje={errores.r_clave_repet}
              mensajeletra={true}
            ></ErrorMessage>
          </div>

          {/* <Alert severity="info" action={
                        <Button className="" color="inherit" size="small" onClick={OpenInfo}>
                            Ver más
                        </Button>
                    }>
                        <b className="">Revisa las consideraciones para modificar tu clave web. </b>
                    </Alert> */}
          {gintentoCambioClave === 2 ? (
            <div className="error-block mt-2">
              <p>Te queda solo un intento permitido</p>
            </div>
          ) : (
            ""
          )}

          <hr />
          {!loading ? (
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <button
                  type="submit"
                  className={` btn-recover margin-center width-mobile ${
                    !(
                      dataForm.password.length === 4 &&
                      dataForm.confirmpassword.length === 4 &&
                      errores.r_clave_web === "" &&
                      errores.r_clave_confirmar === ""
                    ) || gBloqueoPasos
                      ? "disabled-button-web"
                      : ""
                  }`}
                  disabled={
                    !(
                      dataForm.password.length === 4 &&
                      dataForm.confirmpassword.length === 4 &&
                      errores.r_clave_web === "" &&
                      errores.r_clave_confirmar === ""
                    ) || gBloqueoPasos
                  }
                >
                  Confirmar{" "}
                </button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <button
                  className="mt-3 btn-secundary margin-center disabled-button-web"
                  disabled
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="ml-1">Validando</span>
                </button>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
    </>
  );
};

export default FormClave;
