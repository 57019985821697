import React, { useState, useContext } from "react";
import { re } from "../../../data/DataLocal";
import MisdatosContext from "../../../context/misdatos/misdatosContext";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import ModalToken from "../../../components/ModalToken";
import ModalErrorToken from "./ModalErrorToken";

import CustomModal from "../../../components/custromModal/CustomModal";
import { Button } from "../../../components";
const globalErrors = {
  r_prefijo_telefono: "",
  r_telefono: "",
  r_celular: "",
  r_correo: "",
};

const Datos = ({
  info,
  listaCodigos,
  mostrarModalToken,
  openModalToken,
  setOpenModalToken,
  setMostrarModalToken,
  recargar,
}) => {
  const initForm = {
    r_prefijo_telefono:
      info.r_prefijo_telefono === null ? "" : info.r_prefijo_telefono,
    r_telefono: info.r_telefono === null ? "" : info.r_telefono,
    r_celular: info.r_celular === null ? "" : info.r_celular,
    r_correo: info.r_correo === null ? "" : info.r_correo,
    r_tipo_eecc: "",
    r_autorizacion_dp: "",
    r_tratamiento_datos: info.r_tratamiento_datos
      ? info.r_tratamiento_datos
      : false,
  };
  const [formulario, setFormulario] = useState(initForm);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const misdatosContext = useContext(MisdatosContext);
  const { actualizarDatosPost, loadingBtnDatosPost, mensajeAlertaDatosPost } =
    misdatosContext;

  const initDiscoLocal = () => {
    let valorRetorno;
    if (info.r_prefijo_telefono === null) {
      valorRetorno = "";
    } else {
      valorRetorno = JSON.stringify(
        listaCodigos.find((item) => item.r_codigo === info.r_prefijo_telefono)
      );
    }
    return valorRetorno;
  };

  const [discadoLocal, setDiscadoLocal] = useState(initDiscoLocal());
  const [longitudTelefono, setongitudTelefono] = useState(
    discadoLocal === "" ? 0 : parseInt(JSON.parse(discadoLocal).r_longitud)
  );

  const [mensaje1, setMensaje1] = useState("");
  const [errores, setErrores] = useState(globalErrors);
  const [modalInfo, setModalInfo] = useState(null);

  const listCode = () => {
    return listaCodigos.map((item, index) => (
      <option key={index} value={JSON.stringify(item)}>
        {item.r_descripcion}
      </option>
    ));
  };

  const handlerSelect = (e) => {
    let opcion;
    if (e.target.value.length > 0) {
      opcion = JSON.parse(e.target.value);
      setDiscadoLocal(JSON.stringify(opcion));
      setFormulario({
        ...formulario,
        r_prefijo_telefono: opcion.r_codigo,
        r_telefono: "",
      });
      setongitudTelefono(parseInt(opcion.r_longitud));
    } else {
      opcion = "";
      setDiscadoLocal(opcion);
      setongitudTelefono(0);
      setFormulario({
        ...formulario,
        r_prefijo_telefono: "",
        r_telefono: "",
      });
    }
    setMensaje1("");
    setErrores(globalErrors);
  };
  const handlerEmail = (e) => {
    setFormulario({ ...formulario, [e.target.name]: e.target.value });
    setMensaje1("");
    setErrores(globalErrors);
  };
  const handlerNumeros = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setFormulario({ ...formulario, [e.target.name]: valor });
    setMensaje1("");
    setErrores(globalErrors);
  };

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const handlerCheckBox = (e) => {
    if (e.target.checked) {
      setFormulario({ ...formulario, [e.target.name]: e.target.value });
    } else {
      setFormulario({ ...formulario, [e.target.name]: "" });
    }
  };

  const handlerCheckBoxVirtual = (e) => {
    if (e.target.checked) {
      setFormulario({ ...formulario, [e.target.name]: e.target.value });
    } else {
      setFormulario({ ...formulario, [e.target.name]: "" });
    }
    setMensaje1("");
  };

  const validarData = () => {
    let mensajesError = {
      r_prefijo_telefono: "",
      r_telefono: "",
      r_celular: "",
      r_correo: "",
    };
    let respuesta = true;

    // Validamos el código de discado
    if (
      (formulario.r_prefijo_telefono.length !== 0 ||
        formulario.r_prefijo_telefono.trim() !== "") &&
      (formulario.r_telefono.length === 0 ||
        formulario.r_telefono.trim() === "")
    ) {
      if (formulario.r_telefono.length !== longitudTelefono) {
        mensajesError = {
          ...mensajesError,
          r_telefono: `campo de ${longitudTelefono} dígitos`,
        };
      } else {
        if (parseInt(formulario.r_telefono.charAt(0)) === 0) {
          mensajesError = {
            ...mensajesError,
            r_telefono: "formato invalido",
          };
        }
      }
    }
    if (
      formulario.r_celular.length === 0 ||
      formulario.r_celular.trim() === ""
    ) {
      mensajesError = { ...mensajesError, r_celular: "campo requerido" };
    } else {
      if (formulario.r_celular.length !== 9) {
        mensajesError = { ...mensajesError, r_celular: "campo de 9 dígitos" };
      } else {
        if (parseInt(formulario.r_celular.charAt(0)) !== 9) {
          mensajesError = {
            ...mensajesError,
            r_celular: "formato invalido",
          };
        }
      }
    }
    // validamos celular
    if (
      formulario.r_celular.length === 0 ||
      formulario.r_celular.trim() === ""
    ) {
      mensajesError = { ...mensajesError, r_celular: "campo requerido" };
    } else {
      if (formulario.r_celular.length !== 9) {
        mensajesError = { ...mensajesError, r_celular: "campo de 9 dígitos" };
      }
    }

    // validamos Correo
    if (formulario.r_correo.length === 0 || formulario.r_correo.trim() === "") {
      mensajesError = { ...mensajesError, r_correo: "campo requerido" };
    } else {
      if (!re.test(String(formulario.r_correo))) {
        mensajesError = {
          ...mensajesError,
          r_correo: "formato de correo incorrecto",
        };
      } else {
        if (
          formulario.r_correo.includes("..") ||
          formulario.r_correo.includes(".@") ||
          formulario.r_correo.includes("--") ||
          formulario.r_correo.includes("__") ||
          formulario.r_correo.charAt(0) === "."
        ) {
          mensajesError = {
            ...mensajesError,
            r_correo: "formato de correo incorrecto",
          };
        } else {
          if (formulario.r_correo.length > 160) {
            mensajesError = {
              ...mensajesError,
              r_correo: "campo máximo de 160 caracteres",
            };
          }
        }
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error

    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const armarEstructura = () => {
    let estructura = {};

    if (formulario.r_prefijo_telefono !== "" && formulario.r_telefono !== "") {
      estructura["r_prefijo_telefono"] = formulario.r_prefijo_telefono;
      estructura["r_telefono"] = formulario.r_telefono;
    }

    if (formulario.r_telefono !== "") {
      estructura["r_telefono"] = formulario.r_telefono;
    }
    if (formulario.r_celular !== "") {
      estructura["r_celular"] = formulario.r_celular;
    }
    if (formulario.r_correo !== "") {
      estructura["r_correo"] = formulario.r_correo.toUpperCase();
    }
    if (formulario.r_tipo_eecc !== "") {
      estructura["r_tipo_eecc"] = formulario.r_tipo_eecc;
    }
    if (formulario.r_autorizacion_dp !== "") {
      estructura["r_autorizacion_dp"] = formulario.r_autorizacion_dp;
    }

    return estructura;
  };

  // const closeModalInfo = () => () => {
  //   setModalInfo(null);
  // };

  const procesar = () => {
    if (validarData()) {
      const estructura = armarEstructura();
      setShowModalInfo(!showModalInfo);
      setModalInfo({
        base: estructura,
        des_discado:
          discadoLocal === "" ? "" : JSON.parse(discadoLocal).r_descripcion,
        des_codigo:
          discadoLocal === "" ? "" : JSON.parse(discadoLocal).r_codigo,
      });
    }
  };

  const procesarSolicitud = (token) => {
    let info = {
      data: modalInfo.base,
      v_token_digital: token,
    };

    actualizarDatosPost(info);
  };
  return (
    <div>
      <Card>
        <CardBody>
          <section>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="r_celular">Celular</Label>
                  <Input
                    placeholder="Ingrese celular"
                    name="r_celular"
                    value={formulario.r_celular}
                    pattern="[0-9]*"
                    onChange={handlerNumeros}
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    maxLength={9}
                  />
                  {errores.r_celular && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_celular}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="r_correo">E-mail</Label>
                  <Input
                    style={{ textTransform: "uppercase" }}
                    placeholder="Ingrese email"
                    name="r_correo"
                    onChange={handlerEmail}
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    value={formulario.r_correo}
                  />
                  {errores.r_correo && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_correo}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="nombre">Código de discado directo</Label>
                  <select
                    className="form-control"
                    name="r_prefijo_telefono"
                    value={discadoLocal}
                    onChange={handlerSelect}
                  >
                    <option value="">-Seleccionar código-</option>
                    {listCode()}
                  </select>
                  {errores.r_prefijo_telefono && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_prefijo_telefono}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="r_telefono">Teléfono fijo</Label>
                  <Input
                    placeholder="Ingresar teléfono fijo"
                    name="r_telefono"
                    // disabled={formulario.r_prefijo_telefono === ""}
                    pattern="[0-9]*"
                    onChange={handlerNumeros}
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    maxLength={longitudTelefono}
                    value={formulario.r_telefono}
                  />
                  {errores.r_telefono && (
                    <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                      {errores.r_telefono}
                    </span>
                  )}
                </FormGroup>
              </Col>
              {info.r_tipo_eecc === "F" && (
                <Col md={12}>
                  <FormGroup className="mb-0">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="r_tipo_eecc"
                        value="V"
                        onChange={handlerCheckBoxVirtual}
                        id="r_tipo_eecc"
                      />
                      <label
                        style={{ fontSize: "12px" }}
                        className="custom-control-label"
                        htmlFor="r_tipo_eecc"
                      >
                        Autorizo a AFP Habitat el envío de mi Estado de Cuenta
                        en versión resumida así como cualquier comunicación,
                        solicitud, petición y/o documentos vinculados a los
                        trámites que realice en AFP Habitat -incluido cartas,
                        órdenes médicas y dictámenes que emita el Comité Médico
                        de las AFP (COMAFP) y el Comité Médico de la
                        Superintendencia (COMEC)- al correo señalado
                        anteriormente o al envío de lo indicado por cualquier
                        otro medio electrónico que así se disponga, para lo cual
                        declaro que la fecha de recepción de dichas
                        notificaciones por mi parte, será la misma fecha en que
                        AFP Habitat reciba la respuesta automática de recepción
                        del correo referido o confirme la recepción por llamada
                        telefónica al número antes señalado.
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              )}
              {mensaje1 && (
                <Col md={12} className="mt-2">
                  <div className="m-0 alert alert-danger">{mensaje1}</div>
                </Col>
              )}
            </Row>
          </section>
        </CardBody>
        {info.r_tratamiento_datos && (
          <CardFooter>
            <Row>
              <Col md={12}>
                <FormGroup className="mb-0">
                  <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                    Conozco que la información será tratada de conformidad con
                    la{" "}
                    <a
                      rel="noopener noreferrer"
                      href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                      target="_blank"
                    >
                      Política de Privacidad de AFP Habitat S.A.
                    </a>
                  </p>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="r_autorizacion_dp"
                      name="r_autorizacion_dp"
                      value={"1"}
                      onChange={handlerCheckBox}
                    />
                    <label
                      style={{ fontSize: "12px" }}
                      className="custom-control-label"
                      htmlFor="r_autorizacion_dp"
                    >
                      Autorizo voluntariamente que AFP Habitat S.A. realice
                      tratamiento de mis datos personales, con fines de envío de
                      publicidad sobre los productos y servicios de la compañía
                      y de aquellas que forman parte de su grupo económico, de
                      conformidad con la{" "}
                      <a
                        href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Política de Privacidad de AFP Habitat S.A.
                      </a>
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
      <section className=" d-flex justify-content-around pt-3">
        <Button onClick={procesar}>Actualizar</Button>
      </section>
      {/* {modalInfo && (
        <ModalConfirmacion info={modalInfo} closeModal={closeModalInfo} />
      )} */}
      <CustomModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de actualización de datos personales"
        description={
          modalInfo && (
            <>
              <p className="text-jutify">
                Estás a punto de cambiar los siguientes datos, si estás de
                acuerdo con esto da clic en Aceptar.
              </p>
              <section className="mb-2">
                {modalInfo.base.r_prefijo_telefono && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Código de discado</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.des_discado}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_telefono && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Teléfono fijo</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.base.r_telefono}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_celular && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">Celular</p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      {modalInfo.base.r_celular}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_correo && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">E-mail</p>
                    <p
                      className="m-0 text-right custom-mb-modal-bco"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {modalInfo.base.r_correo}
                    </p>
                  </div>
                )}
                {modalInfo.base.r_tipo_eecc && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">
                      Estado Cuenta Virtual
                    </p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      Aceptado
                    </p>
                  </div>
                )}
                {modalInfo.base.r_autorizacion_dp && (
                  <div className="px-3 py-2 d-flex justify-content-between mb-table-modal">
                    <p className="m-0 custom-mb-modal-bco">
                      Terminos y condiciones
                    </p>
                    <p className="m-0 text-right custom-mb-modal-bco">
                      Aceptado
                    </p>
                  </div>
                )}
                {mensajeAlertaDatosPost && (
                  <div className="alert alert-danger mt-2">
                    Hubo un problema por favor vuelva a intentarlo
                  </div>
                )}
              </section>
            </>
          )
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          //DATALAYER CONFIGURACIONES USUARIO : DATOS PERSONALES
          window.dataLayer.push({
            event: "button_click",
            description: "configuracion de actualizacion de datos personales",
            option: "aceptar",
          });
          setOpenModalToken(true);
          setShowModalInfo(false);
        }}
        btnPrimaryLoading={loadingBtnDatosPost}
        textBtnPrimary="Aceptar"
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalErrorToken
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </div>
  );
};

export default Datos;
