import React from "react";
import Divider from "../../../../components/Divider";
import InfoButton from "../../../../components/InfoButton";
import { montoFormat } from "../../../../helper/amountFormat";
import {
  MontoAlDiaDeHoyComponent,
  MontoDisponible,
  SubTitle,
  Title,
  Message,
} from "./styles";

const MontoAlDiaDeHoy = ({ montoTotal }) => {
  return (
    <MontoAlDiaDeHoyComponent>
      <Title>Retirar de fondo libre </Title>

      <SubTitle>
        <p>Al día de hoy tienes:</p>
        {/* <InfoButton>No hay informacion</InfoButton> */}
      </SubTitle>
      <MontoDisponible>{montoFormat(montoTotal, true)}</MontoDisponible>
      <Message>
        * Si luego de registrar su solicitud de retiro TOTAL ingresan nuevos
        aportes a su fondo voluntario sin fin, debe tener en cuenta que el
        retiro será sobre el saldo que se encuentre a la fecha de cargo.
      </Message>
      <Divider marginTop={"24px"} marginBottom={"28px"} />
    </MontoAlDiaDeHoyComponent>
  );
};

export default MontoAlDiaDeHoy;
