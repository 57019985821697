import React, { useReducer } from 'react';
import SeguimientoAportesContext from './seguimientoAportesContext';
import SeguimientoAportesReducer from './seguimientoAportesReducer';

import clienteAxios from '../../config/axios';

import {
    SEGUIMINETO_APORTES_CLEAN,
    SEGUIMINETO_APORTES_ERROR,
    SEGUIMINETO_APORTES_EXITOSO,
    SEGUIMINETO_APORTES_LOADING
} from '../../types/seguiminetoAportes';

const SeguimientoAportesState = props => {
    const initialState = {
        seguimientoAportes: null,
        mensajeAlerta: null,
        loadingPage: false
    };

    const obtenerSeguimientosAportes = async (cantidad = 0) => {
        dispatch({
            type: SEGUIMINETO_APORTES_LOADING,
            payload: true,
        });

        try {
            const respuesta = await clienteAxios.post('/fondo-libre/vsf/retiros-seguimiento', cantidad);
            dispatch({
                type: SEGUIMINETO_APORTES_EXITOSO,
                payload: respuesta.data
            });
        } catch (error) {
            if (error.message === 'Network Error') {
                dispatch({
                    type: SEGUIMINETO_APORTES_ERROR,
                    payload: {
                        code: 1, // error de red
                        msg: 'Su red presenta lentitud, por favor recargue la página.'
                    }
                })
            } else {
                if (error.response?.status === 500) {
                    dispatch({
                        type: SEGUIMINETO_APORTES_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'Error en los servicios, por favor recargue la página.'
                        }
                    })
                } else if (error.response?.status === 404) {
                    dispatch({
                        type: SEGUIMINETO_APORTES_ERROR,
                        payload: {
                            code: 1, // su token a expirado 
                            msg: 'No se encuentra servicio.'
                        }
                    })
                } else if (error.response?.data?.errors?.message === 'Unauthenticated.') {
                    dispatch({
                        type: SEGUIMINETO_APORTES_ERROR,
                        payload: {
                            code: 2, // su token a expirado 
                            msg: 'Su sesión ha expirado, por favor vuelva a iniciar sesión.'
                        }
                    })
                }
            }
        }
    };

    const limpiarSeguimientosAportes = () => {
        dispatch({
            type: SEGUIMINETO_APORTES_CLEAN
        })
    };

    const [state, dispatch] = useReducer(SeguimientoAportesReducer, initialState);
    return (
        <SeguimientoAportesContext.Provider
            value={{
                seguimientoAportes: state.seguimientoAportes,
                mensajeAlerta: state.mensajeAlerta,
                loadingPage: state.loadingPage,
                obtenerSeguimientosAportes,
                limpiarSeguimientosAportes
            }}
        >
            {props.children}
        </SeguimientoAportesContext.Provider>
    );
}

export default SeguimientoAportesState;