import styled from "styled-components";
import Modal from "../../../components/BasicModal";

export const ModalTitle = styled(Modal.Title)`
  text-align: left;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

export const ModalIcon = styled.div`
  width: 24px;
  height: 24px;
  display: grid;

  svg {
    width: 100%;
    height: 100%;
    color: ${(p) => p.theme.palette.state.error};
  }
`;

export const ModalTitleText = styled.div`
  text-align: left;
`;
