import styled, { css } from "styled-components";

export const RadioCardComponent = styled.label`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  box-shadow: ${(p) => p.theme.elevations.below};
  align-items: center;
  cursor: pointer;
  letter-spacing: 0px;
  background-color: ${(p) => p.theme.palette.white};
  color: ${(p) => p.theme.palette.grayscale[800]};
  grid-template-areas: "indicator body";

  ${(p) =>
    p.indicatorSide === "right" &&
    css`
      grid-template-areas: "body indicator";
      grid-template-columns: 1fr auto;
    `}

  input[type='radio'] {
    display: none;
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      color: ${(p) => p.theme.palette.grayscale[400]};
    `}

  ${(p) =>
    p.isChecked &&
    css`
      border-color: ${(p) => p.theme.palette.primary.main};
    `}

  .radio {
    grid-area: indicator;
    width: 24px;
    height: 24px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid ${(p) => p.theme.palette.grayscale["200"]};
    border-radius: 100px;

    ${(p) =>
      p.indicatorAlignment === "top" &&
      css`
        align-self: start;
      `}

    ${(p) =>
      p.indicatorAlignment === "bottom" &&
      css`
        align-self: end;
      `}

    ${(p) =>
      p.isChecked &&
      css`
        border-color: ${(p) => p.theme.palette.primary.main};
        background-color: ${(p) => p.theme.palette.primary.main};
      `}

    svg {
      position: relative;
      width: 12px;
      top: 1px;
      height: 12px;
      color: ${(p) => p.theme.palette.white};
      display: none;
      ${(p) =>
        p.isChecked &&
        css`
          display: block;
        `}
    }
  }

  .body {
    grid-area: body;
    margin-bottom: 0;
    font-family: "Roboto";
    color: ${(p) => p.theme.palette.grayscale[800]};
    ${(p) => p.theme.tipography.bodySmallRegular};
    justify-self: start;
  }
`;
