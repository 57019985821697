import React from "react";
import { NextButtonComponent } from "./styles";

const NextButton = ({ children, isBtnSecondaryisBtnSecondary, ...props }) => {
  return (
    <NextButtonComponent size="sm" isBtnSecondary {...props}>
      {children}
    </NextButtonComponent>
  );
};

export default NextButton;
