import React from "react";
import { ErrorMessageComponent } from "./styles";
import { useFormContext } from "react-hook-form";
import { ErrorMessage as ErrorMessageHookForm } from "@hookform/error-message";
import PropTypes from "prop-types";

const ErrorMessage = ({ name, absolute }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <ErrorMessageComponent absolute={absolute}>
      <ErrorMessageHookForm
        errors={errors}
        name={name}
        render={({ messages, ...props }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <span key={type}>{message} </span>
          ))
        }
      />
    </ErrorMessageComponent>
  );
};

export default ErrorMessage;

ErrorMessage.propTypes = {
  name: PropTypes.string,
  absolute: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  absolute: true,
};
