import React from "react";
import styled from "styled-components";
import { ButtonFL, ContentFlex, FlexItem } from "..";
import Pagination from "@mui/material/Pagination";
// import ArrowRight from "../../../../assets/icons/chevron_right.svg";
import ArrowRight from "../../../../assets/IconBucket/Icon/chevron_right";
import ArrowLeft from "../../../../assets/icons/chevron_left.svg";
const DivContent = styled("div")`
  background: #ffffff;
  width: 100%;

  /* 100% - HeigthBottom - HeigthBottomBar - heigthPaddings  - padding */
  top: calc(100% - 40px - 89px - 16px - 32px);
  zindex: 9;
  padding: 8px 0px;
`;
const DivPagination = styled(Pagination)`
  & .MuiPagination-ul {
    background-color: #ffebef;
    border-radius: 90px;
    color: #6e7378;
  }

  & .MuiPaginationItem-root.Mui-selected {
    background: #ce1336;
    border-radius: 90px;
    color: #fff;
  }
  & .MuiPaginationItem-root.Mui-selected:hover {
    background: #ce1336;
    border-radius: 90px;
    color: #fff;
  }
`;
const BottomButtons = ({
  firstText = "Regresar",
  secondText = "Continuar",
  firstOnClick,
  secondOnClick,
  active,
  width = "100%",
  page,
  onChange,
  isMobile,
  disabled = true,
}) => {
  return (
    <DivContent>
      <ContentFlex
        padding="0px"
        color="#ffffff"
        style={{
          width: width,
        }}
      >
        <FlexItem
          padding={isMobile ? 0 : "16px 8px 16px 16px"}
          isMobile={isMobile}
          size={"unset"}
        >
          <ButtonFL
            active={active}
            isMobile={isMobile}
            onClick={firstOnClick}
            width="40px"
          >
            {/* {isMobile ? <img src={ArrowLeft} alt="ArrowLeft" /> : firstText} */}
            <img src={ArrowLeft} alt="ArrowLeft" />
          </ButtonFL>
        </FlexItem>
        <FlexItem>
          <DivPagination
            count={4}
            hidePrevButton
            hideNextButton
            page={page}
            onChange={onChange}
          />
        </FlexItem>
        <FlexItem
          padding={isMobile ? 0 : "16px 8px 16px 16px"}
          isMobile={isMobile}
          size={"unset"}
        >
          <ButtonFL
            // primary
            disable={disabled}
            isMobile={isMobile}
            onClick={page === 4 ? secondOnClick : null}
            width="40px"
          >
            {/* {isMobile ? (
              <ArrowRight color={disabled ? "#51565F" : "#CE1336"} />
            ) : (
              secondText
            )} */}

            <ArrowRight color={disabled ? "#51565F" : "#CE1336"} />
          </ButtonFL>
        </FlexItem>
      </ContentFlex>
    </DivContent>
  );
};

export default BottomButtons;
