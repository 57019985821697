import React, { useState, useEffect, useContext } from "react";
import {
  SelecionarMonto,
  MontoRetirar,
  SelecionarAporteVoluntarioConFin,
  RetiroAlerta,
} from "./components";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
export default function Retiro(props) {
  const { apvf, contentId, resetear } = props;

  const [showView, setShowView] = useState(1);
  const [selectModeDecision, setSelectModeDecision] = useState(false);
  const [selectMode, setSelectMode] = useState(
    Number(props.dependencies.contribution.obligatory.replace(/,/g, "")) === 0
      ? null
      : true
  );
  const [selectModeForApv, setselectModeForApv] = useState(null);
  const [myMoney, setMyMoney] = useState(10000);
  const [amount, setAmount] = useState("");
  const [amountForHabitat, setAmountForHabitat] = useState("");
  const [disclaimer, setDsiclaimer] = useState(false);
  const [autorization, setAutorization] = useState(false);
  const [despositInFondoHabitat, setDespositInFondoHabitat] = useState(false);
  const [fondoHabitat, setFondoHabitat] = useState(-1);
  const [amountRetire, setAmountRetire] = useState("");
  const [amountTotal, setAmounttotal] = useState("");
  const [disclaimer2, setDsiclaimer2] = useState(false);

  const [checkUseVoluntaryWithEnd, setCheckUseVoluntaryWithEnd] = useState(
    Number(props.dependencies.contribution.voluntary.replace(/,/g, "")) !== 0 &&
      Number(props.dependencies.contribution.obligatory.replace(/,/g, "")) === 0
      ? true
      : false
  );
  const jubilacionContext = useContext(JubilacionContext);
  const { guardarAvanceJuilacionData } = jubilacionContext;
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (apvf) {
    //   setSelectModeDecision(apvf);
    // }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <SelecionarMonto
            prevView={() => props.getOutOfProcess()}
            nextView={() => setShowView(2)}
            nextApvConfin={() => setShowView(3)}
            dependencies={props.dependencies}
            selectModeDecision={selectModeDecision}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            selectModeForApv={selectModeForApv}
            setselectModeForApv={setselectModeForApv}
            myMoney={myMoney}
            setMyMoney={setMyMoney}
            amount={amount}
            setAmount={setAmount}
            disclaimer={disclaimer}
            setDsiclaimer={setDsiclaimer}
            autorization={autorization}
            setAutorization={setAutorization}
            disclaimer2={disclaimer2}
            setDsiclaimer2={setDsiclaimer2}
            despositInFondoHabitat={despositInFondoHabitat}
            setDespositInFondoHabitat={setDespositInFondoHabitat}
            amountForHabitat={amountForHabitat}
            setAmountForHabitat={setAmountForHabitat}
            fondoHabitat={fondoHabitat}
            setFondoHabitat={setFondoHabitat}
            setAmountRetire={setAmountRetire}
            setAmounttotal={setAmounttotal}
          />
        );
      case 2:
        return (
          <MontoRetirar
            prevView={() => setShowView(1)}
            endProcess={props.endProcess}
            endProcessError={props.endProcessError}
            checkUseVoluntaryWithEnd={checkUseVoluntaryWithEnd}
            dependencies={props.dependencies}
            processId={props.processId}
            contentId={contentId}
            selectMode={selectMode}
            amountRetire={amountRetire}
            selectModeForApv={selectModeForApv}
            myMoney={myMoney}
            amount={amount}
            amountForHabitat={amountForHabitat}
            fondoHabitat={fondoHabitat}
            despositInFondoHabitat={despositInFondoHabitat}
            autorization={autorization}
            amountTotal={amountTotal}
            disclaimer2={disclaimer2}
            apvf={apvf}
            showView={() => {
              setShowView(4);
              resetear();
            }}
          />
        );
      case 3:
        return (
          <SelecionarAporteVoluntarioConFin
            prevView={() => setShowView(1)}
            nextView={() => setShowView(2)}
            dependencies={props.dependencies}
            checkUseVoluntaryWithEnd={checkUseVoluntaryWithEnd}
            setCheckUseVoluntaryWithEnd={setCheckUseVoluntaryWithEnd}
            selectModeDecision={selectModeDecision}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            amountRetire={amountRetire}
            selectModeForApv={selectModeForApv}
            setselectModeForApv={setselectModeForApv}
            myMoney={myMoney}
            setMyMoney={setMyMoney}
            amount={amount}
            setAmount={setAmount}
            disclaimer={disclaimer}
            setDsiclaimer={setDsiclaimer}
            autorization={autorization}
            setAutorization={setAutorization}
            disclaimer2={disclaimer2}
            setDsiclaimer2={setDsiclaimer2}
            despositInFondoHabitat={despositInFondoHabitat}
            setDespositInFondoHabitat={setDespositInFondoHabitat}
            amountForHabitat={amountForHabitat}
            setAmountForHabitat={setAmountForHabitat}
            fondoHabitat={fondoHabitat}
            setFondoHabitat={setFondoHabitat}
            setAmountRetire={setAmountRetire}
            setAmounttotal={setAmounttotal}
          />
        );
      case 4:
        return (
          <RetiroAlerta
            error={guardarAvanceJuilacionData}
            showDetail={() => {
              setShowView(2);
              resetear();
            }}
          />
        );
      default:
        return <p></p>;
    }
  };

  return <div>{renderView()}</div>;
}
