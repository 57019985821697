import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import whiteArrowDown from "../../../../../../../assets/icons/white-down-arrow.svg";

export default function DrawerOptionCollapseMode(props) {
  const {
    id,
    title,
    url,
    icon,
    iconHover,
    altIcon,
    subOptions = null,
    subMenuOptions,
    setOpenDrawer,
    optionSelected,
    setOptionSelected,
    handleClose,
    location,
    connectEtario,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (id === optionSelected) setIsOpen(true);
    else
      setIsOpen(
        url
          ? window.location.href.includes(url) && subMenuOptions == null
          : false
      );
  }, [optionSelected, url, id, subMenuOptions]);

  return (
    <div className="collapse-option-menu content-padding">
      {subOptions ? (
        <div
          className={`option-menu-container ${isOpen ? "selected" : ""} ${
            isOpen && subOptions ? "options" : ""
          } `}
          onClick={() => {
            if (id === "logout") {
              handleClose();
            }
            connectEtario(false);
            if (isOpen) {
              setOptionSelected("");
            } else {
              setOptionSelected(id);
            }
          }}
        >
          {isOpen && subOptions ? (
            <img className="icon-menu" src={iconHover} alt={altIcon} />
          ) : (
            <img className="icon-menu" src={icon} alt={altIcon} />
          )}
        </div>
      ) : (
        <div
          className={`option-menu-container ${isOpen ? "selected" : ""} ${
            isOpen && subOptions ? "options" : ""
          } `}
          onClick={() => {
            setOpenDrawer(false);
            history.push(url);
            connectEtario(false);
            if (id === "logout") {
              handleClose();
            }
            if (isOpen) {
              setOptionSelected("");
            } else {
              setOptionSelected(id);
            }
          }}
        >
          {isOpen && url === location.pathname ? (
            <img className="icon-menu" src={iconHover} alt={altIcon} />
          ) : (
            <img className="icon-menu" src={icon} alt={altIcon} />
          )}
        </div>
      )}

      {isOpen && subOptions && (
        <div className="collapse-menu-option-container">
          <div className="title-block">
            <span>{title}</span>
            <img src={whiteArrowDown} alt="white-arrow-down" />
          </div>

          <div
            className="option-block"
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            {subOptions.map((option, index) => (
              <div key={`suboption-${option.id}`} className="option-menu">
                <Link to={option.url}>
                  <span>{option.title}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
