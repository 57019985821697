import ArrowUpWithDollar from "../assets/icons/arrow-up-with-dollar.svg";
import Moneybag from "../assets/icons/money-bag.svg";
import FileWithArrow from "../assets/icons/file-with-arrow.svg";
import homeIcon from "../assets/home-icon.svg";
import walletIcon from "../assets/wallet-icon.svg";
import graphicIcon from "../assets/graphic-icon.svg";
import saveMoneyIcon from "../assets/save-money-icon.svg";
import casaIcon from "../assets/icons/icon-casa.svg";
import carteraIcon from "../assets/icons/icon-cartera.svg";
import inverIcon from "../assets/icons/icon-inver.svg";
import alcanciaIcon from "../assets/icons/icon-alcancia.svg";
import informationIconHover from "../assets/icons/information-menu.svg";
import informationIcon from "../assets/icons/information-menu-dark.svg";
import tfeIcon from "../assets/icons/retiroTfe-red.svg";
import tfeIconRed from "../assets/icons/retiroTfe.svg";
import documentTramite from "../assets/ic_document_tramite.svg";
import documentTramiteWhite from "../assets/ic_document_tramite_white.svg";
/* imagenes/iconos SVG de las CARDS */
import NIVEL_RIESGO_F1 from "../assets/IconBucket/NIVEL_RIESGO_F1.svg";
import RENTABILIDAD_F1 from "../assets/IconBucket/RENTABILIDAD_F1.svg";
import NIVEL_RIESGO_F2 from "../assets/IconBucket/NIVEL_RIESGO_F2.svg";
import RENTABILIDAD_F2 from "../assets/IconBucket/RENTABILIDAD_F2.svg";
import NIVEL_RIESGO_F3 from "../assets/IconBucket/NIVEL_RIESGO_F3.svg";
import RENTABILIDAD_F3 from "../assets/IconBucket/RENTABILIDAD_F3.svg";

import VALORES_CUOTA from "../assets/valor_cuota_16px.svg";
import VALORES_CUOTA_WHITE from "../assets/valor_cuota_white_16px.svg";

export const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

export const PRODUCTS = [
  {
    id: 0,
    name: "Fondo de Jubilación",
  },
  {
    id: 1,
    name: "Fondo Libre Habitat",
  },
  {
    id: 2,
    name: "Aportes Voluntarios con fin Previsional",
  },
];

export const TYPE_FUNDS = [
  {
    id: 0,
    name: "Fondo 1",
  },
  {
    id: 1,
    name: "Fondo 2",
  },
  {
    id: 2,
    name: "Fondo 3",
  },
];

export const LINK_OF_INTERES = [
  // {
  //   id: 0,
  //   title: "Fondo Libre Habitat",
  //   text: "Invierte y retira cuando quieras.",
  //   btnText: "Invertir",
  //   icon: ArrowUpWithDollar,
  //   alt: "arrow-dolar",
  //   className: "bg-light-green",
  //   url: "/mi-habitat-digital/afiliado/apv/fondo-libre-habitat",
  // },
  {
    id: 1,
    title: "Jubílate 100% online",
    text: "Hazlo desde donde estés.",
    btnText: "Iniciar",
    icon: FileWithArrow,
    alt: "file-with-arrow",
    className: "bg-light-purple",
    url: "/mi-habitat-digital/afiliado/jubilacion",
  },
  {
    id: 2,
    title: "Estado de cuenta",
    text: "Conoce el estado de tus inversiones.",
    btnText: "Consultar",
    icon: Moneybag,
    alt: "money-bag",
    className: "bg-light-red",
    url: "/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual",
  },
];

export const optionMenuArray = [
  {
    id: "home",
    title: "Inicio",
    icon: homeIcon,
    iconHover: casaIcon,
    altIcon: "home-icon",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/inicio",
  },
  {
    id: "myFund",
    title: "Mis Fondos",
    icon: walletIcon,
    iconHover: carteraIcon,
    altIcon: "wallet-icon",
    url: "",
    subMenu: [
      {
        id: "sub-estado-mensual",
        title: "Estado de cuenta mensual",
        url: "/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual",
      },
      {
        id: "sub-ultimos",
        title: "Últimos movimientos",
        url: "/mi-habitat-digital/afiliado/mis-fondos/ultimos-movimientos",
      },
      {
        id: "sub-cambio",
        title: "Cambio de tipo de fondo",
        url: "/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo",
      },
      {
        id: "sub-historico",
        title: "Comisión histórica",
        url: "/mi-habitat-digital/afiliado/mis-fondos/comision-historica",
      },
      {
        id: "sub-estado-no-aportante",
        title: "Estado de cuenta no aportante",
        url: "/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-no-aportante",
      },
      {
        id: "sub-evolucion",
        title: "Evolución de tu fondo",
        url: "/mi-habitat-digital/afiliado/mis-fondos/evolucion-de-mi-fondo",
      },
    ],
  },
  {
    id: "apvf",
    title: "Fondo Libre Habitat",
    icon: graphicIcon,
    iconHover: inverIcon,
    altIcon: "graphic-icon",
    url: "",
    subMenu: [
      {
        id: "sub-realizar-aporte",
        title: "Invertir",
        url: "/mi-habitat-digital/afiliado/apv/fondo-libre-habitat",
      },
      {
        id: "sub-seguimiento",
        title: "Seguimiento de aportes",
        url: "/mi-habitat-digital/afiliado/seguimiento/apv",
      },
      {
        id: "sub-retiro",
        title: "Realizar un retiro",
        url: "/mi-habitat-digital/afiliado/apv/retiro-aporte-voluntario-sin-fin",
      },
      {
        id: "debito-mensual",
        title: "Débito Mensual",
        url: "/mi-habitat-digital/afiliado/apv/debito-mensual",
      },
    ],
  },
  {
    id: "retTfe",
    title: "Retiro",
    icon: tfeIcon,
    iconHover: tfeIconRed,
    altIcon: "graphic-icon",
    url: "",
    subMenu: [
      {
        id: "sub-credito",
        title: "Retiro del 25%",
        url: "/mi-habitat-digital/afiliado/retiro/25",
      },
      {
        id: "sub-exterior",
        title: "Transferencia de fondos al exterior",
        url: "/mi-habitat-digital/afiliado/retiro/transferencia-fondos-exterior",
      },
    ],
  },
  {
    id: "information",
    title: "Mi Información",
    icon: informationIcon,
    iconHover: informationIconHover,
    altIcon: "graphic-icon",
    url: "",
    subMenu: [
      {
        id: "sub-boleta",
        title: "Boleta de pensión",
        url: "/mi-habitat-digital/afiliado/informacion/boleta-pago",
      },
      {
        id: "sub-certificado-afiliacion",
        title: "Certificado de afiliación",
        url: "/mi-habitat-digital/afiliado/informacion/drspp",
      },
      {
        id: "sub-comprobante",
        title: "Comprobante de pago SUNAT",
        url: "/mi-habitat-digital/afiliado/informacion/boleta-digital",
      },
      {
        id: "sub-aportes",
        title: "Deuda de tu empleador",
        url: "/mi-habitat-digital/afiliado/informacion/pagos-pendientes",
      },
    ],
  },
  {
    id: "benefits",
    title: "Beneficios y Jubilación",
    icon: saveMoneyIcon,
    iconHover: alcanciaIcon,
    altIcon: "save-money-icon",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/jubilacion",
  },
  {
    id: "estadosTramites",
    title: "Estado de trámites",
    icon: documentTramite,
    iconHover: documentTramiteWhite,
    altIcon: "ic_document_tramite",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/estado-de-tramite",
  },
  {
    id: "valorCuota",
    title: "Valores cuota",
    icon: VALORES_CUOTA,
    iconHover: VALORES_CUOTA_WHITE,
    altIcon: "ic_document_tramite",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/valor-cuota",
  },
];
export const ARRAY_VALUES_SEGMENTOS = ["T", "A", "B", "C", "D"];

export const ARRAY_CUSPP = [
  "346101GMPML8",
  "576290AJBAB1",
  "584731DMBRS8",
  "627951ECZTB8",
  "243511GCADZ1",
  "602531JZPAO0",
  "229181MAJAA5",
  "561500RDMZZ2",
  "574922MSNOA7",
  "573240IBSNA2",
  "611460VVSAI0",
  "650890NPCRS1",
  "611690SGTZR5",
  "598781JMATE0",
  "242840MVTAR6",
  "584750CGCCD0",
  "581830SRERI8",
  "551730MTROR0",
  "574320LVVAN4",
  "601670TLSRO2",
  "276130JLMGH4",
  "550431AGGZB0",
  "580091LNNRR1",
  "245480MVGDZ7",
  "566661MVMUD9",
  "580701CJTMI0",
  "260971CABAR6",
  "529710JSCUU0",
  "550510CPRCC5",
  "586490GUVOE0",
  "242141MMTDR2",
  "564461OHCRA1",
  "587850MGZZO6",
  "582581VDJOR9",
  "567371DVVLQ3",
  "614351MTCAT8",
  "619440MCLER5",
  "551800ANHOT0",
  "585320LQPNA2",
  "599540SOUNB5",
  "267360TGCID2",
  "565430CKVNL5",
  "250740MTPII5",
  "530310MAFOL1",
  "569510CGCZR9",
  "642400YDCGD3",
  "255080MBGTS6",
  "582170ADCZO1",
  "582380VBAAE8",
  "268630CSMII8",
  "566310MVLCO9",
  "621410ARTAR3",
  "595331HGSRW0",
  "556940IHRZT5",
  "561500RDMZZ2",
  "238120GBMIT5",
  "573680SELIU4",
  "575620JPCEZ5",
  "567290MVAAA8",
  "611711DGSUO0",
  "259810MVCAT0",
  "258211JPSOC4",
  "569120RSPSL7",
  "575570CAAAM4",
  "614070LBCND0",
  "594110CLHOT6",
  "592330KLPEU6",
  "271580SMMIA8",
  "579340FOTER7",
  "570800AYZOA0",
  "597720COULB0",
  "599720BHMTA0",
  "264920MOOSI7",
  "615460APTAL2",
  "562840MREEO3",
  "267700TMCIT9",
  "609771RFMRY5",
  "255611ASYON5",
  "569590MLSWI5",
  "628370GEFAR3",
  "587610GLGEO1",
  "576260KMPAA1",
  "579910PRMOA9",
  "616990JGRGU7",
  "572930MACRT5",
  "261860LRSSM0",
  "608050KVHQO8",
  "579260KBDHG5",
  "611570DRFAN8",
  "606210MMYIG0",
  "255640LVSAI5",
  "578530NULAF8",
  "592710LLLNN0",
  "600470MRVRD9",
  "255540CREEO8",
  "256700SLCAT6",
  "578750TSSCV6",
  "606480JPDRZ1",
  "250000JDPGO1",
  "593960MTVRG2",
  "640331JMPML7", //eliminar antes de pasar
];

export const PRIMER_URL = [
  {
    id: "mis-fondos",
    title: "Mis fondos",
    ruta: "",
  },
  {
    id: "informacion",
    title: "Mis información",
    ruta: "",
  },
  {
    id: "jubilacion",
    title: "Jubilación",
    ruta: "",
  },
  {
    id: "estado-de-tramite",
    title: "Estado de trámite",
    ruta: "",
  },
  {
    id: "apv",
    title: "Fondo Libre Habitat",
    ruta: "",
  },
  {
    id: "seguimiento",
    title: "Fondo Libre Habitat",
    ruta: "",
  },
];

export const SEGUNDO_URL = [
  {
    id: "estado-cuenta-mensual",
    title: "Estado de cuenta mensual",
    ruta: "",
  },
  {
    id: "cambio-de-fondo",
    title: "Cambio de fondo",
    ruta: "",
  },
  {
    id: "ultimos-movimientos",
    title: "Últimos movimientos",
    ruta: "",
  },
  {
    id: "comision-historica",
    title: "Comisión histórica",
    ruta: "",
  },
  {
    id: "estado-cuenta-no-aportante",
    title: "Estado de cuenta no aportante",
    ruta: "",
  },
  {
    id: "evolucion-de-mi-fondo",
    title: "Evolución de mi fondo",
    ruta: "",
  },
  {
    id: "retiro-aporte-voluntario-sin-fin",
    title: "Realizar un retiro",
    ruta: "",
  },
  {
    id: "transferencia-fondos-exterior",
    title: "Transferencia de fondos al exterior",
    ruta: "",
  },
  {
    id: "25",
    title: "Retiro del 25%",
    ruta: "",
  },
  {
    id: "apv",
    title: "Seguimiento de tu aporte",
    ruta: "",
  },
  {
    id: "aporte-voluntario-sin-fin",
    title: "Invertir",
    ruta: "",
  },
  {
    id: "datos-personales",
    title: "Datos personales",
    ruta: "",
  },
  {
    id: "clave-web",
    title: "Clave web",
    ruta: "",
  },
  {
    id: "cuentas-bancarias",
    title: "Cuentas bancarias",
    ruta: "",
  },
  {
    id: "boleta-pago",
    title: "Boleta de pago",
    ruta: "",
  },
  {
    id: "drspp",
    title: "Certificado de afiliación",
    ruta: "",
  },
  {
    id: "Boleta-digital",
    title: "Comprobante de pago",
    ruta: "",
  },
  {
    id: "pagos-pendientes",
    title: "Deuda de empleador",
    ruta: "",
  },
];

export const INFORMACION_PERSONAL = [
  {
    id: "datos",
    title: "Datos personales",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/datos-personales",
  },
  {
    id: "clave",
    title: "Clave Web",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/clave-web",
  },
  {
    id: "cuentas",
    title: "Cuentas bancarias",
    subMenu: null,
    url: "/mi-habitat-digital/afiliado/informacion/cuentas-bancarias",
  },
  {
    id: "sesion",
    title: "Cerrar sesión",
    subMenu: null,
    url: "",
  },
];

// Mobile S - 320px
// Mobile M - 375px
// Mobile L - 425px
// Tablet - 768px
// Laptop - 1024px
// Laptop L - 1440px
// 4K - 2560px

export const size = {
  mobileS: "320px",
  mobileL: "425px",
  tablet: "744px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1920px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const considerationsFatca = [
  { id: 0, text: "(1) Naciste en EE. UU." },
  {
    id: 1,
    text: "(2) Tienes obligaciones fiscales en EE. UU. o en otro país.",
  },
  {
    id: 2,
    text: "(3) Tienes nacionalidad americana.",
  },
  {
    id: 3,
    text: "(4) Tienes permiso de residir en EE. UU.",
  },
];

export const InfoCard = [
  {
    id: 1,
    title: "Fondo 1",
    subTitle: " Conservador",
    rentabilidad: "Baja",
    riesgo: "Baja",
    riesgoImg: NIVEL_RIESGO_F1,
    rentabilidadImg: RENTABILIDAD_F1,
    name: `Fondo 1`,
  },
  {
    id: 2,
    title: "Fondo 2",
    subTitle: " Moderado",
    rentabilidad: "Moderada",
    riesgo: "Moderada",
    riesgoImg: NIVEL_RIESGO_F2,
    rentabilidadImg: RENTABILIDAD_F2,
    name: `Fondo 2`,
  },

  {
    id: 3,
    title: "Fondo 3",
    subTitle: " Arriesgado",
    rentabilidad: "Alta",
    riesgo: "Alta",
    riesgoImg: NIVEL_RIESGO_F3,
    rentabilidadImg: RENTABILIDAD_F3,
    name: `Fondo 3`,
  },
];

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  iconBlue: "#0043CE",
  disabledText: "#CDCED0",
  disabledBg: "#F8F9FB",
};
