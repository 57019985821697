import React from 'react'

const GraficTwoActiveIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 85 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7609 13.1384L23.9211 27.4973C28.2966 22.0818 34.9914 18.6178 42.4993 18.6178V0C30.4088 0 19.4992 5.04416 11.7609 13.1384Z"
        fill="#CE1336"
      />
      <path
        d="M-0.000793457 42.4581H18.6354C18.6354 36.7912 20.616 31.5884 23.9208 27.4978L11.7606 13.1389C4.47338 20.7613 -0.000793457 31.0875 -0.000793457 42.4581Z"
        fill="#CE1336"
      />
      <path
        d="M42.4992 0V18.6178C49.9704 18.6178 56.64 22.0489 61.0154 27.4199L73.1698 13.0688C65.4373 5.01515 54.5549 0 42.4992 0Z"
        fill="#CDCED0"
      />
      <path
        d="M84.9984 42.4572C84.9984 31.0517 80.4952 20.6965 73.1692 13.0684L61.0148 27.4195C64.3564 31.5217 66.3621 36.7554 66.3621 42.4572H84.9984Z"
        fill="#CDCED0"
      />
      <path
        d="M45.2663 38.2548L44.1635 35.5737C43.7505 34.5699 42.3542 34.4934 41.8393 35.4472L40.2883 38.3197C39.9632 38.463 39.6466 38.633 39.3402 38.8358C36.4133 40.7731 35.6198 44.7164 37.5681 47.6413C39.5164 50.5662 43.4703 51.3672 46.3972 49.4299C49.324 47.4927 50.1176 43.5494 48.1693 40.6245C47.4329 39.519 46.4115 38.7182 45.2669 38.2521L45.2663 38.2548Z"
        fill="#CDCED0"
      />
    </svg>
  );
};

export default GraficTwoActiveIcon;