import React from "react";
import "./error.css";

const ErrorMessage = (props) => {
  const { mensaje, mensajeletra, handleOpenModal } = props;
  const listErrores = [
    "La clave ingresada no cumple con las consideraciones indicadas.",
    "No utilices números consecutivos: Ejemplo 4321, 5678",
    "No utilices números únicos: Ejemplo 1111, 2222",
    "Las claves no coinciden",
  ];
  return (
    <>
      {mensaje ==
      "La clave ingresada no cumple con las consideraciones indicadas." ? (
        <>
          <p
            className={`text-component-error mb-0 ${
              mensajeletra ? "small " : ""
            }`}
          >
            La clave ingresada no cumple con las consideraciones indicadas:
          </p>
        </>
      ) : (
        <p
          className={`text-component-error mb-0 ${
            mensajeletra ? "small " : ""
          }`}
        >
          {mensaje}
        </p>
      )}
      {listErrores.includes(mensaje) && (
        <ul className={`ml-4 ${mensajeletra ? "small " : ""}`}>
          <li>
            La clave debe ser <b>numérica</b>.
          </li>
          <li>
            No puede contener números consecutivos como <b>1234, 4321,</b> etc.
          </li>
          <li>
            Debe contener números diferentes, sin repeticiones como{" "}
            <b>1111, 2222,</b> etc.
          </li>
          <li>No debe ser igual a tu anterior clave.</li>
        </ul>
      )}
    </>
  );
};

export default ErrorMessage;
