import React, { useState, useEffect } from "react";
import clienteAxios from "../../../config/axios";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const useAporteMensualRequest = () => {
  const [aporteMensual, setAporteMensual] = useState(initialState);

  useEffect(() => {
    const request = async () => {
      setAporteMensual({ ...aporteMensual, loading: true });

      try {
        const response = await clienteAxios.get("/fondo-libre/aporte-mensual");

        return setAporteMensual({ ...initialState, data: response.data });
      } catch (error) {
        const response = error?.response;

        return setAporteMensual({ ...initialState, error: response.data });
      }
    };

    request();
  }, []);

  return aporteMensual;
};
