import React from "react";
import { useHistory } from "react-router";
import { Button } from "../../../../../../../../../../components";
import { Box } from "@mui/material";
import { ContentResumenValidar } from "../../../../../../styles/Decision";
import QuickReferenceAll from "../../../../../../../../../../assets/IconBucket/QuickReferenceAll";
import Alert from "../../../../../../../../../../components/Alert";

export default function Index(props) {
  const history = useHistory();

  return (
    <div className="detalle-info-container residencia">
      <Box>
        <ContentResumenValidar>
          <QuickReferenceAll />
          <h2>Validaremos tus documentos</h2>
          <Box className="HBT_JUBLEGAL_TextValidacion">
            Si todo está en orden, nos contactaremos contigo en un plazo no
            mayor a 5 días hábiles, a través de tu correo o celular, con el fin
            de continuar con tu solicitud de Pensión Objetivo.
          </Box>

          <Alert
            description={
              "Recuerda tener tus datos de contacto actualizados y revisar en tu correo la carpeta de spam o no deseados."
            }
            variant="warning"
          />
        </ContentResumenValidar>
      </Box>

      <div className="container-reja-buttons">
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => history.push("/mi-habitat-digital/afiliado/inicio")}
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
}
