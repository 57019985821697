import styled from "styled-components";

export const CheckboxWrapper = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  width: fit-content;
  align-items: center;

  ${(p) => p.theme.tipography.bodyMediumRegular}

  .native-checkbox {
    display: none;
  }

  .MuiButtonBase-root {
    color: ${(p) => p.theme.palette.grayscale[200]};
    padding: 0;

    &.Mui-checked {
      color: ${(p) => p.theme.palette.primary.main};
      &.Mui-disabled {
        color: ${(p) => p.theme.palette.grayscale.blocked};
      }
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
`;

export const LabelText = styled.p`
  justify-self: start;
  ${(p) => p.theme.tipography.bodyMediumRegular}
  margin-bottom: 0;
`;
