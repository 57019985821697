export const monedas = {
  labelText: "Moneda de la cuenta",
  opciones: [
    { codigo: "1", nombre: "SOLES" },
    { codigo: "2", nombre: "DOLARES" },
  ],
  defaultSelect: "1",
};

export const meses = [
  {
    code: "01",
    mes: "Enero",
  },
  {
    code: "02",
    mes: "Febrero",
  },
  {
    code: "03",
    mes: "Marzo",
  },
  {
    code: "04",
    mes: "Abril",
  },
  {
    code: "05",
    mes: "Mayo",
  },
  {
    code: "06",
    mes: "Junio",
  },
  {
    code: "07",
    mes: "Julio",
  },
  {
    code: "08",
    mes: "Agosto",
  },
  {
    code: "09",
    mes: "Setiembre",
  },
  {
    code: "10",
    mes: "Octubre",
  },
  {
    code: "11",
    mes: "Noviembre",
  },
  {
    code: "12",
    mes: "Diciembre",
  },
];

export const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;