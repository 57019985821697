import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Affiliate,
  AffiliateAvatar,
  AffiliateDocument,
  AffiliateName,
  AffiliateSubtitle,
  AffiliateWrapper,
  CaptchaWrapper,
  Divider,
  FormWrapper,
  PasoDatosDelAfiliadoComponent,
  ResponseRequestWrapper,
  SearchButton,
} from "./styles";
import SectionTitle from "../components/SectionTitle";
import * as Input from "../../../components/Input";
import { useFormContext } from "react-hook-form";
import Alert from "../../../components/Alert";
import { useGastoDeSepelioContext } from "../context";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../components/ButtonsComponents";
import getDocumentName from "../utils/getDocumentName";

const PasoDatosDelAfiliado = ({ handleStepNavigation }) => {
  const {
    state: { affiliate, token },
    getAffiliateInfo,
    resetAffiliateInfo,
  } = useGastoDeSepelioContext();

  const [captchaR, setCaptchaR] = useState(null); //null

  const {
    watch,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  register("buscar-afiliado.afiliado-encontrado", { value: null });

  const onContinue = () => handleStepNavigation("next");
  const onPrev = () => handleStepNavigation("previous");

  var canSearch =
    watch("buscar-afiliado.identification-type") &&
    watch("buscar-afiliado.identification-value") &&
    watch("buscar-afiliado.birthday") &&
    watch("buscar-afiliado.captcha") &&
    !errors["buscar-afiliado"];

  useEffect(() => {
    resetAffiliateInfo();
  }, [
    watch("buscar-afiliado.identification-type"),
    watch("buscar-afiliado.identification-value"),
    watch("buscar-afiliado.birthday"),
  ]);

  const searchButtonHandler = () => {
    getAffiliateInfo({
      doc_type: getValues("buscar-afiliado.identification-type"),
      doc_num: getValues("buscar-afiliado.identification-value"),
      fecha_nacimiento: getValues("buscar-afiliado.birthday"),
      'g-recaptcha-response': captchaR,
      token
    });
  };

  const onChangeCaptcha = (res) =>{
    setCaptchaR(res)

  }

  const history = useHistory();

  return (
    <PasoDatosDelAfiliadoComponent>
      <SectionTitle>Buscar afiliado</SectionTitle>
      <FormWrapper>
        <Input.Wrapper>
          <Input.Title required={false}>Documento de identidad</Input.Title>
          <Input.Document
            documentProps={{ name: "buscar-afiliado.identification-type" }}
            valueProps={{ name: "buscar-afiliado.identification-value" }}
          />
          <Input.ErrorMessage name="buscar-afiliado.identification-value" />
        </Input.Wrapper>
        <Input.Wrapper>
          <Input.Title required={false}>Fecha de nacimiento</Input.Title>
          <Input.Date name="buscar-afiliado.birthday" />
          <Input.ErrorMessage name="buscar-afiliado.birthday" />
        </Input.Wrapper>
        <CaptchaWrapper>
          <Input.Captcha name="buscar-afiliado.captcha" onChange2={onChangeCaptcha} />
          <Input.ErrorMessage name="buscar-afiliado.captcha" />
        </CaptchaWrapper>
        <SearchButton
          fullWidth={true}
          variant="secondary"
          onClick={searchButtonHandler}
          disabled={!canSearch || affiliate?.status === "loading"}
          size="lg"
        >
          {affiliate?.status === "loading" ? "Buscando" : "Buscar"}
        </SearchButton>
      </FormWrapper>
      <Divider marginTop="0" />
      <ResponseRequestWrapper>
        {!affiliate.error && !affiliate.data && (
          <>
            <span className="noSearch">No has buscado un afiliado aún</span>
          </>
        )}
        {affiliate.error && (
          <Alert
            title={
              affiliate.error ===
              "Ya existe una solicitud para gastos de sepelio en proceso."
                ? "Afiliado con trámite en proceso"
                : "Afiliado no encontrado"
            }
            description={
              affiliate.error ===
              "Ya existe una solicitud para gastos de sepelio en proceso."
                ? "No puedes solicitar otro reembolso por Sepelio hasta que sea concluido."
                : "Revisa si el DNI o la fecha de nacimiento están correctos. Puedes buscar a qué AFP pertenece en la SBS."
            }
            action={
              affiliate.error ===
              "Ya existe una solicitud para gastos de sepelio en proceso." ? (
                <span>Buscar trámite</span>
              ) : (
                <span>Buscar en la SBS</span>
              )
            }
            onAction={() => {
              affiliate.error ===
              "Ya existe una solicitud para gastos de sepelio en proceso."
                ? history.push("/zona-beneficiarios/buscar-tramite")
                : window.open(
                    "https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx",
                    "_blank"
                  );
            }}
            variant={"error"}
          />
        )}
        {affiliate?.data && (
          <Affiliate>
            <AffiliateSubtitle>Afiliado encontrado:</AffiliateSubtitle>
            <AffiliateWrapper>
              <AffiliateAvatar>
                {affiliate?.data?.aliasAfiliado}
              </AffiliateAvatar>
              <AffiliateName>{affiliate?.data?.nombreAfiliado}</AffiliateName>
              <AffiliateDocument>
                {getDocumentName(affiliate?.data?.doctype)}:{" "}
                {affiliate?.data?.docnum}
              </AffiliateDocument>
            </AffiliateWrapper>
          </Affiliate>
        )}
      </ResponseRequestWrapper>

      <ButtonsWrapper>
        <PrevButton onClick={onPrev}>Volver</PrevButton>
        <NextButton disabled={!affiliate.data} onClick={onContinue}>
          Continuar
        </NextButton>
      </ButtonsWrapper>
    </PasoDatosDelAfiliadoComponent>
  );
};

export default PasoDatosDelAfiliado;
