import React, { useContext, useState } from "react";
import { Modal } from "@mui/material";
import { ButtonsWrapper, CloseButton, ModalComponent, Table } from "./styles";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import { BsX } from "react-icons/bs";
import PropTypes from "prop-types";
import maskBankAccount from "../../../utils/maskBankAccount";
import RetiroapvsinfinContext from "../../../context/retiroapvsinfin/retiroapvsinfinContext";
import { useFormContext } from "react-hook-form";
import { montoFormat } from "../../../helper/amountFormat";

const ModalConfirmacion = ({ open, onNext, onClose }) => {
  const { datosRetiroApvSinFin } = useContext(RetiroapvsinfinContext);
  const { r_bancos, r_cuentas, r_tipo_cuentas } = datosRetiroApvSinFin;

  const { getValues } = useFormContext();

  const getBankDetails = (codigo) => {
    if (codigo === "nueva") {
      return {
        nombreBanco: r_bancos.find(
          (e) => e.r_codigo === getValues("nombreBanco")
        ).r_descripcion,
        tipoDeCuenta: getValues("tipoDeCuenta"),
        numeroDeCuenta: getValues("numeroDeCuenta"),
        monto: getValues("montoARetirar"),
      };
    }

    const banco = r_cuentas.find((e) => e.r_codigo === codigo);
    return {
      nombreBanco: banco.r_nombre_banco,
      tipoDeCuenta: banco.r_tipo_cuenta,
      numeroDeCuenta: banco.r_numero_cuenta,
      monto: getValues("montoARetirar"),
    };
  };

  const details = getBankDetails(getValues("banco"));

  return (
    <Modal open={open} onClose={onClose}>
      <ModalComponent>
        <CloseButton onClick={onClose}>
          <BsX />
        </CloseButton>
        <div className="title">Confirmación</div>
        <div className="body">
          <p className="description">
            Estás a punto de realizar un retiro de Fondo Libre Habitat con la
            siguiente información:
          </p>
          <Table>
            <p className="title">Datos de retiro</p>
            {[
              {
                label: "Banco",
                value: details.nombreBanco,
              },
              {
                label: "Tipo de cuenta",
                value: details.tipoDeCuenta,
              },
              {
                label: "N.° de cuenta",
                value: maskBankAccount(details.numeroDeCuenta),
              },
              {
                label: "Monto a retirar",
                value: montoFormat(details.monto, true),
              },
            ].map(({ label, value }) => (
              <div className="tableRow" key={label}>
                <p className="label">{label}</p>
                <p className="value">{value}</p>
              </div>
            ))}
          </Table>
        </div>
        <Divider />
        <ButtonsWrapper>
          <Button fullWidth variant={"secondary"} onClick={onClose}>
            Volver
          </Button>
          <Button fullWidth onClick={onNext}>
            Siguiente
          </Button>
        </ButtonsWrapper>
      </ModalComponent>
    </Modal>
  );
};

export default ModalConfirmacion;

ModalConfirmacion.propTypes = {
  open: PropTypes.bool,
};

ModalConfirmacion.defaultProps = {
  open: null,
};
