import React from "react";
/* componentes de material ui */
import { Box, Typography } from "@mui/material";
/* material ui Icon */
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const CardTwo = (props) => {
  const { swipeState, alternativo, rentaFija, variable } = props;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          // maxWidth: "380px",
          height: "160px",
          border: "1.5px solid #F6F6F6",
          borderRadius: "16px",
          p: 2,
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Box sx={{ display: "flex" }}>
            <FiberManualRecordIcon fontSize="small" sx={{ color: "#C92D54" }} />
            &nbsp;
            <Typography sx={{ fontSize: "0.9em" }}>Renta fija</Typography>
          </Box>
          {swipeState !== 0 && (
            <Box sx={{ display: "flex" }}>
              <FiberManualRecordIcon
                fontSize="small"
                sx={{
                  color: "#73607D",
                }}
              />
              &nbsp;
              <Typography sx={{ fontSize: "0.9em" }}>Renta variable</Typography>
            </Box>
          )}

          {swipeState !== 1 && swipeState !== 0 && (
            <Box sx={{ display: "flex" }}>
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ color: "#C3ACCF" }}
              />
              &nbsp;
              <Typography sx={{ fontSize: "0.9em" }}>Alternativo</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "100%",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: rentaFija,
              // swipeState !== 1 && swipeState !== 0
              //   ? swipeState === 2
              //     ? "45%"
              //     : "55%"
              //   : swipeState === 0
              //   ? "100%"
              //   : "80%",

              width: "100%",
              maxWidth: "90px",
              backgroundColor: "#C92D54",
              borderRadius: swipeState === 0 ? "16px" : "16px 16px 0 0 ",
            }}
          >
            <Typography sx={{ fontSize: "0.8em" }}>{rentaFija}</Typography>
          </Box>
          {swipeState !== 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: variable,
                // swipeState !== 1 ? (swipeState === 3 ? "25%" : "25%") : "20%",
                width: "100%",
                maxWidth: "90px",
                backgroundColor: "#73607D",
                borderRadius: swipeState !== 1 ? "" : "0 0 16px 16px",
              }}
            >
              <Typography sx={{ fontSize: "0.8em" }}>{variable}</Typography>
            </Box>
          )}

          {swipeState !== 1 && swipeState !== 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: alternativo,
                // swipeState === 2 ? "35%" : "20%",
                width: "100%",
                maxWidth: "90px",
                backgroundColor: "#C3ACCF",
                borderRadius: "0 0 16px 16px",
              }}
            >
              <Typography sx={{ fontSize: "0.8em" }}>{alternativo}</Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default CardTwo;
