import styled, { css } from "styled-components";
import Select from "react-select";
import MaskedInput from "react-text-mask";

export const SelectComponent = styled(Select)`
  .select__control {
    height: 100%;
    border-radius: 0;
    padding: 4px 0px 4px 16px;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }

  .select__indicators {
    /* display: none; */
  }

  .select__value-container {
    margin: 0;
    padding: 0;
  }

  .select__menu {
    min-width: 150px;
    border-radius: 8px;
    overflow: hidden;
    border: none;
  }
  .select__menu-list {
    padding: 0;
  }

  .select__option {
    background-color: ${(p) => p.theme.palette.white};
    border-radius: 8px;
    ${(p) => p.theme.tipography.bodySmallRegular}
    &:hover {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
      color: ${(p) => p.theme.palette.grayscale[800]};
    }

    &.select__option--is-selected {
      color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.primary[50]};
    }
  }

  .select__indicators {
    flex-direction: row-reverse;
  }
`;

export const InputText = styled(MaskedInput)`
  border: none;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  ${(p) => p.theme.tipography.bodyMediumRegular}
  box-sizing: border-box;
  background-color: transparent;
  height: 100%;
  width: 100%;

  ::placeholder {
    color: ${(p) => p.theme.palette.grayscale[200]};
  }

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};

      /* ::placeholder {
        color: ${(p) => p.theme.palette.state.error};
      } */
    `}
`;

export const DocumentInputComponent = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  height: 48px;
  max-width: 100%;
  position: relative;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  border: 1px solid ${(p) => p.theme.palette.grayscale[50]};

  :focus-within {
    border-color: ${(p) => p.theme.palette.grayscale[500]};
  }

  &.error {
    border-color: ${(p) => p.theme.palette.state.error};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;
