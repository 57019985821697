import clienteAxios from "../../../../../../../config/axios";
import * as actions from "./actions";

export const requestBeneficiaries = (dispatch) => (payload) => {
  dispatch({ type: actions.BENEFICIARIES_REQUEST_SUCCESS, payload });
};

export const sendBeneficiaryDocument = (dispatch) => async (payload) => {
  try {
    dispatch({ type: actions.BENEFICIARIES_SEND_DOCUMENT, payload });

    const formData = new FormData();

    formData.append("id", payload.id);
    formData.append("documento", payload.documento);
    formData.append("documento_partida", payload.documento_partida);
    formData.append("documento_sustento", payload.documento_sustento);
    formData.append("documento_matrimonio", payload.documento_matrimonio);

    const response = await clienteAxios.post(
      "/pmo/documento-beneficiario",
      formData
    );

    if (!response?.data?.success) {
      console.log({ payload });

      dispatch({
        type: actions.BENEFICIARIES_SEND_DOCUMENT_FAIL,
        payload: { id: payload.id },
      });
      return;
    }

    dispatch({
      type: actions.BENEFICIARIES_SEND_DOCUMENT_SUCCESS,
      payload: { id: response?.data?.id },
    });
  } catch (error) {
    dispatch({
      type: actions.BENEFICIARIES_SEND_DOCUMENT_FAIL,
      payload,
    });
  }
};
