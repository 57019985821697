export const SET_CONEXION_ERROR = "SET_CONEXION_ERROR";
export const REMOVE_CONEXION_ERROR = "REMOVE_CONEXION_ERROR";

//
export const BENEFICIARIES_REQUEST = "BENEFICIARIES_REQUEST";
export const BENEFICIARIES_REQUEST_SUCCESS = "BENEFICIARIES_REQUEST_SUCCESS";
export const BENEFICIARIES_REQUEST_FAIL = "BENEFICIARIES_REQUEST_FAIL";

export const BENEFICIARIES_SEND_DOCUMENT = "BENEFICIARIES_SEND_DOCUMENT";
export const BENEFICIARIES_SEND_DOCUMENT_SUCCESS =
  "BENEFICIARIES_SEND_DOCUMENT_SUCCESS";
export const BENEFICIARIES_SEND_DOCUMENT_FAIL =
  "BENEFICIARIES_SEND_DOCUMENT_FAIL";
