import React from "react";
import {
  AccordionComponent,
  ContentComponent,
  ContentWrapper,
  HeaderComponent,
  ItemComponent,
  Trigger,
} from "./styles";
import { BsChevronDown } from "react-icons/bs";

export const Wrapper = ({ type, defaultValue, children }) => {
  return (
    <AccordionComponent type={type} collapsible defaultValue={defaultValue}>
      {children}
    </AccordionComponent>
  );
};

export const Item = ({ value, children }) => {
  return <ItemComponent value={value}>{children}</ItemComponent>;
};

export const Content = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <ContentComponent ref={forwardedRef} {...props}>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentComponent>
    );
  }
);

export const Header = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <HeaderComponent>
      <Trigger {...props} ref={forwardedRef}>
        {children}
        <BsChevronDown />
      </Trigger>
    </HeaderComponent>
  )
);
