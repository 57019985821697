import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const ZonaBeneficiariosLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  min-height: calc(100vh + 32px);
  grid-template-areas: "sidebar" "main";

  // Global style for links
  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }

  p {
    margin-bottom: 0;
  }

  ${(p) => css`
    @media screen and ${device.desktopS} {
      grid-template-areas: "sidebar main";
      grid-template-rows: auto;
      grid-template-columns: 280px 1fr;
      column-gap: 24px;
      padding-right: 24px;
    }
  `}
`;

export const desktop = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 24px;
  min-height: 100%;
  min-width: 100%;
  grid-template-areas: "sidebar" "main";
`;
