import React, { useReducer } from "react";
import comisionhistoricaContext from "./comisionhistoricaContext";
// import ResumenFondoReducer from "./comisionhistoricaReducer";
import ComisionhistoricaReducer from "./comisionhistoricaReducer";
import clienteAxios from "../../config/axios";
import {

  COMISION_HISTORICA_EXITOSO,
  COMISION_HISTORICA_ERROR,
  COMISION_HISTORICA_LOADIN,
  COMISION_HISTORICA_CLEANER,
  COMISION_HISTORICA_CLEANER_PARCIAL,
} from "../../types/comisionHistorica";

const ComisionhistoricaState = (props) => {
  const initialState = {
    datosComisionHistorica: null,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerComisionHistorica = async () => {
    dispatch({
      type: COMISION_HISTORICA_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/mis-inversiones/comision-historica");
      dispatch({
        type: COMISION_HISTORICA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, COMISION_HISTORICA_ERROR);
    }
  };

  const limpiarVariablesComisionHistorica = () => {
    dispatch({
      type: COMISION_HISTORICA_CLEANER,
    });
  };
  const limpiarVariablesComisionHistoricaParcial = () => {
    dispatch({
      type: COMISION_HISTORICA_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(ComisionhistoricaReducer, initialState);
  return (
    <comisionhistoricaContext.Provider
      value={{
        datosComisionHistorica: state.datosComisionHistorica,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        obtenerComisionHistorica,
        limpiarVariablesComisionHistorica,
        limpiarVariablesComisionHistoricaParcial,
      }}
    >
      {props.children}
    </comisionhistoricaContext.Provider>
  );
};

export default ComisionhistoricaState;
