export const arrayDocumentos = [
  {
    id: 1,
    name: "Partida de nacimiento",
    description: [
      {
        id: 1,
        name: "Copia Simple de la Partida de Nacimiento emitida por RENIEC (ambas caras) o Copia Certificada emitida por la Municipalidad.",
        expression: false,
      },
    ],
  },
  {
    id: 2,
    name: "Sustento de condición de invalidez",
    description: [
      {
        id: 1,
        name: "Documentos que sustenten la condición de inválidos para suscribir la solicitud de Evaluación y Calificación de Invalidez.",
        expression: true,
      },
      {
        id: 2,
        name: "Dictamen de invalidez expedido por el COMAFP o COMEC.",
        expression: false,
      },
    ],
  },
  {
    id: 3,
    name: "Constancia de matrimonio o concubinato",
    description: [
      {
        id: 1,
        name: "Copia Simple de la Partida de Matrimonio Civil emitida por RENIEC (ambas caras) o Copia Certificada emitida por la Municipalidad (en ambos documentos con una emisión no mayor a 6 meses).",
        expression: false,
      },
      {
        id: 2,
        name: "Vigencia del nombramiento de Unión de Hecho emitido por SUNARP (vigencia no mayor a 3 meses).",
        expression: false,
      },
    ],
  },
];
