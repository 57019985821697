import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M22.5 13.5a4.5 4.5 0 10-7.5 3.345V22.5l3-1.42 3 1.42v-5.655a4.486 4.486 0 001.5-3.345zm-3 6.63l-1.5-.71-1.5.71v-2.392c.97.35 2.03.35 3 0v2.392zM18 16.5a3 3 0 110-5.999 3 3 0 010 5.999zm-11.25-6H12V12H6.75v-1.5zm0-4.5h7.5v1.5h-7.5V6z"></path>
      <path d="M4.5 22.5A1.502 1.502 0 013 21V3a1.502 1.502 0 011.5-1.5h12A1.501 1.501 0 0118 3v3h-1.5V3h-12v18H12v1.5H4.5z"></path>
    </svg>
  );
}

export default Icon;
