import React, { useState } from "react";

const useStepNavigation = (steps, options = {}) => {
  const {
    onPreviousFromFirstStep = () => {},
    onNextFromLastStep = () => {},
    defaultStep = steps[0],
  } = options;
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const handleStepNavigation = (direction) => {
    if (!direction) {
      return null;
    }

    const index = steps.findIndex((step) => step === currentStep);

    if (direction === "next") {
      const nextStep = steps[index + 1];

      if (nextStep) {
        return setCurrentStep(nextStep);
      }

      return onNextFromLastStep();
    }

    if (direction === "previous") {
      const previousStep = steps[index - 1];

      if (previousStep) {
        return setCurrentStep(previousStep);
      }

      return onPreviousFromFirstStep();
    }
  };

  return { currentStep, setCurrentStep, handleStepNavigation };
};

export default useStepNavigation;
