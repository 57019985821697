import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";
import { useHistory } from "react-router";
import { Mensual, Retiro, Pension } from "./components";

export default function Paso6(props) {
  const {
    processId,
    setCurrectStepNumber,
    setCurrectStepTitle,
    // history,
    modalidad,
    apvf,
  } = props;
  const history = useHistory();
  const [contents, setContents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [dependencies, setDependencies] = useState({
    contribution: {},
    meet_the_time_in_spp: false,
  });
  const jubilacionContext = useContext(JubilacionContext);
  const { contenidoTemaJubilacionData, limpiarObtenerContenidoTemaJubilacion } =
    jubilacionContext;

  useEffect(() => {
    if (modalidad === 1)
      setCurrectStepTitle("Elección de retiro de hasta 95.5%");
    else if (modalidad === 0)
      setCurrectStepTitle("Elección de pensión mensual");
    else setCurrectStepTitle("Elección de pensión objetivo");
    return () => {
      limpiarObtenerContenidoTemaJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        setContents(contenidoTemaJubilacionData.contents);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setBeneficiarios(contenidoTemaJubilacionData.pmo_beneficiarios);
      } else {
        setShowModal(!showModal);
      }
    }
  }, [contenidoTemaJubilacionData]);

  if (contents.length === 0) return <Loading />;

  const renderView = () => {
    if (modalidad === "1")
      return (
        <Retiro
          getBackOneStep={() => setCurrectStepNumber(5)}
          processId={processId}
          contentId={contents[0].id}
          endProcess={() =>
            history.push(
              "/mi-habitat-digital/afiliado/jubilacion-legal/retiro-fin"
            )
          }
          dependencies={dependencies}
          modalidad={modalidad}
          apvf={apvf}
        />
      );
    else if (modalidad === "0")
      return (
        <Mensual
          getBackOneStep={() => setCurrectStepNumber(5)}
          processId={processId}
          contentId={contents[0].id}
          endProcess={() =>
            history.push(
              "/mi-habitat-digital/afiliado/jubilacion-legal/pension-fin"
            )
          }
          modalidad={modalidad}
          apvf={apvf}
        />
      );
    else
      return (
        // Mensual
        <Pension
          getBackOneStep={() => setCurrectStepNumber(5)}
          processId={processId}
          contentId={contents[0].id}
          endProcess={() =>
            history.push(
              "/mi-habitat-digital/afiliado/jubilacion-legal/pmo-fin"
            )
          }
          contenidoTemaJubilacionData={contenidoTemaJubilacionData}
          dependencies={dependencies}
          beneficiarios={beneficiarios}
          modalidad={modalidad}
          apvf={apvf}
        />
      );
  };

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
