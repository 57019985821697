import React, { useState, useEffect, useContext } from "react";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import RetiroDU038Context from "../../context/retiroDU038/retiroDU038Context";
import RetiroDU038 from "./RetiroDU038";
import RetiroDU038Preview from "./RetiroDU038Preview";
import MensajeInformativo from "./MensajeInformativo";

export default function Retiro(props) {
  const misDatosContext = useContext(MisDatosContext);
  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;
  const retiroDU038Context = useContext(RetiroDU038Context);
  const [showView, setShowView] = useState(0);

  const {
    obtenerDatosRetiro,
    datosAlifiadoRetiro,
    grabarRetiroDU038,
    limpiarVariablesRetiro,
  } = retiroDU038Context;
  useEffect(() => {
    obtenerDatosRetiro();
    obtenerMisDatosGet();
    return () => {
      limpiarVariablesRetiro();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (datosAlifiadoRetiro) {
      if (datosAlifiadoRetiro.r_validacion === 1) {
        setShowView(2);
      } else if (datosAlifiadoRetiro.r_validacion === 2) {
        setShowView(1);
      } else {
        setShowView(3);
      }
    }
  }, [datosAlifiadoRetiro]);

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <RetiroDU038
            misDatosGet={misDatosGet}
            client={datosAlifiadoRetiro}
            endProcess={(p) => grabarRetiroDU038(p)}
            history={props.history}
          />
        );
      case 2:
        return <RetiroDU038Preview misDatosGet={datosAlifiadoRetiro} />;
      case 3:
        return <MensajeInformativo />;
      default:
        return null;
    }
  };
  return <div>{renderView()}</div>;
}
