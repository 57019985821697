import {
  VALORES_CUOTA_GET,
  VALORES_CUOTA_ERROR,
  VALORES_CUOTA_LOADING,
  VALORES_CUOTA_RESETEAR,
  VALORES_CUOTA_UTILS_GET,
  VALORES_CUOTA_VAL_12_GET,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case VALORES_CUOTA_GET:
      return {
        ...state,
        datos: action.payload,
      };
    case VALORES_CUOTA_ERROR:
      return {
        ...state,
        resultado: null,
        datos: null,
        deuda: null,
        mensajeAlerta: null,
        loading: false,
      };
    case VALORES_CUOTA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VALORES_CUOTA_RESETEAR:
      return {
        ...state,
        resultado: null,
        datos: null,
        deuda: null,
        mensajeAlerta: null,
        loading: false,
      };
    case VALORES_CUOTA_VAL_12_GET:
      return {
        ...state,
        datos_12: action.payload,
      };
    case VALORES_CUOTA_UTILS_GET:
      return {
        ...state,
        utils: action.payload,
        mensajeAlerta: null,
        loading: false,
      };
    default:
      break;
  }
};
