import { Divider, PaginationItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import styled from "styled-components";
import IC_INFO from "./../../../../../assets/icons/ic_info_retiro_extraordinario.svg";

const Header = styled("div")`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.2px;
  color: #363a3e;
  flex-grow: ${(props) => (props.size ? props.size : "1")};
  flex-basis: 0;
`;

const Content = styled("div")`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.2px;
  color: #51565f;
  flex-grow: ${(props) => (props.size ? props.size : "1")};
  flex-basis: 0;
`;

export const SimpleTable = ({ labels, titulos, contenido }) => {
  const [dataCurrent, setDataCurrent] = useState(contenido ?? []);
  const [sizePagination, setSizePagination] = useState(
    window.screen.width < 990 ? 4 : 8
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (e, value) => {
    setDataCurrent(
      contenido.slice((value - 1) * sizePagination, value * sizePagination)
    );
    setCurrentPage(value);
  };

  useEffect(() => {
    setDataCurrent(contenido?.slice(0, sizePagination));
    setCurrentPage(1);
  }, [contenido]);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "8px",
        }}
      >
        {titulos?.map((e, index) =>
          e != "accion" ? (
            <Header
              key={index}
              size={e.size}
              style={{ display: "inline-flex" }}
            >
              {e.hasIcon && (
                <>
                  <img src={IC_INFO} style={{ paddingRight: "4px" }} />
                </>
              )}
              <>{e.title}</>
            </Header>
          ) : (
            <Header key={index} size={e.size}>
              {" "}
              {e.title}{" "}
            </Header>
          )
        )}
      </div>

      <div>
        {dataCurrent?.map((e, index) => (
          <>
            <div style={{ padding: "8px", display: "flex" }}>
              {labels?.map((l, lIndex) =>
                l != "accion" ? (
                  <Content size={titulos[lIndex].size}> {e[l]}</Content>
                ) : (
                  <Content style={{ textAlign: "center", flexGrow: "1" }}>
                    {" "}
                    <img src={IC_INFO} />{" "}
                  </Content>
                )
              )}
            </div>
            <Divider />
          </>
        ))}
      </div>

      <div
        style={{
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={Math.ceil((contenido?.length ?? 0) / sizePagination)}
          color="primary"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};
