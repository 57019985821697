import React, { Component } from "react";
import GraficoLinea from "./GraficoLinea";
import TabValue from "./TabValue";

class GraficoDetalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        { name: "Fondo 0", id: 0 },
        { name: "Fondo 1", id: 1 },
        { name: "Fondo 2", id: 2 },
        { name: "Fondo 3", id: 3 },
      ],
      selectedItem: 0,
      listFilter: null,
    };
  }

  componentDidMount() {
    const listado = this.formatData(0);
    this.setState({
      ...this.state,
      listFilter: listado,
    });
  }

  formatData = (indice) => {
    const { feeValue } = this.props;
    return feeValue.rows.map((item, index) => {
      const arrayDate = item.day.split("-");
      return {
        id: index,
        fecha: item.day,
        fecha2: `${arrayDate[1]}-${arrayDate[0]}-${arrayDate[2]}`,
        monto: parseFloat(item[`fund${indice}`].split(" ")[1]),
        monto2: parseFloat(item[`fund${indice}`].split(" ")[1]).toString(),
      };
    });
  };

  onChangeValue = (id) => {
    const listado = this.formatData(id);
    this.setState({
      ...this.state,
      listFilter: null,
    });
    this.setState({
      ...this.state,
      selectedItem: id,
      listFilter: listado,
    });
  };

  getMonth = (num) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    return months[num - 1];
  };

  render() {
    const { items, listFilter } = this.state;
    const { month, year } = this.props;
    return (
      <section className="container container-respuesta">
        <h2 className="custom-text-graphic text-center pt-3">
          Valores Cuota de{" "}
          <strong>
            {this.getMonth(month)} - {year}
          </strong>
        </h2>
        <div className={`hbi-content-tabs-fondo pt-4`}>
          <TabValue changeListadoGrafico={this.onChangeValue} items={items} />
        </div>
        {listFilter ? <GraficoLinea listFilter={listFilter} /> : ""}
      </section>
    );
  }
}

export default GraficoDetalle;
