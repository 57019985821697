import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import Arrow_back_24px from "../../../../../../assets/icons/arrow_back_24px.svg";
import { device } from "../../../../../../utils/device";
import {
  Content,
  ContentAppbar,
  ContentPage,
  TitleAppbar,
  IconButton,
} from "../../styles/Page";
const Page = ({ children, isShowAppbar, title, ...props }) => {
  return <ContentPage>{children}</ContentPage>;
};

export default Page;

Page.propTypes = {
  children: PropTypes.node,
  isShowAppbar: PropTypes.bool,
  onClick: PropTypes.func,
};

Page.defaultProps = {
  isShowAppbar: true,
};
