import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import hocWidthWindow from "../../HOC/HocWidthWindow";
import { montoFormat } from "../../helper/amountFormat";
import { CardSimple } from "./componentes/CardSimple";
import { ItemState } from "./componentes/ItemState";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tipography } from "../../components";
import moment from "moment";
import styled from "styled-components";
import IC_CALENDAR from "./../../assets/icons/ic_calendar.svg";
import MENU_MOBILE from "./../../assets/icons/menu_mobile_elipse_2.png";
import IC_N_SOLICITUD from "./../../assets/icons/icon_n_solicitud.svg";
import IC_BANK from "./../../assets/icons/ic_bank.svg";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  iconBlue: "#0043CE",
};

const DivNext = styled("div")`
  width: 20px;
  height: 100%;
  position: absolute;
  left: calc(100% - 20px);
  background: #ce1336;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  align-self: flex-end;
  display: flex;
  @media (min-width: 990px) {
    display: none;
  }
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainerStyles: {
      borderRadius: "16px",
      boxShadow: 2,
    },
    paperContainerStylesChanges: {
      borderRadius: "0px",
      border: "0px",
      boxShadow: "none",
    },
    selectStyles: {
      borderRadius: "16px",
    },
    textFieldStyles: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "16px",
      },
    },
    textFieldFilledStyles: {
      "& .MuiFilledInput-root": {
        borderRadius: "16px",
      },
      "& .MuiFilledInput.Mui-focused": {
        borderColor: " none !important",
      },
    },
    boxDescriptionStyles: {
      display: "flex",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    boxWarningStyles: {
      mt: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    iconStyles: {
      color: palletteColors.iconBlue,
    },
  })
);

const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const SolicitudProcesada = ({
  info,
  limpiarVariablesAportarApvSinFinParcial,
  obtenerDatosParaAportarApvSinFin,
  seguimientoAportes,
}) => {
  const [isMore, setIsMore] = useState(false);
  const [detail, setDetail] = useState([]);
  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    r_estado,
    subtitle,
    r_solicitud,
    r_fecha_solicitud,
    r_fecha_banco,
    r_fecha_acreditacion,

    r_banco,
  } = detail ?? {};

  useEffect(() => {
    window.scrollTo(0, 0);
    if (seguimientoAportes) {
      setDetail(seguimientoAportes[0]);
    }

    return () => {};
  }, [seguimientoAportes]);

  const estado =
    r_estado === "RECHAZADO"
      ? "RECHAZADA"
      : r_estado === "PROCESADA"
      ? "ACREDITADO"
      : "EN CURSO";

  const onClickNext = () => {
    setIsMore(!isMore);
  };
  return (
    <Box>
      <Tipography component="h5" style={{ padding: matches ? 0 : 2 }}>
        Datos de la Inversión
      </Tipography>

      <Box sx={{ p: matches ? 0 : 2 }}>
        {info.apertura && (
          <Grid container spacing={2} sx={{ pb: 2 }}>
            <Grid item xs={12}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Nro de Solicitud"
                    defaultValue={info.apertura.v_num_sol}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Fecha de solicitud"
                    defaultValue={info.apertura.v_fecha_solicitud}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            {/* <Grid item xs={12}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Tipo de fondo"
                    defaultValue={info.apertura.v_fondo}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid> */}
          </Grid>
        )}

        {info.debito && (
          <Grid container spacing={2}>
            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="N.° de Solicitud"
                    defaultValue={info.debito.v_num_sol}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="N.° de cuenta"
                    defaultValue={info.debito.v_num_cuenta}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Fecha de solicitud"
                    defaultValue={info.debito.v_fecha_solicitud}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Monto"
                    defaultValue={`S/ ${montoFormat(info.debito.v_monto)}`}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Banco"
                    defaultValue={info.debito.v_banco}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>

            {/* <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Moneda"
                    defaultValue={info.debito.v_moneda}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid> */}
            {/* {info.debito.v_fecha_vencimiento && (
              <Grid item xs={matches ? 12 : 6}>
                <ThemeProvider theme={Theme}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.textFieldFilledStyles}
                      id="outlined-read-only-input"
                      label="Fecha de vencimiento"
                      defaultValue={info.debito.v_fecha_vencimiento}
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </ThemeProvider>
              </Grid>
            )} */}

            <Grid item xs={matches ? 12 : 6}>
              <ThemeProvider theme={Theme}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textFieldFilledStyles}
                    id="outlined-read-only-input"
                    label="Frecuencia"
                    defaultValue={
                      info.debito.v_frecuencia === "UNICO"
                        ? "ÚNICA"
                        : info.debito.v_frecuencia
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    variant="filled"
                  />
                </FormControl>
              </ThemeProvider>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          sx={{
            paddingTop: { xs: "16px", md: "24px" },
            paddingBottom: { xs: "16px", md: "0px" },
          }}
        >
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                boxShadow: { xs: "0", md: "0px -4px 16px rgba(0, 0, 0, 0.12)" },
                borderRadius: "16px",
                background: "#fff",
                display: { xs: "flex", md: "block" },
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Grid
                container
                className="grid_container_mobile"
                sx={{
                  minHeight: "100%",
                }}
              >
                <Grid
                  item
                  xs={11}
                  md={5}
                  sx={{
                    display: !isMore ? "block" : "none",
                  }}
                  className="animate__animated animate__fadeInLeft animate__faster"
                >
                  <Box
                    sx={{
                      padding: { xs: "16px 0", md: "16px" },
                    }}
                  >
                    <ItemState {...detail} estado={estado} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default hocWidthWindow(SolicitudProcesada);
