import styled from "styled-components";

export const AporteVoluntarioPage = styled.div`
  padding-top: "40px";
`;

export const BodyPageWrapper = styled.div`
  padding: 16px 24px;

  @media screen and (max-width: 700px) {
    padding: 16px 0px;
  }
`;
