import React from "react";

const ICON_HEADPHONE = ({ size = 18, color }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 14H1.5C1.09733 14 0.746667 13.8507 0.448 13.552C0.149333 13.2533 0 12.9027 0 12.5V7C0 6.028 0.184 5.11833 0.552 4.271C0.92 3.42367 1.42 2.684 2.052 2.052C2.684 1.42 3.427 0.92 4.281 0.552C5.135 0.184 6.04833 0 7.021 0C7.979 0 8.88167 0.184 9.729 0.552C10.5763 0.92 11.316 1.42 11.948 2.052C12.58 2.684 13.08 3.42367 13.448 4.271C13.816 5.11833 14 6.028 14 7V12.5C14 12.9027 13.854 13.2533 13.562 13.552C13.2707 13.8507 12.9167 14 12.5 14H9V8H12.5V7C12.5 5.472 11.9653 4.17333 10.896 3.104C9.82667 2.03467 8.528 1.5 7 1.5C5.472 1.5 4.17333 2.03467 3.104 3.104C2.03467 4.17333 1.5 5.472 1.5 7V8H5V14ZM3.5 9.5H1.5V12.5H3.5V9.5ZM10.5 9.5V12.5H12.5V9.5H10.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ICON_HEADPHONE;
