import React, { useContext, useEffect, useState } from "react";
import ReactGa from "react-ga";
import PrimerPaso from "./PrimerPaso";
import SolicitudProcesada from "./SolicitudProcesada";
import SolicitudNoProcesada from "./SolicitudNoProcesada";

import MisdatosContext from "../../../context/misdatos/misdatosContext";
import AuthContext from "../../../context/autenticacion/authContext";
import ModalContext from "../../../context/modal/modalContext";
import { Page } from "../../../components";
import Grid from "@mui/material/Grid";

const MiClaveWeb = () => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;
  const misdatosContext = useContext(MisdatosContext);
  const {
    resultadoClavePost,
    limpiarVariablesClaveWebParcial,
    limpiarVariablesClaveWeb,
    mensajeAlertaClavePost,
  } = misdatosContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const [openModalToken, setOpenModalToken] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaClavePost) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
    }
    return () => {
      limpiarVariablesClaveWebParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlertaClavePost]);
  useEffect(() => {
    if (resultadoClavePost) {
      setMostrarModalToken(resultadoClavePost);
      setOpenModalToken(false);
    } else {
      setMostrarModalToken(null);
    }
  }, [resultadoClavePost]);
  const mostrarMensaje = () => {
    setMessage(mensajeAlertaClavePost);
  };
  if (mensajeAlertaClavePost !== null) return <p></p>;
  return (
    <Page title="Actualización de clave web" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {resultadoClavePost === null ||
          (resultadoClavePost && resultadoClavePost.r_respuesta === -2) ? (
            <PrimerPaso
              mostrarModalToken={mostrarModalToken}
              setOpenModalToken={setOpenModalToken}
              openModalToken={openModalToken}
              setMostrarModalToken={setMostrarModalToken}
            />
          ) : resultadoClavePost.r_respuesta === "1" ? (
            <SolicitudProcesada email={resultadoClavePost.r_email} />
          ) : (
            <SolicitudNoProcesada
              mensaje={resultadoClavePost.r_mensaje}
              resetear={limpiarVariablesClaveWeb}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default MiClaveWeb;
