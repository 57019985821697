import React, { useContext } from "react";
import CodeContext from "../../../../../../../../context/code/codeContext";
const SolicitudNoProcesada = ({ error, showDetail }) => {
  const codeContext = useContext(CodeContext);
  const { limpiarVariablesCode } = codeContext;
  const handleReset = () => {
    showDetail();
    limpiarVariablesCode();
  };
  return (
    <section className="container container-mensaje-info-retiro">
      <div className="component-mensaje">
        <h5 style={{ color: "#bf253d" }}>¡Alerta!</h5>
        <p className="text-center">{error !== null ? error.message : ""}</p>
        <p className="contenedor-btn-no-proceso">
          <button
            className="btn-white custom-no-solicitud-btn"
            onClick={() => handleReset()}
          >
            <i className="fa fa-repeat"></i> Volver a intentarlo
          </button>
        </p>
      </div>
    </section>
  );
};

export default SolicitudNoProcesada;
