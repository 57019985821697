import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../../../../../components";
import JubilacionPmoContext from "../../../../../../../../../../context/jubilacion/pmo/jubPmoContext";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { ModalBeneficiarios } from "../../../../../../component/Modals";
import * as Input from "../../../../../../../../../../components/Input";
import {
  ContentFilesExtention,
  ContentText,
} from "../../../../../../styles/Decision";
import CustomFilesModal from "../../../../../../../../Components/customFilesModal";

export default function Index(props) {
  const { prevView, nextView, beneficiarios } = props;
  const [isOpenModalBeneficiarios, setIsOpenModalBeneficiarios] =
    useState(false);
  const [generalErrorModal, setGeneralErrorModal] = useState(false);
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const jubilacionPmoContext = useContext(JubilacionPmoContext);
  const {
    decisionPmoDocumento,
    resetearDocumentPmo,
    dataDocumentoPmo,
    loadingDocumentoPmo,
  } = jubilacionPmoContext;
  useEffect(() => {
    return () => {
      resetearDocumentPmo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dataDocumentoPmo) {
      if (dataDocumentoPmo.success) {
        nextView();
      } else {
        setGeneralErrorModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDocumentoPmo]);
  const changeIconForLoading = () => {
    if (loadingDocumentoPmo)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const filesMaxSize = 2;
  const filesExtensions = ["pdf", "jpg", "jpeg", "png"];

  const handleValidatePmo = async () => {
    if (beneficiarios.length === 0) {
      const data = new FormData();

      data.append("id", 0);
      data.append("documento", getValues("documentos.id")[0] || null);

      decisionPmoDocumento(data);
    } else {
      nextView();
    }
  };

  const canContinueToNextStep = () => {
    return [
      getValues("documentos.id"),
      !errors["documentos"]?.["id"],
      !loadingDocumentoPmo,
    ].every(Boolean);
  };

  return (
    <div className="detalle-info-container residencia">
      <h1 className="mb-3">
        Ingreso de documentos {beneficiarios.length > 0 ? "1/2" : null}{" "}
      </h1>
      <ContentText>
        Necesitamos que nos proporciones los siguientes documentos para
        continuar con tu solicitud:
      </ContentText>

      <Box>
        <ContentFilesExtention>
          Formatos admitidos: pdf, jpg, jpeg, png. <br /> Se debe subir un
          archivo por cada documento. <br /> Tamaño máximo: 2mb por archivo{" "}
        </ContentFilesExtention>
        <Input.Wrapper>
          <Input.Title required={false}>
            Documento de identidad (Ambas caras)
          </Input.Title>
          <Input.File
            extensions={filesExtensions}
            name="documentos.id"
            size={"small"}
            maxFileSize={filesMaxSize}
          />

          <Input.ErrorMessage name="documentos.id" />
        </Input.Wrapper>
      </Box>

      <div className="container-reja-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className="mb-4 btn-modal-secondary"
          onClick={() => prevView()}
          disabled={loadingDocumentoPmo}
        >
          Volver
        </Button>
        <Button
          className="mb-4 btn-modal-primary"
          onClick={handleValidatePmo}
          disabled={!canContinueToNextStep()}
        >
          Continuar {changeIconForLoading()}
        </Button>
      </div>

      <ModalBeneficiarios
        isOpen={isOpenModalBeneficiarios}
        setIsOpen={setIsOpenModalBeneficiarios}
      />
      <CustomFilesModal
        open={generalErrorModal}
        onClose={setGeneralErrorModal}
      />
    </div>
  );
}
