import React, { useState, useEffect, useContext } from "react";
import { Estimacion, EstimacionDetalle, EstimacionAlerta } from "./components";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";
import moment from "moment";
// Nuevo componente de vista espera para Estimacion
import EsperaEstimacionCard from "../../../../Components/EsperaEstimacionCard/EsperaEstimacionCard";

export default function Paso3(props) {
  const [visible, setVisible] = useState(true);
  const [showView, setShowView] = useState(0);
  const [detailData, setDetailData] = useState({
    id: "",
    percent: "",
    object: null,
  });
  const [contents, setContents] = useState([]);
  const [dependencies, setDependencies] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    limpiarObtenerContenidoTemaJubilacion,
    guardarAvanceJuilacionData,
    limpiarGuardarAvanceTramiteJubilacion,
    mensajeAlerta,
    resetear,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerContenidoTemaJubilacion(props.processId, 3);
    return () => {
      limpiarObtenerContenidoTemaJubilacion();
      limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setContents(contenidoTemaJubilacionData.contents);
        setVisible(contenidoTemaJubilacionData.visible);
        // setShowView(1);
        if (contenidoTemaJubilacionData.dependencies && contenidoTemaJubilacionData.dependencies.r_validacion !== undefined) {
          // if (contenidoTemaJubilacionData.dependencies.r_validacion === 0) {
          setShowView(0);
          // }
        } else {
          setShowView(1);
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  useEffect(() => {
    if (guardarAvanceJuilacionData) {
      if (!guardarAvanceJuilacionData.success) {
        setShowView(3);
        limpiarObtenerContenidoTemaJubilacion();
        limpiarGuardarAvanceTramiteJubilacion();
      }
      limpiarObtenerContenidoTemaJubilacion();
      limpiarGuardarAvanceTramiteJubilacion();
      //    else {

      //     props.setCurrectStepNumber(4)

      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarAvanceJuilacionData]);
  if (mensajeAlerta !== null) return <p></p>;

  console.log(dependencies);
  if (dependencies == null) {
    return <Loading />;
  } else {
    /* if (dependencies.r_validacion && dependencies.r_validacion == 0) {
      setShowView(0);
    } else {
      setShowView(1);
    } */
  }



  const renderView = () => {
    if (showView === 0)
      return (
        <EsperaEstimacionCard
          showScreen={true} // Controlado por el estado
          className="beauty-modal"
          title="Toma de decisión"
          description={
            <div dangerouslySetInnerHTML={{ __html: dependencies.message }} />
          }
          btnFunction={() => {
            props.getOutOfProcess();
          }}
          btnLabel={"Entendido"}
          hasAdvice={true}
        />
      );
    else if (showView === 1)
      return (
        <Estimacion
          data={dependencies}
          endThirdStep={() => props.setCurrectStepNumber(4)}
          showDetail={() => setShowView(2)}
          setDetail={setDetailData}
          processId={props.processId}
          idContent={contents.length === 0 ? 0 : contents[0].id}
        />
      );
    else if (showView === 2)
      return (
        <EstimacionDetalle
          data={dependencies}
          detailData={detailData}
          setDetail={setDetailData}
          getBackToMain={() => setShowView(1)}
        />
      );
    else if (showView === 3)
      return (
        <EstimacionAlerta
          error={guardarAvanceJuilacionData}
          showDetail={() => {
            setShowView(1);
            resetear();
          }}
        />
      );
    else return null;
  };

  return (
    <div className="af-content-estimacion">
      {renderView()}
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
