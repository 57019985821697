import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "../../../assets/IconBucket/Icon/HomeIcon";
import { Box } from "@mui/material";

const BreadCrumb = () => {
  const breadcrumbs = [
    <Box
      key="1"
      color="inherit"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center ",
      }}
    >
      <HomeIcon size={15} color="black" />
    </Box>,

    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "12px", fontWeight: "bold" }}
    >
      Mis Fondos
    </Typography>,
    <Typography key="3" sx={{ fontSize: "12px" }} color="primary">
      Cambio de tipo de fondo
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadCrumb;
