import React, { useState, useEffect } from "react";
import { Drawer, Grid, Modal } from "@mui/material";
import { ModalComponent } from "./stylesModalJS";
import { useHistory } from "react-router-dom";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { BsX } from "react-icons/bs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  drawerStyle: {
    "& .MuiDrawer-paper": {
      borderRadius: "16px 16px 0px 0px",
    },
  },
});

const ModalConfirmar = (props) => {
  const classes = useStyles();
  const { openModal } = props;
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();

  const openModalAction = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    history.push("/");
  };

  useEffect(() => {
    if (openModal) openModalAction();
  }, [openModal]);

  const confirmOperation = () => {
    //DATALAYER GENERA TU CLAVE WEB
    window.dataLayer.push({
      event: "modal_view",
      description: "clave web generada con exito",
    });
    history.push("/");
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  }; //13.6px

  return (
    <>
      {width > 600 ? (
        <Modal open={open} onClose={onClose}>
          <ModalComponent>
            <div>
              <Grid container justifyContent="center">
                <Grid item>
                  <h5 className="margin-center text-center">
                    <TaskAltOutlinedIcon
                      style={{ fontSize: 35 }}
                    ></TaskAltOutlinedIcon>
                    <b> ¡Clave web generada con éxito! </b>{" "}
                  </h5>
                  <br />
                  <p className="margin-center text-center">
                    ¡Excelente <b>Generaste tu nueva clave web</b>
                    <br />
                    correctamente. Ahora estás listo para acceder a
                    <br />
                    Mi Habitat Digital!
                  </p>
                  <br />
                  <button
                    onClick={confirmOperation}
                    className={`mt-3 btn-recover margin-center btn-modal-total`}
                  >
                    Ingresa aqui{" "}
                  </button>
                </Grid>
              </Grid>
            </div>
          </ModalComponent>
        </Modal>
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          anchor={"bottom"}
          className={classes.drawerStyle}
          PaperProps={{
            sx: {
              bordeRadius: "16px 16px 0 0",
              padding: "32px 32px 40px 32px",
            },
          }}
        >
          <div>
            <Grid>
              <Grid item>
                <h5 className=" margin-center text-center">
                  <TaskAltOutlinedIcon
                    style={{ fontSize: 35 }}
                  ></TaskAltOutlinedIcon>
                  <b> ¡Clave generada con éxito! </b>{" "}
                </h5>
                <br />
                <p className="margin-center text-center">
                  ¡Excelente <b>Generaste tu nueva clave web </b>
                  correctamente. Ahora estás listo para acceder a Mi Habitat
                  Digital.
                </p>
                <br />
                <button
                  onClick={confirmOperation}
                  className={` btn-recover margin-center btn-modal-total`}
                >
                  Ingresa aqui{" "}
                </button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default ModalConfirmar;
