import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./customModal.css";
import "./beautyModal.css";
import sgvAdvice from "../../../../assets/recuerda.svg";
import { Button } from "../../../../components";

const CustomModal = (props) => {
  const {
    showModal,
    backdrop = true,
    className,
    toggle,
    fnCloseIconBtn,
    title,
    description,
    hasBtnPrimary,
    textBtnPrimary,
    fnBtnPrimary,
    btnPrimaryLoading,
    disableBtnPrimary,
    hasBtnSecondary,
    textBtnSecondary,
    fnBtnSecondary,
    disableBtnSecondary,
    btnSecondaryLoading,
    hasAdvice,
    txtAdvice,
    onClosed,
  } = props;

  const renderPrimaryIcon = () => {
    if (btnPrimaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const renderSecondaryIcon = () => {
    if (btnSecondaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      className={className}
      backdrop={backdrop}
      centered
      onClosed={onClosed}
      zIndex={99}
    >
      {fnCloseIconBtn && (
        <div className="modal-close">
          <i className="fa fa-times-circle-o" onClick={fnCloseIconBtn}></i>
        </div>
      )}
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Row className="footer-row custom-total-buttons">
          <Col className="footer-col-btn">
            {hasBtnSecondary && (
              <Button
                className="btn-modal-secondary"
                disabled={
                  disableBtnSecondary ||
                  btnPrimaryLoading ||
                  btnSecondaryLoading
                }
                onClick={fnBtnSecondary}
              >
                {textBtnSecondary}
                {renderSecondaryIcon()}
              </Button>
            )}
            {hasBtnPrimary && (
              <Button
                className="btn-modal-primary"
                disabled={
                  disableBtnPrimary || btnPrimaryLoading || btnSecondaryLoading
                }
                onClick={fnBtnPrimary}
              >
                {textBtnPrimary}
                {renderPrimaryIcon()}
              </Button>
            )}
          </Col>
        </Row>
        {hasAdvice && (
          <Row className="footer-row custom-remenber-modal">
            <Col>
              <div className="footer-col-advice">
                <div className="icon-container">
                  <img src={sgvAdvice} alt={"icono de alerta"} />
                </div>
                {txtAdvice}
              </div>
            </Col>
          </Row>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
