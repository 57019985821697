import React, { useReducer } from "react";
import EcmContext from "./ecmensualContext";
import EcmReducer from "./ecmensualReducer";
import clienteAxios from "../../config/axios";
import {
  EC_MENSUAL_LISTA_MESES_EXITOSO,
  EC_MENSUAL_LISTA_MESES_ERROR,
  EC_MENSUAL_LISTA_MESES_LOADIN,
  EC_MENSUAL_EXITOSO,
  EC_MENSUAL_ERROR,
  EC_MENSUAL_LOADIN,
  EC_MENSUAL_CLEANER,
  EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL,
  EC_MENSUAL_CLEANER_ONLY_VARIABLE,
} from "../../types";

const EcmensualState = (props) => {
  const initialState = {
    listaDeOpciones: null,
    externalLink: null,
    mensajeAlerta: null,
    loadingPage: false,
    loadingBtn: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosListaMeses = async () => {
    dispatch({
      type: EC_MENSUAL_LISTA_MESES_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/eecc-mensual/periodos");
      dispatch({
        type: EC_MENSUAL_LISTA_MESES_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, EC_MENSUAL_LISTA_MESES_ERROR);
    }
  };

  const obtenerECMensual = async (datos) => {
    dispatch({
      type: EC_MENSUAL_LOADIN,
      payload: true,
    });
    var windowReference = window.open();
    try {
      const respuesta = await clienteAxios.post("/eecc-mensual", datos);
      windowReference.location = respuesta.data.data.url;
      dispatch({
        type: EC_MENSUAL_EXITOSO,
        payload: respuesta.data.data.url,
      });
    } catch (error) {
      handleErrorsLocal(error, EC_MENSUAL_ERROR);
    }
  };

  const limpiarVariablesECMensual = () => {
    dispatch({
      type: EC_MENSUAL_CLEANER,
    });
  };
  const limpiarVariablesECMensualParcial = () => {
    dispatch({
      type: EC_MENSUAL_LISTA_MESES_CLEANER_PARCIAL,
    });
  };

  const limpiarExternalLink = () => {
    dispatch({
      type: EC_MENSUAL_CLEANER_ONLY_VARIABLE,
    });
  };

  const [state, dispatch] = useReducer(EcmReducer, initialState);
  return (
    <EcmContext.Provider
      value={{
        listaDeOpciones: state.listaDeOpciones,
        externalLink: state.externalLink,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        obtenerDatosListaMeses,
        obtenerECMensual,
        limpiarVariablesECMensual,
        limpiarVariablesECMensualParcial,
        limpiarExternalLink,
      }}
    >
      {props.children}
    </EcmContext.Provider>
  );
};

export default EcmensualState;
