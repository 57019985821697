import styled, { css } from "styled-components";

const getColor = (where = "color", type = "dark") => css`
  ${(p) =>
    p.$variant === "info" &&
    css`
      color: ${(p) =>
        where === "color"
          ? type === "light"
            ? p.theme.palette.state.infoLight
            : p.theme.palette.state.info
          : null};
      background-color: ${(p) =>
        where === "background"
          ? type === "light"
            ? p.theme.palette.state.infoLight
            : p.theme.palette.state.info
          : null};
    `}

  ${(p) =>
    p.$variant === "success" &&
    css`
      color: ${(p) =>
        where === "color"
          ? type === "light"
            ? p.theme.palette.state.successLight
            : p.theme.palette.state.success
          : null};
      background-color: ${(p) =>
        where === "background"
          ? type === "light"
            ? p.theme.palette.state.successLight
            : p.theme.palette.state.success
          : null};
    `}

    ${(p) =>
    p.$variant === "error" &&
    css`
      color: ${(p) =>
        where === "color"
          ? type === "light"
            ? p.theme.palette.state.errorLight
            : p.theme.palette.state.error
          : null};
      background-color: ${(p) =>
        where === "background"
          ? type === "light"
            ? p.theme.palette.state.errorLight
            : p.theme.palette.state.error
          : null};
    `}

    ${(p) =>
    p.$variant === "warning" &&
    css`
      color: ${(p) =>
        where === "color"
          ? type === "light"
            ? p.theme.palette.state.warningLight
            : p.theme.palette.state.warning
          : null};
      background-color: ${(p) =>
        where === "background"
          ? type === "light"
            ? p.theme.palette.state.warningLight
            : p.theme.palette.state.warning
          : null};
    `}
`;

export const AlertComponet = styled.div`
  display: grid;
  border-radius: 16px;
  box-shadow: ${(p) => p.theme.elevations.below};
  padding: 16px;
  ${getColor("background", "light")}
  margin-bottom: 16px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "icon header header"
    "icon description description"
    "icon action action";
  column-gap: 8px;

  ${(p) =>
    !p.$title &&
    css`
      align-items: center;
      grid-template-areas:
        "icon description action"
        "icon description action";
    `}
`;

export const Header = styled.div`
  grid-area: header;
`;

export const Icon = styled.div`
  grid-area: icon;
  ${getColor("color")}

  /* ${(p) =>
    !p.$title &&
    css`
      align-self: center;
    `} */

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Close = styled.div`
  grid-area: close;
  display: grid;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Title = styled.div`
  grid-area: title;
  ${(p) => p.theme.tipography.subtitleS}
  margin-bottom: 8px;
`;

export const Description = styled.div`
  grid-area: description;
  ${(p) => p.theme.tipography.bodySmallRegular}
  align-self: center;
  margin-bottom: 8px;

  ${(p) =>
    p.$action &&
    !p.$title &&
    css`
      margin-bottom: 0px;
    `}

  ${(p) =>
    !p.$action &&
    css`
      margin-bottom: 0px;
    `}
`;

export const Action = styled.div`
  grid-area: action;
  justify-self: end;
  button {
    ${getColor()}
  }
`;
