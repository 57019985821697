export const GET_MONTHLY_DEBIT_DETAILS = "GET_MONTHLY_DEBIT_DETAILS";
export const MONTHLY_DEBIT_DETAILS_SUCCESS = "DEBIT_DETAILS_SUCCESS";
export const MONTHLY_DEBIT_DETAILS_FAIL = "DEBIT_DETAILS_FAIL";

export const DEACTIVATE_MONTHLY_DEBIT = "DEACTIVATE_MONTHLY_DEBIT";
export const DEACTIVATE_MONTHLY_DEBIT_SUCCESS =
  "DEACTIVATE_MONTHLY_DEBIT_SUCCESS";
export const DEACTIVATE_MONTHLY_DEBIT_FAIL = "DEACTIVATE_MONTHLY_DEBIT_FAIL";

export const GENERAL_ERROR_MODAL = "GENERAL_ERROR_MODAL";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";

export const DEACTIVATION_ERROR_MODAL = "DEACTIVATION_ERROR_MODAL";

export const IMPOSSIBLE_DEACTIVATION_MODAL = "IMPOSSIBLE_DEACTIVATION_MODAL";

export const TOKEN_MODAL = "TOKEN_MODAL";

export const CURRENT_SECTION = "CURRENT_SECTION";
