import React, { useContext, useEffect } from "react";
import ResumenComisionHistorica from "./ResumenComisionHistorica";
import "./index.css";
import InfoUsuario from "./InfoUsuario";
import Grid from "@mui/material/Grid";

import Loading from "../../components/Loading/index.jsx";
import ReactGa from "react-ga";
import AuthContext from "../../context/autenticacion/authContext";
import ComisionHistoricaContext from "../../context/comisionhistorica/comisionhistoricaContext";

import ModalContext from "../../context/modal/modalContext";

import { useComisionHistorica } from "../../hooks/useComisionHistorica";
import { Page } from "../../components";

const ComisionHistorica = () => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const comisionHistoricaContext = useContext(ComisionHistoricaContext);
  const {
    datosComisionHistorica,
    mensajeAlerta,
    obtenerComisionHistorica,
    limpiarVariablesComisionHistoricaParcial,
  } = comisionHistoricaContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const { data } = useComisionHistorica(datosComisionHistorica);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerComisionHistorica();
    }

    return () => {
      limpiarVariablesComisionHistoricaParcial();
      actualizarTimer();
    };
    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (datosComisionHistorica === null) return <Loading />;

  return (
    <Page title="Comisión histórica en el SPP" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          {data && (
            <ResumenComisionHistorica
              dataTabla={data.tabla}
              fecha={datosComisionHistorica.r_fecha_afiliacion_spp}
            ></ResumenComisionHistorica>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <InfoUsuario />
        </Grid>
      </Grid>
    </Page>
  );
};

export default ComisionHistorica;
