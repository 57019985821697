import React from "react";
import { withRouter } from "react-router";
import { useEffect } from "react";
import Button from "../Button";
const ModalDetalle = ({
  dispositivo,
  buttonCloseTop,
  titulo,
  closeModal,
  closeBtn,
  view,
  loading,
  dato,
  componenteInfo,
}) => {
  useEffect(() => {
    // limpiarMensajeErrorPost();
    // eslint-disable-next-line
  }, []);

  const procesarSolicitud = () => {
    closeBtn();
  };

  return (
    <section
      className={
        buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`
      }
    >
      <div
        className={
          buttonCloseTop ? `modal-content` : `modal-content modal-content-full`
        }
      >
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={closeModal()}></i>
          </div>
        )}

        <div
          className={
            buttonCloseTop
              ? `mb-modal-title d-flex mt-3`
              : `mb-modal-title d-flex`
          }
        >
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>{titulo}</strong>
          </p>
        </div>
        <section>
          {componenteInfo}

          <section className="d-flex justify-content-around">
            <Button onClick={closeModal()} className="btn-white">
              Cancelar
            </Button>
            {view === "Jubilación" && (dato === 6 || dato === 5) ? null : (
              <Button onClick={procesarSolicitud} className="btn-white">
                Aceptar{" "}
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
            )}
          </section>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalDetalle);
