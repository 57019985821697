import React, { useState } from "react";
import {
  ButtonsWrapper,
  Considerations,
  InputsWrapper,
  NextButton,
  PasoDatosDelSolicitanteComponent,
  PersonTypeInformation,
  PersonTypeWrapper,
  PrevButton,
} from "./styles";
import SectionTitle from "../components/SectionTitle";
import * as Input from "../../../components/Input";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import VerificacionDeEmail from "./VerificacionDeEmail";
import { size } from "../../../utils/device";
import ModalDeTratamientoDeDatos from "./ModalTratamientoDeDatos";
import ExternalLink from "../components/ExternalLink";
const PasoDatosDelSolicitante = ({ handleStepNavigation }) => {
  const [verificationEmailModalIsOpen, setVerificationEmailModalIsOpen] =
    useState(false);
  const [tratamientoDeDatosModalIsOpen, setTratamientoDeDatosModalIsOpen] =
    useState(false);

  const personTypeOptions = [
    { label: "Persona natural", value: "natural" },
    { label: "Persona jurídica", value: "juridica" },
  ];

  const relationshipOptions = [
    { label: "Cónyuge", value: "conyuge" },
    { label: "Conviviente", value: "conviviente" },
    { label: "Hijo(a)", value: "hijo(a)" },
    { label: "Hermano(a)", value: "hermano(a)" },
    { label: "Padre / Madre", value: "padre-madre" },
    { label: "Otro", value: "otro" },
  ];

  const { watch, setValue, getFieldState, formState, getValues } =
    useFormContext();
  const { errors } = formState;
  // comment if not needed just for development purposes
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      const getDefaultValues = () => {
        const deafultValues = {
          "datos-solicitante.person-type": false ? "natural" : "juridica",
          "datos-solicitante.fullname": "Julio Huaroto",
          "datos-solicitante.document-type": "00",
          "datos-solicitante.document-value": "86597056",
          "datos-solicitante.cellphone": "+51 891 864 891",
          "datos-solicitante.email": "julio.benavente.02@gmail.com",
          "datos-solicitante.relationship": "conyuge",
          "datos-solicitante.data-processing": "86597056",
          "datos-solicitante.ruc": "86597056",
          "datos-solicitante.business-name": "Mi empresa SA",
        };

        for (let key in deafultValues) {
          setValue(key, deafultValues[key]);
        }
      };

      // getDefaultValues();
    }
  }, []);

  const canContinue =
    watch("datos-solicitante.person-type") === "natural"
      ? watch("datos-solicitante.first-name") &&
        watch("datos-solicitante.first-lastname") &&
        watch("datos-solicitante.document-type") &&
        watch("datos-solicitante.document-value") &&
        watch("datos-solicitante.cellphone") &&
        watch("datos-solicitante.email") &&
        watch("datos-solicitante.relationship") &&
        watch("datos-solicitante.data-processing") &&
        !errors?.["datos-solicitante"]
      : watch("datos-solicitante.person-type") === "juridica"
      ? watch("datos-solicitante.first-name") &&
        watch("datos-solicitante.first-lastname") &&
        watch("datos-solicitante.document-type") &&
        watch("datos-solicitante.document-value") &&
        watch("datos-solicitante.cellphone") &&
        watch("datos-solicitante.email") &&
        watch("datos-solicitante.ruc") &&
        watch("datos-solicitante.business-name") &&
        watch("datos-solicitante.data-processing") &&
        !errors?.["datos-solicitante"]
      : null;

  const onContinue = () => setVerificationEmailModalIsOpen(true);
  const onPrev = () => handleStepNavigation("previous");

  return (
    <PasoDatosDelSolicitanteComponent>
      <SectionTitle>Datos del solicitante</SectionTitle>

      <PersonTypeWrapper $shrink={watch("datos-solicitante.person-type")}>
        <Input.Wrapper>
          <Input.Title required={true}>Tipo de solicitante</Input.Title>
          <Input.Select
            name="datos-solicitante.person-type"
            options={personTypeOptions}
            placeholder="Selecciona uno"
          />
          <Input.ErrorMessage name="datos-solicitante.person-type" />
        </Input.Wrapper>
      </PersonTypeWrapper>
      <Divider marginTop={"0px"} />
      {watch("datos-solicitante.person-type") && (
        <>
          <Considerations>
            <p>Tomar en consideración lo siguiente:</p>
            <ul>
              <li>
                <b>
                  Los datos como nombres y apellidos deben encontrarse
                  completos.
                </b>{" "}
                Si estos no son los correctos, no podremos atender tu solicitud.
              </li>
              <li>
                <b>
                  Registra de manera completa y correcta tus datos de contacto.{" "}
                </b>
              </li>
              <li>
                Te notificaremos sobre el estado de tu solicitud mediante el
                correo electrónico registrado. Te recomendamos revisarlo de
                forma constante.
              </li>
            </ul>
          </Considerations>
        </>
      )}
      {!watch("datos-solicitante.person-type") && (
        <PersonTypeInformation>
          <div>
            <p className="subtitle">Persona natural:</p>
            <p className="information">
              Los gastos funerarios fueron cubiertos por una persona natural
              identificada con: DNI, Carnet de extranjería o Pasaporte.
            </p>
          </div>
          <div>
            <p className="subtitle">Persona jurídica:</p>
            <p className="information">
              Los gastos funerarios fueron cubiertos por una persona jurídica
              con RUC 20.
            </p>
          </div>
        </PersonTypeInformation>
      )}
      <InputsWrapper>
        {watch("datos-solicitante.person-type") && (
          <>
            <Input.Wrapper>
              <Input.Title required={true}>Primer nombre</Input.Title>
              <Input.Text
                name="datos-solicitante.first-name"
                placeholder={"Primer nombre"}
                size="lg"
                minLength={2}
                maxLength={30}
                justText
              />
              <Input.ErrorMessage name="datos-solicitante.first-name" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={false}>Segundo nombre</Input.Title>
              <Input.Text
                name="datos-solicitante.second-name"
                placeholder={"Segundo nombre"}
                size="lg"
                minLength={2}
                maxLength={30}
                justText
                required={false}
              />
              <Input.ErrorMessage name="datos-solicitante.second-name" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Primer apellido</Input.Title>
              <Input.Text
                name="datos-solicitante.first-lastname"
                placeholder={"Nombres apellidos"}
                size="lg"
                minLength={2}
                maxLength={30}
                justText
              />
              <Input.ErrorMessage name="datos-solicitante.first-lastname" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={false}>Segundo apellido</Input.Title>
              <Input.Text
                name="datos-solicitante.second-lastname"
                placeholder={"Segundo apellido"}
                size="lg"
                minLength={2}
                maxLength={30}
                justText
                required={false}
              />
              <Input.ErrorMessage name="datos-solicitante.second-lastname" />
            </Input.Wrapper>
          </>
        )}

        {watch("datos-solicitante.person-type") === "natural" && (
          <>
            <Input.Wrapper>
              <Input.Title required={true}>Documento de identidad</Input.Title>
              <Input.Document
                documentProps={{ name: "datos-solicitante.document-type" }}
                valueProps={{ name: "datos-solicitante.document-value" }}
              />
              <Input.ErrorMessage name="datos-solicitante.document-value" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Celular</Input.Title>
              <Input.Cellphone name="datos-solicitante.cellphone" />
              <Input.ErrorMessage name="datos-solicitante.cellphone" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Correo electrónico</Input.Title>
              <Input.Text
                name="datos-solicitante.email"
                placeholder={"ej. usuario@email.com"}
                size="lg"
                email={true}
                minLength={3}
                maxLength={30}
              />
              <Input.ErrorMessage name="datos-solicitante.email" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>
                Parentesco con el afiliado
              </Input.Title>
              <Input.Select
                name="datos-solicitante.relationship"
                options={relationshipOptions}
                placeholder="Selecciona uno"
              />
              <Input.ErrorMessage name="datos-solicitante.relationship" />
            </Input.Wrapper>
          </>
        )}
        {watch("datos-solicitante.person-type") === "juridica" && (
          <>
            <Input.Wrapper>
              <Input.Title required={true}>
                Documento de identidad del solicitante
              </Input.Title>
              <Input.Document
                documentProps={{ name: "datos-solicitante.document-type" }}
                valueProps={{ name: "datos-solicitante.document-value" }}
              />
              <Input.ErrorMessage name="datos-solicitante.document-value" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Celular</Input.Title>
              <Input.Cellphone name="datos-solicitante.cellphone" />
              <Input.ErrorMessage name="datos-solicitante.cellphone" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Correo electrónico</Input.Title>
              <Input.Text
                name="datos-solicitante.email"
                placeholder={"ej. usuario@email.com"}
                size="lg"
                email={true}
                minLength={3}
                maxLength={30}
              />
              <Input.ErrorMessage name="datos-solicitante.email" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>RUC</Input.Title>
              <Input.Number
                name="datos-solicitante.ruc"
                size="lg"
                placeholder={"ej. 20551464971"}
                minLength={11}
                maxLength={11}
              />
              <Input.ErrorMessage name="datos-solicitante.ruc" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title required={true}>Razón social</Input.Title>
              <Input.Text
                name="datos-solicitante.business-name"
                placeholder={"ej. AFP Habitat SA"}
                size="lg"
                minLength={3}
                maxLength={30}
              />
              <Input.ErrorMessage name="datos-solicitante.business-name" />
            </Input.Wrapper>
          </>
        )}
      </InputsWrapper>
      <>
        <ButtonsWrapper>
          {watch("datos-solicitante.person-type") && (
            <Input.Wrapper style={{ gridColumn: "1/-1" }}>
              <Input.Checkbox name="datos-solicitante.data-processing">
                Acepto el{" "}
                <ExternalLink
                  onClick={() => setTratamientoDeDatosModalIsOpen(true)}
                >
                  tratamiento de datos personales
                </ExternalLink>{" "}
                para fines adicionales
              </Input.Checkbox>
              <Input.ErrorMessage name="datos-solicitante.data-processing" />
            </Input.Wrapper>
          )}
          <PrevButton fullWidth variant="secondary" onClick={onPrev}>
            Volver
          </PrevButton>
          <NextButton fullWidth disabled={!canContinue} onClick={onContinue}>
            Continuar
          </NextButton>
        </ButtonsWrapper>
      </>
      <VerificacionDeEmail
        open={verificationEmailModalIsOpen}
        setOpen={setVerificationEmailModalIsOpen}
        handleStepNavigation={handleStepNavigation}
      />
      <ModalDeTratamientoDeDatos
        open={tratamientoDeDatosModalIsOpen}
        setOpen={setTratamientoDeDatosModalIsOpen}
      />
    </PasoDatosDelSolicitanteComponent>
  );
};

export default PasoDatosDelSolicitante;

const useAllFieldsAreValid = (fields = []) => {
  const { getFieldState, formState } = useFormContext();

  const allFieldsPassValidation = fields.every((field) => {
    console.log({
      field,
      invalid: getFieldState(field, formState).invalid,
    });

    return !getFieldState(field, formState).invalid;
  });

  return allFieldsPassValidation;
};
