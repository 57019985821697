import React, { useState } from "react";
import styled from "styled-components";
import { ContentFlex, FlexItem, TextAll } from "../../componentes";
import IC_UNCHECKED from "../../../../assets/icons/unchecked.svg";
import IC_CHECKED from "../../../../assets/icons/checked.svg";

const DivContent = styled.div`
  border: ${(props) => (props.active ? "1.5px solid #569BBE" : "0")};
  box-shadow: ${(props) =>
    props.active ? "0" : "0px 4px 16px rgba(0, 0, 0, 0.12)"};
  border-radius: 16px;
  // margin: 0px 6px;
  cursor: pointer;
`;

const SimpleListaCheck = ({
  options,
  optionSelected,
  onChange,
  flexDirection = "row",
}) => {
  const [index, setIndex] = useState(0);
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <ContentFlex
        color="#ffffff"
        padding="0px"
        flexDirection={flexDirection}
        alignItems="initial"
        width={flexDirection === "column" ? "100%" : "auto"}
      >
        {options?.map((e, i) => (
          <FlexItem
            width={flexDirection === "column" ? "100%" : "auto"}
            size="1"
            key={i}
            padding={index % 2 === 1 ? "8px 4px 8px 8px" : "8px 0px 4px"}
          >
            <DivContent
              active={e.val === optionSelected.val}
              onClick={() => onChange(options[i])}
              style={{
                width: "100%",
                minHeight: "48px",
              }}
            >
              <OpcionSimpleListaCheck
                active={e.val === optionSelected.val}
                titulo={e}
                size={
                  flexDirection === "column"
                    ? ["1", "2", "12"]
                    : ["1", "2", "6"]
                }
              />
            </DivContent>
          </FlexItem>
        ))}
      </ContentFlex>
    </div>
  );
};

const OpcionSimpleListaCheck = ({
  active = false,
  titulo,
  size = ["1", "2", "6"],
}) => {
  return (
    <ContentFlex color="#ffffff" padding="0px">
      <FlexItem size={size[1]}>
        <img src={active ? IC_CHECKED : IC_UNCHECKED} />
      </FlexItem>
      <FlexItem size={size[2]} justifyContent="flex-start">
        <TextAll
          fontWeight="400"
          fontSize="14px"
          lineHeight="20px"
          padding="8px 0px"
        >
          {titulo.question}
        </TextAll>
      </FlexItem>
    </ContentFlex>
  );
};

export default SimpleListaCheck;
