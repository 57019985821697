import React from "react";
import Modal from "../../../components/BasicModal";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import CloseIcon from "../../../assets/IconBucket/CloseIcon";
import { IconWrapper, Title } from "./styles";

const ModalDeErrorGeneral = ({ isOpen, setIsOpen, title, subtitle }) => {
  const closeModal = () => setIsOpen(false);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Title>
        <IconWrapper>
          <CloseIcon />
        </IconWrapper>{" "}
        <span>{title}</span>
      </Title>
      <Modal.Subtitle>{subtitle}</Modal.Subtitle>
      <Divider />
      <Button fullWidth style={{ margin: "auto" }} onClick={closeModal}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
