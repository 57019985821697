import React, { useState, useEffect, useContext } from "react";
import ModalClave from "./components/Modal/Modal";
import FormOne from "./components/Forms/Forms";
import Bloqueado from "./errors/vistaBloqueada";
import GenerarClaveContext from "../../context/generarClave/generarClaveContext";
import "./index.css";


const Step01 = (props) => {

    const { changeStep, changeStepOne, bloqueado } = props;
    const generarClaveContexto = useContext(GenerarClaveContext);
    const { gBloqueoPasos,bloquearPasos } = generarClaveContexto;
    const [openModal, setOpenModal] = useState(false)
    const [block, setBlock] = useState(gBloqueoPasos) //false

    const changeOpenModal = () => {
        setOpenModal(true)
    }

    const bloquearP = () => {
        setBlock(true)
        bloquearPasos(true)
    }

    const closeModalClave = () => {
        setOpenModal(false)
    }

    useEffect(()=>{
        if(bloqueado) 
        {
            setBlock(true)
            bloquearPasos(true)
        }
    },[bloqueado])


    return (
        <div >
            <div >
                <p>Antes de proceder con la generación de tu clave web, es necesario
                    <b> validar tu identidad. </b>
                </p>

            </div>
            <div className="privatezone-mobile">
                <FormOne bloquear={bloquearP} changeOpenModal={changeOpenModal}> </FormOne>

                {block
                    ?
                    <Bloqueado></Bloqueado>
                    : ''
                }
            </div>
            {(openModal)
                ? <ModalClave bloquear={bloquearP} changeStep={changeStep} openModal={openModal} close={closeModalClave} changeStepOne={changeStepOne}></ModalClave>
                : ''}

        </div>
    );
};

export default Step01;
