import React from "react";
import Modal from "../../../components/BasicModal";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import CloseIcon from "../../../assets/IconBucket/CloseIcon";
import { IconWrapper, Title } from "./styles";
import { useMonthlyDebitDetailsContext } from "../context";

const ModalDeErrorGeneral = () => {
  const {
    state: { generalErrorModal },
    ...api
  } = useMonthlyDebitDetailsContext();

  return (
    <Modal open={generalErrorModal} onClose={api.closeGeneralErrorModal}>
      <Title>
        <IconWrapper>
          <CloseIcon />
        </IconWrapper>{" "}
        <span>Ocurrió un problema</span>
      </Title>
      <Modal.Subtitle>
        Ocurrió un error inesperado desde nuestro servicio. Inténtalo más tarde.
      </Modal.Subtitle>
      <Divider />
      <Button
        fullWidth
        style={{ margin: "auto" }}
        onClick={api.closeGeneralErrorModal}
      >
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
