import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import Contador from "./Contador";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import CodeContext from "../../context/code/codeContext";
import Loading from "./Loading";
import MensajeAlerta from "./MensajeAlerta";
import { Theme } from "../../theme/createTheme";
import { ThemeButton } from "../../theme/createTheme";

/* Components MUI */
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  CircularProgress,
  Slide,
  DialogActions,
  Button,
  Skeleton,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

import LockedIcon from "../../assets/IconBucket/Icon/LockedIcon.jsx";

/* Icon MUI */

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "0px",
    "& .MuiSelect-iconStandard": {
      color: "#F6F6F6",
    },
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
    fonSize: "10px",
  },
  paperStylesChages: {
    "& .MuiDialog-paper": {
      borderRadius: "0px",
    },
    fonSize: "10px",
  },
  titleStyles: {
    color: palletteColors.Red,
  },
  buttonUpCloseStyles: {
    color: palletteColors.Red,
  },
  buttonsDownStyles: {
    borderRadius: "0px 16px 16px 0px",
    height: "55px",
    width: "100%",
    color: "#CE1336",
    backgroundColor: "#F6F6F6",
    borderLeft: "1px solid #EDEDED",
    "& .MuiButton-contained.Mui-disabled": {
      color: "red",
    },
    "&. .Mui-focusVisible": {
      backgroundColor: "#F6F6F6",
    },
  },
  textFieldFilledStyles: {
    "& .MuiFilledInput-root": {
      borderRadius: " 16px",
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: palletteColors.disabledBg,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ValidateToken = ({
  cerrarModal,
  guardarCode,
  setRequestInfoDialog,
  children,
  activeChildren,
  setMostrarModalToken,
  setMostrarMensajeInfo,
}) => {
  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();

  const matches = useMediaQuery("(min-width:980px)");

  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeButton, setActiveButton] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");
  const [lugarDeEnvio, setLugarDeEnvio] = useState("");
  const [token, setToken] = useState("");
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [mensajeCodigoValidado, setMensajeCodigoValidado] = useState(null);
  const [messageThirdOption, setMessageThirdOption] = useState(false);
  const [reenviarCodigo, setReenviarCodigo] = useState({
    activo: false, // true puedo solicitar clave // false no puedo
    cargando: false, // true solicitando clave // false dejo de solicitar
    solicitar: false, // true hizo una petición de carga // false termino
  });

  const codeContext = useContext(CodeContext);
  const {
    dataModalCode,
    mensajeGetAlerta,
    dataPostCode,
    mensajePostAlerta,
    obtenerDatosCode,
    limpiarVariablesCodePost,
    solicitarCode,
  } = codeContext;

  useEffect(() => {
    if (mensajeGetAlerta) {
      console.log("HUBO UN ERROR AL SOLICITAR TU CODIGO");
    } else {
      obtenerDatosCode();
    }

    return () => {
      limpiarVariablesCodePost();
    };

    // eslint-disable-next-line
  }, [mensajeGetAlerta]);

  useEffect(() => {
    if (dataModalCode) {
      if (dataModalCode.validate === 2) {
        actualizarDataSeleccionada(
          "celular: ",
          "SMS",
          dataModalCode.info.celular
        );
      } else if (dataModalCode.validate === 1) {
        actualizarDataSeleccionada(
          "correo: ",
          "EMAIL",
          dataModalCode.info.email
        );
      }
    }
  }, [dataModalCode]);

  const actualizarDataSeleccionada = (tipo, code, text) => {
    setOptionSelect(tipo + text);
    setLugarDeEnvio(code);
  };

  const elegir = (e) => {
    let valor = e.target.value;
    if (valor === "-1") {
      setMessageThirdOption(true);
      setOptionSelect(valor);
      setActiveButton(false);
    } else {
      let lugarDeEnvio = e.target.value.includes("correo") ? "EMAIL" : "SMS";
      setOptionSelect(valor);
      setActiveButton(true);
      setLoadingEnvio(false);
      setLugarDeEnvio(lugarDeEnvio);
      setMessageThirdOption(false);
      limpiarVariablesCodePost();
    }
  };

  const enviarClave = (option) => (e) => {
    e.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.set("channel", lugarDeEnvio);
    if (option === "enviar") {
      setLoadingEnvio(true);
      setActiveButton(false);
      solicitarCode(bodyFormData)
        .then(() => {
          setLoadingEnvio(false);
          setReenviarCodigo({ ...reenviarCodigo, solicitar: true });
        })
        .catch((error) => {
          setActiveButton(true);
        });
    } else if (option === "re-enviar") {
      setReenviarCodigo({
        activo: false,
        cargando: true,
        solicitar: false,
      });
      solicitarCode(bodyFormData)
        .then(() => {
          setReenviarCodigo({
            ...reenviarCodigo,
            activo: false,
            cargando: false,
            solicitar: true,
          });
        })
        .catch((error) => {
          setActiveButton(true);
        });
    }
  };

  const activarEnvio = (option) => {
    setActiveButton(true);
    setReenviarCodigo({ ...reenviarCodigo, activo: option, solicitar: false });
  };

  const handleToken = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setToken(valor);
    setMensajeCodigoValidado("");
  };

  const cerrarModalLocal = () => {
    limpiarVariablesCodePost();
    cerrarModal();
  };

  const validarData = () => {
    let mensajeCodigo = "";
    if (token.length !== 0) {
      if (token.length < 6) {
        mensajeCodigo = "El token digital es de 6 dígitos";
      }
    } else {
      mensajeCodigo = "El token digital es requerido.";
    }

    if (mensajeCodigo.length > 0) {
      setMensajeCodigoValidado(mensajeCodigo);
      return false;
    } else {
      return true;
    }
  };

  const procesar = () => {
    // setMostrarModalToken(null);
    // setMostrarMensajeInfo(true);
    if (validarData()) {
      setLoadingEnvio(true);
      guardarCode(token);
    }
  };

  return (
    <Box>
      {mensajeGetAlerta !== null ? (
        <p></p>
      ) : dataModalCode === null ? (
        <Grid container spacing={2} sx={{ border: "none" }}>
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={20}
              sx={{ borderRadius: "16px", maxWidth: "220px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={55}
              sx={{ borderRadius: "16px", minWidth: "320px" }}
            />
          </Grid>
          {activeChildren && (
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={140}
                sx={{ borderRadius: "16px", minWidth: "320px" }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={55}
              sx={{ borderRadius: "16px", minWidth: "320px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                pt: 1,
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"60%"}
                height={20}
                sx={{ borderRadius: "16px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={40}
              sx={{
                borderRadius: "40px",
                mt: 2,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}
            >
              Confirma tu solicitud
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              Por tu seguridad, necesitamos validar tu identidad
            </Typography>
          </Grid>
          {activeChildren && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: "100%",
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "16px  0px 0px 16px",
                    p: 1,
                  }}
                >
                  <LockedIcon />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ pb: 2 }}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Método de confirmación
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      onChange={elegir}
                      value={optionSelect}
                      disableUnderline
                      IconComponent={() => null}
                      sx={{
                        borderRight: "1px solid #ACAFB3",
                        "&:hover": {
                          background: "#F6F6F6",
                        },
                      }}
                    >
                      {dataModalCode.info.email && (
                        <MenuItem
                          value={JSON.stringify(
                            "correo: " + dataModalCode.info.email
                          )}
                        >
                          Correo: {dataModalCode.info.email}
                        </MenuItem>
                      )}
                      {dataModalCode.info.celular && (
                        <MenuItem
                          value={JSON.stringify(
                            "celular: " + dataModalCode.info.celular
                          )}
                        >
                          Celular: {dataModalCode.info.celular}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="buttonModalToken"
                  onClick={enviarClave("enviar")}
                  disabled={optionSelect === "" || !activeButton ? true : false}
                  // disableRipple
                >
                  {loadingEnvio ? <CircularProgress size={20} /> : "Enviar"}
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      "& .MuiFilledInput-root": {
                        borderRadius: " 16px ",
                        "& .Mui-disabled": {
                          backgroundColor: "#F6F6F6",
                          borderRadius: " 16px ",
                        },
                      },
                    }}
                    label="Código de confirmación"
                    type="text"
                    name="token"
                    value={token}
                    onChange={handleToken}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      pattern: "[0-9]*",
                      maxLength: "6",
                    }}
                    disabled={!reenviarCodigo.solicitar ? true : false}
                  />
                </FormControl>
              </Grid>
              {mensajePostAlerta && (
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: "14px",
                        color: "#bf253d",
                      }}
                    >
                      Hubo un error al generar su código, por favor vuelva a
                      intentarlo
                    </Typography>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box sx={{ pt: 1 }}>
                  <Typography sx={{ fontSize: "14px", color: "#6E7378" }}>
                    En caso no te llegue el código, podrás volver a solicitarlo{" "}
                    {reenviarCodigo.solicitar ? (
                      <Contador
                        activarEnvio={activarEnvio}
                        iniciaContador={reenviarCodigo.solicitar}
                      />
                    ) : (
                      <strong>ahora</strong>
                    )}
                  </Typography>
                </Box>
              </Grid>
              {mensajeCodigoValidado && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",

                      pt: 1,
                    }}
                  >
                    <Typography color="primary" sx={{ fontSize: "14px" }}>
                      {mensajeCodigoValidado}*
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    pt: 2,
                    pb: 4,
                  }}
                >
                  <Typography
                    sx={{ color: "#2F6D95", textDecoration: "underline" }}
                    onClick={() => {
                      setRequestInfoDialog(true);
                      cerrarModalLocal();
                    }}
                  >
                    ¿No puedes validar esta solicitud?
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      disabled={loadingEnvio}
                      variant="contained"
                      sx={{
                        borderRadius: "16px",
                        width: "100%",
                        "&&:hover::before": {
                          color: "#CE1336",
                        },
                      }}
                      onClick={procesar}
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ValidateToken;
