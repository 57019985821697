/** BOLETAS DE PAGO DE PENSIÓN */
export const BOLETA_PERIODO_EXITOSO = 'BOLETA_PERIODO_EXITOSO';
export const BOLETA_PERIODO_ERROR = 'BOLETA_PERIODO_ERROR';
export const BOLETA_PERIODO_LOADIN = 'BOLETA_PERIODO_LOADIN';

export const BOLETA_CATEGORIA_EXITOSO = 'BOLETA_CATEGORIA_EXITOSO';
export const BOLETA_CATEGORIA_ERROR = 'BOLETA_CATEGORIA_ERROR';
export const BOLETA_CATEGORIA_LOADIN = 'BOLETA_CATEGORIA_LOADIN';
export const CLEAR_BENEFICIARIOS = 'CLEAR_BENEFICIARIOS';


export const BOLETA_PERIODO_Y_CATEGORIA_CLEANER = 'BOLETA_PERIODO_Y_CATEGORIA_CLEANER';
export const BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL = 'BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL';
export const BOLETA_PERIODO_Y_CATEGORIA_CLEANER_ONLY_VARIABLE = 'BOLETA_PERIODO_Y_CATEGORIA_CLEANER_ONLY_VARIABLE';
export const CLEAR_BOLETA = 'CLEAR_BOLETA';

export const BOLETA_PAGO_EXITOSO = 'BOLETA_PAGO_EXITOSO';
export const BOLETA_PAGO_ERROR = 'BOLETA_PAGO_ERROR';
export const BOLETA_PAGO_LOADIN = 'BOLETA_PAGO_LOADIN';
export const BOLETA_PAGO_CLEANER = 'BOLETA_PAGO_CLEANER';