import {
  APORTAR_APVSINFIN_GET_EXITOSO,
  APORTAR_APVSINFIN_GET_ERROR,
  APORTAR_APVSINFIN_GET_LOADIN,
  APORTAR_APVSINFIN_CLEANER,
  APORTAR_APVSINFIN_CLEANER_PARCIAL,
  APORTAR_APVSINFIN_POST_EXITOSO,
  APORTAR_APVSINFIN_POST_ERROR,
  APORTAR_APVSINFIN_POST_LOADIN,
  APORTAR_APVSINFIN_CLEANER_MENSAJE,
  APORTAR_RESETEAR,
  APORTAR_CLEAR_MENSAJE_POST,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case APORTAR_APVSINFIN_GET_EXITOSO:
      /** r_validacion puede ser
       * 0 // no puede realizar un aporte y se mostrara un mensaje
       * 1 // debe seleccionar un fondo, la lista de fonos llega en r_fondo
       * 2 // ya tiene un fonso y solo se le debe mostrar la respuesta llega en r_fondo
       */
      const info = action.payload; //
      const tieneMensaje =
        action.payload.r_validacion === "0" && action.payload.r_mensaje;

      return {
        ...state,
        datosAportarApvSinFin: info,
        mensajeInformativo: tieneMensaje,
        loadingPage: false,
      };
    case APORTAR_APVSINFIN_GET_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case APORTAR_APVSINFIN_CLEANER_MENSAJE:
      return {
        ...state,
        mensajeInformativo: null,
      };
    case APORTAR_APVSINFIN_GET_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case APORTAR_APVSINFIN_CLEANER:
      return {
        ...state,
        datosAportarApvSinFin: null,
        mensajeInformativo: null,
        mensajeAlerta: null,
        mensajeAlertaPost: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoAportarApvSinFin: null,
      };
    case APORTAR_APVSINFIN_CLEANER_PARCIAL:
      return {
        ...state,
        datosAportarApvSinFin: null,
        mensajeInformativo: null,
        mensajeAlertaPost: null,
        // mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoAportarApvSinFin: null,
      };
    case APORTAR_RESETEAR:
      return {
        ...state,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoAportarApvSinFin: null,
      };
    case APORTAR_APVSINFIN_POST_EXITOSO:
      return {
        ...state,
        datosResultadoAportarApvSinFin: action.payload,
        mensajeAlertaPost: null,
        loadingBtn: false,
      };
    case APORTAR_APVSINFIN_POST_LOADIN:
      return {
        ...state,
        loadingBtn: true,
        mensajeAlertaPost: null,
      };
    case APORTAR_APVSINFIN_POST_ERROR:
      return {
        ...state,
        mensajeAlertaPost: action.payload,
        loadingBtn: false,
      };
    case APORTAR_CLEAR_MENSAJE_POST:
      return {
        ...state,
        mensajeAlertaPost: null,
      };
    default:
      break;
  }
};
