import styled from "styled-components";
import { Box } from "@mui/material";

export const IconConent = styled(Box)`
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.palette.secondary.main};
  background-color: ${(p) => p.theme.palette.secondary.lightest};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  padding: ${(p) => p.padding};
  display: flex;
  align-items: center;
  & img {
    width: 100%;
  }
`;
