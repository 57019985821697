import React, { useContext, useEffect } from "react";
import {
  Body,
  Buttons,
  Header,
  Information,
  ModalDeConfirmacionComponent,
  NextButton,
  PrevButton,
} from "./styles";
import { Modal } from "@mui/material";
import ReactivarAporteAlerta from "../components/ReactivarAporteAlerta";
import CloseIcon from "../../../assets/IconBucket/CloseIcon";
import { useMonthlyDebitDetailsContext } from "../context/index";

const ModalDeConfirmacion = () => {
  const {
    state: { confirmationModal },
    closeConfirmationModal,
    openTokenModal,
  } = useMonthlyDebitDetailsContext();

  const handleNextButtom = () => {
    openTokenModal();
    closeConfirmationModal();
  };

  return (
    <Modal open={confirmationModal} onClose={() => closeConfirmationModal()}>
      <ModalDeConfirmacionComponent>
        <Header>
          <div className="close" onClick={() => closeConfirmationModal()}>
            <CloseIcon />
          </div>
          <p className="title">
            ¿Estás seguro que deseas desactivar tu débito mensual?
          </p>
        </Header>
        <Body>
          <Information>
            <p>
              Ya no debitaremos de tu cuenta para invertir automáticamente en
              Fondo Libre Habitat.
            </p>
          </Information>
        </Body>
        <ReactivarAporteAlerta />
        <Buttons>
          <PrevButton onClick={() => closeConfirmationModal()}>
            Volver
          </PrevButton>
          <NextButton onClick={handleNextButtom}>Siguiente</NextButton>
        </Buttons>
      </ModalDeConfirmacionComponent>
    </Modal>
  );
};

export default ModalDeConfirmacion;
