import React from "react";
import { Icon, InputWrapper, TextInputComponent } from "./styles";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import getDeepValue from "../../../utils/getDeepValue";

const TextInput = ({
  name,
  size,
  error,
  icon,
  disabled,
  placeholder,
  email,
  justText,
  minLength,
  maxLength,
  required,
  validate,
  wrapperProps,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isError = error || getDeepValue(errors, name);

  return (
    <InputWrapper {...wrapperProps}>
      <TextInputComponent
        type="text"
        error={isError}
        placeholder={placeholder}
        disabled={disabled}
        $icon={Boolean(icon)}
        size={size}
        {...register(name, {
          required,
          minLength: {
            value: minLength,
            message: `Se necesita un mínimo de ${minLength} caracteres.`,
          },
          maxLength: {
            value: maxLength,
            message: `No puede tener más de ${maxLength} caracteres.`,
          },
          validate: {
            justText: (e) => {
              if (!justText || !e || e?.length < 2) {
                return null;
              }

              const digitsPattern = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;
              const justLetters = e.match(digitsPattern);

              if (justLetters) {
                return true;
              }

              return "No se adminten números ni caracteres.";
            },
            isEmail: (e) => {
              if (!email) {
                return null;
              }
              const emailValidationPattern =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              const isValid = e.match(emailValidationPattern);

              if (isValid) {
                return true;
              }

              return "El formato de email no es correcto.";
            },
            ...validate,
          },
        })}
        {...props}
      />
      {icon && (
        <Icon disabled={disabled} error={isError}>
          {icon}
        </Icon>
      )}
    </InputWrapper>
  );
};

export default TextInput;

TextInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  error: PropTypes.bool,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  justText: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperProps: PropTypes.any,
  validate: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  email: PropTypes.bool,
};

TextInput.defaultProps = {
  name: "inputValue",
  size: "md",
  error: false,
  required: { value: true, message: "Este campo es requerido." },
  disabled: false,
  placeholder: "Placeholder",
};
