import React, { useState } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { montoFormat } from "../../helper/amountFormat";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper.jsx";

import InputMask from "react-input-mask";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Paper,
  Alert,
} from "@mui/material";
import CurrencyInput from "./InputMask";
import Cards from "./Cards";
import SelectCard from "./SelectCard";
import ModalToken from "../../components/ModalToken";
// import { Button } from "../../components";
import { TramRounded } from "@mui/icons-material";
// import CodeContext from "../../context/code/codeContext";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  iconBlue: "#0043CE",
  disabledText: "#CDCED0",
  disabledBg: "#F8F9FB",
};

/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};
/* estilos en className */
const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainerStyles: {
      borderRadius: "16px",
      boxShadow: 2,
      userSelect: "none",
    },
    paperContainerStylesChanges: {
      borderRadius: "0px",
      border: "0px",
      boxShadow: "none",
      userSelect: "none",
    },
    selectStyles: {
      "& .MuiSelect-filled": {
        borderRadius: "16px",
      },
      "& .MuiSelect-filled.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    textFieldStyles: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "16px",
      },
    },
    textFieldFilledStyles: {
      "& .MuiFilledInput-root": {
        borderRadius: "16px",
      },
      "& .MuiFilledInput-root.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    boxDescriptionStyles: {
      display: "flex",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    boxFondoStyles: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      color: palletteColors.GreyText,
    },
    boxWarningStyles: {
      mt: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    iconStyles: {
      color: palletteColors.iconBlue,
    },
  })
);
const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});
const PrimerPaso = (props) => {
  const [formaRetiro, setFormaRetiro] = useState("P"); // 2 es parcial 1 es total
  const [montoRetiro, setMontoRetiro] = useState("");
  const [cuentaDestino, setCuentsDestino] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [mensajeLocal, setMensajeLocal] = useState(null);
  /* Estado para la BasicCard */
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("-1");
  /* estados para el stepper */
  const [stepLength, setStepLength] = useState([
    "Datos de retiro",
    "Verificación de solicitud",
    "Confirmación de solicitud",
  ]);
  const [step, setStep] = useState(1);
  const { data, montoTotal, windowWidth } = props;

  const title =
    windowWidth < 600
      ? "Selecciona la cuenta destino"
      : "Selecciona la cuenta donde abonaremos tu retiro";

  const disableMonto = formaRetiro === "P" ? false : true;
  const disableMontoClass =
    formaRetiro === "P" ? "custom-mask" : "custom-mask custom-mask-disable";
  const cardOrSelect = windowWidth < 600 && data.length > 2 ? true : false;

  const handleMonto = (e) => {
    setMontoRetiro(e.target.value);
    setMensajeLocal(null);
  };

  const handleRadio = (option) => (e) => {
    e.preventDefault();
    if (option === "T") {
      // retiro parcial
      setMontoRetiro(montoFormat(montoTotal));
    } else {
      setMontoRetiro("");
    }
    setFormaRetiro(option);
    setMensajeLocal(null);
  };

  const validarData = () => {
    let mensajeMonto = "";
    let mensajeSeleccioneCuenta = "";
    let montoLocal;
    if (montoRetiro.length > 0) {
      montoLocal = montoRetiro.replace("S/ ", "").replace(/,/g, "");
      if (parseFloat(montoLocal) === 0.0) {
        mensajeMonto = "El monto a retirar debe ser mayor a 0, ";
      } else {
        if (parseFloat(montoLocal) > parseFloat(montoTotal).toFixed(2)) {
          mensajeMonto =
            "El monto a retirar no puede ser mayor al saldo de su fondo.";
        }
      }
    } else {
      mensajeMonto = "El monto a retirar es requerido.";
    }

    if (cuentaDestino === -1) {
      mensajeSeleccioneCuenta = "Seleccione una cuenta.";
    }

    if (mensajeMonto.length > 0 || mensajeSeleccioneCuenta.length > 0) {
      setMensajeLocal(`${mensajeMonto} ${mensajeSeleccioneCuenta}`);
      return false;
    } else {
      return true;
    }
  };

  const procesarSolicitud = () => {
    if (validarData()) {
      if (props.conTokenDigital === 1) {
        setOpenModal(true);
      } else {
        enviarDataAlPadre(null);
      }
    }
  };

  const enviarDataAlPadre = (codeToken) => {
    let montoARetirar = montoRetiro.replace("S/ ", "").replace(/,/g, "");
    let bodyFormData = new FormData();
    if (codeToken) {
      bodyFormData.set("v_token_digital", codeToken);
    }
    bodyFormData.set("v_monto", montoARetirar);
    bodyFormData.set("v_forpag", "A");
    bodyFormData.set("v_tip_pago", formaRetiro);
    bodyFormData.set("v_referencia", cuentaDestino.r_codigo);

    props.procesarSolicitud(bodyFormData);
  };

  const cerrarModal = () => {
    setOpenModal(false);
  };

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div style={{ padding: "16px" }}>
      <Card>
        <CardBody>
          <Form className="p-0">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="saldo">
                    Saldo de tu Fondo Sin fin:{" "}
                    <span
                      className="tooltip-custom"
                      style={{ color: "#bf253d" }}
                    >
                      <i className="fa fa-info-circle" id="TooltipExample"></i>
                      <span className="tooltiptext">
                        Recuerda: El saldo disponible no incluye el cobro de
                        comisión ni el impuesto a las ganancias.
                      </span>
                    </span>
                  </Label>
                  <Input
                    style={{ color: "#1b6792", fontWeight: "500" }}
                    type="text"
                    name="saldo"
                    value={`S/ ${montoFormat(montoTotal)}`}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="switch-container">
                  <Label htmlFor="nombre">Forma de retiro</Label>
                  <div className="buttongroup">
                    <button
                      onClick={handleRadio("T")}
                      className={
                        formaRetiro === "T" ? "select-radio rbtn" : "rbtn"
                      }
                    >
                      Total
                    </button>
                    <button
                      onClick={handleRadio("P")}
                      className={
                        formaRetiro === "P" ? "select-radio rbtn" : "rbtn"
                      }
                    >
                      Parcial
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="nombre">Monto a retirar</Label>
                  <CurrencyInput
                    value={montoRetiro}
                    disabled={disableMonto}
                    className={disableMontoClass}
                    placeholder="S/ 0.00"
                    type="text"
                    onChange={handleMonto}
                  />
                  {formaRetiro === "T" ? (
                    <span style={{ fontSize: "12px", color: "#bf0d3e" }}>
                      Recuerda que el monto es referencial
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>{title}</Col>
            </Row>
            {cardOrSelect ? (
              <SelectCard
                cuentas={data}
                setCuenta={setCuentsDestino}
                setMensaje={setMensajeLocal}
              />
            ) : (
              <Cards
                cuentas={data}
                setCuenta={setCuentsDestino}
                setMensaje={setMensajeLocal}
              />
            )}
            {mensajeLocal && (
              <div className="alert alert-danger">{mensajeLocal}</div>
            )}
          </Form>
        </CardBody>
      </Card>

      <section className="section-buttons d-flex justify-content-around">
        <Button
          name="secondary"
          bgColor="white"
          onClick={procesarSolicitud}
          icon={TramRounded}
        >
          Confirmar
        </Button>
      </section>
      {openModal && (
        <ModalToken
          cerrarModal={cerrarModal} // fn para cerrar modal
          guardarCode={enviarDataAlPadre}
          showModal={openModal}
        />
      )}
    </div>
  );
};

export default hocWidthWindow(PrimerPaso);
