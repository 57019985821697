import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "../../../../../../../components";
import "./index.css";

export default function VistaEspera(props) {
  const {
    imgLogo,
    title,
    description,
    btnLabel,
    btnFunction,
    loading,
    disable,
    note = "",
  } = props;

  const renderIcon = () => {
    if (loading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <Grid container spacing={2} className="waiting-view-container" p={2}>
      <Grid item sm={8} md={8} lg={5} mx={"auto"}>
        <div className="logo-container">
          <img src={imgLogo} alt="icono de confirmacion" />
        </div>
        <div className="body-container">
          <h3>{title}</h3>
          <p>{description}</p>
          {note !== "" && (
            <span className=" note" style={{ margin: "auto" }}>
              {note}
            </span>
          )}
        </div>
        <div className="button-section">
          <Button
            className="btn-modal-primary"
            onClick={btnFunction}
            disabled={disable}
          >
            {btnLabel} {renderIcon()}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
