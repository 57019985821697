import styled, { css } from "styled-components";

export const BotonesDeNavegacionComponente = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 640px;
  margin: auto;
  gap: 16px;
  padding: 24px 0;

  ${(p) =>
    p.step === 2 &&
    css`
      grid-template-columns: 1fr;
      max-width: 320px;
    `}
`;

export const Boton = styled.button`
  padding: 10px 24px;
  outline: none;
  cursor: pointer;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  ${(p) => p.theme.tipography.bodyMediumRegular}

  ${(p) =>
    p.fluid &&
    css`
      width: 100%;
    `}
  
  &:hover {
    box-shadow: ${(p) => p.theme.elevations.below};
  }
`;

export const BotonVolver = styled(Boton)`
  color: ${(p) => p.theme.palette.primary.main};
  border-color: ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.white};
`;

export const BotonSiguiente = styled(Boton)`
  border-color: ${(p) => p.theme.palette.grayscale[100]};
  color: ${(p) => p.theme.palette.white};
  background-color: ${(p) => p.theme.palette.primary.main};

  &:disabled {
    color: ${(p) => p.theme.palette.grayscale["300"]};
    background-color: ${(p) => p.theme.palette.grayscale["100"]};
  }
`;
