import React, { useContext, createContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import * as actionsCreators from "./actionsCreators";

const ZonaDeBeneficiariosContext = createContext();

export const useZonaDeBeneficiariosContext = () => {
  return useContext(ZonaDeBeneficiariosContext);
};

export const GastoDeSepelioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ZonaDeBeneficiariosContext.Provider
      value={{
        state,
        dispatch,
        setGeneralError: actionsCreators.setGeneralError(dispatch),
        removeGeneralError: actionsCreators.removeGeneralError(dispatch),
      }}
    >
      {children}
    </ZonaDeBeneficiariosContext.Provider>
  );
};
