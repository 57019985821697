import React from "react";
import {
  AlertWrapper,
  Body,
  BuscarTramiteComponent,
  ButtonsWrapper,
  FormWrapper,
  InputTitle,
  InputWrapper,
  Main,
  Request,
  RequestCaption,
  RequestIcon,
  RequestInformationItem,
  RequestInformationWrapper,
  RequestSubtitle,
  RequestTitle,
  Title,
} from "./styles";
import * as Input from "../../components/Input";
import FormDecorator from "../../components/Input/FormDecorator";
import Lupa from "../../assets/IconBucket/Lupa";
import Button from "../../components/Buttons";
import { FiRefreshCw } from "react-icons/fi";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import Alert from "../../components/Alert";
import Divider from "../../components/Divider";
import Header from "../../components/BeneficiaryZoneHeader";
import Certification from "../../assets/IconBucket/Certification";
import RefreshRight from "../../assets/IconBucket/RefreshRight";
import InfoButton from "../../components/InfoButton";
import ModalNumeroDeSolicitud from "./ModalNumeroDeSolicitud";
import { device, size } from "../../utils/device";
import useWindowSize from "../../hooks/useWindowSize";
import { BuscarTramiteProvider, useBuscarTramiteContext } from "./context";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

const BuscarTramite = () => {
  var breadcumbs = [
    {
      label: "Zona beneficiarios",
      path: "/zona-beneficiarios",
      current: false,
    },
    {
      label: "Buscar trámite",
      path: "#",
      current: true,
    },
  ];

  const history = useHistory();
  const onPreviousFromHeader = () => {
    history.push("/zona-beneficiarios");
  };

  const formMethods = useForm({ mode: "all", criteriaMode: "all" });

  return (
    <BuscarTramiteProvider>
      <BuscarTramiteComponent>
        <Header onPrevious={onPreviousFromHeader} breadcumbs={breadcumbs} />
        {/* <FormDecorator> */}
        <FormProvider {...formMethods}>
          <Body>
            <form>
              <Main>
                <Title>Busca tu trámite en curso a continuación</Title>
                <Form />
              </Main>
            </form>
          </Body>
        </FormProvider>
        {/* </FormDecorator> */}
      </BuscarTramiteComponent>
    </BuscarTramiteProvider>
  );
};

export default BuscarTramite;

export const Form = () => {
  const {
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, touchedFields, isValid },
  } = useFormContext();

  const {
    state: { request },
    getRequestInfo,
    resetRequestInfo,
  } = useBuscarTramiteContext();

  const searchButtonHandler = async () => {
    try {
      getRequestInfo(getValues());
    } catch (error) {
      console.log(error);
    }
    // getAffiliate();
  };

  const { width } = useWindowSize();

  var canSearch =
    watch("tipo-tramite") &&
    watch("numero-tramite") &&
    watch("tipo-documento") &&
    isValid;

  const tipoTramiteOptions = [{ label: "Gastos de sepelio", value: "sepelio" }];

  useState(() => {
    const setDefaultValues = () => {
      setValue("tipo-tramite", "sepelio");
      setValue("numero-tramite", "3631383");
      setValue("tipo-documento", "00");
      setValue("valor-documento", "32342343");
    };

    if (process.env.NODE_ENV === "development") {
      // setDefaultValues();
    }
  }, []);

  const resetForm = () => {
    reset({
      "tipo-tramite": "",
      "numero-tramite": "",
      "tipo-documento": "00",
      "valor-documento": "",
    });
    resetRequestInfo();
  };

  useEffect(() => {
    resetRequestInfo();
  }, [
    watch("tipo-tramite"),
    watch("numero-tramite"),
    watch("tipo-documento"),
    watch("valor-documento"),
  ]);

  const [modalNumeroDeSolicitudIsOpen, setModalNumeroDeSolicitudIsOpen] =
    useState(false);

  return (
    <>
      <FormWrapper>
        <InputWrapper>
          <InputTitle required={false}>Tipo de trámite</InputTitle>
          <Input.Select
            name="tipo-tramite"
            options={tipoTramiteOptions}
            placeholder="Selecciona tramite"
          />
          <Input.ErrorMessage name="tipo-tramite" />
        </InputWrapper>
        <InputWrapper>
          <InputTitle required={false}>
            Número de solicitud{" "}
            <span style={{ display: "inline-block", marginLeft: "4px" }}>
              <InfoButton
                tooltip={false}
                onClick={() => setModalNumeroDeSolicitudIsOpen(true)}
              />
            </span>{" "}
          </InputTitle>
          <Input.Number
            name="numero-tramite"
            placeholder="0000000"
            minLength={7}
            maxLength={10}
          />
          <Input.ErrorMessage name="numero-tramite" />
        </InputWrapper>
        <InputWrapper>
          <InputTitle required={false}>
            Documento de identidad del afiliado
          </InputTitle>
          <Input.Document
            documentProps={{ name: "tipo-documento" }}
            valueProps={{ name: "valor-documento" }}
          />
          <Input.ErrorMessage name="valor-documento" />
        </InputWrapper>
        <ButtonsWrapper>
          <Button
            model={"circle"}
            variant="secondary"
            disabled={!canSearch}
            icon={<RefreshRight />}
            onClick={resetForm}
            size="lg"
          />
          <Button
            fullWidth
            disabled={!canSearch || request.status === "loading"}
            onClick={searchButtonHandler}
            icon={<Lupa />}
            size="lg"
          >
            Buscar
          </Button>
        </ButtonsWrapper>
      </FormWrapper>

      <ModalNumeroDeSolicitud
        open={modalNumeroDeSolicitudIsOpen}
        setOpen={setModalNumeroDeSolicitudIsOpen}
      />
      {size.desktop > width && <Divider size="md" />}
      {size.desktop <= width && <Divider size="md" marginTop="0px" />}
      {request?.error && (
        <AlertWrapper>
          <Alert
            title={"Trámite no encontrado"}
            description={"Revisa los datos ingresados e intenta de nuevo."}
            variant={"error"}
          />
        </AlertWrapper>
      )}
      {/* {true && ( */}
      {request?.data && (
        <Request>
          <RequestIcon>
            <Certification />
          </RequestIcon>
          <RequestSubtitle>Trámite</RequestSubtitle>
          <RequestTitle>{request?.data.tramite}</RequestTitle>

          {size.tablet > width && (
            <Divider size="md" marginTop="4px" marginBottom="4px" />
          )}
          <RequestInformationWrapper>
            <RequestInformationItem>
              <span className="item">Fecha de registro:</span>{" "}
              <span className={`value`}>{request?.data.fRegistro}</span>
            </RequestInformationItem>
            {size.tablet <= width && (
              <Divider
                mode="vertical"
                size="md"
                marginLeft="32px"
                marginRight="32px"
              />
            )}
            <RequestInformationItem>
              <span className="item">Estado:</span>{" "}
              <span className={`value ${request?.status}`}>
                {request?.data?.estado === "validando" &&
                  "Validando documentos"}
                {request?.data?.estado === "rechazado" && "Rechazado"}
                {request?.data?.estado === "proceso-pago" && "Proceso de pago"}
                {request?.data?.estado === "pagado" && "Pagado"}
              </span>
            </RequestInformationItem>
          </RequestInformationWrapper>
          <RequestCaption>
            {request?.data?.estado === "validando" &&
              "Te mantendremos informado sobre el avance de tu trámite mediante el correo registrado en la solicitud."}
            {request?.data?.estado === "rechazado" &&
              " Podrás encontrar las razones de rechazo en el correo registrador en la solictud."}
            {request?.data?.estado === "proceso-pago" &&
              "Te mantendremos informado sobre el avance de tu trámite mediante el correo registrado en la solicitud."}
            {request?.data?.estado === "pagado" &&
              "El pago se realiza a tu cuenta bancaria. En caso no hayas definido una cuenta o esta sea invalida, podrás recibir el pago desde ventanilla en los siguientes bancos: Scotiabank personas con DNI, BCP personas documentos de identidad extranjeros"}
          </RequestCaption>
        </Request>
      )}
    </>
  );
};
