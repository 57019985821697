import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Vector from "../../../assets/icons/Vector.svg";
import Alcancia from "../../../assets/icons/GroupA.svg";
import UltimosmovimientosContext from "../../../context/ultimosmovimientos/ultimosmovimientosContext";
import moment from "moment";
import Actualizar from "../../../assets/icons/estado_cuenta.svg";
import Estado_cuenta from "../../../assets/icons/actualizar.svg";
import Coin from "../../../assets/icons/rec_coin.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Carousel } from "../../../components";
import DetalleProductoSppContext from "../../../context/detalleProductoSpp/detalleProductoSppContext";
import { MovementRow, Graphyc } from "./components";
import "./index.css";

const BoxContainer = styled.div`
  // padding: 16px;
  display: flex;
  flex-direction: column;
  @media (min-width: 990px) {
    padding: 0;
  }
`;
const BoxStyled = styled(Box)`
  box-shadow: 0px 0px 6px 1px rgb(1 1 1 / 25%);
  background: ${(props) => props.theme.palette.white};
  padding: 16px 0;
  border-radius: 14px;
  margin-bottom: 24px;

  &:first-child {
    border-top: none;
  }
`;
const styles = {
  slide: {
    minHeight: 100,
    WebkitOverflowScrolling: "touch", // iOS momentum scrolling
  },
};
const APVConFin = ({
  habitatValue,
  cuotasValue,
  product,
  SPPValue,
  datosMisInversiones,
  value,
  index,
  jubilacion,
  totalValue,
}) => {
  const detalleProductoSppContext = useContext(DetalleProductoSppContext);
  const getProduct = sessionStorage.getItem("product");
  const [showLastContributions, setShowLastContributions] = useState(false);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
  const ultimosmovimientosContext = useContext(UltimosmovimientosContext);
  const { movimientos, obtenerUltimosMovimientos } = ultimosmovimientosContext;
  const { obtenerDatosDetalleProductoSpp } = detalleProductoSppContext;

  useEffect(() => {
    obtenerDatosDetalleProductoSpp(getProduct);
    // setSPPValue(datosDetalleProductoSpp);
    getObtener(product);
    return () => {
      // limpiarVariablesDetalleProductoSppParcial();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowLastContributions(false);
    if (product) {
      getObtener(product);
    }

    const delay = setTimeout(() => {
      // if (product !== "OBL") {
      setShowLastContributions(true);
      // }
    }, 1000);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [product]);

  const getObtener = (product) => {
    let bodyFormData = new FormData();
    let dateIni = moment().subtract(3, "month");
    let dateFin = moment();
    let pe_ini =
      Number((dateIni.month() + 1).toString().length) === 1
        ? dateIni.year() + "0" + (dateIni.month() + 1)
        : dateIni.year() + "" + (dateIni.month() + 1);
    let pe_fin =
      Number((dateFin.month() + 1).toString().length) === 1
        ? dateFin.year() + "0" + (dateFin.month() + 1)
        : dateFin.year() + "" + (dateFin.month() + 1);

    bodyFormData.set("tipo_aporte", product);
    bodyFormData.set("pe_ini", pe_ini);
    bodyFormData.set("pe_fin", pe_fin);
    obtenerUltimosMovimientos(bodyFormData);
  };
  return (
    <Box className="dashboard-data" style={Object.assign({}, styles.slide)}>
      <BoxContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pl: 0,
            pr: 0,
            pt: 2,
            mb: 4,
            maxHeight: 560,
          }}
        >
          <Carousel
            habitatValue={habitatValue}
            product={product}
            acumulated_mount={totalValue}
            cuotasValue={cuotasValue}
            detalleProductoSPP={SPPValue}
          />
        </Box>
        <h3 className="dashboard-componente_title">Accesos rápidos</h3>
        <BoxStyled>
          <Grid container sx={{ justifyContent: "space-evenly" }}>
            <Grid item xs={4}>
              <Link to="/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo">
                <div className="access">
                  <div className="access-img">
                    <img src={Vector} alt="acccess direct" />
                  </div>
                  <div className="access-text">
                    <h4>Cambio de tipo de fondo</h4>
                  </div>
                </div>
              </Link>
            </Grid>
            {(product === "OBL" || product === "VCF") && jubilacion && (
              <Grid item xs={4}>
                <Link to="/mi-habitat-digital/afiliado/jubilacion">
                  <div className="access">
                    <div className="access-img">
                      <img src={Alcancia} alt="access direct" />
                    </div>
                    <div className="access-text">
                      <h4>Trámites de jubilación</h4>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
            {!jubilacion && (
              <Grid item xs={4}>
                <Link to="/mi-habitat-digital/afiliado/informacion/datos-personales">
                  <div className="access">
                    <div className="access-img">
                      <img src={Actualizar} alt="acccess direct" />
                    </div>
                    <div className="access-text">
                      <h4>Actualizar datos</h4>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
            {/* {product === "VSF" && (
              <Grid item xs={4}>
                <Link to="/mi-habitat-digital/afiliado/apv/fondo-libre-habitat">
                  <div className="access">
                    <div className="access-img">
                      <img src={Coin} alt="acccess direct" />
                    </div>
                    <div className="access-text">
                      <h4>Invertir</h4>
                    </div>
                  </div>
                </Link>
              </Grid>
            )} */}
            {(product === "OBL" || product === "VCF") && (
              <Grid item xs={4}>
                <Link to="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual">
                  <div className="access">
                    <div className="access-img">
                      <img src={Estado_cuenta} alt="acccess direct" />
                    </div>
                    <div className="access-text">
                      <h4>Estado de cuenta</h4>
                    </div>
                  </div>
                </Link>
              </Grid>
            )}
          </Grid>
        </BoxStyled>

        {showLastContributions && (
          <>
            {movimientos && movimientos.length > 0 && (
              <h3 className="dashboard-componente_title">
                Últimos movimientos
              </h3>
            )}

            {movimientos && movimientos.length > 0 && (
              <Grid
                container
                className="box-movement-mobile"
                sx={{ justifyContent: "space-evenly" }}
              >
                <Grid
                  item
                  xs={12}
                  className="movements-section_header box-card mb-3"
                >
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <span className="bold">Fecha</span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <span className="bold">Cuotas</span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <span className="bold">Monto</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="box-card">
                    {movimientos &&
                      movimientos.slice(0, 5).map((item, index) => {
                        if (item.r_valor_cuota) {
                          return (
                            <MovementRow
                              key={index}
                              date={item.r_fecha}
                              fee={item.r_valor_cuota}
                              amount={item.r_monto_soles}
                              description={item.r_descripcion}
                            />
                          );
                        }
                      })}

                    <div className="center-items mt-3 pb-2">
                      <Link to="/mi-habitat-digital/afiliado/mis-fondos/ultimos-movimientos">
                        <button className="btn-red-primary">Ver todo</button>
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {product === "OBL" && datosMisInversiones.length > 0 ? (
          <div style={{ display: value == index ? "block" : "none" }}>
            <h3 className="dashboard-componente_title">
              Evolución de mi fondo
            </h3>
            <BoxStyled
              sx={{
                position: "relative",
                mt: 3,
              }}
            >
              <Grid container sx={{ justifyContent: "space-evenly" }}>
                {datosMisInversiones &&
                  datosMisInversiones.r_tipos_de_aporte.map((item, index) => (
                    <Fragment key={index}>
                      {opcionSeleccionada === index && (
                        <Graphyc
                          otraData={
                            datosMisInversiones[
                              datosMisInversiones.r_tipos_de_aporte[
                                opcionSeleccionada
                              ].r_codigo
                            ]
                          }
                        />
                      )}
                    </Fragment>
                  ))}
                <div className="center-items mt-4 mb-2">
                  <Link to="/mi-habitat-digital/afiliado/mis-fondos/evolucion-de-mi-fondo">
                    <button className="btn-red-primary">Ver más</button>
                  </Link>
                </div>
              </Grid>
            </BoxStyled>
          </div>
        ) : null}
      </BoxContainer>
    </Box>
  );
};

export default APVConFin;
