import React from "react";
import Modal from "./CustomModal";

const ModalActualizarDatos = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={"Declaración jurada"}
      subtitle={
        "Declaro expresamente que, en caso de informar de manera incorrecta u omitir declarar a alguno de mis beneficiarios ante la AFP en el proceso de asesoría para acceder a una pensión del SPP, la variación del cálculo de la PMO será de mi entera responsabilidad."
      }
      onClickBtnPrimary={closeModal}
      textBtnPrimary={"Entendido"}
    />
  );
};

export default ModalActualizarDatos;
