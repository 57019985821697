import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Modal from "./CustomModal";
import { ContentCheckValidacion } from "../../styles/Modal";

const ModalPensionC40 = ({
  isOpen,
  setIsOpen,
  setIsOpenModalPensionC40Final,
  misDatosGet,
  setIsOpenModalActualizarDatos,
  setIsOpenModalNoDatos,
  crearProcesoLegalData,
}) => {
  const [value, setValue] = useState(null);

  const closeModal = () => setIsOpen(false);
  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  const onContinue = (e) => {
    setIsOpen(false);

    if (value === 1) {
      setIsOpenModalPensionC40Final(true);
    } else {
      // if (
      //   misDatosGet.r_persona.r_datos.r_celular !== null &&
      //   misDatosGet.r_persona.r_datos.r_correo !== null
      // ) {
      //   setIsOpenModalActualizarDatos(true);
      // } else {
      //   setIsOpenModalNoDatos(true);
      // }
      crearProcesoLegalData();
    }
  };
  // console.log(value);
  const blockBtn = () => {
    if (value === null) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="¿Actualmente percibes una pensión en otro régimen?"
      subtitle={
        " Por ejemplo: Ley 20530, Ley 19990, Caja policial y Caja militar."
      }
      onClickBtnPrimary={() => onContinue()}
      textBtnPrimary={"Continuar"}
      loadingBtnPrimary={blockBtn()}
    >
      <ContentCheckValidacion>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            label="Sí, percibo"
          />
          <FormControlLabel
            value="0"
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            label="No, no percibo"
          />
        </RadioGroup>
      </ContentCheckValidacion>
    </Modal>
  );
};

export default ModalPensionC40;
