import React from "react";

export default function CoinsIcon({ size = 18, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width={size}
      height={size}
      fill="none"
      stroke={color}
    >
      <path
        d="M35.2376 21.9047V27.619C35.2376 30.0952 29.2681 33.3333 21.9043 33.3333C14.5404 33.3333 8.57092 30.0952 8.57092 27.619V22.8571L9.13092 23.3503C10.7785 25.537 15.8738 27.5884 21.9043 27.5884C29.2681 27.5884 35.2376 24.5294 35.2376 21.9008C35.2376 20.4246 33.3576 18.8075 30.4071 17.6722L29.5234 18.0953M29.5234 12.381V18.0953M29.5234 18.0953C29.5234 20.5715 23.5539 23.8096 16.1901 23.8096C8.82627 23.8096 2.85675 20.5715 2.85675 18.0953V12.381M16.19 18.0648C23.5538 18.0648 29.5234 15.0058 29.5234 12.3772C29.5234 9.74675 23.5538 6.66675 16.19 6.66675C8.82621 6.66675 2.85669 9.74675 2.85669 12.3772C2.85669 15.0058 8.82621 18.0648 16.19 18.0648Z"
        stroke={color}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
