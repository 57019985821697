import PropTypes from "prop-types";

const { bool, string, array, object, oneOf, oneOfType, number } = PropTypes;

export const FileProps = {
  name: string,
  extensions: array,
  validate: object,
  multi: bool,
  size: oneOf(["small", "large"]),
  maxFileSize: number,
  required: oneOfType([bool, object]),
  maxFiles: number,
  truncateAt: number,
};
