export const PAGOS_PENDIENTES_EMPRESAS_EXITOSO = 'PAGOS_PENDIENTES_EMPRESAS_EXITOSO';
export const PAGOS_PENDIENTES_EMPRESAS_ERROR = 'PAGOS_PENDIENTES_EMPRESAS_ERROR';
export const PAGOS_PENDIENTES_EMPRESAS_LOADIN = 'PAGOS_PENDIENTES_EMPRESAS_LOADIN';
export const PAGOS_PENDIENTES_EMPRESAS_CLEANER = 'PAGOS_PENDIENTES_EMPRESAS_CLEANER';

export const PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO = 'PAGOS_PENDIENTES_EMPRESA_PERIODOS_EXITOSO';
export const PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR = 'PAGOS_PENDIENTES_EMPRESA_PERIODOS_ERROR';
export const PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN = 'PAGOS_PENDIENTES_EMPRESA_PERIODOS_LOADIN';
export const PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER = 'PAGOS_PENDIENTES_EMPRESA_PERIODOS_CLEANER';

export const PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO = 'PAGOS_PENDIENTES_DATA_EXCEL_EXITOSO';
export const PAGOS_PENDIENTES_DATA_EXCEL_ERROR = 'PAGOS_PENDIENTES_DATA_EXCEL_ERROR';
export const PAGOS_PENDIENTES_DATA_EXCEL_LOADIN = 'PAGOS_PENDIENTES_DATA_EXCEL_LOADIN';
export const PAGOS_PENDIENTES_DATA_EXCEL_CLEANER = 'PAGOS_PENDIENTES_DATA_EXCEL_CLEANER';