import React, { useReducer } from "react";
import CodeContext from "./codeContext";
import CodeReducer from "./codeReducer";

import clienteAxios from "../../config/axios";

import {
  CODE_GET_EXITOSO,
  CODE_BTN_LOADIN,
  CODE_GET_ERROR,
  CODE_CLEANER,
  CODE_CLEANER_PARCIAL,
  CODE_CLEANER_POST,
  CODE_POST_EXITOSO,
  CODE_POST_ERROR,
  CODE_POST_LOADIN,
} from "../../types";

const CodeState = (props) => {
  const initialState = {
    dataModalCode: null,
    mensajeGetAlerta: null,
    loadingGetBtn: false,
    dataPostCode: null,
    mensajePostAlerta: null,
    loadingPostBtn: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosCode = async () => {
    dispatch({
      type: CODE_BTN_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/token-digital");
      dispatch({
        type: CODE_GET_EXITOSO,
        payload: respuesta.data.data,
      });
    } catch (error) {

      handleErrorsLocal(error, CODE_GET_ERROR);
    }
  };

  const limpiarVariablesCode = () => {
    dispatch({
      type: CODE_CLEANER,
    });
  };
  const limpiarVariablesCodePost = () => {
    dispatch({
      type: CODE_CLEANER_POST,
    });
  };

  const limpiarVariablesCodeParcial = () => {
    dispatch({
      type: CODE_CLEANER_PARCIAL,
    });
  };

  const solicitarCode = async (datos) => {
    dispatch({
      type: CODE_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/token-digital/enviar", datos);
      dispatch({
        type: CODE_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, CODE_POST_ERROR);
    }
  };

  const [state, dispatch] = useReducer(CodeReducer, initialState);
  return (
    <CodeContext.Provider
      value={{
        dataModalCode: state.dataModalCode,
        mensajeGetAlerta: state.mensajeGetAlerta,
        loadingGetBtn: state.loadingGetBtn,
        dataPostCode: state.dataPostCode,
        mensajePostAlerta: state.mensajePostAlerta,
        loadingPostBtn: state.loadingPostBtn,
        obtenerDatosCode,
        limpiarVariablesCode,
        limpiarVariablesCodeParcial,
        limpiarVariablesCodePost,
        solicitarCode,
      }}
    >
      {props.children}
    </CodeContext.Provider>
  );
};

export default CodeState;
