import React, { useState } from "react";
import { DateInputComponent, Icon } from "./styles";
import DatePicker from "react-datepicker";
import {
  string,
  oneOf,
  bool,
  node,
  any,
  object,
  number,
  instanceOf,
  oneOfType,
} from "prop-types";
import es from "date-fns/locale/es"; // the locale you want
import { AiOutlineCalendar } from "react-icons/ai";
import { useFormContext } from "react-hook-form";
import getDeepValue from "../../../utils/getDeepValue";

const DateInput = (props) => {
  const {
    disabled,
    error,
    yearDropdownItemNumber,
    maxDate,
    name,
    required,
    validate,
    onChange,
    datePickerProps,
    ...restProps
  } = props;
  const [startDate, setStartDate] = useState(null);

  const {
    trigger,
    register,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext();
  register(name, { required, ...validate });

  // If at least one follwing errors is truthy return isError true
  const isError = error || getDeepValue(errors, name);
  const onChangeHandler = (date) => {
    setValue(name, date);
    setStartDate(date);
    trigger(name);
  };

  const selectOnChange = onChange || onChangeHandler;

  const currentValue = watch(name);

  return (
    <DateInputComponent error={isError} {...restProps} icon={true}>
      <DatePicker
        selected={currentValue}
        onChange={selectOnChange}
        // open={true}
        placeholderText="dd/mm/aaaa"
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        locale={es}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={yearDropdownItemNumber}
        maxDate={maxDate}
        {...datePickerProps}
      />
      <Icon disabled={disabled} error={isError}>
        <AiOutlineCalendar />
      </Icon>
    </DateInputComponent>
  );
};

export default DateInput;

DateInput.propTypes = {
  name: string,
  size: oneOf(["sm", "md", "lg"]),
  error: bool,
  icon: node,
  disabled: bool,
  required: oneOfType([bool, object]),
  placeholder: string,
  wrapperProps: any,
  validate: object,
  yearDropdownItemNumber: number,
  maxDate: any,
};

DateInput.defaultProps = {
  name: "date-input",
  size: "lg",
  error: false,
  required: {
    value: true,
    message: "El valor de este campo es requerido.",
  },
  disabled: false,
  placeholder: "Placeholder",
  yearDropdownItemNumber: 123,
  maxDate: new Date(),
};
