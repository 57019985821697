import React, { useContext, Fragment, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import GraficoLineas from "./GraficoLineas";
import "./index.css";
import MisInversionesContext from "../../../../../context/misinversiones/misinversionesContext";

const BoxBody = styled(Box)`
  // padding: ${(props) => props.theme.spacing(1)};
  position: relative;
  flex: 0 0 100%;
  flex-shrink: 0;
  ${(props) => props.theme.tipography.h5};
  & .spanText {
    display: block;
    color: ${(props) => props.theme.palette.grayscale.light};
    font-size: 14px;
  }
  .card-body {
    padding: 0;
  }
  // & .amount {
  //   ${(props) => props.theme.tipography.number};
  // }
  & .content-text {
    display: flex;
  }
  & .invested-amount {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
  }

  & .simbolo-desktop span {
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #ce1336;
  }
  & .message-rent-desktop {
    background: #2f6d95;
    border-radius: 7px;
    padding: 7px 8px;
    margin-top: 16px;
  }
  & .message-rent-desktop p {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    margin: 0 auto 0;
    text-align: center;
    letter-spacing: -0.0125em;
    width: 264px;
    color: #ffffff;
  }
`;

export default function MyProducts(props) {
  const misinversionesContext = useContext(MisInversionesContext);

  const {
    datosMisInversiones,
    mensajeAlerta,
    obtenerDatosMisInversiones,
    // limpiarVariablesMisInversionesParcial,
  } = misinversionesContext;

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      // mostrarMensaje();
    } else {
      // ReactGa.pageview(window.location.href);
      obtenerDatosMisInversiones();
    }

    return () => {
      // limpiarVariablesMisInversionesParcial();
      // actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  useEffect(() => {
    if (datosMisInversiones) {
      if (datosMisInversiones.r_validacion === 1) {
        // setNombreFondo(
        //   datosMisInversiones.r_tipos_de_aporte[opcionSeleccionada]
        //     .r_descripcion
        // );
      }
    }
  }, [datosMisInversiones]);

  return (
    // <CardContainer
    //   title={"¿En qué invirtió AFP Habitat?"}
    //   seeMore={false}
    //   color={"#ffffff"}
    //   product={false}
    //   showHeader={false}
    // >
    <BoxBody className="home-box-body">
      {datosMisInversiones && (
        <Fragment>
          <GraficoLineas
            // data={tresOpiones[item.code]}
            fechaInicioFin={"03 / 2022"}
            otraData={datosMisInversiones.r_obligatorio}
          />
        </Fragment>
      )}
    </BoxBody>
    // </CardContainer>
  );
}
