import clienteAxios from './axios';

const tokenAuth = (token, token_type) => {
    if(token && token_type) {
        clienteAxios.defaults.headers.common['Authorization'] = `${token_type} ${token}`;
        clienteAxios.defaults.headers.common['Accept'] = `application/json`;
    } else {
        delete clienteAxios.defaults.headers.common['Authorization'];
    }
}

export default tokenAuth;