import React, { useContext, createContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import * as actionsCreators from "./actionsCreators";

const BeneficiariesContext = createContext();

export const useBeneficiariesContext = () => {
  return useContext(BeneficiariesContext);
};

export const PMOBeneficiariesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BeneficiariesContext.Provider
      value={{
        state,
        dispatch,
        requestBeneficiaries: actionsCreators.requestBeneficiaries(dispatch),
        sendBeneficiaryDocument:
          actionsCreators.sendBeneficiaryDocument(dispatch),
      }}
    >
      {children}
    </BeneficiariesContext.Provider>
  );
};
