import React from "react";
import * as Table from "./tableComponents";
import { View } from "@react-pdf/renderer";
import { montoFormat } from "../../../helper/amountFormat";

const PartTwo = ({
  valorCuota,
  fechaCuota,
  tipoRetiro,
  formaPago,
  montoSolicitado,
  montoCargado,
  nroCuenta,
}) => {
  return (
    <View>
      <Table.Wrapper>
        <Table.Heading>Parte II: Datos del retiro</Table.Heading>
        <Table.Row>
          <Table.Label>Valor cuota utilizado</Table.Label>
          <Table.Detail borderRight={true}>{valorCuota}</Table.Detail>
        </Table.Row>
        <Table.Row>
          <Table.Label>Fecha de valor cuota</Table.Label>
          <Table.Detail borderRight={true}>{fechaCuota}</Table.Detail>
        </Table.Row>
        <Table.Row>
          <Table.Label>Tipo de retiro</Table.Label>
          <Table.Detail borderRight={true}>{tipoRetiro}</Table.Detail>
        </Table.Row>
        <Table.Row>
          <Table.Label>Forma de pago</Table.Label>
          <Table.Detail borderRight={true}>{formaPago}</Table.Detail>
        </Table.Row>
        <Table.Row>
          <Table.Label>Número de cuenta</Table.Label>
          <Table.Detail borderRight={true}>{nroCuenta}</Table.Detail>
        </Table.Row>
      </Table.Wrapper>

      <Table.Wrapper>
        <Table.Row>
          <Table.Label style={{ width: "20%" }} borderTop={true}>
            Monto
          </Table.Label>
          <Table.Label style={{ width: "20%" }} borderTop={true}>
            Soles
          </Table.Label>
          <Table.Label
            style={{ width: "60%" }}
            borderTop={true}
            borderRight={true}
          >
            Expresado en Letras (en soles)
          </Table.Label>
        </Table.Row>
        <Table.Row>
          <Table.Detail
            style={{ width: "20%" }}
            textStyle={{ fontFamily: "Helvetica-Bold" }}
          >
            Solicitado
          </Table.Detail>
          <Table.Detail style={{ width: "20%" }}>
            {montoFormat(montoSolicitado.enSoles)}
          </Table.Detail>
          <Table.Detail style={{ width: "60%" }} borderRight={true}>
            {montoSolicitado.enLetras}
          </Table.Detail>
        </Table.Row>

        <Table.Row>
          <Table.Detail
            style={{ width: "20%" }}
            textStyle={{ fontFamily: "Helvetica-Bold" }}
          >
            Cargado
          </Table.Detail>
          <Table.Detail style={{ width: "20%" }}>
            {montoFormat(montoCargado.enSoles)}
          </Table.Detail>
          <Table.Detail style={{ width: "60%" }} borderRight={true}>
            {montoCargado.enLetras}
          </Table.Detail>
        </Table.Row>
      </Table.Wrapper>
    </View>
  );
};

export default PartTwo;
