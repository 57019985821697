import React from "react";
import {
  CircleButton,
  Content,
  GhostButton,
  Icon,
  PrimaryButton,
} from "./styles";
import PropTypes from "prop-types";
import { BsCircle } from "react-icons/bs";

const Button = (props) => {
  const {
    icon,
    iconAlignment,
    children,
    model,
    type = "button",
    ...restProps
  } = props;

  return (
    <>
      {model === "regular" && (
        <PrimaryButton type={type} {...props} iconAlignment={iconAlignment}>
          {icon && <Icon>{icon}</Icon>}
          <Content>{children}</Content>
        </PrimaryButton>
      )}
      {model === "ghost" && (
        <GhostButton type={type} {...props}>
          {icon && <Icon>{icon}</Icon>}
          <Content>{children}</Content>
        </GhostButton>
      )}
      {model === "circle" && (
        <CircleButton type={type} {...props}>
          {icon ? (
            <Icon>{icon}</Icon>
          ) : (
            <Icon>
              <BsCircle />
            </Icon>
          )}
        </CircleButton>
      )}
    </>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  iconAlignment: PropTypes.oneOf(["left", "right"]),
  textColor: PropTypes.string,
  model: PropTypes.oneOf(["regular", "ghost", "circle"]),
  variant: PropTypes.oneOf(["primary", "secondary", "link", "text"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: "Regular button",
  iconAlignment: "left",
  fullWidth: false,
  size: "md",
  model: "regular",
  variant: "primary",
  disabled: false,
};
