import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import Loading from "../../components/Loading";
import FondoLibreContext from "../../context/fondoLibre/fondoLibreContext";
import { BreadCrumbsFL } from "../PagosDesembolsos/componentes/data/components/BreadcrumbsFL";
import Interest from "../PagosDesembolsos/componentes/data/components/Interest";
import clienteAxios from "../../config/axios";
import "./index.css";
import TabsGraficos from "../FondoLibre/TabsGraficos";
import PieChartFondo from "./PieChartFondo";
import IMG_VIDEO_1 from "../../assets/icons/noticias_inversion_video_1.jpg";
import IMG_CUOTA_1 from "../../assets/icons/ni_icon_1.svg";
import IMG_CUOTA_2 from "../../assets/icons/ni_icon_2.svg";
import IMG_CUOTA_3 from "../../assets/icons/ni_icon_3.svg";
import IMG_CUOTA_4 from "../../assets/icons/ni_icon_4.svg";

import {
  BoxShadowFL,
  ButtonFL,
  ContentFlex,
  FlexItem,
  TextAll,
} from "./componentes";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const FondoLibre = () => {
  const [activeButton, setActiveButton] = React.useState(null);
  const fondoLibreContext = useContext(FondoLibreContext);

  const [windowWidth, setWindowWidth] = React.useState(getWidth());
  const [isMobile, setIsMobile] = React.useState(true);

  const styleText = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    color: "#363A3E",
  };

  const {
    loadingPage,
    valoresPieChart,
    valoresCuotaPorRangoFecha,
    user,
    valoresCuota,

    getValoresCuota,
    getUser,
    getDataPieChart,
    getValoresCuotaRangeFechas,
  } = fondoLibreContext;

  const fnActiveButtonGraph = (range) => {
    setActiveButton(range);
    getValoresCuotaRangeFechas(
      range,
      valoresCuota.valores_cuota.valores.format_fecha
    );
  };

  const handleChangeSelect = (periodo) => {
    getValoresCuotaRangeFechas(
      periodo,
      valoresCuota.valores_cuota.valores.format_fecha
    );
  };

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWindowWidth(getWidth());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    getValoresCuota();
    getUser();
    //getDataPieChart(4);
    getValoresCuotaRangeFechas("1-year", "-");
    setActiveButton("1-year");
  }, []);

  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, [windowWidth]);

  const fondoactual = user ? user.fondo_actual : "";

  const bgfondo0 = fondoactual == 0 ? "#EFF3FE" : "#ffffff";
  const bgfondo1 = fondoactual == 1 ? "#EFF3FE" : "#ffffff";
  const bgfondo2 = fondoactual == 2 ? "#EFF3FE" : "#ffffff";
  const bgfondo3 = fondoactual == 3 ? "#EFF3FE" : "#ffffff";

  const BlockBoxShadow = {
    display: "block",
    background: "#FFFFFF",
    margin: isMobile ? "0px" : "16px 16px 24px 16px",
    padding: "16px",
    borderRadius: "16px",
    boxShadow: isMobile ? "none" : "rgb(0 0 0 / 12%) 0px 4px 16px",
  };

  if (loadingPage) return <Loading />;

  return (
    <>
      <BreadCrumbsFL
        title_mobile={"Inicio"}
        title="Fondo Libre"
        rutas={[
          {
            id: 1,
            title: "Fondo Libre",
            ruta: "#/mi-habitat-digital/afiliado/estado-de-tramite",
            span: fondoactual > 0 ? "Fondo " + fondoactual : null,
          },
        ]}
      />

      <Grid
        container
        sx={{
          paddingTop: { xs: "100px", md: "96px" },
          background: { xs: "#ffffff", md: "#f8f9fb" },
          paddingBottom: { xs: "0px", md: "0px" },
        }}
      >
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              background: "#FFFFFF",
            }}
          >
            <Grid
              container
              sx={{
                background: { xs: "#ffffff", md: "#f8f9fb" },
                padding: { xs: "", md: "0px 8px 8px 8px" },
              }}
            >
              <Grid item xs={6} md={3} lg={3}>
                {valoresCuota && (
                  <CardFondo
                    icon={IMG_CUOTA_1}
                    titulo={"Valor cuota"}
                    valor={valoresCuota.valores_cuota.valores.yesterday}
                    containerBackground={"#ffffff"}
                    iconBackground={"#FFEBEF"}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                {valoresCuota && (
                  <CardFondo
                    icon={IMG_CUOTA_2}
                    titulo={"Fondo 1"}
                    flecha={valoresCuota.valores_cuota.comparativos.fondo1}
                    valor={
                      "S/ " + valoresCuota.valores_cuota.valores.fondos.fondo1
                    }
                    containerBackground={bgfondo1}
                    iconBackground={"#E6F2ED"}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                {valoresCuota && (
                  <CardFondo
                    icon={IMG_CUOTA_3}
                    titulo={"Fondo 2"}
                    flecha={valoresCuota.valores_cuota.comparativos.fondo2}
                    valor={
                      "S/ " + valoresCuota.valores_cuota.valores.fondos.fondo2
                    }
                    containerBackground={bgfondo2}
                    iconBackground={"#F2FAFF"}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                {valoresCuota && (
                  <CardFondo
                    icon={IMG_CUOTA_4}
                    titulo={"Fondo 3"}
                    flecha={valoresCuota.valores_cuota.comparativos.fondo3}
                    valor={
                      "S/ " + valoresCuota.valores_cuota.valores.fondos.fondo3
                    }
                    containerBackground={bgfondo3}
                    iconBackground={"#ECD6F7"}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box sx={BlockBoxShadow}>
            <Box sx={{}}>
              <div style={styleText}>
                <TextAll
                  fontSize="24px"
                  lineHeight="32px"
                  fontWeight="400"
                  padding="0px 0px 16px 0px"
                >
                  Noticias de inversiones
                </TextAll>
                <TextAll
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="400"
                  padding="0px 0px 8px 0px"
                >
                  Nuestro desempeño a largo plazo
                </TextAll>
                <TextAll
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="300"
                  padding="0px 0px 0px 0px"
                >
                  El acontecer nacional y mundial siempre influye a corto plazo
                  en los fondos que administramos, pero nuestro equipo de
                  inversiones ha sabido recuperar la tendencia al alza a lo
                  largo de los años.
                </TextAll>
              </div>
            </Box>
          </Box>
          <Box sx={BlockBoxShadow}>
            {valoresCuotaPorRangoFecha && valoresCuota && (
              <TabsGraficos
                windowWidth={windowWidth}
                onChangeSelectPeriodo={handleChangeSelect}
                data={valoresCuotaPorRangoFecha}
                valoresCuotaActual={valoresCuota.valores_cuota.valores.fondos}
                isMobile={isMobile}
              />
            )}
          </Box>
          <Box sx={BlockBoxShadow}>
            <div>
              <TextAll
                fontSize="24px"
                lineHeight="32px"
                fontWeight="400"
                padding="0px 0px 16px 0px"
              >
                Reporte económico del mes
              </TextAll>
              <TextAll
                fontSize="14px"
                lineHeight="20px"
                fontWeight="300"
                padding="0px 0px 16px 0px"
              >
                ¿Qué está influyendo últimamente en la economía local e
                internacional? Te lo contamos fácil y rápido.
              </TextAll>
              <TextAll
                fontSize="14px"
                lineHeight="20px"
                fontWeight="300"
                padding="0px 0px 16px 0px"
              >
                Proximamente te traeremos las noticias mas impactantes del
                momento
              </TextAll>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const CardFondo = ({
  titulo,
  flecha,
  valor,
  icon,
  iconBackground,
  containerBackground,
}) => {
  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        marginBottom: {
          xs: "1rem",
          md: "0rem",
        },
      }}
    >
      <Grid
        container
        style={{
          background: containerBackground,
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
          borderRadius: "16px",
          padding: "16px 16px 16px 8px",
          margin: "0px 8px",
        }}
      >
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={icon}
              width={"32"}
              height={"32"}
              style={{
                padding: "0px",
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <div className="ml-2">
            <Box
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.2px",
                marginBottom: "4px",
                color: "#363A3E",
              }}
            >
              {titulo}
            </Box>
            <Box
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "10px",
                lineHeight: "12px",
                letterSpacing: "0.2px",
                color: "#51565F",
              }}
            >
              {valor}
              {/*
                            el valor flecha, puede tener el valor 
                            "igual" flecha arriba verde
                            "baja"  flecha abajo  rojo
                            "sube"  flecha arriba verde                  
                        */}

              <i
                className={`fa fa-arrow-up ${
                  flecha == "igual" || flecha == "sube"
                    ? "text-success"
                    : "d-none"
                }`}
                style={{ marginLeft: "4px" }}
              ></i>
              <i
                className={`fa fa-arrow-down ${
                  flecha == "baja" ? "text-danger" : "d-none"
                }`}
                style={{ marginLeft: "4px" }}
              ></i>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
