import styled from "styled-components";
import { Box } from "@mui/material";

export const ContentPage = styled(Box)`
  & .MuiTableCell-head {
    ${(p) => p.theme.tipography.headingSubtitleSemi}
  }
  margin-bottom: 24px;
  & .MuiPaper-root {
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: ${(p) => p.theme.palette.blocked.disabled};
  }
`;
