
import React, { useState } from 'react'
import { Grid, Box } from '@mui/material';
import styled from 'styled-components';
import { SwicthButton } from '../componentes/SwicthButton';
import { TagInfo } from '../componentes/TagInfo';
import { Title } from '../componentes/Title';
import { Tabs } from '../componentes/Tabs';
import { SimpleTable } from '../componentes/Tables/SimpleTable';
import { BreadCrumbsFL } from '../../PagosDesembolsos/componentes/data/components/BreadcrumbsFL';
import { FondoComposicion } from '../componentes/FondoComposicion';
import { TwoColumnsTable } from '../componentes/Tables/TwoColumnsTable';

const BoxPadding = styled('div')`
    padding: 16px 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.2px;
    color: #51565F;
`;

export const BoxShadow = styled(Box)`
    padding: 16px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    border-radius: 16px;
    background: #FFFFFF;
    margin-bottom: 24px;
`;

export const FondoLibreInicio = () => {

    const [cuota, setCuota] = useState(false)

    const onChangeCuota = () => {
        setCuota(!cuota)
    }

    return (
        <>
            <BreadCrumbsFL title_mobile={'Home'} />
            <Grid container sx={{
                paddingTop: { xs: '78px', md: '96px' },
                background: { xs: '#ffffff', md: '#f8f9fb' },
                paddingBottom: { xs: '82px', md: '0px' },
                minHeight: { xs: '78vh', md: 'auto' },
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)'
            }}>
                <Grid item xs={12} lg={8} p={2}>
                    <BoxShadow>
                        <SwicthButton onChange={onChangeCuota} />

                        <BoxPadding style={{
                            textAlign: 'center',
                        }}>
                            <p style={{
                                marginBottom: '0.5rem'
                            }}>Al día de hoy tienes</p>
                            <p style={{
                                fontSize: '20px'
                            }}>{(cuota) ? 's/931.50 cuotas' : 's/18,630.46'}</p>
                        </BoxPadding>
                        <BoxPadding>
                            <TagInfo
                                content={
                                    <>
                                        Tu rentabilidad acumulada en Habitat es s/1,582.15 equivalente a:  +8,49%
                                    </>
                                }
                            />
                        </BoxPadding>
                    </BoxShadow>

                    <BoxShadow>
                        <BoxPadding style={{
                            padding: '16px 0px 0px 0px'
                        }}>
                            <Title
                                titulo='Tu fondo se encuentra compuesto por :'
                                fontSize={'14px'}
                            />
                            <FondoComposicion />

                        </BoxPadding>
                    </BoxShadow>

                    <BoxShadow>
                        <Title titulo='Mis movimientos' />
                        <Tabs />
                        <SimpleTable
                            labels={['fecha', 'descripcion', 'accion']}

                            titulos={[
                                { title: 'Fecha', size: '2' },
                                { title: 'Descripción', size: '2' },
                                { title: 'Acciones', size: '1' },
                            ]}

                            contenido={
                                [
                                    { fecha: '29/03/2022', descripcion: 'Sobre el saldo admir', },
                                    { fecha: '29/03/2022', descripcion: 'Aporte', },
                                    { fecha: '29/03/2022', descripcion: 'Prima de seguro', },
                                    { fecha: '30/03/2022', descripcion: 'Prima de seguro', },
                                    { fecha: '01/04/2022', descripcion: 'Mensaje de prueba', },
                                    { fecha: '02/05/2022', descripcion: 'Seguro nuevo.', },
                                    { fecha: '05/06/2022', descripcion: 'Prima de seguro', },
                                ]
                            }

                        />
                    </BoxShadow>

                    <BoxShadow>
                        <Title
                            titulo='Historial de inversiones'
                            info='Cada periodo inicia con el primer aporte y termina con el retiro total'
                        />

                        <TwoColumnsTable
                            labels={['periodo', 'rendimiento']}
                            titulos={
                                [
                                    { title: 'Periodo', size: '5' },
                                    { title: 'Rendimiento', size: '2' }
                                ]
                            }
                            contenido={
                                [
                                    /* DATA, ICON, COLOR */
                                    {
                                        periodo:
                                        {
                                            data: 'Dic-20 a Jun-21',
                                            icon: null,
                                            color: null
                                        },
                                        rendimiento: {
                                            data: '33.33%',
                                            icon: true,
                                            color: '#48C180'
                                        },
                                    },
                                    {
                                        periodo:
                                        {
                                            data: 'Dic-20 a Jun-21',
                                            icon: null,
                                            color: null
                                        },
                                        rendimiento: {
                                            data: '14.00%',
                                            icon: true,
                                            color: '#48C180'
                                        },
                                    },
                                    {
                                        periodo:
                                        {
                                            data: 'Dic-20 a Jun-21',
                                            icon: null,
                                            color: null
                                        },
                                        rendimiento: {
                                            data: '8.00%',
                                            icon: 'hola',
                                            color: '#48C180'
                                        },
                                    },
                                ]
                            }

                        />
                    </BoxShadow>
                </Grid>


            </Grid>
        </>
    )
}
