/** OBTENER DATOS PERSONALES Y DIRECCIONES*/
export const MIS_DATOS_GET_EXITOSO = "MIS_DATOS_GET_EXITOSO";
export const MIS_DATOS_GET_ERROR = "MIS_DATOS_GET_ERROR";
export const MIS_DATOS_GET_LOADIN = "MIS_DATOS_GET_LOADIN";
export const MIS_DATOS_CLEANER = "MIS_DATOS_CLEANER";
export const MIS_DATOS_CLEANER_PARCIAL = "MIS_DATOS_CLEANER_PARCIAL";

/** ACTUALIZAR SOLO DATOS PERSONALES*/
export const DATOS_PERSONALES_POST_EXITOSO = "DATOS_PERSONALES_POST_EXITOSO";
export const DATOS_PERSONALES_POST_ERROR = "DATOS_PERSONALES_POST_ERROR";
export const DATOS_PERSONALES_POST_LOADIN = "DATOS_PERSONALES_POST_LOADIN";

/** ACTUALIZAR SOLO DIRECCIONES*/
export const DIRECCION_POST_EXITOSO = "DIRECCION_POST_EXITOSO";
export const DIRECCION_POST_ERROR = "DIRECCION_POST_ERROR";
export const DIRECCION_POST_LOADIN = "DIRECCION_POST_LOADIN";

/** ACTUALIZAR CLAVE WEB*/
export const CLAVE_WEB_POST_EXITOSO = "CLAVE_WEB_POST_EXITOSO";
export const CLAVE_WEB_POST_ERROR = "CLAVE_WEB_POST_ERROR";
export const CLAVE_WEB_POST_LOADIN = "CLAVE_WEB_POST_LOADIN";
export const CLAVE_WEB_CLEANER = "CLAVE_WEB_CLEANER";
export const CLAVE_WEB_CLEANER_PARCIAL = "CLAVE_WEB_CLEANER_PARCIAL";

/** SEIS MESES GET */

export const SEIS_MESES_GET_EXITOSO = "SEIS_MESES_GET_EXITOSO";
export const SEIS_MESES_GET_ERROR = "SEIS_MESES_GET_ERROR";
export const SEIS_MESES_LOADIN = "SEIS_MESES_LOADIN";


/** LISTAR TARJETAS */
export const TARJETAS_GET_EXITOSO = "TARJETAS_GET_EXITOSO";
export const TARJETAS_GET_ERROR = "TARJETAS_GET_ERROR";
export const TARJETAS_GET_LOADIN = "TARJETAS_GET_LOADIN";
export const TARJETAS_GET_CLEANER_PARCIAL = "TARJETAS_GET_CLEANER_PARCIAL";
export const TARJETAS_GET_CLEANER = "TARJETAS_GET_CLEANER";

/** ELIMINAR TARJETA */
export const ELIMINAR_TARJETA_POST_EXITOSO = "ELIMINAR_TARJETA_POST_EXITOSO";
export const ELIMINAR_TARJETA_POST_ERROR = "ELIMINAR_TARJETA_POST_ERROR";
export const ELIMINAR_TARJETA_LOADIN = "ELIMINAR_TARJETA_LOADIN";

/** AGREGAR TARJETA */
export const AGREGAR_TARJETA_POST_EXITOSO = "AGREGAR_TARJETA_POST_EXITOSO";
export const AGREGAR_TARJETA_POST_ERROR = "AGREGAR_TARJETA_POST_ERROR";
export const AGREGAR_TARJETA_LOADIN = "AGREGAR_TARJETA_LOADIN";

/** ACTUALIZAR DATOS PARA PROCESOS */
export const ACTUALIZAR_DATOS_PROCESOS = "ACTUALIZAR_DATOS_PROCESOS";