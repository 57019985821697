import React from "react";
import { Item, Content, Header } from "../../components/Accordion";
import { ContentWrapper } from "./styles";

const Caracteristicas = () => {
  return (
    <Item value="caracteristicas">
      <Header>Características</Header>
      <Content>
        <ContentWrapper>
          <ul>
            <li>
              El monto máximo de reembolso es establecido por la SBS y se
              actualiza trimestralmente según el IPC. Este monto es aplicable a
              la fecha de fallecimiento. Puedes revisar el monto actualizado en{" "}
              <a
                href="https://www.sbs.gob.pe/app/stats/EstadisticaSistemaFinancieroResultados.asp?c=S-397"
                target="_blank"
              >
                este enlace.
              </a>
            </li>
            <li>
              Si el afiliado tiene cobertura del{" "}
              <a
                href="http://www.afphabitat.com.pe/seguro-de-invalidez-sobrevivencia-y-gastos-de-sepelio/"
                target="_blank"
              >
                Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio
              </a>
              , este cubrirá el monto; de lo contrario, será pagado con el
              dinero del fondo.
            </li>
            <li>
              AFP Habitat trasladará los recursos de la CIC (Cuenta individual
              de capitalización) al Fondo Tipo 0 en el momento en que sea
              notificada respecto del fallecimiento del afiliado.
            </li>
          </ul>
        </ContentWrapper>
      </Content>
    </Item>
  );
};

export default Caracteristicas;
