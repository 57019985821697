import React from "react";
import { NewCreditCardInputComponent } from "./styles";
import { BsPlusCircle } from "react-icons/bs";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

const CreditCardInput = ({ name, value, required, validate }) => {
  const { watch, register } = useFormContext();

  const isChecked = watch(name) === value;

  return (
    <NewCreditCardInputComponent
      indicatorSide={"right"}
      name={name}
      value={value}
      required={required}
      isChecked={isChecked}
      validate={validate}
    >
      <div className="icon">
        <BsPlusCircle />
      </div>
      <div>Agregar nueva cuenta</div>
    </NewCreditCardInputComponent>
  );
};

export default CreditCardInput;

CreditCardInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
};

CreditCardInput.defaultProps = {
  name: "new-credit-card-input",
  value: "new-credit-card-input",
  required: { value: true, message: "Este campo es requerido." },
  validate: null,
};
