import React from "react";
import { Step, StepLabel } from "@mui/material";
import { StepperComponent } from "./styles";

const Stepper = ({ steps = [], activeStep = 0 }) => {
  return (
    <StepperComponent activeStep={activeStep} alternativeLabel>
      {steps.map(
        ({ label, show }, key) =>
          show !== false && (
            <Step key={key}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
      )}
    </StepperComponent>
  );
};

export default Stepper;
