import React from "react";
import { Link } from "react-router-dom";
import Vector from "./../../../../../assets/icons/Vector.svg";
import Alcancia from "./../../../../../assets/icons/GroupA.svg";
import Actualizar from "./../../../../../assets/icons/estado_cuenta.svg";

import { CardContainer } from "../../../../../components";

import "./index.css";
const accesDirect = ({ product, jubilacion }) => {
  return (
    <CardContainer
      title={"Accesos rápidos"}
      seeMore={false}
      color={"#ffffff"}
      product={false}
      showHeader={true}
      shadow={false}
      border={true}
    >
      {product === "OBL" ? (
        <div className="fund-data-render-desktop">
          <Link to="/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo">
            <div className="access">
              <div className="access-img">
                <img src={Vector} alt="access direct" />
              </div>
              <div className="access-text">
                <h4>Cambio de tipo de fondo</h4>
                <p>
                  Transfiere tu tipo de fondo a otro en cualquiera de los
                  productos que tengas.
                </p>
              </div>
            </div>
          </Link>
          {jubilacion ? (
            <Link to="/mi-habitat-digital/afiliado/jubilacion">
              <div className="access">
                <div className="access-img">
                  <img src={Alcancia} alt="access direct" />
                </div>
                <div className="access-text">
                  <h4>Trámites de jubilación</h4>
                  <p>
                    Disfruta los frutos de tu esfuerzo. Realiza desde aquí los
                    trámites que necesitas.
                  </p>
                </div>
              </div>
            </Link>
          ) : (
            <Link to="/mi-habitat-digital/afiliado/informacion/datos-personales">
              <div className="access">
                <div className="access-img">
                  <img src={Actualizar} alt="acccess direct" />
                </div>
                <div className="access-text">
                  <h4>Actualizar datos</h4>
                  <p>Manten actualizado tus datos</p>
                </div>
              </div>
            </Link>
          )}
        </div>
      ) : (
        <div className="fund-data-render-desktop">
          <Link to="/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo">
            <div className="access">
              <div className="access-img">
                <img src={Vector} alt="access direct" />
              </div>
              <div className="access-text">
                <h4>Cambio de tipo de fondo</h4>
                <p>
                  Transfiere tu tipo de fondo a otro en cualquiera de los
                  productos que tengas.
                </p>
              </div>
            </div>
          </Link>
          <Link to="/mi-habitat-digital/afiliado/apv/fondo-libre-habitat">
            <div className="access">
              <div className="access-img">
                <img src={Alcancia} alt="access direct" />
              </div>
              <div className="access-text">
                <h4>Invertir</h4>
                <p>
                  Disfruta los frutos de tu esfuerzo. Realiza desde aquí los
                  trámites que necesitas.
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
    </CardContainer>
  );
};

export default accesDirect;
