import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";

const ModalActualizarDatos = ({
  isOpen,
  setIsOpen,
  startProcess,
  loadingBtnPrimary = false,
  loadingBtnSecondary = false,
}) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="¿Estás seguro?"
      subtitle={
        "Al volver a calcular, se descartará el resultado de evaluación obtenido."
      }
      loadingBtnPrimary={loadingBtnPrimary}
      onClickBtnPrimary={() => startProcess()}
      textBtnPrimary={"Volver a calcular"}
      loadingBtnSecondary={loadingBtnSecondary}
      isBtnSecondary={true}
      textBtnSecondary="Cancelar"
      onClickBtnSecondary={() =>
        history.push(
          "/mi-habitat-digital/afiliado/informacion/datos-personales"
        )
      }
    />
  );
};

export default ModalActualizarDatos;
