import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CodeContext from "../../context/code/codeContext";
import { CardContainer, Button } from "../../components";
const SolicitudNoProcesada = ({ error, resetear }) => {
  const codeContext = useContext(CodeContext);
  const { limpiarVariablesCode } = codeContext;
  const handleReset = () => {
    resetear();
    limpiarVariablesCode();
  };
  return (
    <CardContainer>
      <div className="component-mensaje">
        <h5 style={{ color: "#bf253d" }}>¡Alerta!</h5>
        <p className="text-center">{error.message}</p>
        <p className="contenedor-btn-no-proceso">
          <Link to="/mi-habitat-digital/afiliado/inicio">
            <Button className="btn-white custom-no-solicitud-btn">
              Volver al inicio
            </Button>
          </Link>
          <Button
            className="btn-white custom-no-solicitud-btn"
            onClick={handleReset}
          >
            <i className="fa fa-repeat"></i> Volver a intentarlo
          </Button>
        </p>
      </div>
    </CardContainer>
  );
};

export default SolicitudNoProcesada;
