import React, { useState } from "react";
import { Collapse, UncontrolledTooltip } from "reactstrap";
// comentario
export default function DetallePagoFila(props) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const {
    id,
    date,
    debtType,
    updated,
    periodSelect,
    setPeriodSelect,
    updatedBackground,
    ryrValue,
    state,
    afp,
  } = props;

  const toogleCollapse = () => {
    setPeriodSelect(id);
    setOpenCollapse(!openCollapse);
  };

  return (
    <div className="mb-3">
      <div className="row-list row-styles" onClick={() => toogleCollapse()}>
        <div className="left-side">
          <div className="date-content">
            <span>{date}</span>
          </div>
        </div>
        <div className="right-side">
          <div className="values-content">
            <div className="value-left text-center">
              <span className="color-blue">
                {debtType === "C" ? "Deuda real" : "Deuda presunta"}
              </span>
            </div>
            <div className="value-right text-center">
              <span className="color-greenlight">
                S/ {Number(updated).toFixed(2)}
              </span>
            </div>
            <div className="icon-toogle">
              <i
                className={
                  periodSelect === id && openCollapse
                    ? "fa fa-arrow-up"
                    : "fa fa-arrow-down"
                }
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={periodSelect === id && openCollapse}>
        <div className="period-detail-content">
          <div className="detail-row">
            <span className="color-blue">
              Fondo total: S/ {updatedBackground}
            </span>
            <i
              id="fondo-actalizado"
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip placement="left" target="fondo-actalizado">
              Aporte pendiente de ingresar a tu fondo
            </UncontrolledTooltip>
          </div>
          <div className="detail-row">
            <span className="color-blue">
              AFP:{" "}
              {afp === "HA"
                ? "Habitat"
                : afp === "IN"
                ? "Integra"
                : afp === "PR"
                ? "Profuturo"
                : "Prima"}
            </span>
            <i
              id="afp-origen"
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip placement="left" target="afp-origen">
              AFP responsable del cobro
            </UncontrolledTooltip>
          </div>
          <div className="detail-row">
            <span className="color-blue">
              Seguro y comisión: S/ {Number(ryrValue).toFixed(2)}
            </span>
            <i
              id="ryr-actalizado-mobile"
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip
              placement="left"
              target="ryr-actalizado-mobile"
            >
              Seguro + Comisión AFP
            </UncontrolledTooltip>
          </div>
          <div className="footer-period-detail">
            <div className="dark-block text-center">
              <span>Estado Gestión</span>
            </div>
            <div className="light-block text-center">
              <span id="estado-gestion-span">
                {state === "A" ? "Administrativo" : "Judicial"}
              </span>
              <UncontrolledTooltip placement="top" target="estado-gestion-span">
                {state === "J"
                  ? "Se ha presentado al poder judicial el cobro de la deuda de su empleador."
                  : state === "A"
                  ? "La solicitud ha sido recibida y se encuentra en gestión con el empleador. "
                  : "El empleador se encuentra en un proceso de liquidación a cargo de Indecopi."}{" "}
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
