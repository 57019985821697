import React from 'react';
import person1 from "../../../../assets/img/person-1.png";
import person2 from "../../../../assets/img/person-2.png";
import person3 from "../../../../assets/img/person-3.png";
import person4 from "../../../../assets/img/person-4.png";
import person5 from "../../../../assets/img/person-5.png";
import person6 from "../../../../assets/img/person-6.png";
import person7 from "../../../../assets/img/person-7.png";
import "./index.css";

const Section7 = () => {
    const goToUrl = (url) => {
        window.open(url, '_self').focus();
    }

    return (
        <section className="section-content section7">
            <div className="container-new-home">
                <h2 className="left-title h2-new-home">Cada etapa de tu vida es importante</h2>
                <p className="text-description">
                    Por eso queremos informarte para que puedas tomar mejores decisiones sobre tu
                    futuro.
                </p>

                <div className="mb-3 mt-3">
                    <div className="desktop-row row-a">
                        <div className="card-person-opinion brown-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/pronto-voy-a-aportar/')}>
                            <div className="img-content">
                                <img src={person1} alt="persona-brazos-cruzados" />
                            </div>
                            <div className="span-content">
                                <span>Pronto voy a<br />aportar</span>
                            </div>
                        </div>

                        <div className="card-person-opinion blue-dark-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/estoy-aportando-hace-poco/')}>
                            <div className="span-content">
                                <span>Estoy aportando<br />hace poco</span>
                            </div>
                            <div className="img-content">
                                <img src={person2} alt="persona-brazos-cruzados" />
                            </div>
                        </div>

                        <div className="card-person-opinion blue-light-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/he-aportado-varios-anos/')}>
                            <div className="img-content">
                                <img src={person3} alt="persona-brazos-cruzados" />
                            </div>
                            <div className="span-content">
                                <span>He aportado<br />varios años</span>
                            </div>
                        </div>
                    </div>

                    <div className="desktop-row row-b">
                        <div className="card-person-opinion purple-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/soy-trabajador-independiente/')}>
                            <div className="span-content">
                                <span>Soy trabajador<br />independiente</span>
                            </div>
                            <div className="img-content">
                                <img src={person4} alt="persona-brazos-cruzados" />
                            </div>
                        </div>

                        <div className="card-person-opinion green-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/estoy-proximo-a-jubilarme/')}>
                            <div className="img-content">
                                <img src={person5} alt="persona-brazos-cruzados" />
                            </div>
                            <div className="span-content">
                                <span>Estoy próximo<br />a jubilarme</span>
                            </div>
                        </div>

                        <div className="card-person-opinion red-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/soy-jubilado/')}>
                            <div className="span-content">
                                <span>Me jubilé<br />del trabajo</span>
                            </div>
                            <div className="img-content">
                                <img src={person6} alt="persona-brazos-cruzados" />
                            </div>
                        </div>

                        <div className="card-person-opinion yellow-light-color" onClick={() => goToUrl('https://www.afphabitat.com.pe/soy-beneficiario-o-heredero/')}>
                            <div className="img-content">
                                <img src={person7} alt="persona-brazos-cruzados" />
                            </div>
                            <div className="span-content">
                                <span>Soy beneficiario<br />o heredero</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Section7;
