import React, { useEffect, useContext, useState } from "react";
import { VistaEspera } from "./etapas/components";
import imgConfirmation from "../../../../assets/confirmacion.svg";
import Grid from "@mui/material/Grid";

import retiro from "../../../../assets/icons/retiro.svg";
import fondo from "../../../../assets/icons/fondo.svg";
import cuenta from "../../../../assets/icons/cuenta1.svg";
import warning from "../../../../assets/icons/icons8-warning-64.png";
import moment from "moment";
import AuthContext from "../../../../context/autenticacion/authContext";
import jubilacionContext from "../../../../context/jubilacion/jubilacionContext";

export default function JubilacionRejaRetiroFin(props) {
  const authContext = useContext(AuthContext);
  const { birthday } = authContext;
  const { rejaData, typeBankJR } = useContext(jubilacionContext);
  const [isNationalBank, setIsNationalBank] = useState(true);
  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");
  useEffect(() => {
    loadNPS();
    window.scrollTo(0, 0);
  }, []);

  const loadNPS = () => {
    const filterAge =
      age < 25
        ? "Menores de 25"
        : age >= 25 && age < 35
        ? "25 A 34"
        : age >= 35 && age < 51
        ? "35 a 51"
        : age >= 51 && age < 65
        ? "51 a 65"
        : "Mayores de 65";

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: "Trámite de REJA",
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", { detail: { id: 1303 } })
    );
  };

  useEffect(() => {
    function getTypeBank() {
      // console.log("TYPEBANK : ", typeBankJR);
      // console.log("rejaData: ", rejaData);
      if (typeBankJR !== null) {
        if (typeBankJR) setIsNationalBank(true);
        else setIsNationalBank(false);
      } else {
        if (rejaData && rejaData.data.type_account_bank !== null) {
          let type = rejaData.data.type_account_bank === 1 ? true : false;
          setIsNationalBank(type);
        }
      }
    }
    getTypeBank();
  }, []);

  return (
    <div>
      <VistaEspera
        imgLogo={imgConfirmation}
        title={`${sessionStorage.getItem(
          "nombreUsuario"
        )}, hemos concluido con tu solicitud de retiro de hasta el 95.5%`}
        description={
          <>
            Según la opción elegida, los plazos para el depósito pueden ser los
            siguientes:
            <br />
            <br />
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={4}>
                <div className="box-wait">
                  <div className="img-retiro-fin">
                    <img src={cuenta} alt="reja retiro" />
                  </div>
                  {/* <p>Cuenta bancaria Nacional: 3 días</p>
                  <p>Cuenta bancaria extranjera: 7 días*</p> */}
                  {isNationalBank
                    ? "Cuenta Nacional: 3 días"
                    : "Cuenta Extranjera: 7días*"}
                </div>
              </Grid>
              <Grid item xs={6} sm={4}>
                <div className="box-wait">
                  <div className="img-retiro-fin">
                    <img src={fondo} alt="reja retiro" />
                  </div>
                  <p>
                    Fondo Libre Habitat:
                    <br /> 7 días para verlo acreditado
                  </p>
                </div>
              </Grid>
              <Grid item xs={6} sm={5} className="container-wait email">
                <h4>Te enviaremos un correo con tu:</h4>
                <div className="box-wait">
                  <div className="img-retiro-fin">
                    <img src={retiro} alt="reja retiro" />
                  </div>
                  <p>Constancia de retiro</p>
                  <p>DJ de no Continuar Aportando</p>
                </div>
              </Grid>
            </Grid>
            <br />
          </>
        }
        note={
          <>
            <Grid container>
              <Grid item xs={12} sm={2} className="px-0">
                <div className="img-warming">
                  <img src={warning} alt="reja retiro" />
                </div>
              </Grid>
              <Grid item xs={12} sm={8} className="px-0">
                <div>
                  {/* *El plazo para acreditar el dinero a una cuenta del exterior
                  puede variar de 7 días a más según los plazos internos del
                  banco receptor.
                  <br />
                  <br /> */}
                  {isNationalBank && (
                    <>
                      Si tus datos bancarios no son correctos, el proceso de
                      pago y cobro será mediante un cheque por ventanilla,
                      deberás acercarte con tu documento de identidad en los
                      siguientes bancos:
                      <ul style={{ marginLeft: "20px" }}>
                        <li>Scotiabank: Si utilizas DNI peruano.</li>
                        <li>
                          BCP: Si utilizas Carnet de Extranjería o Pasaporte.
                        </li>
                      </ul>
                      {/* <br />
                      <br /> */}
                    </>
                  )}
                  IMPORTANTE: <br />
                  <div className="list-retiro-fin">
                    {/* <ul>
                      <li>
                        Si la cuenta regitrada es rechazada por el banco, nos
                        contactaremos contigo para solicitarte una nueva cuenta
                        y sustento para concluir con la transferencia
                        solicitada.
                      </li>
                    </ul> */}
                    {isNationalBank ? (
                      <>
                        Prevén el rechazo evitando el registro de una cuenta
                        bancaria inactiva, mancomunada y/o la carga de un
                        sustento bancario incompleto o adulterado.
                      </>
                    ) : (
                      <>
                        * El plazo para acreditar el dinero a una cuenta del
                        exterior puede variar de 7 días a más según los plazos
                        internos del banco receptor.
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
        btnLabel="Continuar"
        btnFunction={() =>
          props.history.push("/mi-habitat-digital/afiliado/jubilacion")
        }
      />
    </div>
  );
}
