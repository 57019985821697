import React from "react";
import { Wrapper } from "../../components/Accordion";
import Caracteristicas from "../Caracteristicas";
import Requisitos from "../Requisitos";
import QuienesAcceden from "../QuienesAcceden";
import Conceptos from "../Conceptos";
import PlazosFormaPago from "../PlazosFormaPago";

const InformacionAcordion = () => {
  return (
    <Wrapper
    // defaultValue="requisitos"
    >
      <Caracteristicas />
      <QuienesAcceden />
      <Requisitos />
      <Conceptos />
      <PlazosFormaPago />
    </Wrapper>
  );
};

export default InformacionAcordion;
