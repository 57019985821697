import {
  BOLETA_EXITOSO,
  BOLETA_ERROR,
  BOLETA_LOADIN,
  BOLETA_CLEANER,
  BOLETA_CLEANER_PARCIAL,
  PERIODO_EXITOSO,
  PERIODO_ERROR,
  PERIODO_LOADIN,
  PERIODO_CLEANER,
  PERIODO_CLEANER_PARCIAL
} from "../../types/boleta";

export default (state, action) => {
  switch (action.type) {
    case BOLETA_EXITOSO:
      return {
        ...state,
        datosBoleta: action.payload,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case BOLETA_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case BOLETA_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
        datosBoleta: null,
        loadingPage: false,
      };
    case BOLETA_CLEANER:
      return {
        ...state,
        datosBoleta: null,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case BOLETA_CLEANER_PARCIAL:
      return {
        ...state,
        datosBoleta: null,
        loadingPage: false,
      };
      case PERIODO_EXITOSO:
        return {
          ...state,
          periodos: action.payload,
          mensajeAlerta: null,
          loadingPage: false,
        };
      case PERIODO_LOADIN:
        return {
          ...state,
          loadingPage: true,
        };
      case PERIODO_ERROR:
        return {
          ...state,
          mensajeAlerta: action.payload,
          periodos: null,
          loadingPage: false,
        };
      case PERIODO_CLEANER:
        return {
          ...state,
          periodos: null,
          mensajeAlerta: null,
          loadingPage: false,
        };
      case PERIODO_CLEANER_PARCIAL:
        return {
          ...state,
          periodos: null,
          loadingPage: false,
        };
    default:
      break;
  }
};
