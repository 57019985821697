import React, { useContext, useEffect, useState } from "react";
// import {display} from "html2canvas/dist/types/css/property-descriptors/display";
import "./style.css";
import iconclose from "./public/img/iconos/close.png";
import iconAsesor from "./public/img/iconos/icono.hombre.desktop.widget.svg";
import iconAsesora from "./public/img/iconos/icono.mujer.desktop.widget.svg";
import iconWhatsapp from "./public/img/iconos/icon-whatsapp.png";

import AsesorContext from "../../context/asesor/asesorContext";
import { Link } from "react-router-dom";
const Asesor = (props) => {
  const asesorContext = useContext(AsesorContext);
  const { ejecutivo, clickAsesor, setClickAsesor } = asesorContext;
  const openAsesor = (e) => {
    e.preventDefault();
    setClickAsesor(e);
  };
  const setTelefono = (e) => {
    e.preventDefault();

    setClickTelefono(!clickTelefono);
  };

  const [clasesForOpen, setClases] = useState("m_chat__menu m-animation");
  const [clickTelefono, setClickTelefono] = useState(false);
  const [visibleTelefono, setVisibleTelefono] = useState(
    "m-contact__container"
  );
  useEffect(() => {
    if (clickTelefono) {
      setVisibleTelefono("m-contact__container m-visible");
    } else {
      setVisibleTelefono("m-contact__container");
    }
  }, [clickTelefono]);
  useEffect(() => {
    if (!clickAsesor) {
      setClases("m_chat__menu m-animation");
    } else {
      setClases("m_chat__menu m-animation m-visible");
    }
  }, [clickAsesor]);

  return (
    <React.Fragment>
      <div className="m-backdrop" style={{ display: "none" }}></div>
      <div
        className="m-fixed__contact m-button__animation m-animation"
        onClick={openAsesor}
      >
        <div className="m-content">
          <p>Contacta a tu {ejecutivo.r_sexo == "M" ? "asesor" : "asesora"}</p>
          <div className="m-arrow"></div>
        </div>
        <div className="m-image">
          <img
            src={ejecutivo.r_sexo == "M" ? iconAsesor : iconAsesora}
            alt="icono-asesor"
          />
        </div>
      </div>

      <div className={clasesForOpen}>
        <div className="m-close__menu m-button__animation" onClick={openAsesor}>
          <img src={iconclose} alt="icono-cerrar" />
        </div>
        <div className="m-chat__menu--title">
          <div className="m-chat__image">
            <img
              src={ejecutivo.r_sexo == "M" ? iconAsesor : iconAsesora}
              alt="icono-asesor"
            />
          </div>
          <div className="m-chat__content">
            <h2>{ejecutivo.r_nombre}</h2>
            <Link to="/mi-habitat-digital/asesor">
              <p>{ejecutivo.r_sexo == "M" ? "Contáctalo" : "Contáctala"}</p>{" "}
            </Link>
          </div>
        </div>
        <div className="m-chat__menu--container">
          <div className="m-network__container">
            <div
              className="m-network__item m-network--contact"
              onClick={setTelefono}
            >
              <div className="m-image">
                <i className="fa fa-volume-control-phone"></i>
              </div>
              <p>Celular</p>
            </div>
            <div className="m-network__item m-network--whatsapp">
              <a
                className="m-image"
                href={
                  "https://api.whatsapp.com/send?phone=+51" +
                  ejecutivo.r_celular +
                  "&text="
                }
                target="_blank"
              >
                <img src={iconWhatsapp} alt="icono-telefono" />
              </a>
              <p>Whatsapp</p>
            </div>
            <div className="m-network__item m-network--habi">
              <a className="m-image" href={"mailto:" + ejecutivo.r_correo}>
                <i className="fa fa-envelope"></i>
              </a>
              <p>Email</p>
            </div>
          </div>
          <div className={visibleTelefono}>
            <a
              href={"tel:+51" + ejecutivo.r_celular}
              className="m-contact__item"
            >
              {ejecutivo.r_celularshow}
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Asesor;
