import React, { useState } from "react";
import { DownloadButtonComponent } from "./styles";
import { useHistory, Link, useParams } from "react-router-dom";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { MyDocument } from "../../../DescargarInformacionRetiro";
import clienteAxios from "../../../../../config/axios";
import { saveAs } from "file-saver";
import useWindowSize from "../../../../../hooks/useWindowSize";

const DownloadButton = ({ path = "/", icon, ...props }) => {
  const { nro } = useParams();
  const [downloading, setDownloading] = useState(null);

  const { width } = useWindowSize();
  const buttonBreakpoint = 500;
  const request = async () => {
    try {
      setDownloading(true);
      const response = await clienteAxios(
        "/fondo-libre/retiro-informacion-pdf",
        {
          method: "POST",
          data: {
            nro_solicitud: nro,
          },
        }
      );
      await generatePdfDocument(response.data.data);
      setDownloading(false);
    } catch (error) {
      console.error(error);
      setDownloading(false);
      return null;
    }
  };

  const generatePdfDocument = async (documentData) => {
    const blob = await pdf(<MyDocument data={documentData} />).toBlob();
    saveAs(blob, `Solicitud de retiro ${nro}`);
  };

  return (
    <div onClick={request}>
      <DownloadButtonComponent
        size="large"
        model="secondary"
        {...props}
        disabled={downloading}
      >
        {!downloading && (
          <>
            {icon && icon}{" "}
            {width > buttonBreakpoint
              ? "Descargar información de retiro"
              : "Descargar"}
          </>
        )}
        {downloading === true && <>{icon && icon} Descargando</>}
      </DownloadButtonComponent>
    </div>
  );
};

export default DownloadButton;
