import React from "react";

export const SinDatos = (props) => {
  const { changeStepOne } = props;

  const change = () => {
    changeStepOne();
  };

  return (
    <div>
      <h5 className="margin-center text-center">
        <b> No puedo validar esta solicitud</b>
      </h5>

      <p>
        Por tu seguridad, si ya no cuentas con tus métodos de validacion
        registrados, comunicate con nosotros.
      </p>
      <p>
        {" "}
        <b>Llama al:</b> 01 230 2200
      </p>

      <button onClick={change} className={`mt-3 btn-recover margin-center `}>
        Entendido{" "}
      </button>
    </div>
  );
};

export const SinDatosD = (props) => {
  const { changeStepOne } = props;

  const change = () => {
    changeStepOne();
  };

  return (
    <div className="">
      <h5 className="mb-4 margin-center text-center">
        <b> No puedo validar esta solicitud</b>
      </h5>

      <p>
        Por tu seguridad, si ya no cuentas con tus métodos de validacion
        registrados, comunicate con nosotros.
      </p>
      <p>
        {" "}
        <b>Llama al:</b> 01 230 2200
      </p>
      <button
        onClick={change}
        className={`mt-2 btn-recover margin-center btn-modal-total`}
      >
        Entendido{" "}
      </button>
    </div>
  );
};
