import { Box } from "@mui/material";
import styled, { css } from "styled-components";

export const ContentText = styled(Box)`
  ${(p) => p.theme.tipography.bodySmallRegular};
  & h3 {
    ${(p) => p.theme.tipography.h4}
    margin-bottom: 8px;
    color: ${(p) => p.theme.palette.grayscale[500]};
    &.HBT_PMO_MainAmount {
      color: ${(p) => p.theme.palette.secondary.main};
    }
    ${(p) =>
      p.color === "primary" &&
      css`
        &.HBT_PMO_MontoPnesion {
          color: ${(p) => p.theme.palette.primary.main};
        }
      `}
  }

  & h4 {
    ${(p) => p.theme.tipography.subtitle1}
  }
`;
