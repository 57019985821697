import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import { VistaEspera } from "../../../jubilacionReja/etapas/components";
import sgvAdvice from "../../../../../../assets/recuerda.svg";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

import { Retiro } from "./components";

export default function Paso4(props) {
  const { processId, getOutOfProcess, history, modalidad, apvf, setApvf } =
    props;

  const [showView, setShowView] = useState(0);
  const [contents, setContents] = useState([]);
  const [dependencies, setDependencies] = useState({
    contribution: {},
    meet_the_time_in_spp: false,
  });
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    limpiarObtenerContenidoTemaJubilacion,
    mensajeAlerta,
    resetear,
  } = jubilacionContext;

  useEffect(() => {
    obtenerContenidoTemaJubilacion(props.processId, 3);
    return () => {
      limpiarObtenerContenidoTemaJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
        setContents(contenidoTemaJubilacionData.contents);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setShowView(1);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  if (mensajeAlerta !== null) return <p></p>;
  if (contenidoTemaJubilacionLoading || contents.length === 0)
    return <Loading />;

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <VistaEspera
            imgLogo={sgvAdvice}
            title="Aviso importante"
            description={
              <>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,la
                siguiente etapa es muy importante para tramitar con éxito tu
                nuevo retiro. Te pedimos leer cuidadosamente toda la información
                y completar de manera correcta cada detalle
              </>
            }
            btnLabel="Continuar"
            btnFunction={() => setShowView(2)}
          />
        );
      case 2:
        return (
          <Retiro
            getOutOfProcess={() => getOutOfProcess()}
            processId={processId}
            contentId={contents[0].id}
            endProcess={() =>
              history.push(
                "/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso-fin"
              )
            }
            endProcessError={() =>
              history.push(
                "/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso-error"
              )
            }
            dependencies={dependencies}
            modalidad={modalidad}
            apvf={apvf}
            setApvf={setApvf}
            resetear={resetear}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
