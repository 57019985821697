import React, { useState, useEffect, useContext } from "react";
import { VistaEspera } from "../components";
import { SelectMode } from "./components";
import Loading from "../../../../../../components/Loading/index.jsx";
import sgvAdvice from "../../../../../../assets/recuerda.svg";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso6(props) {
  const [showView, setShowView] = useState(0);
  const [dependencies, setDependencies] = useState({
    contribution: {},
    meet_the_time_in_spp: false,
  });
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const { obtenerContenidoPasoReja, temasAsesoriaRejaData } = jubilacionContext;
  useEffect(() => {
    props.setCurrectStepTitle("Eleción de modalidad");
    obtenerContenidoPasoReja(props.processId, 6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);
  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        props.setCurrectStepTitle(temasAsesoriaRejaData.name);
        setDependencies(temasAsesoriaRejaData.dependencies);
        setShowView(1);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  if (
    temasAsesoriaRejaData?.name !== "Elección de Modalidad" ||
    !dependencies?.contribution
  ) {
    return <Loading />;
  }

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <VistaEspera
            imgLogo={sgvAdvice}
            title="Importante"
            description={
              <>
                Lee con cuidado toda la información y completa correctamente
                cada detalle para poder tramitar con éxito tu retiro y/o
                pensión.
              </>
            }
            btnLabel="Continuar"
            btnFunction={() => setShowView(2)}
          />
        );
      case 2:
        return (
          <SelectMode
            endSixthStep={() => props.setCurrectStepNumber(7)}
            getOutOfProcess={() => props.getOutOfProcess()}
            dependencies={dependencies}
          />
        );
      default:
        return <Loading />;
    }
  };

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
