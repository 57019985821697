import React from "react";
import { UncontrolledTooltip } from "reactstrap";
// coemntario QA
export default function DetallePagoFilaDesktop(props) {
  const {
    id,
    date,
    debtType,
    updated,
    updatedBackground,
    ryrValue,
    state,
    afp,
  } = props;

  return (
    <div className="row-list mb-3">
      <div className="left-side-desktop">
        <div className="date-content">
          <span>{date}</span>
        </div>
      </div>
      <div className="center-side-desktop">
        <div className="values-content-desktop">
          <div className="desktop-value-state text-center">
            <span className="color-blue">
              {debtType === "C" ? "Deuda real" : "Deuda presunta"}
            </span>
          </div>

          <div className="desktop-value-1 text-center">
            <span className="color-greenlight">
              S/ {Number(updated).toFixed(2)}
            </span>
          </div>
          <div className="desktop-value-2 text-center">
            <span className="color-blue">S/ {updatedBackground}</span>
          </div>
          <div className="desktop-value-3 text-center">
            <span className="color-blue">S/ {Number(ryrValue).toFixed(2)}</span>
          </div>
          <div className="desktop-value-2 text-center">
            <span className="color-blue">
              {afp === "HA"
                ? "Habitat"
                : afp === "IN"
                ? "Integra"
                : afp === "PR"
                ? "Profuturo"
                : "Prima"}
            </span>
          </div>
        </div>
      </div>
      <div className="right-side-desktop">
        <div className="state-management">
          <span>
            Estado de gestión: <br />{" "}
            <strong id={`estado-gestion-strong-${id}`}>
              {state === "A" ? "Administrativo" : "Judicial"}
            </strong>
          </span>
          <UncontrolledTooltip
            placement="left"
            target={`estado-gestion-strong-${id}`}
          >
            {state === "A"
              ? "La solicitud ha sido recibida y se encuentra en gestión con el empleador. "
              : "Se ha presentado al poder judicial el cobro de la deuda de su empleador."}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
}
