import React from "react";
import Grid from "@mui/material/Grid";
import { CardContainer } from "../../../components";
const InfoUsuario = () => {
  return (
    <Grid item xs={12} lg={4}>
      <CardContainer
        title={"Recuerda"}
        seeMore={false}
        color={"#bf0d3e"}
        product={true}
        showHeader={true}
      >
        <ul className="recomendaciones mb-0">
          <li>
            {" "}
            <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
              A.
            </p>
            <p className="p-0 text-justify">
              Mantén actualizada la lista de tarjetas para así poder realizar
              tus operaciones de inversión y retiro en Fondo Libre Habitat.
            </p>
          </li>
          <li>
            {" "}
            <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
              B.
            </p>
            <p className="p-0 text-justify">
              Podrás eliminar y agregar fácilmente las tarjetas que manejes.
            </p>
          </li>
          <li>
            {" "}
            <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
              C.
            </p>
            <p className="p-0 text-justify">
              Recuerda que podrás tener un máximo de 5 tarjetas.
            </p>
          </li>
        </ul>
      </CardContainer>
    </Grid>
  );
};

export default InfoUsuario;
