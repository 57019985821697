import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";
import { ContentTerms, ContentTextTerms } from "./styles";
import Divider from "../../../../../../components/Divider";
import Alert from "../../../../../../components/Alert";
const ModalActualizarDatos = ({
  isOpen,
  setIsOpen,
  misDatosGet,
  nextStep,
  loadingBtnPrimary = false,
  loadingBtnSecondary = false,
}) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      loadingBtnPrimary={loadingBtnPrimary}
      onClickBtnPrimary={nextStep}
      textBtnPrimary={"Continuar"}
      loadingBtnSecondary={loadingBtnSecondary}
      isBtnSecondary={true}
      textBtnSecondary="Volver"
      onClickBtnSecondary={() =>
        history.push(
          "/mi-habitat-digital/afiliado/informacion/datos-personales"
        )
      }
    >
      <>
        <ContentTerms>
          <h2>Importante</h2>
          <p>
            Recuerda que el establecimiento de tu Pensión Mínima Objetivo
            quedará descartado en los siguientes casos:
          </p>

          <ContentTextTerms>
            <div className="HBT_PMO_TermsAcogimeinto">{"1)"}</div>
            <div>
              Si al jubilarte no tienes el saldo suficiente para financiar tu
              pensión establecida.
            </div>
          </ContentTextTerms>
          <ContentTextTerms>
            <div className="HBT_PMO_TermsAcogimeinto">{"2)"}</div>
            <div>
              Al solicitar cualquier modalidad de jubilación anticipada como por
              ejemplo REJA, JAO u otros regímenes que te permitan generar
              pensión antes de los 65 años.
            </div>
          </ContentTextTerms>
          <ContentTextTerms>
            <div className="HBT_PMO_TermsAcogimeinto">{"3)"}</div>
            <div>
              Si realizas un retiro parcial que haga que el monto restante sea
              inferior al necesario para la pensión establecida.
            </div>
          </ContentTextTerms>
        </ContentTerms>
        <Divider />
        <Alert
          description={
            <p>
              Podrás modificar tu Pensión Mínima Objetivo en cualquier momento
              desde{" "}
              {<b> {"Inicio > Beneficios y Jubilación > Pensión Objetivo."}</b>}
            </p>
          }
        />
      </>
    </Modal>
  );
};

export default ModalActualizarDatos;
