import React from "react";
import "./index.css";
import { withRouter } from "react-router";
import { useEffect } from "react";
const ModalDetalle = ({
  dispositivo,
  buttonCloseTop,
  closeModal,
}) => {
  useEffect(() => {
    // limpiarMensajeErrorPost();
    // eslint-disable-next-line
  }, []);


  return (
    <section
      className={
        buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`
      }
    >
      <div
        className={
          buttonCloseTop ? `modal-content` : `modal-content modal-content-full`
        }
      >
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={()=>closeModal()}></i>
          </div>
        )}

        <div
          className={
            buttonCloseTop
              ? `mb-modal-title d-flex mt-3`
              : `mb-modal-title d-flex`
          }
        >
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={()=>closeModal()}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>Ya cuentas con una solicitud</strong>
          </p>
        </div>
        <section>
          <p className="text_message">
            {sessionStorage.getItem("nombreUsuario")}, ya cuentas con una solicitud 
            registrada, revisa tu bandeja de correo no deseado o spam para validar la 
            información que te hemos enviado. 

          </p>
          <p className="text_message_1">
Cualquier consulta o duda puedes comunicarte con nuestros <a href="https://www.afphabitat.com.pe/canales-de-atencion-remotos/" target="blank__">canales de atención</a>. 
Gracias</p>
       

          <section className="section-buttons d-flex justify-content-around">
            <button onClick={()=>closeModal()} className="btn-white btn-modal-confirm">
              Cerrar{" "}
            </button>
         
          </section>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalDetalle);
