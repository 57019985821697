import React, { useReducer } from "react";
import DrsppContext from "./drsppContext";
import DrsppReducer from "./drsppReducer";
import clienteAxios from "../../config/axios";
import {
  DRSPP_EXITOSO,
  DRSPP_ERROR,
  DRSPP_LOADIN,
  DRSPP_CLEANER,
  DRSPP_CLEANER_PARCIAL,
} from "../../types/drspp";

const DrsppState = (props) => {
  const initialState = {
    datosDrspp: null,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosDrspp = async () => {
    dispatch({
      type: DRSPP_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/mis-datos/drspp");
      dispatch({
        type: DRSPP_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, DRSPP_ERROR);
    }
  };

  const limpiarVariablesDrspp = () => {
    dispatch({
      type: DRSPP_CLEANER,
    });
  };
  const limpiarVariablesDrsppParcial = () => {
    dispatch({
      type: DRSPP_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(DrsppReducer, initialState);
  return (
    <DrsppContext.Provider
      value={{
        datosDrspp: state.datosDrspp,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        obtenerDatosDrspp,
        limpiarVariablesDrspp,
        limpiarVariablesDrsppParcial,
      }}
    >
      {props.children}
    </DrsppContext.Provider>
  );
};

export default DrsppState;
