import {
  COMISION_HISTORICA_EXITOSO,
  COMISION_HISTORICA_ERROR,
  COMISION_HISTORICA_LOADIN,
  COMISION_HISTORICA_CLEANER,
  COMISION_HISTORICA_CLEANER_PARCIAL,
} from "../../types/comisionHistorica";

export default (state, action) => {
  switch (action.type) {
    case COMISION_HISTORICA_EXITOSO:
      return {
        ...state,
        datosComisionHistorica: action.payload,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case COMISION_HISTORICA_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case COMISION_HISTORICA_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
        datosComisionHistorica: null,
        loadingPage: false,
      };
    case COMISION_HISTORICA_CLEANER:
      return {
        ...state,
        datosComisionHistorica: null,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case COMISION_HISTORICA_CLEANER_PARCIAL:
      return {
        ...state,
        datosComisionHistorica: null,
        loadingPage: false,
      };
    default:
      break;
  }
};
