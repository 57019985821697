import React, { useEffect } from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import { Button } from "../../../components";
const SolicitudProcesadaUsuario = ({ info, recargar, loading }) => {
  useEffect(() => {
    //DATALAYER CONFIGURACIONES USUARIO : DATOS PERSONALES
    window.dataLayer.push({
      event: "inputs_update",
      description: "datos modificados",
    });
  }, []);

  return (
    <div className="card">
      <div className="custom-header-cambio-fondo custom-header-li card-header">
        A continuación te mostramos un resumen de los datos modificados.
      </div>
      <div className="card-body content-solicitud-procesada d-flex flex-column">
        <ul className="list-group mb-3">
          {info.r_prefijo_telefono && (
            <li className="list-group-item d-flex justify-content-between">
              <span>
                <strong>Código de discado</strong>
              </span>{" "}
              {info.r_prefijo_telefono.r_respuesta === "0" ? (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_prefijo_telefono.r_mensaje}
                </span>
              ) : (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_prefijo_telefono.r_mensaje}
                </span>
              )}
            </li>
          )}
          {info.r_telefono && (
            <li className="list-group-item d-flex justify-content-between">
              <span>
                <strong>Teléfono fijo</strong>
              </span>{" "}
              {info.r_telefono.r_respuesta === "0" ? (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_telefono.r_mensaje}
                </span>
              ) : (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_telefono.r_mensaje}
                </span>
              )}
            </li>
          )}
          {info.r_celular && (
            <li className="list-group-item d-flex justify-content-between">
              <span>
                <strong>Celular</strong>
              </span>{" "}
              {info.r_celular.r_respuesta === "0" ? (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_celular.r_mensaje}
                </span>
              ) : (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_celular.r_mensaje}
                </span>
              )}
            </li>
          )}
          {info.r_correo && (
            <li className="list-group-item d-flex justify-content-between">
              <span>
                <strong>E-mail</strong>
              </span>{" "}
              {info.r_correo.r_respuesta === "0" ? (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_correo.r_mensaje}
                </span>
              ) : (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_correo.r_mensaje}
                </span>
              )}
            </li>
          )}
          {info.r_tipo_eecc && (
            <li className="list-group-item d-flex justify-content-between">
              <span>
                <strong>Estado de cuenta virtual</strong>
              </span>{" "}
              {info.r_tipo_eecc.r_respuesta === "0" ? (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_tipo_eecc.r_mensaje}
                </span>
              ) : (
                <span className="m-0 text-right custom-mb-modal-bco">
                  {info.r_tipo_eecc.r_mensaje}
                </span>
              )}
            </li>
          )}
        </ul>
        <div className="text-center">
          <Button
            className="btn-red btn-red-custom-ec"
            onClick={() => recargar()}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}{" "}
            Volver
          </Button>
        </div>
      </div>
    </div>
  );
};

export default hocWidthWindow(SolicitudProcesadaUsuario);
