import styled, { css } from "styled-components";
import { device } from "../../utils/device";

const primaryLink = css`
  ${(p) =>
    p.variant === "link" &&
    css`
      border-color: transparent;
      background-color: transparent;
      color: ${(p) => p.theme.palette.secondary.main};
      ${(p) => p.theme.tipography.bodySmallMedium}

      &:hover:enabled {
        background-color: ${(p) => p.theme.palette.transparencies.lightest};
        box-shadow: none;
      }

      &:disabled {
        background-color: transparent;
        border-color: transparent;
        color: ${(p) => p.theme.palette.grayscale[300]};
        cursor: not-allowed;
      }
    `}
`;

const primarySecondary = css`
  ${(p) =>
    p.variant === "secondary" &&
    css`
      border-color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.white};
      color: ${(p) => p.theme.palette.primary.main};
    `}
`;

const primaryText = css`
  ${(p) =>
    p.variant === "text" &&
    css`
      border-color: transparent;
      background-color: transparent;
      ${(p) => p.theme.tipography.bodySmallMedium}
      &:hover:enabled {
        background-color: ${(p) => p.theme.palette.grayscale[100]};
        box-shadow: none;
      }

      &:disabled {
        background-color: transparent;
        border-color: transparent;
        color: ${(p) => p.theme.palette.grayscale[300]};
        cursor: not-allowed;
      }
    `}
`;

const transparentWhiteBg = css`
  background-color: rgba(255, 255, 255, 0.15);
`;

export const PrimaryButton = styled.button`
  outline: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.white};
  ${(p) => p.theme.tipography.bodySmallRegular}
  font-family: Roboto;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-template-areas: "content";

  ${(p) =>
    p.icon &&
    css`
      gap: 8px;
    `}

  ${(p) =>
    p.icon && p.iconAlignment === "left"
      ? css`
          grid-template-areas: "icon content";
        `
      : css`
          grid-template-areas: "content icon";
        `}

  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;

      @media screen and ${device.tablet} {
        max-width: 240px;
      }
    `}

  ${(p) =>
    p.size === "sm"
      ? css`
          padding: 4px 12px;
          border-radius: 8px;
        `
      : p.size === "md"
      ? css`
          padding: 8px 16px;
          border-radius: 12px;
        `
      : p.size === "lg"
      ? css`
          padding: 10px 24px;
          border-radius: 16px;
        `
      : null}
  &:hover:enabled {
    box-shadow: ${(p) => p.theme.elevations.hover};
  }

  &:disabled {
    background-color: ${(p) => p.theme.palette.grayscale[100]};
    border-color: ${(p) => p.theme.palette.grayscale[100]};
    color: ${(p) => p.theme.palette.grayscale[300]};
    cursor: not-allowed;
  }

  ${primarySecondary};

  ${primaryLink};

  ${primaryText};

  ${(p) =>
    p.textColor &&
    css`
      color: ${p.textColor};
    `}
`;

export const Content = styled.span`
  grid-area: content;

  // oneline
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  //
`;

export const Icon = styled.div`
  grid-area: icon;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const GhostButton = styled(PrimaryButton)`
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.main};

  &:hover:enabled {
    box-shadow: none;
    ${transparentWhiteBg}
  }

  &:disabled {
    background-color: transparent;
    color: ${(p) => p.theme.palette.grayscale[200]};
    border-color: ${(p) => p.theme.palette.grayscale[200]};
  }

  /*  */

  ${(p) =>
    p.variant === "secondary" &&
    css`
      ${primarySecondary}
      color: ${(p) => p.theme.palette.white};
      border-color: ${(p) => p.theme.palette.white};
      background-color: transparent;
      &:hover:enabled {
        ${transparentWhiteBg}
      }
    `}

  ${(p) =>
    p.variant === "link" &&
    css`
      ${primaryLink}

      &:hover:enabled {
        ${transparentWhiteBg}
      }
    `}

  ${(p) =>
    p.variant === "text" &&
    css`
      ${primaryText}
      color : ${(p) => p.theme.palette.white};

      &:hover:enabled {
        ${transparentWhiteBg}
      }
    `}
`;

export const CircleButton = styled(PrimaryButton)`
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  grid-template-areas: "icon";

  ${(p) =>
    p.size === "sm" &&
    css`
      width: 26px;
      height: 26px;
    `}

  ${(p) =>
    p.size === "md" &&
    css`
      width: 32px;
      height: 32px;
    `}

  ${(p) =>
    p.size === "lg" &&
    css`
      width: 42px;
      height: 42px;
    `}

  ${(p) =>
    p.variant === "secondary" &&
    css`
      border-color: ${(p) => p.theme.palette.grayscale[100]};
      background-color: ${(p) => p.theme.palette.white};
      color: ${(p) => p.theme.palette.grayscale[800]};
    `}
    
  &:disabled {
    background-color: ${(p) => p.theme.palette.white};
    color: ${(p) => p.theme.palette.grayscale[200]};
    border-color: ${(p) => p.theme.palette.grayscale[200]};
  }
`;
