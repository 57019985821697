import React from "react";
import "./index.css";
import { withRouter } from "react-router";
import { useEffect } from "react";
const ModalDetalle = ({
  dispositivo,
  buttonCloseTop,
  info,
  titulo,
  closeModal,
  closeBtn,
  openModalExitoso,
  datosAfiliado,
  loading,
  procesarPedido,
  history,
}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const procesarSolicitud = () => {
    procesarPedido(info);
    closeBtn();
    openModalExitoso();
  };

  const actualizarDatos = () => {
    history.push("/mi-habitat-digital/afiliado/informacion/datos-personales");
  };

  return (
    <section
      className={
        buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`
      }
    >
      <div
        className={
          buttonCloseTop ? `modal-content` : `modal-content modal-content-full`
        }
      >
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={closeModal()}></i>
          </div>
        )}

        <div
          className={
            buttonCloseTop
              ? `mb-modal-title d-flex mt-3`
              : `mb-modal-title d-flex`
          }
        >
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>{titulo}</strong>
          </p>
        </div>
        <section>
          <p>
            {sessionStorage.getItem("nombreUsuario")}, para que tu solicitud sea
            registrada correctamente, necesitamos que confirmes los siguientes
            datos:
          </p>
          <p>
            <span>Correo:</span>{" "}
            <span style={{ color: "#196691", fontWeight: 500 }}>
              {datosAfiliado.r_correo ? datosAfiliado.r_correo : "No tiene"}
            </span>{" "}
            <br />
            <span>Celular:</span>{" "}
            <span style={{ color: "#196691", fontWeight: 500 }}>
              {datosAfiliado.r_celular ? datosAfiliado.r_celular : "No tiene"}
            </span>{" "}
          </p>
          <p>
            Si los datos son correctos da clic en ACEPTAR, de lo contrario debes
            actualizar tus datos ingresando a Actualizar datos.
          </p>

          <section className="section-buttons d-flex justify-content-around">
            <button onClick={actualizarDatos} className="btn-white">
              Actualizar datos{" "}
            </button>
            <button onClick={procesarSolicitud} className="btn-white">
              Aceptar{" "}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </section>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalDetalle);
