import React, { useState, useContext, useEffect } from "react";
import MisdatosContext from "../../context/misdatos/misdatosContext";
import AuthContext from "../../context/autenticacion/authContext";
import { re } from "../../data/DataLocal";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { withRouter } from "react-router";
import Button from "../Button";
import ModalToken from "../../components/ModalToken/index.jsx";
import ModalTokenError from "../../components/ModalTokenError/index.jsx";

const FormularioRatificaDatos = (props) => {
  const misdatosContext = useContext(MisdatosContext);
  const {
    mensajeAlertaDatosPost,
    loadingBtnDatosPost,
    actualizarDatosPost,
    resultadoDatosPost,
  } = misdatosContext;

  const authContext = useContext(AuthContext);
  const { valido_datos, closeModalValidoDatos } = authContext;
  console.log("valido_datos", valido_datos);
  const [r_correo, setCorreo] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const [openModalToken, setOpenModalToken] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);

  // useEffect(() => {
  //   if (resultadoDatosPost) {
  //     if (resultadoDatosPost.r_correo.r_respuesta === "1") {
  //       validoDatosExitoso("1");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [resultadoDatosPost]);

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const handlerEmail = (e) => {
    setCorreo(e.target.value.toUpperCase());
    setErrorInput("");
  };

  const validarData = () => {
    let respuesta = true;
    let mensajeError = "";

    // validamos Correo
    if (r_correo.length === 0 || r_correo.trim() === "") {
      mensajeError = "campo requerido";
    } else {
      if (!re.test(String(r_correo))) {
        mensajeError = "formato de correo incorrecto";
      } else {
        if (
          r_correo.includes("..") ||
          r_correo.includes(".@") ||
          r_correo.includes("--") ||
          r_correo.includes("__") ||
          r_correo.charAt(0) === "."
        ) {
          mensajeError = "formato de correo incorrecto";
        } else {
          if (r_correo.length > 160) {
            mensajeError = "campo máximo de 160 caracteres";
          }
        }
      }
    }
    setErrorInput(mensajeError);

    // Vemos si existe algun error
    if (mensajeError.length > 0) {
      respuesta = false;
    }

    return respuesta;
  };

  const actualizar = () => {
    if (validarData()) {
      setOpenModalToken(true);
    }
  };

  const procesarSolicitud = async (token) => {
    let info = {
      data: {
        r_correo: r_correo,
      },
      v_token_digital: token,
    };
    let response = await actualizarDatosPost(info);
    if (response.status == "200" && response.data.r_respuesta !== -3) {
      setOpenModalToken(false);
      let validoDatos = {
        cambiar: "2",
        loCambio: true,
      };
      sessionStorage.setItem("valido_datos", JSON.stringify(validoDatos));
    }
  };

  useEffect(() => {
    if (resultadoDatosPost) {
      if (resultadoDatosPost.r_respuesta === -3) {
        setMostrarModalToken(resultadoDatosPost);
        setOpenModalToken(false);
      } else {
        setMostrarModalToken(null);
        setOpenModalToken(false);
        valido_datos.loCambio = true;
      }
    }
  }, [resultadoDatosPost]);

  const handlerClose = () => {
    closeModalValidoDatos();
  };
  return (
    <>
      <section className={`wraper-modal`}>
        <div className={`modal-content`}>
          {valido_datos.loCambio && (
            <div className="modal-close">
              <i className="fa fa-times-circle-o" onClick={handlerClose}></i>
            </div>
          )}

          <section className="py-3">
            <div>
              {valido_datos.loCambio ? (
                <>
                  <Card>
                    <CardHeader className="custom-header-cambio-fondo custom-header-li">
                      Actualización de correo Exitoso
                    </CardHeader>
                    <CardBody>
                      <p>
                        Has actualizado exitosamente tu{" "}
                        <strong style={{ color: "#196691" }}>Correo</strong>.
                      </p>
                    </CardBody>
                  </Card>
                  <section className="d-flex justify-content-around">
                    <Button onClick={handlerClose}>Aceptar</Button>
                  </section>
                </>
              ) : (
                <div>
                  <Card>
                    <CardHeader className="custom-header-cambio-fondo custom-header-li">
                      Actualización de Correo.
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <p style={{ textAlign: "justify" }}>
                            Es necesario que registres tu correo para poder
                            notificarte cuando realices operaciones.
                          </p>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label htmlFor="r_correo">Correo</Label>
                            <Input
                              style={{ textTransform: "uppercase" }}
                              placeholder="Ingrese correo"
                              name="r_correo"
                              onChange={handlerEmail}
                              onPaste={handlerDisable}
                              onCopy={handlerDisable}
                              value={r_correo}
                            />
                            {errorInput && (
                              <span
                                style={{ fontSize: "14px", color: "#bf0d3e" }}
                              >
                                {errorInput}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          {mensajeAlertaDatosPost && (
                            <div className="alert alert-danger mt-2">
                              Hubo un problema por favor vuelva a intentarlo
                            </div>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <section className="d-flex justify-content-around">
                    <Button
                      onClick={handlerClose}
                      name="secondary"
                      bgColor="white"
                    >
                      Cancelar
                    </Button>
                    <Button onClick={actualizar}>
                      Actualizar{" "}
                      {loadingBtnDatosPost && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </Button>
                  </section>
                </div>
              )}
            </div>
          </section>
        </div>
      </section>

      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalTokenError
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </>
  );
};

export default withRouter(FormularioRatificaDatos);
