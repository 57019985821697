import styled, { css } from "styled-components";
import Button from "../../../../components/Buttons";
import { device } from "../../../../utils/device";

export const ButtonsWrapperComponent = styled.div`
  display: grid;
  align-items: center;

  ${(p) => css`
    @media screen and ${device.desktopS} {
      grid-template-columns: 1fr 1fr;
      width: calc(480px + 16px);
      column-gap: 16px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

export const NextButtonComponent = styled(Button)`
  ${(p) => css`
    @media screen and ${device.tablet} {
      margin: auto;
    }
  `}
`;

export const PrevButtonComponent = styled(Button)`
  display: none;

  ${(p) => css`
    @media screen and ${device.desktopS} {
      display: grid;
      justify-self: end;
    }
  `}
`;
