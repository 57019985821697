import React from "react";
import { useMediaQuery } from "@mui/material";
import { device } from "../../utils/constants";

import styled from "styled-components";

const Title1 = styled.h1`
  ${(props) =>
    props.matches ? props.theme.tipography.h1 : props.theme.tipography.h3};
`;
const Title2 = styled.h2`
  ${(props) =>
    props.matches ? props.theme.tipography.h2 : props.theme.tipography.h4};
`;
const Title3 = styled.h3`
  ${(props) =>
    props.matches ? props.theme.tipography.h3 : props.theme.tipography.h5};
`;
const Title4 = styled.h4`
  ${(props) =>
    props.matches ? props.theme.tipography.h4 : props.theme.tipography.h6};
`;
const Title5 = styled.h5`
  ${(props) =>
    props.matches ? props.theme.tipography.h5 : props.theme.tipography.h5};
`;
const Body1 = styled.p`
  ${(props) => props.theme.tipography.body1};
  margin-bottom: 0;
`;
const Body2 = styled.p`
  ${(props) => props.theme.tipography.body2};
  margin-bottom: 0;
`;
const Caption = styled.p`
  ${(props) => props.theme.tipography.caption};
  margin-bottom: 0;
`;
const Overline = styled.p`
  ${(props) => props.theme.tipography.overline};
  margin-bottom: 0;
`;
const Index = ({ component, children, color, ...rest }) => {
  const matches = useMediaQuery(device.mobileL);
  const componentOption =
    component === "h1" ? (
      <Title1 matches={matches} {...rest}>
        {children}
      </Title1>
    ) : component === "h2" ? (
      <Title2 matches={matches} {...rest}>
        {children}
      </Title2>
    ) : component === "h3" ? (
      <Title3 matches={matches} {...rest}>
        {children}
      </Title3>
    ) : component === "h4" ? (
      <Title4 matches={matches} {...rest}>
        {children}
      </Title4>
    ) : component === "h5" ? (
      <Title5 matches={matches} {...rest}>
        {children}
      </Title5>
    ) : component === "body1" ? (
      <Body1 matches={matches} {...rest}>
        {children}
      </Body1>
    ) : component === "body2" ? (
      <Body2 matches={matches} {...rest}>
        {children}
      </Body2>
    ) : component === "caption" ? (
      <Caption matches={matches} {...rest}>
        {children}
      </Caption>
    ) : (
      <Overline matches={matches} {...rest}>
        {children}
      </Overline>
    );

  return componentOption;
};

export default Index;
