import React from "react";
import {
  CloseMenu,
  HabitatLogo,
  Item,
  ItemIcon,
  ItemLabel,
  ItemsList,
  LogoutButton,
  MenuItemsWrapper,
  MenuList,
  MenuToggler,
  SearchButton,
  SidebarComponent,
} from "./styles";
import { FiMenu } from "react-icons/fi";
import Logo from "../../../../assets/IconBucket/HabitatLogoRojo";
import CashIcon from "../../../../assets/IconBucket/CashIcon";
import CloseButton from "../../../../components/CloseButton";
import { Modal } from "@mui/material";
import Divider from "../../../../components/Divider";
import Lupa from "../../../../assets/IconBucket/Lupa";
import { FiLogOut } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import { size } from "../../../../utils/device";

const Sidebar = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleHandler = () => {
    setMenuIsOpen(true);
  };

  const closeMenuHandler = () => {
    setMenuIsOpen(false);
  };

  const { width } = useWindowSize();

  const smallScreen = width < size.desktopS;
  const MenuItemsWrapperSelector = smallScreen ? Modal : MenuItemsWrapper;

  return (
    <SidebarComponent>
      {smallScreen && (
        <MenuToggler onClick={toggleHandler}>
          <FiMenu />
        </MenuToggler>
      )}
      <HabitatLogo to="/">
        <Logo />
      </HabitatLogo>

      <MenuItemsWrapperSelector open={menuIsOpen} onClose={closeMenuHandler}>
        <MenuList>
          {smallScreen && (
            <CloseMenu onClick={closeMenuHandler}>
              <CloseButton />
            </CloseMenu>
          )}
          <SearchButton to="/zona-beneficiarios/buscar-tramite">
            <ItemIcon>
              <Lupa />
            </ItemIcon>
            <ItemLabel>Buscar trámite en proceso</ItemLabel>
          </SearchButton>

          <Divider marginTop={"0px"} marginBottom={"0px"} />
          <ItemsList>
            {[
              {
                id: "gasto-sepelio",
                label: "Gastos de Sepelio",
                icon: <CashIcon />,
                path: "/zona-beneficiarios/tramites/gasto-sepelio",
              },
            ].map(({ id, label, icon, path }) => {
              return (
                <Item key={id} to={path}>
                  <ItemIcon>{icon}</ItemIcon>
                  <ItemLabel>{label}</ItemLabel>
                </Item>
              );
            })}
          </ItemsList>
          <LogoutButton to="/">
            <ItemIcon>
              <FiLogOut />
            </ItemIcon>
            <ItemLabel>Volver al Home</ItemLabel>
          </LogoutButton>
        </MenuList>
      </MenuItemsWrapperSelector>
    </SidebarComponent>
  );
};

export default Sidebar;
