export const size = {
  mobileS: 320 - 1,
  mobileM: 375 - 1,
  tablet: 744 - 1,
  desktopS: 1024 - 1,
  desktop: 1440 - 1,
  desktopL: 1920 - 1,
};

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  desktopS: `(min-width: ${size.desktopS}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktopL}px)`,
};
