import styled from "styled-components";

export const PasoResultadoDeSolicitudComponent = styled.div`
  padding: 16px 24px;
  p {
    margin-bottom: 0px;
  }
`;

export const UnsuccessfulResultComponent = styled.div``;

export const UnsuccessTitle = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;

  .icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  p {
    ${(p) => p.theme.tipography.h5}
  }
`;
