import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import IC_INFO_RETIRO_EXTRAORDINARIO from "./../../../../assets/icons/ic_info_retiro_extraordinario.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as moment from "moment";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "moment/locale/es";

const Card = styled("div")`
  background: #ffffff;
  border: 1px solid rgba(20, 20, 20, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 1.5rem;
`;

const TagTramite = styled("div")`
  padding: 3px 8px;
  border-radius: 16px;
  font-size: 12px;
  color: white;
`;

const Button = styled("a")`
  color: #2f6d95;
  cursor: pointer;
  font-size: 14px;
  :hover {
    text-decoration: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&::before": {
      background: "#EFF3FE",
      border: "1px solid #dadde9",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
    },
    color: "#EFF3FE",
  },
}));

export const HtmlTooltip = styled(
  ({
    className,
    classArrow,
    showTooltip,
    color = "#EFF3FE",
    setShowTooltip,
    placement,
    ...props
  }) => (
    <Tooltip
      open={showTooltip}
      placement={window.screen.width < 990 ? "top" : "right"}
      arrow
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      {...props}
      classes={{
        popper: className,
        arrow: classArrow,
      }}
    />
  )
)(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: "rgba(0, 0, 0, 0.87)",
    /* maxWidth: 220, */
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
  },
}));

export const CardNew = (props) => {
  const {
    url_info,
    r_solicitud,
    monto_solicitado,
    fec_aplicacion,
    r_fecha_solicitud,
    r_estado,
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  let history = useHistory();

  moment.locale("es-es");
  const estado =
    r_estado === "RECHAZADO"
      ? "RECHAZADO"
      : r_estado === "TERMINADO"
      ? "FINALIZADO"
      : "EN CURSO";

  const handleOnClick = (nro) => {
    history.push("/mi-habitat-digital/afiliado/seguimiento" + "/" + nro, {
      search: nro,
    });
  };
  let classes = useStyles();

  return (
    <>
      <Card>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              color: "#2B2B2B",
            }}
          >
            {"N.º " + r_solicitud}
          </div>

          {
            <TagTramite
              style={{
                background:
                  estado === "EN CURSO"
                    ? "#0043CE"
                    : estado === "RECHAZADO"
                    ? "#EFBD0C"
                    : "#1B7935",
              }}
            >
              {estado?.charAt(0).toUpperCase() + estado?.slice(1).toLowerCase()}
            </TagTramite>
          }
        </div>

        <div
          style={{
            padding: "0.75rem 0rem",
          }}
        >
          {
            <>
              <div
                style={{
                  fontSize: "12px",
                  color: "#51565F",
                }}
              >
                Fecha de solicitud: {r_fecha_solicitud}
              </div>

              {monto_solicitado && (
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2B2B2B",
                  }}
                >
                  Monto solicitado: S/{monto_solicitado}
                </div>
              )}
            </>
          }
        </div>

        <div>
          <hr
            style={{
              background: "rgba(20, 20, 20, 0.05)",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              borderTop: "1px solid rgba(0,0,0,.0)",
            }}
          />
        </div>

        <div
          style={{
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={() => handleOnClick(r_solicitud)}>
            Ver detalle
          </Button>
        </div>
      </Card>
    </>
  );
};
