
import React from 'react'
import { ContentFlex, FlexItem } from '..';
import IC_INFO from './../../../../assets/icons/ic_info_retiro_extraordinario.svg';



export const TagInfo = ({ hasIcon = true, paddingContent, color = '#EFF3FE', content, iconSize = 1, textSize = 7, flexDirection = 'row' }) => {

    return (
        <ContentFlex color={color} flexDirection={flexDirection} padding={paddingContent}>
            <FlexItem size={textSize}>{content}</FlexItem>
            {hasIcon && <FlexItem size={iconSize}><img src={IC_INFO} /></FlexItem>}
        </ContentFlex>
    )
}
