import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "100%",
  },
  headerTop: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  centerImage: {
    width: 200,
    padding: 10,
  },
  wraperContent: {
    padding: "0 50px",
  },
  wraperContentMarca: {
    padding: "0 30px",
    marginBottom: 40,
  },
  cabecera: {
    backgroundColor: "#ac3740",
    color: "#fff",
    padding: "20",
  },
  cabeceraTitulo: {
    fontWeight: "500px",
    fontSize: 16,
    borderBottom: 1,
    borderColor: "#fff",
    paddingBottom: "3px",
    width: 220,
  },
  cabeceraSubTitulo: {
    fontSize: 10,
    paddingTop: "3px",
  },
  referente: {
    marginTop: "40",
  },
  referenteText: {
    fontSize: 11,
    marginBottom: 20,
    textAlign: "justify",
  },
  referenteTextBottom: {
    fontSize: 8,
    marginBottom: 20,
    textAlign: "justify",
  },
  referenteTextRight: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "right",
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#000",
  },
  itemLeft: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#e5e6e8",
    padding: "3px",
    fontSize: 12,
    fontWeight: "bold",
  },
  rowBottom: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: 1,
    borderTop: 1,
    borderColor: "#000",
  },
  itemLeftBottom: {
    width: "35%",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#6b6c70",
    padding: "3px",
    fontSize: 12,
    fontWeight: "bold",
  },
  itemRight: {
    width: "65%",
    padding: "4px",
    fontSize: 10,
    color: "#393939",
  },
  trianguloLeft: {
    borderRight: 60,
    borderRightColor: "#fff",
    borderTop: 60,
    borderTopColor: "#e5e6e8",
    position: "fixed",
    backgroundColor: "#fff",
  },
  trianguloRight: {
    borderLeft: 60,
    borderLeftColor: "#fff",
    borderBottom: 60,
    borderBottomColor: "#ab363f",
    position: "fixed",
    backgroundColor: "#fff",
  },
  contentTrianguloLeft: {
    width: "49%",
  },
  contentImage: {
    width: "40%",
    alignSelf: "flex-end",
  },
  contentTrianguloRight: {
    // width: "15%",
  },
  marcaTop: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
});
const Registro = ({ datos }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.headerTop}>
        </View> */}
        {/* <View style={styles.wraperContentMarca}>
          <View style={styles.marcaTop}>
            <View style={styles.contentTrianguloLeft}>
              <View style={styles.trianguloLeft}></View>
            </View>
            <View style={styles.contentImage}>
              <View style={styles.centerImage}>
                <Image src={Logo} />
              </View>
            </View>
            <View style={styles.contentTrianguloRight}>
              <View style={styles.trianguloRight}></View>
            </View>
          </View>
        </View> */}

        <View style={styles.wraperContent}>
          <View style={styles.cabecera}>
            <Text style={styles.cabeceraTitulo} bold>
              DOCUMENTO DE REGISTRO
            </Text>
            <Text style={styles.cabeceraSubTitulo}>
              SISTEMA PRIVADO DE PENSIONES (DRSPP)
            </Text>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteText}>
              El presente documento certifica la afiliación a AFP HABITAT de:
            </Text>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Nombres</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_nombre}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Apellido paterno</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_apellido_paterno}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Apellido materno</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_apellido_materno}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Género</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_sexo}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Documento de identidad</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_documento}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>CUSPP</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_cuspp}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Fecha de afiliación</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_fecha_afiliacion}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Fecha de nacimiento</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_fecha_nacimiento}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Dirección</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_direccion}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Teléfono / Celular</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_telefono}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Correo electrónico</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_email}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.itemLeft}>
                <Text>Fecha de inicio de labores</Text>
                <Text>:</Text>
              </View>
              <View style={styles.itemRight}>
                <Text>{datos.r_fecha_inicio_laboral}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteText}>
              Si tienes alguna duda o consulta puedes comunicarte con nosotros a
              través de nuestro Contact Center al 01 230 2200 desde Lima o 0 801
              14 224 desde provincias, escribirnos a través de nuestro Chat
              Virtual ubicado en nuestra página web o puedes acercarte a
              nuestras agencias de atención situadas en Lima y Trujillo,
              ubícalas aquí: https://www.afphabitat.com.pe/canales-de-atencion.
            </Text>
          </View>
        </View>
        <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteTextBottom}>
              Nota: En caso cambie su situación de trabajador independiente a
              dependiente, recuerde que debe informar a su empleador que se
              encuentra afiliado al Sistema Privado de Pensiones, a fin de que
              tus aportes continúen depositándose en tu cuenta AFP.
            </Text>
          </View>
        </View>
        <View style={styles.wraperContent}>
          <View style={styles.rowBottom}>
            <View style={styles.itemLeftBottom}>
              <Text>Fecha de emisión</Text>
              <Text>:</Text>
            </View>
            <View style={styles.itemRight}>
              <Text>{datos.r_fecha_emision}</Text>
            </View>
          </View>
        </View>

        {/* <View style={styles.wraperContent}>
          <View style={styles.referente}>
            <Text style={styles.referenteTextRight}>DRSPP-2013-000</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  );
};

export default Registro;
