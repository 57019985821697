import styled from "styled-components";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "../../components/Buttons";
import { Page, CardContainer } from "../../components";
export const PageComponent = styled(Box)`
  background: #fff;
  border-radius: 16px;
  margin: 0;
  padding: 0;
  @media (min-width: 990px) {
    margin: 0 16px;
  }
`;
export const BoxDate = styled(Box)`
  justify-content: space-between;
  display: flex;
  align-items: start;
  flex-direction: column;
  & h2 {
    font-size: 16px;
  }

  & h6 {
    font-size: 12px;
  }
  @media (min-width: 990px) {
    flex-direction: row;
    align-items: center;

    & h2 {
      font-size: 20px;
    }
    & h6 {
      font-size: 16px;
    }
  }
`;

export const BoxButton = styled(Box)`
  justify-content: space-start;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  & button {
    margin-left: 8px;
    background: #fff;
    color: #ce1336;
  }
`;

export const MoneyInputComponent = styled(TableContainer)`
  max-width: calc(100vw - 32px);
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
`;

export const ButtonSecondaryComponent = styled(Button)`
  width: 100%;
  background: #fff;
  color: #ce1336;
`;

export const CardContainerComponent = styled(CardContainer)`
  padding: 0;
`;

export const BoxTwelve = styled(Box)`
  padding: 16px 0;
  & h2 {
    font-size: 16px;
  }
  @media (min-width: 990px) {
    & h2 {
      font-size: 20px;
    }
  }
`;

export const BoxNotFound = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 24px 16px;
  & p {
    margin-bottom: 0;
    text-align: center;
  }
`;
