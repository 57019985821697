import React from "react";
import { HeaderComponent, SubTitulo, TipoDeFondo, Titulo } from "./styles";
import formatFondo from "../../../../utils/formatFondo";
import InfoButton from "../InfoButton";

const Header = ({ fondoSeleccionado, step, data }) => {
  return (
    <HeaderComponent>
      <Titulo className="title">Invertir en fondo libre</Titulo>
      <SubTitulo className="sub">Coloca los datos de tu inversión</SubTitulo>
      <TipoDeFondo>
        <p className="label">Estás invirtiendo en:</p>
        {data.r_validacion === "2" && step === 0 ? (
          <span className="fondo">{formatFondo(data.r_fondo)}</span>
        ) : (
          <span className="fondo">{formatFondo(fondoSeleccionado)}</span>
        )}
        <InfoButton align="center" side="top" className="info-button">
          Podrás cambiar el tipo de fondo de tu Fondo Libre Habitat desde:
          <b style={{ display: "block" }}>
            Inicio &gt; Mis fondos &gt; Cambio de tipo de Fondo
          </b>
        </InfoButton>
      </TipoDeFondo>
    </HeaderComponent>
  );
};

export default Header;
