import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import imgBaner from "../../../../../../../../assets/inicio_asesoria.png";
import "./index.css";
import { Button } from "../../../../../../../../components";

export default function AsesoriaInicio(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="workflow-init-container">
      <Row className="row-sections">
        <Col className="left-section" xl="7" lg="7" md="7">
          <div className="workflow-init">
            <h2>¡Iniciamos tu asesoría virtual!</h2>
            <p>
              Para iniciar tu trámite de jubilación, comenzaremos con una
              asesoría de 7 audios, los cuales puedes escuchar mediante tu
              celular, laptop o computadora.
            </p>
            <p>
              <strong>Te sugerimos utilizar audífonos.</strong>
            </p>

            <Button onClick={() => props.gotToTopics()}>
              Iniciar mi asesoría
            </Button>
          </div>
        </Col>
        <Col className="right-section" xl="4" lg="4" md="4">
          <img
            className="img-banner"
            src={imgBaner}
            alt="imagen de dos personas de edad usando una laptop"
          />
        </Col>
      </Row>
    </div>
  );
}
