import React from "react";
import { Modal } from "@mui/material";
import Button from "../../../../../../../components/Buttons";
import Alert from "../../../../../../../components/Alert";
import { Header, ModalDeErrorGeneralComponent } from "./styles";
import { BsX } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const ModalDeErrorGeneral = ({
  open = true,
  onNext,
  onClose,
  mensajeAlertaPost,
}) => {
  const history = useHistory();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDeErrorGeneralComponent>
        <Header>
          <div className="close" onClick={onClose}>
            <BsX />
          </div>
          <p className="title">Error en la solicitud</p>
        </Header>
        <Alert
          variant={"error"}
          description={
            <span>
              <p>
                Ha habido un error en su solicitud: {mensajeAlertaPost?.msg}
              </p>
              <br />
              <p>
                Puede volver a intentarlo en 24 horas o comunicarte con nuestros
                canales de atencion.
              </p>
              <br />
              <p>
                <b>Contact center Lima</b>: 01 230 2200
              </p>
              <p>
                <b>Contact center Provincias</b>: 0801 14 224
              </p>
              <p>
                <b>Whatsapp</b>: 981211004
              </p>
            </span>
          }
        />
        <Button
          fullWidth
          style={{ margin: "auto" }}
          onClick={() => history.push("/mi-habitat-digital/afiliado/inicio")}
        >
          Ir a inicio
        </Button>
      </ModalDeErrorGeneralComponent>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
