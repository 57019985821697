
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Container } from 'reactstrap'
import './../../../stylesMobile.css';
const styleDetail = {
    marginBottom: '0px',
    color: '#6E7378',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.2px',
};

const styleTitle = {
    color: '#2B2B2B',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    fontWeight: '400',
    margin: 'auto !important'
};

const boxStyle = {
    marginBottom: '16px',
    '@media screen and (min-width: 375px)': {
        height: '54px',
    },
};



export const CardSimple = ({ title, detail, imagen, colorBorder = '#B7E3FF', colorBackground = '#F2FAFF' }) => {
    return (
        <>
            <Box sx={boxStyle}>
                <Container className='container-mobile222'>
                    <Grid container columns={16} sx={{
                        background: colorBackground,
                        border: `1px solid ${colorBorder}`,
                        borderRadius: '16px',
                        padding: '8px',
                    }}>
                        <Grid item xs={2} sx={{
                            margin: 'auto',
                        }}>
                            <Container className='container_mobile_icon' style={
                                {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }
                            }>
                                <img src={imagen} />
                            </Container>
                        </Grid>
                        <Grid item xs={14} sx={{
                            margin: 'auto',
                        }}>
                            <Typography sx={styleTitle} gutterBottom component="p">{title}</Typography>

                        </Grid>
                        <Grid item xs={2} sx={{
                        }}></Grid>
                        <Grid item xs={14} sx={{
                            margin: 'auto',
                        }}>
                            <Typography sx={styleDetail} gutterBottom component="p">{detail}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
