import React, { useState } from "react";
import Header from "../componentes/Header";
import * as SummarySection from "../../../components/SummarySection";
import Button from "../../../components/Buttons";
import Alert from "../../../components/Alert";
import { BsXCircle } from "react-icons/bs";
import { montoFormat } from "../../../helper/amountFormat";
import maskBankAccount from "../../../utils/maskBankAccount";
import moment from "moment-business-days";
import "moment/locale/es";
import ModalPlazosNormativos from "../ModalPlazosNormativos";

import {
  PasoResultadoDeSolicitudComponent,
  UnsuccessfulResultComponent,
  UnsuccessTitle,
} from "./styles";

const PasoResultadoDeSolicitud = ({ resultado }) => {
  return (
    <PasoResultadoDeSolicitudComponent>
      <Header activeStep={1} />
      {!resultado.message && <SuccessfulResult resultado={resultado} />}
      {resultado.message && <UnsuccessfulResult />}
    </PasoResultadoDeSolicitudComponent>
  );
};

export default PasoResultadoDeSolicitud;

export const UnsuccessfulResult = () => {
  return (
    <UnsuccessfulResultComponent>
      <UnsuccessTitle>
        <div className="icon">
          <BsXCircle />
        </div>
        <p>Ha habido un error con el proceso</p>
      </UnsuccessTitle>
      <Alert
        variant={"error"}
        style={{ marginBottom: "24px", marginTop: "36px" }}
        description="Ha habido un error en tu solicitud. Puedes volver a intentarlo refrescando la página o hacerlo luego."
      />
    </UnsuccessfulResultComponent>
  );
};

export const SuccessfulResult = ({ resultado }) => {
  const {
    result,
    message,
    r_nro_solicitud,
    r_banco,
    r_num_cuenta,
    r_tipo_cuenta,
    r_monto,
    r_tipo_retiro,
    r_flujo,
    r_documento_identidad,
    r_numero_documento,
    r_cuenta_remesa,
    data_solicitud,
  } = resultado;

  // moment
  moment.updateLocale("pe", {
    workingWeekdays: [1, 2, 3, 4, 5],
  });

  moment.locale("es-PE");

  var steps = [
    {
      label: "Solicitud registrada",
      description: `Se ingresó el ${moment().format(
        "L"
      )}. Ahora nos toca evaluarla. Si todo está conforme, haremos el desembolso correspondiente.`,
      status: "done",
    },
    {
      label: "Resultados de solicitud",
      description: `Si la solicitud procede, realizaremos la transferencia aproximadamente el ${moment()
        .businessAdd(5)
        .format("L")}.`,
      status: "waiting",
    },
  ];

  const [modalPlazosNormativosIsOpen, setModalPlazosNormativosIsOpen] =
    useState();

  const closeModalPlazosNormativos = () =>
    setModalPlazosNormativosIsOpen(false);

  const openModalPlazosNormativos = () => setModalPlazosNormativosIsOpen(true);

  return (
    <SummarySection.Section>
      <SummarySection.Header>Retiro registrado con éxito</SummarySection.Header>
      <Alert
        style={{ marginBottom: "24px", marginTop: "36px" }}
        description="AFP Habitat se encarga del cálculo y declaración del impuesto a la renta."
      />
      <SummarySection.Body>
        <SummarySection.Table>
          <SummarySection.TableTitle>
            Datos de la solicitud
          </SummarySection.TableTitle>
          <SummarySection.TableSection>
            <SummarySection.TableSectionTitle>
              Datos del trámite
            </SummarySection.TableSectionTitle>
            {[
              { label: "N.° de solicitud", value: r_nro_solicitud },
              {
                label: "Fecha de retiro",
                value: moment().format("L"),
              },
              { label: "Monto de retiro", value: montoFormat(r_monto, true) },
              { label: "Tipo de retiro", value: r_tipo_retiro },
            ].map(({ label, value }, i) => (
              <SummarySection.TableItem
                key={label}
                label={label}
                value={value}
              />
            ))}
          </SummarySection.TableSection>
          <SummarySection.TableSection>
            <SummarySection.TableSectionTitle>
              Datos del trámite
            </SummarySection.TableSectionTitle>
            {[
              { label: "Banco", value: r_banco },
              { label: "Tipo de cuenta", value: r_tipo_cuenta },
              { label: "N.° de cuenta", value: maskBankAccount(r_num_cuenta) },
            ].map(({ label, value }, i) => (
              <SummarySection.TableItem
                key={label}
                label={label}
                value={value}
              />
            ))}
          </SummarySection.TableSection>
        </SummarySection.Table>
        <SummarySection.Progress>
          <SummarySection.ProgressHeader>
            Avance de solicitud
          </SummarySection.ProgressHeader>
          <SummarySection.ProgressBody
            steps={steps}
          ></SummarySection.ProgressBody>
          <SummarySection.ProgressFooter>
            <Button variant={"link"} onClick={openModalPlazosNormativos}>
              Ver plazos normativos
            </Button>
          </SummarySection.ProgressFooter>
        </SummarySection.Progress>
      </SummarySection.Body>
      <ModalPlazosNormativos
        open={modalPlazosNormativosIsOpen}
        onNext={closeModalPlazosNormativos}
        onClose={closeModalPlazosNormativos}
      />
    </SummarySection.Section>
  );
};
