import React from "react";

function Icon({ color = "#0043CE" }) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8125 15.2227H1.6875V0.597656C1.6875 0.494531 1.60313 0.410156 1.5 0.410156H0.1875C0.084375 0.410156 0 0.494531 0 0.597656V16.7227C0 16.8258 0.084375 16.9102 0.1875 16.9102H17.8125C17.9156 16.9102 18 16.8258 18 16.7227V15.4102C18 15.307 17.9156 15.2227 17.8125 15.2227ZM3.75 13.3477H5.0625C5.16563 13.3477 5.25 13.2633 5.25 13.1602V9.78516C5.25 9.68203 5.16563 9.59766 5.0625 9.59766H3.75C3.64688 9.59766 3.5625 9.68203 3.5625 9.78516V13.1602C3.5625 13.2633 3.64688 13.3477 3.75 13.3477ZM7.3125 13.3477H8.625C8.72813 13.3477 8.8125 13.2633 8.8125 13.1602V5.66016C8.8125 5.55703 8.72813 5.47266 8.625 5.47266H7.3125C7.20938 5.47266 7.125 5.55703 7.125 5.66016V13.1602C7.125 13.2633 7.20938 13.3477 7.3125 13.3477ZM10.875 13.3477H12.1875C12.2906 13.3477 12.375 13.2633 12.375 13.1602V7.48828C12.375 7.38516 12.2906 7.30078 12.1875 7.30078H10.875C10.7719 7.30078 10.6875 7.38516 10.6875 7.48828V13.1602C10.6875 13.2633 10.7719 13.3477 10.875 13.3477ZM14.4375 13.3477H15.75C15.8531 13.3477 15.9375 13.2633 15.9375 13.1602V3.78516C15.9375 3.68203 15.8531 3.59766 15.75 3.59766H14.4375C14.3344 3.59766 14.25 3.68203 14.25 3.78516V13.1602C14.25 13.2633 14.3344 13.3477 14.4375 13.3477Z"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
