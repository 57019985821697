import React, { useReducer } from "react";
import RetiroFondoLibreContext from "./retiroFondoLibreContext";
import RetiroFondoLibreReducer from "./retiroFondoLibreReducer";

import clienteAxios from "../../../config/axios";

import {
  RETIRO_FONDO_LIBRE_CHANGE_PAGE_EXITOSO,
  RFL_BACK_PAGE_EXITOSO,
  RFL_NEXT_PAGE_EXITOSO,
  RFL_SET_DATA_EXITOSO,
} from "./../../../types/fondoLibre/RetiroFondoLibre";

const RetiroFondoLibreState = (props) => {
  const initialState = {
    indexPage: 0,
    data: null,
  };

  const changePage = async (val) => {
    dispatch({
      type: RETIRO_FONDO_LIBRE_CHANGE_PAGE_EXITOSO,
      payload: val,
    });
  };

  const setData = async (val) => {
    dispatch({
      type: RFL_SET_DATA_EXITOSO,
      payload: val,
    });
  };

  const nextPage = async () => {
    dispatch({
      type: RFL_NEXT_PAGE_EXITOSO,
      payload: 1,
    });
  };

  const backPage = async () => {
    dispatch({
      type: RFL_BACK_PAGE_EXITOSO,
      payload: 1,
    });
  };

  const [state, dispatch] = useReducer(RetiroFondoLibreReducer, initialState);
  return (
    <RetiroFondoLibreContext.Provider
      value={{
        indexPage: state?.indexPage,
        data: state?.data,
        changePage,
        nextPage,
        backPage,
        setData,
      }}
    >
      {props.children}
    </RetiroFondoLibreContext.Provider>
  );
};

export default RetiroFondoLibreState;
