import React from "react";
import PropTypes from "prop-types";
import { WrapperComponent } from "./styles";

const Wrapper = ({ children, ...props }) => {
  return <WrapperComponent {...props}>{children}</WrapperComponent>;
};

export default Wrapper;

Wrapper.propTypes = {
  children: PropTypes.node,
};
