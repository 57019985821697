import React, { useEffect, useState } from "react";
import { Page, Document, PDFViewer } from "@react-pdf/renderer";
import { Title, Logo } from "./components";
import PartOne from "./PartOne";
import PartTwo from "./PartTwo";
import PartThree from "./PartThree";
import PartFour from "./PartFour";
import clienteAxios from "../../../config/axios";
import { useParams } from "react-router-dom";

const DecargarInformacionRetiro = () => {
  const { nro } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const request = async () => {
      try {
        const response = await clienteAxios(
          "/fondo-libre/retiro-informacion-pdf",
          {
            method: "POST",
            data: {
              nro_solicitud: nro,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    request();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {data === null && <div>Cargando ...</div>}
      {data !== null && (
        <PDFViewer style={{ minHeight: "100vh", minWidth: "100vw" }}>
          <Document
            title="Información de retiros"
            creator="AFP HABITAT"
            producer="AFP HABITAT"
            author="AFP HABITAT"
          >
            <Page size="A4" style={{ padding: "36px" }}>
              <Logo />
              <Title>Información de Retiro en Fondo Libre Habitat</Title>
              <PartOne {...data} />
              <PartTwo {...data} />
              <PartThree {...data} />
              <PartFour {...data} />
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
};

export default DecargarInformacionRetiro;

export const MyDocument = ({ data }) => {
  return (
    <Document
      title="Información de retiros"
      creator="AFP HABITAT"
      producer="AFP HABITAT"
      author="AFP HABITAT"
    >
      <Page size="A4" style={{ padding: "36px" }}>
        <Logo />
        <Title>Información de Retiro en Fondo Libre Habitat</Title>
        <PartOne {...data} />
        <PartTwo {...data} />
        <PartThree {...data} />
        <PartFour {...data} />
      </Page>
    </Document>
  );
};
