import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../../../../../components";
import MasImg from "../../../../../../../../../../assets/mas.png";
import AporteObligatorioImg from "../../../../../../../../../../assets/fond_obligatorio.png";
import JubilacionContext from "../../../../../../../../../../context/jubilacion/jubilacionContext";
import moment from "moment";
import CustomModal from "../../../../../../../../Components/custromModal/CustomModal";
import ModalDeErrorGeneral from "../../../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function MontoRetirar(props) {
  const {
    prevView,
    endProcess,
    processId,
    amount,
    amountForHabitat,
    amountRetire,
    amountTotal,
    selectMode,
    despositInFondoHabitat,
    fondoHabitat,
    selectModeForApv,
    dependencies,
    autorization,
    apvf,
    disclaimer2,
  } = props;
  const [showModalT, setShowModalT] = useState(false);

  const [declare, setDeclare] = useState(false);
  const [condition, setCondition] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarAvanceJuilacionData,
    guardarAvanceJuilacionLoading,
    guardarAvanceTramiteJubilacion,
    limpiarGuardarAvanceTramiteJubilacion,
  } = jubilacionContext;

  useEffect(() => {
    return () => {
      limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guardarAvanceJuilacionData && sendRequest) {
      if (guardarAvanceJuilacionData.success) {
        endProcess();
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarAvanceJuilacionData]);

  const toggle = () => {
    setShowModalT(false);
  };

  const open = () => {
    setShowModalT(true);
  };

  const changeIconForLoading = () => {
    if (guardarAvanceJuilacionLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  function number_format(amount, decimals) {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

    return amount_parts.join(".");
  }
  const newRetire = despositInFondoHabitat
    ? Number(amountRetire) - Number(amountForHabitat)
    : amountRetire;
  const convertAmount = Number(amountForHabitat).toFixed(2);
  const HandleSaveTramite = () => {
    const data = [
      {
        id: 34,
        response: true,
        type: "boleean",
      },
      {
        id: 35,
        response: {
          modality: "retiro",
          include_voluntary_contribution:
            apvf === null || apvf === undefined ? false : apvf,
          total_withdrawal: !selectMode
            ? Number(amountTotal)
            : Number(amountRetire),
          partial_withdrawal: !selectMode ? amount : 0,
          auth_funds: autorization,
          free_fund: despositInFondoHabitat
            ? {
                amount: Number(convertAmount),
                tipo_aporte: selectModeForApv ? "Total" : "Parcial",
                type: fondoHabitat,
              }
            : null,
          fatca: disclaimer2,
          authorization: autorization,
        },
      },
    ];

    setSendRequest(true);
    guardarAvanceTramiteJubilacion(processId, 5, { data });
  };
  return (
    <div className="withdraw-container">
      <h1>Retiro de hasta 95.5%</h1>
      <p>A continuación, te mostramos el resumen de tu elección</p>

      {despositInFondoHabitat ? (
        <div className="border-blue mb-5">
          <div className="my-withdraw">
            <div className="content">
              <img
                className="icon icon-hand"
                src={AporteObligatorioImg}
                alt="mano_con_dinero"
              />
              <span>
                Retiro total <br /> S/{" "}
                {number_format(Number(amountRetire).toFixed(2), 2)}
              </span>
            </div>
          </div>

          <div className="main-card-background mt-5">
            <span className="title-card">Monto de retiro</span>
            <p className="amount">
              S/ {number_format(Number(newRetire).toFixed(2), 2)}
            </p>
            <ul>
              <li>
                <span>Elección:</span> Retiro{" "}
                {!selectMode ? "Parcial" : "Total"}
              </li>
              <li>
                <span>Fecha de elección:</span> {moment().format("DD/MM/YYYY")}
              </li>
              <li>
                <span>Medio de pago:</span> Cuenta{" "}
                {dependencies.bank_account.bank}
              </li>
              <li>
                <span>Número de cuenta:</span> xxxx xxxx xxxx{" "}
                {dependencies.bank_account.number}
              </li>
            </ul>
          </div>
          <>
            <div className="center-items">
              <img
                className="icon icon-plus"
                src={MasImg}
                alt="mano_con_dinero"
              />
            </div>
            <div className="main-card-background">
              <span className="title-card">Fondo Libre Habitat</span>
              <p className="amount">
                S/ {number_format(Number(amountForHabitat).toFixed(2), 2)}
              </p>
              <span>Fondo de destino {fondoHabitat}</span>
            </div>
          </>
        </div>
      ) : (
        <div className="center-items mt-5">
          <div className="main-card-background mt-5">
            <img src={AporteObligatorioImg} alt="mano_con_dinero" />
            <span className="title-card">Monto de retiro</span>
            <p className="amount">
              S/ {number_format(Number(amountRetire).toFixed(2), 2)}
            </p>
            <ul>
              <li>
                <span>Elección:</span> Retiro{" "}
                {!selectMode ? "Parcial" : "Total"}
              </li>
              <li>
                <span>Fecha de elección:</span> {moment().format("DD/MM/YYYY")}
              </li>
              <li>
                <span>Medio de pago:</span> Cuenta{" "}
                {dependencies.bank_account.bank}
              </li>
              <li>
                <span>Número de cuenta:</span> xxxx xxxx xxxx{" "}
                {dependencies.bank_account.number}
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="check-container">
        <span className="oneChoice">
          <input
            type="radio"
            value={1}
            checked={declare}
            className="radio-btn"
            id="tfa_711"
            name="declare"
            onClick={(e) => setDeclare(!declare)}
          />
          <label className="postRadioField" id="tfa_711" for="tfa_711">
            <span className="input-radio-faux"></span>
          </label>
        </span>
        <label htmlFor="tfa_711">
          Declaro tener conocimiento que los montos informados mediante este
          proceso son estimados a la fecha y pueden variar durante el proceso de
          pago.
        </label>
      </div>

      <div className="check-container mb-3">
        <span className="oneChoice">
          <input
            type="radio"
            value={1}
            checked={condition}
            className="radio-btn"
            id="tfa_721"
            name="terms_condition"
            onClick={(e) => setCondition(!condition)}
          />
          <label className="postRadioField" id="tfa_721" for="tfa_721">
            <span className="input-radio-faux"></span>
          </label>
        </span>
        <label htmlFor="tfa_721">
          {" "}
          He leído y aceptado{" "}
          <a className="terms-conditions" onClick={() => open()}>
            los Términos y Condiciones.
          </a>
        </label>
      </div>

      <div className="container-legal-buttons mt-3">
        <Button
          className="mb-4"
          name="secondary"
          bgColor="white"
          onClick={() => prevView()}
          disabled={guardarAvanceJuilacionLoading}
        >
          volver
        </Button>
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => HandleSaveTramite()}
          disabled={!declare || !condition || guardarAvanceJuilacionLoading}
        >
          continuar {changeIconForLoading()}
        </Button>
      </div>
      <CustomModal
        showModal={showModalT}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          toggle();
        }}
        title="Términos y condiciones"
        description={
          <div className="mt-3 info-retiro-terminos">
            <p>
              Con respecto a los cálculos y estimaciones de retiro y/o pensión,
              teniendo en cuenta mi grupo familiar y para finalizar mi solicitud
              de retiro de hasta el 95.5% declaro lo siguiente:
            </p>
            <h4>A. Consideraciones generales</h4>
            <ul>
              <li>
                He recibido la debida información y orientación. He recibido de
                manera correcta y clara la información y orientación con
                respecto al trámite de Jubilación por Edad Legal.
              </li>
              <li>
                En caso haya solicitado un porcentaje de retiro menor al 95.5%
                puedo solicitar el restante mediante un trámite de segundo
                retiro 95.5% en el momento que desee.
              </li>
              <li>
                Si posterior a mi retiro del 95.5% realizo aportes obligatorios,
                estas no generarán cobertura previsional para los riesgos de
                invalidez, sobrevivencia y/o gastos de sepelio.
              </li>
              <li>
                Tengo conocimiento que los importes indicados son valores
                estimados al día de la emisión del presente documento, pudiendo
                variar hasta el día del pago.
              </li>
              <li>
                Si habiendo confirmado y accedido a las opciones de retiro
                dispuestas por las leyes N.° 30425 y 30478, decida
                posteriormente desafiliarme, tendré que restituir la totalidad
                de los fondos previsionales en la CIC (Fondo obligatorio),
                incluyendo el número de cuotas que fue materia de retiro.
              </li>
              <li>
                Tengo conocimiento de que el trámite de retiro de 95.5
                modificará el total de periodos al amparo de un convenio de
                seguridad social, pudiendo afectar la posible prestación de
                jubilación, invalidez o sobrevivencia en el país donde se
                llegará a aplicar el convenio.
              </li>
              <li>
                Tengo conocimiento que la cobertura del seguro previsional post
                Retiro parcial o total de 95.5% se determinará con los aportes
                anteriores a la fecha del Retiro del 95.5 donde, para los casos
                de sobrevivencia e invalidez, se evaluará la cobertura de
                acuerdo con el cumplimiento de requisitos de aportes: De los
                últimos 8 periodos aportados, 4 aportes deben corresponder a
                periodos pagados con anterioridad al trámite 95.5. Asimismo, los
                periodos posteriores al retiro del 95.5 no serán tomados en
                cuenta ya que son considerados como exceso.
              </li>
              <li>
                Tengo conocimiento que, en caso la AFP tenga alguna orden de
                embargo o retención judicial, el retiro solicitado de mi CIC
                estará afecto al referido mandado judicial.
              </li>
            </ul>

            <h4>B. De la Cuenta Individual de Capitalización - CIC</h4>
            <ul>
              <li>
                La CIC está compuesta por los Aportes Obligatorios, Aportes
                Voluntarios con Fin Previsional y el importe transferido por la
                Oficina de Normalización Previsional (ONP) en caso el estado del
                Bono sea redimido; no incluyendo los Aportes Voluntarios Sin Fin
                Previsional o los fondos trasladados desde Chile al Perú dentro
                del marco del Convenio sobre Seguridad Social entre la República
                de Perú y la República de Chile.
              </li>
              <li>
                En caso decida realizar un retiro total o parcial y cuente con
                Aportes Voluntarios Con Fin Previsional, estos no tendrán
                retención del 4.5%. En caso opte por jubilación, los Aportes
                Voluntarios que formen parte de mi capital para pensión quedan
                afectos al descuento mensual del 4% para EsSalud.
              </li>
            </ul>

            <h4>C. De la retención y atención en EsSalud</h4>
            <ul>
              <li>
                Tengo conocimiento que el 4.5% del saldo de mi CIC de aportes
                obligatorios, será retenido y transferido por la AFP
                directamente a EsSalud, para garantizar mi acceso a las
                prestaciones y beneficios del asegurado, siempre que la opción
                de retiro que elija sea Total o Parcial. En tanto que, en caso
                elija pensión con el 100% de mi CIC, la retención será del 4% de
                mi pensión mensual, de conformidad con lo establecido por la
                ley.
              </li>
              <li>
                Tengo conocimiento que los Aportes Voluntarios Con Fin
                Previsional no están afectos a la retención del 4.5%.
              </li>
              <li>
                En caso de retiro total, mis potenciales beneficiarios de
                pensión - cónyuge o concubino (a), hijos menores de 18 años de
                edad, hijos mayores de 18 años inválidos o padres en condición
                de dependencia económica o en condición de invalidez - a mi
                fallecimiento, no tendrán derecho a las prestaciones y
                beneficios del asegurado regular del régimen contributivo de la
                seguridad social que brinda EsSalud.
              </li>
              <li>
                En caso de Retiro parcial, mis potenciales beneficiarios luego
                de mi fallecimiento podrán optar por una Pensión de
                Sobrevivencia, procediendo la AFP a la retención del 4% y
                posterior transferencia a EsSalud, para garantizar mi acceso a
                las prestaciones y beneficios del asegurado regular del régimen
                contributivo de la seguridad social en salud.
              </li>
              <li>
                El registro de mis derechohabientes (alta, baja y/o
                modificaciones de datos), deberé realizarlo personalmente en las
                Oficinas de Seguros y Prestaciones Económicas de EsSalud, para
                lo cual deberé cumplir con los requisitos exigidos por esta
                entidad.
              </li>
              <li>
                En caso perciba Pensión de Sobrevivencia (viudez u orfandad)
                dentro del régimen de la Ley N.° 26790 y opte por disponer de
                hasta el 95.5%, independientemente de las opciones de retiro y/o
                pensión que elija, no procede la retención y transferencia del
                4.5% a EsSalud. Asimismo, declaro que he proporcionado esta
                información al momento de suscribir la presente solicitud.
              </li>
            </ul>

            <h4>
              D. De las consecuencias de optar por el Retiro total del 95.5%
            </h4>
            <ul>
              <li>No tendré derecho a ningún beneficio de garantía estatal.</li>
              <li>No adquiero la condición de pensionista.</li>
              <li>
                En caso disponga solicitar a la AFP la entrega del 95.5% del
                saldo de mi CIC, tengo conocimiento que, al no tener la
                condición de pensionista del SPP, mis potenciales beneficiaros
                de pensión - cónyuge o concubino (a), hijos menores de 18 años
                de edad, hijos mayores de 18 años inválidos o padres en
                condición de dependencia o inválidos - a mi fallecimiento, no
                tendrán derecho a pensión alguna de sobrevivencia. Así mismo, no
                se considera la retención del 4.5% para EsSalud en el cálculo de
                las estimaciones.
              </li>
            </ul>

            <h4>E. De las consecuencias de optar por el Retiro parcial</h4>
            <ul>
              <li>No tendré derecho a ningún beneficio de garantía estatal.</li>
              <li>
                Adquiero la condición de pensionista siempre que inicie el
                trámite de Jubilación con el saldo o porcentaje de mi fondo que
                haya decidido destinar para este fin.
              </li>
              <li>
                La pensión que corresponda a mis beneficiarios, producto de la
                Pensión de Sobrevivencia estará afecta al 4% por EsSalud.
              </li>
            </ul>

            <h4>F. De las consecuencias de optar por Pensión de Jubilación</h4>
            <ul>
              <li>
                En caso opte por la cotización del total del saldo de mi CIC
                bajo algún producto previsional, se sujeta a los procedimientos
                de solicitud de cotización de pensión que dispone el Capítulo
                III del Subtítulo I del Título VII del Compendio de Normas del
                SPP y el Anexo N.° 1 correspondiente, así como la exigencia de
                la documentación e información de sustento de su grupo familiar,
                para el cálculo y cotización de las pensiones respectivas.
              </li>
            </ul>

            <h4>G. Del ingreso de aportes posteriores a la elección</h4>
            <ul>
              <li>
                Los Aportes Obligatorios que devenguen con posterioridad a la
                fecha de mi elección y que sean pagados en calidad de trabajador
                dependiente o independiente, serán acreditados en mi CIC,
                pudiendo ser retirados en cualquier momento.
              </li>
              <li>
                Los aportes en cobranza, Bono de Reconocimiento u otros recursos
                que ingresen a mi cuenta con posterioridad a mi decisión de
                elección, seguirán el esquema de mi decisión vigente, pudiendo
                cambiarla en cualquier momento.
              </li>
            </ul>

            <h4>H. De los trámites pendientes de atención</h4>
            <ul>
              <li>
                En caso tenga en proceso un trámite de devolución de aporte al
                amparo de lo dispuesto en el Decreto de Urgencia N.° 037-94 o
                Nulidad de Afiliación (causales: falsificación de firma o
                responsabilidad del empleador), deberán culminar antes de
                acogerme a la disponibilidad del fondo.
              </li>
              <li>
                En caso tenga en proceso un trámite de Libre Desafiliación
                Informada, podré desistir hasta antes de la notificación de la
                Resolución expedida por SBS para acogerme a la disponibilidad
                del fondo.
              </li>
              <li>
                En caso tenga en proceso un trámite de Traspaso hacia otra AFP,
                podré desistir hasta el mes anterior a la remisión de
                información de saldos a transferir a la AFP de destino, para
                acogerme a la disponibilidad del fondo.
              </li>
              <li>
                Respecto a los trámites de Transferencia de Fondos al Exterior o
                Devolución de Aportes Obligatorios bajo otro régimen puedo
                esperar a su culminación o desistir de los mismos para acogerme
                a la disponibilidad del fondo.
              </li>
            </ul>

            <h4>I. De la no obligación de realizar aportes</h4>
            <ul>
              <li>
                En caso haya hecho uso del retiro del 95.5% de mis fondos al
                haber cumplido la edad de jubilación, puedo optar por no
                realizar aportes obligatorios al SPP, debiendo informar a mi
                empleador, adjuntando copia simple del formato denominado
                Solicitud de Opción de Retiro y/o Pensión o cualquier otro
                documento que acredite la disposición de mi fondo.
              </li>
            </ul>

            <h4>J. Del pago de los Fondos</h4>
            <ul>
              <li>
                En caso opte por el retiro total o parcial, recibiré los fondos
                de mi CIC al tercer día (03) hábil de suscribir el formato de
                elección para cuentas de Perú o ventanilla y de 6 a 7 días
                útiles en caso haya indicado una cuenta del exterior.{" "}
              </li>
              <li>
                En caso haya solicitado que el pago se realice mediante una
                cuenta del exterior, asumo los costos, comisiones y tipo de
                cambio que puedan generarse al realizar la transferencia con el
                banco receptor indicado.
              </li>
              <li>
                En caso haya optado por abono en cuenta y exista error en el
                número de cuenta o esta no pueda ser procesada por diferencias
                en los datos proporcionados a la AFP y a la entidad bancaria,
                autorizo que el pago sea realizado por ventanilla al día
                siguiente de haberse detectado el error, liberando a la AFP de
                los gastos ocasionados por la demora. (Ventanilla del banco
                Scotiabank peruanos y BCP si es extranjero).
              </li>
              <li>
                En caso haya optado por abono en cuenta y la operación se
                realiza con Código de Cuenta Interbancario (CCI), tengo
                conocimiento que, al existir un proceso previo de canje y
                compensación de medios de pago entre entidades financieras, los
                fondos de mi CIC pueden exceder el plazo normativo, quedando
                liberada la AFP de los gastos y cualquier responsabilidad
                ocasionados por la demora.
              </li>
              <li>
                En caso opte por el Retiro total o parcial, se sujetará al cobro
                de ITF por la institución bancaria donde sea transferido el
                saldo de mi CIC.
              </li>
            </ul>
          </div>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => toggle()}
        textBtnPrimary="Aceptar"
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
