import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { CheckBoxWrapper, ModalComponent } from "./styles";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import * as Input from "../../../components/Input";
import { BsX } from "react-icons/bs";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

const ModalCondicionesDeRetiro = ({ open, onNext, onClose }) => {
  const { watch, trigger, unregister } = useFormContext();

  useEffect(() => {
    return () => unregister("condicionesRetiro");
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalComponent>
        <div className="close" onClick={onClose}>
          <BsX />
        </div>
        <div className="title">Condiciones de retiro</div>
        <div className="body">
          <p>
            El monto de tu retiro será calculado utilizando el valor cuota que
            se encuentre vigente dos días antes del pago de tu solicitud.
          </p>
          <p>
            De acuerdo con la Ley N.° 29492, la rentabilidad que hayas generado
            en Fondo Libre Habitat está sujeta al impuesto a la renta, el cual
            será retenido por la AFP al ejecutar la solicitud de retiro.
          </p>
          <p>
            Según lo establecido en el Artículo 125° de resolución
            N.°080-98-EF/SAFP, existe libre disponibilidad de operaciones para
            retirar los aportes en Fondo Libre Habitat.
          </p>
          <p>
            El cobro de comisión mensual se realiza una vez al mes o al momento
            de hacer un retiro de Fondo Libre Habitat; además, se calcula según
            el tipo de fondo en el que te encuentres.
          </p>
          <p>
            Te invitamos a conocer el cobro{" "}
            <a
              href="https://www.afphabitat.com.pe/aporte-voluntario/#cuanto-cuesta-el-servicio"
              target="_blank"
            >
              aquí
            </a>
            .
          </p>
        </div>
        <Divider />
        <CheckBoxWrapper>
          <Input.Checkbox name="condicionesRetiro" value={true}>
            Acepto las condiciones
          </Input.Checkbox>
          <Input.ErrorMessage name="condicionesRetiro" />
        </CheckBoxWrapper>

        <Button
          fullWidth
          disabled={!watch("condicionesRetiro")}
          style={{ margin: "auto" }}
          onClick={onNext}
        >
          Entendido
        </Button>
      </ModalComponent>
    </Modal>
  );
};

export default ModalCondicionesDeRetiro;

ModalCondicionesDeRetiro.propTypes = {
  open: PropTypes.bool,
};

ModalCondicionesDeRetiro.defaultProps = {
  open: true,
};
