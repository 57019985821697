import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

// import './respuesta.scss';
am4core.useTheme(am4themes_animated);
class GraficoLinea extends Component {
  componentDidMount() {
    this.drawGraphic(this.props.listFilter);
  }

  componentWillUpdate(prevProps, prevState, snapshot) {
    this.drawGraphic(prevProps.listFilter);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  drawGraphic = (data) => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.language.locale = am4lang_es_ES;
    // chart.paddingRight = 50;
    // chart.paddingLeft = 50;

    chart.data = data;
    chart.language.locale["_decimalSeparator"] = ".";

    // chart.numberFormatter.numberFormat = "#.##";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "fecha";

    categoryAxis.renderer.minLabelPosition = 0.1;
    categoryAxis.renderer.maxLabelPosition = 0.95;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.tooltip.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    /**Ocultamos los tooltips en X y Y */
    valueAxis.tooltip.disabled = true;
    valueAxis.numberFormatter.numberFormat = "#.00";

    let series = chart.series.push(new am4charts.LineSeries());

    series.dataFields.valueY = "monto";
    series.dataFields.valueYZ = "monto2";
    series.dataFields.categoryX = "fecha";
    series.dataFields.categoryXX = "fecha2";
    series.tooltipText =
      "S/ {valueYZ}    {categoryXX.formatDate('dd MMM, yyyy')}";
    series.strokeWidth = 2;
    series.minBulletDistance = 15;

    series.tooltip.background.cornerRadius = 3;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#fff");

    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    chart.cursor = new am4charts.XYCursor();

    chart.cursor.behavior = "none";
    chart.cursor.lineX.disabled = true;

    this.chart = chart;
  };

  render() {
    return (
      <div className="container-graphic">
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        <div id="box-logo"></div>
      </div>
    );
  }
}

export default GraficoLinea;
