import React from "react";

const Texto = ({ opcion }) => {
  const bonoReconocimiento = () => {
    return (
      <div style={{ paddingTop: "1em" }}>
        <p style={{ textAlign: "justify" }}>
          Si aportabas a la ONP antes de afiliarte a una AFP, tus aportes
          podrían ser reconocidos si calificas a algún Bono de Reconocimiento.
          Actualmente existen tres tipos: Bono 1992, 1996 y 2001.
        </p>
        <p style={{ textAlign: "justify" }}>
          Puedes acceder a uno de ellos dependiendo de los{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.afphabitat.com.pe/bono-de-reconocimiento/#cuales-son-los-tipos-de-bono-y-requisitos-para-acceder-a-ellos"
          >
            {" "}
            requisitos{" "}
          </a>{" "}
          que cumplas, como el momento de tu afiliación a una AFP y el tiempo de
          aportes a la ONP.
        </p>
      </div>
    );
  };
  const jubilacionAnticipada = () => {
    return (
      <div style={{ paddingTop: "1em" }}>
        <p style={{ textAlign: "justify" }}>
          Es posible jubilarte antes de los 65 años por motivo de desempleo,
          específicamente desde los 50 años si eres mujer o desde los 55 años si
          eres hombre.
        </p>
        <p style={{ textAlign: "justify" }}>
          Puedes acceder al trámite si tienes al menos 12 meses consecutivos de
          desempleo previos a tu solicitud y si cumples con otros{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.afphabitat.com.pe/pensiones/tipos-de-pension-de-jubilacion/jubilacion-anticipada-por-desempleo/"
          >
            requerimientos
          </a>
          .{" "}
        </p>
      </div>
    );
  };
  const pensionInvalidez = () => {
    return (
      <div style={{ paddingTop: "1em" }}>
        <p style={{ textAlign: "justify" }}>
          La pensión de invalidez es una de las prestaciones o beneficios que
          ofrece el Sistema Privado de Pensiones a los trabajadores afiliados,
          que sin haber optado a jubilarse, sufren un siniestro que le genere un
          menoscabo que represente una pérdida de al menos un 50% de su
          capacidad de trabajo.
        </p>
        <p style={{ textAlign: "justify" }}>
          Para conocer más sobre la Pensión sobre Invalidez ingresa al siguiente{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.afphabitat.com.pe/seguro-de-invalidez-sobrevivencia-y-gastos-de-sepelio/#pension-de-invalidez"
          >
            LINK
          </a>
          .{" "}
        </p>
      </div>
    );
  };
  return opcion === 5
    ? bonoReconocimiento()
    : opcion === 6
    ? pensionInvalidez()
    : jubilacionAnticipada();
};

export default Texto;
