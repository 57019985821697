import styled from "styled-components";

export const ModalBody = styled.div`
  p {
    margin-bottom: 16px;
    ${(p) => p.theme.tipography.bodySmallRegular}
  }

  ul {
    padding-left: 20px;
    ${(p) => p.theme.tipography.bodySmallRegular}
    margin-bottom: 16px;

    li {
      list-style: disc;
      list-style-position: inside;
      text-indent: -20px;
      margin-left: 20px;
      margin-bottom: 8px;
    }

    ul {
      text-indent: -20px;
      margin-left: 20px;
    }
  }
`;
