import React from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import { Button } from "../../../components";

const SolicitudProcesadaAgregar = ({ info, recargar, loading }) => {
  return (
    <div className="card">
      <div className="custom-header-cambio-fondo custom-header-li card-header">
        Solicitud de Agregar de cuenta bancaria.
      </div>
      <div className="card-body">
        <div>
          {/* {info.r_respuesta === "1" && (
            <p className="text-center">La cuenta bancaria fue agregada correctamente</p>
          )}

          {info.r_respuesta === "0" && (
            <p className="text-center">
              Hubo un error al procesar su solicitud, por favor vuelvalo a
              intentar mas tarde
            </p>
          )} */}
          <p className="text-center">{info.r_mensaje}</p>
        </div>
        <div>
          <div className="text-center">
            <Button
              className="btn-red btn-red-custom-ec"
              onClick={() => recargar()}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}{" "}
              Volver
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hocWidthWindow(SolicitudProcesadaAgregar);
