import React from "react";
import { Link } from "react-router-dom";
import { CardContainer } from "../../../../components";
import { montoFormat } from "../../../../helper/amountFormat";
import { useEffect, useState } from "react";
import "./index.css";
const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Setiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export default function LastContribution(props) {
  const { movimientos, productMovement } = props;
  const [oblHidden, setOblHidden] = useState(true);

  useEffect(() => {
    // if (productMovement !== "OBL") {
    setOblHidden(false);
    // }
  }, [productMovement]);

  const alerta = () => {
    const date = new Date();
    const añoActual = date.getFullYear();
    const añoAnterior =
      date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
    const mesActual = meses[date.getMonth()];
    const mesAnterior =
      date.getMonth() === 0
        ? meses[meses.length - 1]
        : meses[date.getMonth() - 1];
    return (
      <div className="alert-warning-no-data">
        No cuentas con movimientos en los meses de{" "}
        {`${mesActual.toLowerCase()} ${añoActual}`} -{" "}
        {`${mesAnterior.toLowerCase()} ${añoAnterior}`}. Si quieres revisar los
        últimos 6 periodos puedes ingresar
        <Link to="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual">
          {" "}
          aquí
        </Link>
        .
      </div>
    );
  };

  return oblHidden ? (
    <></>
  ) : (
    <CardContainer
      title={`Últimos movimientos en ${
        productMovement === "OBL"
          ? "Fondo de Jubilación"
          : productMovement === "VSF"
          ? "Fondo Libre Habitat"
          : "Fondo con Fin Previsional"
      }`}
      seeMore={true}
      color={"#ffffff"}
      product={false}
      showHeader={true}
      showContainer={"desktop"}
      link={"/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual"}
    >
      <div className="home-box-body">
        {movimientos.length === 0 ? (
          alerta()
        ) : (
          <div className="last-contributions-table">
            <div className="table-header">
              <div className="table-row">
                <div className="table-columns">
                  <span>Fecha y Hora</span>
                  <span>Descripción</span>
                  <span>
                    <i className="fa fa-info-circle"></i> Valor cuota
                  </span>

                  <span>Monto</span>
                </div>
              </div>
            </div>
            {movimientos.map((item, index) => (
              <div
                key={index}
                className="movements-section_hidde-message-block-desktop"
              >
                <div className="table-row">
                  <div className="table-columns">
                    <span>{item.r_fecha}</span>
                    <span>
                      {item.r_monto_soles < 0 && item.r_descripcion === "Aporte"
                        ? "Retiro"
                        : item.r_descripcion}
                    </span>
                    <span>{item.r_valor_cuota}</span>
                    <span>{"S/ " + montoFormat(item.r_monto_soles)}</span>
                  </div>
                </div>
              </div>
            ))}
            <p className="my-2">
              <i className="fa fa-info-circle"></i>Valor cuota vigente al
              momento de la operación.
            </p>
          </div>
        )}
      </div>
    </CardContainer>
  );
}
