import React, {useContext } from "react";
import AuthContext from "../../context/autenticacion/authContext";
import "./index.css";
import popup1 from "../../assets/pop-up-1.png";
import popup2 from "../../assets/pop-up-2.png";

import { withRouter } from "react-router";
import hocWidthWindow from "../../HOC/HocWidthWindow";

const FormularioRatificaDatos = (props) => {
  const authContext = useContext(AuthContext);
  const { closeModalBanner } = authContext;

  const handlerClose = () => {
    closeModalBanner();
  };

  return (
    <section className={`wraper-modal`}>
      <div className={`modal-content-banner`}>
        <div className="modal-close">
          <i className="fa fa-times-circle-o" onClick={handlerClose}></i>
        </div>

        <section className="py-3">
          {props.windowWidth > 600 ? (
            <img src={popup1} className="mm_logofooter" alt="" />
          ) : (
            <img src={popup2} className="mm_logofooter banner-img" alt="" />
          )}
        </section>
      </div>
    </section>
  );
};

export default hocWidthWindow(withRouter(FormularioRatificaDatos));
