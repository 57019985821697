import React, { useReducer } from "react";
import BoletaContext from "./boletaContext";
import BoletaReducer from "./boletaReducer";
import clienteAxios from "../../config/axios";
import {
  BOLETA_CLEANER,
  BOLETA_CLEANER_PARCIAL,
  BOLETA_EXITOSO,
  BOLETA_ERROR,
  BOLETA_LOADIN,
  PERIODO_EXITOSO,
  PERIODO_ERROR,
  PERIODO_LOADIN,
} from "../../types/boleta";

const BoletaState = (props) => {
  const initialState = {
    periodos: null,
    datosBoleta: null,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión a expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosPeriodos = async (data) => {
    const datos = {
      v_nro_periodo: data
    }
    dispatch({
      type: PERIODO_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post("/mis-datos/boleta-digital/periodo", datos);
      dispatch({
        type: PERIODO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, PERIODO_ERROR);
    }
  };
  const obtenerDatosBoleta = async (data) => {
    const datos = {
      v_nro_periodo: data
    }
    dispatch({
      type: BOLETA_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post("/mis-datos/boleta-digital/boleta", datos);
      dispatch({
        type: BOLETA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, BOLETA_ERROR);
    }
  };
  const limpiarVariablesBoleta = () => {
    dispatch({
      type: BOLETA_CLEANER,
    });
  };
  const limpiarVariablesBoletaParcial = () => {
    dispatch({
      type: BOLETA_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(BoletaReducer, initialState);
  return (
    <BoletaContext.Provider
      value={{
        periodos: state.periodos,
        datosBoleta: state.datosBoleta,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        obtenerDatosPeriodos,
        obtenerDatosBoleta,
        limpiarVariablesBoleta,
        limpiarVariablesBoletaParcial,
      }}
    >
      {props.children}
    </BoletaContext.Provider>
  );
};

export default BoletaState;
