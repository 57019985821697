import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import { Button } from "../../../../../../../../components";
import ICON_PENSION_MENSUAL from "../../../../../../../../assets/IconBucket/ICON_PENSION_MENSUAL.jsx";
import ICON_CASH from "../../../../../../../../assets/IconBucket/ICON_CASH.jsx";
import ICON_FLAG from "../../../../../../../../assets/IconBucket/ICON_FLAG.jsx";

import Card from "../../../../component/Card";
import {
  ContentCheckMode,
  ContentTitleCheck,
} from "../../../../styles/SelectMode";
import Icon from "../../../../component/IconSVG";

import "./index.css";
import { Box, Grid } from "@mui/material";

export default function SelectMode(props) {
  const { endFifthStep, getOutOfProcess, dependencies, setModalidad, setApvf } =
    props;
  const [selectMode, setSelectMode] = useState(-1);
  const [check2, setCheck2] = useState(false);

  const logicToCheck = (e) => {
    setSelectMode(e.target.value);
  };
  const HandleSaveModalidad = () => {
    setModalidad(selectMode);
    setApvf(check2);
    endFifthStep();
  };

  return (
    <div className="select-mode-container HBT_JUBLEGAL_Paso5">
      <h1>Elección de modalidad</h1>

      <div className="content">
        <p className="mb-4">
          Te presentamos tu Fondo de Jubilación y/o Fondo con Fin Previsional.
        </p>
        <div className="center-items">
          <div className="main-card-background">
            <span className="text-card">Fondo de Jubilación</span>
            <p>S/ {dependencies.contribution.obligatory}</p>
          </div>
        </div>{" "}
        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <p className="mb-3">
            Puedes incluir y sumar tus aportes voluntarios con fin previsional
            para el retiro y/o pensión.
          </p>
        )}
        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <div className="center-items">
            <div className="main-card-background mt">
              <span className="text-card">Fondo con Fin Previsional</span>
              <p>S/.{dependencies.contribution.voluntary}</p>
            </div>
          </div>
        )}
        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={check2}
                  className="radio-btn"
                  id="tfa_730"
                  name="juramento1"
                  onClick={() => setCheck2(!check2)}
                />
                <label
                  className="postRadioField paso6"
                  id="tfa_730"
                  for="tfa_730"
                  style={{ display: "flex" }}
                >
                  <span className="input-radio-faux"></span>
                  <p>
                    {" "}
                    Incluir mis aportes voluntarios como parte del fondo de
                    retiro o pensión.
                  </p>
                </label>
              </span>
            </div>
          </div>
        )}
        <Grid container className="HBT_JUBLEGAL_Content">
          <Grid item xs={12} md={6}>
            <div className="HBT_JUBLEGAL_ContentText">
              <div className="advice-card">
                <p>
                  <strong>Recuerda que ... </strong>{" "}
                </p>
                <ul>
                  <li>
                    El saldo disponible para retiro es calculado a la fecha de
                    hoy. Es decir, podría variar durante el resto del proceso.
                  </li>
                  <li>
                    Se aplicará una retención de tu fondo si tienes un proceso
                    judicial que así lo indique.
                  </li>
                  <li>
                    Si una parte del saldo corresponde al convenio de AFP
                    Habitat entre Perú y Chile, no podrá ser retirada, pues
                    deberá utilizarse para tramitar una pensión.
                  </li>{" "}
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="HBT_JUBLEGAL_ContentText">
              <h3 className="mb-4">Selecciona tu modalidad:</h3>
              <Card>
                <ContentCheckMode>
                  <ContentTitleCheck>
                    <Grid container>
                      <Grid item xs={3} md={3}>
                        <Icon
                          componentIcon={<ICON_PENSION_MENSUAL />}
                          width={"48px"}
                          height={"48px"}
                          bgColor="secondary"
                        />
                      </Grid>
                      <Grid item container xs={9} md={9} alignItems={"center"}>
                        <h4>Pensión mensual</h4>
                      </Grid>
                    </Grid>
                  </ContentTitleCheck>
                  <Radio
                    checked={selectMode === "0"}
                    onChange={logicToCheck}
                    value={0}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </ContentCheckMode>
              </Card>
              <Card>
                <ContentCheckMode>
                  <ContentTitleCheck>
                    <Grid container>
                      <Grid item xs={3}>
                        <Icon
                          componentIcon={<ICON_CASH />}
                          width={"48px"}
                          height={"48px"}
                          bgColor="secondary"
                        />
                      </Grid>
                      <Grid item container xs={9} alignItems={"center"}>
                        <h4>Retiro de hasta 95.5%</h4>
                      </Grid>
                    </Grid>
                  </ContentTitleCheck>
                  <Radio
                    checked={selectMode === "1"}
                    onChange={logicToCheck}
                    value={1}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </ContentCheckMode>
              </Card>
              <Card>
                <ContentCheckMode>
                  <ContentTitleCheck>
                    <Grid container>
                      <Grid item xs={3}>
                        <Icon
                          componentIcon={<ICON_FLAG />}
                          width={"48px"}
                          height={"48px"}
                          bgColor="secondary"
                        />
                      </Grid>
                      <Grid item container xs={9} alignItems={"center"}>
                        <h4>Pensión Objetivo</h4>
                      </Grid>
                    </Grid>
                  </ContentTitleCheck>
                  <Radio
                    checked={selectMode === "2"}
                    onChange={logicToCheck}
                    value={2}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </ContentCheckMode>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="container-legal-buttons">
        <Button
          className="mb-4"
          name="secondary"
          bgColor="white"
          onClick={() => getOutOfProcess()}
        >
          Volver
        </Button>
        <Button
          className={`mb-4 btn-modal-primary ${
            selectMode !== null ? "" : "disable-secondary"
          }`}
          onClick={() => HandleSaveModalidad()}
          disabled={selectMode === -1}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}
