import React, { useEffect, useState, useContext, useRef } from "react";
import "./index.css";
import Contador from "./Contador";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { useTheme as useStyledComponentsTheme } from "styled-components";
import CodeContext from "../../context/code/codeContext";
import Loading from "./Loading";
import MensajeAlerta from "./MensajeAlerta";

/* Components MUI */
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

/* Icon MUI */
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      height: "auto",
    },
    fontSize: "10px",
    top: "0",
  },
  paperStylesChages: {
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0px 0px",
      height: "auto",
    },
    fontSize: "10px",
    top: "auto",
  },
  titleStyles: {
    color: palletteColors.Red,
    paddingRight: "20px",
  },
  errorMessage: {
    color: palletteColors.Red,
    fontSize: "12px",
    marginTop: "4px",
    paddingLeft: "8px",
  },
  buttonUpCloseStyles: {
    color: palletteColors.Red,
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    width: "100%",
  },
  textFieldFilledStyles: {
    "& .MuiFilledInput-root": {
      borderRadius: "16px",
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: palletteColors.disabledBg,
    },
  },
});

const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalToken = ({ cerrarModal, guardarCode, showModal }) => {
  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [optionSelect, setOptionSelect] = useState("");
  const [lugarDeEnvio, setLugarDeEnvio] = useState("");
  const [token, setToken] = useState("");
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [mensajeCodigoValidado, setMensajeCodigoValidado] = useState(null);
  const [messageThirdOption, setMessageThirdOption] = useState(false);
  const [reenviarCodigo, setReenviarCodigo] = useState({
    activo: false, // true puedo solicitar clave // false no puedo
    cargando: false, // true solicitando clave // false dejo de solicitar
    solicitar: false, // true hizo una petición de carga // false termino
  });

  const codeContext = useContext(CodeContext);
  const {
    dataModalCode,
    mensajeGetAlerta,
    dataPostCode,
    mensajePostAlerta,
    obtenerDatosCode,
    limpiarVariablesCodePost,
    solicitarCode,
  } = codeContext;

  useEffect(() => {
    if (mensajeGetAlerta) {
      console.log("HUBO UN ERROR AL SOLICITAR TU CODIGO");
    } else {
      obtenerDatosCode();
    }

    return () => {
      limpiarVariablesCodePost();
    };

    // eslint-disable-next-line
  }, [mensajeGetAlerta]);

  useEffect(() => {
    if (dataModalCode) {
      if (dataModalCode.validate === 2) {
        actualizarDataSeleccionada(
          "celular: ",
          "SMS",
          dataModalCode.info.celular
        );
      } else if (dataModalCode.validate === 1) {
        actualizarDataSeleccionada(
          "correo: ",
          "EMAIL",
          dataModalCode.info.email
        );
      }
    }
  }, [dataModalCode]);

  const actualizarDataSeleccionada = (tipo, code, text) => {
    setOptionSelect(tipo + text);
    setLugarDeEnvio(code);
  };

  const elegir = (e) => {
    let valor = e.target.value;
    if (valor === "-1") {
      setMessageThirdOption(true);
      setOptionSelect(valor);
    } else {
      let lugarDeEnvio = e.target.value.includes("correo") ? "EMAIL" : "SMS";
      setOptionSelect(valor);
      setLoadingEnvio(false);
      setLugarDeEnvio(lugarDeEnvio);
      setMessageThirdOption(false);
      limpiarVariablesCodePost();
    }
  };

  const enviarClave = (option) => (e) => {
    e.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.set("channel", lugarDeEnvio);
    if (option === "enviar") {
      setLoadingEnvio(true);
      solicitarCode(bodyFormData)
        .then(() => {
          setLoadingEnvio(false);
          setReenviarCodigo({ ...reenviarCodigo, solicitar: true });
        })
        .catch((error) => console.log(error));
    } else if (option === "re-enviar") {
      setReenviarCodigo({
        activo: false,
        cargando: true,
        solicitar: false,
      });
      solicitarCode(bodyFormData)
        .then(() => {
          setReenviarCodigo({
            ...reenviarCodigo,
            activo: false,
            cargando: false,
            solicitar: true,
          });
        })
        .catch((error) => console.log(error));
    }
  };

  const activarEnvio = (option) => {
    setReenviarCodigo({ ...reenviarCodigo, activo: option, solicitar: false });
  };

  const handleToken = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setToken(valor);
    setMensajeCodigoValidado("");
  };

  const cerrarModalLocal = () => {
    limpiarVariablesCodePost();
    cerrarModal();
  };

  const validarData = () => {
    let mensajeCodigo = "";
    if (token.length !== 0) {
      if (token.length < 6) {
        mensajeCodigo = "El token digital es de 6 dígitos";
      }
    } else {
      mensajeCodigo = "El token digital es requerido.";
    }

    if (mensajeCodigo.length > 0) {
      setMensajeCodigoValidado(mensajeCodigo);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (validarData()) {
      setLoadingEnvio(true);
      guardarCode(token);
    }
  };

  const confirmationCodeRef = useRef(null);

  useEffect(() => {
    if (confirmationCodeRef.current === null) {
      return;
    }

    const onEnter = function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed

        event.preventDefault();
        // handleSubmit();
      }
    };

    confirmationCodeRef.current.removeEventListener("keypress", onEnter);
    confirmationCodeRef.current.addEventListener("keypress", onEnter);
  }, [confirmationCodeRef, dataPostCode]);

  const styledComponentsTheme = useStyledComponentsTheme();

  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={cerrarModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="sm"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            pl: fullScreen ? 2 : 5,
          }}
        >
          <IconButton
            className={classes.buttonUpCloseStyles}
            onClick={cerrarModalLocal}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {mensajeGetAlerta !== null ? (
          <p></p>
        ) : dataModalCode === null ? (
          <Grid
            container
            spacing={2}
            sx={{ width: matches ? "100%" : "400px", border: "none" }}
          >
            <Grid item xs={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{ width: matches ? "100%" : "450px", border: "none" }}
          >
            <Grid item xs={12}>
              <MensajeAlerta opcion={dataModalCode.validate} />
              <Box>
                <ThemeProvider theme={Theme}>
                  <Typography align="left">
                    Deseo recibir mi token digital en:
                  </Typography>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Selecciona lugar de envío
                    </InputLabel>
                    <Select
                      sx={{ borderRadius: "16px", height: "55px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={classes.selectStyles}
                      label="Selecciona lugar de envío"
                      onChange={elegir}
                      value={optionSelect}
                      disableUnderline
                    >
                      {dataModalCode?.info?.email && (
                        <MenuItem
                          sx={styles.menuItemStyles}
                          value={JSON.stringify(
                            "correo: " + dataModalCode.info.email
                          )}
                        >
                          Correo: {dataModalCode.info.email}
                        </MenuItem>
                      )}
                      {dataModalCode?.info?.celular && (
                        <MenuItem
                          sx={styles.menuItemStyles}
                          value={JSON.stringify(
                            "celular: " + dataModalCode.info.celular
                          )}
                        >
                          Celular: {dataModalCode.info.celular}
                        </MenuItem>
                      )}

                      <MenuItem
                        sx={styles.menuItemStyles}
                        value={JSON.stringify(-1)}
                      >
                        No cuento con estos datos
                      </MenuItem>
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Box>

              {lugarDeEnvio && !dataPostCode && optionSelect !== "-1" && (
                <DialogActions>
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item xs={6} sx={{ margin: "auto" }}>
                      <Button
                        variant="contained"
                        className={classes.buttonsDownStyles}
                        onClick={enviarClave("enviar")}
                        disabled={loadingEnvio}
                        autoFocus
                      >
                        Enviar
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              )}
              {messageThirdOption && (
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography align="justify">
                        Por tu seguridad, si ya no cuentas con tu celular y/o
                        correo registrados, deberás comunicarte a nuestra
                        central de actualización de datos llamando al{" "}
                        <strong>01 230 2200.</strong>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {dataPostCode ? (
                <div>
                  <Box component="form">
                    <Grid container>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <ThemeProvider theme={Theme}>
                          <FormControl fullWidth>
                            <TextField
                              label="Código de confirmación"
                              className={classes.textFieldFilledStyles}
                              type="text"
                              name="token"
                              value={token}
                              inputRef={confirmationCodeRef}
                              onenter
                              onChange={handleToken}
                              onKeyPress={(e) =>
                                e.key === "Enter" && e.preventDefault()
                              }
                              variant="filled"
                              inputProps={{
                                maxLength: 6,
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </FormControl>
                          {mensajeCodigoValidado && (
                            <Grid item xs={12}>
                              <div className={classes.errorMessage}>
                                {mensajeCodigoValidado}
                              </div>
                            </Grid>
                          )}
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: "16px" }}>
                        <FormGroup>
                          <Label
                            htmlFor="mensajito"
                            style={{
                              fontSize: "14px",
                              color:
                                styledComponentsTheme.palette.secondary.main,
                              marginBottom: 0,
                            }}
                          >
                            <b>
                              En caso no te llegué el código, podrás volver a
                              solicitarlo
                            </b>{" "}
                            {reenviarCodigo.solicitar ? (
                              <Contador
                                activarEnvio={activarEnvio}
                                iniciaContador={reenviarCodigo.solicitar}
                              />
                            ) : (
                              <strong>ahora</strong>
                            )}
                          </Label>
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <Button
                              variant="outlined"
                              className={classes.buttonsDownStyles}
                              onClick={enviarClave("re-enviar")}
                              disabled={!reenviarCodigo.activo}
                            >
                              <AutorenewIcon />
                              &nbsp; Volver a enviar token
                            </Button>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Button
                              variant="contained"
                              className={classes.buttonsDownStyles}
                              disabled={loadingEnvio}
                              type="button"
                              onClick={handleSubmit}
                            >
                              Confirmar operación{" "}
                              {loadingEnvio && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              ) : (
                mensajePostAlerta && (
                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: "14px",
                      color: "#bf253d",
                    }}
                  >
                    Hubo un error al generar su código, por favor vuelva a
                    intentarlo
                  </p>
                )
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalToken;
