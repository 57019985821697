import React from "react";

export default function VerticalLinesOneIcon({ size = 18, color }) {
  return (
    <svg
      width="3"
      height="30"
      viewBox="0 0 3 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.5"
        y1="1"
        x2="1.5"
        y2="29"
        stroke="#CDCED0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-dasharray="12 12"
      />
    </svg>
  );
}
