import React from "react";
import {
  TableComponent,
  HeaderComponent,
  SectionTitle,
  SectionComponent,
  InformationItem,
  SummarySectionComponent,
  BodyComponent,
  TableTitleComponent,
  ProgressHeaderComponent,
  ProgressFooterComponent,
  ProgressComponent,
  ProgressBodyComponent,
  StepIconComponent,
} from "./styles";
import Divider from "../Divider";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import CircleCheck from "../../assets/IconBucket/CircleCheck";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import PropTypes from "prop-types";

export const Section = ({ children }) => {
  return <SummarySectionComponent>{children}</SummarySectionComponent>;
};

export const Header = ({ children, ...props }) => (
  <HeaderComponent {...props}>
    <div className="icon">
      <CircleCheck />
    </div>
    <p>{children}</p>
  </HeaderComponent>
);

export const Body = ({ children, ...props }) => {
  return <BodyComponent {...props}>{children}</BodyComponent>;
};

export const Table = ({ children, ...props }) => (
  <TableComponent {...props}>{children}</TableComponent>
);

export const TableTitle = ({ children, ...props }) => (
  <TableTitleComponent {...props}>
    <>{children}</>
    <Divider />
  </TableTitleComponent>
);

export const TableSection = ({ children, ...props }) => (
  <SectionComponent {...props}>{children}</SectionComponent>
);

export const TableSectionTitle = ({ children, ...props }) => (
  <SectionTitle {...props}>{children}</SectionTitle>
);

export const TableItem = ({ label, value, ...props }) => (
  <InformationItem {...props}>
    <p className="label">{label}</p>
    <p className="value">{value}</p>
  </InformationItem>
);

export const Progress = ({ children, ...props }) => {
  return <ProgressComponent {...props}>{children}</ProgressComponent>;
};

export const ProgressHeader = ({ children, ...props }) => {
  return (
    <ProgressHeaderComponent {...props}>
      <>{children}</>
      <Divider />
    </ProgressHeaderComponent>
  );
};

export const ProgressBody = ({ steps, ...props }) => {
  const activeStep = 3;

  function StepIcon(props) {
    const { active, completed, className, step } = props;

    return (
      <StepIconComponent
        ownerState={{ active }}
        className={className}
        status={step.status}
      >
        {step.status === "done" && <FiCheck />}
        {step.status === "waiting" && <AiOutlineClockCircle />}
        {step.status === "rejected" && <BsX />}
      </StepIconComponent>
    );
  }

  return (
    <ProgressBodyComponent {...props}>
      <Stepper orientation="vertical" activeStep={activeStep}>
        {steps.map((step, index, array) => {
          const lineStatus = array[index + 1] ? array[index + 1]["status"] : ""; // it gets the color from the next step

          return (
            <Step key={step.label} expanded={true} data-line={lineStatus}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIcon {...props} step={step} />
                )}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <p>{step.description}</p>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </ProgressBodyComponent>
  );
};

ProgressBody.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      status: PropTypes.oneOf(["done", "waiting", "rejected"]),
    })
  ),
};

ProgressBody.defaultProps = {
  steps: [],
};

export const ProgressFooter = ({ children, ...props }) => {
  return (
    <ProgressFooterComponent {...props}>
      <Divider />
      <> {children}</>
    </ProgressFooterComponent>
  );
};
