import React from "react";
// import { Button } from "../../components";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Grid,
  Typography,
  Alert,
  Button,
  useMediaQuery,
  TextField,
  FormControlLabel,
  FormControl,
  useTheme,
  Paper,
  Checkbox,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
const ModalConfirmacion = ({
  Transition,
  mostrarModalToken,
  cerrarModal,
  guardarCode,
  mensaje,
  classes,
  smallScreen,
}) => {
  return (
    // <div className="wraper-modal">
    //   <div className="modal-content">
    //     <h5 className="card-title text-center" style={{ fontWeight: "600" }}>
    //       Alerta
    //     </h5>
    //     <div>
    //       <div>
    //         <section className="custom-confirm-table">
    //           <p>{mensaje}, vuelve a intentarlo.</p>
    //         </section>
    //       </div>
    //       <div className="d-flex justify-content-around mt-3">
    //         <Button onClick={cerrarModal} name="secondary" bgColor="white">
    //           Cerrar
    //         </Button>
    //         <Button onClick={guardarCode}>Volver</Button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <Dialog
      open={mostrarModalToken}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={smallScreen}
      className={smallScreen && classes}
      onClose={cerrarModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={cerrarModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "20px",textAlign:"center" }}>
                Alerta
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography>{mensaje}, vuelve a intentarlo.</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pr: 2,
            pl: 2,
            pb: 2,
            pt: 2,
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              width: "50%",
              "& button": { width: "100%" },
            }}
          >
            <Button onClick={guardarCode} variant="contained" color="primary">
              Aceptar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalConfirmacion;
