import {
  RETIRO_APVSINFIN_GET_EXITOSO,
  RETIRO_APVSINFIN_GET_ERROR,
  RETIRO_APVSINFIN_GET_LOADIN,
  RETIRO_APVSINFIN_CLEANER,
  RETIRO_APVSINFIN_CLEANER_PARCIAL,
  RETIRO_APVSINFIN_POST_EXITOSO,
  RETIRO_APVSINFIN_POST_ERROR,
  RETIRO_APVSINFIN_POST_LOADIN,
  RETIRO_RESETEAR
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case RETIRO_APVSINFIN_GET_EXITOSO:
      // const tieneCuenta = action.payload.r_cuentas? action.payload.r_cuentas: [];
      const tieneMensaje = action.payload.r_message? action.payload.r_message: null;
      return {
        ...state,
        datosRetiroApvSinFin: action.payload,
        mensajeInformativo: tieneMensaje,
        conTokenDigital: action.payload.r_token_digital,
        loadingPage: false,
      };
    case RETIRO_APVSINFIN_GET_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case RETIRO_APVSINFIN_GET_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case RETIRO_APVSINFIN_CLEANER:
      return {
        ...state,
        datosRetiroApvSinFin: null,
        mensajeInformativo: null,
        conTokenDigital: null,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoRetiroApvSinFin: null,
      };
    case RETIRO_APVSINFIN_CLEANER_PARCIAL:
      return {
        ...state,
        datosRetiroApvSinFin: null,
        mensajeInformativo: null,
        conTokenDigital: null,
        // mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoRetiroApvSinFin: null,
      };
    case RETIRO_RESETEAR:
      return {
        ...state,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoRetiroApvSinFin: null,
      };
    case RETIRO_APVSINFIN_POST_EXITOSO:
      return {
        ...state,
        datosResultadoRetiroApvSinFin: action.payload,
        loadingBtn: false,
      };
    case RETIRO_APVSINFIN_POST_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case RETIRO_APVSINFIN_POST_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    default:
      break;
  }
};
