import React from "react";
import { InformativoComponent } from "./styles";
import InformacionAcordion from "./InformacionAcordion";
import Continuar from "./Continuar";
import QueEs from "./Header";

const Informativo = ({ handleStepNavigation }) => {
  return (
    <InformativoComponent>
      <QueEs />
      <InformacionAcordion />
      <Continuar handleStepNavigation={handleStepNavigation} />
    </InformativoComponent>
  );
};

export default Informativo;
