import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import BasicCardDesktop from "./BasicCardDesktop";
import BasicCardDesktopEtario from "./BasicCardDesktopEtareo";

import CardOne from "./CardOne";
import CardTwo from "./CardTwo";
import moment from "moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const TypeFondoCard = ({
  data,
  step,
  setStep,
  fondoOrigen,
  setFondoDestino,
  birthday,
  defaultSelect,
  validateEtareo,
  fondoActual,
  etario,
}) => {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("-1");

  const [open, setOpen] = useState(false);

  /*  useEffect(() => {
    if (step === 0) {
      setOpcionSeleccionada(defaultSelect);
    }
  }, [defaultSelect, step]); */

  const handleClick = (opcion) => (e) => {
    e.preventDefault();
    setOpcionSeleccionada(opcion);
    setFondoDestino(opcion);
  };

  //#F2FAFF

  const descriptionFund = etario
    ? validateEtareo.tipo_etario === 60
      ? "0,2"
      : "1,2"
    : fondoActual.r_descripcion;
  const splitFund = descriptionFund.split(",");
  if (!etario) splitFund.push(String(fondoOrigen));

  const filterFund = data.filter((item) => splitFund.includes(String(item.id)));

  return (
    <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
      {filterFund.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            width: "33.3%",
            backgroundColor:
              opcionSeleccionada === item.id ? "#FFEBEF" : " #F8F9FB  ",
            borderRadius: "16px",
            p: 1,
            mr: data.length === index + 1 ? "0" : "8px",
          }}
        >
          {etario ? (
            <Box sx={{ pb: 2 }} onClick={handleClick(item.id)}>
              <BasicCardDesktopEtario
                id={item.id}
                title={item.title}
                img={item.riesgoImg}
                rentabilidad={item.rentabilidad}
                riesgo={item.riesgo}
                step={step}
                setStep={setStep}
                setFondoDestino={setFondoDestino}
                fondoOrigen={fondoOrigen}
                opcionSeleccionada={opcionSeleccionada}
              />{" "}
            </Box>
          ) : (
            <Box
              sx={{ pb: 2 }}
              onClick={
                fondoOrigen === item.id ? undefined : handleClick(item.id)
              }
            >
              <BasicCardDesktop
                id={item.id}
                title={item.title}
                img={item.riesgoImg}
                rentabilidad={item.rentabilidad}
                riesgo={item.riesgo}
                step={step}
                setStep={setStep}
                setFondoDestino={setFondoDestino}
                fondoOrigen={fondoOrigen}
                opcionSeleccionada={opcionSeleccionada}
              />
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: open && "1px solid #D8D9DB",
                }}
              >
                <Typography>Más sobre este fondo</Typography>
                {open ? (
                  <IconButton onClick={() => setOpen(!open)}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setOpen(!open)}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
            {open && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Este fondo te conviene si
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: "16px",
                      backgroundColor: "white",
                      display: "flex",
                      height: "58px",
                    }}
                  >
                    {/* <Typography sx={{   fontSize: "40px", lineHeight: "20px", }}>
                     .
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "8px",
                      }}
                    >
                      {item.descripcionUno}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: "16px",
                      backgroundColor: "white",
                      display: "flex",
                      height: "58px",
                    }}
                  >
                    {/* <Typography sx={{   fontSize: "40px", lineHeight: "20px", }}>
                     .
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "8px",
                      }}
                    >
                      {item.descripcionDos}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: "16px",
                      backgroundColor: "white",
                      display: "flex",
                      height: "58px",
                    }}
                  >
                    {/* <Typography sx={{   fontSize: "40px", lineHeight: "20px", }}>
                     .
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "8px",
                      }}
                    >
                      {item.descripcionTres}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    En qué se invierte
                  </Typography>
                </Grid>
                {item.id !== 0 && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#ffff",
                        borderRadius: "16px",
                        paddingBottom: "8%",
                        border: "1.5px solid #F6F6F6",
                        borderRadius: "16px"
                      }}
                    >
                      <CardOne
                        extranjero={item.extranjero}
                        nacional={item.nacional}
                        transito={item.transito}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#ffff",
                      borderRadius: "16px",
                    }}
                  >
                    <CardTwo
                      swipeState={item.id}
                      alternativo={item.alternativo}
                      rentaFija={item.rentaFija}
                      variable={item.rentaVariable}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default TypeFondoCard;
