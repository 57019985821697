import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { CardContainer } from "../../components";

const NoDisponible = ({ mensaje }) => {
  return (
    <CardContainer
      title={"Recuerda"}
      seeMore={false}
      color={"#bf0d3e"}
      product={false}
      showHeader={false}
    >
      <div className="content-message-clear">
        <p>{mensaje}</p>
        <Link to="/mi-habitat-digital/afiliado/inicio">
          <button className={`btn-red btn-red-custom-ec`}>
            Volver al inicio
          </button>
        </Link>
      </div>
    </CardContainer>
  );
};

export default NoDisponible;
