import React from "react";
import { AiOutlineFile } from "react-icons/ai";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { CiImageOn } from "react-icons/ci";

export const getFileName = (options) => {
  const { name, truncateAt } = options;

  const dotIndex = name.lastIndexOf(".");
  const truncate = name.slice(0, dotIndex).length > truncateAt;
  const fileName = truncate
    ? name.slice(0, dotIndex).slice(0, truncateAt)
    : name.slice(0, dotIndex);
  const ext = name.slice(dotIndex + 1);

  const fileNameFormatted = `${fileName}${truncate ? "..." : "."}${ext}`;
  return fileNameFormatted;
};

export const dotFileName = (fileList) => {
  if (!fileList) {
    return null;
  }

  const keys = Object.keys(fileList);
  for (let key of keys) {
    const file = fileList[key];
    let { name } = file;

    const dotCount = (name.match(/\./g) || []).length;

    if (dotCount > 1) {
      return '• Asegúrate de que el nombre del archivo no incluya los siguientes caracteres: / : * . ? " <>|';
    } else {
      return true;
    }
  }
};

export const maxSize = (fileList, maxSizeLimit) => {
  if (!fileList) {
    return null;
  }

  const keys = Object.keys(fileList);

  for (let key of keys) {
    let file = fileList[key];
    let { size } = file;

    let fileIsBelowMaximumSize = size / 1000 / 1000 <= maxSizeLimit;

    if (fileIsBelowMaximumSize) {
      return true;
    }
    return "• El archivo pesa más que lo permitido.";
  }
};

export const fileType = (fileList, extensions = []) => {
  if (!fileList) {
    return null;
  }

  const formatExtensions = extensions.map((e) => `\\.${e}`).join("|");
  var allowedExtensions = new RegExp(`(${formatExtensions})$`, "i");

  const keys = Object.keys(fileList);

  for (let key of keys) {
    let file = fileList[key];
    let { path } = file;
    let isValidType = allowedExtensions.test(path);
    if (isValidType) {
      return true;
    }

    return "• El tipo de archivo no está permitido.";
  }
};

export const maxFilesValidation = (fileList, maxFilesAmmount) => {
  if (!fileList) {
    return null;
  }

  if (fileList.length <= maxFilesAmmount) {
    return true;
  }
  return `• La máxima cantidad de archivos es ${maxFilesAmmount}.`;
};

export const getFakeFiles = (end) => {
  return [
    {
      path: "Solicitud de retiro 10232 (1).jpeg",
      lastModified: 1668721676664,
      lastModifiedDate:
        "Thu Nov 17 2022 16:47:56 GMT-0500 (hora estándar de Perú)",
      name: "Solicitud de retiro 10232 (1).jpeg",
      size: 5565151,
      type: "application/pdf",
      webkitRelativePath: "",
    },
    {
      path: "Solicitud de retiro 10232 (1).docx",
      lastModified: 1668721676664,
      lastModifiedDate:
        "Thu Nov 17 2022 16:47:56 GMT-0500 (hora estándar de Perú)",
      name: "Solicitud de retiro 10232 (2).docx",
      size: 2000120,
      type: "application/pdf",
      webkitRelativePath: "",
    },
    {
      path: "Solicitud de retiro 10232 (1).png",
      lastModified: 1668721676664,
      lastModifiedDate:
        "Thu Nov 17 2022 16:47:56 GMT-0500 (hora estándar de Perú)",
      name: "Solicitud de retiro 10232 (3).png",
      size: 9358,
      type: "application/pdf",
      webkitRelativePath: "",
    },
    {
      path: "Solicitud de retiro 10232 (1).pdf",
      lastModified: 1668721676664,
      lastModifiedDate:
        "Thu Nov 17 2022 16:47:56 GMT-0500 (hora estándar de Perú)",
      name: "Solicitud de retiro 10232 (4).pdf",
      size: 9358,
      type: "application/pdf",
      webkitRelativePath: "",
    },
  ].slice(0, end);
};

export const getFileIcon = (props = {}) => {
  const { path } = props;

  if (!path) {
    return <BsFileEarmarkPdf />;
  }

  const dotIndex = path.lastIndexOf(".");
  const extension = path.slice(dotIndex);

  switch (extension) {
    case ".doc":
    case ".docx":
      return <AiOutlineFile />;
    case ".png":
    case ".jpeg":
      return <CiImageOn />;
    case ".pdf":
      return <BsFileEarmarkPdf />;
    default:
      return <AiOutlineFile />;
  }
};
