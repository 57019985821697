import React from "react";
import { Item, Content, Header } from "../../components/Accordion";
import { ContentWrapper } from "./styles";

const Caracteristicas = () => {
  return (
    <Item value="plazos-forma-pago">
      <Header>Plazo y forma de pago</Header>
      <Content>
        <ContentWrapper>
          <p className="description">
            La validación de la documentación para aprobar o rechazar el
            registro de desembolso por Gastos de Sepelio es de hasta 5 días
            útiles como máximo contados desde el día siguiente de haber
            registrado la solicitud.
          </p>
          <p className="description">
            Si los documentos se entregan correctamente, el plazo para realizar
            el pago por reembolso de Gastos de Sepelio será de la siguiente
            manera:
          </p>
          <ul>
            <li>Si el trámite tiene cobertura de Seguro: 20 días útiles.</li>
            <li>Si el trámite no tiene cobertura de Seguro: 7 días útiles.</li>
          </ul>
          <p className="description">
            Puedes adjuntarnos el voucher de la cuenta bancaria donde se
            depositará el reembolso, siempre y cuando esté a nombre del mismo
            solicitante.
          </p>
          <p>
            En caso de no contar con cuenta bancaria, realizaremos el pago
            mediante ventanilla del banco Scotiabank para solicitantes con DNI
            peruano, y en ventanilla del banco BCP para solicitantes con un
            documento de identidad distinto al DNI.
          </p>
        </ContentWrapper>
      </Content>
    </Item>
  );
};

export default Caracteristicas;
