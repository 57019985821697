import styled, { css } from "styled-components";

export const Button = styled.button`
  /* large button secondary */
  display: flex;
  flex-direction: row;
  gap: 8px;
  letter-spacing: 0px;
  align-items: center;

  /* MODELS */
  ${(p) =>
    p.model === "secondary" &&
    css`
      border: 1px solid ${p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      background-color: ${(p) => p.theme.palette.white};
    `}

  /* SIZES */
  ${(p) =>
    p.size === "large" &&
    css`
      font-size: 16px;
      padding: 10px 24px;
      border-radius: 16px;
    `}

  /* STATES */

  &:hover:not(:disabled) {
    box-shadow: 0 2px 8px rgba(27, 29, 39, 0.32);
  }

  &:disabled {
    border: ${(p) => `1px solid ${p.theme.palette.grayscale["300"]}`};
    color: ${(p) => p.theme.palette.grayscale["300"]};
    cursor: not-allowed;
  }

  /* ICONS */
  ${(p) =>
    p.icon &&
    css`
      svg {
        width: 16px;
        height: 16px;
      }
    `}
`;
Button.defaultProps = {
  model: "secondary",
  size: "large",
};

export const DownloadButtonComponent = styled(Button)`
  margin: 32px auto 0px auto;
`;
