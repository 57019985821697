import React from "react";
import coins from "../../../../assets/icons/coins.svg";
import barsUp from "../../../../assets/icons/bars-up.svg";
import redHouse from "../../../../assets/icons/red-house.svg";
import saveMoney from "../../../../assets/icons/save-money.svg";
import hours24 from "../../../../assets/img/24-hours.png";
import { useHistory } from "react-router-dom";
import "./index.css";

const Section3 = () => {
  const history = useHistory();

  return (
    <section className="section-content bg-gray-light section3">
      <div className="container-new-home">
        <h2 className="mb-5 h2-new-home">¿Qué puedes realizar en línea? </h2>

        <div className="online-operations">
          <div className="card-border online-operation-content">
            <div className="circle-border">
              <img src={coins} alt="red-coins" />
            </div>
            <span className="meta-description">
              Operaciones EN TU cuenta y fondos
            </span>

            <p>
              Revisa tu Estado de Cuenta, actualiza tus datos, cambia de tipo de
              fondo, transfiere tu fondo al extranjero.{" "}
            </p>
          </div>

          {/* <div className="card-border online-operation-content">
            <div className="circle-border">
              <img src={barsUp} alt="read-coins" />
            </div>
            <span className="meta-description">
              INVERTIR EN Fondo Libre HABITAT
            </span>

            <p>
              Persigue tus metas ajenas a la jubilación, sin montos mínimos y
              sin límite de operaciones. ¡Haz retiros cuando quieras!
            </p>
          </div> */}

          <div className="card-border online-operation-content">
            <div className="circle-border">
              <img src={redHouse} alt="read-coins" />
            </div>
            <span className="meta-description">Retirar AFP para vivienda</span>

            <p>
              Retira hasta el 25% de tu fondo para una primera vivienda si eres
              propietario o copropietario del 50% o más.
            </p>
          </div>

          <div className="card-border online-operation-content">
            <div className="circle-border">
              <img src={saveMoney} alt="read-coins" />
            </div>
            <span className="meta-description">
              solicitar JUBILACIÓN y beneficios
            </span>

            <p>
              Jubilación Legal, Jubilación Anticipada Ordinaria o por Desempleo
              y Bono de Reconocimiento.
            </p>
          </div>
        </div>

        <div className="footer-section3">
          <div className="hours-available">
            <span>Estamos a tu disposición las </span>
            <img src={hours24} alt="24-hours" />
          </div>

          <div className="options-privatezone">
            <button
              className="btn-primary-new-home mb-2"
              onClick={() => history.push("/mi-habitat-digital/empezar")}
            >
              Ir a Mi Habitat Digital
            </button>
            <p>
              Generar tu clave web{" "}
              <a href="https://www.afphabitat.com.pe/#/genera-clave-web ">
                desde aquí.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
