import React, { useEffect } from "react";
import "./index.css";
const CerrarSesion = () => {

  useEffect(() => {
    console.log('Cargo componente de cerrar session');
  }, [])

  return (
    <section className="content-cerrar-sesion">
      <div className="d-flex flex-column align-items-center">
        <div className="cerrar-sesion"></div>
        <div className="cerrar-sesion-text">Estamos cerrando tu sesión</div>
      </div>

    </section>

  );
};

export default CerrarSesion;
