import React from "react";
import Modal from "../../../../components/BasicModal";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import InfoIcon from "../../../../assets/IconBucket/InfoIcon";
import { IconWrapper, ModalSubTitle, Title } from "./styles";

const ModalDeErrorGeneral = ({ isOpen = true, setIsOpen }) => {
  const onClose = () => setIsOpen(false);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Title>
        <IconWrapper>
          <InfoIcon />
        </IconWrapper>{" "}
        <span>En mantenimiento</span>
      </Title>
      <ModalSubTitle>
        <p>
          Esta opción no está disponible en este momento. Por favor, contáctanos
          para obtener más información.
        </p>
        <br />
        <p>
          <b>Whatsapp:</b>{" "}
          <a href="http://wa.me/51981211004" target={"_blank"}>
            981 211 004
          </a>
        </p>
        <p>
          <b>Contact Center (Lima):</b> <a href="tel:+012302200">01 230 2200</a>
        </p>
        <p>
          <b>Contact Center (Provincias):</b>{" "}
          <a href="tel:+080114224">0801 14 224</a>
        </p>
      </ModalSubTitle>
      <Divider />
      <Button fullWidth style={{ margin: "auto" }} onClick={onClose}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
