import React, { useState, useEffect } from "react";
import clienteAxios from "../../../config/axios";

const useInvesmentData = () => {
  // Investment data by periods request
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const request = async () => {
      try {
        const response = await clienteAxios(
          "/fondo-libre/historial-inversiones",
          { method: "post" }
        );

        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
      }
    };

    request();
  }, []);

  return { data, loading, error };
};

export default useInvesmentData;
