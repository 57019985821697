import React from "react";
import { Button } from "../../../../../../../../../../components";
import "./index.css";

export default function DetalleInfo(props) {
  const { getBackOneStep, nextView } = props;
  return (
    <div className="detalle-info-container">
      <h1 className="mb-3">Retiro de hasta 95.5%</h1>
      <p>Debes tener en cuenta lo siguiente:</p>
      <ul>
        <li>
          Este trámite te permite retirar hasta el 95.5% de tu cuenta individual
          de capitalización o fondo acumulado.
        </li>
        <li>
          El 4.5% restante será transferido a EsSalud para que tus beneficiarios
          y tú tengan derecho a atención médica.
        </li>
        <li>
          La atención en EsSalud solo será vitalicia para ti. Si lamentablemente
          falleces, tus beneficiarios ya no tendrán derecho a atención médica.
        </li>
        <li>
          Recuerda que al retirar el 95.5% de tu fondo NO eres pensionista. Por
          lo tanto, si falleces, tus beneficiarios no tendrán derecho a Pensión
          de Sobrevivencia.
        </li>
        <li>
          Al disponer de este retiro, ya no tendrás derecho a ningún beneficio
          con garantía estatal (por ejemplo: pensión mínima).
        </li>
      </ul>
      <p></p>
      <p>
        Si estás de acuerdo con el retiro de hasta 95.5%, selecciona Continuar.
      </p>
      <br />
      <br />
      <div className="container-legal-buttons">
        <Button
          className="mb-4"
          name="secondary"
          bgColor="white"
          onClick={() => getBackOneStep()}
        >
          Volver
        </Button>
        <Button className="mb-4" onClick={() => nextView()}>
          Continuar
        </Button>
      </div>
    </div>
  );
}
