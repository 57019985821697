import styled from "styled-components";
import Modal from "../../../components/BasicModal";

export const Subtitle = styled(Modal.Subtitle)`
  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;
