import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { useFormContext } from "react-hook-form";
import {
  FileInputComponent,
  GrayButton,
  NoFilesContainerComponent,
  WithFilesContainerComponent,
} from "./styles";
import { useDropzone } from "react-dropzone";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsFileEarmarkPdf, BsXLg } from "react-icons/bs";
import { fileType, getFileName, maxSize, dotFileName } from "../utils";
import getDeepValue from "../../../../utils/getDeepValue";

const LargeInput = forwardRef(
  (
    {
      name,
      extensions,
      validate,
      size,
      maxFileSize,
      required,
      noClick,
      noDrag,
      ...props
    },
    ref
  ) => {
    const {
      register,
      setValue,
      watch,
      formState: { errors },
      trigger,
    } = useFormContext();
    register(name, {
      required: required,
      validate: {
        maxSize: (e) => maxSize(e, maxFileSize),
        fileType: (e) => fileType(e, extensions),
        dotFileName: (e) => dotFileName(e),
        ...validate,
      },
    });

    const error = getDeepValue(errors, name);

    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return null;
      }

      setValue(name, acceptedFiles);
      trigger(name);
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      multiple: false,
      noClick: noClick,
      noDrag: noDrag,
    });

    // to expose the open function to parent component using ref
    useImperativeHandle(ref, () => {
      return {
        open() {
          open();
        },
      };
    });

    return (
      <FileInputComponent {...getRootProps()} error={error} {...props}>
        <input {...getInputProps()} />
        {!watch(name) && <NoFilesContainer error={error} />}
        {watch(name) && (
          <WithFilesContainer
            error={error}
            file={watch(name)}
            open={open}
            noClick={noClick}
          />
        )}
      </FileInputComponent>
    );
  }
);

export default LargeInput;

const NoFilesContainer = ({ error }) => {
  return (
    <NoFilesContainerComponent error={error}>
      <div className="upload-statement">
        <AiOutlinePlusCircle />
        Subir un archivo
      </div>
      <div className="or-divider">
        <span></span> o <span></span>
      </div>
      <div className="drag-statemet">Arrastralo aqui</div>
    </NoFilesContainerComponent>
  );
};

const WithFilesContainer = ({ file, error, open, noClick }) => {
  const { path, name, size, type } = file[0];
  // validar que pasa si elimina el archivo

  const openFileInput = () => open();

  return (
    <WithFilesContainerComponent error={error}>
      <div className="validation-message">
        {!error && (
          <span className="message">Archivo subido correctamente</span>
        )}
        {error && (
          <span className="message error">
            El archvivo es muy pesado o el formato no es el adecuado
          </span>
        )}
      </div>
      <div className="file">
        <div className="file-name">
          <div className="icon">
            <BsFileEarmarkPdf />
          </div>
          <div className="name">{getFileName({ name })}</div>
        </div>
        <div className="close">
          <BsXLg />
        </div>
      </div>
      <div className="divider"></div>
      <GrayButton
        icon={<AiOutlinePlusCircle />}
        variant="link"
        onClick={noClick && openFileInput}
      >
        Vover a subir
      </GrayButton>
    </WithFilesContainerComponent>
  );
};
