import React, { useEffect, useState } from "react";
import { montoFormat } from "../../../../../helper/amountFormat";
import { BiChevronDown } from "react-icons/bi";
import {
  Divider,
  Growth,
  MobileTableComponent,
  MoreInformation,
  Period,
  PeriodTable,
} from "./styles";
import { formatPeriod } from "./utils";

const MobileTable = ({ items, data }) => {
  const [currentPeriod, setCurrentPeriod] = useState(null);

  const handleOpenPeriod = (id) => {
    if (id === currentPeriod) {
      return setCurrentPeriod(null);
    }
    setCurrentPeriod(id);
  };

  return (
    <MobileTableComponent>
      {data.length > 0 &&
        data.map((item, index) => {
          return (
            <div key={index}>
              <Divider />
              <PeriodTableComponent
                {...item}
                id={index}
                currentPeriod={currentPeriod}
                handleOpenPeriod={handleOpenPeriod}
              />
            </div>
          );
        })}
    </MobileTableComponent>
  );
};

export default MobileTable;

export const PeriodTableComponent = ({
  fin_anio,
  fin_mes,
  ini_anio,
  ini_mes,
  rendimiento,
  aportes,
  retiros,
  rentabilidad,
  currentPeriod,
  handleOpenPeriod,
  id,
}) => {
  return (
    <PeriodTable>
      <Period>
        Periodo desde {formatPeriod(ini_anio, ini_mes, fin_anio, fin_mes)}
      </Period>

      <Growth>
        <p className="label">Rendimiento</p>
        <p
          className={`value ${
            rendimiento > 0 ? "positiveAmount" : "negativeAmount"
          }`}
        >
          {rendimiento}%
        </p>
        <div
          className="icon"
          data-isclosed={!(currentPeriod === id)}
          onClick={() => handleOpenPeriod(id)}
        >
          <BiChevronDown />
        </div>
      </Growth>

      {currentPeriod === id && (
        <MoreInformation>
          <div>
            <p className="label">Aportes acumulados</p>
            <p className="value">S/ {montoFormat(aportes)}</p>
          </div>
          <div>
            <p className="label">Retiros y cargos acumulados</p>
            <p className="value">S/ {montoFormat(retiros)}</p>
          </div>
          <div>
            <p className="label">Rentabilidad acumulada</p>
            <p className="value">S/ {montoFormat(rentabilidad)}</p>
          </div>
        </MoreInformation>
      )}
    </PeriodTable>
  );
};
