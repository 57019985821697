import {
  TRANSFERENCIA_EXTERIOR_POST_EXITOSO,
  TRANSFERENCIA_EXTERIOR_POST_ERROR,
  TRANSFERENCIA_EXTERIOR_POST_LOGIN,
  TRANSFERENCIA_EXTERIOR_CLEAR,
  TRANSFERENCIA_EXTERIOR_CLEAR_PARCIAL,
} from "../../types/transferenciaExterior";

export default (state, action) => {
  switch (action.type) {
    case TRANSFERENCIA_EXTERIOR_CLEAR:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoTransferenciaExteriorPost: null,
        mensajeAlertaTransferenciaExteriorPost: null,
        loadingBtnTransferenciaExteriorPost: false,
      };
    case TRANSFERENCIA_EXTERIOR_CLEAR_PARCIAL:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoTransferenciaExteriorPost: null,
        mensajeAlertaTransferenciaExteriorPost: null,
        loadingBtnTransferenciaExteriorPost: false,
      };
    case TRANSFERENCIA_EXTERIOR_POST_EXITOSO:
      return {
        ...state,
        resultadoTransferenciaExteriorPost: action.payload,
        mensajeAlertaTransferenciaExteriorPost: null,
        loadingBtnTransferenciaExteriorPost: false,
      };
    case TRANSFERENCIA_EXTERIOR_POST_LOGIN:
      return {
        ...state,
        loadingBtnTransferenciaExteriorPost: true,
        mensajeAlertaTransferenciaExteriorPost: null,
      };
    case TRANSFERENCIA_EXTERIOR_POST_ERROR:
      return {
        ...state,
        mensajeAlertaTransferenciaExteriorPost: action.payload,
        loadingBtnTransferenciaExteriorPost: false,
      };
    default:
      break;
  }
};
