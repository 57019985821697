import styled, { css } from "styled-components";

export const ModalDeConfirmacionComponent = styled.div`
  display: grid;
  padding: 16px;
  background-color: ${(p) => p.theme.palette.white};
  border-radius: 16px;
  max-width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  overflow: auto;

  ${(p) =>
    p.frecuencia === "0" &&
    css`
      max-width: 465px;
    `}

  p {
    margin-bottom: 0px;
  }
`;

export const Header = styled.div`
  display: grid;
  row-gap: 16px;
  margin-bottom: 24px;

  .close {
    justify-self: end;
    cursor: pointer;
  }

  .title {
    justify-self: center;
    ${(p) => p.theme.tipography.h5}
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.palette.grayscale[100]};
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  margin-bottom: 24px;

  ${(p) =>
    p.frecuencia === "0" &&
    css`
      grid-template-columns: 1fr;
    `}

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
`;

export const Information = styled.div`
  p {
    ${(p) => p.theme.tipography.bodySmallRegular}
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  ${(p) =>
    p.frecuencia === "0" &&
    css`
      margin-bottom: 24px;
    `}
`;

export const Table = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  color: ${(p) => p.theme.palette.grayscale[700]};

  .title {
    ${(p) => p.theme.tipography.headingSubtitleSemi}
    color: ${(p) => p.theme.palette.grayscale[800]};
    margin-bottom: 8px;
  }

  .tableRow {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr;
    padding: 4px 8px;
    border-radius: 16px;

    &:nth-child(even) {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
    }

    .label {
      align-self: center;
    }
    .value {
      text-align: right;
      align-self: center;
    }
  }
`;

export const Alert = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.state.infoLight};
  ${(p) => p.theme.tipography.bodySmallRegular}
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-shadow: ${(p) => p.theme.elevations.below};
  margin-bottom: 24px;
  svg {
    width: 24px;
    height: 24px;
    color: ${(p) => p.theme.palette.state.info};
  }
`;

export const Buttons = styled.div`
  margin-top: 16px;
  width: clamp(200px, 100%, 500px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
`;

export const Boton = styled.button`
  padding: 10px 24px;
  outline: none;
  cursor: pointer;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  ${(p) => p.theme.tipography.bodyMediumRegular}

  ${(p) =>
    p.fluid &&
    css`
      width: 100%;
    `}
  
  &:hover {
    box-shadow: ${(p) => p.theme.elevations.below};
  }
`;

export const PrevButton = styled(Boton)`
  color: ${(p) => p.theme.palette.primary.main};
  border-color: ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.white};
`;

export const NextButton = styled(Boton)`
  border-color: ${(p) => p.theme.palette.grayscale[100]};
  color: ${(p) => p.theme.palette.white};
  background-color: ${(p) => p.theme.palette.primary.main};

  &:disabled {
    color: ${(p) => p.theme.palette.grayscale["300"]};
    background-color: ${(p) => p.theme.palette.grayscale["100"]};
  }
`;
