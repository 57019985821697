import styled, { css } from "styled-components";

export const DatosDeLaSolicitudComponent = styled.div`
  background-color: ${(p) => p.theme.palette.white};
  max-width: 651px;
  margin: auto;
  display: grid;
`;

export const Header = styled.div`
  .title {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    column-gap: 16px;

    p {
      ${(p) => p.theme.tipography.h6}
    }

    .status {
      display: block;
      padding: 4px 16px;
      border-radius: 100px;
      ${(p) => p.theme.tipography.caption};
      border: 1px solid ${(p) => p.theme.palette.state.success};
      color: ${(p) => p.theme.palette.state.success};
      margin-bottom: 8px;
      align-self: start;
    }
  }
  .subtitle {
    ${(p) => p.theme.tipography.bodySmallRegular}
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const SectionTitle = styled.div`
  ${(p) => p.theme.tipography.headingSubtitleSemi}
  margin-bottom: 4px;
`;

export const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const InformationItem = styled.div`
  padding: 8px 16px;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  border-radius: 16px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .label {
    ${(p) => p.theme.tipography.caption};
    color: ${(p) => p.theme.palette.grayscale[500]};
  }
  .value {
    color: ${(p) => p.theme.palette.grayscale[800]};
  }
`;

export const BotonDesactivar = styled.button`
  padding: 10px 24px;
  outline: none;
  cursor: pointer;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  ${(p) => p.theme.tipography.bodyMediumRegular}
  color: ${(p) => p.theme.palette.primary.main};
  border-color: ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.white};
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  width: min(240px, 100%);

  ${(p) =>
    p.fluid &&
    css`
      width: 100%;
    `}

  &:hover {
    box-shadow: ${(p) => p.theme.elevations.below};
  }
`;
