import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";
import { inputStyles, textInputWrapper, inputIcon } from "../styles";

export const InputWrapper = styled.div`
  ${textInputWrapper}
`;

export const NumberInputComponent = styled(MaskedInput)`
  ${inputStyles}

  ${(p) =>
    p.$icon &&
    css`
      padding-right: 32px;
    `}
`;

export const Icon = styled.div`
  ${inputIcon}
`;
