
import { IconButton} from '@mui/material';
import React, { useState } from 'react'
import styled from 'styled-components';
import { HtmlTooltip } from '../../../PagosDesembolsos/ListadoTramites/componentes/CardNew';
import IC_INFO from './../../../../assets/icons/ic_info_retiro_extraordinario.svg';

const ContentTitle = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.fontSize ? props.fontSize : '16px'};
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #363A3E;
  display: flex;
  align-items: center;
  padding: 8px 0px;
`;

export const Title = ({ titulo = 'Sin titulo', info, fontSize = '16px' }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <ContentTitle fontSize={fontSize}>
      <div>{titulo}</div>
      <div style={{ paddingLeft: '16px' }}>
        {info &&
          <HtmlTooltip showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            color={'#2F6D95'}
            title={
              <div style={
                {
                  padding: '0.4rem',
                }
              }>
                <div style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.2px',
                  color: '#ffffff',
                }}>{info}</div>
              </div>
            }>
            <IconButton onClick={() => setShowTooltip(!showTooltip)}>
              <img src={IC_INFO} />
            </IconButton>
          </HtmlTooltip>

        }
      </div>
    </ContentTitle>
  )
}
