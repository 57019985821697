import * as actions from "./actions";

const currentSectionState = "loading";
const generalErrorModalState = false;
const confirmationModalState = false;
const impossibleDeactivationModalState = false;
const deactivationErrorModalModalState = false;
const tokenModalState = false;

const monthlyDebitDetailsState = {
  data: null,
  state: "idle", // idle, loading, success, fail
  error: null,
};

const monthlyDebitDeactivatedState = {
  data: null,
  state: "idle", // idle, loading, success, fail
  error: null,
};

export const initialState = {
  monthlyDebitDetails: {
    ...monthlyDebitDetailsState,
  },
  monthlyDebitDeactivated: {
    ...monthlyDebitDeactivatedState,
  },
  currentSection: currentSectionState,
  generalErrorModal: generalErrorModalState,
  confirmationModal: confirmationModalState,
  tokenModal: tokenModalState,
  impossibleDeactivationModal: impossibleDeactivationModalState,
  deactivationErrorModalModal: deactivationErrorModalModalState,
};

const reducer = (state, { type, payload }) => {
  // Monthly debit details
  switch (type) {
    case actions.GET_MONTHLY_DEBIT_DETAILS:
      return {
        ...state,
        monthlyDebitDetails: {
          ...monthlyDebitDetailsState,
          state: "loading",
        },
      };
    case actions.MONTHLY_DEBIT_DETAILS_SUCCESS:
      return {
        ...state,
        monthlyDebitDetails: {
          ...monthlyDebitDetailsState,
          data: payload,
          state: "success",
        },
      };
    case actions.MONTHLY_DEBIT_DETAILS_FAIL:
      return {
        ...state,
        monthlyDebitDetails: {
          ...monthlyDebitDetailsState,
          state: "fail",
          error: payload,
        },
      };

    // Deactive monthly debit
    case actions.DEACTIVATE_MONTHLY_DEBIT:
      return {
        ...state,
        monthlyDebitDeactivated: {
          ...monthlyDebitDeactivatedState,
          state: "loading",
        },
      };
    case actions.DEACTIVATE_MONTHLY_DEBIT_SUCCESS:
      return {
        ...state,
        monthlyDebitDeactivated: {
          ...monthlyDebitDeactivatedState,
          state: "success",
          data: payload,
        },
      };
    case actions.DEACTIVATE_MONTHLY_DEBIT_FAIL:
      return {
        ...state,
        monthlyDebitDeactivated: {
          ...monthlyDebitDeactivatedState,
          state: "fail",
          error: payload,
        },
      };

    // Confirmation modal
    case actions.CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: payload,
      };

    // Token modal
    case actions.TOKEN_MODAL:
      return {
        ...state,
        tokenModal: payload,
      };

    // General error modal
    case actions.GENERAL_ERROR_MODAL:
      return {
        ...state,
        generalErrorModal: payload,
      };

    // Impossible deactivation modal
    case actions.IMPOSSIBLE_DEACTIVATION_MODAL:
      return {
        ...state,
        impossibleDeactivationModal: payload,
      };

    // Impossible deactivation modal
    case actions.DEACTIVATION_ERROR_MODAL:
      return {
        ...state,
        deactivationErrorModal: payload,
      };

    // Current section
    case actions.CURRENT_SECTION:
      return {
        ...state,
        currentSection: payload,
      };

    default:
      return state;
  }
};

export default reducer;
