import React, { useState, useEffect, useContext } from "react";
import { DetalleMonto, SelecionarMonto, MontoRetirar } from "./components";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";

export default function Retiro(props) {
  const jubilacionContext = useContext(JubilacionContext);
  const { guardarAPVf } = jubilacionContext;
  const [showView, setShowView] = useState(1);
  const [selectModeDecision, setSelectModeDecision] = useState(false);
  const [selectMode, setSelectMode] = useState(true);
  const [selectModeForApv, setselectModeForApv] = useState(null);
  const [myMoney, setMyMoney] = useState(10000);
  const [amount, setAmount] = useState("");
  const [amountForHabitat, setAmountForHabitat] = useState("");
  const [disclaimer, setDsiclaimer] = useState(false);
  const [disclaimer2, setDsiclaimer2] = useState(false);
  const [autorization, setAutorization] = useState(false);
  const [despositInFondoHabitat, setDespositInFondoHabitat] = useState(false);
  const [fondoHabitat, setFondoHabitat] = useState(-1);
  const [amountRetire, setAmountRetire] = useState("");
  const [amountTotal, setAmounttotal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (guardarAPVf) {
      setSelectModeDecision(guardarAPVf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <DetalleMonto
            getBackOneStep={props.getBackOneStep}
            nextView={() => setShowView(2)}
          />
        );
      case 2:
        return (
          <SelecionarMonto
            prevView={() => setShowView(1)}
            nextView={() => setShowView(3)}
            dependencies={props.dependencies}
            selectModeDecision={selectModeDecision}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            selectModeForApv={selectModeForApv}
            setselectModeForApv={setselectModeForApv}
            myMoney={myMoney}
            setMyMoney={setMyMoney}
            amount={amount}
            setAmount={setAmount}
            disclaimer={disclaimer}
            setDsiclaimer={setDsiclaimer}
            disclaimer2={disclaimer2}
            setDsiclaimer2={setDsiclaimer2}
            autorization={autorization}
            setAutorization={setAutorization}
            despositInFondoHabitat={despositInFondoHabitat}
            setDespositInFondoHabitat={setDespositInFondoHabitat}
            amountForHabitat={amountForHabitat}
            setAmountForHabitat={setAmountForHabitat}
            fondoHabitat={fondoHabitat}
            setFondoHabitat={setFondoHabitat}
            setAmountRetire={setAmountRetire}
            setAmounttotal={setAmounttotal}
          />
        );
      case 3:
        return (
          <MontoRetirar
            prevView={() => setShowView(2)}
            endProcess={props.endProcess}
            dependencies={props.dependencies}
            processId={props.processId}
            selectMode={selectMode}
            amountRetire={amountRetire}
            myMoney={myMoney}
            amount={amount}
            amountForHabitat={amountForHabitat}
            selectModeForApv={selectModeForApv}
            fondoHabitat={fondoHabitat}
            despositInFondoHabitat={despositInFondoHabitat}
            autorization={autorization}
            amountTotal={amountTotal}
            disclaimer2={disclaimer2}
          />
        );
      default:
        return <p></p>;
    }
  };

  return <div>{renderView()}</div>;
}
