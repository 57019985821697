import React, { useState } from "react";
import Header from "../../components/BeneficiaryZoneHeader";
import {
  Body,
  Footer,
  Main,
  ProcedureWrapper,
  Title,
  ZonaDeBeneficiariosComponent,
} from "./styles";
import CashIcon from "../../assets/IconBucket/CashIcon";
import ListItem from "../../components/ListItem";
import Divider from "../../components/Divider";
import { TbPhoneCall } from "react-icons/tb";
import ModalDeContacto from "./ModalDeContacto";
import { useHistory } from "react-router-dom";

const ZonaDeBeneficiarios = () => {
  const [modalDeContactoIsOpen, setModalDeContactoIsOpen] = useState(false);
  const history = useHistory();

  return (
    <ZonaDeBeneficiariosComponent>
      <Header />
      <Body>
        <Main>
          <Title>¿Qué trámite quieres realizar?</Title>
          <ProcedureWrapper>
            {[
              {
                title: "Gastos de Sepelio",
                description: "Reembolso por gastos funerarios.",
                icon: <CashIcon />,
                path: "/zona-beneficiarios/tramites/gasto-sepelio",
              },
              {
                title: "Próximamente",
                description:
                  "Muy pronto podrás realizar más trámites desde nuestra web...",
                disabled: true,
              },
              {
                title: "Próximamente",
                description:
                  "Muy pronto podrás realizar más trámites desde nuestra web...",
                disabled: true,
              },
              {
                title: "Próximamente",
                description:
                  "Muy pronto podrás realizar más trámites desde nuestra web...",
                disabled: true,
              },
            ].map(({ path, ...props }, index) => (
              <ListItem
                key={index}
                onClick={path ? () => history.push(path) : null}
                {...props}
              />
            ))}
          </ProcedureWrapper>
          <Footer>
            <Divider />
            <ListItem
              title="¿Dudas o consultas? Contáctanos"
              icon={<TbPhoneCall />}
              size="sm"
              onClick={() => setModalDeContactoIsOpen(true)}
            />
          </Footer>
          <ModalDeContacto
            open={modalDeContactoIsOpen}
            setOpen={setModalDeContactoIsOpen}
          />
        </Main>
      </Body>
    </ZonaDeBeneficiariosComponent>
  );
};

export default ZonaDeBeneficiarios;

ZonaDeBeneficiarios.Header = {
  onPrevious: () => console.log("onPrevious"),
  onClose: () => console.log("onPrevious"),
  breadcumbs: null,
};
