export const JUBPMO_ACCESS_EXITOSO = "JUBPMO_ACCESS_EXITOSO";
export const JUBPMO_ACCESS_ERROR = "JUBPMO_ACCESS_ERROR";
export const JUBPMO_ACCESS_LOADING = "JUBPMO_ACCESS_LOADING";
export const JUBPMO_ASESORIA_ERROR = "JUBPMO_ASESORIA_ERROR";
export const JUBPMO_ASESORIA_EXITOSO = "JUBPMO_ASESORIA_EXITOSO";
export const JUBPMO_ASESORIA_LOADING = "JUBPMO_ASESORIA_LOADING";

export const JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING =
  "JUBPMO_ACOGIMIENTO_ELIMINAR_LOADING";
export const JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO =
  "JUBPMO_ACOGIMIENTO_ELIMINAR_EXITOSO";
export const JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR =
  "JUBPMO_ACOGIMIENTO_ELIMINAR_ERROR";

export const JUBPMO_CALCULO_LOADING = "JUBPMO_CALCULO_LOADING";
export const JUBPMO_CALCULO_EXITOSO = "JUBPMO_CALCULO_EXITOSO";
export const JUBPMO_CALCULO_ERROR = "JUBPMO_CALCULO_ERROR";

export const JUBPMO_ACOGIMIENTO_LOADING = "JUBPMO_ACOGIMIENTO_LOADING";
export const JUBPMO_ACOGIMIENTO_EXITOSO = "JUBPMO_ACOGIMIENTO_EXITOSO";
export const JUBPMO_ACOGIMIENTO_ERROR = "JUBPMO_ACOGIMIENTO_ERROR";

export const JUBPMO_RESETEAR_ESTADOS = "JUBPMO_RESETEAR_ESTADOS";
export const JUBPMO_RESETEAR_PREV_CALCULO = "JUBPMO_RESETEAR_PREV_CALCULO";

export const JUBPMO_DECISION_VALIDAR_PMO_EXITOSO =
  "JUBPMO_DECISION_VALIDAR_PMO_EXITOSO";
export const JUBPMO_DECISION_VALIDAR_PMO_LOADING =
  "JUBPMO_DECISION_VALIDAR_PMO_LOADING";
export const JUBPMO_DECISION_VALIDAR_PMO_ERROR =
  "JUBPMO_DECISION_VALIDAR_PMO_ERROR";

export const JUBPMO_DECISION_VALIDAR_PMO_CLEAR =
  "JUBPMO_DECISION_VALIDAR_PMO_CLEAR";

export const JUBPMO_DOCUMENTO_PMO_EXITOSO = "JUBPMO_DOCUMENTO_PMO_EXITOSO";
export const JUBPMO_DOCUMENTO_PMO_LOADING = "JUBPMO_DOCUMENTO_PMO_LOADING";
export const JUBPMO_DOCUMENTO_PMO_ERROR = "JUBPMO_DOCUMENTO_PMO_ERROR";

export const JUBPMO_DOCUMENTO_PMO_CLEAR = "JUBPMO_DOCUMENTO_CLEAR";

export const JUBPMO_DECISION_PMO_EXITOSO = "JUBPMO_DECISION_PMO_EXITOSO";
export const JUBPMO_DECISION_PMO_LOADING = "JUBPMO_DECISION_PMO_LOADING";
export const JUBPMO_DECISION_PMO_ERROR = "JUBPMO_DOCUMENTO_PMO_ERROR";
