import React from "react";
import "./customModal.css";
import "./beautyModal.css";

import { Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const CustomModal = (props) => {
  const {
    showModal,
    fnCloseIconBtn,
    title,
    description,
    fnBtnPrimary = false,
    fnBtnSecondary = false,
    btnPrimaryLoading,
    btnSecondaryLoading,
    onClosed,
    textBtnPrimary,
    textBtnSecondary,
    isDisabled = false,
  } = props;

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const renderPrimaryIcon = () => {
    if (btnPrimaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const renderSecondaryIcon = () => {
    if (btnSecondaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };
  //showModal
  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.buttonUpCloseStyles}
              onClick={fnCloseIconBtn}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{title}&nbsp;&nbsp;&nbsp;</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {description}
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ p: 1, justifyContent: "space-evenly" }}>
          {fnBtnSecondary && (
            <Grid item xs={5}>
              <Button
                variant="outlined"
                className={classes.buttonsDownStyles}
                onClick={fnCloseIconBtn}
                disabled={isDisabled ? btnPrimaryLoading : false}
              >
                {textBtnSecondary}
              </Button>
            </Grid>
          )}

          {fnBtnPrimary && (
            <Grid item xs={5}>
              <Button
                variant="contained"
                className={classes.buttonsDownStyles}
                onClick={fnBtnPrimary}
                autoFocus
                disabled={isDisabled ? btnPrimaryLoading : false}
              >
                {textBtnPrimary}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
