import { HOME_LAYOUT_MODAL, HOME_CLEAR_LAYOUT_MODAL } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case HOME_LAYOUT_MODAL:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
    case HOME_CLEAR_LAYOUT_MODAL:
      return {
        ...state,
        status: false,
        message: null,
      };
    default:
      break;
  }
};
