import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../../../utils/device";

export const JubilacionPMOComponent = styled(Box)`
  width: 100%;
  padding: 0;
  ${css`
    &&& {
      @media screen and ${device.tablet} {
        padding: 16px;
      }
    }
  `}
`;
