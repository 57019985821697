import styled from "styled-components";

export const Alert = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.state.infoLight};
  ${(p) => p.theme.tipography.bodySmallRegular}
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-shadow: ${(p) => p.theme.elevations.below};
  p {
    margin-bottom: 0;
  }

  .left {
    svg {
      width: 24px;
      height: 24px;
      color: ${(p) => p.theme.palette.state.info};
    }
  }
  .right {
    p.message {
    }
    p.address {
      font-weight: 700;
    }
  }
`;
