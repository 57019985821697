import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../Button";
import Link from "../Link";

const ContentMessage = styled.div`
  font-style: italic;
  text-align: center;
`;

const NotFoundData = ({ mensaje, url, btnText, icon, funcBtn, link }) => {
  const history = useHistory();

  return (
    <ContentMessage>
      <p>{mensaje}</p>
      {url !== "" && (
        <Button onClick={() => (url !== "" ? history.push(url) : funcBtn())}>
          <span>{btnText}</span>
        </Button>
      )}
      {link !== "" && <Link to={link}>{btnText}</Link>}
    </ContentMessage>
  );
};

export default NotFoundData;
NotFoundData.defaultProps = {
  mensaje: "",
  icon: false,
  url: "",
  link: "",
  btnText: "",
  funcBtn: () => {},
};
