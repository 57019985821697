import React, { useState, useEffect } from "react";
import {
  Divider,
  Header,
  InvesmentHistoricalComponent,
  PaginationComponent,
  Table,
  TR,
} from "./styles";
import Pagination from "react-paginate";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { montoFormat } from "../../../../../helper/amountFormat";
import { MdChevronLeft, MdChevronRight, MdOutlineInfo } from "react-icons/md";
import { Tooltip } from "reactstrap";
import clienteAxios from "../../../../../config/axios";
import fakePeriodData from "./fakeData";
import useInvesmentData from "./useInvestmentData";

const InvestmentHistorical = ({ data, error, loading }) => {
  // Pagination
  const itemsPerPage = 4;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [data, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const { width } = useWindowSize();
  const isMobile = width < 1200;

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const handleTooltip = () => setTooltipIsOpen(!tooltipIsOpen);

  return (
    <InvesmentHistoricalComponent>
      <Header>
        <p>Historial de Inversiones </p>
        <Tooltip
          placement="top"
          isOpen={tooltipIsOpen}
          target="TooltipExample"
          toggle={handleTooltip}
        >
          Se considera lo acumulado desde el primer aporte de cada periodo hasta
          el retiro total.
        </Tooltip>
        <MdOutlineInfo id="TooltipExample" />
      </Header>
      {!isMobile && <DesktopTable data={currentItems} />}
      {isMobile && <MobileTable data={currentItems} />}
      <PaginationComponent size={"small"}>
        <Pagination
          breakLabel="..."
          nextLabel={
            <div className="icon">
              <MdChevronRight />
            </div>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <div className="icon">
              <MdChevronLeft />
            </div>
          }
          renderOnZeroPageCount={null}
        />
      </PaginationComponent>
    </InvesmentHistoricalComponent>
  );
};

export default InvestmentHistorical;
