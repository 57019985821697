import React, { useState, useEffect, useContext } from "react";
import Loading from "../../../../../../components/Loading/index.jsx";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

import { Mensual, Retiro } from "./components";

export default function Paso7(props) {
  const [modeSelected, setModeSelected] = useState(null);
  const [contents, setContents] = useState([]);
  const [dependencies, setDependencies] = useState({
    contribution: {},
    meet_the_time_in_spp: false,
  });
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const { guardarModalidad, obtenerContenidoPasoReja, temasAsesoriaRejaData } =
    jubilacionContext;
  useEffect(() => {
    props.setCurrectStepTitle("Eleción de modalidad");
    obtenerContenidoPasoReja(props.processId, 6);

    //TODO: Para ir al flujo de mensual cambiar a falso;
    setModeSelected(guardarModalidad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modeSelected === true) {
      props.setCurrectStepTitle("Eleción de retiro de hasta 95.5%");
    } else if (modeSelected === false) {
      props.setCurrectStepTitle("Eleción de pensión mensual");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeSelected]);

  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        props.setCurrectStepTitle(temasAsesoriaRejaData.name);
        setContents(temasAsesoriaRejaData.contents);
        setDependencies(temasAsesoriaRejaData.dependencies);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  const renderView = () => {
    if (modeSelected)
      return (
        <Retiro
          getBackOneStep={() => props.setCurrectStepNumber(6)}
          processId={props.processId}
          endProcess={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-reja/retiro-fin"
            )
          }
          dependencies={dependencies}
        />
      );
    else
      return (
        <Mensual
          getBackOneStep={() => props.setCurrectStepNumber(6)}
          processId={props.processId}
          endProcess={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-reja/pension-fin"
            )
          }
          setChangeModeView={() => setModeSelected(true)}
        />
      );
  };

  if (modeSelected === null) return <Loading />;

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
