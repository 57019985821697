import {
    MIS_INVERSIONES_EXITOSO,
    MIS_INVERSIONES_ERROR,
    MIS_INVERSIONES_LOADIN,
    MIS_INVERSIONES_CLEANER,
    MIS_INVERSIONES_CLEANER_PARCIAL,
  } from "../../types/misInversiones";
  
  export default (state, action) => {
    switch (action.type) {
      case MIS_INVERSIONES_EXITOSO:
        return {
          ...state,
          datosMisInversiones: action.payload,
          mensajeAlerta: null,
          loadingPage: false,
        };
      case MIS_INVERSIONES_LOADIN:
        return {
          ...state,
          loadingPage: true,
        };
      case MIS_INVERSIONES_ERROR:
        return {
          ...state,
          mensajeAlerta: action.payload,
          datosMisInversiones: null,
          loadingPage: false,
        };
      case MIS_INVERSIONES_CLEANER:
        return {
          ...state,
          datosMisInversiones: null,
          mensajeAlerta: null,
          loadingPage: false,
        };
      case MIS_INVERSIONES_CLEANER_PARCIAL:
        return {
          ...state,
          datosMisInversiones: null,
          loadingPage: false,
        };
      default:
        break;
    }
  };