import styled from "styled-components";

export const AsesoriaTitle = styled.h6`
  ${(p) => p.theme.tipography.h6}
  padding-bottom: 8px;
  margin-bottom: 16px;
  text-align: center;
`;

export const QueEsComponent = styled.div`
  margin-bottom: 16px;
`;

export const Title = styled.div`
  ${(p) => p.theme.tipography.subtitleM}
  margin-bottom: 16px;
`;

export const Description = styled.p`
  ${(p) => p.theme.tipography.bodySmallRegular}
`;
