import styled, { css } from "styled-components";
import { Box } from "@mui/material";

export const IconConent = styled(Box)`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  padding: ${(p) => p.padding};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 8px;
  /* background-color: ${(p) => p.theme.palette.white}; */
  border-radius: 16px;
  & img {
    width: 100%;
  }

  ${(p) =>
    p.type === "square" &&
    css`
      border-radius: 16px;
      border-style: solid;
      border-width: 1px;
      border-color: ${(p) => p.theme.palette.secondary.main};
    `}
  ${(p) =>
    p.bgColor === "secondary" &&
    css`
      background-color: ${(p) => p.theme.palette.secondary.lightest};
    `}
`;
