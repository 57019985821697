import React from "react";
import PropTypes from "prop-types";
import { ContentPage } from "./styles";
const Page = ({ children, isShowAppbar, title, ...props }) => {
  return <ContentPage>{children}</ContentPage>;
};

export default Page;

Page.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};
