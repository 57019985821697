
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from 'styled-components';
import IC_INFO from './../../../../assets/icons/ic_info_retiro_extraordinario.svg';
import { ContentFlex, FlexItem } from '..';

const FondoTitle = styled('div')`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: #2F6D95;
`;

const FondoDetail = styled('div')`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #363A3E;
`;

export const FondoComposicion = () => {

    const sizeRow = [3, 8, 10];
    return (
        <>
            <ContentFlex padding={'0px'} color={'#ffffff'}>

                <FlexItem size={sizeRow[2]}>
                    <ContentFlex padding={'0px'}>
                        <Composicion
                            icon={IC_INFO}
                            size={[2, 6]}
                            titulo={'Retiros y cargos acumulados'}
                            detalle={'s/39,951.69'}
                        />
                    </ContentFlex>
                </FlexItem>

                <FlexItem size={sizeRow[0]}>
                    <RemoveIcon sx={{ color: '#CE1336' }} />
                </FlexItem>

                <FlexItem size={sizeRow[1]}>
                    <Composicion
                        icon={IC_INFO}
                        titulo={'Aporte acumulados'}
                        detalle={'s/57,000.00'}
                    />
                </FlexItem>

                <FlexItem size={sizeRow[0]}>
                    <AddIcon sx={{ color: '#CE1336' }} />
                </FlexItem>

                <FlexItem size={sizeRow[1]}>
                    <Composicion
                        icon={IC_INFO}
                        titulo={'Rentabilidad acumulada'}
                        detalle={'s/1,582.15'}
                    />
                </FlexItem>

            </ContentFlex>
        </>
    )
}

const Composicion = ({ icon, titulo, detalle, size = [1, 2] }) => {

    const p = '6px';
    return (
        <>
            <ContentFlex padding={'0px'} style={{ flexDirection: 'column' }}>
                <FlexItem>
                    <ContentFlex padding={p}>
                        <img src={icon} />
                    </ContentFlex>
                </FlexItem>
                <FlexItem>
                    <ContentFlex padding={p}>
                        <FondoTitle>{titulo}</FondoTitle>
                    </ContentFlex>
                </FlexItem>
                <FlexItem>
                    <ContentFlex padding={p}>
                        <FondoDetail>{detalle}</FondoDetail>
                    </ContentFlex>
                </FlexItem>
            </ContentFlex>
        </>
    )
}
