import styled, { css } from "styled-components";

export const ModalPlazosNormativosComponent = styled.div`
  display: grid;
  padding: 16px;
  background-color: ${(p) => p.theme.palette.white};
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 375px;
  outline: none;
  p {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: grid;
  row-gap: 16px;
  margin-bottom: 24px;

  .close {
    justify-self: end;
    cursor: pointer;
  }

  .title {
    justify-self: center;
    ${(p) => p.theme.tipography.h5}
  }
`;

export const Body = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  ${(p) => p.theme.tipography.bodySmallRegular}
  letter-spacing: normal;
  display: grid;

  gap: 8px;
  align-items: center;
  box-shadow: ${(p) => p.theme.elevations.below};
  margin-bottom: 24px;

  .header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
      color: ${(p) => p.theme.palette.secondary.main};
    }

    .title {
      ${(p) => p.theme.tipography.subtitle1}
    }
  }
  .description {
    padding-left: calc(16px + 8px);
  }
`;

export const NextButton = styled.button`
  padding: 10px 24px;
  outline: none;
  cursor: pointer;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  ${(p) => p.theme.tipography.bodyMediumRegular}
  border-color: ${(p) => p.theme.palette.grayscale[100]};
  color: ${(p) => p.theme.palette.white};
  background-color: ${(p) => p.theme.palette.primary.main};

  ${(p) =>
    p.fluid &&
    css`
      width: 100%;
    `}

  &:hover {
    box-shadow: ${(p) => p.theme.elevations.below};
  }

  &:disabled {
    color: ${(p) => p.theme.palette.grayscale["300"]};
    background-color: ${(p) => p.theme.palette.grayscale["100"]};
  }
`;
