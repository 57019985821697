import React, { useState, useContext, useEffect } from "react";
import ReactGa from "react-ga";
import InfoUsuario from "./InfoUsuario";
import ListaTarjetas from "./ListaTarjetas";
import AgregarTarjeta from "./AgregarTarjeta";

import SolicitudProcesadaEliminar from "./SolicitudProcesadaEliminar";
import SolicitudProcesadaAgregar from "./SolicitudProcesadaAgregar";

import AuthContext from "../../../context/autenticacion/authContext";
import MisDatosContext from "../../../context/misdatos/misdatosContext";
import ModalContext from "../../../context/modal/modalContext";
import Loading from "../../../components/Loading/index.jsx";
import { Page, CardContainer } from "../../../components";
import Grid from "@mui/material/Grid";
import GeneralErrorModal from "../GeneralErrorModal";

const MisTarjetas = () => {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misDatosContext = useContext(MisDatosContext);
  const {
    tarjetasGet,
    mensajeAlertaTarjetasGet,
    limpiarVariablesTarjetaParcial,
    obtenerTarjetasGet,
    mensajeAlertaEliminarTarjetaPost,
    resultadoEliminarTarjeta,
    resultadoAgregarTarjeta,
    loadingTarjetasPage,
  } = misDatosContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const [openModalToken, setOpenModalToken] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlertaTarjetasGet) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerTarjetasGet();
    }

    return () => {
      limpiarVariablesTarjetaParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlertaTarjetasGet]);
  useEffect(() => {
    if (resultadoEliminarTarjeta) {
      setMostrarModalToken(resultadoEliminarTarjeta);
      setOpenModalToken(false);
    } else {
      setMostrarModalToken(null);
    }
  }, [resultadoEliminarTarjeta]);
  useEffect(() => {
    if (resultadoAgregarTarjeta) {
      setMostrarModalToken(resultadoAgregarTarjeta);
      setOpenModalToken(false);
    } else {
      setMostrarModalToken(null);
    }
  }, [resultadoAgregarTarjeta]);
  const mostrarMensaje = () => {
    setMessage(mensajeAlertaTarjetasGet);
  };

  const [opcionSeleccionada, setOpcionSeleccionada] = useState(1);
  const [bankAccountNotRemovableModal, setBankAccountNotRemovableModal] =
    useState(false);

  const handleAgregarTarjeta = () => {
    setOpcionSeleccionada(2);
  };

  useEffect(() => {
    if (
      mensajeAlertaEliminarTarjetaPost?.msg ===
      "No se pudo eliminar la cuenta bancaria por estar asociada a su débito mensual"
    ) {
      setOpenModalToken(false);
      setBankAccountNotRemovableModal(true);
    }
  }, [mensajeAlertaEliminarTarjetaPost]);

  if (mensajeAlertaTarjetasGet !== null) return <p></p>;
  if (tarjetasGet === null) return <Loading />;

  return (
    <Page title="Cuentas bancarias" showHeader={"mobile"}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <CardContainer>
            <ul className="list-steps-datos">
              <li
                onClick={() => setOpcionSeleccionada(1)}
                className={
                  opcionSeleccionada === 1 ? "active-selected-datos" : ""
                }
              >
                Lista de Cuentas
              </li>
              <li
                onClick={() => setOpcionSeleccionada(2)}
                className={
                  opcionSeleccionada === 2 ? "active-selected-datos" : ""
                }
              >
                Agregar Cuenta
              </li>
            </ul>
            {opcionSeleccionada === 1 ? (
              <>
                {resultadoEliminarTarjeta === null ||
                (resultadoEliminarTarjeta &&
                  resultadoEliminarTarjeta.r_respuesta === -3) ? (
                  <ListaTarjetas
                    info={tarjetasGet.r_cuentas_detalle}
                    agregar={handleAgregarTarjeta}
                    mostrarModalToken={mostrarModalToken}
                    setOpenModalToken={setOpenModalToken}
                    openModalToken={openModalToken}
                    setMostrarModalToken={setMostrarModalToken}
                  />
                ) : (
                  <SolicitudProcesadaEliminar
                    loading={loadingTarjetasPage}
                    recargar={obtenerTarjetasGet}
                    info={resultadoEliminarTarjeta}
                  />
                )}
              </>
            ) : (
              <>
                {resultadoAgregarTarjeta === null ||
                (resultadoAgregarTarjeta &&
                  resultadoAgregarTarjeta.r_respuesta === -3) ? (
                  <AgregarTarjeta
                    bancos={tarjetasGet.r_bancos}
                    tipoCuentas={tarjetasGet.r_tipo_cuentas}
                    mostrarModalToken={mostrarModalToken}
                    setOpenModalToken={setOpenModalToken}
                    openModalToken={openModalToken}
                    setMostrarModalToken={setMostrarModalToken}
                  />
                ) : (
                  <SolicitudProcesadaAgregar
                    loading={loadingTarjetasPage}
                    recargar={obtenerTarjetasGet}
                    info={resultadoAgregarTarjeta}
                  />
                )}
              </>
            )}
          </CardContainer>
          <GeneralErrorModal
            isOpen={bankAccountNotRemovableModal}
            setIsOpen={setBankAccountNotRemovableModal}
            title="Ocurrio un error"
            subtitle="Lo sentimos, pero no puede eliminar esta cuenta porque tiene un débito mensual asociado"
          />
        </Grid>
        <InfoUsuario />
      </Grid>
    </Page>
  );
};

export default MisTarjetas;
