import styled, { css } from "styled-components";
import Button from "../../../../components/Buttons";

export const FileInputComponent = styled.div`
  input[type="file"] {
    display: none;
  }

  width: 100%;
  min-height: 172px;
  border-radius: 16px;
  display: block;
  background-color: ${(p) => p.theme.palette.grayscale[50]};
  padding: 16px;
  border: 2px dashed ${(p) => p.theme.palette.grayscale[400]};

  display: grid;

  ${(p) => p.theme.tipography.bodySmallRegular};

  ${(p) =>
    p.error &&
    css`
      border-color: ${(p) => p.theme.palette.state.error};
    `}
`;

export const NoFilesContainerComponent = styled.div`
  justify-self: center;
  align-self: center;
  display: grid;
  justify-items: center;
  gap: 16px;
  color: ${(p) => p.theme.palette.grayscale[200]};

  ${(p) =>
    p.error &&
    css`
      color: ${(p) => p.theme.palette.state.error};
    `}

  .upload-statement {
    color: ${(p) => p.theme.palette.grayscale[400]};
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    column-gap: 8px;
    ${(p) => p.theme.tipography.bodySmallMedium};

    ${(p) =>
      p.error &&
      css`
        color: ${(p) => p.theme.palette.state.error};
      `}
  }

  .or-divider {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 4px;
    align-items: center;
    span {
      display: inline-block;
      height: 0;
      width: 56px;
      border-top: 1px solid ${(p) => p.theme.palette.grayscale[200]};

      ${(p) =>
        p.error &&
        css`
          border-color: ${(p) => p.theme.palette.state.error};
        `}
    }
  }
`;

export const WithFilesContainerComponent = styled.div`
  display: grid;
  row-gap: 16px;

  .validation-message {
    justify-self: center;
    color: ${(p) => p.theme.palette.state.success};

    ${(p) =>
      p.error &&
      css`
        color: ${(p) => p.theme.palette.state.error};
      `}
  }

  .file {
    padding: 16px;
    border: 1px solid ${(p) => p.theme.palette.state.success};
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr auto;
    color: ${(p) => p.theme.palette.state.success};
    background-color: ${(p) => p.theme.palette.state.successLight};
    ${(p) => p.theme.tipography.bodySmallMedium}

    ${(p) =>
      p.error &&
      css`
        color: ${(p) => p.theme.palette.state.error};
        border-color: ${(p) => p.theme.palette.state.error};
        background-color: ${(p) => p.theme.palette.state.errorLight};
      `}

    .file-name {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: center;
      column-gap: 8px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }
    .close {
      cursor: pointer;
      display: none;
    }
  }

  .divider {
    display: inline-block;
    height: 0;
    width: 100%;
    border-top: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  }

  .upload-again {
    justify-self: center;
    align-self: center;
    ${(p) => p.theme.tipography.bodySmallMedium}
    color: ${(p) => p.theme.palette.grayscale[400]};
  }
`;

export const GrayButton = styled(Button).attrs((p) => {
  return {
    textColor: p.theme.palette.grayscale[400],
  };
})`
  margin: auto;
`;
