import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";
import { ContentCheckValidacion } from "./styles";
import * as Input from "../../../../../../components/Input";
const ModalActualizarDatos = ({
  isOpen,
  setIsOpen,
  misDatosGet,
  startProcess,
  loadingBtnPrimary = false,
  loadingBtnSecondary = false,
}) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Confirma tus datos de contacto"
      subtitle={"Usaremos estos datos para comunicarnos contigo."}
      loadingBtnPrimary={loadingBtnPrimary}
      onClickBtnPrimary={startProcess}
      textBtnPrimary={"Confirmar datos"}
      loadingBtnSecondary={loadingBtnSecondary}
      isBtnSecondary={true}
      textBtnSecondary="Actualizar datos"
      onClickBtnSecondary={() =>
        history.push(
          "/mi-habitat-digital/afiliado/informacion/datos-personales"
        )
      }
    >
      <ContentCheckValidacion>
        <div className="user-data">
          <div className="field mb-2">
            <span className="label">Correo personal:</span>
            <span className="data">
              {misDatosGet.r_persona.r_datos.r_correo
                ? misDatosGet.r_persona.r_datos.r_correo
                : "No registrado"}
            </span>
          </div>

          <div className="field">
            <span className="label">Celular:</span>
            <span className="data">
              {misDatosGet.r_persona.r_datos.r_celular
                ? misDatosGet.r_persona.r_datos.r_celular
                : "No registrado"}
            </span>
          </div>
        </div>
      </ContentCheckValidacion>
    </Modal>
  );
};

export default ModalActualizarDatos;
