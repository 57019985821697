import React from "react";
import { Col, Input, Label, FormGroup } from "reactstrap";
const Texto = ({ opcion, onCheckInput }) => {
  const bonoReconocimiento = () => {
    return (
      <FormGroup row style={{ margin: 0, paddingBottom: "16px" }}>
        <Col sm={{ size: 12 }} className="box-checkbox style-border">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="0"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              ¿Alguna vez realizaste aportes a la ONP (Sistema Nacional de
              Pensiones)?
            </Label>
          </FormGroup>
        </Col>
        <Col sm={{ size: 12 }} className="box-checkbox style-border">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="1"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              ¿Tus aportes a la ONP han sido antes de enero de 2002?
            </Label>
          </FormGroup>
        </Col>

        <Col sm={{ size: 12 }} className="box-checkbox">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="2"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              Contar con un mínimo de 48 meses aportados a la ONP entre el 06 de
              diciembre de 1982 y el 05 de diciembre de 1992.
            </Label>
          </FormGroup>
        </Col>
        <Col sm={{ size: 12 }} className="box-checkbox">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="3"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              Contar con un mínimo de 48 meses aportados a la ONP entre el 01 de
              enero de 1987 y el 31 diciembre de 1996, y estar afiliado a la AFP
              entre el 06 de noviembre de 1996 y el 31 de diciembre de 1997.
            </Label>
          </FormGroup>
        </Col>
        <Col sm={{ size: 12 }} className="box-checkbox">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="4"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              Contar con un mínimo de 48 meses aportados a la ONP entre el 01 de
              enero de 1992 y el 31 de diciembre de 2001.
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
    );
  };

  const pensionInvalidez = () => {
    return (
      <FormGroup row style={{ margin: 0 }}>
        <Col sm={{ size: 12 }} className="box-checkbox style-border">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="0"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              ¿Has sufrido un accidente o padeces una enfermedad con origen
              posterior a tu afiliación al Sistema Privado de Pensiones?
            </Label>
          </FormGroup>
        </Col>
        <Col sm={{ size: 12 }} className="box-checkbox style-border">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="1"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              ¿Este accidente o enfermedad ha originado una pérdida o
              disminución de tu capacidad para trabajar?
            </Label>
          </FormGroup>
        </Col>
        <Col sm={{ size: 12 }} className="box-checkbox">
          <FormGroup check>
            <Label check className="label-text">
              <Input
                type="checkbox"
                id="2"
                className="input-box"
                onChange={onCheckInput}
              />{" "}
              ¿Cuentas con evidencias o documentos para demostrar la condición
              de invalidez? Ejemplos: Informe médico, historia clínica, exámenes
              médicos, etc.
            </Label>
          </FormGroup>
        </Col>
      </FormGroup>
    );
  };
  return opcion === 5 ? bonoReconocimiento() : pensionInvalidez();
};

export default Texto;
