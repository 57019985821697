import React, { useState, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../errors/error";
import { Grid, MenuItem, Select } from "@mui/material";
import GenerarClaveContext from "../../../../context/generarClave/generarClaveContext";
import "./index.css";

const FormOne = (props) => {
  const { changeOpenModal, bloquear } = props;
  const generarClaveContexto = useContext(GenerarClaveContext);
  const {
    gIntentoDocumento,
    gBloqueoPasos,
    bloquearPasos,
    cambioIntentoDocumento,
    cambioIntentoVolverToken,
    cambioIntentoConfirmaToken,
    cambioIntentoClave,
    obtenerDatosUsuario,
    LlenarErrorGenerico,
  } = generarClaveContexto;
  const { register, handleSubmit } = useForm();
  const globalErrors = {
    r_document: "",
    r_confirm_type_document: "",
    r_mensaje: "",
  };
  const [captcha, setCaptcha] = useState(null);
  const [inputNumDoc, guardarinputNumDoc] = useState({
    lenghtNdoc: 8,
    pattern: "[0-9]*",
  });
  const [validateButton, setValidateButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errores, setErrores] = useState(globalErrors);
  const [dataForm, setDataForm] = useState({
    documentType: "NN",
    documentNumber: "",
  });

  const listOptions = [
    {
      value: "NN",
      text: "Selecciona un documento",
      maxLength: 8,
    },
    {
      value: "00",
      text: "D.N.I",
      maxLength: 8,
      pattern: "[0-9]*",
    },
    {
      value: "01",
      text: "Carnet de Extranjería",
      maxLength: 9,
      pattern: "[0-9]*",
    },
    {
      value: "03",
      text: "Lib. Adolesc. Trab.",
      maxLength: 12,
      pattern: "[0-9]*",
    },
    { value: "04", text: "Pasaporte", maxLength: 15, pattern: "[a-zA-Z0-9 ]+" },
  ];

  const todosIntentosBloqueados = () => {
    cambioIntentoDocumento(3);
    cambioIntentoVolverToken(3);
    cambioIntentoConfirmaToken(3);
    cambioIntentoClave(3);
  };

  const llenarStore = () => {
    //llenar valores como bloqueo intentos
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      //DATALAYER GENERA TU CLAVE WEB
      window.dataLayer.push({
        event: "button_click",
        description: "genera tu clave web",
        option: "siguiente",
      });

      let body = {
        doc_type: dataForm.documentType,
        doc_num: dataForm.documentNumber,
        "g-recaptcha-response": captcha,
      };
      let { response, intentosG } = await obtenerDatosUsuario(body);
      if (intentosG > 2 && response) {
        bloquear();
        bloquearPasos(true);
        todosIntentosBloqueados();
        setLoading(false);
      } else {
        if (response) {
          setLoading(false);
          setErrores({ ...errores, r_mensaje: "El documento es inválido" });
        } else {
          llenarStore();
          changeOpenModal();
          setLoading(false);
        }
      }
    } catch (error) {
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
      setLoading(false);
    }
  };

  const history = useHistory();
  const handleDocumentType = (e) => {
    let item = listOptions.find((option) => option.value === e.target.value);
    guardarinputNumDoc({
      ...inputNumDoc,
      lenghtNdoc: item.maxLength,
      pattern: item.pattern,
    });
    setDataForm({
      ...dataForm,
      ["documentType"]: e.target.value,
      ["documentNumber"]: "",
    });
    setErrores(globalErrors);
    setValidateButton(false);
  };

  const respuestaCaptcha = (response) => {
    setCaptcha(response);
  };

  const goHome = () => {
    //DATALAYER GENERA TU CLAVE WEB
    window.dataLayer.push({
      event: "button_click",
      description: "genera tu clave web",
      option: "volver",
    });
    bloquearPasos(false);
    history.push("/");
  };

  const validateData = (event) => {
    let mensajesError = {
      r_document: "",
      r_confirm_type_document: "",
    };
    const { name, value } = event.target;
    mensajesError = errores;
    mensajesError.r_mensaje = "";
    let valueDoc = value;
    if (dataForm.documentType !== "04") {
      valueDoc = value.replace(/[^0-9]/g, "");
    }

    switch (dataForm.documentType) {
      case "NN":
        mensajesError.r_confirm_type_document =
          "Debe seleccionar un tipo de documento.";
        setValidateButton(false);
        break;
      case "00":
        // if (!regexOnlyNumber(valueDoc)) {
        //     mensajesError.r_document = 'Debe utilizar solo números.'
        //     setValidateButton(false)
        //     break;
        // }
        if (valueDoc.length < 8) {
          mensajesError.r_document = "Debe utilizar 8 digitos.";
          setValidateButton(false);
        } else {
          mensajesError.r_document = "";
          setValidateButton(true);
        }

        break;
      case "01":
        // if (!regexOnlyNumber(valueDoc)) {
        //     mensajesError.r_document = 'Debe utilizar solo números.'
        //     setValidateButton(false)
        //     break;
        // }
        if (valueDoc.length < 9) {
          mensajesError.r_document = "Debe utilizar 9 digitos.";
          setValidateButton(false);
        } else {
          mensajesError.r_document = "";
          setValidateButton(true);
        }

        break;
      case "03":
        // if (!regexOnlyNumber(valueDoc)) {
        //     mensajesError.r_document = 'Debe utilizar solo números.'
        //     setValidateButton(false)
        //     break;
        // }
        if (valueDoc.length < 12) {
          mensajesError.r_document = "Debe utilizar 12 digitos.";
          setValidateButton(false);
        } else {
          mensajesError.r_document = "";
          setValidateButton(true);
        }

        break;
      case "04":
        // if (valueDoc.length < 15) {
        //     mensajesError.r_document = 'Debe utilizar 15 caracteres.'
        //     setValidateButton(false)
        // } else {
        mensajesError.r_document = "";
        setValidateButton(true);
        // }

        break;
      default:
        break;
    }
    setDataForm({ ...dataForm, [name]: valueDoc });
    setErrores(mensajesError);
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="new-home-form-group-web box-select">
                <label htmlFor="document-type">Tipo de documento:</label>
                <div className=" box-select-two">
                  <Select
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "&.MuiInputBase-root": {
                        borderRadius: "20px",
                        border: "1px solid #e0e1e2",
                        backgroundColor: "#F5F5F5",
                        width: "100%",
                      },
                      "&.MuiPaper-root": {
                        boxShadow:
                          "0px 4px 13px 0px rgba(27, 29, 39, 0.12) !important",
                      },
                    }}
                    className="form-field bg-form-web w-100 "
                    id="document-type"
                    name="documentType"
                    {...register("documentType", { required: true })}
                    onChange={(e) => handleDocumentType(e)}
                    value={dataForm.documentType}
                  >
                    {listOptions.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <ErrorMessage
                  mensaje={errores.r_confirm_type_document}
                  mensajeletra={true}
                ></ErrorMessage>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="new-home-form-group-web">
                <label htmlFor="document-number">Número de documento:</label>
                <input
                  className="form-field bg-form-web modify-select"
                  id="document-number"
                  type="text"
                  name="documentNumber"
                  value={dataForm.documentNumber}
                  autoComplete="off"
                  pattern={inputNumDoc.pattern}
                  maxLength={inputNumDoc.lenghtNdoc}
                  onChange={validateData}
                />
                <ErrorMessage
                  mensaje={errores.r_document}
                  mensajeletra={true}
                ></ErrorMessage>
                <ErrorMessage
                  mensaje={errores.r_mensaje}
                  mensajeletra={true}
                ></ErrorMessage>
              </div>
            </Grid>
          </Grid>

          {gIntentoDocumento === 2 && (
            <div className="error-block">
              <p>Te queda solo un intento permitido</p>
            </div>
          )}

          <div className="mt-3 captcha-container">
            <ReCAPTCHA
              sitekey="6LfH3XkmAAAAAAZ0C6jfmlhKRjVuO1VFvKt9-pNA"
              onChange={respuestaCaptcha}
            />
          </div>

          <hr />

          {!loading ? (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6} sm={3}>
                <button
                  onClick={goHome}
                  className=" btn-secundary margin-center"
                >
                  Volver
                </button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <button
                  type="submit"
                  className={` btn-recover margin-center ${
                    !captcha || !validateButton || gBloqueoPasos
                      ? "disabled-button-web"
                      : ""
                  }`}
                  disabled={!captcha || !validateButton || gBloqueoPasos}
                >
                  Siguiente{" "}
                </button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <button
                  className="mt-3 btn-secundary margin-center disabled-button-web"
                  disabled
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="ml-1">Validando</span>
                </button>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
    </>
  );
};

export default FormOne;
