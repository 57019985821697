import React from "react";
import { Alert } from "./styles";
import InfoCircle from "../../../../assets/IconBucket/info_circle";

const ReactivarAporteAlerta = () => {
  return (
    <Alert>
      <div className="left">
        <InfoCircle />
      </div>
      <div className="right">
        <p className="message">Podrás volver a activar otro desde:</p>
        <p className="address">Home &gt; Fondo Libre Habitat &gt; Invertir</p>
      </div>
    </Alert>
  );
};

export default ReactivarAporteAlerta;
