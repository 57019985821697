import { createTheme, experimental_sx as sx } from "@mui/material/styles";
import { important } from "polished";
import palette from "./palette";
import tipography from "./tipography";
import elevations from "./elevations";
const theme = createTheme({
  palette,
  tipography,
  elevations,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: "40px",
          // border: "1px solid #CE1336",
          borderRadius: "16px",
          boxShadow: "none",
        },
        contained: {
          "&:disabled": {
            color: "#CDCED0",
            backgroundColor: "#F6F6F6",
            border: "none",
          },
        },
        outlined: {
          border: "1px solid #CE1336",
        },
      },
      variants: [
        {
          props: { variant: "successfully", color: "success" },
          style: {
            height: "40px",
            // border: "1px solid #1B7935",
            borderRadius: "16px",
          },
        },
        {
          props: { variant: "buttonModalToken" },
          style: {
            height: "55px",
            width: "100%",
            border: "none",
            color: "#CE1336",
            backgroundColor: "#F6F6F6",
            borderLeft: "1px solid #EDEDED",
            borderRadius: "0px 16px 16px 0px",
            "&.Mui-disabled": {
              backgroundColor: "#F6F6F6",
            },
            "& .Mui-focusVisible": {
              backgroundColor: "#F6F6F6",
            },

            "&:focus": {
              backgroundColor: "#F6F6F6",
            },
          },
        },
      ],
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#51565F",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#F2FBFF",
          },
          "&:hover": {
            backgroundColor: "#F0F0F0 !important",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            borderRadius: "16px",
            backgroundColor: "#F8F9FB",
          },
          "& .MuiFilledInput.Mui-focused": {
            borderColor: " none !important",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
          "& .MuiFilledInput-root": {
            borderRadius: "16px",
          },
          "& .Mui-disabled": {
            backgroundColor: "#F8F9FB",
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          "& .MuiIcon-colorDisabled": {
            color: " #EDEDED",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          textJustify: "center",
          borderRadius: "16px",
          "& 	.MuiAlert-icon": {
            fontSize: "24px",
          },
        },
        standardSuccess: {
          backgroundColor: "#E6F2ED",
          "& 	.MuiAlert-icon": {
            color: "#1B7935",
          },
        },
        standardInfo: {
          backgroundColor: "#EFF3FE",
          "& 	.MuiAlert-icon": {
            color: "#396DBB",
          },
        },
        standardError: {
          backgroundColor: "#FFEFED",
          "& 	.MuiAlert-icon": {
            color: "#E11900",
          },
        },
        standardWarning: {
          backgroundColor: "#FFFAF0",
          "& 	.MuiAlert-icon": {
            color: "#EFBD0C",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: "static !important",
          color: "#51565F",
          fontWeight: 600,
          borderRadius: "none",

          "& .MuiTabs-indicator": {
            top: 80,
            display: "flex",
            backgroundColor: "#569BBE",
            height: "2px",
          },
          "& .MuiTab-root.Mui-selected": {
            color: "#ce1336",
            backgroundColor: "#FFFFFF",
            border: "none",
            borderRadius: 0,
            borderBottom: "2px solid #ce1336",
          },
          "& .MuiTabs-scroller": {
            border: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          "& .MuiIcon-fontSizeSmall": {
            fontSize: "16px",
          },
          "& .MuiIcon-fontSizeLarge": {
            fontSize: "40px",
          },
          ".MuiIcon-colorPrimary": {
            color: "#51565F",
          },
          ".MuiIcon-colorSecondary": {
            color: "#ffffff !important",
          },
          "&	.MuiIcon-colorError": {
            color: "#E11900",
          },
          "&	.MuiIcon-colorDisabled": {
            color: "#CDCED0",
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          p: 2,
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "principal" },
          style: {
            p: 2,
            border: "none",
            boxShadow: "none",
            borderRadius: "16px",
            width: "100%",
          },
        },
        {
          props: { variant: "transparent" },
          style: {
            p: "4px !important",
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& 	.MuiDialog-paper": {
            // borderRadius: "16px",
          },
        },
      },
    },
  },
});

export default theme;
