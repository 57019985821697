import React from "react";
import { Card } from "reactstrap";
const InfoUsuario = ({ hiddenTop = false }) => {
  return (
    <>
      {!hiddenTop && (
        <Card className="mb-2">
          <section className="text-center content-invierte">
            <div className="img-invierte">
             
            </div>
            <p className="text-justify px-3">
              Si quieres saber más información acerca de la transferencia de
              fondos al exterior, ingresa{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.afphabitat.com.pe/transferencia-de-fondos-al-exterior/"
              >
                aquí
              </a>
              .
            </p>
          </section>
        </Card>
      )}
    </>
  );
};

export default InfoUsuario;
