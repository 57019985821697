import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Stepper from "../../../../../../components/Stepper2";
import useStepNavigation from "../../../../../../hooks/useStepNavigation";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import { Main, Container } from "./styles";
import Asesoria from "../Etapas/Asesoria";
import Calculo from "../Etapas/Calculo";
import Resumen from "../Etapas/Resumen";
import Divider from "../../../../../../components/Divider";
const stepInformation = [
  { label: "Asesoría", id: "asesoria" },
  { label: "Cálculo", id: "calculo" },
  { label: "Resumen", id: "resumen" },
];
const stepInformationModify = [
  { label: "Cálculo", id: "calculo" },
  { label: "Resumen", id: "resumen" },
];
const StepComponent = ({
  defaultStep,
  getOutOfProcess,
  showStepper,
  showModify,
  setShowModify,
  setShowStepper,
  setShowStepComponent,
}) => {
  const { width } = useWindowSize();

  const arrayStepper = showModify ? stepInformationModify : stepInformation;
  const { currentStep, handleStepNavigation } = useStepNavigation(
    arrayStepper.map((e) => e.id),
    { defaultStep: defaultStep || "asesoria" }
  );
  const alternativeSteps = arrayStepper.map((e) => ({ ...e, label: "" }));

  const smallScreenBreakpoint = 375;
  const stepsSelected =
    width < smallScreenBreakpoint ? alternativeSteps : arrayStepper;
  const activeStep = arrayStepper.findIndex((e) => e.id === currentStep);

  const formMethods = useForm({
    mode: "all",
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <Container>
      <FormProvider {...formMethods}>
        <form>
          <Main>
            {showStepper && (
              <Stepper steps={stepsSelected} activeStep={activeStep} />
            )}
            {showStepper && <Divider size="sm" />}
            {currentStep === "asesoria" && (
              <Asesoria
                handleStepNavigation={handleStepNavigation}
                setShowStepComponent={setShowStepComponent}
              />
            )}
            {currentStep === "calculo" && (
              <Calculo
                handleStepNavigation={handleStepNavigation}
                getOutOfProcess={getOutOfProcess}
                setShowModify={setShowModify}
                showModify={showModify}
                setShowStepper={setShowStepper}
              />
            )}
            {currentStep === "resumen" && <Resumen />}
          </Main>
        </form>
      </FormProvider>
    </Container>
  );
};

export default StepComponent;
