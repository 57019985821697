import React from "react";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import { Subtitle } from "./styles";
import Modal from "../../../components/BasicModal";

const ModalNumeroDeSolicitud = ({ open, setOpen }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Número de solicitud</Modal.Title>
      <Modal.Subtitle>
        Puedes encontrar el número de solicitud de tu trámite en el correo que
        te enviamos al momento de registrarlo.
      </Modal.Subtitle>
      <Divider />
      <Button
        fullWidth
        onClick={onClose}
        size="lg"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalNumeroDeSolicitud;
