import React from "react";
import styled from "styled-components";
import Items from "./components/items";

const Container = styled.div`
  display: block;
  width: 100%;
  @media (min-width: 990px) {
    display: none;
  }
`;
const Index = ({
  habitatValue,
  acumulated_mount,
  product,
  cuotasValue,
  detalleProductoSPP,
}) => {
  return (
    <Container>
      <Items
        acumulated_mount={acumulated_mount}
        product={product}
        habitatValue={habitatValue}
        detalleProductoSPP={detalleProductoSPP}
        cuotasValue={cuotasValue}
      />
    </Container>
  );
};

export default Index;
