import React from "react";
import { montoFormat } from "../../helper/amountFormat";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Logo from "../../assets/logo.png";
import firma from "../../assets/firmaNorero.PNG";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "100%",
  },
  headerTop: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    color: "#4b60ae",
  },
  centerImage: {
    width: 100,
    padding: 10,
  },
  tablaHeader: {
    display: "table",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#7697b1",
    color: "#fff",
    margin: "20px 20px 0 20px",
  },
  header6: {
    width: "16.6%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  header6LastChild: {
    width: "16.6%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  tablaBody: {
    display: "table",
    flexDirection: "row",
    fontSize: 8,
    backgroundColor: "#dfe6ed",
    margin: "0 20px",
  },
  body6: {
    width: "16.6%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  body6LastChaild: {
    width: "16.6%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  header2: {
    width: "50%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  header2LastChild: {
    width: "50%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  subHeader4: {
    width: "25%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  subHeader4LastChild: {
    width: "25%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  tablaSubHeader: {
    display: "table",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#7697b1",
    color: "#fff",
    margin: "0 20px 0 20px",
  },
  subHeader4Bold: {
    width: "25%",
    fontSize: 10,
    fontWeight: "900",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  header5: {
    width: "20%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  header5LastChild: {
    width: "20%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  body5: {
    width: "20%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  body5LastChaild: {
    width: "20%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  netoText: {
    width: "25%",
    fontSize: 10,
    fontWeight: "900",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
    backgroundColor: "#7697b1",
  },
  netoTextMonto: {
    width: "25%",
    fontSize: 10,
    fontWeight: "900",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
    backgroundColor: "#7697b1",
  },
  body2: {
    width: "50%",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  body2LastChild: {
    width: "50%",
    borderRight: 0,
    borderColor: "#fff",
    textAlign: "center",
    padding: "5px 1px 5px 1px",
  },
  parrafos: {
    display: "table",
    flexDirection: "row",
    fontSize: 10,
    margin: "20px 20px 0 20px",
  },
  parrafo: {
    width: "50%",
    borderRight: 0,
    textAlign: "justify",
    padding: "5px 10px 5px 5px",
  },
  firmas: {
    display: "table",
    flexDirection: "row",
    fontSize: 10,
    textAlign: "center",
    margin: "20px 20px 0 20px",
  },
  contentFirma: {
    width: "50%",
    padding: "10px 10px 5px 150px",
    textAlign: "center",
  },
  firma: {
    width: 50,
  },
  textoFirma: {
    width: "20%",
    borderTop: 0,
    textAlign: "center",
  },
  textoFirmaLinea: {
    width: "22%",
    borderTop: 1,
    borderColor: "#000",
    textAlign: "center",
  },
  contenedorTextoFirma: {
    display: "table",
    flexDirection: "row",
    fontSize: 10,
    margin: "0 20px",
    textAlign: "center",
  },
  piePagina: {
    display: "table",
    position: "fixed",
    bottom: 0,
    flexDirection: "row",
    fontSize: 10,
    textAlign: "center",
    margin: "20px 20px 20px 20px",
  },
  textoJustificado: {
    textAlign: "justify",
  },
  conceptoImporte: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    borderRight: 1,
    borderColor: "#fff",
    textAlign: "center",
  },
  conceptoImporteItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  concepto: {
    width: "50%",
    borderRight: 1,
    borderColor: "#fff",
    padding: "5px 1px 5px 1px",
  },
  importe: {
    width: "50%",
    borderRight: 0,
    padding: "5px 1px 5px 1px",
  },
});

const Vista = ({ datos }) => {
  const { r_haberes, r_descuentos } = datos;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerTop}>
          <View style={styles.section}>
            <Text style={styles.title}>BOLETA DE PAGO</Text>
          </View>
          <View style={styles.centerImage}>
            <Image src={Logo} />
          </View>
        </View>

        <View style={styles.tablaHeader}>
          <Text style={styles.subHeader4}> Tipo Liq.</Text>
          <Text style={styles.subHeader4}>Tipo de Planilla</Text>
          <Text style={styles.subHeader4}>Tipo de pensión </Text>
          <Text style={styles.subHeader4LastChild}>Modalidad pensión</Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.subHeader4}>{datos.r_tipo_liquidacion}</Text>
          <Text style={styles.subHeader4}>
            {datos.r_tipo_planilla} - {datos.r_num_planilla}
          </Text>
          <Text style={styles.subHeader4}>{datos.r_tipo_pension}</Text>
          <Text style={styles.subHeader4LastChild}>
            {datos.r_modalidad_pension}
          </Text>
        </View>

        <View style={styles.tablaHeader}>
          <Text style={styles.header5}>Apoderado</Text>
          <Text style={styles.header5}>Nro. Exp</Text>
          <Text style={styles.header5}>Documento de identidad</Text>
          <Text style={styles.header5}>Afiliado</Text>
          <Text style={styles.header5LastChild}>CUSSP</Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.body5}>{datos.r_nom_apoderado}</Text>
          <Text style={styles.body5}>{datos.r_num_expediente}</Text>
          <Text style={styles.body5}>{datos.r_num_doc_apoderado}</Text>
          <Text style={styles.body5}>{datos.r_nom_afiliado}</Text>
          <Text style={styles.body5LastChaild}>{datos.r_cuspp}</Text>
        </View>

        <View style={styles.tablaHeader}>
          <Text style={styles.header5}>Sucursal</Text>
          <Text style={styles.header5}>Nro. Solicitud</Text>
          <Text style={styles.header5}>Rem. Base</Text>
          <Text style={styles.header5}>Rem Preliminar.</Text>
          <Text style={styles.header5LastChild}>Periódo de Pago</Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.body5}>{datos.r_sucursal}</Text>
          <Text style={styles.body5}>{datos.r_nro_solicitud}</Text>
          <Text style={styles.body5}>{datos.r_rem_base}</Text>
          <Text style={styles.body5}>{datos.r_rem_preliminar}</Text>
          <Text style={styles.body5LastChaild}>{datos.r_periodo_pago}</Text>
        </View>

        <View style={styles.tablaHeader}>
          <Text style={styles.header6}>Forma de Pago</Text>
          <Text style={styles.header6}>Lugar de Remisión</Text>
          <Text style={styles.header6}>Nro. Cheque</Text>
          <Text style={styles.header6}>Banco</Text>
          <Text style={styles.header6}>N.° Cuenta Corriente</Text>
          <Text style={styles.header6LastChild}>Rango de meses de pago</Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.body6}>{datos.r_forma_pago}</Text>
          <Text style={styles.body6}>{datos.r_lugar_remision}</Text>
          <Text style={styles.body6}>{datos.r_num_cheque}</Text>
          <Text style={styles.body6}>{datos.r_nom_banco}</Text>
          <Text style={styles.body6}>{datos.r_num_cuenta}</Text>
          <Text style={styles.body6LastChaild}>{datos.r_rango_meses_pago}</Text>
        </View>

        <View style={styles.tablaHeader}>
          <Text style={styles.header2}>HABERES</Text>
          <Text style={styles.header2LastChild}>DESCUENTOS</Text>
        </View>
        <View style={styles.tablaSubHeader}>
          <Text style={styles.subHeader4}>Concepto</Text>
          <Text style={styles.subHeader4}>Importe</Text>
          <Text style={styles.subHeader4}>Concepto</Text>
          <Text style={styles.subHeader4LastChild}>Importe</Text>
        </View>
        <View style={styles.tablaBody}>
          <View style={styles.conceptoImporte}>
            <View style={styles.wraper}>
              {r_haberes.map((item, index) => (
                <View key={index} style={styles.conceptoImporteItem}>
                  <View style={styles.concepto}>
                    <Text>{item.r_des_concepto}</Text>
                  </View>
                  <View style={styles.importe}>
                    <Text>{montoFormat(item.r_mon_importe)}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.conceptoImporte}>
            <View style={styles.wraper}>
              {r_descuentos.map((item, index) => (
                <View key={index} style={styles.conceptoImporteItem}>
                  <View style={styles.concepto}>
                    <Text>{item.r_des_concepto}</Text>
                  </View>
                  <View style={styles.importe}>
                    <Text>{montoFormat(item.r_mon_importe)}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.subHeader4}> TOTAL INGRESOS S/. </Text>
          <Text style={styles.subHeader4}>
            {montoFormat(datos.r_haberes_total)}
          </Text>
          <Text style={styles.subHeader4}>TOTAL DESCUENTOS </Text>
          <Text style={styles.subHeader4LastChild}>
            {montoFormat(datos.r_descuentos_total)}
          </Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.subHeader4}></Text>
          <Text style={styles.subHeader4}></Text>
          <Text style={styles.netoText}>NETO A PAGAR S/. </Text>
          <Text style={styles.netoTextMonto}>
            {montoFormat(datos.r_neto_pagar)}
          </Text>
        </View>
        <View style={styles.tablaHeader}>
          <Text style={styles.header2}>Pago a cargo de</Text>
          <Text style={styles.header2LastChild}>Próxima fecha de pago</Text>
        </View>
        <View style={styles.tablaBody}>
          <Text style={styles.body2}>{datos.r_cargo_pago}</Text>
          <Text style={styles.body2LastChild}>{datos.r_proximo_pago}</Text>
        </View>
        <View style={styles.parrafos}>
          <Text style={styles.parrafo}>
            {" "}
            - Recuerde que, para la incorporación de los beneficiarios hijos,
            que alcancen los 18 años de edad y que sigan estudiando en forma
            ininterrumpida y satisfactoria sus estudios, usted podrá presentar
            la documentación hasta el cumplimiento de la mayoría de edad.
          </Text>
          <Text style={styles.parrafo}>
            {" "}
            - Cumplimos con informarle que, al término de su Renta Temporal,
            procederemos a la cancelación de su Cuenta, motivo por el cual la
            pensión en ese último mes, diferirá del monto que venía percibiendo.
          </Text>
        </View>
        <View style={styles.firmas}>
          <View style={styles.contentFirma}>
            <Image style={styles.firma} src={firma} />
          </View>
          <Text style={styles.contentFirma}></Text>
        </View>
        <View style={styles.contenedorTextoFirma}>
          <Text style={styles.textoFirma}></Text>
          <Text style={styles.textoFirmaLinea}>
            {datos.r_firma_nombre} {datos.r_firma_cargo}
          </Text>
          <Text style={styles.textoFirma}></Text>
          <Text style={styles.textoFirmaLinea}> Recibí Conforme</Text>
          <Text style={styles.textoFirma}></Text>
        </View>
        <View style={styles.piePagina} fixed>
          <Text style={styles.textoJustificado}>
            De haber efectuado un cambio de dirección de su domicilio y/o
            correspondencia, agradeceremos comunicarse con nosotros a los
            teléfonos 230-2200 en Lima ó 0801-14224 en provincias o através de
            nuestro correo a INFORMES@AFPHABITAT.COM.PE donde gustosamente lo
            atenderemos.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Vista;
