import React, { useEffect, useContext } from "react";
import JubilacionContext from "../../../../context/jubilacion/jubilacionContext";
import ModalContext from "../../../../context/modal/modalContext";
import Grid from "@mui/material/Grid";
import Loading from "../../../../components/Loading/index.jsx";
import { ViewComponent } from "./component";
import "./index.css";

export default function JubilacionRejaWorkflow(props) {
  const jubilacionContext = useContext(JubilacionContext);
  const { legalDataLoading, obtenerProcesoLegalData, mensajeAlerta } =
    jubilacionContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const mostrarMensaje = (mensaje) => {
    setMessage(mensaje);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerProcesoLegalData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlerta) mostrarMensaje(mensajeAlerta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  if (mensajeAlerta !== null) return <p></p>;
  if (legalDataLoading) return <Loading />;

  return (
    <Grid container justifyContent="center">
      <ViewComponent props={props} />
    </Grid>
  );
}
