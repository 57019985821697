import React from "react";
import PropTypes from "prop-types";
import { DividerComponent } from "./styles";

const Divider = ({ ...props }) => {
  return <DividerComponent {...props} />;
};

export default Divider;

Divider.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  mode: PropTypes.oneOf(["horizontal", "vertical"]),
  bg: PropTypes.oneOf(["light", "dark"]),
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
};

Divider.defaultProps = {
  size: "sm",
  mode: "horizontal",
  bg: "light",
};
