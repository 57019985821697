import React, { useState, useEffect, Children } from "react";
import { Drawer, Grid, Modal } from "@mui/material";
import { ModalComponent } from "./stylesModalJS";
import { BsX } from "react-icons/bs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    drawerStyle: {
        "& .MuiDrawer-paper": {
            borderRadius: "16px 16px 0px 0px",
        },

    },
    
});

const ModalGeneralWeb = (props) => {
    const classes = useStyles();
    const { openModal, close } = props
    const [open, setOpen] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    const openModalAction = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
        close()
    }

    useEffect(() => {
        if (openModal) openModalAction()
    }, [openModal])

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    return (
        <>
            {(width > 600)
                ? <Modal open={open} onClose={onClose}>
                    <ModalComponent>
                        <div className="close" onClick={onClose}>
                            <BsX />
                        </div>
                        <div >
                            <Grid  justifyContent="center">
                                <Grid item >
                                    {props.children[0]}
                                </Grid>
                            </Grid>
                        </div>
                    </ModalComponent>
                </Modal>
                :
                <Drawer open={open} onClose={onClose} anchor={'bottom'} className={classes.drawerStyle}
                    PaperProps={{
                        sx: {
                            bordeRadius: "16px 16px 0 0",
                            padding:"32px 32px 40px 32px",
                        }
                    }}>
                    <div >
                        <Grid justifyContent="center">
                            <Grid item >
                                {props.children[1]}
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            }
        </>
    );
};

export default ModalGeneralWeb;
