import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthContext from "../../../../context/autenticacion/authContext";

import hocVariables from "../../../../HOC/HocVariables";

import "./index.css";

const BottomMenu = (props) => {
  const {
    optionsArray,
    optionSelected,
    setOptionSelected,
    isDraweOpen,
    setIsDrawerOpen,
    setDrawerOption,
    limpiarVariablesContext,
    mensajeInformativo,
    setOpenModalInvertir,
  } = props;
  const authContext = useContext(AuthContext);
  const { cerrarSesion } = authContext;
  const closeSesion = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(true, props.history.location);
    limpiarVariablesContext();
  };
  return (
    <div className="bottom-menu">
      <div className="bottom-menu__container">
        <nav className="bottom-menu__menu">
          <ul className="bottom-menu__list">
            {optionsArray.map((option) => (
              <BottomNavegationOption
                key={option.title}
                option={option}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
                isDraweOpen={isDraweOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                setOpenModalInvertir={setOpenModalInvertir}
                setDrawerOption={setDrawerOption}
                closeSesion={closeSesion}
                mensajeInformativo={mensajeInformativo}
              />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

const BottomNavegationOption = (props) => {
  const {
    option,
    optionSelected,
    setOptionSelected,
    isDraweOpen,
    setIsDrawerOpen,
    setDrawerOption,
    closeSesion,
    mensajeInformativo,
    setOpenModalInvertir,
  } = props;
  const {
    title,
    image,
    alImage,
    imageSelected,
    url,
    subMenuOptions = [],
  } = option;

  const [isSelected, setIsSelected] = useState(false);
  const [name, setIsSelectedName] = useState("");

  const history = useHistory();
  const location = useLocation();
  const urlView = location.pathname.split("/");
  let urlPath =
    urlView[3] === "informacion"
      ? "Mi cuenta"
      : urlView[3] === "apv" || urlView[3] === "seguimiento"
      ? "Invertir"
      : urlView[3] === "inicio"
      ? "Inicio"
      : "";

  useEffect(() => {
    if (urlPath === title) setIsSelected(true);
    else setIsSelected(false);
  }, [optionSelected]);

  const onSelectOption = () => {
    setIsSelectedName(title);
    if (optionSelected === title) {
      if (subMenuOptions.length === 0) return;
      else {
        setIsDrawerOpen(!isDraweOpen);
      }
    } else {
      setOptionSelected(title);
      setDrawerOption(subMenuOptions);
      if (title === "Inicio" || title === "Invertir") setIsDrawerOpen(false);
      if (title === "Cerrar sesión") closeSesion();
      if (subMenuOptions.length === 0) {
        if (title === "Invertir") {
          if (mensajeInformativo === true) {
            setOpenModalInvertir(true);
          } else {
            history.push(url);
          }
        } else {
          history.push(url);
        }
      } else {
        setIsDrawerOpen(true);
      }
    }
  };
  return (
    <li className="bottom-menu__item">
      <div className="item-content" onClick={onSelectOption}>
        <img
          src={optionSelected === title ? imageSelected : image}
          alt={alImage}
        />
        <span className={optionSelected === title ? "selected" : ""}>
          {title}
        </span>
      </div>
    </li>
  );
};

export default hocVariables(BottomMenu);
