import React, { useState, useEffect, useContext } from "react";
import { FormProvider, set, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import jubilacionContext from "../../../../../../../../context/jubilacion/pmo/jubPmoContext";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";
import Loading from "../../../../../../../../components/Loading/index.jsx";

import {
  DetalleMonto,
  Documentos,
  ResumenExcedente,
  Beneficiarios,
  Validacion,
  MontoRetirar,
} from "./components";

export default function Mensual(props) {
  const {
    beneficiarios,
    contenidoTemaJubilacionData,
    processId,
    getBackOneStep,
    endProcess,
    dependencies,
  } = props;
  const [showView, setShowView] = useState(-1);
  const [selectModeForApv, setselectModeForApv] = useState(null);
  const [amount, setAmount] = useState("");
  const [amountForHabitat, setAmountForHabitat] = useState("");
  const [disclaimer, setDsiclaimer] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [autorization, setAutorization] = useState(false);
  const [fondoHabitat, setFondoHabitat] = useState(-1);
  const [tipoAporteLibre, setTipoAporteLibre] = useState(-1);
  const JuBilacionContextPmo = useContext(jubilacionContext);
  const { decisionValidarPmo, dataDecisionValiarPmo, decisionPmo } =
    JuBilacionContextPmo;
  const history = useHistory();
  const formMethods = useForm({
    mode: "all",
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let dato = {
      pension: "",
      productos: "",
    };
    decisionValidarPmo(dato);
  }, []);

  useEffect(() => {
    if (dataDecisionValiarPmo) {
      if (dataDecisionValiarPmo.r_validacion === 1) {
        setAmount(dataDecisionValiarPmo.data.m_excedente);
        if (dataDecisionValiarPmo.r_tiene_excedente === 0) {
          setShowView(4);
        } else {
          setShowView(4);
        }
      } else {
        setShowModal(true);
      }
    }
  }, [dataDecisionValiarPmo]);

  // console.log(dataDecisionValiarPmo);
  // console.log(contenidoTemaJubilacionData);

  if (dataDecisionValiarPmo == null && contenidoTemaJubilacionData == null)
    return <Loading />;
  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <DetalleMonto
            getBackOneStep={props.getBackOneStep}
            nextView={() => {
              if (contenidoTemaJubilacionData.pmo_vld_docs === 2) {
                setShowView(4);
              } else {
                setShowView(2);
              }
            }}
          />
        );

      case 2:
        return (
          <Documentos
            prevView={() => setShowView(1)}
            nextView={() => {
              if (beneficiarios.length > 0) {
                setShowView(3);
              } else {
                setShowView(6);
              }
            }}
            processId={props.processId}
            contentId={props.contentId}
            beneficiarios={beneficiarios}
            endProcess={props.endProcess}
            modalidad={props.modalidad}
            apvf={props.apvf}
          />
        );
      case 3:
        return (
          <Beneficiarios
            prevView={() => setShowView(2)}
            nextView={() => setShowView(6)}
            processId={props.processId}
            contentId={props.contentId}
            endProcess={props.endProcess}
            beneficiarios={beneficiarios}
            modalidad={props.modalidad}
            apvf={props.apvf}
          />
        );
      case 4:
        return (
          <ResumenExcedente
            prevView={() => getBackOneStep()}
            nextView={() =>
              setShowView(
                contenidoTemaJubilacionData.pmo_vld_docs === 2 ? 5 : 1
              )
            }
            selectModeForApv={selectModeForApv}
            setselectModeForApv={setselectModeForApv}
            amount={amount}
            disclaimer={disclaimer}
            setDsiclaimer={setDsiclaimer}
            autorization={autorization}
            setAutorization={setAutorization}
            amountForHabitat={amountForHabitat}
            setAmountForHabitat={setAmountForHabitat}
            fondoHabitat={fondoHabitat}
            setFondoHabitat={setFondoHabitat}
            dataDecisionValiarPmo={dataDecisionValiarPmo}
            decisionValidarPmo={decisionValidarPmo}
            setTipoAporteLibre={setTipoAporteLibre}
            documentsAreValidated={
              contenidoTemaJubilacionData.pmo_vld_docs === 2
            }
          />
        );
      case 5:
        return (
          <MontoRetirar
            prevView={() => setShowView(4)}
            endProcess={() =>
              history.push(
                "/mi-habitat-digital/afiliado/jubilacion-legal/pmo-fin"
              )
            }
            amountForHabitat={amountForHabitat}
            amount={amount}
            decisionPmo={decisionPmo}
            procedureId={processId}
            fondoHabitat={fondoHabitat}
            tipoAporteLibre={tipoAporteLibre}
            dependencies={dependencies}
            autorization={autorization}
          />
        );
      case 6:
        return <Validacion />;
      default:
        return <Loading />;
    }
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        {renderView()}{" "}
        {showModal && (
          <ModalDeErrorGeneral
            message={
              "No podemos procesar su solicitud en este momento. Contáctate con nosotros mediante Whatssap: 981211004, Contact Center (Lima): 01 230 2200, Contact Center (Provincias): 0801 14 224."
            }
          />
        )}
      </FormProvider>
    </div>
  );
}
