import React from "react";
import { Grid, Box, Chip, Typography, Paper, Card } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CoinsIcon from "../../../assets/IconBucket/Icon/CoinsIcon";
import SavingIcon from "../../../assets/IconBucket/Icon/SavingIcon";
const Index = ({ products, validacionDatos }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          border: "1px solid #EDEDED",
          borderRadius: "16px",
          p: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {products.map((item, index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom:
                      products.length > 1 &&
                      products.length - 1 !== index &&
                      "16px",
                  }}
                >
                  <CoinsIcon size={22} color="black" />

                  <Typography
                    color="textColor.main"
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      marginLeft: "8px",
                    }}
                  >
                    {item.r_tipo_aporte === "OBL"
                      ? "Fondo de Jubilación"
                      : "Fondo con Fin Previsional"}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              fontSize: "10px",
              borderRadius: "16px",
              maxHeight: "185px",
              p: "16px",
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItem: "center",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#CDCED0",
                    width: "40px",
                  }}
                >
                  <ArrowForwardIcon />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Chip
                    label={
                      <Typography sx={{ fontSize: "10px" }}>
                        FONDO DESTINO
                      </Typography>
                    }
                    size="small"
                    sx={{
                      p: "4px 10px",
                      backgroundColor: "#CE1336",
                      color: "white",
                    }}
                  />
                  <Typography sx={{ fontSize: "1.2em" }}>
                    &nbsp; Fondo {validacionDatos.tipo_etario === 60 ? 1 : 0}
                  </Typography>
                  <Typography sx={{ fontSize: "16px" }}>
                    &nbsp; Fondo{" "}
                    {validacionDatos.tipo_etario === 60
                      ? "Conservador"
                      : "Protegido"}
                  </Typography>
                </Box>
              </>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
