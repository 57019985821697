
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Container } from 'reactstrap'
import { BoxDesktop, BoxMobile } from '../../../componentes/data/components/BreadcrumbsFL';
import { CardSimple } from '../CardSimple';


const styleDetail = {
    marginBottom: '0px',
    color: '#6E7378',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    paddingRight: '16px'
};

const styleTitle = {
    color: '#2B2B2B',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    fontWeight: '400',
    margin: 'auto !important'
};

export const CardDouble = ({ item1, item2, imagen }) => {
    return (
        <>
            <BoxDesktop>
                <Box sx={{
                    height: 'auto',
                    marginBottom: '16px',
                }}>
                    <Container>
                        <Grid container columns={16} sx={{
                            background: ' #F2FAFF',
                            border: '1px solid #B7E3FF',
                            borderRadius: '16px',
                            padding: '8px',
                        }}>
                            <Grid item xs={2} sx={{
                                margin: 'auto',
                            }}>
                                <Container className='container_mobile_icon'>
                                    <img src={imagen} alt="mobile_icon"/>
                                </Container>
                            </Grid>
                            <Grid item xs={item2.detail ? 7 : 14} sx={{
                                margin: 'auto',
                            }}>
                                <Typography sx={styleTitle} gutterBottom component="p">{item1.title}</Typography>

                            </Grid>
                            {
                                item2.detail &&

                                <Grid item xs={7} sx={{
                                    margin: 'auto',
                                }}>
                                    <Typography sx={styleTitle} gutterBottom component="p">{item2.title}</Typography>

                                </Grid>
                            }
                            <Grid item xs={2} sx={{
                            }}></Grid>
                            <Grid item xs={item2.detail ? 7 : 14} sx={{
                                margin: 'auto',
                            }}>

                                <Typography sx={styleDetail} gutterBottom component="p">{item1.detail}</Typography>
                            </Grid>

                            {
                                item2.detail &&
                                <Grid item xs={7} sx={{
                                    margin: 'auto',
                                }}>

                                    <Typography sx={styleDetail} gutterBottom component="p">{item2.detail}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Container>
                </Box>
            </BoxDesktop>
            <BoxMobile style={{
                padding: '0',
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <CardSimple
                            title={item1.title}
                            colorBackground='#F2FAFF'
                            colorBorder='#B7E3FF'
                            detail={item1.detail}
                            imagen={imagen}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CardSimple
                            title={item2.title}
                            colorBackground='#F2FAFF'
                            colorBorder='#B7E3FF'
                            detail={item2.detail}
                            imagen={imagen}
                        />
                    </Grid>
                </Grid>


            </BoxMobile>
        </>
    )
}
