import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/custromModal/CustomModal";
import "./index.css";
import { Button, FormGroup } from "reactstrap";

const LoginForm = (props) => {
  const { sendRequest, loading, error, dataBlock } = props;
  const { register, handleSubmit, errors } = useForm();
  const [captcha, setCaptcha] = useState(true); //null
  const [modalBlock, setModalBlock] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [inputNumDoc, guardarinputNumDoc] = useState({
    lenghtNdoc: 8,
    pattern: "[0-9]*",
  });

  const recaptchaRef = useRef(null);
  useEffect(() => {
    if (dataBlock) {
      if (dataBlock.r_block === 5) {
        setModalBlock(!modalBlock);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBlock]);

  const listOptions = [
    {
      value: "00",
      text: "D.N.I",
      maxLength: 8,
      pattern: "[0-9]*",
    },
    {
      value: "01",
      text: "Carnet de Extranjería",
      maxLength: 9,
      pattern: "[0-9]*",
    },
    {
      value: "03",
      text: "Lib. Adolesc. Trab.",
      maxLength: 12,
      pattern: "[0-9]*",
    },
    { value: "04", text: "Pasaporte", maxLength: 15, pattern: "[a-zA-Z0-9 ]+" },
  ];

  const onSubmit = async (data) => {
    localStorage.setItem("doc_type", data.documentType);
    localStorage.setItem("doc_num", data.documentNumber);

    if (loginError) {
      data = { ...data, firstAttempt: false };
    }
    // recaptchaRef.current?.reset()
    try {
      // const captcha2 = await recaptchaRef?.current?.execute()
      // console.log('no hay captcha',captcha2,data)
      // console.log('no hay captcha',captcha2.toString())
      // data.captcha = captcha2

      let response = await sendRequest(data, captcha);
      if (!response) {
        setLoginError(true);
      } else {
        setLoginError(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const history = useHistory();
  const handleDocumentType = (e) => {
    let item = listOptions.find((option) => option.value === e.target.value);
    guardarinputNumDoc({
      ...inputNumDoc,
      lenghtNdoc: item.maxLength,
      pattern: item.pattern,
    });
  };

  const respuestaCaptcha = (response) => {
    // console.log(response,'RP');
    setCaptcha(response);
  };

  useEffect(() => {
    console.log("captcha", captcha);
    if (loginError) {
      console.log("FALSE");
      setLoginError(false);
    }
  }, [captcha]);

  return (
    <div className="privatezone-login margin-center">
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="new-home-form-group">
          <label htmlFor="document-type">Tipo de documento:</label>
          <select
            className="form-field"
            id="document-type"
            name="documentType"
            tabIndex={"-1"}
            {...register("documentType", { required: true })}
            onChange={(e) => handleDocumentType(e)}
          >
            {listOptions.map((item, index) => (
              <option key={index} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          {errors?.documentType && (
            <span className="error-new-home">Campo requerido*</span>
          )}
        </div>

        <div className="new-home-form-group">
          <label htmlFor="document-number">Número de documento:</label>
          <input
            className="form-field"
            id="document-number"
            type="text"
            autoComplete="off"
            pattern={inputNumDoc.pattern}
            maxLength={inputNumDoc.lenghtNdoc}
            onKeyPress={(event) => {
              if (inputNumDoc.lenghtNdoc !== 15) {
                let regex = new RegExp("^[0-9]+$");
                let key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                  return false;
                }
              }
            }}
            {...register("documentNumber", {
              required: true,
              maxLength: inputNumDoc.lenghtNdoc,
            })}
          />
          {errors?.documentNumber &&
            errors?.documentNumber.type === "required" && (
              <span className="error-new-home">Campo requerido*</span>
            )}

          {errors?.documentNumber &&
            errors?.documentNumber.type === "maxLength" && (
              <span className="error-new-home">
                Campo de {inputNumDoc.lenghtNdoc} caracteres*
              </span>
            )}
        </div>
        <div className="new-home-form-group">
          <label htmlFor="password">Clave web:</label>
          <input
            className="form-field"
            id="password"
            maxLength={4}
            type="password"
            pattern="[0-9]*"
            {...register("password", {
              required: true,
              minLength: 4,
              maxLength: 4,
            })}
          />
          {errors?.password && errors?.password.type === "required" && (
            <span className="error-new-home">Campo requerido*</span>
          )}

          {errors?.password &&
            (errors?.password.type === "minLength" ||
              errors?.password.type === "maxLength") && (
              <p style={{ fontSize: "14px" }} className="text-danger m-0">
                campo de 4 caracteres
              </p>
            )}
        </div>
        {dataBlock && dataBlock.r_block === 4 && (
          <div className="error-block">
            <p>Te queda solo un intento permitido</p>
          </div>
        )}

        <div className="login-options">
          {/* <a href="https://www.afphabitat.com.pe/recuperar-clave/">
            Genera o recupera tu clave web
          </a> */}
          <Link to="/genera-clave-web" className="link-letter">
            ¿Olvidaste tu clave web? <span>Genérala aquí</span>
          </Link>
        </div>

        <div className="mt-3 captcha-container">
          <ReCAPTCHA
            sitekey="6LfH3XkmAAAAAAZ0C6jfmlhKRjVuO1VFvKt9-pNA"
            onChange={respuestaCaptcha}
            ref={recaptchaRef}
            badge={"bottomleft"}
            size={"normal"}
          />
        </div>

        <button
          type="submit"
          className="mt-3 btn-login margin-center"
          disabled={!captcha || loading}
        >
          Ingresar{" "}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        {/* <div className="unlock-account">
          <Link to="/mi-habitat-digital/cuenta-bloqueada">
            ¿Cuenta bloqueada? <span>Desbloquéala ahora</span>
          </Link>
        </div> */}
      </form>

      {modalBlock && (
        <CustomModal
          showModal={modalBlock}
          className="beauty-modal"
          backdrop={"static"}
          fnCloseIconBtn={() => {
            setModalBlock(!modalBlock);
          }}
          title="Cuenta bloqueada"
          description={
            <p>
              Tu cuenta ha sido bloqueada porque superaste el número de intentos
              permitidos, para desbloquearla haz click aquí.
            </p>
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() => {
            history.push("/mi-habitat-digital/cuenta-bloqueada");
          }}
          textBtnPrimary="Desbloquear cuenta"
          hasBtnSecondary={false}
        />
      )}
    </div>
  );
};

export default LoginForm;
