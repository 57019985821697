import React, { useState, useEffect, useContext } from "react";
import { ValidacionAfiliados } from "./components";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso2(props) {
  const [relationships, setRelationships] = useState([]);
  const [dependencies, setDependencies] = useState({ gender: "" });
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    relationshipsData,
    relationshipsLoading,
    obtenerParentescosRejaData,
    limpiarObtenerParentescosRejaData,

    temasAsesoriaRejaLoading,
    temasAsesoriaRejaData,
    obtenerContenidoPasoReja,
    limpiarObtenerContenidoPasoReja,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerParentescosRejaData();
    obtenerContenidoPasoReja(props.processId, 2);
    return () => {
      limpiarObtenerParentescosRejaData();
      limpiarObtenerContenidoPasoReja();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (relationshipsData) setRelationships(relationshipsData.data);
  }, [relationshipsData]);

  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        props.setCurrectStepTitle(temasAsesoriaRejaData.name);
        setDependencies(temasAsesoriaRejaData.dependencies);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  if (mensajeAlerta !== null) return <p></p>;
  if (relationshipsLoading || temasAsesoriaRejaLoading) return <Loading />;
  return (
    <div>
      <ValidacionAfiliados
        processId={props.processId}
        initialData={{
          pension_orfandad_viudez: null,
          bono_reconocimiento: null,
          beneficiarios: [],
        }}
        dependencies={dependencies}
        relationships={relationships}
        endSecondStep={() => props.setCurrectStepNumber(3)}
        getOutOfProcess={() => props.getOutOfProcess()}
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
