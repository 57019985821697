import React, { useEffect, useState } from "react";
import {
  ContentAmount,
  InfoText,
  HeaderAccordion,
  HeadingTextAccordion,
  ContentTable,
  ContentFondos,
  ContentSelect,
  GridContainer,
} from "../Etapas/Calculo/styles";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import * as Input from "../../../../../../components/Input";
import { useFormContext } from "react-hook-form";
import InfoCircle from "../../../../../../assets/IconBucket/info_circle";
import ICON_FONDOJUB from "../../../../../../assets/IconBucket/ICON_FONDOJUB";
import ICON_FONDOLIBRE from "../../../../../../assets/IconBucket/ICON_FONDOLIBRE";
import ICON_DELETE from "../../../../../../assets/IconBucket/ICON_DELETE";
import Icon from "../../../../Components/IconSVG";
import Button from "../../../../../../components/Buttons";
import Card from "../../../../Components/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ModalBeneficiarios, ModalDeclaracionJurada } from "../Modals";
import Divider from "../../../../../../components/Divider";
import "./index.css";
import { montoFormat } from "../../../../../../helper/amountFormat";

const Dropdown = ({
  handleChange,
  setExpanded,
  fondos,
  amounPension,
  setBeneficiairos,
  setArrayBeneficiarios,
  arrayBeneficiarios,
  handleSetBeneficiairos,
  cantAaños,
  objComplete,
  handleDeleteBeneficiairos,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenModalDeclaracionJurada, setOpenModalDeclaracionJurada] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const { getValues, setValue, trigger, clearErrors } = useFormContext();
  const [amount, setAmount] = useState(null);
  const [complete, setComplete] = useState(objComplete);
  const [isOpenModalBeneficiarios, setIsOpenModalBeneficiarios] =
    useState(null);

  useEffect(() => {
    setValue("calculo.fondoJub", ["true"]);
  }, []);

  const handleAmount = (e) => {
    const value = e.target.value;
    const numberFormatted = value.replace("S/ ", "").replaceAll(",", "");
    setAmount(numberFormatted);
    setValue("calculo.amount", numberFormatted);
    trigger("calculo.amount");
  };

  const handleFirstNext = () => {
    complete.item1 = true;
    complete.open2 = true;
    setComplete(complete);
    setSelectedItem(1);
  };
  const handleSecondNext = () => {
    // setValue("calculo.fondoJub", true);
    complete.item2 = true;
    complete.item3 = true;
    complete.open3 = true;
    setComplete(complete);
    setSelectedItem(2);
    setExpanded("panel3");
    // trigger("calculo.fondoJub");
  };

  const validateBtnNext = () => {
    if (getValues("typePension")) {
      if (getValues("typePension") === "base") {
        return false;
      }
      if (
        getValues("calculo.amount") &&
        Number(getValues("calculo.amount")) >= 1 &&
        Number(getValues("calculo.amount")) > amounPension
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  // console.log({ arrayBeneficiarios });

  const validateBtnSecondNext = () => {
    if (getValues("calculo.beneficiarios")) {
      return false;
    }

    if (arrayBeneficiarios.length === 0) {
      return true;
    }

    return false;
  };
  const handleAgregarBeneficiarios = (obj) => {
    arrayBeneficiarios.push(obj);
    setArrayBeneficiarios(arrayBeneficiarios);
  };

  const isCloseToTotal = (e, total) => {
    if (isNaN(e) || e > total) {
      return true;
    }

    return `El monto debe ser mayor a S/ ${amounPension}`;
  };

  const disabledInput = getValues("typePension") !== "otros";
  const handleClearBeneficiarios = () => {
    setBeneficiairos([]);
    setArrayBeneficiarios([]);
  };

  const handleDeleteBeneficiarios = (dato) => {
    let filterArrayBeneficiario = arrayBeneficiarios.filter(
      (item, index) => Number(index) !== Number(dato)
    );
    setArrayBeneficiarios(filterArrayBeneficiario);
    handleDeleteBeneficiairos(dato);
  };
  const getOBL = fondos.filter((item) => item.r_cuenta === "OBL");
  const getVCF = fondos.filter((item) => item.r_cuenta === "VCF");
  const getVSF = fondos.filter((item) => item.r_cuenta === "VSF");

  return (
    <div className="HBT_ACCORDIONCUSTOM_dropdown">
      <div className="HBT_ACCORDIONCUSTOM_dropdown-header">
        <HeaderAccordion>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="HBT_PMO_AccordionHeader_number">1</div>
            <div> Establece tu pensión menual</div>
          </div>

          <div>{complete.item1 && <ArrowForwardIosRoundedIcon />}</div>
        </HeaderAccordion>
        <Divider />
        <HeaderAccordion disabled={!complete.open2 ? "disabled" : ""}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="HBT_PMO_AccordionHeader_number">2</div>
            <div>Declara a tus beneficiarios</div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{complete.item2 && <ArrowForwardIosRoundedIcon />}</div>
          </div>
        </HeaderAccordion>
        <Divider />
        <HeaderAccordion disabled={!complete.open3 ? "disabled" : ""}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="HBT_PMO_AccordionHeader_number">3</div>
            <div>Confirma tus fondos</div>
          </div>

          <div>
            <div>{complete.item3 && <ArrowForwardIosRoundedIcon />}</div>
          </div>
        </HeaderAccordion>
        <Divider />
      </div>

      <div className={`HBT_ACCORDIONCUSTOM_dropdown-body ${isOpen && "open"}`}>
        {selectedItem === 0 ? (
          <ContentSelect>
            <HeadingTextAccordion>
              Este será el monto que buscas recibir mes a mes al jubilarte.
            </HeadingTextAccordion>
            <GridContainer>
              <Input.RadioCard
                name="typePension"
                value="base"
                indicatorSide="right"
                onClick={() => {
                  clearErrors("calculo.amount");
                  setValue("calculo.amount", "");
                  setAmount(null);
                }}
              >
                <ContentAmount>
                  <div className="HBT_PM_Funds">
                    <h2>Monto mínimo de pensión</h2>
                    <h3>S/ {Number(amounPension).toFixed(2)} por mes</h3>
                    <InfoText>
                      <Icon
                        width="24px"
                        height="24px"
                        componentIcon={<InfoCircle color="#ACAFB3" />}
                      />
                      <p>
                        Equivale al costo de la Canasta Básica de Consumo actual
                        según INEI; puede variar en el tiempo.
                      </p>
                    </InfoText>
                  </div>
                </ContentAmount>
              </Input.RadioCard>

              <Input.RadioCard
                name="typePension"
                value="otros"
                indicatorSide="right"
                onClick={() => {
                  setValue("typePension", "otros");
                }}
              >
                <ContentAmount>
                  <h2>Otro monto</h2>

                  <Input.Wrapper>
                    <Input.Title required={false} disabled={disabledInput}>
                      Monto de pensión
                    </Input.Title>
                    <Input.Money
                      disabled={disabledInput}
                      // onKeyDown={(e = console.log({ e, key: e.key }))}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          console.log("Enter");
                          e.preventDefault();
                        }
                      }}
                      onChange={handleAmount}
                      value={amount}
                      name="calculo.amount"
                      validate={{
                        greaterThanTotal: (e) =>
                          isCloseToTotal(e, amounPension),
                      }}
                    />
                    <Input.ErrorMessage name="calculo.amount" />
                  </Input.Wrapper>
                </ContentAmount>
              </Input.RadioCard>
            </GridContainer>

            <Button
              fullWidth
              onClick={handleFirstNext}
              disabled={validateBtnNext()}
              style={{ marginLeft: "auto" }}
            >
              Siguiente
            </Button>
          </ContentSelect>
        ) : selectedItem === 1 ? (
          <ContentSelect>
            <HeadingTextAccordion>
              Se considera beneficiario al cónyuge o concubino/a, así como a
              aquellos familiares que dependen económicamente de ti.
            </HeadingTextAccordion>
            <ContentTable>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parentesco</TableCell>
                    <TableCell align="right">Edad</TableCell>
                    <TableCell align="right">Sexo</TableCell>
                    <TableCell align="right">Condición</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrayBeneficiarios.length > 0 &&
                    arrayBeneficiarios.map((item, index) => {
                      return (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {item.parentesco}
                          </TableCell>
                          <TableCell align="right">
                            {item.fechaNacimiento}
                          </TableCell>
                          <TableCell align="right">{item.sexo}</TableCell>
                          <TableCell align="right">
                            <div className="HBT_PMO_BtnDelete">
                              <span>
                                {item.discapacidad === "si"
                                  ? "Con discapacidad"
                                  : "Sin discapacidad"}
                              </span>

                              <div
                                className="HBT_PMO_BtnDeleteIcon"
                                onClick={() => handleDeleteBeneficiarios(index)}
                              >
                                <ICON_DELETE />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TableCell align="right" colSpan={4}>
                      <a onClick={() => setIsOpenModalBeneficiarios(true)}>
                        {" "}
                        + Agregar beneficiario
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </ContentTable>

            <Input.Wrapper
              style={{
                gridColumn: "1/-1",
                border: "1px solid #CDCED0",
                padding: "8px 16px 0",
                borderRadius: "16px",
              }}
            >
              <Input.Checkbox
                name="calculo.beneficiarios"
                value={true}
                required={true}
                onClick={() => handleClearBeneficiarios()}
              >
                Declaro no tener beneficiarios
              </Input.Checkbox>
              <Input.ErrorMessage name="calculo.beneficiarios" />
            </Input.Wrapper>
            <InfoText variant="default">
              <Icon
                width="24px"
                height="24px"
                componentIcon={<InfoCircle color="#363A3E" />}
              />
              <p>
                Esta información tiene carácter de{" "}
                <a onClick={() => setOpenModalDeclaracionJurada(true)}>
                  declaración jurada
                </a>{" "}
              </p>
            </InfoText>
            <Button
              fullWidth
              disabled={validateBtnSecondNext()}
              style={{ marginLeft: "auto" }}
              onClick={() => handleSecondNext()}
            >
              Siguiente
            </Button>
          </ContentSelect>
        ) : (
          <ContentSelect>
            <HeadingTextAccordion>
              Selecciona los productos que deseas considerar para calcular tu
              Pensión Objetivo.
            </HeadingTextAccordion>
            {getOBL.length > 0 && (
              <Card border={getValues("calculo.fondoJub") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOJUB />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo de Jubilación</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getOBL[0].r_saldo)} </h3>
                    </div>
                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.fondoJub"
                          value={"true"}
                          required={false}
                          // disabled
                          style={{ pointerEvents: "none" }}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
            {getVCF.length > 0 && (
              <Card border={getValues("calculo.conFin") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOJUB />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo con Fin Previsional</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getVCF[0].r_saldo)} </h3>
                    </div>
                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.conFin"
                          value={"true"}
                          required={false}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
            {getVSF.length > 0 && (
              <Card border={getValues("calculo.fondoLibre") ? "primary" : ""}>
                <ContentFondos>
                  <div className="HBT_PMO_ContentIconFondos">
                    <Icon componentIcon={<ICON_FONDOLIBRE />} />
                  </div>
                  <div className="HBT_PMO_ContentTextFondos">
                    <div>
                      <h2>Fondo Libre Habitat</h2>
                      <p>Al día de hoy tienes:</p>
                      <h3>S/ {montoFormat(getVSF[0].r_saldo)} </h3>
                    </div>

                    <div>
                      <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                        <Input.Checkbox
                          name="calculo.fondoLibre"
                          value={"true"}
                          required={false}
                        >
                          {""}
                        </Input.Checkbox>
                      </Input.Wrapper>
                    </div>
                  </div>
                </ContentFondos>
              </Card>
            )}
          </ContentSelect>
        )}
      </div>
      <ModalBeneficiarios
        isOpen={isOpenModalBeneficiarios}
        setIsOpen={setIsOpenModalBeneficiarios}
        handleAgregarBeneficiarios={handleAgregarBeneficiarios}
        handleSetBeneficiairos={handleSetBeneficiairos}
        cantAaños={cantAaños}
        arrayBeneficiarios={arrayBeneficiarios}
      />
      <ModalDeclaracionJurada
        isOpen={isOpenModalDeclaracionJurada}
        setIsOpen={setOpenModalDeclaracionJurada}
      />
    </div>
  );
};

export default Dropdown;
