import React from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const LabelText = styled.label`
  ${(props) => props.theme.tipography.body1}
  margin: ${(props) => props.theme.spacing(0, 0, 2)}
`;

const FormSelect = styled(Select)`
  && .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    padding: ${(props) => props.theme.spacing(1, 4, 1, 2)};
  }
`;
const SelectForm = (props) => {
  const { textLabel, value, handleChange, options, firstValueOption } = props;
  return (
    <FormControl sx={{ minWidth: 120, width: "100%" }}>
      <LabelText>{textLabel}</LabelText>
      <FormSelect
        displayEmpty
        value={value}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="">
          <em>{firstValueOption}</em>
        </MenuItem>
        {options.map((item) => (
          <MenuItem value={item.value}>{item.description}</MenuItem>
        ))}
      </FormSelect>
    </FormControl>
  );
};

export default SelectForm;
SelectForm.defaultProps = {
  textLabel: "",
  value: "",
  handleChange: "",
  options: [],
  firstValueOption: "",
};
