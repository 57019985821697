import React from 'react'

const GraficTwoDisableIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7584 13.1384L24.9186 27.4973C29.2941 22.0818 35.9889 18.6178 43.4968 18.6178V0C31.4063 0 20.4967 5.04416 12.7584 13.1384Z"
        fill="#CDCED0"
      />
      <path
        d="M0.996704 42.4581H19.6329C19.6329 36.7912 21.6135 31.5884 24.9183 27.4978L12.7581 13.1389C5.47087 20.7613 0.996704 31.0875 0.996704 42.4581Z"
        fill="#CDCED0"
      />
      <path
        d="M43.4967 0V18.6178C50.9678 18.6178 57.6375 22.0489 62.0129 27.4199L74.1673 13.0688C66.4348 5.01515 55.5524 0 43.4967 0Z"
        fill="#EDEDED"
      />
      <path
        d="M85.9959 42.4572C85.9959 31.0517 81.4927 20.6965 74.1667 13.0684L62.0123 27.4195C65.3539 31.5217 67.3596 36.7554 67.3596 42.4572H85.9959Z"
        fill="#EDEDED"
      />
      <path
        d="M45.8804 38.0633L44.587 35.4688C44.1026 34.4975 42.7043 34.5219 42.2597 35.5103L40.92 38.4873C40.606 38.6536 40.3026 38.8461 40.0116 39.0705C37.2322 41.2139 36.7252 45.2042 38.8795 47.9809C41.0338 50.7576 45.0352 51.2712 47.8146 49.1278C50.5941 46.9844 51.101 42.9941 48.9467 40.2174C48.1325 39.1679 47.056 38.4429 45.8807 38.0606L45.8804 38.0633Z"
        fill="#EDEDED"
      />
    </svg>
  );
};

export default GraficTwoDisableIcon;