import React from "react";
import Slider from "react-slick";
import { Button } from "../../../../../../../../components";
import { dataEstimation } from "../../../../../../../../utils/Functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Slide(props) {
  const { data, setDetail, showDetail, loading } = props;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: Object.keys(data).length > 3,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const renderCard = (id, name, text, amount, balance) => {
    return (
      <div key={id}>
        <div className="carousel-container">
          <div className="card-withdrawal">
            <div className="text-content">
              <span className="percent">{name}</span>

              {id === "0" ? (
                <p>{text}</p>
              ) : id === "955" ? (
                <p>
                  {text} <br />
                  S/ {amount}
                </p>
              ) : (
                <p
                  className={`${text === "Monto faltante" ? "faltante" : null}`}
                >
                  {text} <br /> S/ {balance.replace("-", "")}
                </p>
              )}
            </div>

            <Button
              className="btn-modal-secondary"
              name="secondary"
              bgColor="white"
              onClick={() => {
                setDetail({ id: id });
                showDetail();
              }}
              disabled={loading}
            >
              {id === "pm" ? "Ver detalle" : "Ver pensiones"}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const resultDataSlider = dataEstimation(data);
  return (
    <div className="mb-5 mt-2">
      <Slider {...settings}>
        {resultDataSlider.map((item, index) => {
          return renderCard(
            item.id,
            item.name,
            item.text,
            item.amount,
            item.balance
          );
        })}
      </Slider>
    </div>
  );
}
