import React from "react";
import PropTypes from "prop-types";
import { CardContent } from "../../styles/CardContent";
const CardMol = ({ children, ...props }) => {
  return <CardContent>{children}</CardContent>;
};

export default CardMol;

CardMol.propTypes = {
  children: PropTypes.node,
};

CardMol.defaultProps = {
  width: "24px",
  height: "24px",
  type: "square",
  padding: "8px",
};
