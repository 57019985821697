import React, { useContext, createContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import * as actionsCreators from "./actionsCreators";
import { useZonaDeBeneficiariosContext } from "../../../container/Layout/BeneficiaryZone/context";
const GastoDeSepelioContext = createContext();

export const useGastoDeSepelioContext = () => {
  return useContext(GastoDeSepelioContext);
};

export const GastoDeSepelioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setGeneralError } = useZonaDeBeneficiariosContext();

  return (
    <GastoDeSepelioContext.Provider
      value={{
        state,
        dispatch,
        getAffiliateInfo: actionsCreators.getAffiliateInfo(
          dispatch,
          setGeneralError
        ),
        resetAffiliateInfo: actionsCreators.resetAffiliateInfo(
          dispatch,
          setGeneralError
        ),
        getVerificationEmailToken: actionsCreators.getVerificationEmailToken(
          dispatch,
          setGeneralError
        ),
        validateEmailToken: actionsCreators.validateEmailToken(
          dispatch,
          setGeneralError
        ),
        sendRequest: actionsCreators.sendRequest(dispatch, setGeneralError),
      }}
    >
      {children}
    </GastoDeSepelioContext.Provider>
  );
};
