import styled, { css } from "styled-components";
import { Box } from "@mui/material";

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
    color: ${(p) => p.theme.palette.state.error};
  }
`;

export const Title = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  ${(p) => p.theme.tipography.h5}
  margin: 16px auto;
  width: fit-content;
  text-align: center;
`;

export const ModalContent = styled.div``;
export const ModalFooter = styled.div``;

export const ContentCheckValidacion = styled(Box)`
  & .MuiFormGroup-root {
    flex-direction: row;
    justify-content: center;
  }

  & .field {
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    border-radius: 16px;
    padding: 8px 16px;
  }
  & .field .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6e7378;
  }

  & .field .data {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #363a3e;
  }

  & .errorDate {
    ${(p) => p.theme.tipography.caption2}
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

//  Modal beneficiarios
export const ContentDescription = styled(Box)`
  margin-bottom: 8px;
`;
export const ContentModalBeneficiarioDescription = styled(Box)`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.palette.lead[100]};
  border-radius: 16px;
  padding: 16px;
`;

export const ContentExpression = styled(Box)`
  margin-top: 8px;
  text-align: center;
`;

export const ContentTerms = styled(Box)`
  text-align: center;
  height: 298px;
  overflow: scroll;
  & p {
    margin-bottom: 8px;
  }
`;

export const ContentTextTerms = styled(Box)`
  text-align: left;
  background-color: ${(p) => p.theme.palette.lead[100]};
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  ${(p) => p.theme.tipography.bodySmallRegular}

  padding: 16px;

  & .HBT_PMO_TermsAcogimeinto {
    font-weight: 600;
    margin-right: 8px;
  }
`;

export const CustomErrorBeneficiaryModal = styled.div`
  /* position: absolute; */
  top: 100%;
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  overflow: hidden;
  color: ${(p) => p.theme.palette.state.error};
  width: 100%;
  /* display: grid; */

  grid-column: 1/-1;
  ${(p) =>
    p.absolute === false &&
    css`
      position: relative;
    `}

  span {
    ${(p) => p.theme.tipography.caption}
  }
`;
