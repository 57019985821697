import React, { useContext, useState } from "react";
import styled from "styled-components";

import {
  TextAll,
  ContentFlex,
  FlexItem,
} from "../../../../FondoLibre/componentes";
import IC_IMAGE from "../../../../../assets/icons/onboarding_icon1.svg";
import IC_RETIRA_QUIERAS from "./../../../../../assets/icons/img_retira_quieras.png";
import IC_MONTO_MINIMO from "./../../../../../assets/icons/img_monto_minimo.png";
import IC_MAYOR_RENTABILIDAD from "./../../../../../assets/icons/img_mayor_rentabilidad.png";
import BottomButtonsInline from "../../../../FondoLibre/componentes/BottomButtons/index";
import {
  BoxDesktop,
  BoxMobile,
} from "../../../../PagosDesembolsos/componentes/data/components/BreadcrumbsFL";
const DivContainer = styled("div")`
  height: ${(props) => (props.isMobile ? "auto" : "auto")};
  position: ${(props) => (props.isMobile ? "initial" : "relative")};
  padding: ${(props) => (props.isMobile ? "16px 0px 0px" : "48px")};
  width: ${(props) => (props.isMobile ? "initial" : "100%")};
`;
export const Saludo = ({
  onClickNext,
  isMobile,
  windowWidth,
  windowHeight,
  paddingTopHeader,
  setShowOnboard,
  showOnboard,
}) => {
  const { r_primer_nombre, r_primer_apellido } = JSON.parse(
    localStorage.getItem("client")
  );

  return (
    <DivContainer isMobile={isMobile}>
      <TextAll
        fontSize="20px"
        lineHeight="28px"
        fontWeight="400"
        padding="8px 16px 16px 16px"
        textAlign="center"
      >
        Hola,{" "}
        {r_primer_nombre?.charAt(0)?.toUpperCase() +
          r_primer_nombre?.slice(1).toLowerCase()}
        :
      </TextAll>

      <TextAll
        fontSize="18px"
        lineHeight="24px"
        fontWeight="400"
        textAlign={"center"}
      >
        ¿Qué es el Fondo Libre Habitat?
      </TextAll>

      <div className="d-block w-100 text-center">
        <img
          className="img-fluid"
          src={IC_IMAGE}
          style={{
            padding: "8px 8px",
          }}
        />
      </div>

      <TextAll
        fontSize="14px"
        lineHeight="20px"
        fontWeight="400"
        textAlign={"center"}
        color={"#6E7378"}
        padding={isMobile ? "16px 16px 16px 16px" : "16px 3rem"}
      >
        Es un producto que te permite invertir en los distintos tipos de fondo
        de la AFP con el fin de hacer crecer tu inversión. Es de libre
        disposición e independiente de tu fondo de jubilación.
      </TextAll>

      <TextAll
        fontSize="20px"
        lineHeight="28px"
        fontWeight="400"
        textAlign={"center"}
      >
        Beneficios
      </TextAll>

      <ContentFlex
        color="transparent"
        padding={isMobile ? "16px 6px" : "8px 6rem 16px 6rem"}
      >
        <FlexItem flexDirection={"column"} justifyContent={"center"}>
          <img
            src={IC_RETIRA_QUIERAS}
            height={"60px"}
            width={"60px"}
            style={{ margin: "auto" }}
          />
          <TextAll
            fontSize="13px"
            lineHeight="20px"
            fontWeight="400"
            textAlign={"center"}
            padding="8px 5px"
          >
            Retira cuando quieras
          </TextAll>
        </FlexItem>
        <FlexItem flexDirection={"column"} justifyContent={"center"}>
          <img
            src={IC_MONTO_MINIMO}
            height={"60px"}
            width={"60px"}
            style={{ margin: "auto" }}
          />
          <TextAll
            fontSize="13px"
            lineHeight="20px"
            fontWeight="400"
            textAlign={"center"}
            padding="8px 5px"
          >
            Invierte sin monto mínimo
          </TextAll>
        </FlexItem>
        <FlexItem flexDirection={"column"} justifyContent={"center"}>
          <img
            src={IC_MAYOR_RENTABILIDAD}
            height={"60px"}
            width={"60px"}
            style={{ margin: "auto" }}
          />
          <TextAll
            fontSize="13px"
            lineHeight="20px"
            fontWeight="400"
            textAlign={"center"}
            padding="8px 4px"
          >
            Ahorra con mayor rentabilidad
          </TextAll>
        </FlexItem>
      </ContentFlex>

      <BoxDesktop>
        <BottomButtonsInline
          active={true}
          activeSecond={true}
          padding={"8px 5rem 8px 5rem"}
          positiontop={"auto"}
          position={"relative"}
          flexDirection={"row-reverse"}
          firstPadding={"0px 16px"}
          secondPadding={"0px 16px"}
          firstText="Quiero saber más"
          secondText="Invertir"
          isMobile={isMobile}
          firstOnClick={onClickNext}
          secondOnClick={() => setShowOnboard(!showOnboard)}
        />
      </BoxDesktop>

      <BoxMobile>
        <BottomButtonsInline
          active={true}
          activeSecond={true}
          isMobile={isMobile}
          padding={isMobile ? "8px 0px" : "8px 5rem 8px 5rem"}
          positiontop={isMobile ? "calc(100% - 130px)" : "auto"}
          flexDirection={isMobile ? "column" : "row-reverse"}
          firstPadding={isMobile ? "0px 16px" : "0px 16px"}
          secondPadding={isMobile ? "0px 16px 16px 16px" : "0px 16px"}
          firstText="Quiero saber más"
          secondText="Invertir"
          firstOnClick={onClickNext}
          secondOnClick={() => setShowOnboard(!showOnboard)}
        />
      </BoxMobile>
      {/* </div> */}
    </DivContainer>
  );
};
