import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../../../../utils/device";

export const ContentPage = styled(Box)`
  width: 100%;
  ${css`
    &&& {
      @media screen and ${device.tablet} {
        margin: 16px;
        border-radius: 16px;
        background-color: ${(p) => p.theme.palette.white};
      }
    }
  `}
`;
export const ContentAppbar = styled(Box)`
  background-color: ${(p) => p.theme.palette.lead[100]};
`;

export const Content = styled(Box)`
  position: relative;
  padding: 16px 0;
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 8px;
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
`;

export const TitleAppbar = styled.h3`
  ${(p) => p.theme.tipography.subtitleS}
  margin-bottom: 0;
  color: ${(p) => p.theme.palette.primary[400]};
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;
