import { Box, Divider, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import Pagination from "@mui/material/Pagination";
import {
  BoxDesktop,
  BoxMobile,
  BreadCrumbsFL,
} from "../PagosDesembolsos/componentes/data/components/BreadcrumbsFL";
import { CardNew } from "./componentes/CardNew";
import SeguimientoAportesContext from "../../context/seguimientoAportes/seguimientoAportesContext";
import Loading from "../../components/Loading";
import { EmptyData } from "../PagosDesembolsos/componentes/emptyData";
import SelectUnstyled, {
  selectClasses as selectUnstyledClasses,
} from "@mui/base/Select";
import OptionUnstyled, {
  optionClasses as optionUnstyledClasses,
} from "@mui/base/Option";
import PopperUnstyled from "@mui/base/Popper";
import { styled } from "@mui/system";
import { Redirect, useParams } from "react-router-dom";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    font-family: Roboto;
    box-sizing: border-box;
    min-height: calc(1.5em + 18px);
    min-width: 100%;
    color: #51565F;
    letter-spacing: 0.2px;
    text-transform: unset !important;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #CDCED0;
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 5px 10px;
    text-align: left;
  /* color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]}; */

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
      color: #51565F;
    }
  }

  &::after {
    content: '▾';
    float: right;
    color: #51565F;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Roboto;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 100%;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

export const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

export const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} slots={components} />;
});

const ListadoTramites = () => {
  var perfEntries = performance.getEntriesByType("navigation");

  for (var i = 0; i < perfEntries.length; i++) {
    var p = perfEntries[i];
  }

  const seguimientoAportesContext = useContext(SeguimientoAportesContext);
  const {
    seguimientoAportes,
    mensajeAlerta,
    loadingPage,
    obtenerSeguimientosAportes,
    limpiarSeguimientosAportes,
  } = seguimientoAportesContext;
  const { tipo } = useParams();

  const [vista, setVista] = useState("C");
  const [estado, setEstado] = useState("A");

  const [data, setData] = useState(seguimientoAportes ?? []);
  const [dataCurrent, setDataCurrent] = useState(seguimientoAportes ?? []);

  const [sizePagination, setSizePagination] = useState(
    window.screen.width < 990 ? 4 : 8
  );
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
    obtenerSeguimientosAportes();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (seguimientoAportes) {
      setCurrentPage(1);

      setDataCurrent(seguimientoAportes?.slice(0, 8));
      setData(seguimientoAportes);
    }

    return () => {};
  }, [seguimientoAportes]);

  const estadoActual = (e) => {
    return e.r_estado == "RECHAZADA"
      ? "RECHAZADO"
      : e.r_estado == "TERMINADO"
      ? "FINALIZADO"
      : "EN CURSO";
  };

  const handleChangePage = (e, value) => {
    setDataCurrent(
      data.slice((value - 1) * sizePagination, value * sizePagination)
    );
    setCurrentPage(value);
  };

  const changeEstado = (e) => {
    switch (e) {
      case "A":
        setData([...data?.map((e) => e)]);
        break;
      case "C":
        setData([...data?.filter((e) => estadoActual(e) == "EN CURSO")]);
        break;
      case "F":
        setData([...data?.filter((e) => estadoActual(e) == "FINALIZADO")]);

        break;
      case "R":
        setData([...data?.filter((e) => estadoActual(e) == "RECHAZADO")]);
        break;
      default:
        break;
    }
    setEstado(e);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (loadingPage == true) return <Loading />;
  return (
    <>
      {data?.length > 0 ? (
        <div>
          <BreadCrumbsFL
            tipo={tipo}
            title_mobile={"Trámite"}
            title={"Seguimiento a tus aportes"}
            rutas={[
              {
                id: 1,
                title: "Seguimiento a tus aportes",
                ruta: "#/mi-habitat-digital/afiliado/estado-de-tramite",
              },
            ]}
          />
          <Grid
            container
            sx={{
              paddingTop: { xs: "80px", md: "96px" },
              background: { xs: "#ffffff", md: "#f8f9fb" },
              paddingBottom: { xs: "60px", md: "0px" },
              minHeight: { xs: "78vh", md: "auto" },
            }}
          >
            <Grid
              item
              xs={12}
              lg={12}
              p={2}
              style={{
                paddingTop: "0px",
              }}
            >
              <Box
                sx={{
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
                  background: "#ffffff",
                  borderRadius: "16px",
                }}
              >
                <Container
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <Grid
                    container
                    style={{
                      background: "#ffffff",
                    }}
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          padding: "1rem",
                          color: "#202020",
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          lineHeight: "16px",
                          letterSpacing: "0.2px",
                        }}
                      >
                        <b>
                          <BoxDesktop>Selecciona un trámite:</BoxDesktop>{" "}
                          <BoxMobile
                            style={{
                              padding: "0",
                            }}
                          >
                            Selecciona un trámite
                          </BoxMobile>
                        </b>
                      </div>
                      <Divider light />

                      {/* Ocultado hasta nuevo aviso */}
                      {/* <div
                        style={{
                          padding: "1rem",
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        {
                          <div className="select_filter_status">
                            <Container>
                              <CustomSelect
                                defaultValue="A"
                                onChange={changeEstado}
                              >
                                <StyledOption value={"A"}>Todos</StyledOption>
                                <StyledOption value={"C"}>
                                  En curso
                                </StyledOption>
                                <StyledOption value={"F"}>
                                  Finalizado
                                </StyledOption>
                                <StyledOption value={"R"}>
                                  Rechazado
                                </StyledOption>
                              </CustomSelect>
                            </Container>
                          </div>
                        }
                        <div className="select_cuadricula_spacer"></div>
                        <div className="select_cuadricula">
                          <Container>
                            <CustomSelect defaultValue="C" onChange={setVista}>
                              <StyledOption value={"Ordenar"} disabled>
                                Ordenar
                              </StyledOption>
                              <StyledOption value={"C"}>
                                Cuadricula
                              </StyledOption>
                              <StyledOption value={"L"}>Lista</StyledOption>
                            </CustomSelect>
                          </Container>
                        </div>
                      </div> */}
                      {data?.length > 0 ? (
                        <>
                          <Grid container>
                            {dataCurrent?.map((e) => (
                              <Grid
                                item
                                xs={12}
                                md={vista == "C" ? 6 : 12}
                                key={e.nro_solicitud}
                              >
                                <div className="listado_tramite_grid_item">
                                  <CardNew {...e} />
                                </div>
                              </Grid>
                            ))}
                          </Grid>

                          <div
                            style={{
                              padding: "1rem",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                (data?.length ?? 0) / sizePagination
                              )}
                              color="primary"
                              page={currentPage}
                              onChange={handleChangePage}
                            />
                          </div>
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <div
                            style={{
                              padding: "3rem 1rem",
                              color: "#808080",
                            }}
                          >
                            No se encontraron trámites de este estado.
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <EmptyData />
      )}
    </>
  );
};

export default ListadoTramites;
