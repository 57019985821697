import React, { useState, useEffect, useContext } from "react";
import { TextAll } from "../../../../FondoLibre/componentes";
import BottomButtonsInline from "../../../../FondoLibre/componentes/BottomButtons";
import styled from "styled-components";
import Box from "@mui/material/Box";
import BasicCard from "./BasicCardDetail/BasicCard";

import IC_BAJA from "../../../../../assets/icons/icon_medidor_fondo1.svg";
import IC_ARRIESGADO from "../../../../../assets/icons/icon_medidor_fondo3.svg";
import IC_MODERADO from "../../../../../assets/icons/icon_medidor_fondo2.svg";
const DivContainer = styled("div")`
  height: ${(props) => (props.isMobile ? "auto" : "auto")};
  position: ${(props) => (props.isMobile ? "initial" : "relative")};
  padding: ${(props) => (props.isMobile ? "24px 0" : "48px")};
  width: ${(props) => (props.isMobile ? "initial" : "100%")};
`;
const DivRowRed = styled("div")`
  padding: ${(props) => (props.isMobile ? "0" : "0 24px")};
  margin: ${(props) => (props.isMobile ? "0 0 24px" : "0 0 48px")};
  position: relative;
`;
const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const Fondo1 = ({
  fondo,

  SetStep,
  info,
  grafico,
  showOnboard,
  setShowOnboard,
  cartera,
}) => {
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [indexFondo, setIndexFondo] = useState(fondo);
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, []);
  const contenido = [
    {
      titulo: "Conservador",
      texto1:
        "Eres una persona adversa al riesgo, por ello prefieres inversiones con bajo potencial de rentabilidad si los riesgos son mínimos.",
      texto2: "Los inversionistas con este perfil preferirían el Fondo 1",
      texto3:
        "Este fondo puede invertir hasta un 5% de los ahorros en renta variable, y entre un 95% y un 100% en renta fija.",
      rentabilidad: "Bajo",
      riesgo: "Bajo",
      icon: IC_BAJA,
    },
    {
      titulo: "Moderado",
      texto1:
        "Tu tienes una visión de inversión equilibrada entre el riesgo y la tasa de retorno que buscas. Prefieres distribuir tus inversiones entre renta variable (acciones) y renta fija",
      texto2: "Los inversionistas con este perfil preferirían el Fondo 2.",
      texto3:
        "La normativa sobre este fondo invertir entre un 15% y un 40% de los ahorros en renta variable y entre un 60% y un 85% en renta fija.",
      rentabilidad: "Moderado",
      riesgo: "Moderado",
      icon: IC_MODERADO,
    },

    {
      titulo: "Arriesgado",
      texto1:
        "Eres una persona adversa al riesgo, por ello prefieres inversiones con bajo potencial de rentabilidad si los riesgos son mínimos.",
      texto2: "Los inversionistas con este perfil preferirían el Fondo 1",
      texto3:
        "Este fondo puede invertir hasta un 5% de los ahorros en renta variable, y entre un 95% y un 100% en renta fija.",
      rentabilidad: "Alto",
      riesgo: "Alto",
      icon: IC_ARRIESGADO,
    },
  ];

  return (
    <DivContainer isMobile={isMobile}>
      <DivRowRed isMobile={isMobile}>
        <TextAll
          fontSize={isMobile ? "20px" : "24px"}
          lineHeight={isMobile ? "28px" : "32px"}
          fontWeight="400"
          textAlign={"center"}
          color={"#363A3E"}
          padding={isMobile ? "8px 16px" : "0px 16px 12px 16px"}
        >
          Resultado: Fondo {indexFondo}
        </TextAll>
        <TextAll
          fontSize={isMobile ? "18px" : "18px"}
          lineHeight={isMobile ? "20px" : "22px"}
          fontWeight="400"
          textAlign={"left"}
          color={"#6E7378"}
          padding={isMobile ? "8px 16px" : "12px 16px 0px 16px"}
        >
          Perfil {contenido[indexFondo - 1].titulo}
        </TextAll>
        <TextAll
          fontSize={isMobile ? "14px" : "14px"}
          lineHeight={isMobile ? "20px" : "22px"}
          fontWeight="400"
          textAlign={"left"}
          color={"#6E7378"}
          padding={isMobile ? "8px 16px" : "12px 16px 0px 16px"}
        >
          {contenido[indexFondo - 1].texto1}
          <br />
          {contenido[indexFondo - 1].texto2}
          <br />
          {contenido[indexFondo - 1].texto3}
        </TextAll>
      </DivRowRed>
      <Box
        sx={{
          borderRadius: "16px",
        }}
      >
        <BasicCard
          info={info}
          grafico={grafico}
          cartera={cartera}
          indexFondo={indexFondo}
          isMobile={isMobile}
        />
      </Box>

      <BottomButtonsInline
        active={true}
        activeSecond={true}
        isMobile={isMobile}
        width={"100%"}
        positiontop={isMobile ? "0" : "calc(100% - 80px)"}
        position={isMobile ? "relative" : "fixed"}
        padding={isMobile ? "8px 0px" : "8px 4rem"}
        flexDirection={isMobile ? "column-reverse" : "column-reverse"}
        firstPadding={isMobile ? "0px 16px" : "0px 16px"}
        secondPadding={isMobile ? "0px 16px 16px 16px" : "0px 16px"}
        firstText="Quiero rehacer el test"
        firstOnClick={() => SetStep(1)}
        //secondOnClick={indexFondo >= 2 ? null : () => setIndexFondo((e) => e + 1)}
        secondOnClick={() => setShowOnboard(!showOnboard)}
        secondText="Invertir"
      />
    </DivContainer>
  );
};
export default Fondo1;
