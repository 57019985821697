import styled from "styled-components";

export const ModalBody = styled.div`
  p {
    ${(p) => p.theme.tipography.bodySmallRegular}
    text-align: center;

    a {
      color: ${(p) => p.theme.palette.secondary.main};
      text-decoration: underline !important;
    }
  }
`;

export const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-content: center;
  gap: 8px;
  margin-bottom: 16px;

  p {
    text-align: left;
    ${(p) => p.theme.tipography.h5}
  }
`;

export const IconTitle = styled.div`
  color: ${(p) => p.theme.palette.state.warning};
`;
