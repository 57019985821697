import {
  ULTIMOS_MOVIMIENTOS_EXITOSO,
  ULTIMOS_MOVIMIENTOS_LOADIN,
  ULTIMOS_MOVIMIENTOS_ERROR,
  ULTIMOS_MOVIMIENTOS_CLEANER,
  ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case ULTIMOS_MOVIMIENTOS_EXITOSO:
      return {
        ...state,
        resultado: true,
        movimientos: action.payload.data,
        mensajeAlerta: null,
        loadingBtn: false,
      };
    case ULTIMOS_MOVIMIENTOS_ERROR:
      return {
        ...state,
        resultado: null,
        movimientos: null,
        mensajeAlerta: action.payload,
        loadingBtn: false
      };
    case ULTIMOS_MOVIMIENTOS_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case ULTIMOS_MOVIMIENTOS_CLEANER:
      return {
        ...state,
        resultado: null,
        movimientos: null,
        mensajeAlerta: null,
        loadingBtn: false
      };
    case ULTIMOS_MOVIMIENTOS_CLEANER_PARCIAL:
      return {
        ...state,
        resultado: null,
        movimientos: null,
        // mensajeAlerta: null,
        loadingBtn: false
      };
    default:
      break;
  }
};
