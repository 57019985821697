import React from "react";
import Modal from "../../../../../../../components/BasicModal";
import Button from "../../../../../../../components/Buttons";
import DocumentItem from "./DocumentItem";

const MarriageCertificateModal = ({ open, setOpen, data }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Constancia de matrimonio o concubinato</Modal.Title>
      <p style={{ fontWeight: "bold" }}>Que debes adjuntar</p>
      <DocumentItem>
        Para cónyuge: Copia Simple de la Partida de Matrimonio Civil emitida por
        RENIEC (ambas caras) o Copia Certificada emitida por la Municipalidad
        (en ambos documentos con una emisión no mayor a 6 meses).
      </DocumentItem>
      <DocumentItem>
        Para concubino(a): Vigencia del nombramiento de Unión de Hecho emitido
        por SUNARP (vigencia no mayor a 3 meses).
      </DocumentItem>
      <Button
        fullWidth
        onClick={onClose}
        style={{ margin: "auto", marginTop: "16px" }}
      >
        Entiendo
      </Button>
    </Modal>
  );
};

export default MarriageCertificateModal;
