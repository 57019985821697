import {
    PAGOS_DESEMBOLSOS_CLEANER,
    PAGOS_DESEMBOLSOS_ERROR,
    PAGOS_DESEMBOLSOS_EXITOSO,
    PAGOS_DESEMBOLSOS_LOADING,

    PAGOS_DESEMBOLSOS_PERIODOS_CLEANER,
    PAGOS_DESEMBOLSOS_PERIODOS_ERROR,
    PAGOS_DESEMBOLSOS_PERIODOS_LOADING,
    PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO,

    PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING,
    PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER,
    DETALLE_TRAMITE_EXITOSO,
    DETALLE_TRAMITE_LOADING,
    DETALLE_TRAMITE_ERROR,
    CHECK_EXITOSO,
    CHECK_LOADING,
    CHECK_ERROR,
    USER_LOADING,
    USER_EXITOSO,
    USER_ERROR,
} from "../../types/pagosDesembolsos";

export default (state, action) => {
    switch (action.type) {
        /* Tramite */
        case DETALLE_TRAMITE_EXITOSO:
            return {
                ...state,
                detalle: action.payload,
                loadingPage: false,
            }
        case DETALLE_TRAMITE_LOADING:
            return {
                ...state,
                detalle: null,
                loadingPage: true,
            };
        case DETALLE_TRAMITE_ERROR:
            return {
                ...state,
                detalle: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /*CHECK TRAMITE*/
        case CHECK_EXITOSO:
            return {
                ...state,
                check: action.payload,
                loadingPage: false,
            }
        case CHECK_LOADING:
            return {
                ...state,
                check: null,
                loadingPage: true,
            };
        case CHECK_ERROR:
            return {
                ...state,
                check: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* Empresas */
        /* USER */
        case USER_EXITOSO:
            return {
                ...state,
                user: action.payload,
                loadingPage: false,
            }
        case USER_LOADING:
            return {
                ...state,
                user: null,
                loadingPage: true,
            };
        case USER_ERROR:
            return {
                ...state,
                user: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        /* FIN USER */
        case PAGOS_DESEMBOLSOS_EXITOSO:
            return {
                ...state,
                dataEmpresas: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_LOADING:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_DESEMBOLSOS_ERROR:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_CLEANER:
            return {
                ...state,
                dataEmpresas: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO:
            return {
                ...state,
                dataDeudaPeriodos: action.payload,
                mensajeAlerta: null,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_LOADING:
            return {
                ...state,
                loadingPage: true,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_ERROR:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: action.payload,
                loadingPage: false,
            };
        case PAGOS_DESEMBOLSOS_PERIODOS_CLEANER:
            return {
                ...state,
                dataDeudaPeriodos: null,
                mensajeAlerta: null,
                loadingPage: false,
            };

        /* DEUDAS PERIODOS */
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO:
            return {
                ...state,
                allDataExcel: action.payload,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING:
            return {
                ...state,
                loadingAllDataExcel: true,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: action.payload,
                loadingAllDataExcel: false,
            };
        case PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER:
            return {
                ...state,
                allDataExcel: null,
                mensajeAlerta: null,
                loadingAllDataExcel: false,
            };
        default:
            break;
    }
}