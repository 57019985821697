import React from "react";
import Slider from "react-slick";
import { Button } from "../../../../../../../../components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Slide(props) {
  const { data, setDetail, showDetail, loading } = props;
  const reestructureArray = () => {
    const cards = [];
    for (const property in data) {
      if (property !== "cic" && property !== "decision_date") {
        cards.push({
          propiedad: property,
          amount: data[property].amount,
          balance: data[property].balance,
          retirement: data[property].retirement,
        });
      }
    }
    return cards;
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: Object.keys(data).length > 3,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const renderCard = (property, amount, balance, object) => {
    return (
      <div key={property}>
        <div className="carousel-container">
          <div className="card-withdrawal">
            <div className="text-content">
              {property === "0" ? (
                <span className="percent">Si retiro el 0%</span>
              ) : (
                <span className="percent">
                  Si retiro el {property === "955" ? "95.5" : property}%
                </span>
              )}

              {property !== "0" && (
                <>
                  <p>S/ {amount}</p>

                  <span className="available mb-3">
                    Dejo para pensión{" "}
                    <strong>
                      <br />
                      S/ {balance === "-" ? "00.00" : balance}
                    </strong>
                  </span>
                </>
              )}
              {property === "0" && (
                <>
                  <p style={{ width: "150px", margin: "auto" }}>
                    Dejo 100% para pensión
                  </p>
                </>
              )}
            </div>
            {property !== "955" && (
              <Button
                name="secondary"
                bgColor="white"
                className="btn-modal-secondary"
                onClick={() => {
                  setDetail({ percent: property, object });
                  showDetail();
                }}
                disabled={loading}
              >
                Ver Pensiones
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const newArray = reestructureArray();
  return (
    <div className="mb-4 mt-5">
      <Slider {...settings}>
        {newArray.map((item, index) => {
          return renderCard(
            item.propiedad,
            item.amount,
            item.balance,
            item.retirement
          );
        })}
      </Slider>
    </div>
  );
}
