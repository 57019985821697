import styled from "styled-components";

export const HeaderComponent = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
`;

export const Titulo = styled.p`
  ${(p) => p.theme.tipography.h5}
`;

export const SubTitulo = styled.p`
  ${(p) => p.theme.tipography.body2}
  margin-bottom: 24px;
`;

export const TipoDeFondo = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-content: center;
  margin-bottom: 24px;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 14px;
    align-self: center;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    color: ${(p) => p.theme.palette.white};
    background-color: ${(p) => p.theme.palette.secondary.main};
    padding: 4px 8px;
    border-radius: 100px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr auto;

    grid-template-areas:
      "label info"
      "fondo info";

    .label {
      grid-area: label;
    }

    .fondo {
      grid-area: fondo;
      justify-self: start;
    }

    .info-button {
      grid-area: info;
      justify-self: center;
    }
  }
`;
