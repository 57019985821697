import React from "react";
import Modal from "../../../../../../../components/BasicModal";
import Button from "../../../../../../../components/Buttons";
import * as Input from "../../../../../../../components/Input";
import { text } from "@mui/icons-material";
import DocumentItem from "./DocumentItem";

const DisabilityCertificateModal = ({ open, setOpen, data }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Sustento de condición de invalidez</Modal.Title>
      <p style={{ fontWeight: "bold" }}>Que debes adjuntar</p>
      <DocumentItem>
        Documentos que sustenten la condición de inválidos para suscribir la
        solicitud de Evaluación y Calificación de Invalidez.
      </DocumentItem>
      <DocumentItem>
        Dictamen de invalidez expedido por el COMAFP o COMEC.
      </DocumentItem>
      <Button
        fullWidth
        onClick={onClose}
        style={{ margin: "auto", marginTop: "16px" }}
      >
        Entiendo
      </Button>
    </Modal>
  );
};

export default DisabilityCertificateModal;
