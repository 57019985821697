
import { Box } from '@mui/system';
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components';
import Loading from '../../components/Loading';
import PagosDesembolsosContext from '../../context/pagosDesembolsos/pagosDesembolsosContext';
import { Data } from './componentes/data';
import { EmptyData } from './componentes/emptyData';

const BoxDesktop = styled(Box)`
  display: none;
  @media (min-width: 990px) {
    display: block;
  }
`;

const BoxMobile = styled(Box)`
  display: block;
  @media (min-width: 990px) {
    display: none;
  }
`;

export const PagosDesembolsos = () => {

  const pagosDesembolsosContext = useContext(PagosDesembolsosContext);

  const {
    loadingPage,
    checkTramites,
    check,
    clearDataEmpresas,
  } = pagosDesembolsosContext;

  useEffect(() => {
    clearDataEmpresas();
    if (!check)
      checkTramites()
  }, [])

  if (loadingPage) return <Loading />;
  return (
    <>
      <BoxDesktop>
        {(check?.tramite_retiro_apv || check?.tramite_tfe || check?.tramite_credito_hipotecario || check?.tramite_retiros_extraordinarios) ?
          <Data check={check} />
          : <EmptyData />
        }
      </BoxDesktop>

      <BoxMobile>
        {(check?.tramite_retiro_apv || check?.tramite_tfe || check?.tramite_credito_hipotecario || check?.tramite_retiros_extraordinarios) ?
          <Data check={check} />
          : <EmptyData />
        }
      </BoxMobile>
    </>
  )
}
