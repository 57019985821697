import React from "react";

const Texto = ({ opcion }) => {
  return (
    <div style={{ paddingTop: "1em" }}>
      <p style={{ textAlign: "justify" }}>
        Recuerda que para para iniciar tu trámite de transferencia de fondos al
        exterior debes estar afiliado a una Entidad o Institución Previsional en
        el exterior que pertenezca a un Sistema Previsional de carácter
        obligatorio que brinde protección contra los riesgos de vejez o
        jubilación. Para saber más detalles puedes revisar los requisitos{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.afphabitat.com.pe/transferencia-de-fondos-al-exterior/#cuales-son-los-requisitos-para-iniciar-el-tramite"
        >
          aquí.
        </a>
      </p>
    </div>
  );
};

export default Texto;
