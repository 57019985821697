import React from "react";
import { Box, Typography, Grid, Divider, List, ListItem } from "@mui/material";

import { ModalGeneral } from "../../../../components";
import styled from "styled-components";

const ContentRent = styled("div")`
  margin-bottom: 16px;
  width: 100%;
`;
const DivRentaFija = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C92D54")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  fontsize: 12px;
`;

const DivRentaVariable2 = styled("div")`
  width: 91px;
  fontsize: 12px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#73607D")};
  border-radius: ${(props) => (props.borderRent ? props.borderRent : "0px")};
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaAlternativo = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C3ACCF")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  fontsize: 12px;
`;

const DivRentaLocal = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#CE1336")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 8px;
  fontsize: 12px;

  justify-content: center;
  align-items: center;
`;

const DivRentaInternacional = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8A001A")};
  border-radius: 0px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  fontsize: 12px;

  justify-content: center;
  align-items: center;
`;

const DivRentaTransito = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8E9B9E")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  fontsize: 12px;

  justify-content: center;
  align-items: center;
`;
export const styles = {
  cardStyle: {
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid rgba(20, 20, 20, 0.15)",
    userSelect: "none",
    boxShadow: "none",
  },

  cardContentStyle: {
    minHeight: "116px",
    p: 2,
  },

  textCard: {
    fontSize: "14px",
    color: "#363A3E",
  },
  textCardDetail: {
    fontSize: "14px",
    color: "#363A3E",
  },

  titleMobileSubtitleStyle: {
    fontSize: "14px",
    textAlign: "center",
    padding: "0",
    color: "#2F6D95",
  },
  titleResponsiveStyle: {
    fontSize: "14px",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
const index = (props) => {
  const { open, setOpen, arrayFunds } = props;
  return (
    <ModalGeneral
      title={arrayFunds[0].title + " - " + arrayFunds[0].subTitle}
      open={open}
      closeModal={() => setOpen(!open)}
      onClose={() => setOpen(!open)}
    >
      <ContentRent>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={8} lg={6}>
                <List aria-label="mailbox folders">
                  <ListItem>
                    <Box
                      sx={{
                        background: "#CE1336",
                        borderRadius: "90px",
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    >
                      {" "}
                    </Box>
                    <Typography sx={styles.textCardDetail}>
                      Inversiones Locales
                    </Typography>
                  </ListItem>

                  <ListItem>
                    <Box
                      sx={{
                        background: "#8A001A",
                        borderRadius: "90px",
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    ></Box>
                    <Typography sx={styles.textCardDetail}>
                      Inversiones Extranjeras
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Box
                      sx={{
                        background: "#8E9B9E",
                        borderRadius: "90px",
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    ></Box>
                    <Typography sx={styles.textCardDetail}>
                      Operaciones en tránsito
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4} lg={6}>
                {arrayFunds[0].arrayCartera.map((item_detail) => {
                  if (item_detail?.name === "Inversiones locales") {
                    return (
                      <DivRentaLocal
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                      >
                        {item_detail.value}%
                      </DivRentaLocal>
                    );
                  }
                  if (item_detail?.name === "Inversiones en el exterior") {
                    return (
                      <DivRentaInternacional
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                      >
                        {item_detail.value}%
                      </DivRentaInternacional>
                    );
                  } else {
                    return (
                      <DivRentaTransito
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                      >
                        {item_detail.value}%
                      </DivRentaTransito>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={8} lg={6}>
                <List component="nav" aria-label="mailbox folders">
                  <ListItem>
                    <Box
                      sx={{
                        background: "#C92D54",
                        borderRadius: "90px",
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    ></Box>
                    <Typography sx={styles.textCardDetail}>
                      Renta Fija
                    </Typography>
                  </ListItem>

                  <ListItem>
                    <Box
                      sx={{
                        background: "#73607D",
                        borderRadius: "90px",
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    ></Box>
                    <Typography sx={styles.textCardDetail}>
                      Renta Variable
                    </Typography>
                  </ListItem>

                  {arrayFunds[0].id !== 1 && (
                    <ListItem>
                      <Box
                        sx={{
                          background: "#C3ACCF",
                          borderRadius: "90px",
                          width: "13px",
                          height: "13px",
                          marginRight: "4px",
                        }}
                      ></Box>
                      <Typography sx={styles.textCardDetail}>
                        Alternativo
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid item xs={4} lg={6}>
                {arrayFunds[0].arrayFund.map((item_detail) => {
                  if (item_detail?.name === "Renta Fija") {
                    return (
                      <DivRentaFija
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                      >
                        {item_detail.value}%
                      </DivRentaFija>
                    );
                  }
                  if (item_detail?.name === "Renta Variable") {
                    return (
                      <DivRentaVariable2
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                        borderRent={
                          arrayFunds[0].id === 1 ? "0px 0px 16px 16px" : "0px"
                        }
                      >
                        {item_detail.value}%
                      </DivRentaVariable2>
                    );
                  } else {
                    return (
                      <DivRentaAlternativo
                        height={((Number(item_detail.value) + 16) * 100) / 140}
                      >
                        {item_detail.value}%
                      </DivRentaAlternativo>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentRent>
    </ModalGeneral>
  );
};

export default index;
