import React from "react";

const Loading = () => {
  return (
    <div className="loading-modal-token">
      <ul className="list-modal-token">
        <li className="item-list-modal-token"></li>
        <li className="item-list-modal-token"></li>
        <li className="item-list-modal-token"></li>
        <li className="item-list-modal-token"></li>
        <li className="item-list-modal-token"></li>
        <li className="item-list-modal-token"></li>
      </ul>
    </div>
  );
};

export default Loading;
