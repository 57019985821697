import React from "react";
import { Button } from "../../../../../../../../../../components";
import "./index.css";

export default function DetalleInfo(props) {
  const { getBackOneStep, nextView } = props;
  return (
    <div className="detalle-info-container">
      <h1 className="mb-3">Pensión Objetivo</h1>
      <p>Debes tener en cuenta lo siguiente:</p>
      <ul>
        <li>
          Consiste en recibir pagos mensuales con cargo a tu Fondo de
          Jubilación.
        </li>
        <li>
          Dicha pensión dependerá del fondo que tengas acumulado en tu cuenta,
          incluida la rentabilidad, el bono de reconocimiento (si accediste a
          alguno) y la Pensión Objetivo que hayas establecido.
        </li>
        <li>
          El saldo necesario para acceder a esta pensión se calcula considerando
          la cantidad de beneficiarios que tengas, tu edad y expectativas de
          vida.
        </li>
        <li>
          Si tu fondo acumulado supera el saldo necesario para acceder a la
          Pensión Objetivo, podrás retirar el 100% del excedente o saldo
          sobrante.
        </li>
        <li>
          Cada mes se descontará el 4% de tu pensión para el pago de EsSalud, lo
          que brindará derecho a atención médica para ti y tus beneficiarios. En
          tu boleta de pago de pensiones podrás revisar el descuento mencionado.
          Cabe destacar que el monto sobrante retirado queda exento de este
          descuento.
        </li>
      </ul>
      <p>
        La pensión objetivo se pagará después de 10 días hábiles de haber tomado
        tu decisión, y el excedente lo recibirás entre 3 y 7 días hábiles.
      </p>
      <p>
        Si estás de acuerdo con esta opción, selecciona Continuar para seguir
        con el proceso.
      </p>

      <br />
      <br />
      <div className="container-reja-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className="mb-4 btn-modal-secondary"
          onClick={() => getBackOneStep()}
        >
          Volver
        </Button>
        <Button className="mb-4 btn-modal-primary" onClick={() => nextView()}>
          Continuar
        </Button>
      </div>
    </div>
  );
}
