import React from "react";
import { CardContainer, NotFoundData } from "../../components";

const MensajeInformativo = ({ mensaje }) => {
  let texto =
    "Verificamos que actualmente cuentas con una solicitud de retiro de aportes voluntarios sin fin previsional en curso";
  return (
    <CardContainer>
      {mensaje === texto ? (
        <NotFoundData
          mensaje={
            "Actualmente cuentas con una solicitud de retiro en curso. Puedes realizar una nueva solicitud cuando esta haya finalizado y cuentes con saldo en tu Fondo Libre Habitat."
          }
          btnText={"Volver al inicio"}
          url={"/mi-habitat-digital/afiliado/inicio"}
          icon={true}
        />
      ) : (
        <NotFoundData
          mensaje={mensaje + "."}
          btnText={"Volver al inicio"}
          url={"/mi-habitat-digital/afiliado/inicio"}
          icon={true}
        />
      )}

      {/* <h5 style={{ color: "#196691" }}>Condiciones para realizar un Retiro</h5>
      <p className="text-justify mb-3">{mensaje}</p> */}
      {/* <p className="text-justify m-0">
        En caso tengas alguna duda o consulta puedes escribirnos a través del
        chat virtual desde nuestra página web, comunicarte vía Contact Center al
        01 230 2200 desde Lima o 0801 14 224 desde provincias
      </p> */}
    </CardContainer>
  );
};

export default MensajeInformativo;
