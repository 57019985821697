import React, { useState, useContext, useEffect } from "react";

import { Button } from "../../../../../../../../components";
import Slider from "./Slider";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import ModalToken from "../../../../../../../../components/ModalToken";
import clienteAxios from "../../../../../../../../config/axios";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import moment from "moment";
import "./index.css";

const Estimacion = (props) => {
  const { data, endThirdStep, showDetail, setDetail, processId, idContent } =
    props;

  const [confirmeCheck, setConfirmeCheck] = useState(false);

  const [showModalConfirmEnd, setshowModalConfirmEnd] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const [openModal, setOpenModal] = useState(false);

  const [loadPdf, setLoadPDF] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    guardarAvanceJuilacionData,
    guardarAvanceJuilacionLoading,
    guardarAvanceTramiteJubilacion,
  } = jubilacionContext;

  useEffect(() => {
    if (guardarAvanceJuilacionData && sendRequest) {
      if (guardarAvanceJuilacionData.success) {
        endThirdStep();
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarAvanceJuilacionData, sendRequest]);

  if (data === null) return <p></p>;

  const changeIconForLoading = (value) => {
    if (value) {
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    } else {
      return false;
    }
  };

  const handleSaveSte4 = () => {
    if (data.decision_date <= moment().format("YYYY-MM-DD")) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
      setshowModalConfirmEnd(true);
    }
  };
  const cerrarModal = () => {
    setOpenModal(false);
  };
  const downloadPDF = async () => {
    setLoadPDF(true);
    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${props.processId}/pdf-estimation`
      );

      const linkSource = `data:application/pdf;base64,${respuesta.data.data}`;
      const downloadLink1 = document.getElementById("download");
      const fileName = "Estimacion-de-pensión.pdf";
      downloadLink1.href = linkSource;
      downloadLink1.download = fileName;
      downloadLink1.click();

      setLoadPDF(false);
    } catch (error) {}
  };

  const finishStep = (codeToken) => {
    if (data.decision_date <= moment().format("YYYY-MM-DD")) {
      setOpenModal(false);
      setSendRequest(true);
      guardarAvanceTramiteJubilacion(processId, 3, {
        data: [
          {
            id: idContent,
            response: true,
            v_token_digital: codeToken,
            type: "boleean",
          },
        ],
      });
    }
  };
  return (
    <div className="estimacion-container">
      <h1> Estimador de pensión según las modalidades de jubilación</h1>

      <div className="content">
        <div>
          <p className="description">
            Aquí estimaremos cuánto sería tu pensión en cada modalidad de
            jubilación, con el fin de que puedas elegir la que mejor te
            convenga.
          </p>
        </div>
        <div className="main-card-background mt-1">
          <span className="header">Fondo total</span>
          <p>S/ {data.cic}</p>
        </div>
        <h4 className="sub-header mb-1">Modalidades de jubilación</h4>
        <Slider
          data={data}
          setDetail={setDetail}
          showDetail={showDetail}
          loading={guardarAvanceJuilacionLoading}
        />
        <div>
          <p className="description">
            Te recomendamos descargar tu estimación de pensiones para revisarla
            a detalle y así puedas tomar una decisión informada.
          </p>

          <div
            className="container-legal-buttons mt-2"
            style={{ justifyContent: "center" }}
          >
            <Button
              className="mb-3 btn-modal-secondary"
              onClick={() => {
                downloadPDF();
              }}
              disabled={loadPdf}
            >
              Descargar estimación {changeIconForLoading(loadPdf)}
              <a id="download"></a>
            </Button>
          </div>
        </div>
        <div className="check-container mb-3">
          <span className="oneChoice">
            <input
              type="radio"
              value={0}
              checked={confirmeCheck}
              className="radio-btn"
              id="tfa_711"
              name="confirm_check"
              onClick={() => setConfirmeCheck(!confirmeCheck)}
            />

            <label className="postRadioField" id="tfa_711" for="tfa_711">
              <span className="input-radio-faux"></span>
            </label>
          </span>
          <label htmlFor="tfa_711">
            <span className="check-label">
              Acepto haber leído este documento y estoy conforme con la
              información brindada sobre mi estimación de pensiones.
            </span>
          </label>
        </div>
      </div>

      <div
        className="container-legal-buttons"
        style={{ justifyContent: "center" }}
      >
        <Button
          className={`mb-4 btn-modal-primary ${
            confirmeCheck ? "" : "disable-secondary"
          }`}
          disabled={!confirmeCheck}
          onClick={() => handleSaveSte4()}
        >
          continuar {changeIconForLoading(guardarAvanceJuilacionLoading)}
        </Button>
      </div>
      {openModal && (
        <ModalToken
          cerrarModal={cerrarModal}
          guardarCode={finishStep}
          showModal={openModal}
        />
      )}
      <CustomModal
        showModal={showModalConfirmEnd}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => setshowModalConfirmEnd(false)}
        title="Toma de decisión"
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, hemos
            concluido con éxito la primera etapa de tu solicitud.
            <br /> <br />
            El dia {moment(data.decision_date).format("DD/MM/YYYY")} podrás
            ingresar de nuevo a esta opción para elegir tu modalidad de pensión.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => setshowModalConfirmEnd(false)}
        textBtnPrimary={
          data.decision_date <= moment().format("YYYY-MM-DD")
            ? "Continuar"
            : "Cerrar"
        }
        hasAdvice={true}
        txtAdvice={
          <p>
            <strong>Recuerda</strong> estar atento a tu correo o celular para
            recibir tu notificación
          </p>
        }
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
};

export default Estimacion;
