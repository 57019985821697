import styled, { css } from "styled-components";
import { inputStyles, textInputWrapper, inputIcon } from "../styles";

export const InputWrapper = styled.div`
  ${textInputWrapper}
`;

export const TextInputComponent = styled.input`
  ${inputStyles}

  ${(p) =>
    p.$icon &&
    css`
      padding-right: 32px;
    `}
`;

export const Icon = styled.div`
  ${inputIcon}
`;
