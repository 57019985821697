import React, { useState, useEffect } from "react";
import { Button } from "../../../../../../../../../components";
import imgLogo from "../../../../../../../../../assets/solicitud.svg";
import donwloadIcon from "../../../../../../../../../assets/donwload.svg";
import CallIcon from "../../../../../../../../../assets/call.svg";
import MailIcon from "../../../../../../../../../assets/mail.svg";
import CastIcon from "../../../../../../../../../assets/cast.svg";
import "./index.css";

export default function Exitoso(props) {
  const [attached, setAttached] = useState(null);

  useEffect(() => {
    if (props.jaoResultadoData.data.attached)
      setAttached(props.jaoResultadoData.data.attached);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadPDF = () => {
    // const linkSource = `data:application/pdf;base64,${attached}`;
    const downloadLink = document.createElement("a");
    const fileName = "solicitud.pdf";
    downloadLink.href = attached;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const shortStringWithPoints = (value) => {
    const maxLength = 23;
    if (value.length <= maxLength) return value;
    return `${value.substr(0, maxLength)}...`;
  };

  return (
    <>
      <div className="logo-container mb-3">
        <img src={imgLogo} alt="icono de confirmacion" />
      </div>
      <h1>Pre-Evaluación Exitosa</h1>
      <p>
        <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, hemos
        validado que cumples con los requisitos principales para solicitar tu{" "}
        <strong>jubilación anticipada</strong>.
      </p>
      <p>
        Dentro de unos minutos te enviaremos un <strong>correo</strong> con el
        reporte detallado de tu pre-evaluación para Jubilación Anticipada
        Ordinaria.
      </p>

      <div className="center-items">
        <div className="cards-width">
          <div className="whitecard-block mb-4 fondo">
            <h4>Fondo Actual</h4>
            <span className="green-light">
              {props.jaoResultadoData.data.funds.current.format}
            </span>
          </div>
        </div>
      </div>

      <p>
        Por favor, recuerda mantener tus datos de contacto actualizados y
        revisar tu bandeja de spam o correos no deseados.
      </p>

      {!props.jaoResultadoData.data.executive && (
        <p className="mb-5">
          <strong>PASOS A SEGUIR:</strong> Si estás interesado en iniciar el
          trámite, comunícate con nuestros{" "}
          <a
            href="https://www.afphabitat.com.pe/canales-de-atencion/"
            className="link-canales"
            target="__blank"
          >
            <strong>canales de atención</strong>
          </a>{" "}
          para solicitar una cita de Asesoría.
        </p>
      )}

      {props.jaoResultadoData.data.executive && (
        <>
          <p className="text-left">
            Si tienes dudas sobre los resultados enviados, puedes comunicarte
            con tu asesora:
          </p>
          <div className="center-items mb-5">
            <div className="whitecard-block contact-content">
              <div className="row-info">
                <img src={CastIcon} alt="call_icon" />{" "}
                <p>{props.jaoResultadoData.data.executive.name}</p>
              </div>
              <div className="row-info">
                <img src={CallIcon} alt="call_icon" />{" "}
                <p>{props.jaoResultadoData.data.executive.cell_phone}</p>
              </div>
              <div className="row-info">
                <img src={MailIcon} alt="call_icon" />{" "}
                <p>
                  {shortStringWithPoints(
                    props.jaoResultadoData.data.executive.email
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="center-items">
        <div className="pdf-width" onClick={() => downloadPDF()}>
          <div className="pdf-donwload-content">
            <div className="pdf-label">PDF</div>
            <div className="pdf-info-content">
              <span className="name-pdf">solicitud.pdf</span>
            </div>
            <div className="pdf-icon-content">
              <img src={donwloadIcon} alt="descarga-icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-jao-buttons mt-5">
        <Button
          className="mb-4 btn-modal-primary"
          onClick={() => props.getOutOfProcess()}
        >
          Volver a pantalla Principal
        </Button>
      </div>
    </>
  );
}
