import styled from "styled-components";

export const ModalComponent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 16px);
  overflow: auto;
  padding: 16px;
  background-color: ${(p) => p.theme.palette.white};
  box-shadow: ${(p) => p.theme.elevations.aboveMd};
  border-radius: 16px;
  outline: none;
  width: min(calc(100% - 16px), 450px);
  row-gap: 8px;
  display: grid;

  p {
    margin-bottom: 0;
  }

  .title {
    ${(p) => p.theme.tipography.h5}
    text-align: center;
    margin-bottom: 16px;
  }
  .body {
    p.description {
      ${(p) => p.theme.tipography.bodySmallRegular}
      margin-bottom: 16px;
    }
  }
`;

export const CloseButton = styled.div`
  padding: 8px;
  height: 32px;
  width: 32px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 100px;
  justify-self: end;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.palette.grayscale[100]};
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

export const Table = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  color: ${(p) => p.theme.palette.grayscale[700]};

  .title {
    ${(p) => p.theme.tipography.headingSubtitleSemi}
    color: ${(p) => p.theme.palette.grayscale[800]};
    margin-bottom: 8px;
    text-align: left;
  }

  .tableRow {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr;
    padding: 4px 8px;
    border-radius: 16px;

    &:nth-child(even) {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
    }

    .label {
      align-self: center;
    }
    .value {
      text-align: right;
      align-self: center;
    }
  }
`;
