import React from "react";
import * as Input from "../../../../components/Input";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import Alert from "../../../../components/Alert";
import { ButtonsWrapper } from "./styles";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import getDeepValue from "../../../../utils/getDeepValue";
import { useGastoDeSepelioContext } from "../../context";
import * as actions from "../../context/actions";
import Modal from "../../../../components/BasicModal";

const VerificacionDeEmail = ({ open, setOpen, handleStepNavigation }) => {
  const {
    getValues,
    watch,
    setValue,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const name = "datos-solicitante.email-validation";
  const [isValid, setIsValid] = useState(null);

  const {
    state: { verificationEmailToken, validationEmailToken, token },
    dispatch,
    getVerificationEmailToken,
    validateEmailToken,
  } = useGastoDeSepelioContext();

  const canContinue =
    watch(name) && !getDeepValue(errors, name) && isValid !== false;

  const onValidate = async () => {
    try {
      const response = await validateEmailToken({
        doc_type: getValues("datos-solicitante.document-type"),
        doc_num: getValues("datos-solicitante.document-value"),
        email: getValues("datos-solicitante.email"),
        codigo_verificacion: getValues("datos-solicitante.email-validation"),
        token,
      });

      if (response === actions.VALIDATION_EMAIL_TOKEN_SUCCESS) {
        handleStepNavigation("next");
      }
    } catch (error) {
      dispatch({
        type: actions.VALIDATION_EMAIL_TOKEN_FAILURE,
        payload: { error },
      });
    }
  };

  useEffect(() => {
    trigger(name);
  }, [isValid]);

  useEffect(() => {
    if (isValid === false) {
      setIsValid(null);
    }
  }, [watch(name)]);

  useEffect(() => {
    // send token to email
    if (open === true) {
      getVerificationEmailToken({
        doc_type: getValues("datos-solicitante.document-type"),
        doc_num: getValues("datos-solicitante.document-value"),
        email: getValues("datos-solicitante.email"),
        token,
      });
    }

    if (open === false) {
      clearErrors("datos-solicitante.email-validation");
      setValue("datos-solicitante.email-validation", "");
    }
  }, [open]);

  useEffect(() => {
    if (validationEmailToken.error) {
      setError("datos-solicitante.email-validation", {
        types: {
          tokenValidation:
            validationEmailToken.error || "Hubo un error inténtelo nuevamente",
        },
      });
    }
  }, [validationEmailToken.error]);

  const onClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={onClose}
      close={false}
      closeWithBackdrop={false}
    >
      <Modal.Title>Verificación de e-mail</Modal.Title>
      <Modal.Subtitle>
        Te envíamos un código de validación a:{" "}
        <span>{watch("datos-solicitante.email")}</span>
      </Modal.Subtitle>
      <Input.Wrapper style={{ marginTop: "16px" }}>
        <Input.Title required={false}>Código de validación</Input.Title>
        <Input.Number
          placeholder={"______"}
          maxLength={6}
          minLength={6}
          name={name}
          style={{ letterSpacing: "6px" }}
          validate={{
            isValid: (e) => (isValid === false ? "Código incorrecto." : true),
          }}
        />
        <Input.ErrorMessage name={name} />
      </Input.Wrapper>
      <Alert
        description={
          "Si no lo encuentras, revisa tu bandeja de spam o correo no deseado."
        }
      />
      <Divider />
      <ButtonsWrapper>
        <Button variant="secondary" fullWidth onClick={onClose}>
          Volver
        </Button>
        <Button
          fullWidth
          disabled={
            !canContinue ||
            verificationEmailToken.status === "loading" ||
            validationEmailToken.status === "loading"
          }
          onClick={onValidate}
        >
          {verificationEmailToken.status === "loading" && "Enviando token"}
          {validationEmailToken.status === "loading" && "Validando"}
          {verificationEmailToken.status !== "loading" &&
            validationEmailToken.status !== "loading" &&
            "Validar"}
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default VerificacionDeEmail;
