import React from "react";
import {
  DrawerOptionUnCollapseMode,
  DrawerOptionCollapseMode,
} from "./components";

export default function DrawerOption(props) {
  const {
    id,
    title,
    icon,
    iconHover,
    altIcon,
    subMenuOptions,
    url,
    subOptions,
    isDrawerCollapse,
    setOpenDrawer,
    optionSelected,
    handleClose,
    setOptionSelected,
    location,
    sidebarOptions,
    connectEtario,
  } = props;

  if (isDrawerCollapse)
    return (
      <DrawerOptionUnCollapseMode
        id={id}
        title={title}
        icon={icon}
        iconHover={iconHover}
        altIcon={altIcon}
        url={url}
        subOptions={subOptions}
        subMenuOptions={subMenuOptions}
        setOpenDrawer={setOpenDrawer}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        handleClose={handleClose}
        location={location}
        sidebarOptions={sidebarOptions}
        connectEtario={connectEtario}
      />
    );
  else
    return (
      <DrawerOptionCollapseMode
        id={id}
        title={title}
        icon={icon}
        iconHover={iconHover}
        altIcon={altIcon}
        url={url}
        subOptions={subOptions}
        subMenuOptions={subMenuOptions}
        setOpenDrawer={setOpenDrawer}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        handleClose={handleClose}
        location={location}
        sidebarOptions={sidebarOptions}
        connectEtario={connectEtario}
      />
    );
}
