import React from "react";
import { Slide } from "@mui/material";
import Button from "../../../components/Buttons";
import Divider from "../../../components/Divider";
import Modal from "../../../components/BasicModal";
import { Subtitle } from "./styles";

const ModalDeContacto = ({ open, setOpen }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Contáctanos</Modal.Title>
      <Subtitle>
        <p>
          Puedes resolver tus dudas con nosotros desde los siguientes canales:
        </p>
        <br />
        <p>
          <b>Whatsapp:</b>{" "}
          <a href="http://wa.me/51981211004" target={"_blank"}>
            981211004
          </a>
        </p>
        <p>
          <b>Contact Center (Lima):</b> <a href="tel:+012302200">01 230 2200</a>
        </p>
        <p>
          <b>Contact Center (Provincias):</b>{" "}
          <a href="tel:+080114224">0801 14 224</a>
        </p>
      </Subtitle>
      <Divider />
      <Button fullWidth onClick={onClose} size="lg" style={{ margin: "auto" }}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeContacto;
