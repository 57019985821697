import React, { useState, useEffect } from "react";
// import hocVariables from "./HocVariables";
const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const HocWindowWidth = (OriginalComponent) => {
  const NewComponent = (props) => {
    const [windowWidth, setWindowWidth] = useState(getWidth());

    useEffect(() => {
      const resizeListener = () => {
        // change width from the state object
        setWindowWidth(getWidth());
      };
      // set resize listener
      window.addEventListener("resize", resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return <OriginalComponent windowWidth={windowWidth} {...props} />;
  };

  return NewComponent;
};

export default HocWindowWidth;
