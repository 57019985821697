import React, { useState, useEffect, useContext } from "react";
import { ValidacionAfiliados } from "./components";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso2(props) {
  const [relationships, setRelationships] = useState([]);
  const [contents, setContents] = useState(null);
  const [dependencies, setDependencies] = useState({ gender: "" });
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    relationshipsData,
    relationshipsLoading,
    obtenerParentescosRejaData,
    limpiarObtenerParentescosRejaData,

    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    limpiarObtenerContenidoTemaJubilacion,

    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerParentescosRejaData();
    obtenerContenidoTemaJubilacion(props.processId, 2);
    return () => {
      limpiarObtenerParentescosRejaData();
      limpiarObtenerContenidoTemaJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (relationshipsData) setRelationships(relationshipsData.data);
  }, [relationshipsData]);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setContents(contenidoTemaJubilacionData.contents);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  if (mensajeAlerta !== null) return <p></p>;
  if (relationshipsLoading || contenidoTemaJubilacionLoading)
    return <Loading />;

  return (
    <div>
      <ValidacionAfiliados
        processId={props.processId}
        initialData={{ pension_orfandad_viudez: null, beneficiarios: [] }}
        dependencies={dependencies}
        contents={contents}
        relationships={relationships}
        endSecondStep={() => props.setCurrectStepNumber(3)}
        getOutOfProcess={() => props.getOutOfProcess()}
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
