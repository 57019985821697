export const BOLETA_EXITOSO = 'BOLETA_EXITOSO';
export const BOLETA_ERROR = 'BOLETA_ERROR';
export const BOLETA_LOADIN = 'BOLETA_LOADIN';
export const BOLETA_CLEANER = 'BOLETA_CLEANER';
export const BOLETA_CLEANER_PARCIAL = 'BOLETA_CLEANER_PARCIAL';

export const PERIODO_EXITOSO = 'PERIODO_EXITOSO';
export const PERIODO_ERROR = 'PERIODO_ERROR';
export const PERIODO_LOADIN = 'PERIODO_LOADIN';
export const PERIODO_CLEANER = 'PERIODO_CLEANER';
export const PERIODO_CLEANER_PARCIAL = 'PERIODO_CLEANER_PARCIAL';