import React from "react";
import styled from "styled-components";
import FileText from "../../../../../../../assets/IconBucket/FileText";

const DocumentItemStyles = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 16px;
  gap: 16px;
  font-size: ${(p) => p.theme.tipography.bodySmallRegular};
`;

const DocumentItem = ({ children }) => {
  return (
    <DocumentItemStyles>
      <div>
        <FileText />
      </div>
      <div>{children}</div>
    </DocumentItemStyles>
  );
};

export default DocumentItem;
