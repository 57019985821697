import React, { useState, useContext, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import { meses } from "../../../data/DataLocal";
import moment from "moment";
import MisInversionesContext from "../../../context/misinversiones/misinversionesContext";
import UltimosmovimientosContext from "../../../context/ultimosmovimientos/ultimosmovimientosContext";
import { Interest } from "../../Dashboard/components";
import AccesDirect from "./components/TypeValue/accesDirect";
import { TypeValue, MovementDesktop, RentFund, Graphyc } from "./components";
// import Graphyc from "../../MisInversiones/GraficoLineas";
import Loading from "../../../components/Loading/index.jsx";
import Grid from "@mui/material/Grid";
import "./index.css";
import InvestmentHistorical from "./components/InvesmentHistorical";
import useQuery from "../../../hooks/useQuery";

const APVConFin = (props) => {
  const { habitatValue, SPPValue, product, cuotasValue, totalValue } = props;
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
  const misinversionesContext = useContext(MisInversionesContext);

  const [nombreFondo, setNombreFondo] = useState(null);
  const [fecha, setFecha] = useState({ inicio: null, fin: null });
  const {
    datosMisInversiones,
    mensajeAlerta,
    obtenerDatosMisInversiones,
    limpiarVariablesMisInversionesParcial,
  } = misinversionesContext;
  const ultimosmovimientosContext = useContext(UltimosmovimientosContext);
  const {
    movimientos,
    obtenerUltimosMovimientos,
    limpiarUltimosMovimientosParcial,
  } = ultimosmovimientosContext;

  const query = useQuery();
  const productName = query.get("name");

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      // mostrarMensaje();
    } else {
      // ReactGa.pageview(window.location.href);
      obtenerDatosMisInversiones();
      getObtener();
    }

    return () => {
      limpiarVariablesMisInversionesParcial();
      limpiarUltimosMovimientosParcial();
      // actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);
  const getObtener = () => {
    let bodyFormData = new FormData();
    let dateIni = moment().subtract(18, "month");
    let dateFin = moment();
    let pe_ini =
      Number((dateIni.month() + 1).toString().length) === 1
        ? dateIni.year() + "0" + (dateIni.month() + 1)
        : dateIni.year() + "" + (dateIni.month() + 1);
    let pe_fin =
      Number((dateFin.month() + 1).toString().length) === 1
        ? dateFin.year() + "0" + (dateFin.month() + 1)
        : dateFin.year() + "" + (dateFin.month() + 1);

    bodyFormData.set("tipo_aporte", props.product);
    bodyFormData.set("pe_ini", pe_ini);
    bodyFormData.set("pe_fin", pe_fin);
    obtenerUltimosMovimientos(bodyFormData);
  };

  useEffect(() => {
    if (datosMisInversiones) {
      if (datosMisInversiones.r_validacion === 1) {
        setNombreFondo(
          datosMisInversiones.r_tipos_de_aporte[opcionSeleccionada]
            .r_descripcion
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosMisInversiones]);

  const formatFecha = (fecha) => {
    const fechaArray = fecha.split("-");
    return ` ${meses[fechaArray[1] - 1].mes} - ${fechaArray[0]}`;
  };

  const fechaInicioFin = (fechas) => {
    setFecha({
      inicio: formatFecha(fechas.inicio.date),
      fin: formatFecha(fechas.fin.date),
    });
  };

  if (datosMisInversiones === null || movimientos === null) return <Loading />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12} md={7} p={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pb: 2,
              maxHeight: 560,
            }}
          >
            <TypeValue
              habitatValue={habitatValue}
              cuotasValue={cuotasValue}
              acumulated_mount={totalValue}
            />
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
              pb: 2,
            }}
          >
            {
              <RentFund
                product={product}
                detalleProductoSPP={SPPValue.length > 0 ? SPPValue[0] : ""}
                detalleHabitatSPP={habitatValue}
                acumulated_mount={totalValue}
              />
            }
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            {props.product === "OBL" &&
              datosMisInversiones.r_validacion !== 0 &&
              datosMisInversiones.r_tipos_de_aporte.map((item, index) => (
                <Fragment key={index}>
                  {opcionSeleccionada === index && (
                    <Graphyc
                      fechaInicioFin={fechaInicioFin}
                      otraData={
                        datosMisInversiones[
                          datosMisInversiones.r_tipos_de_aporte[
                            opcionSeleccionada
                          ].r_codigo
                        ]
                      }
                    />
                  )}
                </Fragment>
              ))}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              mt: 3,
              mb: 3,
            }}
            className="box-movement-mobile"
          >
            <MovementDesktop movimientos={movimientos.slice(0, 5)} />
          </Box> */}
          {productName === "VSF" &&
            props.invesmentData?.data &&
            props.invesmentData?.data.length > 0 && (
              <InvestmentHistorical {...props.invesmentData} />
            )}
        </Grid>
        <Grid item xs={12} md={5} p={2}>
          <AccesDirect product={product} jubilacion={props.jubilacion} />
          <Interest />
        </Grid>
      </Grid>
    </Box>
  );
};

export default hocWidthWindow(APVConFin);
