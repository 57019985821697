import React, { useEffect, useRef, useState } from "react";
import { Icon, InputWrapper, MoneyInputComponent } from "./styles";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import getDeepValue from "../../../utils/getDeepValue";

const MoneyInput = ({
  name,
  size,
  icon,
  disabled,
  placeholder,
  wrapperProps,
  value,
  onChange,
  required,
  validate,
  ...props
}) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const defaultMaskOptions = {
    prefix: "S/ ",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 16, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };
  const currencyMask = createNumberMask(defaultMaskOptions);
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    const valueChosen = currentValue || value;
    const ammountReformatted = valueChosen
      ?.replace("S/ ", "")
      .replaceAll(",", "");
    setValue(name, ammountReformatted);
  }, [currentValue, value]);

  const isError = getDeepValue(errors, name);

  const handleOnChange = (e) => {
    setCurrentValue(e.target.value);
    trigger(name);
  };

  const rendered = useRef(false);
  useEffect(() => {
    rendered.current = true;
  }, [value]);

  return (
    <InputWrapper {...wrapperProps}>
      <MoneyInputComponent
        type="text"
        error={isError}
        key={`money-input-${rendered}`}
        placeholder={placeholder}
        disabled={disabled}
        mask={currencyMask}
        value={value || currentValue}
        size={size}
        $icon={Boolean(icon)}
        {...register(name, { required, validate: { ...validate } })}
        onChange={onChange || handleOnChange}
        {...props}
      />
      {icon && (
        <Icon disabled={disabled} error={isError}>
          {icon}
        </Icon>
      )}
    </InputWrapper>
  );
};

export default MoneyInput;

MoneyInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  wrapperProps: PropTypes.any,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
};

MoneyInput.defaultProps = {
  name: "input-value",
  size: "md",
  disabled: false,
  required: { value: true, message: "Este campo es requerido." },
  placeholder: "S/ 00.00",
  validate: null,
};
