import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";

const ModalActualizarDatos = ({ isOpen, setIsOpen }) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);

  return (
    <Modal
      close={false}
      isOpen={isOpen}
      onClose={closeModal}
      title="Necesitas actualizar tus datos"
      subtitle={
        "Por favor, actualiza tu número de celular y correo electrónico para continuar con la solicitud y poder contactarte."
      }
      onClickBtnPrimary={() =>
        history.push(
          "/mi-habitat-digital/afiliado/informacion/datos-personales"
        )
      }
      textBtnPrimary={"Actualizar"}
      isBtnSecondary={true}
      textBtnSecondary="Cancelar"
      onClickBtnSecondary={closeModal}
    />
  );
};

export default ModalActualizarDatos;
