import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
const SolicitudProcesada = ({ email }) => {
  return (
    <div className="card">
      <div className="title-informativo card-header">
        Tu solicitud ha sido completada satisfactoriamente
      </div>

      <div className="card-body">
        <p>
          Has modificado exitosamente tu{" "}
          <strong style={{ color: "#196691" }}>Clave Web</strong>, la cual
          deberás utilizar la próxima vez que desees ingresar a este portal.
        </p>
        <p>
          Se te envió un correo a{" "}
          <strong style={{ color: "#196691" }}>{email}</strong>, notificandote
          el cambio de clave.
        </p>
        <p className="text-center">
          <Link to="/mi-habitat-digital/afiliado/inicio">
            <Button>Volver al inicio</Button>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SolicitudProcesada;
