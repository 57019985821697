import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
export default function CardDeuda(props) {
  const setDataToView = () => {
    sessionStorage.setItem("nombre_empresa", props.businessName);
    sessionStorage.setItem("ruc_empresa", props.ruc);
    sessionStorage.setItem("estado_empresa", props.companyState);
    // sessionStorage.setItem("estado_deuda", props.estado);
  };

  return (
    <Col xs="12" lg="6">
      <div className="card-payment-box">
        <div className="content-card-payment">
          <p className="text-center debt-text">Deuda total</p>
          <h2 className="text-center amount-text">S/ {props.amount}</h2>
          <p className="text-center company-text">{props.businessName}</p>
          <Link
            to={
              "/mi-habitat-digital/afiliado/informacion/pagos-pendientes/detalle"
            }
          >
            <button
              className={
                props.isBuildingExcel
                  ? "btn-payments-detail btn-disable"
                  : "btn-payments-detail"
              }
              onClick={setDataToView}
              disabled={props.isBuildingExcel}
            >
              Ver detalle de deuda{" "}
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </Link>
        </div>
      </div>
    </Col>
  );
}
