import React, { useState } from "react";
import whiteDownArrow from "../../../../../../assets/icons/white-down-arrow.svg";
import whiteUpArrow from "../../../../../../assets/icons/white-up-arrow.svg";

const FooterRowOption = (props) => {
    const { title, subOptions } = props.value;
    const [isOpen, setisOpen] = useState(false);

    return (
        <div className="footer-option">
            <div className="footer-header-option" onClick={() => setisOpen(!isOpen)}>
                <h4>{title}</h4>
                {subOptions && (
                    <img
                        id="sub-good-corporate-governance-arrow"
                        src={isOpen ? whiteUpArrow : whiteDownArrow}
                        alt="white-arrow"
                    />
                )}

            </div>
            {subOptions && (
                <div className={isOpen ? "footer-suboptions change" : "footer-suboptions"}>
                    <ul>
                        {subOptions.map((value, index) => (
                            <li key={index}>
                                <a href={value.url} rel="noopener noreferrer">
                                    {value.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FooterRowOption;
