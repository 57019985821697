import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import { AsesoriaInicio, AsesoriaTemas } from "./components";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso1(props) {
  const [showInitView, setShowInitView] = useState(0);
  const [contens, setContens] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    temasAsesoriaRejaLoading,
    temasAsesoriaRejaData,
    obtenerContenidoPasoReja,
    limpiarObtenerContenidoPasoReja,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    props.setCurrectStepTitle("Asesoria");
    obtenerContenidoPasoReja(props.processId, 1);
    return () => {
      limpiarObtenerContenidoPasoReja();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(temasAsesoriaRejaData);
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        setShowInitView(1);
        setContens(temasAsesoriaRejaData.contents);
        props.setCurrectStepTitle(temasAsesoriaRejaData.name);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  const renderView = () => {
    switch (showInitView) {
      case 1:
        return <AsesoriaInicio gotToTopics={() => setShowInitView(2)} />;
      case 2:
        return (
          <AsesoriaTemas
            processId={props.processId}
            contens={contens}
            endFirstStep={() => props.setCurrectStepNumber(2)}
          />
        );
      default:
        return <Loading />;
    }
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (temasAsesoriaRejaLoading || contens.length === 0) return <Loading />;

  return (
    <div>
      {renderView()}
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
