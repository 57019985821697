import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import SolictudNoProcesada from "./SolicitudNoProcesada";
import SolicitudProcesada from "./SolicitudProcesada";
import Onboarding from "./components/OnBoarding/index.jsx";
import ICON_INFO from "../../assets/icons/info_circle.svg";
import { inversion } from "../../Dummys/inversion";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Paper,
  Alert,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { animateScroll as scroll } from "react-scroll";
import Cards from "../../components/CreditCard/Cards";
import RadioButton from "../../components/RadioButtonCustom";
import ModalToken from "../../components/ModalToken";
import CustomModal from "../../components/custromModal/CustomModal";
import { montoFormat } from "../../helper/amountFormat.js";
import AporteapvsinfinContext from "../../context/aporteapvsinfin/aportarapvsinfinContext";
import SeguimientoAportesContext from "../../context/seguimientoAportes/seguimientoAportesContext";
import { Tipography, MessageSnack } from "../../components";
import SelectGeneral from "./SelectGeneral";
import ModalDeclaracionJurada from "./ModalDeclaracionJurada";
import ModalExitFatca from "./ModalExitFatca";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import HorizontalStepper from "../../components/HorizontalStepper/HorizontalStepper.jsx";

import { monedas, cargo, meses } from "./DataLocal";
import { obtenerCtaRemesa } from "../../utils/formulaRemesa";

/* Componentes de la vista */
import BasicCard from "../../components/BasicCard/BasicCard";

import { InfoCard, palletteColors } from "../../utils/constants";
import DatosDeInversion from "./components/DatosDeInversion";
import PasoConfirmacionSolicitud from "./components/PasoConfirmacionSolicitud";
import ModalDeConfirmacion from "./components/ModalDeConfirmacion";
import BotonesDeNavegacion from "./components/BotonesDeNavegacion";
import ModalDeErrorGeneral from "./components/ModalDeErrorGeneral";
import { BodyPageWrapper } from "./styles";

const globalErrors = {
  fondo: "",
  cuentaCard: "",
  banco: "",
  tipoCuenta: "",
  anioYmes: "",
  nroCuenta: "",
  monto: "",
  frecuenciaCargo: "",
  fechaCargo: "",
};

/* para los estilos en sx material ui */
const styles = {
  menuItemStyles: {
    "&:hover": {
      backgroundColor: "#F2FAFF !important",
    },
  },
};

/* estilos en className */
const useStyles = makeStyles((theme) =>
  createStyles({
    paperForm: {
      padding: "16px",
      borderRadius: "16px",
      boxShadow: "0px 4px 16px rgb(0 0 0 / 12%)",
    },
    paperContainerStyles: {
      borderRadius: "16px",
      boxShadow: 2,
      userSelect: "none",
    },
    paperContainerStylesChanges: {
      borderRadius: "0px",
      border: "0px",
      boxShadow: "none",
      userSelect: "none",
      padding: "16px",
    },
    selectStyles: {
      "& .MuiSelect-filled": {
        borderRadius: "16px",
        backgroundColor: "#F6F6F6",
      },
      "& .MuiSelect-filled.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    textFieldStyles: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "16px",
      },
    },
    textFieldFilledStyles: {
      "& .MuiFilledInput-root": {
        borderRadius: "16px",

        backgroundColor: "#F6F6F6",
      },
      "& .MuiFilledInput-root.Mui-disabled": {
        backgroundColor: palletteColors.disabledBg,
      },
    },
    boxDescriptionStyles: {
      display: "flex",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      // height: "80px",
      padding: "12px",
      color: palletteColors.GreyText,
    },
    boxFondoStyles: {
      display: "flex",
      alignItems: "center",
    },
    alertConfirmacion: {
      fontSize: "12px",
    },
    boxWarningStyles: {
      // mt: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      // height: "80px",
      padding: "4px",
      color: palletteColors.GreyText,
    },
    iconStyles: {
      color: palletteColors.iconBlue,
      width: "24px",
    },
  })
);

const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const PrimerPaso = (props) => {
  const {
    data,
    resultado,
    setBreadCrumb,
    nombreUsuario,
    obtenerSeguimientosAportes,
    seguimientoAportes,
  } = props;
  const history = useHistory();
  const mostrarCuentas = data.r_cuentas === "1" ? true : false;
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("-1");
  const [confirmacionInfo, setConfirmacionInfo] = useState(null);
  const [DeclaracionJurada, setDeclaracionJurada] = useState(false);
  const [showOnboard, setShowOnboard] = useState(false);
  const [fondoSeleccionado, setFondoSeleccionado] = useState("");
  const [cuentaDestino, setCuentsDestino] = useState(-1);
  const [banco, setBanco] = useState("");
  const [tipoDeCuenta, setTipoDeCuenta] = useState("");
  const [yearTarjeta, setYearTarjeta] = useState("");
  const [monthTarjeta, setMonthTarjeta] = useState("");
  const [nroDeCuenta, setNroDeCuenta] = useState("");
  const [montoACargar, setMontoACargar] = useState("");
  const [frecuenciaCargo, setFrecuenciaCargo] = useState("");
  const [fechaCargo, setFechaCargo] = useState("");
  const [cargoDoble, setCargoDoble] = useState(false);
  const [filtroTipoCuenta, seFiltroTipoCuenta] = useState([]);
  const [openModalToken, setOpenModalToken] = useState(false);
  const [openModalTokenError, setOpenModalTokenError] = useState(false);
  const [mostrarFecha, setMostrarFecha] = useState(false);
  const [longitudNroCuenta, setLongitudNroCuenta] = useState(0);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [mostrarFormularioCompleto, setMostrarFormularioCompleto] = useState(
    !mostrarCuentas
  );
  const [errores, setErrores] = useState(globalErrors);

  const [modalDeErrorGeneralIsOpen, setModalDeErrorGeneralIsOpen] =
    useState(false);

  const [stepLength, setStepLength] = useState([]);
  const [step, setStep] = useState(0);
  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const {
    aportarApvSinFin,
    loadingBtn,
    mensajeAlertaPost,
    datosResultadoAportarApvSinFin,
  } = aporteapvsinfinContext;

  /* MUI CSS styles */
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (mensajeAlertaPost) {
      setModalDeErrorGeneralIsOpen(true);
      setOpenModalToken(false);
    }
  }, [mensajeAlertaPost]);

  useEffect(() => {
    if (data.r_fondo.length > 1) {
      // setShowOnboard(true);
    }
    if (data.r_validacion === "1") {
      setStepLength([
        "Selección de tipo de fondo",
        "Datos de inversión",
        "Confirmación de solicitud",
      ]);
      setStep(0);
    }
    if (data.r_validacion === "2") {
      setStepLength(["Datos de inversión", "Confirmación de solicitud"]);

      setStep(0);
    }
    // eslint-disable-next-line
  }, [data.r_validacion]);
  useEffect(() => {
    if (resultado) {
      if (resultado.r_respuesta === -6 || resultado.r_respuesta === -5) {
        setOpenModalToken(false);

        setOpenModalTokenError(true);
      } else {
        setStep(2);
        setOpenModalToken(false);
        obtenerSeguimientosAportes();
      }
    }
    // eslint-disable-next-line
  }, [props.resultado]);
  const opcionSeleccionadaCard = (opcion) => {
    if (cuentaDestino === opcion) {
      return null;
    }

    if (opcion === "nueva") {
      setMostrarFormularioCompleto(true);
      setCuentsDestino("nueva");
    } else {
      setMostrarFormularioCompleto(false);
      setCuentsDestino(opcion);
    }
    // limpiamos campos
    setBanco("");
    setTipoDeCuenta("");
    setYearTarjeta("");
    setMonthTarjeta("");
    setNroDeCuenta("");
    seFiltroTipoCuenta([]);
    setLongitudNroCuenta(0);
    setMostrarFecha(false);
    setErrores(globalErrors);
  };
  function handlerTipoCuenta(e) {
    let opcion;
    if (e.length > 0) {
      opcion = JSON.parse(e);
      setTipoDeCuenta(JSON.stringify(opcion));
    } else {
      opcion = "";
      setTipoDeCuenta("");
    }
    if (opcion.r_banco === "7") {
      setNroDeCuenta("0011");
    } else {
      setNroDeCuenta("");
    }
    if (opcion !== "") {
      if (parseInt(opcion.r_fecha_vencimiento) !== 0) {
        setMostrarFecha(true);
      } else {
        setMostrarFecha(false);
        setYearTarjeta("");
        setMonthTarjeta("");
      }
      setLongitudNroCuenta(opcion.r_longitud);
    } else {
      setMostrarFecha(false);
      setLongitudNroCuenta(0);
    }
    setErrores({ ...errores, tipoCuenta: "" });
  }

  const handlerbanco = (opcion) => {
    seFiltroTipoCuenta([]);
    if (opcion !== "") {
      setBanco(opcion);
      let newListTipoCuenta = data.r_tipo_cuentas.filter(
        (item) => item.r_banco === opcion.r_codigo
      );

      seFiltroTipoCuenta(newListTipoCuenta);
    } else {
      setBanco("");
    }
    setMostrarFecha(false);
    setTipoDeCuenta("");
    setLongitudNroCuenta(0);
    setYearTarjeta("");
    setMonthTarjeta("");
    setNroDeCuenta("");
    setErrores({ ...errores, banco: "" });
  };
  const handlerNroCuenta = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setNroDeCuenta(valor);
    setErrores({ ...errores, nroCuenta: "" });
  };
  const handlerYear = (e) => {
    setYearTarjeta(e);
    setMonthTarjeta("");
    setErrores({ ...errores, anioYmes: "" });
  };
  const handlerMonth = (e) => {
    setMonthTarjeta(e);
    setErrores({ ...errores, anioYmes: "" });
  };
  const handlerMonto = (e) => {
    setMontoACargar(e);
    setErrores({ ...errores, monto: "" });
  };
  const handlerFrecuenciaCargo = (e) => {
    setFrecuenciaCargo(e);
    setFechaCargo("");
    setErrores({ ...errores, frecuenciaCargo: "" });
  };
  const handlerFechaCargo = (e) => {
    setFechaCargo(e);
    setErrores({ ...errores, fechaCargo: "" });
  };

  const closeDeclaracionJurada = () => () => {
    setDeclaracionJurada(null);
  };
  const validarData = () => {
    let montoLocal;
    let mensajesError = {
      fondo: "",
      cuentaCard: "",
      banco: "",
      tipoCuenta: "",
      anioYmes: "",
      nroCuenta: "",
      monto: "",
      frecuenciaCargo: "",
      fechaCargo: "",
    };

    if (parseInt(data.r_validacion) === 1) {
      // me indica que necesito seleccionar un fondo
      if (fondoSeleccionado.length === 0) {
        mensajesError = { ...mensajesError, fondo: "campo requerido" };
      }
    }

    // if (parseInt(data.r_cuentas) === 1) {
    // me indica que necesito seleccionar un fondo
    if (cuentaDestino === -1 || cuentaDestino === null) {
      mensajesError = { ...mensajesError, cuentaCard: "campo requerido" };
    }
    // }

    if (mostrarFormularioCompleto) {
      if (banco.length === 0) {
        mensajesError = { ...mensajesError, banco: "campo requerido" };
      }
      if (tipoDeCuenta.length === 0) {
        mensajesError = { ...mensajesError, tipoCuenta: "campo requerido" };
      } else {
        let tipoCuentaObject = JSON.parse(tipoDeCuenta);
        if (nroDeCuenta.length === 0) {
          mensajesError = { ...mensajesError, nroCuenta: "campo requerido" };
        } else {
          if (nroDeCuenta.length < tipoCuentaObject.r_longitud) {
            mensajesError = {
              ...mensajesError,
              nroCuenta: `campo de ${tipoCuentaObject.r_longitud} digitos`,
            };
          }
        }
        if (parseInt(tipoCuentaObject.r_fecha_vencimiento) === 1) {
          if (yearTarjeta.length === 0) {
            mensajesError = { ...mensajesError, anioYmes: "campo requerido" };
          }
          if (monthTarjeta.length === 0) {
            mensajesError = { ...mensajesError, anioYmes: "campo requerido" };
          }
        }
      }
    }

    if (montoACargar.length > 0) {
      montoLocal = montoACargar.replace("S/ ", "").replace(/,/g, "");
      if (parseFloat(montoLocal) === 0.0) {
        mensajesError = { ...mensajesError, monto: "monto mayor a 0" };
      }
    } else {
      mensajesError = { ...mensajesError, monto: "campo requerido" };
    }

    if (!frecuenciaCargo) {
      mensajesError = { ...mensajesError, frecuenciaCargo: "campo requerido" };
    }

    if (frecuenciaCargo === "1") {
      if (!fechaCargo) {
        mensajesError = { ...mensajesError, fechaCargo: "campo requerido" };
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const validarMonto = () => {
    let montoLocal = montoACargar.replace("S/ ", "").replace(/,/g, "");
    let retornamos = false;
    if (parseFloat(montoLocal) >= 25000) {
      retornamos = true;
    }
    return retornamos;
  };
  const procesarSolicitudNormal = (dj, token) => {
    let objetoProcesado = {};
    let apertura = {
      v_fondo_destino: "",
    };
    let debito = {
      v_codigo_banco: "",
      v_tipo_cuenta: "",
      v_num_cuenta: "",
      v_codigo_moneda: "",
      v_fecha_venc: "",
      v_monto: "",
      v_cod_frecuencia: "",
      v_referencia: "",
    };
    let datoTipoCuenta = "";
    let datoCuentaDestino = "";
    if (data.r_validacion === "1") {
      // seleccionar fondo
      apertura.v_fondo_destino = fondoSeleccionado;
      //
      objetoProcesado.apertura = apertura;
    }

    debito = {
      ...debito,
      v_monto: parseFloat(montoACargar.replace("S/ ", "").replace(/,/g, "")),
      v_cod_frecuencia: frecuenciaCargo,
    };

    if (mostrarFormularioCompleto) {
      debito = {
        ...debito,
        v_codigo_banco: banco.r_codigo,
        v_tipo_cuenta: JSON.parse(tipoDeCuenta).r_codigo,
        v_num_cuenta:
          Number(banco.r_codigo) === 7
            ? obtenerCtaRemesa(nroDeCuenta)
            : nroDeCuenta,
        v_codigo_moneda: monedas.defaultSelect,
        v_fecha_venc: `${monthTarjeta}${yearTarjeta}`,
      };
      datoTipoCuenta = JSON.parse(tipoDeCuenta);
    } else {
      // selecciono una tarjeta pre cargada
      debito = {
        ...debito,
        v_referencia: cuentaDestino.r_codigo,
      };
      datoCuentaDestino = cuentaDestino;
    }
    objetoProcesado.debito = debito;
    objetoProcesado.fatca = 0;
    objetoProcesado.fecha_cargo = fechaCargo;
    objetoProcesado.cargo_doble = cargoDoble;

    if (dj) {
      objetoProcesado.dj = dj;
    }

    setConfirmacionInfo({
      data: objetoProcesado,
      banco: banco,
      tipoCuenta: datoTipoCuenta,
      cuentaDestino: datoCuentaDestino,
    });
    setShowModalInfo(!showModalInfo);
  };

  const adjuntarDeclaracionJurada = (dj, token) => {
    procesarSolicitudNormal(dj, token);
    setDeclaracionJurada(null);
  };

  const getFondo = (fondo) => {
    switch (fondo) {
      case 1:
        return "fondo 1 - conservador";
      case 2:
        return "fondo 2 - moderado";
      case 3:
        return "fondo 3 - arriesgado";
      default:
        return " ";
    }
  };

  const buttonAtras = () => {
    if (step === 0 && data.r_fondo.length > 1) {
      //DATALAYER INVERTIR
      // console.log("CONTINUAR 42: ", getFondo(fondoSeleccionado));
      window.dataLayer.push({
        event: "button_click",
        product_name: "fondo libre habitat",
        step_name: "paso 1 - seleccion tipo de fondo",
        description: "elige tu fondo",
        fund_type: getFondo(fondoSeleccionado),
        option: "volver",
      });
    }

    if (
      (step === 0 && data.r_fondo.length === 1) ||
      (step === 1 && data.r_fondo.length > 1)
    ) {
      //DATALAYER INVERTIR
      // console.log(
      //   "CONTINUAR VOLVER 57",
      //   frecuenciaCargo,
      //   frecuenciaCargo ? (frecuenciaCargo == "0" ? "unica" : "mensual") : ""
      // );
      window.dataLayer.push({
        event: "button_click",
        product_name: "fondo libre habitat",
        step_name: "paso 2 - datos de inversion",
        debit_frequency: frecuenciaCargo
          ? frecuenciaCargo == "0"
            ? "unica"
            : "mensual"
          : "",
        option: "volver",
      });
    }

    if (step === 0) {
      history.push("/mi-habitat-digital/afiliado/inicio");
    }
    if (step === 1) {
      setStep(step - 1);
      setBreadCrumb(0);
      setFondoSeleccionado("");
      scroll.scrollToTop({
        duration: 0,
        delay: 0,
      });
      let mensajesError = {
        ...errores,
        fondo: "",
        cuentaCard: "",
        banco: "",
        tipoCuenta: "",
        anioYmes: "",
        nroCuenta: "",
        monto: "",
        anioYmes: "",
      };
      setErrores(mensajesError);
    }
  };

  const procesar = () => {
    if (step === 0 && data.r_fondo.length > 1) {
      if (fondoSeleccionado === "") {
        let mensajesError = { ...errores, fondo: "campo requerido" };
        setErrores(mensajesError);
      } else {
        //DATALAYER INVERTIR
        // console.log("CONTINUAR 42: ", getFondo(fondoSeleccionado));
        window.dataLayer.push({
          event: "button_click",
          product_name: "fondo libre habitat",
          step_name: "paso 1 - seleccion tipo de fondo",
          description: "elige tu fondo",
          fund_type: getFondo(fondoSeleccionado),
          option: "siguiente",
        });
        setStep(1);
        setBreadCrumb(1);
        scroll.scrollToTop({
          duration: 0,
          delay: 0,
        });
        setErrores({ ...errores, fondo: "" });
      }
    }

    if (
      (step === 0 && data.r_fondo.length === 1) ||
      (step === 1 && data.r_fondo.length > 1)
    ) {
      if (validarData()) {
        //DATALAYER INVERTIR
        // console.log(
        //   "SIGUIENTE 57",
        //   frecuenciaCargo == "0" ? "unica" : "mensual"
        // );
        window.dataLayer.push({
          event: "button_click",
          product_name: "fondo libre habitat",
          step_name: "paso 2 - datos de inversion",
          debit_frequency: frecuenciaCargo == "0" ? "unica" : "mensual",
          option: "siguiente",
        });
        if (validarMonto()) {
          setDeclaracionJurada(true);
          scroll.scrollToTop();
        } else {
          procesarSolicitudNormal(null);
        }
      }
    }

    if (step === 2) {
      //DATALAYER INVERTIR
      // console.log("volver al inicio 75");
      window.dataLayer.push({
        event: "button_click",
        product_name: "fondo libre habitat",
        step_name: "paso 3 - confirmacion de solicitud",
        description: "datos de la solicitud",
        option: "volver al inicio",
      });
      history.push("/mi-habitat-digital/afiliado/inicio");
    }
  };

  /* Función para la lista de tipo de cuenta el usuario desea */
  function listTipoCuenta() {
    if (filtroTipoCuenta.length > 0) {
      return filtroTipoCuenta.map((item, index) => (
        <MenuItem
          sx={styles.menuItemStyles}
          key={index}
          value={JSON.stringify(item)}
        >
          {item.r_descripcion}
        </MenuItem>
      ));
    } else {
      return "";
    }
  }

  const listAnio = () => {
    let date = new Date();
    let anioActual = date.getFullYear();
    let anios = [];
    for (let i = 0; i < 10; i++) {
      anios.push(anioActual++);
    }
    return anios;
  };

  const listMonth = (option) => {
    let date = new Date();
    let anioActual = date.getFullYear();
    let mesActual = date.getMonth();
    let localMeses = meses;
    if (option.length > 0) {
      if (parseInt(option) === parseInt(anioActual)) {
        localMeses = meses.slice(parseInt(mesActual), meses.length);
      }

      return localMeses;
    } else {
      return "";
    }
  };

  const procesarSolicitud = async (token) => {
    try {
      confirmacionInfo.data.v_token_digital = token;

      const response = await aportarApvSinFin(confirmacionInfo.data);
    } catch (error) {
      console.log({ error });
    }
  };

  // useEffect(() => {
  //   setStep(2);
  // }, []);

  // console.log({
  //   opcionSeleccionada,
  //   banco,
  //   montoACargar,
  //   tipoDeCuenta,
  //   yearTarjeta,
  //   monthTarjeta,
  //   nroDeCuenta,
  //   frecuenciaCargo,
  //   fechaCargo,
  //   cargoDoble,
  //   cuentaDestino,
  // });

  return (
    <Paper
      className={
        matches
          ? classes.paperContainerStylesChanges
          : classes.paperContainerStyles
      }
    >
      {/* {false ? ( */}
      {/* {showOnboard ? (
        <Onboarding setShowOnboard={setShowOnboard} showOnboard={showOnboard} />
      ) : ( */}
      <>
        <Box sx={{ pt: 2, pb: 3 }}>
          <HorizontalStepper
            step={step}
            stepLength={stepLength}
            opcionSeleccionada={opcionSeleccionada}
            setOpcionSeleccionada={setOpcionSeleccionada}
          />
        </Box>
        <BodyPageWrapper>
          {step !== 2 && (
            <>
              <Grid container sx={{ pb: 2 }}>
                <Grid item xs={matches ? 12 : 12}>
                  {data.r_validacion === "2" && step === 0 ? (
                    <Box>
                      <MessageSnack
                        alt="ICON_INFO"
                        icon={ICON_INFO}
                        shadow={false}
                        description={
                          "Recuerda que debes ser el titular de la cuenta o tarjeta que hayas ingresado."
                        }
                        color={"#EFBD0C"}
                      />
                    </Box>
                  ) : (
                    <>
                      {step === 0 && data.r_fondo.length > 1 && (
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                          <Grid item xs={12} lg={12}>
                            <Tipography component="body2">
                              Elige tu fondo
                            </Tipography>
                          </Grid>

                          {/* Card para los tipos de fondo */}
                          <BasicCard
                            info={InfoCard}
                            defaultSelect="-1"
                            selectOption={setFondoSeleccionado}
                            buttonType="eligeFondo"
                            step={step}
                          />
                        </Grid>
                      )}

                      {errores.fondo && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#CE1336",
                            padding: "0 12px",
                          }}
                        >
                          {errores.fondo}
                        </span>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              {(step === 1 && data.r_fondo.length > 1) ||
              (step === 0 && data.r_fondo.length === 1) ? (
                <>
                  <DatosDeInversion
                    fondoSeleccionado={fondoSeleccionado}
                    cuentas={data.r_cuentas_detalle}
                    setCuenta={opcionSeleccionadaCard}
                    step={step}
                    // new account props
                    data={data}
                    handlerbanco={handlerbanco}
                    tipoDeCuenta={tipoDeCuenta}
                    handlerTipoCuenta={handlerTipoCuenta}
                    mostrarFecha={mostrarFecha}
                    yearTarjeta={yearTarjeta}
                    handlerYear={handlerYear}
                    listMonth={listMonth}
                    banco={banco}
                    handlerNroCuenta={handlerNroCuenta}
                    errores={errores}
                    listTipoCuenta={listTipoCuenta}
                    listAnio={listAnio}
                    monthTarjeta={monthTarjeta}
                    handlerMonth={handlerMonth}
                    longitudNroCuenta={longitudNroCuenta}
                    nroDeCuenta={nroDeCuenta}
                    filtroTipoCuenta={filtroTipoCuenta}
                    mostrarFormularioCompleto={mostrarFormularioCompleto}
                    // informacion de invercion
                    setFrecuenciaCargo={handlerFrecuenciaCargo}
                    montoACargar={montoACargar}
                    handlerMonto={handlerMonto}
                    frecuenciaCargo={frecuenciaCargo}
                    setFechaCargo={handlerFechaCargo}
                    fechaCargo={fechaCargo}
                    cargoDoble={cargoDoble}
                    setCargoDoble={setCargoDoble}
                  />
                </>
              ) : null}
            </>
          )}
          {step === 2 && datosResultadoAportarApvSinFin && (
            <PasoConfirmacionSolicitud data={datosResultadoAportarApvSinFin} />
          )}
          <BotonesDeNavegacion
            onPrevious={buttonAtras}
            onNext={procesar}
            step={step}
          />
        </BodyPageWrapper>
      </>

      <ModalDeErrorGeneral
        open={modalDeErrorGeneralIsOpen}
        mensajeAlertaPost={mensajeAlertaPost}
        onClose={() => setModalDeErrorGeneralIsOpen(false)}
      />

      {confirmacionInfo && (
        <ModalDeConfirmacion
          data={confirmacionInfo}
          open={showModalInfo}
          onClose={() => {
            //DATALAYER INVERTIR
            // console.log(
            //   "confirmacion (volver) 62",
            //   getFondo(fondoSeleccionado)
            // );
            window.dataLayer.push({
              event: "button_click",
              product_name: "fondo libre habitat",
              step_name: "paso 2 - datos de inversion",
              description: "confirmacion",
              debit_frequency: getFondo(fondoSeleccionado),
              option: "volver",
            });
            setShowModalInfo(false);
          }}
          onNext={() => {
            //DATALAYER INVERTIR
            // console.log(
            //   "confirmacion (volver) 62",
            //   getFondo(fondoSeleccionado)
            // );
            window.dataLayer.push({
              event: "button_click",
              product_name: "fondo libre habitat",
              step_name: "paso 2 - datos de inversion",
              description: "confirmacion",
              debit_frequency: getFondo(fondoSeleccionado),
              option: "confirmar",
            });
            setOpenModalToken(true);
            setShowModalInfo(false);
          }}
        />
      )}

      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(!openModalToken);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {openModalTokenError && (
        <ModalExitFatca
          message={resultado.r_mensaje}
          cerrarModal={() => {
            setOpenModalTokenError(false);
          }} // fn para cerrar modal
          guardarCode={() => {
            setOpenModalTokenError(false);
            setOpenModalToken(true);
          }}
        />
      )}
      {DeclaracionJurada && (
        <ModalDeclaracionJurada
          openModal={DeclaracionJurada}
          closeModal={closeDeclaracionJurada}
          adjuntarDeclaracionJurada={adjuntarDeclaracionJurada}
        />
      )}
    </Paper>
  );
};

export default hocWidthWindow(PrimerPaso);
