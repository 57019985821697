import styled, { css } from "styled-components";

export const DividerComponent = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.palette.grayscale[100]};

  ${(p) =>
    p.size === "md" &&
    css`
      border-width: 2px;
    `}

  ${(p) =>
    p.size === "lg" &&
    css`
      border-width: 3px;
    `}

  margin-top: 16px;
  margin-bottom: 16px;
`;
