import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import JubilacionContext from "../../../../context/jubilacion/pmo/jubPmoContext";
import ModalContext from "../../../../context/modal/modalContext";
import Loading from "../../../../components/Loading/index.jsx";
import { ViewComponent, StepComponent } from "./component";
import { JubilacionPMOComponent } from "./styles";
import ModalDeErrorGeneral from "../../Components/ModalDeErrorGeneral";

export default function JubilacionRejaWorkflow(props) {
  const [showStepComponent, setShowStepComponent] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [currectStepNumber, setCurrectStepNumber] = useState("asesoria");
  const [showStepper, setShowStepper] = useState(false);
  const [message, setMessageTraspaso] = useState("");
  const jubilacionContext = useContext(JubilacionContext);
  const {
    dataAccessPmo,
    obtenerAccesoPmo,
    errorAccespmo,
    loadingAccessPmo,
    resetearEstados,
  } = jubilacionContext;
  const [showModal, setShowModal] = useState(false);

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerAccesoPmo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currectStepNumber]);
  useEffect(() => {
    if (dataAccessPmo) {
      if (dataAccessPmo.success) {
        if (dataAccessPmo.traspaso === 1) {
          setMessageTraspaso(dataAccessPmo.message);
        } else {
          if (dataAccessPmo.decision === 1) {
            setMessageTraspaso(dataAccessPmo.message);
          } else {
            if (dataAccessPmo.r_acceso === 1) {
              setCurrectStepNumber("calculo");
              setShowModify(true);
              setShowStepper(false);
            } else {
              setCurrectStepNumber("asesoria");
              setShowStepper(true);
            }
          }
        }
      } else {
        setShowModal(true);
      }
    }
  }, [dataAccessPmo]);

  useEffect(() => {
    return () => {
      resetearEstados();
    };
  }, []);

  const getOutOfProcess = () => {
    props.history.push("/mi-habitat-digital/afiliado/jubilacion");
  };

  if (loadingAccessPmo && currectStepNumber) return <Loading />;
  return (
    <JubilacionPMOComponent>
      {showStepComponent ? (
        <StepComponent
          defaultStep={currectStepNumber}
          getOutOfProcess={getOutOfProcess}
          showStepper={showStepper}
          setShowModify={setShowModify}
          showModify={showModify}
          setShowStepper={setShowStepper}
          setShowStepComponent={setShowStepComponent}
        />
      ) : (
        <ViewComponent
          defaultStep={currectStepNumber}
          setShowStepComponent={setShowStepComponent}
          getOutOfProcess={getOutOfProcess}
          mensaje={message}
        />
      )}
      {showModal && <ModalDeErrorGeneral />}
    </JubilacionPMOComponent>
  );
}
