import debitDateOptions from "./debitDateOptions";

const getDebitFrecuencyDate = (date) => {
  const dateAsNumber = Number(date);

  const rangeOption = debitDateOptions.find(({ min, max }) => {
    const isInRange = dateAsNumber >= min && dateAsNumber <= max;
    return isInRange;
  });

  if (rangeOption) {
    return rangeOption.label;
  }

  return `${date} de cada mes`;
};

export default getDebitFrecuencyDate;
