import styled, { css } from "styled-components";
import Button from "../../../components/Buttons";
import { device } from "../../../utils/device";

export const PasoDocumentosComponent = styled.div`
  ${(p) =>
    css`
      @media screen and ${device.desktop} {
        max-width: calc(100% / 12 * 8);
        margin-left: auto;
        margin-right: auto;
      }
    `}
`;

export const Information = styled.div`
  div {
    margin-bottom: 16px;
    color: ${(p) => p.theme.palette.grayscale[300]};

    p.subtitle {
      ${(p) => p.theme.tipography.bodySmallMedium}
    }
    p.information {
      ${(p) => p.theme.tipography.bodySmallRegular}
    }
  }
  ${(p) =>
    css`
      @media screen and ${device.tablet} {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr;
      }
    `}
`;

export const CauseOfDeathWrapper = styled.div`
  ${(p) =>
    p.$shrink &&
    css`
      @media screen and ${device.tablet} {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    `}
`;

export const InputsWrapper = styled.div`
  ${(p) =>
    css`
      @media screen and ${device.tablet} {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr;
        align-items: end;
      }
    `}
`;

export const RequiredInputsCaption = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  margin-bottom : 24px;

  span {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const ButtonsWrapper = styled.div`
  ${(p) => css`
    @media screen and ${device.tablet} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: calc(480px + 16px);
      column-gap: 16px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

export const NextButton = styled(Button)``;

export const PrevButton = styled(Button)`
  display: none;

  ${(p) => css`
    @media screen and ${device.tablet} {
      display: grid;
      justify-self: end;
    }
  `}
`;
