import React from "react";
import Step from "./Step";
import "./index.css";

export default function MenuStep(props) {
  const { show, setShow, stepArray, currectStepNumber } = props;
  if (!show) return null;

  return (
    <div id="custom-menu" class={`modal-menu ${show ? "show" : "hide"}`}>
      <span className="close-menu" onClick={() => setShow(!show)}>
        &times;
      </span>
      <div className="menu-content">
        <h2>Avance de tu Trámite</h2>
        <div style={{ width: "330px" }}>
          {stepArray.map((item, index) => {
            const number = index + 1;
            return (
              <Step
                currectStepNumber={currectStepNumber}
                name={item.name}
                percentage={100}
                color={
                  "color-" +
                  (number > currectStepNumber
                    ? "pending"
                    : number < currectStepNumber
                    ? "finished"
                    : "active")
                }
                text1={"PASO"}
                number={number}
                position={number % 2 === 0 ? "left" : "right"}
                status={item.status}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
