import React, { useReducer } from "react";
import TransferenciaExteriorContext from "./transferenciaexteriorContext";
import TransferenciaExteriorReducer from "./transferenciaexteriorReducer";
import clienteAxios from "../../config/axios";
import {
  TRANSFERENCIA_EXTERIOR_POST_EXITOSO,
  TRANSFERENCIA_EXTERIOR_POST_ERROR,
  TRANSFERENCIA_EXTERIOR_POST_LOGIN,
  TRANSFERENCIA_EXTERIOR_CLEAR,
  TRANSFERENCIA_EXTERIOR_CLEAR_PARCIAL
} from "../../types/transferenciaExterior";

const TransferenciaExteriorState = (props) => {
  const initialState = {
    /**Actualizar Datos*/
    resultadoTransferenciaExteriorPost: null,
    mensajeAlertaTransferenciaExteriorPost: null,
    loadingBtnTransferenciaExteriorPost: false, // procesando la actualización de datos
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const transferenciaExteriorPost = async (dato) => {
    dispatch({
      type: TRANSFERENCIA_EXTERIOR_POST_LOGIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/av-afiliado", { tipo: dato });
      dispatch({
        type: TRANSFERENCIA_EXTERIOR_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, TRANSFERENCIA_EXTERIOR_POST_ERROR);
    }
  };

  const limpiarVariablesTransferenciaExterior = () => {
    dispatch({
      type: TRANSFERENCIA_EXTERIOR_CLEAR,
    });
  };
  const limpiarVariablesTransferenciaExteriorParcial = () => {
    dispatch({
      type: TRANSFERENCIA_EXTERIOR_CLEAR_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(TransferenciaExteriorReducer, initialState);
  return (
    <TransferenciaExteriorContext.Provider
      value={{
        resultadoTransferenciaExteriorPost: state.resultadoTransferenciaExteriorPost,
        mensajeAlertaTransferenciaExteriorPost: state.mensajeAlertaTransferenciaExteriorPost,
        loadingBtnTransferenciaExteriorPost: state.loadingBtnTransferenciaExteriorPost,
        transferenciaExteriorPost,
        limpiarVariablesTransferenciaExterior,
        limpiarVariablesTransferenciaExteriorParcial,
      }}
    >
      {props.children}
    </TransferenciaExteriorContext.Provider>
  );
};

export default TransferenciaExteriorState;
