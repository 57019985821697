import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 110 20">
      <path
        fill="#CE1336"
        fillRule="evenodd"
        d="M18.48 19.26H0V.74h18.48v18.52zm-6.558-3.145l1.383.016-.301-1.157 3.439.088-1.025-3.454 1.003.062-.381-1.232-2.225-.169.957 3.391-2.133-.086-.7-2.712-1.32-.09 1.303 5.343zm-2.21-1.226l.27 1.197 1.445.026-1.285-5.37-1.368-.095.621 2.798-2.387-.1-.717-3.644-2.732-.211.229 1.392 1.267.08.69 3.825 3.967.102zm-5.22-7.094l-1.229-.111.22 1.331 2.718.22-.65-3.322 2.22.251.561 2.504 1.344.117-1.09-4.56-1.283-.172.205.924-3.6-.438.583 3.256zm6.017-2.445l-.236-.903-1.237-.164 1.107 4.543 1.296.114-.629-2.438 1.999.228.88 3.104 2.217.179-.364-1.186-.982-.091-.89-2.997-3.161-.389zM25.03.744h.738l2.62 4.883h-.682l-.708-1.309h-3.33l-.71 1.309h-.633L25.03.744zm.321.46L23.913 3.9h2.865l-1.427-2.695zM60.488 6.424h-5.994v12.787h5.994c2.163 0 6.045-.476 6.045-3.7 0-1.574-1.598-2.748-3.932-2.913v-.035c2.04-.275 3.415-1.338 3.415-2.86 0-2.802-3.316-3.279-5.528-3.279zm-.932 10.773h-1.622v-3.702h1.622c1.72 0 3.539.256 3.539 1.925 0 1.484-1.94 1.777-3.539 1.777zm-.222-5.607h-1.4V8.439h1.326c1.574 0 3.319.11 3.319 1.594 0 1.32-1.795 1.557-3.245 1.557z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#CE1336"
        d="M25.782 6.426H22.41v12.787h3.37v-5.606h6.742v5.606h3.372V6.426h-3.372v5.166H25.78V6.426z"
      ></path>
      <path
        fill="#CE1336"
        fillRule="evenodd"
        d="M43.041 6.426h3.902l6.77 12.788h-3.828l-1.482-2.93H41.41l-1.506 2.93h-3.583l6.72-12.788zm1.602 2.565H44.6l-2.236 5.276h4.516l-2.236-5.276z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#CE1336"
        d="M71.458 6.426h-3.46v12.788h3.46V6.426zM72.975 8.442h4.885v10.771h3.42V8.443h4.887V6.425H72.975v2.016z"
      ></path>
      <path
        fill="#CE1336"
        fillRule="evenodd"
        d="M89.463 6.426h3.859l6.692 12.788h-3.787l-1.464-2.93H87.85l-1.49 2.93H82.82l6.644-12.788zm1.88 2.565h-.048l-2.468 5.276h4.982l-2.465-5.276z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#CE1336"
        d="M96.674 8.442h4.936v10.771h3.454V8.443h3.264L110 6.425H96.674v2.016zM31.742.744h-3.115v4.883h.647V3.325h2.355v-.42h-2.355V1.163h2.468v-.42z"
      ></path>
      <path
        fill="#CE1336"
        fillRule="evenodd"
        d="M32.42.744h1.607c1.224 0 2.02.46 2.02 1.384 0 .91-.917 1.385-2.086 1.385h-.897v2.114h-.644V.744zm.645 2.35h.448c.973 0 1.889-.084 1.889-.964 0-.616-.467-.965-1.29-.965h-1.047v1.93z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
