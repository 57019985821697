import {
  CODE_BLOQUEADA_GET_EXITOSO,
  CODE_BLOQUEADA_BTN_LOADIN,
  CODE_BLOQUEADA_GET_ERROR,
  CODE_BLOQUEADA_CLEANER,
  CODE_BLOQUEADA_CLEANER_PARCIAL,
  CODE_BLOQUEADA_CLEANER_POST,
  CODE_BLOQUEADA_POST_EXITOSO,
  CODE_BLOQUEADA_POST_ERROR,
  CODE_BLOQUEADA_POST_LOADIN,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case CODE_BLOQUEADA_GET_EXITOSO:
      return {
        ...state,
        dataModalCode: action.payload,
        mensajeGetAlerta: null,
        loadingGetBtn: false,
      };
    case CODE_BLOQUEADA_GET_ERROR:
      return {
        ...state,
        dataModalCode: null,
        mensajeGetAlerta: action.payload,
        loadingGetBtn: false,
      };
    case CODE_BLOQUEADA_BTN_LOADIN:
      return {
        ...state,
        loadingGetBtn: true,
      };
    case CODE_BLOQUEADA_POST_EXITOSO:
      return {
        ...state,
        dataPostCode: action.payload,
        mensajePostAlerta: null,
        loadingPostBtn: false,
      };
    case CODE_BLOQUEADA_POST_ERROR:
      return {
        ...state,
        dataPostCode: null,
        mensajePostAlerta: action.payload,
        loadingGetBtn: false,
      };
    case CODE_BLOQUEADA_POST_LOADIN:
      return {
        ...state,
        loadingPostBtn: true,
      };
    case CODE_BLOQUEADA_CLEANER:
      return {
        ...state,
        dataModalCode: null,
        mensajeGetAlerta: null,
        loadingGetBtn: false,
        dataPostCode: null,
        mensajePostAlerta: null,
        loadingPostBtn: false,
      };
    case CODE_BLOQUEADA_CLEANER_POST:
      return {
        ...state,
        dataPostCode: null,
        mensajePostAlerta: null,
        loadingPostBtn: false,
      };
    case CODE_BLOQUEADA_CLEANER_PARCIAL:
      return {
        ...state,
        dataModalCode: null,
        // mensajeGetAlerta: null,
        loadingGetBtn: false,
        dataPostCode: null,
        mensajePostAlerta: null,
        loadingPostBtn: false,
      };
    default:
      break;
  }
};
