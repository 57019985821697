import styled, { css } from "styled-components";

export const Icon = styled.div`
  background-color: ${(p) => p.theme.palette.primary[50]};
  width: 56px;
  height: 56px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 8px;
  grid-area: icon;
  align-self: center;
  stroke: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const Title = styled.div`
  grid-area: title;
  ${(p) => p.theme.tipography.subtitleM}
  margin-bottom: 6px;
  text-align: left;
  align-self: center;
  color: ${(p) => p.theme.palette.grayscale[800]};
`;

export const Description = styled.div`
  grid-area: description;
  text-align: left;
  ${(p) => p.theme.tipography.bodySmallLight}
  color: ${(p) => p.theme.palette.grayscale[800]};
`;

export const NextButton = styled.div`
  grid-area: button;
  align-self: center;
  color: ${(p) => p.theme.palette.grayscale[800]};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ListItemComponent = styled.button`
  border: 1px solid ${(p) => p.theme.palette.grayscale[100]};
  padding: 16px;
  border-radius: 16px;
  box-shadow: ${(p) => p.theme.elevations.below};
  display: grid;
  margin: 0;
  grid-template-areas:
    "icon title button"
    "icon description button";
  column-gap: 16px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.white};
  width: 100%;

  ${(p) =>
    p.size === "sm" &&
    css`
      grid-template-areas: "icon title button";
      padding: 16px;

      ${Title} {
        margin-bottom: 0px;
      }

      ${Icon} {
        width: auto;
        height: auto;
        background-color: transparent;
        stroke: ${(p) => p.theme.palette.grayscale[800]};
        color: ${(p) => p.theme.palette.grayscale[800]};

        svg {
          width: 16px;
          height: 16px;
        }
      }
    `}

  ${(p) =>
    p.size === "md" &&
    css`
      grid-template-areas: "icon title button";
      padding: 24px 16px;

      ${Title} {
        margin-bottom: 0px;
      }

      ${Icon} {
        width: 32px;
        height: 32px;

        svg {
          width: 16px;
          width: 16px;
        }
      }
    `}

  &:hover {
    box-shadow: ${(p) => p.theme.elevations.belowMd};
  }

  &:disabled {
    box-shadow: none;
    cursor: default;

    ${Icon} {
      background-color: ${(p) => p.theme.palette.grayscale[50]};
      stroke: ${(p) => p.theme.palette.grayscale[200]};
      color: ${(p) => p.theme.palette.grayscale[200]};
    }
    ${Title} {
      color: ${(p) => p.theme.palette.grayscale[200]};
    }
    ${Description} {
      color: ${(p) => p.theme.palette.grayscale[200]};
    }
    ${NextButton} {
      color: ${(p) => p.theme.palette.grayscale[200]};
    }
  }
`;
