import React, { useState, useEffect, useContext } from "react";
import { DetallePagoFila, DetallePagoFilaDesktop } from "./components";
import { UncontrolledTooltip } from "reactstrap";
import PagosPendientesContext from "../../context/pagosPendientes/pagosPendientesContext";
import ModalContext from "../../context/modal/modalContext";
import Loading from "../../components/Loading/index.jsx";
const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];
export default function PagoPendienteDetalle(props) {
  const pagosPendientesContext = useContext(PagosPendientesContext);
  const {
    dataDeudaPeriodos,
    loadingPage,
    mensajeAlerta,
    obtenerDeudaPeriodos,
    limpiarDeudaPeriodos,
  } = pagosPendientesContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const [periodSelect, setPeriodSelect] = useState(0);
  const [busissname, setBusissname] = useState("");
  const [stateName, setStateName] = useState("");
  const [ruc, setRuc] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setBusissname(sessionStorage.getItem("nombre_empresa"));
    setStateName(sessionStorage.getItem("estado_empresa"));
    const rucTemp = sessionStorage.getItem("ruc_empresa");
    setRuc(rucTemp);
    obtenerDeudaPeriodos(rucTemp);

    return () => {
      sessionStorage.removeItem("nombre_empresa");
      sessionStorage.removeItem("ruc_empresa");
      sessionStorage.removeItem("estado_empresa");
      sessionStorage.removeItem("estado_deuda");
      limpiarDeudaPeriodos();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlerta) {
      mostrarMensaje();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  useEffect(() => {
    if (dataDeudaPeriodos) {
      setData(dataDeudaPeriodos);
    }
  }, [dataDeudaPeriodos]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (loadingPage) return <Loading />;

  const newdata = Object.values(data);
  const addValue = newdata.map((item) => {
    const splitA = item.r_periodo.split(" ");
    const monthValue = months.indexOf(splitA[0]) + 1;
    const month =
      monthValue.toString().length === 1 ? "0" + monthValue : monthValue;
    item.numDate = Number(splitA[1]) + "" + month;
    return item;
  });

  const sortDateNumber = addValue.sort((a, b) => {
    return a.numDate - b.numDate;
  });
  return (
    <div className="container payment-detail-section">
      <div className="business-information">
        <h1 className="text-center business-name">{busissname}</h1>
        <p className="business-number">RUC: {ruc}</p>
        <p className="business-number">Estado: {stateName}</p>

        <span className="name-state">
          {stateName !== "Ubicable"
            ? " *En caso usted cuente con la dirección actual del empleador, informarla a la AFP a través de los canales de servicio al cliente para realizar la verificación domiciliaria y con ello reanudar las gestiones de cobranza."
            : ""}
        </span>
      </div>

      <div className="payment-detail-periods mobile">
        <div className="row-list">
          <div className="left-side"></div>
          <div className="right-side">
            <div className="header-list-period">
              <div className="header-left text-center">
                <span>
                  Tipo de <br />
                  deuda{" "}
                  <i
                    id="tipo-deuda-mobile"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip placement="top" target="tipo-deuda-mobile">
                  Deuda presunta: Su empleador no ha pagado o no ha informado el
                  término del vínculo laboral. <br />
                  Deuda real: Se tiene un documento o confirmación que se retuvo
                  un aporte por parte del empleador.
                </UncontrolledTooltip>
              </div>

              <div className="header-right text-center">
                <span>
                  Deuda <br /> total{" "}
                  <i
                    id="deuda-actalizada"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip placement="left" target="deuda-actalizada">
                  Suma del fondo total + <br /> Seguro + Comisión AFP
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="container-period">
          {sortDateNumber.map((value, index) => (
            <DetallePagoFila
              key={index}
              id={index}
              afp={value.r_afp}
              date={value.r_periodo}
              debtType={value.r_tipo_deuda}
              updated={value.r_total_nominal}
              periodSelect={periodSelect}
              setPeriodSelect={setPeriodSelect}
              updatedBackground={value.r_fondo_nominal}
              ryrValue={value.r_ryr_nominal}
              state={value.r_estado_gestion}
            />
          ))}
        </div>
      </div>

      <div className="payment-detail-periods desktop">
        <div className="row-list">
          <div className="left-side-desktop"></div>
          <div className="center-side-desktop">
            <div className="header-list-period-desktop">
              <div className="header-desktop text-center">
                <span>
                  Tipo de <br />
                  deuda{" "}
                  <i
                    id="tipo-deuda-desktop"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="left"
                  target="tipo-deuda-desktop"
                >
                  Deuda presunta: Su empleador no ha pagado o no ha informado el
                  término del vínculo laboral. <br />
                  Deuda real: Se tiene un documento o confirmación que se retuvo
                  un aporte por parte del empleador.
                </UncontrolledTooltip>
              </div>

              <div className="header-desktop text-center">
                <span>
                  Deuda <br />
                  total{" "}
                  <i
                    id="deuda-actalizada-desktop"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="left"
                  target="deuda-actalizada-desktop"
                >
                  Suma del fondo total + <br /> Seguro + Comisión AFP
                </UncontrolledTooltip>
              </div>
              <div className="header-desktop text-center">
                <span>
                  Fondo <br />
                  total{" "}
                  <i
                    id="fondo-actalizado-desktop"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="left"
                  target="fondo-actalizado-desktop"
                >
                  Aporte pendiente de ingresar a tu fondo
                </UncontrolledTooltip>
              </div>
              <div className="header-desktop text-center">
                <span>
                  Seguro <br /> y comisión{" "}
                  <i
                    id="ryr-actalizado-desktop"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="left"
                  target="ryr-actalizado-desktop"
                >
                  Seguro + Comisión AFP
                </UncontrolledTooltip>
              </div>
              <div className="header-desktop text-center">
                <span>
                  AFP
                  <i
                    id="afp-responsable-desktop"
                    className="fa fa-info-circle"
                    aria-hidden="true"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="left"
                  target="afp-responsable-desktop"
                >
                  AFP responsable del cobro
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
          <div className="right-side-desktop"></div>
        </div>
        <div className="container-period-destok">
          {sortDateNumber.map((value, index) => (
            <DetallePagoFilaDesktop
              key={index}
              id={index}
              afp={value.r_afp}
              company_status={value.r_company_status}
              date={value.r_periodo}
              debtType={value.r_tipo_deuda}
              updated={value.r_total_nominal}
              updatedBackground={value.r_fondo_nominal}
              ryrValue={value.r_ryr_nominal}
              state={value.r_estado_gestion}
            />
          ))}
        </div>
      </div>
      <div className="payment-detail-footer">
        <button
          className="btn-main-red"
          onClick={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/informacion/pagos-pendientes"
            )
          }
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
          Volver
        </button>
      </div>
    </div>
  );
}
