import React, { useState } from "react";
import { montoFormat } from "../../../helper/amountFormat";
/* Icon component */
import SavingIcon from "../../../assets/IconBucket/Icon/SavingIcon";
import ChartIcon from "../../../assets/IconBucket/Icon/ChartIcon";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Chip,
  useTheme,
  useMediaQuery,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, createStyles } from "@mui/styles";

import ICON_ADVERTENCIA from "../../../assets/icons/ICON_ADVERTENCIA.svg";
/* icon material ui */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const screenWidth = window.innerWidth;

const screenHeight = window.innerHeight;

const heightDialog = screenHeight - 320;
/* estilos del componente */
export const styles = {
  cardStyle: {
    width: "98%",
    fontSize: "10px",
    border: "1.5px solid #CDCED0",
    borderRadius: "16px",
    cursor: "pointer",
    boxShadow: "0px 4.09442px 13.3069px rgba(27, 29, 39, 0.12)",
    userSelect: "none",
    mb: 2,
  },

  cardStyleDesktop: {
    minWidth: "342px",
    width: "auto",
    marginRight: "24px",
    fontSize: "10px",
    border: "1.5px solid #CDCED0",
    borderRadius: "16px",
    cursor: "pointer",
    boxShadow: "0px 4.09442px 13.3069px rgba(27, 29, 39, 0.12)",
    userSelect: "none",
    mb: 2,
  },

  gridIcon: {
    // minWidth: "40px",
    backgroundColor: "#CE1336",
    border: 0,
    borderRadius: "16px  0 0 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const useStyles = makeStyles({
  DialoMessageStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      top: `${heightDialog}px !important`,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ProductCard = ({
  datosCambioFondo,
  selectOption,
  defaultSelect,
  step,
  setStep,
  setOpen,
  setShowStatus,
  setSwipeState,
  setFondoActual,
  setEtario,
}) => {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(defaultSelect);

  const minWidth = useMediaQuery("(max-width:360px)");

  /* material ui */
  const [closeProcess, setCloseProcess] = React.useState(false);
  const [namePorduct, setNameProduct] = React.useState("");
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesCard = useMediaQuery(theme.breakpoints.down("xl"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  //const desktop = useMediaQuery(theme.breakpoints.down("desktop"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* manejo del evento onClick */
  const handleClick = (option) => (e) => {
    if (
      option.r_estado === "EN TRAMITE" ||
      option.r_estado === "NO PERMITIDO POR PLAZO*"
    ) {
      if (option.r_aplica) {
        if (option.r_aplica === "aplica") {
          setShowStatus({ ...option });
        } else {
          setEtario(true);
          setShowStatus({ ...option });
        }
      } else {
        setShowStatus({ ...option });
      }
    } else if (option.r_estado === "RETIRO APV") {
      setCloseProcess(!closeProcess);
      setNameProduct(
        option.r_aporte === "APORTE OBLIGATORIO"
          ? "Fondo de Jubilación"
          : option.r_aporte === "APORTE VOLUNTARIO CON FIN"
          ? "Fondo con Fin Previsional"
          : "Fondo Libre Habitat"
      );
    } else {
      if (option.r_aplica === "aplica") {
        setOpen(true);
      } else {
        setEtario(false);
        e.preventDefault();
        setOpcionSeleccionada(option);
        setSwipeState(Number(option.r_fondo_origen));
        selectOption({ ...option });
        setStep(0);
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      {datosCambioFondo.map((item, index) => (
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Card
            key={index}
            sx={matchesCard ? styles.cardStyle : styles.cardStyleDesktop}
            onClick={
              step >= 1
                ? ""
                : handleClick({
                    r_aporte: item.r_aporte,
                    r_aplica: item.r_aplica,
                    r_descripcion: item.r_descripcion,
                    r_estado: item.r_estado,
                    r_fecha_solicitud: item.r_fecha_solicitud,
                    r_fondo_destino: item.r_fondo_destino,
                    r_fondo_origen: item.r_fondo_origen,
                    r_num_formulario: item.r_num_formulario,
                    r_fecha_estimada: item.r_fecha_estimada,
                    r_saldo: item.r_saldo,
                    r_tipo_aporte: item.r_tipo_aporte,
                  })
            }
          >
            <Grid container>
              <Grid item sx={styles.gridIcon} xs={1}>
                {item.r_aporte === "APORTE OBLIGATORIO" && (
                  <SavingIcon size={24} color="white" />
                )}
                {item.r_aporte === "APORTE VOLUNTARIO CON FIN" && (
                  <SavingIcon size={24} color="white" />
                )}
                {item.r_aporte === "APORTE VOLUNTARIO SIN FIN" && (
                  <ChartIcon size={24} color="white" />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                sx={{
                  pl: 1,
                  pt: "13px",
                  pb: "13px",
                  pr: "13px",
                }}
              >
                <Grid
                  container
                  sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.r_aporte === "APORTE OBLIGATORIO" &&
                            "Fondo de Jubilación"}
                          {item.r_aporte === "APORTE VOLUNTARIO CON FIN" &&
                            "Fondo con Fin Previsional"}
                          {item.r_aporte !== "APORTE VOLUNTARIO CON FIN" &&
                            item.r_aporte !== "APORTE OBLIGATORIO" &&
                            "Fondo Libre Habitat"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.r_estado !== "RETIRO APV" && (
                          <Box
                            sx={{
                              width: "100%",
                              minHeight: "19.50px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border:
                                item.r_estado === null
                                  ? "none"
                                  : item.r_estado === "EN TRAMITE"
                                  ? "1px solid #396DBB"
                                  : "1px solid #1B7935",
                              borderRadius: "16px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "9px",
                                color:
                                  item.r_estado === "EN TRAMITE"
                                    ? "#396DBB"
                                    : "#1B7935",
                                fontWeight: "bold",
                              }}
                            >
                              {item.r_estado === "EN TRAMITE" && "En curso"}
                              {item.r_estado === "NO PERMITIDO POR PLAZO*" &&
                                "Completado"}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "8px" }}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography
                          sx={{ fontWeight: 400, fontSize: "14px" }}
                          variant="subtitle2"
                          color={
                            opcionSeleccionada.r_aporte === item.r_aporte
                              ? ""
                              : step === 1
                              ? "textColor.disabled"
                              : ""
                          }
                        >
                          Inversión total
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "18px" }}
                          color={
                            opcionSeleccionada.r_aporte === item.r_aporte
                              ? ""
                              : step >= 1
                              ? "textColor.disabled"
                              : ""
                          }
                        >
                          S/. {montoFormat(item.r_saldo)}
                        </Typography>
                      </Grid>
                      {item.r_estado !== "RETIRO APV" && (
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowForwardIosIcon
                            sx={{ color: "#ACAFB3", fontSize: "24px" }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderTop: "1px solid #EDEDED",
                      pt: 1,
                      mt: 1,
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "14px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                          color={
                            opcionSeleccionada.r_aporte === item.r_aporte
                              ? ""
                              : step >= 1
                              ? "textColor.disabled"
                              : ""
                          }
                        >
                          Se encuentra en:
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={
                            <Typography
                              variant="caption"
                              color="white"
                              sx={{
                                fontWeight: "bold",
                                height: "12px",
                                lineHeight: "14px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item.r_fondo_origen === "0" &&
                                "Fondo 0 - Protegido"}
                              {item.r_fondo_origen === "1" &&
                                "Fondo 1 - Conservador"}
                              {item.r_fondo_origen === "2" &&
                                "Fondo 2 - Moderado"}
                              {item.r_fondo_origen === "3" &&
                                "Fondo 3 - Arriesgado"}
                            </Typography>
                          }
                          size="small"
                          variant="outlined"
                          sx={{
                            mb: 0,
                            border: "none",
                            backgroundColor: "#2F6D95",
                            p: "4px 12.3676px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
      <Dialog
        open={closeProcess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCloseProcess(!closeProcess)}
        fullScreen={smallScreen}
        className={smallScreen ? classes.DialoMessageStyle : ""}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      setCloseProcess(!closeProcess);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src={ICON_ADVERTENCIA}
                    alt="ICON_ADVERTENCIA"
                    style={{ width: "60px", margin: "auto" }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  No puedes cambiar tu tipo de fondo en este momento
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography
              sx={{ fontWeight: "400", fontSize: "14px", textAlign: "center" }}
            >
              Tienes una solicitud en proceso, durante su ejecución no podrás
              realizar cambios de tipo de fondo en tu {namePorduct}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              pr: 2,
              pl: 2,
              pb: 2,
              pt: 2,
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                width: "50%",
                "& button": { width: "100%" },
              }}
            >
              <Button
                onClick={() => {
                  setCloseProcess(!closeProcess);
                }}
                variant="contained"
                color="primary"
              >
                Aceptar
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductCard;
