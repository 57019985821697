import React, { useState } from "react";
import arrowDown from "../../../../../../assets/icons/black-arrow-down.svg";
import arrowUp from "../../../../../../assets/icons/black-arrow-up.svg";

const HeaderRowOption = (props) => {
  const { title, url, subOptions } = props.value;
  const [isOpen, setisOpen] = useState(false);

  return (
    <div>
      <div
        className={
          "menu-option" +
          (title === "Cámbiate a HABITAT" ? " traspaso-menu" : "")
        }
        onClick={() => {
          if (subOptions) setisOpen(!isOpen);
          else window.open(url, "_self").focus();
        }}
      >
        <span>{title}</span>
        {subOptions && (
          <img src={isOpen ? arrowUp : arrowDown} alt="black-arrow" />
        )}
      </div>
      {subOptions && (
        <div
          className={
            isOpen ? "subMenuButton-main change" : "subMenuButton-main"
          }
          id="sub-agenda"
        >
          {subOptions.map((value, index) => (
            <div
              key={index}
              className="submenu-option"
              onClick={() => window.open(value.url, "_self").focus()}
            >
              <span> {value.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderRowOption;
