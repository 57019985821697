import React, { useState, useEffect } from "react";
import { CellphoneInputComponent } from "./styles";
import PhoneInput from "react-phone-input-2";
import { useFormContext } from "react-hook-form";
import { string, bool, oneOfType, object, func } from "prop-types";
import getDeepValue from "../../../utils/getDeepValue";

const CellphoneInput = (props) => {
  const { name, validate, required, onChange, disableDropdown } = props;
  const [currentCellphone, setCurrentCellphone] = useState(null);
  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const [dialCodeLength, setDialCodeLength] = useState(2); // default country dial code length

  const minLength = (e, dialCodeLength) => {
    if (!e) {
      return null;
    }

    const valueFormatted = e.replace("+", "").replaceAll(" ", "");
    const cellphoneRealLength = valueFormatted.length - dialCodeLength;
    const minCellphoneLengthAllowed = 9;

    if (cellphoneRealLength >= minCellphoneLengthAllowed) {
      return true;
    }

    return `Debe tener por lo menos ${minCellphoneLengthAllowed} caracteres.`;
  };

  register(name, {
    required,
    validate: {
      minLength: (e) => minLength(e, dialCodeLength),
      ...validate,
    },
  });

  const isError = getDeepValue(errors, name);

  const handleOnChange = (value, country, e, formattedValue) => {
    // country {name dialCode, countryCode, format}

    setDialCodeLength(country.dialCode.length);
    setValue(name, formattedValue);
    trigger(name);
  };

  return (
    <CellphoneInputComponent className={isError ? "error" : ""}>
      <PhoneInput
        disableDropdown={disableDropdown}
        value={watch(name)}
        country={"pe"}
        onChange={onChange || handleOnChange}
        enableSearch={true}
        disableSearchIcon={true}
        masks={{ pe: "... ... ..." }}
        countryCodeEditable={false}
        placeholder={"999 999 999"}
      />
    </CellphoneInputComponent>
  );
};

export default CellphoneInput;

CellphoneInput.propTypes = {
  name: string,
  disabled: bool,
  disableDropdown: bool,
  placeholder: string,
  required: oneOfType([bool, object]),
  onChange: oneOfType([func]),
  validate: object,
};

CellphoneInput.defaultProps = {
  name: "cellphone-value",
  disableDropdown: true,
  required: { value: true, message: "Este campo es requerido." },
  onChange: null,
};
