import React, { useContext, useState, useEffect } from "react";
import HomeContext from "../context/home/homeContext";
import EcmensualContext from "../context/estadocuentamensual/ecmensualContext";
import EcnoaportanteContext from "../context/estadocuentanoaportante/ecnoaportanteContext";
import UltimosmovimientosContext from "../context/ultimosmovimientos/ultimosmovimientosContext";
import CambiofondoContext from "../context/cambiofondo/cambiofondoContext";
import RetiroapvsinfinContext from "../context/retiroapvsinfin/retiroapvsinfinContext";
import AportarapvsinfinContext from "../context/aporteapvsinfin/aportarapvsinfinContext";
import CodeContext from "../context/code/codeContext";
import ModalContext from "../context/modal/modalContext";
import MisdatosContext from "../context/misdatos/misdatosContext";

import BoletapagoContext from "../context/boletapagopension/boletapagoContext";
import DrsppContext from "../context/drspp/drsppContext";
import ComisionHistoricaContext from "../context/comisionhistorica/comisionhistoricaContext";
import MisInversionesContext from "../context/misinversiones/misinversionesContext";
import JubilacionContext from "../context/jubilacion/jubilacionContext";
import clienteAxios from "../config/axios";
import { useLocation } from "react-router-dom";
import { useRef } from "react";

const HocVariables = (OriginalComponent) => {
  const NewComponent = (props) => {
    const homeContext = useContext(HomeContext);
    const { limpiarVariablesHome, total, fondos, opcionesMenu } = homeContext;
    const ecmensualContext = useContext(EcmensualContext);
    const { limpiarVariablesECMensual } = ecmensualContext;

    const ecnoaportanteContext = useContext(EcnoaportanteContext);
    const { limpiarVariablesEcnoaportante } = ecnoaportanteContext;

    const ultimosmovimientosContext = useContext(UltimosmovimientosContext);
    const { limpiarUltimosMovimientos } = ultimosmovimientosContext;

    const cambiofondoContext = useContext(CambiofondoContext);
    const { limpiarVariablesCambioFondo } = cambiofondoContext;

    const retiroapvsinfinContext = useContext(RetiroapvsinfinContext);
    const { limpiarVariablesRetiroApvSinFin } = retiroapvsinfinContext;

    const aportarapvsinfinContext = useContext(AportarapvsinfinContext);
    const { limpiarVariablesAportarApvSinFin } = aportarapvsinfinContext;

    const codeContext = useContext(CodeContext);
    const { limpiarVariablesCode } = codeContext;

    const boletapagoContext = useContext(BoletapagoContext);
    const { limpiarVariablesBoletaPago } = boletapagoContext;

    const drsppContext = useContext(DrsppContext);
    const { limpiarVariablesDrspp } = drsppContext;

    const comisionHistoricaContext = useContext(ComisionHistoricaContext);
    const { limpiarVariablesComisionHistorica } = comisionHistoricaContext;

    const misInversionesContext = useContext(MisInversionesContext);
    const { limpiarVariablesMisInversiones } = misInversionesContext;

    const modalContext = useContext(ModalContext);
    const { setMessage, limpiarVariablesModal } = modalContext;

    const jubilacionContext = useContext(JubilacionContext);
    const { limpiarVariablesJubilacion } = jubilacionContext;

    const misdatosContext = useContext(MisdatosContext);
    const {
      limpiarVariablesClaveWeb,
      limpiarVariablesMisDatos,
      limpiarVariablesTarjeta,
    } = misdatosContext;

    const [cambioFondoShow, setCambioFondoShow] = useState(false);
    const [retiroApvSinFinShow, setRetiroApvSinFinShow] = useState(false);
    const [comisionHistoricaShow, setComisionHistoricaShow] = useState(false);
    const [misInversionesShow, setMisInversionesShow] = useState(false);
    const [retiro25Show, setRetiro25Show] = useState(false);
    const [tfehow, setTfeShow] = useState(false);
    const [hasMonthlyDebit, setHasMonthlyDebit] = useState(false);
    const limpiarVariablesContext = () => {
      limpiarVariablesHome();
      limpiarVariablesModal();
      limpiarVariablesECMensual();
      limpiarVariablesEcnoaportante();
      limpiarUltimosMovimientos();
      limpiarVariablesCambioFondo();
      limpiarVariablesRetiroApvSinFin();
      limpiarVariablesCode();
      limpiarVariablesAportarApvSinFin();
      limpiarVariablesClaveWeb();
      limpiarVariablesMisDatos();
      limpiarVariablesTarjeta();
      limpiarVariablesBoletaPago();
      limpiarVariablesDrspp();
      limpiarVariablesComisionHistorica();
      limpiarVariablesMisInversiones();
      limpiarVariablesJubilacion();
    };

    const evaluarOpcionCambioFondo = (total) => {
      let totalStorage = JSON.parse(sessionStorage.getItem("total"));
      if (totalStorage === null && total === null) {
        setCambioFondoShow(false);
      } else if (totalStorage !== null) {
        if (
          parseFloat(totalStorage.r_saldo) > 0 &&
          parseFloat(totalStorage.r_eecc) > 0
        ) {
          setCambioFondoShow(true);
        } else {
          setCambioFondoShow(false);
        }
      }
    };

    const evaluarOpcionRetiroAPV = (fondos) => {
      let fondosStorage = JSON.parse(sessionStorage.getItem("fondos"));
      if (fondosStorage === null && fondos.length === 0) {
        setRetiroApvSinFinShow(false);
      } else if (fondosStorage !== null) {
        const encontrarAPVSF = fondosStorage.find(
          (item) => item.r_cuenta === "VSF"
        );
        if (encontrarAPVSF) {
          if (parseFloat(encontrarAPVSF.r_saldo) > 0) {
            setRetiroApvSinFinShow(true);
          } else {
            setRetiroApvSinFinShow(false);
          }
        }
        //para la comision historica
        const tieneFondoObligatorio = fondosStorage.find(
          (item) => item.r_cuenta === "OBL"
        );
        if (tieneFondoObligatorio) {
          setComisionHistoricaShow(true);
        }

        // para mis Inversiones
        const hasEECC = fondosStorage.filter((item) => item.r_eecc === "1");
        if (hasEECC.length > 0) {
          setMisInversionesShow(true);
        } else {
          setMisInversionesShow(false);
        }
      }
    };

    const evaluarOpcionesMenu = (opcionesMenu) => {
      let opcionesMenuStorage = JSON.parse(
        sessionStorage.getItem("opcionesMenu")
      );
      if (opcionesMenuStorage === null && opcionesMenu === null) {
      } else if (opcionesMenuStorage !== null) {
        if (opcionesMenuStorage.r_retiro_25 === "1") {
          setRetiro25Show(true);
        } else {
          setRetiro25Show(false);
        }
        if (opcionesMenuStorage.r_tfe === "1") {
          setTfeShow(true);
        } else {
          setTfeShow(false);
        }

        if (opcionesMenuStorage.r_aporte_mensual === 1) {
          setHasMonthlyDebit(true);
        } else {
          setHasMonthlyDebit(false);
        }
      }
    };

    useEffect(() => {
      evaluarOpcionCambioFondo(total);
      evaluarOpcionRetiroAPV(fondos);
      evaluarOpcionesMenu(opcionesMenu);
      // evaluarFondos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total, fondos, opcionesMenu]);

    return (
      <OriginalComponent
        setMessage={setMessage}
        sidebarOptions={{
          cambioFondo: cambioFondoShow,
          retiroApv: retiroApvSinFinShow,
          comisionHistorica: comisionHistoricaShow,
          misInversiones: misInversionesShow,
          retiro25Show: retiro25Show,
          tfehow: tfehow,
          showAporteMensual: hasMonthlyDebit,
        }}
        limpiarVariablesModal={limpiarVariablesModal}
        limpiarVariablesContext={limpiarVariablesContext}
        {...props}
      />
    );
  };

  return NewComponent;
};

export default HocVariables;
