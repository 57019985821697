import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Header from "../../components/BeneficiaryZoneHeader";
import FormDecorator from "../../components/Input/FormDecorator";
import Stepper from "../../components/Stepper";
import clienteAxios from "../../config/axios";
import useWindowSize from "../../hooks/useWindowSize";
import Divider from "./components/Divider";
import useStepNavigation from "./hooks/useStepNavigation";
import PasoAsesoria from "./PasoAsesoria";
import PasoDatosDelAfiliado from "./PasoBuscarAfiliado";
import PasoDatosDelSolicitante from "./PasoDatosDelSolicitante";
import PasoDocumentos from "./PasoDocumentos";
import PasoResumen from "./PasoResumen";
import { GastoDeSepelioComponent, Main } from "./styles";
import { GastoDeSepelioProvider } from "./context";
import { useHistory } from "react-router-dom";

const GastoDeSepelio = ({ defaultStep }) => {
  const { width } = useWindowSize();
  const history = useHistory();

  var stepInformation = [
    { label: "Asesoría", id: "asesoria" },
    { label: "Buscar afiliado", id: "buscar-afiliado" },
    { label: "Datos del solicitante", id: "datos-solicitante" },
    { label: "Documentos", id: "documentos" },
    { label: "Resumen", id: "resumen" },
  ];

  const { currentStep, setCurrentStep, handleStepNavigation } =
    useStepNavigation(
      stepInformation.map((e) => e.id),
      { defaultStep: defaultStep || "asesoria" }
    );

  const alternativeSteps = stepInformation.map((e) => ({ ...e, label: "" }));

  const smallScreenBreakpoint = 375;
  const stepsSelected =
    width < smallScreenBreakpoint ? alternativeSteps : stepInformation;
  const activeStep = stepInformation.findIndex((e) => e.id === currentStep);

  const formMethods = useForm({
    mode: "all",
    criteriaMode: "all",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const onPreviousFromHeader = () => {
    if (currentStep === "asesoria") {
      history.push("/zona-beneficiarios");
    }

    if (
      ["buscar-afiliado", "datos-solicitante", "documentos"].includes(
        currentStep
      )
    ) {
      handleStepNavigation("previous");
    }
  };

  const showPreviousFromHeader = () => {
    if (currentStep === "resumen") {
      return null;
    }

    return true;
  };

  const onCloseFromHeader = () => {
    history.push("/zona-beneficiarios");
  };

  const showCloseFromHeader = () => {
    if (currentStep === "resumen") {
      return null;
    }

    return true;
  };

  var breadcumbs = [
    {
      label: "Zona beneficiarios",
      path: "/zona-beneficiarios",
      current: false,
    },
    {
      label: "Gastos de sepelio",
      path: "/zona-beneficiarios/tramites/gasto-sepelio",
      current: true,
    },
  ];

  return (
    <GastoDeSepelioProvider>
      <GastoDeSepelioComponent>
        <Header
          onPrevious={showPreviousFromHeader() && onPreviousFromHeader}
          onClose={showCloseFromHeader() && onCloseFromHeader}
          breadcumbs={breadcumbs}
        />
        {/* <FormDecorator> */}
        <FormProvider {...formMethods}>
          <from>
            <Main>
              <Stepper steps={stepsSelected} activeStep={activeStep} />
              <Divider size="sm" />
              {currentStep === "asesoria" && (
                <PasoAsesoria handleStepNavigation={handleStepNavigation} />
              )}
              {currentStep === "buscar-afiliado" && (
                <PasoDatosDelAfiliado
                  handleStepNavigation={handleStepNavigation}
                />
              )}
              {currentStep === "datos-solicitante" && (
                <PasoDatosDelSolicitante
                  handleStepNavigation={handleStepNavigation}
                />
              )}
              {currentStep === "documentos" && (
                <PasoDocumentos handleStepNavigation={handleStepNavigation} />
              )}
              {currentStep === "resumen" && <PasoResumen />}
            </Main>
          </from>
        </FormProvider>

        {/* </FormDecorator> */}
      </GastoDeSepelioComponent>
    </GastoDeSepelioProvider>
  );
};

export default GastoDeSepelio;
