import React, { useEffect } from "react";
import "./App.css";
import { Switch, HashRouter, Redirect, Route } from "react-router-dom";
import ReactGa from "react-ga";

// LAYOUTS
import RouteLayout from "./container/Layout/RouteLayout";
import RouteLogin from "./container/Layout/RouteLogin";
import BeneficiaryZoneLayout from "./container/Layout/BeneficiaryZone";

/**LOGIN */
import Login from "./views/Login/index";

/**NEW HOME */
import NewHome from "./views/NewHome";
import GeneraClaveView from "./views/GenerarClaveWeb";

/**DASHBOARD */
import Dashboard from "./views/Dashboard";
import JubilacionInicio from "./views/APVFunds";

/**MIS FONDOS */
import EstadoCuentaMensual from "./views/EstadoCuentaMensual";
import UltimosMovimientos from "./views/UltimosMovimientos";
import CambioFondo from "./views/CambioFondo";
import ComisionHistorica from "./views/ComisionHistorica";
import EstadoCuentaNoAportante from "./views/EstadoCuentaNoAportante";
import Inversiones from "./views/MisInversiones";

/**APORTES */
import AporteVoluntarioSinFin from "./views/AporteVoluntarioSinFin";
import SeguimientoAporte from "./views/SeguimientoAporte";
import { PagoDetalleSeguimiento } from "./views/SeguimientoAporte/PagoDetalle";

/**RETIROS */
import RetiroApv from "./views/RetiroApv";
import RetiroAporteVoluntario from "./views/RetiroAporteVoluntario";
import TransferenciaFondoExterior from "./views/TransferenciaFondoExterior";
import Retiro25 from "./views/Retiro25";

/** JUBILACION */
import Jubilacion from "./views/Jubilacion";
import PMOWorkflow from "./views/Jubilacion/scenes/jubilacionPMO/JubilacionLegalWorkflow";

import LegalWorkflow from "./views/Jubilacion/scenes/jubilacionLegal/JubilacionLegalWorkflow";
import LegalMensualFin from "./views/Jubilacion/scenes/jubilacionLegal/JubilacionLegalMensualFin";
import LegalRetiroFin from "./views/Jubilacion/scenes/jubilacionLegal/JubilacionLegalRetiroFin";
import LegalPMOFin from "./views/Jubilacion/scenes/jubilacionLegal/JubilacionLegalPMOFin";
import RejaWorkflow from "./views/Jubilacion/scenes/jubilacionReja/JubilacionRejaWorkflow";
import RejaMensualFin from "./views/Jubilacion/scenes/jubilacionReja/JubilacionRejaMensualFin";
import RejaRetiroFin from "./views/Jubilacion/scenes/jubilacionReja/JubilacionRejaRetiroFin";
import JaoInfo from "./views/Jubilacion/scenes/JAO/Info";
import JaoWorkflow from "./views/Jubilacion/scenes/JAO/worflow";
import SegundoRetiroWorkflow from "./views/Jubilacion/scenes/segundoRetiro/SegundoRetiroWorkflow";
import SegundoRetiroFin from "./views/Jubilacion/scenes/segundoRetiro/SegundoRetiroFin";
import SegundoRetiroError from "./views/Jubilacion/scenes/segundoRetiro/SegundoRetiroError";
/** DATOS DEL USUARIO */
import MisDatos from "./views/DatosUsuario/MisDatos";
import MiClaveWeb from "./views/DatosUsuario/MiClaveWeb";
import MisTarjetas from "./views/DatosUsuario/MisTarjetas";
import AsesorView from "./views/AsesorView";
import BoletaPagoPension from "./views/BoletaPagoPension";
import Drspp from "./views/Drspp";
import Boleta from "./views/Boleta";
import Solicitud from "./views/Solicitud";
import CuentaBloqueada from "./views/CuentaBloqueada";

// ZONA DE BENEFICIARIOS
import ZonaDeBeneficiarios from "./views/ZonaDeBeneficiarios";
import BuscarTramite from "./views/BuscarTramite";
import GastoDeSepelio from "./views/GastoDeSepelio";

/*Retiro DU038 -- actualizando */
import RetiroDU38 from "./views/RetiroDU038";

/*Deudas Pendientes */
import PagosPendientes from "./views/PagosPendientes/index";
import PagosPendientesDetalle from "./views/PagosPendientes/PagoPendienteDetalle";
import { FondoLibre } from "./views/FondoLibre";

// VALORES CUOTAS
import ValoresCuota from "./views/ValoresCuota";

import AuthState from "./context/autenticacion/authState";
import HomeState from "./context/home/homeState";
import EcmensualState from "./context/estadocuentamensual/ecmensualState";
import EcnoaportanteState from "./context/estadocuentanoaportante/ecnoaportanteState";
import CambiofondoState from "./context/cambiofondo/cambiofondoState";
import PagosDesembolsosState from "./context/pagosDesembolsos/pagosDesembolsosState";
import UltimosmovimientosState from "./context/ultimosmovimientos/ultimosmovimientosState";
import ModalState from "./context/modal/modalState";
import SidebarState from "./context/sidebar/sidebarState";
import RetiroapvsinfinState from "./context/retiroapvsinfin/retiroapvsinfinState";
import CodeState from "./context/code/codeState";
import CuentaBloqueadaState from "./context/cuentaBloqueada/codeState";
import AporteapvsinfinState from "./context/aporteapvsinfin/aportarapvsinfinState";
import MisdatosState from "./context/misdatos/misdatosState";
import BoletapagoState from "./context/boletapagopension/boletapagoState";
import DrsppState from "./context/drspp/drsppState";
import BoletaState from "./context/boleta/boletaState";
import ComisionhistoricaState from "./context/comisionhistorica/comisionhistoricaState";
import MisInversionesState from "./context/misinversiones/misinversionesState";
import AsesorState from "./context/asesor/asesorState";
import RoutePublic from "./container/Layout/RoutePublic";
import PDFReportLayout from "./container/Layout/PDFReportLayout";
import SolicitudState from "./context/solicitud/solicitudState";
// state
import RetiroDU38State from "./context/retiroDU038/retiroDU038State";

import JubilacionState from "./context/jubilacion/jubilacionState";
import JubilacionPMOState from "./context/jubilacion/pmo/jubPmoState";
import Retiro25State from "./context/retiro25/retiro25State";
import TransferenciaexteriorState from "./context/transferenciaExterior/transferenciaexteriorState";
import SeguimientoAportesState from "./context/seguimientoAportes/seguimientoAportesState";
import PagosPendientesState from "./context/pagosPendientes/pagosPendientesState";
import NewHomeState from "./context/newHome/newHomeState";
import DetalleProductoSppState from "./context/detalleProductoSpp/detalleProductoSppState";
import { PagosDesembolsos } from "./views/PagosDesembolsos";
import { ListadoTramites } from "./views/PagosDesembolsos/ListadoTramites";
import { PagoDetalle } from "./views/PagosDesembolsos/PagoDetalle";
import DescargarInformacionRetiro from "./views/PagosDesembolsos/DescargarInformacionRetiro";
import FondoLibreState from "./context/fondoLibre/fondoLibreState";
import ValoresCuotaState from "./context/valoresCuota/valoresCuotaState";

// import { FondoLibre } from "./views/FondoLibre";
import { FondoLibreInicio } from "./views/FondoLibre/inicio";
import { DetalleFondo } from "./views/FondoLibre/detalleFondo";
import RetiroFondoLibreState from "./context/fondoLibre/RetiroFondoLibre/retiroFondoLibreState";
// import { RetiroFondoLibre } from "./views/FondoLibre/RetiroFondoLibre";
import { OnBoarding } from "./views/FondoLibre/OnBoarding";
import CancelarAporteVoluntarioSinFin from "./views/CancelarAporteVoluntarioSinFin";
import GenerarClaveState from "./context/generarClave/generarClaveState";

import { Paso0 } from "./views/Jubilacion/scenes/jubilacionReja/etapas";
import { LegalPaso0 } from "./views/Jubilacion/scenes/jubilacionLegal/etapas";

//Genera clave Contexto

function App() {
  useEffect(() => {
    ReactGa.initialize("UA-41385804-6");
  }, []);
  return (
    <CuentaBloqueadaState>
      <DetalleProductoSppState>
        <SolicitudState>
          <TransferenciaexteriorState>
            <Retiro25State>
              <JubilacionState>
                <JubilacionPMOState>
                  <AsesorState>
                    <SidebarState>
                      <ModalState>
                        <CodeState>
                          <MisInversionesState>
                            <DrsppState>
                              <BoletaState>
                                <BoletapagoState>
                                  <MisdatosState>
                                    <PagosPendientesState>
                                      <SeguimientoAportesState>
                                        <AporteapvsinfinState>
                                          <RetiroapvsinfinState>
                                            <RetiroDU38State>
                                              <UltimosmovimientosState>
                                                <ComisionhistoricaState>
                                                  <CambiofondoState>
                                                    <EcnoaportanteState>
                                                      <EcmensualState>
                                                        <FondoLibreState>
                                                          <PagosDesembolsosState>
                                                            <RetiroFondoLibreState>
                                                              <ValoresCuotaState>
                                                                <HomeState>
                                                                  <AuthState>
                                                                    <NewHomeState>
                                                                      <GenerarClaveState>
                                                                        <HashRouter basename="/">
                                                                          {/* <div className="App"> */}
                                                                          <Switch>
                                                                            <RouteLayout
                                                                              exact
                                                                              name="pagosdesembolsos"
                                                                              path="/mi-habitat-digital/afiliado/fondo-libre"
                                                                              component={
                                                                                FondoLibreInicio
                                                                              }
                                                                            />
                                                                            {/* Zona de beneficiarios routes */}
                                                                            <Route
                                                                              exact
                                                                              path="/zona-beneficiarios"
                                                                              component={() => (
                                                                                <BeneficiaryZoneLayout>
                                                                                  <ZonaDeBeneficiarios />
                                                                                </BeneficiaryZoneLayout>
                                                                              )}
                                                                            />
                                                                            <Route
                                                                              exact
                                                                              path="/zona-beneficiarios/buscar-tramite"
                                                                              component={() => (
                                                                                <BeneficiaryZoneLayout>
                                                                                  <BuscarTramite />
                                                                                </BeneficiaryZoneLayout>
                                                                              )}
                                                                            />
                                                                            <Route
                                                                              exact
                                                                              path="/zona-beneficiarios/tramites/gasto-sepelio"
                                                                              component={() => (
                                                                                <BeneficiaryZoneLayout>
                                                                                  <GastoDeSepelio />
                                                                                </BeneficiaryZoneLayout>
                                                                              )}
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="fondolibredetalle"
                                                                              path="/mi-habitat-digital/afiliado/fondo-libre/detalle"
                                                                              component={
                                                                                DetalleFondo
                                                                              }
                                                                            />
                                                                            {/* pagos y desembolsos: inicio */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="pagosdesembolsos"
                                                                              path="/mi-habitat-digital/afiliado/estado-de-tramite"
                                                                              component={
                                                                                PagosDesembolsos
                                                                              }
                                                                            />
                                                                            {/* fondo libre: inicio */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="fondolibre"
                                                                              path="/mi-habitat-digital/afiliado/noticias-de-inversiones"
                                                                              component={
                                                                                FondoLibre
                                                                              }
                                                                            />
                                                                            <PDFReportLayout
                                                                              name="descargar-informacion-retiro"
                                                                              path="/mi-habitat-digital/afiliado/estado-de-tramite/retiro-apv/:nro/descargar-informacion-retiro"
                                                                              component={
                                                                                DescargarInformacionRetiro
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="pagosdesembolsoslistado"
                                                                              path="/mi-habitat-digital/afiliado/estado-de-tramite/:tipo"
                                                                              component={
                                                                                ListadoTramites
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              name="pagodetalle"
                                                                              path="/mi-habitat-digital/afiliado/estado-de-tramite/:tipo/:nro"
                                                                              component={
                                                                                PagoDetalle
                                                                              }
                                                                            />
                                                                            {/* pagos y desembolsos: fin */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="estadocuentamensual"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual"
                                                                              component={
                                                                                EstadoCuentaMensual
                                                                              }
                                                                            />
                                                                            {/* <Redirect
                                                                            from="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual"
                                                                            to="/mi-habitat-digital/afiliado/inicio"
                                                                          /> */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="cambiofondo"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo"
                                                                              component={
                                                                                CambioFondo
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="ultimosmovimientos"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/ultimos-movimientos"
                                                                              component={
                                                                                UltimosMovimientos
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="comision-historica"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/comision-historica"
                                                                              component={
                                                                                ComisionHistorica
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="estadocuentanoaportante"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-no-aportante"
                                                                              component={
                                                                                EstadoCuentaNoAportante
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="misinversiones"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/evolucion-de-mi-fondo"
                                                                              component={
                                                                                Inversiones
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="aportesvoluntariossinfin"
                                                                              path="/mi-habitat-digital/afiliado/apv/fondo-libre-habitat"
                                                                              component={
                                                                                AporteVoluntarioSinFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="cancelaraportevoluntariosinfin"
                                                                              path="/mi-habitat-digital/afiliado/apv/debito-mensual"
                                                                              component={
                                                                                CancelarAporteVoluntarioSinFin
                                                                              }
                                                                            />
                                                                            {/* <RouteLayout
                                                                        exact
                                                                        name="retiroaportesvoluntariossinfin"
                                                                        path="/mi-habitat-digital/afiliado/apv/retiro-aporte-voluntario-sin-fin"
                                                                        component={
                                                                          RetiroApv
                                                                        }
                                                                      /> */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="retiroaportesvoluntariossinfin"
                                                                              path="/mi-habitat-digital/afiliado/apv/retiro-aporte-voluntario-sin-fin"
                                                                              component={
                                                                                RetiroAporteVoluntario
                                                                              }
                                                                            />
                                                                            {/* <RouteLayout
                                                                        exact
                                                                        name="retiroaportesvoluntarios"
                                                                        path="/mi-habitat-digital/afiliado/apv/aporte-voluntario-retiro"
                                                                        component={
                                                                          RetiroAporteVoluntario
                                                                        }
                                                                      /> */}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="transferencia-fondos-exterior"
                                                                              path="/mi-habitat-digital/afiliado/retiro/transferencia-fondos-exterior"
                                                                              component={
                                                                                TransferenciaFondoExterior
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="retiro-25"
                                                                              path="/mi-habitat-digital/afiliado/retiro/25"
                                                                              component={
                                                                                Retiro25
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="solicitud_sepelio"
                                                                              path="/mi-habitat-digital/solicitud/sepelio"
                                                                              component={
                                                                                Solicitud
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="solicitud_reembolso"
                                                                              path="/mi-habitat-digital/solicitud/reembolso"
                                                                              component={
                                                                                Solicitud
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="solicitud_herencia"
                                                                              path="/mi-habitat-digital/solicitud/herencia"
                                                                              component={
                                                                                Solicitud
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="solicitud_sepelio"
                                                                              path="/mi-habitat-digital/solicitud/sepeleio"
                                                                              component={
                                                                                Solicitud
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="solicitud_bloqueada"
                                                                              path="/mi-habitat-digital/cuenta-bloqueada"
                                                                              component={
                                                                                CuentaBloqueada
                                                                              }
                                                                            />
                                                                            <RouteLogin
                                                                              exact
                                                                              path="/mi-habitat-digital/empezar"
                                                                              name="login"
                                                                              component={
                                                                                Login
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="inicio"
                                                                              path="/mi-habitat-digital/afiliado/inicio"
                                                                              component={
                                                                                Dashboard
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="inicio"
                                                                              path="/mi-habitat-digital/afiliado/tipo-jubilacion"
                                                                              component={
                                                                                JubilacionInicio
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="estadocuentamensual"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-mensual"
                                                                              component={
                                                                                EstadoCuentaMensual
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="cambiofondo"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo"
                                                                              component={
                                                                                CambioFondo
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="ultimosmovimientos"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/ultimos-movimientos"
                                                                              component={
                                                                                UltimosMovimientos
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="comision-historica"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/comision-historica"
                                                                              component={
                                                                                ComisionHistorica
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="estadocuentanoaportante"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/estado-cuenta-no-aportante"
                                                                              component={
                                                                                EstadoCuentaNoAportante
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="misinversiones"
                                                                              path="/mi-habitat-digital/afiliado/mis-fondos/evolucion-de-mi-fondo"
                                                                              component={
                                                                                Inversiones
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="aportesvoluntariossinfin"
                                                                              path="/mi-habitat-digital/afiliado/aporte-voluntario-sin-fin"
                                                                              component={
                                                                                AporteVoluntarioSinFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="retiro-apv"
                                                                              path="/mi-habitat-digital/afiliado/seguimiento/apv"
                                                                              component={
                                                                                SeguimientoAporte
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="retiro-apv"
                                                                              path="/mi-habitat-digital/afiliado/seguimiento/:nro"
                                                                              component={
                                                                                PagoDetalleSeguimiento
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="transferencia-fondos-exterior"
                                                                              path="/mi-habitat-digital/afiliado/retiro/transferencia-fondos-exterior"
                                                                              component={
                                                                                TransferenciaFondoExterior
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="retiro-25"
                                                                              path="/mi-habitat-digital/afiliado/retiro/25"
                                                                              component={
                                                                                Retiro25
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacion"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion"
                                                                              component={
                                                                                Jubilacion
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionLegalinicio"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-legal/tramite"
                                                                              component={
                                                                                LegalWorkflow
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionPMOInicio"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-pmo/tramite"
                                                                              component={
                                                                                PMOWorkflow
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionLegalMensualFin"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-legal/pension-fin"
                                                                              component={
                                                                                LegalMensualFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionLegalRetiroFin"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-legal/retiro-fin"
                                                                              component={
                                                                                LegalRetiroFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionLegalPMO"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-legal/pmo-fin"
                                                                              component={
                                                                                LegalPMOFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionRejainicio"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-reja/tramite"
                                                                              component={
                                                                                RejaWorkflow
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionRejaMensualFin"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-reja/pension-fin"
                                                                              component={
                                                                                RejaMensualFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionRejaRetiroFin"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-reja/retiro-fin"
                                                                              component={
                                                                                RejaRetiroFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionJaoInfo"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-jao/info"
                                                                              component={
                                                                                JaoInfo
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="jubilacionJaoRegistro"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-jao/proceso"
                                                                              component={
                                                                                JaoWorkflow
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="segundoRetiro"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso"
                                                                              component={
                                                                                SegundoRetiroWorkflow
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="segundoRetiroFin"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso-fin"
                                                                              component={
                                                                                SegundoRetiroFin
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="segundoRetiroError"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-segundo-retiro/proceso-error"
                                                                              component={
                                                                                SegundoRetiroError
                                                                              }
                                                                            />{" "}
                                                                            <RouteLayout
                                                                              exact
                                                                              name="datospersonales"
                                                                              path="/mi-habitat-digital/afiliado/informacion/datos-personales"
                                                                              component={
                                                                                MisDatos
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="claveweb"
                                                                              path="/mi-habitat-digital/afiliado/informacion/clave-web"
                                                                              component={
                                                                                MiClaveWeb
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="datostarjeta"
                                                                              path="/mi-habitat-digital/afiliado/informacion/cuentas-bancarias"
                                                                              component={
                                                                                MisTarjetas
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="boletapagopension"
                                                                              path="/mi-habitat-digital/afiliado/informacion/boleta-pago"
                                                                              component={
                                                                                BoletaPagoPension
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="Drspp"
                                                                              path="/mi-habitat-digital/afiliado/informacion/drspp"
                                                                              component={
                                                                                Drspp
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="Boleta"
                                                                              path="/mi-habitat-digital/afiliado/informacion/Boleta-digital"
                                                                              component={
                                                                                Boleta
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="Boleta"
                                                                              path="/mi-habitat-digital/afiliado/retiro/deu038"
                                                                              component={
                                                                                RetiroDU38
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="asesor"
                                                                              path="/mi-habitat-digital/asesor"
                                                                              component={
                                                                                AsesorView
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="pagosPendientes"
                                                                              path="/mi-habitat-digital/afiliado/informacion/pagos-pendientes"
                                                                              component={
                                                                                PagosPendientes
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="pagoPendienteDetalle"
                                                                              path="/mi-habitat-digital/afiliado/informacion/pagos-pendientes/detalle"
                                                                              component={
                                                                                PagosPendientesDetalle
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="actualizacionDatos"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-reja/actualizacion-datos"
                                                                              component={
                                                                                Paso0
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="actualizacionDatosLegal"
                                                                              path="/mi-habitat-digital/afiliado/jubilacion-legal/actualizacion-datos"
                                                                              component={
                                                                                LegalPaso0
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="new_home"
                                                                              path="/"
                                                                              component={
                                                                                NewHome
                                                                              }
                                                                            />
                                                                            <RoutePublic
                                                                              exact
                                                                              name="genera_clave"
                                                                              path="/genera-clave-web"
                                                                              component={
                                                                                GeneraClaveView
                                                                              }
                                                                            />
                                                                            <RouteLayout
                                                                              exact
                                                                              name="valoresCuota"
                                                                              path="/mi-habitat-digital/afiliado/valor-cuota"
                                                                              component={
                                                                                ValoresCuota
                                                                              }
                                                                            />
                                                                            <Redirect
                                                                              from="/"
                                                                              to="/"
                                                                            />
                                                                          </Switch>
                                                                          {/* </div> */}
                                                                        </HashRouter>
                                                                      </GenerarClaveState>
                                                                    </NewHomeState>
                                                                  </AuthState>
                                                                </HomeState>
                                                              </ValoresCuotaState>
                                                            </RetiroFondoLibreState>
                                                          </PagosDesembolsosState>
                                                        </FondoLibreState>
                                                      </EcmensualState>
                                                    </EcnoaportanteState>
                                                  </CambiofondoState>
                                                </ComisionhistoricaState>
                                              </UltimosmovimientosState>
                                            </RetiroDU38State>
                                          </RetiroapvsinfinState>
                                        </AporteapvsinfinState>
                                      </SeguimientoAportesState>
                                    </PagosPendientesState>
                                  </MisdatosState>
                                </BoletapagoState>
                              </BoletaState>
                            </DrsppState>
                          </MisInversionesState>
                        </CodeState>
                      </ModalState>
                    </SidebarState>
                  </AsesorState>
                </JubilacionPMOState>
              </JubilacionState>
            </Retiro25State>
          </TransferenciaexteriorState>
        </SolicitudState>
      </DetalleProductoSppState>
    </CuentaBloqueadaState>
  );
}

export default App;
