import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import hocWidthWindow from "../../HOC/HocWidthWindow";
import { montoFormat } from "../../helper/amountFormat";
import { ItemState } from "./componentes/ItemState";

import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
  GreyText: "#51565F",
  iconBlue: "#0043CE",
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainerStyles: {
      borderRadius: "16px",
      boxShadow: 2,
    },
    paperContainerStylesChanges: {
      borderRadius: "0px",
      border: "0px",
      boxShadow: "none",
    },
    selectStyles: {
      borderRadius: "16px",
    },
    textFieldStyles: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "16px",
      },
    },
    textFieldFilledStyles: {
      "& .MuiFilledInput-root": {
        borderRadius: "16px",
      },
      "& .MuiFilledInput.Mui-focused": {
        borderColor: " none !important",
      },
    },
    boxDescriptionStyles: {
      display: "flex",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    boxWarningStyles: {
      mt: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: palletteColors.BlueBg,
      borderRadius: "16px",
      height: "80px",
      color: palletteColors.GreyText,
    },
    iconStyles: {
      color: palletteColors.iconBlue,
    },
  })
);

const Theme = createTheme({
  palette: {
    primary: {
      main: "#2F6D95",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
});

const SolicitudProcesada = (props) => {
  const [isMore, setIsMore] = useState(false);
  const [detail, setDetail] = useState([]);
  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    r_estado,
    subtitle,
    r_solicitud,
    r_fecha_solicitud,
    r_fecha_banco,
    r_fecha_acreditacion,

    r_banco,
  } = detail ?? {};
  const estado =
    r_estado === "RECHAZADO"
      ? "RECHAZADA"
      : r_estado === "PROCESADA"
      ? "ACREDITADO"
      : "EN CURSO";
  return (
    <Box>
      <Typography variant="h5" style={{ padding: matches ? 0 : 2 }}>
        Datos del retiro
      </Typography>

      <Box sx={{ p: matches ? 0 : 0 }}>
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item xs={12}>
            <ThemeProvider theme={Theme}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textFieldFilledStyles}
                  id="outlined-read-only-input"
                  label="Banco"
                  defaultValue={props.resultado.r_banco}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  variant="filled"
                />
              </FormControl>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12}>
            <ThemeProvider theme={Theme}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textFieldFilledStyles}
                  id="outlined-read-only-input"
                  label="N.° de cuenta"
                  defaultValue={props.resultado.r_num_cuenta}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  variant="filled"
                />
              </FormControl>
            </ThemeProvider>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={matches ? 12 : 6}>
            <ThemeProvider theme={Theme}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textFieldFilledStyles}
                  id="outlined-read-only-input"
                  label="Tipo de cuenta"
                  defaultValue={props.resultado.r_tipo_cuenta}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  variant="filled"
                />
              </FormControl>
            </ThemeProvider>
          </Grid>
          <Grid item xs={matches ? 12 : 6}>
            <ThemeProvider theme={Theme}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textFieldFilledStyles}
                  id="outlined-read-only-input"
                  label="Monto a retirar"
                  defaultValue={"S/ " + montoFormat(props.resultado.r_monto)}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  variant="filled"
                />
              </FormControl>
            </ThemeProvider>
          </Grid>

          <Grid item xs={matches ? 12 : 6}>
            <ThemeProvider theme={Theme}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textFieldFilledStyles}
                  id="outlined-read-only-input"
                  label="Tipo de retiro"
                  defaultValue={props.resultado.r_tipo_retiro}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  variant="filled"
                />
              </FormControl>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            paddingTop: { xs: "16px", md: "24px" },
            paddingBottom: { xs: "16px", md: "0px" },
          }}
        >
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                boxShadow: { xs: "0", md: "0px -4px 16px rgba(0, 0, 0, 0.12)" },
                borderRadius: "16px",
                background: "#fff",
                display: { xs: "flex", md: "block" },
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Grid
                container
                className="grid_container_mobile"
                sx={{
                  minHeight: "100%",
                }}
              >
                <Grid
                  item
                  xs={11}
                  md={5}
                  sx={{
                    display: !isMore ? "block" : "none",
                  }}
                  className="animate__animated animate__fadeInLeft animate__faster"
                >
                  <Box
                    sx={{
                      padding: { xs: "16px 0", md: "16px" },
                    }}
                  >
                    <ItemState {...detail} estado={estado} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default hocWidthWindow(SolicitudProcesada);
