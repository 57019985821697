import * as actions from "./actions";

export const setGeneralError = (dispatch) => () => {
  dispatch({ type: actions.SET_CONEXION_ERROR });
};

export const removeGeneralError = (dispatch) => () => {
  dispatch({ type: actions.REMOVE_CONEXION_ERROR });
  return actions.REMOVE_CONEXION_ERROR;
};
