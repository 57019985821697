import { Alert } from "@mui/material";
import React from "react";
const Bloqueado = (props) => {
  return (
    <div className="mx-1 mt-3">
      <Alert severity="error">
        {/* <span>Pensando en tu seguridad, <b>bloqueamos por 24 horas</b> la posibilidad de hacer esta operación vía web;
                     por ello, si deseas realizarla dentro de ese tiempo o si no fuiste tú, 
                     te recomendamos comunicarte con nuestro <b>Contact Center</b> para ayudarte. </span> */}
        <span>
          Para garantizar tu seguridad, hemos bloqueado temporalmente la opción
          de realizar esta operación vía web durante 24 horas. Por favor,
          inténtalo nuevamente después de este periodo.
        </span>
      </Alert>
    </div>
  );
};

export default Bloqueado;
