import {
    CALCULAR_ESTIMACION_PENSION_LOADING,
    CALCULAR_ESTIMACION_PENSION_EXISOTSO,
    CALCULAR_ESTIMACION_PENSION_ERROR,
    CALCULAR_ESTIMACION_PENSION_CLEANER,
    CALCULAR_ESTIMACION_PENSION_RANGO_LOADING,
    CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO,
    CALCULAR_ESTIMACION_PENSION_RANGO_ERROR,
    CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER,
    OBTENER_HASH_LOADING,
    OBTENER_HASH_EXITOSO,
    OBTENER_HASH_ERROR,
    OBTENER_HASH_CLENEAR,
} from "../../types/newHome";

export default (state, action) => {
    switch (action.type) {
        case OBTENER_HASH_EXITOSO:
            return {
                ...state,
                hashData: action.payload,
                mensajeAlerta: null,
                hashLoading: false,
            };
        case OBTENER_HASH_LOADING:
            return {
                ...state,
                hashLoading: true,
            };
        case OBTENER_HASH_ERROR:
            return {
                ...state,
                hashData: null,
                mensajeAlerta: action.payload,
                hashLoading: false,
            };
        case OBTENER_HASH_CLENEAR:
            return {
                ...state,
                hashData: null,
                mensajeAlerta: null,
                hashLoading: false,
            };

        /* PENSION */
        case CALCULAR_ESTIMACION_PENSION_EXISOTSO:
            return {
                ...state,
                pensionCalculateData: action.payload,
                mensajeAlerta: null,
                pensionCalculateLoading: false,
            };
        case CALCULAR_ESTIMACION_PENSION_LOADING:
            return {
                ...state,
                pensionCalculateLoading: true,
            };
        case CALCULAR_ESTIMACION_PENSION_ERROR:
            return {
                ...state,
                pensionCalculateData: null,
                mensajeAlerta: action.payload,
                pensionCalculateLoading: false,
            };
        case CALCULAR_ESTIMACION_PENSION_CLEANER:
            return {
                ...state,
                pensionCalculateData: null,
                mensajeAlerta: null,
                pensionCalculateLoading: false,
            };

        case CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO:
            return {
                ...state,
                pensionCalculateRangeData: action.payload,
                mensajeAlerta: null,
                pensionCalculateRangeLoading: false,
            };
        case CALCULAR_ESTIMACION_PENSION_RANGO_LOADING:
            return {
                ...state,
                pensionCalculateRangeLoading: true,
            };
        case CALCULAR_ESTIMACION_PENSION_RANGO_ERROR:
            return {
                ...state,
                pensionCalculateRangeData: null,
                mensajeAlerta: action.payload,
                pensionCalculateRangeLoading: false,
            };
        case CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER:
            return {
                ...state,
                pensionCalculateRangeData: null,
                mensajeAlerta: null,
                pensionCalculateRangeLoading: false,
            };

        default:
            break;
    }
};
