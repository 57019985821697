export const CALCULAR_ESTIMACION_PENSION_LOADING = "CALCULAR_ESTIMACION_PENSION_LOADING";
export const CALCULAR_ESTIMACION_PENSION_EXISOTSO = "CALCULAR_ESTIMACION_PENSION_EXISOTSO";
export const CALCULAR_ESTIMACION_PENSION_ERROR = "CALCULAR_ESTIMACION_PENSION_ERROR";
export const CALCULAR_ESTIMACION_PENSION_CLEANER = "CALCULAR_ESTIMACION_PENSION_CLEANER";

export const CALCULAR_ESTIMACION_PENSION_RANGO_LOADING =
    "CALCULAR_ESTIMACION_PENSION_RANGO_LOADING";
export const CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO =
    "CALCULAR_ESTIMACION_PENSION_RANGO_EXISOTSO";
export const CALCULAR_ESTIMACION_PENSION_RANGO_ERROR = "CALCULAR_ESTIMACION_PENSION_RANGO_ERROR";
export const CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER =
    "CALCULAR_ESTIMACION_PENSION_RANGO_CLEANER";

export const OBTENER_HASH_LOADING = "OBTENER_HASH_LOADING";
export const OBTENER_HASH_EXITOSO = "OBTENER_HASH_EXITOSO";
export const OBTENER_HASH_ERROR = "OBTENER_HASH_ERROR";
export const OBTENER_HASH_CLENEAR = "OBTENER_HASH_CLENEAR";
