import styled, { css } from "styled-components";

export const DividerComponent = styled.div`
  ${(p) =>
    p.mode === "horizontal" &&
    css`
      width: 100%;
      height: 0;
      border-top: 1px solid ${(p) => p.theme.palette.grayscale[100]};
      margin: 16px 0px;
    `}

  ${(p) =>
    p.mode === "vertical" &&
    css`
      width: 0;
      height: 100%;
      border-left: 1px solid ${(p) => p.theme.palette.grayscale[100]};
      margin: 0px 16px;
    `}

  ${(p) =>
    p.bg === "dark" &&
    css`
      border-color: ${(p) => p.theme.palette.grayscale[200]};
    `}


  ${(p) =>
    p.size === "md"
      ? css`
          border-width: 2px;
        `
      : p.size === "lg"
      ? css`
          border-width: 3px;
        `
      : null}

    ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${p.marginTop};
    `}
    
    ${(p) =>
    p.marginBottom &&
    css`
      margin-bottom: ${p.marginBottom};
    `}
    
    ${(p) =>
    p.marginLeft &&
    css`
      margin-left: ${p.marginLeft};
    `}
    
    ${(p) =>
    p.marginRight &&
    css`
      margin-right: ${p.marginRight};
    `}
`;
