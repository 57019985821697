import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import { Tipography } from "..";
/* Iconos */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { makeStyles } from "@mui/styles";

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
};

const useStyles = makeStyles({
  buttonStyles: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    width: "100%",
    heigth: "55px",
    color: palletteColors.GreyNeutro,
    border: "1px solid",
    borderRadius: "16px",
    borderColor: palletteColors.Grey,
  },
  buttonStylesChanges: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    width: "100%",
    heigth: "55px",
    color: palletteColors.Blue,
    border: "1px solid",
    borderRadius: "16px",
    borderColor: palletteColors.Blue,
  },
  gridContainerButtonStyles: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
});

const RadioButtonCustom = ({ info, selectOption, className }) => {
  const [typeButton, setTypeButton] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (info.typeButton === "eligeFondo") setTypeButton(true);
  }, [info]);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState(
    info.defaultSelect
  );

  const handleClick = (opcion) => (e) => {
    e.preventDefault();
    setOpcionSeleccionada(opcion);
    selectOption(opcion);
  };

  return (
    <Grid container sx={{ pl: matches ? 0 : 0, pr: matches ? 0 : 1 }}>
      <Grid
        item
        xs={matches ? 12 : 12}
        sx={{
          display: "flex",
          alignContent: "center",
        }}
      >
        {info.labelText.length > 0 && (
          <Tipography
            component="body2"
            style={{
              paddingBottom: "16px",
            }}
          >
            {info.labelText}
          </Tipography>
        )}
      </Grid>
      <Grid item xs={matches ? 12 : 12}>
        <Grid
          container
          spacing={1}
          sx={{ display: "flex" }}
          className={classes.gridContainerButtonStyles}
        >
          {info.opciones.map((item, index) => (
            <Grid
              key={index}
              item
              xs={matches ? 6 : typeButton ? 4 : 6}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingRight: "8px",
              }}
            >
              <Box
                variant="outlined"
                key={item.codigo}
                onClick={handleClick(item.codigo)}
                className={
                  opcionSeleccionada === item.codigo
                    ? classes.buttonStylesChanges
                    : classes.buttonStyles
                }
              >
                <Checkbox
                  icon={<CheckCircleIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  style={{
                    fontSize: "0.5em",
                    color:
                      opcionSeleccionada === item.codigo
                        ? palletteColors.Blue
                        : palletteColors.Grey,
                  }}
                />
                <Typography sx={{ fontSize: "0.8em" }}>
                  {item.nombre}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RadioButtonCustom;
