import styled, { css } from "styled-components";

export const SolicitudCanceladaComponent = styled.div`
  margin: auto;
  max-width: 651px;
`;

export const Subtitle = styled.p`
  ${(p) => p.theme.tipography.bodySmallRegular}
  color: ${(p) => p.theme.palette.grayscale[400]};
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  margin: auto;
  margin-top: 48px;
  max-width: 280px;
`;
