import styled from "styled-components";

export const ModalComponent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 16px);
  overflow: auto;
  padding: 32px;
  background-color: ${(p) => p.theme.palette.white};
  box-shadow: ${(p) => p.theme.elevations.aboveMd};
  
  outline: none;
  width: min(calc(100% - 16px), 450px);
  row-gap: 8px;
  display: grid;

  @media  (min-width: 600px) {
    border-radius: 16px;
  }

  p {
    margin-bottom: 0;
  }

  .close {
    padding: 8px;
    height: 32px;
    width: 32px;
    display: grid;
    justify-content: center;
    align-content: center;
    border-radius: 100px;
    justify-self: end;
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.palette.grayscale[100]};
    }
  }
  .title {
    ${(p) => p.theme.tipography.h5}
    text-align: center;
    margin-bottom: 16px;
  }
  .body {
    p {
      ${(p) => p.theme.tipography.bodySmallRegular}

      a {
        color: ${(p) => p.theme.palette.secondary.main};
        text-decoration: underline !important;
      }
    }
    p:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;
