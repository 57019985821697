import React, { useContext, useEffect, useState } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { Box } from "@mui/system";
import Container from "@mui/material/Container";
import ModalToken from "../../components/ModalToken";
import AuthContext from "../../context/autenticacion/authContext";
import CustomModal from "../../components/custromModal/CustomModal";
import CodeContext from "../../context/cuentaBloqueada/codeContext";
import { Grid } from "@mui/material";
import { FormGroup, Label, Input } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorGenerico from "./ErrorGenerico";
import "./index.css";
import logo from "../../assets/logo-fixed.png";
import ModalErrorToken from "./ModalErrorToken";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../GenerarClaveWeb/errors/error";

const SolicitudView = ({ ruta }) => {
  const listOptions = [
    { value: "00", text: "D.N.I", maxLength: 8, pattern: "[0-9]*" },
    {
      value: "01",
      text: "Carnet de Extranjería",
      maxLength: 9,
      pattern: "[0-9]*",
    },
    {
      value: "03",
      text: "Lib. Adolesc. Trab.",
      maxLength: 12,
      pattern: "[0-9]*",
    },
    { value: "04", text: "Pasaporte", maxLength: 15, pattern: "[a-zA-Z0-9 ]+" },
  ];
  const history = useHistory();

  const [typeDoc, setTypeDoc] = useState("00");
  const [mostrarModalToken, setMostrarModalToken] = useState(null);
  const [openModalToken, setopenModalToken] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);

  const [optionSelect, setOptionSelect] = useState("");
  const [lugarDeEnvio, setLugarDeEnvio] = useState("");
  const [token, setToken] = useState("");
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [mensajeCodigoValidado, setMensajeCodigoValidado] = useState(null);
  const [messageThirdOption, setMessageThirdOption] = useState(false);
  const [reenviarCodigo, setReenviarCodigo] = useState({
    activo: false, // true puedo solicitar clave // false no puedo
    cargando: false, // true solicitando clave // false dejo de solicitar
    solicitar: false, // true hizo una petición de carga // false termino
  });
  const [numDoc, setNumDoc] = useState({
    value: "",
    lenghtNdoc: 8,
    pattern: "[0-9]*",
  });

  const globalErrors = {
    r_document: "",
    r_confirm_type_document: "",
    r_mensaje: "",
  };
  const [captcha, setCaptcha] = useState(null);
  const [captchaLlenado, setCaptchaCargado] = useState(false);
  const [errores, setErrores] = useState(globalErrors);

  const [result, setResult] = useState(true); //deberia ser true
  const authContext = useContext(AuthContext);
  const codeContext = useContext(CodeContext);
  const {
    dataModalCode,
    dataPostCode,
    obtenerDatosCodeBloqueada,
    limpiarVariablesCodePost,
    limpiarVariablesCode,
    validarCodeBloqueada,
  } = codeContext;
  const {
    dataUnBlockLoading,
    dataUnBlock,
    obtenerDatosBloqueados,
    limpiarVariablesAccountBlock,
    LlenarErrorGenerico,
  } = authContext;
  useEffect(() => {
    limpiarVariablesAccountBlock();
    limpiarVariablesCodePost();
    limpiarVariablesCode();
  }, []);

  useEffect(() => {
    if (dataUnBlock) {
      if (dataUnBlock.code === 0) {
        setModalBlock(!modalBlock);
      } else {
        actualizarDataSeleccionada(
          "celular: ",
          "SMS",
          dataUnBlock.data.info.celular
        );
        actualizarDataSeleccionada(
          "correo: ",
          "EMAIL",
          dataUnBlock.data.info.email
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUnBlock]);
  useEffect(() => {
    if (dataPostCode) {
      if (dataPostCode.code === 0) {
        //DATALAYER DESBLOQUEAR CUENTA
        window.dataLayer.push({
          event: "modal_view",
          description: "tu cuenta ha sido desbloqueada",
        });
        setResult(false);
      } else {
        setMensajeCodigoValidado(dataPostCode.response);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPostCode]);

  const actualizarDataSeleccionada = (tipo, code, text) => {
    setOptionSelect(tipo + text);
    setLugarDeEnvio(code);
  };
  const { register, errors } = useForm();

  const handleNumDoc = (e) => {
    setNumDoc({
      ...numDoc,
      value: e.target.value,
    });
  };

  const respuestaCaptcha = (response) => {
    setCaptcha(response);
    if (!["", null, undefined].includes(response)) {
      setCaptchaCargado(true);
    }
  };

  const elegir = (e) => {
    let valor = e.target.value;
    if (valor === "-1") {
      setMessageThirdOption(true);
      setOptionSelect(valor);
    } else {
      let lugarDeEnvio = e.target.value.includes("correo") ? "EMAIL" : "SMS";
      setOptionSelect(valor);
      setLoadingEnvio(false);
      setLugarDeEnvio(lugarDeEnvio);
      setMessageThirdOption(false);
      limpiarVariablesCode();
      limpiarVariablesCodePost();
    }
  };
  const enviarClave = (option) => (e) => {
    e.preventDefault();

    let bodyFormData = {
      token: dataUnBlock.data.token,
      canal: lugarDeEnvio,
    };

    if (option === "enviar") {
      setLoadingEnvio(true);
      obtenerDatosCodeBloqueada(bodyFormData)
        .then(() => {
          setLoadingEnvio(false);
          setReenviarCodigo({ ...reenviarCodigo, solicitar: true });
        })
        .catch((error) => console.log(error));
    } else if (option === "re-enviar") {
      setReenviarCodigo({
        activo: false,
        cargando: true,
        solicitar: false,
      });
      obtenerDatosCodeBloqueada(bodyFormData)
        .then(() => {
          setReenviarCodigo({
            ...reenviarCodigo,
            activo: false,
            cargando: false,
            solicitar: true,
          });
        })
        .catch((error) => console.log(error));
    }
  };

  const handleToken = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setToken(valor);
    setMensajeCodigoValidado("");
  };

  const validarData = () => {
    let mensajeCodigo = "";
    if (token.length !== 0) {
      if (token.length < 6) {
        mensajeCodigo = "El token digital es de 6 dígitos";
      }
    } else {
      mensajeCodigo = "El token digital es requerido.";
    }

    if (mensajeCodigo.length > 0) {
      setMensajeCodigoValidado(mensajeCodigo);
      return false;
    } else {
      return true;
    }
  };

  const procesar = async () => {
    if (validarData()) {
      setLoadingEnvio(true);
      let data = {
        codigo: token,
        token: dataUnBlock.data.token,
      };
      await validarCodeBloqueada(data);
      setLoadingEnvio(false);
    }
  };

  const handleDocumentType = (e) => {
    let item = listOptions.find((option) => option.value === e.target.value);
    setTypeDoc(item.value);
    setNumDoc({
      ...numDoc,
      lenghtNdoc: item.maxLength,
      pattern: item.pattern,
    });
  };

  const handleConsult = async () => {
    //DATALAYER DESBLOQUEAR CUENTA
    window.dataLayer.push({
      event: "button_click",
      description: "desbloquea tu cuenta",
      option: "desbloquear",
    });
    let mensajesError = {
      r_document: "",
      r_confirm_type_document: "",
    };
    let data = {
      doc_type: typeDoc,
      doc_num: numDoc.value,
      "g-recaptcha-response": captcha,
    };

    if (validateDataForm(typeDoc, numDoc.value)) {
      let codigoR = await obtenerDatosBloqueados(data);
      if (codigoR === 1) {
        mensajesError.r_document = "Documento no pertenece afiliado.";
        mensajesError.r_confirm_type_document = "";
        setErrores(mensajesError);
      }
    }
  };

  const regexOnlyNumber = (text) => {
    let regex = new RegExp("^[0-9]+$");
    if (!regex.test(text)) {
      return false;
    } else {
      return true;
    }
  };

  const validateDataForm = (type, value) => {
    let rpt = true;
    let mensajesError = {
      r_document: "",
      r_confirm_type_document: "",
    };
    mensajesError = errores;
    switch (type) {
      case "00":
        if (!regexOnlyNumber(value)) {
          mensajesError.r_document = "Debe utilizar solo números.";
          rpt = false;
          break;
        }
        if (value.length < 8) {
          mensajesError.r_document = "Debe utilizar 8 digitos.";
          rpt = false;
        } else {
          mensajesError.r_document = "";
        }

        break;
      case "01":
        if (!regexOnlyNumber(value)) {
          mensajesError.r_document = "Debe utilizar solo números.";
          rpt = false;
          break;
        }
        if (value.length < 9) {
          mensajesError.r_document = "Debe utilizar 9 digitos.";
          rpt = false;
        } else {
          mensajesError.r_document = "";
        }

        break;
      case "03":
        if (!regexOnlyNumber(value)) {
          mensajesError.r_document = "Debe utilizar solo números.";
          rpt = false;
          break;
        }
        if (value.length < 12) {
          mensajesError.r_document = "Debe utilizar 12 digitos.";
          rpt = false;
        } else {
          mensajesError.r_document = "";
        }

        break;
      case "04":
        // if (value.length < 15) {
        //   mensajesError.r_document = 'Debe utilizar 15 caracteres.'
        //   rpt = false
        // } else {
        mensajesError.r_document = "";
        // }

        break;
      default:
        break;
    }
    setErrores({ ...mensajesError, r_document: mensajesError.r_document });
    return rpt;
  };

  return (
    <Box className="cuenta-container px-0">
      <ErrorGenerico></ErrorGenerico>
      <div className="header">
        <a href="https://www.afphabitat.com.pe/">
          <img src={logo} alt="" className="logoImg" />
        </a>
      </div>
      <Container maxWidth="lg">
        {result && (
          <Grid container className="contenedor-formulario">
            <Grid item xs={12} lg={6} className="cuenta-left">
              <p style={{ textAlign: "justify" }}>
                Por tu seguridad, tu cuenta de Mi Habitat Digital ha sido
                bloqueada, completa tu documento de identidad y te enviaremos un
                token para desbloquearla.
              </p>
            </Grid>
            <Grid item xs={12} lg={6} className="cuenta-right">
              <form id="form">
                <h3>Desbloquea tu cuenta</h3>
                <div className="form-group">
                  <label htmlFor="tipoDocumento">Tipo de documento:</label>
                  <select
                    className="form-control"
                    id="document-type"
                    {...register("documentType", { required: true })}
                    onChange={(e) => handleDocumentType(e)}
                  >
                    {listOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {errors?.documentType && (
                    <span className="error-new-home">Campo requerido*</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="documento">Nro Documento:</label>
                  <input
                    className="form-control"
                    id="document-number"
                    type="text"
                    autoComplete="off"
                    pattern={numDoc.pattern}
                    maxLength={numDoc.lenghtNdoc}
                    onKeyPress={(event) => {
                      if (numDoc.lenghtNdoc !== 15) {
                        let regex = new RegExp("^[0-9]+$");
                        let key = String.fromCharCode(
                          !event.charCode ? event.which : event.charCode
                        );
                        if (!regex.test(key)) {
                          event.preventDefault();
                          return false;
                        }
                      }
                    }}
                    {...register("documentNumber", {
                      required: true,
                      maxLength: numDoc.lenghtNdoc,
                    })}
                    onChange={(e) => handleNumDoc(e)}
                  />
                  {errors?.documentNumber &&
                    errors?.documentNumber.type === "required" && (
                      <span className="error-new-home">Campo requerido*</span>
                    )}

                  {errors?.documentNumber &&
                    errors?.documentNumber.type === "maxLength" && (
                      <span className="error-new-home">
                        Campo de {numDoc.lenghtNdoc} caracteres*
                      </span>
                    )}
                  <ErrorMessage
                    mensaje={errores.r_document}
                    mensajeletra={true}
                  ></ErrorMessage>
                </div>

                {captchaLlenado ? (
                  ""
                ) : (
                  <div className=" text-center my-3 captcha-container">
                    <ReCAPTCHA
                      sitekey="6LfH3XkmAAAAAAZ0C6jfmlhKRjVuO1VFvKt9-pNA"
                      onChange={respuestaCaptcha}
                    />
                  </div>
                )}

                {!dataUnBlock && (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={4} lg={5} justifyContent="center">
                      <button
                        type="button"
                        id="btn-recuperar"
                        className={`button habitat btn-total-2 text-center ${
                          !captcha ? "button-disabled" : ""
                        }`}
                        onClick={handleConsult}
                        disabled={!captcha}
                      >
                        Desbloquear{" "}
                        {dataUnBlockLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </Grid>
                  </Grid>
                )}

                {dataUnBlock && dataUnBlock.code !== 0 && (
                  <div>
                    {
                      <section className="d-flex container-options-clave">
                        <div className="form-group" style={{ width: "100%" }}>
                          <label htmlFor="lugardeenvio">
                            Deseo recibir mi token en:
                          </label>
                          <select
                            id="lugarEnvio"
                            className="form-control"
                            name="lugarEnvio"
                            onChange={elegir}
                            value={optionSelect}
                          >
                            <option value="">
                              - Seleccionar lugar de envio-
                            </option>
                            {dataUnBlock.data.info.email && (
                              <option
                                id="correo"
                                value={"correo: " + dataUnBlock.data.info.email}
                              >
                                Correo: {dataUnBlock.data.info.email}
                              </option>
                            )}
                            {dataUnBlock.data.info.celular && (
                              <option
                                id="celular"
                                value={
                                  "celular: " + dataUnBlock.data.info.celular
                                }
                              >
                                Celular: {dataUnBlock.data.info.celular}
                              </option>
                            )}

                            <option id="celular" value={-1}>
                              No cuento con estos datos
                            </option>
                          </select>
                        </div>
                      </section>
                    }
                    {messageThirdOption && (
                      <section>
                        <p>
                          Por tu seguridad, si ya no cuentas con tu celular y/o
                          correo registrados, deberás comunicarte a nuestra
                          central de actualización de datos llamando al{" "}
                          <strong>01 2302200.</strong>
                        </p>
                      </section>
                    )}

                    {lugarDeEnvio &&
                      !dataPostCode &&
                      !dataModalCode &&
                      optionSelect !== "-1" && (
                        <section className=" container-options-clave container-options-clave-custom">
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              lg={5}
                              justifyContent="center"
                            >
                              <button
                                type="button"
                                className="btn-total-2 text-center"
                                disabled={loadingEnvio}
                                onClick={enviarClave("enviar")}
                              >
                                Enviar token{" "}
                                {loadingEnvio && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                              </button>
                            </Grid>
                          </Grid>
                        </section>
                      )}
                    {dataModalCode && optionSelect !== "-1" && (
                      <section className="content-token mt-2">
                        <Grid container>
                          <Grid item xs={12}>
                            <FormGroup>
                              <Label htmlFor="token">
                                Escribe el código aquí:
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                name="token"
                                maxLength="6"
                                pattern="[0-9]*"
                                value={token}
                                onChange={handleToken}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <FormGroup>
                              <Label
                                htmlFor="mensajito"
                                style={{ fontSize: "12px", color: "#bf0d3e" }}
                              >
                                En caso no te llegue el código, vuelvelo a
                                solicitar.
                              </Label>
                            </FormGroup>
                          </Grid>
                          {mensajeCodigoValidado && (
                            <Grid item xs={12}>
                              <div className="alert alert-danger">
                                {mensajeCodigoValidado}, recargue la página y
                                vuelva a intentarlo.
                              </div>
                            </Grid>
                          )}
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              lg={5}
                              justifyContent="center"
                            >
                              <button
                                type="button"
                                onClick={procesar}
                                className="btn-total-2"
                              >
                                Confirmar{" "}
                                {loadingEnvio && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </section>
                    )}
                  </div>
                )}
              </form>
            </Grid>
          </Grid>
        )}
        {!result && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <Grid>
              <section id="exitoso" class="contenedor-mensaje contener-section">
                <div class="contenedor-top">
                  <div class="content-svg">
                    <svg
                      class="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        class="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        class="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>

                  <div class="contenedor-bottom">
                    <h3 class="text-center">Tu cuenta ha sido desbloqueada</h3>
                    <button
                      onClick={() =>
                        history.push("/mi-habitat-digital/empezar")
                      }
                    >
                      Ir a mi Habitat Digital
                    </button>
                  </div>
                </div>
              </section>
            </Grid>
          </Box>
        )}

        <CustomModal
          showModal={modalBlock}
          className="beauty-modal"
          backdrop={"static"}
          fnCloseIconBtn={() => {
            setModalBlock(!modalBlock);
          }}
          title="Tu cuenta se encuentra activa"
          description={
            <p>
              Hemos detectado que tu cuenta no ha sido bloqueada, recuerda que
              el límite de intentos para ingresar a Mi Habitat Digital son
              máximo 5.
            </p>
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() => {
            history.push("/");
            limpiarVariablesCodePost();
            setModalBlock(!modalBlock);
            limpiarVariablesCodePost();
            limpiarVariablesCode();
            limpiarVariablesAccountBlock();
          }}
          textBtnPrimary="Volver al inicio"
          hasBtnSecondary={false}
        />
        {openModalToken && (
          <ModalToken
            cerrarModal={() => {
              setopenModalToken(false);
            }} // fn para cerrar modal
            // guardarCode={aceptarProcesoModal}
          />
        )}
        {mostrarModalToken && (
          <ModalErrorToken
            mensaje={mostrarModalToken[0].message}
            cerrarModal={() => {
              setMostrarModalToken(false);
            }}
            guardarCode={() => {
              setMostrarModalToken(false);
              setopenModalToken(true);
            }}
          />
        )}
      </Container>
    </Box>
  );
};
export default hocWidthWindow(SolicitudView);
