import {
  HOME_EXITOSO,
  HOME_ERROR,
  HOME_LOADIN,
  HOME_CLEANER,
  HOME_CLEANER_PARCIAL,
  HOME_GET_GRAPHYC,
  HOME_LOADING_GRAPHYC,
  HOME_ERROR_GRAPHYC,
  HOME_RESETEAR_GRAPHYC,
} from "../../types";

import {
  SUMMARY_FUND_CLEANER,
  SUMMARY_FUND_ERROR,
  SUMMARY_FUND_EXITOSO,
  SUMMARY_FUND_LOADING,
  OPEN_MODAL_SIX_APORTS,
} from "../../types/home";

export default (state, action) => {
  switch (action.type) {
    case HOME_EXITOSO:
      const data = action.payload;
      const dataArray = Object.keys(data).map((i) => data[i]);
      const fondos = dataArray.filter((item) => item.r_fondo !== null);
      const total = dataArray.find((item) => item.r_fondo === null);
      sessionStorage.setItem("fondos", JSON.stringify(fondos));
      sessionStorage.setItem("total", JSON.stringify(total));
      return {
        ...state,
        fondos: fondos,
        total: total,
        mensajeAlerta: null,
        loading: false,
      };
    case "OPCIONESMENU":
      const opcionesMenu = action.payload;

      sessionStorage.setItem("opcionesMenu", JSON.stringify(opcionesMenu));
      return {
        ...state,
        opcionesMenu: opcionesMenu,
      };
    case HOME_ERROR:
      return {
        ...state,
        fondos: [],
        total: null,
        mensajeAlerta: action.payload,
        loading: false,
      };
    case HOME_LOADIN:
      return {
        ...state,
        loading: true,
      };
    case HOME_CLEANER:
      return {
        ...state,
        fondos: [],
        total: null,
        mensajeAlerta: null,
        loading: false,
        opcionesMenu: null,
      };
    case HOME_CLEANER_PARCIAL:
      return {
        ...state,
        fondos: [],
        total: null,
        // mensajeAlerta: null,
        loading: false,
      };
    case HOME_LOADING_GRAPHYC:
      return {
        ...state,
        loadingGraphyc: true,
      };
    case HOME_GET_GRAPHYC:
      return {
        ...state,
        graphycs: action.payload,
        mensajeAlertaGraphyc: null,
        loadingGraphyc: false,
      };
    case HOME_RESETEAR_GRAPHYC:
      return {
        ...state,
        graphycs: [],
        mensajeAlertaGraphyc: null,
        loadingGraphyc: false,
      };
    case HOME_ERROR_GRAPHYC:
      return {
        ...state,
        graphycs: [],
        mensajeAlertaGraphyc: action.payload,
        loadingGraphyc: false,
      };

    case SUMMARY_FUND_LOADING:
      return {
        ...state,
        summaryFundLoading: true,
      };
    case SUMMARY_FUND_CLEANER:
      return {
        ...state,
        summaryFundData: null,
        summaryFundLoading: false,
        mensajeAlerta: null,
      };
    case SUMMARY_FUND_EXITOSO:
      return {
        ...state,
        summaryFundData: action.payload,
        summaryFundLoading: false,
        mensajeAlerta: null,
      };
    case SUMMARY_FUND_ERROR:
      return {
        ...state,
        summaryFundData: null,
        summaryFundLoading: false,
        mensajeAlerta: action.payload,
      };
    case OPEN_MODAL_SIX_APORTS:
      return {
        ...state,
        openModalAport: action.payload,
      };
    default:
      break;
  }
};
