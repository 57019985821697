
import { Box } from '@mui/system';
import React, { useState } from 'react'
import styled from 'styled-components';

const Tab = styled('div')`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    flex-grow: 1;
    padding: 8px;
    color: ${props => props.active ? "#ffffff" : "#363A3E"};
    border-radius: 16px;
    background: ${props => props.active ? "#CE1336" : "transparent"};
`;

export const Tabs = ({ titulos }) => {

    const [index, setIndex] = useState(0);


    return (
        <>
            <Box sx={{
                border: '1px solid rgba(20, 20, 20, 0.15)',
                borderRadius: '16px',
                display: 'flex',
                margin: '8px',
            }}>
                <Tab active={index == 0} onClick={() => setIndex(0)}>Resumen</Tab>
                <Tab active={index == 1} onClick={() => setIndex(1)}>Aportes</Tab>
                <Tab active={index == 2} onClick={() => setIndex(2)}>Retiros</Tab>
            </Box>
        </>
    )
}
