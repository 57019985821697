import React from "react";
import Modal from "../../../BasicModal";
import Divider from "../../../Divider";
import Button from "../../../Buttons";
import { ButtonsWrapper, ModalTitle } from "./styles";
import WarningIcon from "../../../../assets/IconBucket/WarningIcon";

const index = ({ isOpen, setIsOpen, onContinue }) => {
  const closeModal = () => {
    //DATALAYER INVERTIR
    // console.log("eliminar");
    window.dataLayer.push({
      event: "button_click",
      product_name: "fondo libre habitat",
      step_name: "paso 2 - datos de inversion",
      description: "segura que quieres eliminar tu cuenta",
      option: "volver",
    });
    setIsOpen(false);
  };

  const onClickDelete = () => {
    setIsOpen(false);
    onContinue();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <ModalTitle>
        <div className="icon-wrapper">
          <WarningIcon />
        </div>
        ¿Seguro quieres eliminar esta cuenta?
      </ModalTitle>
      <Divider marginTop="8px" />
      <ButtonsWrapper>
        <Button variant={"secondary"} fullWidth onClick={closeModal}>
          Volver
        </Button>
        <Button fullWidth onClick={onClickDelete}>
          Eliminar
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default index;
