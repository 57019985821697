import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="32"
      // height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M13.72 5.147L2.426 24a2.667 2.667 0 002.28 4h22.587a2.667 2.667 0 002.28-4L18.28 5.147a2.667 2.667 0 00-4.56 0v0zM16 12v5.333M16 22.666h.012"
      ></path>
    </svg>
  );
}

export default Icon;
