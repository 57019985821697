import { Box } from "@mui/material";
import styled from "styled-components";

export const ContentTitleCheck = styled(Box)`
  width: 100%;

  & h4 {
    ${(p) => p.theme.tipography.h5}
    margin-bottom: 0;
  }
`;
export const ContentCheckMode = styled(Box)`
  display: flex;
`;
