import React, { useState, useContext } from "react";
import { Button } from "../../../../../../../../components";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import "./index.css";

export default function SelectMode(props) {
  const { endSixthStep, getOutOfProcess, dependencies } = props;
  const [selectMode, setSelectMode] = useState(null);
  const [check2, setCheck2] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const { guardarRejaEtapa6 } = jubilacionContext;

  const logicToCheck = (value) => {
    if (
      (value === "0" && selectMode === false) ||
      (value === "1" && selectMode === true)
    )
      setSelectMode(null);
    else if (
      (value === "0" && selectMode === true) ||
      (value === "1" && selectMode === false)
    )
      setSelectMode(!selectMode);
    else {
      if (value === "1" && selectMode === null) setSelectMode(true);
      else setSelectMode(false);
    }
  };
  const HandleSaveModalidad = () => {
    const datos = {
      modalidad: selectMode,
      apvf: check2,
    };

    guardarRejaEtapa6(datos);
    endSixthStep();
  };
  return (
    <div className="select-mode-container">
      <h1>Elección de modalidad</h1>

      <div className="content">
        <p className="mb-4">
          Te presentamos tu Fondo de Jubilación y/o Aportes Voluntarios con fin
          Previsional.
        </p>

        <div className="center-items">
          <div className="main-card-background mt-4">
            <span className="text-card">Fondo Obligatorio</span>
            <p>S/ {dependencies.contribution.obligatory}</p>
          </div>
        </div>

        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <p className="mb-3">
            Puedes incluir y sumar tus aportes voluntarios con fin previsional
            para el retiro o pensión.
          </p>
        )}
        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <div className="center-items">
            <div className="main-card-background mt-4">
              <span className="text-card">Fondo con Fin Previsional</span>
              <p>S/.{dependencies.contribution.voluntary}</p>
            </div>
          </div>
        )}

        {Number(dependencies.contribution.voluntary) === 0 ? null : (
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={check2}
                  className="radio-btn"
                  id="tfa_730"
                  name="juramento1"
                  onClick={() => setCheck2(!check2)}
                />
                <label
                  className="postRadioField paso6"
                  id="tfa_730"
                  for="tfa_730"
                  style={{ display: "flex" }}
                >
                  <span className="input-radio-faux"></span>
                  <p>
                    {" "}
                    Incluir mis aportes voluntarios como parte del fondo de
                    retiro o pensión.
                  </p>
                </label>
              </span>
            </div>
          </div>
        )}
        <div className="advice-card">
          <div className="advice-card-info">
            <p>
              <strong>Recuerda que ... </strong>{" "}
            </p>
            <ul>
              <li>
                El saldo disponible para retiro y/o pensión es calculado a la
                fecha de hoy. Es decir, podría variar en lo que quede del
                proceso.
              </li>
            </ul>
          </div>
        </div>

        <h3 className="mb-4">Selecciona tu modalidad:</h3>

        <div className="modes-check-container mb-5">
          <div
            className={`check-mode-content ${
              selectMode === false ? "select-mode-check" : ""
            }`}
          >
            <label htmlFor="tfa_711">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  checked={selectMode === false}
                  className="radio-btn"
                  id="tfa_711"
                  name="select_mode"
                  onClick={(e) => logicToCheck(e.target.value)}
                />
                <label className="postRadioField" id="tfa_711" for="tfa_711">
                  <span className="input-radio-faux"></span>
                </label>
              </span>

              <p>Pensión mensual</p>
            </label>
          </div>
          <div
            className={`check-mode-content ${
              selectMode === true ? "select-mode-check" : ""
            }`}
          >
            <label htmlFor="tfa_721">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={selectMode === true}
                  className="radio-btn"
                  id="tfa_721"
                  name="select_mode"
                  onClick={(e) => logicToCheck(e.target.value)}
                />
                <label className="postRadioField" id="tfa_721" for="tfa_721">
                  <span className="input-radio-faux"></span>
                </label>
              </span>

              <p>Retiro de hasta 95.5%</p>
            </label>
          </div>
        </div>
      </div>

      <div className="container-reja-buttons">
        <Button
          className="mb-4 btn-modal-secondary"
          name="secondary"
          bgColor="white"
          onClick={() => getOutOfProcess()}
        >
          Volver
        </Button>
        <Button
          className={`mb-4 btn-modal-primary ${
            selectMode !== null ? "" : "disable-secondary"
          }`}
          onClick={() => HandleSaveModalidad()}
          disabled={selectMode === null}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}
