import React from "react";
import "./customModal.css";
import "./beautyModal.css";

import { Dialog, Divider, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useMediaQuery, useTheme, Grid, Button } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const styles = {
  titleModal: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeigt: "28px",
  },
  ContentModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    textAlign: "justify",
  },
  ContentAdviceModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    color: "black",
  },
};

const CustomModal = (props) => {
  const { showModal, title, onClosed } = props;

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/mi-habitat-digital/afiliado/inicio");
  };

  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div style={{ height: "16px" }}></div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.titleModal}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px" }}>
        <Typography sx={styles.ContentModal}>
          Esta opción se encuentra en mantenimiento. Te avisaremos cuando vuelva
          a estar disponible.
        </Typography>
        <Divider sx={{ marginTop: "16px" }} />
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ p: 1, justifyContent: "space-evenly" }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.buttonsDownStyles}
              onClick={handleRedirect}
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
