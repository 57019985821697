import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import IC_INFO_RETIRO_EXTRAORDINARIO from "./../../../../../assets/icons/ic_info_retiro_extraordinario.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as moment from "moment";
import { IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import "moment/locale/es";

const Card = styled("div")`
  background: #ffffff;
  border: 1px solid rgba(20, 20, 20, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 1.5rem;
`;

const TagTramite = styled("div")`
  padding: 3px 8px;
  border-radius: 16px;
  font-size: 12px;
  color: white;
`;

const Button = styled("a")`
  color: #2f6d95;
  cursor: pointer;
  font-size: 14px;
  :hover {
    text-decoration: none;
  }
`;

const useStyles = makeStyles(theme => ({
  arrow: {
    "&::before": {
      background: '#EFF3FE',
      border: "1px solid #dadde9",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
    },
    color: '#EFF3FE'
  },
}));


export const HtmlTooltip = styled(
  ({
    className,
    classArrow,
    showTooltip,
    color = "#EFF3FE",
    setShowTooltip,
    placement,
    ...props
  }) => (
    <Tooltip
      open={showTooltip}
      placement={window.screen.width < 990 ? "top" : "right"}
      arrow
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      {...props}
      classes={{
        popper: className,
        arrow: classArrow,
      }}
    />
  )
)(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${color}`,
    color: "rgba(0, 0, 0, 0.87)",
    /* maxWidth: 220, */
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
  },
}));

export const CardNew = ({
  url_info,
  nro_solicitud,
  title,
  subtitle,
  estado_des,
  estado_tr,
  estado_ro,
  estado_rch,
  estado_acr,
  monto_solicitado,
  fec_aplicacion,
  fecha_recep,
  fecha_recep_format,
  fec_estado_rch,
  current_sol_status,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  let history = useHistory();
  const { tipo } = useParams();
  moment.locale("es-es");
  const estado =
    tipo === "retiro-apv" || tipo === "tfe" || tipo === "credito-hipotecario"
      ? current_sol_status === "RECHAZADA"
        ? "RECHAZADO"
        : current_sol_status === "PROCESADA"
          ? "FINALIZADO"
          : "EN CURSO"
      : "RETIROS EXTRAORDINARIOS";

  const handleOnClick = (nro) => {
    history.push(
      "/mi-habitat-digital/afiliado/estado-de-tramite/" + tipo + "/" + nro,
      { search: nro }
    );
  };

  let classes = useStyles();

  return (
    <>
      <Card>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              color: "#2B2B2B",
            }}
          >
            {tipo === "retiros-extraordinarios" ? title : "N.º " + nro_solicitud}
          </div>

          {tipo !== "retiros-extraordinarios" && (
            <TagTramite
              style={{
                background:
                  estado === "EN CURSO"
                    ? "#0043CE"
                    : estado === "RECHAZADO"
                      ? "#EFBD0C"
                      : "#1B7935",
              }}
            >
              {estado?.charAt(0).toUpperCase() + estado?.slice(1).toLowerCase()}
            </TagTramite>
          )}
        </div>

        <div
          style={{
            padding: "0.75rem 0rem",
          }}
        >
          {tipo !== "retiros-extraordinarios" ? (
            <>
              <div
                style={{
                  fontSize: "12px",
                  color: "#51565F",
                }}
              >
                Fecha de solicitud: {moment(fecha_recep).format("DD MMM YYYY")}
              </div>

              {monto_solicitado && (
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2B2B2B",
                  }}
                >
                  Monto solicitado: S/{monto_solicitado}
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                fontSize: "16px",
                letterSpacing: "0.2px",
                color: "#6E7378",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{subtitle}</div>
              <div>
                <HtmlTooltip
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                  color={'#EFF3FE'}
                  classArrow={classes.arrow}
                  title={
                    <div
                      style={{
                        padding: "0.4rem",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "0.2px",
                          color: "#363A3E",
                        }}
                      >
                        Conoce más sobre la {subtitle}
                      </div>
                      <a
                        href={url_info}
                        style={{
                          fontSize: "12px",
                          lineHeight: "20px",
                          letterSpacing: "0.2px",
                          textDecoration: "underline",
                          color: "#3D769C",
                          display: "flex",
                          padding: "8px 0px",
                          justifyContent: "flex-end",
                        }}
                      >
                        Ir a la web
                      </a>
                    </div>
                  }
                >
                  <IconButton onClick={() => setShowTooltip(!showTooltip)}>
                    <img src={IC_INFO_RETIRO_EXTRAORDINARIO} alt="retiro-extrordinario"/>
                  </IconButton>
                </HtmlTooltip>
              </div>
            </div>
          )}
        </div>

        <div>
          <hr
            style={{
              background: "rgba(20, 20, 20, 0.05)",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              borderTop: "1px solid rgba(0,0,0,.0)",
            }}
          />
        </div>

        <div
          style={{
            display: "inline-flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={() => handleOnClick(nro_solicitud)}>
            Ver detalle
          </Button>
        </div>
      </Card>
    </>
  );
};
