import React from "react";
import Header from "../componentes/Header";
import MontoAlDiaDeHoy from "./MontoAlDiaDeHoy";
import { useFormContext } from "react-hook-form";
import DatosDeRetiro from "./DatosDeRetiro";
import { PasoDatosDeRetiroComponent } from "./styles";

const PasoDatosDeRetiro = ({ montoTotal }) => {
  const {
    watch,
    formState: { isValid },
  } = useFormContext();

  return (
    <PasoDatosDeRetiroComponent>
      <Header />
      <MontoAlDiaDeHoy montoTotal={montoTotal} />
      <DatosDeRetiro montoTotal={montoTotal} />
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </PasoDatosDeRetiroComponent>
  );
};

export default PasoDatosDeRetiro;
