import styled, { css } from "styled-components";

export const ErrorMessageComponent = styled.div`
  // position: absolute;
  top: 100%;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.palette.state.error};
  width: 100%;
  text-align: justify;
  /* display: grid; */
  line-height: 1.2 !important;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.absolute === false &&
    css`
      position: relative;
    `}

  span {
    ${(p) => p.theme.tipography.caption};
    line-height: 1.2 !important;
  }
`;
