import React, { useContext, useEffect } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import PrimerPaso from "./PrimerPaso";
import Boleta from "./Boleta";
import "./index.css";

import BoletapagoContext from "../../context/boletapagopension/boletapagoContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ReactGa from "react-ga";
import Loading from "../../components/Loading/index.jsx";
import { CardContainer, Page, NotFoundData } from "../../components";
import Grid from "@mui/material/Grid";
/** PARA EL PDF  */
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
/** PARA EL PDF FIN */

const BoletaPagoPension = (props) => {
  //Obtenemos los state
  const boletapagoContext = useContext(BoletapagoContext);

  //Obtenemos los state
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const {
    obtenerDatosPeriodos,
    obtenerDatosBeneficiarios,
    clearBeneficiarios,
    clearBoleta,
    obtenerDatosBoleta,
    mensajeAlerta,
    periodoInfo,
    categoriaInfo,
    categoriaLoading,
    loadingBtn,
    datosBoleta,
    limpiarVariablesBoletaPagoParcial,
  } = boletapagoContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosPeriodos();
    }

    return () => {
      limpiarVariablesBoletaPagoParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  let r_beneficiarios = null;
  if (categoriaInfo) {
    r_beneficiarios = categoriaInfo.r_beneficiarios;
  }

  const getBeneficiarios = (datos) => {
    obtenerDatosBeneficiarios(datos);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (periodoInfo === null) return <Loading />;
  const { r_cuspp, r_periodos } = periodoInfo;

  return (
    <Page title="Boletas de pago de pensión" showHeader={"mobile"}>
      <Grid container spacing={2}>
        {periodoInfo.r_validacion === 1 ? (
          <Grid item xs={12} lg={8}>
            <PrimerPaso
              custpp={r_cuspp}
              periodos={r_periodos}
              beneficiarios={r_beneficiarios}
              loadingBeneficiario={categoriaLoading}
              loadingBtn={loadingBtn}
              getBeneficiarios={getBeneficiarios}
              clearBeneficiarios={clearBeneficiarios}
              clearBoleta={clearBoleta}
              obtenerDatosBoleta={obtenerDatosBoleta}
            />

            {datosBoleta && (
              <>
                <div
                  className="d-flex justify-content-center mb-2"
                  style={{ backgroundColor: "#fff" }}
                >
                  <PDFDownloadLink
                    className="btn btn-danger custon-btn-donwload"
                    document={<Boleta datos={datosBoleta} />}
                    fileName="boletapago.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Cargando..."
                      ) : (
                        <span>
                          Descargar{" "}
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </span>
                      )
                    }
                  </PDFDownloadLink>
                </div>
                {props.windowWidth > 600 && (
                  <section className="contenedor-over">
                    <PDFViewer width="100%" height="100%">
                      <Boleta datos={datosBoleta} />
                    </PDFViewer>
                  </section>
                )}
              </>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} lg={12}>
            <CardContainer>
              <NotFoundData
                mensaje={
                  <span>
                    Actualmente no registras boletas de pensión.
                    <br /> Te invitamos a seguir navegando dentro de Mi Habitat
                    Digital.
                  </span>
                }
                btnText={"Volver al inicio"}
                url={"/mi-habitat-digital/afiliado/inicio"}
                icon={true}
              />
            </CardContainer>
          </Grid>
        )}

        {/* {props.windowWidth < 600 && datosBoleta && <Recordatorio />} */}
      </Grid>
    </Page>
  );
};

export default hocWidthWindow(BoletaPagoPension);
