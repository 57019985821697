import styled, { css, keyframes } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import { device } from "../../../../utils/device";

export const AccordionComponent = styled(Accordion.Root)`
  h3 {
    margin-bottom: 0;
  }
`;

export const ItemComponent = styled(Accordion.Item)`
  border-top: 1px solid ${(p) => p.theme.palette.grayscale[100]};

  &:last-of-type {
    border-bottom: 1px solid ${(p) => p.theme.palette.grayscale[100]};
  }
`;

export const HeaderComponent = styled(Accordion.Header)``;

export const Trigger = styled(Accordion.Trigger)`
  width: 100%;
  background-color: ${(p) => p.theme.palette.white} !important;
  text-align: left;
  ${(p) => p.theme.tipography.subtitleM}
  padding:  16px 0;
  border: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  color: ${(p) => p.theme.palette.grayscale[800]};
  transition: all 200ms ease-in-out;

  ${(p) => css`
    @media screen and ${device.tablet} {
      column-gap: 16px;
      justify-content: start;
    }
  `}

  svg {
    width: 16px;
    height: 16px;
    align-self: center;
    color: ${(p) => p.theme.palette.grayscale[800]};
    transition: 200ms ease-in-out;
  }

  &[data-state="open"] {
    svg {
      transform: rotate(-180deg);
    }
  }
`;

const slideDown = keyframes`
  from {
    height: 0;
    
  }
  
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
`;

export const ContentComponent = styled(Accordion.Content)`
  ${(p) => p.theme.tipography.bodySmallRegular}
  overflow: hidden;

  &[data-state="open"] {
    animation: ${slideDown} 300ms ease-out;
  }
  &[data-state="closed"] {
    animation: ${slideUp} 300ms ease-out;
  }
`;

export const ContentWrapper = styled.div`
  padding-bottom: 16px;
`;
