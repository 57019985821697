import styled, { css } from "styled-components";
import { device } from "../../utils/device";
import * as Input from "../../components/Input";

export const BuscarTramiteComponent = styled.div`
  grid-area: main;
  display: grid;
  grid-template-rows: auto 1fr;

  grid-template-areas:
    "header"
    "body";
`;

export const Body = styled.div`
  grid-area: body;
  border-radius: 16px;
  background-color: ${(p) => p.theme.palette.white};
  margin-bottom: 24px;
`;

export const Main = styled.div`
  display: grid;
  align-content: start;
  padding: 24px 16px 16px 16px;

  a {
    color: ${(p) => p.theme.palette.secondary.main};
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }

  ${css`
    @media screen and ${device.desktopS} {
      padding: 48px 32px;
    }

    @media screen and ${device.desktopL} {
      padding: 48px 24px;
    }
  `}
`;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  ${css`
    @media screen and ${device.tablet} {
      justify-self: end;
      grid-column: 1/-1;
    }

    @media screen and ${device.desktop} {
      justify-self: auto;
      grid-column: auto;
      align-self: center;
    }
  `}
`;

export const FormWrapper = styled.div`
  ${css`
    @media screen and ${device.tablet} {
      display: grid;
      column-gap: 8px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and ${device.desktop} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and ${device.desktopL} {
      grid-template-columns: 1fr 1fr 1fr calc(42px + 8px + 240px);
    }
  `}
`;

export const InputWrapper = styled(Input.Wrapper)`
  ${css`
    @media screen and ${device.tablet} {
      display: grid;
      grid-template-rows: 1fr auto;
    }
  `}
`;

export const InputTitle = styled(Input.Title)`
  align-self: end;
`;

export const AlertWrapper = styled.div`
  ${css`
    @media screen and ${device.tablet} {
      margin-top: 24px;
    }

    @media screen and ${device.desktop} {
      margin-top: 0;
    }
  `}
`;

export const Request = styled.div`
  border: 1px solid ${(p) => p.theme.palette.grayscale[200]};
  grid-template-columns: auto 1fr;
  border-radius: 24px;
  padding: 24px;
  display: grid;
  column-gap: 8px;

  ${css`
    @media screen and ${device.tablet} {
      margin-top: 24px;
    }

    @media screen and ${device.desktop} {
      margin-top: 0;
    }
  `}
`;

export const RequestIcon = styled.div`
  grid-row: 1/7;
  margin-top: 8px;
  color: ${(p) => p.theme.palette.grayscale[800]};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const RequestSubtitle = styled.div`
  ${(p) => p.theme.tipography.caption}
`;

export const RequestTitle = styled.div`
  ${(p) => p.theme.tipography.h6}

  ${css`
    @media screen and ${device.tablet} {
      margin-bottom: 16px;
    }
  `}
`;

export const RequestInformationItem = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}

  span.item {
    ${(p) => p.theme.tipography.subtitleS}
  }

  span.value {
    &.rechazado {
      color: ${(p) => p.theme.palette.state.error};
    }

    &.pagado {
      color: ${(p) => p.theme.palette.state.success};
    }
  }
`;

export const RequestInformationWrapper = styled.div`
  margin-bottom: 4px;

  ${css`
    @media screen and ${device.tablet} {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      justify-self: start;
      align-self: start;
      margin-bottom: 16px;
    }
  `}
`;

export const RequestCaption = styled.div`
  ${(p) => p.theme.tipography.caption}
  color :   ${(p) => p.theme.palette.grayscale[400]}
`;
