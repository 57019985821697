import React, { useContext } from "react";
import { CancelarAporteVoluntarioSinFinContext } from "..";
import * as SummarySection from "../../../components/SummarySection";
import Button from "../../../components/Buttons";
import Alert from "../../../components/Alert";
import { Subtitle, ButtonWrapper, SolicitudCanceladaComponent } from "./styles";

import { useHistory } from "react-router-dom";
import { useMonthlyDebitDetailsContext } from "../context";

const SolicitudCancelada = () => {
  const {
    state: { monthlyDebitDeactivated },
    ...api
  } = useMonthlyDebitDetailsContext();

  const { r_fecha_activacion, r_fecha_desactivacion } =
    monthlyDebitDeactivated.data;

  const history = useHistory();
  const handleGoHome = () =>
    history.push("/mi-habitat-digital/afiliado/inicio");

  return (
    <SolicitudCanceladaComponent>
      <SummarySection.Section>
        <SummarySection.Header
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          Tu Débito mensual fue desactivado con éxito
        </SummarySection.Header>
        <Subtitle>
          Ya no te encuentras invirtiendo automáticamente en Fondo Libre
          Habitat.
        </Subtitle>
        <Alert
          description={
            <span>
              Podrás volver a activar otro desde:
              <b style={{ display: "block" }}>
                Home &gt; Fondo Libre Habitat &gt; Invertir
              </b>
            </span>
          }
        ></Alert>
        <SummarySection.Body>
          <SummarySection.Table style={{ gridColumn: "1/-1" }}>
            <SummarySection.TableTitle>Resumen</SummarySection.TableTitle>
            <SummarySection.TableSection>
              {[
                { label: "Fecha de activación", value: r_fecha_activacion },
                {
                  label: "Fecha de desactivación",
                  value: r_fecha_desactivacion,
                },
              ].map(({ label, value, show }, i) => (
                <SummarySection.TableItem
                  key={label}
                  label={label}
                  value={value}
                />
              ))}
            </SummarySection.TableSection>
          </SummarySection.Table>
        </SummarySection.Body>
        <ButtonWrapper>
          <Button size="lg" fullWidth onClick={handleGoHome}>
            Volver al inicio
          </Button>
        </ButtonWrapper>
      </SummarySection.Section>
    </SolicitudCanceladaComponent>
  );
};

export default SolicitudCancelada;
