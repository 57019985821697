import React, { useState } from "react";
import { Content, Header, Item } from "../../components/Accordion";
import { size } from "../../../../utils/device";
import Download from "../../../../assets/IconBucket/DownloadIcon";
import {
  BotonDeTipo,
  BotonesDeCambioDeTipo,
  Consideraciones,
  ContentWrapper,
  DownloadButtonWrapper,
  RequisitoItem,
  RequisitosWrapper,
  Section,
  Subtitle,
  Title,
} from "./styles";
import { BsFilePdf } from "react-icons/bs";
import ModalAutorizacionReembolso from "../ModalAutorizacionReembolso";
import useWindowSize from "../../../../hooks/useWindowSize";
import Divider from "../../../../components/Divider";
import Button from "../../../../components/Buttons";

const Requisitos = () => {
  const [typeOfApplicant, setTypeOfApplicant] = useState("natural");
  const { width } = useWindowSize();
  const smallScreen = width < size.tablet;

  const [
    modalAutorizacionReembolsoIsOpen,
    setModalAutorizacionReembolsoIsOpen,
  ] = useState(false);
  return (
    <Item value="requisitos">
      <Header>Requisitos</Header>
      <Content>
        <ContentWrapper>
          {smallScreen && (
            <Section>
              <Consideraciones>
                <p>Formatos admitidos: pdf.</p>
                <p>Se debe subir un archivo por cada documento.</p>
                <p>Tamaño máximo de archivo: 2mb.</p>
              </Consideraciones>
              <Title>Gastos cubiertos por:</Title>
              <BotonesDeCambioDeTipo>
                {[
                  { id: "natural", label: "Persona natural" },
                  { id: "empresa", label: "Persona jurídica" },
                ].map(({ id, label }) => (
                  <BotonDeTipo
                    type="button"
                    key={id}
                    data-isactive={id === typeOfApplicant}
                    onClick={() => setTypeOfApplicant(id)}
                  >
                    {label}
                  </BotonDeTipo>
                ))}
              </BotonesDeCambioDeTipo>
            </Section>
          )}
          <Section>
            {!smallScreen && (
              <Consideraciones>
                <p>
                  Cada archivo listado debe ser subido individualmente en
                  formato png, jpg, jpeg, pdf
                </p>
                <p>Se admite un solo archivo por documento</p>
                <p>Peso máximo por archivo 2mb</p>
              </Consideraciones>
            )}
            <Title>Documentos generales</Title>
          </Section>
          {smallScreen && (
            <>
              {typeOfApplicant === "natural" && <RequisitosPersonaNatural />}
              {typeOfApplicant === "empresa" && <RequisitosEmpesa />}
            </>
          )}
          {!smallScreen && (
            <RequisitosWrapper>
              <RequisitosPersonaNatural />
              <Divider mode="vertical" />
              <RequisitosEmpesa />
            </RequisitosWrapper>
          )}

          <ModalAutorizacionReembolso
            open={modalAutorizacionReembolsoIsOpen}
            setOpen={setModalAutorizacionReembolsoIsOpen}
          />
          <RequisitosAdicionalesEspecificos
            modalAutorizacionReembolsoIsOpen={modalAutorizacionReembolsoIsOpen}
            setModalAutorizacionReembolsoIsOpen={
              setModalAutorizacionReembolsoIsOpen
            }
          />
        </ContentWrapper>
      </Content>
    </Item>
  );
};

export default Requisitos;

export const Requisito = ({ description, aditional }) => {
  return (
    <RequisitoItem $aditional={aditional}>
      <div className="file-icon">
        <BsFilePdf />
      </div>
      <div className="body">
        <p className="description">{description}</p>
      </div>
      {/* <span className="aditional">{aditional}</span> */}
      <>{aditional}</>
    </RequisitoItem>
  );
};

export const RequisitosPersonaNatural = () => {
  const { width } = useWindowSize();

  return (
    <Section>
      {width >= size.tablet && <Subtitle>Persona natural</Subtitle>}
      {[
        {
          description:
            "Copia simple del documento de identidad del solicitante.",
          aditional: null,
        },
        {
          description:
            "Copia simple del certificado médico de defunción o copia certificada de declaración judicial de muerte presunta.",
          aditional: null,
        },
        {
          description: "Acta de defunción.",
          aditional: null,
        },
        {
          description:
            "Boletas o facturas originales que acrediten los gastos de sepelio, donde figuren los nombres del solicitante y del afiliado fallecido.",
          aditional: null,
        },
        {
          description:
            "Voucher bancario no mayor a 30 días, donde figure el nombre del banco, nombre del solicitante, moneda y número de cuenta.",
          aditional: null,
        },
      ].map(({ description, aditional }, key) => (
        <Requisito key={key} description={description} aditional={aditional} />
      ))}
    </Section>
  );
};

export const RequisitosEmpesa = () => {
  const { width } = useWindowSize();

  return (
    <Section>
      {width >= size.tablet && <Subtitle>Persona jurídica</Subtitle>}

      {[
        {
          description:
            "Copia simple del documento de identidad del solicitante.",
          aditional: null,
        },
        {
          description:
            "Copia simple del documento de identidad del dueño de la empresa.",
          aditional: null,
        },
        {
          description:
            "Copia autenticada notarialmente de la escritura pública de la empresa inscrita en registros públicos.",
          aditional: null,
        },
        {
          description:
            "Copia simple del certificado médico de defunción o copia certificada de declaración judicial de muerte presunta.",
          aditional: null,
        },
        {
          description: "Acta de defunción.",
          aditional: null,
        },
        {
          description:
            "Boletas o facturas originales que acrediten los gastos de sepelio, donde figuren los nombres del solicitante y del afiliado fallecido.",
          aditional: null,
        },
        {
          description:
            "Voucher bancario no mayor a 30 días, donde figure el nombre del banco, nombre del solicitante, moneda y número de cuenta.",
          aditional: null,
        },
      ].map(({ description, aditional }, key) => (
        <Requisito key={key} description={description} aditional={aditional} />
      ))}
    </Section>
  );
};

export const RequisitosAdicionalesEspecificos = ({
  modalAutorizacionReembolsoIsOpen,
  setModalAutorizacionReembolsoIsOpen,
}) => {
  const onClickInfoButton = () => setModalAutorizacionReembolsoIsOpen(true);

  return (
    <Section>
      <Title>Documentos específicos</Title>
      <Subtitle>Muerte accidental</Subtitle>
      {[
        {
          description: "Copia simple de necropsia.",
        },
        {
          description:
            "Copia simple del atestado policial o documento oficial que relate el suceso.",
        },
      ].map(({ description, aditional }, key) => (
        <Requisito key={key} description={description} aditional={aditional} />
      ))}
      <Subtitle>Muerte por enfermedad/muerte natural </Subtitle>
      {[
        {
          description:
            "Copia simple de la epicrisis o resumen de la historia clínica.",
        },
      ].map(({ description, aditional }, key) => (
        <Requisito key={key} description={description} aditional={aditional} />
      ))}
      <Subtitle>
        En caso el solicitante no sea un familiar directo del fallecido.
      </Subtitle>
      {[
        {
          description:
            "Autorización de reembolso del familiar directo y copia de DNI del familiar.",
          onClickInfoButton,
        },
      ].map(({ description, aditional }, key) => (
        <Requisito
          key={key}
          description={description}
          aditional={
            <DownloadButtonWrapper>
              <Button
                icon={<Download />}
                size="sm"
                variant={"secondary"}
                onClick={onClickInfoButton}
              >
                Descargar formato
              </Button>
            </DownloadButtonWrapper>
          }
        />
      ))}
    </Section>
  );
};
