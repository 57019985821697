import React, { Component, Fragment } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

// import './respuesta.scss';
am4core.useTheme(am4themes_animated);
class GraficoDoceMeses extends Component {
  componentDidMount() {
    const { twelveMonth } = this.props;
    this.drawGraphic(twelveMonth);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  drawGraphic = (dataArray) => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.language.locale = am4lang_es_ES;
    // chart.paddingRight = 50;
    // chart.paddingLeft = 50;

    chart.data = dataArray;
    chart.language.locale["_decimalSeparator"] = ".";

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";

    categoryAxis.renderer.minLabelPosition = 0.1;
    categoryAxis.renderer.maxLabelPosition = 0.95;

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.tooltip.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    /**Ocultamos los tooltips en X y Y */
    valueAxis.tooltip.disabled = true;

    /** Formateamos el número a 2 decimales */
    valueAxis.numberFormatter.numberFormat = "#,###.00";

    let series0 = this.createSeries(
      "fund0",
      "Fondo 0",
      chart,
      "rgb(24, 104, 183)"
    );
    let series1 = this.createSeries(
      "fund1",
      "Fondo 1",
      chart,
      "rgb(57, 77, 96)"
    );
    let series2 = this.createSeries("fund2", "Fondo 2", chart, "#77adff");
    let series3 = this.createSeries(
      "fund3",
      "Fondo 3",
      chart,
      "rgb(217, 0, 52)"
    );

    this.customSeriesColor(series0, "rgb(24, 104, 183)");
    this.customSeriesColor(series1, "rgb(57, 77, 96)");
    this.customSeriesColor(series2, "#77adff");
    this.customSeriesColor(series3, "rgb(217, 0, 52)");

    chart.cursor = new am4charts.XYCursor();

    chart.cursor.behavior = "none";
    chart.cursor.lineX.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(2, 2, 2, 2);
    marker.strokeWidth = 2;
    marker.strokeOpacity = 1;
    marker.stroke = am4core.color("#ccc");
    chart.legend.itemContainers.template.events.on("hit", function (ev) {
      // console.log('miremos ', ev.target.dataItem.dataContext.name)
    });
    this.chart = chart;
  };

  createSeries = (field, name, chart, customColor) => {
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "date";
    series.dataFields.categoryXX = "date2";
    series.name = name;
    series.tooltip.label.fontSize = 13;
    series.tooltipText =
      "S/ {valueY}    {categoryXX.formatDate('dd MMM, yyyy')}";
    series.stroke = am4core.color(customColor);

    return series;
  };

  customSeriesColor = (series, customColor) => {
    series.stroke = am4core.color(customColor);
    series.fill = am4core.color(customColor);
  };

  render() {
    const { twelveMonth } = this.props;
    return (
      <Fragment>
        {twelveMonth && (
          <div className="container-graphic bg-white">
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
            <div id="box-logo"></div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default GraficoDoceMeses;
