import React from "react";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import Modal from "../../../../components/BasicModal";
import ExternalLink from "../../components/ExternalLink";

const ModalDeTratamientoDeDatos = ({ open, setOpen }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>Tratamiento de datos personales</Modal.Title>
      <Modal.Subtitle>
        <p>
          Conozco que la información será tratada de conformidad con la{" "}
          <ExternalLink
            href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
            target="_blank"
          >
            Política de Privacidad
          </ExternalLink>{" "}
          de AFP Habitat S.A.
        </p>
        <br />
        <p>
          Autorizo voluntariamente que AFP Habitat S.A. realice tratamiento de
          mis datos personales, además, con fines de envío de publicidad sobre
          los productos y servicios de la compañía y de aquellas que forman
          parte de su grupo económico, de conformidad con la{" "}
          <ExternalLink
            href="https://www.afphabitat.com.pe/wp-content/uploads/2019/11/Politica-de-privacidad-2019.pdf"
            target="_blank"
          >
            Política de Privacidad (facultativo)
          </ExternalLink>
          .
        </p>
      </Modal.Subtitle>
      <Divider />

      <Button fullWidth onClick={onClose} style={{ margin: "auto" }}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeTratamientoDeDatos;
