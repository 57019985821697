import React, { useState, useEffect } from "react";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import styled from "styled-components";

const ContentRent = styled("div")`
  margin-bottom: 16px;
  padding: 16px;
`;
const DivRentaFija = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C92D54")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaVariable2 = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#73607D")};
  border-radius: ${(props) => (props.borderRent ? props.borderRent : "0px")};
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaAlternativo = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#C3ACCF")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const DivRentaLocal = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#CE1336")};
  border-radius: 16px 16px 0px 0px;
  text-align: center;
  color: #ffffff;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
`;

const DivRentaInternacional = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8A001A")};
  border-radius: 0px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivRentaTransito = styled("div")`
  width: 91px;
  height: ${(props) => (props.height ? `${props.height}px` : "114px")};
  background: ${(props) => (props.background ? props.background : "#8E9B9E")};
  border-radius: 0px 0px 16px 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Circle = styled("div")`
  height: ${(props) => (props.size ? props.size : "25px")};
  width: ${(props) => (props.size ? props.size : "25px")};
  background-color: ${(props) =>
    props.background ? props.background : "#bbb"};
  border-radius: 50%;
  display: inline-block;
`;
/* estilos */
export const styles = {
  cardStyle: {
    width: "98%",
    margin: "0 auto",
    fontSize: "10px",
    borderRadius: "16px",

    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
    userSelect: "none",
  },
  cardStyleChanges: {
    width: "98%",
    fontSize: "10px",
    borderRadius: "16px",
    border: "1.5px solid #2F6D95",

    boxShadow: "0px 8px 24px rgba(27, 29, 39, 0.17)",
    userSelect: "none",
  },
  cardContentStyle: {
    minHeight: "250px",
    p: 2,
  },

  textCard: {
    fontSize: "14px",
    color: "#363A3E",
  },
  textCardDetail: {
    fontSize: "12px",
    color: "#363A3E",
  },
  circleCardLocal: {
    fontSize: "14px",
    color: "#363A3E",
  },
  circleCardInte: {
    fontSize: "14px",
    color: "#363A3E",
  },
  circleCardTrans: {
    fontSize: "14px",
    color: "#363A3E",
  },
  boxContainerTitle: {
    borderRadius: "8px",
    // bgcolor: "#B7E3FF",
    // width: "90%",

    pl: 1,
    pr: 1,
  },
  boxContainerTitleChanges: {
    borderRadius: "8px",
    // bgcolor: "#B7E3FF",
    // width: "85%",

    pl: 1,
    pr: 1,
  },
  boxContainerTitleChangesXL: {
    borderRadius: "8px",
    // bgcolor: "#B7E3FF",
    // width: "80%",
  },
  titleDesktopStyle: {
    fontSize: "1.5em",
    // pt: "6px",
    fontWeight: "bold",
  },
  titleDesktopSubtitleStyle: {
    background: " #2F6D95",
    borderRadius: "16px",
    fontSize: "12px",
    padding: "4px 8px",
    textAlign: "center",
    color: "#ffffff",
  },
  titleMobileSubtitleStyle: {
    background: " #2F6D95",
    borderRadius: "16px",
    fontSize: "12px",
    textAlign: "center",
    padding: "4px 8px",
    color: "#ffffff",
  },
  titleResponsiveStyle: {
    fontSize: "1.6em",
    // pt: "6px",
    fontWeight: "bold",
  },
  gridCheckboxStyle: {
    display: "flex",
    justifyContent: "end",
  },
  gridRiesgoStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pt: 2,
  },
  gridRentabilidadStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    bgcolor: "#F6F6F6",
    borderRadius: "8px",
  },
};

const BasicCard = (props) => {
  const { info, defaultSelect, buttonType, selectOption, step } = props;
  /* Hooks para los cambios al ser seleccionados */
  const [typeButton, setTypeButton] = useState(false);
  const [arrayFunds, setArrayFunds] = useState([]);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(defaultSelect);
  /* material ui */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const screenXL = useMediaQuery(theme.breakpoints.down("xl"));

  /* Hook verifica la data */
  useEffect(() => {
    if (buttonType === "eligeFondo") setTypeButton(true);
  }, [buttonType, info]);

  useEffect(() => {
    if (step === 0) {
      setOpcionSeleccionada(defaultSelect);
    }
  }, [step]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const resultFund = info.map((item) => {
      let arrayPies = props.grafico?.funds?.typeOfIncomeChart?.filter(
        (itemX) => itemX.fondo == item.id
      );
      let arrayCartera = props.cartera?.ManagedPortfolioChart?.filter(
        (itemY) => itemY.fondo == item.id
      );
      item.arrayFund = arrayPies;
      item.arrayCartera = arrayCartera;
      return item;
    });
    setArrayFunds(resultFund);
  }, []);
  return (
    <Grid container sx={{ pb: 2 }}>
      {arrayFunds &&
        arrayFunds.map((item) => {
          return (
            <Grid key={item.id} item xs={matches ? 12 : 4}>
              <Box
                sx={{
                  background: "#F6F6F6",
                  ml: 1,
                  mr: 1,
                  borderRadius: "16px",
                }}
              >
                <Card sx={styles.cardStyle}>
                  <Box sx={styles.cardContentStyle}>
                    <Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <Box
                            sx={
                              screenXL
                                ? matches
                                  ? styles.boxContainerTitle
                                  : styles.boxContainerTitleChangesXL
                                : styles.boxContainerTitleChanges
                            }
                          >
                            <Typography
                              sx={
                                matches
                                  ? styles.titleResponsiveStyle
                                  : styles.titleDesktopStyle
                              }
                            >
                              {item.title}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Box
                            sx={
                              screenXL
                                ? matches
                                  ? styles.boxContainerTitle
                                  : styles.boxContainerTitleChangesXL
                                : styles.boxContainerTitleChanges
                            }
                          >
                            <Typography
                              sx={
                                matches
                                  ? styles.titleMobileSubtitleStyle
                                  : styles.titleDesktopSubtitleStyle
                              }
                            >
                              {item.subTitle}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ pt: 1 }}>
                      <Grid item xs={6} sx={styles.gridRiesgoStyle}>
                        <Box
                          component="img"
                          sx={{ width: "86px", pb: 2, pt: 2 }}
                          src={item.riesgoImg}
                          alt="Gráfico de nivel de riesgo"
                        />
                      </Grid>
                      <Grid item xs={6} sx={styles.gridRiesgoStyle}>
                        <Box
                          component="img"
                          sx={{ width: "66px", pb: 2, pt: 2 }}
                          src={item.rentabilidadImg}
                          alt="Gráfico de nivel de riesgo"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            borderRadius: "8px",
                            pl: 1,
                            pr: 1,
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              Nivel de Riesgo:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ color: "#6E7378", fontSize: "12px" }}
                              >
                                {item.riesgo}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            borderRadius: "8px",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              Potencial de rentabilidad:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography
                                sx={{
                                  color: "#6E7378",
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                {item.rentabilidad}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
                <ContentRent>
                  <Typography sx={styles.textCard}>
                    Cómo se distribuye el fondo
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 3 }} />
                  <Grid container>
                    <Grid item lg={7}>
                      <List aria-label="mailbox folders">
                        <ListItem sx={{ pl: 0 }}>
                          <Box
                            sx={{
                              background: "#CE1336",
                              borderRadius: "90px",
                              width: "13px",
                              height: "13px",
                              marginRight: "4px",
                            }}
                          ></Box>
                          <Typography sx={styles.textCardDetail}>
                            Inversiones Locales
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ pl: 0 }}>
                          <Box
                            sx={{
                              background: "#8A001A",
                              borderRadius: "90px",
                              width: "13px",
                              height: "13px",
                              marginRight: "4px",
                            }}
                          ></Box>
                          <Typography sx={styles.textCardDetail}>
                            Inversiones Extranjeras
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                          <Box
                            sx={{
                              background: "#8E9B9E",
                              borderRadius: "90px",
                              width: "13px",
                              height: "13px",
                              marginRight: "4px",
                            }}
                          ></Box>
                          <Typography sx={styles.textCardDetail}>
                            Operaciones en tránsito
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item lg={5}>
                      {item.arrayCartera.map((item_detail) => {
                        if (item_detail?.name === "Inversiones locales") {
                          return (
                            <DivRentaLocal
                              height={(Number(item_detail.value) / 2 + 16) * 2}
                            >
                              {item_detail.value}%
                            </DivRentaLocal>
                          );
                        }
                        if (
                          item_detail?.name === "Inversiones en el exterior"
                        ) {
                          return (
                            <DivRentaInternacional
                              height={(Number(item_detail.value) / 2 + 16) * 2}
                            >
                              {item_detail.value}%
                            </DivRentaInternacional>
                          );
                        } else {
                          return (
                            <DivRentaTransito
                              height={(Number(item_detail.value) / 2 + 16) * 2}
                            >
                              {item_detail.value}%
                            </DivRentaTransito>
                          );
                        }
                      })}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ pt: 2 }}>
                    <Grid item lg={7}>
                      <List component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ pl: 0 }}>
                          <Box
                            sx={{
                              background: "#C92D54",
                              borderRadius: "90px",
                              width: "13px",
                              height: "13px",
                              marginRight: "4px",
                            }}
                          ></Box>
                          <Typography sx={styles.textCardDetail}>
                            Renta Fija
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ pl: 0 }}>
                          <Box
                            sx={{
                              background: "#73607D",
                              borderRadius: "90px",
                              width: "13px",
                              height: "13px",
                              marginRight: "4px",
                            }}
                          ></Box>
                          <Typography sx={styles.textCardDetail}>
                            Renta Variable
                          </Typography>
                        </ListItem>
                        {item.id !== 1 && (
                          <ListItem sx={{ pl: 0 }}>
                            <Box
                              sx={{
                                background: "#C3ACCF",
                                borderRadius: "90px",
                                width: "13px",
                                height: "13px",
                                marginRight: "4px",
                              }}
                            ></Box>
                            <Typography sx={styles.textCardDetail}>
                              Alternativo
                            </Typography>
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                    <Grid item lg={5}>
                      {item.arrayFund.map((item_detail) => {
                        if (item_detail?.name === "Renta Fija") {
                          return (
                            <DivRentaFija
                              height={(Number(item_detail.value) / 2 + 8) * 2}
                            >
                              {item_detail.value}%
                            </DivRentaFija>
                          );
                        }
                        if (item_detail?.name === "Renta Variable") {
                          return (
                            <DivRentaVariable2
                              height={(Number(item_detail.value) / 2 + 8) * 2}
                              borderRent={
                                item.id === 1 ? "0px 0px 16px 16px" : "0px"
                              }
                            >
                              {item_detail.value}%
                            </DivRentaVariable2>
                          );
                        } else {
                          return (
                            <DivRentaAlternativo
                              height={(Number(item_detail.value) / 2 + 8) * 2}
                            >
                              {item_detail.value}%
                            </DivRentaAlternativo>
                          );
                        }
                      })}
                    </Grid>
                  </Grid>
                </ContentRent>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default BasicCard;
