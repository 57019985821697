import React, { useEffect, useContext } from "react";
import * as Input from "../../../../components/Input";
import Divider from "../../../../components/Divider";
import InfoButton from "../../../../components/InfoButton";
import { useFormContext } from "react-hook-form";
import RetiroapvsinfinContext from "../../../../context/retiroapvsinfin/retiroapvsinfinContext";

import {
  BankAccounts,
  CamposObligatorios,
  DatosEntidadFinancieraComponent,
  FileInputWrapper,
  InputFile,
  InputFileContainer,
  ModalTrigger,
  NewCardInformation,
} from "./styles";
import { useRef } from "react";
import BankReceiptModal from "../BankReceiptModal";
import { useState } from "react";
import { setTimeout } from "timers";
import clienteAxios from "../../../../config/axios";

const DatosEntidadFinanciera = () => {
  const { datosRetiroApvSinFin } = useContext(RetiroapvsinfinContext);
  const { r_bancos, r_cuentas, r_tipo_cuentas } = datosRetiroApvSinFin;
  const [bankReceiptModalIsOpen, setBankReceiptModalIsOpen] = useState(false);
  const [modalTriggerIsOpen, setModalTriggerIsOpen] = useState(true);

  const [listOfCreditCards, setListOfCreditCards] = useState(r_cuentas);

  const {
    watch,
    unregister,
    trigger,
    setValue,
    formState: { touchedFields },
  } = useFormContext();

  const bancosOptions = r_bancos.map((banco) => ({
    label: banco.r_descripcion,
    value: banco.r_codigo,
  }));

  useEffect(() => {
    // setTimeout(() => unSelectCreditCard(), 2000);
  }, []);

  const tipoDeCuentasOptions = r_tipo_cuentas
    .filter(
      (tipoDeCuenta) =>
        tipoDeCuenta.r_banco === watch("nombreBanco") &&
        tipoDeCuenta.r_codigo !== "TCR"
    )
    .map((banco) => ({
      label: banco.r_descripcion,
      value: banco.r_codigo,
    }));

  useEffect(() => {
    if (watch("banco") === "nueva") {
      return;
    }

    unregister("nombreBanco");
    unregister("tipoDeCuenta");
    unregister("numeroDeCuenta");
    unregister("evidenciaBancaria");
  }, [watch("banco") === "nueva"]);

  useEffect(() => {
    if (touchedFields?.["numeroDeCuenta"] && watch("tipoDeCuenta")) {
      trigger("numeroDeCuenta");
    }
  }, [watch("tipoDeCuenta")]);

  const longitud = r_tipo_cuentas.find(
    (tipoDeCuenta) =>
      tipoDeCuenta.r_banco === watch("nombreBanco") &&
      tipoDeCuenta.r_codigo === watch("tipoDeCuenta")
  )?.r_longitud;

  const placeholder =
    r_tipo_cuentas.find(
      (tipoDeCuenta) => tipoDeCuenta.r_banco === watch("nombreBanco")
    )?.r_cci === "1"
      ? "CCI:  999 - 999 - 999999999999 - 99"
      : "ej:   999 - 999999999";

  const isNotProperLength = (e, longitud) => {
    if (e === null) {
      return true;
    }

    if (e.length === parseInt(longitud)) {
      return true;
    }

    return `Su cuenta debe contrar con ${longitud} dígitos`;
  };

  const onClickModalTrigger = () => {
    setBankReceiptModalIsOpen(true);
    setModalTriggerIsOpen(false);
  };

  const onSuccededRemoval = ({ reference }) => {
    const newListOfCreditCards = listOfCreditCards.filter(({ r_codigo }) => {
      return r_codigo !== reference;
    });
    setListOfCreditCards(newListOfCreditCards);

    // Removed the selected credit card
    if (watch("banco") === reference) {
      setValue("banco", null);
      trigger("banco");
    }
  };

  const [aporteMensual, setAporteMensual] = useState(null);
  const accountReferenceNotAllowedToRemove =
    aporteMensual?.detalle_aporte?.ref_cuenta;

  const requestAporteMensual = async () => {
    try {
      const { data } = await clienteAxios.get("/fondo-libre/aporte-mensual");

      setAporteMensual({ ...data });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (listOfCreditCards.length) {
      requestAporteMensual();
    }
  }, [listOfCreditCards]);

  return (
    <DatosEntidadFinancieraComponent>
      <Input.Title>Selecciona tu cuenta bancaria</Input.Title>
      <BankAccounts numeroDeComponentes={listOfCreditCards.length || 0}>
        {listOfCreditCards.map((cuenta) => (
          <Input.CreditCard
            key={cuenta.r_codigo}
            cuenta={cuenta}
            name="banco"
            value={cuenta.r_codigo}
            onSuccededRemoval={onSuccededRemoval}
            isRemovable={accountReferenceNotAllowedToRemove !== cuenta.r_codigo}
          />
        ))}
        <Input.NewCreditCard name="banco" value="nueva" />
        <Input.ErrorMessage name="banco" />
      </BankAccounts>
      {watch("banco") === "nueva" && (
        <NewCardInformation>
          <div className="left-side">
            <Input.Wrapper>
              <Input.Title>Entidad financiera</Input.Title>
              <Input.Select
                placeholder={"Selecciona tu entidad financiera"}
                name="nombreBanco"
                options={bancosOptions}
              />
              <Input.ErrorMessage name="nombreBanco" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title>Tipo de cuenta</Input.Title>
              <Input.Select
                placeholder={"Eje.: Cuenta corriente"}
                options={tipoDeCuentasOptions}
                name="tipoDeCuenta"
                key={`tipo-de-cuenta-${watch("nombreBanco")}`}
              />
              <Input.ErrorMessage name="tipoDeCuenta" />
            </Input.Wrapper>
            <Input.Wrapper>
              <Input.Title>Nº de cuenta</Input.Title>
              <Input.Number
                size={"lg"}
                name="numeroDeCuenta"
                placeholder={placeholder}
                maxLength={parseInt(longitud)}
                validate={{
                  isNotProperLength: (e) => isNotProperLength(e, longitud),
                }}
                disabled={!watch("tipoDeCuenta")}
              />
              <Input.ErrorMessage name="numeroDeCuenta" />
            </Input.Wrapper>
          </div>
          <div className="right-side">
            <FileInputWrapper>
              <Input.Title
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                Adjuntar evidencia bancaria:
                <InfoButton
                  tooltip={false}
                  style={{ display: "inline-block" }}
                  onClick={onClickModalTrigger}
                />
              </Input.Title>
              <InputFileContainer>
                <Input.File
                  size="small"
                  name={"evidenciaBancaria"}
                  extensions={["png", "jpg", "jpeg", "pdf", "doc", "docx"]}
                  maxFileSize={1.2}
                />
                {modalTriggerIsOpen && (
                  <ModalTrigger onClick={onClickModalTrigger} />
                )}
              </InputFileContainer>

              <p className="restrictions">
                Ejemplo: Voucher o comprobante digital donde salga tu nombre,
                entidad bancaria y nº de cuenta.
              </p>
              <p className="restrictions">
                Formatos admitidos: png, jpg, jpeg, pdf, doc y docx. Tamaño
                máximo de archivo: 1.2mb.
              </p>
            </FileInputWrapper>
          </div>
        </NewCardInformation>
      )}
      {watch("banco") === "nueva" && (
        <Divider marginTop={"24px"} marginBottom={"24px"} />
      )}
      <Input.Wrapper>
        <Input.Checkbox name="terms" value={true}>
          He verificado que el número de cuenta que estoy registrando es
          correcto. AFP Habitat no será responsable en caso la cuenta esté
          errada.
        </Input.Checkbox>
        <Input.ErrorMessage name="terms" />
      </Input.Wrapper>
      <Divider marginTop={"24px"} marginBottom={"24px"} />
      <BankReceiptModal
        isOpen={bankReceiptModalIsOpen}
        setIsOpen={setBankReceiptModalIsOpen}
      />
      <CamposObligatorios>
        Los campos marcados con * son obligatorios.
      </CamposObligatorios>
    </DatosEntidadFinancieraComponent>
  );
};

export default DatosEntidadFinanciera;
