import React, { useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  InfoTooltipComponent,
  InfoButton,
  TooltipContent,
  TooltipArrow,
  TooltipContentWrapper,
} from "./styles";
import * as Tooltip from "@radix-ui/react-tooltip";
import PropTypes from "prop-types";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

const InfoTooltip = ({
  open,
  onOpenChange,
  delayDuration,
  side,
  align,
  children,
  defaultOpen,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleOnClick = () => {
    //DATALAYER INVERTIR
    window.dataLayer.push({
      event: "tooltip_click",
      product_name: "fondo libre habitat",
      step_name: "paso 2 - datos de inversion",
      description: "invertir en fondo libre",
    });
    setIsOpen(!isOpen);
  };
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <InfoTooltipComponent onMouseLeave={() => setIsOpen(false)} {...props}>
      <Tooltip.Provider>
        <Tooltip.Root
          open={isOpen}
          onOpenChange={onOpenChange}
          delayDuration={delayDuration}
          defaultOpen={defaultOpen}
        >
          <Tooltip.Trigger asChild onClick={handleOnClick} ref={ref}>
            <InfoButton className="IconButton">
              <AiOutlineInfoCircle />
            </InfoButton>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <TooltipContent sideOffset={4} side={side} align={align}>
              <TooltipContentWrapper style={{ maxWidth: "300px" }}>
                {children}
              </TooltipContentWrapper>
              <TooltipArrow />
            </TooltipContent>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </InfoTooltipComponent>
  );
};
export default InfoTooltip;

InfoTooltip.propTypes = {
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  delayDuration: PropTypes.number,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  children: "Tooltip",
  open: false,
  disableHoverableContent: false,
};
