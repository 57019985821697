import React, { useState, useEffect } from "react";

import { InterestCard } from "./components";
import { CardContainer } from "../../../../components";
import { LINK_OF_INTERES } from "../../../../utils/constants";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Grid } from "@mui/material";

export default function Interest(props) {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (size.width && size.width < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  return (
    <CardContainer
      title={"Te puede interesar"}
      seeMore={false}
      color={"#ffffff"}
      product={false}
      showHeader={true}
      shadow={false}
      border={true}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {LINK_OF_INTERES.map((item) => {
          return (
            <InterestCard
              key={item.id}
              title={item.title}
              info={item.text}
              img={item.icon}
              alt={item.alt}
              className={item.className}
              link={item.url}
              btnText={item.btnText}
              isMobile={isMobile}
            />
          );
        })}
      </Grid>
    </CardContainer>
  );
}
