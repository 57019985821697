import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const LabelContainer = styled(Typography)`
  && {
    ${(props) => props.theme.tipography.body3};
    border-radius: 14px;
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.spacing(2)};
    padding: 2px 8px;
    white-space: nowrap;
    margin-top: ${(props) => props.theme.spacing(2)};
    text-transform: unset;
  }
`;
const Label = (props) => {
  const { className, color, ground, shape, children, ...rest } = props;

  // const classes = useStyles();

  // const rootClassName = clsx(
  //   {
  //     [classes.root]: true,
  //     [classes.rounded]: true,
  //     //   [classes.rounded]: shape === "rounded",
  //   },
  //   className
  // );

  const style = {
    // backgroundColor: ground && lighten(0.42, ground),
    backgroundColor: ground,
    color,
  };

  return (
    <LabelContainer
      {...rest}
      // className={rootClassName}
      style={style}
      variant="overline"
    >
      {children}
    </LabelContainer>
  );
};

export default Label;
