import React from "react";
import { Button } from "../../../../../../../../../../components";
import "./index.css";

export default function DetalleInfo(props) {
  const { getBackOneStep, nextView } = props;
  return (
    <div className="detalle-info-container">
      <h1 className="mb-3">Retiro de hasta 95.5%</h1>
      <p>Debes tener en cuenta lo siguiente:</p>
      <ul>
        <li>
          Este trámite le permite retirar, hasta el 95.5% de su cuenta
          individual de capitalización (fondo acumulado).
        </li>
        <li>
          El 4.5% restante, será transferido a EsSalud para que tanto usted como
          sus beneficiarios tengan derecho a la atención médica.
        </li>
        <li>
          Es importante mencionar que, si elige esta opción, la atención en
          EsSalud será vitalicia para usted, pero si fallece sus derechos
          habientes ya no tendrán derecho a la atención médica.
        </li>
        <li>
          En el caso del retiro del 95.5% de su fondo, debe tener en cuenta que,
          al no tener la condición de pensionista, si usted falleciera sus
          beneficiarios no tendrán derecho a percibir una pensión de
          sobrevivencia.
        </li>
        <li>
          Al disponer del retiro de su fondo, ya no tendrá derecho a ningún
          beneficio con garantía estatal como, por ejemplo: la pensión mínima.
        </li>
      </ul>
      <p>
        Continuando con esta opción podrás validar el monto disponible de retiro
        y generar la solicitud de pago a la cuenta que validamos anteriormente
        contigo.
      </p>
      <br />
      <br />
      <div className="container-reja-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className="mb-4 btn-modal-secondary"
          onClick={() => getBackOneStep()}
        >
          Volver
        </Button>
        <Button className="mb-4 btn-modal-primary" onClick={() => nextView()}>
          Continuar
        </Button>
      </div>
    </div>
  );
}
