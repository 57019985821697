import React, { useContext, useEffect } from "react";
import "../../container/Layout/style.css";
import iconAsesor from "../../container/Layout/public/img/iconos/icono.hombre.desktop.widget.svg";
import iconAsesora from "../../container/Layout/public/img/iconos/icono.mujer.desktop.widget.svg";
import iconWhatsapp from "../../container/Layout/public/img/iconos/icon-whatsapp.png";
import AsesorContext from "../../context/asesor/asesorContext";
const AsesorView = () => {
  const asesorContext = useContext(AsesorContext);
  const { ejecutivo } = asesorContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <section className="m-section">
        <div className="m-container">
          <div className="m-contact">
            <div className="m-contact__asesor">
              <h2>
                Contacta a tu {ejecutivo.r_sexo == "M" ? "Asesor" : "Asesora"}
              </h2>
              <div className="m-asesor__item">
                <div className="m-asesor__image">
                  <img
                    src={ejecutivo.r_sexo == "M" ? iconAsesor : iconAsesora}
                    alt="icono-asesor"
                  />
                </div>
                <div className="m-asesor__content">
                  <p style={{ marginBottom: 0 }}>{ejecutivo.r_nombre}</p>
                </div>
              </div>
            </div>
            <div className="m-contact__personal">
              <p>{ejecutivo.r_sexo == "M" ? "Llámalo" : "Llámala"} al:</p>
              <div className="m-personal__item">
                <div className="m-personal__image">
                  <i className="fa fa-volume-control-phone"></i>
                </div>
                <div className="m-personal__content">
                  <a href={"tel:+51" + ejecutivo.r_celular}>
                    {ejecutivo.r_celularshow}
                  </a>
                  <i className="fa fa-copy"></i>
                </div>
              </div>
            </div>
            <div className="m-contact__personal">
              <p>Escríbele por Whatsapp al:</p>
              <div className="m-personal__item">
                <div className="m-personal__image">
                  <img src={iconWhatsapp} alt="icono-telefono" />
                </div>
                <div className="m-personal__content">
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=+51" +
                      ejecutivo.r_celular +
                      "&text="
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ejecutivo.r_celularshow}
                  </a>
                  <i className="fa fa-copy"></i>
                </div>
              </div>
            </div>
            <div className="m-contact__personal">
              <p>O por correo electr&oacute;nico:</p>
              <div className="m-personal__item">
                <div className="m-personal__image">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="m-personal__content">
                  <a href={"mailto:" + ejecutivo.r_correo}>
                    {ejecutivo.r_correo}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AsesorView;
