export const formatNumberMonthToText = (month) => {
  const months = {
    "01": "Ene",
    "02": "Feb",
    "03": "Mar",
    "04": "Abr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Ago",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dic",
  };

  return months[month];
};

export const formatPeriod = (ini_anio, ini_mes, fin_anio, fin_mes) => {
  return `${formatNumberMonthToText(ini_mes)}-${ini_anio.slice(
    2
  )} a ${formatNumberMonthToText(fin_mes)}-${fin_anio.slice(2)}`;
};
