import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M16.5 29.333c7.363 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.334-13.334C9.136 2.666 3.166 8.636 3.166 16s5.97 13.333 13.333 13.333zM16.5 21.333V16M16.5 10.666h.013"
      ></path>
    </svg>
  );
}

export default Icon;
