import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alcancia from "../../../assets/icons/Alcancia-rent.svg";
import Retire from "../../../assets/icons/retire.svg";
import Bullet from "../../../assets/icons/bullet.svg";
import { Motion, spring } from "react-motion";
import { montoFormat, montoFormatMiles } from "../../../helper/amountFormat";
import { extractUserNameFromCookie } from "../../../helper/cookie";

import CustomModal from "../../../components/custromModal/CustomModal";
const ItemContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s,
    background-color 1s;
  background: #fff;
  border-radius: 14px;
  transform-style: preserve-3d;
  position: relative;
  user-select: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.33);

  &.level0 {
    height: 180px;
    max-height: 480px;
  }
  &.level1 {
    height: 200px;
    max-height: 560px;
  }
  &.card-open {
    height: 475px;
  }
  &.card-open-vf {
    height: 435px;
  }
  & h5 {
    ${(props) => props.theme.tipography.body2}
  }
  & .body-card-newstyle p {
    ${(props) => props.theme.tipography.body2}
    margin-left:10px;
    // display: flex;
    align-items: center;
  }
  & .tooltip-rent .fa {
    ${(props) => props.theme.tipography.body_link_medium}
  }
  .box-image {
    background: rgb(26 102 145 / 62%);
    padding: 8px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-image img {
    width: 25px;
  }
`;

// ...

const RentabilidadButton = styled(Button)`
  && {
    text-align: center;
    border: none;
    background: #fff;
    border-radius: 14px;
    transform-style: preserve-3d;
    position: relative;
    user-select: none;
  }
`;
const cookieNameSPP = "SPP";
const cookieValuesSPP = "SPP";
const daysToExpireSPP = new Date(2147483647 * 1000).toUTCString();
const Items = ({
  habitatValue,
  acumulated_mount,
  detalleProductoSPP,
  cuotasValue,
  product,
}) => {
  const [text, setText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);
  const [show, setShow] = useState(false);
  const [alignment, setAlignment] = useState("AFP Habitat");
  useEffect(() => {
    //  document.cookie = cookieNameSPP +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }, []);
  useEffect(() => {
    if (extractUserNameFromCookie("SPP") === "" && alignment === "SPP") {
      document.cookie =
        cookieNameSPP + "=" + cookieValuesSPP + "; expires=" + daysToExpireSPP;
      setOpenModal(!openModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alignment]);

  const handleChange = (event, newAlignment) => {
    if (alignment !== newAlignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleText = () => {
    setText(!text);
    setShow(!show);
    setChange(false);
    setOpen(false);
  };

  const handleTextCuotas = () => {
    setText(!text);
    setShow(!show);
    setOpen(false);
  };
  const handleShowModal = () => {
    setOpenModal(!openModal);
  };
  const className =
    "item " +
    (!text ? "level0 " : "level1 ") +
    (show ? "change " : "") +
    (change && product === "OBL"
      ? "card-open"
      : change && (product === "VCF" || product === "VSF")
      ? "card-open-vf"
      : "");
  const aportAcumH =
    Number(habitatValue[0].aportes) === 0
      ? Number(habitatValue[0].aportes)
      : Number(habitatValue[0].aportes.replace(",", ""));
  const cargosH =
    Number(habitatValue[0].retiros) === 0
      ? Number(habitatValue[0].retiros)
      : Number(habitatValue[0].retiros.replace("-", ""));

  const newRent =
    Number(acumulated_mount) - 0 - Number(aportAcumH) + Math.abs(cargosH);
  const period =
    detalleProductoSPP.length > 0
      ? detalleProductoSPP[0].periodo.split("-")
      : "";

  const textButton = text ? "Ver soles" : "Ver Cuotas";
  return (
    <Motion style={{ y: spring(show ? 180 : 0) }}>
      {({ y }) => (
        <ItemContainer
          sx={{ maxWidth: 425 }}
          className={className}
          style={{ transform: `rotateY(${y}deg)` }}
        >
          <div className="flip-front">
            <div className="header-card-style">
              <h5>
                Tu fondo acumulado <br /> <span>Fondo {cuotasValue.fondo}</span>
              </h5>
              <button onClick={() => handleText()}>{textButton}</button>
            </div>
            <div className="body-card-newstyle">
              <h2>S/ {montoFormat(acumulated_mount)} </h2>
              {/* <p>
                Tu rentabilidad acumulada en Habitat es: <br />
                <span className="text1">
                  S/{" "}
                  {habitatValue?.hasOwnProperty("sumRenrendAcumtAcum_")
                    ? montoFormat(
                        habitatValue?.sumRenrendAcumtAcum_.replace(
                          ",",
                          ""
                        )
                      )
                    : montoFormat(
                        habitatValue?.rendAcum.replace(",", "")
                      )}
                </span>{" "}
                equivalente a:{" "}
                <span
                  className={
                    "text2" +
                    (Number(formPercent) > 0 ? " text2-green" : " text2-red")
                  }
                >
                  {montoFormat(formPercent)}%{" "}
                </span>{" "}
                {Number(formPercent) > 0 ? (
                  ""
                ) : (
                  <span
                    className="tooltip-custom tooltip-rent"
                    style={{ color: "#bf253d" }}
                  >
                    <i className="fa fa-info-circle" id="TooltipExample"></i>
                    <span className="tooltiptext">
                      Recuerda que la rentabilidad negativa a corto plazo es
                      temporal y que tu fondo persigue una meta de largo plazo:
                      tu jubilación.
                    </span>
                  </span>
                )}
              </p> */}

              <div className="content-rentabilidad">
                {/* { product === "OBL" ? null 
                : */}
                  <RentabilidadButton
                    variant="outlined"
                    onClick={() => {
                      setOpen(!open);
                      setChange(!change);
                    }}
                  >
                    Ver detalle de tu fondo
                  </RentabilidadButton>
                {/* } */}

                <div
                  className={
                    "rentabilidad-text " +
                    (open && product === "OBL"
                      ? "open"
                      : open && (product === "VCF" || product === "VSF")
                      ? "open-VF"
                      : "")
                  }
                >
                  {product === "OBL" ? (
                    <ToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                    >
                      <ToggleButton value="AFP Habitat">
                        AFP Habitat
                      </ToggleButton>
                      {detalleProductoSPP.length > 0 && (
                        <ToggleButton value="SPP">SPP</ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  ) : null}

                  <div className="list-rentabilidad">
                    {alignment === "AFP Habitat" ? (
                      <h3>Tu fondo acumulado se encuentra compuesto por</h3>
                    ) : (
                      <h3>
                        Tu fondo acumulado se encuentra compuesto por{" "}
                        <i
                          onClick={() => setOpenModal(!openModal)}
                          style={{ color: "#196691" }}
                          className="fa fa-info-circle"
                          aria-hidden="true"
                        ></i>
                      </h3>
                    )}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <div className="list-detail-rent">
                          <Grid container>
                            <Grid
                              item
                              xs={5}
                              sx={{
                                display: "flex",
                              }}
                              className=""
                            >
                              <div className="box-image">
                                <img src={Alcancia} alt="" />
                              </div>
                              <p>Aportes acumulados:</p>
                            </Grid>{" "}
                            <Grid
                              item
                              xs={7}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className=""
                            >
                              <span>
                                + S/{" "}
                                {alignment === "SPP"
                                  ? montoFormat(
                                      detalleProductoSPP[0].aportes_acum_spp
                                    )
                                  : montoFormat(aportAcumH)}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <div className="list-detail-rent">
                          <Grid container>
                            <Grid
                              item
                              xs={5}
                              sx={{
                                display: "flex",
                              }}
                              className=""
                            >
                              <div className="box-image">
                                <img src={Retire} alt="" />
                              </div>
                              <p>Retiros y cargos:</p>
                            </Grid>{" "}
                            <Grid
                              item
                              xs={7}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className=""
                            >
                              <span style={{ color: "rgb(138 140 143)" }}>
                                - S/{" "}
                                {alignment === "SPP"
                                  ? montoFormat(
                                      detalleProductoSPP[0].ret_cargos_spp.replace(
                                        /[-]/g,
                                        ""
                                      )
                                    )
                                  : montoFormat(cargosH)}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>{" "}
                      <Grid item xs={12}>
                        <div className="list-detail-rent">
                          <Grid
                            container
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{
                                display: "flex",
                              }}
                            >
                              <div className="box-image">
                                <img src={Bullet} alt="" />
                              </div>
                              <p>Rentabilidad acumulada:</p>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  color:
                                    alignment === "SPP"
                                      ? detalleProductoSPP[0].rent_spp.includes(
                                          "-"
                                        )
                                        ? "rgb(138 140 143)"
                                        : "+"
                                      : newRent.toString().includes("-")
                                      ? "rgb(138 140 143)"
                                      : "+",
                                }}
                              >
                                {alignment === "SPP"
                                  ? detalleProductoSPP[0].rent_spp.includes("-")
                                    ? "-"
                                    : "+"
                                  : newRent.toString().includes("-")
                                  ? "-"
                                  : "+"}{" "}
                                S/{" "}
                                {alignment === "SPP"
                                  ? montoFormat(
                                      detalleProductoSPP[0].rent_spp.replace(
                                        /[-]/g,
                                        ""
                                      )
                                    )
                                  : montoFormat(
                                      newRent.toString().replace(/[-]/g, "")
                                    )}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    {/*               
                    {product === "OBL" ? (
                      <div className="message-rent">
                        <p>
                          Tu fecha de afiliación a AFP Habitat fue el{" "}
                          {cuotasValue.fecha_incorporacion}.
                        </p>
                      </div>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-back">
            <div className="header-card-style">
              <h5>Tu fondo acumulado en cuotas </h5>
              <button onClick={() => handleTextCuotas()}>{textButton}</button>
            </div>
            <div className="body-card-newstyle">
              <h2>{montoFormatMiles(cuotasValue?.monCuota)}</h2>
              <p>
                Total de cuotas acumuladas al día de hoy con un valor de{" "}
                <span className="text2"> {cuotasValue.valCuota}</span>{" "}
                equivalente a:{" "}
                <span className="text2">
                  S/ {montoFormat(acumulated_mount)}
                </span>{" "}
              </p>
            </div>
          </div>
          <CustomModal
            showModal={openModal}
            className="beauty-modal"
            toggle={() => {
              handleShowModal(!openModal);
            }}
            fnCloseIconBtn={false}
            title="Recuerda"
            description={
              period !== "" && (
                <>
                  <p className="mb-0">
                    Considerar que la información del SPP no son datos al día de
                    hoy, la información es con corte a{" "}
                    {period[0].toLowerCase() + "/" + period[1]}.
                  </p>
                  <p className="mb-0">* SPP: Sistema Privado de Pensiones</p>
                </>
              )
            }
            hasBtnPrimary={true}
            fnBtnPrimary={() => setOpenModal(!openModal)}
            textBtnPrimary="Entendido"
            hasBtnSecondary={false}
          />
        </ItemContainer>
      )}
    </Motion>
  );
};

export default Items;
