import {
  MIS_DATOS_GET_EXITOSO,
  MIS_DATOS_GET_ERROR,
  MIS_DATOS_GET_LOADIN,
  MIS_DATOS_CLEANER,
  MIS_DATOS_CLEANER_PARCIAL,
  DATOS_PERSONALES_POST_EXITOSO,
  DATOS_PERSONALES_POST_ERROR,
  DATOS_PERSONALES_POST_LOADIN,
  DIRECCION_POST_EXITOSO,
  DIRECCION_POST_ERROR,
  DIRECCION_POST_LOADIN,
  CLAVE_WEB_POST_EXITOSO,
  CLAVE_WEB_POST_ERROR,
  CLAVE_WEB_POST_LOADIN,
  CLAVE_WEB_CLEANER,
  CLAVE_WEB_CLEANER_PARCIAL,
  SEIS_MESES_LOADIN,
  SEIS_MESES_GET_EXITOSO,
  SEIS_MESES_GET_ERROR,
  TARJETAS_GET_EXITOSO,
  TARJETAS_GET_ERROR,
  TARJETAS_GET_LOADIN,
  TARJETAS_GET_CLEANER,
  TARJETAS_GET_CLEANER_PARCIAL,
  ELIMINAR_TARJETA_POST_EXITOSO,
  ELIMINAR_TARJETA_POST_ERROR,
  ELIMINAR_TARJETA_LOADIN,
  AGREGAR_TARJETA_POST_EXITOSO,
  AGREGAR_TARJETA_POST_ERROR,
  AGREGAR_TARJETA_LOADIN,
  ACTUALIZAR_DATOS_PROCESOS,
} from "../../types/misDatos";

export default (state, action) => {
  switch (action.type) {
    case MIS_DATOS_GET_EXITOSO:
      return {
        ...state,
        misDatosGet: action.payload,
        mensajeAlertaDatosGet: null,
        loadingPage: false,
        resultadoDatosPost: null,
        mensajeAlertaDatosPost: null,
        loadingBtnDatosPost: false,
        resultadoDireccionPost: null,
        mensajeAlertaDireccionPost: null,
        loadingBtnDireccionPost: false,
      };
    case MIS_DATOS_GET_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case MIS_DATOS_GET_ERROR:
      return {
        ...state,
        mensajeAlertaDatosGet: action.payload,
      };
    case MIS_DATOS_CLEANER:
      return {
        ...state,
        misDatosGet: null,
        mensajeAlertaDatosGet: null,
        loadingPage: false,
        /**Actualizar Datos*/
        resultadoDatosPost: null,
        mensajeAlertaDatosPost: null,
        loadingBtnDatosPost: false,
        /**Actualizar Dirección*/
        resultadoDireccionPost: null,
        mensajeAlertaDireccionPost: null,
        loadingBtnDireccionPost: false,
      };
    case MIS_DATOS_CLEANER_PARCIAL:
      return {
        ...state,
        misDatosGet: null,
        loadingPage: false,
        /**Actualizar Datos*/
        resultadoDatosPost: null,
        mensajeAlertaDatosPost: null,
        loadingBtnDatosPost: false,
        /**Actualizar Dirección*/
        resultadoDireccionPost: null,
        mensajeAlertaDireccionPost: null,
        loadingBtnDireccionPost: false,
      };
    case DATOS_PERSONALES_POST_EXITOSO:
      return {
        ...state,
        resultadoDatosPost: action.payload,
        mensajeAlertaDatosPost: null,
        loadingBtnDatosPost: false,
      };
    case DATOS_PERSONALES_POST_LOADIN:
      return {
        ...state,
        loadingBtnDatosPost: true,
        mensajeAlertaDatosPost: null,
      };
    case DATOS_PERSONALES_POST_ERROR:
      return {
        ...state,
        mensajeAlertaDatosPost: action.payload,
        loadingBtnDatosPost: false,
      };
    case DIRECCION_POST_EXITOSO:
      return {
        ...state,
        resultadoDireccionPost: action.payload,
        mensajeAlertaDireccionPost: null,
        loadingBtnDireccionPost: false,
      };
    case DIRECCION_POST_LOADIN:
      return {
        ...state,
        loadingBtnDireccionPost: true,
        mensajeAlertaDireccionPost: null,
      };
    case DIRECCION_POST_ERROR:
      return {
        ...state,
        mensajeAlertaDireccionPost: action.payload,
        loadingBtnDireccionPost: false,
      };
    case CLAVE_WEB_POST_EXITOSO:
      return {
        ...state,
        resultadoClavePost: action.payload,
        mensajeAlertaClavePost: null,
        loadingBtnClavePost: false,
      };
    case CLAVE_WEB_POST_LOADIN:
      return {
        ...state,
        loadingBtnClavePost: true,
        resultadoClavePost: null,
        mensajeAlertaClavePost: null,
      };
    case CLAVE_WEB_POST_ERROR:
      return {
        ...state,
        mensajeAlertaClavePost: action.payload,
        loadingBtnClavePost: false,
      };
    case CLAVE_WEB_CLEANER:
      return {
        ...state,
        resultadoClavePost: null,
        mensajeAlertaClavePost: null,
        loadingBtnClavePost: false,
      };
    case CLAVE_WEB_CLEANER_PARCIAL:
      return {
        ...state,
        resultadoClavePost: null,
        loadingBtnClavePost: false,
      };

    case SEIS_MESES_GET_EXITOSO:
      return {
        ...state,
        resultado6mesesGet: action.payload,
        mensajeAlerta6mesesGet: null,
        loadingBtn6mesesGet: false,
      };
    case SEIS_MESES_LOADIN:
      return {
        ...state,
        loadingBtn6mesesGet: true,
        resultado6mesesGet: null,
        mensajeAlerta6mesesGet: null,
      };
    case SEIS_MESES_GET_ERROR:
      return {
        ...state,
        mensajeAlerta6mesesGet: action.payload,
        loadingBtn6mesesGet: false,
      };
    case TARJETAS_GET_EXITOSO:
      return {
        ...state,
        tarjetasGet: action.payload,
        mensajeAlertaTarjetasGet: null,
        loadingTarjetasPage: false,
        resultadoEliminarTarjeta: null,
        mensajeAlertaEliminarTarjetaPost: null,
        loadingBtnEliminarTarjetaPost: false,
        /**Agregar Tarjeta */
        resultadoAgregarTarjeta: null,
        mensajeAlertaAgregarTarjetaPost: null,
        loadingBtnAgregarTarjetaPost: false,
      };
    case TARJETAS_GET_LOADIN:
      return {
        ...state,
        loadingTarjetasPage: true,
      };
    case TARJETAS_GET_ERROR:
      return {
        ...state,
        mensajeAlertaTarjetasGet: action.payload,
      };
    case TARJETAS_GET_CLEANER:
      return {
        ...state,
        tarjetasGet: null,
        mensajeAlertaTarjetasGet: null,
        loadingTarjetasPage: false,
        resultadoEliminarTarjeta: null,
        mensajeAlertaEliminarTarjetaPost: null,
        loadingBtnEliminarTarjetaPost: false,
        resultadoAgregarTarjeta: null,
        mensajeAlertaAgregarTarjetaPost: null,
        loadingBtnAgregarTarjetaPost: false,
      };
    case TARJETAS_GET_CLEANER_PARCIAL:
      return {
        ...state,
        tarjetasGet: null,
        loadingTarjetasPage: false,
        mensajeAlertaEliminarTarjetaPost: null,
      };

    case ELIMINAR_TARJETA_POST_EXITOSO:
      return {
        ...state,
        resultadoEliminarTarjeta: action.payload,
        mensajeAlertaEliminarTarjetaPost: null,
        loadingBtnEliminarTarjetaPost: false,
      };
    case ELIMINAR_TARJETA_LOADIN:
      return {
        ...state,
        loadingBtnEliminarTarjetaPost: true,
        mensajeAlertaEliminarTarjetaPost: null,
      };
    case ELIMINAR_TARJETA_POST_ERROR:
      return {
        ...state,
        mensajeAlertaEliminarTarjetaPost: action.payload,
        loadingBtnEliminarTarjetaPost: false,
      };

    case AGREGAR_TARJETA_POST_EXITOSO:
      return {
        ...state,
        resultadoAgregarTarjeta: action.payload,
        mensajeAlertaAgregarTarjetaPost: null,
        loadingBtnAgregarTarjetaPost: false,
      };
    case AGREGAR_TARJETA_LOADIN:
      return {
        ...state,
        loadingBtnAgregarTarjetaPost: true,
        mensajeAlertaAgregarTarjetaPost: null,
      };
    case AGREGAR_TARJETA_POST_ERROR:
      return {
        ...state,
        mensajeAlertaAgregarTarjetaPost: action.payload,
        loadingBtnAgregarTarjetaPost: false,
      };
    case ACTUALIZAR_DATOS_PROCESOS:
      return {
        ...state,
        isDataUpdated: true,
      };
    default:
      break;
  }
};
