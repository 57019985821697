import React, { useEffect } from "react";
import ProductCard from "./components/ProductCard";
import Grid from "@mui/material/Grid";
import "./index.css";
import { MessageSnack } from "../../../../components";

export default function Funds(props) {
  const { fondos, gender, open, setOpen, validateEtareo } = props;
  const filterProduct = fondos.filter((item) => item.r_aporte !== "VSF");
  const filterVSF = filterProduct.filter((item) => Number(item.r_saldo) !== 0);
  const formatDate =
    validateEtareo.code === "No aplica"
      ? "2/4/2022"
      : validateEtareo.fec_limite;
  return (
    <div className="dashboard-data--card-funds">
      <div className="card-funds">
        <h3 className="section-title mb-4">
          Bienvenid{gender === "F" ? "a" : "o"},{" "}
          <span className="text-name">
            {props.name.charAt(0) +
              props.name.slice(1, props.name.length).toLowerCase()}
          </span>
        </h3>
        <Grid
          container
          sx={{
            pb: 0,
            justifyContent: "center",
          }}
        >
          {open && filterVSF.length > 0 && (
            <MessageSnack
              alt="ICON_ADV"
              bgAlert="#FFFAF0"
              shadow={true}
              title="Cambio de fondo etario en curso"
              description={`Podrás decidir un fondo para tus productos de Jubilación hasta el ${formatDate}, de lo contrario pasarán al Fondo ${
                validateEtareo.tipo_etario === 60 ? 1 : 0
              }.`}
              action={true}
              textAction="Saber más"
              color={"#EFBD0C"}
              onClickAction={() => setOpen(true)}
            />
          )}

          <ProductCard datosCambioFondo={fondos} />
        </Grid>
      </div>
    </div>
  );
}
