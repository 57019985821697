import React from "react";

export default function ChartIcon({ size = 18, color, lineColor = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      width={size}
      height={size}
      fill="none"
      stroke={color}
    >
      <path
        d="M4.67578 19.2229V19.2346H4.6875H20.8125C20.9092 19.2346 20.9883 19.3137 20.9883 19.4104V20.7229C20.9883 20.8196 20.9092 20.8987 20.8125 20.8987H3.1875C3.09085 20.8987 3.01172 20.8196 3.01172 20.7229V4.5979C3.01172 4.50125 3.09085 4.42212 3.1875 4.42212H4.5C4.59665 4.42212 4.67578 4.50125 4.67578 4.5979V19.2229ZM8.0625 17.3362H6.75C6.65335 17.3362 6.57422 17.2571 6.57422 17.1604V13.7854C6.57422 13.6887 6.65335 13.6096 6.75 13.6096H8.0625C8.15915 13.6096 8.23828 13.6887 8.23828 13.7854V17.1604C8.23828 17.2571 8.15915 17.3362 8.0625 17.3362ZM11.625 17.3362H10.3125C10.2158 17.3362 10.1367 17.2571 10.1367 17.1604V9.6604C10.1367 9.56375 10.2158 9.48462 10.3125 9.48462H11.625C11.7217 9.48462 11.8008 9.56375 11.8008 9.6604V17.1604C11.8008 17.2571 11.7217 17.3362 11.625 17.3362ZM15.1875 17.3362H13.875C13.7783 17.3362 13.6992 17.2571 13.6992 17.1604V11.4885C13.6992 11.3919 13.7783 11.3127 13.875 11.3127H15.1875C15.2842 11.3127 15.3633 11.3919 15.3633 11.4885V17.1604C15.3633 17.2571 15.2842 17.3362 15.1875 17.3362ZM18.75 17.3362H17.4375C17.3408 17.3362 17.2617 17.2571 17.2617 17.1604V7.7854C17.2617 7.68875 17.3408 7.60962 17.4375 7.60962H18.75C18.8467 7.60962 18.9258 7.68875 18.9258 7.7854V17.1604C18.9258 17.2571 18.8467 17.3362 18.75 17.3362Z"
        fill={lineColor}
        stroke={lineColor}
        strokeWidth="0.0234375"
      />
    </svg>
  );
}
