import React, { useEffect, useState } from "react";
import { PasoConfirmacionSolicitudComponent } from "./styles";
import * as SummarySection from "../../../../components/SummarySection";
import Button from "../../../../components/Buttons";
import { montoFormat } from "../../../../helper/amountFormat";
import maskBankAccount from "../../../../utils/maskBankAccount";
import formatFondo from "../../../../utils/formatFondo";
import moment from "moment-business-days";
import ModalPlazosNormativos from "../ModalPlazosNormativos";
import getDebitFrecuencyDate from "../../utils/getDebitFrecuencyDate";

const PasoConfirmacionSolicitud = ({ data }) => {
  var {
    v_num_sol,
    v_fecha_solicitud,
    v_monto,
    v_fondo,
    v_frecuencia,
    v_fecha_cargo,
    v_fecha_primer_debito_largo,
    v_fecha_primer_debito,
    v_cargo_doble,
    v_banco,
    v_num_cuenta,
  } = data.debito;

  const [modalPlazosNormativosIsOpen, setModalPlazosNormativosIsOpen] =
    useState();

  const closeModalPlazosNormativos = () =>
    setModalPlazosNormativosIsOpen(false);

  const openModalPlazosNormativos = () => setModalPlazosNormativosIsOpen(true);

  // moment
  moment.updateLocale("pe", {
    workingWeekdays: [1, 2, 3, 4, 5],
  });

  moment.locale("es-PE");

  const steps = [
    {
      status: "done",
      label: "Solicitud registrada",
      description: `Hemos recibido tu solicitud el ${moment().format(
        "L"
      )}, validaremos ciertos requisitos y te informaremos cualquier novedad.`,
      show: true,
    },
    {
      status: "waiting",
      label: "Resultados de solicitud",
      description:
        v_frecuencia === "Mensual"
          ? `Tu primer débito será procesado en ${v_fecha_primer_debito}. Podrás revisar el valor cuota ingresando a Mis Fondos > Últimos movimientos`
          : `Tu aporte será procesado el ${moment()
              .businessAdd(5)
              .format(
                "L"
              )}. Puedes revisar el valor cuota ingresando a Mis Fondos > Últimos movimientos`,
      show: true,
    },
  ];

  const stepsFiltered = steps.filter((e) => e.show);

  const getFondo = (fondo) => {
    switch (fondo) {
      case "1":
        return "fondo 1 - conservador";
      case "2":
        return "fondo 2 - moderado";
      case "3":
        return "fondo 3 - arriesgado";
      default:
        return " ";
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("client"));
    const cuspp = user.r_cuspp;
    window.dataLayer.push({
      event: "virtualPage",
      pagePath:
        "/mi-habitat-digital/afiliado/apv/fondo-libre-habitat/completado",
      pageName: "Fondo Libre - Solicitud Completada con éxito",
      aporte: v_monto,
      cuspp,
    });

    //DATALAYER INVERTIR
    // console.log(
    //   "solicitud confirmada",
    //   getFondo(v_fondo),
    //   v_num_sol,
    //   v_cargo_doble
    // );
    window.dataLayer.push({
      event: "fondo_libre_exitoso",
      product_name: "fondo libre habitat",
      step_name: "paso 3 - confirmacion de solicitud",
      fund_type: getFondo(v_fondo),
      debit_frequency: v_frecuencia === "Único" ? "única" : "mensual",
      solicitud: v_num_sol ?? "",
      state: v_cargo_doble ? "activo" : "inactivo",
    });
  }, []);

  return (
    <PasoConfirmacionSolicitudComponent>
      <SummarySection.Section>
        <SummarySection.Header
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          Solicitud registrada con éxito
        </SummarySection.Header>

        <SummarySection.Body>
          <SummarySection.Table>
            <SummarySection.TableTitle>
              Datos de la solicitud
            </SummarySection.TableTitle>
            <SummarySection.TableSection>
              <SummarySection.TableSectionTitle>
                Datos del trámite
              </SummarySection.TableSectionTitle>
              {[
                { label: "N.° de solicitud", value: v_num_sol, show: true },
                {
                  label: "Fecha de solicitud",
                  value: v_fecha_solicitud,
                  show: true,
                },
                {
                  label: "Monto a invertir",
                  value: `S/ ${montoFormat(v_monto)}`,
                  show: true,
                },
                {
                  label: "Tipo de fondo",
                  value: formatFondo(v_fondo),
                  show: true,
                },
                {
                  label: "Frecuencia de débito",
                  value: v_frecuencia,
                  show: true,
                },
                {
                  label: "Fecha de débito mensual",
                  value: v_fecha_cargo,
                  show: v_frecuencia !== "Único",
                },
                {
                  label: "Primer débito a ejecutar",
                  value: v_fecha_primer_debito_largo,
                  show: v_frecuencia !== "Único",
                },
                {
                  label: "Cargos dobles en julio y diciembre",
                  value: v_cargo_doble ? "Activo" : "Inactivo",
                  show: v_frecuencia !== "Único",
                },
              ].map(
                ({ label, value, show }, i) =>
                  show && (
                    <SummarySection.TableItem
                      key={label}
                      label={label}
                      value={value}
                    />
                  )
              )}
            </SummarySection.TableSection>
            <SummarySection.TableSection>
              <SummarySection.TableSectionTitle>
                Datos de la entidad financiera
              </SummarySection.TableSectionTitle>
              {[
                { label: "Entidad financiera", value: v_banco },
                {
                  label: "N.° de cuenta",
                  value: maskBankAccount(v_num_cuenta),
                },
              ].map(({ label, value }, i) => (
                <SummarySection.TableItem
                  key={label}
                  label={label}
                  value={value}
                />
              ))}
            </SummarySection.TableSection>
          </SummarySection.Table>
          <SummarySection.Progress>
            <SummarySection.ProgressHeader>
              Avance de solicitud
            </SummarySection.ProgressHeader>
            <SummarySection.ProgressBody
              steps={stepsFiltered}
            ></SummarySection.ProgressBody>
            <SummarySection.ProgressFooter>
              <Button variant={"link"} onClick={openModalPlazosNormativos}>
                Ver plazos normativos
              </Button>
            </SummarySection.ProgressFooter>
          </SummarySection.Progress>
        </SummarySection.Body>
      </SummarySection.Section>
      <ModalPlazosNormativos
        open={modalPlazosNormativosIsOpen}
        onClose={closeModalPlazosNormativos}
        frecuencia={v_frecuencia}
      />
    </PasoConfirmacionSolicitudComponent>
  );
};

export default PasoConfirmacionSolicitud;
