import {
  CAMBIO_FONDO_GET_EXITOSO,
  CAMBIO_FONDO_GET_ERROR,
  CAMBIO_FONDO_GET_LOADIN,
  CAMBIO_FONDO_CLEANER,
  CAMBIO_FONDO_CLEANER_PARCIAL,
  CAMBIO_FONDO_POST_EXITOSO,
  CAMBIO_FONDO_POST_ERROR,
  CAMBIO_FONDO_POST_LOADIN,
  ETARIO_ERROR,
  ETARIO_GET,
  ETARIO_RESETEAR,
  ETARIO_LOADING,
  ETARIO_CONNECT,
  GET_DATA_INVESTMENTS
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case CAMBIO_FONDO_GET_EXITOSO:
      return {
        ...state,
        datosCambioFondo: action.payload,
        loadingPage: false,
      };
    case CAMBIO_FONDO_GET_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case CAMBIO_FONDO_GET_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };
    case CAMBIO_FONDO_CLEANER:
      return {
        ...state,
        datosCambioFondo: null,
        mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoCambio: null,
      };
    case CAMBIO_FONDO_CLEANER_PARCIAL:
      return {
        ...state,
        datosCambioFondo: null,
        // mensajeAlerta: null,
        loadingPage: false,
        loadingBtn: false,
        datosResultadoCambio: null,
      };
    case CAMBIO_FONDO_POST_EXITOSO:
      return {
        ...state,
        datosResultadoCambio: action.payload,
        loadingBtn: false,
      };
    case CAMBIO_FONDO_POST_LOADIN:
      return {
        ...state,
        loadingBtn: true,
      };
    case CAMBIO_FONDO_POST_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
      };

    case ETARIO_GET:
      return {
        ...state,
        validateEtareo: action.payload,
        loadingEtareo: false,
      };
    case ETARIO_RESETEAR:
      return {
        ...state,
        validateEtareo: null,
        loadingEtareo: false,
        errorEtareo: null,
      };
    case ETARIO_CONNECT:
      return {
        ...state,
        conectEtareo: action.payload,
      };
    case GET_DATA_INVESTMENTS:
      return {
        ...state,
        dataInvestment: action.payload
      }
    default:
      break;
  }
};
