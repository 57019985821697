import React from "react";
import Modal from "../../../../../../../components/BasicModal";
import Button from "../../../../../../../components/Buttons";
import Divider from "../../../../../../../components/Divider";
import XCircleIcon from "../../../../../../../assets/IconBucket/XCircleIcon";
import { BsXCircle } from "react-icons/bs";
import { IconWrapper, Title } from "./styles";

const ModalDeError = ({ modalIsOpen, setModalIsOpen }) => {
  const closeModal = () => setModalIsOpen(false);
  return (
    <Modal open={modalIsOpen} onClose={closeModal}>
      <Title>
        <IconWrapper as={"div"}>
          <XCircleIcon />
        </IconWrapper>{" "}
        <span>No pudimos registrar tu solicitud</span>
      </Title>
      <Modal.Subtitle>
        Ocurrió un error inesperado, inténtalo de nuevo. Si el problema persiste
        comunicate con nosotros.
      </Modal.Subtitle>
      <Divider />
      <Button fullWidth style={{ margin: "auto" }} onClick={closeModal}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeError;
