import React from "react";

function Icon({ color = "#0043CE" }) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1088_71673"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="33"
      >
        <rect y="0.800781" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1088_71673)">
        <path
          d="M18.232 18.5656C17.1206 18.5656 16.176 18.1768 15.3984 17.3992C14.6208 16.6216 14.232 15.6771 14.232 14.5656C14.232 13.4547 14.6208 12.5104 15.3984 11.7328C16.176 10.9547 17.1206 10.5656 18.232 10.5656C19.343 10.5656 20.2872 10.9547 21.0648 11.7328C21.843 12.5104 22.232 13.4547 22.232 14.5656C22.232 15.6771 21.843 16.6216 21.0648 17.3992C20.2872 18.1768 19.343 18.5656 18.232 18.5656ZM7.99843 23.0328C7.37603 23.0328 6.8483 22.816 6.41523 22.3824C5.98163 21.9493 5.76483 21.4216 5.76483 20.7992V8.36561C5.76483 7.74375 5.98163 7.21601 6.41523 6.78241C6.8483 6.34935 7.37603 6.13281 7.99843 6.13281H28.432C29.0539 6.13281 29.5816 6.34935 30.0152 6.78241C30.4483 7.21601 30.6648 7.74375 30.6648 8.36561V20.7992C30.6648 21.4216 30.4483 21.9493 30.0152 22.3824C29.5816 22.816 29.0539 23.0328 28.432 23.0328H7.99843ZM11.0984 20.7992H25.332C25.332 19.9325 25.632 19.1992 26.232 18.5992C26.832 17.9992 27.5654 17.6992 28.432 17.6992V11.4656C27.5654 11.4656 26.832 11.1656 26.232 10.5656C25.632 9.96561 25.332 9.23228 25.332 8.36561H11.0984C11.0984 9.23228 10.7984 9.96561 10.1984 10.5656C9.59843 11.1656 8.8651 11.4656 7.99843 11.4656V17.6992C8.8651 17.6992 9.59843 17.9992 10.1984 18.5992C10.7984 19.1992 11.0984 19.9325 11.0984 20.7992ZM26.6648 27.4656H3.56483C2.94296 27.4656 2.41523 27.2491 1.98163 26.816C1.54856 26.3824 1.33203 25.8547 1.33203 25.2328V10.1328H3.56483V25.2328H26.6648V27.4656Z"
          fill="#2F6D95"
        />
      </g>
    </svg>
  );
}

export default Icon;
