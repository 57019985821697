import React, { useState, useEffect, Fragment } from "react";
import "./index.css";

const Contador = (props) => {
  //Extraemos los valores del context

  let localTimer = props.iniciaContador? 2: 0;
  const [minutes, setMinutos] = useState(localTimer);
  const [seconds, setSegundos] = useState(0);
  useEffect(() => {
    let myInterval = null;

    myInterval = setInterval(() => {
      if (seconds > 0) {
        setSegundos((seconds) => seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          props.activarEnvio(true); // activa el boton, detiene el contador 
          clearInterval(myInterval);
        } else {
          setMinutos((minutes) => minutes - 1);
          setSegundos((seconds) => 59);
        }
      }
    }, 1000);


    return () => clearInterval(myInterval);
    // eslint-disable-next-line
  }, [seconds, minutes]);



  return (
    minutes !== null &&
    minutes >= 0 && (
      <strong
        className="m-0 timer-text"
        style={{ paddingTop: "6px", color: "#bf0d3e" }}
      >
        
        {minutes === 0 && seconds === 0 ? (
          <span></span>
        ) : (
          <Fragment>
              <span>en <i className="fa fa-clock-o"></i> {" "}</span>
            <span>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </Fragment>
        )}
      </strong>
    )
  );
};

export default Contador;
