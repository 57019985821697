import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const PasoResumenComponent = styled.div``;

export const HeaderInformation = styled.p`
  ${(p) => p.theme.tipography.bodySmallRegular}
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  row-gap: 8px;
  ${(p) => css`
    @media screen and ${device.tablet} {
      grid-template-columns: 1fr 1fr;
      max-width: calc(480px + 16px);
      column-gap: 16px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;
