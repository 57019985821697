import styled, { css } from "styled-components";
import RadioCard from "../RadioCard";

export const NewCreditCardInputComponent = styled(RadioCard)`
  padding: 24px;
  border-style: dashed;
  min-height: 148px;
  justify-items: center;
  align-items: center;
  box-shadow: none;

  .radio {
    display: none;
  }

  .body {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    justify-items: center;
    gap: 8px;

    ${(p) =>
      p.isChecked &&
      css`
        color: ${(p) => p.theme.palette.primary.main};
      `}

    .icon {
      display: grid;
      justify-content: center;
      align-content: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  ${(p) =>
    p.isChecked &&
    css`
      border-style: solid;
    `}
`;
