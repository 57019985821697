import React from 'react';
import Sheet from 'react-modal-sheet';
import { useModal } from '@react-aria/overlays';


export const ContentModal = ({ sheetState, content, heigth = { height: 'auto' }, hasHeader = true }) => {
    const containerRef = React.useRef(null);
    useModal();

    return (
        <>
            <Sheet.Container
                /* {...overlay.overlayProps}
                {...dialog.dialogProps} */
                ref={containerRef}
                style={heigth}
            >
                {hasHeader && <Sheet.Header
                    style={{
                        height: '20px',
                    }}
                />}
                <Sheet.Content
                >{content}</Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </>
    );
};