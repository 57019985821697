import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import Contador from "./Contador";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Button } from "../../../../../../../components";
import CodeContext from "../../../../../../../context/code/codeContext";
import Loading from "./Loading";
import MensajeAlerta from "./MensajeAlerta";

const ModalToken = ({ cerrarModal, guardarCode, className }) => {
  const [optionSelect, setOptionSelect] = useState("");
  const [lugarDeEnvio, setLugarDeEnvio] = useState("");
  const [token, setToken] = useState("");
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [mensajeCodigoValidado, setMensajeCodigoValidado] = useState(null);

  const [reenviarCodigo, setReenviarCodigo] = useState({
    activo: false, // true puedo solicitar clave // false no puedo
    cargando: false, // true solicitando clave // false dejo de solicitar
    solicitar: false, // true hizo una petición de carga // false termino
  });

  const codeContext = useContext(CodeContext);
  const {
    dataModalCode,
    mensajeGetAlerta,
    dataPostCode,
    mensajePostAlerta,
    obtenerDatosCode,
    limpiarVariablesCodePost,
    solicitarCode,
  } = codeContext;

  useEffect(() => {
    if (mensajeGetAlerta) {
      console.log("HUBO UN ERROR AL SOLICITAR TU CODIGO");
    } else {
      obtenerDatosCode();
    }
    // eslint-disable-next-line
  }, [mensajeGetAlerta]);

  useEffect(() => {
    if (dataModalCode) {
      if (dataModalCode.validate === 2) {
        actualizarDataSeleccionada(
          "celular: ",
          "SMS",
          dataModalCode.info.celular
        );
      } else if (dataModalCode.validate === 1) {
        actualizarDataSeleccionada(
          "correo: ",
          "EMAIL",
          dataModalCode.info.email
        );
      }
    }
  }, [dataModalCode]);

  const actualizarDataSeleccionada = (tipo, code, text) => {
    setOptionSelect(tipo + text);
    setLugarDeEnvio(code);
  };

  const elegir = (e) => {
    let valor = e.target.value;
    let lugarDeEnvio = e.target.value.includes("correo") ? "EMAIL" : "SMS";
    setOptionSelect(valor);
    setLoadingEnvio(false);
    setLugarDeEnvio(lugarDeEnvio);
    limpiarVariablesCodePost();
  };

  const enviarClave = (option) => (e) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.set("channel", lugarDeEnvio);
    if (option === "enviar") {
      setLoadingEnvio(true);
      solicitarCode(bodyFormData)
        .then(() => {
          setLoadingEnvio(false);
          setReenviarCodigo({ ...reenviarCodigo, solicitar: true });
        })
        .catch((error) => console.log(error));
    } else if (option === "re-enviar") {
      setReenviarCodigo({
        activo: false,
        cargando: true,
        solicitar: false,
      });
      solicitarCode(bodyFormData)
        .then(() => {
          setReenviarCodigo({
            ...reenviarCodigo,
            activo: false,
            cargando: false,
            solicitar: true,
          });
        })
        .catch((error) => console.log(error));
    }
  };

  const activarEnvio = (option) => {
    setReenviarCodigo({ ...reenviarCodigo, activo: option, solicitar: false });
  };

  const handleToken = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setToken(valor);
    setMensajeCodigoValidado("");
  };

  const cerrarModalLocal = () => {
    limpiarVariablesCodePost();
    cerrarModal();
  };

  const validarData = () => {
    let mensajeCodigo = "";
    if (token.length !== 0) {
      if (token.length < 6) {
        mensajeCodigo = "El token digital es de 6 dígitos";
      }
    } else {
      mensajeCodigo = "El token digital es requerido.";
    }

    if (mensajeCodigo.length > 0) {
      setMensajeCodigoValidado(mensajeCodigo);
      return false;
    } else {
      return true;
    }
  };

  const procesar = () => {
    if (validarData()) {
      setLoadingEnvio(true);
      guardarCode(token);
      cerrarModal();
    }
  };
  return (
    <div className="wraper-modal">
      <div className={`modal-content ${className}`}>
        <div className="flex mb-4" style={{ position: "relative" }}>
          <span onClick={cerrarModalLocal} className="close-modal-token">
            <i className="fa fa-times-circle-o"></i>
          </span>
        </div>

        {mensajeGetAlerta !== null ? (
          <p></p>
        ) : dataModalCode === null ? (
          <Loading />
        ) : (
          <section>
            <MensajeAlerta opcion={dataModalCode.validate} />

            {dataModalCode.validate === 3 && (
              <section className="d-flex container-options-clave">
                <div className="form-group" style={{ width: "100%" }}>
                  <label htmlFor="lugardeenvio">
                    Deseo recibir mi token digital en:{" "}
                  </label>
                  <select
                    id="lugarEnvio"
                    className="form-control"
                    name="lugarEnvio"
                    onChange={elegir}
                    value={optionSelect}
                  >
                    <option value="">- Seleccionar lugar de envio-</option>
                    <option
                      id="correo"
                      value={"correo: " + dataModalCode.info.email}
                    >
                      correo: {dataModalCode.info.email}
                    </option>
                    <option
                      id="celular"
                      value={"celular: " + dataModalCode.info.celular}
                    >
                      celular: {dataModalCode.info.celular}
                    </option>
                  </select>
                </div>
              </section>
            )}

            {lugarDeEnvio && !dataPostCode && (
              <section className="d-flex container-options-clave container-options-clave-custom">
                <div>
                  <Button
                    disabled={loadingEnvio}
                    onClick={enviarClave("enviar")}
                  >
                    Enviar{" "}
                    {loadingEnvio && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </div>
              </section>
            )}

            {dataPostCode ? (
              <section className="content-token">
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label htmlFor="token">
                        Escribe el código para confirmar la operación:
                      </Label>
                      <Input
                        type="text"
                        name="token"
                        maxLength="6"
                        pattern="[0-9]*"
                        value={token}
                        onChange={handleToken}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <Label
                        htmlFor="mensajito"
                        style={{ fontSize: "12px", color: "#bf0d3e" }}
                      >
                        En caso no te llegué el código, podrás volver a
                        solicitarlo{" "}
                        {reenviarCodigo.solicitar ? (
                          <Contador
                            activarEnvio={activarEnvio}
                            iniciaContador={reenviarCodigo.solicitar}
                          />
                        ) : (
                          <strong>ahora</strong>
                        )}
                      </Label>
                    </FormGroup>
                  </Col>
                  {mensajeCodigoValidado && (
                    <Col md={12}>
                      <div className="alert alert-danger">
                        {mensajeCodigoValidado}
                      </div>
                    </Col>
                  )}
                  <Col>
                    <section className="d-flex justify-content-around token">
                      <Button
                        disabled={!reenviarCodigo.activo}
                        onClick={enviarClave("re-enviar")}
                        type="button"
                        style={{ textTransform: "lowercase" }}
                      >
                        <span
                          className={reenviarCodigo.cargando ? "cargando" : ""}
                        >
                          <i className="fa fa-refresh"></i>
                        </span>{" "}
                        volver a enviar token
                      </Button>
                      <Button onClick={procesar}>
                        Confirmar operación
                        {loadingEnvio && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </Button>
                    </section>
                  </Col>
                </Row>
              </section>
            ) : (
              mensajePostAlerta && (
                <p
                  style={{
                    marginTop: "2em",
                    fontSize: "14px",
                    color: "#bf253d",
                  }}
                >
                  Hubo un error al generar su código, por favor vuelva a
                  intentarlo
                </p>
              )
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default ModalToken;
