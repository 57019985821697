import React from "react";
import { CardBody } from "reactstrap";
import TablaDesktop from "./TablaDesktop";
import TabaMobile from "./TablaMobile";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { CardContainer } from "../../components";

const ResumenComisionHistorica = ({ dataTabla, windowWidth, fecha }) => {
  return (
    <CardContainer>
      <CardBody className="px-0 py-2 mb-6">
        {windowWidth > 600 ? (
          <TablaDesktop data={dataTabla} />
        ) : (
          <TabaMobile data={dataTabla} tipo={"apv"} />
        )}

        <span style={{ fontSize: "13px", display: "block", fontWeight: "400" }}>
          Tu fecha de afiliación al SPP es: {fecha}
        </span>
        <span style={{ fontSize: "13px", display: "block", fontWeight: "400" }}>
          *SPP: Sistema Privado de Pensiones
        </span>
      </CardBody>
    </CardContainer>
  );
};
export default hocWidthWindow(ResumenComisionHistorica);
