import { Modal } from "@mui/material";
import styled from "styled-components";

export const ModalDeConfirmacionComponent = styled(Modal)`
  .MuiBackdrop-root {
    background-color: ${(p) => p.theme.palette.transparencies.dark};
  }
`;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 8px;
  text-align: center;
`;

export const Subtitle = styled.h5`
  ${(p) => p.theme.tipography.bodySmallRegular}
  text-align: center;

  span {
    ${(p) => p.theme.tipography.bodySmallMedium}
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;
