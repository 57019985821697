import styled from "styled-components";

export const MontoAlDiaDeHoyComponent = styled.div``;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  ${(p) => p.theme.tipography.bodyMediumRegular}
  margin-bottom: 16px;
`;

export const MontoDisponible = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 16px;
  ${(p) => p.theme.tipography.h4}
  font-weight: 400;
  max-width: 343px;
  color: ${(p) => p.theme.palette.secondary.main};
  border: 1px solid ${(p) => p.theme.palette.secondary.main};
  background-color: ${(p) => p.theme.palette.secondary.lightest};
  border-radius: 16px;
`;

export const Message = styled.div`
 ${(p) => p.theme.tipography.bodySmallMessage}
  margin-top: 16px;
`;
