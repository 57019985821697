import styled from "styled-components";

export const ContentWrapper = styled.div`
  p.description {
    margin-bottom: 16px;
  }

  li {
    a {
      color: ${(p) => p.theme.palette.secondary.main};
      text-decoration: underline;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      text-indent: -20px; /* key property */
      margin-left: 20px; /* key property */
      margin-bottom: 14px;
    }

    ul {
      text-indent: -20px; /* key property */
      margin-left: 20px; /* key property */
    }
  }
`;
