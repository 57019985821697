import React, { useReducer } from "react";
import MisDatosContext from "./misdatosContext";
import MisDatosReducer from "./misdatosReducer";
import clienteAxios from "../../config/axios";
import {
  MIS_DATOS_GET_EXITOSO,
  MIS_DATOS_GET_ERROR,
  MIS_DATOS_GET_LOADIN,
  MIS_DATOS_CLEANER,
  MIS_DATOS_CLEANER_PARCIAL,
  DATOS_PERSONALES_POST_EXITOSO,
  DATOS_PERSONALES_POST_ERROR,
  DATOS_PERSONALES_POST_LOADIN,
  DIRECCION_POST_EXITOSO,
  DIRECCION_POST_ERROR,
  DIRECCION_POST_LOADIN,
  CLAVE_WEB_POST_EXITOSO,
  CLAVE_WEB_POST_ERROR,
  CLAVE_WEB_POST_LOADIN,
  CLAVE_WEB_CLEANER,
  CLAVE_WEB_CLEANER_PARCIAL,
  SEIS_MESES_LOADIN,
  SEIS_MESES_GET_EXITOSO,
  SEIS_MESES_GET_ERROR,
  TARJETAS_GET_EXITOSO,
  TARJETAS_GET_ERROR,
  TARJETAS_GET_LOADIN,
  TARJETAS_GET_CLEANER_PARCIAL,
  TARJETAS_GET_CLEANER,
  ELIMINAR_TARJETA_POST_EXITOSO,
  ELIMINAR_TARJETA_POST_ERROR,
  ELIMINAR_TARJETA_LOADIN,
  AGREGAR_TARJETA_POST_EXITOSO,
  AGREGAR_TARJETA_POST_ERROR,
  AGREGAR_TARJETA_LOADIN,
  ACTUALIZAR_DATOS_PROCESOS,
} from "../../types/misDatos";

const MisDatosState = (props) => {
  const initialState = {
    /**Obtener Datos y Dirección */
    misDatosGet: null,
    mensajeAlertaDatosGet: null, // En caso el servicio o el inter falle
    loadingPage: false, // para el get mostramos el loading
    /**Actualizar Datos*/
    resultadoDatosPost: null,
    mensajeAlertaDatosPost: null,
    loadingBtnDatosPost: false, // procesando la actualización de datos
    /**Actualizar Dirección*/
    resultadoDireccionPost: null,
    mensajeAlertaDireccionPost: null,
    loadingBtnDireccionPost: false, // procesando la actualización de dirección
    /**Actualizar Clave*/
    resultadoClavePost: null,
    mensajeAlertaClavePost: null,
    loadingBtnClavePost: false, // procesando la actualización de claveweb
    /**Actualización 6 meses */
    resultado6mesesGet: null,
    mensajeAlerta6mesesGet: null,
    loadingBtn6mesesGet: false,
    /**Obtenemos las tarjetas */
    tarjetasGet: null,
    mensajeAlertaTarjetasGet: null,
    loadingTarjetasPage: false,
    /**Eliminar Tarjeta */
    resultadoEliminarTarjeta: null,
    mensajeAlertaEliminarTarjetaPost: null,
    loadingBtnEliminarTarjetaPost: false,
    /**Agregar Tarjeta */
    resultadoAgregarTarjeta: null,
    mensajeAlertaAgregarTarjetaPost: null,
    loadingBtnAgregarTarjetaPost: false,
    // Aporte mensual
    aporteMensualData: null,
    aporteMensuaLoading: null,
    aporteMensualError: null,

    // Actualización de datos
    isDataUpdated: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    const errorMessageResponse = error?.response?.data?.r_mensaje;

    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (
        errorMessageResponse ===
        "No se pudo eliminar la cuenta bancaria por estar asociada a su débito mensual"
      ) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "No se pudo eliminar la cuenta bancaria por estar asociada a su debito mensual",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerMisDatosGet = async () => {
    dispatch({
      type: MIS_DATOS_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/actualizacion-datos");
      dispatch({
        type: MIS_DATOS_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, MIS_DATOS_GET_ERROR);
    }
  };

  const actualizarDatosPost = async (datos) => {
    dispatch({
      type: DATOS_PERSONALES_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/actualizacion-datos/personales/procesar",
        datos
      );
      dispatch({
        type: DATOS_PERSONALES_POST_EXITOSO,
        payload: respuesta.data,
      });
      return respuesta;
    } catch (error) {
      handleErrorsLocal(error, DATOS_PERSONALES_POST_ERROR);
    }
  };

  const actualizarDireccionPost = async (datos) => {
    dispatch({
      type: DIRECCION_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/actualizacion-datos/direccion/procesar",
        datos
      );
      dispatch({
        type: DIRECCION_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, DIRECCION_POST_ERROR);
    }
  };

  const actualizarClavePost = async (datos) => {
    dispatch({
      type: CLAVE_WEB_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/cambiar-clave-web", datos);
      dispatch({
        type: CLAVE_WEB_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CLAVE_WEB_POST_ERROR);
    }
  };
  const cambiarClavePost = async (datos) => {
    dispatch({
      type: CLAVE_WEB_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/actualizar-clave-web", datos);
      dispatch({
        type: CLAVE_WEB_POST_EXITOSO,
        payload: respuesta.data,
      });
      return respuesta;
    } catch (error) {
      handleErrorsLocal(error, CLAVE_WEB_POST_ERROR);
    }
  };
  const actualiza6MesesPost = async () => {
    dispatch({
      type: SEIS_MESES_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(
        "/actualizacion-datos/validados"
      );
      dispatch({
        type: SEIS_MESES_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, SEIS_MESES_GET_ERROR);
    }
  };

  const limpiarVariablesMisDatos = () => {
    dispatch({
      type: MIS_DATOS_CLEANER,
    });
  };
  const limpiarVariablesMisDatosParcial = () => {
    dispatch({
      type: MIS_DATOS_CLEANER_PARCIAL,
    });
  };

  const limpiarVariablesClaveWeb = () => {
    dispatch({
      type: CLAVE_WEB_CLEANER,
    });
  };
  const limpiarVariablesClaveWebParcial = () => {
    dispatch({
      type: CLAVE_WEB_CLEANER_PARCIAL,
    });
  };

  const obtenerTarjetasGet = async () => {
    dispatch({
      type: TARJETAS_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/mis-datos/tarjetas");
      dispatch({
        type: TARJETAS_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, TARJETAS_GET_ERROR);
    }
  };

  const limpiarVariablesTarjeta = () => {
    dispatch({
      type: TARJETAS_GET_CLEANER,
    });
  };
  const limpiarVariablesTarjetaParcial = () => {
    dispatch({
      type: TARJETAS_GET_CLEANER_PARCIAL,
    });
  };

  const eliminarTarjetaPost = async (datos) => {
    dispatch({
      type: ELIMINAR_TARJETA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/mis-datos/tarjetas/eliminar",
        datos
      );
      dispatch({
        type: ELIMINAR_TARJETA_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, ELIMINAR_TARJETA_POST_ERROR);
    }
  };

  const agregarTarjetaPost = async (datos) => {
    dispatch({
      type: AGREGAR_TARJETA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/mis-datos/tarjetas/guardar",
        datos
      );
      dispatch({
        type: AGREGAR_TARJETA_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, AGREGAR_TARJETA_POST_ERROR);
    }
  };

  const actualizarDatosProcesos = (data) => {
    dispatch({
      type: ACTUALIZAR_DATOS_PROCESOS,
      payload: data,
    });
  };

  const [state, dispatch] = useReducer(MisDatosReducer, initialState);
  return (
    <MisDatosContext.Provider
      value={{
        misDatosGet: state.misDatosGet,
        mensajeAlertaDatosGet: state.mensajeAlertaDatosGet,
        loadingPage: state.loadingPage,
        resultadoDatosPost: state.resultadoDatosPost,
        mensajeAlertaDatosPost: state.mensajeAlertaDatosPost,
        loadingBtnDatosPost: state.loadingBtnDatosPost,
        resultadoDireccionPost: state.resultadoDireccionPost,
        mensajeAlertaDireccionPost: state.mensajeAlertaDireccionPost,
        loadingBtnDireccionPost: state.loadingBtnDireccionPost,
        resultadoClavePost: state.resultadoClavePost,
        mensajeAlertaClavePost: state.mensajeAlertaClavePost,
        loadingBtnClavePost: state.loadingBtnClavePost,
        resultado6mesesGet: state.resultado6mesesGet,
        mensajeAlerta6mesesGet: state.mensajeAlerta6mesesGet,
        loadingBtn6mesesGet: state.loadingBtn6mesesGet,
        tarjetasGet: state.tarjetasGet,
        mensajeAlertaTarjetasGet: state.mensajeAlertaTarjetasGet,
        loadingTarjetasPage: state.loadingTarjetasPage,
        resultadoEliminarTarjeta: state.resultadoEliminarTarjeta,
        mensajeAlertaEliminarTarjetaPost:
          state.mensajeAlertaEliminarTarjetaPost,
        loadingBtnEliminarTarjetaPost: state.loadingBtnEliminarTarjetaPost,
        resultadoAgregarTarjeta: state.resultadoAgregarTarjeta,
        mensajeAlertaAgregarTarjetaPost: state.mensajeAlertaAgregarTarjetaPost,
        loadingBtnAgregarTarjetaPost: state.loadingBtnAgregarTarjetaPost,
        isDataUpdated: state.isDataUpdated,
        obtenerMisDatosGet,
        actualizarDatosPost,
        actualizarDireccionPost,
        actualizarClavePost,
        cambiarClavePost,
        actualiza6MesesPost,
        limpiarVariablesMisDatos,
        limpiarVariablesMisDatosParcial,
        limpiarVariablesClaveWeb,
        limpiarVariablesClaveWebParcial,
        obtenerTarjetasGet,
        limpiarVariablesTarjetaParcial,
        limpiarVariablesTarjeta,
        eliminarTarjetaPost,
        agregarTarjetaPost,
        actualizarDatosProcesos,
      }}
    >
      {props.children}
    </MisDatosContext.Provider>
  );
};

export default MisDatosState;
