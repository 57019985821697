import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import hocWindowWidth from "../../HOC/HocWidthWindow";
/*Menu Icon */
import blackAccount from "../../assets/iconBottomBar/black-account.svg";
import redAccount from "../../assets/iconBottomBar/red-account.svg";
import blackDollar from "../../assets/icons/Fondo_Invertir.svg";
import redDollar from "../../assets/icons/Fondo_red.svg";
import blackHome from "../../assets/iconBottomBar/black-home.svg";
import redHome from "../../assets/iconBottomBar/red-home.svg";
/*Sub Menu nicon */
import personalData from "../../assets/icons/personal-data.svg";
import keyboardIcon from "../../assets/icons/keyboard-icon.svg";
import dollarCoin from "../../assets/icons/wallet_24.svg";
import cambioIcon from "../../assets/icons/cambio-fondo.svg";
import logoutIcon from "../../assets/logout-icon.svg";
import logoutRedIcon from "../../assets/icons/logout-red.svg";

import { BottomMenu, BottomDrawer } from "./components";
import "./index.css";

const optionsArray = [
  {
    title: "Inicio",
    image: blackHome,
    alImage: "home-icon",
    imageSelected: redHome,
    url: "/mi-habitat-digital/afiliado/inicio",
    subMenuOptions: [],
  },

  // {
  //   title: "Invertir",
  //   image: blackDollar,
  //   alImage: "dollar-icon",
  //   imageSelected: redDollar,
  //   url: "/mi-habitat-digital/afiliado/apv/fondo-libre-habitat",
  //   subMenuOptions: [],
  // },
  {
    title: "Mi cuenta",
    image: blackAccount,
    alImage: "account-icon",
    imageSelected: redAccount,
    subMenuOptions: [
      {
        title: "Datos  personales",
        subTitle: "Mantenlos actualizados.",
        url: "/mi-habitat-digital/afiliado/informacion/datos-personales",
        icon: personalData,
      },
      {
        title: "Clave web",
        subTitle: "Cámbiala desde aquí.",
        url: "/mi-habitat-digital/afiliado/informacion/clave-web",
        icon: keyboardIcon,
      },

      {
        title: "Cambio de tipo de fondo",
        subTitle: "A un clic de distancia.",
        url: "/mi-habitat-digital/afiliado/mis-fondos/cambio-de-fondo",
        icon: cambioIcon,
      },
      {
        title: "Cuentas bancarias",
        subTitle: "Actualízalas cuando lo necesites.",
        url: "/mi-habitat-digital/afiliado/informacion/cuentas-bancarias",
        icon: dollarCoin,
      },
    ],
  },
];

const BottomNavegation = (props) => {
  const location = useLocation();
  let urlPath = (urlView) =>
    urlView[3] === "informacion"
      ? "Mi cuenta"
      : urlView[3] === "apv" || urlView[3] === "seguimiento"
      ? "Invertir"
      : urlView[3] === "inicio"
      ? "Inicio"
      : "";
  const [optionSelected, setOptionSelected] = useState(
    urlPath(location.pathname.split("/"))
  );
  const [optionDetailSelected, setOptionDetailSelected] = useState("");
  const [isDraweOpen, setIsDrawerOpen] = useState(false);
  const [draweOptions, setDrawerOption] = useState([]);

  useEffect(() => {
    setOptionSelected(urlPath(location.pathname.split("/")));
  }, [location]);

  if (props.windowWidth > 980) return null;

  let backdrop;

  const backDropClickHandler = () => {
    setIsDrawerOpen(false);
  };
  if (isDraweOpen) {
    backdrop = (
      <div
        className="m-backdrop zi-0"
        onClick={() => backDropClickHandler()}
      ></div>
    );
  }

  return (
    <div className={`bottom-navegation-component ${isDraweOpen ? "open" : ""}`}>
      {backdrop}
      <BottomDrawer
        draweOptions={draweOptions}
        isDraweOpen={isDraweOpen}
        location={urlPath}
        setIsDrawerOpen={setIsDrawerOpen}
        setOptionDetailSelected={setOptionDetailSelected}
        setOptionSelected={setOptionSelected}
      />
      <BottomMenu
        optionsArray={optionsArray}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        optionDetailSelected={optionDetailSelected}
        isDraweOpen={isDraweOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setDrawerOption={setDrawerOption}
        history={props.history}
        setOpenModalInvertir={props.setOpenModalInvertir}
        mensajeInformativo={props.mensajeInformativo}
      />
    </div>
  );
};

export default hocWindowWidth(BottomNavegation);
