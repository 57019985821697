import React, { useState, useContext } from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import { CardContainer, Button } from "../../../components";
import CustomModal from "../../../components/custromModal/CustomModal";
import ModalToken from "../../../components/ModalToken";
import MisdatosContext from "../../../context/misdatos/misdatosContext";
import ModalErrorToken from "./ModalErrorToken";

import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";

const globalErrors = {
  r_clave_web: "",
  r_clave_nueva: "",
  r_clave_confirmar: "",
};

const PrimerPaso = ({
  mostrarModalToken,
  openModalToken,
  setOpenModalToken,
  setMostrarModalToken,
}) => {
  const [claves, setClaves] = useState({
    r_clave_web: "",
    r_clave_nueva: "",
    r_clave_confirmar: "",
  });
  const misdatosContext = useContext(MisdatosContext);
  const { actualizarClavePost, loadingBtnClavePost } = misdatosContext;
  const [errores, setErrores] = useState(globalErrors);
  const [showPassword, setShowPassword] = useState("");
  const [confirmacionInfo, setConfirmacionInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handlerShowPassword = (option) => (e) => {
    if (showPassword.length > 0) {
      setShowPassword("");
    } else {
      setShowPassword(option);
    }
  };

  const handlerPassword = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setClaves({ ...claves, [e.target.name]: valor });
    setErrores({ ...errores, [e.target.name]: "" });
  };

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const validarData = () => {
    let mensajesError = {
      r_clave_web: "",
      r_clave_nueva: "",
      r_clave_confirmar: "",
    };

    if (claves.r_clave_web === 0 || claves.r_clave_web.trim() === "") {
      mensajesError = { ...mensajesError, r_clave_web: "campo requerido" };
    } else {
      if (claves.r_clave_web.length < 4) {
        mensajesError = { ...mensajesError, r_clave_web: "campo de 4 dígitos" };
      }
    }
    if (claves.r_clave_nueva === 0 || claves.r_clave_nueva.trim() === "") {
      mensajesError = { ...mensajesError, r_clave_nueva: "campo requerido" };
    } else {
      if (claves.r_clave_nueva.length < 4) {
        mensajesError = {
          ...mensajesError,
          r_clave_nueva: "campo de 4 dígitos",
        };
      } else {
        if (claves.r_clave_web === claves.r_clave_nueva) {
          mensajesError = {
            ...mensajesError,
            r_clave_nueva: "debe ser diferente a la clave actual",
          };
        }
      }
    }

    if (
      claves.r_clave_confirmar === 0 ||
      claves.r_clave_confirmar.trim() === ""
    ) {
      mensajesError = {
        ...mensajesError,
        r_clave_confirmar: "campo requerido",
      };
    } else {
      if (claves.r_clave_confirmar.length < 4) {
        mensajesError = {
          ...mensajesError,
          r_clave_confirmar: "campo de 4 dígitos",
        };
      } else {
        if (claves.r_clave_confirmar !== claves.r_clave_nueva) {
          mensajesError = {
            ...mensajesError,
            r_clave_confirmar: "no es igual a la nueva clave",
          };
        }
      }
    }

    setErrores(mensajesError);

    // Vemos si existe algun error
    let respuesta = true;
    for (let item in mensajesError) {
      if (mensajesError[item].length > 0) {
        respuesta = false;
        return;
      }
    }

    return respuesta;
  };

  const handleSubmit = () => {
    if (validarData()) {
      setConfirmacionInfo({ ...claves });
      setShowModal(true);
    }
  };

  const procesarSolicitud = (token) => {
    let bodyFormData = new FormData();
    bodyFormData.set("v_clave", confirmacionInfo.r_clave_web);
    bodyFormData.set("v_nueva_clave", confirmacionInfo.r_clave_nueva);
    bodyFormData.set("v_token_digital", token);
    actualizarClavePost(bodyFormData);
  };
  return (
    <CardContainer>
      <Card>
        <CardBody>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label htmlFor="r_clave_web">Clave web actual</Label>
                <InputGroup>
                  <Input
                    type={showPassword === "r_clave_web" ? "text" : "password"}
                    placeholder="Clave actual"
                    name="r_clave_web"
                    value={claves.r_clave_web}
                    maxLength={4}
                    pattern="[0-9]*"
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    onChange={handlerPassword}
                  />
                  <InputGroupAddon addonType="append">
                    <span
                      style={{ cursor: "pointer" }}
                      className="input-group-text"
                      onClick={handlerShowPassword("r_clave_web")}
                    >
                      <i
                        className={
                          showPassword === "r_clave_web"
                            ? "fa fa-eye"
                            : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  </InputGroupAddon>
                </InputGroup>

                {errores.r_clave_web && (
                  <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                    {errores.r_clave_web}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label htmlFor="r_clave_nueva">Nueva clave web</Label>
                <InputGroup>
                  <Input
                    type={
                      showPassword === "r_clave_nueva" ? "text" : "password"
                    }
                    placeholder="Nueva clave"
                    name="r_clave_nueva"
                    value={claves.r_clave_nueva}
                    maxLength={4}
                    pattern="[0-9]*"
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    onChange={handlerPassword}
                  />
                  <InputGroupAddon addonType="append">
                    <span
                      style={{ cursor: "pointer" }}
                      className="input-group-text"
                      onClick={handlerShowPassword("r_clave_nueva")}
                    >
                      <i
                        className={
                          showPassword === "r_clave_nueva"
                            ? "fa fa-eye"
                            : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  </InputGroupAddon>
                </InputGroup>

                {errores.r_clave_nueva && (
                  <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                    {errores.r_clave_nueva}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label htmlFor="r_clave_confirmar">Confirmar nueva clave</Label>
                <InputGroup>
                  <Input
                    type={
                      showPassword === "r_clave_confirmar" ? "text" : "password"
                    }
                    placeholder="Confirmar clave"
                    name="r_clave_confirmar"
                    value={claves.r_clave_confirmar}
                    maxLength={4}
                    pattern="[0-9]*"
                    onPaste={handlerDisable}
                    onCopy={handlerDisable}
                    onChange={handlerPassword}
                  />
                  <InputGroupAddon addonType="append">
                    <span
                      style={{ cursor: "pointer" }}
                      className="input-group-text"
                      onClick={handlerShowPassword("r_clave_confirmar")}
                    >
                      <i
                        className={
                          showPassword === "r_clave_confirmar"
                            ? "fa fa-eye"
                            : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  </InputGroupAddon>
                </InputGroup>

                {errores.r_clave_confirmar && (
                  <span style={{ fontSize: "14px", color: "#bf0d3e" }}>
                    {errores.r_clave_confirmar}
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <section className="d-flex justify-content-around">
        <Button onClick={handleSubmit}>Actualizar clave web</Button>
      </section>

      <CustomModal
        showModal={showModal}
        className="beauty-modal"
        toggle={() => {
          setShowModal(!showModal);
        }}
        fnCloseIconBtn={() => {
          setShowModal(!showModal);
        }}
        title="Confirmación de actualización de clave web"
        description={""}
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setOpenModalToken(true);
          setShowModal(false);
        }}
        textBtnPrimary="Aceptar"
        btnPrimaryLoading={loadingBtnClavePost}
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }} // fn para cerrar modal
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalErrorToken
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </CardContainer>
  );
};

export default hocWidthWindow(PrimerPaso);
