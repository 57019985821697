import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "./CustomModal";
import { ContentCheckValidacion } from "../../styles/Modal";

const ModalPensionC40 = ({
  isOpen,
  setIsOpen,
  misDatosGet,
  crearProcesoLegalData,
  createLegalDataLoading,
}) => {
  const history = useHistory();
  const closeModal = () => setIsOpen(false);
  const datos = {
    type_of_procedure_id: 2,
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Confirma tus datos de contacto"
      loadingBtnPrimary={createLegalDataLoading}
      onClickBtnPrimary={() => crearProcesoLegalData(datos)}
      textBtnPrimary={"Confirmar datos"}
      loadingBtnSecondary={createLegalDataLoading}
      isBtnSecondary={true}
      textBtnSecondary="Actualizar datos"
      onClickBtnSecondary={() =>
        history.push(
          "/mi-habitat-digital/afiliado/informacion/datos-personales"
        )
      }
    >
      <ContentCheckValidacion>
        <div className="user-data">
          <div className="field mb-2">
            <span className="label">Correo personal:</span>
            <span className="data">
              {misDatosGet.r_persona.r_datos.r_correo
                ? misDatosGet.r_persona.r_datos.r_correo
                : "No registrado"}
            </span>
          </div>

          <div className="field">
            <span className="label">Celular:</span>
            <span className="data">
              {misDatosGet.r_persona.r_datos.r_celular
                ? misDatosGet.r_persona.r_datos.r_celular
                : "No registrado"}
            </span>
          </div>
        </div>
      </ContentCheckValidacion>
    </Modal>
  );
};

export default ModalPensionC40;
