import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button } from "reactstrap";
import LyricMusicPlayer from "../../../../../../Components/lyricsMusicPlayer/LyricMusicPlayerNuevoRetiro";
import Timeline from "../../../../../../Components/timeline/Timeline";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import svgTiempo from "../../../../../../../../assets/tiempo.svg";
import svgRepeticionTema from "../../../../../../../../assets/repeticion_tema.svg";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import AuthContext from "../../../../../../../../context/autenticacion/authContext";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function AsesoriaTemas(props) {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarAvanceJuilacionData,
    guardarAvanceJuilacionLoading,
    guardarAvanceTramiteJubilacion,
    limpiarGuardarAvanceTramiteJubilacion,
  } = jubilacionContext;

  const { endFirstStep, processId, contens, dependencies } = props;

  const [topicsData, setTopicsData] = useState(contens);
  const [currentTopic, setCurrentTopic] = useState(contens[0].topic);
  const [currentTopicData, setCurrentTopicData] = useState(contens[0]);
  const [endVideo, setEndVideo] = useState(false);
  const [durationVideo, setDurationVideo] = useState("0");
  const [isTopicRepeat, setIsTopicRepeat] = useState(false);
  const [endProcess, setEndProcess] = useState(false);

  const [modalNextTopic, setModalNextTopic] = useState(false);
  const [modalRepeatTopic, setModalRepeatTopic] = useState(false);
  const [topicForBlock, setTopicForBlock] = useState(null);
  const [topicForRepeat, setTopicForRepeat] = useState(null);
  const [descriptionForRepeat, setDescriptionForRepeat] = useState("");
  const [modalBlockTopic, setModalBlockTopic] = useState(false);
  const [modalEndTopics, setModalEndTopics] = useState(false);
  const [isRepeatWorkflow, setIsRepeatWorkflow] = useState(false);
  const [modalAnotherProcess, setModalAnotherProcess] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    actualizarTimer();
    window.scrollTo(0, 0);
    return () => {
      limpiarGuardarAvanceTramiteJubilacion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contens) {
      const topicPending = contens.find((conten) => conten.status === "active");
      setCurrentTopic(topicPending.topic);
      setCurrentTopicData(topicPending);
    }
  }, [contens]);

  useEffect(() => {
    if (guardarAvanceJuilacionData && sendRequest) {
      if (guardarAvanceJuilacionData.success) {
        setSendRequest(false);
        if (endProcess) {
          if (!dependencies) setModalEndTopics(true);
          else endFirstStep();
        } else setModalNextTopic(true);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarAvanceJuilacionData]);

  const fnBtnrepeatVideoAudio = () => {
    document.getElementById("video").currentTime = 0;
    document.getElementById("video").play();
  };

  const reloadAudio = () => {
    document.getElementById("video").pause();
    document.getElementById("video").load();
    document.getElementById("video").play();
    setEndVideo(false);
  };

  const fnBtnNextTopic = () => {
    actualizarTimer();
    const lastTopic = topicsData[topicsData.length - 1].topic;

    if (lastTopic === currentTopicData.topic) {
      if (!dependencies) {
        setEndProcess(true);
        setSendRequest(true);
        guardarAvanceTramiteJubilacion(processId, 1, {
          data: [{ id: currentTopicData.id, response: true }],
        });
      } else {
        setModalEndTopics(true);
      }
      return;
    }

    const nextTopic = currentTopicData.topic + 1;
    const nextTopicData = topicsData.find((item) => item.topic === nextTopic);
    if (nextTopic > currentTopic) {
      setSendRequest(true);
      guardarAvanceTramiteJubilacion(processId, 1, {
        data: [{ id: currentTopicData.id, response: true }],
      });
    } else {
      setCurrentTopicData(nextTopicData);
      setIsTopicRepeat(false);
      reloadAudio();
    }
  };

  const fnBtnModalNextTopic = () => {
    const nextTopic = currentTopicData.topic + 1;
    setCurrentTopic(nextTopic);
    setCurrentTopicData(topicsData.find((item) => item.topic === nextTopic));
    reloadAudio();
    setModalNextTopic(false);
  };

  const toggleModalBlockTopic = () => {
    setModalBlockTopic(!modalBlockTopic);
  };
  const toggleModalNextTopic = () => {
    setModalNextTopic(!modalNextTopic);
  };

  const fnBtnModalBlockTopic = () => {
    if (currentTopic !== currentTopicData.topic) {
      setCurrentTopicData(
        topicsData.find((item) => item.topic === currentTopic)
      );
      reloadAudio();
    }
    setModalBlockTopic(false);
  };

  const fnBtnModalRepeatTopic = () => {
    actualizarTimer();
    setCurrentTopicData(
      topicsData.find((item) => item.topic === topicForRepeat)
    );
    reloadAudio();
    setIsTopicRepeat(true);
    setModalRepeatTopic(false);
  };

  const logicToChangeTopics = (topic, description) => {
    if (isRepeatWorkflow) {
      setCurrentTopicData(topicsData.find((item) => item.topic === topic));
      setIsTopicRepeat(true);
      reloadAudio();
    } else if (topic !== currentTopic) {
      setTopicForRepeat(topic);
      setDescriptionForRepeat(description);
      setModalRepeatTopic(true);
    } else {
      setCurrentTopicData(
        topicsData.find((item) => item.topic === currentTopic)
      );
      setIsTopicRepeat(false);
      reloadAudio();
    }
  };

  const changeIconForLoading = () => {
    if (guardarAvanceJuilacionLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <div className="workflow-container">
      <Row className="workflow-sections">
        <Col className="left-section" xl="5" lg="5" md="5" sm="3" xs="3">
          <div>
            <Timeline
              topicsData={topicsData}
              currentTopic={currentTopic}
              currentTopicData={currentTopicData}
              setModalBlockTopic={setModalBlockTopic}
              setTopicForBlock={setTopicForBlock}
              logicToChangeTopics={logicToChangeTopics}
              isRepeatWorkflow={isRepeatWorkflow}
              actualizarTimer={actualizarTimer}
            />
          </div>
        </Col>
        <Col className="right-section" xl="7" lg="7" md="7" sm="9" xs="9">
          <div className="time-row">
            <span>
              <img src={svgTiempo} alt="tiempo" />
              {durationVideo} minuto
            </span>
          </div>
          {isTopicRepeat && (
            <div className="repeat-row-mobile">
              <img
                className="icon-repeat-mobile"
                src={svgRepeticionTema}
                alt="icono de repeticion"
              />
            </div>
          )}
          {/* <h6>{currentTopicData.title}</h6> */}
          <h4>{currentTopicData.description}</h4>
          <div className="break-line"></div>
          <LyricMusicPlayer
            asesoria="legal"
            currentTopicData={currentTopicData}
            setEndVideo={setEndVideo}
            setDurationVideo={setDurationVideo}
          />

          <div className="content-topic-buttons">
            <Button
              className={`mb-4 btn-modal-secondary ${
                endVideo ? "" : "disable-secondary"
              }`}
              onClick={() => fnBtnrepeatVideoAudio()}
              disabled={!endVideo}
            >
              Repetir Tema
            </Button>
            <Button
              className={`mb-4 btn-modal-primary ${
                endVideo ? "" : "disable-primary"
              }`}
              onClick={() => fnBtnNextTopic()}
              disabled={!endVideo || guardarAvanceJuilacionLoading}
            >
              {topicsData[topicsData.length - 1].topic !==
              currentTopicData.topic
                ? "Siguiente Tema "
                : "Finalizar asesoría "}{" "}
              {changeIconForLoading()}
            </Button>
          </div>
        </Col>
      </Row>

      <CustomModal
        showModal={modalNextTopic}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={toggleModalNextTopic}
        title={`${currentTopicData.title} - ${currentTopicData.description}`}
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, ya
            terminaste con el{" "}
            {`${currentTopicData.title} - "${currentTopicData.description}"`},
            si te quedó alguna duda puedes volverlo a escuchar cuando gustes. Al
            dar clic en “CONFORME” estás aceptando haber recibido y entendido la
            información brindada.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnModalNextTopic}
        textBtnPrimary="Conforme"
      />
      <CustomModal
        showModal={modalBlockTopic}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={toggleModalBlockTopic}
        title="Lo sentimos, aún no puedes reproducir este tema."
        description={
          <p>
            Primero debes culminar los temas anteriores antes de pasar al <br />{" "}
            Tema {topicForBlock}.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnModalBlockTopic}
        textBtnPrimary="Ver tema(s) pendiente(s)"
      />
      <CustomModal
        showModal={modalRepeatTopic}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={() => {
          setModalRepeatTopic(false);
        }}
        title={`Volver a reproducir el Tema ${topicForRepeat} - ${descriptionForRepeat}`}
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, a
            continuación, podrás repetir el tema solicitado,
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={fnBtnModalRepeatTopic}
        textBtnPrimary="Repetir Tema"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          setModalRepeatTopic(false);
        }}
        textBtnSecondary="Seguir aquí"
      />
      <CustomModal
        showModal={modalEndTopics}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={() => {
          setModalEndTopics(false);
          if (!dependencies) {
            endFirstStep();
          }
        }}
        title="Hemos concluido con la asesoría"
        description={
          <p>
            <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, has
            terminado la etapa de asesoría. Ahora necesitamos que nos brindes
            una cuenta bancaria en la cual realizaremos el abono de tu nuevo
            retiro.
          </p>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          if (dependencies) setModalAnotherProcess(true);
          else endFirstStep();

          setModalEndTopics(false);
        }}
        textBtnPrimary="Continuar"
      />

      {dependencies && (
        <CustomModal
          showModal={modalAnotherProcess}
          className="beauty-modal"
          backdrop="static"
          fnCloseIconBtn={() => setModalAnotherProcess(!modalAnotherProcess)}
          title={
            dependencies.recognition_bonus
              ? "Pensión mínima"
              : "Bono de reconomiento"
          }
          description={
            dependencies.recognition_bonus ? (
              <p>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,
                ¿Cuentas con al menos 20 años de aportes entre la ONP y AFP? Si
                es así, podrías acceder a la jubilación por Pensión mínima, que
                te brindaría una pensión base de forma vitalicia.
                <br />
                <br />
                Si crees tener derecho a esta jubilación, por favor comunícate a
                uno de nuestros canales de atención para solicitar una asesoría.
                <br />
                <a href="https://www.afphabitat.com.pe/canales-de-atencion-remotos/">
                  https://www.afphabitat.com.pe/canales-de-atencion-remotos/
                </a>
                <br />
                <br />
                En caso no desees optar por la Pensión mínima, puedes seguir con
                tu trámite de Jubilación por Edad Legal dando clic en
                “Continuar”.
              </p>
            ) : (
              <p>
                <strong>{sessionStorage.getItem("nombreUsuario")}</strong>,
                ¿Realizaste los aportes en los periodos especificados por la ONP
                para acceder a un Bono de Reconocimiento? Si es el caso, podrías
                iniciar esta solicitud paralelamente a tu trámite de Jubilación
                por Edad Legal.
                <br /> <br />
                Te enviaremos un correo con la información necesaria para poder
                validar si aplicas o no a uno de los tipos de Bono vigentes.
              </p>
            )
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() => {
            setEndProcess(true);
            setSendRequest(true);
            guardarAvanceTramiteJubilacion(processId, 1, {
              data: [{ id: currentTopicData.id, response: true }],
            });
          }}
          textBtnPrimary="Continuar"
          btnPrimaryLoading={guardarAvanceJuilacionLoading}
          hasBtnSecondary={true}
          fnBtnSecondary={() => setModalAnotherProcess(!modalAnotherProcess)}
          textBtnSecondary="Volver"
        />
      )}
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
