import {
  JUBILACION_POST_EXITOSO,
  JUBILACION_POST_ERROR,
  JUBILACION_POST_LOADIN,
  JUBILACION_CLEANER,
  JUBILACION_CLEANER_PARCIAL,
  MENU_DATA,
  ACTUALIZAR_PROCESO_JUBILACION_CLEANER,
  ACTUALIZAR_PROCESO_JUBILACION_ERROR,
  ACTUALIZAR_PROCESO_JUBILACION_EXITOSO,
  ACTUALIZAR_PROCESO_JUBILACION_LOADIN,
  CREAR_PROCESO_JUBILACION_CLEANER,
  CREAR_PROCESO_JUBILACION_ERROR,
  CREAR_PROCESO_JUBILACION_EXITOSO,
  CREAR_PROCESO_JUBILACION_LOADIN,
  OBTENER_PROCESO_JUBILACION_CLEANER,
  OBTENER_PROCESO_JUBILACION_ERROR,
  OBTENER_PROCESO_JUBILACION_EXITOSO,
  OBTENER_PROCESO_JUBILACION_LOADIN,
  OBTENER_PROCESO_LEGAL_LOADIN,
  OBTENER_PROCESO_LEGAL_EXITOSO,
  OBTENER_PROCESO_LEGAL_ERROR,
  OBTENER_PROCESO_LEGAL_CLEANER,
  CREAR_PROCESO_LEGAL_LOADIN,
  CREAR_PROCESO_LEGAL_EXITOSO,
  CREAR_PROCESO_LEGAL_ERROR,
  CREAR_PROCESO_LEGAL_CLEANER,
  OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN,
  OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO,
  OBTENER_CONTENIDO_PASO_JUBILACION_ERROR,
  OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_CLEANER,
  REJA_SGA_VALIDAR_PENSION_LOADING,
  REJA_SGA_VALIDAR_PENSION_EXITOSO,
  REJA_SGA_VALIDAR_PENSION_ERROR,
  REJA_SGA_VALIDAR_PENSION_CLEANER,
  OBTENER_PROCESO_REJA_LOADIN,
  OBTENER_PROCESO_REJA_EXITOSO,
  OBTENER_PROCESO_REJA_ERROR,
  OBTENER_PROCESO_REJA_CLEANER,
  CREAR_PROCESO_REJA_LOADIN,
  CREAR_PROCESO_REJA_EXITOSO,
  CREAR_PROCESO_REJA_ERROR,
  CREAR_PROCESO_REJA_CLEANER,
  GUARDAR_PASO_DOS_REJA_LOADIN,
  GUARDAR_PASO_DOS_REJA_EXITOSO,
  GUARDAR_PASO_DOS_REJA_ERROR,
  GUARDAR_PASO_DOS_REJA_CLEANER,
  OBTENER_PARENTESCOS_REJA_LOADIN,
  OBTENER_PARENTESCOS_REJA_EXITOSO,
  OBTENER_PARENTESCOS_REJA_ERROR,
  OBTENER_PARENTESCOS_REJA_CLEANER,
  REJA_ESTIMACION_LOADING,
  REJA_ESTIMACION_EXITOSO,
  REJA_ESTIMACION_ERROR,
  REJA_ESTIMACION_CLEANER,
  REJA_PDF_ESTIMACION_LOADING,
  REJA_PDF_ESTIMACION_EXITOSO,
  REJA_PDF_ESTIMACION_ERROR,
  REJA_PDF_ESTIMACION_CLEANER,
  CREAR_PROCESO_ETAPA1_LOADIN,
  CREAR_PROCESO_ETAPA1_EXITOSO,
  CREAR_PROCESO_ETAPA1_ERROR,
  CREAR_PROCESO_ETAPA1_CLEANER,
  OBTENER_TEMAS_ASESORIA_REJA_LOADIN,
  OBTENER_TEMAS_ASESORIA_REJA_EXITOSO,
  OBTENER_TEMAS_ASESORIA_REJA_ERROR,
  OBTENER_TEMAS_ASESORIA_REJA_CLEANER,
  CREAR_PROCESO_ETAPA2_LOADIN,
  CREAR_PROCESO_ETAPA2_EXITOSO,
  CREAR_PROCESO_ETAPA2_ERROR,
  CREAR_PROCESO_ETAPA2_CLEANER,
  CREAR_PROCESO_ETAPA3_LOADIN,
  CREAR_PROCESO_ETAPA3_EXITOSO,
  CREAR_PROCESO_ETAPA3_ERROR,
  CREAR_PROCESO_ETAPA3_CLEANER,
  OBTENER_MARCO_BONO_LOADIN,
  OBTENER_MARCO_BONO_EXITOSO,
  OBTENER_MARCO_BONO_ERROR,
  OBTENER_MARCO_BONO_CLEANER,
  CREAR_PROCESO_ETAPA4_LOADIN,
  CREAR_PROCESO_ETAPA4_EXITOSO,
  CREAR_PROCESO_ETAPA4_ERROR,
  CREAR_PROCESO_ETAPA4_CLEANER,
  CREAR_PROCESO_ETAPA5_LOADIN,
  CREAR_PROCESO_ETAPA5_EXITOSO,
  CREAR_PROCESO_ETAPA5_ERROR,
  CREAR_PROCESO_ETAPA5_CLEANER,
  OBTENER_MARCO_PENSION_LOADIN,
  OBTENER_MARCO_PENSION_EXITOSO,
  OBTENER_MARCO_PENSION_ERROR,
  OBTENER_MARCO_PENSION_CLEANER,
  CREAR_PROCESO_ETAPA6_LOADIN,
  CREAR_PROCESO_ETAPA6_EXITOSO,
  CREAR_PROCESO_ETAPA6_ERROR,
  CREAR_PROCESO_ETAPA6_CLEANER,
  CREAR_PROCESO_ETAPA7_LOADIN,
  CREAR_PROCESO_ETAPA7_EXITOSO,
  CREAR_PROCESO_ETAPA7_ERROR,
  CREAR_PROCESO_ETAPA7_CLEANER,
  OBTENER_PASO_6_REJA_DATOS,
  RETIRO_RESETEAR,
  ACCEDER_JAO_LOADING,
  ACCEDER_JAO_EXITOSO,
  ACCEDER_JAO_ERROR,
  ACCEDER_JAO_CLEAR,
  GET_JAO_PROCESS_LOADING,
  GET_JAO_PROCESS_EXITOSO,
  GET_JAO_PROCESS_ERROR,
  GET_JAO_PROCESS_CLEAR,
  GET_JAO_BENEFICIARIES_LOADING,
  GET_JAO_BENEFICIARIES_EXITOSO,
  GET_JAO_BENEFICIARIES_ERROR,
  GET_JAO_BENEFICIARIES_CLEAR,
  SAVE_JAO_BENEFICIARIES_LOADING,
  SAVE_JAO_BENEFICIARIES_EXITOSO,
  SAVE_JAO_BENEFICIARIES_ERROR,
  SAVE_JAO_BENEFICIARIES_CLEAR,
  GET_JAO_SALDOS_LOADING,
  GET_JAO_SALDOS_EXITOSO,
  GET_JAO_SALDOS_ERROR,
  GET_JAO_SALDOS_CLEAR,
  SAVE_JAO_SALDOS_LOADING,
  SAVE_JAO_SALDOS_EXITOSO,
  SAVE_JAO_SALDOS_ERROR,
  SAVE_JAO_SALDO_CLEAR,
  SAVE_JAO_AVISO_LOADING,
  SAVE_JAO_AVISO_EXITOSO,
  SAVE_JAO_AVISO_ERROR,
  SAVE_JAO_AVISO_CLEAR,
  GET_JAO_RESULTADO_LOADING,
  GET_JAO_RESULTADO_EXITOSO,
  GET_JAO_RESULTADO_ERROR,
  GET_JAO_RESULTADO_CLEAR,
  GET_ACCEDE_SEGUNDO_RETIRO_LOADING,
  GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO,
  GET_ACCEDE_SEGUNDO_RETIRO_ERROR,
  GET_ACCEDE_SEGUNDO_RETIRO_CLEAR,
  SEGUNDO_RETIRO_TYPE_BANK,
  JUBILACION_LEGAL_TYPE_BANK,
  REJA_TYPE_BANK,
} from "../../types/jubilacion";

export default (state, action) => {
  switch (action.type) {
    case ACCEDER_JAO_LOADING:
      return {
        ...state,
        jaoAccessLoading: true,
      };
    case ACCEDER_JAO_CLEAR:
      return {
        ...state,
        jaoAccessData: null,
        jaoAccessLoading: false,
        mensajeAlerta: null,
      };
    case ACCEDER_JAO_EXITOSO:
      return {
        ...state,
        jaoAccessData: action.payload,
        jaoAccessLoading: false,
        mensajeAlerta: null,
      };
    case ACCEDER_JAO_ERROR:
      return {
        ...state,
        jaoAccessData: null,
        jaoAccessLoading: false,
        mensajeAlerta: action.payload,
      };

    case GET_JAO_PROCESS_LOADING:
      return {
        ...state,
        jaoProcessLoading: true,
      };
    case GET_JAO_PROCESS_CLEAR:
      return {
        ...state,
        jaoProcessData: null,
        jaoProcessLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_PROCESS_EXITOSO:
      return {
        ...state,
        jaoProcessData: action.payload,
        jaoProcessLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_PROCESS_ERROR:
      return {
        ...state,
        jaoProcessData: null,
        jaoProcessLoading: false,
        mensajeAlerta: action.payload,
      };

    case GET_JAO_BENEFICIARIES_LOADING:
      return {
        ...state,
        jaoBeneficiariesLoading: true,
      };
    case GET_JAO_BENEFICIARIES_CLEAR:
      return {
        ...state,
        jaoBeneficiariesData: null,
        jaoBeneficiariesLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_BENEFICIARIES_EXITOSO:
      return {
        ...state,
        jaoBeneficiariesData: action.payload,
        jaoBeneficiariesLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_BENEFICIARIES_ERROR:
      return {
        ...state,
        jaoBeneficiariesData: null,
        jaoBeneficiariesLoading: false,
        mensajeAlerta: action.payload,
      };

    case SAVE_JAO_BENEFICIARIES_LOADING:
      return {
        ...state,
        saveJaoBeneficiariesLoading: true,
      };
    case SAVE_JAO_BENEFICIARIES_CLEAR:
      return {
        ...state,
        saveJaoBeneficiariesData: null,
        saveJaoBeneficiariesLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_BENEFICIARIES_EXITOSO:
      return {
        ...state,
        saveJaoBeneficiariesData: action.payload,
        saveJaoBeneficiariesLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_BENEFICIARIES_ERROR:
      return {
        ...state,
        saveJaoBeneficiariesData: null,
        saveJaoBeneficiariesLoading: false,
        mensajeAlerta: action.payload,
      };

    case GET_JAO_SALDOS_LOADING:
      return {
        ...state,
        jaoSaldosLoading: true,
      };
    case GET_JAO_SALDOS_CLEAR:
      return {
        ...state,
        jaoSaldosData: null,
        jaoSaldosLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_SALDOS_EXITOSO:
      return {
        ...state,
        jaoSaldosData: action.payload,
        jaoSaldosLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_SALDOS_ERROR:
      return {
        ...state,
        jaoSaldosData: null,
        jaoSaldosLoading: false,
        mensajeAlerta: action.payload,
      };

    case SAVE_JAO_SALDOS_LOADING:
      return {
        ...state,
        saveJaoSaldosLoading: true,
      };
    case SAVE_JAO_SALDO_CLEAR:
      return {
        ...state,
        saveJaoSaldosData: null,
        saveJaoSaldosLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_SALDOS_EXITOSO:
      return {
        ...state,
        saveJaoSaldosData: action.payload,
        saveJaoSaldosLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_SALDOS_ERROR:
      return {
        ...state,
        saveJaoSaldosData: null,
        saveJaoSaldosLoading: false,
        mensajeAlerta: action.payload,
      };

    case SAVE_JAO_AVISO_LOADING:
      return {
        ...state,
        saveJaoAvisoLoading: true,
      };
    case SAVE_JAO_AVISO_CLEAR:
      return {
        ...state,
        saveJaoAvisoData: null,
        saveJaoAvisoLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_AVISO_EXITOSO:
      return {
        ...state,
        saveJaoAvisoData: action.payload,
        saveJaoAvisoLoading: false,
        mensajeAlerta: null,
      };
    case SAVE_JAO_AVISO_ERROR:
      return {
        ...state,
        saveJaoAvisoData: null,
        saveJaoAvisoLoading: false,
        mensajeAlerta: action.payload,
      };

    case GET_JAO_RESULTADO_LOADING:
      return {
        ...state,
        jaoResultadoLoading: true,
      };
    case GET_JAO_RESULTADO_CLEAR:
      return {
        ...state,
        jaoResultadoData: null,
        jaoResultadoLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_RESULTADO_EXITOSO:
      return {
        ...state,
        jaoResultadoData: action.payload,
        jaoResultadoLoading: false,
        mensajeAlerta: null,
      };
    case GET_JAO_RESULTADO_ERROR:
      return {
        ...state,
        jaoResultadoData: null,
        jaoResultadoLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_PROCESO_JUBILACION_LOADIN:
      return {
        ...state,
        jubilacionDataLoading: true,
      };
    case OBTENER_PROCESO_JUBILACION_CLEANER:
      return {
        ...state,
        jubilacionData: null,
        jubilacionDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_JUBILACION_EXITOSO:
      return {
        ...state,
        jubilacionData: action.payload,
        jubilacionDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_JUBILACION_ERROR:
      return {
        ...state,
        jubilacionData: null,
        jubilacionDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case GET_ACCEDE_SEGUNDO_RETIRO_LOADING:
      return {
        ...state,
        accedeSegundoRetiroDataLoading: true,
      };
    case GET_ACCEDE_SEGUNDO_RETIRO_CLEAR:
      return {
        ...state,
        accedeSegundoRetiroData: null,
        accedeSegundoRetiroDataLoading: false,
        mensajeAlerta: null,
      };
    case GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO:
      return {
        ...state,
        accedeSegundoRetiroData: action.payload,
        accedeSegundoRetiroDataLoading: false,
        mensajeAlerta: null,
      };
    case GET_ACCEDE_SEGUNDO_RETIRO_ERROR:
      return {
        ...state,
        accedeSegundoRetiroData: null,
        accedeSegundoRetiroDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_PROCESO_LEGAL_LOADIN:
      return {
        ...state,
        legalDataLoading: true,
      };
    case OBTENER_PROCESO_LEGAL_CLEANER:
      return {
        ...state,
        legalData: null,
        legalDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_LEGAL_EXITOSO:
      return {
        ...state,
        legalData: action.payload,
        legalDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_LEGAL_ERROR:
      return {
        ...state,
        legalData: null,
        legalDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_LEGAL_LOADIN:
      return {
        ...state,
        createLegalDataLoading: true,
      };
    case CREAR_PROCESO_LEGAL_CLEANER:
      return {
        ...state,
        createLegalData: null,
        createLegalDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_LEGAL_EXITOSO:
      return {
        ...state,
        createLegalData: action.payload,
        createLegalDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_LEGAL_ERROR:
      return {
        ...state,
        createLegalData: null,
        createLegalDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN:
      return {
        ...state,
        contenidoTemaJubilacionLoading: true,
      };
    case OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER:
      return {
        ...state,
        contenidoTemaJubilacionData: null,
        contenidoTemaJubilacionLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO:
      return {
        ...state,
        contenidoTemaJubilacionData: action.payload,
        contenidoTemaJubilacionLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_CONTENIDO_PASO_JUBILACION_ERROR:
      return {
        ...state,
        contenidoTemaJubilacionData: null,
        contenidoTemaJubilacionLoading: false,
        mensajeAlerta: action.payload,
      };

    case GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN:
      return {
        ...state,
        guardarAvanceJuilacionLoading: true,
      };
    case GUARDAR_AVANCE_TRAMITE_JUBILACION_CLEANER:
      return {
        ...state,
        guardarAvanceJuilacionData: null,
        guardarAvanceJuilacionLoading: false,
        mensajeAlerta: null,
      };
    case GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO:
      return {
        ...state,
        guardarAvanceJuilacionData: action.payload,
        guardarAvanceJuilacionLoading: false,
        mensajeAlerta: null,
      };
    case GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR:
      return {
        ...state,
        guardarAvanceJuilacionData: null,
        guardarAvanceJuilacionLoading: false,
        mensajeAlerta: action.payload,
      };

    case REJA_SGA_VALIDAR_PENSION_LOADING:
      return {
        ...state,
        rejaSgaValidarLoading: true,
      };
    case REJA_SGA_VALIDAR_PENSION_CLEANER:
      return {
        ...state,
        rejaSgaValidarData: null,
        rejaSgaValidarLoading: false,
        mensajeAlerta: null,
      };
    case REJA_SGA_VALIDAR_PENSION_EXITOSO:
      return {
        ...state,
        rejaSgaValidarData: action.payload,
        rejaSgaValidarLoading: false,
        mensajeAlerta: null,
      };
    case REJA_SGA_VALIDAR_PENSION_ERROR:
      return {
        ...state,
        rejaSgaValidarData: null,
        rejaSgaValidarLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_PROCESO_REJA_LOADIN:
      return {
        ...state,
        rejaDataLoading: true,
      };
    case OBTENER_PROCESO_REJA_CLEANER:
      return {
        ...state,
        rejaData: null,
        rejaDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_REJA_EXITOSO:
      return {
        ...state,
        rejaData: action.payload,
        rejaDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PROCESO_REJA_ERROR:
      return {
        ...state,
        rejaData: null,
        rejaDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_REJA_LOADIN:
      return {
        ...state,
        createRejaDataLoading: true,
      };
    case CREAR_PROCESO_REJA_CLEANER:
      return {
        ...state,
        createRejaData: null,
        createRejaDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_REJA_EXITOSO:
      return {
        ...state,
        createRejaData: action.payload,
        createRejaDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_REJA_ERROR:
      return {
        ...state,
        createRejaData: null,
        createRejaDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA1_LOADIN:
      return {
        ...state,
        guardarPaso1ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA1_CLEANER:
      return {
        ...state,
        guardarPaso1Response: null,
        guardarPaso1ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA1_EXITOSO:
      return {
        ...state,
        guardarPaso1Response: action.payload,
        guardarPaso1ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA1_ERROR:
      return {
        ...state,
        guardarPaso1Response: null,
        guardarPaso1ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_TEMAS_ASESORIA_REJA_LOADIN:
      return {
        ...state,
        temasAsesoriaRejaLoading: true,
      };
    case OBTENER_TEMAS_ASESORIA_REJA_CLEANER:
      return {
        ...state,
        temasAsesoriaRejaData: null,
        temasAsesoriaRejaLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_TEMAS_ASESORIA_REJA_EXITOSO:
      return {
        ...state,
        temasAsesoriaRejaData: action.payload,
        temasAsesoriaRejaLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_TEMAS_ASESORIA_REJA_ERROR:
      return {
        ...state,
        temasAsesoriaRejaData: null,
        temasAsesoriaRejaLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA2_LOADIN:
      return {
        ...state,
        guardarPaso2ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA2_CLEANER:
      return {
        ...state,
        guardarPaso2Response: null,
        guardarPaso2ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA2_EXITOSO:
      return {
        ...state,
        guardarPaso2Response: action.payload,
        guardarPaso2ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA2_ERROR:
      return {
        ...state,
        guardarPaso2Response: null,
        guardarPaso2ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA3_LOADIN:
      return {
        ...state,
        guardarPaso3ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA3_CLEANER:
      return {
        ...state,
        guardarPaso3Response: null,
        guardarPaso3ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA3_EXITOSO:
      return {
        ...state,
        guardarPaso3Response: action.payload,
        guardarPaso3ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA3_ERROR:
      return {
        ...state,
        guardarPaso3Response: null,
        guardarPaso3ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_MARCO_BONO_LOADIN:
      return {
        ...state,
        marcoBonoDataLoading: true,
      };
    case OBTENER_MARCO_BONO_CLEANER:
      return {
        ...state,
        marcoBonoData: null,
        marcoBonoDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_MARCO_BONO_EXITOSO:
      return {
        ...state,
        marcoBonoData: action.payload,
        marcoBonoDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_MARCO_BONO_ERROR:
      return {
        ...state,
        marcoBonoData: null,
        marcoBonoDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA4_LOADIN:
      return {
        ...state,
        guardarPaso4ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA4_CLEANER:
      return {
        ...state,
        guardarPaso4Response: null,
        guardarPaso4ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA4_EXITOSO:
      return {
        ...state,
        guardarPaso4Response: action.payload,
        guardarPaso4ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA4_ERROR:
      return {
        ...state,
        guardarPaso4Response: null,
        guardarPaso4ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_PARENTESCOS_REJA_LOADIN:
      return {
        ...state,
        relationshipsLoading: true,
      };
    case OBTENER_PARENTESCOS_REJA_CLEANER:
      return {
        ...state,
        relationshipsData: null,
        relationshipsLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PARENTESCOS_REJA_EXITOSO:
      return {
        ...state,
        relationshipsData: action.payload,
        relationshipsLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PARENTESCOS_REJA_ERROR:
      return {
        ...state,
        relationshipsData: null,
        relationshipsLoading: false,
        mensajeAlerta: action.payload,
      };

    case GUARDAR_PASO_DOS_REJA_LOADIN:
      return {
        ...state,
        saveStepTwoDataLoading: true,
      };
    case GUARDAR_PASO_DOS_REJA_CLEANER:
      return {
        ...state,
        saveStepTwoData: null,
        saveStepTwoDataLoading: false,
        mensajeAlerta: null,
      };
    case GUARDAR_PASO_DOS_REJA_EXITOSO:
      return {
        ...state,
        saveStepTwoData: action.payload,
        saveStepTwoDataLoading: false,
        mensajeAlerta: null,
      };
    case GUARDAR_PASO_DOS_REJA_ERROR:
      return {
        ...state,
        saveStepTwoData: null,
        saveStepTwoDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case REJA_ESTIMACION_LOADING:
      return {
        ...state,
        estimacionRejaLoading: true,
      };
    case REJA_ESTIMACION_CLEANER:
      return {
        ...state,
        estimacionRejaData: null,
        estimacionRejaLoading: false,
        mensajeAlerta: null,
      };

    case RETIRO_RESETEAR:
      return {
        ...state,
        mensajeAlerta: null,
        estimacionRejaLoading: false,
        estimacionPDFRejaLoading: false,
        datosResultadoRetiroApvSinFin: null,
        temasAsesoriaRejaData: null,
        temasAsesoriaRejaLoading: false,
      };
    case REJA_ESTIMACION_EXITOSO:
      return {
        ...state,
        estimacionRejaData: action.payload,
        estimacionRejaLoading: false,
        mensajeAlerta: null,
      };
    case REJA_ESTIMACION_ERROR:
      return {
        ...state,
        estimacionRejaData: null,
        estimacionRejaLoading: false,
        mensajeAlerta: action.payload,
      };

    case REJA_PDF_ESTIMACION_LOADING:
      return {
        ...state,
        estimacionPDFRejaLoading: true,
      };
    case REJA_PDF_ESTIMACION_CLEANER:
      return {
        ...state,
        estimacionPDFRejaData: null,
        estimacionPDFRejaLoading: false,
        mensajeAlerta: null,
      };
    case REJA_PDF_ESTIMACION_EXITOSO:
      return {
        ...state,
        estimacionPDFRejaData: action.payload,
        estimacionPDFRejaLoading: false,
        mensajeAlerta: null,
      };
    case REJA_PDF_ESTIMACION_ERROR:
      return {
        ...state,
        estimacionPDFRejaData: null,
        estimacionPDFRejaLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA5_LOADIN:
      return {
        ...state,
        guardarPaso5ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA5_CLEANER:
      return {
        ...state,
        guardarPaso5Response: null,
        guardarPaso5ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA5_EXITOSO:
      return {
        ...state,
        guardarPaso5Response: action.payload,
        guardarPaso5ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA5_ERROR:
      return {
        ...state,
        guardarPaso5Response: null,
        guardarPaso5ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case OBTENER_MARCO_PENSION_LOADIN:
      return {
        ...state,
        marcoPensionDataLoading: true,
      };
    case OBTENER_MARCO_PENSION_CLEANER:
      return {
        ...state,
        marcoPensionData: null,
        marcoPensionDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_MARCO_PENSION_EXITOSO:
      return {
        ...state,
        marcoPensionData: action.payload,
        marcoPensionDataLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_MARCO_PENSION_ERROR:
      return {
        ...state,
        marcoPensionData: null,
        marcoPensionDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA6_LOADIN:
      return {
        ...state,
        guardarPaso6ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA6_CLEANER:
      return {
        ...state,
        guardarPaso6Response: null,
        guardarPaso6ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA6_EXITOSO:
      return {
        ...state,
        guardarPaso6Response: action.payload,
        guardarPaso6ResponseLoading: false,
        mensajeAlerta: null,
      };
    case OBTENER_PASO_6_REJA_DATOS:
      return {
        ...state,
        guardarModalidad: action.payload.modalidad,
        guardarAPVf: action.payload.apvf,
      };

    case CREAR_PROCESO_ETAPA6_ERROR:
      return {
        ...state,
        guardarPaso6Response: null,
        guardarPaso6ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_ETAPA7_LOADIN:
      return {
        ...state,
        guardarPaso7ResponseLoading: true,
      };
    case CREAR_PROCESO_ETAPA7_CLEANER:
      return {
        ...state,
        guardarPaso7Response: null,
        guardarPaso7ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA7_EXITOSO:
      return {
        ...state,
        guardarPaso7Response: action.payload,
        guardarPaso7ResponseLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_ETAPA7_ERROR:
      return {
        ...state,
        guardarPaso7Response: null,
        guardarPaso7ResponseLoading: false,
        mensajeAlerta: action.payload,
      };

    case CREAR_PROCESO_JUBILACION_LOADIN:
      return {
        ...state,
        createJubilacionDataLoading: true,
      };
    case CREAR_PROCESO_JUBILACION_CLEANER:
      return {
        ...state,
        createJubilacionData: null,
        createJubilacionDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_JUBILACION_EXITOSO:
      return {
        ...state,
        createJubilacionData: action.payload,
        createJubilacionDataLoading: false,
        mensajeAlerta: null,
      };
    case CREAR_PROCESO_JUBILACION_ERROR:
      return {
        ...state,
        createJubilacionData: null,
        createJubilacionDataLoading: false,
        mensajeAlerta: action.payload,
      };

    case ACTUALIZAR_PROCESO_JUBILACION_LOADIN:
      return {
        ...state,
        updateProcessLoading: true,
      };
    case ACTUALIZAR_PROCESO_JUBILACION_CLEANER:
      return {
        ...state,
        updateProcessData: null,
        updateProcessLoading: false,
        mensajeAlerta: null,
      };
    case ACTUALIZAR_PROCESO_JUBILACION_EXITOSO:
      return {
        ...state,
        updateProcessData: action.payload,
        updateProcessLoading: false,
        mensajeAlerta: null,
      };
    case ACTUALIZAR_PROCESO_JUBILACION_ERROR:
      return {
        ...state,
        updateProcessData: null,
        updateProcessLoading: false,
        mensajeAlerta: action.payload,
      };

    case JUBILACION_CLEANER:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoJubilacionPost: null,
        mensajeAlertaJubilacionPost: null,
        loadingBtnJubilacionPost: false,
        opcionesMenu: null,
      };
    case JUBILACION_CLEANER_PARCIAL:
      return {
        ...state,
        /**Actualizar Datos*/
        resultadoDatosPost: null,
        mensajeAlertaDatosPost: null,
        loadingBtnDatosPost: false,
        opcionesMenu: null,
      };
    case JUBILACION_POST_EXITOSO:
      return {
        ...state,
        resultadoJubilacionPost: action.payload,
        mensajeAlertaJubilacionPost: null,
        loadingBtnJubilacionPost: false,
      };
    case JUBILACION_POST_LOADIN:
      return {
        ...state,
        loadingBtnJubilacionPost: true,
        mensajeAlertaJubilacionPost: null,
      };
    case JUBILACION_POST_ERROR:
      return {
        ...state,
        mensajeAlertaJubilacionPost: action.payload,
        loadingBtnJubilacionPost: false,
      };
    case MENU_DATA:
      sessionStorage.setItem("opcionesMenu", JSON.stringify(action.payload));

      return {
        ...state,
        opcionesMenu: action.payload,
      };
    case SEGUNDO_RETIRO_TYPE_BANK:
      return {
        ...state,
        typeBankSR: action.payload,
      };
    case JUBILACION_LEGAL_TYPE_BANK:
      return {
        ...state,
        typeBankJL: action.payload,
      };
    case REJA_TYPE_BANK:
      return {
        ...state,
        typeBankJR: action.payload,
      };
    default:
      break;
  }
};
