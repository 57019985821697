const getDocumentName = (documentCode) => {
  if (!documentCode) {
    return "";
  }

  const documentOptions = {
    "00": "DNI",
    "01": "Carné de extranjería",
    "03": "Lib. Adolesc. Trab",
    "04": "Pasaporte",
  };

  return documentOptions[documentCode] || "Documento";
};

export default getDocumentName;
