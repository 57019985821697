import React from "react";

export default function HomeIcon({ size = 18, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 10"
      width={size}
      height={size}
      fill="none"
      stroke={color}
    >
      <path
        d="M1.14597 5.41248L5.50003 0.875L9.85418 5.41248L8.80003 4.31395V8.39167C8.80003 8.58616 8.72277 8.77269 8.58524 8.91021C8.44772 9.04774 8.26119 9.125 8.0667 9.125H2.93337C2.73887 9.125 2.55235 9.04774 2.41482 8.91021C2.2773 8.77269 2.20003 8.58616 2.20003 8.39167V4.32007L1.14597 5.41248Z"
        stroke="#363A3E"
        strokeWidth="0.914375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
