import React from "react";
import { Box, Chip, Typography, Card } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CardFondoSelected = (props) => {
  const { fondoActual, fondoDestino } = props;

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "500px" }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            fontSize: "10px",
            borderRadius: "16px",
            maxHeight: "185px",
            p: "16px",
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItem: "center",
            }}
          >
            {
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Chip
                  label={
                    <Typography sx={{ fontSize: "10px" }}>
                      FONDO ACTUAL
                    </Typography>
                  }
                  size="small"
                  sx={{ p: "8px 10px" }}
                />
                <Typography sx={{ fontSize: "12px" }}>
                  &nbsp; Fondo {fondoActual.r_fondo_origen}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  &nbsp;
                  {fondoActual.r_fondo_origen === "9" && "Protegido"}
                  {fondoActual.r_fondo_origen === "0" && "Protegido"}
                  {fondoActual.r_fondo_origen === "1" && "Conservador"}
                  {fondoActual.r_fondo_origen === "2" && "Moderado"}
                  {fondoActual.r_fondo_origen === "3" && "Arriesgado"}
                </Typography>
              </Box>
            }
            {fondoDestino !== "" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#CDCED0",
                    width: "40px",
                  }}
                >
                  <ArrowForwardIcon />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Chip
                    label={
                      <Typography sx={{ fontSize: "10px" }}>
                        FONDO DESTINO
                      </Typography>
                    }
                    size="small"
                    sx={{
                      p: "4px 10px",
                      backgroundColor: "#CE1336",
                      color: "white",
                    }}
                  />
                  <Typography sx={{ fontSize: "1.2em" }}>
                    &nbsp; Fondo {fondoDestino === 9 ? 0 : fondoDestino}
                  </Typography>
                  <Typography sx={{ fontSize: "16px" }}>
                    &nbsp;
                    {Number(fondoDestino) === 9 && "Protegido"}
                    {Number(fondoDestino) === 0 && "Protegido"}
                    {Number(fondoDestino) === 1 && "Conservador"}
                    {Number(fondoDestino) === 2 && "Moderado"}
                    {Number(fondoDestino) === 3 && "Arriesgado"}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default CardFondoSelected;
