import React, { useReducer } from "react";
import DetalleProductoSppContext from "./detalleProductoSppContext";
import DetalleProductoSppReducer from "./detalleProductoSppReducer";
import clienteAxios from "../../config/axios";
import {
  DETALLE_PRODUCTO_SPP_EXITOSO,
  DETALLE_PRODUCTO_SPP_ERROR,
  DETALLE_PRODUCTO_SPP_LOADIN,
  DETALLE_PRODUCTO_SPP_CLEANER,
  DETALLE_PRODUCTO_SPP_CLEANER_PARCIAL,
} from "../../types/detalleProductoSpp";

const DetalleProductoSppState = (props) => {
  const initialState = {
    datosDetalleProductoSpp: null,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosDetalleProductoSpp = async (product_type) => {
    dispatch({
      type: DETALLE_PRODUCTO_SPP_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get(`/detalle-producto-spp?product_type=${product_type}`);
      dispatch({
        type: DETALLE_PRODUCTO_SPP_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, DETALLE_PRODUCTO_SPP_ERROR);
    }
  };

  const limpiarVariablesDetalleProductoSpp = () => {
    dispatch({
      type: DETALLE_PRODUCTO_SPP_CLEANER,
    });
  };
  const limpiarVariablesDetalleProductoSppParcial = () => {
    dispatch({
      type: DETALLE_PRODUCTO_SPP_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(DetalleProductoSppReducer, initialState);
  return (
    <DetalleProductoSppContext.Provider
      value={{
        datosDetalleProductoSpp: state.datosDetalleProductoSpp,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        obtenerDatosDetalleProductoSpp,
        limpiarVariablesDetalleProductoSpp,
        limpiarVariablesDetalleProductoSppParcial,
      }}
    >
      {props.children}
    </DetalleProductoSppContext.Provider>
  );
};

export default DetalleProductoSppState;
