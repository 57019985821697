import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import HomeContext from "../../../../../../context/home/homeContext";

import useWindowSize from "../../../../../../hooks/useWindowSize";
import { size } from "../../../../../../utils/device";

import Modal from "./CustomModal";
import AccordionDesktop from "../AccordionDesktop";
import AccordionMobile from "../AccordionMobile";

const objComplete = {
  item1: false,
  open1: true,
  item2: false,
  open2: false,
};
const ModalActualizarDatos = ({
  isOpen,
  setIsOpen,
  data,
  decisionValidarPmo,
}) => {
  const { getValues, setValue, reset } = useFormContext();
  const [expanded, setExpanded] = useState("panel1");
  const [amountPension, setAMountPension] = useState(0);
  const homeContext = useContext(HomeContext);

  const { width } = useWindowSize();
  const isSmallScreen = width <= size.desktopS;
  const { fondos, obtenerDatosHome } = homeContext;

  const handleChange = (panel) => {
    setExpanded(panel);
  };

  const closeModal = () => setIsOpen(false);
  useEffect(() => {
    //  actualizarTimer();
    window.scrollTo(0, 0);
    obtenerDatosHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearData = () => {
    setValue("calculo.amount", "");
    setValue("calculo.conFin", "");
    setValue("calculo.fondoLibre", "");
    setValue("calculo.fondoJub", "");
  };
  const handleCalcular = () => {
    let OBL = "OBL";
    if (getValues("calculo.conFin") === "true") {
      OBL = "OBL,VCF";
    }
    if (getValues("calculo.fondoLibre") === "true") {
      OBL = "OBL,VSF";
    }
    if (
      getValues("calculo.conFin") === "true" &&
      getValues("calculo.fondoLibre") === "true"
    ) {
      OBL = "OBL,VSF,VCF";
    }
    let dato = {
      pension: getValues("calculo.amount") ? getValues("calculo.amount") : "",
      productos: OBL,
    };

    decisionValidarPmo(dato);
    closeModal();
    reset();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Pensión Minima"
      onClickBtnPrimary={() => handleCalcular()}
      textBtnPrimary={"Calcular"}
      isBtnSecondary={true}
      onClickBtnSecondary={closeModal}
      textBtnSecondary={"Cancelar"}
      bodyProps={{ maxWidth: "637px" }}
    >
      <Grid xl={12} sm={12} xs={12}>
        {isSmallScreen ? (
          <AccordionMobile
            handleChange={handleChange}
            expanded={expanded}
            setExpanded={setExpanded}
            amounPension={Number(data.cbc)}
            setAMountPension={setAMountPension}
            fondos={fondos}
            objComplete={objComplete}
            data={data}
          />
        ) : (
          <AccordionDesktop
            handleChange={handleChange}
            expanded={expanded}
            setExpanded={setExpanded}
            amounPension={Number(data.cbc)}
            setAMountPension={setAMountPension}
            fondos={fondos}
            objComplete={objComplete}
            data={data}
          />
        )}
      </Grid>
    </Modal>
  );
};

export default ModalActualizarDatos;
