import React, {useReducer} from 'react';
import ModalContext from './modalContext';
import ModalReducer from './modalReducer';


import {
    HOME_LAYOUT_MODAL,
    HOME_CLEAR_LAYOUT_MODAL,
} from '../../types';

const ModalState = props => {
    const initialState = {
        status: false,
        message: null,
    };

    const setMessage = (msg) => {
        dispatch({
            type:HOME_LAYOUT_MODAL,
            payload: {
                message: msg,
                status: true
            }
        })
    }
    const limpiarVariablesModal = () => {
        dispatch({
            type:HOME_CLEAR_LAYOUT_MODAL,
        })
    }

    const [state, dispatch] = useReducer(ModalReducer, initialState);
    return (
        <ModalContext.Provider
            value={{
                status: state.status,
                message: state.message,
                setMessage,
                limpiarVariablesModal
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
};

export default ModalState;