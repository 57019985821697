import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";

const yellowColor = "FFFF00";
const lightBlueColor = "E1EFFF";
const darkBlueColor = "006CEE";

const leftAlignment = { vertical: "middle", horizontal: "left" };
const centerAlignment = { vertical: "middle", horizontal: "center" };
const rightAlignment = { vertical: "middle", horizontal: "right" };

const defaultBackground = (color) => {
  return {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: color },
    bgColor: { argb: color },
  };
};

const topBorder = {
  top: { style: "thin", color: { argb: darkBlueColor } },
};
const bottomBorder = {
  bottom: { style: "thin", color: { argb: darkBlueColor } },
};

export default function makeCustomExcel(data, userInfo) {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Habitat";

  const worksheet = workbook.addWorksheet("Reporte Deuda");

  const rowInit = 1;
  let row = rowInit;

  const columnA = worksheet.getColumn(1);
  columnA.width = 20;
  const columnB = worksheet.getColumn(2);
  columnB.width = 30;
  const columnC = worksheet.getColumn(3);
  columnC.width = 20;
  const columnD = worksheet.getColumn(4);
  columnD.width = 15;
  const columnE = worksheet.getColumn(5);
  columnE.width = 20;
  const columnF = worksheet.getColumn(6);
  columnF.width = 20;
  const columnG = worksheet.getColumn(7);
  columnG.width = 20;
  const columnH = worksheet.getColumn(8);
  columnH.width = 20;
  const columnI = worksheet.getColumn(9);
  columnI.width = 20;
  const columnJ = worksheet.getColumn(10);
  columnJ.width = 20;
  const columnK = worksheet.getColumn(11);
  columnK.width = 20;
  const columnL = worksheet.getColumn(12);
  columnL.width = 20;
  const columnM = worksheet.getColumn(13);
  columnM.width = 20;
  const columnN = worksheet.getColumn(14);
  columnN.width = 20;
  const columnO = worksheet.getColumn(15);
  columnO.width = 20;

  /* Date */
  worksheet.mergeCells(`A${row}:B${row}`);
  worksheet.getCell(`A${row}`).value = "REPORTADO POR: ";
  worksheet.getCell(`A${row}`).alignment = centerAlignment;
  worksheet.getCell(`C${row}`).value = "AFP HABITAT";
  worksheet.getCell(`C${row}`).alignment = {
    vertical: "center",
    horizontal: "right",
  };
  row++;

  worksheet.mergeCells(`A${row}:B${row}`);
  worksheet.getCell(`A${row}`).value = "FECHA DEL REPORTE";
  worksheet.getCell(`A${row}`).alignment = centerAlignment;
  worksheet.getCell(`C${row}`).value = getFormatterDate(new Date());
  worksheet.getCell(`C${row}`).alignment = {
    vertical: "center",
    horizontal: "right",
  };
  row++;
  row++;
  worksheet.mergeCells(`A${row}:F${row}`);
  worksheet.getCell(`A${row}`).value = "DATOS DEL AFILIADO";
  worksheet.getCell(`A${row}`).alignment = centerAlignment;
  worksheet.getCell(`A${row}`).fill = defaultBackground(lightBlueColor);
  row++;
  // worksheet.getCell(`C${row}`).fill = defaultBackground(yellowColor);

  /* Date */
  worksheet.addRow([
    "CUSPP",
    "TIPO DE DOCUMENTO",
    "N° DE DOCUMENTO",
    "APELLIDO PATERNO",
    "APELLIDO MATERNO",
    "NOMBRES",
  ]);
  worksheet.getRow(row).alignment = centerAlignment;
  row++;

  worksheet.getCell(`A${row}`).value = userInfo.cuspp;
  worksheet.getCell(`A${row}`).alignment = centerAlignment;
  worksheet.getCell(`B${row}`).value =
    userInfo.doc_type == "00"
      ? "DNI"
      : userInfo.doc_type == "01"
      ? "CE"
      : userInfo.doc_type == "03"
      ? "Lib. Adolesc. Trab"
      : "PAS";
  worksheet.getCell(`B${row}`).alignment = centerAlignment;
  worksheet.getCell(`C${row}`).value = userInfo.doc_num;
  worksheet.getCell(`C${row}`).alignment = centerAlignment;
  worksheet.getCell(`D${row}`).value = userInfo.apellido_paterno;
  worksheet.getCell(`D${row}`).alignment = centerAlignment;
  worksheet.getCell(`E${row}`).value = userInfo.apellido_materno;
  worksheet.getCell(`E${row}`).alignment = centerAlignment;
  worksheet.getCell(`F${row}`).value = userInfo.nombres;
  worksheet.getCell(`F${row}`).alignment = centerAlignment;

  row++;

  worksheet.addRow([]);
  row++;
  worksheet.addRow(["ESTADO_DETALLE:", "ACTIVO"]);
  worksheet.getCell(`A${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`B${row}`).fill = defaultBackground(lightBlueColor);
  row++;
  worksheet.addRow([]);
  row++;
  /* building a table*/
  let arrayDataObject = groupBy(data, "r_ruc");
  for (let property in arrayDataObject) {
    addHeaderRow(worksheet, row, defaultBackground());
    row++;
    for (let valueRow of arrayDataObject[property]) {
      addValueRow(worksheet, row, valueRow);
      row++;
    }
    addFooterRow(worksheet, row, arrayDataObject[property]);
    row++;
    worksheet.addRow([]);
    row++;
  }
  worksheet.mergeCells(`A${row}:C${row}`);
  worksheet.getCell(`A${row}`).value =
    "C = Cierta / P = Presunta / J = Judicial / A = Administrativa";
  worksheet.getCell(`A${row}`).alignment = leftAlignment;
  row++;
  worksheet.mergeCells(`A${row}:G${row}`);
  worksheet.getCell(`A${row}`).value =
    "*Recuerda que la falta de aportes podría ocasionar que no cuentes con la cobertura del Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio";
  worksheet.getCell(`A${row}`).alignment = leftAlignment;
  row++;
  return workbook;
}

function addHeaderRow(worksheet, row) {
  worksheet.addRow([
    "AFP",
    "RUC",
    "RAZON_SOCIAL",
    "PERIODO",
    "TIPO_DEUDA",
    "TIPO_COBRANZA",
    "FONDO NOMINAL",
    "RyR NOMINAL",
    "TOTAL NOMINAL",
  ]);
  worksheet.getRow(row).alignment = centerAlignment;
  worksheet.getCell(`A${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`A${row}`).border = bottomBorder;
  worksheet.getCell(`B${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`B${row}`).border = bottomBorder;
  worksheet.getCell(`C${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`C${row}`).border = bottomBorder;
  worksheet.getCell(`D${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`D${row}`).border = bottomBorder;
  worksheet.getCell(`E${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`E${row}`).border = bottomBorder;
  worksheet.getCell(`F${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`F${row}`).border = bottomBorder;
  worksheet.getCell(`G${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`G${row}`).border = bottomBorder;
  worksheet.getCell(`H${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`H${row}`).border = bottomBorder;
  worksheet.getCell(`I${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`I${row}`).border = bottomBorder;
}

function addValueRow(worksheet, row, valueRow) {
  worksheet.getCell(`A${row}`).value =
    valueRow.r_afp === "HA"
      ? "HABITAT"
      : valueRow.r_afp === "IN"
      ? "INTEGRA"
      : valueRow.r_afp === "PR"
      ? "PROFUTURO"
      : "PRIMA";
  worksheet.getCell(`A${row}`).alignment = rightAlignment;
  worksheet.getCell(`B${row}`).value = valueRow.r_ruc;
  worksheet.getCell(`B${row}`).alignment = rightAlignment;
  worksheet.getCell(`C${row}`).value = valueRow.r_razon_social;
  worksheet.getCell(`C${row}`).alignment = leftAlignment;
  worksheet.getCell(`D${row}`).value = valueRow.r_periodo;
  worksheet.getCell(`D${row}`).alignment = leftAlignment;
  worksheet.getCell(`E${row}`).value = valueRow.r_tipo_deuda;
  worksheet.getCell(`E${row}`).alignment = centerAlignment;
  worksheet.getCell(`F${row}`).value = valueRow.r_estado_gestion;
  worksheet.getCell(`F${row}`).alignment = leftAlignment;
  worksheet.getCell(`G${row}`).value = toMoney(valueRow.r_fondo_nominal);
  worksheet.getCell(`G${row}`).alignment = rightAlignment;
  worksheet.getCell(`H${row}`).value = toMoney(valueRow.r_ryr_nominal);
  worksheet.getCell(`H${row}`).alignment = rightAlignment;
  worksheet.getCell(`I${row}`).value = toMoney(valueRow.r_total_nominal);
  worksheet.getCell(`I${row}`).alignment = rightAlignment;
}

function addFooterRow(worksheet, row, array) {
  worksheet.mergeCells(`A${row}:F${row}`);
  worksheet.getCell(`A${row}`).value = "Total";
  worksheet.getCell(`A${row}`).alignment = leftAlignment;
  worksheet.getCell(`A${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`A${row}`).border = topBorder;

  worksheet.getCell(`G${row}`).value = toMoney(
    sumArrayProperty(array, "r_fondo_nominal")
  );
  worksheet.getCell(`G${row}`).alignment = rightAlignment;
  worksheet.getCell(`G${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`G${row}`).border = topBorder;
  worksheet.getCell(`H${row}`).value = toMoney(
    sumArrayProperty(array, "r_ryr_nominal")
  );
  worksheet.getCell(`H${row}`).alignment = rightAlignment;
  worksheet.getCell(`H${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`H${row}`).border = topBorder;
  worksheet.getCell(`I${row}`).value = toMoney(
    sumArrayProperty(array, "r_total_nominal")
  );
  worksheet.getCell(`I${row}`).alignment = rightAlignment;
  worksheet.getCell(`I${row}`).fill = defaultBackground(lightBlueColor);
  worksheet.getCell(`I${row}`).border = topBorder;
}

function getFormatterDate(date) {
  if (!(date instanceof Date)) return "--/--/--";
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (month < 10) {
    return `${day}-0${month}-${year}`;
  } else {
    return `${day}-${month}-${year}`;
  }
}

function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

function sumArrayProperty(array, property) {
  return array.reduce((a, b) => Number(a) + (Number(b[property]) || 0), 0);
}

function toMoney(amount) {
  if (amount == null || isNaN(amount)) return "0.00";

  try {
    var decimalCount = 2;
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const decimals = ".";
    const thousands = ",";
    const negativeSign = amount < 0 ? "-" : "";
    amount = Math.abs(Number(amount));
    let i = parseInt((amount || 0).toFixed(decimalCount)).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return "S/ " +
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimals +
        Math.abs(amount - parseInt(i))
          .toFixed(decimalCount)
          .slice(2)
      : "");
  } catch (error) {
    return "";
  }
}
