import React, { useState, useEffect, useContext } from "react";
import { VistaEspera } from "../../../jubilacionReja/etapas/components";
import { SelectMode } from "./components";
import Loading from "../../../../../../components/Loading/index.jsx";
import sgvAdvice from "../../../../../../assets/recuerda.svg";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

export default function Paso5(props) {
  const [showView, setShowView] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [dependencies, setDependencies] = useState({
    contribution: {},
    meet_the_time_in_spp: false,
  });

  const jubilacionContext = useContext(JubilacionContext);
  const {
    contenidoTemaJubilacionLoading,
    contenidoTemaJubilacionData,
    obtenerContenidoTemaJubilacion,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerContenidoTemaJubilacion(props.processId, 5);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  useEffect(() => {
    if (contenidoTemaJubilacionData) {
      if (contenidoTemaJubilacionData.success) {
        props.setCurrectStepTitle(contenidoTemaJubilacionData.name);
        setDependencies(contenidoTemaJubilacionData.dependencies);
        setShowView(1);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contenidoTemaJubilacionData]);

  if (mensajeAlerta !== null) return <p></p>;
  if (contenidoTemaJubilacionLoading) return <Loading />;

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <VistaEspera
            imgLogo={sgvAdvice}
            title="Importante"
            description={
              <>
                Lee con cuidado toda la información y completa correctamente
                cada detalle para poder tramitar con éxito tu retiro y/o
                pensión.
              </>
            }
            btnLabel="Continuar"
            btnFunction={() => setShowView(2)}
          />
        );
      case 2:
        return (
          <SelectMode
            endFifthStep={() => props.setCurrectStepNumber(6)}
            getOutOfProcess={() => props.getOutOfProcess()}
            dependencies={dependencies}
            setModalidad={props.setModalidad}
            setApvf={props.setApvf}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
