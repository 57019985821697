import {
  DRSPP_EXITOSO,
  DRSPP_ERROR,
  DRSPP_LOADIN,
  DRSPP_CLEANER,
  DRSPP_CLEANER_PARCIAL,
} from "../../types/drspp";

export default (state, action) => {
  switch (action.type) {
    case DRSPP_EXITOSO:
      return {
        ...state,
        datosDrspp: action.payload,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case DRSPP_LOADIN:
      return {
        ...state,
        loadingPage: true,
      };
    case DRSPP_ERROR:
      return {
        ...state,
        mensajeAlerta: action.payload,
        datosDrspp: null,
        loadingPage: false,
      };
    case DRSPP_CLEANER:
      return {
        ...state,
        datosDrspp: null,
        mensajeAlerta: null,
        loadingPage: false,
      };
    case DRSPP_CLEANER_PARCIAL:
      return {
        ...state,
        datosDrspp: null,
        loadingPage: false,
      };
    default:
      break;
  }
};
