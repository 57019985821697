import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BoxDate, BoxButton, MoneyInputComponent } from "./styles";
import Button from "../../components/Buttons";
import Excel from "./Excel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const FeeValue = ({ content, year, month, isLoading, value }) => {
  const getMonth = (num) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    return months[num - 1];
  };
  const handlePdf = () => {
    window.location = `https://www.afphabitat.com.pe/privado/consulta/pdf_cartola/${month}-${year}`;
  };
  return (
    <TabPanel value={value} index={0}>
      <Box>
        <BoxButton>
          <Button onClick={handlePdf} text="PDF" size={"sm"}>
            PDF
          </Button>
          <Excel
            content={content}
            month={month}
            year={year}
            button={
              <Button text="XLS" size={"sm"}>
                XLS
              </Button>
            }
          />
        </BoxButton>
        <BoxDate>
          <h2>
            Valores Cuota {getMonth(month)} - {year}
          </h2>
          <h6>Fecha: {content.date}</h6>
        </BoxDate>
        <MoneyInputComponent component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-center text-uppercase">
                  Día
                </TableCell>
                <TableCell className="text-center text-uppercase">
                  Fondo 0
                </TableCell>
                <TableCell className="text-center text-uppercase">
                  Fondo 1
                </TableCell>
                <TableCell className="text-center text-uppercase">
                  Fondo 2
                </TableCell>
                <TableCell className="text-center text-uppercase">
                  Fondo 3
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content.rows.map((row) => {
                const allFundsHaveData = [
                  row.fund1,
                  row.fund2,
                  row.fund3,
                ].every((e) => !["S/ 0", "S/ 0.0000000"].includes(e));
                return (
                  allFundsHaveData && (
                    <TableRow key={row.day}>
                      <TableCell className="text-center">{row.day}</TableCell>
                      <TableCell className="text-center">{row.fund0}</TableCell>
                      <TableCell className="text-center">{row.fund1}</TableCell>
                      <TableCell className="text-center">{row.fund2}</TableCell>
                      <TableCell className="text-center">{row.fund3}</TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>{" "}
        </MoneyInputComponent>
      </Box>
    </TabPanel>
  );
};

export default FeeValue;
