import React from "react";

const QuickReferenceAll = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_457_37970"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_457_37970)">
        <path
          d="M8 7V21.65V21.5V41V7ZM14.45 27H22.2C22.5667 26.4333 22.9667 25.9 23.4 25.4C23.8333 24.9 24.3167 24.4333 24.85 24H14.45C14.025 24 13.6688 24.1446 13.3813 24.4338C13.0938 24.7229 12.95 25.0813 12.95 25.5087C12.95 25.9362 13.0938 26.2917 13.3813 26.575C13.6688 26.8583 14.025 27 14.45 27ZM14.45 35.5H20.75C20.65 35 20.575 34.5 20.525 34C20.475 33.5 20.4667 33 20.5 32.5H14.45C14.025 32.5 13.6688 32.6446 13.3813 32.9338C13.0938 33.2229 12.95 33.5813 12.95 34.0087C12.95 34.4362 13.0938 34.7917 13.3813 35.075C13.6688 35.3583 14.025 35.5 14.45 35.5ZM8 44C7.2 44 6.5 43.7 5.9 43.1C5.3 42.5 5 41.8 5 41V7C5 6.2 5.3 5.5 5.9 4.9C6.5 4.3 7.2 4 8 4H24.8C25.2148 4 25.6102 4.08333 25.9861 4.25C26.362 4.41667 26.6833 4.63333 26.95 4.9L36.1 14.05C36.3667 14.3167 36.5833 14.638 36.75 15.0139C36.9167 15.3898 37 15.7852 37 16.2V22.65C36.5333 22.4167 36.05 22.2167 35.55 22.05C35.05 21.8833 34.5333 21.75 34 21.65V16.3H26.05C25.625 16.3 25.2688 16.1563 24.9813 15.8688C24.6938 15.5813 24.55 15.225 24.55 14.8V7H8V41H23.75C24.4167 41.7 25.1583 42.3 25.975 42.8C26.7917 43.3 27.6833 43.7 28.65 44H8ZM32 38.5C33.5667 38.5 34.875 37.975 35.925 36.925C36.975 35.875 37.5 34.5667 37.5 33C37.5 31.4333 36.975 30.125 35.925 29.075C34.875 28.025 33.5667 27.5 32 27.5C30.4333 27.5 29.125 28.025 28.075 29.075C27.025 30.125 26.5 31.4333 26.5 33C26.5 34.5667 27.025 35.875 28.075 36.925C29.125 37.975 30.4333 38.5 32 38.5ZM43.25 44.25C42.95 44.55 42.6 44.7 42.2 44.7C41.8 44.7 41.45 44.55 41.15 44.25L36.85 39.95C36.15 40.4167 35.3917 40.7917 34.575 41.075C33.7583 41.3583 32.9 41.5 32 41.5C29.6389 41.5 27.632 40.673 25.9792 39.0191C24.3264 37.3652 23.5 35.3569 23.5 32.9941C23.5 30.6314 24.327 28.625 25.9809 26.975C27.6348 25.325 29.6431 24.5 32.0059 24.5C34.3686 24.5 36.375 25.3264 38.025 26.9792C39.675 28.632 40.5 30.6389 40.5 33C40.5 33.9 40.3583 34.7583 40.075 35.575C39.7917 36.3917 39.4167 37.15 38.95 37.85L43.25 42.15C43.55 42.45 43.7 42.8 43.7 43.2C43.7 43.6 43.55 43.95 43.25 44.25Z"
          fill="#2F6D95"
        />
      </g>
    </svg>
  );
};

export default QuickReferenceAll;
