import React from "react";

import { Text, View } from "@react-pdf/renderer";

export const Wrapper = ({ children, style }) => {
  return (
    <View
      style={{
        marginBottom: "16px",
        ...style,
      }}
    >
      {children}
    </View>
  );
};

export const Row = ({ children, style }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        ...style,
      }}
    >
      {children}
    </View>
  );
};

export const Heading = ({ children, style }) => {
  return (
    <View
      style={{
        width: "100%",
        padding: "8px",
        backgroundColor: "#CE1336",
        ...style,
      }}
    >
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          color: "white",
          fontSize: "10px",
        }}
      >
        {children}
      </Text>
    </View>
  );
};

export const Label = ({
  children,
  style,
  textStyle,
  borderTop,
  borderRight,
  ...props
}) => {
  return (
    <View
      style={{
        backgroundColor: "#F6F6F6",
        padding: "6px 8px",
        width: "50%",
        borderTop: borderTop && "1px solid #B9B9B9",
        borderRight: borderRight && "1px solid #B9B9B9",
        borderBottom: "1px solid #B9B9B9",
        borderLeft: "1px solid #B9B9B9",
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
      {...props}
    >
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          fontSize: "10px",
          textAlign: "center",
          ...textStyle,
        }}
      >
        {children}
      </Text>
    </View>
  );
};

export const Detail = ({
  children,
  style,
  textStyle,
  borderTop,
  borderRight,
  ...props
}) => {
  return (
    <View
      style={{
        width: "50%",
        padding: "6px 8px",
        borderTop: borderTop && "1px solid #B9B9B9",
        borderRight: borderRight && "1px solid #B9B9B9",
        borderBottom: "1px solid #B9B9B9",
        borderLeft: "1px solid #B9B9B9",
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
      {...props}
    >
      <Text
        style={{
          fontSize: "10px",
          textAlign: "center",
          fontFamily: "Helvetica",
          ...textStyle,
        }}
      >
        {children}
      </Text>
    </View>
  );
};
