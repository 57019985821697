import React from "react";

function Icon({ color = "#0043CE" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8125 15.5625H1.6875V0.9375C1.6875 0.834375 1.60312 0.75 1.5 0.75H0.1875C0.084375 0.75 0 0.834375 0 0.9375V17.0625C0 17.1656 0.084375 17.25 0.1875 17.25H17.8125C17.9156 17.25 18 17.1656 18 17.0625V15.75C18 15.6469 17.9156 15.5625 17.8125 15.5625ZM3.75 13.6875H5.0625C5.16563 13.6875 5.25 13.6031 5.25 13.5V10.125C5.25 10.0219 5.16563 9.9375 5.0625 9.9375H3.75C3.64687 9.9375 3.5625 10.0219 3.5625 10.125V13.5C3.5625 13.6031 3.64687 13.6875 3.75 13.6875ZM7.3125 13.6875H8.625C8.72812 13.6875 8.8125 13.6031 8.8125 13.5V6C8.8125 5.89687 8.72812 5.8125 8.625 5.8125H7.3125C7.20937 5.8125 7.125 5.89687 7.125 6V13.5C7.125 13.6031 7.20937 13.6875 7.3125 13.6875ZM10.875 13.6875H12.1875C12.2906 13.6875 12.375 13.6031 12.375 13.5V7.82812C12.375 7.725 12.2906 7.64062 12.1875 7.64062H10.875C10.7719 7.64062 10.6875 7.725 10.6875 7.82812V13.5C10.6875 13.6031 10.7719 13.6875 10.875 13.6875ZM14.4375 13.6875H15.75C15.8531 13.6875 15.9375 13.6031 15.9375 13.5V4.125C15.9375 4.02187 15.8531 3.9375 15.75 3.9375H14.4375C14.3344 3.9375 14.25 4.02187 14.25 4.125V13.5C14.25 13.6031 14.3344 13.6875 14.4375 13.6875Z"
        fill="#396DBB"
      />
    </svg>
  );
}

export default Icon;
