import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material";
import hocVariables from "../../../../HOC/HocVariables";
import AuthContext from "../../../../context/autenticacion/authContext";
import logoutIconHover from "../../../../assets/icons/icon-logout.svg";
import redCloseIcon from "../../../../assets/red-close.svg";
import { DrawerOption } from "./components";
import logoutIcon from "../../../../assets/logout-icon.svg";
import openDrawerIcon from "../../../../assets/icon-open.svg";
import closeDrawerIcon from "../../../../assets/icon-menu.svg";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";

const MobileMenu = (props) => {
  const {
    isOpen,
    setIsOpen,
    isDrawerCollapse,
    optionMenuArray,
    optionSelected,
    setOptionSelected,
    setIsDrawerCollapse,
    limpiarVariablesContext,
    location,
    sidebarOptions,
    connectEtario,
  } = props;

  const authContext = useContext(AuthContext);
  const { cerrarSesion } = authContext;
  const closeSesion = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(true, props.history.location);
    limpiarVariablesContext();
  };

  let backdrop;
  const backDropClickHandler = () => {
    setIsOpen(false);
  };
  if (isOpen) {
    backdrop = (
      <div className="m-backdrop" onClick={() => backDropClickHandler()}></div>
    );
  }
  const client = JSON.parse(localStorage.getItem("client"));

  const deleteObj = (array) => {
    let filteredArray = JSON.parse(JSON.stringify(array));

    if (filteredArray) {
      if (sidebarOptions.cambioFondo && !sidebarOptions.retiroApv) {
        filteredArray[2].subMenu[2].show = false;
      }
      if (!sidebarOptions.tfehow && !sidebarOptions.retiro25Show) {
        filteredArray[3].show = false;
      } else {
        if (!sidebarOptions.tfehow) {
          filteredArray[3].subMenu[1].show = false;
        } else if (!sidebarOptions.retiro25Show) {
          filteredArray[3].subMenu[0].show = false;
        }
      }
    }
    return filteredArray;
  };
  return (
    <>
      <div
        className={`drawer-container ${
          isOpen ? "open-drawer" : "close-drawer"
        }`}
      >
        <Box
          sx={{ width: isDrawerCollapse ? 280 : 90, height: "100%" }}
          role="presentation"
          className="drawer-box"
        >
          <div className="content-user-mobile">
            <div
              className={`content-padding pb-4 pt-4 close-drawer ${
                isDrawerCollapse ? "close-icon" : "center-items"
              }`}
            >
              <IconButton
                onClick={() => {
                  setIsOpen(false);
                  // setIsDrawerCollapse(false);
                }}
              >
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <div className="info-mobile-user">
              <Box>
                <div className="circle-user-caracter">
                  {client.r_primer_nombre.charAt(0)}
                  {client.r_primer_apellido.charAt(0)}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "28px",
                    letterSpacing: "0.2px",
                    color: "#363A3E",
                  }}
                >
                  {client.r_primer_nombre}
                </Box>
                <Box
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.2px",
                    color: "#ACAFB3",
                  }}
                >
                  DNI: {localStorage.getItem("doc_num")}
                </Box>
              </Box>
            </div>
          </div>

          <div className="content-option-drawer">
            <div className="content-padding">
              {deleteObj(optionMenuArray)
                .filter((e) => e.show !== false)
                .map((value, index) => {
                  return (
                    <DrawerOption
                      key={`option-${value.id}`}
                      id={value.id}
                      icon={value.icon}
                      altIcon={value.altIcon}
                      title={value.title}
                      url={value.url}
                      subOptions={value.subMenu}
                      setOpenDrawer={setIsOpen}
                      iconHover={value.iconHover}
                      isDrawerCollapse={isDrawerCollapse}
                      optionSelected={optionSelected}
                      setOptionSelected={setOptionSelected}
                      location={location}
                      sidebarOptions={sidebarOptions}
                      connectEtario={connectEtario}
                    />
                  );
                })}
            </div>

            <div>
              <div className="content-padding">
                {/* <DrawerOption
                  id="help"
                  icon={questionIcon}
                  altIcon="question-icon"
                  title="Ayuda"
                  setOpenDrawer={setIsOpen}
                  isDrawerCollapse={isDrawerCollapse}
                  optionSelected={optionSelected}
                  setOptionSelected={setOptionSelected}
                /> */}

                <DrawerOption
                  id="logout"
                  icon={logoutIcon}
                  iconHover={logoutIconHover}
                  altIcon="logout-icon"
                  title="Cerrar Sesión"
                  url=""
                  setOpenDrawer={setIsOpen}
                  isDrawerCollapse={isDrawerCollapse}
                  optionSelected={optionSelected}
                  handleClose={closeSesion}
                  setOptionSelected={setOptionSelected}
                  location={location}
                  sidebarOptions={sidebarOptions}
                  connectEtario={connectEtario}
                  {...props}
                />
              </div>
            </div>
          </div>
          {/* <div className="bg-info-user">
            <DrawerUserInfo isDrawerCollapse={isDrawerCollapse} />
          </div> */}
        </Box>
        {backdrop}
      </div>

      {/* <div class="sidebar-mobile" {...handlers}></div> */}
    </>
  );
};
export default hocVariables(MobileMenu);
