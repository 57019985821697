export const PAGOS_DESEMBOLSOS_EXITOSO = 'PAGOS_DESEMBOLSOS_EXITOSO';
export const PAGOS_DESEMBOLSOS_ERROR = 'PAGOS_DESEMBOLSOS_ERROR';
export const PAGOS_DESEMBOLSOS_LOADING = 'PAGOS_DESEMBOLSOS_LOADING';
export const PAGOS_DESEMBOLSOS_CLEANER = 'PAGOS_DESEMBOLSOS_CLEANER';

export const DETALLE_TRAMITE_EXITOSO = 'DETALLE_TRAMITE_EXITOSO';
export const DETALLE_TRAMITE_ERROR = 'DETALLE_TRAMITE_ERROR';
export const DETALLE_TRAMITE_LOADING = 'DETALLE_TRAMITE_LOADING';
export const DETALLE_TRAMITE_CLEANER = 'DETALLE_TRAMITE_CLEANER';

export const CHECK_EXITOSO = 'CHECK_EXITOSO';
export const CHECK_ERROR = 'CHECK_ERROR';
export const CHECK_LOADING = 'CHECK_LOADING';
export const CHECK_CLEANER = 'CHECK_CLEANER';

export const VALORES_CUOTA_EXITOSO = 'VALORES_CUOTA_EXITOSO';
export const VALORES_CUOTA_ERROR = 'VALORES_CUOTA_ERROR';
export const VALORES_CUOTA_LOADING = 'VALORES_CUOTA_LOADING';
export const VALORES_CUOTA_CLEANER = 'VALORES_CUOTA_CLEANER';

export const USER_EXITOSO = 'USER_EXITOSO';
export const USER_ERROR = 'USER_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const USER_CLEANER = 'USER_CLEANER';

export const GRAFICO_VALORES_CUOTA_RANGO_FECHAS_EXITOSO = 'GRAFICO_VALORES_CUOTA_RANGO_FECHAS_EXITOSO';
export const GRAFICO_VALORES_CUOTA_RANGO_FECHAS_ERROR = 'GRAFICO_VALORES_CUOTA_RANGO_FECHAS_ERROR';
export const GRAFICO_VALORES_CUOTA_RANGO_FECHAS_LOADING = 'GRAFICO_VALORES_CUOTA_RANGO_FECHAS_LOADING';
export const GRAFICO_VALORES_CUOTA_RANGO_FECHAS_CLEANER = 'GRAFICO_VALORES_CUOTA_RANGO_FECHAS_CLEANER';

export const PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO = 'PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO';
export const PAGOS_DESEMBOLSOS_PERIODOS_ERROR = 'PAGOS_DESEMBOLSOS_PERIODOS_ERROR';
export const PAGOS_DESEMBOLSOS_PERIODOS_LOADING = 'PAGOS_DESEMBOLSOS_PERIODOS_LOADING';
export const PAGOS_DESEMBOLSOS_PERIODOS_CLEANER = 'PAGOS_DESEMBOLSOS_PERIODOS_CLEANER';

export const PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER';

export const GRAFICO_DATA_PIECHART_EXITOSO = 'GRAFICO_DATA_PIECHART_EXITOSO';
export const GRAFICO_DATA_PIECHART_ERROR = 'GRAFICO_DATA_PIECHART_ERROR';
export const GRAFICO_DATA_PIECHART_LOADING = 'GRAFICO_DATA_PIECHART_LOADING';
export const GRAFICO_DATA_PIECHART_CLEANER = 'GRAFICO_DATA_PIECHART_CLEANER';


export const PERIODOS_EXITOSO = 'PERIODOS_EXITOSO';
export const PERIODOS_ERROR = 'PERIODOS_ERROR';
export const PERIODOS_LOADING = 'PERIODOS_LOADING';
export const PERIODOS_CLEANER = 'PERIODOS_CLEANER';



export const GRAFICO_DATA_CARTERA_EXITOSO = 'GRAFICO_DATA_CARTERA_EXITOSO';
export const GRAFICO_DATA_CARTERA_ERROR = 'GRAFICO_DATA_CARTERA_ERROR';
export const GRAFICO_DATA_CARTERA_LOADING = 'GRAFICO_DATA_CARTERA_LOADING';
export const GRAFICO_DATA_CARTERA_CLEANER = 'GRAFICO_DATA_CARTERA_CLEANER';


export const HOJA_LIQUIDACION_EXITOSO = 'HOJA_LIQUIDACION_EXITOSO';
export const HOJA_LIQUIDACION_ERROR = 'HOJA_LIQUIDACION_ERROR';
export const HOJA_LIQUIDACION_LOADING = 'HOJA_LIQUIDACION_LOADING';
export const HOJA_LIQUIDACION_CLEANER = 'HOJA_LIQUIDACION_CLEANER';



export const LIST_APORTES_LOADING = 'LIST_APORTES_LOADING';
export const LIST_APORTES_ERROR = 'LIST_APORTES_ERROR';
export const LIST_APORTES_EXITOSO = 'LIST_APORTES_EXITOSO';
export const LIST_APORTES_CLEANER = 'LIST_APORTES_CLEANER';

export const LIST_RETIROS_LOADING = 'LIST_RETIROS_LOADING';
export const LIST_RETIROS_ERROR = 'LIST_RETIROS_ERROR';
export const LIST_RETIROS_EXITOSO = 'LIST_RETIROS_EXITOSO';
export const LIST_RETIROS_CLEANER = 'LIST_RETIROS_CLEANER';