import React, { useReducer } from "react";
import MisInversionesContext from "./misinversionesContext";
import MisInversionesReducer from "./misinversionesReducer";
import clienteAxios from "../../config/axios";
import {
  MIS_INVERSIONES_EXITOSO,
  MIS_INVERSIONES_ERROR,
  MIS_INVERSIONES_LOADIN,
  MIS_INVERSIONES_CLEANER,
  MIS_INVERSIONES_CLEANER_PARCIAL,
} from "../../types/misInversiones";

const DrsppState = (props) => {
  const initialState = {
    datosMisInversiones: null,
    mensajeAlerta: null,
    loadingPage: false,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosMisInversiones = async () => {
    dispatch({
      type: MIS_INVERSIONES_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/mis-inversiones/evolucion-del-fondo");

      dispatch({
        type: MIS_INVERSIONES_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, MIS_INVERSIONES_ERROR);
    }
  };

  const limpiarVariablesMisInversiones = () => {
    dispatch({
      type: MIS_INVERSIONES_CLEANER,
    });
  };
  const limpiarVariablesMisInversionesParcial = () => {
    dispatch({
      type: MIS_INVERSIONES_CLEANER_PARCIAL,
    });
  };

  const [state, dispatch] = useReducer(MisInversionesReducer, initialState);
  return (
    <MisInversionesContext.Provider
      value={{
        datosMisInversiones: state.datosMisInversiones,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        obtenerDatosMisInversiones,
        limpiarVariablesMisInversiones,
        limpiarVariablesMisInversionesParcial,
      }}
    >
      {props.children}
    </MisInversionesContext.Provider>
  );
};

export default DrsppState;