
import React, { useState, useContext, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";

import EcmensualContext from "../../context/estadocuentamensual/ecmensualContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ReactGa from "react-ga";
import NoDisponible from "../../components/NoDisponible/index.jsx";
import Loading from "../../components/Loading/index.jsx";
import Grid from "@mui/material/Grid";
import { CardContainer, Page, Button } from "../../components";
import MessageSnack from "../../components/MessageSnack";
import ICON_INFO from "../../assets/icons/info_circle.svg";
import "./index.css";

const EstadoCuentaMensual = (props) => {
  //Obtenemos los state del home
  const ecmensualContext = useContext(EcmensualContext);
  const {
    listaDeOpciones,
    externalLink,
    mensajeAlerta,
    loadingBtn,
    obtenerDatosListaMeses,
    obtenerECMensual,
    limpiarVariablesECMensualParcial,
    limpiarExternalLink,
  } = ecmensualContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const [mes, guardarMes] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosListaMeses();
    }

    return () => {
      limpiarVariablesECMensualParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  useEffect(() => {
    if (externalLink) {
    }
  }, [externalLink]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const handleSelectMes = (e) => {
    limpiarExternalLink();
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);
      guardarMes(item.r_periodo);
    } else {
      guardarMes(null);
    }
  };

  const consultar = (e) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.set("period", mes);
    obtenerECMensual(bodyFormData);
  };

  const activarBtn =
    mes && !loadingBtn
      ? { class: "", status: false }
      : { class: "disable", status: true };

  if (mensajeAlerta !== null) return <p></p>;
  if (listaDeOpciones === null) return <Loading />;
  if (listaDeOpciones.length === 0)
    return <NoDisponible mensaje={"No dispone con estado de cuenta mensual"} />;

  return (
    <Page title="Estado de cuenta mensual" showHeader={"mobile"} paddingMobile="0" /* Este padding solo aplica para mobile */>
      <CardContainer>
        <Grid container py={1}>
          <Grid item xs={12}>
            <h1 className="title-page">Estado de cuenta mensual</h1>
            <p className="instructions">
              Consulta el estado de cuenta del mes que prefieras seleccionándolo aquí.
            </p>
          </Grid>

          <Grid item xs={12} lg={6}>
            <div className="select-button-container">
              <FormGroup className="mb-0 select-container">
                <Label for="doc_number" className="label-select-periodo">Selecciona el periodo</Label>
                <select
                  className="form-control"
                  type="select"
                  name="doc_type"
                  id="doc_type"
                  onChange={handleSelectMes}
                >
                  <option value={""} className="select-periodo">-Periodo-</option>
                  {listaDeOpciones.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.r_descripcion}
                    </option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup className="mb-0 button-container">
                <Button disabled={activarBtn.status} onClick={consultar} className="consultar-button">
                  <span>Consultar</span>
                  {loadingBtn && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </Button>
              </FormGroup>
            </div>
          </Grid>

          {/* <Grid item xs={12}>
          <div className="note-container">
            <div className="info-icon-container">
              <i className="info-icon">ℹ</i>
            </div>
            <ul className="note-list">
              <li className="note-item">
                El periodo de consulta está disponible hasta <b>6 meses</b>, por lo que te recomendamos descargarlo como PDF desde nuestra versión web para que lo tengas siempre disponible.
              </li>
              <li className="note-item">
                El enlace generado para ver tu estado de cuenta tiene una duración máxima de 2 horas.
              </li>
              <li className="note-item">
                Si necesitas un estado de cuenta completo, puedes solicitarlo en cualquier momento a través de nuestros <a href="https://www.afphabitat.com.pe/canales-de-atencion/" className="note-link" target="_blank" rel="noopener noreferrer">canales de atención</a>.
              </li>
            </ul>
          </div>
        </Grid> */}

          <MessageSnack icon={ICON_INFO} alt="ICON_INFO" marginTop="1.2em">
            <ul className="note-list">
              <li className="note-item">
                El periodo de consulta está disponible hasta <b>6 meses</b>, por lo que te recomendamos descargarlo como PDF desde nuestra versión web para que lo tengas siempre disponible.
              </li>
              {/* <li className="note-item">
                El enlace generado para ver tu estado de cuenta tiene una duración máxima de 2 horas.
              </li> */}
              <li className="note-item">
                Si necesitas un estado de cuenta completo, puedes solicitarlo en cualquier momento a través de nuestros <a href="https://www.afphabitat.com.pe/canales-de-atencion/" className="note-link" target="_blank" rel="noopener noreferrer">canales de atención</a>.
              </li>
            </ul>
          </MessageSnack>

        </Grid>
      </CardContainer>
    </Page>

  );
};

export default EstadoCuentaMensual;
