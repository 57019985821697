import React, { Fragment } from "react";

const MensajeAleta = ({ opcion }) => {
  return (
    <Fragment>
      {opcion === 3 ? (
        <div className="title-modal-token">
          Esta operación se confirmará con tu token digital
        </div>
      ) : opcion === 2 ? (
        <div className="title-modal-token">
          Esta operación se confirmará con tu token digital
        </div>
      ) : opcion === 1 ? (
        <div className="title-modal-token">
          Esta operación se confirmará con tu token digital
        </div>
      ) : (
        <div className="title-modal-token">
          Lo sentimos pero necesitamos que sus datos sean actualizados, tanto{" "}
          <strong>Correo</strong>, como <strong>Celular</strong>.
        </div>
      )}
    </Fragment>
  );
};

export default MensajeAleta;
