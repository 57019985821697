import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Data from "./data/data.json";
import { useFormContext } from "react-hook-form";
import LyricMusicPlayer from "../../../../../Components/lyricsMusicPlayer/LyricMusicPlayerPMO";
import AuthContext from "../../../../../../../context/autenticacion/authContext";
import JubilacionContext from "../../../../../../../context/jubilacion/pmo/jubPmoContext";
import * as Input from "../../../../../../../components/Input";
import { ContainerAsesoria } from "./styles";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../../../../Components/ButtonsComponents";

export default function Asesoria(props) {
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const { handleStepNavigation, setShowStepComponent } = props;
  const currentTopicData = Data[0];
  const { getValues, setValue } = useFormContext();
  const [endVideo, setEndVideo] = useState(false);
  const jubilacionContext = useContext(JubilacionContext);
  const { guardarAsesoria, mensajeAsesoria, resetearEstados } =
    jubilacionContext;

  console.log(mensajeAsesoria);
  useEffect(() => {
    actualizarTimer();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (mensajeAsesoria) {
      handleStepNavigation("next");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAsesoria]);

  const fnBtnNextTopic = () => {
    guardarAsesoria();
  };

  return (
    <ContainerAsesoria>
      <Grid container>
        <Grid className="right-section" xl={12} sm={12} xs={12}>
          <h6>{currentTopicData.title}</h6>
          <h4>{currentTopicData.description}</h4>
          <div className="break-line"></div>
          <LyricMusicPlayer
            asesoria="legal"
            currentTopicData={currentTopicData}
            setEndVideo={setEndVideo}
          />

          <Input.Wrapper style={{ gridColumn: "1/-1" }}>
            <Input.Checkbox
              name="asesoria.acepta-informarcion"
              value={true}
              required={true}
            >
              Acepto haber recibido y entendido la información brindada.
            </Input.Checkbox>
            <Input.ErrorMessage name="asesoria.acepta-informarcion" />
          </Input.Wrapper>
          <ButtonsWrapper>
            <PrevButton
              variant="secondary"
              isBtnSecondary
              onClick={() => setShowStepComponent(false)}
            >
              Volver
            </PrevButton>

            <NextButton
              disabled={!endVideo || !getValues("asesoria.acepta-informarcion")}
              onClick={fnBtnNextTopic}
              type="button"
            >
              Continuar
            </NextButton>
          </ButtonsWrapper>
        </Grid>
      </Grid>
    </ContainerAsesoria>
  );
}
