import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import "./index.css";
import {
  montoFormat,
  montoFormatMiles,
} from "../../../../../helper/amountFormat";
import { CardContainerDesktop } from "../../../../../components";
const FUNDS = {
  AMOUNT: {
    id: 1,
    data: {
      amount: "158,162.73",
      rent: "87,162.73",
      percent: "+10.2%",
    },
  },
  QUOTA: {
    id: 2,
    data: {
      amount: "122353.19",
      rent: "187162.73",
      percent: "158,162.73",
    },
  },
};
const BoxBody = styled(Box)`
  padding: ${(props) => props.theme.spacing(1)};
  position: relative;
  ${(props) => props.theme.tipography.h5};
  & .spanText {
    display: block;
    color: ${(props) => props.theme.palette.grayscale.light};
    font-size: 14px;
    margin-bottom: 10px;
  }
  // & .amount {
  //   ${(props) => props.theme.tipography.number};
  // }
  & .content-text {
    display: flex;
  }
  & .invested-amount {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    white-space: nowrap;
  }
`;

export default function MyProducts({
  habitatValue,
  acumulated_mount,
  cuotasValue,
}) {
  const [fundSelected, setFundSelected] = useState(FUNDS.AMOUNT.id);

  const onClickFund = (fund) => {
    setFundSelected(fund);
  };

  return (
    <CardContainerDesktop
      title={"¿En qué invirtió AFP Habitat?"}
      seeMore={false}
      color={"#ffffff"}
      product={false}
      showHeader={false}
    >
      <BoxBody className="home-box-body">
        <div className="custom-tabs mb-4">
          <div
            className={`tab ${
              fundSelected === FUNDS.AMOUNT.id ? "selected" : ""
            }`}
            onClick={() => onClickFund(FUNDS.AMOUNT.id)}
          >
            Soles
          </div>
          <div
            className={`tab ${
              fundSelected === FUNDS.QUOTA.id ? "selected" : ""
            }`}
            onClick={() => onClickFund(FUNDS.QUOTA.id)}
          >
            Cuotas
          </div>
        </div>
        <div className="ml-3">
          <div className="ml-3 mr-3  d-flex">
            <div>
              <span className="spanText mr-4">Al día de hoy tienes: </span>
            </div>
            {fundSelected === FUNDS.AMOUNT.id ? (
              <span className="block invested-amount">{`S/ ${montoFormat(
                acumulated_mount
              )}`}</span>
            ) : (
              <span className="block invested-amount">
                {montoFormatMiles(cuotasValue?.monCuota)}{" "}
              </span>
            )}
          </div>
          <div className="ml-3 mr-3 pt-2">
            <p className="text-detail-product">
              {fundSelected === FUNDS.AMOUNT.id ? (
                <>
                  {/* Tu rentabilidad acumulada en Habitat es: <br />{" "}
                  <span className="amount">
                    S/{" "}
                    {montoFormat(
                      habitatValue?.hasOwnProperty("sumRenrendAcumtAcum_")
                        ? habitatValue?.sumRenrendAcumtAcum_.replace(",", "")
                        : habitatValue?.rendAcum.replace(",", "")
                    )}
                  </span>{" "}
                  equivalente a:{" "}
                  <span
                    className={
                      "percent" +
                      (Number(formPercent) > 0 ? " text2-green" : " text2-red")
                    }
                  >
                    {montoFormat(formPercent)}%{" "}
                  </span>
                  {Number(formPercent) > 0 ? (
                    ""
                  ) : (
                    <span
                      className="tooltip-custom tooltip-rent"
                      style={{ color: "#bf253d" }}
                    >
                      <i className="fa fa-info-circle" id="TooltipExample"></i>
                      <span className="tooltiptext">
                        Recuerda que la rentabilidad negativa a corto plazo es
                        temporal y que tu fondo persigue una meta de largo
                        plazo: tu jubilación.
                      </span>
                    </span>
                  )} */}
                </>
              ) : (
                <>
                  Total de cuotas acumuladas a la fecha de hoy con un valor de{" "}
                  <span className="fee">
                    {montoFormatMiles(cuotasValue?.valCuota)}
                  </span>{" "}
                  por cuota, siendo un total de{" "}
                  <span className="amount">
                    S/{montoFormat(acumulated_mount)}
                  </span>{" "}
                  de saldo acumulado.
                </>
              )}
            </p>
          </div>
        </div>
      </BoxBody>
    </CardContainerDesktop>
  );
}
