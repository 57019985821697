
import React from 'react'
import { Grid, Box } from '@mui/material';
import styled from 'styled-components';
import { Title } from '../componentes/Title';
import { Tabs } from '../componentes/Tabs';
import { SimpleTable } from '../componentes/Tables/SimpleTable';
import { BreadCrumbsFL } from '../../PagosDesembolsos/componentes/data/components/BreadcrumbsFL';

const BoxShadow = styled(Box)`
    padding: 16px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    border-radius: 16px;
    background: #FFFFFF;
    margin-bottom: 24px;
`;

export const DetalleFondo = () => {
    return (
        <>
            <BreadCrumbsFL title_mobile={'Home'} />
            <Grid container sx={{
                paddingTop: { xs: '78px', md: '96px' },
                background: { xs: '#ffffff', md: '#f8f9fb' },
                paddingBottom: { xs: '82px', md: '0px' },
                minHeight: { xs: '78vh', md: 'auto' },
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)'
            }}>
                <Grid item xs={12} lg={8} p={2}>

                    <BoxShadow>
                        <Title titulo='Detalle del periodo Dic-20 a Jun-21' />

                        <SimpleTable
                            labels={['fecha', 'descripcion', 'cargos']}

                            titulos={
                                [
                                    { title: 'Saldo final', size: '2' },
                                    { title: 'Aportes acumulados', size: '3', hasIcon: true },
                                    { title: 'Cargos', size: '1' },
                                ]
                            }

                            contenido={
                                [
                                    { fecha: '20,000.00', descripcion: '20,000.00', cargos: '-' },
                                ]
                            }

                        />
                    </BoxShadow>

                    <BoxShadow>
                        <Title titulo='Mis movimientos' />
                        <Tabs />
                        <SimpleTable
                            labels={['fecha', 'descripcion', 'accion']}

                            titulos={[
                                { title: 'Fecha', size: '2' },
                                { title: 'Descripción', size: '2' },
                                { title: 'Acciones', size: '1' },
                            ]}

                            contenido={
                                [
                                    { fecha: '29/03/2022', descripcion: 'Sobre el saldo admir', },
                                    { fecha: '29/03/2022', descripcion: 'Aporte', },
                                    { fecha: '29/03/2022', descripcion: 'Prima de seguro', },
                                    { fecha: '30/03/2022', descripcion: 'Prima de seguro', },
                                    { fecha: '01/04/2022', descripcion: 'Mensaje de prueba', },
                                    { fecha: '02/05/2022', descripcion: 'Seguro nuevo.', },
                                    { fecha: '05/06/2022', descripcion: 'Prima de seguro', },
                                ]
                            }

                        />
                    </BoxShadow>

                </Grid>


            </Grid>
        </>
    )
}
