import React, { useState, useEffect, useContext } from "react";
import { Button } from "../../../../../../../components";
import imgLogo from "../../../../../../../assets/recuerda.svg";
import JubilacionContext from "../../../../../../../context/jubilacion/jubilacionContext";
import ModalDeErrorGeneral from "../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function AvisoImportante(props) {
  const { beneficiarieRegister, saldosRegister, setStep } = props;
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const jubilacionContext = useContext(JubilacionContext);
  const {
    saveJaoAvisoData,
    saveJaoAvisoLoading,
    guardarAvisoJaoData,
    limpiarGuardarAvisoJaoData,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      limpiarGuardarAvisoJaoData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveJaoAvisoData) {
      if (saveJaoAvisoData.success) {
        props.setDataResult(saveJaoAvisoData);
        setStep(4);
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveJaoAvisoData]);

  const onContinue = () => {
    const datos = {
      balances: saldosRegister,
      haveBeneficiaries: beneficiarieRegister.haveBeneficiaries,
      beneficiaries: beneficiarieRegister.beneficiaries,
    };
    guardarAvisoJaoData(datos);
  };

  const changeIconForLoading = () => {
    if (saveJaoAvisoLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  return (
    <>
      <div className="aviso-jao">
        <div className="logo-container">
          <img src={imgLogo} alt="icono de confirmacion" />
        </div>
        <h1>Aviso Importante</h1>

        <ul className="mb-4">
          <li>
            La presente pre-evaluación no es una solicitud de pensión, ni da
            inicio a una.
          </li>
          <li>
            Los cálculos que te brindemos como resultado, serán sobre la base de
            la información proporcionada y la fecha de presentación de este
            requerimiento.
          </li>
          <li>
            Las remuneraciones a considerar para el cálculo son las reportadas
            por tus empleadores mediante la plataforma de{" "}
            <a href="https://www.afpnet.com.pe/">AFPNET</a> en calidad de
            dependiente. Las reportadas fuera de dicha plataforma, así como
            todos tus aportes que hubieras hecho como independientes no son
            consideradas para el calculo del promedio de sus remuneraciones.{" "}
          </li>
          <li>
            La evaluación solicitada tiene calidad informativa, es decir, no
            será considerada como vinculante para la procedencia de la
            evaluación oficial de tu solicitud de pensión.
          </li>
          <li>Recuerda revisar tu bandeja de spam o correos no deseados.</li>
        </ul>

        <div className="confirm-check">
          <div className="check-container center">
            <span className="oneChoice">
              <input
                type="radio"
                checked={checked}
                className="radio-btn"
                id="tfa_731"
                name="cuenta_bancaria"
                onClick={(e) => setChecked(!checked)}
                disabled={saveJaoAvisoLoading}
              />
              <label className="postRadioField mb-0" for="tfa_731">
                <span className="input-radio-faux"></span>
              </label>
            </span>
            <label className="postRadioField mb-0" for="tfa_731">
              <span className="check-label">
                Estoy conforme con la información brindada.
              </span>
            </label>
          </div>
        </div>
        <div className="container-jao-buttons mt-5">
          <Button
            className="mb-4 btn-modal-primary"
            onClick={() => {
              onContinue();
            }}
            disabled={!checked || saveJaoAvisoLoading}
          >
            Continuar {changeIconForLoading()}
          </Button>
        </div>
      </div>
      {showModal && <ModalDeErrorGeneral />}
    </>
  );
}
