import React, { useEffect, useState } from "react";
import { Icon, InputWrapper, NumberInputComponent } from "./styles";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import getDeepValue from "../../../utils/getDeepValue";

const NumberInput = ({
  name,
  size,
  icon,
  disabled,
  placeholder,
  wrapperProps,
  maxLength,
  minLength,
  required,
  validate,
  ...props
}) => {
  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: false,
    allowDecimal: false,
    decimalSymbol: ".",
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: maxLength, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: true,
  };
  const currencyMask = createNumberMask(defaultMaskOptions);
  const isError = getDeepValue(errors, name);

  const handleOnChange = (e) => {
    setValue(name, e.target.value);
    trigger(name);
  };

  return (
    <InputWrapper {...wrapperProps}>
      <NumberInputComponent
        type="text"
        error={isError}
        placeholder={placeholder}
        disabled={disabled}
        mask={currencyMask}
        value={watch(name)}
        $icon={Boolean(icon)}
        size={size}
        maxLength={maxLength}
        {...register(name, {
          required,
          minLength: {
            value: minLength,
            message: `Se necesita un mínimo de ${minLength} caracteres.`,
          },
          maxLength: {
            value: maxLength,
            message: `No puede tener más de ${maxLength} caracteres.`,
          },
          validate: {
            ...validate,
          },
        })}
        onChange={handleOnChange}
        {...props}
      />
      {icon && (
        <Icon disabled={disabled} error={isError}>
          {icon}
        </Icon>
      )}
    </InputWrapper>
  );
};

export default NumberInput;

NumberInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperProps: PropTypes.any,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

NumberInput.defaultProps = {
  name: "inputValue",
  size: "lg",
  icon: null,
  disabled: false,
  required: { value: true, message: "Este campo es requerido." },
  placeholder: "Ingresa . . .",
  maxLength: 20,
};
