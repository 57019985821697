import { Slide, Fade } from "@mui/material";
import React from "react";
import CloseButton from "../CloseButton";
import useWindowSize from "../../hooks/useWindowSize";
import { size } from "../../utils/device";
import {
  ModalBody,
  ModalComponent,
  SubtitleComponent,
  TitleComponent,
} from "./styles";
import PropTypes from "prop-types";

const Modal = ({ ...props }) => {
  const { width } = useWindowSize();

  const isSmallScreen = width <= size.tablet;

  if (isSmallScreen) {
    return <SmallScreenModal {...props} />;
  } else {
    return <BigScreenModal {...props} />;
  }
};

export default Modal;

const modalTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  close: PropTypes.bool,
  closeWithBackdrop: PropTypes.bool,
};

Modal.propTypes = { ...modalTypes };

const SmallScreenModal = ({
  open,
  close,
  title,
  subtitle,
  children,
  onClose,
  closeWithBackdrop,

  ...props
}) => {
  return (
    <ModalComponent
      open={open}
      onClose={closeWithBackdrop && onClose}
      {...props}
    >
      <Slide direction="up" in={open}>
        <ModalBody>
          {close && <CloseButton onClick={onClose} />}
          {children}
        </ModalBody>
      </Slide>
    </ModalComponent>
  );
};

SmallScreenModal.propTypes = {
  ...modalTypes,
};

SmallScreenModal.defaultProps = {
  close: true,
  closeWithBackdrop: true,
};

const BigScreenModal = ({
  open,
  close,
  title,
  subtitle,
  children,
  onClose,
  closeWithBackdrop,
  bodyProps,
  ...props
}) => {
  return (
    <ModalComponent
      open={open}
      onClose={closeWithBackdrop && onClose}
      {...props}
    >
      <Fade in={open}>
        <ModalBody style={bodyProps}>
          {close && <CloseButton onClick={onClose} />}
          {children}
        </ModalBody>
      </Fade>
    </ModalComponent>
  );
};

BigScreenModal.propTypes = {
  ...modalTypes,
};

BigScreenModal.defaultProps = {
  close: true,
  closeWithBackdrop: true,
};

export const ModalTitle = ({ children, ...props }) => {
  return <TitleComponent {...props}>{children}</TitleComponent>;
};
Modal.Title = ModalTitle;

export const ModalSubtitle = ({ children, ...props }) => {
  return <SubtitleComponent {...props}>{children}</SubtitleComponent>;
};
Modal.Subtitle = ModalSubtitle;
