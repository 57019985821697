import React, { useState } from "react";
import { Button } from "../../../components";
const SelectCard = ({ cuentas, setCuenta, setMensaje, preview }) => {
  const [opcionSeleccionada, setOpcionSelecionada] = useState("");
  const formatNombre = (banco, numero) => {
    return `${banco.toUpperCase()} - ${numero}`;
  };

  const selectCardOption = (e) => {
    if (e.target.value.length > 0) {
      let item = JSON.parse(e.target.value);
      setOpcionSelecionada(JSON.stringify(item));
      setCuenta(item);
    } else {
      setOpcionSelecionada("");
      setCuenta("");
    }
  };

  const selectCard = (option) => (e) => {
    e.preventDefault();
    setOpcionSelecionada("");
    setCuenta(option);
    preview();
  };
  return (
    <div className="mb-2">
      {cuentas && cuentas.length > 0 ? (
        <select
          className="form-control"
          type="select"
          name="cuenta"
          id="cuenta"
          onChange={selectCardOption}
          value={opcionSeleccionada}
        >
          <option value={""}>-Seleccione cuenta-</option>
          {cuentas.map((item, index) => (
            <option key={index} value={JSON.stringify(item)}>
              {formatNombre(item.r_nombre_banco, item.r_numero_cuenta)}
            </option>
          ))}
        </select>
      ) : null}

      <div className="text-center mt-2">
        <Button onClick={selectCard("nueva")}>Agregar cuenta</Button>
      </div>
    </div>
  );
};

export default SelectCard;
