import React from "react";
import Modal from "../../../../components/BasicModal";
import Alert from "../../../../components/Alert";
import Divider from "../../../../components/Divider";
import Button from "../../../../components/Button";
import { ModalBody } from "./styles";

const BankReceiptModal = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Title>Recuerda que:</Modal.Title>
      <ModalBody>
        <p>
          El sustento a cargar puede ser un voucher, Estado de Cuenta o la
          captura de pantalla del pago desde un aplicativo. Debe contener la
          siguiente información:
        </p>
        <ul>
          <li>Un nombre y un apellido como mínimo.</li>
          <li>Nombre o logo de la entidad bancaria.</li>
          <li>
            Número de la cuenta bancaria, la cual debe ser en soles, estar a
            título personal, no a nombre de otra persona ni mancomunada.
          </li>
          <li>
            O Código de Cuenta Interbancario (CCI), excepto si el sustento es de
            Interbank, BCP, BBVA o Scotiabank.
          </li>
          <li>No es necesaria la foto de tu tarjeta.</li>
        </ul>
      </ModalBody>

      <Alert
        variant={"warning"}
        description={
          "Si la evidencia no cumple con lo indicado, no se podrá realizar el pago."
        }
      />
      <Divider />
      <Button fullWidth style={{ margin: "auto" }} onClick={closeModal}>
        Entendido
      </Button>
    </Modal>
  );
};

export default BankReceiptModal;
