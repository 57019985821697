import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Input from "../../../../../../components/Input";
import Icon from "../../../../Components/IconSVG";
import { useFormContext } from "react-hook-form";
import Button from "../../../../../../components/Buttons";
import InfoCircle from "../../../../../../assets/IconBucket/info_circle";
import ICON_FONDOJUB from "../../../../../../assets/IconBucket/ICON_FONDOJUB";
import ICON_FONDOLIBRE from "../../../../../../assets/IconBucket/ICON_FONDOLIBRE";
import ICON_DELETE from "../../../../../../assets/IconBucket/ICON_DELETE";
import { montoFormat } from "../../../../../../helper/amountFormat";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { ModalBeneficiarios, ModalDeclaracionJurada } from "../Modals";
import {
  ContentAmount,
  InfoText,
  HeaderAccordion,
  HeadingTextAccordion,
  ContentTable,
  ContentFondos,
  ContentAcordion,
} from "../Etapas/Calculo/styles";
import Card from "../../../../Components/Card";

const objComplete = {
  item1: false,
  open1: true,
  item2: false,
  open2: false,
  item3: false,
  open3: false,
};
const Index = ({
  expanded,
  handleChange,
  setExpanded,
  handleSetBeneficiairos,
  setBeneficiairos,
  setArrayBeneficiarios,
  arrayBeneficiarios,
  handleDeleteBeneficiairos,
  fondos,
  amounPension,
}) => {
  console.log({
    expanded,
    handleChange,
    setExpanded,
    handleSetBeneficiairos,
    setBeneficiairos,
    setArrayBeneficiarios,
    arrayBeneficiarios,
    handleDeleteBeneficiairos,
    fondos,
    amounPension,
  });

  const { getValues, setValue, trigger, setError } = useFormContext();
  const [amount, setAmount] = useState(null);
  const [isOpenModalDeclaracionJurada, setOpenModalDeclaracionJurada] =
    useState(false);
  const [complete, setComplete] = useState(objComplete);
  const [isOpenModalBeneficiarios, setIsOpenModalBeneficiarios] =
    useState(null);

  const handleAmount = (e) => {
    const value = e.target.value;
    const numberFormatted = value.replace("S/ ", "").replaceAll(",", "");

    setAmount(numberFormatted);
    setValue("calculo.amount", numberFormatted);
    trigger("calculo.amount");
  };

  const handleFirstNext = () => {
    complete.item1 = true;
    complete.open2 = true;
    setComplete(complete);
    handleChange("panel2");
  };
  const handleSecondNext = () => {
    complete.item2 = true;
    complete.item3 = true;
    complete.open3 = true;
    setComplete(complete);
    handleChange("panel3");
    setValue("calculo.fondoJub", true);
  };

  const isCloseToTotal = (e, total) => {
    if (isNaN(e) || e > total) {
      return true;
    }

    return `El monto debe ser mayor a S/ ${amounPension}`;
  };
  const validateBtnNext = () => {
    if (getValues("typePension")) {
      if (getValues("typePension") === "base") {
        return false;
      }
      if (
        getValues("calculo.amount") &&
        Number(getValues("calculo.amount")) >= 1 &&
        Number(getValues("calculo.amount")) > amounPension
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  const validateBtnSecondNext = () => {
    if (getValues("calculo.beneficiarios")) {
      return false;
    }

    if (arrayBeneficiarios.length === 0) {
      return true;
    }

    return false;
  };
  const handleAgregarBeneficiarios = (obj) => {
    arrayBeneficiarios.push(obj);
    setArrayBeneficiarios(arrayBeneficiarios);
  };

  const handleOpen = (dato) => (event, isExpanded) => {
    if (complete.item1 && dato === "panel1") {
      setExpanded(isExpanded ? dato : "panel2");
    } else if (complete.item2 && dato === "panel2") {
      setExpanded(isExpanded ? dato : "panel3");
    } else if (complete.item3 && dato === "panel3") {
      setExpanded(isExpanded ? dato : false);
    }
  };
  const disabledInput = getValues("typePension") !== "otros";

  const handleClearBeneficiarios = () => {
    setBeneficiairos([]);
    setArrayBeneficiarios([]);
  };
  const handleDeleteBeneficiarios = (dato) => {
    let filterArrayBeneficiario = arrayBeneficiarios.filter(
      (item, index) => Number(index) !== Number(dato)
    );
    setArrayBeneficiarios(filterArrayBeneficiario);
    handleDeleteBeneficiairos(dato);
  };
  const getOBL = fondos.filter((item) => item.r_cuenta === "OBL");
  const getVCF = fondos.filter((item) => item.r_cuenta === "VCF");
  const getVSF = fondos.filter((item) => item.r_cuenta === "VSF");

  return (
    <ContentAcordion>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleOpen("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <HeaderAccordion>
            <div className="HBT_PMO_AccordionHeader_number">1</div>
            <div> Establece tu pensión menual</div>
          </HeaderAccordion>
        </AccordionSummary>
        <AccordionDetails>
          <HeadingTextAccordion>
            Este será el monto que buscas recibir mes a mes al jubilarte.
          </HeadingTextAccordion>
          <Input.RadioCard
            name="typePension"
            value="base"
            indicatorSide="right"
          >
            <ContentAmount>
              <div className="HBT_PM_Funds">
                <h2>Monto mínimo de pensión</h2>
                <h3>S/ {Number(amounPension).toFixed(2)} por mes</h3>
                <InfoText>
                  <Icon
                    width="24px"
                    height="24px"
                    componentIcon={<InfoCircle color="#ACAFB3" />}
                  />
                  <p>
                    Equivale al costo de la Canasta Básica de Consumo actual
                    según INEI; puede variar en el tiempo.
                  </p>
                </InfoText>
              </div>
            </ContentAmount>
          </Input.RadioCard>
          <Input.RadioCard
            name="typePension"
            value="otros"
            indicatorSide="right"
            onClick={() => setValue("typePension", "otros")}
          >
            <ContentAmount>
              <h2>Otro monto</h2>

              <Input.Wrapper>
                <Input.Title required={false} disabled={disabledInput}>
                  Monto de pensión
                </Input.Title>
                <Input.Money
                  disabled={disabledInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      console.log("Enter");
                      e.preventDefault();
                    }
                  }}
                  onChange={handleAmount}
                  value={amount}
                  name="calculo.amount"
                  validate={{
                    greaterThanTotal: (e) => isCloseToTotal(e, amounPension),
                  }}
                />
                <Input.ErrorMessage name="calculo.amount" />
              </Input.Wrapper>
            </ContentAmount>
          </Input.RadioCard>
          <Button
            fullWidth
            onClick={handleFirstNext}
            disabled={validateBtnNext()}
          >
            Siguiente
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleOpen("panel2")}
      >
        <AccordionSummary
          expandIcon={complete.open2 && <ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <HeaderAccordion disabled={!complete.open2 ? "disabled" : ""}>
            <div className="HBT_PMO_AccordionHeader_number">2</div>
            <div>Declara a tus beneficiarios</div>
          </HeaderAccordion>
        </AccordionSummary>
        <AccordionDetails>
          <HeadingTextAccordion>
            Se considera beneficiario al cónyuge o concubino/a, así como a
            aquellos familiares que dependen económicamente de ti.
          </HeadingTextAccordion>
          <ContentTable
            style={{
              maxWidth: "calc(100vw - 42px)",
              overflow: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Parentesco</TableCell>
                  <TableCell align="right">Edad</TableCell>
                  <TableCell align="right">Sexo</TableCell>
                  <TableCell align="right">Condición</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayBeneficiarios.length > 0 &&
                  arrayBeneficiarios.map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {item.parentesco}
                        </TableCell>
                        <TableCell align="right">
                          {item.fechaNacimiento}
                        </TableCell>
                        <TableCell align="right">{item.sexo}</TableCell>
                        <TableCell align="right">
                          <div className="HBT_PMO_BtnDelete">
                            <span>
                              {item.discapacidad === "si"
                                ? "Con discapacidad"
                                : "Sin discapacidad"}
                            </span>

                            <div
                              className="HBT_PMO_BtnDeleteIcon"
                              onClick={() => handleDeleteBeneficiarios(index)}
                            >
                              <ICON_DELETE />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    <a onClick={() => setIsOpenModalBeneficiarios(true)}>
                      {" "}
                      + Agregar beneficiario
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ContentTable>

          <Input.Wrapper
            style={{
              gridColumn: "1/-1",
              border: "1px solid #CDCED0",
              padding: "8px 16px 0",
              borderRadius: "16px",
            }}
          >
            <Input.Checkbox
              name="calculo.beneficiarios"
              value={true}
              required={true}
              onClick={() => handleClearBeneficiarios()}
            >
              Declaro no tener beneficiarios
            </Input.Checkbox>
            <Input.ErrorMessage name="calculo.beneficiarios" />
          </Input.Wrapper>
          <InfoText variant="default">
            <Icon
              width="24px"
              height="24px"
              componentIcon={<InfoCircle color="#363A3E" />}
            />
            <p>
              Esta información tiene carácter de{" "}
              <a onClick={() => setOpenModalDeclaracionJurada(true)}>
                declaración jurada
              </a>{" "}
            </p>
          </InfoText>
          <Button
            fullWidth
            disabled={validateBtnSecondNext()}
            onClick={() => handleSecondNext()}
          >
            Siguiente
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleOpen("panel3")}
      >
        <AccordionSummary
          expandIcon={complete.open3 && <ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <HeaderAccordion disabled={!complete.open3 ? "disabled" : ""}>
            <div className="HBT_PMO_AccordionHeader_number">3</div>
            <div>Confirma tus fondos</div>
          </HeaderAccordion>
        </AccordionSummary>
        <AccordionDetails>
          <HeadingTextAccordion>
            Selecciona los productos que deseas considerar para calcular tu
            Pensión Objetivo.
          </HeadingTextAccordion>
          {getOBL.length > 0 && (
            <Card border={getValues("calculo.fondoJub") ? "primary" : ""}>
              <ContentFondos>
                <div className="HBT_PMO_ContentIconFondos">
                  <Icon componentIcon={<ICON_FONDOJUB />} />
                </div>
                <div className="HBT_PMO_ContentTextFondos">
                  <div>
                    <h2>Fondo de Jubilación</h2>
                    <p>Al día de hoy tienes:</p>
                    <h3>S/ {montoFormat(getOBL[0].r_saldo)} </h3>
                  </div>
                  <div>
                    <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                      <Input.Checkbox
                        name="calculo.fondoJub"
                        value={true}
                        required={false}
                        disabled
                      >
                        {""}
                      </Input.Checkbox>
                    </Input.Wrapper>
                  </div>
                </div>
              </ContentFondos>
            </Card>
          )}
          {getVCF.length > 0 && (
            <Card border={getValues("calculo.conFin") ? "primary" : ""}>
              <ContentFondos>
                <div className="HBT_PMO_ContentIconFondos">
                  <Icon componentIcon={<ICON_FONDOJUB />} />
                </div>
                <div className="HBT_PMO_ContentTextFondos">
                  <div>
                    <h2>Fondo con Fin Previsional</h2>
                    <p>Al día de hoy tienes:</p>
                    <h3>S/ {montoFormat(getVCF[0].r_saldo)} </h3>
                  </div>
                  <div>
                    <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                      <Input.Checkbox
                        name="calculo.conFin"
                        value={true}
                        required={false}
                      >
                        {""}
                      </Input.Checkbox>
                    </Input.Wrapper>
                  </div>
                </div>
              </ContentFondos>
            </Card>
          )}
          {getVSF.length > 0 && (
            <Card border={getValues("calculo.fondoLibre") ? "primary" : ""}>
              <ContentFondos>
                <div className="HBT_PMO_ContentIconFondos">
                  <Icon componentIcon={<ICON_FONDOLIBRE />} />
                </div>
                <div className="HBT_PMO_ContentTextFondos">
                  <div>
                    <h2>Fondo Libre Habitat</h2>
                    <p>Al día de hoy tienes:</p>
                    <h3>S/ {montoFormat(getVSF[0].r_saldo)} </h3>
                  </div>

                  <div>
                    <Input.Wrapper style={{ gridColumn: "1/-1" }}>
                      <Input.Checkbox
                        name="calculo.fondoLibre"
                        value={true}
                        required={false}
                      >
                        {""}
                      </Input.Checkbox>
                    </Input.Wrapper>
                  </div>
                </div>
              </ContentFondos>
            </Card>
          )}
        </AccordionDetails>
      </Accordion>
      <ModalBeneficiarios
        isOpen={isOpenModalBeneficiarios}
        setIsOpen={setIsOpenModalBeneficiarios}
        handleAgregarBeneficiarios={handleAgregarBeneficiarios}
        handleSetBeneficiairos={handleSetBeneficiairos}
        arrayBeneficiarios={arrayBeneficiarios}
      />
      <ModalDeclaracionJurada
        isOpen={isOpenModalDeclaracionJurada}
        setIsOpen={setOpenModalDeclaracionJurada}
      />
    </ContentAcordion>
  );
};

export default Index;
