import React from "react";
import "./index.css";
import { withRouter } from "react-router";
import { useEffect } from "react";
const ModalExitoso = ({
  dispositivo,
  buttonCloseTop,
  titulo,
  closeModal,
  history,
}) => {
  useEffect(() => {
    // limpiarMensajeErrorPost();
    // eslint-disable-next-line
  }, []);

  const HandleCloseModal = () => {
    history.push("/mi-habitat-digital/afiliado/inicio");
    closeModal()
  }

  return (
    <section
      className={
        buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`
      }
    >
      <div
        className={
          buttonCloseTop ? `modal-content` : `modal-content modal-content-full`
        }
      >
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={closeModal()}></i>
          </div>
        )}

        <div
          className={
            buttonCloseTop
              ? `mb-modal-title d-flex mt-3`
              : `mb-modal-title d-flex`
          }
        >
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>{titulo}</strong>
          </p>
        </div>
        <section>
          <p>
            {sessionStorage.getItem("nombreUsuario")}, para registrar la cita de asesoría primero necesitamos
            contar con el detalle de tus aportaciones en la ONP.<br />
            Te hemos enviado por correo el formato para que registres los periodos laborales. Por favor revisa
            tu bandeja de spam o correo no deseado. Gracias.
          </p>

          <section className="section-buttons d-flex justify-content-around">
            <button onClick={HandleCloseModal} className="btn-white">
              Cerrar
            </button>
          </section>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalExitoso);
