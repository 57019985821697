import React from "react";

const GraficOneActiveIcon = ({ width = 85, height = 54 }) => {
  return (
    <svg
      width="86"
      height="55"
      viewBox="0 0 86 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5947 13.8113L24.7549 28.1701C29.1303 22.7546 35.8251 19.2906 43.333 19.2906V0.672852C31.2425 0.672852 20.333 5.71702 12.5947 13.8113Z"
        fill="#CDCED0"
      />
      <path
        d="M43.333 0.672852V19.2906C50.8041 19.2906 57.4737 22.7217 61.8492 28.0928L74.0035 13.7416C66.271 5.688 55.3886 0.672852 43.333 0.672852Z"
        fill="#CDCED0"
      />
      <path
        d="M85.8321 43.13C85.8321 31.7246 81.3289 21.3693 74.003 13.7412L61.8486 28.0923C65.1902 32.1946 67.1959 37.4283 67.1959 43.13H85.8321V43.13Z"
        fill="#CDCED0"
      />
      <path
        d="M39.3814 39.7759L36.4697 39.9463C35.3796 40.0102 34.8083 41.3004 35.5197 42.0941L37.6621 44.4849C37.6803 44.8375 37.7267 45.1907 37.8076 45.5449C38.5797 48.9286 41.9982 50.9529 45.4412 50.0652C48.8841 49.1776 51.0502 45.7134 50.2781 42.3298C49.5059 38.9461 46.0874 36.9218 42.6445 37.8095C41.3432 38.145 40.226 38.8475 39.3791 39.7746L39.3814 39.7759Z"
        fill="#CDCED0"
      />
      <path
        d="M0.833008 43.1307H19.4692C19.4692 40.4812 19.9021 37.9331 20.701 35.5527L3.04984 29.5615C1.6122 33.8223 0.833008 38.3856 0.833008 43.1307Z"
        fill="#CE1336"
      />
      <path
        d="M3.04984 29.5615L20.701 35.5527C21.6107 32.8419 22.9949 30.3484 24.7545 28.1704L12.5944 13.8115C8.34824 18.2529 5.05718 23.6124 3.04984 29.5615Z"
        fill="#CDCED0"
      />
    </svg>

    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 86 54"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M12.7576 13.1384L24.9178 27.4973C29.2933 22.0818 35.9881 18.6178 43.496 18.6178V0C31.4055 0 20.4959 5.04416 12.7576 13.1384Z"
    //     fill="#EDEDED"
    //   />
    //   <path
    //     d="M0.995911 42.4581H19.6322C19.6322 36.7912 21.6127 31.5884 24.9175 27.4978L12.7573 13.1389C5.47008 20.7613 0.995911 31.0875 0.995911 42.4581Z"
    //     fill="#CDCED0"
    //   />
    //   <path
    //     d="M43.4959 0V18.6178C50.9671 18.6178 57.6367 22.0489 62.0121 27.4199L74.1665 13.0688C66.434 5.01515 55.5516 0 43.4959 0Z"
    //     fill="#EDEDED"
    //   />
    //   <path
    //     d="M85.9951 42.4572C85.9951 31.0517 81.4919 20.6965 74.1659 13.0684L62.0115 27.4195C65.3531 31.5217 67.3589 36.7554 67.3589 42.4572H85.9951Z"
    //     fill="#EDEDED"
    //   />
    //   <path
    //     d="M41.3835 37.8914L38.6418 36.9494C37.6153 36.5968 36.6358 37.5949 37.0121 38.6113L38.1454 41.6729C38.0383 42.0117 37.957 42.3617 37.907 42.7257C37.4293 46.203 39.8668 49.4027 43.3499 49.8707C46.833 50.3388 50.0453 47.8983 50.5229 44.421C51.0006 40.9437 48.5631 37.744 45.08 37.276C43.7635 37.0991 42.4877 37.3374 41.3819 37.8893L41.3835 37.8914Z"
    //     fill="#EDEDED"
    //   />
    // </svg>
  );
};

export default GraficOneActiveIcon;
