import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { montoFormat } from "../../../../../helper/amountFormat";
/* Icon component */
import SavingIcon from "../../../../../assets/IconBucket/Icon/SavingIcon";
import ChartIcon from "../../../../../assets/IconBucket/Icon/ChartIcon";
import CoinsIcon from "../../../../../assets/IconBucket/Icon/CoinsIcon";

/* componentes de material ui */
import {
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";

/* icon material ui */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

/* estilos del componente */
export const styles = {
  cardStyle: {
    width: "100%",
    fontSize: "10px",
    border: "1.5px solid #CDCED0",
    borderRadius: "16px",
    cursor: "pointer",
    boxShadow: "0px 4.09442px 13.3069px rgba(27, 29, 39, 0.12)",
    mb: 2,
  },

  gridIcon: {
    // minWidth: "40px",
    backgroundColor: "#CE1336",
    border: 0,
    borderRadius: "16px  0 0 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const ProductCard = ({ datosCambioFondo }) => {
  const desktop = useMediaQuery("(max-width:1430px)");
  const history = useHistory();

  /* material ui */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  //const desktop = useMediaQuery(theme.breakpoints.down("desktop"));

  /* manejo del evento onClick */
  const getClient = localStorage.getItem("client");
  const parseClient = JSON.parse(getClient);
  const handleURL = (url, product) => {
    sessionStorage.setItem("product", product);
    history.push(url);
  };

  return (
    <>
      {datosCambioFondo.map((item, index) => (
        <Card
          key={index}
          sx={styles.cardStyle}
          onClick={() => {
            Number(item.r_saldo) !== 0 &&
              handleURL(
                "/mi-habitat-digital/afiliado/tipo-jubilacion",
                item.r_cuenta
              );
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <Grid item sx={styles.gridIcon} xs={1}>
              {item.r_cuenta === "OBL" && (
                <SavingIcon size={24} color="white" />
              )}
              {item.r_cuenta === "VCF" && (
                <SavingIcon size={24} color="white" />
              )}
              {item.r_cuenta === "VSF" && <ChartIcon size={24} color="white" />}
            </Grid>
            <Grid
              item
              xs={11}
              sx={{
                pl: 1,
                pt: "13px",
                pb: "13px",
                pr: "13px",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
                    {item.r_cuenta === "OBL" && "Fondo de Jubilación"}
                    {item.r_cuenta === "VCF" && "Fondo con Fin Previsional"}
                    {item.r_cuenta === "VSF" && "Fondo Libre Habitat"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                        variant="subtitle2"
                      >
                        Al día de hoy tienes:
                      </Typography>
                      <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
                        S/. {montoFormat(item.r_saldo)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {Number(item.r_saldo) !== 0 ? (
                        <ArrowForwardIosIcon
                          sx={{ color: "#ACAFB3", fontSize: "24px" }}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderTop: "1px solid #EDEDED",
                    pt: 1,
                    mt: 1,
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box item xs={4}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "14px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        Se encuentra en:
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        label={
                          <Typography
                            variant="caption"
                            color="white"
                            sx={{
                              fontWeight: "bold",
                              height: "12px",
                              lineHeight: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item.r_fondo === "0" && "Fondo 0 - Protegido"}
                            {item.r_fondo === "1" && "Fondo 1 - Conservador"}
                            {item.r_fondo === "2" && "Fondo 2 - Moderado"}
                            {item.r_fondo === "3" && "Fondo 3 - Arriesgado"}
                          </Typography>
                        }
                        size="small"
                        variant="outlined"
                        sx={{
                          mb: 0,
                          border: "none",
                          backgroundColor: "#2F6D95",
                          p: "4px 12.3676px",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};

export default ProductCard;
