import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
} from "@mui/material";

import GraficoGeneral from "./GraficoGeneral";
import GraficoFondo from "../FondoLibre/GraficoFondo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class TabsGraficos extends Component {
  constructor(props) {
    super(props);
    this.state = { value: 0, selectedFondo: 0, selectedPeriod: "1-year" };
  }

  componentDidMount() {
    const { twelveMonth } = this.props;
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeSelectFondo = (event) => {
    this.setState({
      value: event.target.value,
      selectedFondo: event.target.value,
    });
  };

  handleChangeSelectPeriod = (event) => {
    const range = event.target.value;
    this.props.onChangeSelectPeriodo(range);
  };

  render() {
    const { data, windowWidth, valoresCuotaActual, isMobile } = this.props;

    return (
      <Fragment>
        <Box sx={{ width: "100%" }}>
          <div className="no-gutters row w-100">
            <div className="col-3 pr-2">
              <FormControl
                className="select_ni_custom"
                fullWidth
                variant="filled"
              >
                <InputLabel id="periodo-select-filled-label">
                  Periodo
                </InputLabel>
                <Select
                  labelId="periodo-select-filled-label"
                  id="periodo-select-filled"
                  value={this.state.selectedPeriod}
                  onChange={this.handleChangeSelectPeriod}
                >
                  <MenuItem value={"1-month"}>1 mes</MenuItem>
                  <MenuItem value={"3-month"}>3 meses</MenuItem>
                  <MenuItem value={"6-month"}>6 meses</MenuItem>
                  <MenuItem value={"1-year"}>1 año</MenuItem>
                  <MenuItem value={"5-year"}>5 años</MenuItem>
                  <MenuItem value={"8-year"}>8 años</MenuItem>
                  <MenuItem value={"15-year"}>Desde el inicio</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-3 pl-2">
              <FormControl
                className="select_ni_custom"
                fullWidth
                variant="filled"
              >
                <InputLabel id="fondo-select-label">Fondo</InputLabel>
                <Select
                  labelId="fondo-select-label"
                  id="fondo-select"
                  value={this.state.selectedFondo}
                  onChange={this.handleChangeSelectFondo}
                ></Select>
              </FormControl>
            </div>
          </div>

          <Box sx={{ borderBottom: { xs: 0, md: 0 }, borderColor: "divider" }}>
            <Tabs
              className="tabs_fondo_libre_graficos"
              value={this.state.value}
              onChange={this.handleChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? true : false}
              aria-label="basic tabs example"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Fondo 0" {...a11yProps(1)} />
              <Tab label="Fondo 1" {...a11yProps(2)} />
              <Tab label="Fondo 2" {...a11yProps(3)} />
              <Tab label="Fondo 3" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel
            className="tab_panel_grafico"
            value={this.state.value}
            index={0}
          >
            <GraficoGeneral
              twelveMonth={data}
              windowWidth={windowWidth}
              isMobile={isMobile}
            />
          </TabPanel>
          <TabPanel
            className="tab_panel_grafico"
            value={this.state.value}
            index={1}
          >
            <GraficoFondo
              data={data}
              idfondo={"fund0"}
              windowWidth={windowWidth}
              title={"Fondo 0"}
              color={"#1868b7"}
              valcuota={valoresCuotaActual.fondo0}
              isMobile={isMobile}
            />
          </TabPanel>
          <TabPanel
            className="tab_panel_grafico"
            value={this.state.value}
            index={2}
          >
            <GraficoFondo
              data={data}
              idfondo={"fund1"}
              windowWidth={windowWidth}
              title={"Fondo 1"}
              color={"#CE1336"}
              valcuota={valoresCuotaActual.fondo1}
              isMobile={isMobile}
            />
          </TabPanel>
          <TabPanel
            className="tab_panel_grafico"
            value={this.state.value}
            index={3}
          >
            <GraficoFondo
              data={data}
              idfondo={"fund2"}
              windowWidth={windowWidth}
              title={"Fondo 2"}
              color={"#48C180"}
              valcuota={valoresCuotaActual.fondo2}
              isMobile={isMobile}
            />
          </TabPanel>
          <TabPanel
            className="tab_panel_grafico"
            value={this.state.value}
            index={4}
          >
            <GraficoFondo
              data={data}
              idfondo={"fund3"}
              windowWidth={windowWidth}
              title={"Fondo 3"}
              color={"#77adff"}
              valcuota={valoresCuotaActual.fondo3}
              isMobile={isMobile}
            />
          </TabPanel>
        </Box>
      </Fragment>
    );
  }
}

export default TabsGraficos;
