import React from "react";
import {
  Description,
  Icon,
  ListItemComponent,
  NextButton,
  Title,
} from "./styles";
import { BiChevronRight } from "react-icons/bi";
import PropTypes from "prop-types";

const ListItem = ({ title, icon, description, size, disabled, onClick }) => {
  return (
    <ListItemComponent onClick={onClick} disabled={disabled} size={size}>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
      {size === "lg" && <Description>{description}</Description>}
      <NextButton>
        <BiChevronRight />
      </NextButton>
    </ListItemComponent>
  );
};

export default ListItem;

ListItem.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.node,
  description: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  size: "lg",
};
