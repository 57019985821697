
import {
    GENC_DATOS_USUARIO_ERROR,
    GENC_DATOS_USUARIO_EXITOSO,
    GENC_BLOQUEO_PASOS_GENERAL,
    GENC_INTENTO_DOCUMENTO,
    GENC_INTENTO_VOLVER_TOKEN,
    GENC_INTENTO_CONFIRMAR_TOKEN,
    GENC_INTENTO_CAMBIO_CLAVE,
    GENC_TOKEN_MOD_GENCLAVEWEB,
    ERROR_GENERICO_CLAVE_WEB

} from "../../types/generarClave";

export default (state, action) => {
    switch (action.type) {
        case ERROR_GENERICO_CLAVE_WEB:
            return {
                ...state,
                genericoError: action.payload,
            };
        case GENC_TOKEN_MOD_GENCLAVEWEB:
            return {
                ...state,
                gTokenClaveWeb: action.payload,
            };
        case GENC_DATOS_USUARIO_EXITOSO:
            return {
                ...state,
                gUsuarioExitoso: action.payload,
            };
        case GENC_DATOS_USUARIO_ERROR:
            return {
                ...state,
                gUsuarioExitoso: null,
            };
        case GENC_BLOQUEO_PASOS_GENERAL:
            return {
                ...state,
                gBloqueoPasos: action.payload,
            };
        case GENC_INTENTO_DOCUMENTO:
            return {
                ...state,
                gIntentoDocumento: action.payload,
            };
        case GENC_INTENTO_VOLVER_TOKEN:
            return {
                ...state,
                gIntentoVolverToken: action.payload,
            };
        case GENC_INTENTO_CONFIRMAR_TOKEN:
            return {
                ...state,
                gIntentoConfirmaToken: action.payload,
            };
        case GENC_INTENTO_CAMBIO_CLAVE:
            return {
                ...state,
                gintentoCambioClave: action.payload,
            };
        default:
            break;
    }
};
