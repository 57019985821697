export const PAGOS_DESEMBOLSOS_EXITOSO = 'PAGOS_DESEMBOLSOS_EXITOSO';
export const PAGOS_DESEMBOLSOS_ERROR = 'PAGOS_DESEMBOLSOS_ERROR';
export const PAGOS_DESEMBOLSOS_LOADING = 'PAGOS_DESEMBOLSOS_LOADING';
export const PAGOS_DESEMBOLSOS_CLEANER = 'PAGOS_DESEMBOLSOS_CLEANER';

export const DETALLE_TRAMITE_EXITOSO = 'DETALLE_TRAMITE_EXITOSO';
export const DETALLE_TRAMITE_ERROR = 'DETALLE_TRAMITE_ERROR';
export const DETALLE_TRAMITE_LOADING = 'DETALLE_TRAMITE_LOADING';
export const DETALLE_TRAMITE_CLEANER = 'DETALLE_TRAMITE_CLEANER';

export const CHECK_EXITOSO = 'CHECK_EXITOSO';
export const CHECK_ERROR = 'CHECK_ERROR';
export const CHECK_LOADING = 'CHECK_LOADING';
export const CHECK_CLEANER = 'CHECK_CLEANER';

export const USER_EXITOSO = 'USER_EXITOSO';
export const USER_ERROR = 'USER_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const USER_CLEANER = 'USER_CLEANER';

export const PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO = 'PAGOS_DESEMBOLSOS_PERIODOS_EXITOSO';
export const PAGOS_DESEMBOLSOS_PERIODOS_ERROR = 'PAGOS_DESEMBOLSOS_PERIODOS_ERROR';
export const PAGOS_DESEMBOLSOS_PERIODOS_LOADING = 'PAGOS_DESEMBOLSOS_PERIODOS_LOADING';
export const PAGOS_DESEMBOLSOS_PERIODOS_CLEANER = 'PAGOS_DESEMBOLSOS_PERIODOS_CLEANER';

export const PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_EXITOSO';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_ERROR';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_LOADING';
export const PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER = 'PAGOS_DESEMBOLSOS_DATA_EXCEL_CLEANER';