import React, { useReducer } from "react";
import RetiroDU038Context from "./retiroDU038Context";
import RetiroDU038Reducer from "./retiroDU038Reducer";
import clienteAxios from "../../config/axios";
import {
  RETIRO_DU038_GET_EXITOSO,
  RETIRO_DU038_GET_ERROR,
  RETIRO_DU038_GET_LOADIN,
  RETIRO_DU038_CLEANER,
  RETIRO_DU038_POST_EXITOSO,
  RETIRO_DU038_POST_ERROR,
  RETIRO_DU038_POST_LOADIN,
} from "../../types";

const RetiroDU038State = (props) => {
  const initialState = {
    // datosRetiroApvSinFin: null,
    // conTokenDigital: null,
    // -------------------------
    datosAlifiadoRetiro: null,
    mensajeInformativo: null,
    mensajeAlerta: null,
    loadingPage: false,
    loadingBtn: false,
    datosResultadosAfiliadosRetiro: null
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosRetiro = async () => {
    dispatch({
      type: RETIRO_DU038_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/retiro-du038");
      dispatch({
        type: RETIRO_DU038_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, RETIRO_DU038_GET_ERROR);
    }
  };

  const limpiarVariablesRetiro = () => {
    dispatch({
      type: RETIRO_DU038_CLEANER,
    });
  };



  const grabarRetiroDU038 = async (datos) => {
    dispatch({
      type: RETIRO_DU038_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/retiro-du038/procesar", datos);
      dispatch({
        type: RETIRO_DU038_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, RETIRO_DU038_POST_ERROR);
    }
  };

  // const resetear = () => {
  //   dispatch({
  //     type: RETIRO_DU038_RESETEAR,
  //     payload: true, // mandamos el loading al boton
  //   });
  // }

  const [state, dispatch] = useReducer(RetiroDU038Reducer, initialState);
  return (
    <RetiroDU038Context.Provider
      value={{
        // datosRetiroApvSinFin: state.datosRetiroApvSinFin,
        datosAlifiadoRetiro: state.datosAlifiadoRetiro,
        datosResultadosAfiliadosRetiro: state.datosResultadosAfiliadosRetiro,
        mensajeInformativo: state.mensajeInformativo,
        // conTokenDigital:state.conTokenDigital,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        datosResultadoRetiroApvSinFin: state.datosResultadoRetiroApvSinFin,
        obtenerDatosRetiro,
        limpiarVariablesRetiro,
        grabarRetiroDU038,
        // resetear
      }}
    >
      {props.children}
    </RetiroDU038Context.Provider>
  );
};

export default RetiroDU038State;
