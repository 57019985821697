import { Grid, Box, Container } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { BreadCrumbsFL } from "./components/BreadcrumbsFL";
import APV from "./../../../../assets/icons/icon_retiro_aporte_voluntario.svg";
import IC_COIN from "./../../../../assets/icons/coins.svg";
import CREDITO_HIPOTECARIO from "./../../../../assets/icons/icon_credito_hipotecario.svg";
import TRANSFERENCIA_EXTERIOR from "./../../../../assets/icons/icon_transferencia_al_exterior.svg";
import { CustomSelect, StyledOption } from "../../ListadoTramites";

const styleText = {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  paddingLeft: "8px",
  letterSpacing: "0.2px",
  color: "#2B2B2B",
};

export const Data = ({ check }) => {
  const [vista, setVista] = useState("C");
  return (
    <>
      <BreadCrumbsFL title_mobile={"Home"} title="Trámites" />
      <Grid
        container
        sx={{
          paddingTop: { xs: "100px", md: "96px" },
          background: { xs: "#ffffff", md: "#f8f9fb" },
          paddingBottom: { xs: "82px", md: "0px" },
          minHeight: { xs: "78vh", md: "auto" },
          // boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid item xs={12} lg={12} p={2}>
          <Box
            sx={{
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
              borderRadius: "16px",
              background: "#FFFFFF",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1.5rem",
              }}
            >
              <div style={styleText}>
                Selecciona el tipo de solicitud para ver su estado actual:
              </div>

              <Box
                sx={{ display: { xs: "none", md: "block", lg: "block" } }}
                style={{
                  width: "150px",
                }}
              >
                <CustomSelect defaultValue="C" onChange={setVista}>
                  <StyledOption value={"Ordenar"} disabled>
                    Ordenar
                  </StyledOption>
                  <StyledOption value={"C"}>Cuadricula</StyledOption>
                  <StyledOption value={"L"}>Lista</StyledOption>
                </CustomSelect>
              </Box>
            </div>

            <Grid
              container
              rowSpacing={2}
              style={{
                padding: "8px",
              }}
            >
              {check?.tramite_retiro_apv && (
                <Grid
                  item
                  xs={12}
                  md={vista == "C" ? 6 : 12}
                  style={{
                    padding: "0",
                  }}
                >
                  <Card
                    titulo="Retiro de Fondo Libre Habitat"
                    imagen={APV}
                    tipo={"retiro-apv"}
                  />
                </Grid>
              )}

              {check?.tramite_tfe && (
                /* true && */
                <Grid
                  item
                  xs={12}
                  md={vista == "C" ? 6 : 12}
                  style={{
                    padding: "0",
                  }}
                >
                  <Card
                    titulo="Transferencia al Exterior"
                    imagen={TRANSFERENCIA_EXTERIOR}
                    tipo={"tfe"}
                  />
                </Grid>
              )}

              {check?.tramite_credito_hipotecario && (
                /* true && */
                <Grid
                  item
                  xs={12}
                  md={vista == "C" ? 6 : 12}
                  style={{
                    padding: "0",
                  }}
                >
                  {/* <Card titulo='Retiro 25% para vivienda' imagen={CREDITO_HIPOTECARIO} tipo={'credito-hipotecario'} /> */}
                  <Card
                    titulo="Retiro 25% para vivienda"
                    imagen={CREDITO_HIPOTECARIO}
                    tipo={"credito-hipotecario"}
                  />
                </Grid>
              )}

              {check?.tramite_retiros_extraordinarios && (
                /* true && */
                <Grid
                  item
                  xs={12}
                  md={vista == "C" ? 6 : 12}
                  style={{
                    padding: "0",
                  }}
                >
                  <Card
                    titulo="Retiros Extraordinarios"
                    imagen={IC_COIN}
                    tipo={"retiros-extraordinarios"}
                  />
                </Grid>
              )}
            </Grid>

            <Container sx={{ padding: "1rem" }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const ContainerS = styled(Container)`
  display: flex;
  height: 68px;
  border-radius: 16px;
  border: solid 1px #ededed;
  cursor: pointer;
  :hover {
    background: #ededed;
  }
`;

const Card = ({ titulo, imagen, tipo }) => {
  let history = useHistory();

  const goDetalle = (e) => {
    e.preventDefault();
    history.push("/mi-habitat-digital/afiliado/estado-de-tramite/" + tipo);
  };

  return (
    <>
      <Box
        onClick={goDetalle}
        style={{
          padding: "8px",
          display: "flex",
        }}
      >
        <ContainerS
          style={{
            display: "flex",
          }}
        >
          <Grid container columns={{ xs: 20 }}>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FFEBEF",
                  borderRadius: "5px",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                <img src={imagen} alt="detalle-tramite" />
              </Box>
            </Grid>
            <Grid
              item
              xs={17}
              sx={{
                padding: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={styleText}>{titulo}</div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                margin: "auto",
              }}
            >
              <ChevronRightIcon sx={{ color: "#2B2B2B" }} />
            </Grid>
          </Grid>
        </ContainerS>
      </Box>
    </>
  );
};
