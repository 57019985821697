import React, { useState } from "react";
import RedDownArrow from "../../../../../assets/icons/red-down-arrow.svg";
import RedUpArrow from "../../../../../assets/icons/red-up-arrow.svg";
import { Collapse } from "reactstrap";
import Grid from "@mui/material/Grid";

import "./index.css";

export default function MovementRow(props) {
  const { date, fee, amount, description } = props;
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <Grid container className="movements-section_movement">
      <Grid item xs={12} className="movements-section_row">
        <Grid container className="movements-section_column-info">
          <Grid
            item
            xs={4}
            sx={{
              textAlign: "center",
            }}
          >
            <span>{date}</span>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              textAlign: "center",
            }}
          >
            <span className="bold">{fee}</span>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              textAlign: "center",
            }}
          >
            <span className={`bold ${amount > 0 ? "c-blue" : "c-red"}`}>
              {`S/ ${amount}`}{" "}
              <img
                src={collapse ? RedUpArrow : RedDownArrow}
                alt=""
                onClick={toggle}
              />
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse isOpen={collapse}>
          <div className="pb-3">
            <div className="movements-section_hidde-message-block">
              <p>Correspondiente a {description}</p>
            </div>
          </div>
        </Collapse>
      </Grid>
    </Grid>
  );
}
