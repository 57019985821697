import React, { useState, useEffect } from "react";
import hocWidthWindow from "../../../HOC/HocWidthWindow";
import SolicitudNoProcesada from "../SolicitudNoProcesada";
import SolicitudProcesada from "../SolicitudProcesada";
import ModalToken from "../../../components/ModalToken";
import { obtenerCtaRemesa } from "../../../utils/formulaRemesa";
import ModalExitFatca from "./ModalExitFatca";
import PasoDatosDeRetiro from "../PasoDatosDeRetiro";

import { Box, Paper } from "@mui/material";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import NavigationButtons from "../componentes/NavigationButtons";
import ModalCondicionesDeRetiro from "../ModalCondicionesDeRetiro";
import ModalConfirmacion from "../ModalConfirmacion";
import PasoResultadoDeSolicitud from "../PasoResultadoDeSolicitud";
import useDecodedFiles from "../utils/useDecodeFiles";

const PrimerPaso = (props) => {
  /** aqui solo cae cuando r_validacion es 1 o 2 */
  const { data, montoTotal, resultado } = props;
  const [openModalToken, setOpenModalToken] = useState(false);
  const [openModalTokenError, setOpenModalTokenError] = useState(false);
  const [modalCondicionesDeRetiroIsOpen, setModalCondicionesDeRetiroIsOpen] =
    useState(false);
  const [modalDeConfirmacionIsOpen, setModalDeConfirmacionIsOpen] =
    useState(false);
  const [imageFile, setImageFile] = useState(null);

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (resultado) {
      if (Number(resultado.result) === 9) {
        setOpenModalToken(false);

        setOpenModalTokenError(true);
      } else {
        setStep(1);
        setOpenModalToken(false);
      }
    }

    // eslint-disable-next-line
  }, [resultado]);

  const formMethods = useForm({
    mode: "all",
    criteriaMode: "all",
  });

  const { watch, handleSubmit } = formMethods;

  var result = {
    result: "1",
    message: "",
    r_nro_solicitud: "r_nro_solicitud",
    r_banco: "r_banco",
    r_num_cuenta: "r_num_cuenta",
    r_tipo_cuenta: "r_tipo_cuenta",
    r_monto: "500000.2",
    r_tipo_retiro: "r_tipo_retiro",
    r_flujo: "r_flujo",
    r_documento_identidad: "r_documento_identidad",
    r_numero_documento: "r_numero_documento",
    r_cuenta_remesa: "r_cuenta_remesa",
    data_solicitud: "data_solicitud",
  };

  const files = useDecodedFiles(watch("evidenciaBancaria"));

  useEffect(() => {
    setImageFile(files);
  }, [files]);

  const submitRetiro = (data, token) => {
    const payload = {
      v_token_digital: token,
      v_monto: data.montoARetirar,
      v_forpag: "A",
      v_tip_pago: data.tipoDeRetiro,
      v_referencia: data.banco !== "nueva" ? data.banco : null,
      v_tipo_banco: data.banco === "nueva" ? data.nombreBanco : null,
      v_tipo_cuenta: data.banco === "nueva" ? data.tipoDeCuenta : null,
      v_nro_cuenta: data.banco === "nueva" ? data.numeroDeCuenta : null,
      v_cuenta_remesa:
        Number(data.nombreBanco) === 7
          ? obtenerCtaRemesa(data.numeroDeCuenta)
          : null,
      v_adjunto: imageFile,
      v_adjunto_blob: data.evidenciaBancaria?.[0],
      v_adjunto_name: data.evidenciaBancaria?.[0].name,
      v_adjunto_type: data.evidenciaBancaria?.[0].type,
      v_adjunto_size: data.evidenciaBancaria?.[0].size,
    };

    props.procesarSolicitud(payload);
  };

  return (
    <Paper style={{ marginTop: "16px", padding: "16px" }}>
      <FormProvider {...formMethods}>
        {step === 0 && <PasoDatosDeRetiro montoTotal={montoTotal} />}
        {step === 1 && resultado && (
          <PasoResultadoDeSolicitud resultado={resultado} />
        )}

        {modalCondicionesDeRetiroIsOpen && (
          <ModalCondicionesDeRetiro
            open={modalCondicionesDeRetiroIsOpen}
            onNext={() => {
              setModalDeConfirmacionIsOpen(true);
              setModalCondicionesDeRetiroIsOpen(false);
            }}
            onClose={() => setModalCondicionesDeRetiroIsOpen(false)}
          />
        )}
        {modalDeConfirmacionIsOpen && (
          <ModalConfirmacion
            open={modalDeConfirmacionIsOpen}
            onNext={() => {
              setOpenModalToken(true);
              setModalDeConfirmacionIsOpen(false);
            }}
            onClose={() => setModalDeConfirmacionIsOpen(false)}
          />
        )}
        <NavigationButtons
          activeStep={step}
          onNext={{
            0: () => {
              setModalCondicionesDeRetiroIsOpen(true);
            },
          }}
        />
      </FormProvider>

      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(!openModalToken);
          }} // fn para cerrar modal
          guardarCode={(token) => {
            handleSubmit((data) => submitRetiro(data, token))();
            // setStep(1);
            // setOpenModalToken(false);
          }}
        />
      )}

      {openModalTokenError && (
        <ModalExitFatca
          message={resultado.r_mensaje}
          cerrarModal={() => {
            setOpenModalTokenError(false);
          }} // fn para cerrar modal
          guardarCode={() => {
            setOpenModalTokenError(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </Paper>
  );
};

export default hocWidthWindow(PrimerPaso);
