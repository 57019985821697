import React from "react";
import * as actions from "./actions";

// Initial state
const requestInitialState = {
  data: null,
  status: "idle", // idle loading success failure
  error: null,
};

export const initialState = {
  request: { ...requestInitialState },
};

const reducer = (state, action) => {
  switch (action.type) {
    // affiliate
    case actions.GET_REQUEST_INFO:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "loading",
        },
      };
    case actions.GET_REQUEST_INFO_SUCCESS:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "success",
          data: action.payload,
        },
      };
    case actions.GET_REQUEST_INFO_FAILURE:
      return {
        ...state,
        request: {
          ...requestInitialState,
          status: "failure",
          error: action.payload,
        },
      };
    case actions.RESET_REQUEST_INFO_INFO:
      return {
        ...state,
        request: {
          ...requestInitialState,
        },
      };

    default:
      break;
  }
};

export default reducer;
