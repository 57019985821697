import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../../../utils/device";

export const ContentPage = styled(Box)`
  width: 100%;
  background-color: ${(p) => p.theme.palette.white};

  ${css`
    &&& {
      @media screen and ${device.tablet} {
        margin: 16px;
        border-radius: 16px;
        background-color: ${(p) => p.theme.palette.white};
      }
    }
  `}
`;
