import React, { useReducer } from "react";
import BoletaPagoContext from "./boletapagoContext";
import BoletaPagoReducer from "./boletapagoReducer";
import clienteAxios from "../../config/axios";
import {
  BOLETA_PERIODO_EXITOSO,
  BOLETA_PERIODO_ERROR,
  BOLETA_PERIODO_LOADIN,

  BOLETA_CATEGORIA_EXITOSO,
  BOLETA_CATEGORIA_ERROR,
  BOLETA_CATEGORIA_LOADIN,
  CLEAR_BENEFICIARIOS,

  BOLETA_PAGO_EXITOSO,
  BOLETA_PAGO_ERROR,
  BOLETA_PAGO_LOADIN,
  BOLETA_PAGO_CLEANER,
  BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL,
  CLEAR_BOLETA,
  // BOLETA_PERIODO_Y_CATEGORIA_CLEANER_ONLY_VARIABLE
} from "../../types/boletaPagoPension";

const BoletaPagoState = (props) => {
  const initialState = {
    periodoInfo: null,
    categoriaInfo: null,
    categoriaLoading: false,
    datosBoleta: null,
    mensajeAlerta: null,
    loadingPage: false,
    loadingBtn: false
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log('entramos al default errors');
      }
    }
  };

  const obtenerDatosPeriodos = async () => {
    dispatch({
      type: BOLETA_PERIODO_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/mis-datos/boleta-pensionista");
      dispatch({
        type: BOLETA_PERIODO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, BOLETA_PERIODO_ERROR);
    }
  };

  const obtenerDatosBeneficiarios = async (datos) => {

    dispatch({
      type: BOLETA_CATEGORIA_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post("/mis-datos/boleta-pensionista/beneficiarios", datos);

      dispatch({
        type: BOLETA_CATEGORIA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, BOLETA_CATEGORIA_ERROR);
    }
  };

  const clearBeneficiarios = () => {
    dispatch({
      type: CLEAR_BENEFICIARIOS,
    });
  }
  const clearBoleta = () => {
    dispatch({
      type: CLEAR_BOLETA,
    });
  }

  const obtenerDatosBoleta = async (datos) => {
    dispatch({
      type: BOLETA_PAGO_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.post("/mis-datos/boleta-pensionista/boleta", datos);

      dispatch({
        type: BOLETA_PAGO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, BOLETA_PAGO_ERROR);
    }
  };

  const limpiarVariablesBoletaPago = () => {
    dispatch({
      type: BOLETA_PAGO_CLEANER,
    });
  };
  const limpiarVariablesBoletaPagoParcial = () => {
    dispatch({
      type: BOLETA_PERIODO_Y_CATEGORIA_CLEANER_PARCIAL,
    });
  };

  // const limpiarExternalLink = () => {
  //   dispatch({
  //     type: BOLETA_PERIODO_Y_CATEGORIA_CLEANER_ONLY_VARIABLE,
  //   });
  // }

  const [state, dispatch] = useReducer(BoletaPagoReducer, initialState);
  return (
    <BoletaPagoContext.Provider
      value={{
        periodoInfo: state.periodoInfo,
        categoriaInfo: state.categoriaInfo,
        categoriaLoading: state.categoriaLoading,
        datosBoleta: state.datosBoleta,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        obtenerDatosPeriodos,
        obtenerDatosBeneficiarios,
        clearBeneficiarios,
        clearBoleta,
        obtenerDatosBoleta,
        limpiarVariablesBoletaPago,
        limpiarVariablesBoletaPagoParcial,
        // limpiarExternalLink
      }}
    >
      {props.children}
    </BoletaPagoContext.Provider>
  );
};

export default BoletaPagoState;
