import { Grid, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import GenerarClaveContext from "../../../../context/generarClave/generarClaveContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ErrorMessage from "../../errors/error";
import Countdown from "../Counter/counter";
import "../Forms/index.css";

const EnvioModal = (props) => {
  const {
    changeStep,
    bloquear,
    openCI,
    openSD,
    changePrevEnvio,
    closeModalPrincipal,
    moreTime = false,
  } = props;
  const generarClaveContexto = useContext(GenerarClaveContext);
  const {
    gTokenClaveWeb,
    gUsuarioExitoso,
    gIntentoVolverToken,
    gIntentoConfirmaToken,
    gBloqueoPasos,
    bloquearPasos,
    cambioIntentoDocumento,
    cambioIntentoVolverToken,
    cambioIntentoConfirmaToken,
    cambioIntentoClave,
    envioTokenDigital,
    confirmaTokenDigital,
    LlenarErrorGenerico,
    datosInicialUsuario,
  } = generarClaveContexto;
  const { register } = useForm();
  const globalErrors = {
    r_document: "",
    r_envioToken: "",
  };
  const [firstSend, setFirstSend] = useState(false);
  const [timer, setTimer] = useState(10000);
  const [changeForm, setChangeForm] = useState(false); //n2
  const [dataForm, setDataForm] = useState({ documentType: "NN", code: "" });
  const [errores, setErrores] = useState(globalErrors);
  const [validateButton, setValidateButton] = useState(false);
  const [reactivateCount, setReactivateCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOP, setLoadingOP] = useState(false);
  const styles = {
    menuItemStyles: {
      "&:hover": {
        backgroundColor: "#F2FAFF !important",
      },
    },
  };
  let listOptions = [
    {
      value: "NN",
      text: " Selecciona lugar de envío",
    },
    {
      value: "EMAIL",
      text: " Correo: " + gUsuarioExitoso.email,
      html: <EmailOutlinedIcon style={{ fontSize: 20 }}></EmailOutlinedIcon>,
    },
    {
      value: "SMS",
      text: " Celular: " + gUsuarioExitoso.cel,
      html: (
        <ChatBubbleOutlineOutlinedIcon
          style={{ fontSize: 20 }}
        ></ChatBubbleOutlineOutlinedIcon>
      ),
    },
    {
      value: "OTROS",
      text: "No cuento con esos datos",
    },
  ];

  const [listaOpciones, setListaOpciones] = useState(listOptions);

  useEffect(() => {
    if (!["", null, undefined].includes(gUsuarioExitoso)) {
      listaOpciones[1].text = " Correo: " + gUsuarioExitoso.email;
      listaOpciones[2].text = " Celular: " + gUsuarioExitoso.cel;
      setListaOpciones(listaOpciones);
    } else {
      datosInicialUsuario();
      setListaOpciones([]);
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }
  }, [gUsuarioExitoso]);

  const todosIntentosBloqueados = () => {
    cambioIntentoDocumento(3);
    cambioIntentoVolverToken(3);
    cambioIntentoConfirmaToken(3);
    cambioIntentoClave(3);
  };

  const handleDocumentType = (e) => {
    setDataForm({ ...dataForm, documentType: e.target.value });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      let body = {
        canal: dataForm.documentType,
        token: gTokenClaveWeb,
        moreTime: true,
      };
      let { response, intentosG, bloqueadoG } = await envioTokenDigital(body);
      if (bloqueadoG || (intentosG > 3 && response)) {
        closeModalPrincipal();
        bloquear();
        bloquearPasos(true);
        todosIntentosBloqueados();
        setLoading(false);
      } else {
        if (response) {
          setLoading(false);
          setErrores({
            ...errores,
            r_document: "Hubo un error, intentelo más tarde",
          });
        } else {
          setLoading(false);
          setFirstSend(true);
          setChangeForm(true);
        }
      }
    } catch (error) {
      setLoading(false);
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }
  };

  const resendCode = async () => {
    try {
      let body = {
        canal: dataForm.documentType,
        token: gTokenClaveWeb,
        moreTime: true,
      };

      let { response, intentosG, bloqueadoG } = await envioTokenDigital(body);
      if (bloqueadoG || (intentosG > 3 && response)) {
        closeModalPrincipal();
        bloquear();
        bloquearPasos(true);
        todosIntentosBloqueados();
      } else {
        if (response) {
          setErrores({
            ...errores,
            r_document: "Hubo un error, intentelo más tarde",
          });
        } else {
          setTimer(1000);
          setReactivateCount(true);
        }
      }
    } catch (error) {
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }
  };

  const sendActivate = () => {
    setTimer(0);
    setReactivateCount(false);
  };

  const confirmOperation = async () => {
    try {
      setLoadingOP(true);
      let body = { token_digital: dataForm.code, token: gTokenClaveWeb };

      let { response, intentosG } = await confirmaTokenDigital(body);
      if (intentosG > 2 && response) {
        closeModalPrincipal();
        bloquear();
        bloquearPasos(true);
        todosIntentosBloqueados();
        setLoadingOP(false);
      } else {
        if (response) {
          setLoadingOP(false);
          openCI();
        } else {
          setLoadingOP(false);
          changeStep();
        }
      }
    } catch (error) {
      setLoadingOP(false);
      LlenarErrorGenerico({
        message: "Error en los servicios, por favor recargue la página.",
        activo: true,
      });
    }
  };

  const openContacto = () => {
    openSD();
  };

  const validateData = (event) => {
    let mensajesError = {
      r_document: "",
    };
    const { name, value } = event.target;
    if (name === "documentType") {
      setDataForm({ ...dataForm, [name]: value });
      return;
    }
    mensajesError = errores;
    let valueDoc = value.replace(/[^0-9]/g, "");
    // if (!regexOnlyNumber(value)) {
    //     mensajesError.r_document = 'Debe utilizar solo números.'
    //     setValidateButton(false)

    // }
    if (valueDoc.length < 6) {
      mensajesError.r_document = "Debe utilizar 6 digitos.";
      setValidateButton(false);
    } else {
      mensajesError.r_document = "";
      setValidateButton(true);
    }
    setDataForm({ ...dataForm, [name]: valueDoc });
    setErrores(mensajesError);
  };

  useEffect(() => {
    if (changePrevEnvio) {
      setChangeForm(false);
      setDataForm({ documentType: "EMAIL", code: "" });
      setValidateButton(false);
    }
  }, [changePrevEnvio]);

  return (
    <div className="">
      <div>
        <div className="new-home-form-group-web mt-3 content-select-web">
          <label htmlFor="document-type">
            Deseo recibir mi token digital en:
          </label>
          <br />
          <Select
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiInputBase-root": {
                borderRadius: "20px",
                border: "1px solid #e0e1e2",
                backgroundColor: "#F5F5F5",
                width: "100%",
              },
              "&.MuiPaper-root": {
                boxShadow: "0px 4px 13px 0px rgba(27, 29, 39, 0.12) !important",
              },
            }}
            id="document-type"
            name="documentType"
            {...register("documentType", { required: true })}
            onChange={(e) => handleDocumentType(e)}
            value={dataForm.documentType}
          >
            {/* {listaOpciones &&
              listaOpciones.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.html ? item.html : ""}
                  <span className="ml-2"> {item.text} </span>
                </MenuItem>
              ))} */}
            <MenuItem sx={styles.menuItemStyles} value={"NN"}>
              Selecciona lugar de envío
            </MenuItem>
            {gUsuarioExitoso?.email && (
              <MenuItem sx={styles.menuItemStyles} value={"EMAIL"}>
                Correo: {gUsuarioExitoso.email}
              </MenuItem>
            )}
            {gUsuarioExitoso?.cel && (
              <MenuItem sx={styles.menuItemStyles} value={"SMS"}>
                Celular: {gUsuarioExitoso.cel}
              </MenuItem>
            )}
            <MenuItem sx={styles.menuItemStyles} value={"OTROS"}>
              No cuento con estos datos
            </MenuItem>
          </Select>
          <ErrorMessage
            mensaje={errores.r_envioToken}
            mensajeletra={true}
          ></ErrorMessage>
        </div>
        {!changeForm ? (
          ""
        ) : (
          <div className="new-home-form-group-web">
            <label htmlFor="codigo">Código de confirmación</label>
            <input
              className="form-field bg-form-web modify-select"
              id="codigo"
              type="text"
              autoComplete="off"
              name="code"
              value={dataForm.code}
              minLength={6}
              maxLength={6}
              onChange={validateData}
              placeholder="_ _ _ _ _ _"
            />
            <ErrorMessage
              mensaje={errores.r_document}
              mensajeletra={true}
            ></ErrorMessage>
          </div>
        )}

        {dataForm.documentType === "OTROS" ? (
          <div className="mt-4">
            <small>
              Por tu seguridad si ya no cuentas con tu celular y/o correo
              registradados deberas comunicarte al
              <b> 01 230 2200</b>
            </small>

            <div className="sindatos margin-center text-center mt-2">
              <u onClick={openContacto}> ¿No puedes validar esta solicitud?</u>
            </div>
          </div>
        ) : (
          <div>
            {firstSend ? (
              <>
                {timer !== 0 ? (
                  <small className="letter-codigo-token">
                    {" "}
                    <b>
                      En caso de que no te llegue el código, podrás volver a
                      <br />
                      solicitarlo en:
                      <Countdown
                        sendActivate={sendActivate}
                        reactivate={reactivateCount}
                        startingMinutes={moreTime ? 3 : 2}
                      ></Countdown>
                    </b>
                  </small>
                ) : (
                  <small className="letter-codigo-token">
                    {" "}
                    <b>
                      En caso de que no te llegue el código, podrás volver a
                      <br />
                      solicitarlo ahora
                    </b>
                  </small>
                )}
              </>
            ) : (
              ""
            )}
            {gIntentoVolverToken === 2 ? (
              <div className="error-block mt-2">
                <p>Te queda solo un intento permitido de solicitud de código</p>
              </div>
            ) : (
              ""
            )}
            {gIntentoConfirmaToken === 2 && changeForm ? (
              <div className="error-block mt-2">
                <p>
                  Te queda solo un intento permitido de confirmación de
                  operación
                </p>
              </div>
            ) : (
              ""
            )}

            {!changeForm ? (
              <div>
                <hr />
                {loading ? (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <button
                        className="btn-recover margin-center btn-modal-total disabled-button-web"
                        disabled
                      >
                        {loading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        <span className="ml-1">Enviar</span>
                      </button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <button
                        onClick={onSubmit}
                        className={`btn-recover margin-center btn-modal-total ${
                          dataForm.documentType === "NN" || gBloqueoPasos
                            ? "disabled-button-web"
                            : ""
                        }`}
                        disabled={
                          dataForm.documentType === "NN" || gBloqueoPasos
                        }
                      >
                        Enviar{" "}
                      </button>
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : (
              <div>
                <Grid justifyContent="center">
                  <Grid item xs={12}>
                    <button
                      onClick={resendCode}
                      className={`mt-3 btn-recover margin-center btn-modal-total ${
                        timer !== 0 ||
                        dataForm.documentType === "NN" ||
                        gBloqueoPasos
                          ? "disabled-button-secondary"
                          : "btn-secundary"
                      }`}
                      disabled={
                        timer !== 0 ||
                        dataForm.documentType === "NN" ||
                        gBloqueoPasos
                      }
                    >
                      <AutorenewIcon></AutorenewIcon>
                      Volver a enviar código{" "}
                    </button>
                  </Grid>

                  {loadingOP ? (
                    <Grid item xs={12}>
                      <button
                        className="mt-3 btn-recover margin-center btn-modal-total disabled-button-web"
                        disabled
                      >
                        {loadingOP && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        <span className="ml-1"> Confirmar operación</span>
                      </button>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <button
                        onClick={confirmOperation}
                        className={` mt-3 btn-recover margin-center btn-modal-total ${
                          !validateButton ||
                          dataForm.documentType === "NN" ||
                          gBloqueoPasos
                            ? "disabled-button-web"
                            : ""
                        }`}
                        disabled={
                          !validateButton ||
                          dataForm.documentType === "NN" ||
                          gBloqueoPasos
                        }
                      >
                        Confirmar operación{" "}
                      </button>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EnvioModal;
