import React, { useEffect, useState, useContext, Fragment } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import { CardHeader, Card, CardBody } from "reactstrap";
import { Page, NotFoundData, CardContainer } from "../../components";
import Grid from "@mui/material/Grid";

import "./index.css";
import { meses } from "../../data/DataLocal";
import InfoUsuario from "./InfoUsuario";
import ModalRecuerda from "./ModalRecuerda";
import MenuSelect from "./MenuSelect";
import GraficoLineas from "./GraficoLineas";

import Loading from "../../components/Loading/index.jsx";
import ReactGa from "react-ga";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import MisInversionesContext from "../../context/misinversiones/misinversionesContext";

const MisInversiones = (props) => {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
  const [nombreFondo, setNombreFondo] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const [fecha, setFecha] = useState({ inicio: null, fin: null });

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  const misinversionesContext = useContext(MisInversionesContext);
  const {
    datosMisInversiones,
    mensajeAlerta,
    obtenerDatosMisInversiones,
    limpiarVariablesMisInversionesParcial,
  } = misinversionesContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosMisInversiones();
    }

    return () => {
      limpiarVariablesMisInversionesParcial();
      actualizarTimer();
    };

    // eslint-disable-next-line
  }, [mensajeAlerta]);

  useEffect(() => {
    if (datosMisInversiones) {
      if (datosMisInversiones.r_validacion === 1) {
        setNombreFondo(
          datosMisInversiones.r_tipos_de_aporte[opcionSeleccionada]
            .r_descripcion
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosMisInversiones]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  const closeModal = () => () => {
    setOpenModal(false);
  };

  const formatFecha = (fecha) => {
    const fechaArray = fecha.split("-");
    return `${meses[fechaArray[1] - 1].mes.toLocaleLowerCase()}  ${
      fechaArray[0]
    }`;
  };

  const fechaInicioFin = (fechas) => {
    setFecha({
      inicio: formatFecha(fechas.inicio.date),
      fin: formatFecha(fechas.fin.date),
    });
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (datosMisInversiones === null) return <Loading />;

  return (
    <Page title=" Evolución de tu fondo" showHeader={"mobile"}>
      {datosMisInversiones.r_validacion === 0 ? (
        <CardContainer>
          <NotFoundData
            mensaje={
              "No cuentas con información en tus fondos. Te invitamos a seguir navegando dentro de mi Habitat Digital"
            }
            btnText={"Volver al inicio"}
            url={"/mi-habitat-digital/afiliado/inicio"}
            icon={true}
          />
        </CardContainer>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <MenuSelect
              fondos={
                datosMisInversiones.r_tipos_de_aporte?.filter(
                  (aporte) =>
                    datosMisInversiones[aporte.r_codigo] &&
                    datosMisInversiones[aporte.r_codigo].length
                ) || []
              }
              opcionSeleccionada={opcionSeleccionada}
              setOpcionSeleccionada={setOpcionSeleccionada}
              setNombreFondo={setNombreFondo}
            />
            <Card>
              <CardHeader className="custom-header-cambio-fondo custom-header-li d-flex justify-content-between pr-4">
                <span className="refValor">
                  ({fecha.inicio} / {fecha.fin})
                </span>
              </CardHeader>
              <CardBody className="custom-card-body">
                {datosMisInversiones.r_tipos_de_aporte?.map((item, index) => (
                  <Fragment key={index}>
                    {opcionSeleccionada === index && (
                      <GraficoLineas
                        // data={tresOpiones[item.code]}
                        fechaInicioFin={fechaInicioFin}
                        otraData={
                          datosMisInversiones[
                            datosMisInversiones.r_tipos_de_aporte?.[
                              opcionSeleccionada
                            ]?.r_codigo
                          ]
                        }
                      />
                    )}
                  </Fragment>
                ))}
              </CardBody>
            </Card>
          </Grid>
          {props.windowWidth > 600 && (
            <Grid item xs={12} lg={4}>
              <InfoUsuario mensajes={datosMisInversiones.r_tipos_de_aporte} />
            </Grid>
          )}

          {openModal && props.windowWidth < 600 && (
            <ModalRecuerda
              mensajes={datosMisInversiones.r_tipos_de_aporte}
              closeModal={closeModal}
            />
          )}
        </Grid>
      )}
    </Page>
  );
};

export default hocWidthWindow(MisInversiones);
