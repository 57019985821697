import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import hocWidthWindow from "../../HOC/HocWidthWindow";

import { montoFormat } from "../../helper/amountFormat";

am4core.useTheme(am4themes_animated);
// let mensajeToolTipFondAcum =
//   "[#000]Fon. Acumulado \n (Aport Acum. - Cargo + Rent.): \n {aportAcum} - {cargo} +  {rentAcum}  = \n S/ [#000]{fondAcum} : [#000]{date.formatDate('MMM, yyyy')}";
// let mensajeToolTipFondAcum =
//   "[#000]Fon. Acumulado  = S/ [#000]{fondAcum} : [#000]{date.formatDate('MMM, yyyy')}";
// let mensajeToolTipAportAcum =
//   "[#000]Aporte. Acumulado = S/ [#000]{aportAcum} : [#000]{date.formatDate('MMM, yyyy')}";
class GraficoLineas extends Component {
  state = {
    infoCuadros: null,
    cantidadAportes: 0,
  };

  componentDidMount() {
    const { otraData } = this.props;

    if (!otraData)
      return <></>;

    const dataFormateada = this.formatData(otraData);

    if (dataFormateada.length > 0) {
      this.setState({
        ...this.state,
        infoCuadros: dataFormateada[dataFormateada.length - 1],
        cantidadAportes: dataFormateada.length,
      });
    }
    // if (dataFormateada.length > 2) {
    this.props.fechaInicioFin({
      inicio: dataFormateada[0],
      fin: dataFormateada[dataFormateada.length - 1],
    });
    this.drawGraphic(dataFormateada);
    // }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  formatData = (data) => {
    let newData = data.map((item) => {
      const fechaArray = item.r_fecha.split("-");
      const newFecha = `${fechaArray[2]}-${fechaArray[0]}-${fechaArray[1]}`;
      return {
        date: newFecha,
        rentAcum: montoFormat(item.r_rentabilidad),
        aportAcum: montoFormat(item.r_aporte),
        fondAcum: montoFormat(item.r_saldo),
        cargo: montoFormat(item.r_retiro_cargo),
      };
    });

    return newData;
  };

  drawGraphic = (dataArray) => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.language.locale = am4lang_es_ES;
    chart.data = dataArray;

    chart.dateFormatter.dateFormat = "yyyy-MM-dd";

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 23;
    dateAxis.dateFormats.setKey("month", "MM/YY");
    dateAxis.periodChangeDateFormats.setKey("month", "MM/YY");
    dateAxis.renderer.labels.template.rotation = 270;
    dateAxis.renderer.labels.template.dx = -5;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    chart.numberFormatter.language = new am4core.Language();
    chart.numberFormatter.language.locale = am4lang_en_US;
    valueAxis.numberFormatter.numberFormat = "S/ #,###.##";
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "fondAcum";
    series.dataFields.dateX = "date";
    series.tooltipText = "S/ {fondAcum}";
    series.strokeWidth = 2;
    series.minBulletDistance = 15;

    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 10;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";

    // Make bullets grow on hover
    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#6bb8d9");

    var bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.labels.template.text = "Fondo acumulado en soles";
    chart.logo.disabled = true;
    this.chart = chart;
  };

  render() {
    return (
      <div className="container-graphic bg-white">
        <div id="chartdiv"></div>
      </div>
    );
  }
}

export default hocWidthWindow(GraficoLineas);
