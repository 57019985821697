import React from "react";
import PropTypes from "prop-types";
import { TitleComponent } from "./styles";

const Title = ({ required, children, ...props }) => {
  return (
    <TitleComponent {...props}>
      {required && <span className="required-star">* </span>}
      {children}
    </TitleComponent>
  );
};

export default Title;

Title.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

Title.defaultProps = {
  required: true,
  disabled: false,
};
