import React, { useEffect } from "react";
import {
  Body,
  Buttons,
  Divider,
  Header,
  Information,
  ModalDeConfirmacionComponent,
  Table,
  NextButton,
  PrevButton,
} from "./styles";
import { useContext } from "react";
import { montoFormat } from "../../../../helper/amountFormat";
import { BsX } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Modal } from "@mui/material";
import { cargo } from "../../DataLocal";
import getDebitFrecuencyDate from "../../utils/getDebitFrecuencyDate";
import Alert from "../../../../components/Alert";

const ModalDeConfirmacion = ({ data, open, onClose, onNext }) => {
  const {
    cuentaDestino,
    data: { debito, fecha_cargo, cargo_doble },
    banco,
    tipoCuenta,
  } = data;
  const { r_codigo, r_nombre_banco, r_numero_cuenta, r_tipo_cuenta } =
    cuentaDestino;
  const { v_monto, v_cod_frecuencia, v_num_cuenta } = debito;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDeConfirmacionComponent frecuencia={v_cod_frecuencia}>
        <Header>
          <div className="close" onClick={onClose}>
            <BsX />
          </div>
          <p className="title">Confirmación</p>
          <Divider />
        </Header>
        <Body frecuencia={v_cod_frecuencia}>
          {/* 1 === Mensual y 0 === Única */}
          {v_cod_frecuencia === "1" && (
            <Information>
              <p>
                <b>Condiciones</b>
              </p>
              <p>
                Estás a un paso de invertir en tu Fondo Libre Habitat con la
                modalidad de débito mensual.
              </p>
              <p>
                Debitaremos mensualmente de tu cuenta bancaria el monto
                seleccionado para transferirlo a tu cuenta de Fondo Libre
                Habitat.
              </p>
              <p>
                Tu primer débito se concretará dentro del rango de fechas que
                elegiste. En caso haya feriados dentro de dicho rango, puede que
                se realice unos días después.
              </p>
            </Information>
          )}
          {v_cod_frecuencia === "0" && (
            <Information frecuencia={v_cod_frecuencia}>
              <p>
                Estás a un paso de invertir en tu cuenta de Fondo Libre Habitat
                con la siguiente información.
              </p>
            </Information>
          )}
          <Table>
            <p className="title">Datos de inversión</p>
            {[
              {
                label: "Entidad financiera",
                value: cuentaDestino ? r_nombre_banco : banco.r_descripcion,
                show: true,
              },
              {
                label: "Tipo de cuenta",
                value: cuentaDestino ? r_tipo_cuenta : tipoCuenta.r_descripcion,
                show: true,
              },
              {
                label: "N.° de cuenta",
                value: cuentaDestino
                  ? r_numero_cuenta
                  : `XXXXXXX${v_num_cuenta.slice(-4)}`,
                show: true,
              },
              {
                label: "Monto a invertir",
                value: `S/ ${montoFormat(v_monto)}`,
                show: true,
              },
              {
                label: "Frecuencia de débito",
                value:
                  cargo.opciones.find((e) => e.codigo === v_cod_frecuencia)
                    .nombre || "",
                show: true,
              },
              {
                label: "Fecha de débito",
                value: getDebitFrecuencyDate(fecha_cargo),
                show: v_cod_frecuencia === "1",
              },
              {
                label: "Cargos dobles en julio y diciembre ",
                value: cargo_doble ? "Activo" : "Inactivo",
                show: v_cod_frecuencia === "1",
              },
            ].map(
              ({ label, value, show }, key) =>
                show && (
                  <div className="tableRow" key={key}>
                    <p className="label">{label}</p>
                    <p className="value">{value}</p>
                  </div>
                )
            )}
          </Table>
        </Body>
        <Alert description="Recuerda que debes ser el titular de la cuenta o tarjeta ingresada." />
        {v_cod_frecuencia === "1" && (
          <Alert description="Registrar un débito mensual no impide que puedas seguir invirtiendo con la frecuencia única, si así lo deseas." />
        )}
        <Divider />
        <Buttons>
          <PrevButton onClick={onClose}>Volver</PrevButton>
          <NextButton onClick={onNext}>Confirmar</NextButton>
        </Buttons>
      </ModalDeConfirmacionComponent>
    </Modal>
  );
};

export default ModalDeConfirmacion;
