import React from "react";
import { montoFormat } from "../../../helper/amountFormat";
import * as Table from "./tableComponents";

const PartFour = ({ impuestoRenta, saldoRetirado, retiroFondoLibre }) => {
  return (
    <Table.Wrapper>
      <Table.Heading>Parte IV: Impuesto a la Ganancia de Capital</Table.Heading>
      <Table.Row>
        <Table.Label>Detalle</Table.Label>
        <Table.Label borderRight={true}>Monto en soles</Table.Label>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Retiro Fondo Libre Habitat
        </Table.Detail>
        <Table.Detail borderRight={true}>
          {montoFormat(retiroFondoLibre)}
        </Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Retención por Impuesto a la Renta
        </Table.Detail>
        <Table.Detail borderRight={true}>
          {montoFormat(impuestoRenta)}
        </Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Detail
          textStyle={{ fontFamily: "Helvetica-Bold", textAlign: "left" }}
        >
          Saldo a Abonar
        </Table.Detail>
        <Table.Detail borderRight={true}>
          {montoFormat(saldoRetirado)}
        </Table.Detail>
      </Table.Row>
    </Table.Wrapper>
  );
};

export default PartFour;
