import React, { useContext, createContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import * as actionsCreators from "./actionsCreators";
import { useZonaDeBeneficiariosContext } from "../../../container/Layout/BeneficiaryZone/context";

const BuscarTramiteContext = createContext();

export const useBuscarTramiteContext = () => {
  return useContext(BuscarTramiteContext);
};

export const BuscarTramiteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setGeneralError } = useZonaDeBeneficiariosContext();

  return (
    <BuscarTramiteContext.Provider
      value={{
        state,
        dispatch,
        getRequestInfo: actionsCreators.getRequestInfo(
          dispatch,
          setGeneralError
        ),
        resetRequestInfo: actionsCreators.resetRequestInfo(
          dispatch,
          setGeneralError
        ),
      }}
    >
      {children}
    </BuscarTramiteContext.Provider>
  );
};
