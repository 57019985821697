import React from "react";
import Button from "../../../../components/Buttons";
import { useFormContext } from "react-hook-form";
import { ButtonsWrapper } from "./styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const NavigationButtons = ({ activeStep, onNext }) => {
  const {
    formState: { isValid },
  } = useFormContext();

  const history = useHistory();
  const goToHomePage = () => {
    history.push("/mi-habitat-digital/afiliado/inicio");
  };
  return (
    <ButtonsWrapper activeStep={activeStep}>
      {activeStep === 0 && (
        <>
          <Button
            fullWidth={true}
            size="lg"
            variant={"secondary"}
            onClick={goToHomePage}
            type="button"
          >
            Volver
          </Button>
          <Button
            fullWidth={true}
            size="lg"
            type="button"
            disabled={!isValid}
            onClick={onNext?.[0]}
          >
            Siguiente
          </Button>
        </>
      )}
      {activeStep === 1 && (
        <Button size={"lg"} fullWidth={true} onClick={goToHomePage}>
          Volver al inicio
        </Button>
      )}
    </ButtonsWrapper>
  );
};

export default NavigationButtons;

NavigationButtons.propTypes = {
  activeStep: PropTypes.oneOf([0, 1]),
};

NavigationButtons.defaultProp = {
  activeStep: 0,
};
