import styled, { css } from "styled-components";
import { device } from "../../../../utils/device";
import { Link } from "react-router-dom";

export const SidebarComponent = styled.div`
  grid-area: sidebar;
  padding: 16px;
  background-color: ${(p) => p.theme.palette.lead[100]};
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  align-items: center;
  top: 0;
  width: 100%;
  position: sticky;
  z-index: 1000;

  ${(p) => css`
    @media screen and ${device.desktopS} {
      /* max-width: 280px; */
      background-color: ${(p) => p.theme.palette.white};
      height: 100vh;
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
    }
  `}
`;

export const MenuItemsWrapper = styled.div`
  height: 100%;
  padding-top: 32px;
`;

export const HabitatLogo = styled(Link)`
  display: inline-block;

  svg {
    height: 20px;
  }

  ${(p) => css`
    @media screen and ${device.desktopS} {
      height: 72px;
      display: grid;
      align-items: center;
      justify-items: center;

      svg {
        width: 196px;
        height: 33px;
      }
    }
  `}
`;

export const MenuToggler = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.palette.grayscale[800]};
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const MenuList = styled.div`
  display: grid;
  row-gap: 16px;
  width: 270px;
  height: 100%;
  background-color: white;
  padding: 16px;
  grid-template-rows: auto auto auto 1fr auto;
  outline: none;

  ${(p) => css`
    @media screen and ${device.desktopS} {
      width: auto;
      padding: 0px;
      height: 100%;
    }
  `}
`;

export const CloseMenu = styled.div`
  margin-left: auto;
`;

export const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Item = styled(Link)`
  && {
    text-decoration: none !important;
    padding: 8px 16px;
    display: flex;
    gap: 16px;
    border-radius: 8px;
    ${(p) => p.theme.tipography.bodySmallRegular}
    align-items: center;
    cursor: pointer;
    color: ${(p) => p.theme.palette.grayscale[800]};
    stroke: ${(p) => p.theme.palette.grayscale[800]};

    &:hover {
      background-color: ${(p) => p.theme.palette.transparencies.light};
      text-decoration: none !important;
    }
  }

  // active
  /* background-color: ${(p) => p.theme.palette.primary[50]};
  color: ${(p) => p.theme.palette.primary.main};
  stroke: ${(p) => p.theme.palette.primary.main}; */
`;

export const ItemIcon = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ItemLabel = styled.div``;

export const SearchButton = styled(Item)``;

export const LogoutButton = styled(Item)`
  align-self: end;
`;
