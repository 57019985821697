import React, { useState } from "react";
import BCPLogo from "../../../assets/IconBucket/BCPLogo";
import InterbankLogo from "../../../assets/IconBucket/InterbankLogo";
import ScotiabankLogo from "../../../assets/IconBucket/ScotiabankLogo";
import BBVALogo from "../../../assets/IconBucket/BBVALogo";
import DefaulBancoLogo from "../../../assets/IconBucket/DefaulBancoLogo";
import { CreditCardInputComponent, RightSide } from "./styles";
import PropTypes from "prop-types";
import RemoveCreditCardConfirmationModal from "./RemoveCreditCardConfirmationModal";
import { useFormContext } from "react-hook-form";
import { BsCheckLg } from "react-icons/bs";
import Button from "../../Buttons";
import ModalToken from "../../ModalToken";
import clienteAxios from "../../../config/axios";
import GeneralErrorModal from "./GeneralErrorModal";

const CreditCardInput = ({
  cuenta,
  name,
  value,
  required,
  onFailedRemoval,
  onSuccededRemoval,
  isRemovable,
  validate,
  onClick,
  ...prop
}) => {
  const { r_nombre_banco, r_tipo_cuenta, r_numero_cuenta, r_codigo } = cuenta;

  const { watch, register } = useFormContext();

  const [
    removeCreditCardConfirmationModalIsOpen,
    setRemoveCreditCardConfirmationModalIsOpen,
  ] = useState(false);
  const [removeCreditCardErrorModal, setRemoveCreditCardErrorModal] =
    useState(false);
  const [isNotRemovableModal, setIsNotRemovableModal] = useState(false);
  const [tokenModalIsOpen, setTokenModalIsOpen] = useState(false);
  const [generalErrorModal, setGeneralErrorModal] = useState(false);

  const closeTokenModal = () => setTokenModalIsOpen(false);

  const onContinueRemoveCreditCardConfirmationModal = () => {
    setTokenModalIsOpen(true);
  };

  const onRemoveCreditCard = () => {
    if (isRemovable === false) {
      return setIsNotRemovableModal(true);
    }

    setRemoveCreditCardConfirmationModalIsOpen(true);
  };

  const procesarSolicitud = async (token) => {
    const requestPayload = {
      v_referencia: r_codigo,
      v_token_digital: token,
    };

    try {
      const response = await clienteAxios.post(
        "/mis-datos/tarjetas/eliminar",
        requestPayload
      );

      // To be used in a parent component
      if (onSuccededRemoval) {
        //DATALAYER INVERTIR
        // console.log("confirmar eliminacion");
        window.dataLayer.push({
          event: "button_click",
          product_name: "fondo libre habitat",
          step_name: "paso 2 - datos de inversion",
          description: "segura que quieres eliminar tu cuenta",
          option: "eliminar",
        });
        onSuccededRemoval({ reference: requestPayload.v_referencia, response });
      }
    } catch (error) {
      const { status } = error?.response;

      if (status >= 400 && status < 500) {
        setRemoveCreditCardErrorModal(true);
      }

      if (status >= 500) {
        setGeneralErrorModal(true);
      }

      // To be used in a parent component
      if (onFailedRemoval) {
        onFailedRemoval({
          reference: requestPayload.v_referencia,
          response: error?.response,
        });
      }
    }

    setTokenModalIsOpen(false);
  };

  const creditCardisChecked = watch(name) === value;

  return (
    <CreditCardInputComponent
      name={name}
      value={value}
      required={required}
      validate={validate}
      onClick={onClick}
      htmlFor={`credit-card-${value}`}
      isChecked={creditCardisChecked}
      {...prop}
    >
      <div className="card-information">
        <div className="logo">{getLogo(r_nombre_banco)}</div>
        <p>Cuenta: {r_tipo_cuenta}</p>
        <p>N.°: {r_numero_cuenta}</p>
      </div>
      <RightSide isChecked={creditCardisChecked}>
        <div className="radio">
          <BsCheckLg />
        </div>
        {isRemovable && (
          <Button size="sm" variant={"secondary"} onClick={onRemoveCreditCard}>
            Eliminar
          </Button>
        )}
      </RightSide>
      <input
        type="radio"
        value={value}
        id={`credit-card-${value}`}
        {...register(name, { required, validate: { ...validate } })}
      />
      {tokenModalIsOpen && (
        <ModalToken
          showModal={tokenModalIsOpen}
          cerrarModal={closeTokenModal}
          guardarCode={procesarSolicitud}
        />
      )}
      <RemoveCreditCardConfirmationModal
        isOpen={removeCreditCardConfirmationModalIsOpen}
        setIsOpen={setRemoveCreditCardConfirmationModalIsOpen}
        onContinue={onContinueRemoveCreditCardConfirmationModal}
      />
      <GeneralErrorModal
        isOpen={generalErrorModal}
        setIsOpen={setGeneralErrorModal}
        title={"Ocurrió un problema"}
        subtitle={
          "Ocurrió un error inesperado desde nuestro servicio. Inténtalo más tarde."
        }
      />
      <GeneralErrorModal
        isOpen={removeCreditCardErrorModal}
        setIsOpen={setRemoveCreditCardErrorModal}
        title={"Ocurrió un problema"}
        subtitle={
          "Tu solicitud no ha podido ser procesada. Puedes intentarlo de nuevo, si los problemas siguen inténtalo más tarde."
        }
      />
      <GeneralErrorModal
        isOpen={isNotRemovableModal}
        setIsOpen={setIsNotRemovableModal}
        title={"Ocurrió un problema"}
        subtitle={
          "No puedes eliminar esta cuenta porque está asociada a un débito mensual."
        }
      />
    </CreditCardInputComponent>
  );
};

export default CreditCardInput;

CreditCardInput.propTypes = {
  cuenta: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  validate: PropTypes.object,
};

CreditCardInput.defaultProps = {
  cuenta: {
    r_nombre_banco: "Otro",
    r_tipo_cuenta: "XXXXXXXXXXXXX",
    r_numero_cuenta: "XXXXXXXXXXXXXXXX",
  },
  name: "credit-card-input",
  value: "credit-card-input",
  required: { value: true, message: "Este campo es requerido." },
  validate: null,
};

const getLogo = (nombreBanco) => {
  const banco = {
    BCP: <BCPLogo />,
    INTERBANK: <InterbankLogo />,
    SCOTIABANK: <ScotiabankLogo />,
    BBVA: <BBVALogo />,
  };

  return banco[nombreBanco] || <DefaulBancoLogo />;
};
