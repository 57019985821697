import React, { useReducer } from "react";
import RetiroapvsinfinContext from "./retiroapvsinfinContext";
import RetiroapvsinfinReducer from "./retiroapvsinfinReducer";
import clienteAxios from "../../config/axios";
import {
  RETIRO_APVSINFIN_GET_EXITOSO,
  RETIRO_APVSINFIN_GET_ERROR,
  RETIRO_APVSINFIN_GET_LOADIN,
  RETIRO_APVSINFIN_CLEANER,
  RETIRO_APVSINFIN_CLEANER_PARCIAL,
  RETIRO_APVSINFIN_POST_EXITOSO,
  RETIRO_APVSINFIN_POST_ERROR,
  RETIRO_APVSINFIN_POST_LOADIN,
  RETIRO_RESETEAR
} from "../../types";

const RetiroapvsinfinState = (props) => {
  const initialState = {
    datosRetiroApvSinFin: null,
    mensajeInformativo: null,
    conTokenDigital: null,
    mensajeAlerta: null,
    loadingPage: false,
    loadingBtn: false,
    datosResultadoRetiroApvSinFin: null,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosRetiroApvSinFin = async () => {
    dispatch({
      type: RETIRO_APVSINFIN_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/tipo-de-aporte/vsf/retiro");
      dispatch({
        type: RETIRO_APVSINFIN_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, RETIRO_APVSINFIN_GET_ERROR);
    }
  };

  const limpiarVariablesRetiroApvSinFin = () => {
    dispatch({
      type: RETIRO_APVSINFIN_CLEANER,
    });
  };
  const limpiarVariablesRetiroApvSinFinParcial = () => {
    dispatch({
      type: RETIRO_APVSINFIN_CLEANER_PARCIAL,
    });
  };

  const retirarApvSinFin = async (datos) => {
    dispatch({
      type: RETIRO_APVSINFIN_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/tipo-de-aporte/vsf/retiro/procesar", datos);
      dispatch({
        type: RETIRO_APVSINFIN_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {

      handleErrorsLocal(error, RETIRO_APVSINFIN_POST_ERROR);
    }
  };

  const resetear = () => {
    dispatch({
      type: RETIRO_RESETEAR,
      payload: true, // mandamos el loading al boton
    });
  }

  const [state, dispatch] = useReducer(RetiroapvsinfinReducer, initialState);
  return (
    <RetiroapvsinfinContext.Provider
      value={{
        datosRetiroApvSinFin: state.datosRetiroApvSinFin,
        mensajeInformativo: state.mensajeInformativo,
        conTokenDigital: state.conTokenDigital,
        mensajeAlerta: state.mensajeAlerta,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        datosResultadoRetiroApvSinFin: state.datosResultadoRetiroApvSinFin,
        obtenerDatosRetiroApvSinFin,
        limpiarVariablesRetiroApvSinFin,
        limpiarVariablesRetiroApvSinFinParcial,
        retirarApvSinFin,
        resetear
      }}
    >
      {props.children}
    </RetiroapvsinfinContext.Provider>
  );
};

export default RetiroapvsinfinState;
