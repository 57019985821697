import React, { useReducer } from "react";
import AportarapvsinfinContext from "./aportarapvsinfinContext";
import AportarapvsinfinReducer from "./aportarapvsinfinReducer";
import clienteAxios from "../../config/axios";
import {
  APORTAR_APVSINFIN_GET_EXITOSO,
  APORTAR_APVSINFIN_GET_ERROR,
  APORTAR_APVSINFIN_GET_LOADIN,
  APORTAR_APVSINFIN_CLEANER,
  APORTAR_APVSINFIN_CLEANER_PARCIAL,
  APORTAR_APVSINFIN_POST_EXITOSO,
  APORTAR_APVSINFIN_POST_ERROR,
  APORTAR_APVSINFIN_CLEANER_MENSAJE,
  APORTAR_APVSINFIN_POST_LOADIN,
  APORTAR_RESETEAR,
  APORTAR_CLEAR_MENSAJE_POST,
} from "../../types";

const AportarapvsinfinState = (props) => {
  const initialState = {
    datosAportarApvSinFin: null,
    mensajeInformativo: null,
    mensajeAlerta: null,
    mensajeAlertaPost: null,
    loadingPage: false,
    loadingBtn: false,
    datosResultadoAportarApvSinFin: null,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response?.status === 500) {
        dispatch({
          type: origin_error,
          payload: {
            code: 1, // su token a expirado
            msg: "Error en los servicios, por favor recargue la página.",
          },
        });
      } else if (error.response?.data?.errors?.message === "Unauthenticated.") {
        dispatch({
          type: origin_error,
          payload: {
            code: 2, // su token a expirado
            msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
          },
        });
      } else if (
        error.response?.data?.r_respuesta < 0 &&
        error.response?.data?.r_respuesta != -6
      ) {
        dispatch({
          type: origin_error,
          payload: {
            msg: error.response?.data?.r_mensaje,
          },
        });
      } else {
        console.log("entramos al default errors");
      }
    }
  };

  const obtenerDatosParaAportarApvSinFin = async () => {
    dispatch({
      type: APORTAR_APVSINFIN_GET_LOADIN,
      payload: true,
    });
    try {
      const respuesta = await clienteAxios.get("/tipo-de-aporte/vsf");
      dispatch({
        type: APORTAR_APVSINFIN_GET_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, APORTAR_APVSINFIN_GET_ERROR);
    }
  };

  const limpiarVariablesAportarApvSinFin = () => {
    dispatch({
      type: APORTAR_APVSINFIN_CLEANER,
    });
  };
  const limpiarVariablesAportarApvSinFinParcial = () => {
    dispatch({
      type: APORTAR_APVSINFIN_CLEANER_PARCIAL,
    });
  };

  const aportarApvSinFin = async (datos) => {
    dispatch({
      type: APORTAR_APVSINFIN_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/tipo-de-aporte/vsf/apertura-debito/procesar",
        datos
      );
      dispatch({
        type: APORTAR_APVSINFIN_POST_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, APORTAR_APVSINFIN_POST_ERROR);
    }
  };

  const resetear = () => {
    dispatch({
      type: APORTAR_RESETEAR,
      payload: true, // mandamos el loading al boton
    });
  };

  const limpiarMensajeErrorPost = () => {
    dispatch({
      type: APORTAR_CLEAR_MENSAJE_POST,
    });
  };

  const limpiarMensajeError = () => {
    dispatch({
      type: APORTAR_APVSINFIN_CLEANER_MENSAJE,
    });
  };

  const [state, dispatch] = useReducer(AportarapvsinfinReducer, initialState);
  return (
    <AportarapvsinfinContext.Provider
      value={{
        datosAportarApvSinFin: state.datosAportarApvSinFin,
        mensajeInformativo: state.mensajeInformativo,
        mensajeAlerta: state.mensajeAlerta,
        mensajeAlertaPost: state.mensajeAlertaPost,
        loadingPage: state.loadingPage,
        loadingBtn: state.loadingBtn,
        datosResultadoAportarApvSinFin: state.datosResultadoAportarApvSinFin,
        obtenerDatosParaAportarApvSinFin,
        limpiarVariablesAportarApvSinFin,
        limpiarVariablesAportarApvSinFinParcial,
        limpiarMensajeError,
        limpiarMensajeErrorPost,
        aportarApvSinFin,
        resetear,
      }}
    >
      {props.children}
    </AportarapvsinfinContext.Provider>
  );
};

export default AportarapvsinfinState;
