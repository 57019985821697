import React from "react";
import Modal from "../../../../components/BasicModal";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import XCircleIcon from "../../../../assets/IconBucket/XCircleIcon";
import { IconWrapper, Title } from "./styles";
import { useZonaDeBeneficiariosContext } from "../context";

const ModalDeErrorGeneral = () => {
  const {
    state: { conexionError },
    removeGeneralError,
  } = useZonaDeBeneficiariosContext();

  const closeModal = () => removeGeneralError();
  return (
    <Modal open={conexionError} onClose={closeModal}>
      <Title>
        <IconWrapper>
          <XCircleIcon />
        </IconWrapper>{" "}
        <span>Ocurrió un problema</span>
      </Title>
      <Modal.Subtitle>
        Ocurrió un error inesperado desde nuestro servicio. Inténtalo más tarde.
      </Modal.Subtitle>
      <Divider />
      <Button fullWidth style={{ margin: "auto" }} onClick={closeModal}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalDeErrorGeneral;
