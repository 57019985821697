import React from "react";
import {
  ButtonsWrapper,
  HeaderInformation,
  PasoResumenComponent,
} from "./styles";
import * as SummarySection from "../../../components/SummarySection";
import Alert from "../../../components/Alert";
import moment from "moment-business-days";
import Button from "../../../components/Buttons";
import { useHistory } from "react-router-dom";
import { useGastoDeSepelioContext } from "../context";

const PasoResumen = () => {
  const steps = [
    {
      status: "done",
      label: "Solicitud registrada",
      description: `Se ingresó el ${moment().format(
        "L"
      )}. Ahora nos toca evaluarla.`,
    },
    {
      status: "waiting",
      label: "Revisión de documentos",
      description:
        "Tus documentos serán evaluados en un plazo de 5 días útiles contados desde el siguiente día de haber registrado esta solicitud, si todo está en orden, te enviaremos un correo para validar tu identidad.",
    },
    {
      status: "waiting",
      label: "En proceso de pago",
      description:
        "Una vez aprobada tu solicitud, realizaremos la devolución correspondiente.",
    },
    {
      status: "waiting",
      label: "Pagado",
    },
  ];

  const history = useHistory();
  const onGoBackToHabitat = () => history.push("/");
  const onRegisterNewProcedure = () => history.push("/zona-beneficiarios");
  const goToSearchProcedurePage = () =>
    history.push("/zona-beneficiarios/buscar-tramite");

  const {
    state: { request },
  } = useGastoDeSepelioContext();

  return (
    <PasoResumenComponent>
      <SummarySection.Section>
        <SummarySection.Header style={{ paddingTop: "8px" }}>
          Tu trámite fue registrado con éxito
        </SummarySection.Header>
        <HeaderInformation style={{ marginBottom: "16px" }}>
          Evaluaremos los documentos registrados, en caso se encuentren
          conformes te contactaremos mediante el correo indicado para confirmar
          el registro de tu solicitud.
        </HeaderInformation>
        <Alert
          description={
            <span>
              Puedes hacer seguimiento usando tu nº de solicitud desde:{" "}
              <b>Inicio / Buscar trámite en proceso</b>
            </span>
          }
          action={"Ir"}
          onAction={goToSearchProcedurePage}
        />
        <SummarySection.Body>
          <SummarySection.Table>
            <SummarySection.TableTitle>
              Datos de la solicitud
            </SummarySection.TableTitle>
            <SummarySection.TableSection>
              {[
                { label: "Tipo de tramite", value: request?.data?.tipoTramite },
                {
                  label: "N.° de solicitud",
                  value: request?.data?.nroSolicitud,
                },
                {
                  label: "Fecha de solicitud",
                  value: request?.data?.fechaSolicitud,
                },
                { label: "Afiliado", value: request?.data?.afiliado },
                {
                  label: "Causa de difunción",
                  value: request?.data?.causaDifuncion,
                },
                { label: "Solicitante", value: request?.data?.solicitante },
                {
                  label: "Correo donde se realizará la comunicación ",
                  value: request?.data?.correo,
                },
                {
                  label: "Telefono donde se realizará la comunicación",
                  value: request?.data?.telefono,
                },
              ].map(({ label, value, show }, i) => (
                <SummarySection.TableItem
                  key={label}
                  label={label}
                  value={value}
                />
              ))}
            </SummarySection.TableSection>
          </SummarySection.Table>
          <SummarySection.Progress>
            <SummarySection.ProgressHeader>
              Avance de solicitud
            </SummarySection.ProgressHeader>
            <SummarySection.ProgressBody
              steps={steps}
            ></SummarySection.ProgressBody>
          </SummarySection.Progress>
        </SummarySection.Body>
      </SummarySection.Section>
      <ButtonsWrapper>
        <Button fullWidth size="lg" onClick={onGoBackToHabitat}>
          Volver a AFP Habitat
        </Button>
        <Button
          fullWidth
          variant={"secondary"}
          size="lg"
          onClick={onRegisterNewProcedure}
        >
          Registrar otro trámite
        </Button>
      </ButtonsWrapper>
    </PasoResumenComponent>
  );
};

export default PasoResumen;
