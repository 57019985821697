import React from "react";

const FileText = ({ size = 18, color }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3327 3.33203H9.99935C9.1153 3.33203 8.26745 3.68322 7.64233 4.30834C7.01721 4.93347 6.66602 5.78131 6.66602 6.66537V33.3321C6.66602 34.2161 7.01721 35.064 7.64233 35.6891C8.26745 36.3142 9.1153 36.6654 9.99935 36.6654H29.9994C30.8834 36.6654 31.7313 36.3142 32.3564 35.6891C32.9815 35.064 33.3327 34.2161 33.3327 33.3321V13.332L23.3327 3.33203Z"
        stroke="#363A3E"
        stroke-width="3.33334"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.334 3.33203V13.332H33.334"
        stroke="#363A3E"
        stroke-width="3.33334"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6673 21.668H13.334"
        stroke="#363A3E"
        stroke-width="3.33334"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6673 28.332H13.334"
        stroke="#363A3E"
        stroke-width="3.33334"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6673 15H15.0007H13.334"
        stroke="#363A3E"
        stroke-width="3.33334"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileText;
