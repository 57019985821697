import styled, { css } from "styled-components";
import { device } from "../../../../../../../utils/device";
import * as SummarySection from "../../../../../../../components/SummarySection";

export const PasoResumenComponent = styled.div``;

export const HeaderInformation = styled.p`
  ${(p) => p.theme.tipography.bodySmallRegular}
  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  row-gap: 8px;
  justify-content: center;
`;

export const ContentDatosSolicitud = styled(SummarySection.Body)`
  display: block;
  ${(p) =>
    p.stepperShow &&
    css`
      display: grid;
    `}
`;
