import React, { useState } from "react";
import Modal from "./CustomModal";
import {
  ContentCheckValidacion,
  ContentModalBeneficiarioDescription,
  ContentExpression,
  ContentDescription,
} from "../../styles/Modal";
import * as Input from "../../../../../../components/Input";
import InfoButton from "../../../../../../components/InfoButton";
import { Box } from "@mui/material";
import { arrayDocumentos } from "../../../../../../utils/dataDocumentos";
import FileText from "../../../../../../assets/IconBucket/FileText";
import IconSVG from "../../../../Components/IconSVG";
const ModalBeneficiarios = ({
  isOpen,
  setIsOpen,
  misDatosGet,
  crearProcesoLegalData,
  createLegalDataLoading,
}) => {
  const [isOpenModalDocumentos, setOpenModalDocumentos] = useState(false);
  const [typeDocument, setTypeDocument] = useState(false);
  const closeModal = () => setIsOpen(false);
  const closeModalDocs = () => setOpenModalDocumentos(false);
  const datos = {
    type_of_procedure_id: 2,
  };
  const filesMaxSize = 2;
  const filesExtensions = ["pdf", "jpg", "jpeg", "png"];

  const handleOpenModalDocumentos = (type) => {
    setTypeDocument(type);
    setOpenModalDocumentos(true);
  };
  const getArrayDocuments = arrayDocumentos.filter(
    (item) => item.id === typeDocument
  );
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title="Documentos de beneficiario"
        subtitle={
          "Sube los siguientes documentos para que podamos validar a tu beneficiario:"
        }
        loadingBtnPrimary={createLegalDataLoading}
        onClickBtnPrimary={() => crearProcesoLegalData(datos)}
        textBtnPrimary={"Subir"}
        isBtnSecondary={true}
        textBtnSecondary="Cancelar"
        onClickBtnSecondary={closeModal}
      >
        <ContentCheckValidacion>
          <Input.Wrapper>
            <Input.Title required={false}>
              Documento de identidad (Ambas caras)
            </Input.Title>
            <Input.File
              required={{
                value: false,
                message: "Este campo no es requerido.",
              }}
              extensions={filesExtensions}
              name="documentos.pago"
              size={"small"}
              maxFileSize={filesMaxSize}
            />

            <Input.ErrorMessage name="documentos.id" />
          </Input.Wrapper>
          <Input.Wrapper>
            <Input.Title required={false}>
              Partida de nacimiento{" "}
              <span style={{ display: "inline-block", marginLeft: "4px" }}>
                <InfoButton
                  tooltip={false}
                  onClick={() => handleOpenModalDocumentos(1)}
                />
              </span>{" "}
            </Input.Title>
            <Input.File
              required={{
                value: false,
                message: "Este campo no es requerido.",
              }}
              extensions={filesExtensions}
              name="documentos.pago"
              size={"small"}
              maxFileSize={filesMaxSize}
            />

            <Input.ErrorMessage name="documentos.id" />
          </Input.Wrapper>
          <Input.Wrapper>
            <Input.Title required={false}>
              Sustento de condición de invalidez
              <span style={{ display: "inline-block", marginLeft: "4px" }}>
                <InfoButton
                  tooltip={false}
                  onClick={() => handleOpenModalDocumentos(2)}
                />
              </span>{" "}
            </Input.Title>
            <Input.File
              required={{
                value: false,
                message: "Este campo no es requerido.",
              }}
              extensions={filesExtensions}
              name="documentos.pago"
              size={"small"}
              maxFileSize={filesMaxSize}
            />

            <Input.ErrorMessage name="documentos.id" />
          </Input.Wrapper>
        </ContentCheckValidacion>
      </Modal>

      <Modal
        isOpen={isOpenModalDocumentos}
        onClose={closeModalDocs}
        title={getArrayDocuments.length > 0 && getArrayDocuments[0].name}
        subtitle={"Que debes adjuntar"}
        onClickBtnPrimary={() => setOpenModalDocumentos(false)}
        textBtnPrimary={"Entendido"}
      >
        {getArrayDocuments.length > 0 && (
          <>
            {getArrayDocuments[0].description.map((item) => (
              <ContentDescription>
                <ContentModalBeneficiarioDescription>
                  <IconSVG
                    width={"40px"}
                    height={"40px"}
                    padding={"0px"}
                    componentIcon={<FileText />}
                  />

                  <Box>{item.name}</Box>
                </ContentModalBeneficiarioDescription>
                {item.expression && <ContentExpression>o</ContentExpression>}
              </ContentDescription>
            ))}
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalBeneficiarios;
