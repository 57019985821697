import React, { useState, useEffect, useContext } from "react";
import { FormGroup, Label, Input, UncontrolledTooltip } from "reactstrap";
import CustomModal from "../../../../../../Components/custromModal/CustomModal";
import JubilacionContext from "../../../../../../../../context/jubilacion/jubilacionContext";
import { Button } from "../../../../../../../../components";
import moment from "moment";
import ModalDeErrorGeneral from "../../../../../../Components/ModalDeErrorGeneral";

import "./index.css";

export default function ValidacionAfiliados(props) {
  const {
    relationships,
    endSecondStep,
    getOutOfProcess,
    processId,
    contents,
    dependencies,
  } = props;

  const jubilacionContext = useContext(JubilacionContext);
  const {
    guardarAvanceJuilacionData,
    guardarAvanceJuilacionLoading,
    guardarAvanceTramiteJubilacion,
    limpiarGuardarAvanceTramiteJubilacion,
    limpiarObtenerContenidoTemaJubilacion,
  } = jubilacionContext;

  const [modalAddBeneficiarie, setModalAddBeneficiarie] = useState(false);
  const [haveBeneficiaries, setHaveBeneficiaries] = useState(null);
  const [havePension, setHavePension] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [addBeneficiarie, setAddbeneficiarie] = useState({
    relationship: "",
    birthday: "",
    gender: "",
    situation: "",
  });
  const [error, setError] = useState({
    birthday: "",
    situation: "",
  });
  const [sendRequest, setSendRequest] = useState(false);
  const [confirmSaveModal, setConfirmSaveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      limpiarGuardarAvanceTramiteJubilacion();
    };
  }, []);

  useEffect(() => {
    if (guardarAvanceJuilacionData && sendRequest) {
      if (guardarAvanceJuilacionData.success) {
        limpiarObtenerContenidoTemaJubilacion();

        endSecondStep();
      } else {
        setShowModal(!showModal);
      }
    }
  }, [guardarAvanceJuilacionData, sendRequest]);

  const handleHaveBeneficiaries = (value) => {
    if (
      (value === "1" && haveBeneficiaries === null) ||
      (value === "1" && haveBeneficiaries === false)
    )
      setHaveBeneficiaries(true);
    else if (
      (value === "0" && haveBeneficiaries === null) ||
      (value === "0" && haveBeneficiaries === true)
    )
      setHaveBeneficiaries(false);
  };

  const addBeneficiaryToArray = () => {
    if (addBeneficiarie.relationship === 1) {
      if (moment().diff(addBeneficiarie.birthday, "years", false) < 18) {
        setError({
          birthday: "No se puede agregar a un conyuge menor de 18 años.",
          situation: "",
        });
        return;
      }
    } else if (addBeneficiarie.relationship === 2) {
      if (
        moment().diff(addBeneficiarie.birthday, "years", false) > 18 &&
        addBeneficiarie.situation === "Sin discapacidad"
      ) {
        setError({
          birthday: "",
          situation:
            "No se puede agregar a un hijo mayor de 18 años sin discapacidad.",
        });
        return;
      } else if (
        moment(addBeneficiarie.birthday).isBefore(moment(dependencies.birthday))
      ) {
        setError({
          birthday: "La edad del hijo no puede ser mayor a la de usted",
          situation: "",
        });
        return;
      }
    } else if (
      addBeneficiarie.relationship === 3 ||
      addBeneficiarie.relationship === 4
    ) {
      if (
        moment(dependencies.birthday).isBefore(moment(addBeneficiarie.birthday))
      ) {
        setError({
          birthday: `${
            addBeneficiarie.relationship === 4
              ? "La edad de la madre"
              : "La edad del padre"
          }  es menor a su edad`,
          situation: "",
        });
        return;
      }
    }

    const temporal = beneficiaries.slice();
    temporal.push(addBeneficiarie);
    setBeneficiaries(temporal);
    resetAddBeneficiarie();
    setModalAddBeneficiarie(!modalAddBeneficiarie);
  };

  const deleteBeneficiaryToArray = (indexForDelete) => {
    const temporal = beneficiaries.slice();
    temporal.splice(indexForDelete, 1);
    setBeneficiaries(temporal);
  };

  const resetAddBeneficiarie = () => {
    setAddbeneficiarie({
      relationship: NaN,
      birthday: "",
      gender: "",
      situation: "",
    });
  };

  const changeIconForLoading = () => {
    if (guardarAvanceJuilacionLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const fnBtnSaveAndContinue = () => {
    const data = [
      {
        id: contents[0].id,
        response: haveBeneficiaries,
        beneficiaries,
        type: "boleean",
      },
      {
        id: contents[1].id,
        response: havePension,
        type: "boleean",
      },
    ];
    setSendRequest(true);
    guardarAvanceTramiteJubilacion(processId, 2, { data });
  };

  const validateData = () => {
    if (haveBeneficiaries === null) return false;
    else if (havePension === null) return false;
    else if (haveBeneficiaries && beneficiaries.length === 0) return false;
    else return true;
  };

  const isInvalidateAddBeneficiaie = () => {
    if (isNaN(addBeneficiarie.relationship)) return true;

    if (addBeneficiarie.birthday === "") return true;

    if (addBeneficiarie.gender === "") return true;

    if (addBeneficiarie.situation === "") return true;

    return false;
  };

  return (
    <div className="legal-beneficiarios">
      <h1 className="mb-2">Validación de jubilación</h1>
      <p id="text-intro">
        Para continuar, completa las siguientes preguntas de acuerdo a la
        información recibida en la asesoría virtual. Esta información tiene
        carácter de declaración jurada.
      </p>
      <br />
      <div className="content">
        <div className="content-box">
          <h3>
            I. ¿Cuentas con beneficiarios?{" "}
            <i
              id="fondo-actalizado-1"
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip placement="top" target="fondo-actalizado-1">
              Se considera beneficiario al cónyuge o concubino/a, así como a
              aquellos familiares que dependen económicamente de ti.
              <br />
              <br />
              Pueden ser tus hijos menores de 18 años o mayores de 18 con alguna
              discapacidad, padres que dependan económicamente de ti.
            </UncontrolledTooltip>
          </h3>
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={haveBeneficiaries}
                  className="radio-btn"
                  id="tfa_710"
                  name="beneficiarios"
                  disabled={guardarAvanceJuilacionLoading}
                  onClick={(e) => {
                    setBeneficiaries([]);
                    handleHaveBeneficiaries(e.target.value);
                  }}
                />
                <label className="postRadioField" id="tfa_710" for="tfa_710">
                  <span className="input-radio-faux"></span>Sí, tengo
                  beneficiarios
                </label>
              </span>
              <br />
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  checked={haveBeneficiaries === false}
                  className="radio-btn"
                  id="tfa_711"
                  name="beneficiarios"
                  onClick={(e) => handleHaveBeneficiaries(e.target.value)}
                  disabled={guardarAvanceJuilacionLoading}
                />
                <label className="postRadioField" id="tfa_711" for="tfa_711">
                  <span className="input-radio-faux"></span>No, no tengo
                  beneficiarios
                </label>
              </span>
            </div>
          </div>
        </div>
        {haveBeneficiaries && (
          <div className="beneficiarios-table-container">
            <div className="border-table mb-2">
              <div className="row-beneficiaries">
                <div className="first-column center-tems">
                  <div className="table-header center-tems">
                    <span>Parentesco</span>
                  </div>
                </div>
                <div className="second-column center-tems">
                  <div className="table-header center-tems">
                    <span>Fecha de nacimiento</span>
                  </div>
                </div>
                <div className="third-column center-tems">
                  <div className="table-header center-tems">
                    <span>Sexo</span>
                  </div>
                </div>
                <div className="fourth-column center-tems">
                  <div className="table-header center-tems">
                    <span>Estado</span>
                  </div>
                </div>
                <div className="fifth-column center-tems "></div>
              </div>
              {beneficiaries.map((value, index) => {
                return (
                  <div className="row-beneficiaries">
                    <div className="first-column center-tems">
                      <span className="beneficiaries-rows-span">
                        {
                          relationships.find((x) => x.id == value.relationship)
                            .name
                        }
                      </span>
                    </div>
                    <div className="second-column center-tems">
                      <span className="beneficiaries-rows-span">
                        {value.birthday}
                      </span>
                    </div>
                    <div className="third-column center-tems">
                      <span className="beneficiaries-rows-span">
                        {value.gender}
                      </span>
                    </div>
                    <div className="fourth-column center-tems">
                      <span className="beneficiaries-rows-span">
                        {value.situation}
                      </span>
                    </div>
                    <div
                      className="fifth-column center-tems"
                      onClick={() => deleteBeneficiaryToArray(index)}
                    >
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="center-tems">
              <button
                className="btn-red add-beneficiaries-btn"
                onClick={() => setModalAddBeneficiarie(true)}
                disabled={guardarAvanceJuilacionLoading}
              >
                Agregar beneficiario
              </button>
            </div>
          </div>
        )}

        <div className="content-box">
          <h3>
            II. ¿Recibes actualmente algún tipo de pensión por sobrevivencia?{" "}
            <i
              id="fondo-actalizado-2"
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
            <UncontrolledTooltip placement="top" target="fondo-actalizado-2">
              Puede ser una pensión de sobrevivencia o viudez que te brinde
              atención médica en Essalud.
            </UncontrolledTooltip>
          </h3>
          <div className="margin-container">
            <div className="radio-container">
              <span className="oneChoice">
                <input
                  type="radio"
                  value={1}
                  checked={havePension === true}
                  className="radio-btn"
                  id="tfa_720"
                  name="orfandad_viudez"
                  onChange={(e) => setHavePension(true)}
                  disabled={guardarAvanceJuilacionLoading}
                />
                <label className="postRadioField" id="tfa_720" for="tfa_720">
                  <span className="input-radio-faux"></span>Sí, recibo pensión.
                </label>
              </span>
              <br />
              <span className="oneChoice">
                <input
                  type="radio"
                  value={0}
                  checked={havePension === false}
                  className="radio-btn"
                  id="tfa_721"
                  name="orfandad_viudez"
                  onChange={(e) => setHavePension(false)}
                  disabled={guardarAvanceJuilacionLoading}
                />
                <label className="postRadioField" id="tfa_721" for="tfa_721">
                  <span className="input-radio-faux"></span>No recibo pensión.
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container-legal-buttons">
        <Button
          name="secondary"
          bgColor="white"
          className={`mb-4 btn-modal-secondary ${
            validateData() ? "" : "disable-primary"
          }`}
          onClick={() => getOutOfProcess()}
          disabled={guardarAvanceJuilacionLoading}
        >
          Cerrar
        </Button>
        <Button
          className={`mb-4 btn-modal-primary ${
            validateData() ? "" : "disable-secondary"
          }`}
          onClick={() => setConfirmSaveModal(!confirmSaveModal)}
          disabled={!validateData() || guardarAvanceJuilacionLoading}
        >
          Continuar {changeIconForLoading()}
        </Button>
      </div>

      <CustomModal
        showModal={modalAddBeneficiarie}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          resetAddBeneficiarie();
          setModalAddBeneficiarie(!modalAddBeneficiarie);
        }}
        title="Añadir Beneficiario"
        description={
          <div style={{ textAlign: "left" }}>
            <FormGroup>
              <Label htmlFor="exampleSelect">Parentesco</Label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={addBeneficiarie.relationship}
                onChange={(e) => {
                  if (parseInt(e.target.value) === 3) {
                    setAddbeneficiarie({
                      ...addBeneficiarie,
                      gender: "Hombre",
                      relationship: parseInt(e.target.value),
                    });
                  } else if (parseInt(e.target.value) === 4) {
                    setAddbeneficiarie({
                      ...addBeneficiarie,
                      gender: "Mujer",
                      relationship: parseInt(e.target.value),
                    });
                  } else if (parseInt(e.target.value) === 1) {
                    const genderAfiliado =
                      dependencies.gender === "M" ? "Mujer" : "Hombre";
                    setAddbeneficiarie({
                      ...addBeneficiarie,
                      gender: genderAfiliado,
                      relationship: parseInt(e.target.value),
                    });
                  } else {
                    setAddbeneficiarie({
                      ...addBeneficiarie,
                      gender: "",
                      relationship: parseInt(e.target.value),
                    });
                  }
                  setError({ birthday: "", situation: "" });
                }}
              >
                <option value="">--Selecionar--</option>
                {relationships.map((value, index) => {
                  if (
                    value.id === 1 &&
                    beneficiaries.some((x) => x.relationship === 1)
                  )
                    return null;
                  else if (
                    value.id === 3 &&
                    beneficiaries.some((x) => x.relationship === 3)
                  )
                    return null;
                  else if (
                    value.id === 4 &&
                    beneficiaries.some((x) => x.relationship === 4)
                  )
                    return null;
                  else
                    return (
                      <option key={`${index}-${value.name}`} value={value.id}>
                        {value.name === "Hijo"
                          ? "Hijo(a)"
                          : value.name === "Cónyuge"
                          ? "Cónyuge/Concubino(a)"
                          : value.name}
                      </option>
                    );
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="exampleDate">Fecha de nacimiento</Label>
              <Input
                type="date"
                name="date"
                id="birthdate"
                placeholder="dd/mm/aaaa"
                max={moment().format("YYYY-MM-DD")}
                value={addBeneficiarie.birthday}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  const value = e.target.value;
                  setError({ birthday: "", situation: "" });
                  setAddbeneficiarie({ ...addBeneficiarie, birthday: value });
                }}
              />
              <p id="beneficiarie-text-error">{error.birthday}</p>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="exampleSelectMulti">Sexo</Label>
              <Input
                type="select"
                name="sexo"
                id="sexo"
                value={addBeneficiarie.gender}
                onChange={(e) =>
                  setAddbeneficiarie({
                    ...addBeneficiarie,
                    gender: e.target.value,
                  })
                }
                disabled={
                  addBeneficiarie.relationship === 3 ||
                  addBeneficiarie.relationship === 4 ||
                  addBeneficiarie.relationship === 1
                }
              >
                <option value="">--Selecionar--</option>
                <option value="Hombre">Masculino</option>
                <option value="Mujer">Femenino</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelectMulti">Estado</Label>
              <Input
                type="select"
                name="estado"
                id="estado"
                onChange={(e) => {
                  setError({ birthday: "", situation: "" });
                  setAddbeneficiarie({
                    ...addBeneficiarie,
                    situation: e.target.value,
                  });
                }}
              >
                <option value="">--Selecionar--</option>
                <option value="Sin discapacidad">Sin discapacidad</option>
                <option value="Con discapacidad">Con discapacidad</option>
              </Input>
              <p id="beneficiarie-text-error">{error.situation}</p>
            </FormGroup>
          </div>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={addBeneficiaryToArray}
        textBtnPrimary="Guardar"
        disableBtnPrimary={isInvalidateAddBeneficiaie()}
      />

      <CustomModal
        showModal={confirmSaveModal}
        className="beauty-modal"
        backdrop="static"
        fnCloseIconBtn={() => {
          setConfirmSaveModal(false);
        }}
        title="Confirmar la información brindada"
        description={<p></p>}
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          fnBtnSaveAndContinue();
          setConfirmSaveModal(false);
        }}
        textBtnPrimary="Confirmar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          setConfirmSaveModal(false);
        }}
        textBtnSecondary="Volver"
      />
      {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
