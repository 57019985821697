import React from "react";

function Icon(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"></path>
    </svg>
  );
}

export default Icon;
