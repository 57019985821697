import styled, { css } from "styled-components";
import { device } from "../../../../utils/device";
import Divider from "../../../../components/Divider";

export const ContentWrapper = styled.div`
  p.description {
    margin-bottom: 16px;
  }

  ${(p) => css`
    @media screen and ${device.tablet} {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr auto 1fr;
    }
  `}
`;

export const SubTitle = styled.div`
  ${(p) => p.theme.tipography.subtitleS}
  margin-bottom: 16px;
`;

export const DividerComponent = styled(Divider)`
  display: none;

  ${(p) => css`
    @media screen and ${device.tablet} {
      display: block;
    }
  `}
`;

export const Section = styled.ul`
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }

  li {
    list-style: disc;
    list-style-position: inside;
    text-indent: -20px; /* key property */
    margin-left: 20px; /* key property */
    margin-bottom: 8px;

    a {
      color: ${(p) => p.theme.palette.secondary.main};
      text-decoration: underline;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  ul {
    text-indent: -20px; /* key property */
    margin-left: 20px; /* key property */
  }
`;
