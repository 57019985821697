import React, { useContext, useEffect, useState } from "react";
import hocWidthWindow from "../../HOC/HocWidthWindow";
import "./index.css";
import {
  // Button,
  Container,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import logo from "../../assets/logo-fixed.png";
import icono_fingerprint from "./img/fingerprint.png";
import icono_nrodoc from "./img/tarjeta-de-identificacion.png";
import icono_calendar from "./img/calendar.png";
import icono_parentesco from "./img/parentesco.png";
import icono_person from "./img/person.png";
import icono_phone from "./img/call.png";
import icono_mail from "./img/mail.png";
import icono_gracias from "./img/group.png";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import SolicitudContext from "../../context/solicitud/solicitudContext";

import { Button } from "../../components";

const SolicitudView = ({ ruta }) => {
  const solicitudContext = useContext(SolicitudContext);

  const { guardo, loadingBtn, guardarSolicitud, resetGuardo } =
    solicitudContext;
  const listOptions = [
    { value: "00", text: "D.N.I", maxLength: 8, pattern: "[0-9]*" },
    {
      value: "01",
      text: "Carnet de Extranjería",
      maxLength: 9,
      pattern: "[0-9]*",
    },
    {
      value: "03",
      text: "Lib. Adolesc. Trab.",
      maxLength: 12,
      pattern: "[0-9]*",
    },
    { value: "04", text: "Pasaporte", maxLength: 15, pattern: "[a-zA-Z0-9 ]+" },
  ];
  const parentescos = [
    { value: "Papá", text: "Padre" },
    { value: "Mamá", text: "Madre" },
    { value: "Hijo", text: "Hijo" },
    { value: "Tio", text: "Tio" },
    { value: "Tercero", text: "Tercero" },
  ];
  const [typeDoc, guardartypeDoc] = useState(null);
  const [typeDocSol, guardartypeDocSol] = useState(null);
  const [inputNumDoc, guardarinputNumDoc] = useState({
    lenghtNdoc: 8,
    pattern: "[0-9]*",
  });

  const [inputNumDocSol, guardarinputNumDocSol] = useState({
    lenghtNdocSol: 8,
    patternSol: "[0-9]*",
  });

  const { lenghtNdoc, pattern } = inputNumDoc;
  const { lenghtNdocSol, patternSol } = inputNumDocSol;
  const [modal, setModal] = useState(false);
  const [clase_1, setClase_1] = useState("");
  const [clase_2, setClase_2] = useState("thankyoupage d-none");
  const [clase_3, setClase_3] = useState("form-solicitud");
  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (guardo) {
      setModal(false);
      setClase_1("d-none");
      setClase_2("thankyoupage");
      setClase_3("form-solicitud d-none");
    } else {
      setClase_1("");
      setClase_2("thankyoupage d-none");
      setClase_3("form-solicitud");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardo]);
  useEffect(() => {
    resetGuardo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let titulo = "";
  let tipo = "";
  if (ruta == "/mi-habitat-digital/solicitud/herencia") {
    titulo =
      "Inicia tu solicitud por Herencia aquí ingresando los siguientes datos:";
    tipo = 1;
  } else if (ruta == "/mi-habitat-digital/solicitud/reembolso") {
    titulo =
      "Inicia tu solicitud para pensión de Sobrevivencia aquí ingresando los siguientes datos:";
    tipo = 2;
  } else if (ruta == "/mi-habitat-digital/solicitud/sepelio") {
    titulo =
      "Solicita el reembolso por gastos de sepelio aquí ingresando los siguientes datos:";
    tipo = 3;
  }
  let valuesIniciales = {
    doc_type: "00",
    doc_num: "",
    fecha_nac: "",
    parentesco: "Papá",
    doc_type_sol: "00",
    doc_num_sol: "",
    fecha_nac_sol: "",
    nombres_sol: "",
    celular_sol: "",
    correo_sol: "",
    tipo_email: "@gmail.com",
    r_correo: "",
    r_otro: "",
  };
  const [solicitante, setSolicitante] = useState(valuesIniciales);

  const {
    fecha_nac,
    parentesco,
    doc_num_sol,
    fecha_nac_sol,
    nombres_sol,
    celular_sol,
    correo_sol,
    tipo_email,
    r_correo,
    r_otro,
  } = solicitante;
  const guardar = (e) => {
    // setListo(false);
    solicitante.doc_type = typeDoc;
    solicitante.doc_type_sol = typeDocSol;

    // solicitante.doc_type = typeDoc;
    setSolicitante({
      ...solicitante,
      tipo: tipo,
      doc_type: typeDoc,
      doc_type_sol: typeDocSol,
    });

    if (loadingBtn == false) {
      let enviar = Object.assign(solicitante, { tipo: tipo });
      guardarSolicitud(enviar);
    }
  };
  const setMapa = (e) => {
    e.preventDefault();
    setValue(e.target.name, e.target.value);
    if (
      e.target.name == "r_correo" ||
      e.target.name == "tipo_email" ||
      e.target.name == "r_otro"
    ) {
      let correo = "";
      if (tipo_email == "") {
        correo = r_correo + r_otro;
      } else {
        correo = r_correo + tipo_email;
      }
      setSolicitante({
        ...solicitante,
        correo_sol: correo,
        [e.target.name]: e.target.value,
      });
    } else {
      setSolicitante({
        ...solicitante,
        [e.target.name]: e.target.value,
      });
    }
  };
  const { register, handleSubmit, errors, clearError, setValue, watch } =
    useForm();

  const watchMax = watch("doc_type", [lenghtNdoc, pattern]);
  const watchMax2 = watch("doc_type_sol", [lenghtNdocSol, patternSol]);
  const handleDocumentType = (e) => {
    let item = JSON.parse(e.target.value);

    setValue("doc_type", item.value);
    guardartypeDoc(item.value);
    clearError("doc_num");
    guardarinputNumDoc({
      ...inputNumDoc,
      lenghtNdoc: item.maxLength,
      pattern: item.pattern,
    });
    setMapa(e);
  };
  const handleDocumentType2 = (e) => {
    let item = JSON.parse(e.target.value);
    setValue("doc_type_sol", item.value);
    clearError("doc_num_sol");
    guardartypeDocSol(item.value);
    guardarinputNumDocSol({
      ...inputNumDocSol,
      lenghtNdocSol: item.maxLength,
      patternSol: item.pattern,
    });
    setMapa(e);
  };

  const handleNumDoc = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setValue("doc_num", valor);
    setMapa(e);
  };
  const handleNumDoc2 = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    setValue("doc_num_sol", valor);
    setMapa(e);
  };

  const docInput = (dato) => {
    return (
      <input
        className="form-control"
        type="text"
        id="doc_num"
        autoComplete="off"
        pattern={pattern}
        onChange={handleNumDoc}
        maxLength={dato[0]}
        {...register("doc_num", { required: true, maxLength: dato[0] })}
        placeholder="Ingresa tu número"
      />
    );
  };
  const docInput2 = (dato) => {
    return (
      <input
        className="form-control"
        type="text"
        name="doc_num_sol"
        id="doc_num_sol"
        autoComplete="off"
        pattern={patternSol}
        onChange={handleNumDoc2}
        maxLength={dato[0]}
        {...register("doc_num_sol", { required: true, maxLength: dato[0] })}
        placeholder="Ingresa tu número"
      />
    );
  };

  return (
    <Container className="solicitud-container" fluid={true}>
      <div className="header">
        <a href="https://www.afphabitat.com.pe/">
          <img src={logo} alt="" className="logoImg" />
        </a>
      </div>
      <div className="full-container">
        <div className="fondo_form">&nbsp;</div>
        <h1 className={clase_1}>{titulo}</h1>
        <div className="form-container in-container">
          <div className={clase_2}>
            <img src={icono_gracias} alt="" align="center" />
            <h3>¡Listo!</h3>
            <p>
              Un asesor se contactará para poder ayudarte con tu solicitud a la
              brevedad posible
            </p>
            <div>
              <NavLink
                to="/mi-habitat-digital/empezar?noafiliado"
                className="btnBack"
              >
                REALIZAR OTRA SOLICITUD
                <i className="fa fa-arrow-left">&nbsp;</i>
              </NavLink>
            </div>
          </div>
          <form className={clase_3}>
            <div className="row">
              <div className="col-md-12">
                <h2 className="tituloForm">
                  Información del afiliado o fallecido
                </h2>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type">Tipo de documento*</Label>
                  <div className="input-group select">
                    <span>
                      <img src={icono_fingerprint} alt="" />
                    </span>
                    <select
                      className="form-control "
                      name="doc_type"
                      id="doc_type"
                      onChange={handleDocumentType}
                      // {...register}
                    >
                      {listOptions.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Nro. Documento*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_nrodoc} alt="" className="for-nrodoc" />
                    </span>
                    {docInput(watchMax)}
                    {errors.doc_num && errors.doc_num.type === "required" && (
                      <p
                        style={{ fontSize: "14px" }}
                        className="text-danger m-0"
                      >
                        Campo requerido
                      </p>
                    )}
                    {errors.doc_num && errors.doc_num.type === "maxLength" && (
                      <p
                        style={{ fontSize: "14px" }}
                        className="text-danger m-0"
                      >
                        campo de {lenghtNdoc} caracteres
                      </p>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">
                    Fecha de nacimiento del afiliado fallecido*
                  </Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_calendar} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="date"
                      id="fecha_nac"
                      autoComplete="off"
                      pattern="dd/mm/yyyy"
                      onChange={setMapa}
                      {...register("fecha_nac", {
                        required: true,
                        min: "1900-01-01",
                        max: "9999-12-31",
                      })}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {errors.fecha_nac && (
                    <p style={{ fontSize: "14px" }} className="text-danger m-0">
                      Campo Inválido
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type">
                    Parentesco del solicitante con el afiliado*
                  </Label>
                  <div className="input-group select">
                    <span>
                      <img src={icono_parentesco} alt="" />
                    </span>
                    <select
                      className="form-control "
                      name="parentesco"
                      id="parentesco"
                      onChange={setMapa}
                      // ref={register}
                    >
                      {parentescos.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 antes_title">
                <h2 className="tituloForm">Información del Solicitante</h2>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type_sol">Tu documento*</Label>
                  <div className="input-group select">
                    <span>
                      <img src={icono_fingerprint} alt="" />
                    </span>
                    <select
                      className="form-control "
                      name="doc_type_sol"
                      id="doc_type_sol"
                      onChange={handleDocumentType2}
                      // ref={register}
                    >
                      {listOptions.map((item, index) => (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Nro. Documento*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_nrodoc} alt="" className="for-nrodoc" />
                    </span>
                    {docInput2(watchMax2)}
                    {errors.doc_num_sol &&
                      errors.doc_num_sol.type === "required" && (
                        <p
                          style={{ fontSize: "14px" }}
                          className="text-danger m-0"
                        >
                          Campo requerido
                        </p>
                      )}
                    {errors.doc_num_sol &&
                      errors.doc_num_sol.type === "maxLength" && (
                        <p
                          style={{ fontSize: "14px" }}
                          className="text-danger m-0"
                        >
                          campo de {lenghtNdoc} caracteres
                        </p>
                      )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tu Fecha de nacimiento *</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_calendar} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="date"
                      id="fecha_nac_sol"
                      autoComplete="off"
                      pattern="dd/mm/yyyy"
                      onChange={setMapa}
                      {...register("fecha_nac_sol", {
                        required: true,
                        min: "1900-01-01",
                        max: "9999-12-31",
                      })}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {errors.fecha_nac_sol && (
                    <p style={{ fontSize: "14px" }} className="text-danger m-0">
                      Campo Inválido
                    </p>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tus nombres y apellidos*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_person} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombres_sol"
                      id="nombres_sol"
                      autoComplete="off"
                      pattern=""
                      onChange={setMapa}
                      {...register("nombres_sol", { required: true })}
                      placeholder="Ingresa tu nombre completo"
                    />
                    {errors.nombres_sol &&
                      errors.nombres_sol.type === "required" && (
                        <p
                          style={{ fontSize: "14px" }}
                          className="text-danger m-0"
                        >
                          Campo requerido
                        </p>
                      )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tu celular*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_phone} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      id="celular_sol"
                      autoComplete="off"
                      minLength={9}
                      maxLength={9}
                      pattern="9[0-9]+"
                      onChange={setMapa}
                      {...register("celular_sol", {
                        required: true,
                        maxLength: 9,
                        minLength: 9,
                        pattern: /^9[0-9]+$/i,
                      })}
                      placeholder="+51 000 000 000"
                    />
                    {errors.celular_sol && (
                      <p
                        style={{ fontSize: "14px" }}
                        className="text-danger m-0"
                      >
                        El formato de celular es inválido
                      </p>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tu correo*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_mail} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="correo_sol"
                      id="correo_sol"
                      autoComplete="off"
                      pattern="^([A-Za-z0-9._\-])@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}"
                      onChange={setMapa}
                      {...register("correo_sol", {
                        required: true,
                        pattern:
                          /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}/i,
                      })}
                      placeholder="ejemplo@gmail.com"
                    />
                    {errors.correo_sol && (
                      <p
                        style={{ fontSize: "14px" }}
                        className="text-danger m-0"
                      >
                        Ingrese un Email Válido
                      </p>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 for_required">
                (*) Campos obligatorios
              </div>
              <div className="col-md-12 buttons">
                <Button
                  // className="btnRegSolicitud"
                  onClick={handleSubmit(toggle)}
                >
                  Registrar Solicitud
                  <i className="fa fa-arrow-right">&nbsp;</i>
                </Button>
              </div>
              <div className="col-md-12 buttons">
                <NavLink
                  to="/mi-habitat-digital/empezar?noafiliado"
                  className="btnBack"
                >
                  Retroceder
                  <i className="fa fa-arrow-left">&nbsp;</i>
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-lg"
        id="modal-solicitud"
      >
        <ModalHeader toggle={toggle}>
          Verifica la información Ingresada
        </ModalHeader>
        <ModalBody className="body-modal">
          <form action="" className="form-solicitud-final">
            <div className="row">
              <div className="col-md-12">
                <h2 className="tituloForm">
                  Información del afiliado o fallecido
                </h2>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type">Tipo de documento*</Label>
                  <div className="input-group">
                    <span>
                      <img src={icono_fingerprint} alt="" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="r_tipodoc2"
                      id="r_tipodoc2"
                      autoComplete="off"
                      value="DNI"
                      // onChange={handleNumDoc}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Nro. Documento*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_nrodoc} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="doc_num2"
                      id="doc_num2"
                      autoComplete="off"
                      value="45856955"
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">
                    Fecha de nacimiento del afiliado fallecido*
                  </Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_calendar} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="fecha_nac"
                      id="fecha_nac"
                      value={fecha_nac}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type">
                    Parentesco del solicitante con el afiliado*
                  </Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_parentesco} alt="" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="parentesco"
                      id="parentesco"
                      autoComplete="off"
                      value={parentesco}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 antes_title">
                <h2 className="tituloForm">Información del Solicitante</h2>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_type">Tu documento*</Label>
                  <div className="input-group">
                    <span>
                      <img src={icono_fingerprint} alt="" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="r_tipodoc2"
                      id="r_tipodoc2"
                      autoComplete="off"
                      value="DNI"
                      // onChange={handleNumDoc}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Nro. Documento*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_nrodoc} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="doc_num_sol"
                      id="doc_num_sol"
                      autoComplete="off"
                      value={doc_num_sol}
                      // onChange={handleNumDoc}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">
                    Fecha de nacimiento del afiliado fallecido*
                  </Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_calendar} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="fecha_nac_sol"
                      id="fecha_nac_sol"
                      value={fecha_nac_sol}
                      // onChange={handleNumDoc}
                      // ref={register({ required: true, maxLength: dato[0] })}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tus nombres y apellidos*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_person} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="nombres_sol"
                      id="nombres_sol"
                      autoComplete="off"
                      value={nombres_sol}
                      // onChange={handleNumDoc}
                      // ref={register({ required: true, maxLength: dato[0] })}
                      placeholder="Ingresa tu nombre completo"
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="celular_sol">Tu celular*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_phone} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="celular_sol"
                      id="celular_sol"
                      autoComplete="off"
                      pattern=""
                      // onChange={handleNumDoc}
                      // ref={register({ required: true, maxLength: dato[0] })}
                      value={celular_sol}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label htmlFor="doc_num">Tu correo*</Label>
                  <div className="input-group ">
                    <span>
                      <img src={icono_mail} alt="" className="for-nrodoc" />
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      name="correo_sol"
                      id="correo_sol"
                      autoComplete="off"
                      pattern=""
                      value={correo_sol}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 for_required">
                (*) Datos obligatorios
              </div>
              <div className="col-md-12 buttons">
                <Button className="btnRegSolicitud" onClick={guardar}>
                  CONFIRMAR
                  {!loadingBtn ? (
                    <i className="fa fa-arrow-right">&nbsp;</i>
                  ) : (
                    <i className="fa fa-spin fa-spinner">&nbsp;</i>
                  )}
                </Button>
              </div>
              <div className="col-md-12 buttons">
                <Button name="secondary" bgColor="white" onClick={toggle}>
                  ACTUALIZAR LA INFORMACIÓN
                  <i className="fa fa-arrow-left">&nbsp;</i>
                </Button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Container>
  );
};
export default hocWidthWindow(SolicitudView);
