import { Box } from "@mui/material";
import styled from "styled-components";

export const LiStyle = styled("li")`
    list-style-type:none;
    &:before{
        content:'\00b7'; 
        font-size: 20px; 
        line-height: 24px; 
        vertical-align: middle;
    }
`;

export const BoxShadowFL = styled(Box)`
  padding: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  border-radius: 16px;
  background: #ffffff;
  width: 100%;
  margin: 24px 16px;
`;

export const TextAll = styled("div")`
  font-family: "Roboto";
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "20px")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  text-overflow: ${(props) =>
    props.textOverflow ? props.textOverflow : "clip"};
  color: ${(props) => (props.color ? props.color : "#363A3E")};
  padding: ${(props) => (props.padding ? props.padding : "8px 16px")};
  white-space: ${(props) =>
    props.textOverflow == "ellipsis" ? "nowrap" : "normal"};
  overflow: hidden;
`;

export const ButtonFL = styled("div")`
  background: ${(props) =>
    props.active == false || props.disable
      ? "#EDEDED"
      : props.primary
      ? "#CE1336"
      : "transparent"};
  border-radius: ${(props) => (props.isMobile ? "16px" : "16px")};
  border: ${(props) =>
    props.active == false || props.disable
      ? "1px solid #EDEDED"
      : "1px solid #CE1336"};
  // margin: ${(props) => (props.margin ? props.margin : "8px 0px")};
  padding: ${(props) => (props.padding ? props.padding : "8px")};
  text-align: center;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.active == false ? "#8C9195" : props.primary ? "#ffffff" : "#CE1336"};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  min-width: ${(props) => (props.isMobile ? "unset" : "240px")};
  line-height: 24px;
  pointer-events: ${(props) => (props.disable ? props.disable : "auto")};
  cursor: pointer;
  // height: ${(props) => (props.height ? props.height : "40px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  justify-content: center;
`;

export const ContentFlex = styled("div")`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  background: ${(props) => (props.color ? props.color : "#F6F6F6")};
  border-radius: 16px;
  padding: ${(props) => (props.padding ? props.padding : "16px")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  height: 100%;
  width: 100%;
`;

export const ContentFlexSmall = styled("div")`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  background: ${(props) => (props.color ? props.color : "#F6F6F6")};
  border-radius: 16px;
  padding: ${(props) => (props.padding ? props.padding : "8px")};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  height: 100%;
  width: 100%;
`;

export const ContentFlexStart = styled("div")`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  background: ${(props) => (props.color ? props.color : "#F6F6F6")};
  border-radius: 16px;
  padding: ${(props) => (props.padding ? props.padding : "16px")};
  align-items: start;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  height: 100%;
  width: 100%;
`;
export const FlexItem = styled("div")`
  flex-grow: ${(props) => (props.size ? props.size : "1")};
  flex-basis: ${(props) => (props.isMobile ? "unset" : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  // min-width: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
`;

export const ImageColor = styled("div")`
  background-color: ${(props) => (props.color ? props.color : "red")};
  width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.height ? props.height : "20px")};
  /* opacity: 0; */
  -webkit-mask: url(${(props) => (props.path ? props.path : "#51565F")})
    no-repeat center;
  mask: url(${(props) => (props.path ? props.path : "#51565F")}) no-repeat
    center;
`;

export const SaldoFondoLibre = styled("div")`
  width: 343px;
  height: 64px;
  background: #f2faff;
  border: 1px solid #2f6d95;
  border-radius: 16px;
  text-align: center;
  color: #2f6d95;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
