import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Detalle = ({ changeListadoGrafico, items }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const onChangeValue = (event, newValue) => {
    setSelectedItem(newValue);
    changeListadoGrafico(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedItem}
        onChange={onChangeValue}
        aria-label="basic tabs example"
      >
        {items.map((item, index) => (
          <Tab label={item.name} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
};

export default Detalle;
