import React from "react";
import Button from "../../../../components/Buttons";
import Divider from "../../../../components/Divider";
import DownloadIcon from "../../../../assets/IconBucket/DownloadIcon";
import { Content } from "./styles";
import { BsFilePdf } from "react-icons/bs";
import Modal, { ModalTitle } from "../../components/Modal";
import ExternalLink from "../../components/ExternalLink";

const ModalAutorizacionReembolso = ({ open, setOpen }) => {
  const onClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>Autorización de reembolso</ModalTitle>
      <Content>
        <p>
          Este documento es una autorización emitidia por parte de un familiar
          directo del afiliado fallecido, que habilita al solicitante a realizar
          un tramite de devolución de gastos por sepelio.
        </p>
        <p>
          Debe ser llenada con los datos descritos en el formato y firmada por
          el familiar que emite la autorización.
        </p>
        {/* <p>
          <ExternalLink
            href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx"
            target="_blank"
          >
            Aquí
          </ExternalLink>{" "}
          puedes conocer el CUSPP del afiliado.
        </p> */}
      </Content>
      <a
        href="https://www.afphabitat.com.pe/wp-content/uploads/2023/03/Gastos_de_sepelio_autorizacion.pdf"
        target={"_blank"}
        download="AUTORIZACIÓN DE TRÁMITE DE GASTOS DE SEPELIO"
        style={{ all: "initial" }}
      >
        <Button
          variant={"secondary"}
          style={{ margin: "auto" }}
          icon={<DownloadIcon />}
        >
          Descargar formato
        </Button>
      </a>
      <Divider />
      <Button fullWidth onClick={onClose} style={{ margin: "auto" }}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalAutorizacionReembolso;
