import React, { useState } from "react";
import { Button, CardContainer } from "../../components";
import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap";

const PrimerPaso = ({
  custpp,
  periodos,
  beneficiarios,
  loadingBeneficiario,
  loadingBtn,
  getBeneficiarios,
  clearBeneficiarios,
  clearBoleta,
  obtenerDatosBoleta,
}) => {
  let disableSelect = beneficiarios ? false : true;

  const [periodoSelect, setPeriodoSelect] = useState({
    value: "",
    info: "",
  });
  const [beneficiarioSelect, setBeneficiarioSelect] = useState({
    value: "",
    info: "",
  });

  const btnDisable =
    periodoSelect.info.trim().length !== 0 &&
    beneficiarioSelect.info.trim().length !== 0
      ? false
      : true;

  const handlePeriodo = (e) => {
    let option = e.target.value.length > 0 ? JSON.parse(e.target.value) : null;
    let objeto = { value: "", info: "" };
    setBeneficiarioSelect({ ...objeto });
    if (option) {
      objeto.value = e.target.value;
      objeto.info = option.r_periodo;

      let bodyFormData = new FormData();
      bodyFormData.set("r_cuspp", custpp);
      bodyFormData.set("r_periodo", option.r_periodo);
      getBeneficiarios(bodyFormData);
      // setDisableSelect(false);
    } else {
    }
    clearBeneficiarios();
    setPeriodoSelect({ ...objeto });
  };

  const handleBeneficiario = (e) => {
    let option = e.target.value.length > 0 ? JSON.parse(e.target.value) : null;
    let objeto = { value: "", info: "" };
    if (option) {
      objeto.value = e.target.value;
      objeto.info = option.r_valor;
    }
    clearBoleta();
    setBeneficiarioSelect({ ...objeto });
  };

  const validateSelect = () => {
    let result = false;

    if (
      periodoSelect.info.trim().length !== 0 &&
      beneficiarioSelect.info.trim().length !== 0
    ) {
      result = true;
    } else {
      result = false;
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateSelect) {
      let bodyFormData = new FormData();
      bodyFormData.set("r_beneficiario", beneficiarioSelect.info);
      obtenerDatosBoleta(bodyFormData);
    }
  };

  return (
    <CardContainer>
      <section>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="r_clave_web">Periodo</Label>
              <InputGroup>
                <select
                  className="form-control"
                  type="select"
                  id="doc_type"
                  value={periodoSelect.value}
                  onChange={handlePeriodo}
                >
                  <option value={""}>-Periodo-</option>
                  {periodos.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.r_periodo.slice(0, 4) +
                        "-" +
                        item.r_periodo.slice(4, 6)}
                    </option>
                  ))}
                </select>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label htmlFor="r_clave_nueva">
                Beneficiario{" "}
                {loadingBeneficiario && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Label>
              <select
                className="form-control"
                type="select"
                disabled={disableSelect}
                value={beneficiarioSelect.value}
                onChange={handleBeneficiario}
              >
                <option value={""}>-Beneficiarios-</option>
                {beneficiarios && (
                  <>
                    {beneficiarios.map((item, index) => (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.r_nom_apoderado}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </FormGroup>
          </Col>
        </Row>
      </section>
      <section className="d-flex justify-content-around">
        <Button
          className={btnDisable ? "btn-white btn-disable-white" : "btn-white"}
          disabled={btnDisable}
          onClick={handleSubmit}
        >
          Consultar Boleta
          {loadingBtn && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </Button>
      </section>
    </CardContainer>
  );
};

export default PrimerPaso;
