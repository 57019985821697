import styled from "styled-components";
import { Box } from "@mui/material";

export const CardContent = styled(Box)`
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.palette.grayscale[200]};
  padding: 16px;
  box-shadow: 0px 4px 13px rgba(27, 29, 39, 0.12);
  /* max-width: 343px; */
  background-color: ${(p) => p.theme.palette.white};
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
`;

export const ProductsConsidered = styled(CardContent)`
  padding: 20px 24px;
  ${(p) => p.theme.tipography.bodySmallMedium}

  .title {
    margin-bottom: 16px;
  }

  ul {
    li {
      list-style: none;
      margin-left: 24px;
      color: ${(p) => p.theme.palette.grayscale["500"]};

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
`;
