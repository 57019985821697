import React from "react";
import * as Table from "./tableComponents";

const PartOne = ({ nombre, cuspp }) => {
  return (
    <Table.Wrapper>
      <Table.Heading>Parte I: Identificación del Afiliado</Table.Heading>
      <Table.Row>
        <Table.Label>
          Apellido paterno, materno y nombres del afiliado
        </Table.Label>
        <Table.Detail borderRight={true}>{nombre}</Table.Detail>
      </Table.Row>
      <Table.Row>
        <Table.Label>CUSPP</Table.Label>
        <Table.Detail borderRight={true}>{cuspp}</Table.Detail>
      </Table.Row>
    </Table.Wrapper>
  );
};

export default PartOne;
