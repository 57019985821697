import styled, { css } from "styled-components";
import { Box, Grid } from "@mui/material";

export const Content = styled(Box)`
  margin-bottom: 12px;
  & h3 {
    ${(p) => p.theme.tipography.subtitle1}
  }
`;
export const ContentPension = styled(Box)`
  ${(p) => p.theme.tipography.body2}

  & h4 {
    ${(p) => p.theme.tipography.body2}
  }
`;

export const Chip = styled(Box)`
  color: ${(p) => p.theme.palette.white};
  background-color: ${(p) => p.theme.palette.grayscale.blod};
  border-radius: 8px;
  padding: 4px 24px;

  ${(p) =>
    p.color === "secondary" &&
    css`
      background-color: ${(p) => p.theme.palette.secondary.main};
    `}
`;

export const GridBorderColor = styled(Grid)`
  ${(p) =>
    p.HBTborderColor === "right" &&
    css`
      border-right-color: ${(p) => p.theme.palette.secondary.main};
      border-right-style: solid;
      border-right-width: 1px;
    `}

  ${(p) =>
    p.HBTborderColor === "left" &&
    css`
      border-left-color: ${(p) => p.theme.palette.secondary.main};
      border-left-style: solid;
      border-left-width: 1px;
    `}
`;

export const ContentPm = styled(Box)`
  margin-bottom: 16px;
  & h3 {
    ${(p) => p.theme.tipography.subtitle1}
  }
`;
