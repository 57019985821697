function getDigitoUno(banco,oficina){
                var num = banco + oficina;
                var pesos = new Array(0,1,2,1,0,2,1,2);
                var suma = 0;
                var digitoUno = null;
                var res = 0;
                var digito
                for(var i=0; i< num.length;i++)
                {
                               digito = parseInt(num.substring(i,i+1));
                               var res = parseInt(digito * pesos[i]);
                               if(res >= 10)
                               {
                                               var primerNum = parseInt(res / 10);
                                               var segundoNum = parseInt(res % 10);
                                               res = primerNum + segundoNum;           
                               }
                               suma = suma + res;
                }
                if ((suma % 10) != 0)
                               suma = 10 - (parseInt(suma % 10));
                else
                               suma = parseInt(suma % 10);
                               
                digitoUno = suma +         "";
                
                return digitoUno;
}

function getDigitoDos(control,cuenta){
                var num = control + cuenta;
                var pesos = new Array(1,2,1,2,1,2,1,2,1,2);
                var suma = 0;
                var digitoDos = null;
                var res = 0;
                var digito,primerNum,segundoNum
                for(var i=0; i < num.length;i++)
                {
                               digito = parseInt(num.substring(i,i+1));
                               res = parseInt(digito * pesos[i]);
                               if(res >= 10)
                               {
                                               primerNum = parseInt(res / 10);
                                               segundoNum = parseInt(res % 10);
                                               res = parseInt(primerNum + segundoNum);       
                               }
                               suma = suma + res;
                }
                if ((suma % 10) != 0)
                               suma = 10 - (parseInt(suma % 10));
                else
                               suma = parseInt(suma % 10);
                
                digitoDos = suma+"";
                return digitoDos;
}

export function obtenerCtaRemesa(value){
                var nro_cuenta_ = value;

                var banco = nro_cuenta_.substr(0,4);
                var oficina  = nro_cuenta_.substr(4,4);
                var dCuenta  = nro_cuenta_.substr(8,17);
                var d1 = getDigitoUno(banco, oficina);
    var d2 = getDigitoDos(dCuenta.substring(0, 2), dCuenta.substring(2));
    var lastNum= d1+ '' + d2
var position = 8;
    var positionNewWord = [nro_cuenta_.slice(0, position), lastNum, nro_cuenta_.slice(position)].join('');
    
                return positionNewWord

}

