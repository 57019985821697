import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { BsCheckLg } from "react-icons/bs";
import { cargo } from "../../DataLocal";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import debitDateOptions from "../../utils/debitDateOptions";
import Alert from "../../../../components/Alert";
import AporteapvsinfinContext from "../../../../context/aporteapvsinfin/aportarapvsinfinContext";

import {
  MontoAInvertirComponent,
  InformacionDeInversionComponente,
  FrecuenciaDeDebitoComponente,
  SectionTitleComponent,
  FechaDeCargoComponent,
  CargoDobleComponent,
  CargoDobleBotton,
  SwitchThumb,
  SwitchRoot,
  DebitDateSelect,
  MensajeCamposObligatorios,
  CardSelectComponent,
  MoneyInput,
  ErrorMessage,
  FrecuenciaDeDebitoHeader,
  ModalTrigger,
  DebitFrecuencyCardWrapper,
} from "./styles";
import clienteAxios from "../../../../config/axios";
import InfoButton from "../InfoButton";
import MonthlyDebitNotAvailableModal from "./MonthlyDebitNotAvailableModal";
import { useContext } from "react";

const InformacionDeInversion = ({
  setFrecuenciaCargo,
  montoACargar,
  handlerMonto,
  setFechaCargo,
  errores,
  cargoDoble,
  setCargoDoble,
  frecuenciaCargo,
  aporteMensual,
  fechaCargo,
  data,
}) => {
  return (
    <InformacionDeInversionComponente>
      <MontoAInvestir
        montoACargar={montoACargar}
        handlerMonto={handlerMonto}
        errores={errores}
      />
      <FrecuenciaDeDebito
        setFrecuenciaCargo={setFrecuenciaCargo}
        errores={errores}
        data={data}
        aporteMensual={aporteMensual}
      />
      {frecuenciaCargo === "1" && (
        <>
          <FechaDeCargo setFechaCargo={setFechaCargo} errores={errores} />
          <CargoDoble cargoDoble={cargoDoble} setCargoDoble={setCargoDoble} />
        </>
      )}
      <MensajeCamposObligatorios>
        Los campos marcados con <span>*</span> son obligatorios
      </MensajeCamposObligatorios>
      <MonthlyDebitNextMonthMessage fechaCargo={fechaCargo} />
    </InformacionDeInversionComponente>
  );
};

export default InformacionDeInversion;

const MonthlyDebitNextMonthMessage = ({ fechaCargo }) => {
  const [alertIsOn, setAlertIsOn] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const optionSelected = debitDateOptions.find((e) => e.value === fechaCargo);

    if (optionSelected === undefined) {
      return setAlertIsOn(false);
    }
    if (currentDay <= optionSelected.max && currentDay >= optionSelected.min) {
      return setAlertIsOn(true);
    }
    return setAlertIsOn(false);
  }, [fechaCargo]);

  if (alertIsOn) {
    return (
      <div style={{ gridColumn: "1/-1" }}>
        <Alert
          description={"Tu primer débito será a partir del siguiente mes. "}
        ></Alert>
      </div>
    );
  }

  if (!alertIsOn) {
    return <></>;
  }
};

const MontoAInvestir = ({ montoACargar, handlerMonto, errores }) => {
  const defaultMaskOptions = {
    prefix: "S/ ",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <MontoAInvertirComponent>
      <SectionTitle title="Monto a invertir" />
      <MoneyInput
        value={montoACargar}
        onChange={(e) => handlerMonto(e.target.value)}
        mask={currencyMask}
        placeholder="S/ 0.00"
      />
      {errores?.monto && <ErrorMessage>{errores.monto}</ErrorMessage>}
    </MontoAInvertirComponent>
  );
};

const FrecuenciaDeDebito = ({
  setFrecuenciaCargo,
  errores,
  data,
  aporteMensual,
}) => {
  const [currentOptionSelected, setCurrentOptionSelected] = useState(null);

  const handleOnSelectOption = (codigo) => {
    setCurrentOptionSelected(codigo);
    setFrecuenciaCargo(codigo);
  };

  const aporteapvsinfinContext = useContext(AporteapvsinfinContext);
  const { datosAportarApvSinFin } = aporteapvsinfinContext;

  const disableMonthlyDebitOption = (code, aporteMensual) =>
    code === "1" && (aporteMensual?.vigente === true || aporteMensual === null);

  const [
    monthlyDebitNotAvailableModalIsOpen,
    setMonthlyDebitNotAvailableModalIsOpen,
  ] = useState(false);

  const onClickModalTrigger = () =>
    setMonthlyDebitNotAvailableModalIsOpen(true);

  return (
    <FrecuenciaDeDebitoComponente>
      <FrecuenciaDeDebitoHeader>
        <SectionTitle title="Frecuencia de débito" className="frecuencia" />
        <InfoButton>
          <span>
            <b>Única: </b> Cargo que se debitará por única vez de la cuenta
            registrada.
          </span>
          <br />
          <br />
          <span>
            <b>Mensual: </b> Te permite programar un débito mensual según la
            fecha de cargo.
          </span>
        </InfoButton>
      </FrecuenciaDeDebitoHeader>
      {/* {cargo.opciones.map(({ codigo, nombre }, key) => {
        console.log({ codigo });
        return (
          <CardSelect
            key={`${codigo}-${nombre}`}
            optionSelected={currentOptionSelected}
            onSelect={() => handleOnSelectOption(codigo)}
            codigo={codigo}
            disabled={disableMonthlyDebitOption(codigo, aporteMensual)}
          >
            {nombre}
          </CardSelect>
        );
      })} */}
      {/* Unica */}
      <CardSelect
        key={`${cargo.opciones[0].codigo}-${cargo.opciones[0].nombre}`}
        optionSelected={currentOptionSelected}
        onSelect={() => handleOnSelectOption(cargo.opciones[0].codigo)}
        codigo={cargo.opciones[0].codigo}
      >
        {cargo.opciones[0].nombre}
      </CardSelect>

      {/* Mensual */}
      {datosAportarApvSinFin?.r_inhabilitar_debito_mensual !== 1 && (
        <CardSelect
          key={`${cargo.opciones[1].codigo}-${cargo.opciones[1].nombre}`}
          optionSelected={currentOptionSelected}
          onSelect={() => handleOnSelectOption(cargo.opciones[1].codigo)}
          codigo={cargo.opciones[1].codigo}
          disabled={disableMonthlyDebitOption(
            cargo.opciones[1].codigo,
            aporteMensual
          )}
        >
          {cargo.opciones[1].nombre}
        </CardSelect>
      )}

      {/* <CardSelect
        key={`${codigo}-${nombre}`}
        optionSelected={currentOptionSelected}
        onSelect={() => handleOnSelectOption(codigo)}
        codigo={codigo}
        disabled={disableMonthlyDebitOption(codigo, aporteMensual)}
      >
        {nombre}
      </CardSelect> */}
      {errores?.frecuenciaCargo && (
        <ErrorMessage>{errores.frecuenciaCargo}</ErrorMessage>
      )}
      {/* <MonthlyDebitNotAvailableModal
        isOpen={monthlyDebitNotAvailableModalIsOpen}
        setIsOpen={setMonthlyDebitNotAvailableModalIsOpen}
      /> */}
    </FrecuenciaDeDebitoComponente>
  );
};

const CardSelect = ({
  children,
  onSelect,
  optionSelected,
  codigo,
  disabled,
  ...props
}) => {
  return (
    <CardSelectComponent
      onClick={onSelect}
      isChecked={codigo === optionSelected}
      disabled={disabled}
      {...props}
    >
      <div className="radio">
        <BsCheckLg />
      </div>
      <p className="label">{children}</p>
    </CardSelectComponent>
  );
};

const SectionTitle = ({ title, style = null, ...props }) => {
  return (
    <SectionTitleComponent style={style} {...props}>
      <span>* </span>
      {title}
    </SectionTitleComponent>
  );
};

const FechaDeCargo = ({ setFechaCargo, errores }) => {
  const handleOnChange = ({ value }) => {
    setFechaCargo(value);
  };

  return (
    <FechaDeCargoComponent>
      <SectionTitle title="Fecha de débito" />
      <DebitDateSelect
        options={debitDateOptions}
        isSearchable={false}
        classNamePrefix={"select"}
        placeholder="Selecciona una fecha"
        onChange={handleOnChange}
      />
      {errores?.fechaCargo && <ErrorMessage>{errores.fechaCargo}</ErrorMessage>}
    </FechaDeCargoComponent>
  );
};

// Con la nueva actualizacion eso ya no es necesario
// const getDateOptions = () => {
//   const options = [];

//   for (let i = 1; i <= 28; i++) {
//     options.push({ value: `${i}`, label: `${i} de cada mes` });
//   }
//   return options;
// };

const CargoDoble = ({ cargoDoble, setCargoDoble }) => {
  const handleSwitch = (e) => setCargoDoble(e);
  return (
    <CargoDobleComponent>
      <SectionTitle style={{ visibility: "hidden" }} />
      <p className="lable">Cargo doble en Julio y Diciembre</p>{" "}
      <InfoButton>
        El cargo doble te permite invertir más con tu grati realizando un aporte
        doble en julio y diciembre
      </InfoButton>
      <CargoDobleBotton>
        <SwitchRoot value={cargoDoble} onCheckedChange={handleSwitch}>
          <SwitchThumb />
        </SwitchRoot>
      </CargoDobleBotton>
    </CargoDobleComponent>
  );
};
