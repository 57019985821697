import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import {
  ModalPlazosNormativosComponent,
  Header,
  Body,
  NextButton,
} from "./styles";
import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
const ModalPlazosNormativos = ({ open, onClose, frecuencia }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalPlazosNormativosComponent>
          <Header>
            <div className="close" onClick={onClose}>
              <BsX />
            </div>
            <p className="title">Plazos normativos</p>
          </Header>
          <Body>
            <div className="header">
              <AiOutlineCalendar />
              <div className="title">Transferencia bancaria</div>
            </div>
            <div className="description">
              {frecuencia === "MENSUAL"
                ? "Cada débito mensual se hará efectivo dentro de los siguientes 5 días útiles desde el primer día del rango seleccionado."
                : " Se concretará dentro de los siguientes 5 días útiles, que serán contados después de registrada la solicitud."}
            </div>
          </Body>
          <NextButton onClick={onClose}>Entendido</NextButton>
        </ModalPlazosNormativosComponent>
      </Fade>
    </Modal>
  );
};

export default ModalPlazosNormativos;
