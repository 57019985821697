import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CoinsIcon from "../../../../assets/IconBucket/Icon/CoinsIcon";
import SavingIcon from "../../../../assets/IconBucket/Icon/SavingIcon";
import ValidateToken from "../../../../components/validateToken";
import CardFondoSelected from "../../components/CardFondoSelected";
import dummyLyeric from "../../../../Dummys/dummyFondo.json";

import { Button, ModalGeneral } from "../../../../components";
import CardSelectedEtario from "../CardSelectedEtario.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ContentText = styled(Box)`
  margin: auto;
  color: #363a3e;
`;
const TextPrimary = styled.p`
  ${(props) => props.theme.tipography.h5};
  color: ${(props) => (props.color ? props.color : "#363A3E")};
  text-align: center;
  font-weight: 600;
  margin-bottom: 24px;
`;
const SubtitlePrimary = styled.p`
  ${(props) => props.theme.tipography.subtitle1};
  color: ${(props) => (props.color ? props.color : "#363A3E")};
  font-weight: 600;
  margin-bottom: 8px;
`;

const Title = styled.h1`
  ${(props) => props.theme.tipography.h1};
  font-weight: 400;
  margin-bottom: 32px;
`;

// const TextPrimary = styled.p`
//   ${(props) => props.theme.tipography.h5};
//   color: ${(props) => (props.color ? props.color : "#363A3E")};
//   font-weight: 600;
//   margin-bottom: 0;
// `;
const screenHeight = window.innerHeight;
const screenWidth = window.innerWidth;

const height =
  screenHeight <= 667
    ? 0
    : screenWidth >= 360 && screenWidth <= 400
    ? screenHeight - 680
    : screenHeight - 620;
const heightToken =
  screenHeight <= 667
    ? 0
    : screenWidth >= 360 && screenWidth <= 400
    ? screenHeight - 615
    : screenHeight - 625;
const heightDialog = screenHeight - 290;

const useStyles = makeStyles({
  DialogStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  DialogStyleChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },
  DialogTokenStyle: {
    "& .MuiDialog-paper": {
      borderRadius: screenHeight <= 667 ? "0px" : "16px 16px 0 0",
      top: `${heightToken}px !important`,
    },
  },
  DialoMessageStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      top: `${heightDialog}px !important`,
    },
  },
});

const Index = (props) => {
  const {
    setEtario,
    cambiarFondoEtario,
    datosCambioFondo,
    openConfirm,
    setOpenConfirm,
    setStep,
    setFondoActual,
    validacionEtarioFunction,
    validacionDatos,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:1500px)");
  const mediaScreen = useMediaQuery("(max-width:1364px)");
  const smallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));
  const smallScreen = useMediaQuery("(max-width:1300px)");
  const smallScreenSm = useMediaQuery(theme.breakpoints.down("sm"));
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState(false);
  const [openModalToken, setopenModalToken] = useState(false);
  const [requestInfoDialog, setRequestInfoDialog] = useState(false);

  const handleClose = () => {
    setopenModalToken(false);
  };
  const handleDialogCondition = () => {
    setOpen(false);
  };
  const handleDialogToken = () => {
    setopenModalToken(false);
  };

  const handleChangeFund = () => {
    const filterProduct = datosCambioFondo.filter(
      (item) => item.r_tipo_aporte !== "VSF"
    );
    setFondoActual(filterProduct[0]);
    setStep(0);
    validacionEtarioFunction(false);
    setEtario(true);
  };
  const aceptarProcesoModal = (token) => {
    const filterProductVCF = datosCambioFondo.filter(
      (item) => item.r_tipo_aporte === "VCF"
    );
    let sendData = {
      obl_destino: validacionDatos.tipo_etario === 60 ? 1 : 0,
      vcf_destino: filterProductVCF.length === 0 ? "" : 1,
      ind_decision: "C",
    };

    sendData.v_token_digital = token;
    cambiarFondoEtario(sendData);
  };

  const filterProduct = datosCambioFondo.filter(
    (item) => item.r_tipo_aporte !== "VSF"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "auto",
        height: "100%",
      }}
    >
      <ModalGeneral
        open={openConfirm}
        textPrimary="Estoy de acuerdo"
        textSecondary="Escoger otro fondo"
        textClose="Decidir luego"
        hasPrimary={true}
        hasSecondary={true}
        closeModal={() => setOpenConfirm(!openConfirm)}
        onClickPrimary={() => {
          setOpen(!open);
          setOpenConfirm(!openConfirm);
        }}
        onClickSecondary={handleChangeFund}
      >
        <ContentText>
          <ContentText>
            <TextPrimary>
              Ahora conoces más sobre el cambio de tipo de fondo etario
            </TextPrimary>
            <SubtitlePrimary>Así quedarían tus fondos:</SubtitlePrimary>
            <CardSelectedEtario
              products={filterProduct}
              validacionDatos={validacionDatos}
            />
          </ContentText>
        </ContentText>
      </ModalGeneral>

      <Box>
        <Dialog
          className={
            smallScreenSm ? classes.DialogStyleChages : classes.DialogStyle
          }
          fullScreen={smallScreenSm}
          open={open}
          aria-labelledby="alert-dialog-title"
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: " center" }}
          >
            <Box sx={{ maxWidth: "500px" }}>
              <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      handleDialogCondition();
                      setActiveButton(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Condiciones de cambio de tipo de fondo etario
                </Typography>
              </DialogTitle>

              <DialogContent>
                <>
                  <Box
                    sx={{
                      backgroundColor: "#F8F9FB",
                      borderRadius: "16px",
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{ textAlign: "justify", lineHeight: "18px" }}
                    >
                      Tu Fondo de Jubilación y Fondo con Fin Previsional
                      cambiarán al Fondo{" "}
                      {validacionDatos.tipo_etario === 60 ? 1 : 0}.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#F8F9FB",
                      borderRadius: "16px",
                      p: 2,
                      mb: screenHeight <= 667 ? 1 : 2,
                    }}
                  >
                    <Typography
                      sx={{ textAlign: "justify", lineHeight: "18px" }}
                    >
                      Tu solicitud de cambio de tipo de fondo etario pasará por
                      un proceso de validación, cuando finalice te enviaremos un
                      correo electrónico informando el resultado de dicha
                      validación.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F8F9FB",
                      borderRadius: "16px",
                      p: 2,
                      mb: screenHeight <= 667 ? 1 : 2,
                    }}
                  >
                    <Typography
                      sx={{ textAlign: "justify", lineHeight: "18px" }}
                    >
                      Se considerará el valor cuota vigente a la fecha de
                      aplicación de cada solicitud de tipo de fondo.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F8F9FB",
                      borderRadius: "16px",
                      p: 2,
                      mb: screenHeight <= 667 ? 1 : 2,
                    }}
                  >
                    <Typography
                      sx={{ textAlign: "justify", lineHeight: "18px" }}
                    >
                      Podrás cambiar nuevamente el fondo de tus productos una
                      vez esté aplicado este cambio de fondo etario.
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeButton ? true : false}
                        onClick={() => {
                          setActiveButton(!activeButton);
                        }}
                      />
                    }
                    label="Acepto las condiciones"
                  />

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      pr: 2,
                      pl: 2,
                      pb: 2,
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        width: "50%",
                        "& button": { width: "100%" },
                      }}
                    >
                      <Button
                        disabled={!activeButton ? true : false}
                        onClick={() => {
                          // handleSelect(fondoActual, fondoDestino);
                          setopenModalToken(true);
                          handleDialogCondition();
                          setActiveButton(false);
                          // guardarCambio();
                        }}
                        variant="contained"
                        color="primary"
                        sx={{
                          "&:hover": {
                            background: "#CE1336",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Aceptar
                      </Button>
                    </Box>
                  </Box>
                </>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>

        {openModalToken && (
          <Dialog
            className={
              smallScreen ? classes.DialogStyleChages : classes.DialogStyle
            }
            fullScreen={smallScreen}
            open={openModalToken}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box>
                <DialogTitle sx={{ pb: 0 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={() => handleDialogToken()}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <ValidateToken
                    // setMostrarModalToken={setMostrarModalToken}
                    // setMostrarMensajeInfo={setMostrarMensajeInfo}
                    children={
                      <Box
                        sx={{
                          border: "1px solid #EDEDED",
                          borderRadius: "16px",
                          p: 1,
                          display: "flex",
                          flexDirection: mediaScreen && "column",
                          alignItems: "center",
                        }}
                      >
                        {filterProduct.map((item) => {
                          return (
                            <Box>
                              <Box sx={{ display: "flex" }}>
                                <SavingIcon size={22} color="black" />
                                &nbsp;
                                <Typography
                                  color="textColor.main"
                                  sx={{ fontWeight: 500, fontSize: "16px" }}
                                >
                                  {item.r_aporte === "APORTE OBLIGATORIO" &&
                                    "Fondo de Jubilación"}
                                  {item.r_aporte ===
                                    "APORTE VOLUNTARIO CON FIN" &&
                                    "Fondo con Fin Previsional"}
                                </Typography>
                              </Box>
                              <Box>
                                <CardFondoSelected
                                  fondoActual={item}
                                  fondoDestino={
                                    validacionDatos.tipo_etario === 60 ? 1 : 0
                                  }
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    }
                    activeChildren={smallScreen ? true : false}
                    cerrarModal={() => {
                      setopenModalToken(false);
                    }}
                    guardarCode={aceptarProcesoModal}
                    setRequestInfoDialog={setRequestInfoDialog}
                    handleClose={handleClose}
                  />
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
        )}

        <Dialog
          open={requestInfoDialog}
          TransitionComponent={Transition}
          keepMounted
          fullScreen={smallScreen}
          className={smallScreen ? classes.DialoMessageStyle : ""}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      onClick={() => {
                        setRequestInfoDialog(false);
                        setopenModalToken(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    No puedo validar esta solicitud
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography>
                Por tu seguridad, si ya no cuentas con tus métodos de validacion
                registrados, comunicate con nosotros.
              </Typography>
              <Box sx={{ width: "100%", display: "flex", pt: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Llama al:&nbsp;
                </Typography>

                <Typography
                  sx={{ textDecoration: "underline", color: "#2F6D95" }}
                >
                  01 2302200
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                pr: 2,
                pl: 2,
                pb: 2,
                pt: 2,
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  width: "50%",
                  "& button": { width: "100%" },
                }}
              >
                <Button
                  onClick={() => {
                    setRequestInfoDialog(false);
                    setopenModalToken(true);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Aceptar
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        {/* {mostrarModalToken && (
          <ModalErrorToken
            Transition={Transition}
            smallScreen={smallScreen}
            mostrarModalToken={mostrarModalToken}
            mensaje={messageErro[0].message}
            classes={classes.DialoMessageStyle}
            cerrarModal={() => {
              setMostrarModalToken(false);
            }}
            guardarCode={() => {
              setMostrarModalToken(false);
              setopenModalToken(true);
            }}
          />
        )} */}
      </Box>
    </Box>
  );
};

export default Index;
