import React, { useState } from "react";
import "./index.css";
import { withRouter } from "react-router";
import ListaBeneficios from "./Components/ListaBeneficios";
import { Button } from "../../components";
const ModalFilter = ({
  dispositivo,
  buttonCloseTop,
  info,
  titulo,
  closeModal,
  closeBtnExitoso,
  openModalAlertaX,
  openModalMessage,
  history,
  closeBtn,
  dato,
}) => {
  const [checkList, setCheckList] = useState([]);
  const obj = {
    id: "",
    checked: "",
  };
  const opcional = [];

  const onCheckInput = (e) => {
    const id = e.target.id;
    const checked = e.target.checked;
    obj.id = id;
    obj.checked = checked;

    const filterList = checkList.filter(
      (value) => Number(value.id) === Number(id)
    );
    if (filterList.length !== 0) {
      const newData = checkList.map((obj) => {
        if (Number(obj.id) === Number(id)) {
          return Object.assign({}, obj, { checked: checked });
        } else {
          return obj;
        }
      });
      setCheckList(newData);
    } else {
      setCheckList((oldArray) => [...oldArray, obj]);
    }
  };
  const filterListValues = checkList.filter((value) => value.checked === true);
  const filterListValuesValidate = checkList
    .map((value) =>
      Number(value.id) === 0 || Number(value.id) === 1
        ? value
        : opcional.push(value)
    )
    .filter((value) => value !== null)
    .filter((value) => value.checked === true);
  const optionalData = opcional.filter((value) => value.checked === true);

  const onAcceptModal = () => {
    if (dato === 6) {
      if (filterListValues.length < 3) {
        openModalAlertaX();
      } else {
        closeModal();
      }
    } else {
      if (
        (filterListValuesValidate.length < 2 &&
          (optionalData.length <= 1 || optionalData.length <= 3)) ||
        (filterListValuesValidate.length === 2 && optionalData.length < 1)
      ) {
        openModalAlertaX();
      } else {
        closeModal();
      }
    }
  };
  return (
    <section
      className={
        buttonCloseTop ? `wraper-modal` : `wraper-modal wraper-modal-force-p`
      }
    >
      <div
        className={
          buttonCloseTop ? `modal-content` : `modal-content modal-content-full`
        }
      >
        {buttonCloseTop && (
          <div className="modal-close">
            <i className="fa fa-times-circle-o" onClick={() => closeBtn()}></i>
          </div>
        )}

        <div className={`mb-modal-title d-flex`}>
          {dispositivo === "mobile" ? (
            // <p onClick={closeModal}>
            <p>
              <i className="fa fa-chevron-left" onClick={closeModal}></i>
            </p>
          ) : (
            ""
          )}

          <p>
            <strong>{titulo}</strong>
          </p>
        </div>
        <section>
          <p className="text-note">
            {dato === 6
              ? "Para continuar con el proceso de pension por invalidez, marque las opciones y validaremos si cumple con los requisitos"
              : "Para continuar con el proceso de bono por reconocimiento, marque las opciones y validaremos si cumple con los requisitos"}
          </p>
          <section>
            <ListaBeneficios opcion={dato} onCheckInput={onCheckInput} />
          </section>

          <section className="d-flex justify-content-around">
            <Button onClick={() => closeBtn()} className="btn-white">
              Cerrar
            </Button>
            <Button onClick={() => onAcceptModal()} className="btn-white">
              Continuar
            </Button>
          </section>
        </section>
      </div>
    </section>
  );
};

export default withRouter(ModalFilter);
