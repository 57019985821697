import React, { useContext, useEffect } from "react";
import "./index.css";
import EcnoaportanteContext from "../../context/estadocuentanoaportante/ecnoaportanteContext";
import AuthContext from "../../context/autenticacion/authContext";
import ModalContext from "../../context/modal/modalContext";
import ReactGa from "react-ga";
import Loading from "../../components/Loading/index.jsx";
import ConSaldo from "./ConSaldo";
import SinSaldo from "./SinSaldo";
import { Page, NotFoundData, CardContainer } from "../../components";

const EstadoCuentaNoAportante = () => {
  const ecnoaportanteContext = useContext(EcnoaportanteContext);
  const {
    resultado,
    datos,
    deuda,
    mensajeAlerta,
    obtenerDatosNoAportante,
    limpiarVariablesEcnoaportanteParcial,
  } = ecnoaportanteContext;

  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;

  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //si hay un error mostraremos un mensaje
    if (mensajeAlerta) {
      mostrarMensaje();
    } else {
      ReactGa.pageview(window.location.href);
      obtenerDatosNoAportante();
    }

    return () => {
      limpiarVariablesEcnoaportanteParcial();
      actualizarTimer();
    };
    // eslint-disable-next-line
  }, [mensajeAlerta]);

  const mostrarMensaje = () => {
    setMessage(mensajeAlerta);
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (resultado === null) return <Loading />;
  return (
    <Page title="Estado de cuenta no aportante" showHeader={"mobile"}>
      {!resultado ? (
        <CardContainer>
          <NotFoundData
            mensaje={
              <span>
                Actualmente vienes aportando a tu Fondo de Jubilación por lo
                tanto no tienes este tipo de cuenta.
                <br /> Te invitamos a seguir navegando dentro de Mi Habitat
                Digital.
              </span>
            }
            btnText="Volver al inicio"
            icon={true}
            url={"/mi-habitat-digital/afiliado/inicio"}
          />
        </CardContainer>
      ) : (
        <CardContainer>
          {Number(datos.r_saldo) > 0 ? (
            <ConSaldo datos={datos} deuda={deuda} />
          ) : (
            <SinSaldo secuencial={datos.r_secuencial} deuda={deuda} />
          )}
        </CardContainer>
      )}
    </Page>
  );
};

export default EstadoCuentaNoAportante;
