import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { Container } from "reactstrap";
import IC_RECHAZADA from "./../../../../../assets/icons/ic_rechazada.svg";
import IC_EN_ESPERA from "./../../../../../assets/icons/ic_en_espera.svg";
import { useParams } from "react-router-dom";
import PagosDesembolsosContext from "./../../../../../context/pagosDesembolsos/pagosDesembolsosContext";
import moment from "moment";
import styled from "styled-components";
import {
  BoxDesktop,
  BoxMobile,
} from "../../../componentes/data/components/BreadcrumbsFL";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PBold = styled.div`
  color: #2b2b2b;
  display: inline-block;
`;

export const ItemState = ({
  fec_est_des,
  monto_acreditado,
  fec_estado_ro,
  fecha_solicitud,
  armadas,
  mod_retiro,
  fec_aplicacion,
  pais_entidad,
  moneda,
  estado,
  monto_solicitado,
  fec_estado_rch,
  fec_estado_tr,
  motivo_rch,
  fecha_recep,
  fec_estado_acr,
  prox_fecha_aprobada,
  prox_fecha_procesada,
  fecha_procedencia,
  current_sol_status,
}) => {
  const { tipo } = useParams();

  const icons = [
    <Container
      style={{
        background: "#EFBD0C",
        borderRadius: "250px",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={IC_RECHAZADA} height={24} width={24} alt="rechazada"/>
    </Container>,
    <CheckTwoToneIcon
      fontSize="large"
      sx={{
        padding: "0.3rem",
        borderRadius: "120px",
        background: "#1B7935",
        color: "#FFF",
      }}
    />,
    <Container
      style={{
        background: "#EDEDED",
        borderRadius: "250px",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={IC_EN_ESPERA} height={24} width={24} alt="wait"/>
    </Container>,
  ];

  const titulos = ["Completada", "Estás aquí", "En espera"];

  const subtitulos = [
    "Solicitud ingresada",
    estado === "RETIROS EXTRAORDINARIOS"
      ? "Solicitud procesada"
      : estado.trim() === "ACREDITADO"
      ? "Resultado de la solicitud"
      : estado === "RECHAZADA"
      ? "Solicitud rechazada"
      : "Resultado de la solicitud",
    "Solicitud procesada",
  ];
  const mensajeExtraordinarios =
    armadas?.length > 1 ? (
      <>Las fechas de pagos mostradas por cada armada son aproximadas.</>
    ) : (
      <>La fecha de pago mostrada es aproximada.</>
    );
  const mensajes = [
    tipo === "retiros-extraordinarios" ? (
      <>
        Tu solicitud se ingresó el{" "}
        <PBold>
          {tipo === "retiros-extraordinarios"
            ? fecha_solicitud
            : moment(new Date(fecha_recep)).format("DD/MM/YYYY")}
        </PBold>
        . Será evaluada y de ser conforme se hará el desembolso correspondiente.
      </>
    ) : (
      <>
        Se ingresó el{" "}
        <PBold>
          {tipo === "retiros-extraordinarios"
            ? fecha_solicitud
            : moment(new Date(fecha_recep)).format("DD/MM/YYYY")}{" "}
        </PBold>
        . Ahora nos toca evaluarla. Si todo está conforme, haremos el desembolso
        correspondiente.
      </>
    ),
    current_sol_status === "INGRESADA" ? (
      <>
        Te contaremos si fue aprobada o rechazada aproximadamente el{" "}
        <PBold> {prox_fecha_aprobada}.</PBold>
      </>
    ) : current_sol_status === "RECHAZADA" ? (
      tipo === "tfe" ? (
        <>
          Lo sentimos, tu solicitud ha sido rechazada el{" "}
          <PBold>{moment(new Date(fec_estado_ro)).format("DD/MM/YYYY")}.</PBold>
        </>
      ) : tipo === "credito-hipotecario" ? (
        <>
          Lo sentimos, tu solicitud ha sido rechazada el{" "}
          <PBold>{moment(new Date(fec_est_des)).format("DD/MM/YYYY")}.</PBold>
        </>
      ) : (
        <>
          Lo sentimos, tu solicitud ha sido rechazada el{" "}
          <PBold>
            {moment(new Date(fec_estado_rch)).format("DD/MM/YYYY")}.
          </PBold>{" "}
          Por el siguiente motivo: <PBold> {motivo_rch}.</PBold>
        </>
      )
    ) : tipo === "tfe" ? (
      <>
        ¡Tu solicitud fue aprobada el{" "}
        <PBold>{moment(new Date(fec_estado_tr)).format("DD/MM/YYYY")}</PBold>!
      </>
    ) : tipo === "retiro-apv" ? (
      <>
        ¡Tu solicitud fue aprobada el{" "}
        <PBold>{moment(new Date(fec_estado_acr)).format("DD/MM/YYYY")}</PBold>!
      </>
    ) : (
      <>
        Tu solicitud fue aprobada en la siguiente fecha de procedencia:{" "}
        <PBold>
          {moment(new Date(fecha_procedencia)).format("DD/MM/YYYY")}
        </PBold>
        .
      </>
    ),

    current_sol_status === "INGRESADA" || current_sol_status === "APROBADA" ? (
      tipo === "credito-hipotecario" && mod_retiro === "Amortización" ? (
        <>
          {" "}
          Si la solicitud procede, realizaremos la transferencia aproximadamente
          el <PBold> {prox_fecha_procesada}.</PBold>
        </>
      ) : tipo === "credito-hipotecario" && mod_retiro === "Cuota Inicial" ? (
        <>
          Si la solicitud procede, realizaremos la transferencia tras la
          indicación de la entidad financiera.{" "}
        </>
      ) : (
        <>
          {" "}
          Si la solicitud procede, realizaremos la transferencia aproximadamente
          el <PBold> {prox_fecha_procesada}.</PBold>
        </>
      )
    ) : tipo === "tfe" ? (
      pais_entidad === "Peru" && moneda === "Soles" ? (
        <>
          ¡Transferencia lista! El monto de S/
          {tipo === "tfe" ? monto_acreditado : monto_solicitado} fue depositado
          el{" "}
          <PBold>
            {" "}
            {moment(new Date(fec_aplicacion)).format("DD/MM/YYYY")}
          </PBold>{" "}
        </>
      ) : (
        <>
          ¡Transferencia lista! Se depositó S/
          {tipo === "tfe" ? monto_acreditado : monto_solicitado} a tu número de
          cuenta bancaria el{" "}
          <PBold>
            {" "}
            {moment(new Date(fec_aplicacion)).format("DD/MM/YYYY")}
          </PBold>
          . Recuerda que recibirás el monto mencionado en el plazo indicado por
          tu banco.{" "}
        </>
      )
    ) : tipo === "retiro-apv" ? (
      <>
        ¡Transferencia lista! Fue realizada a tu cuenta bancaria el{" "}
        <PBold> {moment(new Date(fec_aplicacion)).format("DD/MM/YYYY")}.</PBold>{" "}
        Recuerda que al monto transferido le corresponde un descuento por
        comisión e impuesto a la renta (IR).
      </>
    ) : (
      <>
        ¡Transferencia lista! El monto de S/
        {tipo === "credito-hipotecario"
          ? monto_acreditado
          : monto_solicitado}{" "}
        fue depositado el{" "}
        <PBold>{moment(new Date(fec_aplicacion)).format("DD/MM/YYYY")}</PBold>.
      </>
    ),
  ];

  return (
    <>
      <ItemPagoDesembolso
        key="1"
        id={1}
        colorLine={"#1B7935"}
        icon={icons[1]}
        titulo={
          current_sol_status === "APROBADA" ||
          current_sol_status === "PROCESADA" ||
          tipo === "retiros-extraordinarios"
            ? titulos[0]
            : current_sol_status === "RECHAZADA"
            ? titulos[0]
            : titulos[1]
        }
        subtitulo={subtitulos[0]}
        mensaje={mensajes[0]}
        hasExpandend={estado === "EN CURSO" && current_sol_status !== "APROBADA"}
      />
      <ItemPagoDesembolso
        key="2"
        id={2}
        colorLine={
          tipo === "retiros-extraordinarios"
            ? "#1B7935"
            : current_sol_status === "PROCESADA" ||
              current_sol_status === "APROBADA"
            ? "#1B7935"
            : "#ACAFB3"
        }
        icon={
          tipo === "retiros-extraordinarios"
            ? icons[1]
            : current_sol_status === "APROBADA" ||
              current_sol_status === "PROCESADA"
            ? icons[1]
            : current_sol_status === "RECHAZADA"
            ? icons[0]
            : icons[2]
        }
        titulo={
          titulos[
            current_sol_status === "APROBADA"
              ? estado === "EN CURSO"
                ? 1
                : 0
              : current_sol_status === "RECHAZADA"
              ? 1
              : current_sol_status === "PROCESADA" ||
                tipo === "retiros-extraordinarios"
              ? 0
              : 2
          ]
        }
        subtitulo={subtitulos[1]}
        mostrarCorreo={
          current_sol_status === "RECHAZADA" &&
          (tipo === "credito-hipotecario" || tipo === "tfe")
        }
        ultimo={
          current_sol_status === "RECHAZADA" || tipo === "retiros-extraordinarios"
        }
        mensaje={
          tipo === "retiros-extraordinarios"
            ? mensajeExtraordinarios
            : mensajes[1]
        }
        isOpacy={estado === "EN CURSO" && current_sol_status !== "APROBADA"}
        hasExpanded={tipo === "retiros-extraordinarios"}
      />
      {estado !== "RECHAZADA" && tipo !== "retiros-extraordinarios" && (
        <ItemPagoDesembolso
          key="3"
          id={3}
          colorLine={current_sol_status === "PROCESADA" ? "#1B7935" : "#ACAFB3"}
          icon={
            current_sol_status === "APROBADA"
              ? icons[2]
              : current_sol_status === "PROCESADA"
              ? icons[1]
              : icons[2]
          }
          titulo={
            titulos[
              current_sol_status === "APROBADA"
                ? 2
                : current_sol_status === "PROCESADA"
                ? 0
                : 2
            ]
          }
          ultimo={true}
          subtitulo={subtitulos[2]}
          mensaje={mensajes[2]}
          isOpacy={estado === "EN CURSO"}
          hasExpanded={
            current_sol_status === "PROCESADA" || estado === "ACREDITADO"
          }
        />
      )}
    </>
  );
};

export const ItemPagoDesembolso = ({
  hasExpanded = false,
  titulo,
  subtitulo,
  mensaje,
  icon,
  colorLine,
  ultimo = false,
  mostrarCorreo = false,
  id,
  isOpacy = false,
}) => {
  const pagosDesembolsosContext = useContext(PagosDesembolsosContext);

  const { user } = pagosDesembolsosContext;

  const [expanded, setExpanded] = useState(
    hasExpanded || titulo === "Estás aquí" ? id : null
  );
  useEffect(() => {
    setExpanded(hasExpanded || titulo === "Estás aquí" ? id : null);
  }, [titulo, hasExpanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={2} md={1}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </div>
        </Grid>
        <Grid item xs={10} md={11}>
          <Box
            sx={{
              padding: "0.5rem 0.2rem",
            }}
          >
            <div
              style={{
                padding: "0 6px 6px 8px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.2px",
                color: isOpacy ? "#ACAFB3" : "#2B2B2B",
                display: "flex",
                alignItems: "center",
                margin: "auto !important",
              }}
            >
              {" "}
              {titulo}{" "}
            </div>
          </Box>
        </Grid>

        <Grid item xs={2} md={1}>
          <div
            style={{
              margin: "auto",
              height: ultimo ? "0px" : "100%",
              backgroundImage: `repeating-linear-gradient(0deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px), repeating-linear-gradient(90deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px),repeating-linear-gradient(180deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px),repeating-linear-gradient(270deg, ${colorLine}, ${colorLine} 2.52px, transparent 4px, transparent 14.71px, ${colorLine} 21px)`,
              backgroundSize: "3px 100%, 100% 3px, 3px 100% , 100% 3px",
              backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
              backgroundRepeat: "no-repeat",
              width: "2px",
              textAlign: "center",
            }}
          ></div>
        </Grid>

        <Grid
          item
          xs={10}
          md={11}
          sx={{
            paddingBottom: "0px",
          }}
        >
          <Box
            sx={{
              padding: {
                xs: ultimo ? "0 0.7rem" : "1rem 0.7rem",
                md: "0 0.7rem",
              },
            }}
          >
            <BoxDesktop>
              <div
                style={{
                  padding: "0 0.12rem 0.12rem 0.12rem",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: "16px",
                  color: isOpacy ? "#6E7378" : "#101010",
                  lineHeight: "24px",
                  letterSpacing: "0.2px",
                  margin: "auto !important",
                }}
              >
                {subtitulo}
              </div>

              <Box
                sx={{
                  display: { xs: ultimo ? "block" : "none", md: "block" },
                }}
              >
                <div className="detail_message_container">
                  <div className="detail_message_container-msg">{mensaje}</div>
                </div>
              </Box>
            </BoxDesktop>

            <BoxMobile style={{ padding: "0" }}>
              <Accordion
                expanded={expanded === id}
                onChange={handleChange(id)}
                sx={{
                  padding: "0",
                  boxShadow: "0",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="panel1bh-header"
                  sx={{
                    padding: "0",
                  }}
                >
                  <div
                    style={{
                      padding: "0 0.12rem 0.12rem 0.12rem",
                      fontWeight: "400",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: isOpacy ? "#6E7378" : "#101010",
                      lineHeight: "24px",
                      letterSpacing: "0.2px",
                      margin: "auto !important",
                    }}
                  >
                    {subtitulo}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0",
                  }}
                >
                  <Box>
                    <div className="detail_message_container">
                      <div className="detail_message_container-msg">
                        {mensaje}
                      </div>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </BoxMobile>
          </Box>
          {mostrarCorreo && (
            <div
              style={{
                fontSize: "12px",
                padding: "1rem",
                background: "#FFF8E0",
                borderRadius: "16px",
              }}
            >
              El motivo fue enviado a tu correo electrónico:{" "}
              <div
                style={{
                  color: "#3D769A",
                }}
              >
                {user?.email}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
