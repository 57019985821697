import React, { useState } from "react";
import "./customModal.css";
import "./beautyModal.css";

import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  Divider,
  Typography,
} from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DialogActions from "@mui/material/DialogActions";
import {
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";
import { EditOffOutlined, EditOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const palletteColors = {
  Red: "#CE1336",
  Blue: "#2F6D95",
  BlueBg: "#F2FAFF",
  red50: "#FFEBEF",
  Grey: "#CFCFCF",
  GreyNeutro: "#363A3E",
  GreyBg: "#F6F6F6",
};

const useStyles = makeStyles({
  selectStyles: {
    borderRadius: "16px",
  },
  paperStyles: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
    },
  },
  paperStylesChages: {
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px 16px 0 0",
      maxHeight: "95%",
      height: "unset",
    },
  },

  buttonUpCloseStyles: {
    "&.MuiIconButton-root": {
      padding: "0",
    },
  },
  buttonsDownStyles: {
    borderRadius: "16px",
    height: "40px",
    padding: "4px 0px",
    width: "100%",
  },
});

const styles = {
  titleModal: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeigt: "28px",
  },
  ContentModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
  },
  ContentAdviceModal: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeigt: "16.41px",
    color: "black",
  },
};

const CustomModal = (props) => {
  const {
    showModal,
    fnCloseIconBtn,
    title,
    fnBtnPrimary = false,
    fnBtnSecondary = false,
    btnPrimaryLoading,
    btnSecondaryLoading,
    onClosed,
    textBtnPrimary,
    textBtnSecondary,
    isDisabled = false,
    acceptField,
    fnBtnChecked = false,
  } = props;

  /* MUI CSS styles */
  const classes = useStyles();
  /* Breakpoints MUI */
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  /* Breakpoint para la pantalla */
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [checked, setChecked] = useState(false);

  const renderPrimaryIcon = () => {
    if (btnPrimaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const renderSecondaryIcon = () => {
    if (btnSecondaryLoading)
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    else return false;
  };

  const handleChangeBox = (event) => {
    fnBtnChecked(event.target.checked);
  };
  //showModal
  return (
    <Dialog
      className={fullScreen ? classes.paperStylesChages : classes.paperStyles}
      open={showModal}
      onClose={onClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      maxWidth="xs"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.buttonUpCloseStyles}
              onClick={fnCloseIconBtn}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!fnBtnSecondary && (
              <TaskAltOutlinedIcon
                sx={{ marginRight: "4px", width: "32px", height: "32px" }}
                color="success"
              />
            )}
            <Typography sx={styles.titleModal}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px" }}>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {fnBtnSecondary ? (
          <>
            <Typography sx={styles.ContentModal}>
              <div>
                Si tus datos bancarios no son correctos, el proceso de pago y
                cobro será mediante un cheque por ventanilla, deberás acercarte
                con tu documento de identidad en los siguientes bancos:
              </div>
              <div style={{ marginTop: "20px" }}>
                <ul style={{ padding: "0px 27px" }}>
                  <li>Scotiabank: Si utilizas DNI peruano.</li>
                  <li>BCP: Si utilizas Carnet de Extranjería o Pasaporte.</li>
                </ul>
              </div>
            </Typography>
            <Alert
              sx={{ alignItems: "center" }}
              severity="warning"
              color="warning"
            >
              <span style={styles.ContentAdviceModal}>
                Prevén el rechazo evitando el registro de una cuenta
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  bancaria inactiva, mancomunada
                </span>{" "}
                y/o la carga de un sustento bancario{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  incompleto o adulterado.
                </span>
              </span>
            </Alert>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                margin: "16px 0px",
              }}
            >
              <Checkbox
                defaultChecked={acceptField}
                onChange={handleChangeBox}
              />
              <Typography sx={styles.ContentModal}>
                He verificado que el número de cuenta que estoy registrando es
                correcto. AFP Habitat no será responsable si la cuenta es
                incorrecta.
              </Typography>
            </Box>
            <Divider />
          </>
        ) : (
          <>
            <Typography sx={styles.ContentModal}>
              Método de pago configurado con éxito. Haz clic en continuar para
              ir al siguiente paso.
            </Typography>
            <Divider sx={{ marginTop: "16px" }} />
          </>
        )}

        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ p: 1, justifyContent: "space-evenly" }}>
          {fnBtnSecondary && (
            <Grid item xs={5}>
              <Button
                variant="outlined"
                className={classes.buttonsDownStyles}
                onClick={fnCloseIconBtn}
                disabled={isDisabled ? btnPrimaryLoading : false}
                startIcon={<EditOutlined />}
              >
                {textBtnSecondary}
              </Button>
            </Grid>
          )}

          {fnBtnPrimary && (
            <Grid item xs={fnBtnSecondary ? 5 : 12}>
              <Button
                variant="contained"
                className={classes.buttonsDownStyles}
                onClick={fnBtnPrimary}
                autoFocus
                disabled={fnBtnSecondary ? !acceptField : false}
              >
                {textBtnPrimary}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
