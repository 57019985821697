import React from 'react';
import Topics from './Topic';
import './index.css';

export default function Timeline(props) {
    const { topicsData, currentTopic, currentTopicData, setModalBlockTopic, setTopicForBlock, logicToChangeTopics, isRepeatWorkflow, actualizarTimer } = props;

    return (
        <div id="cd-timeline" className="cd-container">
            {topicsData.map((data, index) => (
                <Topics
                    key={index}
                    topic={data.topic}
                    title={data.title}
                    description={data.description}
                    currentTopic={currentTopic}
                    currentTopicData={currentTopicData}
                    setModalBlockTopic={setModalBlockTopic}
                    setTopicForBlock={setTopicForBlock}
                    logicToChangeTopics={logicToChangeTopics}
                    isRepeatWorkflow={isRepeatWorkflow}
                    actualizarTimer={actualizarTimer}
                />
            ))}
        </div>
    )
}
