import React, { useContext, useEffect, useState } from "react";
import { Header } from "../NewHome/components";
import FooterG from "./components/Footer/FooterG";
import Step01 from "./Step01";
import Step02 from "./Step02";
import GenerarClaveContext from "../../context/generarClave/generarClaveContext";
import ErrorGenerico from "./errors/generico";
import "./index.css";

const GeneraClaveView = (props) => {


    const generarClaveContexto = useContext(GenerarClaveContext);
    const { gBloqueoPasos, bloquearPasos } = generarClaveContexto;
    const [step, setStep] = useState(1)
    const [block, setBlock] = useState(false)               

    const changeStep = () => [
        setStep(2)
    ]

    const changeStepOne = () => [
        setStep(1)
    ]

    const bloquear = () => {
        setStep(1)
        setBlock(true)
    }

    return (

        <div className="vertical-hidden">
            <Header />
            <div className="wrap-genera">
                <div className="privatezone-clave margin-center desktop-loginG padding-32">


                    <ErrorGenerico> </ErrorGenerico>


                    <h3>Genera tu clave web</h3>
                    {(step === 1)
                        ? <Step01 changeStepOne={changeStepOne} bloqueado={block} changeStep={changeStep}></Step01>
                        : <Step02 bloquear={bloquear}></Step02>
                    }
                </div>
            </div>

            
            {/* <FooterG></FooterG> */}
        </div>

    );
};

export default GeneraClaveView;
