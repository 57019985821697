import styled, { css } from "styled-components";
import { device } from "../../../../utils/device";

export const ContentWrapper = styled.div``;

export const Title = styled.div`
  ${(p) => p.theme.tipography.subtitleS}
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  color :${(p) => p.theme.palette.grayscale[700]};
  margin-bottom: 8px;
`;

export const Section = styled.div`
  margin-bottom: 16px;

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      text-indent: -20px; /* key property */
      margin-left: 20px; /* key property */
    }
  }
`;

export const Consideraciones = styled.div`
  ${(p) => p.theme.tipography.caption}
  color :${(p) => p.theme.palette.grayscale[400]};
  margin-bottom: 16px;
`;

export const BotonesDeCambioDeTipo = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border: 1px solid ${(p) => p.theme.palette.grayscale[100]};
  border-radius: 100px;
`;

export const BotonDeTipo = styled.button`
  ${(p) => p.theme.tipography.bodySmallRegular}
  background: transparent;
  outline: none;
  border: 1px solid transparent;
  padding: 5px 16px;
  margin: 0;
  cursor: pointer;
  border-radius: 100px;
  color: ${(p) => p.theme.palette.grayscale[800]};

  &[data-isactive="true"] {
    border-color: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const RequisitosWrapper = styled.div`
  ${(p) => css`
    @media screen and ${device.tablet} {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  `}
`;

export const RequisitoItem = styled.div`
  ${(p) => p.theme.tipography.bodySmallRegular}
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.palette.grayscale[100]};

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .file-icon {
    width: 16px;
    height: 16px;
    display: grid;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .body {
    .description {
    }
    .aditional {
      ${(p) => p.theme.tipography.caption}
      color: ${(p) => p.theme.palette.state.info}
    }
  }

  ${(p) =>
    p.$aditional &&
    css`
      row-gap: 24px;

      @media screen and ${device.tablet} {
        grid-template-columns: auto 1fr auto;
      }
    `}
`;

export const DownloadButtonWrapper = styled.div`
  grid-column: 1/-1;
  justify-self: end;
  ${css`
    @media screen and ${device.tablet} {
      grid-column: auto;
    }
  `}
`;
