import React from "react";
import { CardContainer } from "../../components";

const InfoUsuario = () => {
  return (
    <CardContainer
      title={"Recuerda"}
      seeMore={false}
      color={"#bf0d3e"}
      product={true}
      showHeader={true}
    >
      <ul className="recomendaciones mb-0">
        <li>
          {" "}
          <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
            A.
          </p>
          <p className="p-0 text-justify">
            La comisión mostrada es la comisión acumulada desde que ingresaste
            al Sistema Privado de Pensiones.
          </p>
        </li>
        <li>
          {" "}
          <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
            B.
          </p>
          <p className="p-0 text-justify">
            Se te cobra una comisión por el servicio de administración e
            inversión de tus fondos en el Perú y en el extranjero.
          </p>
        </li>
        <li>
          {" "}
          <p className="circle-item" style={{ padding: "0.05em 0.4em" }}>
            C.
          </p>
          <p className="p-0 text-justify">
            Si deseas conocer los tipos de comisión que existen en el Sistema
            Privado de Pensiones, puedes hacerlo{" "}
            <a
              href="https://www.afphabitat.com.pe/todo-sobre-el-sistema/"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
            .
          </p>
        </li>
      </ul>
    </CardContainer>
  );
};

export default InfoUsuario;
