import styled, { css } from "styled-components";

export const TitleComponent = styled.div`
  ${(p) => p.theme.tipography.bodySmallMedium}
  margin-bottom: 4px;
  grid-column: 1/-1;

  .required-star {
    color: ${(p) => p.theme.palette.primary.main};
  }

  ${(p) =>
    p.disabled === true &&
    css`
      color: ${(p) => p.theme.palette.grayscale[200]};
    `}
`;
