import React from "react";
import { useMediaQuery, Box, Typography, Button } from "@mui/material";
import { device } from "../../../../utils/constants";
import AdvertenciaIcon from "../../../../assets/IconBucket/Icon/AdvertenciaIcon";
import styled from "styled-components";

const Message = styled(Box)`
  ${(props) => props.theme.tipography.caption};
  align-items: center;
  border-radius: ${(props) => props.theme.spacing(2)};
  background: ${(props) =>
    props.bgAlert ? props.bgAlert : props.theme.palette.alert.info};
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(2)};
  box-shadow: ${(props) =>
    props.shadow ? "0px 4px 13px rgba(27, 29, 39, 0.12)" : "none"};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  color: #363a3e;
  & img {
    margin-right: ${(props) => props.theme.spacing(1)};
  }
`;

const Index = ({
  icon,
  alt,
  children,
  color,
  bgAlert,
  shadow,
  title,
  description,
  action,
  textAction,
  onClickAction,
}) => {
  return (
    <Message bgAlert={bgAlert} shadow={shadow}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginBottom: "auto",
          paddingRight: "8px",
        }}
      >
        {alt === "ICON_ADV" && <AdvertenciaIcon size={"24px"} />}
        {icon && <img src={icon} alt={alt} />}{" "}
      </Box>
      <Box sx={{ width: "100%" }}>
        {title && (
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {description}
          </Typography>
        )}
        {children}
        {action && (
          <Button
            variant="text"
            onClick={onClickAction}
            sx={{
              border: "none",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "8px",
              color: color ? color : "#000",
              float: "right",
              height: "auto",
              padding: "0",
            }}
          >
            {textAction}
          </Button>
        )}
      </Box>
    </Message>
  );
};

export default Index;
