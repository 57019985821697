import React, { useEffect, useContext } from "react";
import "./index.css";
import AuthContext from "../../context/autenticacion/authContext";
import hocVariables from "../../HOC/HocVariables";
import Button from "../Button";
const Modal = (props) => {
  const authContext = useContext(AuthContext);
  const { cerrarModalLoginForce, iniciarSesion } = authContext;

  const { limpiarVariablesContext } = props;

  useEffect(() => {
    limpiarVariablesContext();
    // eslint-disable-next-line
  }, []);

  const forzarInicio = () => {
    let datosLogin = props.datos;
    datosLogin.set("force", 1);
    iniciarSesion(datosLogin);
    cerrarModalLoginForce();
  };
  return (
    <div className="wraper-modal top-0">
      <div className="modal-content">
        <h5 className="card-title text-center " style={{ fontWeight: "600" }}>
          Advertencia
        </h5>
        <div>
          <p className="text-center">
            {props.mensaje
              ? props.mensaje
              : "Usted ya tiene una Sesión iniciada"}{" "}
            ¿Desea continuar aquí?
          </p>
          <div
            style={{
              border: "1px solid #f1f1f1",
              margin: "0 16px 16px",
              borderRadius: "16px",
              padding: "4px 8px",
              fontWeight: "500",
              background: "#f1f1f1",
            }}
          >
            Si acepta, las sesión en otros dispositivos quedaran desactivadas.
          </div>
          <div className="d-flex justify-content-around">
            <Button
              bgColor="white"
              name="secondary"
              className="btn btn-secondary"
              onClick={() => cerrarModalLoginForce()}
            >
              Cancelar
            </Button>
            <Button className="btn btn-primary" onClick={forzarInicio}>
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hocVariables(Modal);
