import moment from "moment";

export const getYearsFromNow = (date) => {
  return moment().diff(date, "years");
};

let relationships = {
  1: "Cónyuge",
  2: "Hijo(a)",
  3: "Padre",
  4: "Madre",
};

export const getRelationshipName = (id) => {
  return relationships[id];
};
