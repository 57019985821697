import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import JubilacionContext from "../../../../../../../context/jubilacion/pmo/jubPmoContext";
import CodeContext from "../../../../../../../context/code/codeContext";
import ModalDeErrorGeneral from "../../../../../Components/ModalDeErrorGeneral";

import HomeContext from "../../../../../../../context/home/homeContext";
import { montoFormat } from "../../../../../../../helper/amountFormat";
import useWindowSize from "../../../../../../../hooks/useWindowSize";
import { size } from "../../../../../../../utils/device";
import Grid from "@mui/material/Grid";
import AuthContext from "../../../../../../../context/autenticacion/authContext";
import Card from "../../../../../Components/Card";
import Icon from "../../../../../Components/IconSVG";
import ICON_FLAG from "../../../../../../../assets/IconBucket/ICON_FLAG";
import {
  ModalAceptarTerminos,
  ModalAceptarAcogimiento,
  ModalDeError,
  ModalTokenError,
} from "../../Modals";
import ModalDeToken from "../../Modals/ModalToken";
import {
  ContainerAsesoria,
  ContentResult,
  ResultAmountCalc,
  CalcAmount,
  AmountToday,
  TextLayaout,
  ContentSubtitle,
  GridContainerResult,
} from "./styles";
import AccordionDesktop from "../../AccordionDesktop";
import AccordionMobile from "../../AccordionMobile";
import Button from "../../../../../../../components/Buttons";
import {
  ButtonsWrapper,
  NextButton,
  PrevButton,
} from "../../../../../Components/ButtonsComponents";
import moment from "moment";
let objCalculo = {
  montoPension: "",
  montoNecesario: "",
  montoAcumulado: "",
  montoExcedente: "",
  montoFaltante: "",
};
const objComplete = {
  item1: false,
  open1: true,
  item2: false,
  open2: false,
  item3: false,
  open3: false,
};
export default function Calculo(props) {
  const {
    handleStepNavigation,
    getOutOfProcess,
    setShowModify,
    setShowStepper,
    showModify,
  } = props;
  const authContext = useContext(AuthContext);
  const { actualizarTimer } = authContext;
  const jubilacionContext = useContext(JubilacionContext);
  const homeContext = useContext(HomeContext);
  const {
    dataAccessPmo,
    eliminarAcogimiento,
    datoEliminarAcogimiento,
    obtenerCalculo,
    dataCalculo,
    guardarAcogimiento,
    dataAcogimiento,
    resetearPrevCalculo,
  } = jubilacionContext;
  const [showModal, setShowModal] = useState(false);

  const { fondos, obtenerDatosHome } = homeContext;
  const { getValues, setValue, trigger, reset } = useFormContext();
  const [expanded, setExpanded] = useState("panel1");
  const [idSolictud, setIdSolicitud] = useState("panel1");
  const [amountPension, setAMountPension] = useState(0);
  const [showStatusModify, setshowStatusModify] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpenModalToken, setIsOpenModalToken] = useState(false);
  const [beneficiarios, setBeneficiairos] = useState([]);
  const [arrayBeneficiarios, setArrayBeneficiarios] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [isShowModalAcceptAcogimiento, setIsShowModalAcceptAcogimiento] =
    useState(false);
  const [isShowModalAcceptTerm, setIsShowModalAcceptTerm] = useState(false);
  const [isShowModalTokenError, setIsShowModalTokenError] = useState(false);
  const { width } = useWindowSize();
  const isSmallScreen = width <= size.desktopS;
  const data = localStorage.getItem("client");
  const parseDatos = JSON.parse(data);
  let fechaCumpleaños = moment(parseDatos.r_fecha_cumpleaños);
  let getAaños = moment().diff(fechaCumpleaños, "years");
  const handleChange = (panel) => {
    setExpanded(panel);
  };
  let cantAaños = 65 - getAaños;
  useEffect(() => {
    actualizarTimer();
    window.scrollTo(0, 0);
    obtenerDatosHome();
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dataAccessPmo) {
      setAMountPension(dataAccessPmo.cbc);
      if (dataAccessPmo.r_acceso === 1) {
        setShowResult(true);
        setshowStatusModify(true);
        objCalculo.montoAcumulado = dataAccessPmo.data.t_acumulado;
        objCalculo.montoExcedente = dataAccessPmo.data.m_excedente;
        objCalculo.montoFaltante = dataAccessPmo.data.m_faltante;
        objCalculo.montoNecesario = dataAccessPmo.data.t_acumulado_necesario;
        objCalculo.montoPension = dataAccessPmo.data.m_pension;
        setIdSolicitud(dataAccessPmo.id);
      } else {
      }
    }
  }, [dataAccessPmo]);

  useEffect(() => {
    if (dataCalculo) {
      // if (dataCalculo.success) {

      if (dataCalculo.r_validacion === 1) {
        objCalculo.montoAcumulado = dataCalculo.data.t_acumulado;
        objCalculo.montoExcedente = dataCalculo.data.m_excedente;
        objCalculo.montoFaltante = dataCalculo.data.m_faltante;
        objCalculo.montoNecesario = dataCalculo.data.t_acumulado_necesario;
        objCalculo.montoPension = dataCalculo.data.m_pension;
        setIdSolicitud(dataCalculo.r_solicitud);
        setShowResult(true);
      } else {
        setShowResult(true);
        setModalIsOpen(true);
      }
      // } else {
      //   setShowModal(true);
      // }
    }
  }, [dataCalculo]);

  useEffect(() => {
    if (dataAcogimiento) {
      // if (dataAcogimiento.success) {
      if (dataAcogimiento.r_validacion === 1) {
        handleStepNavigation("next");
      } else {
        setIsShowModalTokenError(true);
      }
      // } else {
      //   setShowModal(true);
      // }
    }
  }, [dataAcogimiento]);
  useEffect(() => {
    if (datoEliminarAcogimiento) {
      // if (datoEliminarAcogimiento.success) {

      if (datoEliminarAcogimiento.r_validacion === 1) {
        handleStepNavigation("next");
      } else {
        setIsShowModalTokenError(true);
      }
      // } else {
      //   setShowModal(true);
      // }
    }
  }, [datoEliminarAcogimiento]);
  const fnBtnNextTopic = () => {
    setIsShowModalAcceptTerm(true);
  };

  const clearData = () => {
    setArrayBeneficiarios([]);
    setBeneficiairos([]);
    setExpanded("panel1");
    reset();
    objComplete.item1 = false;
    objComplete.open1 = true;
    objComplete.item2 = false;
    objComplete.open2 = false;
    objComplete.item3 = false;
    objComplete.open3 = false;
  };
  const handleObtenerCalculo = () => {
    let OBL = "OBL";
    if (getValues("calculo.conFin") === "true") {
      OBL = "OBL,VCF";
    }
    if (getValues("calculo.fondoLibre") === "true") {
      OBL = "OBL,VSF";
    }
    if (
      getValues("calculo.conFin") === "true" &&
      getValues("calculo.fondoLibre") === "true"
    ) {
      OBL = "OBL,VSF,VCF";
    }

    let objPost = {
      beneficiarios: beneficiarios,
      pension: getValues("calculo.amount") ? 1 : 0,
      amount: getValues("calculo.amount") ? getValues("calculo.amount") : null,
      cuentas: OBL,
    };

    obtenerCalculo(objPost);
  };

  const handleSetBeneficiairos = (obj) => {
    const newArray = [...beneficiarios, obj];
    setBeneficiairos(newArray);
  };
  const handleDeleteBeneficiairos = (dato) => {
    const newArray = beneficiarios.filter((item, index) => index !== dato);
    setBeneficiairos(newArray);
  };
  const onPrev = () => {
    handleStepNavigation("previous");
    resetearPrevCalculo();
    setShowResult(false);
    clearData();
  };

  return (
    <ContainerAsesoria>
      {showStatusModify ? (
        <h3 className="HBT_PMO_TitleContent">Mi Pensión Objetivo</h3>
      ) : (
        <h3 className="HBT_PMO_TitleContent">Cálculo de Pensión Objetivo</h3>
      )}

      {!showResult ? (
        <Grid container>
          <Grid xl={12} sm={12} xs={12}>
            <p style={{ marginBottom: "32px" }}>
              ¿Cuánto necesitas ahorrar para lograr la pensión que deseas?
              Ingresa los datos para calcularlo.
            </p>

            {isSmallScreen ? (
              <AccordionMobile
                handleChange={handleChange}
                expanded={expanded}
                setExpanded={setExpanded}
                handleSetBeneficiairos={handleSetBeneficiairos}
                setBeneficiairos={setBeneficiairos}
                arrayBeneficiarios={arrayBeneficiarios}
                setArrayBeneficiarios={setArrayBeneficiarios}
                amounPension={Number(amountPension)}
                fondos={fondos}
                cantAaños={cantAaños}
                objComplete={objComplete}
                handleDeleteBeneficiairos={handleDeleteBeneficiairos}
              />
            ) : (
              <AccordionDesktop
                handleChange={handleChange}
                expanded={expanded}
                setExpanded={setExpanded}
                amounPension={Number(amountPension)}
                handleSetBeneficiairos={handleSetBeneficiairos}
                setBeneficiairos={setBeneficiairos}
                fondos={fondos}
                arrayBeneficiarios={arrayBeneficiarios}
                setArrayBeneficiarios={setArrayBeneficiarios}
                cantAaños={cantAaños}
                objComplete={objComplete}
                handleDeleteBeneficiairos={handleDeleteBeneficiairos}
              />
            )}

            <ButtonsWrapper>
              <PrevButton
                variant="secondary"
                isBtnSecondary
                onClick={() => {
                  if (showModify) {
                    setShowResult(true);
                    setShowStepper(false);
                    setshowStatusModify(true);
                    clearData();
                  } else {
                    onPrev();
                  }
                }}
              >
                Volver
              </PrevButton>

              <NextButton
                disabled={expanded !== "panel3"}
                onClick={() => handleObtenerCalculo(true)}
              >
                Continuar
              </NextButton>
            </ButtonsWrapper>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {!showStatusModify && (
            <ContentSubtitle>
              <h4>Resultado</h4>
              <a
                onClick={() => {
                  clearData();
                  setIsShowModalAcceptAcogimiento(true);
                }}
              >
                Volver a calcular
              </a>
            </ContentSubtitle>
          )}
          {isSmallScreen && (
            <ContentResult>
              <Icon
                componentIcon={<ICON_FLAG />}
                width={"48px"}
                height={"48px"}
                style={{ backgroundColor: "#fff" }}
              />
              <p>
                Pensión mensual deseada
                <br /> <span> S/ {objCalculo.montoPension}</span>
              </p>
            </ContentResult>
          )}

          <Card>
            <GridContainerResult>
              <div>
                {!isSmallScreen && (
                  <ContentResult>
                    <Icon
                      componentIcon={<ICON_FLAG />}
                      width={"48px"}
                      height={"48px"}
                      style={{ backgroundColor: "#fff", borderRadius: "32px" }}
                    />
                    <p>
                      Pensión mensual deseada <br />{" "}
                      <span> S/ {montoFormat(objCalculo.montoPension)}</span>
                    </p>
                  </ContentResult>
                )}
                <ResultAmountCalc>
                  <span className="HBT_PMO_LabelTextFunds">
                    Fondos necesarios:
                  </span>
                  <span className="HBT_PMO_LabelTextAmount">
                    S/ {montoFormat(objCalculo.montoNecesario)}
                  </span>
                </ResultAmountCalc>
                <AmountToday>
                  <span className="HBT_PMO_LabelTextFunds">
                    Fondos acumulados al día de hoy:
                  </span>
                  <span className="HBT_PMO_LabelTextAmount">
                    S/ {montoFormat(objCalculo.montoAcumulado)}
                  </span>
                </AmountToday>
              </div>

              <div>
                <Card style={{ height: "159px" }}>
                  {objCalculo.montoExcedente !== "" && (
                    <CalcAmount>
                      <h2>Al jubilarte, podrías retirar</h2>
                      <h3>S/ {montoFormat(objCalculo.montoExcedente)}</h3>
                    </CalcAmount>
                  )}
                  {objCalculo.montoFaltante !== "" && (
                    <CalcAmount>
                      <h2>Necesitas seguir aportando un total de:</h2>
                      <h3>
                        S/ {montoFormat(Math.abs(objCalculo.montoFaltante))}
                      </h3>
                    </CalcAmount>
                  )}
                </Card>
                <TextLayaout>
                  Calculado según tu saldo al día de hoy
                </TextLayaout>
                {!isSmallScreen ? (
                  showStatusModify ? (
                    <ButtonsWrapper>
                      <PrevButton
                        variant="secondary"
                        isBtnSecondary
                        onClick={() => setIsOpenModalToken(true)}
                      >
                        Eliminar
                      </PrevButton>

                      <NextButton
                        variant="secondary"
                        isBtnSecondary
                        onClick={() => {
                          setShowModify(true);
                          setShowStepper(true);
                          setShowResult(false);
                          setshowStatusModify(false);
                        }}
                      >
                        Modificar
                      </NextButton>
                    </ButtonsWrapper>
                  ) : (
                    <ButtonsWrapper>
                      <PrevButton
                        variant="secondary"
                        isBtnSecondary
                        onClick={() => getOutOfProcess()}
                      >
                        Volver al inicio
                      </PrevButton>

                      <NextButton onClick={fnBtnNextTopic}>
                        Establecer Pensión Objetivo
                      </NextButton>
                    </ButtonsWrapper>
                  )
                ) : null}
              </div>
            </GridContainerResult>
          </Card>
          {isSmallScreen ? (
            showStatusModify ? (
              <ButtonsWrapper>
                <PrevButton
                  variant="secondary"
                  isBtnSecondary
                  onClick={() => setIsOpenModalToken(true)}
                >
                  Eliminar
                </PrevButton>

                <NextButton
                  variant="secondary"
                  isBtnSecondary
                  onClick={() => {
                    setShowModify(true);
                    setShowStepper(true);
                    setShowResult(false);
                    setshowStatusModify(false);
                  }}
                >
                  Modificar
                </NextButton>
              </ButtonsWrapper>
            ) : (
              <ButtonsWrapper>
                <PrevButton
                  variant="secondary"
                  isBtnSecondary
                  onClick={() => getOutOfProcess()}
                >
                  Volver al inicio
                </PrevButton>

                <NextButton onClick={fnBtnNextTopic}>
                  Establecer Pensión Objetivo
                </NextButton>
              </ButtonsWrapper>
            )
          ) : null}
        </Grid>
      )}

      <ModalAceptarAcogimiento
        isOpen={isShowModalAcceptAcogimiento}
        setIsOpen={setIsShowModalAcceptAcogimiento}
        startProcess={() => {
          setShowResult(false);
          setIsShowModalAcceptAcogimiento(false);
        }}
      />
      <ModalAceptarTerminos
        isOpen={isShowModalAcceptTerm}
        setIsOpen={setIsShowModalAcceptTerm}
        nextStep={() => {
          setIsShowModalAcceptTerm(false);
          setIsOpenModalToken(true);
        }}
      />

      <ModalDeToken
        showModal={isOpenModalToken}
        cerrarModal={() => {
          setIsOpenModalToken(!isOpenModalToken);
        }}
        setIsShowModalTokenError={setIsShowModalTokenError}
        isShowModalTokenError={isShowModalTokenError}
        guardarCode={(token) => {
          if (showStatusModify) {
            eliminarAcogimiento({ v_token_digital: token });
          } else {
            guardarAcogimiento({
              id_solicitud: idSolictud,
              v_token_digital: token,
            });
          }
        }}
      />

      <ModalDeError
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setIsShowModalTokenError={setIsShowModalTokenError}
        isShowModalTokenError={isShowModalTokenError}
      />
      {showModal && <ModalDeErrorGeneral />}
    </ContainerAsesoria>
  );
}
