import { clienteZonaBeneficiarios } from "../../../config/axios";
import * as actions from "./actions";

export const getAffiliateInfo =
  (dispatch, setGeneralError) => async (payload) => {
    try {
      dispatch({ type: actions.GET_AFFILIATE_INFO });

      const { data } = await clienteZonaBeneficiarios.post(
        "/gastos-sepelio/info-afiliado",
        payload
      );

      dispatch({ type: actions.AFFILIATE_SUCCESS, payload: data.data });
      dispatch({ type: actions.UPDATE_TOKEN, payload: data.token });
    } catch (error) {
      const message = error?.response?.data?.message;
      const token = error?.response?.data?.token;
      const status = error?.response?.status;

      if (status >= 500) {
        setGeneralError();
      }

      if (token) {
        dispatch({ type: actions.UPDATE_TOKEN, payload: token });
      }

      dispatch({
        type: actions.AFFILIATE_FAILURE,
        payload: message,
      });
    }
  };

export const resetAffiliateInfo = (dispatch, setGeneralError) => () => {
  dispatch({ type: actions.RESET_AFFILIATE_INFO });
};

export const getVerificationEmailToken =
  (dispatch, setGeneralError) => async (payload) => {
    try {
      dispatch({ type: actions.GET_VERIFICATON_EMAIL_TOKEN });

      const { data } = await clienteZonaBeneficiarios.post(
        "/gastos-sepelio/generar-token",
        payload
      );

      dispatch({
        type: actions.VERIFICATION_EMAIL_TOKEN_SUCCESS,
        payload: data.data,
      });
      dispatch({ type: actions.UPDATE_TOKEN, payload: data.token });
    } catch (error) {
      const message = error?.response?.data?.message;
      const token = error?.response?.data?.token;
      const status = error?.response?.status;

      if (status >= 500) {
        setGeneralError();
      }

      if (token) {
        dispatch({ type: actions.UPDATE_TOKEN, payload: token });
      }

      dispatch({
        type: actions.VERIFICATION_EMAIL_TOKEN_FAILURE,
        payload: message,
      });
    }
  };

export const validateEmailToken =
  (dispatch, setGeneralError) => async (payload) => {
    try {
      dispatch({ type: actions.VALIDATION_EMAIL_TOKEN });

      const { data } = await clienteZonaBeneficiarios.post(
        "/gastos-sepelio/validate-token",
        payload
      );

      dispatch({
        type: actions.VALIDATION_EMAIL_TOKEN_SUCCESS,
        payload: data.data,
      });
      dispatch({ type: actions.UPDATE_TOKEN, payload: data.token });

      return actions.VALIDATION_EMAIL_TOKEN_SUCCESS;
    } catch (error) {
      const message = error?.response?.data?.message;
      const token = error?.response?.data?.token;
      const status = error?.response?.status;

      if (status >= 500) {
        setGeneralError();
      }

      if (token) {
        dispatch({ type: actions.UPDATE_TOKEN, payload: token });
      }
      dispatch({
        type: actions.VALIDATION_EMAIL_TOKEN_FAILURE,
        payload: message,
      });

      return actions.VALIDATION_EMAIL_TOKEN_FAILURE;
    }
  };

export const sendRequest = (dispatch, setGeneralError) => async (payload) => {
  try {
    dispatch({ type: actions.SEND_REQUEST });

    const { data } = await clienteZonaBeneficiarios.post(
      "/gastos-sepelio/generar-solicitud",
      payload
    );

    dispatch({ type: actions.SEND_REQUEST_SUCCESS, payload: data.data });
    dispatch({ type: actions.UPDATE_TOKEN, payload: data.token });

    return actions.SEND_REQUEST_SUCCESS;
  } catch (error) {
    const message = error?.response?.data?.message;
    const token = error?.response?.data?.token;
    const status = error?.response?.status;

    if (status >= 500) {
      setGeneralError();
    }

    if (token) {
      dispatch({ type: actions.UPDATE_TOKEN, payload: token });
    }

    dispatch({
      type: actions.SEND_REQUEST_FAILURE,
      payload: message,
    });

    return actions.SEND_REQUEST_FAILURE;
  }
};
