import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  palette: {
    primary: {
      main: "#51565F",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
    info: {
      main: "#F6F6F6",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        underline: {
          "&&:hover::before": {
            backgroundColor: "transparent",
          },
        },
        root: ({ theme }) =>
          theme.unstable_sx({
            "& .MuiFilledInput-root": {
              borderRadius: "16px",
            },
          }),
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&&:hover::before": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: "16px",
            height: "55px",
            backgroundColor: "#F6F6F6",
          }),
      },
      variant: [
        {
          props: { variant: "selectModalToken" },
          style: {
            "& .root": {
              color: "#51565F",
            },
            borderRadius: "0px",
            "& .MuiSelect-iconStandard": {
              color: "#F6F6F6",
            },
            "&:focus": {
              backgroundColor: "#F6F6F6",
            },
          },
        },
      ],
    },
  },
});

export const ThemeButton = createTheme({
  palette: {
    primary: {
      main: "#F6F6F6",
      light: "#F2FAFF",
    },
    secondary: {
      main: "#CFCFCF",
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        backgroundColor: "#F6F6F6",
        textTransform: "none",
        boxShadow: "none",
        "&.Mui-disabled": {
          backgroundColor: "#F6F6F6",
          opacity: 0.7,
        },
        "& .Mui-focusVisible": {
          backgroundColor: "#F6F6F6",
        },
      },
    },
    variants: [
      {
        props: { variant: "withoutStyles" },
        style: {
          backgroundColor: "#F6F6F6",
          height: "40px",
          borderRadius: "16px",
          "&.Mui-disabled": {
            backgroundColor: "#F6F6F6",
            opacity: 0.7,
          },
          "& .Mui-focusVisible": {
            backgroundColor: "#F6F6F6",
          },
        },
      },
    ],
  },
});
