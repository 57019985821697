import { Modal } from "@mui/material";
import styled from "styled-components";

export const ModalAutorizacionReembolsoComponent = styled(Modal)`
  .MuiBackdrop-root {
    background-color: ${(p) => p.theme.palette.transparencies.dark};
  }
`;

export const ModalAutorizacionReembolsoBody = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  outline: none;
`;

export const Title = styled.h5`
  ${(p) => p.theme.tipography.h5}
  margin-bottom: 8px;
  text-align: center;
`;

export const Content = styled.h5`
  ${(p) => p.theme.tipography.bodySmallRegular}
  text-align: center;

  p:not(:last-of-type) {
    margin-bottom: 16px;
  }

  margin-bottom: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;
